import { memo } from 'react';
import SubmitButton from '../../../SubmitButton';
import SimpleBar from 'simplebar-react';

const MapFilters = memo(props => {
  const {
    count,
    isLoading,
    onSumbit,
    resetFilter,
    disableSubmit,
    component: Component
  } = props;


  return (
    <div className="sf__filter-block" style={{height: 'calc(100% - 155px)'}}>
      <div className="sf__filter sf__filter_on-map">
        <SimpleBar className="sf__map-scroll sf__map-scroll_filter">
          <Component {...props} />
        </SimpleBar>
        <div style={{textAlign: 'center', backgroundColor: 'white', borderRadius: '0 0 20px 20px', height: 100, position: 'absolute', left: 0, right: 0, bottom: 0}}>
          <SubmitButton
            className="sf__filter-btn button button_view_default sf__map-btn"
            onClick={onSumbit}
            isLoading={isLoading}
            type="button"
            disabled={disableSubmit}
          >
            {count}
          </SubmitButton>
          <span
            className="sf__clear-filter-btn link link_color_brand-3"
            onClick={resetFilter}
          >
            Очистить фильтр
          </span>
        </div>
      </div>
    </div>
  );
});

export default MapFilters;
