import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import useMedia from '../../hooks/useMedia';
import useSearchEngine from '../../hooks/useSearchEngine';
import { SmallCloseIcon } from '../Public/markup/icons';
import FilterSearchResult from './SmartFilter/FilterSearchResult';
import { DebounceInput } from 'react-debounce-input';


export const FilterDebouncedSearch = props => {
  const {
    toggleFilter,
    placeholder = "",
    hideInfoButton,
    suburbansSearch,
    searchResultHandler,
    results,
    onSearch,
    value
  } = props

  const isMobile = useMedia("isMobile")

  return (
    <div className="form__item form__item_2x form__item_main"
      style={suburbansSearch ? { flex: 1, flexBasis: '40%', marginBottom: 0} : { flex: 1, marginBottom: 0 }}
    >
      <div className="index-search__search">
        <label
          className="input index-search__search-input for-smart-filter"
          htmlFor="index-search"
        >
          <DebounceInput
            className="input__control smart-filter"
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            name="country"
            onFocus={() => toggleFilter?.(false)}
            minLength={1}
            debounceTimeout={500}
            onChange={e=>onSearch(e.target.value)}
            value={value}
          />
          <svg className="just-new-icon-search" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6919 17.1657C14.0056 17.1657 16.6919 14.4004 16.6919 10.9892C16.6919 7.57801 14.0056 4.81271 10.6919 4.81271C7.37819 4.81271 4.69189 7.57801 4.69189 10.9892C4.69189 14.4004 7.37819 17.1657 10.6919 17.1657Z" stroke="#2C2E3E" />
            <path d="M15.3081 15.0696L19.1919 18.7098" stroke="#2C2E3E" strokeLinecap="square" />
          </svg>
          <FilterSearchResult
            results={results}
            isVisible={!!results?.length}
            searchResultHandler={searchResultHandler}
            redesignResult={true}
          />
        </label>
        {!isMobile && !hideInfoButton &&
          <div className="info">
            <svg className="icon icon_info">
              <use xlinkHref="#info"></use>
            </svg>
            <div className="info__popup">
              <p>
                Начинайте вводить параметры фильтра и&nbsp;выбирайте
                из&nbsp;предложенного списка. Вы&nbsp;также можете любой запрос
                добавить в&nbsp;исключения
              </p>
            </div>
          </div>
        }
        {!!value?.length &&
          <div className="info" style={{ backgroundColor: "#fff", cursor: "pointer" }} onClick={()=>onSearch("")}>
            <SmallCloseIcon />
          </div>}

      </div>
    </div>
  );
}
