import React, { memo, useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { PhotoPopup } from "../../components/Viewer/PhotoSlider";
import Button from "../../components/core/Button";
import { CarIcon, WalkIcon } from "../../components/core/Card/icons";
import Modal from "../../components/core/Modal";
import PanModal from "../../components/core/PanModal";
import { ZoomIcon } from "../../components/core/SwiperSlider/icons";
import { useMapModal } from "../../hooks/useMapModal";
import AddCompare from "./AddCompare";
import './index.scss';
import {Prompt, useLocation, useParams} from "react-router-dom";
import useApi from "../../hooks/useApi";
import request, { getUrl } from "../../api";
import { formatMoney } from "../../utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ContentStateContexProvider, useContentStateContext } from "./ContentStateContex";
import useMedia from "../../hooks/useMedia";
import {Binoculars} from "../../components/Public/markup/icons";
import Preloader from "../../components/Preloader";
import { onImgError } from "../../utils/special";


const fieldsBySettings = {
  showArea: ["squareTotal", "squareKitchen"],
  showBuildingAndSection: ["section", "addressCorp"],
  showBuildingType: ["buildingType"],
  showCompletionDate: ["ending"],
  showContractType: ["contractType"],
  showFinish: ["decoration"],
  showKeyIssueDate: ["issuingKeys"],
  showMeterPrice: ["meterPrice"],
  showPaymentMethod: ["paymentTypes"],
  showPrice: ["minPrice"],
  showCeilingHeight: ["сellHeight"],
  showFloor: ["floorFormat"],
  showBlockTitle: ["blockTitle"],
  showBuilderTitle: ["builderTitle"],
  showDifference: ["showDifference"]
}


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CompareSlide = memo(({
                             item,
                             settings,
                             setGallery,
                             floorPlanRef,
                             setActiveId,
                             activeId,
                             deletedId,
                             setDeletedId,
                             moveItemToFirst,
                             toggleFirstFixation,
                             fixedPrice,
                             setFixedPrice,
                             metrPrice,
                             setMetrPrice,
                             setTotalSquere,
                             totalSquere
}) => {
  const isDesktop = useMedia('isDesktop');
  // console.log(squereTotal)
  const [openMapModal, closeMapModal] = useMapModal({
    cords: item.coordinates,
    mapCenter: item.coordinates,
  })
  const { elements, setElements, getElementsMaxHeight } = useContentStateContext()

  const [openModal, hideModal] = useModal(({}) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compare" closeByPastClick
           close={hideModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#EF372E"/>
          <line x1="12.0435" y1="7.5" x2="12.0435" y2="13.5" stroke="#EF372E" stroke-linecap="round"/>
          <circle cx="12.0435" cy="15.5" r="0.5" fill="#EF372E"/>
        </svg>
        Удалить объект из сравнения?
      </div>

      <div style={{display: 'flex', gap: 10}}>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={hideModal}>Нет</Button>
        </div>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={() => {
            setDeletedId(prev => (prev === item.id ? null : item.id))
            hideModal()
          }}>Да</Button>
        </div>
      </div>

    </Modal>
  ), []);



  const numberPrice = (price) => parseInt(price.replace(/\D/g, ''), 10)
  const stringPrice = (price) => price.toLocaleString('ru-RU') + ' ₽'
  const stringSquere = (price) => price.toLocaleString('ru-RU') + ' м²'


  function calculatePercentageDifference(baseNumber, compareNumber, mode) {
    const percent = ((compareNumber - baseNumber) / baseNumber) * 100;

    if (mode === "greater" && compareNumber > baseNumber) {
      return Math.round(percent) + '%';
    }
    if (mode === "greaterMetr" && compareNumber > baseNumber) {
      return Math.round(percent) + 'м²';
    }

    if (mode === "less" && compareNumber < baseNumber) {
      return Math.round(percent) + '%';
    }
    if (mode === "lessMetr" && compareNumber < baseNumber) {
      return Math.round(percent) + 'м²';
    }

    return null;
  }

  const ListItem = memo(({ title, value, field, stat }) => Object.entries(fieldsBySettings).some(([settingName, fields]) => settings[settingName] && fields?.includes(field)) && <li className="compare-slider__list-item compare-slider__list-item_stat" ref={ref=>setElements(ref, field)} style={{minHeight: getElementsMaxHeight(field)}}>
    <div>
    <p className="compare-slider__list-label">{title}</p>
    <p className="compare-slider__list-value">{(!!value || value === 0) ? value : "—"}</p>
    </div>
    {activeId &&
    <div className="compare-slider__list-statistic">

      {item.id !== activeId && field === 'minPrice' && numberPrice(stat) > numberPrice(fixedPrice) &&
        <div style={{color: '#2CBA2E'}}>
          {settings.showDifference && <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}><path d="M7.825 4.44326L4.675 1.39565" stroke="#2CBA2E" stroke-linecap="round"/><path d="M4.325 1.3956L1.175 4.44322" stroke="#2CBA2E" stroke-linecap="round"/><path d="M4.49999 1.20515L4.49999 12.4194" stroke="#2CBA2E" stroke-linecap="round"/></svg>}
          {settings.showDifference === 'absolute' && (!!value || value === 0) &&
            stringPrice(numberPrice(stat) - numberPrice(fixedPrice))  }
          {settings.showDifference === 'percent' && (!!value || value === 0) &&
            calculatePercentageDifference(numberPrice(fixedPrice), numberPrice(stat), "greater")}
        </div>}

      {item.id !== activeId && field === 'minPrice' && numberPrice(fixedPrice) > numberPrice(stat) &&
        <div style={{color: '#FF2D23'}}>
          {settings.showDifference && <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}><path d="M1.175 9.3956L4.325 12.4432" stroke="#FF2D23" stroke-linecap="round"/><path d="M4.675 12.4433L7.825 9.39565" stroke="#FF2D23" stroke-linecap="round"/><path d="M4.50001 12.6337L4.50001 1.41943" stroke="#FF2D23" stroke-linecap="round"/></svg>}
          {settings.showDifference === 'absolute' && (!!value || value === 0) &&
            stringPrice(numberPrice(fixedPrice) - numberPrice(stat))}
          {settings.showDifference === 'percent' && (!!value || value === 0) &&
            calculatePercentageDifference(numberPrice(fixedPrice), numberPrice(stat), "less")}
        </div>}

      {item.id !== activeId && field === 'meterPrice' && stat > metrPrice &&
        <div style={{color: '#2CBA2E'}}>
          {settings.showDifference && <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}><path d="M7.825 4.44326L4.675 1.39565" stroke="#2CBA2E" stroke-linecap="round"/><path d="M4.325 1.3956L1.175 4.44322" stroke="#2CBA2E" stroke-linecap="round"/><path d="M4.49999 1.20515L4.49999 12.4194" stroke="#2CBA2E" stroke-linecap="round"/></svg>}
          {settings.showDifference === 'absolute' && (!!value || value === 0) &&
            stringPrice(stat - metrPrice)}
          {settings.showDifference === 'percent' && (!!value || value === 0) &&
            calculatePercentageDifference(metrPrice, stat, "greater")}
        </div>}

      {item.id !== activeId && field === 'meterPrice' && metrPrice > stat  &&
        <div style={{color: '#FF2D23'}}>
          {settings.showDifference && <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}><path d="M1.175 9.3956L4.325 12.4432" stroke="#FF2D23" stroke-linecap="round"/><path d="M4.675 12.4433L7.825 9.39565" stroke="#FF2D23" stroke-linecap="round"/><path d="M4.50001 12.6337L4.50001 1.41943" stroke="#FF2D23" stroke-linecap="round"/></svg>}
          {settings.showDifference === 'absolute' && (!!value || value === 0) &&
            stringPrice(metrPrice - stat )}
          {settings.showDifference === 'percent' && (!!value || value === 0) &&
            calculatePercentageDifference(metrPrice, stat, "less")}
        </div>}




      {item.id !== activeId &&  field === 'squareTotal' && stat > totalSquere &&
        <div style={{color: '#2CBA2E'}}>
          {settings.showDifference && <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}><path d="M7.825 4.44326L4.675 1.39565" stroke="#2CBA2E" stroke-linecap="round"/><path d="M4.325 1.3956L1.175 4.44322" stroke="#2CBA2E" stroke-linecap="round"/><path d="M4.49999 1.20515L4.49999 12.4194" stroke="#2CBA2E" stroke-linecap="round"/></svg>}
          {settings.showDifference === 'absolute' && (!!value || value === 0) &&
            stringSquere(stat - totalSquere)  }
          {settings.showDifference === 'percent' && (!!value || value === 0) &&
            calculatePercentageDifference(totalSquere, stat, "greaterMetr")}
        </div>
      }

      {item.id !== activeId &&  field === 'squareTotal' && totalSquere > stat &&
        <div style={{color: '#FF2D23'}}>
          {settings.showDifference && <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}><path d="M1.175 9.3956L4.325 12.4432" stroke="#FF2D23" stroke-linecap="round"/><path d="M4.675 12.4433L7.825 9.39565" stroke="#FF2D23" stroke-linecap="round"/><path d="M4.50001 12.6337L4.50001 1.41943" stroke="#FF2D23" stroke-linecap="round"/></svg>}
          {settings.showDifference === 'absolute' && (!!value || value === 0) &&
            stringSquere(totalSquere - stat )  }
          {settings.showDifference === 'percent' && (!!value || value === 0) &&
            calculatePercentageDifference(totalSquere, stat, "lessMetr")}
        </div>
      }


    </div>
    }
  </li>)
  return <>
    <div className="compare-slider__item" style={{ minWidth: isDesktop ? 310 : 294.4, maxWidth: isDesktop ? 310 : 294.4, userSelect: "none" }} ref={ref=>setElements(ref, "slide")}>
        <img style={{marginLeft: 10, marginRight: 10}} className="compare-table__photo-building" src={getUrl(item.blockPhoto?.src)} onError={onImgError}/>
        <div className="compare-slider__item-plan">
          <div className="compare-slider__plan-image">
            <Button
              buttonType="clean"
              addClass="swiper-zoom"
              onClick={() => setGallery([{ "src": item.plan.src }])}
            >
              <ZoomIcon />
            </Button>

            <img onClick={() => setGallery([{ "src": item.plan.src }])}
              src={getUrl(item.plan.src)}
              alt=""
              onError={onImgError}
              />
          </div>
          <div className="compare-table__zoom-items">
              <span
                className={`compare-table__drag ${item.id === activeId ? "active" : ""}`}
                onClick={() => {
                  setActiveId(prev => (prev === item.id ? null : item.id))
                  setFixedPrice(prev => (prev === item.minPrice ? null : item.minPrice))
                  setMetrPrice(prev => (prev === item.meterPrice ? null : item.meterPrice))
                  setTotalSquere(prev => (prev === item.squareTotal ? null : item.squareTotal))
                  moveItemToFirst(item.id);
                  toggleFirstFixation()
                }}

              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.54423 14.409L4 19.9532" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.4722 4.13111L9.84156 8.76072C9.84156 8.76072 7.78341 7.72718 5.58058 9.51085C5.4575 9.60931 5.37855 9.7526 5.36109 9.90924C5.34362 10.0659 5.38906 10.2231 5.48743 10.3462C5.50091 10.3633 5.51547 10.3795 5.53103 10.3948L13.5427 18.4064C13.6532 18.5187 13.8038 18.5825 13.9614 18.5838C14.1189 18.5851 14.2706 18.5238 14.383 18.4133C14.4046 18.3929 14.4245 18.3707 14.4424 18.3469C15.0667 17.5215 16.0477 15.8122 15.1926 14.1107L19.8232 9.48013C19.9353 9.36944 19.9989 9.21877 20 9.06121C20.0011 8.90365 19.9397 8.75209 19.8291 8.63983L19.8232 8.63289L15.3184 4.13111C15.2079 4.01885 15.0573 3.95506 14.8997 3.95376C14.7421 3.95246 14.5905 4.01375 14.4781 4.12418L14.4722 4.13111Z" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.97168 8.72305L12.6769 11.4362" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            <span onClick={() => {
              openModal()
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.99219H19" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 9.99219V15.9922" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 9.99219V15.9922" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 5.99219V19.2922C6 19.4778 6.07024 19.6559 6.19526 19.7872C6.32029 19.9184 6.48986 19.9922 6.66667 19.9922H17.3333C17.5101 19.9922 17.6797 19.9184 17.8047 19.7872C17.9298 19.6559 18 19.4778 18 19.2922V5.99219" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 5.99219V4.99219C9 4.72697 9.12643 4.47262 9.35147 4.28508C9.57652 4.09754 9.88174 3.99219 10.2 3.99219H13.8C14.1183 3.99219 14.4235 4.09754 14.6485 4.28508C14.8736 4.47262 15 4.72697 15 4.99219V5.99219" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </span>
          </div>
        </div>
        <div style={{marginBottom: 10}} className={`label-item label_${item.status.color}`}>{item.status.name}</div>
        <div className="compare-slider__item-title h5-l">{item.title}</div>

      <div className="compare-slider__object-photo" ref={ref=>setElements(ref, "compareButton")} style={{minHeight: getElementsMaxHeight("compareButton")}}>
        {!!item.coordinates && <div className="compare-slider__object-image">
          <Button
            buttonType="clean"
            addClass="swiper-zoom"
            onClick={openMapModal}
          >
            <ZoomIcon />
          </Button>
          <img onClick={openMapModal}
            //карты
               src="https://crm.1-an.ru/upload/iblock/ded/pafazq6l3rlt9j0mb3j4ctxxjnor2pbg/123456_vosstanovleno_vosstanovleno.jpg"
               alt="" />
        </div>}
        <p className="compare-slider__object-description compare-slider__object-description_address"
           ref={ref => setElements(ref, "compareAddress")}
           style={{ minHeight: getElementsMaxHeight("compareAddress"), height:  getElementsMaxHeight("compareAddress")}}
        >{item.address}</p>
        <div className="sf-card__metro-list"
        ref={ref => setElements(ref, "compareSubways")}
        style={{ minHeight: getElementsMaxHeight("compareSubways"), height:  getElementsMaxHeight("compareSubways")}}
        >
          {item.subway && item.subway.map(({ color, distanceType, minutes, title }) => (
            <div className={`sf-card__metro`} key={title}>
                <span className="sf-card__metro-station">
                  <span
                    className="sf-card__metro-circle"
                    style={{ backgroundColor: color }}
                  />
                  м. {title}
                </span>
              {!!distanceType && !!minutes && (
                <div className="sf-card__metro-time">
                  {distanceType === 'byTransport' ? (
                    <CarIcon />
                  ) : (
                    <WalkIcon />
                  )}
                  <span className="sf-card__metro-route">{minutes} мин.</span>
                </div>
              )}
            </div>
          ))}
        </div>

        <div
          className="compare-table__note compare-table__note_basic"
          ref={ref => setElements(ref, "compareNotes")} style={{ minHeight: getElementsMaxHeight("compareNotes"), height:  getElementsMaxHeight("compareNotes")}}
        >
          Заметка:
          <p className="compare-table__note-text">{item.note}</p>
        </div>

      </div>

      <ul className="compare-slider__list">
          <ListItem title="Цена, 100% оплата" value={item.minPrice} stat={item.minPrice} field="minPrice" />
          <ListItem title="Цена за м²" value={formatMoney(item.meterPrice, " ₽")} stat={item.meterPrice} field="meterPrice" />
          <ListItem title="Общая площадь, м²" value={item.squareTotal} field="squareTotal" stat={item.squareTotal}/>
          <ListItem title="Площадь кухни, м²" value={item.squareKitchen} field="squareKitchen" />
          <ListItem title="Этаж" value={item.floorFormat} field="floorFormat" />
          <ListItem title="Высота потолков" value={item.сellHeight} field="сellHeight" />
          <ListItem title="Отделка" value={item.decoration} field="decoration" />
        </ul>

        {!!item.floorPlan && <p style={{marginTop: 30}} className="fixed compare-slider__plan-title h5-l">План этажа</p>}
        {!!item.floorPlan && <div style={{marginBottom: 30}} className="compare-slider__floor-plan" ref={floorPlanRef}>
        <Button
          buttonType="clean"
          addClass="swiper-zoom"
          onClick={() => setGallery([{ "src": item.floorPlan.src }])}
        >
          <ZoomIcon />
        </Button>
        <img onClick={() => setGallery([{ "src": item.floorPlan.src }])}
             src={getUrl(item.floorPlan?.src)} alt="" />
      </div>}

        <ul className="compare-slider__list">
          <ListItem title="Форма договора" value={item.contractType} field="contractType" />
          <ListItem title="Корпус / участок / Дом" value={item.addressCorp} field="addressCorp" />
          <ListItem title="Секция" value={item.section} field="section" />
          <ListItem title="Срок сдачи" value={item.ending} field="ending" />
          <ListItem title="Срок выдачи ключей" value={item.issuingKeys} field="issuingKeys" />
          <ListItem title="Способы оплаты" value={item.paymentTypes} field="paymentTypes" />
          <ListItem title="Тип дома" value={item.buildingType} field="buildingType" />
          <ListItem title="Застройщик" value={item.builderTitle} field="builderTitle" />
          <ListItem title="ЖК" value={item.blockTitle} field="blockTitle" />
        </ul>

    </div>
  </>
})

const Compare = memo(props => {

  const { id,  } = useParams()

  const location = useLocation();
  const isPublic = location.pathname.includes("public");


  const { data: { items: itemsTmp = [], title, compilation, agent, isChanged }, isLoading, send, setData, refresher, } = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilationCompare", { id }],
  })

  const isDesktop = useMedia('isDesktop');
  const isTablet = useMedia('isTablet');
  const [gallery, setGallery] = useState(false);


  const [dwnCompare, setDwnCompare] = useState(false)
  const [openCompareModal, closeCompareModal] = useModal(
    () => (
      <PanModal title={dwnCompare ? 'Скачать сравнение' : 'Отправить сравнение'} closeModal={() => {
        setDwnCompare(false)
        closeCompareModal()
      }} isSmall>
        <AddCompare download={dwnCompare} />
      </PanModal>
    ), [dwnCompare]
  );




  const [scrollRef, setScrollRef] = useState()

  const [scrollRefLeft, setScrollRefLeft] = useState(scrollRef?.scrollLeft ?? 0)

  const hasPrev = scrollRefLeft > 0;

  const hasNext = scrollRefLeft < (scrollRef?.scrollWidth - scrollRef?.clientWidth)

  const onScroll = (e) => setScrollRefLeft(e.target.scrollLeft)

  const scrollElems = direction => {
    if (!scrollRef) return
    const currentPosition = scrollRef.scrollLeft;
    const elementWidth = scrollRef.firstChild.clientWidth
    scrollRef.scrollLeft = direction === "prev" ? currentPosition - elementWidth : currentPosition + elementWidth
    setScrollRefLeft(scrollRef.scrollLeft)
  }

  const onResize = () => scrollElems("next");

  const onBeforeUnload = e => {
    e.preventDefault();
    e.returnValue = '';
  }

  useEffect(() => {
    if (!scrollRef) return
    window.addEventListener("resize", onResize)
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.removeEventListener("resize", onResize)
    }
  }, [scrollRef])


  const floorPlanRef = useRef(null);

  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;
    if (isFirstFixed && destination.index === 0) {
      return;
    }
    setData((prev) => ({
      ...prev,
      items: reorder(prev.items, source.index, destination.index),
      isChanged: true,
    }));
  };

  const [activeId, setActiveId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [fixedPrice, setFixedPrice] = useState(null)
  const [metrPrice, setMetrPrice] = useState(null)
  const [totalSquere, setTotalSquere] = useState(null)

  const [isFirstFixed, setIsFirstFixed] = useState(false);
  const toggleFirstFixation = () => {
    setIsFirstFixed((prev) => !prev);
    scrollRef.scrollTo({ left: 0, behavior: "smooth" });
  };

  const saveComparePdf = () => {
    return request('panpartner:favorite.ajax', 'getCompilationComparePdf', { id })
      .then(
        data => {
          window.open(getUrl(data?.pdf?.path), '_blank')
          return Promise.resolve(data);
        },
      );
  }

  useEffect(() => {
    setData(prev => {
      return {
        ...prev,
         items:  itemsTmp.filter(item => item.id !== deletedId),
      }
    })
  }, [deletedId])

  const moveItemToFirst = (id) => {
    setData((prev) => {
      const items = [...prev.items];
      const selectedItem = items.find((item) => item.id === id);
      const filteredItems = items.filter((item) => item.id !== id);
      return {
        ...prev,
        items: [selectedItem, ...filteredItems],
      };
    });
  };

  if (isLoading) return <Preloader loading={true} />
  return (
    <>
      {isPublic &&
        <div className="compilation-public-header" style={{marginBottom: 0}}>
          <div className="wrapper" style={{maxWidth: 768}}>
            <Binoculars/>
            <h2 className="compilation-public-header__title h2">
              {compilation?.title}
            </h2>
            <p className="compilation-public-header__info-text">
              {compilation?.comment}
            </p>
          </div>
        </div>
      }
      <div className='wrapper compare'>
      <div className="compare-table__header-title" style={{marginTop: 30, paddingLeft: 10, paddingRight: 10}}>
        <h3 className='h3 compare__title'>{!isPublic ? 'Сравнение' : ''}</h3>
        <div className="compare-table__header-actions">
          <span className="link link_color_black" onClick={saveComparePdf}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 11L12 14L15 11" stroke="#2C2E3E" stroke-linecap="round"/>
              <path d="M12 13L12 5" stroke="#2C2E3E" stroke-linecap="round"/>
              <path d="M5 13L5 18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V13" stroke="#2C2E3E" stroke-linecap="round"/>
            </svg>
            Сохранить
          </span>
        </div>
      </div>

      <section>

          <div className="swiper-overbtn">
            {hasPrev && <div className="swiper-button-prev" onClick={()=>scrollElems("prev")} />}
            {hasNext && <div className="swiper-button-next" onClick={()=>scrollElems("next")} />}
          </div>
    <ContentStateContexProvider>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={ref=>{
                  provided.innerRef(ref)
                  setScrollRef(ref)
                }}
                {...provided.droppableProps}
                className="drop-content"
                onScroll={onScroll}
              >
                {itemsTmp.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={!isTablet ? `item-${item.id}` : false}
                    index={index}
                    isDragDisabled={item.id === activeId}
                  >
                    {provided => <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CompareSlide
                        item={item}
                        setGallery={setGallery}
                        floorPlanRef={floorPlanRef}
                        settings={compilation.settings}
                        setActiveId={setActiveId}
                        setFixedPrice={setFixedPrice}
                        fixedPrice={fixedPrice}
                        activeId={activeId}
                        setDeletedId={setDeletedId}
                        deletedId={deletedId}
                        moveItemToFirst={moveItemToFirst}
                        toggleFirstFixation={toggleFirstFixation}
                        metrPrice={metrPrice}
                        setMetrPrice={setMetrPrice}
                        totalSquere={totalSquere}
                        setTotalSquere={setTotalSquere}
                      />
                    </div>}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        </ContentStateContexProvider>
      </section>

        {/*{isPublic &&*/}
        {/*  <div className="compare-public-footer">*/}
        {/*    <div className="compare-slider">*/}
        {/*      <ul className="compare-slider__list">*/}
        {/*        <li className="compare-slider__list-item">*/}
        {/*          <p className="compare-slider__list-label">Ваш агент</p>*/}
        {/*          <p className="compare-slider__list-value">{agent?.name + ' ' + agent?.lastName}</p>*/}
        {/*        </li>*/}
        {/*        <li className="compare-slider__list-item">*/}
        {/*          <p className="compare-slider__list-label">Телефон</p>*/}
        {/*          <p className="compare-slider__list-value">{agent?.phone}</p>*/}
        {/*        </li>*/}
        {/*        <li className="compare-slider__list-item">*/}
        {/*          <p className="compare-slider__list-label">E-mail</p>*/}
        {/*          <p className="compare-slider__list-value">-</p>*/}
        {/*        </li>*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*}*/}

      <PhotoPopup
        items={gallery}
        index={0}
        open={!!gallery?.length}
        onClose={() => setGallery(false)}

      />
         {isChanged && <Prompt message="Изменения, сделанные на странице, не сохранятся. Вы точно хотите выйти?" />}
    </div>
    </>
  )

})

export default Compare;
