import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editClients, getClient } from '../../actions/clients';
import Preloader from '../Preloader';
import Form from './Form';


const groups = [
  {
    id: 'agent',
    title: 'Агент',
    description: 'Имеет доступ к базе. Не видит комиссионное вознаграждение.',
  },
  {
    id: 'agent_extended',
    title: 'Агент&nbsp;с&nbsp;расширенным доступом',
    description: 'Имеет доступ к базе и комиссионному вознаграждению. ',
  },
  {
    id: 'master_account',
    title: 'Мастер-аккаунт',
    description:
      'Имеет доступ к базе, комиссионному вознаграждению, может добавлять сотрудников.',
  },
];

const Edit = (props) => {
  const { add, history } = props;
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    getClient(id).then(
      (data) => {
        const newData = {
          id: data.client.id,
          clientId: data.client.id,
          name: data.client.name,
          lastName: data.client.lastName,
          city:
            data.client.city == 'spb' ? 'Санкт-Петербург' : data.client.city,
          street: data.client.street,
          house: data.client.house,
          status: data.client.status.toString(),
          managerId: data.client.agent,
          oldManagerId: data.client.agent,
          addToFavourite: data.client.isFavorite,
          number: data.client.number,
          patronymic: data.client.patronymic,
          phone:
            data.client.phone && data.client.phone.length
              ? data.client.phone
              : [''],
          email:
            data.client.email && data.client.email.length
              ? data.client.email
              : [''],
          comment: data.client.comment || '',
        };
        setData({
          agents: data.agents.map((agent) => ({
            id: agent.id,
            title: `${agent.lastName} ${agent.name}`,
          })),
          statuses: data.statuses.map((status) => ({
            id: status.id,
            title: status.name,
            color: status.color,
          })),
          advertisingId: data.client.advertisingId,
          defaultValues: newData,
        });
        setFetching(false);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [id]);

  return (
    <>
      {data && <Form add={add} history={history} edit={true} {...data} />}
      <Preloader loading={fetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  add: (form) => dispatch(editClients(form)),
});

export default connect(null, mapDispatchToProps)(Edit);
