import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

const Confirm = (props) => {
	const { close, confirm, cancel, title, buttonLabel } = props;

	useEffect(() => {
		document.body.classList.add('no-scroll');
		return () => document.body.classList.remove('no-scroll');
	});

	return (
		<Modal close={close}>
			<h2 class="modal__title">{title}</h2>
			<div class="modal-form__submit">
				<button
					className="button button_view_outline-with-icon clients__back-btn"
					onClick={cancel}
				>
					<svg className="icon icon_back">
						<use xlinkHref="#back"></use>
					</svg>
					<span>Вернуться</span>
				</button>
				<button
					class="button button_view_default js-del-confirm"
					onClick={confirm}
				>
					{buttonLabel ? buttonLabel : 'Удалить'}
				</button>
			</div>
		</Modal>
	);
};

export default Confirm;
