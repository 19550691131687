import { useSelector } from 'react-redux';

const usePermission = (permission = null) => {
  const access = useSelector((state) => state.user.access);
  const clientMode = useSelector((state) => state.general.clientMode);

  switch (permission) {
    case 'not-agent':
      return ['agent', 'extended'].indexOf(access) === -1;
    case 'agent':
      return ['general', 'master', 'marketer', "head_sales_dep"].indexOf(access) > -1 && !clientMode;
    case 'marketer':
      return ['general', 'marketer', "head_sales_dep"].indexOf(access) > -1;
    case 'general':
      return ['general'].indexOf(access) > -1;
    case 'motivation':
      return ['agent', "test_access"].indexOf(access) < 0 && !clientMode;
    case 'client':
      return clientMode;
    case 'agency:edit':
      return ['general'].indexOf(access) > -1 && !clientMode;
    case 'toprealtors':
      return access === 'toprealtors';
    case 'master+':
      return ['general', 'master'].includes(access)
    default:
      return false;
  }
};

export default usePermission;
