import React, { useEffect, useMemo, useRef, useState } from "react";
import './index.scss'
import DetailHeader from "../../Public/markup/detail/DetailHeader";
import Slider from "../../Public/markup/detail/Slider";
import InfoCard from "../../Public/markup/detail/InfoCard";
import Button from "../../core/Button";
import { Link, useParams, useLocation } from "react-router-dom";
import useMedia from "../../../hooks/useMedia";
import {formatMoney, getSuffix, scrollToRef, toFormData} from "../../../utils";
import { useModal } from "react-modal-hook";
import PanModal from "../../core/PanModal";
import ContactsModal from "../../Public/markup/detail/ContactsModal";
import NewAlert from "../../core/NewAlert";
import ResidenceHint from "../../ResidenceHint";
import request, { getUrl } from "../../../api";
import Card from "../../core/Card";
import VideoList from "../../Public/markup/detail/VideoList";
import CaseModal from "../../CaseModal";
import Help from "../../Public/markup/detail/Help";
import Files from "../../Public/markup/detail/Files";
import About from "../../Public/markup/detail/About";
import Furnish from "../../Public/markup/detail/Furnish";
import Iframe from "../../Public/markup/detail/Iframe";
import { PhotoPopup } from "../../Viewer/PhotoSlider";
import Preloader from "../../Preloader";
import Remains from "../../Remains";
import NotFound from "../../Public/markup/detail/NotFound";
import useFilter from "../../../hooks/useFilter";
import DubaiComplexFilterForm from "../../Public/markup/DubaiComplexFilterForm";
import Tags from "../../Public/markup/Tags";
import FastViewDubaiModal from "./FastView";
import Header from "../../Public/markup/Header";
import ContactCard from "../../core/ContactCard";
import SubmitButton from "../../SubmitButton";
import {useSelector} from "react-redux";
import ManagerModal from "../../Public/markup/detail/ManagerModal";
import FilterToggleBtn from "../../Public/markup/FilterToggleBtn";
import Wrap from "../../core/ModalWrap";
import MobileModal from "../../core/MobileModal";
import Modal from "../../core/Modal";
import MobileMapFilter from "../../Public/markup/MapFilters/MobileMapFilter";
import SendFilter from "../../SavedFilters/SendFilter";

const ComplexDubai = () => {

  const { blockId, savedFilterXmlId, city } = useParams();
  const isTablet = useMedia('isTablet');
  const isMobile = useMedia('isMobile');
  // const [priceFormat, setPriceFormat] = useState(getURLparam("currency") ?? 'USD')
  const [priceFormat, setPriceFormat] = useState('RUB')
  const [currency, setCurrency] = useState('RUB');
  const [mobileComplexInfo, setMobileComplexInfo] = useState(false)

  const filterFromLS = JSON.parse(localStorage.getItem(`${city}Filter`))?.[blockId]
  const {
    handleFilter,
    submit,
    isLoading,
    isSending,
    filter = {},
    exclude = {},
    count,
    send,
    data: {
      block = {},
      filter: filterData = {},
      prices,
      blockInfo,
      blockVideos,
      contacts,
      schedule,
      residencePermit,
      residencePermitInfo,
      cases,
      filesInfo,
      priceInfo,
      typicalLayouts,
      managerInfo,
      agent,
      savedFilter,
      savedFilterSettings
    },
    setFilterAndExclude,
    isCountLoading,
    clearByIdent,
    filterBadges,
    removeBadge,
    resetFilter,
    favoritesProps,

  } = useFilter({
    apiConfig: {
      component: "panpartner:primary.ajax",
      getCountFunction: 'getCount',
      submitFunction: "getBlock",
      loadSubItems: "getApartments",
      getMapItems: 'getBlocks',
    },
    defaultFilter: {
      status: [1],
      blockId,
    },
    favoritesParams: {
      city,
      id: blockId,
      type: 'blockEntity',
    },
    favoritePath: 'block.favorite',
    defaultParams: {
      isGetApartments: true,
      isAssignment: true,
      onlyTypes: true,
      city,
      id: blockId,
      savedFilterXmlId,
      "filter[currency]": currency
    },
    shadowFilters: ["blockId", "status", "currency"],
    extractCount: ({ countBlocks }) => countBlocks,
    extractLoadMoreCount: ({ countBlocks }) => countBlocks,
    extractMapItems: ({ blocks }) => blocks,
    fields: {
      status: [],
      countBedrooms: [],
      type: [],
      prices: {},
      residencePermit: ""
    },
    badgeFormattersParams: {
      currency: priceFormat === "RUB" ? "₽" : priceFormat.toLowerCase()
    },
    isGetFilter: true,
    refreshWhenFilterChange: true,
    beforeRefresh: formData => formData.append("filter[currency]", priceFormat),
    filterFromLS,
  })
  const isPublic = !!savedFilterXmlId

  const [fetching, setFetching] = useState(false);
  const [successCallback, setSuccessCallback] = useState(false);
  const onSubmit = () => {
    setFetching(true);
    const form = toFormData({
      name: `${userInfo.name} ${userInfo.lastName}`,
      urgently: true,
      managerId: block?.manager?.id,
      type: "global",
      objectId: blockId,
      city
    })
    callback(form).then(() => {
      setFetching(false);
      setSuccessCallback(true);
    });
  };


  useEffect(() => () => localStorage.removeItem(`${city}Filter`), []);
  const [showGp, setShowGp] = useState(false);

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
         contacts={contacts}
         schedule={schedule}
         managerId={block?.manager?.id}
         params={{
          type: "global",
          objectId: blockId,
          city
        }}
         />
      </PanModal>
    ),
    [block],
  );
  const filesRef = useRef(null);

  const filesBlockRef = useRef(null);
  const scrollToFiles = () =>
    scrollToRef(filesRef, -filesBlockRef.current?.offsetParent?.offsetTop);

  const [caseId, setCaseId] = useState(false)
  const [openCaseModal, closeCaseModal] = useModal(
    () => (
      <PanModal title={'Кейс'} closeModal={closeCaseModal} hashParams={`caseId=${caseId}`} isBig>
        <CaseModal caseId={caseId} closeModal={closeCaseModal} />
      </PanModal>
    ),
    [caseId]);

  const openCaseModalCallback = (id) => {
    setCaseId(id)
    openCaseModal()
  }

  const decorationSliders = useMemo(() => {
    return [
      { title: 'Меблировка', items: block?.finishingReady },
      { title: 'Чистовая', items: block?.finishingPreReady },
      { title: 'Черновая', items: block?.finishing },
    ].filter((item) => !!item?.items?.length);
  }, [block]);

  useEffect(() => {
    if (mobileComplexInfo) {
      window.scrollTo({ top: 0 })
      document.body.classList.add('no-scroll')
    }
    if (!mobileComplexInfo) {
      document.body.classList.remove('no-scroll')
    }
  }, [mobileComplexInfo]);


  const [planId, setPlanId] = useState(false)
  const [planTitle, setPlanTitle] = useState("")
  const [openAptModal, closeAptModal] = useModal(
    () => (
      <PanModal hideBackButton={isPublic} title={planTitle} closeModal={closeAptModal} isBig customClass={"panmodal_mobile content_redesign-mobile"}>
        <FastViewDubaiModal
          isPublicModal={isPublic}
          city={city}
          publicManager={{ ...managerInfo }}
          planTitle={setPlanTitle}
          priceFormat={setPriceFormat}
          activeCurrency={priceFormat}
          planId={planId}
          xmlId={savedFilterXmlId}
        />
      </PanModal>
    ),
    [planId, priceFormat, planTitle]);
  const toggleFavorite = () => favoritesProps?.toggleFavorite?.({ id: block?.id })

  const userInfo = useSelector(state => state.user?.info);
  const [callbackStatus, setCallbackStatus] = useState(false)
  const callback = () => request("panpartner:forms.ajax", "callback", {
    urgently: true,
    managerId: managerInfo?.id,
    name: `${userInfo?.name} ${userInfo?.lastName}`,
    type: 'global',
    objectId: blockId,
    city,
    savedFilterXmlId: savedFilterXmlId
  }).then(
    (success) => {
      setCallbackStatus({ type: "success" })},
    err => setCallbackStatus({ type: "error", message: err[0]?.message })
  )

  const isLaptop = useMedia('isLaptop');

  const location = useLocation();

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="global"
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap >
    ),
    [filter, exclude, filterData],
  );

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <MobileMapFilter
            redesign={true}
            component={props => <div className="dubai-complex-filters-mobile">

              <DubaiComplexFilterForm
                filterData={filterData}
                filter={filter}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                isLoading={isLoading}
                currency={priceFormat}
                setCurrency={setPriceFormat}
                currencyList={priceInfo?.price?.prices}
              />


            </div>}
            resetFilter={resetFilter}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={() => {
              closeFilter();
              submit();
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            isMobile={isMobile}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );



  return (
    <>
        <div className="sf wrapper">
          {!!agent && !isTablet && <Header {...agent} savedFilterSettings={savedFilterSettings} />}
          {block && !isTablet && (
            <DetailHeader
              title={block?.title}
              description={block?.address}
              commission={block.commission}
              isCustomerFixation={block?.builder?.isCustomerFixation}
              coordinates={block?.coordinates}
              {...favoritesProps}
              toggleFavorite={toggleFavorite}
              sf={isPublic}
            />
          )}

          {isTablet &&
            <div className="complex-mobile-header">
              <Link to={isPublic ? `/public/global/${savedFilterXmlId}` : `/realty/dxb/`}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5 17L9.5 12L14.5 7" stroke="#2C2E3E" strokeLinecap="round"/>
                </svg>
              </Link>
              {block?.title}
            </div>
          }

          <div className="sf__detail-row">
            <div className="sf__detail-col">
              {!!block.photos?.complex?.length && (
                <Slider
                  items={block.photos.complex}
                  openGeneralPlan={() => setShowGp(true)}
                  showButton={!!block.generalPlan?.length}
                />
              )}
              {isMobile && priceFormat && priceInfo &&
                <>
                  <p style={{marginTop: 23}}>{block?.address}</p>
                  <header className="sf__info-header sf__info-header_mobile">
                    <div className="sf__header-col sf__header-col_mobile">
                      <p className="h3-l sf__price" style={{ whiteSpace: 'nowrap' }}>{priceInfo.price?.prices?.[priceFormat]?.value}</p>
                      <p className="sf__descr text">
                        Цена
                      </p>
                    </div>
                    <div className="sf__header-col sf__header-col_mobile">
                      <p className="h3-l sf__price">{priceInfo?.count?.value}</p>
                      <p className="sf__descr text">{priceInfo?.count?.title}</p>
                    </div>
                  </header>
                </>

              }

              {!!cases?.length &&
                <div style={{ marginTop: 32 }}>
                  <NewAlert
                    text="Скачать готовые stories и посты по этому комплексу для ваших соц.сетей вы можете в нашем "
                    link="мобильном приложении"
                    bgColor="#f6f7f8"
                    iconColor="#2CBA2E"
                    linkColor="#2CBA2E"
                    openAppModal
                    oneLine
                  />
                </div>
              }

            </div>
            <div className="sf__detail-col">

              {!isTablet && <InfoCard items={blockInfo} priceFormat={setPriceFormat} activeCurrency={priceFormat} choiceCurrency={priceInfo} />}

              {
                residencePermit &&
                <ResidenceHint
                  title={residencePermitInfo?.[0]?.title}
                  text={residencePermitInfo?.[0]?.value}
                  withBottomBorder={isPublic && !isLaptop ? true : false}
                />
              }

              {isMobile &&
                <div className="about-complex-mobile" onClick={() => {setMobileComplexInfo(true)}} style={isPublic ? {marginBottom: 15} : {}}>
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.7941 17.4868L4.7941 5.56579C4.7941 4.97368 4.32162 4.5 3.73103 4.5C3.14044 4.5 2.66797 4.97368 2.66797 5.56579L2.66797 17.4868C2.66797 18.5921 3.57354 19.5 4.67598 19.5C5.77841 19.5 6.68399 18.5921 6.68399 17.4868L6.68399 6.67105C6.68399 6.67105 6.68399 6.23684 7.19583 6.23684L20.8582 6.23684C20.8582 6.23684 21.3306 6.27632 21.3306 6.78947L21.3306 17.3684C21.3306 17.3684 21.4881 19.5 18.9683 19.5H4.67598" stroke="#2C2E3E" strokeMiterlimit="10"/>
                      <path d="M3.73047 4.5L18.5346 4.5C18.5346 4.5 19.9127 4.5 19.9127 5.76316V6.27632" stroke="#2C2E3E" strokeMiterlimit="10"/>
                      <path d="M13.5332 13.7031H9.75341C9.51718 13.7031 9.32031 13.5057 9.32031 13.2689V9.47938C9.32031 9.24253 9.51718 9.04517 9.75341 9.04517H13.5332C13.7694 9.04517 13.9663 9.24253 13.9663 9.47938V13.2689C13.9663 13.5057 13.7694 13.7031 13.5332 13.7031Z" stroke="#2C2E3E" strokeMiterlimit="10"/>
                      <line x1="9.32031" y1="16.1487" x2="18.8247" y2="16.1487" stroke="#2C2E3E"/>
                    </svg>
                    Подробнее о комплексе
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 7L14.5 12L9.5 17" stroke="#2C2E3E" strokeLinecap="round"/>
                  </svg>
                </div>
              }
              {
                mobileComplexInfo &&
                  <div className="complex-mobile-popup">
                    <div className="complex-mobile-header">
                      <Link onClick={() => {setMobileComplexInfo(false)}}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.5 17L9.5 12L14.5 7" stroke="#2C2E3E" strokeLinecap="round"/>
                        </svg>
                      </Link>
                      {block?.title}
                    </div>
                    <InfoCard items={blockInfo} priceFormat={setPriceFormat} activeCurrency={priceFormat} choiceCurrency={priceInfo} />
                  </div>
              }


              {
                isMobile && contacts && !isPublic &&
                  <div className="complex-mobile-contacts">
                    {contacts.map((contact) => {
                    return (
                      contact.managers.length > 0 && (
                        <div className="sf__manager-block">
                          {contact.managers.map((manager) => (
                            <ManagerModal {...manager} />
                          ))}
                        </div>
                      )
                    );
                  })}

                    {!successCallback ? (
                      <div style={{marginTop: 15}}>
                        <SubmitButton
                          className="btn h6 btn_primary btn_full"
                          isLoading={fetching}
                          onClick={onSubmit}
                        >
                          Перезвонить мне
                        </SubmitButton>
                      </div>
                    ) : (
                      <div style={{marginTop: 15}}>
                        <NewAlert
                          title="Заявка отправлена"
                          text={`Ожидайте звонок. Мы свяжемся с Вами в рабочее время.`}
                          bgColor="#DFF5E0"
                          iconColor="#2CBA2E"
                        />
                      </div>
                    )}

                </div>
              }

              {
                isPublic ?
                  <>
                  <ContactCard
                    forAptModal
                    logo
                    {...managerInfo}
                  />
                    {callbackStatus?.type === "success" && <NewAlert
                      title="Заявка отправлена"
                      text={`Ожидайте звонок. ${managerInfo?.name ? managerInfo?.name + " скоро свяжется с Вами." : "Мы свяжемся с Вами в рабочее время."}`}
                      bgColor="#DFF5E0"
                      iconColor="#2CBA2E"
                    />}
                    {callbackStatus?.type === "error" && <NewAlert
                      title="Ошибка"
                      text={callbackStatus?.message}
                      bgColor="#FFEAE9"
                      iconColor="#FF2D23"
                    />}
                    <section className="sf__section">
                      {!(callbackStatus?.type === "success") && <SubmitButton
                        type="button"
                        className="btn h6 btn_primary btn_full"
                        onClick={callback}
                        isLoading={isSending}
                      >
                        Перезвоните мне
                      </SubmitButton>}
                    </section>
                  </>
                  :
                  <section className="sf__section">
                    {
                      !isMobile &&
                      <Button buttonType="primary" isFull onClick={openContactsModal}>
                        Контакты
                      </Button>
                    }

                    {!isLaptop &&
                    <Button buttonType="line" isFull onClick={scrollToFiles}>
                      Файлы
                    </Button>
                    }

                  </section>
              }

            </div>
          </div>

          <div className="sf__detail-row sf__detail-row_full">
            <h3 className="h3-l sf__h3" style={!isPublic ? { display: 'flex', marginBottom: 10 } : { display: 'flex', marginBottom: 20 }}>
              <span style={{ marginRight: 10 }}>Типовые планировки и цены</span>
              {block?.remainsInfo?.files && !isPublic && !isMobile && <div style={{ width: 'max-content', display: 'inline-block', position: 'relative', zIndex: 11 }}><Remains remains={block.remainsInfo} /></div>}
            </h3>

            {!isMobile &&
              <>
                <DubaiComplexFilterForm
                  filterData={filterData}
                  filter={filter}
                  setFilterAndExclude={setFilterAndExclude}
                  handleFilter={handleFilter}
                  isLoading={isLoading}
                  currency={priceFormat}
                  setCurrency={setPriceFormat}
                  currencyList={priceInfo?.price?.prices}
                />
                <Tags
                badges={filterBadges}
                removeBadge={removeBadge}
                resetFilter={resetFilter}
                />
              </>
            }


            {isMobile && <div className="complex-filter-mobbtn" style={isPublic && isMobile ? {marginTop: -35} : {}}>
              <FilterToggleBtn
                icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="8.63281" y1="4.5" x2="8.63281" y2="6.7" stroke="#2C2E3E" strokeLinecap="round"/>
                        <line x1="16.0996" y1="17.3" x2="16.0996" y2="19.5" stroke="#2C2E3E" strokeLinecap="round"/>
                        <line x1="8.63281" y1="14.1" x2="8.63281" y2="19.5" stroke="#2C2E3E" strokeLinecap="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5098 7.01172C9.89563 7.01172 11.0196 8.13569 11.0196 9.52152C11.0196 10.9074 9.89563 12.0313 8.5098 12.0313C7.12397 12.0313 6 10.9074 6 9.52152C6 8.13569 7.12397 7.01172 8.5098 7.01172Z" fill="white" stroke="#2C2E3E" strokeLinejoin="round"/>
                        <line x1="16.0996" y1="4.5" x2="16.0996" y2="9.9" stroke="#2C2E3E" strokeLinecap="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9766 12.0314C17.3624 12.0314 18.4864 13.1553 18.4864 14.5412C18.4864 15.927 17.3624 17.051 15.9766 17.051C14.5908 17.051 13.4668 15.927 13.4668 14.5412C13.4668 13.1553 14.5908 12.0314 15.9766 12.0314Z" fill="white" stroke="#2C2E3E" strokeLinejoin="round"/>
                    </svg>}
                onClick={openFilter}/>
            </div>}


            {
              !!typicalLayouts?.length ?
                <div className="sf__cards-list sf__cards-list_flats-dubai" >
                  {typicalLayouts.map((item) => {
                    return (
                      <Card
                        key={item.id}
                        topLabel={item.type.title}
                        isBordered
                        isFlat
                        title={item.price[priceFormat].value}
                        src={getUrl(item?.plan?.src)}
                        status={'status'}
                        subtiltle={item.apartmentInfo}
                        bottomStatus={item.countInfo}
                        withoutBtnsList
                        onClick={() => {
                          // !isPublic &&
                          openAptModal()
                          setPlanId(item.id)
                        }}
                        // link={isPublic ? location.pathname + '/' + item.id : false}
                        updateFlat
                      />
                    )
                  })}
                </div>
                :
                <NotFound forTable />
            }


          </div>

          <div className="sf__detail-row">
            <div className="sf__detail-col">
              <About
                title={`О комплексе «${block ? block.name : ''}»`}
                text={block?.about?.previewText}
                advantages={block?.about?.advantages}
                audioReview={!isPublic ? block?.audioReview : false}
              />

              <Furnish items={decorationSliders} complexTitle />

              <section className="sf__section">
                {!!block?.typical3DTour && (
                  <Iframe title="Шоу-рум" src={block.typical3DTour} />
                )}
              </section>

              {!!block?.photos?.aeroPanorama && <section className="sf__section">
                <Iframe title="Аэропанорама" src={`${block?.photos?.aeroPanorama}`} />
              </section>}

              {!!cases?.length && <section className="sf__section">
                <div className="case-info">
                  <p className="h3-l sf__h3 case-info__block-title_link" style={cases && cases.length > 0 ? {} : { marginBottom: 0 }}>Кейсы
                    <Link className="case-info__cases-link" to='/cases'>Все кейсы</Link>
                  </p>
                  <div className="other-cases">
                    {
                      cases &&
                      cases.map((item) => {
                        return (
                          <div className="other-cases__item" onClick={() => openCaseModalCallback(item?.id)}
                            key={item?.id}>
                            <img className="other-cases__img" src={getUrl(item?.plan?.src)} alt="" />
                            <p className="other-cases__tit h4">{item?.title}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </section>
              }

              <VideoList items={blockVideos} />

              {filesInfo?.length > 0 && !isPublic && (
                <div ref={filesBlockRef}>
                  <Files innerRef={filesRef} items={filesInfo} />
                </div>
              )}

            </div>
            <div className="sf__detail-col sf__sticky">
              <Help sf={isPublic} managerInfo={managerInfo} dubai={true} openContactsModal={openContactsModal}/>
            </div>
          </div>
          <Preloader loading={isLoading} />
          <PhotoPopup
            open={showGp}
            items={block.generalPlan}
            index={0}
            onClose={() => setShowGp(false)}
          />
        </div>
    </>
  )
}

export default ComplexDubai
