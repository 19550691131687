import React from 'react';
import ContactsPage from '../components/Constructor/InnerPage/ContactsPage/ContactsPage';
import '../components/Constructor/index.scss';



const ConstructorContacts = ({setFullScreenLoading}) => {
  return  <ContactsPage setFullScreenLoading={setFullScreenLoading} />
};

export default ConstructorContacts;
