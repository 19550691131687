import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from '../Preloader';
import './index.scss';
import useFilter from '../../hooks/useFilter';
import { useEffect } from 'react';
import SuburbanContent from './SuburbanContent';
import { getSuburbanFilterConfig } from './Utils';

const SuburbanContentContainer = memo(({ method, isActive, tab = [] }) => {
  const { villageId, city } = useParams();

  const contentProps = useFilter(getSuburbanFilterConfig({
    method, city, villageId, tab
  }));
  const filterBadges = contentProps.createBadges(contentProps.filter, ['villageId', 'status'], {}, { [tab.searchFilter]: tab.searchPlaceholder })
  const { isLoading, data: { items }, setLoadCondition } = contentProps;

  useEffect(() => {
    if (items || !isActive) return;
    setLoadCondition(true)
  }, [isActive, items])

  if (!isActive) return null
  if (isLoading) return <Preloader loading />;
  return <SuburbanContent
    villageId={villageId}
    city={city}
    {...contentProps}
    filterBadges={filterBadges}
    tab={tab}
  />
});

export default SuburbanContentContainer;
