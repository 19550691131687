import { memo } from "react";
import { getUrl } from "../../../../api";
import Card from "../../../core/Card";

const SuburbanLandCard = memo((props) => {
    const { numberParcel, link, endingDate, plan, priceDiscount, squareParcel } = props;

    return <Card
        {...props}
        isFlat
        isBordered
        topLabel={numberParcel}
        link={link}
        title={priceDiscount}
        subtiltle={squareParcel}
        src={getUrl(plan?.src)}
        descValue={endingDate}
    />
});

export default SuburbanLandCard;
