import React from "react";
// import banksMocs from './mocs.js';
import Banks from "./Banks";
const ConstructorBanks = (props) => {
  return (
    <section className="banks constructor__section">
      <div className="constructor__container">
        <div className="constructor__box">
          <div className="constructor__wrapper banks__wrapper">
            <div className="constructor__section-title">
              <h2 className="h2 constructor__title advantages__title">
                Аккредитованные банки
              </h2>
            </div>
            <Banks items={props.banks} />
          </div>
        </div>
      </div>
    </section>
  )
};

export default ConstructorBanks;
