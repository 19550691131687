import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFormSettings, sendForm } from '../../actions/forms';
import { infoToast, standartErrorToast } from '../../components/toasts';
import Distributor from './Distributor';
import './index.scss';

const Poll = (props) => {
  const { city, forExport = false, setActiveTest } = props
  const [formSettings, setFormSettings] = useState([])
  const [isExistResult, setIsExistResult] = useState(false)
  if(setActiveTest){
    setActiveTest.current = setFormSettings
  }

  useEffect(() => {
    if(forExport) return
    getFormSettings(29, city, 1).then(resp =>{
      setIsExistResult(resp.isExistResult)
      setFormSettings(resp.questions)
    },

    standartErrorToast
    )
  }, [])

  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [agreement, setAgreement] = useState(true)
  const handleChange = (value, ident, arrayValue) => {
    const tmp = {
      ...form
    }
    if (arrayValue && value) {
      if (!tmp[ident]) {
        tmp[ident] = []
      }
      tmp[ident].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[ident] = tmp[ident].filter(val => val !== arrayValue)
    } else {
      tmp[ident] = value
    }
    setForm(prev => ({ ...prev, ...tmp }))
  }
  const submit = () => {
    if(!agreement){
      setErrors(prev=>({...prev, agreement: true}))
      return
    }
    let formData = new FormData();
    let keys = Object.keys(form);

    keys.forEach((key) => {
      if (Array.isArray(form[key])) {
        form[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, form[key]);
      }
    });
    sendForm(29, formData, city).then(
      (data) => {
        setIsExistResult(true)
        infoToast(<div>Спасибо! Форма успешно отправлена</div>)
      },
        standartErrorToast
    );
  }
  return (
    !forExport ?
    <section className="section section_view_white">
      <div className="wrapper">
        <h1 className="h1 h1_default">
          <span className="poll-title">
            Опрос
            <span className="poll-timer big-text">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.79" cy="10.9629" r="9.5" stroke="#8F95AC" />
                <line x1="10.7207" y1="5.51563" x2="10.7207" y2="11.6132" stroke="#8F95AC" />
                <line x1="10.5743" y1="11.2597" x2="14.7206" y2="15.4061" stroke="#8F95AC" />
              </svg>
            4 мин.
            </span>
          </span>
        </h1>

        <section className="poll">

          <form action="#" className="poll__form">
            {formSettings && <Distributor questions={formSettings} form={form} handleChange={handleChange} />}
            <div className="poll__item" style={{justifyContent: "center"}}>
              <div class="checkbox checkbox_view_invert">
                <div
                  class={
                    "checkbox__box" +
                    (errors.agreement ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    class="checkbox__control checkbox_view_flex-start"
                    type="checkbox"
                    id="modal-partnership-agreement"
                    name="agreement"
                    checked={agreement}
                    onChange={e=>setAgreement(e.target.checked)}
                  />
                  <label
                    class="checkbox__input"
                    htmlFor="modal-partnership-agreement"
                  ></label>
                  <div class="checkbox__marker"></div>
                </div>
                <label
                  class="checkbox__label"
                  htmlFor="modal-partnership-agreement"
                >
                  Отправляя эту форму Вы соглашаетесь с<br />
                  <Link className="link" to={`/policy`} target="_blank">политикой конфиденциальности</Link>
                </label>
              </div>
            </div>
            <button
              type="button"
              className="poll__submit poll__submit_big-mb button button_view_default"
              onClick={submit}
              disabled={!agreement || isExistResult}
            >
             {isExistResult? 'Вы уже прошли опрос' : 'Отправить'}
            </button>

          </form>
        </section>
      </div>
    </section> :
    <section className="poll">

      <form action="#" className="poll__form">
        {formSettings && <Distributor questions={formSettings} form={form} handleChange={handleChange} />}
        <div className="poll__item" style={{justifyContent: "center"}}>
          <div class="checkbox checkbox_view_invert">
            <div
              class={
                "checkbox__box" +
                (errors.agreement ? " checkbox__box-error" : "")
              }
            >
              <input
                class="checkbox__control checkbox_view_flex-start"
                type="checkbox"
                id="modal-partnership-agreement"
                name="agreement"
                checked={agreement}
                onChange={e=>setAgreement(e.target.checked)}
              />
              <label
                class="checkbox__input"
                htmlFor="modal-partnership-agreement"
              ></label>
              <div class="checkbox__marker"></div>
            </div>
            <label
              class="checkbox__label"
              htmlFor="modal-partnership-agreement"
            >
              Отправляя эту форму Вы соглашаетесь с<br />
              <Link className="link" to={`/policy`} target="_blank">политикой конфиденциальности</Link>
            </label>
          </div>
        </div>
        <button
          type="button"
          className="poll__submit poll__submit_big-mb button button_view_default"
          onClick={submit}
          disabled={!agreement || isExistResult}
        >
         {isExistResult? 'Вы уже прошли опрос' : 'Отправить'}
        </button>

      </form>
    </section>
  );
};


const mapStateToProps = (state) => ({
  city: state?.user?.info?.city,
  user: state?.user?.info
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Poll);
