import { memo } from "react";
import { getUrl } from "../../../../api";
import Card from "../../../core/Card";
import { getRoundedPrice, evaulateEndings } from "../../../../utils";
import { useState } from "react";



const DubaiCardContainer = memo(props => {
    const { currency, link, photo, address, builder, apartmentsInfo, title, endings, index, toggleFavorite, id, get, onClick, xmlId } = props
    const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
    const handleFavorite = e => {
        e.preventDefault()
        if(isFavoriteLoading) return
        setIsFavoriteLoading(true)
        toggleFavorite({id}, `blocks[${index}].favorite`).finally(setIsFavoriteLoading)
    };
    return <Card
        {...props}
        src={getUrl(photo?.src)}
        title={title}
        descTitle={builder?.title}
        deadline={endings}
        subtiltle={address}
        customTable
        tableItems={apartmentsInfo?.apartmentsByRoomType.map?.(item => ({
            id: item.roomTypeId,
            title: item.roomTypeName,
            middleTitle: item.minSquare ? `от ${item.minSquare} м²` : "",
            subtitle: `от ${getRoundedPrice(item.minPrice[currency])} млн. ${currency}`
        }))}
        // link={`${link}?currency=${currency}`}
        link={`${link}`}
        handleFavorite={handleFavorite}
        inFavorite={get(`blocks[${index}].favorite.inFavorite`)}
        onClick={()=>onClick(xmlId)} // у Дубая в карточке считывается xmlId в качестве id поэтому так
    />
});

export default DubaiCardContainer;
