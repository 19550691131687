import request from "../api";
const component = "panpartner:favorite.ajax"

export const addFavorite = data => ({
  type: "ADD_TO_FAVORITES",
  data,
});

const requestFavorites = () => ({ type: "FAVORITES_REQUEST" });

export const successFavorites = data => ({
  type: "FAVORITES_SUCCESS",
  data,
});

export const failureFavorites = () => ({ type: "FAVORITES_FAILURE" });

export const addToFavorites = (city, id, type, whatPage) => dispatch => request(
  component,
  "addToFavorite",
  { city, id, type, }
).then(data => {
  dispatch(
    addFavorite({ id: parseInt(id), city, type, data, whatPage })
  );
  dispatch(getFavoriteCount());
  return Promise.resolve(data);
});

export const removeFromFavorite = data => ({
  type: "REMOVE_FROM_FAVORITES",
  data,
});

const requestRemoveFromFavorites = () => ({ type: "REMOVE_FROM_FAVORITES_REQUEST" })

export const successRemoveFromFavorites = data => ({
  type: "REMOVE_FROM_FAVORITES_SUCCESS",
  data,
});

export const failureRemoveFromFavorites = () => ({ type: "REMOVE_FROM_FAVORITES_FAILURE" });

export const removeFromFavorites = (city, id, type, whatPage) => dispatch => {
  dispatch(requestRemoveFromFavorites);
  return request(
    component,
    "removeFromFavorite",
    { city, id, type, }
  ).then(
    data => {
      dispatch(
        successRemoveFromFavorites({
          id: parseInt(id),
          city,
          type,
          whatPage,
        })
      );
      dispatch(getFavoriteCount());
      return Promise.resolve(data);
    },
  );
};
export const fetchFavorites = () => dispatch => {
  dispatch(requestFavorites());
  return request(component, "getFavorites")
    .then(
      data => {
        dispatch(successFavorites(data));
        return Promise.resolve(data);
      },
      error => {
        dispatch(failureFavorites());
        return Promise.reject(error);
      }
    );
};

const requestAddClientToFavorite = () => ({ type: "ADD_CLIENT_TO_FAVORITES_REQUEST" })

export const successAddClientToFavorite = data => ({
  type: "ADD_CLIENT_TO_FAVORITES_SUCCESS",
  data,
});

export const addClientToFavorite = (
  id,
  clientId,
  client,
  favoriteId,
  whatPage,
  type
) => dispatch => {
  dispatch(requestAddClientToFavorite);
  return request(component, "addClientToFavorite", {
    id: favoriteId,
    clientId,
  })
    .then(
      data => {
        dispatch(
          successAddClientToFavorite({
            id: parseInt(id),
            client,
            clientId,
            favoriteId,
            whatPage,
            type,
          })
        );
        return Promise.resolve(data);
      },
    );
};
export const successRemoveClientFromFavorite = data => ({
  type: "REMOVE_CLIENT_FROM_FAVORITES_SUCCESS",
  data,
});

export const removeClientFromFavorite = (
  id,
  clientId,
  client,
  favoriteId,
  whatPage,
  type
) => dispatch => request(
  component,
  "removeClientFromFavorite",
  { id: favoriteId, clientId, }
).then(data => {
  dispatch(
    successRemoveClientFromFavorite({
      id: parseInt(id),
      client,
      clientId,
      whatPage,
      type,
    })
  );
  return Promise.resolve(data);
});


export const setFavoritesFilter = filter => ({
  type: "SET_FAVORITES_FILTER",
  filter,
});

export const successGetFavoriteCount = count => ({
  type: "SUCCESS_GET_FAVORITE_COUNT",
  count,
});

export const getFavoriteCount = () => dispatch => request(
  component,
  "getCount"
).then(data => dispatch(successGetFavoriteCount(data.count)));

export const clearFavoritesFilter = defaultCity => ({
  type: "CLEAR_FAVORITES_FILTER",
  defaultCity,
});