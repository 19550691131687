import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  clearOffers, fetchOffers, saveOffers
} from "../../actions/profile.offers";
import { editSubscription, getRubrics, successStart } from "../../actions/start";
import { acceptOffers } from "../../actions/user";
import Checkbox from "../../components/core/Checkbox";
import { OffersList } from "../../components/Profile/Offers";
import { errorToast } from '../../components/toasts';
import { scrollToRef } from "../../utils";
import './index.scss';



const Start = (props) => {
  const {fetch, reset, acceptOffers, history, rubrics = [], successStart, offers = [] } = props
  const [rubricsIds, setRubrics] = useState([])
  const [accepted, setAccepted] = useState(true)
  const setSubscribtion = (isChecked, id) => {
    if (isChecked) {
      setRubrics(prev => [...prev, id])
    } else {
      setRubrics(prev => [...prev].filter(rubricId => rubricId !== id))
    }
  }
  useEffect(() => {
    fetch();
    getRubrics().then(data=> {
      successStart({payload: data.rubrics})
      return data
    }).then(data=>setRubrics(data?.rubrics?.map(r=>r.id)));
    window.addEventListener('scroll', handleScroll);
    return () => {
      reset();
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  // useEffect(() => {
  //   if(!Array.isArray(rubrics)){
  //     throw new Error(`rubrics не является массивом. Тип - ${typeof rubrics}, значение ${rubrics} `)
  //   }
  // }, rubrics);
  const [zIndex, setZindex] = useState(false);
  const zIndexModOn = () => setZindex(true);
  const zIndexModOff = () => setZindex(false);

  const [zIndex2, setZindex2] = useState(false);
  const zIndexModOn2 = () => setZindex2(true);
  const zIndexModOff2 = () => setZindex2(false);

  const offersSectionRef = useRef(null);

  const offersScrollPadding = -50;

  const onScrollBtnClick = (evt) => {
    evt.preventDefault();
    scrollToRef(offersSectionRef, offersScrollPadding);
  };

  const [isScrollPanelVisible, setVisible] = useState(true);
  const offsetTop = 300;

  const handleScroll = () => {
    let currentScrollPosition = offersSectionRef.current?.getBoundingClientRect().top;
    setVisible(currentScrollPosition > offsetTop);
  };

  const handleSubmit = () => {
    if (!accepted) {
      errorToast(<div>Необходимо принять условия оферты</div>)
      return
    }
    let form = new FormData();
    rubricsIds.map(id => {
      form.append("rubrics[]", id)
    })
    if (rubricsIds.length === 0) {
      form.append("rubrics[]", "")
    }
    editSubscription(form)
    saveOffers().then(
      () => {
        acceptOffers();
        history.push('/');
      },
      () => { }
    );
  }


  return (
    <section className="start section section_view_white">

      <div className="wrapper">
        <div className="wrapper__center wrapper__center_start">

          <section className="promo">
            <h1 className="promo__title h1">Добро пожаловать в ПАН!</h1>
            <div className="promo__container">

              <div className="promo__box">

                <h3 className="promo__box-title h3">
                  ПАН Партнер — ваш первый надежный партнер в недвижимости
                </h3>

                <span className="pill">Санкт-Петербург и Москва</span>
              </div>

              <img
                className="promo__image"
                src={require('../../assets/images/start/promo.svg').default}
                alt=""
              />

            </div>
          </section>

          <section className="benefits benefits_01">
            <header className="benefits__header">
              <h3 className="h3">Ключевые ценности и преимущества ПАН Партнер</h3>
              <span className="benefits__counter">01.</span>
              <p className="benefits__info">
                Прежде чем начать работу,
                внимательно изучите условия <button type="button" className="link" onClick={onScrollBtnClick}>договоров-оферт.</button>
              </p>
            </header>

            <div className="benefits__list">
              <div className="benefits__item benefits__item_01">
                <img
                  src={require('../../assets/images/start/benefits/01.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Отзывчивый<br />сервис
                </h4>
                <p>
                  Все дело в людях. Наши партнеры знают,
                  что могут положиться на помощь в любом удобном формате.
                  За нашими сервисами и услугами стоят люди,
                  которые любят свое дело и понимают ценность
                  человеческих отношений.
                </p>
              </div>

              <div className="benefits__item benefits__item_02">
                <img
                  src={require('../../assets/images/start/benefits/02.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Технологии<br />с душой
                </h4>
                <p>
                  Делайте подборки объектов,
                  отправляйте их клиентам прямо из личного кабинета.
                  Пользуйтесь голосовыми помощниками на портале и в мессенджерах.
                  Оставляйте обратную связь об информационных решениях ПАН.
                </p>
              </div>
              <div className="benefits__item benefits__item_03">
                <img
                  src={require('../../assets/images/start/benefits/03.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">Экономия<br />времени</h4>
                <p>
                  Мы уделили много времени настройке отношений между клиентами,
                  агентами, застройщкиами и банками, построили четкую систему,
                  которая понятна и удобна всем участникам рынка.
                  Просто оставайтесь частью этой системы.
                </p>
              </div>
              <div className="benefits__item benefits__item_04">
                <img
                  src={require('../../assets/images/start/benefits/04.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">Честные<br />правила игры</h4>
                <p>
                  Мы за честную конкуренцию и честные цены.
                  Мы против не регламентированных застройщиком
                  скидок для клиентов, демпинга и откатов.
                  Мы не работаем с преходящими «серыми» игроками рынка,
                  только по договору и только с юр. лицами.
                </p>
              </div>
            </div>

          </section>

          <section className="benefits benefits_02">
            <header className="benefits__header">
              <h3 className="h3">Услуги и сервисы</h3>
              <span className="benefits__counter">02.</span>
              <p className="benefits__info">
                Прежде чем начать работу,
                внимательно изучите условия <button type="button" className="link" onClick={onScrollBtnClick}>договоров-оферт.</button>
              </p>
            </header>

            <div className="benefits__list">

              <div className="benefits__item benefits__item_05">
                <img
                  src={require('../../assets/images/start/benefits/05.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Новостройки<br />
                  и переуступки<br />
                  СПб и Москвы
                </h4>
                <p>
                  Более 65 000 квартир под покупку и инвестирование для ваших клиентов.
                  Удобный поиск по базе и 30 компетентных персональных менеджеров.
                </p>
              </div>

              <div className="benefits__item benefits__item_06">
                <img
                  src={require('../../assets/images/start/benefits/06.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Вторичка<br />и Трейд-ИН
                </h4>
                <p>
                  Вместе с вами мы помогаем клиентам продать старую квартиру
                  или обменять на новостройку.
                  Мы делаем это быстро, экспертно, с гарантированными выплатами.
                </p>
              </div>

              <div className="benefits__item benefits__item_07">
                <img
                  src={require('../../assets/images/start/benefits/07.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Ипотека и<br />
                  юридическая помощь
                </h4>
                <p>
                  Расчет ипотеки онлайн, бесплатное одобрение от 1 дня,
                  полное сопровождение сделки, даже без вашего присутствия.
                  Юридическая помощь на всех этапах сделки.
                </p>
              </div>

              <div className="benefits__item benefits__item_08">
                <img
                  src={require('../../assets/images/start/benefits/08.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Автоэкскурсии за<br />
                  КАД с экспертом
                </h4>
                <p>
                  Отправляйтесь на экскурсию к новостройкам на Авто ПАН
                  с независимым экспертом и получите исчерпывающую информацию
                  о локациях и объектах.
                </p>
              </div>

              <div className="benefits__item benefits__item_09">
                <img
                  src={require('../../assets/images/start/benefits/09.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">
                  Честная<br />лидогенерация
                </h4>
                <p>
                  Получайте до 10 проверенных живых обращений в день по заданным параметрам.
                  Стоимость от 750 рублей за заявку.
                  Прямые звонки, заявки после модерации колл-центром.
                </p>
              </div>

              <div className="benefits__item benefits__item_10">
                <img
                  src={require('../../assets/images/start/benefits/10.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">Комфортные<br />переговоры</h4>
                <p>
                  Приводите клиентов в наш офис или назначьте встречу
                  в одной из 10 бесплатных переговорных в разных концах Петербурга.
                  Экономьте время и получайте удовольствие от продаж с ПАН Комфорт.
                </p>
              </div>

              <div className="benefits__item benefits__item_11">
                <img
                  src={require('../../assets/images/start/benefits/11.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">Школа<br />риелторов</h4>
                <p>
                  Первая школа риелторов в онлайн
                  и оффлайн форматах для начинающих агентов.
                  Наши опытные специалисты проводят комплексное обучение работе
                  с клиентами и дают знания о рынке недвижимости.
                </p>
              </div>

              <div className="benefits__item benefits__item_12">
                <img
                  src={require('../../assets/images/start/benefits/12.svg').default}
                  alt=""
                />
                <h4 className="benefits__title h4">Обучение для<br />партнеров</h4>
                <p>
                  Следите за обучающими мероприятиями.
                  Регулярные обучения от застройщиков,
                  приглашенные эксперты продаж,
                  юриспруденции и маркетинга.
                </p>
              </div>

            </div>

          </section>

          <section className="sub">
            <h2 className="sub__title h2">
              Подписывайтесь на рассылки
              и присоединяйтесь к сообществу профессионалов
            </h2>

            <div className="sub__list">
              <div className="sub__item" onMouseEnter={zIndexModOn}>
                <img
                  src={require('../../assets/images/start/sub/tg.svg').default}
                  alt=""
                />

                <div className="sub__box">
                  <h3 className="sub__header h3">
                    Быстро, четко, по делу
                  </h3>
                  <p className="sub__text">
                    Только свежие новости для партнеров
                    о недвижимости в удобном формате
                  </p>

                  <div className="sub__hidden">
                    <p className="sub__text">
                      Узнавайте первым о стартах продаж новостроек
                      и актуальных ценах на объекты.
                      Следите за новостями и участвуйте в акциях застройщиков.
                      Получайте анонсы очных обучений, вебинаро
                      и новых сервисов ПАН Партнер.
                      Ищите новости по хештегам и будьте в курсе событий отрасли.
                    </p>
                    <a
                      href="https://tlgg.ru/panpartner"
                      className="sub__btn button"
                      target="_blank"
                      rel="noopener noreferrer">
                      Присоединиться
                    </a>
                  </div>
                </div>
              </div>
              <div className="sub__item sub__item_insta" onMouseEnter={zIndexModOn2}>
                <img
                  src={require('../../assets/images/start/sub/insta.svg').default}
                  alt=""
                />
                <div className="sub__box">
                  <h3 className="sub__header h3">
                    Креативно
                  </h3>
                  <p className="sub__text">
                    Интересно рассказываем о&nbsp;мире недвижимости
                    и вселенной ПАН Партнер
                  </p>


                  <div className="sub__hidden">
                    <p className="sub__text">
                      Что нового в законодательстве, как меняются ипотечные ставки,
                      последние новости недвижимости.
                      Чем живет ПАН Партнер, почему мы одна семья,
                       и за что любим свою работу.
                       Как непрерывно развиваться, успевать в профессиональной гонке
                       и предлагать клиенту лучшее — следите за обучениями ПАН Партнер.
                    </p>
                    <a
                      href="https://www.instagram.com/panpartner/"
                      className="sub__btn button"
                      target="_blank"
                      rel="noopener noreferrer">
                      Присоединиться
                    </a>
                  </div>
                </div>
              </div>
              <div className={`sub__item sub__item_yt ${zIndex ? 'sub__item_z-index' : ''}`} onMouseEnter={zIndexModOff}>
                <img
                  src={require('../../assets/images/start/sub/yt.svg').default}
                  alt=""
                />
                <div className="sub__box">
                  <h3 className="sub__header h3">
                    База знаний ПАН
                  </h3>
                  <p className="sub__text">
                    Все, что нужно знать профессионалу<br />в недвижимости
                  </p>


                  <div className="sub__hidden">
                    <p className="sub__text sub__text_margin">
                      Как работать с застройщиками, что такое<br />переуступки,
                      как за 1 неделю перевести офис на удаленку — эти и другие знания,
                      которые помогут<br />вам в работе
                    </p>
                    <a
                      href="https://www.youtube.com/channel/UCk3K9s8_K6yJ1Qg0nmAqVgA/featured"
                      className="sub__btn button"
                      target="_blank"
                      rel="noopener noreferrer">
                      Присоединиться
                    </a>
                  </div>
                </div>
              </div>
              <div className={`sub__item sub__item_socials ${zIndex2 ? 'sub__item_z-index' : ''}`} onMouseEnter={zIndexModOff2}>
                <h4 className="h4">
                  Получай закрытую рассылку для партнеров
                  ПАН в любом удобном мессенджере:
                </h4>
                <div className="sub__hidden">
                  <ul className="sub__ul">
                    <li>Обучающие материалы</li>
                    <li>Подборки объектов</li>
                    <li>Cпециальные предложения</li>
                  </ul>
                  <p className="big-text sub__big-text">
                    Только по делу, без спама. Просто кликни на кнопку мессенджера!
                  </p>
                </div>
                <ul className="sub__socials">
                  <li className="sub__social sub__social_wa">
                    <a
                      className="button button_view_round-control"
                      href="https://wa.me/79626863547?&text=%D0%9E%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D0%BD%D0%B5%20%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D1%8F%D1%8F%20%D1%8D%D1%82%D0%BE%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5%20subscribe_1ff16c03-6cc4-757a-cea1-01749afec16f"
                      target="_blank"
                      rel="noopener noreferrer">
                      <svg className="icon">
                        <use xlinkHref="#social-wa" />
                      </svg>
                    </a>
                  </li>
                  <li className="sub__social sub__social_tg">
                    <a
                    className="button button_view_round-control"
                    href="https://telegram.me/panpartnerbot?start=subscribe_1ff16c03-6cc4-757a-cea1-01749afec16f"
                    target="_blank"
                    rel="noopener noreferrer">
                      <svg className="icon">
                        <use xlinkHref="#social-tg" />
                      </svg>
                    </a>
                  </li>
                  <li className="sub__social sub__social_fb">
                    <a
                      className="button button_view_round-control"
                      href="https://m.me/129438147749384?ref=subscribe_1ff16c03-6cc4-757a-cea1-01749afec16f"
                      target="_blank"
                      rel="noopener noreferrer">
                      <svg className="icon">
                        <use xlinkHref="#social-fb" />
                      </svg>
                    </a>
                  </li>
                  <li className="sub__social sub__social_viber">
                    <a
                      className="button button_view_round-control"
                      href="viber://pa?chatURI=panpartner&context=subscribe_1ff16c03-6cc4-757a-cea1-01749afec16f"
                      target="_blank"
                      rel="noopener noreferrer">
                      <svg className="icon">
                        <use xlinkHref="#social-viber" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="news">
            <div className="news__container">
              <header className="news__header">
                <h2 className="news__title h2">
                  Каждый день мы делимся с партнерами новостями отрасли.
                </h2>
                <p className="news__descr big-text">
                  Выберите интересную тему и получайте email-рассылку только на интересные вам темы.
                </p>
              </header>
              <div className="news__choices">
                <ul className="news__checkboxes">

                  {rubrics?.map((newsItem) =>
                    <li
                      className="news__checkbox"
                      key={`news-checkbox-key-${newsItem.id}`}>
                      <Checkbox
                        id={`news-checkbox-${newsItem.id}`}
                        view="start-checkbox"
                        labelText={newsItem.name}
                        checked={rubricsIds.includes(newsItem.id)}
                        onChange={(e) => setSubscribtion(e.target.checked, newsItem.id)}
                      />
                    </li>
                  )}

                </ul>
              </div>
            </div>

          </section>

          <section className="offers" ref={offersSectionRef}>
            <div className="offers__container">
              <header className="offers__header">
                <h2 className="h2 offers__title">
                  Прежде чем начать работу, внимательно изучите условия договоров-оферт.
                </h2>
                <p className="big-text offers__text">
                  В офертах мы описали юридические правила сотрудничества с ПАН Партнер.
                  Обязательно уделите им время.
                </p>
                <p className="big-text offers__text">
                  Ознакомьтесь со всеми регламентами взаимодействия
                  и получите доступ к базе новостроек прямо сейчас!
                </p>
              </header>
              <div className="offers__list">
                <div className="offers__warning">
                  Просим Вас внимательно ознакомиться со всеми документами.
                  Чтобы получить доступ к базе Вы должны принять все оферты.
                </div>

                <OffersList items={offers} />

                <Checkbox
                  id={"offers-checkbox"}
                  view="start-checkbox"
                  labelText={"Принимаю все условия оферты"}
                  checked={accepted}
                  onChange={(e) => setAccepted(e.target.checked)}
                />

                <button disabled={!accepted} className="button button_view_default" onClick={handleSubmit}>Принять</button>
              </div>

            </div>
          </section>

        </div>
      </div>

      <div className={`scroll-panel wrapper ${!isScrollPanelVisible && `hidden`}`}>
        <div className="scroll-panel__container">
          <p className="h5">Прежде чем начать работу, внимательно изучите условия договоров-оферт.</p>
          <button
            className="button button_view_default"
            type="submit"
            onClick={onScrollBtnClick}>
            Ознакомиться
          </button>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchOffers()),
  reset: () => dispatch(clearOffers()),
  acceptOffers: () => dispatch(acceptOffers()),
  successStart: (payload) => dispatch(successStart(payload)),
});

const mapStateToProps = (state) => ({
  fetching: state.profileOffers.fetching,
  error: state.profileOffers.error,
  offers: state.profileOffers.offers,
  agree: state.user.acceptOffers,

  rubrics: state.start.rubrics,
});

export default connect(mapStateToProps, mapDispatchToProps)(Start);
