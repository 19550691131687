import React, { useEffect, useState } from "react";
import { checkAgent, editAgents, getAccessRights } from "../../actions/agents";
import Preloader from '../Preloader';
import Form from "./Form";

const defaultValues = {
  id: 0,
  name: "",
  lastName: "",
  patronymic: "",
  phone: [""],
  email: [""],
  comment: "",
  active: true,
  role: "agent",
};

const Add = (props) => {
  const { history } = props;

  const [groups, setGroups] = useState(null)

  useEffect(() => {
    getAccessRights().then(data => setGroups(data.accessRights))
  }, [])

  if(!groups) return <Preloader loading={true} /> 
  return (
    <Form
      add={editAgents}
      history={history}
      defaultValues={defaultValues}
      groups={groups}
      checkEmail={checkAgent}
    />
  );
};

export default Add
