import React, { useState } from "react";

const Analytics = (props) => {
  const { yandexMetric, setState, propsCreator, selfDomain } = props;


  //const selfDomain = 1; // чтобы открыть табы, если надо для тестов, удалить с пропсов


  const [activeTab, setActiveTab] = useState(1);



  return (
    <div className="admin__input-block admin__list-tabs domens">
      <div className="admin__list-container admin__list-container_lg">
        <div className={`admin__list-tab${activeTab == 1 ? ` active` : ``}`} onClick={() => setActiveTab(1)}>
          <span>Яндекс.Метрика</span>
        </div>
        <div className={`admin__list-tab${activeTab == 2 ? ` active` : ``} ${selfDomain == 1 ? "with-tooltip-disabled" : "with-tooltip"}`} onClick={() => selfDomain == 1 ? setActiveTab(2) : null}>
          <span>Facebook</span>
          <span className="tooltip" >Доступно только после подключения своего домена</span>
        </div>

        <div className={`admin__list-tab${activeTab == 3 ? ` active` : ``} ${selfDomain == 1 ? "with-tooltip-disabled" : "with-tooltip"} `} onClick={() => selfDomain == 1 ? setActiveTab(3) : null}>
          <span>Вконтакте</span>
          <span className="tooltip" >Доступно только после подключения своего домена</span>
        </div>

      </div>

      <div className={`admin__list-box${activeTab == 1 ? `` : ` hidden`}`}>
        <div className="admin__input-row admin__input-row_mt">
          <div className="admin__input-block admin__input-block_xs">
            <label htmlFor="" className="admin__label">
              Счетчик Яндекс.Метрика
              <div className="info">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                  <p>Подключите счетчик Яндекс.Метрика, чтобы собирать статистику о посещаемости </p>
                </div>
              </div>
            </label>
            <input type="text" className="admin__input" value={yandexMetric ? yandexMetric : ""} onChange={(e) => setState(e.target.value.replace(/\D/, ""), "yandexMetric")} placeholder={`98765432`} />
          </div>
        </div>
      </div>

      <div className={`admin__list-box${activeTab == 2 ? `` : ` hidden`}`}>
        <div className="admin__input-row admin__input-row_mt">
          <div className="admin__input-block admin__input-block_xs">
            <label htmlFor="" className="admin__label">
              Facebook Pixel
              <div className="info">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                  <p>Инструмент, с помощью которого можно отслеживать поведение пользователей на вашем сайте</p>
                </div>
              </div>
            </label>
            <input type="text" className="admin__input" placeholder={`12345678901234567`} {...propsCreator("pixel")} />
          </div>
        </div>
        <div className="admin__input-row admin__input-row_mt">
          <div className="admin__input-block admin__input-block_lg">
            <label htmlFor="" className="admin__label">
              Верификация домена
              <div className="info">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                  <p>Для подтверждения ваших прав на домен, добавьте код из рекламного кабинета Facebook</p>
                </div>
              </div>
            </label>
            <input type="text" className="admin__input" {...propsCreator("pixelMeta")} placeholder={`<meta name="facebook-domain-verification" content="ваш уникальный код" />`} />
          </div>
        </div>
        <div className="text domen__sign domen__sign_center">
          <span className="info">
            <svg className="icon icon_info">
              <use xlinkHref="#info" />
            </svg>
          </span>
          <p className="text">
            Стандартная цель «lead» подключается по умолчанию на успешную отправку всех форм
          </p>
        </div>
      </div>

      <div className={`admin__list-box${activeTab == 3 ? `` : ` hidden`}`}>
        <div className="admin__input-row admin__input-row_mt">
          <div className="admin__input-block admin__input-block_xs">
            <label htmlFor="" className="admin__label">
              VK Pixel
              <div className="info">
                <svg className="icon icon_info">
                  <use xlinkHref="#info" />
                </svg>
                <div className="info__popup">
                  <p>Инструмент, с помощью которого можно отслеживать поведение пользователей на вашем сайте</p>
                </div>
              </div>
            </label>
            <input type="text" className="admin__input" {...propsCreator("vk")} placeholder={`VK-TRTR-111111-tR1R1`} />
          </div>
        </div>

        <div className="text domen__sign domen__sign_center">
          <span className="info">
            <svg className="icon icon_info">
              <use xlinkHref="#info" />
            </svg>
          </span>
          <p className="text">
            Стандартная цель «conversion» подключается по умолчанию на успешную отправку всех форм, кроме формы на странице лид-магнита. Для лид-магнита используется цель «lead»
          </p>
        </div>
      </div>

      <div className="admin__section admin__section_pb-0">

        <div class="admin__title">
          <h5 class="admin__h5 h5">Идентификаторы и цели</h5>
          <div className="info">
            <svg className="icon icon_info">
              <use xlinkHref="#info" />
            </svg>
            <div className="info__popup">
              <p>Для регистрации событий, добавьте цели с указанными идентификаторами в Вашу систему аналитики</p>
            </div>
          </div>
        </div>


        <table className="domen__table domen__table_sm">
          <tr>
            <th>Название</th>
            <th>Идентификатор цели</th>
          </tr>

          <tr>
            <td className="bold">Просмотр формы на главной страницы</td>
            <td>tr_main_form_view</td>
          </tr>
          <tr>
            <td className="bold">Отправка формы с главной страницы</td>
            <td>tr_main_form_send</td>
          </tr>

          <tr>
            <td className="bold">Просмотр формы в карточке ЖК</td>
            <td>tr_building_form_view</td>
          </tr>
          <tr>
            <td className="bold">Отправка формы из карточки ЖК</td>
            <td>tr_buiding_form_send</td>
          </tr>
          <tr>
            <td className="bold">Просмотр формы лид-магнита</td>
            <td>tr_magnet_form_view</td>
          </tr>
          <tr>
            <td className="bold">Отправка формы лид-магнита</td>
            <td>tr_magnet_form_send</td>
          </tr>
          <tr>
            <td className="bold">Переход в Instagram</td>
            <td>tr_instagram_click</td>
          </tr>
          <tr>
            <td className="bold">Переход в Telegram</td>
            <td>tr_telegram_click</td>
          </tr>
          <tr>
            <td className="bold">Переход во Вконтакте</td>
            <td>tr_vk_click</td>
          </tr>
          <tr>
            <td className="bold">Переход в Facebook</td>
            <td>tr_facebook_click</td>
          </tr>
          <tr>
            <td className="bold">Переход в Viber</td>
            <td>tr_viber_click</td>
          </tr>
          <tr>
            <td className="bold">Переход в WhatsApp</td>
            <td>tr_whatsapp_click</td>
          </tr>
          <tr>
            <td className="bold">Просмотр всех отзывов</td>
            <td>tr_all_reviews_view</td>
          </tr>
          <tr>
            <td className="bold">Подгрузка недвижимости</td>
            <td>tr_load_more_variants</td>
          </tr>
          <tr>
            <td className="bold">Отправка формы со страницы лид магнита</td>
            <td>tr_lead_magnet_page_send</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Analytics;
