import mergeWith from 'lodash/mergeWith';
import slice from 'lodash/slice';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import ym from "react-yandex-metrika";
import { getFormSettings, sendForm } from '../../actions/forms';
import BotBar from '../../components/BotBar/BotBar';
import Dropdown from '../../components/core/Dropdown';
import Preloader from '../../components/Preloader';
import SubmitButton from '../../components/SubmitButton';
import { errorToast, infoToast, standartErrorToast } from '../../components/toasts';
import { formatMoney, media } from "../../utils";
import FileButton from './FileButton';
import './index.scss';
import SelectDopdown from './SelectDopdown';

const defaultFiles = {
  plan: {
    files: [],
    filesList: []
  },
  photos: {
    files: [],
    filesList: []
  },
};
const getValueForDropdown = (code, formSettings) => {
  return formSettings.find(setting => setting.code === code)?.items?.[0]?.value
}

const AddSecondary = () => {

  const [formSettings, setFormSettings] = useState(null)
  const [form, setForm] = useState({})
  // const [builders, setBuilders] = useState([])
  const [, setActiveBuilder] = useState({})
  const [, setErrors] = useState("")
  const [agreement, setAgreement] = useState(true)
  const [files, setFiles] = useState(defaultFiles);
  const [sending, setSending] = useState(false)
  const [formId, setFormId] = useState(32)

  const defaultCodes = ["object_type", "sale_type", "district", "house_type", "bathroom", "elevator", "rooms", "balcon"]
  const fetch = (formId) => {
    getFormSettings(formId).then(resp => {
      setFormSettings(resp.questions)
      // setBuilders(resp.builders)
    },
    standartErrorToast
    )
  }
  const userId = useSelector(state => state?.user?.info?.id)
  useEffect(() => {
    if (!formId) return
    fetch(formId)
    setForm({})
  }, [formId])

  useEffect(() => {
    if (!formSettings) return
    defaultCodes.forEach(code => {
      handleChange(getValueForDropdown(code, formSettings), code)
    })
    handleChange(userId, "user_id")
  }, [formSettings, userId])


  const getFieldName = (code, isMulti) => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        if (isMulti) {
          return [...setting.items]
        }
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const isRequired = code => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        return setting.required
      }
    }
    return false
  }
  const handleChange = (value, code, arrayValue) => {
    const tmp = {
      ...form
    }
    const handlers = {

      defalutText: (code, val) => {
        const ident = getFieldName(code)
        tmp[ident] = val
      },

      builderName: function () {
        if (typeof value === "string") {
          setActiveBuilder({})
          this.defalutText("builderId", false)
          this.defalutText(code, value)
          return
        }
        setActiveBuilder(value)
        this.defalutText("builderId", value.id)
        this.defalutText(code, value.name)
      },

      blockName: function () {
        if (typeof value === "string" || !value) {
          return
        }
        this.defalutText("blockId", value.id)
        this.defalutText(code, value.name)
      },

    }
    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      handlers[code] ? handlers[code]() : handlers.defalutText(code, value)
    }
    setForm(prev => ({ ...prev, ...tmp }))
  }

  const submit = () => {

    if (!agreement) {
      errorToast(<div>Необходимо согласие на обработку персональных данных</div>)
      setErrors(prev => ({ ...prev, agreement: true }))
      return
    }

    if (window.location.host !== 'localhost:3000') {
      ym('reachGoal', 'sell_secondary');
    }

    let formData = new FormData();
    let keys = Object.keys(form);
    keys.forEach((key) => {
      if (Array.isArray(form[key])) {
        form[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, form[key]);
      }
    });
    let filesKeys = Object.keys(files);
    filesKeys.forEach((key) => {
      files[key].files.forEach((data) => {
        if (data.file) {
          formData.append(data.fieldNames, data.file);
        }
      });
    });
    setSending(true)
    sendForm(formId, formData, "spb").then(
      () => {
        infoToast( <div>Спасибо! Форма успешно отправлена</div>)
        setSending(false)
        setFiles(defaultFiles);
        const formValues = {}
        setForm({});
        defaultCodes.forEach(code => {
          formValues[code] = getValueForDropdown(code, formSettings)
        })
        formValues[getFieldName("user_id")] = userId
        setForm(formValues);
      },
      (error) => {
        standartErrorToast(error)
        setSending(false)
      },
    );
  }

  const loadFiles = (e, ident, isMulti) => {
    const tmp = [...files[ident].filesList, ...e.target.files];
    const fieldNames = getFieldName(ident, isMulti)
    if (!tmp.length > 0) return
    if (isMulti) {
      let tmpFiles = mergeWith(slice(fieldNames, 0, 10), slice(tmp, 0, 10), (first, second) => ({ fieldNames: first.fieldName, file: second }))
      setFiles(prev => ({
        ...prev,
        [ident]: {
          files: tmpFiles,
          filesList: slice(tmp, 0, 10)
        }
      }))
      return
    }
    setFiles(prev => {
      let reversed = [...tmp].reverse()
      return {
        ...prev, [ident]:
        {
          files: [{ fieldNames, file: reversed[0] }],
          filesList: slice(reversed, 0, 1)
        }
      }
    })

  };

  const removeFile = (key, index) => {
    const filesList = [...files[key]?.filesList];
    const tmpFiles = [...files[key]?.files];
    filesList.splice(index, 1);
    tmpFiles.splice(index, 1);
    setFiles({
      ...files, [key]: {
        files: tmpFiles,
        filesList
      }
    });
  };
  if (!formSettings) {
    return <Preloader loading={true} />
  }

  return (
    <section className="sell section section_view_white">
      <div className="container container_sm">
        <div className="sell__title">
          <h1 className="h1">Добавление объекта</h1>

          <div className="sell__switcher switcher">
            <div
              className={`switcher__btn ${formId === 32 ? "active" : ""}`}
              onClick={() => setFormId(32)}
            >
              Санкт-Петербург
            </div>
            <div
              className={`switcher__btn ${formId === 33 ? "active" : ""}`}
              onClick={() => setFormId(33)}
            >
              Москва
            </div>
          </div>
        </div>

        <h4 className="sell__subtitle h4">Общая информация</h4>

        <div className="modal-form">
          <div className="modal-form__wrapper">
            <div className={`modal-form__gist ${media('isMobile') ? 'with-margin' : ''}`}>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Тип объекта {isRequired("object_type") && <span className="is-red-text sell__required">*</span>}</span>
                    <div className="select select_type_form">
                      <Dropdown
                        options={formSettings.find(setting => setting.code === "object_type")?.items?.map(item => ({ id: item.value, title: item.name }))}
                        value={form[getFieldName("object_type")] ? form[getFieldName("object_type")] : ""}
                        onChange={(val) => handleChange(val, "object_type")}
                      />
                    </div>
                  </label>
                </div>
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Комиссия для партнёров ПАН {isRequired("commission") && <span className="is-red-text sell__required">*</span>}</span>
                    <input name="name" className="input__control" value={formatMoney(form[getFieldName("commission")])} onChange={(e) => handleChange(e.target.value.replace(/\s/g, ""), "commission")} />
                  </label>
                </div>
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Тип продажи {isRequired("sale_type") && <span className="is-red-text sell__required">*</span>}</span>
                    <div className="select select_type_form">
                      <Dropdown
                        options={formSettings.find(setting => setting.code === "sale_type")?.items?.map(item => ({ id: item.value, title: item.name }))}
                        value={form[getFieldName("sale_type")] ? form[getFieldName("sale_type")] : ""}
                        onChange={(val) => handleChange(val, "sale_type")}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="modal-form__row">
                <div>
                  <div className="input input_width_available input_type_form">
                    <span className="input__label">Ипотека {isRequired("mortgage") && <span className="is-red-text sell__required">*</span>}</span>
                  </div>
                  <div className="modal-form__row modal-form__radio-row">
                    {formSettings?.find(setting => setting.code === "mortgage")?.items?.map((item, i) => {
                      return <div className="radio" key={`mort-${i}`}>
                        <div className="radio__box">
                          <input
                            className="radio__control"
                            type="radio"
                            id={`mortgage-availible-${i}`}
                            name="mortgage"
                            value={item.value}
                            onChange={() => {
                              handleChange(item.value, "mortgage")
                            }}
                            checked={form?.[getFieldName("mortgage")] == item.value}
                          />
                          <label className="radio__input" htmlFor={`mortgage-availible-${i}`}></label>
                          <div className="radio__marker"></div>
                        </div>
                        <label class="radio__label" htmlFor={`mortgage-availible-${i}`}>{item.name}</label>
                      </div>
                    })}
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>


        <h4 className="sell__subtitle h4">Информация о квартире</h4>

        <div className="modal-form">
          <div className="modal-form__wrapper">

            <div className="modal-form__row">
              <div className="modal-form__item modal-form__item_2x">

                <label className="input input_width_available input_type_form input_preudo_rub">
                  <span className="input__label">Цена за объект {isRequired("object_price") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={formatMoney(form[getFieldName("object_price")])} onChange={(e) => handleChange(e.target.value.replace(/\s/g, ""), "object_price")} />
                </label>

              </div>

              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Район {isRequired("object_type") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "district")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("district")] ? form[getFieldName("district")] : ""}
                      onChange={(val) => handleChange(val, "district")}
                    />
                  </div>
                </label>
              </div>
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Метро {isRequired("subway") && <span className="is-red-text sell__required">*</span>}</span>
                  {/* <input name="name" className="input__control" value={form[getFieldName("subway")] ? form[getFieldName("subway")] : ""} onChange={(e) => handleChange(e.target.value, "subway")} placeholder="Адмиралтейская" /> */}
                  <SelectDopdown
                    formData={form[getFieldName("subway")]}
                    items={formSettings.find(setting => setting.code === "subway").items}
                    isAutocomplete changeHandler={(checked, value) => handleChange(checked, getFieldName("subway"), value)}
                    right={true}
                    identity={"subway"}
                  />
                </label>
              </div>

            </div>

            <div className="modal-form__row">
              <div className="modal-form__item modal-form__item_single-wide">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Адрес {isRequired("adress") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("adress")] ? form[getFieldName("adress")] : ""} onChange={(e) => handleChange(e.target.value, "adress")} placeholder="Например, Королева пр-кт., 20, к.6" />
                </label>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">S общая {isRequired("s_general") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("s_general")] ? form[getFieldName("s_general")] : ""} onChange={(e) => handleChange(e.target.value, "s_general")} placeholder="Общая площадь" />
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">S жилая {isRequired("s_living") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("s_living")] ? form[getFieldName("s_living")] : ""} onChange={(e) => handleChange(e.target.value, "s_living")} placeholder="Жилая площадь" />
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">S кухни {isRequired("s_kitchen") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("s_kitchen")] ? form[getFieldName("s_kitchen")] : ""} onChange={(e) => handleChange(e.target.value, "s_kitchen")} placeholder="Площадь кухни" />
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Кол-во комнат {isRequired("rooms") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "rooms")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("rooms")] ? form[getFieldName("rooms")] : ""}
                      onChange={(val) => handleChange(val, "rooms")}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Тип дома {isRequired("house_type") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "house_type")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("house_type")] ? form[getFieldName("house_type")] : ""}
                      onChange={(val) => handleChange(val, "house_type")}
                    />
                  </div>
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Этаж {isRequired("floor") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("floor")] ? form[getFieldName("floor")] : ""} onChange={(e) => handleChange(e.target.value, "floor")} placeholder="Этаж" />
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Этажность {isRequired("floors") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("floors")] ? form[getFieldName("floors")] : ""} onChange={(e) => handleChange(e.target.value, "floors")} placeholder="Этажность" />
                </label>
              </div>
              <div className="modal-form__item">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Год постройки {isRequired("year") && <span className="is-red-text sell__required">*</span>}</span>
                  <input name="name" className="input__control" value={form[getFieldName("year")] ? form[getFieldName("year")] : ""} onChange={(e) => handleChange(e.target.value, "year")} placeholder="Год постройки" />
                </label>
              </div>
            </div>

            <div className="modal-form__row">
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Санузел {isRequired("bathroom") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "bathroom")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("bathroom")] ? form[getFieldName("bathroom")] : ""}
                      onChange={(val) => handleChange(val, "bathroom")}
                    />
                  </div>
                </label>
              </div>
              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Балкон {isRequired("balcon") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "balcon")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("balcon")] ? form[getFieldName("balcon")] : ""}
                      onChange={(val) => handleChange(val, "balcon")}
                    />
                  </div>
                </label>
              </div>

              <div className="modal-form__item modal-form__item_sm">
                <label className="input input_width_available input_type_form">
                  <span className="input__label">Лифт {isRequired("elevator") && <span className="is-red-text sell__required">*</span>}</span>
                  <div className="select select_type_form">
                    <Dropdown
                      options={formSettings.find(setting => setting.code === "elevator")?.items?.map(item => ({ id: item.value, title: item.name }))}
                      value={form[getFieldName("elevator")] ? form[getFieldName("elevator")] : ""}
                      onChange={(val) => handleChange(val, "elevator")}
                    />
                  </div>
                </label>
              </div>

            </div>

            <div className="modal-form__row modal-form__row_mt_32">
              <FileButton
                label={`Планировка ${isRequired("plan") ? "*" : ""}`}
                loadFiles={(e) => loadFiles(e, "plan", true)}
                files={files.plan.filesList}
                ident="plan"
                removeFile={removeFile}
              />

              <FileButton
                label={`Фотографии ${isRequired("photos") ? "*" : ""}`}
                loadFiles={(e) => loadFiles(e, "photos", true)}
                files={files.photos.filesList}
                ident="photos"
                removeFile={removeFile}
              />
            </div>
          </div>
        </div>

        <div className="modal-form modal-form_mt_50">
          <div className="modal-form__wrapper">
            <div className={`modal-form__gist ${media('isMobile') ? 'with-margin' : ''}`}>
              <h4 className="sell__subtitle h4">Дополнительно</h4>

              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="textarea textarea_type_form"
                  >
                    <span className="textarea__label">Описание: {isRequired("description") && <span className="is-red-text sell__required">*</span>}</span>
                    <textarea
                      className="textarea__control"
                      name="comment"
                      onChange={(e) => handleChange(e.target.value, "description")}
                      placeholder="Укажите дополнительную информацию о вашем объекте"
                      value={form[getFieldName("description")] ? form[getFieldName("description")] : ""}
                    ></textarea>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="modal-form modal-form_mt_25">
          {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
            return <div className="modal-form__row" key={`policy-${i}`}>
              <div className="modal-form__item">
                <div className={`checkbox ${media('isMobile') ? '' : 'checkbox_view_invert'}`}>
                  <div className="checkbox__box">
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      name="agree"
                      value={item.value}
                      id="modal-reservation-agreement"
                      onChange={(e) => handleChange(e.target.checked, item?.fieldName, e.target.value)}
                      checked={!!form?.[item?.fieldName]?.includes(item.value)}

                    /><label className="checkbox__input" htmlFor="modal-reservation-agreement"></label>
                    <div className="checkbox__marker"></div>
                  </div>
                  <label className="checkbox__label" htmlFor="modal-reservation-agreement">Cогласие на обработку персональных данных {isRequired("policy") && <span className="is-red-text sell__required">*</span>}</label>
                </div>
              </div>
            </div>
          })}
        </div>
        <div className="modal-form modal-form_mt_25">
        <div className="modal-form__row">
              <div className="modal-form__item">
                <div className={`checkbox ${media('isMobile') ? '' : 'checkbox_view_invert'}`}>
                  <div className="checkbox__box">
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      name="agree"
                      id="modal-reservation-agreement"
                      onChange={(e) => setAgreement(e.target.checked)}
                      checked={agreement}

                    /><label className="checkbox__input" htmlFor="modal-reservation-agreement"></label>
                    <div className="checkbox__marker"></div>
                  </div>
                  <label className="checkbox__label" htmlFor="modal-reservation-agreement">Cогласие на обработку персональных данных <span className="is-red-text sell__required">*</span></label>
                </div>
              </div>
            </div>
        </div>
        <div className="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            onClick={submit}
            isLoading={sending}
          >
            Отправить заявку
        </SubmitButton>
        </div>

        <BotBar customText={`Моментальные уведомления о изменении Вашего объекта в нашем телеграм-боте`} largeText={true} />
      </div>
    </section>
  );
};

export default AddSecondary;
