import 'antd/es/table/style/index.css';
import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import useDropdown from '../../hooks/useDropdown';
import usePermission from '../../hooks/usePermission';
import { formatDate, media } from '../../utils';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Popup from '../core/Popup';
import Table from '../core/Table';
import { errorToast, infoToast } from '../toasts';
import RemoveEventForm from './RemoveEventForm';


const ColumnName = ({ name, id }) => {
  return <span className="lk-meetings__table-name">{name}</span>;
};

const ColumnAction = (props) => {
  const { id, removeEvent, time, name, date } = props;

  const [node, open, show, hide] = useDropdown(false);

  const showConfirm = () => {
    media('isMobile') ? removeEventMobileModal() : removeEventModal();
    hide();
    media('isMobile') && closeClick()
  };

  const onRemoveEvent = (comment) => {
    return removeEvent(id, comment).then(
      (data) => {
        media('isMobile') ? closeRemoveEventMobileModal() : closeRemoveEventModal();
        infoToast(<div>{data.message}</div>)
        return Promise.resolve(data);
      },
      (error) => {
        errorToast(<div>{error?.[0]?.message}</div>)
        return Promise.reject(error);
      },
    );
  };

  const [removeEventModal, closeRemoveEventModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeRemoveEventModal}>
        <RemoveEventForm
          name={name}
          date={date}
          time={time}
          handleSubmit={onRemoveEvent}
        />
      </Modal>
    ),
    [name, date, time, onRemoveEvent],
  );

  const [removeEventMobileModal, closeRemoveEventMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={closeRemoveEventMobileModal} title="Отмена бронирования">
        <RemoveEventForm
          name={name}
          date={date}
          time={time}
          handleSubmit={onRemoveEvent}
        />
      </MobileModal>
    ),
    [name, date, time, onRemoveEvent],
  );

  const [closePopup, setClosePopup] = useState(false);

  const closeClick = () => {
    setClosePopup(true)
  }

  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={
            'dropdown js-dropdown form__drop' + (open ? ' is-showing' : '')
          }
        >
          {media('isMobile') ?
            <Popup
              trigger={
                <button
                  type="link"
                  className="dropdown__btn button form__drop-btn button_type_round"
                  onClick={(e) => {
                    show()
                  }}
                >
                  <span className="form__drop-name"></span>
                  <svg className="icon icon_elipsis">
                    <use xlinkHref="#elipsis"></use>
                  </svg>
                </button>
              }
              closePopup={closePopup}
            >
              <div className="dt__more">
                <hr />
                <div className="dt__more-control" onClick={showConfirm}>
                  <svg className="icon icon_delete">
                    <use xlinkHref="#delete"></use>
                  </svg>
                  <span>Отменить бронь</span>
                </div>
              </div>
            </Popup> :

            <>
              <button
                type="link"
                className="dropdown__btn button form__drop-btn button_type_round"
                onClick={(e) => show()}
              >
                <span className="form__drop-name"></span>
                <svg className="icon icon_elipsis">
                  <use xlinkHref="#elipsis"></use>
                </svg>
              </button>
              <div className="dropdown__block">
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    <div className="dt__more">
                      <hr />
                      <div className="dt__more-control" onClick={showConfirm}>
                        <svg className="icon icon_delete">
                          <use xlinkHref="#delete"></use>
                        </svg>
                        <span>Отменить бронь</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  const { items, fetch, removeEvent } = props;
  const isAgent = usePermission('agent');

  let columns = [
    {
      title: 'Переговорная',
      dataIndex: 'name',
      key: 'name',
      width: '35%',
      render: (text, record) => {
        return <ColumnName {...record} />;
      },
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
      sorter: (a, b) => {
        let clientA = `${a.client?.name}${a.client?.lastName}`;
        let clientB = `${b.client?.name}${b.client?.lastName}`;
        if (!clientA && !clientB) return 0;
        if (!clientA) return -1;
        if (!clientB) return 1;

        return clientA > clientB ? 1 : -1;
      },
      render: (client, record) => {
        if (!client) return '—';

        return (
          <span className="nowrap">{`${client.name} ${client.lastName}`}</span>
        );
      },
    },
    {
      title: 'Дата и время',
      dataIndex: 'date',
      key: 'date',
      width: 250,
      sorter: (a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1),
      render: (date, record) => {
        return (
          <div
            className="lk-events__date"
            style={{ justifyContent: 'space-between' }}
          >
            <span className="nowrap">
              {formatDate(date, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </span>
            <div className="lk-events__time">
              <span dangerouslySetInnerHTML={{ __html: record.time }}></span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => (a.status?.value > b.status?.value ? 1 : -1),
      render: (status, record) => {
        return (
          <div className="lk-events__status" style={{ alignItems: 'center' }}>
            <div
              className={`label-item label_${
                status.xmlId === 'CANCEL' ? 'red' : 'green'
              }`}
            >
              {status.value}
            </div>
            {record.comment && (
              <div className="info lk-events__status-info">
                <svg className="icon icon_comment">
                  <use xlinkHref="#comment"></use>
                </svg>
                <div className="info__popup">{record.comment}</div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'action',
      width: 50,
      render: (status, record) => {
        if (status.xmlId === 'CANCEL') return null;
        return <ColumnAction {...record} removeEvent={removeEvent} />;
      },
    },
  ];

  if (!isAgent) {
    columns = columns.filter((item) => item.key != 'agentName');
  }

  const getMobileColumns = () => [
    {
      title: 'Переговорная',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <ColumnName {...record} />;
      },
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
      render: (column, record) => (
        <span>
          {record.client.lastName} {record.client && `${record.client.name[0]}.`}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'action',
      width: 50,
      render: (status, record) => {
        if (status.xmlId === 'CANCEL') return null;
        return <ColumnAction {...record} removeEvent={removeEvent} />;
      },
    },
  ]

  const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Дата и время:</span>

            <div
              className="lk-events__date"
              style={{ justifyContent: 'space-between' }}
            >
              <span className="nowrap">
                {formatDate(record.date, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </span>
              <div className="lk-events__time">
                <span dangerouslySetInnerHTML={{ __html: record.time }}></span>
              </div>
            </div>
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Статус:</span>

            <div className="lk-events__status" style={{ alignItems: 'center' }}>
              <div
                className={`label-item label_${
                  record.status.xmlId === 'CANCEL' ? 'red' : 'green'
                }`}
              >
                {record.status.value}
              </div>
              {record.comment && (
                <div className="info lk-events__status-info">
                  <Popup
                    trigger={
                      <svg className="icon icon_comment">
                        <use xlinkHref="#comment"></use>
                      </svg>
                    }
                  >
                    {record.comment}
                  </Popup>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="pan-table lk-meetings__table">
      <div className="flats__row-table is-active">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={media("isMobile") ? getMobileColumns() : columns}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: 'Ничего не найдено' }}
          customPagination={true}
          expandable={media("isMobile") ? {
              expandedRowRender: record => expandedRow(record),
            } : false}
        />
      </div>
    </div>
  );
};

export default Items;
