import { getPricePerMeter } from "../../utils";
import cloneDeep from 'lodash/cloneDeep'
export const filterToParam = (filter, id, city) => {
  const form = new FormData()
  form.append("filter[villageId]", id)
  form.append("villageId", id)
  form.append("city", city)
  Object.entries(filter).forEach(([key, value]) => {
    switch (key) {
      case 'squareParcel':
      case 'squareHouse':
      case 'prices':
      case 'floors':
      case 'countFloors':
      case 'endings':
        if (!value) {
          break
        }
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subValue !== null && subValue.length > 0) {
            form.append(`filter[${key}][${subKey}]`, subValue)
          }
        });
        break;
      case "isOnlyHanded":
        if (value?.length > 0) {
          form.append(`filter[isOnlyHanded]`, "Y")
        }
        break;
      default:
        if (Array.isArray(value)) {
          value.forEach(item => form.append(`filter[${key}][]`, item))
          return
        }
        if (value && value.length) {
          form.append(`filter[${key}]`, value)
        }
    }
  });
  return form;
};
const modifyRow = (item, city) => {
  const { objectType, id } = item;
  const isAssignment = objectType === 'assignment';
  const links = {
    assignment: `/assignments/${id}${window.location.search}`,
    booking: `/flat-booking/${objectType}/${id}/${city}`,
    suburbans: `/realty/${city}/apartment/${id}${window.location.search}`,
  };
  item.costPerMeter = parseInt(
    getPricePerMeter(item.costWithDiscount, item.squareTotal),
  );
  item.bookingLink = links.booking;
  item.link = links.suburbans;
  if (isAssignment) item.link = links.assignment;
  return item;
};

export const extractSubItems = (setData, resp) => {
  const building = resp.suburbans?.[0];
  const type = building?.types?.[0];
  setData((prev) => {
    const currentBuildingIndex = prev.suburbans.findIndex(
      (tmpBuilding) => tmpBuilding?.buildingId === building?.buildingId,
    );
    if (currentBuildingIndex === -1) return prev;
    const tmp = cloneDeep(prev);
    const currentTypeIndex = tmp.suburbans[
      currentBuildingIndex
    ].types.findIndex((tmpType) => tmpType.roomTypeId === type.roomTypeId);
    tmp.suburbans[currentBuildingIndex].types[currentTypeIndex] = type;
    tmp.suburbans[currentBuildingIndex].types[currentTypeIndex].items.map(
      modifyRow,
    );
    return tmp;
  });
};

export const parseStringAsInt = str => typeof str === "string" ? parseInt(str.replace(/ /gi, "")) : str;

export const getSuburbanFilterConfig = ({ component, method, city, villageId, cottageSettlement, tab, newFavorite, newParams = {}, savedFilterXmlId }) => ({
  apiConfig: {
    component: component ?? 'panpartner:suburbans.ajax',
    submitFunction: method,
    loadSubItems: method,
  },
  defaultFilter: {
    status: [1],
    villageId,
    cottageSettlement
  },
  defaultParams: {
    isGetSuburbans: 1,
    ...(villageId || cottageSettlement ? { villageId: villageId ?? cottageSettlement, } : []),
    ...(city ? { city } : []),
    ...(savedFilterXmlId ? { savedFilterXmlId } : [])
  },
  favoritesParams: {
    city,
    id:  villageId ?? cottageSettlement,
    type: newFavorite ? 'cottageSettlement' : 'village',
  },
  favoritePath: 'village.favorite',
  shadowFilters: ['villageId', 'status', "cottageSettlement", "type", "realtyType"],
  fields: {
    type: [],
    squareHouse: {},
    squareParcel: {},
    prices: {},
    countBedrooms: {},
    bathroomTypes: [],
    communications: [],
    materials: [],
    features: [],
    endings: {},
    floors: [],
    countFloors: {},
    isOnlyHanded: [],
    payment: [],
    status: [],
    constructionPeriod: [],
    section: [],
  },
  isGetFilter: true,
  refreshWhenFilterChange: true,
  refreshMethod: method,
  paginationEnabled: true,
  defaultLimit: 15,
  defaultLoadCondition: false,
  legacyRestoreFromLS: true,
  loadMoreDataKey: "items",
  extractSubItems: (...args) => extractSubItems(...args, city),
  filterFromLS: { filter: JSON.parse(localStorage.getItem('suburbansFilter'))?.[
    villageId
  ]},
  requiredFilters: ["villageId"],
  ...newParams
})

export const getSuburbanFilterConfigNew = ({ component, method, city, villageId, cottageSettlement, tab, newParams = {}, savedFilterXmlId }) => ({
  apiConfig: {
    component: component ?? 'panpartner:new.suburbans.ajax',
    submitFunction: method,
    loadSubItems: method,
  },
  defaultFilter: {
    status: [1],
    villageId,
    cottageSettlement,
    type: tab.typeId
  },
  defaultParams: {
    isGetSuburbans: 1,
    city,
    villageId: villageId ?? cottageSettlement,
    ...(savedFilterXmlId ? { savedFilterXmlId } : [])
  },
  favoritesParams: {
    city,
    id:  villageId ?? cottageSettlement,
    type: 'village',
  },
  favoritePath: 'village.favorite',
  shadowFilters: ['villageId', 'status', "cottageSettlement", "type", "realtyType"],
  fields: {
    type: [],
    squareParcel: {},
    prices: {},
    countBedrooms: {},
    bathroomTypes: [],
    communications: [],
    materials: [],
    features: [],
    endings: {},
    floors: [],
    countFloors: {},
    isOnlyHanded: [],
    payment: [],
    status: [],
    constructionPeriod: [],
    section: [],
  },
  isGetFilter: true,
  refreshWhenFilterChange: true,
  refreshMethod: method,
  paginationEnabled: true,
  defaultLimit: 15,
  defaultLoadCondition: false,
  legacyRestoreFromLS: true,
  loadMoreDataKey: "items",
  extractSubItems: (...args) => extractSubItems(...args, city),
  // filterFromLS: { filter: JSON.parse(localStorage.getItem('suburbansFilter'))?.[
  //     villageId
  //     ]},
  requiredFilters: ["villageId"],
  processData: (resp, prev) => {
    if (prev.filter) delete resp.filter;
    return { ...prev, ...resp };
  },
  ...newParams
})
