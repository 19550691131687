import React, { useEffect, useState } from "react";
import { getUrl } from '../../../../api';
import ThemeBg1 from './ThemeBg1';
import ThemeBg2 from './ThemeBg2';
import ThemeBg3 from './ThemeBg3';
import ThemeBg4 from './ThemeBg4';
import ThemeBg5 from './ThemeBg5';

const Themes = (props) => {

    const { currentTheme, name,lastName, post, photo, setTheme, iconEyeButton: IconEyeButton, darkMode} = props;
    const themes = [<ThemeBg1 {...props} />, <ThemeBg2 {...props} />, <ThemeBg3 {...props} />, <ThemeBg4 {...props} />, <ThemeBg5 {...props} />]
    const [image, setImage] = useState("")

    useEffect(() => {
        if (!photo) {
            setImage(require('../../../../assets/images/constructor/photo-upload.svg').default)
            return
        }
        if (photo?.src) {
            setImage(getUrl(photo?.src))
        } else {
            if (typeof photo === "string") {
                setImage(photo)
                return
            }
            const reader = new FileReader();
            reader.onload = (e) => setImage(e.target.result)
            reader.readAsDataURL(photo.blob ? photo.blob : photo);
        }
    }, [photo, props])
    return (<ul className="admin__theme-list">{themes.map((theme, i) => {
        return (
            <li className="admin__theme-item" key={"theme-" + i}>
                <div className={`admin__theme-block${darkMode === 'Y' ? ` admin__theme-block_dark` : ``}`}>
                    {theme}

                    <div className="admin__theme-manager">
                        <img src={image} alt="" className={`admin__theme-avatar${i == 3 || i == 4 ? ` admin__theme-avatar_border_square` : ``}`} />
                        {(!!post || !!name || !!lastName) && <div className="admin__theme-info admin__theme-info_bg-white">
                            <p className="admin__theme-name">{`${name || ""} ${lastName || ""}`}</p>
                            {!!post && <p className="admin__theme-position">{post}</p>}
                        </div>}
                    </div>
                </div>

                <div className="admin__theme-controls">
                    <input type="radio" className="admin__radio admin__radio_hidden" checked={currentTheme == i} id={"theme-" + i} name="theme" />
                    <label htmlFor={"theme-" + i} className="button admin__theme-label admin__menu-btn admin__menu-btn_transparent" onClick={() => setTheme(i+1)}>Выбрать</label>
                    {/* <IconEyeButton /> */}
                </div>
            </li>)
    })} </ul>)
};

export default Themes;