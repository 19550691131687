import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { media } from '../../utils';
import Confirm from "../core/Confirm";
import Popup from '../core/Popup';
import { errorToast, infoToast } from "../toasts";

 // вообще это копипаста, такого тут много, но заебёшься рефакторить

export const ColumnStatus = memo(({ status, statusColor }) => {
  if (!statusColor || !status) return "";
  return <div
    className={`label-item label_${statusColor}`}
    style={{ backgroundColor: `${statusColor}4d`, color: statusColor }}
  >{status}</div>
});

export const ColumnPay = memo(({ items }) => {

  const text = useMemo(() => items ? items.map(({ name }) => name).join("<br />") : "", [items])

  return <div dangerouslySetInnerHTML={{ __html: text }} />
});

export const ColumnManager = memo(({title, phone}) => (
  <div className="requests-manager">
    {title}<br />
    <a className="clients__phone" href={`tel:${phone}`}>
      {phone}
    </a>
  </div>))

export const ColumnAction = (props) => { //оставил в первозданном виде, как его сотворили Олег и Айбек
  const { deleteRequest, sendRequest } = props;

  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setOpen(false);
    }
  };

  const showConfirm = () => {
    confirmModal();
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const confirm = () => {
    deleteRequest(props.id).then(
      () => {
        hideModal();
        infoToast(<div>Заявка удалена</div>);
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить заявку?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const handleSend = () => {
    setOpen(false);
    sendRequest({
      id: props.id,
      client: props.clientId,
      object: props.objectId,
      manager: props.agentId,
      city: props.city,
    }).then(
      data => infoToast(<div>Заявка отправлена менеджеру бронирования</div>),
      error => errorToast(<div>{error}</div>)
    );
  };

  if (!props.statusColor || props.statusColor !== "violet") return "";

  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={
            "dropdown js-dropdown form__drop" + (open ? " is-showing" : "")
          }
        >
          {media('isMobile') ?
            <Popup
              trigger={
                <button
                  type="link"
                  className="dropdown__btn button form__drop-btn button_type_round"
                  onClick={(e) => setOpen(!open)}
                >
                  <span className="form__drop-name"></span>
                  <svg className="icon icon_elipsis">
                    <use xlinkHref="#elipsis"></use>
                  </svg>
                </button>
              }
            >
              <div className="dt__more">
                <hr />
                {props.statusColor === "violet" && (
                  <div className="dt__more-control" onClick={() => handleSend()}>
                    <svg className="icon icon_delete">
                      <use xlinkHref="#delete"></use>
                    </svg>
                    <span>Отправить заявку</span>
                  </div>
                )}

                <div
                  className="dt__more-control js-modal-btn js-client-del"
                  data-client-id="client-1"
                  data-id="delete-client"
                  onClick={showConfirm}
                >
                  <svg className="icon icon_delete">
                    <use xlinkHref="#delete"></use>
                  </svg>
                  <span>Удалить</span>
                </div>
              </div>
            </Popup> :

            <>
              <button
                type="link"
                className="dropdown__btn button form__drop-btn button_type_round"
                onClick={(e) => setOpen(!open)}
              >
                <span className="form__drop-name"></span>
                <svg className="icon icon_elipsis">
                  <use xlinkHref="#elipsis"></use>
                </svg>
              </button>
              <div className="dropdown__block">
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    <div className="dt__more">
                      <hr />
                      {props.statusColor === "violet" && (
                        <div className="dt__more-control" onClick={() => handleSend()}>
                          <svg className="icon icon_delete">
                            <use xlinkHref="#delete"></use>
                          </svg>
                          <span>Отправить заявку</span>
                        </div>
                      )}

                      <div
                        className="dt__more-control js-modal-btn js-client-del"
                        data-client-id="client-1"
                        data-id="delete-client"
                        onClick={showConfirm}
                      >
                        <svg className="icon icon_delete">
                          <use xlinkHref="#delete"></use>
                        </svg>
                        <span>Удалить</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};