import compact from 'lodash/compact';
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteSite,
  editSite,
  fetchConstructor,
  getAdv,
  getSites,
  setState
} from '../../../actions/constructor';
import adminLink from "../../../assets/images/constructor/admin-link.svg";
import useInnerCall from '../../../hooks/useInnerCall';
import useMedia from '../../../hooks/useMedia';
import usePermission from '../../../hooks/usePermission';
import { ReactComponent as Delete } from "../../../images/svg/delete.svg";
import { ReactComponent as Publish } from "../../../images/svg/publish.svg";
import { ReactComponent as Save } from "../../../images/svg/save.svg";
import { iterateAsync, validateMail } from '../../../utils';
import { isValidSubdomain } from '../../../utils/location';
import Preloader from '../../Preloader';
import { errorToast, infoToast, standartErrorToast } from '../../toasts';
import Adv from './Tabs/Adv.js';
import Edit from './Tabs/Edit.js';
import Stats from './Tabs/Stats.js';
import { converter, convertPhoto } from './utils';

const Admin = (props) => {
  const {
    theme,
    agent,
    isFetching,
    status,
    agency,
    customJS,
    subDomain,
    reviews,
    yandexMetric,
    promoText,
    id,
    counters,
    sites,
    landings,
    agents,
    email,
    widgetReviews,
    leadMagnet,
    vk,
    pixel,
    pixelMeta,
    selfDomain,
    site,
    coordinates,
    phoneAgency,
    multiLink,
    videoChannel,
    videoTitle,
    yandexVerification,
    googleVerification,
    count
  } = props.state
  const { fetch, user, forcedSetState, getSites, getAdv } = props
  const [isSavingInProcess, setIsSavingInProcess] = useState(false)
  const [domain, setDomain] = useState(subDomain || `agent-${user.id}`)
  const isMobile = useMedia("isMobile")
  const [activeTab, setActiveTab] = useState(0)
  const isMarketerAccess = usePermission("marketer")
  const isToprealtors = usePermission('toprealtors')

  useEffect(() => {
    fetch()
    getSites()
    if (isMarketerAccess || isToprealtors) {
      getAdv()
    }
  }, [])

  const wrapperRef = useRef(null);
  const scrollToFirstSectorRef = useInnerCall()

  const createParams = async () => {
    let converted = converter(props.state)
    console.log('converted :>> ', converted);
    const tmpParams = {
      promoText,
      name: agent.name || "",
      lastName: agent.lastName || "",
      post: agent.post || "",
      agencyName: agency.name || "",
      phone: agency.phone || "",
      address: agency.address || "",
      style: theme.style || "",
      color: theme.color || "",
      yandexMetric,
      customJs: customJS || "",
      header: theme.header || "",
      text: theme.text || "",
      blocks: converted.blocks,
      slides: [],
      socials: converted.socials,
      advantages: converted.advantages,
      services: converted.services,
      promo: {
        social: converted.promoSocials
      },
      sets: converted.sets,
      defaultCity: converted.defaultCity.id,
      defaultRealtyType: converted.defaultRealtyType,
      leadMagnet: converted.leadMagnet,
      reviews: await iterateAsync(reviews, async (review) => {
        const photo = await convertPhoto(review.photo?.src ? review.photo?.src : review.photo)
        return { ...review, photo }
      }),
      // photo: await convertPhoto(agent.photo?.src ? agent.photo?.src : agent.photo),
      // logo: await convertPhoto(agency.logo?.src ? agency.logo?.src : agency.logo),
      email,
      widgetReviews,
      domain: props.state.domain || "",
      vk,
      pixel,
      pixelMeta,
      site,
      coordinates,
      phoneAgency,
      multiLink,
      videoChannel,
      videoTitle,
      yandexVerification,
      googleVerification,
      darkMode: props.state.theme?.darkMode
    }

    tmpParams.reviews = tmpParams.reviews.filter(review => !!review.fio && !!review.review)
    return [JSON.stringify(tmpParams), tmpParams]
  }

  const saveSite = async (status) => {
    setIsSavingInProcess(true)
    const [settings, params] = await createParams()
    const noName = status == 1 && isToprealtors && !params.name && !params.lastName
    const tmpMail = email?.replace?.(/ /gi, "")
    const isValidEmail = validateMail.test(tmpMail)
    if (noName || !isValidEmail) {
      errorToast(`
      ${noName ? "Для публикации необходимо ввести имя и фамилию" : ""}
      ${!tmpMail ? "Введите e-mail" : !isValidEmail ? "\nВведите корректный e-mail" : ""}`)
      scrollToFirstSectorRef.current()
      setIsSavingInProcess(false)
      return
    }
    const photo = agent.photo?.src ? JSON.stringify(agent.photo) : agent.photo.blob
    const logo = agency.logo?.src ? JSON.stringify(agency.logo) : agency.logo.blob
    const fileLeadMagnet = leadMagnet?.file?.src ? JSON.stringify(leadMagnet.file) : leadMagnet?.file?.fileObj
    const tmpReviewsPhotos = []
    const reviews = agency.logo?.src ? JSON.stringify(agency.logo) : agency.logo
    // reviews: await iterateAsync(reviews, async (review)=>{
    //   const photo = await convertPhoto(review.photo?.src ? review.photo?.src : review.photo)
    //   return {...review, photo}
    // }),
    let form = new FormData();
    if (domain) {
      form.append(`subDomain`, domain);
    }

    form.append(`settings`, settings);
    form.append(`status`, status);
    form.append(`defaultCity`, props.state.defaultCity);
    form.append(`defaultRealtyType`, props.state.defaultRealtyType);
 
    if (photo) {
      if (agent.photo?.src) {
        form.append(`photo`, photo);
      } else {
        form.append(`photo`, photo, agent.photo?.name);
      }
    }
    if (logo) {
      if (agency.logo?.src) {
        form.append(`logo`, logo);
      } else {
        form.append(`logo`, logo, agency.logo?.name);
      }
    }
    if (id) {
      form.append(`id`, id);
    }
    if (fileLeadMagnet) {
      if (leadMagnet?.file?.src) {
        form.append(`fileLeadMagnet`, fileLeadMagnet);
      } else {
        form.append(`fileLeadMagnet`, fileLeadMagnet, agency.logo?.name);
      }
    }
    // form.append(`reviews`, JSON.stringify(reviews));
    if (!isValidSubdomain(domain)) {

      errorToast('Данный домен не доступен для регистрации')
      setIsSavingInProcess(false)
      return
    }
    return editSite(form).then(
      (resp) => {
        infoToast(<div>{resp.message ? resp.message : "Сайт успешно сохранён"}</div>)
        fetch()
        setIsSavingInProcess(false)
        if (status) {
          window.open(resp.site.selfDomain == 1 && resp.site.domain ? `https://${resp.site.domain}` : `https://${domain}.toprealtors.ru/`)
        }
      },
      error => {
        errorToast(error[0]?.message)
        setIsSavingInProcess(false)
        return Promise.reject(error)
      },
    );
  }
  const match = (path) => path.match(/(?:[a-z]+)/gi)

  const setState = (value, path, arrayValue, specialSearch = "id") => {
    const matches = match(path).reverse()
    let result;
    let tmpValue;
    if (arrayValue) {
      let index = arrayValue.findIndex(item => {
        return typeof item === "object" ? item[specialSearch] == value[specialSearch] : item == value
      })
      tmpValue = [...arrayValue]
      tmpValue.splice(index, 1, value)
      tmpValue = compact(tmpValue)
    }
    result = matches.reduce((acc, current) => {
      return { [current]: acc }
    }, tmpValue ? tmpValue : value)
    props.setState(result)
  }

  const delSite = () => deleteSite(id).then(
    data => {
      infoToast(<div>Сайт успешно удалён</div>)
      window.open("/", "_self")
    },
    standartErrorToast
  );

  if (isFetching) {
    return <Preloader loading={true} />
  }

  const defaultMessage = "Доступно после публикации"
  const tabs = [
    {
      title: "Контент",
      message: defaultMessage,
      access: true,
      id: 0,
    },
    ...(isToprealtors ? [{
      title: "Клиенты",
      message: defaultMessage,
      access: isToprealtors,
      altComponent: <Link to="/personal/clients" className="switcher__btn admin__tabs-tab">Клиенты</Link>,
      id: 1

    }] : []),
    {
      title: "Статистика",
      message: defaultMessage,
      access: status == 1 && !!sites?.length,
      id: 2
    },
    // ...(isMarketerAccess || isToprealtors ? [{
    //   id: 3,
    //   title: "Реклама",
    //   message: isToprealtors ? <div style={{ padding: 10 }}>
    //     Раздел аренды рекламных кампаний <br /> {(isToprealtors && status != 1) ? "доступен после публикации" : "доступен только для партнеров."}
    //     <br /><br /> {(isToprealtors && status != 1) ? "" : <Link to="/add-partner" className="link" style={{ color: "#ff2d23" }}>Стать партнером</Link>}
    //   </div> : defaultMessage, access: isMarketerAccess || (isToprealtors && status == 1)
    // }] : [])
  ]

  const getTitle = (activeTab) => {
    switch (activeTab) {
      case 1:
        return "Сайты"
      case 2:
        return "Статистика"
      case 3:
        return "Кампании"
      default:
        return "Личный сайт"
    }
  }

  return (
    <div className="constructor">
      {!isToprealtors && status == 0 && <div class="admin__banner bold">
        <span style={{ color: "#FF2D23" }}>{count} сайтов</span>
        &nbsp;
        <span>создано риелторами на нашей платформе</span>
      </div>}
      <section className="constructor__main for-admin">
        <section className="admin constructor__section" ref={wrapperRef}>
          <div className="constructor__container">
            <div className="admin__wrapper constructor__wrapper">
              <div className="admin__header-wrapper">
                <div className="admin__header">
                  <h1 className="h1 admin__h1" style={{ flexGrow: 0.5, flexBasis: "200px" }}>{getTitle(activeTab)}</h1>
                  <div className="admin__tabs switcher">
                    {tabs.map(({ title, message, access, altComponent, id }, i) => (
                      <div key={id} className={`${activeTab === id || access ? "with-tooltip-disabled" : "with-tooltip"}`}>
                        {altComponent ? altComponent : <div
                          className={`switcher__btn admin__tabs-tab
                         ${activeTab === id ? "active" : access ? "" : "disabled"}`
                          }
                          onClick={() => access ? setActiveTab(id) : null}
                          key={`admin-tab-${id}`}>
                          <span>
                            {title}
                          </span>
                        </div>}
                        <span className="tooltip" style={{ ...(isMobile && activeTab == 2 ? { right: 0 } : []) }}>{message}</span>
                      </div>
                    ))}
                  </div>
                  <a
                    href="https://pan-promo.ru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="admin__showpromo-anchor admin__anchor"
                    style={{ visibility: activeTab === 0 ? "visible" : "hidden" }}
                  >Посмотреть промо</a>
                </div>

                {/*Для группы ТР в админке сайта выводится баннер. {isToprealtors && <TgBanner />} */}

                {activeTab === 0 && <div className="admin__header-info">
                  {status == 1 && (subDomain || selfDomain == 1) && <div className={"admin__anchor-wrapper"}>
                    <img src={adminLink} />
                    <a
                      href={selfDomain ? `https://${props.state.domain}/` : `https://${subDomain}.toprealtors.ru/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="admin__anchor"
                    >{selfDomain == 1 ? props.state.domain : `${subDomain}.toprealtors.ru`}</a>
                    {!!counters?.all && <div className="admin__views">
                      <svg className="icon icon_eye">
                        <use xlinkHref="#view" />
                      </svg>
                      <span>{counters?.all}</span>
                    </div>}
                  </div>}

                  <div className="admin__header-btns">
                    <button type="button" onClick={() => saveSite(1)} className="button admin__header-btn admin__icon-btn admin__icon-btn_gray">
                      <span className="admin__icon without-border">
                        <Publish />
                      </span>
                      <span>Опубликовать</span>
                    </button>

                    <button type="button" onClick={() => saveSite(0)} className="button admin__header-btn admin__icon-btn admin__icon-btn_gray">
                      <span className="admin__icon without-border">
                        <Save />
                      </span>
                      <span>Сохранить</span>
                    </button>

                    {!!id && <button type="button" onClick={delSite} className="button admin__header-btn admin__icon-btn admin__icon-btn_gray">
                      <span className="admin__icon admin__icon_delete without-border">
                        <Delete />
                      </span>
                      <span>Удалить</span>
                    </button>}
                  </div>
                </div>}
              </div>
            </div>
          </div>
          {activeTab === 0 && <Edit
            saveSite={saveSite}
            setState={setState}
            isSavingInProcess={isSavingInProcess}
            user={user}
            forcedSetState={forcedSetState}
            state={props.state}
            domain={domain}
            setDomain={setDomain}
            scrollToFirstSectorRef={scrollToFirstSectorRef}
          />}
          {activeTab === 2 && <Stats
            sites={sites}
            user={user}
          />}
          {activeTab === 3 && <Adv
            landings={landings}
            user={user}
            agents={agents}
            forcedSetState={forcedSetState}
          />}
        </section>
      </section>
    </div>
  )
};
const mapStateToProps = state => ({
  state: state.siteConstructor,
  user: state.user.info
});

const mapDispatchToProps = dispatch => ({
  setState: (state) => dispatch(setState(state)),
  forcedSetState: payload => dispatch({ type: "CONSTRUCTOR_FORCED_SET_STATE", payload }),
  fetch: (subDomain) => dispatch(fetchConstructor(subDomain)),
  getSites: (params) => getSites(params, dispatch),
  getAdv: (params) => getAdv(params, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
