import { memo } from 'react';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import { getSelectPropsCreator } from '../utils';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';


const SecondaryPublicFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    isMobile,
    clearExcludeOnly,
    clearFilterOnly
  } = props;

  const createSelectProps = getSelectPropsCreator(props)

  return <>
    <SelectFilter
      {...createSelectProps("rooms")} 
      data={{
        title: "К-во комнат",
        identity: "rooms",
      }}
      isAutocomplete={false}
    />
    <SelectFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
    />
    <SelectFilter
      {...createSelectProps("subways")}
      data={{
        title: "Метро",
        autocompleteTitle: "Введите название метро",
        identity: "subways",
      }}
    />
    <PriceFilter
      autoPosition
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      wrapperClass="sf__filter-item"
    />
    <FloorsFilter
      autoPosition
      items={filterData.floors?.items ?? []}
      filter={filter.floors ?? {}}
      data={{
        title: 'Этаж',
        minPlaceholder: 'от ',
        maxPlaceholder: 'до ',
        defaultValues: filterData.floors.range ?? null,
      }}
      onChange={({ filter }) => handleFilter('floors', filter)}
      secondData={{
        title: filterData.isNoFirstFloor?.[0]?.title ?? 'Не первый',
        value: filter.isNoFirstFloor ?? 'N',
      }}
      secondChange={v => handleFilter('isNoFirstFloor', v === 'Y' ? v : "")}
      wrapperClass="sf__filter-item"
    />
    <SquareFilterOnly
      autoPosition
      filter={filter.squareTotal ?? { min: "", max: "" }}
      data={{
        title: 'Площадь',
        minPlaceholder: 'S общ. от ',
        maxPlaceholder: 'S общ. до ',
        defaultValues: filterData.squareTotal?.range ?? null
      }}
      onChange={({filter}) => handleFilter("squareTotal", filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareTotal"}
      items={filterData.squareTotal?.items}
      showPlaceholderOnLists
      debounced
      wrapperClass="sf__filter-item"
    />
  </>
});

export default SecondaryPublicFilters;