import React, { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import request, { getUrl } from "../../api";
import {
    CompilationOpenIcon,
    SfFilterCopyIcon,
    SfFilterDeleteIcon,
    SfFilterEditIcon, SfFilterMenuIcon
} from "../../components/Public/markup/icons";
import Button from "../../components/core/Button";
import Modal from "../../components/core/Modal";
import useClickPast from "../../hooks/useClickPast";
import { months, parseDate, renderDate } from "../../utils";
import { successToast } from '../../components/toasts';

const compareBy = (type) => {
    switch (type) {
        case "price-asc":
            return (a, b) => a.price - b.price;
        case "price-desc":
            return (a, b) => b.price - a.price;
        case "date-asc":
            return (a, b) => a && b ? new Date(a.dateAdd).getTime() - new Date(b.dateAdd).getTime() : a.id - b.id;
        case "date-desc":
            return (a, b) => a && b ? new Date(b.dateAdd).getTime() - new Date(a.dateAdd).getTime() : a.id - b.id;
        default:
            return () => 0
    }
}

export const sortComplex = [
    { id: '0', title: 'По цене (сначала дешевле)', fn: compareBy("price-asc") },
    { id: '1', title: 'По цене (сначала дороже)', fn: compareBy("price-desc") },
    { id: '2', title: 'По дате добавления (сначала ранние)', fn: compareBy("date-asc") },
    { id: '3', title: 'По дате добавления (сначала поздние)', fn: compareBy("date-desc") },
];

export const goToUrl = (link = "", e) => {
    e?.stopPropagation?.()
    window.open(link, "_blank");
}

export const compilationsColumns = [
    {
        title: "Объект",
        dataIndex: "title",
        key: "title",
        render: (title, record) => {
            if (!title) return null;
            return <div>
                {/*<a href={getUrl(record.link)}*/}
                <a href={window.location.origin + record.link}
                    className="clients__table-link clients__table-client"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="nowrap">{title}</span>
                </a>
                <div>
                    {record.countViews} {record.lastView && <> / <span style={{ color: '#777A89' }}>{record.lastView}</span></>}
                </div>
            </div>
        },
    },
    {
        title: "Сотрудник",
        dataIndex: "agent",
        permission: "not-agent",
    },
    {
        title: "Дата создания",
        dataIndex: "dateAdd",
        render: date => {
            if(!date) return null
            const {
                days,
                month,
                year,
                formattedTime,
            } = parseDate(date?.replace?.("Z",""))
            return `${days} ${months[month].toLowerCase().slice(0, 3)} ${year} в ${formattedTime}`
        }
    },

];

export const CompilationControls = ({ deleteCompilation, agentId, link, id, openCompilationSettingsModal }) => {
    const isMyCompilation = useSelector(state => agentId === state.user?.info?.id);
    const [isActive, setIsActive] = useState(false);
    const node = useClickPast(() => setIsActive(false));
    const toggleActive = () => setIsActive(prev => !prev);

    const [openModal, hideModal] = useModal(({ }) => (
        <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={hideModal}>
            <div className="crm-table__sf-controlsmodaltitle">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
                    <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
                    <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
                </svg>
                Удалить подборку?
            </div>
            <div className="crm-table__sf-controlsmodaltext">
                После удаления вся информация будет утеряна
            </div>
            <div className="crm-table__sf-controlsmodalbtns">
                <Button buttonType="line" onClick={() => deleteCompilation(id)}>Да</Button>
                <Button buttonType="line" onClick={hideModal}>Нет</Button>
            </div>
        </Modal>
    ), [id]);

    const openModalCallback = () => {
        openModal();
        setIsActive(false)
    };
    const handleCopy = () => successToast(<div>Скопировано в буфер обмена</div>);
    const publicLink = `/public${link}`;
    return <>
        <span className="crm-table__sf-opencontrols" onClick={toggleActive}>
            <SfFilterMenuIcon />
        </span>
        <ul className={`${isActive ? 'crm-table__sf-controls active' : 'crm-table__sf-controls'}`} ref={node} onClick={toggleActive}>

            <li>
                <Link style={{color: 'inherit', display: 'flex', width: '100%'}} to={publicLink}>
                    <CompilationOpenIcon/>
                    Открыть
                </Link>
            </li>

            <li onClick={() => {
                openCompilationSettingsModal()
            }}>
                <SfFilterEditIcon />
                Редактировать
            </li>
            <li>
                <CopyToClipboard text={getUrl(publicLink)} onCopy={handleCopy}>
                    <Button buttonType="clean"><SfFilterCopyIcon />Скопировать ссылку</Button>
                </CopyToClipboard>
            </li>
            <li className={isMyCompilation ? "" : "disabled"} onClick={openModalCallback}>
                <SfFilterDeleteIcon />
                Удалить
                {!isMyCompilation && <div className="hint">
                    Вы не можете удалять подборки своих сотрудников
                </div>}
            </li>
        </ul>
    </>
}
