import React from 'react';
import './index.scss';

const CookiesPolicy = () => {
  return (
    <section className="policy">
      <div className="wrapper">
        <section className="promo">
          <h1 className="promo__title h1">Политика использования cookies</h1>
          <p className="promo__text">
            Что такое Cookies?<br/><br/>
            Сookies — это небольшой фрагмент данных, который panpartner.ru (далее - веб-сайт, наш веб-сайт) запрашивает у браузера, используемого на Вашем компьютере или мобильном устройстве. Cookies содержат информацию о Ваших действиях на веб-сайте, а также могут содержать сведения о Вашем оборудовании, дату и время сессии. Сookies хранятся локально на Вашем компьютере или мобильном устройстве.<br/><br/>
            ООО «ПАН» обрабатывает Cookies, собранные в процессе использования посетителями нашего веб-сайта. Продолжая пользоваться нашим веб-сайтом после появления баннера-уведомления об использовании Cookies и/или при нажатии кнопки «Хорошо, закрыть» на баннере-уведомлении об использовании Cookies, Вы даете свое согласие на обработку Ваших Cookies.<br/><br/>
            Как мы используем Ваши Cookies?<br/><br/>
            Cookies используются ООО «ПАН» в целях улучшения и обеспечения нормальной работоспособности веб-сайта, а также в целях совершенствования продуктов и услуг ООО «ПАН» и его партнеров — в частности, путем определения Ваших предпочтений для предоставления Вам целевой информации по таким продуктам и услугам.<br/><br/>
            Как мы обрабатываем Ваши Cookies?<br/><br/>
            Мы обрабатываем полученные данные, в том числе, с использованием метрических программ и систем аналитики, таких как Яндекс.Метрика.<br/><br/>
            Какие виды Сookies мы используем?<br/><br/>
            Сессионные<br/><br/>
            Существуют только во временной памяти в течение времени, когда пользователь находится на странице веб-сайта. Обычно сессионные Cookies удаляют после того, как Вы закрываете окно веб-сайта. Сессионные Cookies позволяют помнить информацию о Вашем выборе на предыдущей странице, чтобы избежать необходимости повторного ввода информации.<br/><br/>
            Постоянные<br/><br/>
            Сookies, которые хранятся на Вашем компьютере или мобильном устройстве и не удаляются при закрытии браузера. Постоянные Сookies могут сохранять пользовательские настройки для определенного веб-сайта, позволяя использовать эти предпочтения в будущих сеансах использования веб-сайта.<br/><br/>
            Такие Cookies позволяют идентифицировать Вас как уникального пользователя и при возвращении на веб-сайт помогают вспомнить информацию о Вас и ранее совершенных Вами действиях.<br/><br/>
            Аналитические/маркетинговые<br/><br/>
            Включают в себя информацию о том, как Вы используете веб-сайт, например, какие страницы Вы посещаете, по каким ссылкам переходите. Мы используем эти Cookies, в основном, для того, чтобы улучшить функционал веб-сайта и Ваш пользовательский опыт при его использовании. Иногда такие Cookies также позволяют нам определить Ваши предпочтения и предоставить Вам полезную целевую информацию по продуктам, услугам и сервисам, которые могут Вас заинтересовать.<br/><br/>
            Обязательные<br/><br/>
            Это минимальный набор Cookies, использование которых необходимо для обеспечения правильной и бесперебойной работы веб-сайта.<br/><br/>
            Как отказаться от обработки Сookies?<br/><br/>
            Cookies устанавливаются в браузер на Вашем устройстве — в большинстве случаев это происходит автоматически. Вы можете отказаться от сохранения и использования Cookies на своем устройстве или удалить уже сохраненные Cookies в настройках Вашего браузера.<br/><br/>
            В том случае, если Вы откажитесь от обработки Cookies, наш веб-сайт будет использовать только обязательные Cookies.<br/><br/>


          </p>
        </section>
      </div>
    </section>
  );
};

export default CookiesPolicy;