
import React, { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { themeColors } from '../components/Constructor/constructorUtils';
import MobileModal from '../components/core/MobileModal';
import Modal from '../components/core/Modal';
import Wrap from '../components/core/ModalWrap';
import useMedia from './useMedia';

export const useYaMap = ({ title, cords = [], mapCenter = [], markerColor }) => {

    const isMobile = useMedia("isMobile")
    const [Maps, setMaps] = useState(null)
    const [ymaps, setYmaps] = useState(null);
    const [mapInstance, setMapInstance] = useState(null);

    const dynamicMapImport = async () => {
        const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
        setMaps({ YMaps, Map, Placemark, RulerControl })
    };
    useEffect(() => {
        if(!cords) return 
        dynamicMapImport()
    }, [cords])

    

    useEffect(() => {
        if (!mapInstance || !ymaps) return
        const placemarkTemplate = ymaps.templateLayoutFactory.createClass(
        `<svg width="48" height="57" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 56" style="enable-background:new 0 0 48 56;" xml:space="preserve">
            <path style="fill-rule:evenodd;clip-rule:evenodd;" fill="${themeColors[markerColor] || themeColors.red}" d="M8.12,0.12c-4.42,0-8,3.58-8,8v31.75c0,4.42,3.58,8,8,8h10.56L24,55.88l5.32-8.02h10.56c4.42,0,8-3.58,8-8V8.12  c0-4.42-3.58-8-8-8H8.12z"/>
            <path fill="#FFFFFF" d="M30.73,11.33H17.27c-0.37,0-0.67,0.29-0.67,0.65v23.86c0,0.36,0.3,0.65,0.67,0.65h5.22v-3.81h3.03v3.81h5.22  c0.37,0,0.67-0.29,0.67-0.65V11.99C31.4,11.62,31.1,11.33,30.73,11.33z M22.85,30.24h-3.03v-3.28h3.03V30.24z M22.85,24.5h-3.03  v-3.28h3.03V24.5z M22.85,18.77h-3.03V15.5h3.03V18.77z M28,30.24h-3.03v-3.28H28V30.24z M28,24.5h-3.03v-3.28H28V24.5z M28,18.77  h-3.03V15.5H28V18.77z"/>
        </svg>
        `,
            
        )
        const placeMaprk = new ymaps.Placemark(cords, {
        }, {
            iconLayout: placemarkTemplate,
            iconOffset: [-24, -58],
        });
        mapInstance.geoObjects
            .add(placeMaprk);
    }, [mapInstance, ymaps])

    const [openMapModal, closeMapModal] = useModal(
        () => (
            <Wrap wrapper={children => isMobile
                ? <MobileModal closeClick={closeMapModal} title="" className="for-map">{children}</MobileModal>
                : <Modal close={closeMapModal} hideDefaultClose withoutBG>{children}</Modal>
            } >
                {
                    !isMobile && (
                        <div
                            className="modal__close "
                            data-id="dist-map"
                            onClick={closeMapModal}
                        >
                            <svg className="icon">
                                <use xlinkHref="#x"></use>
                            </svg>
                        </div>
                    )

                }

                <h3 className="modal__title">{title}</h3>
                <div className="dist-map" id="dist-map-container">
                    {Maps && <Maps.YMaps>
                        <Maps.Map
                            defaultState={{
                                center: mapCenter,
                                zoom: 16,
                            }}
                            modules={['templateLayoutFactory', 'Template', "Placemark"]}
                            onLoad={setYmaps}
                            instanceRef={setMapInstance}
                            className="complex__map-wrap"
                        >
                        </Maps.Map>
                    </Maps.YMaps>}
                </div>
            </Wrap >
        ), [Maps]);

    return [openMapModal, closeMapModal]
}