import { formatDate, searchInFieldsByString } from "../../../utils";

const clientsInitilaState = {
    isFetching: true,
    clients: [],
    filterData: {},
    agents: [],
    filter: {},
    statuses: []
}

const clientsReducer = (state, { type, payload = {} }) => {
    const { value, ident, clientId } = payload;
    switch (type) {
        case "getClients/success":
            return {
                ...state,
                isFetching: false,
                ...generateData(payload)
            }
        case "setFilter":
            const tmp = { ...state.filter, [ident]: value }
            const removeCondition = !tmp[ident]
                || (Array.isArray(tmp[ident]) && !tmp[ident].length)
                || (ident === "dates" && !tmp[ident].startDate && !tmp[ident].endDate);

            if (removeCondition) {
                delete tmp[ident]
            }
            return {
                ...state,
                filter: tmp
            }
        case "removeFilter":
            const tmpFilter = { ...state.filter }
            if (Array.isArray(tmpFilter[ident])) {
                tmpFilter[ident] = tmpFilter[ident].filter(item => item !== value)
                if(!tmpFilter[ident].length){
                    delete tmpFilter[ident]
                }
            } else {
                delete tmpFilter[ident]
            }
            return { ...state, filter: tmpFilter }
        case "modifyClient":
            return { ...state, clients:  modifyClient(state.clients, ident, value, clientId) }
        case "changeClientsMultiple": 
            const {selectedObjects,  multipleStatus, responsibleMultiple } = payload
            const tmpClients = [...state.clients]
            for (const clientId of selectedObjects) {
                const clientIndex = tmpClients.findIndex(client => client.id === clientId)
                const tmpClient = { ...tmpClients[clientIndex] }
                tmpClient.status = multipleStatus?  multipleStatus : tmpClient.status;
                if(responsibleMultiple){
                    tmpClient.agentName = getAgnetTitleById(state.filterData.agents, tmpClient.agent)
                    tmpClient.agent = responsibleMultiple;
                }
                tmpClients.splice(clientIndex, 1, tmpClient)
            }
            return {...state, clients: tmpClients }
        case "removeClient":
            const removableClientIndex = state.clients.findIndex(client => client.id == clientId)
            const tmpCl = [...state.clients]
            tmpCl.splice(removableClientIndex, 1)
            return { ...state, clients: tmpCl }
        case "resetFilter":
            return { ...state, filter: {} };
        default:
            throw new Error(`Неверный тип в экшене: ${type}`);
    }
};

const modifyClient = (clients, ident, value, clientId) => {
    const tmpClients = [...clients];
    const clientIndex = tmpClients.findIndex(client => client.id === clientId)
    const tmpClient = { ...tmpClients[clientIndex] }
    tmpClient[ident] = value;
    tmpClients.splice(clientIndex, 1, tmpClient)
    return tmpClients
}

const extractTitle = arr => {
    const result = []
    for (const item of arr) {
        result.push({ id: item.id, title: `${item.name} ${item.lastName}` })
    }
    return result
}
const compareDates = (a, b, type) => {
    if (type === "min") return a > b ? b : a;
    if (type === "max") return a < b ? b : a;
}

const generateData = payload => {
    const filterData = {
        agents: extractTitle(payload.agents),
        clients: [],
        dates: null,
        statuses: payload.statuses
    }
    const clients = []
    const tmpDates = {
        min: false,
        max: false
    }
    for (const client of payload.clients) {
        filterData.clients.push({ id: client.id, title: `${client.name} ${client.lastName}` })
        client.date = client.date? new Date(client.date.replace("Z", "")): ""
        client.formattedDate =client.date? formatDate(client.date, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }): client.date

        client.agentName = getAgnetTitleById(filterData.agents, client.agent)
        clients.push(client)
        tmpDates.min = tmpDates.min ? compareDates(tmpDates.min, client.date, "min") : client.date
        tmpDates.max = tmpDates.max ? compareDates(tmpDates.max, client.date, "max") : client.date
    }
    filterData.dates = tmpDates
    return { filterData, clients }
}
const getAgnetTitleById = (agents, agentId) => {
    const currentAgent = agents.find(agent => agent.id == agentId)
    return currentAgent ? currentAgent.title : ""
}

const isEmptyFilter = (filter = {}) => !Object.keys(filter).length

const searchSettings = {
    name: "",
    lastName: "",
    patronymic: "",
    email: "",
    agentName: "", 
    phone: /[\(,\),\+, ,\,,\-]/gi,
}

const applyFilter = (filter, clients, search = "") => {
    const filterEntries = Object.entries(filter)
    if (!filterEntries.length && !search.replace(/ /g, "")) return clients
    let result = clients.filter(client => {
        let condition = true
        for (const [key, values] of filterEntries) {
            if (key === "dates") {
                condition = condition
                    && (!values.startDate || client.date >= values.startDate)
                    && (!values.endDate || client.date <= values.endDate)
                continue;
            }
            if (Array.isArray(values)) {
                condition = condition && values.some(value => client[key] == value)
                continue;
            }
            condition = condition && client[key] == values
        }
        return condition
    })
    result = searchInFieldsByString(result, searchSettings, search)
    return result
}

export { clientsInitilaState, clientsReducer, isEmptyFilter, applyFilter };

