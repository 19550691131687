import request from '../api';
import { actionFactory } from './actionUtils';

const requestSecondary = actionFactory("SECONDARY_REQUEST");

const successSecondary = actionFactory("SECONDARY_SUCCESS");

export const clearSecondary = actionFactory("SECONDARY_RESET");

const failureSecondary = actionFactory("SECONDARY_FAILURE");

export const fetchSecondary = (id, city, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestSecondary());
    return request("panpartner:secondaries.ajax", "getSecondary", {
      id,
      city,
      savedFilterXmlId
    }).then(
      (data) => {
        const {
          secondary,
          bankPrograms,
          subways,
          agent,
          isActive,
          isShowFilter,
          client,
          similar,
          savedFilterSettings
        } = data;
        const { manager, ...rest } = secondary;
        dispatch(
          successSecondary({
            data: rest,
            manager: manager,
            banks: bankPrograms,
            subways: subways,
            agent,
            isActive,
            isShowFilter,
            client,
            similar,
            savedFilterSettings
          }),
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(failureSecondary());
        return Promise.reject(error);
      },
    );
  };
