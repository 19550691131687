import React, {useEffect, useState} from "react";
import './index.scss'
import {Link, NavLink, useParams} from "react-router-dom";
import useApi from "../../hooks/useApi";
import Preloader from "../Preloader";
import Slider from "../Public/markup/detail/Slider";
import InfoCard from "../Public/markup/detail/InfoCard";
import NewAlert from "../core/NewAlert";
import caseCompareColumns from './caseCompareColumn'
import {LocaleNotFound} from "../core/Table/tableUtils";
import Table from "../core/Table";
import {getUrl} from "../../api";
import {InfoListAdvIcon, InfoListAlarmIcon} from "../Public/markup/icons";
import ContactCard from "../core/ContactCard";
import SubmitButton from "../SubmitButton";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import CaseModal from "../CaseModal";
import useMedia from "../../hooks/useMedia";
import {useSelector} from "react-redux";
import DownloadApp from "../HomePage/DownloadApp";

const Case = () => {

  const {id} = useParams()
  const {data: {case: data = {}}, error, isLoading, isSending, send} = useApi({
    payload: ["panpartner:cases.ajax", "getItem", {id}],

  })

  const {data: {semiCases = []}} = useApi({
    payload: ["panpartner:cases.ajax", "getSemiCases", {id}]
  })

  const [callbackStatus, setCallbackStatus] = useState(false)

  const userInfo = useSelector(state => state.user?.info);

  const callback = () => send(["panpartner:forms.ajax", "callback", {
    urgently: true,
    managerId: data.manager?.id,
    name: `${userInfo?.name} ${userInfo?.lastName}`,
    objectId: data.objectId,
    type: data.flatType
  }]).then(
    success => setCallbackStatus({ type: "success" }),
    err => setCallbackStatus({ type: "error", message: err[0]?.message })
  )

  const searchHashParams = new URLSearchParams(window.location.hash);
  const [caseId, setCaseId] = useState(false)
  const [openCaseModal, closeCaseModal] = useModal(
    () => (
      <PanModal title={'Кейс'} closeModal={closeCaseModal} hashParams={`caseId=${caseId}`} isBig>
        <CaseModal caseId={caseId} closeModal={closeCaseModal} />
      </PanModal>
    ),
    [caseId]);
  useEffect(() => {
    const id = searchHashParams.get('caseId');
    if (!id) return;
    setCaseId(id)
    openCaseModal()
  }, []);

const openCaseModalCallback = (id) => {
  setCaseId(id)
  openCaseModal()
}

  const isSmallLaptop = useMedia("isSmallLaptop")
  const isMobile = useMedia("isMobile");
  const [downloadApp, setDownloadApp] = useState(isMobile);


  if (isLoading) return <Preloader loading/>

  return (
    downloadApp ? <DownloadApp downloadApp={downloadApp} setDownloadApp={setDownloadApp} /> :
    <>
      <div className="wrapper">
        <div className="common-header">
          <div className="common-header__title">
            <NavLink
              to={'/cases'}
              className="common-header__chevron"
            >
              <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round"/>
              </svg>
            </NavLink>
            <h3 className="common-title">
              {data?.title}
            </h3>
          </div>
        </div>
        <div className="sf case">
          <div className="sf__detail-row sf__detail-row--common-page">
            <div className="sf__detail-col">
              <Slider
                items={data?.photos}
                viewFromWindow={data?.viewFromWindows}
                withBorder
                windowView
                showButton={!!data?.viewFromWindows}
              />
              <NewAlert
                text="Скачать готовые stories и пост по этому кейсу для ваших соц.сетей вы можете в нашем "
                link="мобильном приложении."
                bgColor="#f6f7f8"
                iconColor="#2CBA2E"
                linkColor="#2CBA2E"
              />

              { !isSmallLaptop &&
                <div className="case-info">

                  {
                    data?.comment &&
                    <div className="case-info__block">
                      <p className="case-info__block-title h3">Комментарий</p>
                      <p>{data?.comment}</p>
                    </div>
                  }
                  {data.compare &&
                    <div className="custom-table custom-table_cases">
                      <Table
                        rowKey="id"
                        columns={caseCompareColumns}
                        dataSource={data?.compare?.fields}
                        pagination={false}
                        locale={LocaleNotFound}
                      />
                    </div>
                  }
                  {
                    data?.object &&
                    <div className="case-info__block">
                      <p className="case-info__block-title h3">Информация об объекте</p>
                      <InfoCard items={data?.object}/>
                    </div>
                  }

                  {
                    semiCases.length > 0 &&
                    <div className="case-info__block">
                      <p className="case-info__block-title case-info__block-title_link h3">Рекомендуем так же <Link
                        className="case-info__cases-link" to='/cases'>Все кейсы</Link></p>
                      <div className="other-cases">
                        {
                          semiCases.map((item) => {
                            return (
                              <div className="other-cases__item" onClick={() => openCaseModalCallback(item?.id)}
                                   key={item?.id}>
                                <img className="other-cases__img" src={getUrl(item?.plan?.src)} alt=""/>
                                <p className="other-cases__tit h4">{item?.title}</p>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  }

                </div>
              }
            </div>
            <div className="sf__detail-col">
              {isSmallLaptop &&
                  <p className="h3" style={{marginBottom: 15}}>Информация об объекте</p>
              }
              <InfoCard items={data?.mortgage} dateAdd={data?.dateAdd}/>

              <ul className="info-list">
                <li className="info-list__item">
                  <div className="info-list__icon"><InfoListAlarmIcon color={'#8790E5'}/></div>
                  <div className="info-list__content">
                    <p className="info-list__title h6" style={{color: '#8790E5'}}>Внимание</p>
                    <p className="info-list__text">Актуальность кейса уточняйте у менеджера</p>
                  </div>
                </li>
                <li className="info-list__item">
                  <div className="info-list__icon"><InfoListAdvIcon color={'#2CBA2E'}/></div>
                  <div className="info-list__content">
                    <p className="info-list__title h6" style={{color: '#2CBA2E'}}>Можно рекламировать</p>
                    <p className="info-list__text">Кейс соответствует рекламному регламенту</p>
                  </div>
                </li>
              </ul>

              {data.manager &&
                <ContactCard
                  name={data?.manager?.name + ' ' + data?.manager?.lastName}
                  phone={data?.manager?.phone}
                  telegram={data?.manager?.telegram !== '' ? data?.manager?.telegram : false}
                  logo
                  forAptModal
                  photo={data?.manager?.photo}
                  post={data?.manager?.post}
                  email={data?.manager?.email}
                />
              }

              {callbackStatus?.type === "success" && <NewAlert
                title="Заявка отправлена"
                text={`Ожидайте звонок. Мы свяжемся с Вами в рабочее время.`}
                bgColor="#DFF5E0"
                iconColor="#2CBA2E"
              />}
              {callbackStatus?.type === "error" && <NewAlert
                title="Ошибка"
                text={callbackStatus?.message}
                bgColor="#FFEAE9"
                iconColor="#FF2D23"
              />}

              {!(callbackStatus?.type === "success") && <SubmitButton
                type="button"
                className="btn h6 btn_line btn_full"
                onClick={callback}
                isLoading={isLoading || isSending}
              >
                Перезвоните мне
              </SubmitButton>}


              { isSmallLaptop &&
              <div className="case-info">
                {
                  data?.comment &&
                  <div className="case-info__block">
                    <p className="case-info__block-title h3">Комментарий</p>
                    <p>{data?.comment}</p>
                  </div>
                }

                {data.compare &&
                  <div className="custom-table custom-table_cases">
                    <Table
                      rowKey="id"
                      columns={caseCompareColumns}
                      dataSource={data?.compare?.fields}
                      pagination={false}
                      locale={LocaleNotFound}
                    />
                  </div>
                }
                {
                  data?.object &&
                  <div className="case-info__block">
                    <p className="case-info__block-title h3">Информация об объекте</p>
                    <InfoCard items={data?.object}/>
                  </div>
                }

                {
                  semiCases.length > 0 &&
                  <div className="case-info__block">
                    <p className="case-info__block-title case-info__block-title_link h3">Рекомендуем так же <Link
                      className="case-info__cases-link" to='/cases'>Все кейсы</Link></p>
                    <div className="other-cases">
                      {
                        semiCases.map((item) => {
                          return (
                            <div className="other-cases__item" onClick={() => openCaseModalCallback(item?.id)}
                                 key={item?.id}>
                              <img className="other-cases__img" src={getUrl(item?.plan?.src)} alt=""/>
                              <p className="other-cases__tit h4">{item?.title}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }

              </div>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Case
