import { memo } from 'react';
import useMedia from '../../../hooks/useMedia';
import RadioDropdown from '../../core/RadioDropdown';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SearchFilter from '../../FilterRealty/SearchFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import SubmitButton from '../../SubmitButton';
import { getSelectPropsCreator } from '../utils';
import FilterBtn from './FilterBtn';



const VillageFilterForm = memo(props => {
  const {
    clearExcludeOnly,
    clearFilterOnly,
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    searchPlaceholder,
    searchFilter,
  } = props
  const createSelectProps = getSelectPropsCreator(props);
  return (
    <div className="sf__filter form" style={{marginTop: 0}}>
      {!!searchPlaceholder && <SearchFilter
        placeholder={searchPlaceholder}
        handleChange={v => handleFilter(searchFilter, v)}
        value={filter[searchFilter] ?? ""}
        wrapperClass="sf__filter-item"
      />}
      {!!filterData?.section?.length && <SelectFilter
        {...createSelectProps("section")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Тип",
          identity: "section",
        }}
      />}
      {!!filterData.squareHouse?.range && <SquareFilterOnly
        autoPosition
        wrapperClass="sf__filter-item"
        filter={filter.squareHouse ?? { min: "", max: "" }}
        data={{
          title: 'Площадь дома',
          minPlaceholder: 'S дома от ',
          maxPlaceholder: 'S дома до ',
          defaultValues: filterData.squareHouse?.range ?? null
        }}
        onChange={v => setFilterAndExclude(v, 'squareHouse')}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        identity={"squareHouse"}
        items={filterData.squareHouse?.items}
        showPlaceholderOnLists
        debounced
      />}
      {!!filterData.squareParcel?.range && <SquareFilterOnly
        autoPosition
        wrapperClass="sf__filter-item"
        filter={filter.squareParcel ?? { min: "", max: "" }}
        data={{
          title: 'Площадь участка',
          minPlaceholder: 'S уч. от ',
          maxPlaceholder: 'S уч. до ',
          defaultValues: filterData.squareParcel?.range ?? null,
        }}
        placeholderMes={'сот.'}
        onChange={v => setFilterAndExclude(v, 'squareParcel')}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        identity={"squareParcel"}
        items={filterData.squareParcel?.items}
        showPlaceholderOnLists
        debounced
      />}
      {!!filterData?.countBedrooms?.items?.length && <SelectFilter
        {...createSelectProps("countBedrooms")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Спален",
          identity: "countBedrooms",
        }}
        items={filterData?.countBedrooms?.items}
      />}
      {!!filterData?.constructionPeriod?.length && <SelectFilter
        {...createSelectProps("constructionPeriod")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Срок строительства",
          identity: "constructionPeriod",
        }}
        items={filterData?.constructionPeriod}
      />}
      {!!filterData.prices && <PriceFilter
        autoPosition
        filter={filter.prices ?? { min: "", max: "" }}
        items={filterData.prices?.items ?? []}
        wrapperClass="sf__filter-item"
        data={{
          title: "Цена",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.prices?.range ?? 0,
        }}
        onChange={v => setFilterAndExclude(v, "prices")}
        debounced
      />}
      {!!filterData?.decoration?.length && <SelectFilter
        autoPosition
        onChange={(filter, exclude) => setFilterAndExclude({ filter, exclude }, "decoration")}
        items={filterData.decoration ?? []}
        wrapperClass="sf__filter-item"
        filterValues={filter.decoration}
        data={{
          title: "Отделка",
          identity: "decoration",
        }}
      />}
      {!!filterData?.endings?.items?.length && <EndingsFilter
        autoPosition
        ident={"endings"}
        filter={filter.endings ?? { min: "", max: "" }}
        items={filterData.endings?.items ?? []}
        data={{
          title: "Срок сдачи",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.endings?.ranges ?? null,
        }}
        wrapperClass="sf__filter-item"
        onChange={v => setFilterAndExclude(v, "endings")}
        secondData={{
          title: "Сданные дома",
          value: filter.isOnlyHanded ?? "N"
        }}
        secondChange={v => handleFilter("isOnlyHanded", v === "Y" ? v : null)}
      />}
      {!!filterData?.features?.length && <SelectFilter
        {...createSelectProps("features")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Доп.опции",
          identity: "features",
        }}
      />}
      {!!filterData?.communications?.length && <SelectFilter
        {...createSelectProps("communications")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Коммуникации",
          identity: "communications",
        }}
      />}
      {!!filterData?.materials?.length && <SelectFilter
        {...createSelectProps("materials")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Материалы дома",
          identity: "materials",
        }}
      />}


      {!!filterData.countFloors?.items?.length && <FloorsFilter
        autoPosition
        items={filterData.countFloors?.items ?? []}
        filter={filter.countFloors ?? {}}
        wrapperClass="sf__filter-item"
        data={{
          title: 'Этажность',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.countFloors?.range ?? null,
        }}
        onChange={v => setFilterAndExclude(v, 'countFloors')}
      />}

      <div className="form__row sf__filter-item_full">
        <div className="index-search__show-booked">
          <label className="switch" htmlFor="filter-booked">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                id="filter-booked"
                checked={filter.status?.includes?.("3")}
                onChange={e => handleFilter("status", "3")}
              />
              <div
                className="switch__input"
                htmlFor="filter-booked"
              ></div>
              <div className="switch__marker"></div>
            </div>
            <span className="switch__label">
              Показать забронированные
            </span>
          </label>
        </div>
      </div>
    </div>
  );
});

export default VillageFilterForm;
