import VideoCard from './VideoCard';
import SwiperSlider from '../../../core/SwiperSlider';
import Tabs from '../../../core/Tabs';

const VideoList = ({ items }) => {
  if (!items?.length) return null;
  const webinarItems = items.filter((item) => item.type.xmlId === 'webinar');
  const reviewItems = items.filter((item) => item.type.xmlId === 'review');

  return (
    <section className="sf__section">

      {
        // Если видео только одноного типа, напримиер, Обзор, то табы не выводим
      }
      {!!webinarItems.length && !!reviewItems.length ? (
        <Tabs title={'Видео'}>
          <section
            className="sf__video-list sf__promo-list sf-slider"
            label="Все"
          >
            <div className="swiper-pan">
              <SwiperSlider
                spaceBetween={20}
                slidesPerView={2}
                items={items}
                wrap={(item) => (
                  <div className="sf-slide">
                    <VideoCard {...item} key={item.id} />
                  </div>
                )}
              />
            </div>
          </section>

          {!!webinarItems.length && (
            <section
              className="sf__video-list sf__promo-list sf-slider"
              label="Вебинары"
            >
              <div className="swiper-pan">
                <SwiperSlider
                  spaceBetween={20}
                  slidesPerView={2}
                  items={webinarItems}
                  wrap={(item) => (
                    <div className="sf-slide">
                      <VideoCard {...item} key={item.id} />
                    </div>
                  )}
                />
              </div>
            </section>
          )}
          <section
            className="sf__video-list sf__promo-list sf-slider"
            label="Обзоры"
          >
            <div className="swiper-pan">
              <SwiperSlider
                spaceBetween={20}
                slidesPerView={2}
                items={reviewItems}
                wrap={(item) => (
                  <div className="sf-slide">
                    <VideoCard {...item} key={item.id} />
                  </div>
                )}
              />
            </div>
          </section>
        </Tabs>
      ) : (
        <section
          className="sf__video-list sf__promo-list sf-slider"
          label="Все"
        >
          <h3 class="h3-l sf__h3">Видео</h3>
          <div className="swiper-pan">
            <SwiperSlider
              spaceBetween={20}
              slidesPerView={2}
              items={items}
              wrap={(item) => (
                <div className="sf-slide">
                  <VideoCard {...item} key={item.id} />
                </div>
              )}
            />
          </div>
        </section>
      )}
    </section>
  );
};

export default VideoList;
