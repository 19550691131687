import React, { useEffect } from 'react';

const MobileModal = ({
  title,
  children,
  closeClick,
  cancelClick,
  cancelTitle,
  fixedTitle,
  submitClick,
  submitTitle,
  style,
  className
}) => {

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  });

  return (
    <div
      className={
        `mobile-filters${fixedTitle ? ` with-padding` : ''}${className ? ` ${className}` : ''}`
      }
      style={style}
    >
      <div className={`mobile-filters__title ${fixedTitle ? 'is-fixed' : ''}`}>
        <svg
          className="icon icon_arrow_up"
          onClick={closeClick}
        >
          <use xlinkHref="#arrow_up"></use>
        </svg>
        <h3>{title}</h3>
      </div>
      <div className="mobile-filters__content">
        {children}

        {(cancelTitle || submitTitle) &&
          <div className="mobile-filters__submit">
            <button
              className="button button__mobile-filters"
              onClick={cancelClick}
            >
              Очистить
              {cancelTitle}
            </button>
            <button
              className="button button__mobile-filters"
              onClick={submitClick}
            >
              {submitTitle}
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default MobileModal;
