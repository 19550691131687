import React, { memo } from 'react';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import { getSelectPropsCreator } from '../utils';
import {SuburbanHouseFilterIcon} from "./icons";
import EndingsFilter from "../../FilterRealty/EndingsFilter";

const VillagesHouseFilter = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass,
    clearExcludeOnly,
    clearFilterOnly,
    hideTitle
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    {!hideTitle && <div className="filter-title">
      <SuburbanHouseFilterIcon/>
      Параметры дома
    </div>}
    <FloorsFilter
      {...createSelectProps("countFloors")}
      onChange={v => setFilterAndExclude(v, "countFloors")}
      filter={filter?.countFloors ?? { min: "", max: "" }}
      items={filterData.countFloors?.items ?? []}
      data={{
        title: "Кол-во этажей",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.countFloors?.range ?? null,
      }}
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      autoPosition
      wrapperClass={wrapperClass}
      filter={filter.squareLiving ?? { min: "", max: "" }}
      data={{
        title: 'S жилая',
        minPlaceholder: 'S жилая от ',
        maxPlaceholder: 'S жилая до ',
        defaultValues: filterData.squareLiving?.range ?? null
      }}
      onChange={({ filter }) => handleFilter('squareLiving', filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareLiving"}
      items={filterData.squareLiving?.items}
      showPlaceholderOnLists
      debounced
    // placeholderMes="сот."
    />
    <SelectFilter
      {...createSelectProps("countBathrooms")}
      data={{
        title: "Кол-во санузлов",
        identity: "countBathrooms",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("decorationHouse")}
      data={{
        title: "Отделка",
        identity: "decorationHouse",
      }}
      wrapperClass={wrapperClass}
    />

  </>
});

export default VillagesHouseFilter;
