import './index.scss';
import useMedia from '../../hooks/useMedia';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Public/markup/Header';
import DetailHeader from '../../components/Public/markup/detail/DetailHeader';
import InfoCard from '../../components/Public/markup/detail/InfoCard';
import Manager from '../../components/Public/markup/detail/Manager';
import Slider from '../../components/Public/markup/detail/Slider';
import PromoBar from '../../components/Public/markup/detail/PromoBar';
import DetailFilter from '../../components/Public/markup/detail/DetailFilter';
import About from '../../components/Public/markup/detail/About';
import Mortgage from '../../components/Public/markup/detail/Mortgage';
import PromoList from '../../components/Public/markup/detail/PromoList';
import Furnish from '../../components/Public/markup/detail/Furnish';
import VideoList from '../../components/Public/markup/detail/VideoList';
import useFilter from '../../hooks/useFilter';
import cloneDeep from 'lodash/cloneDeep';
import Tags from '../../components/Public/markup/Tags';
import PrimaryComplexFilterForm from '../../components/Public/markup/PrimaryComplexFilterForm';
import Preloader from '../../components/Preloader';
import { memo } from 'react';
import DetailList from '../../components/Public/markup/detail/DetailList/DetailList';
import { formatMoney, getPricePerMeter, scrollToRef } from '../../utils';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import { PhotoPopup } from '../../components/Viewer/PhotoSlider';
import { useRef } from 'react';
import SwiperSlider from '../../components/core/SwiperSlider';
import PrimaryPublicCard from '../../components/Public/markup/Containers/PrimaryPublicCard';
import { useEffect } from 'react';


// на дев2 больше всего данных тут (главное id ЖК): https://localhost:3000/public/apartments/734ecaab9cab09435190a3df6e8db34f/spb/complex/64768
const FilterDetail = memo(props => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');

  const [showMap, setShowMap] = useState(false);
  const [showGp, setShowGp] = useState(false);
  const openGeneralPlan = () => setShowGp(true)

  const actionsRef = useRef(null)
  const aboutBlockRef = useRef(null)

  const { id, city, savedFilterXmlId } = useParams();
  const isPublic = !!savedFilterXmlId

  const modifyRow = item => {
    const { objectType, id } = item;
    const isAssignment = objectType === "assignment";
    const links = {
      assignment: `/assignments/${id}${window.location.search}`,
      booking: `/flat-booking/${objectType}/${id}/${city}`,
      publicApartments: `/public/${savedFilterXmlId}/${city}/apartment/${id}`,
      apartments: `/realty/${city}/apartment/${id}${window.location.search}`,
    };
    item.costPerMeter = parseInt(getPricePerMeter(item.costWithDiscount, item.squareTotal));
    item.bookingLink = links.booking;
    item.link = links.apartments;
    if (isPublic) item.link = links.publicApartments;
    if (isAssignment) item.link = links.assignment;
    return item;
  }
  const extractSubItems = (setData, resp) => {
    const building = resp.apartments?.[0]
    const type = building?.types?.[0]
    setData(prev => {
      const currentBuildingIndex = prev.apartments.findIndex(tmpBuilding => tmpBuilding?.buildingId === building?.buildingId);
      if (currentBuildingIndex === -1) return prev
      const tmp = cloneDeep(prev);
      const currentTypeIndex = tmp.apartments[currentBuildingIndex].types.findIndex(tmpType => tmpType.roomTypeId === type.roomTypeId);
      tmp.apartments[currentBuildingIndex].types[currentTypeIndex] = type;
      tmp.apartments[currentBuildingIndex].types[currentTypeIndex].items.map(modifyRow)
      return tmp;
    })
  }
  const {
    handleFilter,
    isLoading,
    filter = {},
    data: { // вся инфа тут
      block = {},
      filter: filterData = {},
      blockInfo,
      agent,
      client,
      isActive,
      isShowFilter,
      managerInfo,
      otherBlocks,
      savedFilter,
      apartments,
      builderActions,
      blockVideos,
      savedFilterSettings
    },
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    removeBadge,
    resetFilter,
    loadSubItems,
  } = useFilter({  //TO DO: вынести конфиги фильтров отдельно
    apiConfig: {
      component: "panpartner:block.detail.ajax",
      submitFunction: "getBlock",
      loadSubItems: "getApartments"
    },
    defaultFilter: {
      status: [1],
      blockId: id,
    },
    defaultParams: {
      isGetApartments: true,
      isAssignment: true,
      onlyTypes: true,
      city,
      id,
      savedFilterXmlId
    },
    shadowFilters: ["blockId", "status"],
    fields: {
      buildings: [],
      blocks: [],
      status: [],
      decoration: [],
      rooms: [],
      endings: {},
      prices: {},
      isOnlyHanded: ""
    },
    isGetFilter: true,
    refreshWhenFilterChange: true,
    extractSubItems,
    filterFromLS: JSON.parse(localStorage.getItem("blocksFilter"))?.[id],
  })

  const decorationSliders = useMemo(() => {
    const { finishing, finishingPreReady, finishingReady } = block;
    return [{ title: "Чистовая", items: finishingReady }, { title: "Предчистовая", items: finishingPreReady }, { title: "Черновая", items: finishing }].filter(item => !!item?.items?.length)
  }, [block])

  useEffect(() => () => localStorage.removeItem("blocksFilter"), []);

  if (isLoading) return <Preloader loading />
  const cols = [{
    title: "Цена при 100% оплате",
    value: `от ${formatMoney(block.apartmentsInfo?.minPrice)} р.`
  },
  {
    title: "Кол-во квартир",
    value: block.apartmentsInfo?.count
  },]
  const scrollToActions = () => scrollToRef(actionsRef, -aboutBlockRef.current?.offsetTop)

  return (
    <div className="sf wrapper">
      {!!agent && <Header {...agent} savedFilterSettings={savedFilterSettings} />}
      <DetailHeader title={`ЖК «${block.name}»`} description={block.address} subways={block.subway} />
      <div className="sf__detail-row">
        <div className="sf__detail-col">
          {!isTablet && !!block.photos.complex?.length && <Slider items={block.photos.complex} openGeneralPlan={openGeneralPlan} showButton />}
          {!!builderActions?.length && <PromoBar {...builderActions[0]} count={builderActions?.length} onClick={scrollToActions} />}
        </div>
        <div className="sf__detail-col">
          {!isTablet && <InfoCard items={blockInfo} cols={cols} />}
          <Manager {...managerInfo} />
        </div>
      </div>
      <section className="sf__detail-filter">
        <h3 className="h3 sf__detail-h3">Планировки и цены</h3>
        <div className="sf__detail-filter-block">
          <PrimaryComplexFilterForm
            filterData={filterData}
            filter={filter}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            isLoading={isLoading}
          />
        </div>
      </section>

      <Tags badges={filterBadges} removeBadge={removeBadge} resetFilter={resetFilter} />
      <DetailList items={apartments} loadItems={loadSubItems} showSkeleton={showSkeleton} />

      <div className="sf__detail-row" ref={aboutBlockRef}>
        <div className="sf__detail-col">
          <About title={`О ЖК ${block.name}`} text={block.about?.previewText} advantages={block.about?.advantages} />
          {isLaptop && <Mortgage />}
          <Furnish items={decorationSliders} />
          <PromoList items={builderActions} innerRef={actionsRef} />
          <VideoList items={blockVideos} />
        </div>
        {!isLaptop && (
          <div className="sf__detail-col sf__sticky">
            <Mortgage />
          </div>
        )}

      </div>
      {!!otherBlocks?.blocks?.length && <h3 className="h3 sf__detail-h3">Другие ЖК застройщика</h3>}
      {!!otherBlocks?.blocks?.length && <SwiperSlider
        items={otherBlocks?.blocks}
        wrap={(item) => (
          <div className="sf-slide" style={{ marginRight: 16 }}>
            <PrimaryPublicCard {...item} link={`/public/apartments/${savedFilterXmlId}/${city}/complex/${item.id}`} />
          </div>
        )}
        navigation={false}
        options={{
          slidesPerView: 3,
          breakpoints: {
            767: {
              slidesPerView: 2,
              pagination: false,
            },
            1279: {
              slidesPerView: 3,
              pagination: false,
            },
          },
        }}
      />}

      <PhotoPopup
        open={showGp}
        items={block.generalPlan}
        index={0}
        onClose={() => setShowGp(false)}

      />
    </div>
  );
});

export default FilterDetail
