import { uniq, uniqBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Avatar from "react-avatar";
import { DebounceInput } from "react-debounce-input";
import useApiManager from "../../hooks/useApiManager";
import useDebounceState from "../../hooks/useDebounceState";
import { modyficateKeys, months, parseDate } from "../../utils";
import SearchInp from "../core/SearchInput";
import StatelessTabs from "../core/Tabs/StatelessTabs";
import {
  ChatHeaderMoreIcon,
  ChatHeaderSearchIcon,
  ChatSearchBottomArrowIcon,
  ChatSearchTopArrowIcon
} from "../Public/markup/icons";
import { errorToast } from "../toasts";
import ChatInfo from './ChatInfo';
import ChatItem from "./ChatItem";
import Message from "./ChatMessage";
import { ChatBtn, ChatFooter, CustomSelect, generateHtmlMessage, itemsByTab, mapStateByMethod, parseZulipMessage, tabs } from './Components';
import SaveImage from './floppy.png';
import Messages from "./Messages";
import './index.scss';

const Chat = () => {

  const {
    registerQueue,
    getProfile,
    getUsersList,
    getChannels,
    getMessages,
    unregisterQueue,
    updatePresence,
    sendMessage,
    getEvents,
    updateMessagesFlags,
    uploadFile,
    setTyping,
    deleteMessage,
    updateMessage,

    state,
    setValue,
    reset,
    setValues,
    dispatch,
    api,
    stateRef
  } = useApiManager([
    { action: "registerQueue", method: "GET" },
    { action: "getProfile" },
    { action: "getUsersList" },
    { action: "getChannels" },
    { action: "getMessages" },
    { action: "unregisterQueue" },
    { action: "updatePresence" },
    { action: "sendMessage" },
    { action: "getEvents", method: "GET" },
    { action: "updateMessagesFlags" },
    { action: "uploadFile" },
    { action: "setTyping" },
    { action: "deleteMessage" },
    { action: "updateMessage" },
  ], {
    defaultPayload: { c: 'panpartner:chat.ajax' },
    initialState: {
      isChatOpen: false,
      activeTab: 0,
      currentChat: null,// объект со всей информацией о чате
      showChatInfo: false,
      loadingState: [],
      messagesDump: {},
      lastEventId: null,
      presences: {},
      filterMessages: "all",
      showAddList: false,
      selectedChats: [],
      forwardedMessage: null,
      reply: null,
      allMessages: []
    }
  })

  const {
    activeTab,
    isChatOpen,
    currentChat,
    showChatInfo,
    loadingState,
    user,
    members,
    streams,
    messagesDump,
    queueId,
    lastEventId,
    searchValue,
    chatSearchValue,
    searchResult,
    chatSearchResult,
    presences,
    scrollMessage,
    filterMessages,
    showAddList,
    selectedChats,
    forwardedMessage,
    reply,
    allMessages
  } = state;

  const observerRef = useRef(null);
  const inputRef = useRef(null)
  stateRef.current = state;

  const scrollToDown = () => document.querySelector(".open-chat__overfield")?.scrollTo?.(0, 99999);

  const getLastMessage = (chat, messages = allMessages) => [...messages].reverse().find(message => {
    if (chat.stream_id) return message.stream_id === chat.stream_id
    return message?.type === "private" && message.display_recipient?.some?.(rec => rec.id === chat.user_id)
  })

  const changeMessage = (messageId, newValues = {}, deleteMessage = false) => {

    const messageIndex = state.allMessages.findIndex(message => message.id === messageId);

    const currentMessage = [...state.allMessages][messageIndex];

    if (!currentMessage) return;


    let messages = [...state.allMessages];

    messages[messageIndex] = { ...currentMessage, ...newValues };

    if (deleteMessage) messages = allMessages.filter(message => message.id !== messageId);

    setValues({
      allMessages: messages,
      currentChat: { ...currentChat, lastMessage: [...messages].reverse().find(message => message.type === "private" && message.display_recipient.some(rec => rec.id === currentChat.id)) }
    });
  }


  console.log('state :>> ', state);
  const deleteMsg = id => {

    changeMessage(id, { process: "delete" })

    deleteMessage({ "params[message_id]": id }).then(({ resp }) => {
      const deleteResult = resp.deleteResult
      if (deleteResult?.result === "error") return changeMessage(id, { error: deleteResult?.msg })

      changeMessage(id, {}, true)
    })
  }

  const forwardMessage = () => {
    if (!selectedChats?.length) return setValues({ selectedChats: [], forwardedMessage: null })
    const isSaved = currentChat.email === user.email
    const content = `@_**${isSaved ? user.full_name : currentChat.name ?? currentChat.full_name}|${currentChat.id}** [писал/а](https://zulip.bars-mc.ru/#narrow/dm/${currentChat.id}-dm/near/${forwardedMessage.id}):
      \`\`\`quote
      ${forwardedMessage.content}
      \`\`\``

    sendMessage({
      ...modyficateKeys({
        type: activeTab === 0 ? "private" : "stream",
        content: content.trim(),
        read_by_sender: true
      }, "]", "params["),
      ...selectedChats.reduce((acc, current, index) => {
        acc[`params[to][${index}]`] = current.user_id ?? current.email
        acc[`params[reply_to][${index}]`] = current.email
        return acc
      }, {}),
      // [activeTab === 1 ? "params[to][0]" : "params[to]"]: activeTab === 1 ? currentChat.name : currentChat.email,
      requestID: `sendMessage-${Date.now()}`,
    })

      .then(({ resp }) => {

        setValues({
          reply: null,
          selectedChats: [],
          forwardedMessage: null,
          members: members.map(member => { // channels отдельно
            if (!selectedChats.some(({ chat_id }) => member.id === chat_id)) return member
            const tmp = { ...member }
            tmp.messages = [...tmp.messages, {
              id: resp.sendResult?.id,
              content: `<p>
          <span class=\"user-mention silent\" data-user-id=\"11\">${isSaved ? user.full_name : currentChat.name ?? currentChat.full_name}|${currentChat.id}</span> 
          <a href=\"/#narrow/dm/${currentChat.id}-dm/near/${reply.id}\">писал/а</a>:<br>\n<code>${forwardedMessage.content}\n</code></p>`,
              content_type: "text/html",
              timestamp: `${Date.now()}`.slice(0, -3),
              sender_id: user.user_id,
              flags: ["read"]
            }]
            return tmp
          })
        });

        scrollToDown()

      })
  }

  const focus = () => inputRef.current?.focus?.()

  useEffect(() => {
    focus()
  }, [currentChat, reply])

  const replyMessage = () => {

    if (!reply) return
    const content = `<div class="reply" message-id="${reply.id}">${currentChat.isSavedMessage ? "Вы писали:" : `${reply?.sender_full_name ?? currentChat.full_name} писал/а:`} 
    <br/>
    <div class="reply-content"> ${reply.content} </div>
    </div>
      ${messagesDump?.[currentChat?.requestID] ?? ""}
    `

    send(content, {
      ...modyficateKeys({
        type: activeTab === 0 ? "private" : "stream",
        content: content.trim(),
        read_by_sender: true
      }, "]", "params["),
      ...[currentChat].reduce((acc, current, index) => {
        acc[`params[to][${index}]`] = current.user_id ?? current.email
        acc[`params[reply_to][${index}]`] = current.email
        return acc
      }, {}),
      [activeTab === 1 ? "params[to][0]" : "params[to]"]: activeTab === 1 ? currentChat.name : currentChat.email,
      [activeTab === 1 ? "params[reply_to][0]" : "params[reply_to]"]: activeTab === 1 ? currentChat.name : currentChat.email,
      requestID: `sendMessage-${Date.now()}`,
    })
  }

  const readMessages = (messages) => {
    if (!messages?.length) return
    updateMessagesFlags({
      ...messages.reduce((acc, v, i) => ({ ...acc, [`messages[${i}]`]: v }), {}),
      "op": "add",
      "flag": "read",
    }).then(resp => {
      setValues({
        currentChat: {
          ...stateRef.current.currentChat, messages: stateRef.current.currentChat.messages.map(message => {
            if (messages.includes(message.id)) return { ...message, flags: message.flags ? [...message.flags, "read"] : ["read"] }
            return message
          }),
        },
        allMessages: stateRef.current.allMessages.map(message => {
          if (messages.includes(message.id)) return { ...message, flags: message.flags ? [...message.flags, "read"] : ["read"] }
          return message
        })
      })
      setDebouncedValues([])
    })
  }

  const [readedMessages, setReadedMessages, setDebouncedValues] = useDebounceState({
    setState: (prev, ...args) => (uniq([...prev, +args[0].id.replace("message-", "")])),
    fn: (message, prev) => readMessages(uniq([...prev, +message.id.replace("message-", "")])),
    defaultState: [],
  })

  const scrollToMessage = ({ currentChat, id }) => {
    if (!id) return
    if (currentChat) setValue("currentChat", currentChat)
    const chatElem = document.querySelector(".open-chat__overfield");
    const messageElem = document.getElementById(id);
    chatElem.scrollTo({
      top: messageElem?.offsetTop,
    });
  }

  const send = (messageContent, payload) => {

    const content = typeof messageContent === "string" ? messageContent : messagesDump[currentChat?.requestID];

    if (!content || !currentChat) return;

    setValues({
      messagesDump: { ...messagesDump, [currentChat?.requestID]: "" },
    });

    const tmpMessage = {
      id: Date.now(),
      content,
      timestamp: `${Date.now()}`.slice(0, -3),
      sender_id: user.user_id,
      sender_email: user.email,
      display_recipient: uniqBy([currentChat, user], "email"),
      process: "send",
      type: currentChat.stream_id ? "stream" : "private"

    }

    const tmpMessages = [...allMessages, tmpMessage];


    setValues({
      messagesDump: { ...messagesDump, [currentChat?.requestID]: "" },
      currentChat: { ...currentChat, lastMessage: tmpMessage },
      allMessages: tmpMessages,
      reply: null,
      selectedChats: [],
      forwardedMessage: null,
    });

    setTimeout(() => scrollToDown())

    return sendMessage(payload ?? {
      ...modyficateKeys({
        type: activeTab === 0 ? "private" : "stream",
        content: content.replace(/!\[/g, "[").trim(),
        read_by_sender: true
      }, "]", "params["),
      [activeTab === 1 ? "params[to][0]" : "params[to]"]: activeTab === 1 ? currentChat.stream_id : currentChat.email,
      requestID: `sendMessage-${Date.now()}`,
    })

  }

  // useEffect(() => {
  //   if (!lastEventId && lastEventId !== 0) return;

  //   const payload = modyficateKeys({
  //     queue_id: queueId,
  //     last_event_id: lastEventId,
  //     dont_block: false,
  //     slim_presence: true
  //   }, "]", "params[");

  //   getEvents(payload).then(({ resp }) => {
  //     const { events } = resp;
  //     const lastEvent = events?.events?.[events?.events?.length - 1]
  //     if (events?.result === "error" && ["BAD_REQUEST", "BAD_EVENT_QUEUE_ID"].includes(events.code)) {
  //       registerQueue().then(({ resp }) => setValues(mapStateByMethod.registerQueue(resp)))
  //       return
  //     }
  //     const tmpState = {
  //       lastEventId: lastEvent?.id,
  //       members: stateRef.current.members,
  //       streams: stateRef.current.streams,
  //       presences: stateRef.current.presences,
  //       currentChat: stateRef.current.currentChat,
  //       allMessages: stateRef.current.allMessages
  //     }

  //     if (events.events?.length) {
  //       const actions = {
  //         presence: v => {
  //           tmpState.presences[v.email] = { ...(tmpState.presences[v.email] || []), aggregated: v.presence["website"] }
  //         },
  //         message: ({ message, flags: newFlags }) => {
  //           const isCurrentChatMessage = message.display_recipient?.some( rec=> rec.email === currentChat.email)

  //           const isMyMessage = message.sender_email === user.email;

  //           const flags = newFlags ? [...newFlags, ...(isMyMessage ? ["read"] : [])] : isMyMessage ? ["read"] : [];


  //           const messageIndex = tmpState.allMessages.findIndex(msg=>msg.id === message.id) 
  //           tmpState.allMessages[messageIndex] = { ...message, flags }
  //           tmpState.allMessages = uniqBy([...tmpState.allMessages ].sort((a, b) => a.id - b.id), "id");

  //           if(isCurrentChatMessage) setTimeout(()=>scrollToDown(), 100)
  //         },
  //         delete_message: ({ message_id, message_type }) => {

  //           tmpState.allMessages = allMessages.filter(message => message.id !== message_id)

  //         },
  //         typing: ({ sender, op, message_type }) => {
  //           if (message_type === "direct") {
  //             if (currentChat?.email === sender?.email) tmpState.currentChat = {
  //               ...tmpState.currentChat,
  //               isTyping: op === "start",
  //             }

  //             const findedChatIndex = tmpState.members.findIndex(member => member.email === sender.email)

  //             if (findedChatIndex >= 0) tmpState.members[findedChatIndex].isTyping = op === "start"
  //           }
  //         }
  //       }

  //       events.events.forEach(e => actions[e.type]?.(e));
  //     }

  //     setValues(tmpState)
  //   })

  // }, [lastEventId])

  const presence = (status = "active") => {
    updatePresence({ "params[status]": status }).then(({ resp }) => {
      setValues(mapStateByMethod.updatePresence(resp))
      setTimeout(() => {
        presence(status)
      }, 30000)
    },
      err => {
        if (err?.[0]?.message) errorToast(err?.[0]?.message);
        setTimeout(() => {
          presence(status)
        }, 30000)

      }
    );
  }
  const searchMessages = (searchValue, type) => {
    setValues(type === "chat" ? { chatSearchValue: searchValue } : { searchValue })
    if (!searchValue) return setValues({
      isSearchOpen: false,
      chatSearchResult: false,
      searchResult: false
    });

    getMessages({
      ...modyficateKeys({
        "anchor": "newest",
        "num_before": 1000,
        "num_after": 0,
        "apply_markdown": "true"
      }, "]", "params["),
      ...(type === "chat" ? {
        "params[narrow][0][operator]": activeTab === 1 ? "channel" : "dm",
        "params[narrow][0][operand]": activeTab === 1 ? currentChat.name : currentChat.email,
        "params[narrow][1][operator]": "search",
        "params[narrow][1][operand]": searchValue
      } : []),
      ...(type === "list" ? {
        "params[narrow][0][operator]": "search",
        "params[narrow][0][operand]": searchValue,
      } : []),

    })
      .then(resp => setValues(mapStateByMethod.getMessages(resp, { requestID: `search-${type}` }, stateRef.current)))

  }

  const beforeunload = e => {
    unregisterQueue({ queue_id: state.queueId }).then(resp => setValues({ queueId: null, lastEventId: null }));

  }
  console.log('streams :>> ', streams);
  useEffect(() => {
    getChannels()
      .then((resp) => {
        setValues(mapStateByMethod.getChannels(resp, {}, stateRef.current))
      })

    // Promise.all([
    //   registerQueue(),
    //   getProfile(),
    //   getUsersList(),
    // ]).then(arr => {
    //   setValues(arr.reduce((acc, curr) => ({ ...acc, ...mapStateByMethod[curr.config[1]](curr.resp) }), {}))
    // })

    window.addEventListener("beforeunload", beforeunload);
    return () => {
      window.removeEventListener("beforeunload", beforeunload);
      beforeunload()

    }

  }, [])

  useEffect(() => {
    if (!isChatOpen) {
      const timer = setTimeout(() => setIsAnimating(false), 700)
      return () => clearTimeout(timer)
    }

    setIsAnimating(true);
    // presence()
    // return () => updatePresence({ "params[status]": "idle" })
  }, [isChatOpen])


  const chatRequestId = currentChat?.requestID

  useEffect(() => {
    if (!chatRequestId) return
    console.log('dialogId: currentChat.chatId || currentChat.id  :>> ', currentChat);
    getMessages({ dialogId: currentChat.chatId || currentChat.id }, `getMessages-${chatRequestId}`).then((resp) => {
      setValues(mapStateByMethod.getMessages(resp, { requestID: chatRequestId }, stateRef.current))
      scrollToMessage({ id: scrollMessage })
    })

    var callback = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) setReadedMessages(entry.target)

      });
    };
    observerRef.current = new IntersectionObserver(callback, {
      root: document.querySelector(".open-chat__overfield"),
      rootMargin: "0px",
      threshold: 1.0,
    });

  }, [chatRequestId])

  const chatList = useMemo(() => {

    const items = state.members || []

    if (searchValue && searchResult) return searchResult.map(message => {
      const isSelfMessage = !(message.display_recipient?.length === 2 && message.type === "private") && (message.display_recipient?.length === 1 && message.sender_email === user.email)
      const findedChat = [...members, ...streams].find(item => item.type === "stream" ? item.stream_id === message.stream_id : !isSelfMessage ? message.display_recipient[1].email : item.email === message.sender_email)
      const chatItem = findedChat ? { ...findedChat } : { ...message }
      const otherUser = message.display_recipient.find(item => item.email !== user.email)
      chatItem.messages = chatItem.messages ? uniqBy([...chatItem.messages, message].sort((a, b) => a.id - b.id), "id") : [message];
      chatItem.params = {
        ...modyficateKeys({
          num_before: 60,
          num_after: 150,
          anchor: message.id,
          client_gravatar: true,
          "apply_markdown": "true",
        }, "]", "params["),
        "params[narrow][0][operator]": chatItem.type === "stream" ? "channel" : "dm",
        "params[narrow][0][operand]": chatItem.type === "stream" ? message.stream_id : !isSelfMessage ? otherUser.email : chatItem.email, //streams  - "public"
        "params[narrow][1][operator]": "near",
        "params[narrow][1][operand]": message.id
      }
      chatItem.requestID = `${itemsByTab[activeTab]}-${chatItem.type === "stream" ? chatItem.stream_id : !isSelfMessage ? otherUser.id : chatItem.user_id}-search-${message.id}`
      chatItem.lastMessage = { ...message, scroll: true };

      if (isSelfMessage) {
        chatItem.avatar_url = SaveImage
        chatItem.full_name = "Сохранённое"
        chatItem.email = user.email
        chatItem.isSavedMessage = true
      }

      if (!isSelfMessage) {
        chatItem.avatar_url = null
        chatItem.full_name = otherUser.full_name
        chatItem.email = otherUser.email
      }



      return chatItem
    })

    return items && items.map(item => ({
      ...item,
      messages: item.message ?? [],
      requestID: `${itemsByTab[activeTab]}-${item.id}`,
      lastMessage: getLastMessage(item),

    }))
      .filter(item => {

        if (item.type === "chat") return activeTab === 1
        return activeTab === 0
      })
  }, [state, activeTab])
  console.log('item :>> ', chatList);
  useEffect(() => {//del и убрать кнопку из App
    window.addEventListener('load', () => window.BX?.SiteButton.hide());
  }, [])

  const [isAnimating, setIsAnimating] = useState(false);

  const messagesList = useMemo(() => {
    if (!currentChat) return []
    return [...allMessages].filter(({ chat_id }) => currentChat.chat_id === chat_id ).sort((a, b) => a.id - b.id)
  }, [allMessages, currentChat])

  const showChatWindow = isChatOpen || isAnimating;

  return (
    <>
      {showChatWindow && <div className={`overchat ${isChatOpen ? 'active' : 'hide'}`}>
        <div className={`chat ${isChatOpen ? 'active' : 'hide'}`}>
          <div className="chat__close-chat" onClick={() => {
            setValues({
              isChatOpen: false,
              currentChat: null,
              showChatInfo: false,
              isSearchOpen: false,
              chatSearchResult: false,
              searchResult: false,
            })
          }
          }>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="white" stroke-linecap="round" /><path d="M1 11L6 6L11 11" stroke="white" stroke-linecap="round" /></svg>
          </div>
          <div className="chat__left">
            <div className="chat__search">
              <SearchInp
                value={searchValue ?? ""}
                isSearching={loadingState.includes("search-list")}
                placeholder="Поиск"
                searchIcon
                onChange={e => searchMessages(e.target.value, "list")}
                reset={() => searchMessages("", "list")}

                renderInput={props => <DebounceInput
                  minLength={1}
                  debounceTimeout={500}
                  autoComplete="off"
                  className="section-search__inp"
                  {...props}
                />}
              />
            </div>

            <div className="chat__tabs">
              <StatelessTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={v => setValue("activeTab", v)}
                isInline
              />
            </div>

            <div className="chat__sort">
              <div className="chat__sort-select">
                <CustomSelect
                  options={[
                    { id: "all", label: 'Все' },
                    { id: "read", label: 'Непрочитанные' },
                    { id: "active", label: 'Активные' },
                  ]}
                  onChange={({ id }) => setValue("filterMessages", id)}
                />
              </div>
              {!!forwardedMessage && <div className={'chat__sort-edit chat__sort-edit_sort'} onClick={() => setValues({
                selectedChats: [],
                forwardedMessage: null
              })}>
                Отмена
              </div>}
              {!!forwardedMessage && <div className={'chat__sort-edit chat__sort-edit_sort'} onClick={forwardMessage}>Готово</div>}
            </div>
            {loadingState.includes("search-list") && <div className="chat__empty-text">Поиск...</div>}
            {!!searchValue && !loadingState.includes("search-list") && !chatList?.length && <div className="chat__empty-text">Ничего не найдено</div>}
            {!loadingState.includes("search-list") && <ul className="chats-list">
              {chatList && chatList.map((item) => {

                const { textContent, images, lastImage, scroll } = parseZulipMessage(item.lastMessage || { content_type: "text/html", content: item.description, id: 0, })
              const shotIsTypingStatus = item.isTyping && !item.requestID.includes("search-") && !item.isSavedMessage
              return <ChatItem
                userName={item.title}
                // isFile="Секрктный документ.pdf"
                lastMessageTime={shotIsTypingStatus ? false : !!item.message.date && parseDate(item.message.date, false, true).formattedTime}
                sortered={!!forwardedMessage}
                selected={selectedChats.some(selected => item.email === selected.email)}
                selectItem={() => setValues({ selectedChats: selectedChats.some(selected => item.email === selected.email) ? selectedChats.filter(v => v.email !== item.email) : [...selectedChats, item] })}
                logo={item.avatar?.url}
                isActive={(!scroll && currentChat?.requestID === item.requestID) || scrollMessage === `message-${item.id}`}
                message={shotIsTypingStatus ? `${currentChat.full_name} пишет...` : item.message.text}
                isImage={lastImage}
                images={images}
                onClick={() => {
                  if (currentChat?.id === item.id) return
                  if (item.requestID.includes("search-") && currentChat?.id === item.id) {
                    setValues({ scrollMessage: scroll ? `message-${item.id}` : null, })
                    return scrollToMessage({ id: `message-${item.id}` })
                  }
                  if (forwardedMessage) return
                  setValues({ currentChat: item, chatSearchResult: false, scrollMessage: scroll ? `message-${item.id}` : null, reply: null })
                }}
                key={item.requestID}
                id={item.id}
                isOnline={item?.email !== user?.email && presences?.[item.email]?.aggregated?.status === "active"}
                unreadMessages={!!item.messages?.length && item.messages.reduce((acc, current) => !current.flags?.includes?.("read") ? acc + 1 : acc, 0)}
              />
              }
              )}

            </ul>}

          </div>
          <div className="chat__right" style={{ filter: !forwardedMessage ? "none" : `grayscale(90%) blur(1px)` }}>
            {!!currentChat && <div className="open-chat">
              <div className="open-chat__header">
                <div className="open-chat__header-left">
                  <div className="open-chat__user">
                    <div className="open-chat__user-logo">
                      <Avatar
                        name={currentChat.full_name ?? currentChat.name}
                        size="48"
                        round={true}
                        textSizeRatio={2.857}
                      />
                      <div className="open-chat__user-status open-chat__user-status_active" />
                    </div>
                    <div className="open-chat__user-info">
                      <p className="open-chat__user-name">{currentChat.full_name ?? currentChat.name}</p>
                      {currentChat.email && <p className="open-chat__user-post">{currentChat.email}</p>}
                      {currentChat.description && <p className="open-chat__user-post">{currentChat.description}</p>}
                      {/* <p className="open-chat__user-post">Ваш личный менеджер</p> */}
                    </div>
                  </div>
                </div>
                <div className="open-chat__header-right">
                  <span className={`open-chat__search-btn ${currentChat?.isSearchOpen ? 'open-chat__search-btn_active' : ''}`}
                    onClick={() => {
                      setValues({
                        currentChat: { ...currentChat, isSearchOpen: !currentChat.isSearchOpen }
                      })
                      if (chatSearchValue) searchMessages(chatSearchValue, "chat")
                    }}>
                    <ChatHeaderSearchIcon />
                  </span>
                  <span className="open-chat__header-actionbtn" onClick={() => {
                    setValues({
                      showChatInfo: true,
                      currentChat: { ...currentChat, isSearchOpen: false }
                    })
                  }}>
                    <ChatHeaderMoreIcon />
                  </span>
                </div>
              </div>

              {currentChat?.isSearchOpen &&
                <div className="open-chat__search">
                  <div className="open-chat__search-area">
                    <div className="open-chat__search-arrows">
                      <span><ChatSearchBottomArrowIcon /></span>
                      <span><ChatSearchTopArrowIcon /></span>
                    </div>
                    <div className="open-chat__search-inp">
                      <SearchInp
                        value={chatSearchValue ?? ""}
                        isSearching={loadingState.includes("search-chat")}
                        placeholder="Поиск"
                        searchIcon
                        onChange={e => searchMessages(e.target.value, "chat")}
                        reset={() => searchMessages("", "chat")}

                        renderInput={props => <DebounceInput
                          minLength={1}
                          debounceTimeout={500}
                          autoComplete="off"
                          className="section-search__inp"
                          {...props}
                        />}
                      />
                    </div>
                    {false && searchValue !== '' &&
                      <div className="open-chat__search-count">1/5</div>
                    }

                  </div>

                  {!!chatSearchResult?.length && <div className="open-chat__search-list">
                    <ul className="chats-list chats-list_search">
                      {chatSearchResult.map(item => <ChatItem
                        userName={item.sender_full_name ?? item.name}
                        // unreadMessages='4'
                        // isFile="Секрктный документ.pdf"
                        lastMessageTime={!!item.timestamp && parseDate(new Date(+`${item.timestamp}000`), false, true).formattedTime}
                        sortered={!!forwardedMessage}
                        logo={item.avatar_url}
                        // isActive={currentChat?.requestID === item.requestID}
                        message={item.match_content}
                        // isImage={{ src: y, text: 'funnypicture.jpg' }}
                        onClick={() => {
                          getMessages({
                            ...modyficateKeys({
                              num_before: 60,
                              num_after: 150,
                              anchor: item.id,
                              "apply_markdown": "true",
                              client_gravatar: true
                            }, "]", "params["),
                            "params[narrow][0][operator]": currentChat.type === "stream" ? "channel" : "dm",
                            "params[narrow][0][operand]": currentChat.type === "stream" ? item.stream_id : currentChat.email, //streams  - "public"
                            "params[narrow][1][operator]": "near",
                            "params[narrow][1][operand]": item.id,
                            requestID: chatRequestId
                          }).then((resp) => {
                            setValues(mapStateByMethod.getMessages(resp, { requestID: chatRequestId }, stateRef.current))
                            setTimeout(() => scrollToMessage({ id: `message-${item.id}` }))
                          })
                        }}

                      />)}
                    </ul>
                  </div>}
                </div>
              }
              {!currentChat && <div className="chat__empty-text">Пожалуйста, выберите чат</div>}
              {currentChat && !messagesList?.length && !loadingState.includes(currentChat.requestID) && !loadingState.includes(`getMessages-${currentChat.requestID}`) && <div className="chat__empty-text">Сообщений пока нет</div>}
              {currentChat && !messagesList?.length && loadingState.includes(`getMessages-${currentChat.requestID}`) && <div className="chat__empty-text">Загрузка...</div>}
              {!!messagesList?.length && <Messages currentChat={stateRef.current.currentChat} setValues={setValues} key={currentChat?.user_id}>
                {messagesList.map((message, i) => {
                  const {
                    days,
                    month,
                    year,
                    formattedTime,
                  } = parseDate(new Date(message.date), false, true)

                  const { days: previousMessageDay, month: previousMessageMoth, year: previousMessageYear } = parseDate(messagesList[i === 0 ? i : i - 1].date, false, true)
                  const dateText = ((previousMessageDay != days && month !== previousMessageMoth && year !== previousMessageYear) || i === 0) && `${days} ${months[month].toLowerCase()} ${year}`

             
                  return <React.Fragment key={message.id}>
                    {dateText && <div className="open-chat__date">{dateText}</div>}
                    {message.author_id === 0 && <div className="open-chat__date">{message.text}</div> }
                    {message.author_id !== 0 && <Message
                      // image={y}
                      time={formattedTime}
                      // fromMe={user.user_id === message.sender_id}
                      {...message}
                      content={message.text}
                      // images={images}
                      observer={observerRef.current}
                      deleteMsg={() => deleteMsg(message.id)}
                      forwardMessage={() => setValue("forwardedMessage", message)}
                      replyMessage={() => setValue("reply", message)}
                      scrollToMessage={scrollToMessage}
                      message={message}
                    />}
                  </React.Fragment>
                })}

              </Messages>}
              {!!currentChat && <ChatFooter
                state={state}
                stateRef={stateRef}
                setValue={setValue}
                setValues={setValues}
                send={reply ? replyMessage : send}
                uploadFile={uploadFile}
                setTyping={setTyping}
                disabled={!!forwardedMessage}
                reply={reply}
                inputRef={inputRef}
              />}
            </div>}

            {showChatInfo && <ChatInfo
              close={() => setValue("showChatInfo", false)}
              {...currentChat}
            />}


          </div>
        </div>
      </div>}
      {members?.length && <ChatBtn onClick={() => setValue("isChatOpen", !isChatOpen)} />}
    </>
  )
}

export default Chat