import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import request from '../api';
import { actionFactory } from './actionUtils';

const meetingsSelector = (state) => state.meetings.data;
const filterSelector = (state) => state.meetings.filter;

export const filteredMeetings = createSelector(
  [meetingsSelector, filterSelector],
  (meetings, filter) => {
    let result = meetings;

    if (filter.agents.length) {
      result = result.filter((item) =>
        includes(filter.agents, item.agent?.id),
      );
    }

    if (filter.clients.length) {
      result = result.filter(item =>filter.clients.some(cl=> cl == item.client?.id))
    }

    if (filter.status.length) {
      result = result.filter(item =>includes(filter.status, item.status?.id));
    }

    if (filter.date.startDate) {
      result = result.filter((item) => item.date >= filter.date.startDate);
    }

    if (filter.date.endDate) {
      result = result.filter((item) => item.date <= filter.date.endDate);
    }

    return result;
  },
);

const requestMeetings = actionFactory("MEETINGS_REQUEST");

const successMeetings = actionFactory("MEETINGS_SUCCESS");

const failureMeetings = actionFactory("MEETINGS_FAILURE");

export const clearMeetings = actionFactory("MEETINGS_RESET");

export const setFilterMeetings = actionFactory("MEETINGS_SET_FILTER");

export const clearFilterMeetings = actionFactory("MEETINGS_CLEAR_FILTER");

export const fetchMeetings = city =>
  dispatch => {
    dispatch(requestMeetings());
    return request("panpartner:meeting.rooms.ajax", "getEventMeetings", { city })
      .then(
        data => {
          const { events, meetingRooms, filterData } = handleFilter(data);
          dispatch(successMeetings({ data: events, filterData, meetingRooms }));
        },
        error => {
          dispatch(failureMeetings());
          return Promise.reject(error);
        },
      );
  };

export const removeEventMeeting = (id, comment, city) =>
  dispatch =>
    request("panpartner:meeting.rooms.ajax", "removeEventMeeting", { id, comment, city })
      .then(
        data => {
          dispatch(fetchMeetings());
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureMeetings());
          return Promise.reject(error);
        },
      );

const handleFilter = ({ filter, events, meetingRooms }) => {
  let items = [];

  events.forEach((event) => {
    let iDate = new Date(event.date).setHours(0, 0, 0, 0);

    items.push({
      ...event,
      date: iDate,
    });
  });

  let minMax = {
    min: new Date(filter.date.range.min),
    max: new Date(filter.date.range.max),
  };

  return {
    filterData: {
      agents: filter.agent,
      clients: filter.client,
      status: filter.status,
      date: minMax,
    },
    events: items,
    meetingRooms,
  };
};