import React from 'react';
import useMedia from '../../hooks/useMedia';
import Advantages from './new/Advantages';
import Comment from './new/Comment';
import ComplexAbout from './new/ComplexAbout';
import ComplexInfo from './new/ComplexInfo';
import FirstScreen from './new/FirstScreen';
import FloorPlan from './new/FloorPlan';
import Images from './new/Images';
import ImagesGrid from './new/ImagesGrid';
import Info from './new/Info';
import Infrastructure from './new/Infrastructure';
import Mortgage from './new/Mortgage';
import Note from './new/Note';
import Prices from './new/Prices';
import SingleImage from './new/SingleImage';
import Title from './new/Title';
import PagePDF from './PagePDF';
import PresentationControls from './PresentationControls';
import Sertificate from './Sertificate';

// import Complex from './Complex';
// import Sertificate from './Sertificate';
// import Infrastructure from './Infrastructure';
// import Furnish from './Furnish';
// import Showroom from './Showroom';

const PresentationContent = (props) => {
  const {
    data,
    settings,
    formData,
    fetchClients,
  } = props;
  const isSuburban = data.typeXmlId === "suburban";
  const isParcel = data?.object?.type?.xmlId === "parcel";
  const isCommercial = data.typeXmlId === "commercial";
  const isSecondary = data.typeXmlId === "secondary";

  const isMobile = useMedia("isMobile");
  const activeSettings = () => {
    if (!formData) return {};

    return formData.settings;
  };


  if (!data) return null;

  const isBlockNameActive = !!formData?.settings?.complex_name;

  return (
    <div className="presentation__content">
      {/*{!isMobile && <PresentationControls {...props} />}*/}
      <div className="pdf">
        <div className="pdf-body">
          <PagePDF data={data} activeSettings={formData?.settings || {}}>
            <Title
              data={data}
              activeSettings={formData?.settings || {}}
              isCommercial={isCommercial}
            />
            <FirstScreen
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
              isCommercial={isCommercial}
            />
            {!isParcel && <Comment
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />}
            {/* {isParcel && data.object?.photosUrl?.length > 1 ? <ImagesGrid
              imgs={data.object?.photosUrl}
              wrapperStyle={{ marginTop: 60 }}
              dataId="object_photos"
            /> : <SingleImage
              src={data.object?.photosUrl?.[0]?.src}
              dataId="object_photos"
            />} */}
            {isParcel && <Comment
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />}
            <Info
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
              isParcel={isParcel}
            />
            {!isSecondary && <Note
              title="Примечания"
              text={data?.object?.note}
            />}
            {isSecondary && <ImagesGrid
              title={`Фотографии объекта`}
              imgs={data.object?.photos}
              dataId="none"
            />}
            {isSecondary && <Note
              text={data?.object?.description}
              title="Описание"
              isActive={!!formData?.settings?.note}
            />}
            {!isCommercial && data.object?.generalPlan?.empty !== true && <SingleImage
              title={`${data.object?.type?.title} на ген. плане`}
              src={data.object?.generalPlan?.src}
              dataId="on_genplan"

            />}
            {!isParcel && <ImagesGrid
              title={`Фотографии объекта`}
              imgs={data.object?.photosUrl}
              wrapperStyle={{ marginTop: 60 }}
              dataId="object_photos"
            />}

            <FloorPlan
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
              isCommercial={isCommercial}
            />
            {isCommercial && data.object?.generalPlan?.some?.(item=>!item.empty) && data.object?.generalPlan.map((item, i) => <SingleImage
              title={i === 0 ? "Ген. план" : null}
              src={item.src}
              dataId={"on_genplan"}
              key={i}
              className={"small-margin-block"}
            />)}
            {isCommercial && <SingleImage
              title="Направление вида из окон"
              src={data.object?.viewFromWindow?.src}
              dataId={"view_for_windows"}
            />}
            {!isParcel && <Images
              images={data.additionalPhotos}
              object={data.object}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />}
            <Prices
              data={data}
              dynamicPrice={data.object?.dynamicPrice}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />
            {isSuburban && <ComplexInfo
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />}
            <Mortgage
              mortgage={data.mortgage}
              objectName={isSuburban || isCommercial ?"объекта": "квартиры"}
            />
            {isParcel && <Images
              images={data.additionalPhotos}
              object={data.object}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />}
            <ImagesGrid
              title={formData?.settings?.complex_name ? `Фотографии ЖК «${data.object?.blockName}»` : `Фотографии ЖК`}
              imgs={data.object?.blockPhotos}
              dataId="main_photo"
            />
            <ImagesGrid
              title={activeSettings?.complex_name ? `Фотографии отделки ЖК «${data.object?.blockName}»` : `Фотографии отделки ЖК`}
              imgs={data.object?.decorationPhoto}
              dataId="layout_picture_ready"
            />
            {!isSuburban && <ComplexInfo
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
              isCommercial={isCommercial}
            />
            }
            <ComplexAbout
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
              isBlockNameActive={isBlockNameActive}
            />
            {!isSecondary && <Advantages data={data} activeSettings={formData?.settings || {}} />}
            <Infrastructure infrastructure={data.object?.infrastructure} />

            <Sertificate
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />
          </PagePDF>



          {/* <PagePDF data={data} activeSettings={formData?.settings || {}}>
            <Furnish
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />

          </PagePDF> */}
          {/* 
          <PagePDF data={data} activeSettings={formData?.settings || {}}>
            <Infrastructure
              data={data}
              settings={settings}
              activeSettings={formData?.settings || {}}
            />
          </PagePDF>

          {data.object.typical3dTour && (
            <PagePDF data={data} activeSettings={formData?.settings || {}}>
              <Showroom
                data={data}
                settings={settings}
                activeSettings={formData?.settings || {}}
              />
            </PagePDF>
          )}

          {data.object.agentCertificate && (
            <PagePDF data={data} activeSettings={formData?.settings || {}}>
              <Sertificate
                data={data}
                settings={settings}
                activeSettings={formData?.settings || {}}
              />
            </PagePDF> 
          )}*/}
        </div>
      </div>
    </div>
  );
};

export default PresentationContent;
