import React, { useEffect, useRef, useState } from "react";
import { useModal } from 'react-modal-hook';
import SimpleBar from "simplebar-react";
import useDropdown from "../../hooks/useDropdown";
import { media } from '../../utils';
import MobileModal from '../core/MobileModal';
import NewClientForm from "../forms/NewClient";
import { infoToast } from "../toasts";

const Dropdown = ({ value, options, onChange, disabled = false }) => {
  const [node, open, show, hide] = useDropdown(false);
  const [current, setCurrent] = useState(null);

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    hide();
  };

  useEffect(() => {
    let m = false;
    if (value) {
      m = options.find((item) => item.id == value);
    }
    setCurrent(m || options[0]);
  }, [value, options]);

  return (
    <div
      ref={node}
      className={`custom-select ${open ? `is-showing` : ``} ${
        disabled ? `is-disabled` : ``
      }`}
    >
      {current && (
        <>
          <div
            className="custom-select__selected card-heart-selected"
            onClick={(e) => {
              e.stopPropagation();
              disabled || open ? hide() : show();
            }}
          >
            {current.lastName + " " + current.name}
          </div>
          <SimpleBar className="custom-select__options">
            <ul>
              {options.map((item) => (
                <li
                  className={
                    "custom-select__option" +
                    (current.id === item.id ? " is-active" : "")
                  }
                  key={item.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange(item.id);
                  }}
                >
                  {item.lastName + " " + item.name}
                </li>
              ))}
            </ul>
          </SimpleBar>
        </>
      )}
    </div>
  );
};

const PopoverBlock = (props) => {
  const {
    type,
    id,
    favorite = {
      clients: []
    },
    city,
    allClients,
    addClientToFavorite,
    removeClientFromFavorite,
    fetchClients,
    whatPage,
    showOnLeft,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [popupIsOpened, setPopupIsOpened] = useState(false);
  const popoverRef = useRef(null);
  const popupStateRef = useRef(popupIsOpened);
  useEffect(() => {
    document.addEventListener("mousedown", outsideClickHandler);

    if (favorite?.clients?.length === 0) {
      setActiveTab(1);
    }
    if (allClients[0]) {
      setSelectedClient(allClients[0].id);
    }
    return () => {
      document.removeEventListener("mousedown", outsideClickHandler);
    };
  }, []);
  useEffect(() => {
    if (favorite?.clients?.length === 0) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [popupIsOpened]);

  useEffect(() => {
    if (selectedClient === 0 && allClients.length > 0) {
      setSelectedClient(allClients[0].id);
    }
  }, [allClients]);

  const closePopup = () => {
    if (popupStateRef) {
      if (favorite?.clients?.length === 0) {
        setPopupIsOpened(false);
        setActiveTab(1);
        return;
      }
      setPopupIsOpened(false);
      setActiveTab(0);
    }
  };
  const outsideClickHandler = (e) => {
    if (popoverRef && !popoverRef.current.contains(e.target) && popupStateRef) {
      closePopup();
    }
  };
  
  const goToNextTab = () => setActiveTab((prevState) => ++prevState);

  const goToPrevTab = () => setActiveTab((prevState) => --prevState);

  const [openMobileForm, closeMobileForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        fixedTitle={true}
        title="Прикрепить к своему клиенту"
        closeClick={closeMobileForm}
      >
        <div
          className="button-mobile is-add-client"
          onClick={(e) => {
            e.stopPropagation();
            openMobileAddForm()
          }}
        >
          <button className="button button_type_add"></button>
          <span>Добавить клиента</span>
        </div>

        <Dropdown
          options={allClients}
          value={selectedClient}
          onChange={setSelectedClient}
        />

        <div className="mobile-filters__show-all">
          <button
            className="button button_view_default"
            onClick={(e) => {
              e.stopPropagation();
              if (
                !favorite?.clients?.find(
                  (client) => client.id == selectedClient
                )
              ) {
                addClientToFavorite(
                  id,
                  selectedClient,
                  allClients.find(
                    (item) => item.id == selectedClient
                  ),
                  favorite.favoriteId,
                  whatPage,
                  type
                );
                setActiveTab(0);
              } else {
                infoToast(<div>Выбранный клиент уже в избранном</div>)
              }
              closeMobileForm()
            }}
          >
            Прикрепить
          </button>
        </div>
      </MobileModal>
    ),
    [
      allClients,
      selectedClient,
      setSelectedClient,
      favorite,
      id,
      whatPage,
      type,
      setActiveTab,
      addClientToFavorite
    ]
  );

  const [openMobileAddForm, closeMobileAddForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        title="Добавить клиента"
        closeClick={closeMobileAddForm}
        fixedTitle={true}
      >
        <NewClientForm
          onAdd={(id) => {
            fetchClients(city);
            goToPrevTab();
          }}
        />
      </MobileModal>
    ), [id, fetchClients, city]
  );


  return (
    <div className="card__fav-attachments">
      <div
        className={`popover fav-attach ${popupIsOpened ? "is-showing" : ""}`}
      >
        <div
          className="fav-attach__btn"
          onClick={(e) => {
            e.stopPropagation();
            media("isMobile") ? openMobileForm() : setPopupIsOpened(true);
          }}
        >
          {favorite?.clients?.length === 0 && (
            <svg className="icon icon_plus">
              <use xlinkHref="#plus"></use>
            </svg>
          )}
          <svg className="icon icon_user">
            <use xlinkHref="#user"></use>
          </svg>
        </div>
        <div
          className={`popover__block ${
            activeTab === 2 && !showOnLeft ? "popover__block_align_right" : ""
          }`}
          ref={popoverRef}
        >
          <div className="popover__inner">
            <div className="form-attach">
              <div
                className={`form-attach__tab ${
                  activeTab === 1 ? "active" : ""
                }`}
              >
                <div className="form-attach__body">
                  <div className="bold">
                    Хотите прикрепить к своему клиенту?
                  </div>
                  <div className="select select_type_form">
                    <label
                      className="custom-select-label"
                      htmlFor="favorite-client-5"
                    >
                      Клиент
                    </label>
                    <Dropdown
                      options={allClients}
                      value={selectedClient}
                      onChange={setSelectedClient}
                    />
                  </div>

                  <div
                    className="add-client"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToNextTab();
                    }}
                  >
                    <button className="button button_type_add"></button>
                    <span>Добавить клиента</span>
                  </div>
                  <div className="form-attach__submit">
                    <button
                      className="button button_view_grey"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          !favorite?.clients?.find(
                            (client) => client.id == selectedClient
                          )
                        ) {
                          addClientToFavorite(
                            id,
                            selectedClient,
                            allClients.find(
                              (item) => item.id == selectedClient
                            ),
                            favorite.favoriteId,
                            whatPage,
                            type
                          );
                          setActiveTab(0);
                        } else {
                          infoToast(<div>Выбранный клиент уже в избранном</div>)
                        }
                      }}
                    >
                      Прикрепить
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`form-attach__tab ${
                  activeTab === 2 ? "active" : ""
                }`}
              >
                <div className="form-attach__return">
                  <button
                    className="button button_view_outline-with-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToPrevTab();
                    }}
                  >
                    <svg className="icon icon_arrow-return">
                      <use xlinkHref="#arrow_return"></use>
                    </svg>
                    Вернуться
                  </button>
                </div>
                <div className="form-attach__new-client">
                  <NewClientForm
                    onAdd={(id) => {
                      fetchClients(city);
                      goToPrevTab();
                    }}
                  />
                </div>
              </div>
              <div
                className={`form-attach__tab ${
                  activeTab === 0 ? "active" : ""
                }`}
              >
                <ul className="form-attach__clients">
                  {favorite?.clients?.map((client, i) => (
                    <li
                      className="form-attach__client"
                      key={client.lastName + client.id}
                    >
                      {`${client.lastName} ${client.name[0]}.`}
                      <button
                        className="button button_type_delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeClientFromFavorite(
                            id,
                            client.id,
                            allClients.find((item) => item.id == client.id),
                            favorite.favoriteId,
                            whatPage,
                            type
                          );
                          if (favorite?.clients?.length === 1) {
                            setActiveTab(1);
                          }
                        }}
                      ></button>
                    </li>
                  ))}
                </ul>
                <div
                  className="form-attach__add"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToNextTab();
                  }}
                >
                  <button className="button button_view_outline-with-icon">
                    <svg className="icon icon_plus">
                      <use xlinkHref="#plus"></use>
                    </svg>
                    Добавить клиента
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopoverBlock;
