import { handleFavorite } from './handlers';

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  manager: null,
  banks: [],
  subways: [],
  savedFilterSettings: {}
};

const secondary = (state = initialState, action) => {
  switch (action.type) {
    case "SECONDARY_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "SECONDARY_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        manager: action.manager,
        banks: action.banks,
        subways: action.subways,
        agent: action.agent,
        isActive: action.isActive,
        isShowFilter: action.isShowFilter,
        client: action.client,
        similar: action.similar,
        savedFilterSettings: action.savedFilterSettings
      };
    case "SECONDARY_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "SECONDARY_RESET":
      return { ...initialState };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
      const page = action.data.whatPage
      if (page === 'secondary' || page === "similar") {
        const ident = page === "secondary" ? "data" : "similar"
        const isSingle = page === "secondary"
        return { ...state, [ident]: handleFavorite(state[ident], action, isSingle) }
      }
      return state
    default:
      return state;
  }
};

export default secondary;
