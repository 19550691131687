
import request from '../api';

const component = "panpartner:contests.ajax"

export const getContests = () => request(component, "getContests")

export const isContestParticipant = contestId => request(component, "isContestParticipant", {contestId})

export const addContestParticipant = contestId => request(component, "addContestParticipant", {contestId})

export const getContestResults = contestId => request(component, "getContestResults", {contestId})