import React, { memo, useEffect, useState } from 'react';
import './index.scss'
import {
  MortgageCopyIcon,
  MortgageDwnIcon,
  SfFilterCopyIcon,
  TelegramIcon,
  WhatsupIcon
} from "../../components/Public/markup/icons";
import useApi from "../../hooks/useApi";
import ContactCard from "../../components/core/ContactCard";
import Files from "../../components/Public/markup/detail/Files";
import Dropdown from "../../components/core/Dropdown";

import SubmitButton from "../../components/SubmitButton";

import useUniversalReducer from '../../hooks/useUniversalReducer';
import get from 'lodash/get';
import set from 'lodash/set';
import { formatMoney, modyficateKeys, toFormData } from '../../utils';
import { FormItem, sanitizeNumber, EarlyForm, createLimitSanitizer, extractFields } from './MortgageUtils';
import Preloader from '../../components/Preloader';

import PaymentsSchedule from '../../components/MortgageCalc/payments';
import PanModal from '../../components/core/PanModal';
import useModalWithData from '../../hooks/useModalWithData';
import { errorToast, successToast } from '../../components/toasts';
import _ from 'lodash'
import { useParams } from 'react-router-dom';

const MortgageNew = memo(() => {
  const { hash } = useParams();

  const {
    data: {
      files,
      managers,
      otherLinks,
      mortgage,

      period: periods,
      type: types,

      clients
    },
    isLoading,
    send,
    isSending
  } = useApi({
    payload: ["panpartner:calc.ajax", "getData", { hash }],
    processData: resp => ({ ...resp, clients: resp.clients.map(client => ({ ...client, title: `${client.lastName} ${client.name}` })) }),
    successCallback: resp => {
      const yearPercent = resp?.mortgage?.[0]?.percent
      if (!yearPercent) return

      setValues({
        firstMortForm: {
          yearPercent,
          ...extractFields(resp.params, ["price", "prepaymentPercent", "firstPayment", "years", "yearPercent"], 0)
        },
        secondMortForm: {
          yearPercent,
          ...extractFields(resp.params, ["price", "prepaymentPercent", "firstPayment", "priceIncreasePercent", "years", "yearPercent", "increasedPrice"], 1)
        },
        firstRepayment: resp.params?.earlyRepayments?.[0],
        secondRepayment: resp.params?.earlyRepayments?.[1],
      })

    }
  });

  const { state, setValue, setValues } = useUniversalReducer({
    firstMortForm: {},
    secondMortForm: {},
    firstRepayment: false,
    secondRepayment: false,
    activeRate: 0,
    errors: {} //[path]: value
  });

  const { openModal: openMortgageModal } = useModalWithData({
    content: ({ closeModal, ...props }) => <PanModal title={'График платежей'} closeModal={closeModal} isBig>
      <PaymentsSchedule
        {...props}
        prepay={props.firstPayment}
        rateEstate={props.yearPercent}
        period={props.years}
        price={props.increasedPrice ?? props.price}
        otherData={props.withPrepayment ? {
          "earlyRepayments[0]": firstRepayment,
          "earlyRepayments[1]": secondRepayment,
        } : []}
      />
    </PanModal>
  })

  const {
    firstMortForm,
    secondMortForm,
    firstRepayment,
    secondRepayment,
    activeRate,
    selectedClient,
    errors,
    calculation,
    calculationWithEarlyPayments,
    clientLink
  } = state;

  const setData = (path, value) => {
    const tmp = { ...state }
    set(tmp, path, value)
    setValues(tmp)
  }

  const setMultipleValues = values => {
    const tmp = { ...state }
    Object.entries(values).forEach(([path, value]) => set(tmp, path, value))
    setValues(tmp)
  }

  const createProps = (path, sanitize, formatter) => ({
    onChange: e => setData(path, sanitize ? sanitize(e.target.value) : e.target.value),
    value: formatter ? formatter(get(state, path)) : get(state, path),
    error: get(errors, path)
  })

  const setPayValues = (values, path) => {
    const {
      price = get(state, `${path}.price`),
      firstPayment = get(state, `${path}.firstPayment`),
      prepaymentPercent = get(state, `${path}.prepaymentPercent`),
    } = values;

    setMultipleValues({
      [`${path}.price`]: price,
      [`${path}.prepaymentPercent`]: values.firstPayment || values.price ? _.floor(firstPayment / price * 100, 2) : prepaymentPercent,
      [`${path}.firstPayment`]: parseInt(values.prepaymentPercent ? price * _.floor(prepaymentPercent, 2) / 100 : firstPayment),
    })
  }

  const setPriceIncreaseValues = (values, path) => {

    const {
      price = get(state, `${path}.price`),
      increasedPrice = get(state, `${path}.increasedPrice`),
      priceIncreasePercent = get(state, `${path}.priceIncreasePercent`),
      prepaymentPercent = get(state, `${path}.prepaymentPercent`),
      firstPayment = get(state, `${path}.firstPayment`),
    } = values;

    setMultipleValues({
      [`${path}.price`]: price,
      [`${path}.priceIncreasePercent`]: values.increasedPrice || values.price ? _.floor((increasedPrice - price) / price * 100 , 2): priceIncreasePercent,
      [`${path}.increasedPrice`]: values.priceIncreasePercent ? parseInt(+price + price * _.floor(priceIncreasePercent, 2) / 100) : increasedPrice,
      [`${path}.prepaymentPercent`]: !("prepaymentPercent" in values) ? _.floor(firstPayment / increasedPrice * 100, 2): values.prepaymentPercent,
      [`${path}.firstPayment`]: !values.firstPayment ? parseInt(increasedPrice * _.floor(prepaymentPercent, 2) / 100) : values.firstPayment,
    })
  }

  const checkFields = (object, config = {}) => {
    if (!object) return;
    const { errorMessages, fields } = config;
    const errors = {}
    fields.forEach(key => {
      const value = object[key]
      if (!value && value != 0) errors[key] = errorMessages ? errorMessages[key] : " "
    })
    return Object.keys(errors).length ? errors : false;
  }

  const calculate = async () => {

    const firstFormErrors = checkFields(firstMortForm, {
      fields: ["price", "prepaymentPercent", "firstPayment", "years", "yearPercent"]
    });
    const secondFormErrors = checkFields(secondMortForm, {
      fields: ["price", "prepaymentPercent", "firstPayment", "priceIncreasePercent", "years", "yearPercent", "increasedPrice"]
    });

    setMultipleValues({
      "errors.firstMortForm": firstFormErrors,
      "errors.secondMortForm": secondFormErrors,
    });

    if (firstFormErrors && secondFormErrors) return errorToast("Заполните поля калькулятора");

    const params = toFormData({
      ...(!firstFormErrors ? modyficateKeys(firstMortForm, "[0]") : []),
      ...(!secondFormErrors ? modyficateKeys({ ...secondMortForm, price: secondMortForm.increasedPrice }, firstFormErrors ? "[0]" : "[1]") : []),
      "earlyRepayments[0]": firstRepayment,
      "earlyRepayments[1]": secondRepayment,
    }, "", true)
    const { calculation, calculationWithEarlyPayments } = await send(["panpartner:calc.ajax", "calculate", params]);

    if (hash) return setValues({
      calculation,
      calculationWithEarlyPayments,
    })

    const paramsGetUrl = toFormData({
      ...(!firstFormErrors ? modyficateKeys(firstMortForm, "][0]", "params[") : []),
      ...(!secondFormErrors ? modyficateKeys({ ...secondMortForm, price: secondMortForm.increasedPrice }, firstFormErrors ? "][0]" : "][1]", "params[") : []),
      "params[earlyRepayments][0]": firstRepayment,
      "params[earlyRepayments][1]": secondRepayment,
    })

    const { url: clientLink } = await send(["panpartner:calc.ajax", "getUrl", paramsGetUrl]);

    setValues({
      calculation,
      calculationWithEarlyPayments,
      clientLink
    })
  }

  if (isLoading) return <Preloader loading />;

  return (
    <div className="wrapper">
      <div className="common-header">
        <div className="common-header__title"><h3 className="common-title">Ипотечный калькулятор</h3></div>
      </div>
      <p className="mortgage__top-text">Узнайте примерный платёж, ставку и другие предварительные условия по
        ипотеке</p>
      <div className="mortgage__menu">
      {!hash &&<div className="mortgage__menu-left">

          {!!otherLinks?.length && <a className="link" target="_blank" href={otherLinks[0]?.value} >
            <img src={otherLinks[0].icon} alt="" />{otherLinks[0].title}
          </a>}

          <a
            className="link"
            target="_blank"
            href={'https://drive.google.com/drive/folders/1vh-wQUkdVO7qE6xOqfFaOhT2RnEpKcA0'}
            rel="noreferrer"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 5 }}><path d="M9.71429 11.4286C12.8702 11.4286 15.4286 10.2774 15.4286 8.85719C15.4286 7.43703 12.8702 6.28577 9.71429 6.28577C6.55837 6.28577 4 7.43703 4 8.85719C4 10.2774 6.55837 11.4286 9.71429 11.4286Z" stroke="#EF372E" stroke-linecap="round" stroke-linejoin="round" /><path d="M4 8.85718V11.7143C4 13.1353 6.55714 14.2857 9.71429 14.2857C12.8714 14.2857 15.4286 13.1353 15.4286 11.7143V8.85718" stroke="#EF372E" stroke-linecap="round" stroke-linejoin="round" /><path d="M15.4286 9.76392C18.0362 10.0068 20 11.0477 20 12.2858C20 13.7068 17.4429 14.8572 14.2858 14.8572C12.8858 14.8572 11.6 14.6287 10.6077 14.2572" stroke="#EF372E" stroke-linecap="round" stroke-linejoin="round" /><path d="M8.57141 14.2362V15.1429C8.57141 16.5639 11.1286 17.7143 14.2857 17.7143C17.4428 17.7143 20 16.5639 20 15.1429V12.2858" stroke="#EF372E" stroke-linecap="round" stroke-linejoin="round" /></svg>
            Кредитные калькуляторы
          </a>
        </div>}
        {!!clientLink && <div className="mortgage__menu-right">
          {/* <span>
            <MortgageDwnIcon />
            Скачать расчет
          </span> */}
          <span onClick={() => navigator.clipboard.writeText(clientLink).then(() => successToast("Ссылка скопирована в буфер обмена"))}>
            <MortgageCopyIcon />
            Скопировать ссылку на расчет
          </span>
        </div>}
      </div>


      <div className="mortgage-calc">
        <div className="mortgage-calc__left">
          <ul className="mortgage-calc__rates">
            {
              mortgage && mortgage.map((item, i) => (
                <li
                  key={item.id}
                  onClick={() => setValues({
                    activeRate: activeRate === i ? null : i,
                    firstMortForm: { ...firstMortForm, yearPercent: item.percent },
                    secondMortForm: { ...secondMortForm, yearPercent: item.percent },
                  })}
                  className={activeRate === i ? 'active' : ''}
                >
                  {item.title}
                </li>
              ))
            }
          </ul>

          <div className="mortgage-calc__calcs">
            <div className="mortgage-calc__part">
              <p className="mortgage-calc__name-calc">Стандартная ипотека</p>
              <FormItem
                label="Стоимость недвижимости"
                postfix="₽"
                type="text"
                {...createProps("firstMortForm.price", sanitizeNumber, formatMoney)}
                onChange={e => setPayValues({ price: sanitizeNumber(e.target.value) }, "firstMortForm")}
              />
              <FormItem
                label="Первоначальный взнос"
                postfix="%"
                onChange={e => setPayValues({ prepaymentPercent: e.target.value }, "firstMortForm")}
                value={firstMortForm.prepaymentPercent}
                error={get(errors, "firstMortForm.prepaymentPercent")}
              />
              <FormItem
                label="Первоначальный взнос"
                postfix="₽"
                type="text"
                value={formatMoney(firstMortForm.firstPayment)}
                onChange={e => setPayValues({ firstPayment: sanitizeNumber(e.target.value) }, "firstMortForm")}
                error={get(errors, "firstMortForm.firstPayment")}
              />

              <div className="mortgage-calc__part-row">
                <FormItem label="Срок ипотеки" postfix="лет" {...createProps("firstMortForm.years", createLimitSanitizer(0, 100))} />
                <FormItem label="Процентная ставка" postfix="%" {...createProps("firstMortForm.yearPercent", createLimitSanitizer(0, 100))} />
              </div>
            </div>
            <div className="mortgage-calc__part">
              <p className="mortgage-calc__name-calc">Ипотека с удорожанием</p>

              <FormItem
                label="Стоимость недвижимости"
                postfix="₽"
                type="text"
                {...createProps("secondMortForm.price", sanitizeNumber, formatMoney)}
                onChange={e => setPriceIncreaseValues({ price: sanitizeNumber(e.target.value) }, "secondMortForm")}
              />

              <div className="mortgage-calc__part-row">
                <FormItem
                  label="% удорожания"
                  postfix="%"
                  {...createProps("secondMortForm.priceIncreasePercent", createLimitSanitizer(0, 100))}
                  onChange={e => setPriceIncreaseValues({ priceIncreasePercent: e.target.value }, "secondMortForm")}
                />
                <FormItem
                  label="После удорожания"
                  type="text"
                  postfix="₽"
                  {...createProps("secondMortForm.increasedPrice", sanitizeNumber, formatMoney)}
                  onChange={e => setPriceIncreaseValues({ increasedPrice: sanitizeNumber(e.target.value) }, "secondMortForm")}

                />
              </div>

              <div className="mortgage-calc__part-row">
                <FormItem
                  label="Первоначальный взнос"
                  postfix="%"
                  value={secondMortForm.prepaymentPercent}
                  onChange={e =>setPriceIncreaseValues({ prepaymentPercent: e.target.value }, "secondMortForm")}
                  error={get(errors, "secondMortForm.prepaymentPercent")}
                />
                <FormItem
                  label="Первоначальный взнос"
                  postfix="₽"
                  type="text"
                  value={formatMoney(secondMortForm.firstPayment)}
                  onChange={e => setPriceIncreaseValues({ firstPayment: sanitizeNumber(e.target.value) }, "secondMortForm")}
                  error={get(errors, "secondMortForm.firstPayment")}
                />
              </div>

              <div className="mortgage-calc__part-row">
                <FormItem label="Срок ипотеки" postfix="лет" {...createProps("secondMortForm.years", createLimitSanitizer(0, 100))} />
                <FormItem label="Процентная ставка" postfix="%" {...createProps("secondMortForm.yearPercent", createLimitSanitizer(0, 100))} />

              </div>

            </div>
          </div>
          <div className="calculate_btn" style={{marginTop: 25}}>
            <SubmitButton
              type="button"
              className="btn h6 btn_primary btn_full"
              onClick={calculate}
              isLoading={isSending}
            >
              Рассчитать
            </SubmitButton>
          </div>

        </div>
        <div className="mortgage-calc__right">
          {!!firstRepayment && <EarlyForm
            setValue={(key, v) => setData(key ? `firstRepayment.${key}` : "firstRepayment", v)}
            values={firstRepayment}
            types={types}
            periods={periods}
            number={1}
          />}
          {!!secondRepayment && <EarlyForm
            setValue={(key, v) => setData(key ? `secondRepayment.${key}` : "secondRepayment", v)}
            values={secondRepayment}
            types={types}
            periods={periods}
            number={2}
          />}

          {(!firstRepayment || !secondRepayment) && (
            <div
              className={`mortgage-calc__early-btn ${(!!firstRepayment) ? 'mortgage-calc__early-btn_half' : ''}`}
              onClick={() => setValue(firstRepayment ? "secondRepayment" : "firstRepayment", { sum: 10000, period: "one", date: new Date(`${new Date().getMonth() + 2}.01.${new Date().getFullYear()}`).toLocaleDateString("us"), type: "payment" })}
            >
              <svg width="62" height="61" style={{ marginBottom: 20 }} viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.5 30.5C61.5 13.6559 47.8441 0 31 0C14.1559 0 0.5 13.6559 0.5 30.5C0.5 47.3441 14.1559 61 31 61C47.8441 61 61.5 47.3441 61.5 30.5Z" fill="#F6F7F8" />
                <path d="M23.8232 30.5002H38.1762" stroke="#2C2E3E" stroke-linecap="round" />
                <path d="M31 23.3237L31 37.6767" stroke="#2C2E3E" stroke-linecap="round" />
              </svg>
              Рассчитать досрочное<br /> погашение
            </div>
          )}
        </div>
      </div>

      {!isSending && calculation && <div className="mortgage-calc__bottom">
        <div className="mortgage-results" style={hash ? {width: '68%'} : {}}>
          <div className="mortgage-results__part">
            {calculation.map(({
              monthlyPayment,
              requiredIncome,
              sumMortgage,
              sum,
              period,
              generalPercent,
              percentPercent }, i) => <div className="mortgage-results__section" key={i}>
                <div>
                  <h4>Предварительный расчет</h4>

                  <div className="mortgage-results__progress">
                    <div className="mortgage-results__designations">
                      <span>проценты</span>
                      <span>основной долг</span>
                    </div>
                    <ProgressBar progress={percentPercent} max={100} />
                  </div>

                  <ul className="mortgage-results__list">


                    <li>
                      <span>Сумма кредита</span>
                      <span dangerouslySetInnerHTML={{ __html: sumMortgage }} />
                    </li>
                    <li>
                      <span>Ежемесячный платеж</span>
                      <span dangerouslySetInnerHTML={{ __html: monthlyPayment }} />
                    </li>
                    <li>
                      <span>Проценты</span>
                      <span>{formatMoney(parseInt(sanitizeNumber(sum) * percentPercent / 100))} ₽ </span>
                    </li>
                    <li>
                      <span>Сумма всех платежей</span>
                      <span dangerouslySetInnerHTML={{ __html: sum }} />
                    </li>
                    <li>
                      <span>Срок ипотеки</span>
                      <span dangerouslySetInnerHTML={{ __html: period }} />
                    </li>
                    <li>
                      <span>Необходимый доход</span>
                      <span dangerouslySetInnerHTML={{ __html: requiredIncome }} />
                    </li>
                  </ul>
                </div>
                <div>
                  <SubmitButton
                    type="button"
                    className="btn h6 btn_secondary btn_full"
                    onClick={() => openMortgageModal(errors.firstMortForm || i === 1 ? secondMortForm : firstMortForm)}
                  >График платежей</SubmitButton>
                </div>

              </div>)}


          </div>

          {!!calculationWithEarlyPayments?.length && (
            <div className="mortgage-results__part">
              {calculationWithEarlyPayments.map(({ percentPercent, sum, sumMortgage, period, sumDiff, periodDiff, monthlyPayment }, i) => {
                const { sum: calculationSum, percentPercent: calcPercentPercent } = calculation[i]
                return <div className="mortgage-results__section" key={i}>
                  <div>
                    <h4>Досрочное погашение</h4>
                    <ul className="mortgage-results__list">
                      <li>
                        <span>Сумма кредита</span>
                        <span dangerouslySetInnerHTML={{ __html: sumMortgage }} />
                      </li>
                      <li>
                        <span>Проценты</span>
                        <span>{formatMoney(parseInt(sanitizeNumber(sum) * percentPercent / 100))} ₽ <br />
                          <span style={{ fontWeight: 400 }}>{formatMoney(parseInt(sanitizeNumber(sum) * percentPercent / 100) - parseInt(sanitizeNumber(calculationSum) * calcPercentPercent / 100))} ₽</span>
                        </span>
                      </li>
                      <li>
                        <span>Проценты + Кредит</span>
                        <span>{sum}<br />
                          {!!sumDiff && <span style={{ fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: sumDiff }} />}
                        </span>
                      </li>
                      <li>
                        <span>Ежемесячный платеж</span>
                        <span dangerouslySetInnerHTML={{ __html: monthlyPayment }} />
                      </li>
                      <li>
                        <span>Срок ипотеки</span>
                        <span>{period}<br />
                          {!!periodDiff && <span style={{ fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: periodDiff }} />}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <SubmitButton
                    type="button"
                    className="btn h6 btn_secondary btn_full"
                    onClick={() => openMortgageModal({
                      withPrepayment: true,
                      ...errors.firstMortForm || i === 1 ? secondMortForm : firstMortForm
                    })}
                  >График платежей</SubmitButton>
                </div>
              })}
            </div>
          )}
        </div>

        {!hash && !!clients?.length && <div className="mortgage-calc__send">
          <h4>Отправить расчёт клиенту</h4>

          <div className="mortgage-calc__send-content">
            <Dropdown
              withSearch={clients?.length > 4}
              options={clients}
              placeholder="Выберите клиента"
              value={selectedClient?.id}
              customHandler={v => setValue("selectedClient", v)}
              searchPlaceholder="Введите ФИО клиента"
            />

            {selectedClient && <ul className="mortgage-calc__send-list">
              <li>  <a
                href={`https://t.me/${selectedClient?.phone}`}
                className="sf__manager-social"
                target={'_blank'}
                title={`${selectedClient?.name} в Telegram`}
                rel="noreferrer"
              >
                <TelegramIcon />
              </a></li>
              <li>          <a
                href={`https://wa.me/${selectedClient?.phone}`}
                className="sf__manager-social"
                target={'_blank'}
                rel="noreferrer"
              >
                <WhatsupIcon />
              </a></li>
              <li onClick={() => navigator.clipboard.writeText(clientLink).then(() => successToast("Ссылка скопирована в буфер обмена"))}>
                <SfFilterCopyIcon />
              </li>
            </ul>}
          </div>

        </div>}
      </div>}





      {!hash && managers &&
        <>
          <h3 className="common-title" style={{ marginTop: 30, marginBottom: 20 }}>По вопросам ипотеки</h3>
          <div className="mortgage__managers">
            {managers.map((item) => {
              return (
                <div className="mortgage__managers-item" key={item.title}>
                  <ContactCard
                    name={item?.title}
                    phone={item?.phone}
                    telegram={item?.telegram}
                    logo={item?.photo?.src}
                    post={item?.position}
                  />
                </div>
              )
            })}
          </div>
        </>
      }


      {!hash && files && <div className="mortgage__overfiles" style={{ marginTop: 30, maxWidth: '66.6%' }}><Files customTitle='Полезные файлы' items={files} /></div>}
      <p style={{ marginTop: 16, marginBottom: 32 }}>Расчет носит справочный характер и не может быть основанием для совершения юридически значимых действий.</p>
    </div>
  )
})

const ProgressBar = ({ progress, max }) => {
  const progressPercentage = (progress / max) * 100;
  const remainingPercentage = 100 - progressPercentage;

  const containerStyle = {
    width: '100%',
    backgroundColor: '#E3F0F7',
    borderRadius: '12px',
    height: '18px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  };

  const progressStyle = {
    width: `${progressPercentage}%`,
    backgroundColor: '#2C2E3E',
    height: '100%',
    borderRadius: '12px',
    transition: 'width 0.5s ease-in-out',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: 12,
    fontWeight: 500
  };

  const remainingStyle = {
    width: `${remainingPercentage}%`,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontWeight: 'bold',
    fontSize: 12,
  };

  return (
    <div style={containerStyle}>
      <div style={progressStyle}>
        {Math.round(progressPercentage)}%
      </div>
      <div style={remainingStyle}>
        {Math.round(remainingPercentage)}%
      </div>
    </div>
  );
};

export default MortgageNew;
