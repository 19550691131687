import React, {memo, useEffect, useRef, useState} from "react";
import { Table, Descriptions, Card } from "antd";
import useApi from "../../hooks/useApi";
import {getUrl} from "../../api";
import Preloader from "../../components/Preloader";
import {ZoomIcon} from "../../components/core/SwiperSlider/icons";
import Button from "../../components/core/Button";
import {formatMoney} from "../../utils";
import {useParams} from "react-router-dom";
import useDocTitle from "../../hooks/useDocTitle";
import {PhotoPopup} from "../../components/Viewer/PhotoSlider";
import useMedia from "../../hooks/useMedia";
import {useModal} from "react-modal-hook";
import Modal from "../../components/core/Modal";

const CompareTable = () => {

  const { id } = useParams()
  useDocTitle('Сравнение')
  const scrollRef = useRef(null);

  const { data: { items, title, compilation }, isLoading, send, setData, refresher } = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilationCompare", { id }],
  })

  const isDesktop = useMedia('isDesktop');
  const isLaptop = useMedia('isLaptop');
  const isSmallLaptop = useMedia('isSmallLaptop');
  const isTablet = useMedia('isTablet');

  const [openGallery, setGallery] = useState(false);
  const [planPhoto, setPlanPhoto] = useState(false)
  const [withArrow, setWithArrow] = useState(false)

  useEffect(() => {
    if (items && items.length > 5) {
      setWithArrow(true)
    }
  }, [items])

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - (isDesktop ? 310 : 294.4),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + (isDesktop ? 310 : 294.4),
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const tableContent = document.querySelector(".ant-table-content");
    if (tableContent) {
      scrollRef.current = tableContent;
      tableContent.style.overflow = "hidden";
    }
  }, [isLoading]);

  const [openModal, hideModal] = useModal(({}) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick
           close={hideModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#EF372E"/>
          <line x1="12.0435" y1="7.5" x2="12.0435" y2="13.5" stroke="#EF372E" stroke-linecap="round"/>
          <circle cx="12.0435" cy="15.5" r="0.5" fill="#EF372E"/>
        </svg>
        Удалить объект из сравнения?
      </div>

      <div style={{display: 'flex', gap: 10}}>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={hideModal}>Нет</Button>
        </div>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={hideModal}>Да</Button>
        </div>
      </div>

    </Modal>
  ), []);

  if (isLoading) return <Preloader loading />;

  const fieldsBySettings = {
    showArea: ["squareTotal", "squareKitchen"],
    showBuildingAndSection: ["section", "addressCorp"],
    showBuildingType: ["buildingType"],
    showBlockTitle: ["builderTitle"],
    showCompletionDate: ["ending"],
    showContractType: ["contractType"],
    showFinish: ["decoration"],
    showKeyIssueDate: ["issuingKeys"],
    showMeterPrice: ["meterPrice"],
    showPaymentMethod: ["paymentTypes"],
    showPrice: ["minPrice"],
    showCeilingHeight: ["сellHeight"],
    showFloor: ["floorFormat"],
  }

  const ListItem = memo(({ title, value, field }) => Object.entries(fieldsBySettings).some(([settingName, fields]) => compilation.settings[settingName] && fields?.includes(field)) && <div className="compare-table__list-item">
    <p className="compare-table__list-label">{title}</p>
    <p className="compare-table__list-value">{(!!value || value === 0) ? value : "—"}</p>
  </div>)

  const verticalData = [
    {
      key: "photo",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <>
            <img className="compare-table__photo-building" src={getUrl(item.photos?.[0]?.src)}/>
            <div className="compare-table__plan">
              <div className="compare-table__plan-image">
                <Button
                  buttonType="clean"
                  addClass="compare-table__zoom-btn"
                  onClick={() => {
                    setPlanPhoto({ "src": item.photos?.plan?.src })
                    setGallery([{ "src": item.photos?.plan?.src }])
                  }
                }>
                  <ZoomIcon />
                </Button>
                <div className="compare-table__zoom-items">
                  <span className="compare-table__drag">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.54423 14.409L4 19.9532" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.4722 4.13111L9.84156 8.76072C9.84156 8.76072 7.78341 7.72718 5.58058 9.51085C5.4575 9.60931 5.37855 9.7526 5.36109 9.90924C5.34362 10.0659 5.38906 10.2231 5.48743 10.3462C5.50091 10.3633 5.51547 10.3795 5.53103 10.3948L13.5427 18.4064C13.6532 18.5187 13.8038 18.5825 13.9614 18.5838C14.1189 18.5851 14.2706 18.5238 14.383 18.4133C14.4046 18.3929 14.4245 18.3707 14.4424 18.3469C15.0667 17.5215 16.0477 15.8122 15.1926 14.1107L19.8232 9.48013C19.9353 9.36944 19.9989 9.21877 20 9.06121C20.0011 8.90365 19.9397 8.75209 19.8291 8.63983L19.8232 8.63289L15.3184 4.13111C15.2079 4.01885 15.0573 3.95506 14.8997 3.95376C14.7421 3.95246 14.5905 4.01375 14.4781 4.12418L14.4722 4.13111Z" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9.97168 8.72305L12.6769 11.4362" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <span onClick={openModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 5.99219H19" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14 9.99219V15.9922" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10 9.99219V15.9922" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 5.99219V19.2922C6 19.4778 6.07024 19.6559 6.19526 19.7872C6.32029 19.9184 6.48986 19.9922 6.66667 19.9922H17.3333C17.5101 19.9922 17.6797 19.9184 17.8047 19.7872C17.9298 19.6559 18 19.4778 18 19.2922V5.99219" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9 5.99219V4.99219C9 4.72697 9.12643 4.47262 9.35147 4.28508C9.57652 4.09754 9.88174 3.99219 10.2 3.99219H13.8C14.1183 3.99219 14.4235 4.09754 14.6485 4.28508C14.8736 4.47262 15 4.72697 15 4.99219V5.99219" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                  </span>
                </div>
                <img src={getUrl(item.photos?.plan?.src)}/>
              </div>
            </div>
            <div className={`label-item label_${item.status.color}`}>{item.status.name}</div>
            <p className="h5-l" style={{marginBottom: 20}}>{item.title}</p>
          </>
        );
        return acc;
      }, {}),
    },
    {
      key: "subway",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <div>
            {item?.subway.map((i) => {
              return (
                <div className="compare-table__subway">
                  <span
                    className="sf-card__metro-circle"
                    style={{ backgroundColor: i.color, padding: 0, marginLeft: 0 }}
                  />
                  {i.title}
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.201 6.602L5.101 3.901H11.704L12.605 6.602H4.201ZM11.704 9.60301C11.206 9.60301 10.804 9.20201 10.804 8.70301C10.804 8.20501 11.206 7.80301 11.704 7.80301C12.203 7.80301 12.605 8.20501 12.605 8.70301C12.605 9.20201 12.203 9.60301 11.704 9.60301ZM5.101 9.60301C4.603 9.60301 4.201 9.20201 4.201 8.70301C4.201 8.20501 4.603 7.80301 5.101 7.80301C5.599 7.80301 6.002 8.20501 6.002 8.70301C6.002 9.20201 5.599 9.60301 5.101 9.60301ZM12.557 3.607C12.434 3.252 12.098 3 11.704 3H5.101C4.708 3 4.372 3.252 4.249 3.607L3 7.203V12.005C3 12.335 3.27 12.605 3.6 12.605H4.201C4.534 12.605 4.801 12.335 4.801 12.005V11.405H12.005V12.005C12.005 12.335 12.275 12.605 12.605 12.605H13.205C13.538 12.605 13.805 12.335 13.805 12.005V7.203L12.557 3.607Z" fill="#777A89"/></svg>
                    {i.minutes + ' мин.'}</span>
                </div>
              )
            })}
          </div>
        )
        return acc;
      }, {}),
    },
    {
      key: "note",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <div className="compare-table__note">
            Заметка:
            <p className="compare-table__note-text">{item.note}</p>
          </div>
        )
        return acc;
      }, {}),
    },
    {
      key: "minPrice",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Цена, 100% оплата" value={item.minPrice} field="minPrice" />
        )
        return acc;
      }, {}),
    },
    {
      key: "meterPrice",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Цена за м²" value={formatMoney(item.meterPrice, " ₽")} field="meterPrice" />
        )
        return acc;
      }, {}),
    },
    {
      key: "squareTotal",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Общая площадь, м²" value={item.squareTotal} field="squareTotal" />
        )
        return acc;
      }, {}),
    },
    {
      key: "squareKitchen",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Площадь кухни, м²" value={item.squareKitchen} field="squareKitchen" />
        )
        return acc;
      }, {}),
    },
    {
      key: "floorFormat",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Этаж" value={item.floorFormat} field="floorFormat" />
        )
        return acc;
      }, {}),
    },
    {
      key: "сellHeight",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Высота потолков" value={item.сellHeight} field="сellHeight" />
        )
        return acc;
      }, {}),
    },
    {
      key: "decoration",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Отделка" value={item.decoration} field="decoration" />
        )
        return acc;
      }, {}),
    },
    {
      key: "floorPlan",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <>
            <p className="h5-l" style={{marginBottom: 20, marginTop: 20}}>План этажа</p>
            <img className="compare-table__photo-building" style={{marginBottom: 40}} src={getUrl(item.floorPlan?.src)}/>
          </>
        );
        return acc;
      }, {}),
    },
    {
      key: "contractType",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Форма договора" value={item.contractType} field="contractType" />
        )
        return acc;
      }, {}),
    },

    {
      key: "addressCorp",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Корпус / участок / Дом" value={item.addressCorp} field="addressCorp" />
        )
        return acc;
      }, {}),
    },
    {
      key: "section",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Секция" value={item.section} field="section" />
        )
        return acc;
      }, {}),
    },
    {
      key: "ending",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Срок сдачи" value={item.ending} field="ending" />
        )
        return acc;
      }, {}),
    },
    {
      key: "issuingKeys",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Срок выдачи ключей" value={item.issuingKeys} field="issuingKeys" />
        )
        return acc;
      }, {}),
    },
    {
      key: "paymentTypes",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Способы оплаты" value={item.paymentTypes} field="paymentTypes" />
        )
        return acc;
      }, {}),
    },
    {
      key: "buildingType",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Тип дома" value={item.buildingType} field="buildingType" />
        )
        return acc;
      }, {}),
    },
    {
      key: "builderTitle",
      ...items.reduce((acc, item, index) => {
        acc[`col${index + 1}`] = (
          <ListItem title="Застройщик" value={item.builderTitle} field="builderTitle" />
        )
        return acc;
      }, {}),
    },

  ];

  const columns = [
    ...items.map((_, index) => ({
      dataIndex: `col${index + 1}`,
      key: `col${index + 1}`,
      width:  isDesktop ? 310 : 294.4,
      onCell: (record) => ({
        className: record.key === "photo" ? "compare-table__top-cell" :
          record.key === "subway" ? 'compare-table__white-color' :
          record.key === "note" ? 'compare-table__white-color' : '' ,
      }),
    })),
  ];

  return (
    <div className="wrapper compare-table">

      <div className="compare-table__header-title">
        <h3 className='h3 compare__title'>Сравнение</h3>
        <div className="compare-table__header-actions">
          <span className="link link_color_black">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 11L12 14L15 11" stroke="#2C2E3E" stroke-linecap="round"/>
              <path d="M12 13L12 5" stroke="#2C2E3E" stroke-linecap="round"/>
              <path d="M5 13L5 18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V13" stroke="#2C2E3E" stroke-linecap="round"/>
            </svg>
            Сохранить
          </span>
        </div>
      </div>


      {withArrow &&
      <button
        onClick={scrollLeft}
        style={{
          position: "absolute",
          top: 100,
          left: 50,
          zIndex: 1,
          backgroundColor: 'transparent',
          transform: 'rotate(180deg)',
          cursor: 'pointer'
        }}
      >
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="44" width="44" height="44" rx="22" transform="rotate(90 44 0)" fill="#F6F7F8"/>
          <path d="M19.5 17L24.5 22L19.5 27" stroke="#2C2E3E" stroke-linecap="round"/>
        </svg>
      </button> }
      {withArrow &&
        <button
          onClick={scrollRight}
          style={{
            position: "absolute",
            top: 100,
            right: 50,
            zIndex: 1,
            backgroundColor: 'transparent',
            cursor: 'pointer'
          }}
        >
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="44" width="44" height="44" rx="22" transform="rotate(90 44 0)" fill="#F6F7F8"/>
            <path d="M19.5 17L24.5 22L19.5 27" stroke="#2C2E3E" stroke-linecap="round"/>
          </svg>
        </button>
      }
      <Table
        columns={columns}
        dataSource={verticalData}
        pagination={false}
        scroll={{ x: items.length * (isDesktop ? 310 : 294.4) }}
        showHeader={false}
        rowClassName={(record) => {
          if (record.key === "photo") return "compare-table__top-row";
          if (record.key === "subway") return "compare-table__subway-row";
          if (record.key === "note") return "compare-table__note-row";
          if (record.key === "floorPlan") return "compare-table__floor";
          return "";
        }}
      />

      <PhotoPopup
        items={[planPhoto]}
        index={0}
        open={openGallery}
        onClose={() => setGallery(false)}

      />
    </div>
  );


}

export default CompareTable



