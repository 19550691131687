import { useViewportSpy } from 'beautiful-react-hooks';
import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import SimpleBar from "simplebar-react";
import { youtubeAPIRequest } from "../../../api";

const VieoSkeleton = (props) => (
  <ContentLoader 
    speed={2}
    width={"100%"}
    viewBox="0 0 1600 558"
    backgroundColor="#f4f4f8"
    foregroundColor="#EAEAEF"
    {...props}
  >
    <rect x="65" y="0" rx="15" ry="15" width="845" height="558" /> 
    <rect x="957" y="0" rx="15" ry="15" width="252" height="160" /> 
    <rect x="957" y="199" rx="15" ry="15" width="252" height="160" /> 
    <rect x="957" y="398" rx="15" ry="15" width="252" height="160" /> 
    <rect x="1240" y="27" rx="8" ry="8" width="162" height="34" /> 
    <rect x="1240" y="226" rx="8" ry="8" width="162" height="34" /> 
    <rect x="1240" y="425" rx="8" ry="8" width="162" height="34" /> 
    <rect x="1240" y="75" rx="5" ry="5" width="237" height="14" /> 
    <rect x="1240" y="97" rx="5" ry="5" width="237" height="14" /> 
    <rect x="1240" y="119" rx="5" ry="5" width="88" height="14" /> 
    <rect x="1240" y="274" rx="5" ry="5" width="237" height="14" /> 
    <rect x="1240" y="296" rx="5" ry="5" width="237" height="14" /> 
    <rect x="1240" y="318" rx="5" ry="5" width="88" height="14" /> 
    <rect x="1240" y="473" rx="5" ry="5" width="237" height="14" /> 
    <rect x="1240" y="495" rx="5" ry="5" width="237" height="14" /> 
    <rect x="1240" y="517" rx="5" ry="5" width="88" height="14" />
  </ContentLoader>
)


const YoutubeIcon = () => {
  return (
    <svg width="52" height="38" viewBox="0 0 52 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.9099 6.66646C50.3141 4.39525 48.5469 2.59894 46.3125 1.99328C42.2635 0.89209 26 0.89209 26 0.89209C26 0.89209 9.73646 0.89209 5.68073 2.00016C3.44635 2.60582 1.67917 4.40213 1.08333 6.67334C0 10.789 0 19.3921 0 19.3921C0 19.3921 0 27.9951 1.08333 32.1108C1.67917 34.382 3.44635 36.1784 5.68073 36.784C9.73646 37.8852 26 37.8852 26 37.8852C26 37.8852 42.2635 37.8852 46.3193 36.784C48.5536 36.1784 50.3208 34.382 50.9167 32.1108C52 27.9951 52 19.3921 52 19.3921C52 19.3921 52 10.789 50.9099 6.66646Z" fill="#ED1F24" />
      <path d="M20.8 27.3205L34.3079 19.3919L20.8 11.4634V27.3205Z" fill="white" />
    </svg>
  );
};

const Video = ({ videoChannel, videoTitle }) => {
  const [videoInfo, setVideoInfo] = useState([])
  const [currentVideo, setCurrentVideo] = useState(null)
  const ref = useRef();
  const isVisible = useViewportSpy(ref);

  useEffect(() => {
    if (!videoChannel || videoInfo?.length > 0 || !isVisible) return
    youtubeAPIRequest(videoChannel).then(resp => {
      const videos = []
      for (const item of resp.data.items) {
        if (item.id.videoId) {
          videos.push({
            id: item.id.videoId,
            // desc: item.snippet.description,
            thumb: item.snippet.thumbnails.high,
            title: item.snippet.title.replace(/&quot;/gi, '"')
          })
        }
      }

      setVideoInfo(videos)
      setCurrentVideo(videos?.[0])
    })
  }, [isVisible])

  if (!videoChannel) return <section className="video constructor__section" ref={ref}/>

  return (
    <section className="video constructor__section" ref={ref}>
      {!currentVideo ? <VieoSkeleton /> :
        <div className="constructor__container">
          <div className="video__wrapper constructor__wrapper">
            <div className="constructor__section-title">
              <h2 className="h2 constructor__title video__title">
                {videoTitle}
              </h2>
            </div>
            <div className={`video__box ${videoInfo?.length === 1 ? "video__sidebar" : ""}`}>

              <div className="video__main">
                {/* <button type="button" className="video__placeholder video__item">
                <img src={currentVideo.thumb.url} alt="" className="video__prev" />
                <div className="video__main-info">
                  <YoutubeIcon />

                  <h5 className="video__h5">{currentVideo.title}</h5>
                  <p className="video__text">{currentVideo.desc}</p>
                </div>
              </button> */}
                <iframe
                  src={`https://www.youtube.com/embed/${currentVideo?.id}?controls=0&iv_load_policy=3`}
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  title={currentVideo?.title}
                />
              </div>


              {videoInfo?.length > 1 && <SimpleBar className="video__sidebar">
                {videoInfo.map(item => (
                  <button type="button" className="video__item" onClick={() => setCurrentVideo(item)}>

                    <div className="video__sidebox">
                      <img src={item.thumb.url} alt="" className="video__prev video__prev_side" />
                      <YoutubeIcon />
                    </div>

                    <div className="video__side-info">
                      <h5 className="video__h5">{item.title}</h5>
                    </div>
                  </button>
                ))}

              </SimpleBar>}


            </div>

          </div>

        </div>}
    </section>
  )
};

export default Video;
