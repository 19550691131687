import compact from 'lodash/compact';
import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAgency, getBlocks } from '../actions/constructor';
import Advantages from '../components/Constructor/Advantages/Advantages.js';
import Background from '../components/Constructor/Background/Background.js';
import Banner from '../components/Constructor/Banner/Banner.js';
import PropertyBanner from '../components/Constructor/Banner/PropertyBanner.js';
import Calculator from '../components/Constructor/Calculator/Calculator.js';
import CallbackModal from "../components/Constructor/CallbackModal/CallbackModal.js";
import ConsultationSimple from '../components/Constructor/Consultation/Consultation-2.js';
import Consultation from '../components/Constructor/Consultation/Consultation.js';
import '../components/Constructor/index.scss';
import Lid from '../components/Constructor/Lid/Lid.js';
import LidModal from "../components/Constructor/LidModal/LidModal.js";
import Promo from '../components/Constructor/Promo/Promo.js';
import Property from '../components/Constructor/Property';
import Reviews from '../components/Constructor/Reviews/Reviews.js';
import Services from '../components/Constructor/Services/Services.js';
import Video from '../components/Constructor/Video/Video.js';
import Preloader from '../components/Preloader';
import useDomEvent from '../hooks/useDomEvent';
import { runScriptsFromHTML, scrollToRef, sendConstructorEvent } from "../utils";
import { getSubdomain } from '../utils/location';


const Constructor = (props) => {
  const {
    agency,
    agent,
    theme,
    blocks,
    services,
    advantages,
    reviews,
    promo,
    socials,
    complexes,
    isFetching,
    promoText,
    customJS,
    widgetReviews,
    leadMagnet = {},
    coordinates,
    site,
    phoneAgency,
    multiLink,
    videoChannel,
    videoTitle
  } = props.state

// console.log(props.state)
  const { fetch, setPreviewData, getBlocks, location, history, setFullScreenLoading, showPropertyBanner } = props
// console.log(props)
  const [setsRef, servicesRef, reviewsRef, leadMagnetRef] = [useRef(null), useRef(null), useRef(null), useRef(null)]
  const isPreview = window.location.search.includes("preview")
  const [customHtml, setCustomHtml] = useState(null)

  const hash = location.hash

  const scrollToHash = (hash) => {
    if (!hash) return
    const refs = {
      "#sets": setsRef,
      "#services": servicesRef,
      "#reviews": reviewsRef,
      "#lead": leadMagnetRef
    }
    scrollToRef(refs[hash])
  }

  useDomEvent("load", () => setTimeout(() => scrollToHash(hash), 1000))

  // useEffect(() => scrollToHash(hash), [hash])

  useEffect(() => {
    if (customJS) setCustomHtml(runScriptsFromHTML(customJS))
  }, [customJS])

  useEffect(() => {
    if (isPreview) {
      const data = localStorage.getItem("previewData")
      if (!data) {
        history.push("/constructor-admin")
        setFullScreenLoading(false)
        return
      }
      const parsedData = JSON.parse(data)
      parsedData.sets[0].isActive = true
      setPreviewData(parsedData)
    }
    if (isPreview) return
    fetch()
  }, [])

  const [callBackTitle, setcallBackTitle] = useState("Получить консультацию")


  const [openModal, closeCallbackModal] = useModal(() =>
   <CallbackModal closeCallbackModal={closeCallbackModal} title={callBackTitle} isPreview={isPreview} />,
  [callBackTitle]);

  const openCallbackModal = (title = "Получить консультацию") => {
    setcallBackTitle(title)
    openModal()
  }

  const [lidTitle, setLidTitle] = useState("Получить консультацию")

  const [openModalLid, closeLidModal] = useModal(() => (
    <LidModal closeLidModal={closeLidModal} title={lidTitle} isPreview={isPreview} {...leadMagnet} />
  ), [lidTitle]);

  const openLidModal = (title = "Заголовок лид-магнита") => {
    setLidTitle(title)
    openModalLid()
    sendConstructorEvent("tr_magnet_form_view")
  }

  const getComponent = (order) => {
    if (!order || !order.active) {
      return <section></section>
    }
    const components = {
      "main": <Promo agent={agent} site={site} promo={socials} theme={theme} agencyName={agency.name} openCallbackModal={() => openCallbackModal()} key={order.component} multiLink={multiLink}/>,

      ...(multiLink || isFetching ? [] : {
        "sets": <Property
          theme={theme}
          items={complexes}
          getBlocks={getBlocks}
          isPreview={isPreview}
          innerRef={setsRef}
          key={order.component}
          openCallbackModal={openCallbackModal}
        />,
        "services": <Services services={services} theme={theme} openCallbackModal={() => openCallbackModal()} innerRef={servicesRef} key={order.component} />,
        "mortgage": <Calculator openCallbackModal={openCallbackModal} key={order.component} />,
        "advantages": <Advantages advantages={advantages} openCallbackModal={() => openCallbackModal()} key={order.component} />,
        "reviews": reviews?.length > 0 || !!widgetReviews ? <Reviews reviews={reviews} innerRef={reviewsRef} key={order.component} widgetReviews={widgetReviews} /> : <section></section>,
        "promo": <Banner promo={promo} promoText={promoText} key={order.component} />,
        "leadMagnet": <Lid theme={theme} openLidModal={() => openLidModal()} {...leadMagnet} innerRef={leadMagnetRef} />,
        "video":  <Video videoChannel={videoChannel} videoTitle={videoTitle} />
      })
    }
    return components[order.component]
  }
  if (isFetching) {
    return <Preloader loading={true} />
  }
  if(multiLink) return  <div className="constructor__background" style={{overflow: "hidden"}}>
  <Background theme={theme} />
  {getComponent(blocks[0])}
</div>
  return (
    <>
      <div className="constructor__background">
        <Background theme={theme} />
        {getComponent(blocks[0])}
        {getComponent(blocks[1])}
        {getComponent(blocks[2])}

        {showPropertyBanner && <PropertyBanner openCallbackModal={() => openCallbackModal()} />}
        {/* {true && <PropertyBanner openCallbackModal={() => openCallbackModal()} />} */}
      </div>
      {blocks.slice(3).map(order => getComponent(order))}
      {coordinates?.length && agency.address ? <Consultation
        isPreview={isPreview}
        coordinates={coordinates}
        address={agency.address}
        site={site}
        phoneAgency={phoneAgency}
        phone={agency.phone}
        theme={theme}
      /> :
        <ConsultationSimple isPreview={isPreview} coordinates={coordinates} />}
      {!!customHtml && <div dangerouslySetInnerHTML={{ __html: customHtml }} />}
    </>
  )
};

const mapStateToProps = (state) => ({
  // orders: sortedOrders(state)
  showPropertyBanner: !state.agency.hasNextPage && !!state.agency.complexes?.length,
  state: state.agency,
  socials: compact(state.agency?.socials.map(soc => soc.link ? soc : null)),
});

const mapDispatchToProps = (dispatch) => {
  const subDomain = getSubdomain()
  return {
    fetch: (subdomain) => dispatch(fetchAgency(subdomain ? subdomain : subDomain)),
    setPreviewData: (data) => dispatch({ type: "AGENCY_SET_PREVIEW_STATE", payload: data }),
    getBlocks: (form, isLoadMore) => {
      form.subDomain = subDomain
      return dispatch(getBlocks(form, isLoadMore))
    },
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Constructor));
