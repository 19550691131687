import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getClient,

  handleFavoriteClients, removeClient
} from "../../actions/clients";
import { scrollToRef } from "../../utils";
import Confirm from "../core/Confirm";
import Preloader from "../Preloader";
import { infoToast } from "../toasts";
import BlockDeals from "./BlockDeals";
import BlockFilters from "./BlockFilters";
import BlockPresentations from "./BlockPresentations";
import BlockRequests from "./BlockRequests";
import BlockTasks from "./BlockTasks";
import "./index.css";




const ClientNavigation = (props) => {
  const { items } = props;

  return (
    <>
      {items && (
        <div className="client__nav">
          <ul className="client__nav-list">
            {items.map((item) => (
              <li className="client__nav-item" key={item.id}>
                <a
                  className="client__nav-link"
                  onClick={() => scrollToRef(item.ref)}
                >
                  <svg className="icon">
                    <use xlinkHref={item.icon}></use>
                  </svg>
                  <span>{item.title}</span>
                  {item.count > 0 && (
                    <span className="client__link-qty">{item.count}</span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const ColumnStatus = (props) => {
  const { statuses, id } = props;

  if (!statuses || !statuses.length) return "";

  const status = statuses.find((item) => item.id == id);
  return (
    <>
      {status && (
        <div className={`label-item label_${status.color}`}>{status.title}</div>
      )}
    </>
  );
};

const FilesBlock = (props) => {
  const { items, innerRef } = props;
  return (
    <div className="client__section has-offset" ref={innerRef}>
      <div className="client__section-header">
        <h3 className="client__section__title">Файлы</h3>
      </div>
      <div className="client__files-ready">
        {items.map((item) => (
          <div className="client__files-item" key={item.id}>
            <div className="file">
              <div className="file__title">
                <div className="file__icon">
                  <svg className="icon">
                    <use xlinkHref="#deals-02"></use>
                  </svg>
                </div>
                <div className="file__name">{item.description}</div>
              </div>
              <div className="file__controls">
                <a className="file__link">Скачать</a>
                <button className="button file__delete button_view_round-control">
                  <svg className="icon">
                    <use xlinkHref="#delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const View = (props) => {
  const { id } = useParams();
  const { remove, history, handleFavorite } = props;

  const [fetching, setFetching] = useState(true);

  const [item, setItem] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [agents, setAgents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [dealTypes, setDealTypes] = useState([]);
  const [isFavorite, setFavorite] = useState(false);

  const dealsRef = useRef(null);
  const requestsRef = useRef(null);
  const filesRef = useRef(null);
  const tasksRef = useRef(null);
  const presenationsRef = useRef(null);
  const filtersRef = useRef(null);
  const compareRef = useRef(null);
  const favoritesRef = useRef(null);

  const navigation = [
    //{ id: 0, title: "Сделки клиента", count: 0, icon: "#deals-02", ref: dealsRef },
    {
      id: 1,
      title: "Заявки клиента",
      count: 0,
      icon: "#applications-02",
      ref: requestsRef,
    },
    {
      id: 2,
      title: "Задачи и напоминания",
      count: 0,
      icon: "#tasks-02",
      ref: tasksRef,
    },
    {
      id: 3,
      title: "Презентации",
      count: 0,
      icon: "#presentation-02",
      ref: presenationsRef,
    },
    {
      id: 4,
      title: "Сохраненные фильтры",
      count: 0,
      icon: "#filters-02",
      ref: filtersRef,
    },
    //{ id: 5, title: "Сравнения", count: 0, icon: "#comparisons-02", ref: compareRef },
    {
      id: 6,
      title: "Избранное",
      count: 0,
      icon: "#favorite-02",
      ref: favoritesRef,
    },
    { id: 7, title: "Файлы", count: 0, icon: "#files-02", ref: filesRef },
  ];

  useEffect(() => {
    getClient(id).then(
      (data) => {
        const newData = {
          ...data.client,
          phone: data.client.phone || [],
          email: data.client.email || [],
          status: data.client.status.toString(),
          comment: data.client.comment || "",
        };
        setAgents(
          data.agents.map((agent) => ({
            id: agent.id,
            title: `${agent.lastName} ${agent.name}`,
          })),
        );
        setStatuses(
          data.statuses.map((status) => ({
            id: status.id,
            title: status.name,
            color: status.color,
          })),
        );
        setItem(newData);
        setFiles(data.client.file);
        setFavorite(newData.isFavorite);
        setTasks(data.client.tasks);
        setDealTypes(data.dealTypes);
        setFetching(false);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [id]);

  const confirm = () => {
    let person = `${item.lastName} ${item.name}`;
    remove(id).then(() => {
      hideModal();
      history.push(`/personal/clients`);
      infoToast(
        <div className="Toastify__text">
          Клиент <strong>{person}</strong> удален
        </div>
      )
    });
  };

  const handleFavoriteClick = (e) => {
    e.preventDefault();
    handleFavorite(id, !isFavorite).then((data) => {
      setFavorite(!isFavorite);
    });
  };

  const [confirmModal, hideModal] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Confirm
          close={hideModal}
          title={`Вы действительно хотите удалить клиента ${getTitle()}?`}
          confirm={confirm}
          cancel={hideModal}
        />
      );
    },
    [item],
  );

  const getTitle = () => {
    let title = `${item.lastName? item.lastName: ""} ${item.name}`;
    if (item.patronymic) title += ` ${item.patronymic.charAt(0)}.`;
    return title;
  };

  const getAgent = () => {
    let agent = agents.find((agent) => agent.id == item.agent);
    if (agent) return `${agent.title}`;
    return "";
  };

  const getAddress = () => {
    let address = [
      item.city ? `г. ${item.city}` : "",
      item.street || "",
      item.house || "",
      item.number || "",
    ];
    return _.compact(address).join(", ");
  };

  return (
    <div>
      {item && (
        <section className="section section_view_white client no-header-profile">
          <div className="wrapper">
            <div className="personal_content">
              <div className="personal__clients">
                <div className="client__header">
                  <div className="client__title">
                    <div className="h1">{getTitle()}</div>
                  </div>
                  <div className="client__ops">
                    <div className="label-item label_brand">Клиент</div>
                    <ColumnStatus statuses={statuses} id={item.status} />
                    <div
                      className={`client__fav label-item label_is-fav link-hover ${
                        isFavorite ? `is-fav` : ``
                      }`}
                      onClick={handleFavoriteClick}
                    >
                      <svg className="icon">
                        <use xlinkHref="#star"></use>
                      </svg>
                      <span>
                        {isFavorite
                          ? `Добавлен в избранное`
                          : `Добавить в избранное`}
                      </span>
                    </div>
                  </div>
                  <div className="client__controls">
                    <div className="client__control">
                      <Link to={`/personal/clients/edit/${id}`}>
                        <div className="button_view_round-with-label">
                          <div className="button button_view_round-control">
                            <svg className="icon button__icon">
                              <use xlinkHref="#edit02"></use>
                            </svg>
                          </div>
                          <span className="button__label">Редактировать</span>
                        </div>
                      </Link>
                    </div>
                    <div className="client__control">
                      <div
                        className="button_view_round-with-label"
                        onClick={confirmModal}
                      >
                        <div className="button button_view_round-control">
                          <svg className="icon button__icon">
                            <use xlinkHref="#delete-outline"></use>
                          </svg>
                        </div>
                        <span className="button__label">Удалить</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="client__grid">
                  <div className="client__aside">
                    <div className="client__data">
                      <ul className="client__id">
                        <li className="client__id-item">
                          <div className="client__key">Менеджер:</div>
                          <div className="client__value">{getAgent()}</div>
                        </li>
                        {getAddress() && (
                          <li className="client__id-item">
                            <div className="client__key">Адрес:</div>
                            <div className="client__value">{getAddress()}</div>
                          </li>
                        )}

                        <li className="client__id-item">
                          <div className="client__key">Телефон:</div>
                          <div className="client__value">
                            {item.phone.length > 0 &&
                              item.phone.map((phone, index) => (
                                <a
                                  className="link-hover color-brand-1"
                                  href={`tel:${phone}`}
                                  key={index}
                                >
                                  {phone}
                                </a>
                              ))}
                          </div>
                        </li>
                        <li className="client__id-item">
                          <div className="client__key">E-mail:</div>
                          <div
                            className="client__value"
                            style={{ maxWidth: "60%" }}
                          >
                            {item.email.length > 0 &&
                              item.email.map((email, index) => (
                                <a
                                  className="link-hover color-brand-1"
                                  href={`emailto:${email}`}
                                  title={email}
                                  key={index}
                                >
                                  {email}
                                </a>
                              ))}
                          </div>
                        </li>
                      </ul>
                      {item.comment && (
                        <div className="client__feed">
                          <svg className="icon">
                            <use xlinkHref="#feed"></use>
                          </svg>
                          <div className="client__feed-content">
                            <div className="client__feed-text">
                              {item.comment}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <ClientNavigation items={navigation} />
                  </div>
                  <div className="client__content">
                    {<BlockDeals items={tasks} innerRef={dealsRef} />}
                    {<BlockRequests items={tasks} innerRef={requestsRef} />}
                    {
                      <BlockTasks
                        items={tasks}
                        deals={dealTypes}
                        innerRef={tasksRef}
                      />
                    }
                    {
                      <BlockPresentations
                        items={tasks}
                        innerRef={presenationsRef}
                      />
                    }
                    {<BlockFilters items={tasks} innerRef={filtersRef} />}
                    {files && <FilesBlock items={files} innerRef={filesRef} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Preloader loading={fetching} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(removeClient(id)),
  handleFavorite: (id, state) => dispatch(handleFavoriteClients(id, state)),
});

export default connect(null, mapDispatchToProps)(View);
