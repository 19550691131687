import { uniqBy } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import request from "../../api";
import { processFiles } from "../../components/Constructor/Admin/MyObjects/Components";
import ContactCard from "../../components/core/ContactCard";
import DealAct from "../../components/core/DealAct";
import DealInfo from "../../components/core/DealInfo";
import Tabs from "../../components/core/Tabs";
import UploaderDocs from "../../components/core/UploaderDocs";
import Preloader from "../../components/Preloader";
import { standartErrorToast, standartSuccessToast } from "../../components/toasts";
import useApi from "../../hooks/useApi";
import { appendFiles, applyToFormData, getSuffix, objectFlagsToBinary, toFormData } from "../../utils";
import './index.scss';
import '../../components/core/TabsNew/index.scss';
import useFilter from "../../hooks/useFilter";
import VillagesFilter from "../../components/Public/markup/VillagesFilter";
import VillagesTopFilter from "../../components/Public/markup/VillagesTopFilter";
import FilterSwitch from "./FilterSwitch";
import SubmitButton from "../../components/SubmitButton";
import CommonSettingsSF, { SettingsSettingsSF } from "./CommonSettings";
import DraggableZone from "../../components/FilterRealty/Draggable";
import { SfFilterTrashIcon } from "../../components/Public/markup/icons";
import { useModal } from "react-modal-hook";
import Modal from "../../components/core/Modal";
import Button from "../../components/core/Button";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import _set from 'lodash/set'
const saveSF = form => request("panpartner:saved.filter.ajax", "editFilter", form);

const payloadList = {
    primary: {
        apiConfig: {
            component: "panpartner:block.filter.ajax",
            getCountFunction: 'getCount',
            submitFunction: 'getFilter',
        },
        extractCount: ({ countBlocks }) => countBlocks,
    },
    secondary: {
        apiConfig: {
            component: "panpartner:secondaries.ajax",
            getCountFunction: 'getCount',
            submitFunction: 'getFilter',
        },
        extractCount: ({ count }) => count,
    },
    assignment: {
        apiConfig: {
            component: "panpartner:assignments.ajax",
            getCountFunction: 'getCount',
            submitFunction: 'getFilter',
        },
        extractCount: ({ count }) => count,
    },
    commercial: {
        apiConfig: {
            component: "panpartner:commercials.ajax",
            getCountFunction: 'getCount',
            submitFunction: 'getFilter',
        },
        extractCount: ({ count }) => count,
    },
    suburban: {
        apiConfig: {
            component: "panpartner:suburbans.ajax",
            getCountFunction: 'getCountVillages',
            submitFunction: 'getVillagesFilter',
        },
        extractCount: ({ countVillages }) => countVillages,
    },
    global: {
        apiConfig: {
            component: "panpartner:primary.ajax",
            getCountFunction: 'getCount',
            submitFunction: 'getFilter',
        },
        extractCount: ({ countBlocks }) => countBlocks,
    },
    project: {
        apiConfig: {
            component: "panpartner:suburban.special.offers.ajax",
            getCountFunction: 'getCount',
            submitFunction: 'getFilter',
        },
        extractCount: ({ count }) => count,
    },
    cottagesettlement: {
        apiConfig: {
            component: "panpartner:new.suburbans.ajax",
            getCountFunction: 'getCountVillages',
            submitFunction: 'getVillagesFilter',
        },
        extractCount: ({ countVillages }) => countVillages,
    }
}
const CrmEditSf = memo(props => { // в процессе, переделывается под СФ 
    const { savedFilterXmlId, type, city } = useParams()
    const typeData = payloadList[type]
    // const [currency, setCurrency] = useState(type === "global" ?'USD' : "RUB");
    const [currency, setCurrency] = useState("RUB");

    const {
        handleFilter,
        submit,
        isLoading,
        filter = {},
        exclude = {},
        count,
        data: {
            blocks = [],
            filter: filterData = {},
            prices,
            client,
            clients,
            agent,
            agents,
            isActive,
            isShowFilter,
            savedFilter,
            savedFilterSettings,
        },
        setFilterAndExclude,
        filterBadges,
        excludeBadges,
        removeBadge,
        isCountLoading,
        changeLimit,
        page,
        limit,
        changePage,
        showSkeleton,
        handleSort,
        sortId,
        infiniteRef,
        moreIsLoading,
        view,
        setView,
        mapItems,
        dispatchMap,
        mapState,
        resetFilter,
        submitMap,
        isMapItemsLoading,

        clearFilter,
        clearExclude,
        filterLength,
        isExcludeEmpty,
        isFilterEmpty,
        switchFilterToExclude,
        createBadges,
        clearByIdent,
        toForm,
        setData
    } = useFilter({
        apiConfig: typeData?.apiConfig,
        defaultFilter: {
            status: [1],
        },
        badgeFormattersParams: {
            currency: currency === "RUB" ? "₽" : currency.toLowerCase()
        },
        defaultParams: {
            getAllFilters: 1,
            savedFilterXmlId,
            // "filter[currency]": currency,
            city,
            getClients: 1,
        },
        isGetFilter: true,
        shadowFilters: ['status'],
        extractCount: typeData?.extractCount,
        restoreSF: true,
        legacyRestoreFromLS: true,
        fields: {
            blocks: [],
            status: [],
            districts: [],
            rooms: [],
            endings: {},
            prices: {},
            builders: [],
            isOnlyHanded: '',
        },
    });

    const userState = useSelector(state => state.user ?? {})
    const isUserSF = savedFilter?.agent?.id === userState.info?.id
    const [openModal, hideModal] = useModal(({ }) => (
        <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={hideModal}>
            <div className="crm-table__sf-controlsmodaltitle">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
                    <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
                    <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
                </svg>
                Удалить подборки?
            </div>
            <div className="crm-table__sf-controlsmodaltext">
                После удаления вся информация будет утеряна
            </div>
            <div className="crm-table__sf-controlsmodalbtns">
                <Button buttonType="line" onClick={() => { deleteCompilation(savedFilter?.id) }}>Да</Button>
                <Button buttonType="line" onClick={hideModal}>Нет</Button>
            </div>
        </Modal>
    ), [savedFilter]);

    const deleteCompilation = (id) => {
        request("panpartner:saved.filter.ajax", "delete", {
            id: id,
            city: city
        }).then(
            (success) => {
                hideModal()
                window.location.href = "/crm/filters";
            },
        )
    }


    const [saveInProcess, setSaveInProcess] = useState(false)
    const handleSave = saveType => {
        setSaveInProcess(true)
        const formData = toForm();
        applyToFormData(formData, {
            filterType: type,
            "clients[]": client?.id,
            clientPhone: client?.phone?.toString?.(),
            id: savedFilter?.id,
            agentId: agent?.id,
            isShowFilter: isShowFilter ? "Y" : "N",
            name: savedFilter.name,
        })
        applyToFormData(formData, objectFlagsToBinary(savedFilterSettings), "savedFilterSettings")
        formData.delete("savedFilterSettings[icon]");
        formData.delete("savedFilterSettings[id]");
        formData.delete("savedFilterSettings[user]");
        formData.delete("savedFilterSettings[savedFilterXmlId]");
        formData.append("realtyType", filter.realtyType)
        if (saveType !== "default") formData.append("savedFilterSettings[savedFilterXmlId]", savedFilterXmlId);
        saveSF(formData)
            .then(standartSuccessToast, standartErrorToast)
            .finally(setSaveInProcess)
    }
    const changeSettings = (path, val) => {
        setData(prev => {
            const tmp = { ...prev }
            _set(tmp, path, val)
            return tmp
        })
    }

    if (isLoading) return <Preloader loading />
    return (
        <div className="wrapper">
            <div className="common-header">
                <div className="common-header__title">
                    <span
                        // onClick={history.goBack}
                        onClick={() => { window.location.href = "/crm/filters" }}
                        className="common-header__chevron"
                    >
                        <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round" />
                        </svg>
                    </span>
                    <h3 className="common-title">
                        Обзор подборки
                        {/*{savedFilterSettings?.title}*/}
                    </h3>
                </div>
            </div>



            <div className="deal-wrapper">
                {
                    savedFilter?.id && userState && isUserSF &&
                    <div className="common-link common-link_dark" onClick={openModal}>
                        <SfFilterTrashIcon />
                        Удалить подборку
                    </div>
                }
                {/*{<div className="common-link common-link_dark" onClick={openModal}>*/}
                {/*    <SfFilterTrashIcon/>*/}
                {/*    Удалить подборку*/}
                {/*</div>}*/}
                <div className="deal-content">
                    <Tabs isInline>
                        <div label={'Общее'}>

                            {/*<h5 className="small-five-title">Контакты по сделке</h5>*/}
                            <CommonSettingsSF
                                savedFilterXmlId={savedFilterXmlId}
                                clients={clients}
                                savedFilterSettings={savedFilterSettings}
                                changeSettings={changeSettings}
                                save={handleSave}
                                isLoading={isCountLoading || isLoading || saveInProcess}
                                client={client}
                                savedFilter={savedFilter}
                                agents={agents}
                                agent={agent}
                                isActive={isActive}
                                isUserSF={isUserSF}
                            />
                        </div>
                        <div label={'Параметры'} className="sf-settings-container">
                            <div className="sf-setting-common">
                                <div className="checkbox checkbox_view_red ">
                                    <div className="checkbox__box">
                                        <input
                                            className="checkbox__control"
                                            type="checkbox"
                                            id="header-site"
                                            name="remember"
                                            checked={isShowFilter ? isShowFilter : false}
                                            onChange={e => changeSettings("isShowFilter", e.target.checked)}
                                        />
                                        <label className="checkbox__input" htmlFor="header-site" />
                                        <div className="checkbox__marker" />
                                    </div>
                                    <label className="checkbox__label" htmlFor="header-site">Включить поиск в подборке</label>
                                </div>
                                <div style={{ display: "block" }} className="sf-settings__sf-overfilters">

                                    <FilterSwitch
                                        type={type}

                                        filterData={filterData}
                                        filter={filter}
                                        exclude={exclude}
                                        setFilterAndExclude={setFilterAndExclude}
                                        handleFilter={handleFilter}
                                        onSumbit={submit}
                                        isLoading={isCountLoading || isLoading}
                                        disableButton={!count}
                                        clearByIdent={clearByIdent}
                                        count={`${count} объект${getSuffix(count)}`}
                                        isExcludable
                                        wrapperClass="sf-setting-common__select sf-setting-common__select_sf"

                                        currency={currency}
                                        setCurrency={setCurrency}
                                        currencyList={prices}

                                        city={city}
                                        savedFilter={savedFilter}

                                        changeSettings={changeSettings}
                                        savedFilterSettings={savedFilter}
                                    />

                                    <DraggableZone
                                        filterBadges={filterBadges}
                                        excludeBadges={excludeBadges}
                                        clearFilter={clearFilter}
                                        clearExclude={clearExclude}
                                        isExcludeEmpty={isExcludeEmpty}
                                        isFilterEmpty={isFilterEmpty}
                                        removeBadge={removeBadge}
                                        switchValues={switchFilterToExclude}
                                    />
                                    <SubmitButton
                                        isLoading={isCountLoading || isLoading || saveInProcess}
                                        className="button button_view_default"
                                        onClick={handleSave}
                                        disabled={!count}
                                    >
                                        Сохранить {count} объект{getSuffix(count)}
                                    </SubmitButton>
                                </div>
                            </div>
                        </div>
                        <div label={'Настройки'}>
                            <h5 className="small-five-title">Настройки отображения фильтра</h5>
                            <p style={{ marginBottom: 30 }}>Вы можете настроить внешний вид вашего фильтра, <br />сделав его уникальным</p>
                            <SettingsSettingsSF
                                savedFilterSettings={savedFilterSettings}
                                changeSettings={changeSettings}
                                save={handleSave}
                                isLoading={isCountLoading || isLoading || saveInProcess}
                            />
                        </div>

                    </Tabs>
                </div>
            </div>

        </div>
    )
});

export default CrmEditSf;
