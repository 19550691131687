import RadioDropdown from "../../components/core/RadioDropdown";
import AssignmentsBottomFilters from "../../components/Public/markup/AssignmentsBottomFilters";
import AssignmentsTopFilters from "../../components/Public/markup/AssignmentsTopFilters";
import CommercialBottomFilters from "../../components/Public/markup/CommercialBottomFilters";
import CommercialTopFilter from "../../components/Public/markup/CommercialTopFilter";
import DubaiBottomFilters from "../../components/Public/markup/DubaiBottomFilters";
import DubaiTopFilters from "../../components/Public/markup/DubaiTopFilters";
import PrimaryBottomFilters from "../../components/Public/markup/PrimaryBottomFilters";
import PrimaryTopFilters from "../../components/Public/markup/PrimaryTopFilters";
import ProjectsBottomFilter from "../../components/Public/markup/ProjectsBottomFilter";
import ProjectsTopFilter from "../../components/Public/markup/ProjectsTopFilter";
import SecondaryBottomFilters from "../../components/Public/markup/SecondaryBottomFilters";
import SecondaryTopFilters from "../../components/Public/markup/SecondaryTopFilters";
import VillagesFilter from "../../components/Public/markup/VillagesFilter";
import VillagesGeneralFilter from "../../components/Public/markup/VillagesGeneralFilter";
import VillagesHouseFilter from "../../components/Public/markup/VillagesHouseFilter";
import VillagesNewTopFilter from "../../components/Public/markup/VillagesNewTopFilter";
import VillagesParcelfilter from "../../components/Public/markup/VillagesParcelfilter";
import VillagesTogglesFilter from "../../components/Public/markup/VillagesTogglesFilter";
import VillagesTopFilter from "../../components/Public/markup/VillagesTopFilter";

const FilterSwitch = ({ type, changeSettings, savedFilter, ...props }) => {
    switch (type) {
        case "primary":
            return <>
                <PrimaryTopFilters {...props} />
                <PrimaryBottomFilters {...props} />
            </>
        case "secondary":
            return <>
                <SecondaryTopFilters {...props} />
                <SecondaryBottomFilters {...props} />
            </>
        case "assignment":
            return <>
                <AssignmentsTopFilters {...props} />
                <AssignmentsBottomFilters {...props} />
            </>
        case "commercial":
            return <>
                <CommercialTopFilter {...props} />
                <CommercialBottomFilters {...props} />
            </>
        case "suburban":
            return <>
                <VillagesTopFilter {...props} />
                <VillagesFilter {...props} />
            </>
        case "global":
            return <>
                <DubaiTopFilters {...props} />
                {props?.city !== "cyp" && <DubaiBottomFilters {...props} /> //#dxb cyp ref
                }
            </>
        case "project":
            return <>
                <ProjectsTopFilter {...props} />
                <ProjectsBottomFilter {...props} />
            </>
        case "cottagesettlement":
            const realtyType = props.filter?.realtyType ?? "unset"

            return <>
                <RadioDropdown
                    onChange={v => {props.setFilterAndExclude({ filter: v }, "realtyType")}}
                    items={[{ id: "parcel", title: "Участок" }, { id: "house", title: "Дома" }, { id: "unset", title: "Не выбран" }]}
                    value={realtyType}
                    data={{ title: "Тип" }}
                /> 

                <VillagesNewTopFilter {...props}/>
                <VillagesGeneralFilter hideTitle {...props} />
                <VillagesParcelfilter hideTitle {...props} disabledFilters={realtyType === "house" && ["onlySelfBuild"]} />
                <div className={realtyType === "parcel" ? "disabled-filter-row" : ""}>
                    <VillagesHouseFilter hideTitle {...props} />
                    <VillagesTogglesFilter hideTitle {...props} />
                </div>
                <div className="form__row">
                    <div className="index-search__show-booked" style={{ marginTop: 0 }}>
                        <label className="switch" htmlFor="filter-booked">
                            <div className="switch__box">
                                <input
                                    className="switch__control"
                                    type="checkbox"
                                    id="filter-booked"
                                    checked={props.filter.status?.includes?.("3")}
                                    onChange={e => props.handleFilter("status", "3")}
                                />
                                <div
                                    className="switch__input"
                                    htmlFor="filter-booked"
                                ></div>
                                <div className="switch__marker"></div>
                            </div>
                            <span className="switch__label">
                                Забронированные
                            </span>
                        </label>
                    </div>
                </div>
            </>
        default:
            return null
    }
};
export default FilterSwitch;