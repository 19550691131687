import React from "react";
import { Link } from "react-router-dom";

const FilesBreadcrumbs = (props) => {
  const { breadcrumbs } = props;

  if (breadcrumbs.length  < 2) return ``;
  return (
    <div className="files__breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => (
        <div className="files__breadcrumb" key={breadcrumb.id}>
          {breadcrumbs.length === index + 1 && (
            <span
              className="files__breadcrumb-link"
              dangerouslySetInnerHTML={{ __html: breadcrumb.name }}
            ></span>
          )}
          {breadcrumbs.length > index + 1 && (
            <Link
              to={`/files${breadcrumb.id > 0 ? `/${breadcrumb.id}` : ``}`}
              className="files__breadcrumb-link link-hover"
            >
              <span
                dangerouslySetInnerHTML={{ __html: breadcrumb.name }}
              ></span>
            </Link>
          )}
          {(breadcrumbs.length > 1 && (breadcrumbs.length - 1) !== index) && <div className="files__breadcrumb-delimiter">/</div>}
        </div>
      ))}
    </div>
  );
};

export default FilesBreadcrumbs;
