import React, {useEffect, useState} from "react";
import {useModal} from "react-modal-hook";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import {savePresentationSettings} from "../../actions/presentation";
import {media, settingsToParams} from "../../utils";
import Modal from "../core/Modal";
import SubmitButton from "../SubmitButton";
import {errorToast, infoToast, standartErrorToast, successToast} from "../toasts";
import getSettings from "./data";
import EditForm from "./EditForm";
import InputImage from "./InputImage";
import InputSwitcher from "./InputSwitcher";
import renderPDF from "./renderPDF";
import PanModal from "../core/PanModal";
import CaseModal from "../CaseModal";
import MortgageCalc from "../MortgageCalc";
import Button from "../core/Button";
import useApi from "../../hooks/useApi";

const Item = (props) => {
  const {
    data = {},
    name,
    button,
    switchers,
    images,
    settings,
    currSettings,
    settingsData,
    formData,
    setFormData,
    save,
    addPhoto,
    removePhoto,
    handleSettings,
    modalMortgageState,
    setModalMortgageState,
    mortgageRates,
    initialModalMortgageState,
    onSave
  } = props;
  const [open, toggle] = useState(true);
  const [hovered, setHovered] = useState(false);

  const handleHover = (val) => {
    if (hovered) {
      return;
    }
    setHovered(val)
    setTimeout(() => setHovered(false), 2000)
  };

  const handleSwitch = (checked, identity, value) => {
    const switchingElements = document.querySelectorAll(
      `[data-id=${identity}]`
    );

    /**
     * show/hide blocks
     */
    switchingElements.forEach((el) => {
      if (!checked) {
        el.classList.add("hidden");
      } else {
        el.classList.remove("hidden");
      }
    });
    if (identity === "price" && !checked) {
      const morgageEl = document.querySelectorAll(
        `[data-id=mortgage]`
      );
      const dynamicElems = document.querySelectorAll(
        `[data-id=dynamic_price]`
      );

      morgageEl.forEach(el => el.classList.add("hidden"))
      dynamicElems.forEach(el => el.classList.add("hidden"))
    }
    setFormData({
      settings: {
        ...formData.settings,
        [identity]: checked ? value : "",
        ...(identity === "price" && !checked ? {mortgage: "", dynamic_price: ""} : [])
      },
    });

    handleSettings();
    renderPDF();
  };

  const handleImageUpload = (file, index) => {
    let form = new FormData();

    form.append("additionalPhoto[]", file);

    addPhoto(form, data.id, data.city, index).then(
      data => {
        successToast(<div>Фото успешно добавлено</div>)
        renderPDF();
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const handleImageDelete = (id) => {
    removePhoto(data.id, id, data.city).then(
      data => {
        renderPDF();
        infoToast(<div>{data.message}</div>)
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const handleEditText = (value) => {
    const {identity} = button;

    setFormData({
      [identity]: value,
    });

    let options = {
      settings: settingsToParams(currSettings),
      description: identity == "description" ? value : formData.description,
      comment: identity == "comment" ? value : formData.comment,
    };

    save(options.settings, data.id, options.description, options.comment).then(
      data => infoToast(<div>Изменения успешно сохранены</div>),
      error => errorToast(<div>{error?.[0]?.message}</div>)
    )
  };

  const [editModal, hideEditModal] = useModal(
    ({in: open, onExited}) => (
      <Modal close={hideEditModal} classes="modal_large presentation__modal">
        <EditForm
          title={button.label}
          length={button.length}
          defaultValue={data?.[button.key]}
          handleSubmit={handleEditText}
          setFormData={setFormData}
          hide={hideEditModal}
          button={button}
        />
      </Modal>
    ),
    [data, handleEditText, button]
  );


  const [priceEstate, setPriceEstate] = useState(modalMortgageState?.cost)
  const [firstPayEstate, setFirstPayEstate] = useState(modalMortgageState?.initialPayment)
  const [timeEstate, setTimeEstate] = useState(modalMortgageState?.period)
  const [rateEstate, setRateEstate] = useState(modalMortgageState?.percent)


  useEffect(() => {
    if(!priceEstate && !firstPayEstate && !timeEstate && !rateEstate) {
      setPriceEstate(modalMortgageState?.cost)
      setFirstPayEstate(modalMortgageState?.initialPayment)
      setTimeEstate(modalMortgageState?.period)
      setRateEstate(modalMortgageState?.percent)
    }

  },[modalMortgageState])

  useEffect(() => {
    setModalMortgageState({priceEstate,firstPayEstate,timeEstate,rateEstate})
  },[priceEstate, firstPayEstate, timeEstate, rateEstate])


  // console.log(modalMortgageState)
  const [openMortgageModal, closeMortgageModal] = useModal(
    () => (
      <PanModal title={'Настройки ипотеки'} closeModal={() => {
        setModalMortgageState(initialModalMortgageState)
        setPriceEstate(initialModalMortgageState?.cost)
        setFirstPayEstate(initialModalMortgageState?.initialPayment)
        setTimeEstate(initialModalMortgageState?.period)
        setRateEstate(initialModalMortgageState?.percent)
        closeMortgageModal()
      }} >
        <div style={{width: '510px', margin: '25px auto'}}>
          <MortgageCalc
            price={priceEstate}
            city={"spb"}
            type="apartment"
            id={'calcId1'}
            period={timeEstate}
            prepay={firstPayEstate}
            percent={rateEstate}
            btnClass={`constructor__btn`}
            forPdfSettings={true}
            setPriceEstate={setPriceEstate}
            setFirstPayEstate={setFirstPayEstate}
            setTimeEstate={setTimeEstate}
            setRateEstate={setRateEstate}
            mortgage={mortgageRates}
            modalMortgageState={modalMortgageState}
          />

          <Button isFull className="button button_view_default" onClick={() => {
            onSave()
            closeMortgageModal()
          }}>
            Cохранить
          </Button>
        </div>
      </PanModal>
    ),
    [modalMortgageState]);

  if (!data) return null;

  return (
    <div className="presentation__filter">
      <div
        className={"presentation__filter-header" + (open ? " is-active" : "")}
        onClick={() => toggle(!open)}
      >
        <div className="presentation__filter-name">
          <span>{name}</span>
        </div>
        {!media("isMobile") &&
        <div className="presentation__filter-control">
          <div className="button button_view_round-control">
            <svg className="icon">
              <use xlinkHref="#arrow_bottom"></use>
            </svg>
          </div>
        </div>
        }
      </div>
      <div
        className={"presentation__filter-dropdown" + (open ? "" : " is-hidden")}
      >

        {images && (
          <div className="presentation__filter-images">
            {new Array(images).fill(0).map((_, index) => (
              <InputImage
                image={data.additionalPhotos[index]}
                handleUpload={handleImageUpload}
                handleDelete={handleImageDelete}
                key={data.additionalPhotos[index]?.id || index}
                index={index}
              />
            ))}
          </div>
        )}
        {settings &&
        switchers &&
        switchers.map((item, index) => {
          if (!settings[item.name]) return null;
          return (
            <div className="presentation__filter-switch" key={index}>
              <InputSwitcher
                {...item}
                value={settings[item.name].value}
                valueId={settingsData[item.name]}
                handleChange={handleSwitch}
                disabled={settings[item.name].disabled == "Y"}
                switcherId={`presentation-switch-desktop-${data.id}-${index}`}
                hovered={hovered}
                setHovered={handleHover}
                formData={formData}
                isMortgage={settings[item.name].code === 'mortgage'}
              >

                {settings[item.name].disabled == "Y" && (
                  <div className={`info__popup ${hovered && item.name === "agent_certificate" ? "forced-visible" : ""}`}
                  >
                    {item.name === "agent_certificate" ? <>Информация доступа только для сертифицированных
                      партнеров. <br/> <Link
                        target="_blank"
                        to={`/pan-school`}
                        className="card__dates"
                      >
                        Узнать подробнее
                      </Link> </> : "Информация недоступна для данного объекта"}
                  </div>
                )}

              </InputSwitcher>
              {settings[item.name].code === 'mortgage' && <span className="presentation__filter-switch_settings-link" onClick={openMortgageModal}>Настроить</span>}
            </div>
          );
        })}

        {button && (
          <>
            {media("isMobile") ?
              <EditForm
                title={button.label}
                length={button.length}
                defaultValue={data?.[button.key]}
                setFormData={setFormData}
                handleSubmit={handleEditText}
                button={button}
              /> :
              <div className="presentation__filter-button" onClick={editModal}>
                <div
                  className="button button_view_outline-with-icon js-modal-btn"
                  data-id={button.modal}
                  style={{fontWeight: 'bold'}}
                >

                  <div>
                    {
                      button.icon && button.icon === '#comment' ?
                        <svg style={{marginRight: 10}} width="25" height="25" viewBox="0 0 25 25" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.1666 10.5547H14.8333" stroke="#2C2E3E" strokeLinecap="round"
                                strokeLinejoin="round"/>
                          <path d="M10.1666 12.8877H14.8333" stroke="#2C2E3E" strokeLinecap="round"
                                strokeLinejoin="round"/>
                          <path
                            d="M14.0818 17.2551L13.0026 19.0566C12.9622 19.1228 12.9091 19.1803 12.8464 19.2259C12.7837 19.2716 12.7125 19.3043 12.6371 19.3224C12.5617 19.3405 12.4834 19.3435 12.4069 19.3312C12.3303 19.319 12.2569 19.2917 12.1908 19.2511C12.1117 19.2023 12.0452 19.1357 11.9964 19.0566L10.9172 17.2551C10.8664 17.1688 10.7939 17.0972 10.7069 17.0474C10.62 16.9976 10.5216 16.9713 10.4214 16.9712H6.08333C5.92862 16.9712 5.78025 16.9097 5.67085 16.8003C5.56146 16.6909 5.5 16.5426 5.5 16.3879L5.5 7.05452C5.5 6.89982 5.56146 6.75144 5.67085 6.64205C5.78025 6.53265 5.92862 6.47119 6.08333 6.47119L18.9167 6.47119C19.0714 6.47119 19.2198 6.53265 19.3291 6.64205C19.4385 6.75144 19.5 6.89982 19.5 7.05452V16.3879C19.5 16.5426 19.4385 16.6909 19.3291 16.8003C19.2198 16.9097 19.0714 16.9712 18.9167 16.9712H14.5786C14.4784 16.9718 14.38 16.9983 14.293 17.048C14.206 17.0978 14.1333 17.1691 14.0818 17.2551V17.2551Z"
                            stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        : button.icon && button.icon === '#edit' ?
                        <svg style={{marginRight: 10}} width="25" height="25" viewBox="0 0 25 25" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.99275 19.9221H6.14182C5.9716 19.9221 5.80835 19.8545 5.68798 19.7341C5.56762 19.6137 5.5 19.4505 5.5 19.2803L5.5 15.6936C5.50014 15.5255 5.5662 15.3642 5.68399 15.2443L15.3113 5.61696C15.4308 5.49591 15.5934 5.42724 15.7635 5.42604C15.9336 5.42484 16.0972 5.4912 16.2184 5.61054L16.2248 5.61696L19.803 9.19511C19.8632 9.25423 19.9111 9.32462 19.944 9.40227C19.977 9.47992 19.9943 9.5633 19.995 9.64765C19.9957 9.732 19.9797 9.81565 19.9481 9.89383C19.9164 9.97202 19.8697 10.0432 19.8105 10.1033L19.803 10.1097L9.99275 19.9221Z"
                            stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M13.2019 7.72632L17.6947 12.2191" stroke="#2C2E3E" strokeLinecap="round"
                                strokeLinejoin="round"/>
                          <path d="M19.6201 19.9211L9.99275 19.9211L5.54065 15.469" stroke="#2C2E3E"
                                strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M15.4482 9.9729L7.74634 17.6748" stroke="#2C2E3E" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg> :
                        <svg className="icon">
                          <use xlinkHref={button.icon}></use>
                        </svg>
                    }

                    <span>{button.label}</span>
                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

const Settings = (props) => {
  const {
    data,
    settings = {},
    currSettings,
    settingsData,
    save,
    formData,
    setFormData,
    addPhoto,
    removePhoto,
    handleSettings,
    handleSave,
    id,
    canSave,
    onClose,
    modalMortgageState,
    setModalMortgageState,
    mortgageRates,
    initialModalMortgageState
  } = props;

  const [savingInProcess, setSavingInProcess] = useState(false);
  const SETTINGS = getSettings({
    noteName: data?.typeXmlId === "secondary" ? "Описание" : null,
    hideShowRoom: false
  })
  const saveSettings = () => {
    setSavingInProcess(true)
    let options = {
      ...settingsToParams(formData?.settings),
      description: formData.description,
      comment: formData.comment,
      id: data.id,
      type: data?.typeXmlId,
    };
    savePresentationSettings({
      ...options,

    })
      .then(() => {
        successToast("Настройки сохранены")
      }, standartErrorToast)
      .finally(() => setSavingInProcess(false))
  }

  const onSave = () => {
    save().then(
      () => infoToast(<div>Изменения успешно сохранены</div>),
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  if (!data) return null;

  return (
    <>
      {media("isMobile") ?
        <div className="mobile-presentation-settings">
          {SETTINGS.map((item) => (
            <Item
              settings={settings}
              {...item}
              key={item.id}
              data={data}
              save={save}
              addPhoto={addPhoto}
              removePhoto={removePhoto}
              formData={formData}
              currSettings={currSettings}
              settingsData={settingsData}
              setFormData={setFormData}
              handleSettings={handleSettings}
              modalMortgageState={modalMortgageState}
              setModalMortgageState={setModalMortgageState}
              mortgageRates={mortgageRates}
              initialModalMortgageState={initialModalMortgageState}
              onSave={onSave}
            />
          ))}

          <button
            className="button button_view_default"
            onClick={onClose}
            disabled={!canSave}
          >
            <span>Применить</span>
          </button>
        </div> :
        <SimpleBar className="presentation__aside">
          <div className="presentation__aside-title">
            <h3>Настройки презентации</h3>
          </div>
          <div className="presentation__aside-hint">
            <p>Включите те разделы, которые вы хотели бы видеть в презентации</p>
          </div>
          <div className="presentation__aside-wrapper">
            <div className="presentation__aside-filters pdf-filters">
              {SETTINGS.map((item) => (
                <Item
                  settings={settings}
                  {...item}
                  key={item.id}
                  data={data}
                  save={save}
                  addPhoto={addPhoto}
                  removePhoto={removePhoto}
                  formData={formData}
                  currSettings={currSettings}
                  settingsData={settingsData}
                  setFormData={setFormData}
                  handleSettings={handleSettings}
                  modalMortgageState={modalMortgageState}
                  setModalMortgageState={setModalMortgageState}
                  mortgageRates={mortgageRates}
                  initialModalMortgageState={initialModalMortgageState}
                  onSave={onSave}
                />
              ))}
              <div className="presentation__save-filters">
                <SubmitButton
                  isLoading={savingInProcess}
                  type="button"
                  className="button button_view_outline-with-icon"
                  onClick={saveSettings}
                  addStyle={{fontWeight: 'bold'}}
                >
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 16.269C14.1123 16.269 15.8247 14.5567 15.8247 12.4443C15.8247 10.332 14.1123 8.61963 12 8.61963C9.88767 8.61963 8.17529 10.332 8.17529 12.4443C8.17529 14.5567 9.88767 16.269 12 16.269Z"
                      stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M16.4377 7.43185C16.6403 7.61813 16.8315 7.80936 17.0114 8.00556L19.1873 8.31685C19.542 8.93226 19.8152 9.59116 20 10.277L18.6773 12.0374C18.6773 12.0374 18.7007 12.5793 18.6773 12.8502L20 14.6117C19.8158 15.2977 19.5426 15.9567 19.1873 16.5718L17.0114 16.8821C17.0114 16.8821 16.6375 17.273 16.4377 17.4558L16.1275 19.6316C15.5121 19.9863 14.8532 20.2595 14.1673 20.4443L12.4058 19.1216C12.1354 19.145 11.8635 19.145 11.5931 19.1216L9.83267 20.4443C9.1466 20.2602 8.48761 19.9869 7.87251 19.6316L7.56122 17.4558C7.36574 17.2698 7.17344 17.0786 6.98752 16.8821L4.81275 16.5718C4.45804 15.9564 4.18484 15.2975 4 14.6117L5.32271 12.8502C5.32271 12.8502 5.29827 12.3083 5.32271 12.0374L4 10.277C4.18418 9.59094 4.45742 8.93195 4.81275 8.31685L6.98752 8.00556C7.17344 7.80901 7.36574 7.61778 7.56122 7.43185L7.87251 5.25708C8.48792 4.90238 9.14683 4.62917 9.83267 4.44434L11.5931 5.76705C11.8635 5.74261 12.1355 5.74261 12.4058 5.76705L14.1673 4.44434C14.8534 4.62852 15.5124 4.90176 16.1275 5.25708L16.4377 7.43185Z"
                      stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>

                  <span>Сохранить шаблон</span>
                </SubmitButton>
                <button>

                </button>
              </div>

            </div>
          </div>
        </SimpleBar>
      }
    </>
  );
};

export default Settings;
