import React from 'react';
import './index.scss';

const Polzovatel = () => {
  return (
    <section className="policy">
      <div className="wrapper">
        <section className="promo">
          <h1 className="promo__title h1">Пользовательское соглашение</h1>
          <p className="promo__date">
            г. Санкт-Петербург
            <br />
            «01» января 2025 г.
          </p>
          <p className="promo__text">
            Настоящий документ (далее – Соглашение) разработан Индивидуальным
            предпринимателем Леоновой Натальей Игоревной (ОГРНИП: 317784700016519,
            ИНН: 781148711456), являющимся Владельцем Сайта «PANPARTNER» (далее –
            Владелец Сайта). Текст настоящего пользовательского соглашения является
            публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского
            кодекса Российской Федерации.
          </p>
        </section>

        <section className="block block_01">
          <img
            src={require('../../assets/images/policy/01_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">01. Общие положения</h4>
            <div className="block__container">
              <span className="block__counter">1.1.</span>
              <p>
                Предметом Соглашения является предоставление Пользователю доступа к
                информации и сервисам, размещенным на Сайте. Под действие Соглашения
                подпадают все существующие, реально функционирующие на момент доступа
                услуги, сервисы Сайта, а также любые их предыдущие и последующие
                модификации и появляющиеся в дальнейшем дополнительные услуги
                (сервисы) (далее – Сервис).
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">1.2.</span>
              <p>
                Соглашение может быть изменено Администрацией Сайта без специального
                уведомления путем размещения по тому же адресу новой редакции
                Соглашения. Новая редакция Соглашения вступает в силу с момента ее
                размещения в сети Интернет, если иное не предусмотрено новой редакцией
                Соглашения.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">1.3.</span>
              <p>
                Администрация Сайта является правообладателем объектов авторского
                права, с помощью которых функционирует Сайт (программы для ЭВМ), а
                также объектов авторского права, размещенных на Сайте, и предоставляет
                безвозмездную неисключительную лицензию на использование этих объектов
                в пределах, установленных пользовательским соглашением.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">1.4.</span>
              <p>
                Персональная информация о Пользователе, предоставленная Администрации
                Сайта в связи с использованием Сервиса (персональные данные), хранится
                и обрабатывается в соответствии с условиями Политики
                конфиденциальности, размещенной в сети Интернет по адресу:
                https://panpartner.ru/policy.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">1.5.</span>
              <p>
                Пользователь гарантирует, что передаваемые им данные третьих лиц
                получены законным путем, а также Пользователем получено согласие на
                передачу персональных данных третьим лицам. Вся ответственность,
                штрафы за нарушение данного требования лежит на Пользователе.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">1.9.</span>
              <p>
                Цены, указанные на Сайте, не являются Офертой. Администрация Сайта не
                принимает на себя никакой ответственности за задержку третьих лиц по
                обновлению цен/характеристик объектов, за удаление аккаунта
                Пользователя или невозможность загрузить любые данные Пользователя, а
                также не несет ответственности за соответствие Сервиса целям и
                требованиям Пользователя.
              </p>
            </div>
          </div>
        </section>

        <section className="block block_02">
          <img
            src={require('../../assets/images/policy/02_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">02. Права и обязанности сторон</h4>
            <div className="block__container">
              <span className="block__counter">2.1.</span>
              <p>
                Администрация Сайта вправе изменять содержание данного Сайта,
                ограничить доступ к Сайту в случае нарушения Пользователем условий
                Соглашения.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.2.</span>
              <p>Администрация Сайта вправе:</p>
              <p>
                — Запретить доступ Пользователя к отдельным функциональным
                возможностям Сервиса при нарушении данного Соглашения, иных
                документов, размещенных на Сайте, регламентов партнеров Администрации
                Сайта, Застройщиков;
              </p>
              <p>
                — Удалить страницу Пользователя, без компенсации каких-либо убытков, в
                случае нарушения положений данного Соглашения, Политики обработки
                персональных данных или Оферты, расположенной на Сайте;
              </p>
              <p>
                — Удалить любое информационно-значимое наполнение информационного
                ресурса, в том числе в виде текстов, объявлений, анонсов, фото, видео,
                в том числе новости и прочие материалы (далее - Контент) без
                объяснения причин, в том числе в случае нарушения Пользователем
                условий Соглашения, получения Администрацией соответствующих запросов
                правоохранительных, иных государственных органов, органов местного
                самоуправления;
              </p>
              <p>
                — Немедленно ограничить доступ Пользователя к Сайту, его личному
                кабинету, расторгнуть данное Соглашение, в одностороннем порядке
                Договор комиссии, ограничить доступ к Приложениям Администрации сайта
                и Сайту, при любом оскорблении, неуважительном общении Пользователя с
                клиентами, Застройщиками, сотрудниками и представителями Администрации
                Сайта.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.3.</span>
              <p>
                Пользователь вправе пользоваться Сайтом исключительно в целях и
                порядке, предусмотренных Соглашением и не запрещенных законодательством
                Российской Федерации.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.4.</span>
              <p>Пользователь соглашается не использовать Сайт для:</p>
              <p>
                — Загрузки, отправки или любого другого способа опубликования
                материалов, которые являются незаконными, вредоносными, угрожающими,
                оскорбляющими нравственность, клеветническими, нарушающими авторские и
                иные права интеллектуальной собственности, представляющими собой акт
                недобросовестной конкуренции, пропагандирующими ненависть и/или
                дискриминацию людей по расовому, этническому, половому, социальному
                признакам, а также нарушающими принятые нормы и этику общения в сети
                Интернет, либо затрудняющими работу других Пользователей с Сервисом;
              </p>
              <p>
                — Нарушения прав несовершеннолетних лиц и/или причинения им вреда в
                любой форме;
              </p>
              <p>
                — Загрузки, отправки или любого другого способа опубликования
                материалов, которые нарушают любые права третьих лиц, в том числе
                право на товарные знаки (знаки обслуживания), коммерческую тайну,
                и/или для нарушения любых иных прав интеллектуальной собственности
                третьих лиц;
              </p>
              <p>
                — Загрузки, отправки или любого другого способа опубликования
                материалов, которые Пользователь не имеет права делать доступными по
                закону или согласно каким-либо соглашениям с третьими лицами;
              </p>
              <p>
                — Нарушения каких-либо норм действующего российского и/или
                международного законодательства, а также законодательства иностранных
                государств;
              </p>
              <p>
                — Предоставления доступа к Сайту третьим лицам, не являющимся
                сотрудниками Пользователя или не работающими с ним по
                гражданско-правовому договору. Ответственность за действия третьих
                лиц, а также за утечку любой информации с Сайта полностью ложится на
                Пользователя.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">2.5.</span>
              <p>
                Действия и/или бездействие Пользователя, повлекшие нарушение прав
                Администрации сайта или направленные на нарушение прав Администрации
                сайта на любые материалы, сайт, объекты реализации или их компоненты,
                влекут уголовную, гражданскую и административную ответственность в
                соответствии с законодательством Российской Федерации.
              </p>
            </div>
          </div>
        </section>

        <section className="block block_03">
          <img
            src={require('../../assets/images/policy/03_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">03. Использование сайта</h4>
            <div className="block__container">
              <span className="block__counter">3.1.</span>
              <p>
                В соответствии с условиями соглашения Администрация Сайта
                предоставляет Пользователю право использования Сайта следующими
                способами:
              </p>
              <p>
                — Просмотр Контента Сайта, в том числе запись на мастер-классы
                партнеров Администрации Сайта, вебинаров и других информационных
                мероприятий, анонсированных на Сайте и организованных Администрацией
                Сайта;
              </p>
              <p>— Бронирование Объектов на Сайте;</p>
              <p>— Направление сообщений Администрации Сайта;</p>
              <p>
                — Скачивание и использование материалов Сайта, которое администрация
                разрешила использовать Пользователям.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.2.</span>
              <p>
                Использование материалов Сайта без согласия правообладателей не
                допускается.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.3.</span>
              <p>
                Для отправки сообщений Администрации Сайта с помощью Сервиса, а также
                при регистрации в личном кабинете на Сайте, Пользователь обязуется
                предоставить достоверную и полную информацию о себе по вопросам,
                предлагаемым в форме для отправки сообщения. Если Пользователь
                предоставляет неверную информацию или у Администрации Сайта есть
                основания полагать, что предоставленная Пользователем информация
                неполна или недостоверна, Администрация Сайта вправе отказать
                Пользователю в использовании своих Сервисов (либо их отдельных
                функций).
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.4.</span>
              <p>
                Администрация Сайта никаким образом не проверяет предоставляемую
                Пользователем информацию и не несет ответственности перед любыми
                третьими лицами за точность и достоверность такой информации.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.5.</span>
              <p>
                Администрация Сайта вправе в любой момент запросить у Пользователя
                документы, подтверждающие данные, указанные им при отправке сообщения
                с помощью Сервиса. В случае непредоставления Пользователем
                подтверждающих документов Администрация Сайта вправе отказать
                Пользователю в использовании Сервиса или его отдельных функций.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.6.</span>
              <p>
                Если Пользователем не доказано обратное, действия по отправке
                сообщения с помощью Сервиса, совершенные с использованием его номера
                телефона, e-mail, считаются совершенными самим Пользователем.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">3.7.</span>
              <p>
                Пользователь согласен на передачу его персональных данных в целях
                отправки Исполнителем сообщений в адрес Застройщиков, партнеров
                Администрации Сайта.
              </p>
            </div>
          </div>
        </section>

        <section className="block block_04">
          <img
            src={require('../../assets/images/policy/04_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">04. Ответственность</h4>
            <div className="block__container">
              <span className="block__counter">4.1.</span>
              <p>
                Администрация Сайта не несет ответственность за посещение и
                использование Пользователем внешних ресурсов, ссылки на которые могут
                содержаться на Сайте.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">4.2.</span>
              <p>
                Администрация Сайта не несет ответственность и не имеет прямых или
                косвенных обязательств перед Пользователем в связи с любыми
                возможными или возникшими потерями или убытками, связанными с любым
                содержанием Сайта, в том числе, но не ограничиваясь, за достройку
                объектов недвижимости Застройщиком, за банкротство Правообладателей,
                чьи Объекты размещены на Сайте, регистрацией авторских прав и
                сведениями о такой регистрации, товарами или услугами, доступными на
                или полученными через внешние сайты или ресурсы либо иные контакты
                Пользователя, в которые он вступил, используя размещенную на Сайте
                информацию или ссылки на внешние ресурсы.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">4.3.</span>
              <p>Администрация Сайта не несет ответственности за:</p>
              <p>
                — Задержки или сбои в процессе совершения операции, возникшие
                вследствие непреодолимой силы, а также любого случая неполадок в
                телекоммуникационных, компьютерных, электрических и иных смежных
                системах;
              </p>
              <p>
                — Ненадлежащее функционирование Сайта, в случае если Пользователь не
                имеет необходимых технических средств для его использования, а также
                не несет никаких обязательств по обеспечению Пользователей такими
                средствами;
              </p>
              <p>
                — Изменение регламентов и внутренней документации Застройщиков, за
                изменение цен Застройщиком (изменение цен происходит на Сайте дважды в
                неделю), поэтому стоимость на Сайте Объектов недвижимости не является
                Офертой.
              </p>
            </div>
          </div>
        </section>

        <section className="block block_05">
          <img
            src={require('../../assets/images/policy/05_pict.svg').default}
            alt=""
          />
          <div className="block__box">
            <h4 className="block__title">05. Прочие условия</h4>
            <div className="block__container">
              <span className="block__counter">5.1.</span>
              <p>
                В случае возникновения любых разногласий или споров между Сторонами
                Соглашения обязательным условием до обращения в суд является
                предъявление претензии (письменного предложения о добровольном
                урегулировании спора). В случае невозможности достичь согласия между
                сторонами в претензионном порядке в течение 60 (шестидесяти)
                календарных дней с момента получения другой Стороной письменной
                претензии, рассмотрение спора должно быть передано любой
                заинтересованной стороной в суд по месту нахождения Администрации
                Сайта.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.2.</span>
              <p>
                Признание судом какого-либо положения Соглашения недействительным или
                не подлежащим принудительному исполнению не влечет недействительности
                иных положений Соглашения.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.3.</span>
              <p>
                Администрация Сайта имеет право раскрыть информацию о Пользователе,
                если действующее законодательство Российской Федерации требует или
                разрешает такое раскрытие.
              </p>
            </div>
            <div className="block__container">
              <span className="block__counter">5.4.</span>
              <p>
                Адрес для отправки юридически значимых сообщений, включая заявлений о
                нарушении авторских, смежных прав: info@panpartner.ru
              </p>
            </div>
          </div>
        </section>

        <p className="update-date">Редакция от «01» января 2025 г.</p>
      </div>
    </section>
  );
};

export default Polzovatel;