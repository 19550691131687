import React, { useRef, useState } from "react";
import { AddressSuggestions } from "react-dadata";
import { useFieldArray, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getUrl } from "../../api";
import useMedia from '../../hooks/useMedia';
import { validateMail } from "../../utils";
import {PenIcon, TelegramIcon, WhatsupIcon} from "../Public/markup/icons";
import Requisites from "../Requisites";
import Checkbox from '../core/Checkbox';
import Wrap from '../core/ModalWrap';
import Popup from '../core/Popup';
import { errorToast } from '../toasts';
import ChangePassword from "./ChangePassword";
import "react-dadata/dist/react-dadata.css";

const phoneRequired = (v) =>
  v && v.indexOf("_") === -1 ? undefined : "Введите телефон";

const Input = (props) => {
  const { name, inputRef, value, ...rest } = props;
  return (
    <InputMask {...rest} mask="+7 (999) 999-99-99" maskChar="_">
      {(inputProps) => (
        <input value={value} name={name} ref={inputRef} {...inputProps} />
      )}
    </InputMask>
  );
};

const accessData = {
  general: {
    name: "Генеральный директор",
    description:
      "Имеет доступ к базе, комиссионному вознаграждению, может добавлять сотрудников.",
  },
  master: {
    name: "Мастер-аккаунт",
    description:
      "Имеет доступ к базе, комиссионному вознаграждению, может добавлять сотрудников.",
  },
  "agent_extended": {
    name: "Агент расширенным доступом",
    description: "Имеет доступ к базе и комиссионному вознаграждению.",
  },
  agent: {
    name: "Агент",
    description:
      "Имеет доступ к базе. Не видит комиссионное вознаграждение.",
  },
}

const Form = (props) => {

  const userAccess = useSelector(state=>state.user.access);

  const isMobile = useMedia("isMobile");

  const {
    edit,
    editPassword,
    defaultValues,
    canEditAgency,
    profile,
    settings,
    setSettings,

    requisitesData = {},
    refreshRequisites,
    showRequisites
  } = props;
  const accessInfo = accessData[userAccess]

  const { manager } = profile;
  const { requisites, bankRequisites, companyId } = requisitesData;
  const { handleSubmit, register, errors, control, setValue} = useForm({
    defaultValues,
  });
  console.log(Object.keys(requisitesData).length > 0)
  const cityRef = useRef(null);

  const [city, setCity] = useState({value: defaultValues.cityName});
  const [logo, setLogo] = useState(
    defaultValues.workLogo ? getUrl(defaultValues.workLogo.src): null,
  );

  const [logoFile, setLogoFile] = useState(null);

  const onSubmit = (values) => {
    if(!city?.value && canEditAgency) return errorToast("Введите корректный город")
    if (canEditAgency) {
      values.cityName = city ? city.value : defaultValues.cityName;
      if (logoFile) {
        values.workLogo = logoFile;
      }
    }
    edit(values);

  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "phone",
  });

  const {
    fields: fieldsEmail,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const getTitle = () => {
    let title = `${profile.lastName ? profile.lastName: ""} ${profile.name? profile.name: ""}`;
    if (profile.patronymic) title += ` ${profile.patronymic.charAt(0)}.`;
    return title;
  };

  const changeFile = (e) => {
    const tmpFiles = e.target.files[0];
    setLogoFile(tmpFiles);
    var reader = new FileReader();
    var url = reader.readAsDataURL(tmpFiles);

    reader.onloadend = function (e) {
      setLogo(reader.result);
    };
  };

  const handleCityBlur = (ref) => {
    if (ref.current.state.suggestions.length > 0) {
      if (
        ref.current.state.inputQuery !==
        ref.current.state.suggestions[0].data.city
      ) {
        /*let suggestion = ref.current.state.suggestions[0];
        suggestion.value = suggestion.data.city;
        ref.current.setState({
          query: suggestion.data.city,
          inputQuery: suggestion.data.city,
          displaySuggestions: false,
        });*/
        ref.current.selectSuggestion(0);
      }
    }
  };


  const [validValue, setValidValue] = useState("")
  const telegramInputFocus = (e) => {
    if (e.target.value) {
      return
    }
    setValidValue("@")
    setValue(`telegram`, "@")
  }
  const telegramBlur = (e)=> {
    if (e.target.value === "@") {
      setValidValue("")
      setValue(`telegram`, "")
    }
  }
  const setTelegramValue = (e) => {
    let val = e.currentTarget.value
    if(val && val[0] !== "@"){
      setValue(`telegram`, `@${val}`)
      val = `@${val}`
    }
    let match = val.match(/^@[_0-9a-zA-Z]{0,}/)
    if (match && match[0] === val) {
      setValidValue(val)
      setValue(`telegram`, val)
    } else {
      setValue(`telegram`, validValue)
    }
  }


  return (
    <section className="section section_view_white profile no-header-profile">
      <div className="wrapper">
        <div className="personal_content">
          <div className="profile__header">
            <div className="profile__title">
              <h1>{getTitle()}</h1>
            </div>
            {accessInfo && (
              <div className="profile__access">
                <div className="card__pill">{accessInfo.name}</div>

                {accessInfo.description && (
                  <div className="info">
                    {isMobile ?
                      <Popup
                        trigger={
                          <svg className="icon icon_info">
                            <use xlinkHref="#info"></use>
                          </svg>
                        }
                      >
                        {accessInfo.description}
                      </Popup> :
                      <svg className="icon icon_info">
                        <use xlinkHref="#info"></use>
                      </svg>
                    }
                    {!isMobile && <div className="info__popup">{accessInfo.description}</div>}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="profile__grid">
            <div className="profile__grid-col profile__aside">
              {manager && (
                <div className="manager small profile__manager">
                  <div className="profile__manager-label">
                    <span>Ваш личный менеджер</span>
                  </div>
                  <div className="profile__manager-content">
                    {manager.photo && (
                      <div className="profile__manager-photo">
                        <img
                          src={`${manager.photo.src}`}
                          alt={`${manager.name} ${manager.lastName}`}
                        />
                      </div>
                    )}
                    <Wrap wrapper={children => isMobile ? <div className="manager-content-wrapper">{children}</div> : children} >
                      <div className="manager-title">
                        <span>
                          {manager.name} {manager.lastName}
                        </span>
                      </div>
                      <div className="manager-contacts profile__manager-contacts">
                        <ul className="manager-contacts_list">
                          {manager.phone && (
                            <li className="manager-contacts_item">
                              <a
                                className="link-hover color-brand-1"
                                href={`tel:${manager.phone}`}
                              >
                                {manager.phone}
                              </a>
                            </li>
                          )}
                          {manager.email && (
                            <li className="manager-contacts_item">
                              <a
                                className="link-hover color-brand-1"
                                href={`mailto:${manager.email}`}
                              >
                                {manager.email}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      {(manager.telegram || manager.phone) && (
                        <div className="manager-socials">
                          <ul className="manager-socials__list">
                            {false && manager.phone && (
                              <li className="manager-socials__item manager-socials_whatsapp">
                                <a
                                  href={`https://api.whatsapp.com/send?phone=${manager.phone}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="button button_view_round-control"
                                >
                                  <svg className="icon">
                                    <use xlinkHref="#socials_whatsapp"></use>
                                  </svg>
                                </a>
                              </li>
                            )}
                            {manager.telegram && (
                              <li className="manager-socials__item manager-socials_tg">
                                <a
                                  href={`https://t.me/${manager.telegram}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="button button_view_round-control"
                                >
                                  <svg className="icon">
                                    <use xlinkHref="#socials_tg"></use>
                                  </svg>
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </Wrap >
                  </div>
                </div>
              )}
            </div>
            <div className="profile__grid-col profile__info">
              <form
                className="profile__form js-form-address"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="lk-form__row">
                  <div className="index-search__show-booked">
                    <Checkbox
                      id={'tradeIn'}
                      onChange={(e) => setSettings(prev => prev ?
                        { ...prev, isFastView: e.target.checked ? "1" : "0" } :
                        { isFastView: e.target.checked ? "1" : "0" })
                      }
                      checked={settings?.isFastView === "1"}
                      labelText={"Быстрый просмотр"}
                    />
                  </div>
                </div>
                <div className="lk-form__row">
                  <div className="lk-form__row-title">О Вас</div>
                  <div className="lk-form__multi-row">
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="cl-sec-name"
                        >
                          <span className="input__label">
                            {isMobile ? 'Ваша фамилия:' : 'Фамилия:'}
                          </span>
                          <input
                            name="lastName"
                            className={
                              "input__control" +
                              (errors.lastName ? " input__error" : "")
                            }
                            id="cl-sec-name"
                            ref={register({
                              required: "Введите фамилию",
                            })}
                            autoComplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="cl-name"
                        >
                          <span className="input__label">
                            {isMobile ? 'Ваша имя:' : 'Имя:'}
                          </span>
                          <input
                            name="name"
                            className={
                              "input__control" +
                              (errors.name ? " input__error" : "")
                            }
                            ref={register({
                              required: "Введите имя",
                            })}
                            id="cl-name"
                            autoComplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="cl-f-name"
                        >
                          <span className="input__label">
                            {isMobile ? 'Ваша отчество:' : 'Отчество:'}
                          </span>
                          <input
                            name="patronymic"
                            className="input__control"
                            id="cl-f-name"
                            autoComplete="off"
                            ref={register}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {canEditAgency && (
                  <div className="lk-form__row _bg-gray js-fias">
                    <div className="lk-form__row-title">Агентство</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="lk-form-agency"
                        >
                          <span className="input__label">
                            Название агентства
                          </span>
                          <input
                            name="workCompany"
                            defaultValue={defaultValues.workCompany}
                            className={
                              "input__control" +
                              (errors.workCompany ? " input__error" : "")
                            }
                            id="lk-form-agency"
                            ref={register}
                            autoComplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="lk-form-city"
                        >
                          <span className="input__label">Город</span>
                          <AddressSuggestions
                            token="20bd4e741cc5ed7093cda4b029e958f1322d5dd8"
                            value={city}
                            defaultQuery={profile.cityName}
                            onChange={(suggestion) => {
                              suggestion.value = suggestion.data.city;
                              suggestion.unrestricted_value =
                                suggestion.data.city;
                              setCity(suggestion);
                            }}
                            filterFromBound={`city`}
                            filterToBound={`city`}
                            filterLocations={[{ city_type_full: "город" }]}
                            filterLocationsBoost={[
                              { kladr_id: "7800000000000" },
                            ]}
                            renderOption={(suggestion) => {
                              suggestion.value = suggestion.data.city;
                              return <>{suggestion.data.city}</>;
                            }}
                            ref={cityRef}
                            inputProps={{
                              className: "input__control",
                              onBlur: (event) => {
                                handleCityBlur(cityRef);
                              },
                              onChange: e=>setCity(e.target.value)
                            }}
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form-files lk-form__logo"
                          htmlFor="lk-form-logo"
                        >
                          <input
                            className="input__control"
                            type="file"
                            id="lk-form-logo"
                            onChange={changeFile}
                          />
                          <div className="lk-form__logo-image">
                            <img src={logo} />
                          </div>
                          <div className="lk-form__logo-load link-hover">
                            Загрузить лого
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="lk-form__row for-contacts">
                  <div className="lk-form__row-title">Контактные данные</div>
                  <div className="lk-form__row-content">
                    <div className="lk-form__fr">
                      {fields.map((item, index) => (
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor={`cl-phone${index}`}
                          key={`phone${index}`}
                        >
                          <span className="input__label">Телефон:</span>

                          <Input
                            name={`phone[${index}]`}
                            className={
                              "input__control" +
                              (errors.phone && errors.phone[index]
                                ? " input__error"
                                : "")
                            }
                            type="text"
                            id={`cl-phone${item.id}`}
                            inputRef={register({
                              required: "Введите телефон",
                              validate: { inputTelRequired: phoneRequired },
                            })}
                            defaultValue={item.value}
                            key={item.id}
                            mask="+7 (999) 999-99-99"
                          />

                          {index === 0 ? (
                            <span className="input__alert info">
                              {isMobile ?
                                <Popup
                                  trigger={
                                    <svg className="icon icon_alert">
                                      <use xlinkHref="#alert"></use>
                                    </svg>
                                  }
                                >
                                  <p>
                                    <strong>Приоритетный номер</strong>
                                  </p>
                                  <br />
                                  <p style={{ lineHeight: '18px '}}>
                                    При заполненни формы обратной связи, этот
                                    номер будет автоматически подставляться в
                                    форму.
                                  </p>
                                </Popup> :
                                <svg className="icon icon_alert">
                                  <use xlinkHref="#alert"></use>
                                </svg>
                              }
                              {!isMobile &&
                                <div className="info__popup">
                                  <p>
                                    <strong>Приоритетный номер</strong>
                                  </p>
                                  <p>
                                    При заполненни формы обратной связи, этот
                                    номер будет автоматически подставляться в
                                    форму.
                                  </p>
                                </div>
                              }
                            </span>
                          ) : (
                              <button
                                className="input__remove"
                                onClick={(e) => {
                                  remove(index);
                                  e.preventDefault();
                                }}
                              >
                                <svg
                                  className="icon"
                                  width="7"
                                  height="8"
                                  viewBox="0 0 7 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.66667 1.3L0.333333 6.7"
                                    strokeLinecap="round"
                                  ></path>
                                  <path
                                    d="M0.333333 1.3L5.66667 6.7"
                                    strokeLinecap="round"
                                  ></path>
                                </svg>
                              </button>
                            )}
                        </label>
                      ))}
                      <div className="lk-form__add-param">
                        <button
                          className="button button_type_add"
                          onClick={(e) => {
                            e.preventDefault();
                            append("");
                          }}
                        ></button>
                        <span>Добавить ещё телефон</span>
                      </div>
                    </div>
                    <div className="lk-form__fr">
                      {fieldsEmail.map((item, index) => (
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor={`cl-email${index}`}
                          key={index}
                        >
                          <span className="input__label">E-mail:</span>
                          <input
                            name={`email[${index}]`}
                            className={
                              "input__control" +
                              (errors.email && errors.email[index]
                                ? " input__error"
                                : "")
                            }
                            type="email"
                            id={`cl-email${index}`}
                            autoComplete="off"
                            ref={register({
                              required: "Введите e-mail",
                              pattern: {
                                value: validateMail,
                                message: "Неправильный формат e-mail",
                              },
                            })}
                          />
                          {index === 0 ? (
                            <span className="input__alert info">
                              {isMobile ?
                                <Popup
                                  trigger={
                                    <svg className="icon icon_alert">
                                      <use xlinkHref="#alert"></use>
                                    </svg>
                                  }
                                >
                                  <p>
                                    <strong>Приоритетный e-mail</strong>
                                  </p>
                                  <br />
                                  <p style={{ lineHeight: '18px '}}>
                                    При заполненни формы обратной связи, этот
                                    e-mail будет автоматически подставляться в
                                    форму.
                                  </p>
                                </Popup> :
                                <svg className="icon icon_alert">
                                  <use xlinkHref="#alert"></use>
                                </svg>
                              }
                              {!isMobile &&
                                <div className="info__popup">
                                  <p>
                                    <strong>Приоритетный e-mail</strong>
                                  </p>
                                  <p>
                                    При заполненни формы обратной связи, этот
                                    e-mail будет автоматически подставляться в
                                    форму.
                                  </p>
                                </div>
                              }
                            </span>
                          ) : (
                              <button
                                className="input__remove"
                                onClick={(e) => {
                                  removeEmail(index);
                                  e.preventDefault();
                                }}
                              >
                                <svg
                                  className="icon"
                                  width="7"
                                  height="8"
                                  viewBox="0 0 7 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.66667 1.3L0.333333 6.7"
                                    strokeLinecap="round"
                                  ></path>
                                  <path
                                    d="M0.333333 1.3L5.66667 6.7"
                                    strokeLinecap="round"
                                  ></path>
                                </svg>
                              </button>
                            )}
                        </label>
                      ))}
                      <div className="lk-form__add-param">
                        <button
                          className="button button_type_add"
                          onClick={(e) => {
                            e.preventDefault();
                            appendEmail("");
                          }}
                        ></button>
                        <span>Добавить ещё e-mail</span>
                      </div>
                    </div>
                    <div className="lk-form__socials">
                      <div className="lk-form__fr">
                        <label
                          className="input input_type_form input_type_form_icon-left input_width_available"
                          htmlFor="lk-form-whatsapp"
                        >
                          <span className="input__label">What's app:</span>
                          <InputMask
                            name={`watsApp`}
                            id="lk-form-whatsapp"
                            className={"input__control"}
                            defaultValue={defaultValues.watsApp}
                            inputRef={register({})}
                            mask="+7 (999) 999-99-99"
                          ></InputMask>
                          <span className="input__icon">
                            <svg className="icon icon_watch">
                              <use xlinkHref="#socials_whatsapp"></use>
                            </svg>
                          </span>
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label
                          className="input input_type_form input_type_form_icon-left input_width_available"
                          htmlFor="lk-form-tg"
                        >
                          <span className="input__label">Telegram:</span>
                          <input
                            name={`telegram`}
                            id="lk-form-tg"
                            className={
                              "input__control" +
                              (errors.telegram ? " input__error" : "")
                            }
                            defaultValue={defaultValues.telegram}
                            ref={register({
                              pattern: /@[_0-9a-zA-Z]{5,}/
                            })}

                            onFocus={telegramInputFocus}
                            onChange={e => setTelegramValue(e)}
                            onBlur={e => telegramBlur(e)}
                          ></input>
                          <span className="input__icon">
                            <svg className="icon icon_watch">
                              <use xlinkHref="#socials_tg"></use>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                { showRequisites && requisites?.length && bankRequisites?.length ?
                  <div className="lk-form__row _bg-gray requisites">
                    <div className="lk-form__row-title">Реквизиты</div>
                    <div className="lk-form__row-content">
                      <p className="requisites__hint">
                        Реквизиты будут использоваться для формирования счетов и других финансовых документов. <br/>Пожалуйста,
                        указывайте корректные данные.
                      </p>
                      <div className="requisites__text-block">
                        <div className="requisites__info">
                          <div className="requisites__info-title">Реквизиты компании</div>
                          <div className="requisites__info-text">

                            {requisites.map((item, index) => {
                              return (
                                <p>{index !== 0 ? item.title + ": " + item.value : item.value}</p>
                              )
                            })}


                          </div>
                        </div>
                        <div className="requisites__info">
                          <div className="requisites__info-title">Банковские реквизиты</div>
                          <div className="requisites__info-text">

                            {bankRequisites.map((item) => {
                              return (
                                <p>
                                  {item.title + ": " + item.value}
                                </p>
                              )
                            })}

                          </div>
                        </div>
                      </div>
                      <div className="requisites__bottom-hints">
                        <p className="requisites__hint">
                          Чтобы изменить реквизиты, напишите, пожалуйста личному менеджеру
                        </p>
                        <div style={{display: 'flex', gap: 30}}>
                          <a href={`mailto:${requisitesData.manager.email}`} className="requisites__write-manager">
                            <PenIcon/>
                            Написать менеджеру
                          </a>
                          { requisitesData.manager.imTg !== '' &&
                            <a href={`https://t.me/${requisitesData.manager.imTg}`} target="_blank" className="requisites__write-manager">
                              <TelegramIcon/>
                              Telegram
                            </a>
                          }
                          {requisitesData.manager.whatsApp !== '' &&
                            <a href={`https://wa.me/${requisitesData.manager.whatsApp}`} target="_blank" className="requisites__write-manager">
                              <WhatsupIcon/>
                              WhatsUp
                            </a>
                          }
                        </div>

                      </div>
                    </div>
                  </div> : <></>
                }




                {!isMobile &&
                  <div className="lk-form__footer">
                    <div className="lk-form__reglament">
                      <Link to={`/personal/profile/offers`}>
                        <div className="input_type_upload-btn">
                          Договор оферты
                        </div>
                      </Link>
                    </div>
                    <div className="lk-form__btns">
                      <div className="lk-form__submit">
                        <button
                          className="button button_view_default"
                          type="submit"
                        >
                          Сохранить
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </form>
              {
                showRequisites && Object.keys(requisitesData).length > 0 &&
                <Requisites showForm={!requisites?.length} refresher={refreshRequisites} {...requisitesData} />
              }

              <ChangePassword editPassword={editPassword} />

              {isMobile &&
                <div className="lk-form__footer">
                  <div className="lk-form__reglament">
                    <Link to={`/personal/profile/offers`}>
                      <div className="input_type_upload-btn">
                        Договор оферты
                      </div>
                    </Link>
                  </div>
                  <div className="lk-form__btns">
                    <div className="lk-form__submit">
                      <button
                        className="button button_view_default"
                        type="submit"
                      >
                        Сохранить изменения
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
