import _keys from 'lodash/keys';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import useMedia from "../../hooks/useMedia";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { login, restorePassword, testLogin } from '../../actions/user';
import useTimer from '../../hooks/useTimer';
import { restoreLastPath } from '../../utils';
import Modal from '../../components/core/Modal';
import Forgot from '../../components/forms/Forgot';
import SubmitButton from '../../components/SubmitButton';
import { errorToast, successToast } from '../../components/toasts';


import { getFormWithUTM } from '../../utils/location';
import DownloadApp from '../../components/HomePage/DownloadApp';

const LoginContainer = props => <Login {...props} key={props?.history?.location?.state?.fromLogin + Date.now()} /> //быстрое решение чтобы кнопочка войти работала

const Login = (props) => {
  const { login, history, fetching, error, location, testLogin, user } = props;
  const { handleSubmit, register, errors, control, getValues } = useForm({
    defaultValues: {
      phone: "",
      email: "",
      remember: true
    },
  });
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorBlock, setErrorBlock] = useState(false);
  const [isFetching, setIsFetching] = useState(fetching)
  const [paswordIsSended, setPaswordIsSended] = useState(false)

  const params = new URLSearchParams(window.location.search);
  const eventParam = params.get('event');

  const {
    timeout,
    timerIsStarted,
    runTimer
  } = useTimer(60)
  const [phoneSaved, setPhoneSaved] = useState(null)
  const [openForgotModal, closeForgotModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeForgotModal} classes="modal_small pass-recover">
        <Forgot />
      </Modal>
    ),
  );

  const onError = (error) => {

    setIsFetching(false)
    errorToast(error[0]?.message)
  }
  const onSuccess = data => {
    setErrorMessage(false)
    setIsFetching(false)
    successToast(data.message)
  }

  const startRestoreByPhone = (data) => {
    let phone = data || getValues("login") || phoneSaved
    setPhoneSaved(phone)
    setPaswordIsSended(true)
    runTimer()
  }
  const [loginType, setLoginType] = useState(null)

  const isMobile = useMedia("isMobile");

  const restore = () => {
    let login = getValues("login") || phoneSaved
    restorePassword({ login }).then(
      data => {
        onSuccess(data)
        setLoginType(data.type)
        if (data.type === "phone") {
          startRestoreByPhone(login)
          return
        }
        history.replace({ pathname: "welcome" });
      },
      onError
    )
  }

  const onSubmit = (values, isTest) => {
    setIsFetching(true);
    setDownloadApp(false);

    if (!paswordIsSended && isForgotPass) {
      restore()
      return
    }

    let form = getFormWithUTM();
    if (isTest) {
      testLogin().then(
        () => {
          setIsFetching(false)
          history.replace({ pathname: restoreLastPath(user.history) });
        },
        (errorMessage) => {
          setErrorBlock(true);
          setErrorMessage(errorMessage);
          setIsFetching(false)
        },
      );
      return;
    } else {
      let keys = _keys(values);
      keys.forEach((item) => {
        if (item === "remember") {
          form.append(`${item}`, values[item] ? "Y" : "N");
          return
        }
        form.append(`${item}`, values[item]);
      });
    }
    if (paswordIsSended && !form.get("login")) {
      form.append("login", phoneSaved)
    }
    login(form).then(
      () => {
        setIsFetching(false)
        if (eventParam) {
          return history.replace({ pathname: `/events/${eventParam}` });
        }
        history.replace({ pathname: restoreLastPath(user.history) });
      },
      (errorMessage) => {
        localStorage.clear()
        setErrorBlock(true);
        setErrorMessage(errorMessage);
        setIsFetching(false)
      },
    );
  };

  const ua = window.navigator.userAgent;
  const isAndroid = /android/i.test(ua);

  const [downloadApp, setDownloadApp] = useState(isMobile);

  if (downloadApp) {
    document.body.style.overflow = "hidden";
  }


  return (
    downloadApp ? <DownloadApp downloadApp={downloadApp} setDownloadApp={setDownloadApp} /> :
    <div className="hp__page">
      <section className="hp__become-p hp__promo">
        <div className="hp__page-form">
          <h1 className="hp__h1 h1 text-center">{isForgotPass ? `Восстановить пароль` : `Войти`}</h1>
          {
            isForgotPass &&
            <p className="hp__sign hp__sign_sm color-brand-3 big-text text-center">
              {paswordIsSended ? `Введите полученный пароль` : `Для восстановления введите телефон, который был указан при регистрации`}
            </p>
          }


          <div className="hp__form-content">
            {
            !errorBlock &&
            <form
              className="form"
              name="sign-in"
              onSubmit={handleSubmit((val) => onSubmit(val))}
            >
                {/* {error && (
                  <div className="form__error-text">
                    {errorMessage || `Неверный логин пользователя или пароль`}
                  </div>
                )} */}
                {location.state && location.state.referrer && (
                  <div className="form__info-text">
                    Ваш пароль был успешно изменен
                  </div>
                )}

                {!paswordIsSended &&
                  <div className="hp__form-item">
                    <label htmlFor="sign-in_login" className="hp__form-label text">{isForgotPass ? `Ваш номер телефона`: `Логин`}</label>

                    <div
                      className="input input_width_available input_type_form"
                    >
                      <input
                        name="login"
                        id="sign-in_login"
                        placeholder={isForgotPass ? `Ваш номер телефона`: `Ваш телефон или email`}
                        className={
                          'input__control' +
                          (errors.email ? ' input__error' : '')
                        }
                        ref={register({
                          required: 'Введите телефон или email',
                        })}
                      />
                      {errors.login && (
                        <span className="input__error-label">
                          {errors.login.message}
                        </span>
                      )}
                    </div>

                  </div>
                }
                {(!isForgotPass || paswordIsSended) &&
                  <div className="hp__form-item">
                    <label htmlFor="sign-in_password" className="hp__form-label text">Пароль</label>
                    <div
                      className="input input_width_available input_type_form"
                    >
                      <input
                        name="password"
                        type={showPass ? 'text' : 'password'}
                        placeholder="Введите пароль"
                        className={
                          'input__control' +
                          (errors.password ? ' input__error' : '')
                        }
                        id="sign-in_password"
                        ref={register({
                          required: 'Введите пароль',
                        })}
                      />
                      <div
                        className="lk-form__password input__icon"
                        onClick={() => setShowPass(!showPass)}
                        style={{backgroundColor: 'transparent'}}
                      >
                        {showPass && (
                          <span className="lk-form__password-visible">
                            <svg className="icon icon_watch">
                              <use xlinkHref="#input-hide"></use>
                            </svg>
                          </span>
                        )}
                        {!showPass && (
                          <span className="lk-form__password-hidden">
                            <svg className="icon icon_watch">
                              <use xlinkHref="#icon_watch"></use>
                            </svg>
                          </span>
                        )}
                      </div>

                      {errors.password && (
                        <span className="input__error-label">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                }


                {!timerIsStarted && isForgotPass && loginType === "phone" && paswordIsSended && (
                  <div className="hp__form-item">
                    <span className="preview__anchor" onClick={restore}>
                      Запросить новый пароль
                    </span>
                  </div>
                )}
                {timerIsStarted && isForgotPass && loginType === "phone" && (
                  <div className="hp__form-item preview__countdown">
                    <p>
                      Запросить новый пароль можно будет через:{' '}
                      <span className="preview__counter">
                        0:{timeout < 10 && '0'}
                        {timeout}
                      </span>
                    </p>
                  </div>
                )}
                {!isForgotPass && <div className="hp__form-item hp__form-item_space-between">

                  <div className="checkbox checkbox_view_red ">
                    <div className="checkbox__box">
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="sign-in_remember"
                        name="remember"
                        ref={register}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="sign-in_remember"
                      ></label>
                      <div className="checkbox__marker"></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="sign-in_remember"
                    >
                      Запомнить меня
                    </label>
                  </div>


                  <Link to={`/recovery`} className="link-hover color-brand-3">
                    Забыли пароль?
                  </Link>

                </div>}
                <div className="hp__form-item hp__form-item_space-between">
                    <SubmitButton
                      className="hp__submit button button_view_default"
                      isLoading={isFetching}
                      type="submit"
                    >
                      <span>{isForgotPass && !paswordIsSended ? "Отправить" : "Войти"}</span>
                    </SubmitButton>
                  {(window.location.host === 'dev2.panpartner.ru' ||
                    window.location.host === 'localhost:3000') && false && (
                        <SubmitButton
                          className="hp__submit button button_view_default"
                          isLoading={isFetching}
                          type="button"
                          onClick={() => onSubmit(null, true)}
                        >
                          <span>Тестовый доступ</span>
                        </SubmitButton>
                    )}
                </div>
                {!isForgotPass && <div className="hp__form-item">
                  <div className="sign-in__footer">
                    Еще нет аккаунта?{' '}
                    <Link to={`/register/new`}>
                      <span className="sign-in__sign-up link-hover">
                        Зарегистрироваться
                      </span>
                    </Link>
                  </div>
                </div>}
            </form>
            }

            {
              errorBlock &&
              <div className="hp__error-block">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_528_3276)">
                  <path d="M21.4018 7.65039C22.5565 5.65039 25.4433 5.65039 26.598 7.65039L42.1863 34.6502C43.341 36.6502 41.8976 39.1502 39.5882 39.1502H8.41153C6.10213 39.1502 4.65875 36.6502 5.81345 34.6502L21.4018 7.65039Z" fill="#FF2D23"/>
                  <path d="M22.7521 19.7264H25.0081V24.3664L24.5921 27.7104H23.1681L22.7521 24.3664V19.7264ZM25.1041 31.1504H22.6561V28.7184H25.1041V31.1504Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_528_3276">
                  <rect width="48" height="48" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>

                <p className="hp__error-title color-red">{errorMessage}</p>

                <div className="hp__error-btns">
                  <Link to={`/help`}
                    className="hp__error-btn button button_view_default"
                    type="button"
                  >
                    Получить помощь
                  </Link>
                  <button
                    className="hp__error-btn button button_view_outline"
                    type="button"
                    onClick={() => setErrorBlock(false)}
                  >
                    Войти еще раз
                    </button>
                </div>

                {!isForgotPass && <div className="hp__error-btns">
                  <Link to={`/recovery`} className="link-hover color-brand-3 text-center">
                    Забыли пароль?
                  </Link>

                </div>}
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  )
};

const mapDispatchToProps = (dispatch) => ({
  login: (form) => dispatch(login(form)),
  testLogin: () => dispatch(testLogin()),
});

const mapStateToProps = (state) => ({
  user: state.user,
  fetching: state.user.fetching,
  error: state.user.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
