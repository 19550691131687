import { searchInFieldsByString } from "../../utils";
import {dealsColumns, fixationsColumns, sfColumns, viewingsColumns} from "./columns";
import _get from "lodash/get";

const searchSettings = {
    client: "",
    clientPhone: /[\(,\),\-,\+, ]/g
};

const compareDate = (d1, d2) => !d2 || new Date(d2) <= new Date(d1.slice(0, 10));
const compareMultiple = (item, filter) => !filter?.length || filter.some(id => id == item || id.toString() === item?.id?.toString?.()); // массивы в качестве id с бэка
const compareMultipleByKey = (item, filter, dataKey) => !filter?.length || filter.some(id => id == item || id.toString() === _get(item, dataKey).toString());
const compareRadio = (value, filter) => !value || filter === value;
const compareToggle = (value, filter) => !filter || !!value;
const compareByUserIdToggle = (value, filter, userId) =>!filter || value == userId;


const filterFunctions = {
    date: compareDate,
    multiple: compareMultiple,
    multipleByKey: compareMultipleByKey,
    toggle: compareToggle,
    radio: compareRadio,
    compareByUserIdToggle
};
const selfClient = {
    type: "toggle",
    title: "Только мои клиенты",
    permissionRequired: "not-agent"
};
const endFixationPeriod = {
    type: "toggle",
    title: "Заканчивается срок фиксации",
};
const fixationPageConfig = {
    bottomFilters: { endFixationPeriod },
    topFilters: {
        stageId: {
            type: "multiple",
            dataKey: "stage",
            props: {
                data: {
                    title: "Статус",
                    autocompleteTitle: "Введите название статуса",
                    identity: "stage",
                }
            }
        },
        contactId: {
            type: "multiple",
            dataKey: "agent",
            props: {
                data: {
                    title: "Сотрудник",
                    autocompleteTitle: "Введите имя сотрудника",
                    identity: "contact",
                }
            }
        },
        dateCreate: { type: "date", dataKey: "lastDate", props: { placeholder: "За 6 месяцев" } },
    },
}

// ключи bottomFilters и topFilters должны быть названы так же, как поля в айтемах, dataKey - ключ по которому доступны данные фильтра с бэка
const CRM_TABS = [
    { id: 2, title: "Клиенты", url: "/crm/clients" },
    {
        id: 0,
        isDefault: true,
        payload: ["panpartner:deals.ajax", "getFixes"],
        searchPlaceholder: "ФИО клиента или номер телефона",
        searchSettings,
        title: "Фиксации",
        url: "/crm/fixations",
        itemsPath: "fixes",
        columns: fixationsColumns,
        bottomFilters: { endFixationPeriod, selfClient },
        topFilters: {
            lastDate: { type: "date", props: { placeholder: "За 6 месяцев" } },
            contact: {
                type: "multiple",
                permissionRequired: "not-agent",
                dataKey: "agent",
                props: {
                    data: {
                        title: "Сотрудник",
                        autocompleteTitle: "Введите имя сотрудника",
                        identity: "contact",
                    }
                }
            },
        },
    },
    {
        id: 3,
        payload: ["panpartner:deals.ajax", "getSignUpToViews"],
        searchPlaceholder: "ФИО клиента или номер телефона",
        searchSettings,
        title: "Записи на просмотр",
        url: "/crm/viewing",
        itemsPath: "items",
        columns: viewingsColumns,
        bottomFilters: { selfClient },
        topFilters: {
            lastDate: { type: "date", props: { placeholder: "За 6 месяцев" } },
            contact: {
                type: "multiple",
                permissionRequired: "not-agent",
                dataKey: "agent",
                props: {
                    data: {
                        title: "Сотрудник",
                        autocompleteTitle: "Введите имя сотрудника",
                        identity: "contact",
                    }
                }
            },
        },
    },

    { id: 1, title: "Сделки", url: "/crm/deals" },
    { id: 3, title: "Презентации", url: "/crm/presentations" },
    {
        id: 2,
        title: "Сохраненные фильтры",
        url: "/crm/filters",
        payload: ["panpartner:saved.filter.ajax", "getSavedFilters", {}],
        searchPlaceholder: "ФИО клиента или название подборки",
        searchSettings: { name: "", comment: "" },
        columns: sfColumns,
        showDeleteButton: true,
        bottomFilters: { onlySelfClietns: {
            type: "compareByUserIdToggle",
            title: "Только мои подборки",
            permissionRequired: "not-agent",
            valueKey: "agentId"
        }},
        itemsPath: "savedFilters",
        selectionEnabled: true,
        topFilters: {
            agentId: {
                type: "multiple",
                dataKey: "agentId",
                dataFullPath: "agentId",
                // permissionRequired: "not-agent",
                props: {
                    data: {
                        title: "Сотрудник",
                        autocompleteTitle: "Введите имя сотрудника",
                        identity: "agentId",
                    }
                }
            },
            dateCreate: { type: "date", dataKey: "dateCreate", props: { placeholder: "За 6 месяцев" } },
        },
    },
    // {
    //     id: 1,
    //     payload: ["panpartner:deals.ajax", "getDeals", { isGetFilter: 1 }],
    //     searchPlaceholder: "ФИО клиента",
    //     searchSettings: { client: "" },
    //     title: "Сделки",
    //     url: "/crm/deals",
    //     itemsPath: "deals",
    //     columns: dealsColumns,
    //     bottomFilters: { selfClient },
    //     topFilters: {
    //         stageId: {
    //             type: "multiple",
    //             dataKey: "status",
    //             props: {
    //                 data: {
    //                     title: "Статус",
    //                     autocompleteTitle: "Введите название статуса",
    //                     identity: "status",
    //                 }
    //             }
    //         },
    //         contactId: {
    //             type: "multiple",
    //             dataKey: "agent",
    //             permissionRequired: "not-agent",
    //             props: {
    //                 data: {
    //                     title: "Сотрудник",
    //                     autocompleteTitle: "Введите имя сотрудника",
    //                     identity: "contact",
    //                 }
    //             }
    //         },
    //         dateCreate: { type: "date", dataKey: "lastDate", props: { placeholder: "За 6 месяцев" } },
    //     },
    // },



    { id: 5, title: "Подборки", url: "/crm/compilations" },
    // { id: 6, title: "Мотивация", url: "/crm/motivation" },
];

const getCurrentCRMTab = path => CRM_TABS.find(({ isDefault, url }) =>
    isDefault && path === "/crm" ? true : path === url
);

const defaultCrmFilter = {
    search: "",
    lastDate: "",
    dateCreate: "",
    agentId: [],
    contact: [],
    client: [],
    stageId: [],

};

const crmFiltrateItems = ({ data, filter, currentTab, userId, alternativeData }) => {
    if (!currentTab) return
    const { itemsPath, searchSettings, topFilters = {}, bottomFilters = {} } = currentTab;
    const items = alternativeData ?? data[itemsPath];
    const filters = { ...topFilters, ...bottomFilters };
    if (!items?.length) return [];
    const entries = Object.entries(filters);
    let result = items.filter(item => entries.reduce(
        (prev, [key, { type, valueKey }]) => prev && filterFunctions[type](item[valueKey? valueKey : key], filter[key], userId), true)
    )
    if (!searchSettings) return result;
    return searchInFieldsByString(result, searchSettings, filter.search)
};

export { CRM_TABS, getCurrentCRMTab, defaultCrmFilter, crmFiltrateItems, fixationPageConfig };
