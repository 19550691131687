import { useMediaQuery } from "beautiful-react-hooks";
import _filter from "lodash/filter";
import React, { useEffect, useMemo } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import {
  clearFilterEstate,
  fetchEstateFilter, setFilterEstate
} from "../../actions/estate";
import {
  filterToParams,
  isEmptyFilterOrExclude,
  media
} from "../../utils";
import {
  defaultFullFilterEstate
} from "../../utils/defaultFilters";
import Modal from "../core/Modal";
import Dragable from "../FilterRealty/Dragable";
import EndingsFilter from "../FilterRealty/EndingsFilter";
import PriceFilter from "../FilterRealty/PriceFilter";
import SelectFilter from "../FilterRealty/SelectFilter";
import SaveFilter from "../SavedFilters/SendFilter";

var inDebounce;
const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    countBlocksToShow,
    clearFilterOnly,
    clearExcludeOnly,
    city,
    getCountBlocks,
    isPublic,
    savedFilterXmlId,
    savedFilter,
    isShowFilter,
    fetching,
  } = props;
  // const [countBlocks, setCountBlocks] = useState(filterData.countBlocks);

  // useEffect(() => {
  //   fetchFilter(city, savedFilterXmlId);
  // }, []);

  useEffect(() => {
    if (savedFilter) {
      setFilter({
        filter: { ...savedFilter.filter },
        exclude: { ...savedFilter.exclude },
      });
    }
  }, [savedFilter]);

  const hideParamsTitle = useMemo(() => {
    if(!savedFilter) return false
    const showParams = (Array.isArray(savedFilter.exclude) && !!savedFilter.exclude?.length ) || JSON.stringify(savedFilter?.filter) !== '{"status":["1"]}'
    return !isShowFilter && !showParams
  }, [savedFilter, isShowFilter]) //царская проверка на пустоту фильтра :)

  const handleFilter = (data, identity) => {
    let tempValue = {
      filter: {
        [identity]: data.filter ? data.filter : filter.filter[identity],
      },
      exclude: {
        [identity]: data.exclude ? data.exclude : filter.exclude[identity],
      },
    };
    setFilter(tempValue);
    let tmpFilter = {
      filter: { ...filter.filter, ...tempValue.filter },
      exclude: { ...filter.exclude, ...tempValue.exclude },
    };
    clearTimeout(inDebounce);
    let params = filterToParams(tmpFilter);
    inDebounce = setTimeout(() => {
      getCountBlocks(props.city, params, savedFilterXmlId);
    }, 300);
  };

  const smallScreen = useMediaQuery("(max-width: 1280px)");

  const handleClearFilter = (e) => {
    e.preventDefault();
    getCountBlocks(props.city, filterToParams(savedFilter), savedFilterXmlId);
    clearFilter();
    setFilter(savedFilter);
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    onSubmit();
  };


  const [saveFilterModal, hideSaveFilterModal] = useModal(
    ({  }) => (
      <Modal close={hideSaveFilterModal} classes="modal_filter">
        <SaveFilter
          filter={filter}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
        />
      </Modal>
    ),
    [filter, filterData]
  );

  if(hideParamsTitle) return null
  return (
    <section className="section index-search index-search_dark">
      <div className="wrapper wrapper__for-list">
        <div className="section__grid">
          <div
            className="section__grid-col index-search__filter index-search__filter-public"
          >
            {!isShowFilter && (
              <h2 className="public__title">Параметры фильтра</h2>
            )}
            <form className="form">
              {!!isShowFilter && (
                <div className="index-search__filter-body">
                  <div className="index-search__filter-top">
                    <div className="form__row">
                      <SelectFilter
                        onChange={(a, b) =>
                          handleFilter({ filter: a, exclude: b }, "blocks")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                          filterData.blocks
                            ? Object.values(filterData.blocks)
                            : []
                        }
                        filterValues={filter.filter.blocks}
                        excludeValues={filter.exclude.blocks}
                        data={{
                          title: "Название ЖК",
                          autocompleteTitle: "Введите название ЖК",
                          identity: "blocks",
                        }}
                      />
                      <SelectFilter
                        onChange={(a, b) =>
                          handleFilter({ filter: a, exclude: b }, "districts")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                          filterData.districts
                            ? Object.values(filterData.districts)
                            : []
                        }
                        filterValues={filter.filter.districts}
                        excludeValues={filter.exclude.districts}
                        data={{
                          title: "Район",
                          autocompleteTitle: "Введите название района",
                          identity: "districts",
                        }}
                        right={media("isMobile")}
                      />
                      <SelectFilter
                        onChange={(a, b) =>
                          handleFilter({ filter: a, exclude: b }, "rooms")
                        }
                        isExcludable={true}
                        isAutocomplete={false}
                        items={
                          filterData.rooms
                            ? Object.values(filterData.rooms)
                            : []
                        }
                        filterValues={filter.filter.rooms}
                        excludeValues={filter.exclude.rooms}
                        data={{
                          title: "К-во комнат",
                          autocompleteTitle: "",
                          identity: "rooms",
                        }}
                      />
                      <EndingsFilter
                        ident={"endings"}
                        right={smallScreen}
                        filter={
                          filter.filter.endings
                            ? filter.filter.endings
                            : { min: "", max: "" }
                        }
                        items={
                          filterData.endings ? filterData.endings.items : []
                        }
                        data={{
                          title: "Срок сдачи",
                          minPlaceholder: "от ",
                          maxPlaceholder: "до ",
                          defaultValues: filterData.endings
                            ? filterData.endings.ranges
                            : null,
                        }}
                        onChange={(a) => handleFilter(a, "endings")}
                        secondData={{
                          title: "Сданные дома",
                          value: filter.filter.isOnlyHanded?.length
                            ? filter.filter.isOnlyHanded[0].id
                            : "N",
                        }}
                        secondChange={(a) => {
                          let tmp = a === "Y" ? [{ id: a }] : [];
                          handleFilter(
                            {
                              filter: tmp,
                              exclude: [
                                ..._filter(
                                  filter.exclude.isOnlyHanded,
                                  (val) => val.id != a
                                ),
                              ],
                            },
                            "isOnlyHanded"
                          );
                        }}
                      />
                      <PriceFilter
                        filter={
                          filter.filter.prices
                            ? filter.filter.prices
                            : { min: "", max: "" }
                        }
                        exclude={
                          filter.exclude.prices
                            ? filter.exclude.prices
                            : { min: "", max: "" }
                        }
                        items={filterData.prices ? filterData.prices.items : []}
                        data={{
                          title: "Цена",
                          minPlaceholder: "от ",
                          maxPlaceholder: "до ",
                          defaultValues: filterData.prices
                            ? filterData.prices.range
                            : 0,
                        }}
                        onChange={(a) => handleFilter(a, "prices")}
                      />
                      <SelectFilter
                        onChange={(a, b) =>
                          handleFilter({ filter: a, exclude: b }, "builders")
                        }
                        isExcludable={true}
                        isAutocomplete={true}
                        items={
                          filterData.builders
                            ? Object.values(filterData.builders)
                            : []
                        }
                        filterValues={filter.filter.builders}
                        excludeValues={filter.exclude.builders}
                        data={{
                          title: "Застройщик",
                          autocompleteTitle: "Введите название застройщика",
                          identity: "builders",
                        }}
                        right={!smallScreen || media("isMobile")}
                      />
                    </div>
                  </div>
                </div>
              )}
              {filter && filterData && !isEmptyFilterOrExclude(filter, null, defaultFullFilterEstate) && (
                <div className={!!isShowFilter ? "" : "public__params"}>
                  <Dragable
                    readonly={!isShowFilter}
                    filter={filter.filter}
                    fullFilter={filter}
                    exclude={filter.exclude}
                    filterData={filterData}
                    handleFilter={handleFilter}
                    clearExcludeOnly={clearExcludeOnly}
                    clearFilterOnly={clearFilterOnly}
                    defFullFilter={defaultFullFilterEstate}
                    getCount={(filterOrExclude) =>
                      getCountBlocks(
                        props.city,
                        filterToParams(filterOrExclude),
                        savedFilterXmlId
                      )
                    }
                  />
                </div>
              )}
              {!!isShowFilter && (
                <div className="index-search__filter-controls">
                  {/* <div className="index-search__filter-control index-search__filter-voice">
                  <button className="button button_view_round">
                    <svg className="icon icon_mic">
                      <use xlinkHref="#mic"></use>
                    </svg>
                  </button>
                  <span>Голосовой помощник</span>
                </div> */}
                  <button
                    onClick={handleClearFilter}
                    className="button button_type_clear visible_mobile"
                  >
                    <svg className="icon icon_x">
                      <use xlinkHref="#x"></use>
                    </svg>
                    <span>Очистить фильтр</span>
                  </button>
                  <a
                    className="index-search__filter-control index-search__filter-submit"
                    href="#filter-results"
                  >
                    {countBlocksToShow ? (
                      <button
                        className="button button_view_default"
                        type="submit"
                        onClick={handleFilterClick}
                        disabled={fetching ? true : false}
                      >
                        Показать{` ${countBlocksToShow} ЖК`}
                      </button>
                    ) : (
                      <button
                        className="button button_view_default"
                        type="submit"
                        disabled
                      >
                        Ничего не найдено
                      </button>
                    )}
                  </a>
                  <div className="index-search__filter-control index-search__filter-options">
                    <button
                      onClick={handleClearFilter}
                      className="button button_type_clear visible_note"
                    >
                      <svg className="icon icon_x">
                        <use xlinkHref="#x"></use>
                      </svg>
                      <span>Очистить фильтр</span>
                    </button>
                    {!isPublic && (
                      <div className="index-search__filter-save">
                        <button
                          className="button button_view_outline"
                          onClick={(e) => {
                            e.preventDefault();
                            saveFilterModal();
                          }}
                        >
                          Сохранить фильтр
                        </button>
                        {/* <button className="button button_type_settings">
                      <svg className="icon icon_settings">
                        <use xlinkHref="#settings"></use>
                      </svg>
                    </button> */}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!!isShowFilter && (
                <div className="index-search__filter-controls-m">
                  <div className="index-search__filter-controls-col">
                    {/* <div className="index-search__filter-control index-search__filter-voice">
                    <button className="button button_view_round">
                      <svg className="icon icon_mic">
                        <use xlinkHref="#mic"></use>
                      </svg>
                    </button>
                  </div> */}
                    <button
                      onClick={handleClearFilter}
                      className={`button button_type_clear visible_mobile ${media("isMobile") && "complex__filter-clear-filter"}`}
                    >
                      <svg className="icon icon_x">
                        <use xlinkHref="#x"></use>
                      </svg>
                      <span>Очистить фильтр</span>
                    </button>
                  </div>
                  <div className="index-search__filter-controls-col">
                    <div className="index-search__filter-control index-search__filter-submit">
                      {countBlocksToShow ? (
                        <button
                          className="button button_view_default"
                          type="submit"
                          onClick={handleFilterClick}
                          disabled={fetching ? true : false}
                        >
                          Показать{` ${countBlocksToShow} ЖК`}
                        </button>
                      ) : (
                        <button
                          className="button button_view_default"
                          type="submit"
                          disabled
                        >
                          Ничего не найдено
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="index-search__filter-controls-col">
                    <div className="index-search__filter-control index-search__filter-options">
                      <button
                        onClick={handleClearFilter}
                        className="button button_type_clear visible_note"
                      >
                        <svg className="icon icon_x">
                          <use xlinkHref="#x"></use>
                        </svg>
                        <span>Очистить фильтр</span>
                      </button>
                      {!isPublic && (
                        <div className="index-search__filter-save">
                          <button
                            className="button button_view_outline"
                            onClick={(e) => {
                              e.preventDefault();
                              saveFilterModal();
                            }}
                          >
                            Сохранить фильтр
                          </button>
                          {/* <button className="button button_type_settings">
                        <svg className="icon icon_settings">
                          <use xlinkHref="#settings"></use>
                        </svg>
                      </button> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  filterData: state.estate.filterData,
  filter: state.estate.filter,
  countApartments: state.estate.countApartments,
  countBlocks: state.estate.countBlocks,
  countBlocksToShow: state.estate.countBlocksToShow,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchFilter: (city, savedFilterXmlId) =>
      dispatch(fetchEstateFilter(city, savedFilterXmlId)),
    setFilter: (filter) => dispatch(setFilterEstate(filter)),
    clearFilter: () => dispatch(clearFilterEstate()),
    clearFilterOnly: () => dispatch({ type: "ESTATE_CLEAR_FILTER_ONLY" }),
    clearExcludeOnly: () => dispatch({ type: "ESTATE_CLEAR_EXCLUDE_ONLY" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
