import React, {useEffect, useMemo, useRef, useState} from "react";
import './index.scss'
import {Link, useParams} from "react-router-dom";
import {AllViewiIcon, FixationImportantIcon} from "../Public/markup/icons";
import InputMask from "react-input-mask";
import Dropdown from "../core/Dropdown";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {useSelector} from "react-redux";
import useUniversalForm from "../../hooks/useUniversalForm";
import {useModal} from "react-modal-hook";
import Modal from "../core/Modal";
import Button from "../core/Button";
import Preloader from "../Preloader";
import ru from "date-fns/locale/ru";
import NewAlert from "../core/NewAlert";
import SubmitButton from "../SubmitButton";
import { formatDate } from "../../utils";
import { standartErrorToast, successToast } from "../toasts";
import SelectFilter from "../FilterRealty/SelectFilter";
import FlexibleTag from "../../pages/Fixations/FlexibleTag";
import moment from 'moment';
import useFileManager from "../../hooks/useFileManager";
import SimpleBar from "simplebar-react";
import StatelessTabs from "../core/Tabs/StatelessTabs";

registerLocale('ru', ru);
setDefaultLocale('ru');
const toDate = str => {
  if (str instanceof Date) return str
  const [day, months, years] = str?.split?.(".") || [];
  if (years?.trim?.()?.length !== 4) return new Date();
  return new Date(years, months - 1, day);
}
const emptyClient = {
  email: "",
  lastName: "",
  name: "",
  patronymic: "",
  phone: "",
  id: 0
}
const emptyUniFormClient = {
  clientEmail: "",
  clientLastName: "",
  clientName: "",
  clientPatronymic: "",
  clientPhone: "",
  clientId: 0,
  clientBirthday: ""
}
const clientToUniForm = cl => ({
  clientEmail: cl.email ? cl.email : "",
  clientLastName: cl.lastName,
  clientName: cl.name,
  clientPatronymic: cl.patronymic,
  clientPhone: cl.phone,
  clientId: cl.id,
  clientBirthday: ""
})

const filterAndMap = (arr, fn) => {
  const tmp = [];
  for (const item of arr) {
    const value = fn(item);
    value && tmp.push(value);
  }
  return tmp;
}

const RecordViewing = () => {

  const tabs = [
    { title: 'В Санкт-Петербурге' },
    { title: 'В Москве' },
  ];


  const { id, city } = useParams();

  const [activeTab, setActiveTab] = useState(city === 'msk' ? 1 : 0)

  const userInfo = useSelector(state => state.user.info)
  const initialObjectKey = "blocks"
  const initialObjectCodeName = "block";

  const [modalData, setModalData] = useState({})
  const openModalWithData = (data) => {
    setModalData(data)
    openModal()
  }
  const closeModal = () => {
    setModalData({})
    hideModal()
  }

  const [openModal, hideModal] = useModal(({}) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick
           close={hideModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#2C2E3E"/>
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round"/>
          <circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E"/>
        </svg>
        {modalData.title}
      </div>
      <div className="crm-table__sf-controlsmodaltext"
           dangerouslySetInnerHTML={{
             __html: modalData.text,
           }}
      >
      </div>
      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={closeModal}>Понятно</Button>
      </div>
    </Modal>
  ), [modalData]);


  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if(id) {
      setSelectedItems([Number(id)])
    }
  }, [id])
  const [selectedDates, setSelectedDates] = useState({})
  const [selectedType, setSelectedType] = useState([])
  const [isDateError, setIsDateError] = useState(false)
  const [successMessage, setSuccessMessage] = useState()
  const requiredFields = {
    clientLastName: "Введите фамилию клиента",
    clientName: "Введите имя клиента",
    clientPhone: "Введите телефон клиента",
    clientPatronymic: "Введите отчество клиента",
    ...(isDateError ? { time: "Введите корректное время" } : {}),
    ...(selectedItems?.length ? [] : {selected: `Выберите ЖК`})
  }
  const {
    form,
    getValue,
    isLoading,
    isSending,
    response: {
      clients,
      builders,
      fieldsDescription,
      // blocks,
      ...resp
    },
    setValue,
    setCheckboxValue,
    setValues,
    send,
    convertArrayToUniformData,
    errors,
    forcedSetValue
  } = useUniversalForm({
    formId: city === 'spb' ? 18 : city === 'msk' ? 19 : 18,
    city: city,
    defaultValues: {
      name: `${userInfo.name} ${userInfo.lastName}`,
      phone: userInfo.phone,

    },
    defaultCheckboxes: ["personal_data", "familiar_with_advertising", "familiar_with_builder"],
  })

  const selectClient = id => {
    const client = clients.find(client => client.id === id)
    setValues(id ? clientToUniForm(client) : emptyUniFormClient)
  }

  const normalizedClients = useMemo(() => {
    if (!clients) return [];
    const tmpClients = [{...emptyClient, name: "Ничего не выбрано", lastName: ""}, ...clients]
    return tmpClients.map(cl => ({...cl, title: `${cl.name} ${cl.lastName}`}))
  }, [clients])

    const [requaredPassportBuilders, setRequaredPassportBuilders] = useState([])
    useEffect(() => {
      if (builders) {
        setRequaredPassportBuilders(builders.filter(item => item.passportView === true))
      }
    },[builders])

    const [openPassportModal, hidePassportModal] = useModal(() => (
        <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick close={hidePassportModal}>
          <div className="crm-table__sf-controlsmodaltitle">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="8.5" stroke="#2C2E3E" /><line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round" /><circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E" /></svg>
            Застройщики для которых важен паспорт:
          </div>
          <SimpleBar className="search-result" style={{ maxHeight: 200, display: "block", position: 'relative', boxShadow: 'none' }}>
            {requaredPassportBuilders.map((builder) => {
              return(
                  <div style={{marginTop: 5, marginBottom: 5}}>{builder.name}</div>
              )
            })}
          </SimpleBar>

          <div className="crm-table__sf-controlsmodalbtns">
            <Button buttonType="line" onClick={hidePassportModal}>Понятно</Button>
          </div>
        </Modal>
    ), [requaredPassportBuilders]);

  const processValues = () => {
    const clientBirthday = getValue("clientBirthday");
    if (!clientBirthday) return;
    return { clientBirthday: formatDate(clientBirthday) }
  }

  const convertValues = () => {
    const dates = []
    const times = []
    const where = []
    Object.values(selectedDates).forEach(({ date, time }) => {
      dates.push(date)
      times.push(time)
    })
    const tmpWhereItems = { ...recordItem }
    selectedItems.forEach(key => {
      if (key in tmpWhereItems) return
      const index = resp[initialObjectKey].findIndex(({ id }) => id === key)
      const { flat, showRoom } = resp[initialObjectKey][index]?.where
      if (flat && showRoom) {
        tmpWhereItems[key] = ""
        return
      }
      tmpWhereItems[key] = flat ? "flat" : "showRoom"
    })
    Object.values(tmpWhereItems).forEach((v) => where.push(v));

    const tmpSelected = selectedItems ? [...selectedItems].sort((a, b) => a - b) : []

    return {
      ...(convertArrayToUniformData(initialObjectCodeName, tmpSelected) || []),
      ...(convertArrayToUniformData("date", dates) || []),
      ...(convertArrayToUniformData("time", times.map(item => item.replace(/C(\d{2}:\d{2})до(\d{2}:\d{2})/g, 'C $1 до $2'))) || []),
      ...(convertArrayToUniformData("where", where) || []),
    }
  }

  const submit = () => {
    send(
      processValues(),
      convertValues(),
      requiredFields,
    ).then(resp => {
      setSuccessMessage(resp.message)
      successToast(resp.message)
      setSelectedItems([])
      setSelectedDates({})
      setSelectedType({})
      setRecordItem({})
      setTimeout(setSuccessMessage, 5000)
      setValues({...emptyUniFormClient, comment: ""})
    }, standartErrorToast)
  }

  const selectDateTime = (val, key, subkey, error = "") => {
    setSelectedDates(prev => ({
      ...prev,
      [key]: prev[key] ? { ...prev[key], [subkey]: val, [`${subkey}Error`]: error } : { [subkey]: val, [`${subkey}Error`]: error  }
    }))
  }

  const selectDate = (val, key, subkey) => {
    const momentDate = val instanceof Date ? moment(val) : moment(val, 'DD.MM.YYYY', true);
    const isValidDate = momentDate?.isValid?.();
    const value = isValidDate ? momentDate.format("DD.MM.YYYY") : val;
    const isValidCount = val?.replace?.(/\D/gi, "")?.length < 8
    selectDateTime(value, key, subkey, isValidDate || isValidCount ? "" : "Некорректная дата")
  }

  const selectTime = (val, key, subkey) => {
    const strippedVal = val.replace(/\s/g, "");
    const isValidFormat = /^C\d{2}:\d{2}до\d{2}:\d{2}$/.test(strippedVal);
    let error = "";
    if (!isValidFormat) {
      error = "Некорректное время или интервал времени";
    } else {
      const [from, to] = strippedVal.substring(1).split("до").map(time => moment(time, "HH:mm", true));

      if (!from.isValid() || !to.isValid() || from.isSameOrAfter(to)) {
        error = "Неверный интервал времени";
      }
    }
    console.log(error);
    if (error !== "") {
      setIsDateError(true);
    } else {
      setIsDateError(false);
    }
    selectDateTime(strippedVal, key, subkey, error);
  };

  const onFilesChange = files => forcedSetValue(files, "passport")

  const { files, uploadFiles, deleteFile, editFileName, } = useFileManager({
    maxFiles: 10,
    onFilesChange
  });

  const [recordItem, setRecordItem] = useState({})

  const setSelectedWhere = (key, value) => {
    setRecordItem(prev => ({
      ...prev,
      [key]: value
    }))
  }
  const CustomRadio = ({recordItem, setRecordItem}) => {

    const handleOptionChange = (e) => {
      setRecordItem(e.target.value);
    };


    return (
      <div className="custom-radio">

          <label>
            <input
              type="radio"
              value="show"
              checked={recordItem === 'show'}
              onChange={handleOptionChange}
            />
            Шоурум
          </label>


          <label>
            <input
              type="radio"
              value="flat"
              checked={recordItem === 'flat'}
              onChange={handleOptionChange}
            />
            Квартира
          </label>

      </div>
    );
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    let sanitizedData = pasteData.replace(/[^\d]/g, '');
    if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('7') || sanitizedData.startsWith('+7')) {
      sanitizedData = sanitizedData.substring(2);
    }
    setValue(sanitizedData, "clientPhone")
  };

  if (isLoading && !isSending) return <Preloader loading={true}/>;
  return (
    <div className="wrapper fixations">
      <div className="common-header">
        <div className="common-header__title common-header__title_withlink">
          <h3 className="common-title">
            Запись на просмотр
          </h3>
          <Link to={'/crm/viewing'} target="_blank" className="common-header__fullinfo-link"
                style={{display: 'flex', alignItems: 'center'}}>
            <AllViewiIcon/>
            <span style={{marginLeft: 7}}>Все записи на просмотр</span>
          </Link>
        </div>
      </div>
      <StatelessTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isInline
        onClick={(i) => {
          if (i === 1) { window.location.href = `/book-a-tour/msk` }
          if (i === 0) { window.location.href = `/book-a-tour/spb` }
          setActiveTab(i)
        }}
      />
      <div className="fixations__container">
        <div className="fixations__settings-block">
          <p className="h6">Ваши контакты</p>

          <div className="hp__form-item">
            <label className="hp__form-label text" style={{opacity: "0.5"}}>
              Имя и фамилия
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={'input__control'}
                style={{background: "#fff", border: "1px solid #DFE1E9"}}
                value={`${userInfo.name} ${userInfo.lastName}`}
                disabled
              />
            </label>
          </div>

          <div className="fixations__block-dash">
            <label className="input input_width_available input_type_form">
              <span className="input__label" style={{opacity: "0.5"}}>Телефон</span>
              <InputMask
                className="input__control input input_type_form"
                name="phone"
                mask="+7 (999) 999-99-99"
                autoComplete="off"
                maskChar="_"
                value={userInfo.phone}
                style={{background: "#fff", border: "1px solid #DFE1E9"}}
                disabled
              />
            </label>
          </div>

          <p className="h6">Контакты клиента</p>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              Заполнить данные автоматически
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <Dropdown
                wrapperClass="custom-select_sf-filter custom-select_fixations"
                withSearch={normalizedClients?.length > 4}
                options={normalizedClients}
                options={clients}
                placeholder="Выберите клиента"
                value={getValue("clientId")}
                onChange={selectClient}
                searchPlaceholder="Введите ФИО клиента"
              />
            </label>
          </div>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              <span>Фамилия<span className="is-red-text sell__required">*</span></span>
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={`input__control${errors.clientLastName ? " input__error" : ""}`}
                value={getValue("clientLastName")}
                onChange={e => setValue(e.target.value, "clientLastName")}
              />
            </label>
            {errors.clientLastName && (
              <span className="input__error-label">
                            {errors.clientLastName}
                        </span>
            )}
          </div>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              <span>Имя<span className="is-red-text sell__required">*</span></span>
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={`input__control${errors.clientPhone ? " input__error" : ""}`}
                value={getValue("clientName")}
                onChange={e => setValue(e.target.value, "clientName")}
              />
            </label>
            {errors.clientName && (
              <span className="input__error-label">
                            {errors.clientName}
                        </span>
            )}
          </div>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              <span>Отчество<span className="is-red-text sell__required">*</span></span>
            </label>
            <label
              className="input input_width_available input_type_form"
            >
              <input
                className={`input__control${errors.clientPatronymic ? " input__error" : ""}`}
                value={getValue("clientPatronymic")}
                onChange={e => setValue(e.target.value, "clientPatronymic")}
              />
            </label>
            {errors.clientPatronymic && (
              <span className="input__error-label">
                            {errors.clientPatronymic}
                        </span>
            )}
          </div>

          <div>
            <label className="input input_width_available input_type_form">
                        <span className="input__label">
                            <span>Телефон<span className="is-red-text sell__required">*</span></span>
                        </span>
              <InputMask
                className={`input__control input input_type_form${errors.clientPhone ? " input__error" : ""}`}
                name="phone"
                mask="+7 (999) 999-99-99"
                autoComplete="off"
                maskChar="_"
                value={getValue("clientPhone")}
                onChange={e => setValue(e.target.value, "clientPhone")}
                onPaste={handlePaste}
              />
            </label>
            {errors.clientPhone && (
              <span className="input__error-label">
                            {errors.clientPhone}
                        </span>
            )}
          </div>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              E-mail
              {fieldsDescription?.email && <span
                className="hp__form-label-rules"
                onClick={() => openModalWithData(fieldsDescription.email)}
              >
                            Почему это важно
                        </span>
              }
            </label>
            <label className="input input_width_available input_type_form">
              <input
                className={'input__control'}
                value={getValue("clientEmail")}
                onChange={e => setValue(e.target.value, "clientEmail")}
              />
            </label>
          </div>

          <div className="hp__form-item">
            <label className="hp__form-label text">
              Дата рождения
              {fieldsDescription?.birthday && <span
                className="hp__form-label-rules"
                onClick={() => openModalWithData(fieldsDescription.birthday)}
              >
                            Почему это важно
                        </span>
              }
            </label>
            <label className="input input_width_available input_type_form fixation-dp-fix">
              <DatePicker
                dateFormat="dd.MM.yyyy"
                maxDate={new Date()}
                showTimeSelect={false}
                todayButton="Сегодня"
                dropdownMode="select"
                autoComplete="off"
                className="input__control"
                onChange={v => setValue(v, "clientBirthday")}
                shouldCloseOnSelect
                showYearDropdown
                showMonthDropdown
                selected={getValue("clientBirthday")}
              />
            </label>
          </div>

          <div className="hp__form-item fixations__block-dash">
            <label className="hp__form-label text">
              Паспорт клиента
              <span
                  className="hp__form-label-rules"
                  onClick={() => openPassportModal()}
              >
                        Почему это важно
                    </span>
            </label>



            <div className="modal-form__row">
              <div className="modal-form__item">
                <label
                    className="input input_type_upload-btn input_width_available"
                    htmlFor="modal-reservation-file-input"
                >
                  <input
                      className="input__control"
                      type="file"
                      multiple
                      id="modal-reservation-file-input"
                      name="file"
                      onChange={uploadFiles}
                  />
                  Прикрепить документ
                </label>
                <div
                    className="modal-form__files js-upload-container"
                    data-id="modal-reservation-file-input"
                >
                  {files.map((file, i) => (
                      <div data-num="0" className="file-up" key={file.fakeId}>
                        <div className="file-up__inner">
                          <svg className="icon file-up__icon">
                            <use xlinkHref="#deals-01"></use>
                          </svg>
                          <span className="file-up__name">{file?.fileName}</span>
                        </div>
                        <button className="button button_view_round-control file-up__del"
                                onClick={() => deleteFile(i)}
                        >
                          <svg className="icon">
                            <use xlinkHref="#x"></use>
                          </svg>
                        </button>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <p className="h6">Информация о записи на просмотр</p>


          <div className="hp__form-item">
            <label className="hp__form-label text">
              <span>Название ЖК (не более 5)<span className="is-red-text sell__required">*</span></span>
            </label>

            <label
              className="input input_width_available input_type_form"
            >
              <SelectFilter
                items={resp[initialObjectKey]}
                isAutocomplete
                filterValues={selectedItems}
                onChange={(val) => setSelectedItems(val)}
                data={{
                  title: "Выберите ЖК",
                  identity: initialObjectKey,
                  autocompleteTitle: "Введите название ЖК",
                }}
                titleKey="title"
                wrapperClass={`select-filter-fix custom-select_sf-filter custom-select_sf-filter-fixations${!selectedItems.length && !!Object.entries(errors).length ? " drop__error" : ""}`}
                disabledIds={selectedItems.length > 4 ? filterAndMap(resp[initialObjectKey], item => selectedItems.includes(item.id) ? false : item.id) : []}
              />
            </label>
            {!selectedItems.length && !!Object.entries(errors).length && (
              <span className="input__error-label">
                       Выберите ЖК
                    </span>
            )}
            <div style={{display: "flex", flexDirection: "column", marginTop: 16}}>
              {selectedItems.map(id => {

                const item = resp[initialObjectKey]?.find?.(item => item.id === id)

                // const fixCondition = city === "spb" ? false : item?.fixConditions;
                const fixCondition = (city === "msk" || city === "spb") ? false : item?.fixConditions;

                return <div key={id} style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 15}}>

                  <div className="hp__form-item view-info">
                    <div className="view-info__header">
                      <div className="view-info__title h6">{item?.title}</div>
                      <div className="view-info__cancel"
                           onClick={() => setSelectedItems(prev => prev.filter(selectedId => selectedId !== id))}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 7L12 12L17 7" stroke="#2C2E3E" strokeLinecap="round"/>
                          <path d="M7 17L12 12L17 17" stroke="#2C2E3E" strokeLinecap="round"/>
                        </svg>
                      </div>
                    </div>
                    {item.signUpToViewConditions !== '' && <label className="hp__form-label text" onClick={() => openModalWithData({title: `Условия записи в ЖК ${item.title}`, text: item.signUpToViewConditions})}><span className="hp__form-label-rules">Условия записи</span></label>}
                    <div className="view-info__dates">
                      <div className="hp__form-item">
                        <label className="hp__form-label text">
                          <span>Желаемая дата<span className="is-red-text sell__required">*</span></span>
                        </label>
                        <label className="input input_width_available input_type_form">
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            showTimeSelect={false}
                            todayButton="Сегодня"
                            dropdownMode="select"
                            autoComplete="off"
                            onSelect={v => selectDate(v, id, "date")}
                            selected={toDate(selectedDates[id]?.date)}
                            shouldCloseOnSelect
                            showYearDropdown
                            showMonthDropdown
                            minDate={new Date(new Date())}
                            placeholderText={'18.07.2023'}
                            customInput={<div>
                              <InputMask
                                className={`input__control${!!selectedDates[id]?.dateError ? " input__error" : ""}`}
                                mask="99.99.9999"
                                autoComplete="off"
                                maskChar=" "
                                placeholder={'18.07.2023'}
                                value={selectedDates[id]?.date}
                                onChange={e => selectDate(e.target.value, id, "date", true)}
                              />
                            </div>
                            }
                          />
                        </label>
                        {!!selectedDates[id]?.dateError && (
                          <span className="input__error-label">
                            {selectedDates[id].dateError}
                        </span>
                        )}
                      </div>
                      <div className="hp__form-item">
                        <label className="hp__form-label text">
                          <span>Время<span className="is-red-text sell__required">*</span></span>

                        </label>
                        <label className="input input_width_available input_type_form">
                          <InputMask
                            mask="C 99:99 до 99:99"
                            maskChar="_"
                            formatChars={{ '9': '[0-9]', _: '[0-5]' }}
                            className={`input__control${selectedDates[id]?.timeError ? " input__error" : ""}`}
                            value={selectedDates[id]?.time}
                            onChange={e => selectTime(e.target.value, id, "time")}
                            placeholder={'C 13:00 до 14:00'}
                          />
                        </label>
                        {!!selectedDates[id]?.timeError && (
                          <span className="input__error-label">
                            {selectedDates[id]?.timeError}
                        </span>
                        )}
                      </div>
                    </div>

                    {item.where.flat && item.where.showRoom && <CustomRadio recordItem={recordItem[id]} setRecordItem={val=>setSelectedWhere(id, val)}/>}
                    {item.where.flat && !item.where.showRoom && <p style={{color: '#8f95ac', marginTop: 5}}>Укажите в комментарии номер квартиры</p>}
                    {!item.where.flat && item.where.showRoom && <p style={{color: '#8f95ac', marginTop: 5}}>Запись в шоу-рум</p>}

                  </div>

                  {fixCondition && <span className="rules-text" onClick={() => openModalWithData({
                    title: `Условия фиксации ${item.name}`,
                    text: fixCondition
                  })}>
                                Условия фиксации
                            </span>}
                </div>
              })}
            </div>
          </div>


          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form"
                htmlFor="modal-reservation-commentary"
              >
                <span className="textarea__label">Комментарий</span>
                <textarea
                  style={{minHeight: 132}}
                  className="textarea__control"
                  id="modal-reservation-commentary"
                  placeholder="По желанию Вы можете указать дополнительную информацию о записи на просмотр"
                  value={getValue("comment")}
                  onChange={e => setValue(e.target.value, "comment")}
                />
              </label>
            </div>
          </div>

          <div className="checkbox checkbox_view_red fixations__checkboxs">
            <div className="checkbox__box">
              <input
                className="checkbox__control"
                type="checkbox"
                id="data-id"
                name="remember"
                checked={!!getValue("personal_data", true)}
                onChange={e => setCheckboxValue(e, "personal_data")}

              />
              <label className="checkbox__input" htmlFor="data-id"/>
              <div className="checkbox__marker"/>
            </div>
            <label className="checkbox__label" htmlFor="data-id" style={{marginBottom: -3}}>
              Отправляя заявку вы соглашаетесь на обработку
              <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link link-red"> персональных
                данных</Link>
            </label>
          </div>
          <div className="checkbox checkbox_view_red fixations__checkboxs">
            <div className="checkbox__box">
              <input
                className="checkbox__control"
                type="checkbox"
                id="data-adv"
                name="remember"
                checked={!!getValue("familiar_with_advertising", true)}
                onChange={e => setCheckboxValue(e, "familiar_with_advertising")}

              />
              <label className="checkbox__input" htmlFor="data-adv"/>
              <div className="checkbox__marker"/>
            </div>
            <label className="checkbox__label" htmlFor="data-adv">
              Ознакомлен (-а) с регламентом по рекламе
            </label>
          </div>
          <div className="checkbox checkbox_view_red fixations__checkboxs">
            <div className="checkbox__box">
              <input
                className="checkbox__control"
                type="checkbox"
                id="data-reglament"
                name="remember"
                checked={!!getValue("familiar_with_builder", true)}
                onChange={e => setCheckboxValue(e, "familiar_with_builder")}
              />
              <label className="checkbox__input" htmlFor="data-reglament"/>
              <div className="checkbox__marker"/>
            </div>
            <label className="checkbox__label" htmlFor="data-reglament" style={{marginBottom: -3}}>
              Ознакомлен (-а) с регламентом взаимодействия с застройщиком
            </label>
          </div>

          {successMessage && <NewAlert
            title={successMessage}
            bgColor="#DFF5E0"
            iconColor="#2CBA2E"
            oneLine
          />}
          <SubmitButton
            className="button button_view_default"
            type="button"
            isLoading={isSending}
            onClick={submit}
          >
            <span>Отправить заявку</span>
          </SubmitButton>

        </div>
        <div className="sf__detail-col sf__sticky_fixations" style={{ alignSelf: 'start', marginTop: 10, maxWidth: 308, position: 'absolute', right: '-10px', height: '100%' }}>
          <div className="sf__sticky">
            <NewAlert
              title="Важно"
              text="При записи на просмотр квартир, необходимо в поле «комментарий» указать номера квартир, которые Вы хотите посмотреть. "
              bgColor="#FFE6E5"
              customIcon={<FixationImportantIcon color="#FF2D23"/>}
            />
          </div>
        </div>
        </div>
    </div>
  )
}



export default RecordViewing
