import React, {useEffect, useRef, useState} from 'react';
import { ChatFooterFileIcon, ChatMessageActionIcon, ChatMessageSentIcon } from "../Public/markup/icons";
import { getUrl } from '../../api';
import {PhotoPopup} from "../Viewer/PhotoSlider";
import { zulipFilesEndpoint } from './Components';

const getZulipURL = url => getUrl(url, zulipFilesEndpoint)

const Message = ({ message, fromMe, time, images, file, content, content_type, flags = [], id, observer, deleteMsg, forwardMessage, replyMessage, error, scrollToMessage }) => {
  const messageRef = useRef()

  useEffect(() => {
    if (flags?.includes("read") || !messageRef.current || !observer) return
    observer.observe(messageRef.current);

  }, [flags, messageRef, observer])


  const [gallery, setGallery] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const onClickToMessage = e => {
    if(e.target.className === "reply" && e.target.getAttribute("message-id")) scrollToMessage({id: `message-${e.target.getAttribute("message-id")}`})
  }

  return (
    <div className={!fromMe ? "open-chat__overmessage" : 'open-chat__overmessage open-chat__overmessage_me'} id={`message-${id}`} ref={messageRef} onClick={onClickToMessage}>
      <div
        className={`open-chat__message 
        ${fromMe ? 'open-chat__message_me' : ''} 
        ${images?.length === 1 ? 'open-chat__message-image' : ''} 
        ${images?.length === 2 ? 'open-chat__message-image_two' : ''} 
        ${images?.length === 3 ? 'open-chat__message-image_three' : ''} 
        ${images?.length === 4 ? 'open-chat__message-image_four' : ''} 
        ${images?.length > 4 ? 'open-chat__message-image_more' : ''} 
        ${file ? 'open-chat__message_file' : ''} 
        ${error ? 'open-chat__message_error' : ''}`
      }>

        {/*{!!images?.length && images.map(image => <div className="open-chat__message-img" key={image.text}>*/}
        {/*  <img src={getZulipURL(image.src)} alt="" />*/}
        {/*  {content && ["text/html", "text/x-markdown"].includes(content_type) && <div className="open-chat__message-text open-chat__message-text_img" dangerouslySetInnerHTML={{ __html: content }} />}*/}
        {/*</div>)}*/}

        {
          images?.length === 1 &&
          <div className="open-chat__message-img">
            <img src={getZulipURL(images[0].src)} onClick={() => {
              setGallery(images)
              setGalleryIndex(1)
            }}  alt="" />
              {content && ["text/html", "text/x-markdown"].includes(content_type) && <div className="open-chat__message-text open-chat__message-text_img" dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
        }

        {
          images?.length === 2 &&
          <div className="open-chat__message-img">
            <div className="open-chat__message-img_two">
              <img src={getZulipURL(images[0].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(1)
                }}  alt="" />
              <img src={getZulipURL(images[1].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(2)
                }}  alt="" />
            </div>
            {content && ["text/html", "text/x-markdown"].includes(content_type) && <div className="open-chat__message-text open-chat__message-text_img" dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
        }

        {
          images?.length === 3 &&
          <div className="open-chat__message-img">
            <div className="open-chat__message-img_three">
              <div className="open-chat__message-img_three-row">
                <img src={getZulipURL(images[0].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(1)
                }}   alt="" />
                <img src={getZulipURL(images[1].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(2)
                }}   alt="" />
              </div>
              <div className="open-chat__message-img_three-row">
                <img src={getZulipURL(images[2].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(3)
                }}   alt="" />
              </div>
            </div>
            {content && ["text/html", "text/x-markdown"].includes(content_type) && <div className="open-chat__message-text open-chat__message-text_img" dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
        }

        {
          images?.length === 4 &&
          <>
          <div className="open-chat__message-img">
            <div className="open-chat__message-img_four">
              <div className="open-chat__message-img_four-row">
                <img src={getZulipURL(images[0].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(1)
                }}  alt="" />
                <img src={getZulipURL(images[1].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(2)
                }}  alt="" />
              </div>
              <div className="open-chat__message-img_four-row">
                <img src={getZulipURL(images[2].src)} onClick={() => {
                  setGallery(images)
                  setGalleryIndex(3)
                }}  alt="" />
                <div style={{position: 'relative'}}>
                  <img src={getZulipURL(images[3].src)} onClick={() => {
                    setGallery(images)
                    setGalleryIndex(4)
                  }} alt="" />
                  {images.length === 4 &&
                    <div className="open-chat__message-img_four-more">
                      + {images.length}
                    </div>
                  }
                </div>

              </div>
            </div>
            {content && ["text/html", "text/x-markdown"].includes(content_type) && <div className="open-chat__message-text open-chat__message-text_img" dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
          </>
        }


        {content && ["text/html", "text/x-markdown"].includes(content_type) && !images?.length && <div className="open-chat__message-text" dangerouslySetInnerHTML={{ __html: content }} />}
        {!!error &&<p> Пока так замутил вывод ошибки, надо доделать: {error}</p>}
        {file && <div className="open-chat__message-file"><span><ChatFooterFileIcon /></span><span>{file}</span></div>}
        <div className="open-chat__message-info">
          <div className="open-chat__message-time">{time}</div>
          {flags.includes("read") && fromMe && <div className="open-chat__message-status">
            <ChatMessageSentIcon />
          </div>}
          {!!error &&
            <span className="open-chat__message-error-flag">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.8999" cy="12" r="8.5" stroke="#EF372E"/>
                <line x1="12.9434" y1="7.5" x2="12.9434" y2="13.5" stroke="#EF372E" stroke-linecap="round"/>
                <circle cx="12.9434" cy="15.5" r="0.5" fill="#EF372E"/>
              </svg>

              <ul className="open-chat__action-list">
                <li>Отправить</li>
                <li style={{color: '#EF372E'}}>Удалить</li>
              </ul>
          </span>}
        </div>
      </div>
      <div className="open-chat__message-actions">
        <span className="open-chat__message-actionbtn">
          <ChatMessageActionIcon />
          <ul className="open-chat__action-list">
            <li onClick={replyMessage}>Ответить</li>
            <li>Сохранить</li>
            <li onClick={forwardMessage}>Переслать</li>
            <li>Копировать</li>
            <li>Редактировать</li>
            <li style={{ color: '#FF2D23FF' }} onClick={deleteMsg}>Удалить</li>
          </ul>
        </span>

      </div>
      <PhotoPopup
        items={gallery}
        index={galleryIndex}
        open={!!gallery?.length}
        onClose={() => setGallery(false)}

      />
    </div>
  )
}

export default Message
