import React, { useState } from 'react'
import useFileManager from '../hooks/useFileManager'

export const FileRow = ({ name, del, edit }) => {

    const [isEditing, setIsEditing] = useState(false)
    const setEditing = () => setIsEditing(prev => !prev)
    if (!name && !isEditing) return ""
    return <div class="file-uploader">
        <div class="file-uploader__inner">
            <svg class="icon file-uploader__icon">
                <use xlinkHref="#deals-01"></use>
            </svg>
            <span class="file-uploader__name">{name}</span>
            <div class="file-uploader__controls">
                <button
                    class="button button_view_round-control file-uploader__del"
                    type="button"
                    onClick={del}
                >
                    <svg class="icon">
                        <use xlinkHref="#x"></use>
                    </svg>
                </button>
                {!!edit && <button
                    class="button button_view_round-control file-uploader__edit"
                    type="button"
                    onClick={setEditing}
                >
                    <svg class="icon">
                        <use xlinkHref="#edit"></use>
                    </svg>
                </button>}
            </div>
        </div>
        {isEditing && <div class="file-uploader__input input input_type_form">
            <input type="text" class="input__control" value={name} onChange={e => edit(e.target.value)} />
            <button class="input__remove" type="button" onClick={() => edit("")}>
                <svg class="icon">
                    <use xlinkHref="#x"></use>
                </svg>
            </button>
        </div>}
    </div>
}

const FileUploader = ({ onFilesChange }) => {

    const { files, uploadFiles, deleteFile, editFileName } = useFileManager({
        maxFiles: 10,
        onFilesChange: onFilesChange
    })

    return (
        <div className="modal-form__row">
            <div className="modal-form__item">
                <label
                    className="input input_type_upload-btn"
                    htmlFor="modal-reservation-file-input"
                >
                    <input
                        class="input__control"
                        type="file"
                        multiple
                        id="modal-reservation-file-input"
                        name="file"
                        onChange={uploadFiles}
                    />
            Прикрепите файлы
          </label>
                <div
                    className="modal-form__files"
                >
                    {files.map((file, index) => (
                        <FileRow key={file.id} name={file.fileName} del={() => deleteFile(index)} edit={(val) => editFileName(val, index)} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FileUploader
