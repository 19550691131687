import React from "react";
import { Link } from "react-router-dom";
import { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import usePermission from "../../hooks/usePermission";
import Photos from "../Complex/Photos";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import mocData from "./mocData";

import SimilarCardsList from "./SimilarCardsList";


const data = mocData;
const photos = { ...data.block.photos }
const photosComplex = {
  complex: [...(photos.complex ? photos.complex : [])],
  ...(!!data.block.aeroPanorama ? { aeroPanorama: data.block.aeroPanorama } : [])
}
const DetailCard = props => {
  const isMobile = useMedia("isMobile");
  const {
    presentation,
    heightCell1,
    decoration,
    features,
    manager,
    floors,
    openSpecialModal,
    countBedrooms,
    heightCell2,
    commission,
    id,
    title,
    price,
    squareTotal,
    meterPrice,
    section,
    squareHouse,
    constructionPeriod,
    photos,
    planPhotos,
    decorationPhotos,
    livingWinter,
    semiObjects,
    hidePrice,
    isPublic
  } = props

  const photosObject = {
    complex: photos,
    plan: planPhotos
  }

  const shoowMotivation = usePermission("motivation")

  return (
    <>
      <article className="special__detail-card">

        <h2 className="special__detail-h2">{title}</h2>

        <div className="special__detail-slider">
          <ErrorPlug stopSending={true}>
            <Photos
              photos={photosObject}
              tour={data.builder.typical3DTour}
              addClass={`assignation-photos special__detail-photos`}
              blurBg
              customIdent={`photos-${id}`}
              disableIcons={true}
              withToggler
              wideTrumbs
              navTitles={{
                complex: "Фотографии",
                plan: "Планировки"
              }}
            />
          </ErrorPlug>
        </div>

        <div className="special__detail-info">
          <div className="special__detail-top">
            <h1 className="h1 special__detail-name">{title}</h1>
            {!isPublic && !!commission && shoowMotivation && <span className="special__label">Комиссия: {commission}</span>
            }

          </div>

          <div className="special__detail-grid">
            {!!price && !hidePrice && <div className="special__detail-col">
              <span className="special__detail-value special__detail-value_lg">{price}</span>
              <span className="special__detail-sub">{meterPrice}</span>
            </div>}
            <div className="special__detail-col">
              <span className="special__detail-value special__detail-value_lg">{squareTotal}</span>
              <span className="special__detail-sub">Общая площадь</span>
            </div>
          </div>

          <div className="special__detail-btns">
            {presentation?.src && <a href={getUrl(presentation.src, "https://panpartner.ru")} type="application/octet-stream" target="_blank" onClick={() => openSpecialModal(title, manager)} className="button button_view_default special__detail-pres" download>Скачать презентацию</a>}
            {!isPublic && <Link
              to={`/client-fixation-special/${id}`}
              target={`_blank`}
              className="button button_view_outline-with-icon special__detail-lock"
            >
              <img src={require('../../assets/images/special/lock.svg').default} alt="" />
              Фиксация клиента
            </Link>}
          </div>
          <div className="special__detail-grid special__detail-grid_no-border">
            {heightCell1 && <div className="special__detail-col">
              <span className="special__detail-value">{heightCell1}{heightCell2 ? ` и ${heightCell2}` : ""} м</span>
              <span className="special__detail-sub">Высота потолков</span>
            </div>}
            {countBedrooms && <div className="special__detail-col">
              <span className="special__detail-value">{countBedrooms}</span>
              <span className="special__detail-sub">Количество спален</span>
            </div>}

            {decoration?.title && <div className="special__detail-col">
              <span className="special__detail-value">{decoration.title}</span>
              <span className="special__detail-sub">Отделка</span>
            </div>}
            {features && <div className="special__detail-col">
              <span className="special__detail-value">{features.map((v, i, arr) => `${v?.title}${i !== (arr.length - 1) ? ", " : ""}`)}</span>
              <span className="special__detail-sub">Особенности</span>
            </div>}
            {floors && <div className="special__detail-col">
              <span className="special__detail-value">{floors}</span>
              <span className="special__detail-sub">Этажей</span>
            </div>}
            {constructionPeriod?.title && <div className="special__detail-col">
              <span className="special__detail-value">{constructionPeriod.title}</span>
              <span className="special__detail-sub">Срок строительства</span>
            </div>}
            <div className="special__detail-col">
              <span className="special__detail-value">{livingWinter? "Да": "Нет"}</span>
              <span className="special__detail-sub">Проживание зимой</span>
            </div>
            <div className="special__detail-col">
              <span className="special__detail-value">{section}</span>
              <span className="special__detail-sub">Тип</span>
            </div>
          </div>

          {manager && <div className="special__manager">
            {manager.photo && <img className="special__manager-avatar" src={getUrl(manager.photo.src)} alt="" />}
            <div className="special__manager-info">
              <span className="special__manager-name">{manager.name}<br />{manager.lastName}</span>
              <span className="special__manager-position special__detail-sub">Ваш личный менеджер</span>
            </div>


            {
              isMobile ? (
                <div className="info">
                  <svg className="icon icon_info">
                    <use xlinkHref="#info"></use>
                  </svg>
                  <div className="info__popup">
                    <div className="special__manager-contacts">
                      <a className="link link_color_black" href={`tel:${manager.phone}`}>{manager.phone}</a>
                      <a className="link link_color_black" href={`mailto:${manager.email}`}>{manager.email}</a>
                      {manager.telegram && <a className="link link_color_black" href={`https://tlgg.ru/${manager.telegram.replace("@", "")}`}>{manager.telegram}</a>}
                    </div>
                  </div>
                </div>
              ) :
                (
                  <div className="special__manager-contacts">
                    <a className="link link_color_black" href={`tel:${manager.phone}`}>{manager.phone}</a>
                    <a className="link link_color_black" href={`mailto:${manager.email}`}>{manager.email}</a>
                    {manager.telegram && <a className="link link_color_black" href={`https://tlgg.ru/${manager.telegram.replace("@", "")}`}>{manager.telegram}</a>}
                  </div>
                )
            }

          </div>}
        </div>
      </article>

      {!!semiObjects?.length && <SimilarCardsList hidePrice semiObjects={semiObjects}/>}
    </>
  );
};

export default DetailCard;
