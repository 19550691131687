import React from "react";
import useApi from "../../hooks/useApi";
import RecentlyCard from "../../components/RecentlyCard";
import {getUrl} from "../../api";
import Preloader from "../../components/Preloader";

const ViewingEstate = () => {

  const { data: { viewedObjects }, isLoading} = useApi({
    payload: ["panpartner:viewed.objects.ajax", "getItems"],
  })

  if (isLoading) return <Preloader loading={true} />;
  return (
    <div className="wrapper sf">
      <h3 className="h3 sf__h1" style={{marginBottom: 28}}>
        <p>Вы недавно смотрели</p>
      </h3>
      <div className="sf__cards-list">
        {
          viewedObjects !== undefined && viewedObjects.map((item) => {
            return(
              <RecentlyCard
                img={getUrl(item?.objectInfo?.photo?.src)}
                link={item?.objectInfo?.link}
                price={item?.objectInfo?.price}
                description={item?.objectInfo?.description}
                builder={item?.objectInfo?.title}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default ViewingEstate
