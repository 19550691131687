import { useMediaQuery } from 'beautiful-react-hooks';
const queries = {
  isSmallMobile: "(max-width: 374px)",
  isMobile: "(max-width: 640px)",
  isTablet: "(max-width: 767px)",
  isSmallLaptop: "(max-width: 1023px)",
  isLaptop: "(max-width: 1279px)",
  isDesktop: "(max-width: 1599px)",
  is950: "(max-width: 950px)",

  breakLaptop: "(max-width: 1279px)",
}

/**
 * @param {String}
 * - isSmallMobile: max-width: 374px;
 * - isMobile: max-width: 640px
 * - isTablet: max-width: 767px;
 * - isLaptop: max-width: 1279px;
 * - isDeskto: max-width: 1599px;
 * - breakLaptop: max-width: 1279px;
 */
const useMedia = type => useMediaQuery(queries[type]);
export default useMedia;
