import { memo } from "react";
import { getUrl } from "../../../../api";
import { priceFormat } from "../../../../utils";
import Card from "../../../core/Card";


const CommercialPublicCard = memo((props) => {
    const { blockName, link, endingDate, plan, costWithDiscount, squareTotal, status } = props
    const cardTitle = `${priceFormat(costWithDiscount)}, ${squareTotal} м²`

    return <Card
        {...props}
        isFlat
        isBordered
        link={link}
        bottomText={`Срок сдачи: ${endingDate}`}
        title={cardTitle}
        src={getUrl(plan.src)}
        descTitle={blockName}
        status={status}
    />
});

export default CommercialPublicCard;
