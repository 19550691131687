import _filter from 'lodash/filter';
import without from 'lodash/without';
import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import {
  clearFilterSuburbans,
  getCountForFilter, setFilterSuburbans,
  setView
} from "../../actions/suburbans";
import useMedia from "../../hooks/useMedia";
import {
  filterToParams,

  getSuffix, isEmptyFilterOrExclude
} from "../../utils";
import {
  defaultFullFilterSuburbans
} from "../../utils/defaultFilters";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import Wrap from '../core/ModalWrap';
import SaveFilter from "../SavedFilters/SendFilter";
import Controls from './Controls';
import DistrictsFilter from "./DistrictsFilter";
import Dragable from "./Dragable";
import EndingsFilter from "./EndingsFilter";
import FloorsFilter from "./FloorsFilter";
import PriceFilter from "./PriceFilter";
import SelectFilter from "./SelectFilter";
import SmartFilter from "./SmartFilter/";
import SquareFilterOnly from "./SquareFilterOnly";
var inDebounce;
const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    countBlocksToShow,
    city,
    getCountSuburbans,
    fetching,
    setView,
    countApartmentsTotal,
    headerRef,
    isFiltersOpen,
    onClick,
    isShowFilter,
    isPublic,
    savedFilterXmlId
  } = props;
  const [showFilter, toggleFilter] = useState(true);
  const isMobile = useMedia("isMobile")
  const smallScreen = useMedia("isLaptop");
  const mediumScreen = useMedia("isDesctop");

  const handleFilter = (data, identity) => {
    let tempValue = {
      filter: {
        [identity]: data.filter ? data.filter : filter.filter[identity],
      },
      exclude: {
        [identity]: data.exclude ? data.exclude : filter.exclude[identity],
      },
    };
    if (identity === "isOnlyHanded") {
      tempValue.filter.endings = { min: "", max: "" };
      tempValue.exclude.endings = { min: "", max: "" };
    }
    setFilter(tempValue);
    let tmpFilter = {
      filter: { ...filter.filter, ...tempValue.filter },
      exclude: { ...filter.exclude, ...tempValue.exclude },
    };

    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      getCountSF(tmpFilter);
    }, 300);
  };
  const getCountSF = (tmpFilter) => {
    let params = filterToParams(tmpFilter, false, savedFilterXmlId);
    getCountSuburbans(props.city, params);
  }

  const [clearedSmartFilter, setCleared] = useState(false);
  const handleClearFilter = (e) => {
    if (e) {
      e.preventDefault();
    }
    setCleared("full")
    getCountSuburbans(props.city, filterToParams(defaultFullFilterSuburbans, false, savedFilterXmlId));
    clearFilter();
  };

  const handleFilterClick = (e, showMap = false) => {
    e.preventDefault();
    if (showMap) {
      setView("map");
    }
    onSubmit(showMap);
  };

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SaveFilter
          filter={filter}
          filterData={filterData}
          filterType="suburban"
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap >
    ),
    [filter, filterData],
  );

  const clearExcludeOnly = () => {
    props.clearExcludeOnly()
    setCleared("exclude")
  }
  const clearFilterOnly = () => {
    props.clearFilterOnly()
    setCleared("filter")
  }

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    document.body.classList.add('no-scroll')
    headerRef.header?.current && headerRef.header.current.classList.add('hidden')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
    headerRef.header?.current && headerRef.header.current.classList.remove('hidden')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  const showAllClick = (e, showMap = false) => {
    e.preventDefault();
    toggleFilters()
    if (showMap) {
      setView("map");
    }
    onSubmit(showMap);
    onClick && onClick();
  }

  const handleToggle = (e) => {
    let tempFilter = [...filter.filter.status];
    if (e.target.checked) {
      tempFilter.push("3");
    } else {
      tempFilter = without(tempFilter, "3");
    }
    setFilter({
      filter: { ...filter.filter, status: tempFilter },
    });
    let params = filterToParams({
      ...filter,
      filter: { ...filter.filter, status: tempFilter },
    }, false, savedFilterXmlId);
    getCountSuburbans(props.city, params);
  };
  useEffect(() => {
    if (isFiltersOpen) toggleFilters();
  }, [isFiltersOpen]);

  const [count, setCount] = useState(0);

  const countFilters = (filtersCount) => {
    setCount(filtersCount)
  }

  const dragable =
  <div className={!!isShowFilter ? "" : "public__params"}>
    <Dragable
      filter={filter.filter}
      fullFilter={filter}
      exclude={filter.exclude}
      filterData={filterData}
      handleFilter={handleFilter}
      clearExcludeOnly={clearExcludeOnly}
      clearFilterOnly={clearFilterOnly}
      getCount={(filterOrExclude) =>
        getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
      }
      enableRemoving={!isPublic && !isShowFilter}
      hideParams={true}
      defFullFilter={defaultFullFilterSuburbans}
      isMobile={isOpen && isMobile}
      countFilters={countFilters}
      readonly={isPublic}
    />
    </div>

  const topFilters = (
    <>
      <DistrictsFilter
        onChange={(a, b) =>
          handleFilter({ filter: a, exclude: b }, "districts")
        }
        isExcludable={true}
        isAutocomplete={true}
        items={
          filterData.districts
            ? Object.values(filterData.districts)
            : []
        }
        filterValues={filter.filter.districts}
        excludeValues={filter.exclude.districts}
        data={{
          title: "Район",
          autocompleteTitle: "Введите название района",
          identity: "districts",
        }}
        city={props.city}
        setDistricts={(a) =>
          handleFilter({ filter: a }, "districts")
        }
        isMobile={isOpen && isMobile}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        getCount={(filterOrExclude) =>
          getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
        }
        defFullFilter={defaultFullFilterSuburbans}
        fullFilter={filter}
        right={isMobile && true}
      />
      <SelectFilter
        onChange={(a, b) =>
          handleFilter({ filter: a, exclude: b }, "type")
        }
        isExcludable={true}
        items={
          filterData.type
            ? Object.values(filterData.type)
            : []
        }
        filterValues={filter.filter.type}
        excludeValues={filter.exclude.type}
        data={{
          title: "Тип объекта",
          autocompleteTitle: "",
          identity: "type",
        }}
        isAutocomplete={false}
        isMobile={isOpen && isMobile}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        getCount={(filterOrExclude) =>
          getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
        }
        defFullFilter={defaultFullFilterSuburbans}
        fullFilter={filter}
      />

      <SquareFilterOnly
        filter={filter.filter.squareParcel}
        data={{
          title: 'Площадь участка',
          minPlaceholder: 'S уч. от ',
          maxPlaceholder: 'S уч. до ',
          defaultValues: filterData.squareParcel
            ? filterData.squareParcel.range
            : null,
        }}
        onChange={(a, identity) => handleFilter(a, identity)}
        clearFilterOnly={clearFilter}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        identity={"squareParcel"}
        placeholderMes="сот."
        items={filterData.squareParcel?.items}
        showPlaceholderOnLists
      />
      <SquareFilterOnly
        right={true}
        filter={filter.filter.squareHouse}
        data={{
          title: 'Площадь дома',
          minPlaceholder: 'S дома от ',
          maxPlaceholder: 'S дома до ',
          defaultValues: filterData.squareHouse
            ? filterData.squareHouse.range
            : null,
        }}
        onChange={(a, identity) => handleFilter(a, identity)}
        identity={"squareHouse"}
        clearFilterOnly={clearFilter}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        items={filterData.squareHouse?.items}
        showPlaceholderOnLists
      />
    </>
  )

  const filters = (
    <div className="index-search__filter-body">
      <div className="index-search__filter-top">
        <div className="form__row">

          <SmartFilter
            filterData={filterData}
            setFilter={setFilter}
            handleFilter={handleFilter}
            filterValues={filter.filter}
            excludeValues={filter.exclude} 
            fullFilterValues={filter}
            clearFilter={handleClearFilter}
            toggleFilter={toggleFilter}
            showFilter={showFilter}
            submit={onSubmit}
            city={props.city}
            clearExcludeOnly={clearExcludeOnly}
            clearFilterOnly={clearFilterOnly}
            getCount={getCountSF}
            isCleared={clearedSmartFilter}
            resetCleared={() => setCleared(false)}
            isMobile={isOpen}
            countApartmentsTotal={countApartmentsTotal}
            placholder="Посёлок, застройщик, район"
            textFilters={['districts', 'subways', 'blocks', 'banks', 'builders', "villages"]}
          />

          {isMobile && !isOpen &&
            <button className="button-mobile for-main" onClick={toggleFilters}>
              Все фильтры
              {count > 0 && ` (${count})`}
            </button>
          }

          {isOpen && filter && filterData && !isEmptyFilterOrExclude(filter, "full", defaultFullFilterSuburbans) &&
            dragable
          }

          {showFilter && !isMobile && topFilters}

          {showFilter && isOpen && isMobile && topFilters}

          {!isMobile &&
            <button
              className="button button_type_reveal-filter"
              type="button"
              onClick={() => toggleFilter(!showFilter)}
              style={{ display: showFilter ? "none" : "block" }}
            >
              <span className="visible_mobile">Фильтры</span>
              <span className="visible_note">Развернуть фильтр</span>
            </button>
          }
        </div>
      </div>

      {(!isMobile || isOpen) &&
        <div
          className="index-search__filter-main"
          style={{ display: showFilter ? "block" : "none" }}
        >
          <div className="form__row">
            <PriceFilter
              filter={
                filter.filter.prices
                  ? filter.filter.prices
                  : { min: "", max: "" }
              }
              exclude={
                filter.exclude.prices
                  ? filter.exclude.prices
                  : { min: "", max: "" }
              }
              items={
                filterData.prices ? filterData.prices.items : []
              }
              data={{
                title: "Цена",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.prices
                  ? filterData.prices.range
                  : 0,
              }}
              onChange={(a) => handleFilter(a, "prices")}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              // getCount={(filterOrExclude) =>
              //   getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              // }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
            />
          <SelectFilter
            autoPosition
            onChange={(a, b) =>
              handleFilter({ filter: a, exclude: b }, "payment")
            }
            isExcludable={true}
            isAutocomplete={false}
            items={
              filterData.payment
                ? Object.values(filterData.payment)
                : []
            }
            filterValues={filter.filter.payment}
            excludeValues={filter.exclude.payment}
            data={{
              title: "Способ оплаты",
              autocompleteTitle: "",
              identity: "payment",
            }}
            isMobile={isOpen && isMobile}
            clearFilterOnly={clearFilterOnly}
            clearExcludeOnly={clearExcludeOnly}
            defFullFilter={defaultFullFilterSuburbans}
            fullFilter={filter}
          />
            <EndingsFilter
              ident={"endings"}
              right={mediumScreen}
              filter={
                filter.filter.endings
                  ? filter.filter.endings
                  : { min: "", max: "" }
              }
              items={
                filterData.endings ? filterData.endings.items : []
              }
              data={{
                title: "Срок сдачи",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.endings
                  ? filterData.endings.ranges
                  : null,
              }}
              onChange={(a) => handleFilter(a, "endings")}
              secondData={{
                title: "Сданные дома",
                value: filter.filter?.isOnlyHanded?.length
                  ? filter.filter?.isOnlyHanded[0]?.id
                  : "N",
              }}
              secondChange={(a) => {
                let tmp = a === "Y" ? [{ id: a }] : [];
                handleFilter(
                  {
                    filter: tmp,
                    exclude: [
                      ..._filter(
                        filter.exclude.isOnlyHanded,
                        (val) => val.id != a,
                      ),
                    ],
                  },
                  "isOnlyHanded",
                );
              }}
              style={{ display: showFilter ? "block" : "none" }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "builders")
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.builders
                  ? Object.values(filterData.builders)
                  : []
              }
              filterValues={filter.filter.builders}
              excludeValues={filter.exclude.builders}
              data={{
                title: "Застройщик",
                autocompleteTitle: "Введите название застройщика",
                identity: "builders",
              }}
              right={!smallScreen}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "materials")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.materials
                  ? Object.values(filterData.materials)
                  : []
              }
              filterValues={filter.filter.materials}
              excludeValues={filter.exclude.materials}
              data={{
                title: "Материал дома",
                autocompleteTitle: "",
                identity: "materials",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
              right={smallScreen}
            />
             <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "features")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.features
                  ? Object.values(filterData.features)
                  : []
              }
              filterValues={filter.filter.features}
              excludeValues={filter.exclude.features}
              data={{
                title: "Особенности",
                autocompleteTitle: "",
                identity: "features",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
              right={!smallScreen}
            />
               <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "communications")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.communications
                  ? Object.values(filterData.communications)
                  : []
              }
              filterValues={filter.filter.communications}
              excludeValues={filter.exclude.communications}
              data={{
                title: "Коммуникации",
                autocompleteTitle: "",
                identity: "communications",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
              right={!smallScreen}
            />
              <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, "villages")
              }
              isExcludable={true}
              isAutocomplete={false}
              items={
                filterData.villages
                  ? Object.values(filterData.villages)
                  : []
              }
              filterValues={filter.filter.villages}
              excludeValues={filter.exclude.villages}
              data={{
                title: "Посёлок",
                autocompleteTitle: "",
                identity: "villages",
              }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
              right={smallScreen}
            />
          <FloorsFilter
              // right={mediumScreen}
              filter={filter.filter.countFloors}
              items={
                filterData.countFloors?.items ? filterData.countFloors.items : []
              }
              data={{
                title: "Этажность",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.countFloors
                  ? filterData.countFloors.range
                  : null,
              }}
              onChange={(a) => handleFilter(a, "countFloors")}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
              right={smallScreen}
            />
            {/* скрыто тк пока высоток нет */}
            {false && <FloorsFilter
              // right={mediumScreen}
              filter={filter.filter.floors}
              items={
                filterData.floors?.items ? filterData.floors.items : []
              }
              data={{
                title: "Этаж",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.floors
                  ? filterData.floors.range
                  : null,
              }}
              onChange={(a) => handleFilter(a, "floors")}
              // secondData={{
              //   title: filterData.isNoFirstFloor
              //     ? filterData.isNoFirstFloor[0].title
              //     : "Не первый",
              //   value: filter.filter.isNoFirstFloor.length
              //     ? filter.filter.isNoFirstFloor[0].id
              //     : "N",
              // }}
              // secondChange={(a) => {
              //   let tmp = a === "Y" ? [{ id: a }] : [];
              //   handleFilter(
              //     {
              //       filter: tmp,
              //       exclude: [
              //         ..._filter(
              //           filter.exclude.isNoFirstFloor,
              //           (val) => val.id != a,
              //         ),
              //       ],
              //     },
              //     "isNoFirstFloor",
              //   );
              // }}
              isMobile={isOpen && isMobile}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              getCount={(filterOrExclude) =>
                getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
              }
              defFullFilter={defaultFullFilterSuburbans}
              fullFilter={filter}
              right={smallScreen}
            />}
            <SelectFilter
            onChange={(a, b) =>
              handleFilter({ filter: a, exclude: b }, "nearby")
            }
            isExcludable={true}
            isAutocomplete={false}
            items={
              filterData.nearby
                ? Object.values(filterData.nearby)
                : []
            }
            filterValues={filter.filter.nearby}
            excludeValues={filter.exclude.nearby}
            data={{
              title: "Рядом",
              autocompleteTitle: "",
              identity: "nearby",
            }}
            isMobile={isOpen && isMobile}
            clearFilterOnly={clearFilterOnly}
            clearExcludeOnly={clearExcludeOnly}
            getCount={(filterOrExclude) =>
              getCountSuburbans(props.city, filterToParams(filterOrExclude, false, savedFilterXmlId))
            }
            defFullFilter={defaultFullFilterSuburbans}
            fullFilter={filter}
          />
           <SelectFilter
            autoPosition
            onChange={(a, b) =>
              handleFilter({ filter: a, exclude: b }, "purpose")
            }
            isExcludable={true}
            isAutocomplete={false}
            items={
              filterData.purpose
                ? Object.values(filterData.purpose)
                : []
            }
            filterValues={filter.filter.purpose}
            excludeValues={filter.exclude.purpose}
            data={{
              title: "Статус земли",
              autocompleteTitle: "",
              identity: "purpose",
            }}
            isMobile={isOpen && isMobile}
            clearFilterOnly={clearFilterOnly}
            clearExcludeOnly={clearExcludeOnly}
            defFullFilter={defaultFullFilterSuburbans}
            fullFilter={filter}
          />
<div className="form__row">
              <div className="index-search__show-booked">
                <label className="switch" htmlFor="filter-booked">
                  <div className="switch__box">
                    <input
                      className="switch__control"
                      type="checkbox"
                      id="filter-booked"
                      checked={filter.filter.status.includes("3")}
                      onChange={handleToggle}
                    />
                    <div
                      className="switch__input"
                      htmlFor="filter-booked"
                    ></div>
                    <div className="switch__marker"></div>
                  </div>
                  <span className="switch__label">
                    Показать забронированные
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )


  return (
    <section className="section index-search index-search_dark for-main">
      <div className="wrapper">
        <div className="section__grid">
          <div className="section__grid-col index-search__header for-main">
            <h1 className={isMobile ? "" : "h2"}>
              {isMobile ?
                <>
                  Загородная недвижимость
                  <br />
                  {props.city == "msk" ? "Москвы" : "Санкт-Петербурга"}
                </> :
                <>
                  {`Загородная недвижимость ${props.city == "msk" ? "Москвы" : "Санкт-Петербурга"}`}
                </>
              }
            </h1>
          </div>
          <div className="section__grid-col index-search__filter ">
            <form className="form">
              {isOpen && isMobile && isShowFilter ?
                <div className="mobile-filters">
                  <div className="mobile-filters__title">
                    <svg
                      className="icon icon_arrow_up"
                      onClick={() => {
                        toggleFilters()
                        onClick && onClick()
                      }}
                    >
                      <use xlinkHref="#arrow_up"></use>
                    </svg>
                    <h3>Все фильтры</h3>
                  </div>
                  <div className="mobile-filters__content">
                    {filters}

                    {!isPublic && <button
                      className="button button_view_outline for-mobile-filters"
                      onClick={(e) => {
                        e.preventDefault();
                        saveFilterModal();
                      }}
                    >
                      Сохранить фильтр
                    </button>}
                  </div>

                  <div className="mobile-filters__show-all">

                    {countBlocksToShow ? (
                      <button
                        className="button button_view_default"
                        type="submit"
                        onClick={showAllClick}
                        disabled={fetching ? true : false}
                      >
                        Показать{` ${countBlocksToShow} посёлков`}
                      </button>
                    ) : (
                        <button
                          className="button button_view_default"
                          type="submit"
                          disabled
                        >
                          Ничего не найдено
                        </button>
                      )}
                  </div>
                </div> : isShowFilter?  filters : null
              }

              {!isOpen && filter && filterData && !isEmptyFilterOrExclude(filter, "full", defaultFullFilterSuburbans) &&
                dragable
              }

              {isShowFilter && <Controls
                saveFilterModal={saveFilterModal}
                handleClearFilter={handleClearFilter}
                handleFilterClick={handleFilterClick}
                countBlocksToShow={countBlocksToShow}
                fetching={fetching}
                isHomePage={props.isHomePage}
                isMobile={isMobile}
                altButtonTitle ={(count)=>`Показать ${count} объект${getSuffix(count)}`}
                isPublic={isPublic}
              />}
            </form>


          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const isPublic = ownProps.isPublic
  return {
  city: ownProps.city ? ownProps.city : (state.user?.info?.city || "spb") ,
  filterData: state.suburbans.filterData,
  filter: state.suburbans.filter,
  countBlocksToShow: state.suburbans.countVillagesToShow,
  headerRef: state.refs,
  isShowFilter: isPublic? state.suburbans.isShowFilter : true
}};

const mapDispatchToProps = (dispatch, ownProps) => {
  let cityProps = ownProps.city;
  return {
    setFilter: (filter) => dispatch(setFilterSuburbans(filter)),
    clearFilter: () => dispatch(clearFilterSuburbans()),
    getCountSuburbans: (city, params) =>getCountForFilter(cityProps ? cityProps : city, params, dispatch),
    clearFilterOnly: () => dispatch({ type: "SUBURBANS_CLEAR_FILTER_ONLY" }),
    clearExcludeOnly: () => dispatch({ type: "SUBURBANS_CLEAR_EXCLUDE_ONLY" }),
    setView: (view) => dispatch(setView(view)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
