export const ListIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="5.5"
      y1="5.5"
      x2="18.5"
      y2="5.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <line
      x1="5.5"
      y1="11.5"
      x2="18.5"
      y2="11.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <line
      x1="5.5"
      y1="17.5"
      x2="18.5"
      y2="17.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export const TileIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="5" width="2.8" height="2.8" rx="1.4" fill="currentColor" />
    <rect x="5" y="10.6001" width="2.8" height="2.8" rx="1.4" fill="currentColor" />
    <rect x="5" y="16.2" width="2.8" height="2.8" rx="1.4" fill="currentColor" />
    <rect x="10.6001" y="5" width="2.8" height="2.8" rx="1.4" fill="currentColor" />
    <rect
      x="10.6001"
      y="10.6001"
      width="2.8"
      height="2.8"
      rx="1.4"
      fill="currentColor"
    />
    <rect
      x="10.6001"
      y="16.2"
      width="2.8"
      height="2.8"
      rx="1.4"
      fill="currentColor"
    />
    <rect x="16.2" y="5" width="2.8" height="2.8" rx="1.4" fill="currentColor" />
    <rect
      x="16.2"
      y="10.6001"
      width="2.8"
      height="2.8"
      rx="1.4"
      fill="currentColor"
    />
    <rect x="16.2" y="16.2" width="2.8" height="2.8" rx="1.4" fill="currentColor" />
  </svg>
);

export const ChessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2244 6.02734V18.0273"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2244 12.0273H6.22437"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="5.72437"
      y="5.52734"
      width="13"
      height="13"
      rx="1.5"
      stroke="currentColor"
    />
  </svg>
);