import React from 'react';

const Socials = (props) => {
  const { items } = props;

  return (
    <div className="footer__social footer__block">
      <ul className="footer__social-list">
        {items.map((item, index) => (
          <li className="footer__social-item" key={index}>
            <a
              className="footer__social-link button button_with-icon button_view_social"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className={`icon icon_social-${item.icon}`}>
                <use xlinkHref={`#social-${item.icon}`}></use>
              </svg>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Socials;
