import './index.scss';
import classNames from 'classnames';

const StatusCircle = ({addClass, status}) => {

  const StatusClasses = classNames({
    [`status-circle status-circle_${status.color}`]: true,
    [addClass]: addClass,
  });
  return (
    <div
      className={StatusClasses}
    >
      <div className="status-circle__tooltip">{status?.name}</div>
    </div>
  );
};

export default StatusCircle;