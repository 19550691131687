import axios from 'axios';
import request, { apiUrl } from '../api';
import { actionFactory, handleResponse } from './actionUtils';

const comp = "panpartner:presentations.ajax"

export const requestPresentation = actionFactory("PRESENTATION_REQUEST");
export const requestPresentationEnd = actionFactory("PRESENTATION_REQUEST_END");

const successPresentation = actionFactory("PRESENTATION_SUCCESS");

const successClients = actionFactory("PRESENTATION_CLIENTS_SUCCESS");

export const clearPresentation = actionFactory("PRESENTATION_RESET");

const failurePresentation = actionFactory("PRESENTATION_FAILURE");

export const fetchPresentation = (id) =>
  dispatch => {
    dispatch(requestPresentation());
    return request(comp, "getPresentation", { id })
      .then(
        data => {
          const { presentation, settings, formData, settingsData } = handleData(
            data
          );
          dispatch(
            successPresentation({
              data: presentation,
              settings,
              formData,
              settingsData,
            })
          );
        },
        error => {
          dispatch(failurePresentation());
          return Promise.reject(error);
        }
      );
  };

const handleData = ({ presentation, settings }) => {
  let settingsData = {};
  let currSettings = {};

  Object.entries(settings).forEach(([key, item]) => {

    settingsData[key] = item.values[0].id;
    currSettings[key] = item.value ?? "";
    // if(key === "agent_certificate"){
    //   settingsData[key] = item.values[0].def;
    // }
  });

  return {
    presentation,
    settings,
    settingsData,
    currSettings,
    formData: {
      settings: currSettings,
      comment: presentation.detailText,
      description: presentation.previewText,
    },
  };
};

export const editPresentation = (settings, id, description, comment, city, firstPayEstate, rateEstate, timeEstate) =>
  dispatch => {
    dispatch(requestPresentation());
    return request(comp, "editPresentation", {
      id,
      ...settings,
      description,
      comment,
      city,
      'mortgage[initialPayment]': firstPayEstate,
      'mortgage[percent]': rateEstate,
      'mortgage[period]': timeEstate,

    }).then(
      data => {
        const { presentation, settings, formData, settingsData } = handleData(
          data
        );
        dispatch(
          successPresentation({
            data: presentation,
            settings,
            formData,
            settingsData,
            filePath: data.filePath
          })
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(failurePresentation());
        return Promise.reject(error);
      }
    );
  };

const successPhotoUpload = actionFactory("PRESENTATION_PHOTO_UPLOAD_SUCCESS");

export const addAdditionalPhoto = (form, id, city) =>
  dispatch =>
    axios({
      method: 'post',
      withCredentials: true,
      url: apiUrl,
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        c: 'panpartner:presentations.ajax',
        mode: 'class',
        action: 'addAdditionalPhoto',
        id,
        city,
      },
      data: form,
    })
      .then(handleResponse)
      .then(
        data => {
          dispatch(successPhotoUpload({additionalPhotos: data.files}));
          return Promise.resolve(data);
        },
        error => {
          dispatch(failurePresentation());
          return Promise.reject(error);
        }
      );
const successPhotoRemove = actionFactory("PRESENTATION_PHOTO_REMOVE_SUCCESS");

export const removeAdditionalPhoto = (id, photoId, city) =>
  dispatch => request(comp, "removeAdditionalPhoto", { id, photoId, city })
    .then(
      data => {
        dispatch(successPhotoRemove({id: photoId}));
        return Promise.resolve(data);
      },
      error => {
        dispatch(failurePresentation());
        return Promise.reject(error);
      }
    );

export const showPresentation = (clientId, id) => request(comp, "showPresentation", { clientId, id, })

export const getClients = (name, city) =>
  dispatch =>
    request("panpartner:saved.filter.ajax", "getClients", { name, city, })
      .then(
        data => {
          dispatch(successClients({ clients: handleClients(data) }));
          return Promise.resolve(data);
        }
      );

const handleClients = (data) => {
  let clients = data.clients.map((client) => ({
    id: client.id,
    title: `${client.name} ${client.lastName}`,
    phone: client.phone[0],
    email: client.email[0],
  }));

  return clients;
};

export const savePresentationSettings = form => request(comp, "savePresentationSettings", form)
