import { useCallback, useEffect, useRef, useState } from "react";

const useDropdown = () => {
  const node = useRef(null);

  const [open, setOpen] = useState(false);

  const show = useCallback(() => setOpen(true), []);
  const hide = useCallback(() => setOpen(false), []);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    hide();
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return [node, open, show, hide];
};

export default useDropdown;