import TinySlider from "tiny-slider-react";
import React, {useState} from "react";
import {PromoIcon} from "../Public/markup/icons";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import PromoCardModal from "../Public/markup/detail/PromoCardModal";

const ActionSlider = ({actionInfo, managerInfo, scroll}) => {
  const settings = {
    items: actionInfo.moreActions.length === 1 ? 1 : 2,
    slideBy: 'page',
    autoplay: false,
    controls: true,
    nav: false,
    mouseDrag: true,
    gutter: 20,
    loop: false,
    responsive: {
      640: {
        items: actionInfo.moreActions.length === 1 ? 1 : 2
      },
      900: {
        items: actionInfo.moreActions.length === 1 ? 1 : 2
      }
    }
  };

  const [actId, setActId] = useState(null)
  const [openModal, closeModal] = useModal(
    () => (
      <PanModal title={'Акция'} closeModal={closeModal} isSmall>
        <PromoCardModal id={actId} managerInfo={managerInfo} />
      </PanModal>
    ),
    [actId],
  );

  return (
    <div className="actions-slider">

      <h3 className="h3-l sf__h3 actions-slider__top-title">Акции<span  onClick={() => scroll()}>Смотреть все</span></h3>
      <TinySlider settings={settings}>
        {actionInfo.moreActions.map(action => (
          <div key={action.id} className="actions-slider__slide" onClick={() => {
            openModal()
            setActId(action.id)
          }}>
            <div className="actions-slider__slide-inside" >

              <div style={{display: 'flex'}}>
                <span className="actions-slider__icon"><PromoIcon /></span>
                <div>
                  <p className="h4-l sf__promo-title">
                    {action.title}
                  </p>
                  <p className="sf__promo-timing">
                    <span className="sf__promo-key text">Акция действует:</span>
                    <p className="sf__promo-value h6">{action.activeTo}</p>
                  </p>
                </div>
              </div>


            </div>

          </div>
        ))}
      </TinySlider>
    </div>
  );
};

export default ActionSlider


