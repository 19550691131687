import { memo } from 'react';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SearchFilter from '../../FilterRealty/SearchFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';

const ParkingFilterForm = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude
  } = props;
  const createSelectProps = getSelectPropsCreator(props);
  return (
    <div className="sf__filter form">
      <SearchFilter
        placeholder="Номер машиноместа"
        handleChange={v => handleFilter('number', v)}
        value={filter.number ?? ""}
        wrapperClass="sf__filter-item"
      />
      <SelectFilter
        {...createSelectProps("parkingType")}
        data={{ title: "Тип паркинга", identity: "parkingType" }}
        wrapperClass="sf__filter-item"
      />
      <PriceFilter
        autoPosition
        filter={filter.prices ?? { min: "", max: "" }}
        items={filterData.prices?.items ?? []}
        wrapperClass="sf__filter-item"
        data={{
          title: "Цена от-до",
          defaultValues: filterData.prices?.range ?? 0,
        }}
        onChange={v => setFilterAndExclude(v, "prices")}
        debounced
      />
      <FloorsFilter
        autoPosition
        items={filterData.floors?.items ?? []}
        filter={filter.floors ?? {}}
        data={{
          title: 'Этаж',
          minPlaceholder: 'от ',
          maxPlaceholder: 'до ',
          defaultValues: filterData.floors?.range ?? null,
        }}
        onChange={({ filter }) => handleFilter('floors', filter)}
        wrapperClass="sf__filter-item"
      />
      {!!filterData?.features?.length && <SelectFilter
        {...createSelectProps("features")}
        data={{ title: "Особенности", identity: "features" }}
        wrapperClass="sf__filter-item"
      />}
    </div>
  );
});

export default ParkingFilterForm;