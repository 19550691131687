import React from "react";
import FavoriteFilter from "./FavoritesFilter";
import FavoritesList from "./FavoritesList";


const FavoritesTab = (props) => {
  const {
    blocks,
    apartments,
    currentTab,
    filterData,
    filter,
    setFavoritesFilter,
    isAgent,
    clientMode,
    clearFilter,
    villages,
  } = props;
  const getList = () => {
    switch (currentTab) {
      case "block":
        return blocks.concat(villages)
      case "apartment":
        return apartments
    }
  }
  return (
    <div className="favorites__tab active">
      {filterData.city?.length > 0 && <FavoriteFilter
        filterData={filterData}
        filter={filter}
        setFavoritesFilter={setFavoritesFilter}
        currentTab={currentTab}
        city={props.city}
        clearFilter={() => clearFilter(props.city)}
        access={props.access}
      />}
      <FavoritesList
        currentTab={currentTab}
        list={getList()}
        city={props.city}
        isAgent={isAgent}
        clientMode={clientMode}
      />
    </div>
  );
};

export default FavoritesTab;
