import React from "react";
import { ReactComponent as House } from "../../images/svg/house-gray.svg";
import { ReactComponent as Sot } from "../../images/svg/sot.svg";
import { formatMoney, getSuffix, makeComa } from "../../utils";

export const getApartmentsFields = data => {
    if (!data) return false;
    return {
        link: `/realty/${data.cityCode}/apartment/${data.id}`,
        imageUrl: data.planSmall?.src,
        title: `${data.roomType} | ${data.squareTotal} м² | ${formatMoney(data.costWithDiscount)} р.`,
        subtitle: `ЖК «${data.block?.name}»`,
        address: data.block?.address,
        ending: data.ending,
        company: `Застройщик: ${data.builder?.name}`,
        countBuildings: data.countBuildings? `${data.countBuildings} корпус${getSuffix(data.countBuildings)}`: "",
        features: [
            {
                name: "Этаж",
                value: data.floor
            },
            {
                name: "Отделка",
                value: data.decoration
            },
            {
                name: "Корпус",
                value: data.corpus
            },
            {
                name: "Тип",
                value: "Новостройка"
            },
            {
                name: "№ кв.",
                value: data.number
            },
            {
                name: "Ключи",
                value: data.issuingKeys
            },

        ],
        subway: data.subway,
        id: data.id,
        type: "apartment",
        city: data.cityCode,
        favorite: data.favorite
    }
}
export const getAssignmentsFields = data => {
    if (!data) return false;
    return {
        link: `/assignments/${data.id}`,
        imageUrl: data.planSmall?.src,
        title: `${data.roomType} | ${data.squareTotal} м² | ${formatMoney(data.baseCost)} р.`,
        subtitle: `ЖК «${data.block?.name}»`,
        address: data.block?.address,
        ending: data.ending,
        company: `Застройщик: ${data.builder?.name}`,
        countBuildings: data.countBuildings? `${data.countBuildings} корпус${getSuffix(data.countBuildings)}`: "",
        features: [
            {
                name: "Этаж",
                value: data.floor
            },
            {
                name: "Отделка",
                value: data.decoration
            },
            {
                name: "Корпус",
                value: data.corpus
            },
            {
                name: "Тип",
                value: "Переуступка"
            },
            {
                name: "№ кв.",
                value: data.number
            },
            {
                name: "Ключи",
                value: data.issuingKeys
            },

        ],
        subway: data.subway,
        id: data.id,
        type: "assignment",
        city: data.cityCode,
        favorite: data.favorite
    }
}
export const getSecondaryFields = data => {
    if (!data) return false;
    return {
        link: `/secondary/${data.id}`,
        imageUrl: data.planSmall?.src,
        title: `Вторичка | ${data.roomType?.name} | ${formatMoney(data.baseCost)} р.`,
        subtitle: data.block?.name ? `ЖК «${data.block?.name}»` : "",
        address: data.address,
        ending: null,
        company: data.builder?.name? `Застройщик: ${data.builder?.name}` : "",
        countBuildings: data.countBuildings? `${data.countBuildings} корпус${getSuffix(data.countBuildings)}`: "",
        features: [
            {
                name: "Площадь",
                value: data.squareTotal ? `${data.squareTotal} м²` : null
            },
            {
                name: "Этаж",
                value: `${data.floor}${data.floors ? `/${data.floors}` : ""}`
            },
            {
                name: "Год постройки",
                value: data.endingYear
            },
            {
                name: "Санузел",
                value: data.bathroomType?.name
            },
            
            {
                name: "Балкон",
                value: data.balconyType?.name
            },
        ],
        subway: data.subway,
        id: data.id,
        type: "secondary",
        city: "spb",
        favorite: data.favorite,
        communications: data.viewFromWindow?.length ? data.viewFromWindow.map?.(makeComa) : null,
        communicationsTitle: "Вид из окон"
    }
}
export const getSuburbanFields = data => {
    if (!data) return false;
    return {
        link: `/lands/spb/${data.id}`,
        imageUrl: data.plan?.src,
        title: [
            `${formatMoney(data.price)} р.`,
            ...(data.squareTotal ? [<div className="pan-card__square">
                <House className="house-icon" />
                {data.squareTotal} м²
            </div>] : []),
            ...(data.squareParcel ? [<div className="pan-card__square-parcel">
                <Sot className="sot-icon" />
                {data.squareParcel} сот.
            </div>] : [])

        ],
        subtitle: data.village?.name? `Посёлок: ${data.village?.name}`: "",
        address: data.address,
        ending: data.ending,
        company: data.builder?.name ? `Застройщик: ${data.builder?.name}` : null,
        countBuildings: null,
        features: [
            {
                name: "Тип",
                value: data.name
            },
            {
                name: "Этажей",
                value: data.floors
            },
            {
                name: "Отделка",
                value: data.decoration
            },
            {
                name: "Корпус",
                value: data.section
            },

            {
                name: "№ участка",
                value: data.number
            },
            {
                name: "Спален",
                value: data.countBedrooms
            },

        ],
        subway: data.subway,
        id: data.id,
        type: "suburban",
        city: "spb",
        favorite: data.favorite,
        communications: data.communications?.length ? data.communications.map(makeComa) : null,
        communicationsTitle: "Коммуникации"
    }
}

export const getCommercialFields = data => {
    if (!data) return false;
    return {
        link: `/commercial/${data.id}`,
        imageUrl: data.planSmall?.src,
        title: `Коммерция | ${data.squareTotal} м² | ${formatMoney(data.costWithDiscount)} р.`,
        subtitle: data.block?.name? `ЖК «${data.block?.name}»`: "",
        address: data.block?.address,
        ending: data.ending,
        company: data.builder?.name ? `Застройщик: ${data.builder?.name}`: "",
        countBuildings: data.countBuildings? `${data.countBuildings} корпус${getSuffix(data.countBuildings)}`: "",
        features: [
            {
                name: "Площадь",
                value: data.squareTotal ? `${data.squareTotal} м2` : null
            },
            {
                name: "Этаж",
                value: data.floor
            },
            {
                name: "Отделка",
                value: data.decoration
            },
            {
                name: "Корпус",
                value: data.section
            },

            {
                name: "№ пом.",
                value: data.number
            },
            {
                name: "Выд. мощность",
                value: data.power ? `${data.power} кВт` : null
            },
        ],
        subway: data.subway,
        id: data.id,
        type: "commercial",
        city: "spb",
        favorite: data.favorite
    }
}
export const getParkingFields = data => {
    if (!data) return false;
    return {
        link: `/realty/${data.cityCode}/apartment/${data.id}`,
        imageUrl: data.planSmall?.src,
        title: `${data.roomType} | ${data.squareTotal} м² | ${formatMoney(data.costWithDiscount)} р.`,
        subtitle: `ЖК «${data.block?.name}»`,
        address: data.block?.address,
        ending: data.ending,
        company: `Застройщик: ${data.builder?.name}`,
        countBuildings: data.countBuildings? `${data.countBuildings} корпус${getSuffix(data.countBuildings)}`: "",
        features: [
            {
                name: "Этаж",
                value: data.floor
            },
            {
                name: "Отделка",
                value: data.decoration
            },
            {
                name: "Корпус",
                value: data.corpus
            },
            {
                name: "Тип",
                value: "Новостройка"
            },
            {
                name: "№ кв.",
                value: data.number
            },
            {
                name: "Ключи",
                value: data.issuingKeys
            },

        ],
        subway: data.subway,
        id: data.id,
        type: "apartment",
        city: data.cityCode,
        favorite: data.favorite
    }
}

export const propsCreatorByType = data => {
    switch (data.type) {
        case "assignment": return getAssignmentsFields(data);
        case "apartment": return getApartmentsFields(data);
        case "secondary": return getSecondaryFields(data);
        case "commercial": return getCommercialFields(data);
        case "suburban": return getSuburbanFields(data);
        case "parking": return getParkingFields(data);
    }
}
