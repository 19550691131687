import React from 'react';


const Note = ({ title, text, isActive }) => {

  return (
    <div  className={`presentation__info pdf-block presentation__info_flat ${!text && isActive ? ' mb-50' : ''}`}>
      <div className={`presentation__box ${!text ? ' hidden' : ''}`} data-id={!text ? "none" : "note"}>
        <div className="presentation__title">{title}</div>
        <div className="presentation__info-description">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Note;