import React from "react";
import './index.scss'

const RecentlyCard = ({img, price, description, builder, link}) => {
  return (
    <a href={link} target="_blank" className="recently-card">
      <img className="recently-card__image" src={img} alt=""/>
      <p className="recently-card__price">{price}</p>
      <p className="recently-card__type">{description}</p>
      <p className="recently-card__builder">{builder}</p>
    </a>
  )
}

export default RecentlyCard

