export const filterByKey = {
  rooms: {
    ст: 87,
    cn: 87,
    студия: 87,
    cnelbz: 87,
    1: 86,
    2: 84,
    3: 85,
    4: 88,
    5: 93,
    7: 96,
    8: 98,
    '2е': 89,
    '3е': 91,
    '4е': 92,
    '5е': 95,
    '2e': 89,
    '3e': 91,
    '4e': 92,
    '5e': 95,
  },
  payment: {
    'военная ипотека': 4,
    военная: 4,
    ипотека: 3,
    'мат капитал': 6,
    рассрочка: 2,
    'сельская ипотека': 7,
    субсидии: 5,
  },
  blockClass: {
    бизнес: 28,
    комфорт: 29,
    эконом: 30,
    элит: 31,
    ',bpytc': 28,
    rjvajhn: 29,
    rjyjv: 30,
    "'kbn": 31,
  },
  decoration: {
    'без отделки': 1,
    подчистовая: 2,
    чистовая: 3,
    ',tp jnltkrb': 1,
    gjlxbcnjdfz: 2,
    xbcnjdfz: 3,
  },
  registrations_spb: {
    город: 1,
    область: 2,
    ujhjl: 1,
  },
  registrations_msk: {
    город: 3,
    ujhjl: 3,
  },
  availability: {
    пешком: 43,
    транспорт: 44,
    gtirjv: 43,
    nhfycgjhn: 44,
  },
  isApartments: {
    'апарт да': 'Y',
    'апарт нет': 'N',
    'fgfhn lf': 'Y',
    'fgfhn ytn': 'N',
  },
  isOnlyHanded: 'Y',
};
