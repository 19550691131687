import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { getInviter } from '../../actions/profile';
import './index.scss';
const Invite = () => {
  const { inviteCode } = useParams();
  const [inviter, setInviter] = useState({})
  useEffect(() => {
    if (inviteCode) {
      localStorage.setItem("inviteCode", inviteCode)
      getInviter(inviteCode).then(data => setInviter(data.inviter))
    }
  }, [inviteCode])

  return (
    <main className="invite">
      <div className="wrapper">
        <section className="section invite__section">
          <div className="invite__block">
            <img
              className="invite__logo"
              src={require('../../assets/images/invite/logo.svg').default}
              alt=""
            />
            <h1 className="invite__title h1">{`${inviter.name} ${inviter.lastName}`} приглашает Вас стать партнером ПАН ПАРТНЕР</h1>
            <p className="invite__highlight is-red-text h3">Получайте 100% комиссионных</p>
            <p className="invite__text big-text">со сделок по новостройкам<br />в Санкт-Петербурге и Москве.</p>

            <p className="invite__bottom-text big-text">А еще вы получите бутылку шампанского за первую сделку.</p>

            <Link to={`/register`} className="invite__btn button button_view_default">Стать партнером</Link>

          </div>

          <img
            className="invite__img"
            src={require('../../assets/images/invite/invite-friend.svg').default}
            alt=""
          />
        </section>


      </div>
    </main>
  );
};

export default Invite;
