import request from '../api';
import { actionFactory } from './actionUtils';

const requestEstate = actionFactory("ESTATE_REQUEST");

const requestEstateFilter = actionFactory("ESTATE_FILTER_REQUEST");

const successEstate = actionFactory("ESTATE_SUCCESS");

const successEstateFilter = actionFactory("ESTATE_FILTER_SUCCESS");

const failureEstate = actionFactory("ESTATE_FAILURE");

const failureEstateFilter = actionFactory("ESTATE_FILTER_FAILURE");

export const setFilterEstate = filter => ({
  type: 'ESTATE_SET_FILTER',
  filter,
});

export const setView = view => ({
  type: 'ESTATE_SET_VIEW',
  view,
});

export const setPage = page => ({
  type: 'ESTATE_SET_PAGE',
  page,
});

export const setPageCount = (pageCount) => ({
  type: 'ESTATE_SET_PAGE_COUNT',
  pageCount,
});

export const setSort = sort => ({
  type: "SET_SORT",
  sort
})
export const clearFilterEstate = actionFactory("ESTATE_CLEAR_FILTER")

export const clearIncludesEstate = actionFactory("ESTATE_CLEAR_INCLUDES");

export const clearExcludesEstate = actionFactory("ESTATE_CLEAR_EXCLUDES");

export const fetchEstateFilter = (city, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestEstateFilter());
    return request("panpartner:block.filter.ajax", "getFilter", { city, savedFilterXmlId })
      .then(
        data => {
          dispatch(successEstateFilter({filterData: data?.filter || data}));
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureEstateFilter());
          return Promise.reject(error);
        }
      );
  };

const setCountBlocks = actionFactory("ESTATE_SET_COUNT_BLOCKS");

export const getCountBlocks = (city, params, savedFilterXmlId, noStatistic) =>
  dispatch =>
    request("panpartner:block.filter.ajax", "getCount", {
      city,
      savedFilterXmlId,
      noStatistic,
      ...params,
    })
      .then(data => dispatch(setCountBlocks(data)))


export const fetchEstateItems = (
  city,
  form,
  limit,
  page,
  isGetFilter = 1,
  sort = {},
  savedFilterXmlId,
  noStatistic,
  isFirstRequest
) =>
  dispatch => {
    dispatch(requestEstate());
    return request("panpartner:block.filter.ajax", "getBlocks", {
      city,
      isGetFilter,
      limit,
      page,
      ...sort,
      savedFilterXmlId,
      noStatistic,
      ...form,
    })
      .then(
        data => {
          data.isFirstRequest = isFirstRequest
          dispatch(successEstate(data));
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureEstate());
          return Promise.reject(error);
        }
      );
  };

export const getEstatePage = (
  city,
  form,
  limit,
  page,
  isGetFilter,
  sort,
  savedFilterXmlId,
  noStatistic
) =>
  dispatch =>
    dispatch(
      fetchEstateItems(
        city,
        form,
        limit,
        page,
        isGetFilter,
        sort,
        savedFilterXmlId,
        noStatistic,
        true
      ),
    );

const requestEstateMap = actionFactory("ESTATE_REQUEST_MAP");

const successEstateMap = actionFactory("ESTATE_SUCCESS_MAP"); 

export const fetchEstateMap = (city, form, sort={}, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestEstateMap());
    return request("panpartner:block.filter.ajax", "getBlocks", {
      city,
      isGetFilter: 0,
      sort,
      savedFilterXmlId,
      ...form,
    })
      .then(
        (data) => {
          dispatch(successEstateMap(data));
          return Promise.resolve(data);
        },
        (error) => {
          dispatch(failureEstate());
          return Promise.reject(error);
        },
      );
  };