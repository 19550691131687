export const getUniversalReducer = initialState => (state, { type, payload }) => {
    const { key, value } = payload
    switch (type) {
      case 'set':
        return { ...state, [key]: value };
      case 'apply':
        return { ...state, ...payload };
      case 'reset':
        return initialState;
      default:
        throw new Error();
    }
  };