import { memo } from 'react';

const Manager = memo(({ phone, logo, email, name, workCompany }) => (
  <section className="sf__manager sf__block">
    <header className="sf__manager-header">
      {!!logo?.value && (
        <div className="sf__manager-pic">
          <img src={logo.value} alt="" />
        </div>
      )}
      <div className="sf__manager-info">
        {!!name?.value && <p className="sf__manager-name h6">{name.value}</p>}
        {!!workCompany?.value && (
          <p className="sf__manager-pos">АН «{workCompany.value}»</p>
        )}
      </div>
    </header>
    <div className="sf__manager-contacts">
      {!!phone?.value && (
        <div className="sf__manager-col">
          <p className="text sf__manager-key">{phone.title}</p>
          <a href={phone.link} className="link link_color_black">
            {phone.value}
          </a>
        </div>
      )}
      {!!email?.value && (
        <div className="sf__manager-col">
          <p className="text sf__manager-key">E-mail</p>
          <a href={email.link} className="link link_color_black">
            {email.value}
          </a>
        </div>
      )}
    </div>
  </section>
));

export default Manager;
