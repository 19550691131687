import request from '../../api';
const initialFilter = Object.freeze({
    agent: [],
    title: [],
    dates: {
        startDate: null,
        endDate: null,
    },
    status: [],
    manager: [],
})
export const initialState = {
    data: [],
    filter: initialFilter,
    filterData: null,
    isLoading: true,
    page: 0,
    countAllDeals: 0
};

export const reducer = (state, action) => {
    const { type, deals, filters, value, key, countAllDeals } = action
    switch (type) {
        case 'fetchData':
            return {
                filter: initialFilter,
                data: deals,
                filterData: filters,
                isLoading: false,
                page: state.page + 1,
                countAllDeals
            };
        case 'setFilter':

            return {
                ...state,
                filter: { ...state.filter, ...action.filter }
            };
        case 'clearFilter':
            return { ...state, filter: initialFilter }
        case "loadMore":
            return {
                ...state,
                data: [...state.data, ...deals],
                page: state.page + 1,
                countAllDeals
            }
        case "setState":
            return { ...state, ...action.payload }
        default:
            throw new Error("Нет типа в экшене");
    }
}

export const getDeals = form => request("panpartner:requests.ajax", "getDeals", form)


// взял тут, чтобы не париться https://stackoverflow.com/questions/1486476/json-stringify-changes-time-of-date-because-of-utc
const convertToLocalDate = dateToFormat => {
    const date = new Date(dateToFormat);
    const hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
    let minutesDiff = (date.getHours() - date.getTimezoneOffset()) % 60;
    date.setHours(hoursDiff);
    date.setMinutes(minutesDiff);
    return date
}

export const filterToParamDeals = (defaultParams = {}, filter) => {
    const params = {
        ...defaultParams
    }
    if (!filter) return params
    Object.entries(filter).forEach(([key, value]) => {
        if (key === "dates") {
            if (value.startDate) {
                params["filter[dateCreate][min]"] = convertToLocalDate(value.startDate)
            }
            if (value.endDate) {
                params["filter[dateCreate][max]"] = convertToLocalDate(value.endDate)
            }
            return
        }
        params[`filter[${key}]`] = value
    })
    return params
}