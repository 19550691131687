const initialState = {
  isFetching: false,
  error: false,
  data: [],
  filterData: {
    agent: [],
    status: [],
    client: [],
    dates: {
      min: null,
      max: null,
    },
  },
  filter: {
    search: "",
    agent: [],
    client: [],
    dates: {
      startDate: null,
      endDate: null,
    },
    status: []
  },
};

const requests = (state = initialState, action) => {
  switch (action.type) {
    case "REQUESTS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "REQUESTS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        filterData: action.filterData,
      };
    case "REQUESTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "REQUESTS_RESET":
      return { ...initialState };
    case "REQUESTS_SET_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "REQUESTS_REMOVE":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
      };
    case "REQUESTS_CLEAR_FILTER":
      return { ...state, filter: initialState.filter };
    default:
      return state;
  }
};

export default requests;
