import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useParams } from "react-router";
import { getFormSettings, sendForm } from '../../../actions/constructor';
import { sendConstructorEvent, sendVKEvent } from "../../../utils";
import { getFormWithUTM, getSubdomain } from '../../../utils/location';
import { infoToast, standartErrorToast, successToast } from "../../toasts";
import request from "../../../api";

const Consultation = (props) => {

  const { darkTheme, isPreview} = props;
  const subDomain = getSubdomain()
  const { handleSubmit, register, errors } = useForm();
  const [callbackForm, setCallbackForm] = useState({})
  const [formSettings, setFormSettings] = useState([])
  const { id } = useParams()
  const getFieldName = (code) => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const setForm = (value, code, arrayValue) => {
    const tmp = {
      ...callbackForm
    }

    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      const ident = getFieldName(code)
      tmp[ident] = value
    }
    setCallbackForm(prev => ({ ...prev, ...tmp }))
  }

  const submit = (successCallback = () => { }) => {
    let formData = getFormWithUTM();
    let keys = Object.keys(callbackForm);
    if (isPreview) {
      infoToast(<div>В режиме предварительного просмотра отправка форм невозможна</div>)
      return
    }
    keys.forEach((key) => {
      if (Array.isArray(callbackForm[key])) {
        callbackForm[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, callbackForm[key]);
      }
    });
    if(id){
      const buildingField = getFieldName("building_name")
      formData.append(buildingField, id)
    }
    formData.append("subDomain", subDomain)
    formData.append("title", "Получить консультацию")
    formData.append("url", window.location.href)
    if (captchaImageUrl && captchaSid) {
      formData.append("captchaSid", captchaSid)
    }
    formData.append("captchaWord", captchaWord)
    sendForm(34, formData).then(
      () => {
        successToast(<div>Спасибо! Форма успешно отправлена</div>)
        sendConstructorEvent(id ? "tr_buiding_form_send" : "tr_main_form_send", id ? { complexId: id } : null, true);
        sendVKEvent('conversion');
      },

      standartErrorToast
    );
  }

  const refreshCaptcha = () => {
    return request('panpartner:forms.ajax', 'refreshCaptcha')
      .then(
        data => {
          setCaptchaSid(data?.captchaSid)
          setCaptchaImageUrl(data?.captchaImageUrl)
          return Promise.resolve(data);
        },
      );
  }

  const [captchaImageUrl, setCaptchaImageUrl] = useState('')
  const [captchaSid, setCaptchaSid] = useState('')
  const [captchaWord, setCaptchaWord] = useState('')

  useEffect(() => {
    getFormSettings(34, "spb", subDomain)
      .then(
        resp => {
          setFormSettings(resp.questions);
          setCaptchaImageUrl(resp.captchaImageUrl);
          setCaptchaSid(resp.captchaSid)
        },
        standartErrorToast
      )
  }, []);

  // useEffect(() => {
  //   if (captchaImageUrl && captchaSid) {
  //     console.log(captchaImageUrl);
  //     console.log(captchaSid);
  //   }
  //   console.log(callbackForm)
  // }, [captchaImageUrl, captchaSid, callbackForm]);

  useEffect(() => {
    if (!formSettings) return
    const defaultPolicyValue = formSettings.find(setting => setting.code === "policy")?.items?.[0]?.value
    setForm([defaultPolicyValue], "policy")
  }, [formSettings])




  return (
    <section className={`consultation consultation_single constructor__section${darkTheme ? ` consultation_dark constructor__section_gray` : ``}`}>
      <div className="constructor__container">

        <div className="constructor__wrapper consultation__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title advantages__title">
              Получить консультацию
            </h2>
          </div>
          <form className="consultation__form form">
            <div className="form__row">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-partnership-firstName"
              >

                <input
                  id="modal-partnership-firstName"
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите имя",
                  })}
                  placeholder="Ваше имя"
                  value={callbackForm[getFieldName("name")]}
                  onChange={e => setForm(e.target.value, "name")}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="form__row">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-partnership-name"
              >

                <InputMask
                  id="modal-partnership-name"
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  name="phone"
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                  placeholder="Ваш телефон"
                  value={callbackForm[getFieldName("phone")]}
                  onChange={e => setForm(e.target.value, "phone")}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>

            <div className="form__row">
              <div style={{width: '100%', marginBottom: 23, display: 'flex', gap: 15, alignItems: 'center', justifyContent: 'center'}}>
                <img src={captchaImageUrl} alt=""/>
                <div style={{cursor: 'pointer'}} onClick={refreshCaptcha}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0026 13.6693L6.72845 16.9434L10.0026 20.2175" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.9048 10.5586L17.1789 7.28443L13.9048 4" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.0978 7.28442H6.51223C5.84594 7.28442 5.20695 7.5491 4.73581 8.02024C4.26468 8.49137 4 9.13037 4 9.79665V15.9743" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.89191 16.9434H17.4878C17.8172 16.946 18.1434 16.8794 18.4453 16.7477C19.0593 16.4909 19.5475 16.0027 19.8044 15.3887C19.9314 15.0853 19.9979 14.76 20 14.4311V8.25352" stroke="#2C2E3E" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <input
                id="modal-partnership-captchaWord"
                className={
                  "input__control"
                }
                placeholder="Введите код с картинки"
                value={captchaWord}
                onChange={e => setCaptchaWord(e.target.value)}
              />



            </div>

            {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
              return <div className="form__row" key={`consultation-item-${i}`}>
                <div className="checkbox checkbox_view_invert">
                  <div
                    className={
                      "checkbox__box" +
                      (errors.agreement ? " checkbox__box-error" : "")
                    }
                  >
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="modal-partnership-agreement"
                      name="agreement"
                      ref={register({
                        required: true,
                      })}
                      value={item.value}
                      onChange={(e) => setForm(e.target.checked, item?.fieldName, e.target.value)}
                      checked={!!callbackForm?.[item?.fieldName]?.includes(item.value)}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="modal-partnership-agreement"
                    ></label>
                    <div className="checkbox__marker"></div>
                  </div>
                  <label
                    className="checkbox__label"
                    htmlFor="modal-partnership-agreement"
                  >
                    Отправляя эту форму, Вы соглашаетесь с<br />
                    <a className="link" href="https://toprealtors.ru/policy" target="_blank">политикой конфиденциальности</a>
                  </label>
                </div>
              </div>
            })}


            <button className="button constructor__btn form__submit" type="button" onClick={()=>submit()}>
              Отправить
            </button>
          </form>
        </div>

      </div>
    </section>
  )
};

export default Consultation;
