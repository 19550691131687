import React, { memo, useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import request from "../../api";
import { formatMoney, media } from '../../utils';
import Modal from '../core/Modal';
import Table from "../core/Table";
import ObjectEditRequest from '../forms/ObjectEditRequest';
import { standartErrorToast, standartSuccessToast } from "../toasts";
import { ReactComponent as Actualize } from "./actualize.svg";
import { ReactComponent as Deactivate } from "./deactivate.svg";


const actualize = id => request("panpartner:secondaries.ajax", "actualizeObject", { id }).then(standartSuccessToast, standartErrorToast)

const deactivate = id => request("panpartner:secondaries.ajax", "deactivateObject", { id }).then(standartSuccessToast, standartErrorToast)

const ColumnStatus = memo(({ status, statusColor }) => {
  if (!statusColor) return "";
  return status && <div className={`label-item label_${statusColor}`}>{status}</div>
});

//хто это делал я не знаю)
const ColumnPay = (props) => {
  const { items } = props;

  if (!items) return "";

  const getText = () => {
    return items.map(({ name }) => name).join("<br />");
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: getText() }} />
    </>
  );
};

const ColumnManager = (props) => {
  const { manager } = props;

  if (!manager) return "";

  const { name, lastName, phone } = manager;

  return (
    <>
      {lastName} {name.charAt(0)}.<br />
      <a class="clients__phone" href={`tel:${phone}`}>
        {phone}
      </a>
    </>
  );
};

const ColumnAction = memo(({ id, objectType, needActualize, fetchItems }) => {

  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = e => !node.current?.contains(e.target) && setOpen(false);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  const showConfirm = () => {
    confirmModal();
    setOpen(false);
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Modal close={hideModal} classes="">
      <ObjectEditRequest close={hideModal} id={id} type={objectType} />
    </Modal>
  ));

  return (
    <div class="control_more">
      <div class="form__item">
        <div
          ref={node}
          className={`dropdown form__drop ${open ? "is-showing" : ""}`}
        >
          <button
            type="link"
            class="dropdown__btn button form__drop-btn button_type_round"
            onClick={(e) => setOpen(!open)}
          >
            <span class="form__drop-name"></span>
            <svg class="icon icon_elipsis">
              <use xlinkHref="#elipsis"></use>
            </svg>
          </button>
          <div class="dropdown__block">
            <div class="dropdown__inner">
              <div class="form__drop-content">
                <div class="dt__more">
                  <hr />
                  <div class="dt__more-control" onClick={() => confirmModal()}>
                    <svg class="icon icon_edit">
                      <use xlinkHref="#edit"></use>
                    </svg>
                    <span>Редактировать</span>
                  </div>
                  {objectType === "Вторичка" && <div class="dt__more-control" onClick={() => deactivate(id).then(fetchItems)}>
                    <div class="icon icon_edit">
                      <Deactivate />
                    </div>
                    <span>Снять с публикации</span>
                  </div>}
                  {needActualize && <div class="dt__more-control" onClick={() => actualize(id).then(fetchItems)}>
                    <div class="icon icon_edit">
                      <Actualize />
                    </div>
                    <span>Актуализировать</span>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const Items = memo(({ items, fetchItems }) => {

  const getMobileColumns = () => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Цена",
      dataIndex: "costWithDiscount",
      key: "costWithDiscount",
      render: (cost) => formatMoney(cost) + " р."
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (text, record) => (
        <ColumnAction
          {...record}
          fetchItems={fetchItems}

        />
      ),
    },
  ]

  const getColumns = () => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Тип",
      dataIndex: "objectType",
      key: "objectType",
      sorter: (a, b) => a.objectType - b.objectType,
    },
    {
      title: "Объект",
      key: "object",
      dataIndex: "object",
      render: (column, record) => {
        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: column }} />
            {record.id && (
              <Link
                class="orders__table-label"
                to={
                  record.objectType === "Вторичка" ? `/secondary/${record.id}` : `/assignments/${record.id}`
                  // : `/realty/${record.city}/apartment/${record.id}`
                }
              >
                Карточка объекта
              </Link>
            )}
          </>
        )
      },
    },

    {
      title: "",
      dataIndex: "views",
      key: "views",
      render: (column, record) => (<div><span className="lk-form__password-hidden">
        <svg className="icon icon_watch">
          <use xlinkHref="#icon_watch"></use>
        </svg>
      </span> {record.views}</div>)
    },
    {
      title: "Дата обновления",
      dataIndex: "dateUpdate",
      key: "dateUpdate",
      sorter: (a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1),
      render: (column) =>
        new Date(column).toLocaleDateString("ru", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }),
    },
    {
      title: "Цена",
      dataIndex: "costWithDiscount",
      key: "costWithDiscount",
      sorter: (a, b) => a.costWithDiscount - b.costWithDiscount,
      render: (cost) => formatMoney(cost) + " р."
    },
    {
      title: "Комиссия",
      dataIndex: "commission",
      key: "commission",
      sorter: (a, b) => a.commission - b.commission,
      render: (comission) => {
        return !!comission ? <ColumnStatus status={comission.toString().includes("договор") ? comission : formatMoney(comission) + " р."} statusColor={"blue"} /> : "—"
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.color > b.status.color,
      render: (status) => {
        return <ColumnStatus status={status.name} statusColor={status.color} />
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (text, record) => {
        if(record.status.id == "1001") return null
        return <ColumnAction
          {...record}
          fetchItems={fetchItems}
        />
      },
    },
  ];

  const expandedRow = (record) => {
    const updatedDate = (date) => (
      new Date(date).toLocaleDateString("ru", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }))

    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Тип:</span>
            {record.objectType}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Объект:</span>

            <Link
              class="orders__table-label"
              to={
                `/assignments/${record.id}`
                // : `/realty/${record.city}/apartment/${record.id}`
              }
            >
              Карточка объекта
            </Link>
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Просмотры:</span>
            {record.views}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Дата обновления:</span>
            {updatedDate(record.dateUpdate)}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Цена:</span>
            {record.costWithDiscount}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Комиссия:</span>
            <ColumnStatus status={formatMoney(record.comission) + " р."} statusColor={"blue"} />
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Статус:</span>
            <ColumnStatus status={record.status.name} statusColor={record.status.color} />
          </div>

        </div>
      </div>
    )
  }

  return (
    <div class="orders__content for-agents">
      <div className="flats__row-table is-active">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={media("isMobile") ? getMobileColumns() : getColumns()}
          expandable={media("isMobile") ? {
            expandedRowRender: record => expandedRow(record),
          } : false}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: "ничего не найдено" }}
          customPagination={true}
          rowClassName={(record, index) => {
            if (record.needActualize) return "ant-table-actualize-color"
            if ((index + 1) % 2 === 0) return "ant-table-second-color"
          }}
        />
      </div>
    </div>
  );
});

export default Items;
