export default () => {
  const boards = document.querySelectorAll('.js-board');

  const initBoard = (board) => {
    let isDown = false;
    let startX;
    let startY;
    let scrollLeft;
    let scrollTop;

    board.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - board.offsetLeft;
      startY = e.pageY - board.offsetTop;
      scrollLeft = board.scrollLeft;
      scrollTop = board.scrollTop;
    });

    board.addEventListener('mouseleave', () => {
      isDown = false;
    });

    board.addEventListener('mouseup', () => {
      isDown = false;
    });

    board.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const speed = 0.8;
      const x = e.pageX - board.offsetLeft;
      const y = e.pageY - board.offsetTop;
      const deltaX = (x - startX) * speed;
      const deltaY = (y - startY) * speed; 
      board.scrollLeft = scrollLeft - deltaX;
      board.scrollTop = scrollTop - deltaY;
    });
  }

  const initSections = (board) => {
    const boardParent = board.parentNode;
    const navItems = boardParent.querySelectorAll('.js-board-section');

    function handleNav() {
      const section = board.querySelector(`#${this.dataset.id}`);
      const activeItem = boardParent.querySelector('.js-board-section.active');
      activeItem.classList.remove('active');
      this.classList.add('active');

      board.scrollLeft = section.offsetLeft - board.offsetLeft - 60;
    }

    navItems.forEach(nav => nav.addEventListener('click', handleNav));
  }

  boards.forEach(board => {
    initBoard(board);
    initSections(board);
  })
}