import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AccessDenied } from '../components/App';
import AccessDeniedPlaceholder from '../pages/AccessDenied';
import ConstructorAdmin from '../pages/ConstructorAdmin';
import Home from '../pages/Home';
import HomePage from '../pages/HomePage';

const usePermittedPaths = (auth) => {
  const isAuthorized = useSelector((state) => state.user.isAuthorized);
  const config = {
    "toprealtors": {
      permittedPaths: ['/personal/clients', '/personal/profile', "/constructor-admin", "/add-partner", "/objects/add", "/objects", "/objects/edit"],
      MainPage: ConstructorAdmin,
      DeniedComponent: AccessDeniedPlaceholder,
    },
    default: {
      permittedPaths: [""],
      MainPage: isAuthorized ? Home : HomePage,
      accessDenied: false,
      DeniedComponent: AccessDenied,
    }
  }
  const access = useSelector((state) => state.user.access);
  const location = useLocation()
  const pathName = location.pathname
  const isPublic = !auth || pathName.includes("public")
  const currentConfig = config[access]
  if (!currentConfig) return config.default
  const accessDenied = !(currentConfig.permittedPaths?.some?.(permitted => pathName.includes(permitted)) || isPublic)
  return { ...currentConfig, accessDenied }

};

export default usePermittedPaths;