const initialState = {
    offers: [],
    error: false,
    fetching: false,
  };
  
  const profileOffers = (state = initialState, action) => {
    switch (action.type) {
      case "PROFILE_OFFERS_REQUEST":
        return { ...state, error: false, fetching: true };
      case "PROFILE_OFFERS_SUCCESS":
        return {
          error: false,
          fetching: false,
          offers: action.offers
        };
      case "PROFILE_OFFERS_FAILURE":
        return { ...state, error: true, fetching: false };
      default:
        return state;
    }
  };
  
  export default profileOffers;