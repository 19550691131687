import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import request from '../../../../api';
import Preloader from '../../../Preloader';
import { runScriptsFromHTML } from "../../../../utils";
import { getSubdomain } from '../../../../utils/location';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useModal } from 'react-modal-hook';
import CallbackModal from "../../CallbackModal/CallbackModal.js";

import Calculator from '../../../Calculator/Calculator.js';


const Mortgage = (props) => {
  const {setFullScreenLoading} = props;
  const subDomain = getSubdomain();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const agencyData = useSelector(state=>state.agency);
  const isPreview = window.location.search.includes("preview")

  const [openModal, closeModal] = useModal(() =>
  <CallbackModal closeCallbackModal={closeModal} title={"Консультация по ипотеке"} isPreview={isPreview} />,
 []);

  
  useEffect(() => {
    request("panpartner:agent.sites.ajax", "getReviews", { subDomain })
    .then(resp => {
      setData(resp?.site?.reviews || [])
      let agency = resp.site?.agency
      let agent = resp.site?.agent
      let socials = resp.site?.socials
      let theme = resp.site?.theme
      let yandexMetric = resp.site?.yandexMetric
      let isReviewsActive = resp.site?.blocks[7].active
      let widgetReviews = resp.site?.widgetReviews
      dispatch({
        type: "AGENCY_SET_STATE", payload: {
          agent,
          agency,
          socials,
          theme,
          yandexMetric,
          isReviewsActive,
          widgetReviews
        }
      })
    })
    .finally(() => {
      setIsLoading(false)
      setFullScreenLoading(false)
    })
  }, []);
  



  if (isLoading) return <Preloader loading={true} />
  
  return (
    <div className="constructor">
      <section className="constructor__section inner">
        <div className="constructor__container">
          <div className="constructor__box">

            <Breadcrumbs currentPage={`Ипотека`} />

            <div className="inner__main">
              <header className="inner__header">
                <h1 className="h1 inner__title">Ипотека</h1>
                <p className="big-text inner__text">Оформление ипотеки — ключевой вопрос, с которым сталкиваются большинство покупателей после выбора квартиры. Выбор банка и лучшей процентной ставки, подача документов и последующее сопровождение сделки — простые, но в то же время важные задачи, которые стоит доверить профессионалам!</p>
              </header>

              <section className="inner__section inner__section_no-border">
                <h2 className="h2 inner__h2">Помощь в оформлении ипотеки</h2>

                <p className="inner__p text">Факты говорят о том, что самостоятельно подать документы и получить одобрение ипотеки получается менее чем у 50% покупателей. Это связано с тем, что большинство людей допускают ошибки при сборе и подаче документов, не умеют выстраивать коммуникацию с банком или, получив отказ в одном банке, прекращают свои действия.
                <p className="inner__p text">Другая проблема заключается в том, что многие просто-напросто боятся подавать документы на ипотеку или не верят в то, что получат одобрение.</p>
                <p className="inner__p text">На фоне высокого спроса на недвижимость, все больше людей прибегают к помощи ипотечных специалистов, чтобы не только сэкономить время, но и гарантированно получить одобрение. По статистике, при подаче документов через профессионала, одобрение ипотеки происходит в 98% случаев!</p>
                <p className="inner__p text">Профессиональный подход, знание ипотечных программ и безукоризненная работа с документами позволяет получить одобрение даже в самых, по мнению покупателей, «сложных» случаях.</p></p>

                <div className="inner__big-pic inner__main-color-bg">
                  <img src={require('../../../../assets/images/constructor/ipoteka.png').default} alt="" />
                </div>
              </section>

              <section className="inner__section">
                <h2 className="h2 inner__h2">Порядок оформления ипотеки</h2>

                <p className="big-text">Оформление ипотеки происходит в несколько этапов</p>

                <ul className="inner__list">
                  <li className="inner__list-item">
                    <div>
                      <span className="inner__list-title h55">Сбор документов на ипотеку</span>
                      <p className="text">Чтобы подать заявку на ипотеку, предварительно нужно подготовить пакет необходимых документов. Ипотечный специалист обязательно даст пошаговый план оформления и подскажет, какие документы нужны для оформления ипотеки.</p>
                    </div>
                   </li>
                  <li className="inner__list-item">
                    <div>
                      <span className="inner__list-title h55">Заполнение анкеты</span>
                      <p className="text">Заявка на одобрение ипотеки подается через заполнение анкеты. В каждом банке есть собственный бланк с анкетой и обязательными полями. Большинство ошибок допускается именно на этапе заполнении анкеты при отсутствии контроля со стороны эксперта.</p>
                    </div>
                  </li>
                  <li className="inner__list-item">
                    <div>
                      <span className="inner__list-title h55">Получение положительного ответа от банка</span>
                      <p className="text">После получения ответа приходит СМС-оповещение или звонок от сотрудника банка. Поверьте, взять ипотеку в банке и получить положительный ответ гораздо проще, чем вам кажется!</p>
                    </div>
                  </li>
                  <li className="inner__list-item">
                    <div>
                      <span className="inner__list-title h55">Выезд на сделку в отделение банка</span>
                      <p className="text">Будь то оформление ипотеки в новостройке или на вторичном рынке, завершение данного этапа — это подписание документов в офисе банка. Очевидно что, согласование времени сделки, переносы и прочая коммуникация в разы быстрее выполняется профессионалами своего дела.</p>
                    </div>
                  </li>
                </ul>
              </section>

              <section className="inner__section inner__section_no-border">
                <h2 className="h2 inner__h2">Ипотечный калькулятор</h2>

                <p className="big-text">Получите качественную помощь при оформлении материнского капитала на ипотеку и получении налогового вычета по ипотеке. Не теряйте времени — доверьтесь профессионалам!</p>

                <p className="inner__calc-title text color-brand-3">Предварительно рассчитать платеж по ипотеке и размер первоначального взноса поможет ипотечный калькулятор.*</p>


                <Calculator
                  openCallbackModal={openModal}
                  price={4500000}
                  city={"spb"}
                  type="apartment"
                  id={1}
                  period={30}
                  prepay={500000}
                  percent={6.5}
                  btnClass={`constructor__btn`}
                />
              </section>

            </div>

          </div>
        </div>

        
      </section>
    </div>
  );
};

export default Mortgage;