import _ from "lodash";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { fixApartment } from "../../actions/forms";
import { media } from '../../utils';
import Dropdown from "../core/Dropdown";
import SubmitButton from "../SubmitButton";
import { standartErrorToast } from "../toasts";
import FormAddClient from "./FormAddClient";

const FixApartment = (props) => {
  const { clients, id, phone, email, name, handleSend, builderId, city } = props;

  const defaultValues = {
    id: id,
    name: name,
    clientId: clients[0]?.id,
    phone: phone,
    email: email,
    comment: "",
    payment: [],
    agree: true,
  };
  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [show, setShow] = useState(false);

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [fetching, setFetching] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const changeFile = (e) => {
    const tmpFiles = e.target.files;
    if (tmpFiles.length > 0) {
      let tFiles = [...files];
      let tNames = [...fileNames];
      [...tmpFiles].forEach((item) => {
        tFiles.push(item);
        tNames.push(item.name);
      });
      setFiles(tFiles);
      setFileNames(tNames);
    }
  };

  const handleAddClient = (id) => {
    setValue("clientId", id);
    setShow(false);
  };

  const onSubmit = (data) => {
    setFetching(true);

    let form = new FormData();
    let keys = _.keys(data);

    keys.forEach((item) => {
      let identity = item;
      if (Array.isArray(data[item])) {
        data[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        form.append(`${identity}`, data[item]);
      }
    });
    if (files.length) {
      files.map((file, index) => {
        form.append(`files[]`, file);
        form.append(`fileName[]`, fileNames[index]);
        return file;
      });
    }
    form.append(`builderId`, builderId);
    const getCity = () => {
      return window.location.href.includes("msk")? "msk" : "spb"
    }
    
    form.append("city", getCity())
    
    fixApartment(form).then(
      (data) => {
        setSuccess(true);
        handleSend();
      },
      (error) => {
        setFetching(false);
        standartErrorToast(error)
        setError(true);
      }
    );
  };
  const emptyClient = [
    {
      id: "-1",
      title: "Без клиента",
    },
  ];

  return (
    <>

      {!media('isMobile') && <h2 className="modal__title">Фиксация клиента</h2>}
      <form className="modal-form" name="reserve-apartment">
        <div className="modal-form__wrapper">
          <input type="hidden" name="id" ref={register} />
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-name"
              >
                <span className="input__label">Ваши имя и фамилия</span>
                <input
                  id="modal-reservation-name"
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите фамилию",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-phone"
              >
                <span className="input__label">Ваш телефон</span>
                <InputMask
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autocomplete="off"
                  maskChar=" "
                  name="phone"
                  defaultValue={phone}
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="select select_type_form">
                <label
                  className="custom-select-label"
                  htmlFor="modal-reservation-client"
                >
                  Клиент
                </label>
                <Controller
                  as={Dropdown}
                  name="clientId"
                  options={clients}
                  onChange={([value]) => value}
                  inputRef={register}
                  control={control}
                  noSetCurrent={true}
                />
              </div>
              <div
                className="add-client js-show active"
                style={{ display: !show ? "flex" : "none" }}
                onClick={() => setShow(true)}
              >
                <button
                  className="button button_type_add"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                ></button>
                <span>Добавить клиента</span>
              </div>
            </div>
          </div>

          <FormAddClient
            show={show}
            onAddClient={(id) => handleAddClient(id)}
          />

          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form"
                htmlFor="modal-reservation-commentary"
              >
                <span className="textarea__label">Комментарий</span>
                <textarea
                  className="textarea__control"
                  id="modal-reservation-commentary"
                  ref={register}
                  name="comment"
                ></textarea>
              </label>
            </div>
          </div>
          <div className="modal-form__row">
              <div className="modal-form__item">
                <label
                  className="input input_type_upload-btn"
                  htmlFor="modal-reservation-file-input"
                >
                  <input
                    class="input__control"
                    type="file"
                    multiple
                    id="modal-reservation-file-input"
                    name="file"
                    onChange={changeFile}
                  />
                  Прикрепите файлы
                </label>
                <div
                  className="modal-form__files js-upload-container"
                  data-id="modal-reservation-file-input"
                >
                  {fileNames.map((file, index) => (
                    <div data-num="0" class="file-up" key={index}>
                      <div class="file-up__inner">
                        <svg class="icon file-up__icon">
                          <use xlinkHref="#deals-01"></use>
                        </svg>
                        <span class="file-up__name">{file}</span>
                        <div class="file-up__controls">
                          <button class="button button_view_round-control file-up__del">
                            <svg class="icon">
                              <use xlinkHref="#x"></use>
                            </svg>
                          </button>
                          <button class="button button_view_round-control file-up__edit">
                            <svg class="icon">
                              <use xlinkHref="#edit"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    "checkbox__box" +
                    (errors.agree ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        "Необходимо согласиться с правилами обработки данных",
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
        <SubmitButton
              className="button button_view_default"
              isLoading={fetching}
              onClick={handleSubmit(onSubmit)}
            >
              <span>Отправить заявку</span>
            </SubmitButton>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  email: state.user.info.email,
  phone: state.user.info.phone,
  clients: state.user.clients.map((client) => ({
    id: client.id,
    title: `${client.name} ${client.lastName}`,
  })),
  name: `${state.user.info.name} ${state.user.info.lastName}`,
  block: state.apartment.block,
  city: state.user?.info?.city
});

export default connect(mapStateToProps, null)(FixApartment);
