import React, { memo, useLayoutEffect, useRef } from "react";
import './index.scss';

const Messages = memo(({ currentChat, setValues, children }) => {
  const chatWindowRef = useRef()

  useLayoutEffect(() => {
    if(!chatWindowRef.current) return
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [chatWindowRef])

  return <div 
  className="open-chat__overfield" 
  onClick={(e) => currentChat?.isSearchOpen && setValues({ currentChat: { ...currentChat, isSearchOpen: false } })}
  ref={chatWindowRef}
  >
    <div className="open-chat__field">
      {children}
    </div>
  </div>
})

export default Messages;