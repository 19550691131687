import "antd/es/table/style/index.css";
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import useMedia from '../../../hooks/useMedia';
import { formatDate, media } from '../../../utils';
import Checkbox from '../../core/Checkbox';
import Confirm from "../../core/Confirm";
import Dropdown from '../../core/Dropdown';
import MobileConfirm from "../../core/MobileConfirm";
import Popup from '../../core/Popup';
import Table from "../../core/Table";
import { errorToast, infoToast } from "../../toasts";
import Tasks from "./Tasks";



const ClientName = (props) => {
  const getTitle = () => {
    let title = `${props.lastName} ${props.name}`;
    if (props.patronymic) title += ` ${props.patronymic.charAt(0)}.`;
    return title;
  };

  return (
    <Link className="clients__table-link clients__table-client">
      {getTitle()}
      <svg className="icon icon_external-link">
        <use xlinkHref="#external_link"></use>
      </svg>
    </Link>
  );
};

const ClientAction = (props) => {
  const { deleteClient } = props;
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const showConfirm = () => {
    confirmModal();
    setOpen(false);
  };

  const showMobileConfirm = () => {
    confirmMobileModal();
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const confirm = () => {
    let person = `${props.lastName} ${props.name}`;
    deleteClient(props.id).then(
      () => {
        hideModal();
        infoToast(
          <div className="Toastify__text">
          Клиент <strong>{person}</strong> удален
        </div>
        )
      },
      error => errorToast( <div>{error?.[0]?.message}</div>)
    );
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить клиента ${props.name} ${props.lastName? props.lastName: ""}?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [confirmMobileModal, hideMobileModal] = useModal(({ in: open, onExited }) => (
    <MobileConfirm
      mobileTitle="Удаление"
      closeClick={hideMobileModal}
      title={`Вы действительно хотите удалить клиента ${props.name} ${props.lastName? props.lastName: ""}?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={
            "dropdown js-dropdown form__drop" + (open ? " is-showing" : "")
          }
        >
          {media('isMobile') ?
            <Popup
              trigger={
                <button
                  type="link"
                  className="dropdown__btn button form__drop-btn button_type_round"
                >
                  <span className="form__drop-name"></span>
                  <svg className="icon icon_elipsis">
                    <use xlinkHref="#elipsis"></use>
                  </svg>
                </button>
              }
            >
              <div className="dt__more">
                <hr />
                <Link to={`/personal/clients/edit/${props.id}`}>
                  <div className="dt__more-control js-client-edit">
                    <svg className="icon icon_edit">
                      <use xlinkHref="#edit"></use>
                    </svg>
                    <span>Редактировать</span>
                  </div>
                </Link>
                <div
                  className="dt__more-control js-modal-btn js-client-del"
                  data-client-id="client-1"
                  data-id="delete-client"
                  onClick={showMobileConfirm}
                >
                  <svg className="icon icon_delete">
                    <use xlinkHref="#delete"></use>
                  </svg>
                  <span>Удалить</span>
                </div>
              </div>
            </Popup> :

            <>
              <button
                type="link"
                className="dropdown__btn button form__drop-btn button_type_round"
                onClick={(e) => setOpen(!open)}
              >
                <span className="form__drop-name"></span>
                <svg className="icon icon_elipsis">
                  <use xlinkHref="#elipsis"></use>
                </svg>
              </button>
              <div className="dropdown__block">
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    <div className="dt__more">
                      <hr />
                      <Link to={`/personal/clients/edit/${props.id}`}>
                        <div className="dt__more-control js-client-edit">
                          <svg className="icon icon_edit">
                            <use xlinkHref="#edit"></use>
                          </svg>
                          <span>Редактировать</span>
                        </div>
                      </Link>
                      <div
                        className="dt__more-control js-modal-btn js-client-del"
                        data-client-id="client-1"
                        data-id="delete-client"
                        onClick={showConfirm}
                      >
                        <svg className="icon icon_delete">
                          <use xlinkHref="#delete"></use>
                        </svg>
                        <span>Удалить</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

const ColumnFavorite = (props) => {
  const { id, name, lastName, isFavorite, handleClick, source, fromCrm } = props;
  return (
    <div className="clients__table-client">
      {/* <button
        className={`button clients__fav-btn` + (isFavorite ? ` is-fav` : ``)}
        onClick={() => handleClick(id, isFavorite)}
      >
        <svg className="icon icon_star">
          <use xlinkHref="#star"></use>
        </svg>
      </button> */}
      <Link
        className="clients__table-link clients__table-client"
        to={fromCrm ? `/personal/clients/edit/${id}?fromCrm=true` : `/personal/clients/edit/${id}`}
      >
        {name} {lastName}
      </Link>
      {!!source?.title && <div className="clients__lid with-tooltip">
        <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M25.2857 11.6284H6.71429C6.35714 11.6284 6 11.9828 6 12.3373V15.0548C6 15.4092 6.35714 15.7637 6.71429 15.7637H8.38095L13.9762 24.507V27.3426C13.9762 27.5789 14.2143 27.6971 14.3333 27.6971H17.6667C17.9048 27.6971 18.0238 27.5789 18.0238 27.3426V24.507L23.8571 15.7637H25.1667C25.5238 15.7637 25.881 15.4092 25.881 15.0548V12.3373C26 11.9828 25.6429 11.6284 25.2857 11.6284Z" fill="#CCD0DD" />
            <path d="M16 18.0086V7.13861" stroke="#8F95AC" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M16.2382 4.53924L20.0477 9.02903C20.4049 9.38349 20.1668 9.97425 19.6906 9.97425H12.3096C11.8334 9.97425 11.5953 9.38349 11.9525 9.02903L15.5239 4.53924C15.762 4.42108 16.0001 4.42108 16.2382 4.53924Z" fill="#8F95AC" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="20" height="23.1579" fill="white" transform="translate(6 4.42108)" />
            </clipPath>
          </defs>
        </svg>
        <span class="tooltip" style={{ top: 40 }}>{source?.title}</span>
      </div>
      }
    </div>
  );
};

const ColumnStatus = (props) => {
  const { statuses, id } = props;

  if (!statuses || !statuses.length) return "";

  const status = statuses.find((item) => item.id == id);
  return (
    <>
      {status && (
        <div className={`label-item label_${status.color}`}>{status.title}</div>
      )}
    </>
  );
};

const ColumnAgent = (props) => {
  const { agents, id } = props;
  if (!agents || !agents.length) return "";

  const agent = agents.find((item) => item.id == id);
  return <>{agent && `${agent.title}`}</>;
};



const Items = (props) => {
  const {
    items,

    toggleItems,
    selectedItems,

    statuses,
    agents,
    handleFavorite,
    handleDelete,
    deals,
    handleDeals,
    removeTask,
    setStatus,
    statusTitleKey,
    changeStatus,
    fromCrm
  } = props;
  const isMobile = useMedia("isMobile");
  const isTablet = useMedia("isTablet");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  const getMobileColumns = () => [
    {
      title: () => {
        const allIds = items.map(item => item.id).sort()
        const checked = isEqual(allIds, [...selectedItems].sort())
        return <Checkbox
          id={'all'}
          view="invert"
          checked={checked}
          onChange={e => toggleItems(allIds, e.target.checked)}
        />
      },
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
        <Checkbox
          id={id}
          view="invert"
          checked={selectedItems.includes(id)}
          onChange={e => toggleItems(id, e.target.checked)}
        />
      ),
    },
    {
      title: "Клиент",
      dataIndex: "name",
      key: "client",
      render: (text, record) => {
        return (
          <ColumnFavorite
            {...record}
            handleClick={() => handleFavorite(record.id, !record.isFavorite)}
          />
        );
      },
      width: 40,
    },
    // {
    //   title: "Телефон",
    //   dataIndex: "phone",
    //   key: "phone",
    //   render: (phone) =>
    //     phone.map((item) => (
    //       <a className="clients__phone" key={item} href={`tel:${item}`}>
    //         {item}
    //       </a>
    //     )),
    // },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      render: (date, record) => {
        if(!date) return "—"
        return <span>{formatDate(date, {
          day: 'numeric',
          month: 'numeric',
          year: '2-digit',
      })}</span>;
      },
    },
    {
      title: "Стат",
      dataIndex: "status",
      key: "status1",
      render: (status, record) => {
        const findedStatus = statuses.find(item => item?.id == status)
        return (
          <div class="table2-status">
            <div className={`status-circle`} style={{ backgroundColor: ` ${findedStatus?.color}` }}></div>
          </div>
        )
      },
    },
    // {
    //   title: "",
    //   key: "action",
    //   width: 50,
    //   render: (text, record) => (
    //     <ClientAction {...record} deleteClient={handleDelete} />
    //   ),
    // },
  ]
  const TOOLTIP_CUT_VALUE = 60;

  const getColumns = () => [
    {
      title: () => {
        const allIds = items.map(item => item.id).sort()
        const checked = isEqual(allIds, [...selectedItems].sort())
        return <Checkbox
          id={'all'}
          view="invert"
          checked={checked}
          onChange={e => toggleItems(allIds, e.target.checked)}
        />
      },
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
        <Checkbox
          id={id}
          view="invert"
          checked={selectedItems.includes(id)}
          onChange={e => toggleItems(id, e.target.checked)}
        />
      ),
    },
    {
      title: "Клиент",
      dataIndex: "name",
      key: "client",
      render: (text, record) => {
        return (
          <ColumnFavorite
            fromCrm={props.fromCrm}
            {...record}
            handleClick={() => handleFavorite(record.id, !record.isFavorite)}
          />
        );
      },
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (phone) =>
        phone.map((item) => (
          <a className="clients__phone" key={item} href={`tel:${item}`}>
            {item}
          </a>
        )),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (email) =>
        email.map((item) => (
          <a
            className="clients__phone"
            key={item}
            href={`mailto:${item}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {item}
          </a>
        )),
    },

    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      width: isDesktop ? "auto" : 300,
      render: (comment) => {
        return comment.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span style={{wordBreak: 'break-word'}}>{`${comment?.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{comment}</div>
          </div>
        ) : comment.length == 0 ? ( <span style={{wordBreak: 'break-word'}}>—</span>) : (
          <span style={{wordBreak: 'break-word'}}>{comment}</span>
        )
        }
    },

    {
      title: "Ответственный",
      dataIndex: "agent",
      key: "agent",
      render: (agent) => <ColumnAgent agents={agents} id={agent} />,
    },

    {
      title: "Дата добавл.",
      dataIndex: "date",
      key: "date",
      render: (date, record) => {
        if(!date) return "—"
        return <span>{formatDate(date, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
      })}</span>;
      },
    },


    // {
    //   title: "Статус",
    //   dataIndex: "status",
    //   key: "status",
    //   sorter: (a, b) => a.status - b.status,
    //   render: (status) => <ColumnStatus statuses={statuses} id={status} />,
    // },
    // {
    //   title: "Статус",
    //   dataIndex: "status",
    //   key: "status",
    //   width: 220,
    //   sorter: (a, b) => a.status - b.status,
    //   render: (status, record) => {
    //     return   <Dropdown
    //       value={statuses.find(item => item.id == status)}
    //       options={statuses}
    //       onChange={(value) => {
    //         setStatus(record.id, value);
    //       }}
    //     />
    //   },
    // },

    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <Dropdown
            options={statuses}
            value={status.toString()}
            onChange={status => changeStatus(record.id, status)}
            titleKey={statusTitleKey}
            colored
            coloredHex
            openTop={selectedItems.length}
          />
        );

      },
    },
    // {
    //   title: "Задачи",
    //   key: "tasks",
    //   dataIndex: "tasks",
    //   className: "dt_mod_no-p_h",
    //   render: (tasks, record) => (
    //     <Tasks
    //       tasks={tasks}
    //       deals={deals}
    //       client={record.id}
    //       handleEdit={handleDeals}
    //       removeTask={removeTask}
    //     />
    //   ),
    // },
    // {
    //   title: "",
    //   key: "action",
    //   width: 50,
    //   render: (text, record) => (
    //     <ClientAction {...record} deleteClient={handleDelete} />
    //   ),
    // },
  ];

  const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">E-mail:</span>
            {record.email.map((item) => (
              <a
                className="clients__phone"
                key={item}
                href={`mailto:${item}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {item}
              </a>
            ))}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Агент:</span>
            <ColumnAgent agents={agents} id={record.agent} />
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Брони:</span>
            {record.reservations || "—"}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title is-centered">Статус:</span>
            <Dropdown
              value={statuses.find(item => item.id == record.status)}
              options={statuses}
              onChange={(value) => {
                setStatus(record.id, value);
              }}
            />
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Задачи:</span>
            <Tasks
              tasks={record.tasks}
              deals={deals}
              client={record.id}
              handleEdit={handleDeals}
              removeTask={removeTask}
              name={record.name}
              lastName={record.lastName}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div class={`ot-table ot-table_bordered-header${isMobile ? ` table2` : ``}`}>

      <div className="pan-table for-clients">
        <div className="flats__row-table is-active">
          <Table
            rowKey="id"
            showSorterTooltip={false}
            columns={isMobile ? getMobileColumns() : getColumns()}
            // expandable={isMobile ? {
            //   expandedRowRender: record => expandedRow(record),
            // } : false}
            dataSource={items}
            pagination={false}
            locale={{ emptyText: "ничего не найдено" }}
            customPagination={true}
          />
        </div>
      </div>

    </div>
  );
};

export default Items;
