import chunk from "lodash/chunk";
import take from "lodash/take";
import React, { useEffect, useState } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { addToFavorites, removeFromFavorites } from "../../../actions/favorites";
import { getUrl } from "../../../api";
import useMedia from "../../../hooks/useMedia";
import { getRoundedPrice } from "../../../utils";
import LoadMoreSpinner from "../../LoadMoreSpinner";

const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 0);
  });

const MapItem = (props) => {
  const {
    city,
    clientMode,
    isAgent,
    commission,
    favorite,
    removeFav,
    addToFavorites,
    isPublic,
    savedFilterXmlId,
    isMobile,
    subway,
    endings,
    apartmentsInfo,
    photo
  } = props;

  const handleFavorite = (city, id) => {
    favorite.inFavorite ? removeFav(city, id) : addToFavorites(city, id);
  };

  const routes = (
    <ul className="map-card__routes">
      {subway && subway.map((subway, index) => {
        if (!subway) return "";
        return (
          <li className="map-card__routes-item" key={index}>
            {subway.color &&
              <span
                className="map-card__metro-color"
                style={{ backgroundColor: subway.color }}
              ></span>
            }
            {subway.name &&
              <span className="map-card__metro-station">
                м. {subway.name}
              </span>
            }
            {subway.distanceType &&
              <span className="map-card__route-type">
                <svg
                  className={`icon icon_${
                    subway.distanceType === "byFoot"
                      ? "pedestrian"
                      : "auto"
                  }`}
                >
                  <use
                    xlinkHref={`#${
                      subway.distanceType === "byFoot"
                        ? "pedestrian"
                        : "auto"
                    }`}
                  ></use>
                </svg>
              </span>
            }
            {subway.minutes &&
              <span className="map-card__route-time">
                {subway.minutes} мин.
              </span>
            }
          </li>
        );
      })}
    </ul>
  )

  return (
    <li
      className="map-list__item"
      onClick={() => props.setMapCenter(props.coordinates)}
    >
      <div className="map-card">
        <div className="map-card__header">
          {!clientMode && !isAgent && !isPublic && (
            <ul className="card__pills">
              <li className="card__pill" href="#">
                {commission
                  ? `Комиссия: ${commission.join(", ")}`
                  : "Комиссия: уточняйте у менеджера"}
              </li>
            </ul>
          )}
          {!isPublic && (
            <button
              className={`button button_view_fav button_with_icon map-card__fav ${
                favorite.inFavorite ? "is-fav" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleFavorite(city, props.id, "block");
              }}
              type="button"
            >
              <svg className="icon icon_fav">
                <use xlinkHref="#fav"></use>
              </svg>
            </button>
          )}
        </div>

        {isMobile &&
          <>
            <h3 className="map-card__title">{props.name}</h3>
            <div className="map-card__address">{props.address}</div>
          </>
        }

        <div className="map-card__body">
          <div className="map-card__image">
            <img
              src={getUrl(photo?.src)}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = getUrl('/upload/bcg_houses-01-01.svg');
              }}
            />
          </div>
          <div className="map-card__content">
            <div className="map-card__row map-card__row_distribute_between">
              <div className="map-card__place">
                {!isMobile &&
                  <>
                    <h3 className="map-card__title">{props.name}</h3>
                    <div className="map-card__address">{props.address}</div>
                  </>
                }
              </div>
              {/* {!isMobile &&
                <div className="map-card__price">
                  от{" "}
                  {getRoundedPrice(
                    minBy(
                      apartmentsInfo.apartmentsByRoomType,
                      (v) => v.minPrice
                    ).minPrice
                  )}{" "}
                  млн. р.
                </div>
              } */}
            </div>
            {!isMobile &&
              <div className="map-card__company">
                <div className="map-card__grid">
                  <div className="map-card__left">
                    Застройщик: {props.builder?.name}
                  </div>
                  <div className="map-card__right">
                    {props.countBuildings} корпусов
                  </div>
                </div>
              </div>
            }
            {endings && endings.from &&<div className="map-card__dates">
              {endings.from.quarter} кв. {endings.from.year} —{" "}
              {endings.to.quarter} кв. {endings.to.year}
            </div>}
            {apartmentsInfo?.apartmentsByRoomType && (
              <div className="card__variants">
                {apartmentsInfo.apartmentsByRoomType.map((item, index) => (
                  <div className="card__grid" key={index}>
                    <div className="card__left">
                      <div className="card__left-inner">
                        <div className="card__left-part">
                          <strong>{item.roomTypeName}</strong>
                          {!isMobile && <span> / {item.count}</span>}
                        </div>
                        {!isMobile &&
                          <div className="card__left-part">
                            от {item.minSquare} м2
                          </div>
                        }
                      </div>
                    </div>
                    <div className="card__right">
                      от {getRoundedPrice(item.minPrice)} млн. р.
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!isMobile && routes}

            {(apartmentsInfo?.isApartment || apartmentsInfo?.isAssignment) &&
              <ul className="map-card__options">
                {apartmentsInfo.isApartment && (
                  <li className="map-card__option ">Апартаменты</li>
                )}
                {/*apartmentsInfo.isAssignment && (
                  <li className="map-card__option ">Переуступки застр.</li>
                )*/}
              </ul>
            }
            {!isMobile &&
              <Link
                to={`/complexes/${city}/${props.id}${window.location.search}`}
                target="_blank"
              >
                <button className="button button_view_default constructor__btn map-card__details-button">
                  Планировки и цены
                </button>
              </Link>
            }
          </div>
        </div>

        {isMobile && routes}
      </div>
    </li>
  );
};

const MapList = (props) => {
  const {
    items,
    city,
    clientMode,
    isAgent,
    removeFav,
    addToFavorites,
    isPublic,
    savedFilterXmlId,
  } = props;
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const isMobile = useMedia("isMobile")
  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage(p * 10 < items.length);
      setLoading(false);
    });
  }

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    threshold: 20,
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  useEffect(() => {
    setPage(0);
    setData(take(items, 10));
    setHasNextPage(items.length > 10);
  }, [items]);

  return (
    <div className={`map__list ${data[0]?.type === 'parcel' || data[0]?.type === 'house' ? 'map__list_suburban' : ''}`}>
      <div className="wrapper wrapper_fluid">
        {data[0]?.type !== 'parcel' && data[0]?.type !== 'house' &&
        <div className="cards__map-cards">
          {!isMobile && <h3 className="map-list-title">{items.length} ЖК</h3>}

          <SimpleBar className="map-list">
            <div ref={infiniteRef}>
              <ul className="map-list__list">
                {data &&
                  data.map((item) => {
                    return (
                      isMobile ?
                        <Link
                          // to={
                          //   isPublic
                          //     ? `/public/${savedFilterXmlId}/${city}/complex/${item.id}`
                          //     : `/realty/${city}/complex/${item.id}`
                          // }
                          to={`/complexes/${city}/${item.id}${window.location.search}`}
                          target="_blank"
                          className="map-list__link"
                          key={`map-list-item-${item.id}`}
                        >
                          <MapItem
                            key={`map-list-item-${item.id}`}
                            {...item}
                            city={city}
                            setMapCenter={props.setMapCenter}
                            isAgent={isAgent}
                            clientMode={clientMode}
                            removeFav={removeFav}
                            addToFavorites={addToFavorites}
                            isPublic={isPublic}
                            savedFilterXmlId={savedFilterXmlId}
                            isMobile={isMobile}
                          />
                        </Link> :
                        <MapItem
                          key={`map-list-item-${item.id}`}
                          {...item}
                          city={city}
                          setMapCenter={props.setMapCenter}
                          isAgent={isAgent}
                          clientMode={clientMode}
                          removeFav={removeFav}
                          addToFavorites={addToFavorites}
                          isPublic={isPublic}
                          savedFilterXmlId={savedFilterXmlId}
                          isMobile={isMobile}
                        />
                      )
                  })}
              </ul>
            </div>
            {loading && <LoadMoreSpinner loading={true} />}
          </SimpleBar>
        </div>
        }
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  removeFav: (city, id) => {
    dispatch(removeFromFavorites(city, id, "block", "estateMap"));
  },

  addToFavorites: (city, id) =>
    dispatch(addToFavorites(city, id, "block", "estateMap")),
});

export default connect(null, mapDispatchToProps)(MapList);
