import { handleFavorite } from './handlers';
export const cleanFilter = Object.freeze({
  communications: [],
  countBedrooms: [],
  bathroomTypes: [],
  isOnlyHanded: [],
  countFloors: {
    max: "",
    min: ""
  },
  endings: {
    max: null,
    min: null
  },
  features: [],
  floors: {
    max: "",
    min: ""
  },
  materials: [],
  prices: {
    max: "",
    min: ""
  },
  rooms: [],
  squareHouse: {
    max: "",
    min: ""
  },
  squareParcel: {
    max: "",
    min: ""
  },
  status: [1],
  type: []
});

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  // активность сохр. фильтра по ссылкам /public/*
  isActive: true,
  agent: null,
  filterData: {},
  banks: [],
  news: [],
  actions: [],
  otherBlocks: [],
  filter: cleanFilter,
  apartments: [],
  count: "initial",
};

const suburban = (state = initialState, action) => {
  const {
    type,
  } = action;
  const cleanAction = { ...action }
  delete cleanAction.type
  switch (type) {
    case "GET_VILLAGE_SUCCESS":
    case "GET_VILLAGE_LANDS_SUCCESS":
    case "GET_COUNT_LANDS":
      return {
        ...state,
        ...cleanAction
      };
    case "SUBURBAN_RESET":
      return { ...initialState };
    case "VILLAGE_SET_FILTER":
      return { ...state, filter: action.filter }
    case "VILLAGE_CLEAR_FILTER":
      return { ...state, filter: cleanFilter, count: "initial", }
    case "VILLAGE_LANDS_SUCCESS":
      return { ...state, apartments: action.apartments }
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'suburban') {
        const isSingle = action.data.type === "village"
        const key = isSingle ? "village" : "villages"
        return { ...state, [key]: handleFavorite(state[key], action, isSingle) }
      }
      return state
    default:
      return state;
  }
};

export default suburban;
