import React, { useState } from 'react';
import './index.scss';

const Accordeon = ({ children, title, withClass, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggling = () => setIsOpen(!isOpen);

    return (
      <div className={`accordeon${withClass ? ` ${withClass}` : ``}${isOpen ? ` opened` : ``}`}  {...props}>
        <div class="accordeon__title" onClick={toggling}>
          <span className="accordeon__title-text">{title}</span>
          <button
            type="button"
            className={`button accordeon__btn`}
            aria-label="Открыть панель">
            <svg className="icon icon_more">
              <use xlinkHref="#chevron"></use>
            </svg>
          </button>
        </div>

        <div className={`accordeon__content medium-text`}>
          {children}
        </div>
      </div>
    );
};

export default Accordeon;
