import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './index.scss';

const MobileModal = ({
  title,
  children,
  closeClick,
  cancelClick,
  cancelTitle,
  fixedTitle,
  submitClick,
  submitTitle,
  style,
  className
}) => {

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  });

  const theme = useSelector(state => state.agency.theme);
  const [color, setColor] = useState(theme.color);
  let currentColor;
  useEffect(() => {
    setColor(theme.color)
  }, [theme])
  switch (color) {
    case 'green':
      currentColor = `mobile-modal`;
      break;

    case 'blue':
      currentColor = `mobile-modal constructor_color-blue`;
      break;

    case 'yellow':
      currentColor = `mobile-modal constructor_color-yellow`;
      break;

    case 'red':
      currentColor = `mobile-modal constructor_color-red`;
      break;

    case 'gray':
      currentColor = `mobile-modal constructor_color-gray`;
      break;
    default:
      currentColor = `mobile-modal`;
      break;
  }

  return (
    <div
      className={
        `mobile-modal ${fixedTitle ? `with-padding` : ''} ${className ? className : ''} ${currentColor}`
      }
      style={style}
    >
      <div className={`mobile-modal__title ${fixedTitle ? 'is-fixed' : ''}`}>
        <svg
          className="icon icon_arrow_up"
          onClick={closeClick}
        >
          <use xlinkHref="#arrow_up"></use>
        </svg>
        <h3>{title}</h3>
      </div>
      <div className="mobile-modal__content">
        {children}

        {(cancelTitle || submitTitle) &&
          <div className="mobile-modal__submit">
            <button
              className="button button__mobile-modal"
              onClick={cancelClick}
            >
              Очистить
              {cancelTitle}
            </button>
            <button
              className="button button__mobile-modal"
              onClick={submitClick}
            >
              {submitTitle}
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default MobileModal;
