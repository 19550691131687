import React, { memo, useMemo, useState } from "react";
import useMedia from "../../../hooks/useMedia";
import { formatDate, removeBodyScroll, returnBodyScroll } from '../../../utils';
import Badge from "../../Badge";
import DatepickerDropdown from "../../core/DatepickerDropdown";
import SelectFilter from "../../FilterRealty/SelectFilter";


const mapFilters = {
  agent: "agents",
  status: "statuses",
  dates: "dates",

}

const Filter = memo(props => {
  const { filter, filterData, setFilter, clearFilter, search, setSearch, removeFilter, fromCrm } = props;

  const isMobile = useMedia("isMobile")

  const handleFilter = (value, ident) => setFilter({ value, ident });

  const renderBadges = useMemo(() => {
    const entries = Object.entries(filter)
    if (entries.length === 0 || !filterData) return []
    const titles = {
      type: "Тип",
      agent: "Агент",
      status: "Статус",
      dates: "Дата"
    }
    const result = []

    for (const [key, value] of entries) {
      if (key === "dates") {
        result.push(<Badge
          key={`${key}`}
          identity={key}
          item={{
            title: `${filter.dates.startDate
              ? formatDate(filter.dates.startDate, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })
              : ``
              } - ${filter.dates.endDate
                ? formatDate(filter.dates.endDate, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })
                : ``
              }`
          }}
          title={titles[key]}

          handleClick={(value, ident) => removeFilter({ value, ident })}
        />)
        continue
      }
      for (const subvalue of value) {
        const item = filterData[mapFilters[key]].find(item => item.id == subvalue)
        result.push(<Badge
          key={`${key}-${item.id}`}
          identity={key}
          item={item}
          title={titles[key]}
          alternativeKey={key === "status" ? "name" : "title"}
          handleClick={(value, ident) => removeFilter({ value, ident })}
        />)
      }
    }
    return result
  }, [filter, filterData])

  const count = useMemo(() => renderBadges.length, [filter])

  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? removeBodyScroll()
      : returnBodyScroll()
  }

  const filters = (
    <>
      <div className="clients__filter-col">
        <DatepickerDropdown
          onChange={(value) => handleFilter(value, "dates")}
          value={filter.dates || {
            startDate: null,
            endDate: null,
          }}
          isMobile={isOpen && isMobile}
          clearFilters={clearFilter}
        />
      </div>
      <div className="clients__filter-col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "agent")}
          isExcludable={false}
          isAutocomplete={true}
          items={filterData.agents ? Object.values(filterData.agents) : []}
          filterValues={filter.agent}
          excludeValues={[]}
          data={{
            title: "Агент",
            autocompleteTitle: "Введите имя агента",
            identity: "agent",
          }}
          isMobile={isOpen && isMobile}
          clearFilterOnly={clearFilter}
        />
      </div>
      <div className="clients__filter-col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, "status")}
          isExcludable={false}
          isAutocomplete={false}
          items={
            filterData.statuses ? Object.values(filterData.statuses) : []
          }
          filterValues={filter.status}
          excludeValues={[]}
          data={{
            title: "Статус",
            autocompleteTitle: "",
            identity: "status",
          }}
          titleKey="name"
          isMobile={isOpen && isMobile}
          clearFilterOnly={clearFilter}
        />
      </div>
      {/* <div className="clients__filter-addit for-clients">
        <div className="clients__filter-addit-item">
          <div className="checkbox js-filter">
            <div className="checkbox__box">
              <input
                className="checkbox__control mini-filter__control js-filter-by"
                type="checkbox"
                id="only-fav"
                name="only-fav"
                checked={filter.isFavorite}
                onChange={(e) =>
                  handleFilter(e.target.checked, "isFavorite")
                }
              />
              <label className="checkbox__input" for="only-fav"></label>
              <div className="checkbox__marker"></div>
            </div>
            <label className="checkbox__label" for="only-fav">
              Только избранные
            </label>
          </div>
        </div>
      </div> */}
    </>
  )

  return (
    <div className="mini-filter">
      <form
        className="form"
        onSubmit={(e) => e.preventDefault()}
      >
        {isMobile ?
          <>
            <div className="clients__filter-col clients__filter-col_search">
              <label
                className="input input_type_search input_width_available"
                for="clients-search"
              >
                <input
                  className="input__control"
                  type="search"
                  id="clients-search"
                  placeholder="Поиск по клиенту, агенту ..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <svg className="icon icon_search">
                  <use xlinkHref="#search"></use>
                </svg>
              </label>
            </div>
            <button className="button-mobile for-main" onClick={toggleFilters}>
              Фильтры
              {count > 0 && ` (${count})`}
            </button>
          </> :

          <div className="mini-filter__grid">
            <div className="clients__filter-col clients__filter-col_search">
              <label
                className="input input_type_search input_width_available"
                for="clients-search"
              >
                <input
                  className="input__control"
                  type="search"
                  id="clients-search"
                  placeholder="Поиск по клиенту, агенту ..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <svg className="icon icon_search">
                  <use xlinkHref="#search"></use>
                </svg>
              </label>
            </div>

            {filters}
            {!fromCrm &&
              <div className="clients__filter-addit-item">
                <button
                  className="button button_type_clear mini-filter__button"
                  type="button"
                  onClick={clearFilter}
                  disabled={!count}
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
              </div>
            }
          </div>
        }
      </form>

      {isOpen && isMobile &&
        <div className="mobile-filters for-clients">
          <div className="mobile-filters__title">
            <svg
              className="icon icon_arrow_up"
              onClick={() => toggleFilters()}
            >
              <use xlinkHref="#arrow_up"></use>
            </svg>
            <h3>Все фильтры</h3>
          </div>
          <div className="mobile-filters__content">
            {filters}

              <div className="index-search__filter-applied">
                <div className="index-search__filter-inc">
                  {renderBadges}
                </div>
              </div>

            <div className="mobile-filters__gutter"></div>

            <div className="mobile-filters__submit">
              <button
                className="button button__mobile-filters"
                type="button"
                onClick={(e) => {
                  clearFilter()
                  toggleFilters()
                }}
              >
                Очистить
              </button>
              <button
                className="button button__mobile-filters"
                onClick={(e) => {
                  e.preventDefault()
                  toggleFilters()
                }}
              >
                Применить
              </button>
            </div>
          </div>
        </div>
      }

      {!isMobile && !fromCrm && (
        <div className="index-search__filter-applied">
          <div className="index-search__filter-inc">
            {renderBadges}
          </div>
        </div>
      )}
    </div>
  );
});

export default Filter;
