import React, { useState, useEffect, useRef } from "react";

const AssignationText = (props) => {
  const { note } = props;
  const [show, setShow] = useState(true);
  const [toggleBtn, setToggleBtn] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const newDiv = document.createElement("div");
    newDiv.innerHTML = note;
    var resultLength = newDiv.innerText.split(" ").join("");
    if (divRef.current.offsetHeight > 75 && resultLength.length > 125) {
      setShow(false);
      setToggleBtn(true);
    }
  }, []);

  return (
    <div className={`assignation-about${show ? ` opened` : ``}`}>
      <div className="assignation-about__info">
        <svg className="icon">
          <use xlinkHref="#info"></use>
        </svg>
      </div>

      <div className="assignation-about__body" ref={divRef}>
        <div
          className={`assignation-about__col`}
          dangerouslySetInnerHTML={{
            __html: note,
          }}
        >

      </div>
    </div>
    {
      toggleBtn && (
      <button
        className="assignation-about__toggle button"
        type="button"
        onClick={() => setShow(prevState => !prevState)}>
        {show ? `Свернуть` : `Подробнее`}
        <svg className="icon icon_chevron">
          <use xlinkHref="#chevron"></use>
        </svg>
      </button>
      )
    }

    </div>
  );
};

export default AssignationText;
