import chunk from "lodash/chunk";
import take from "lodash/take";
import uniq from "lodash/uniq";
import React, { useEffect, useState } from "react";
import { useModal } from 'react-modal-hook';
import { getRoundedPrice } from '../../../utils';
import CallbackModal from "../../Constructor/CallbackModal/CallbackModal.js";
import LoadMoreSpinner from '../../LoadMoreSpinner';




const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 200);
  });

const Typical = ({ apartments, isPreview, id }) => {
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState(null)
  useEffect(() => {
    setPage(0);
    setData(take(apartments, 10));
    setHasNextPage(apartments.length > 10);
  }, [apartments]);

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(apartments, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage(p * 10 < apartments.length);
      setLoading(false);
    });
  }
  const [openCallbackModal, closeCallbackModal] = useModal(({ in: open, onExited }) => (
    <CallbackModal closeCallbackModal={closeCallbackModal} isPreview={isPreview} selectedType={selectedType} id={id}/>
  ), [selectedType, id]);
  const openModal = (id) => {
    setSelectedType(id)
    openCallbackModal()
  }

  return (
    <section className="typical constructor__section">
      <div className="constructor__container">
        <div className="typical__wrapper constructor__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title typical__title">
              Типовые планировки и цены
            </h2>
          </div>
          <ul className="typical__list">
            {data.map(item => <li className="typical__item">
              <span className="typical__label h3">{item.roomType?.name}</span>
              <img
                src={`${item.plan
                  ? item.plan.src
                  : require('../../../assets/images/emptyPlan.svg').default
                  }`}
                alt="" className="typical__image"
              />
              <ul className="typical__header">
                <li className="typical__header-item">
                  <span className="typical__header-title">Цена</span>
                  <span className="typical__header-value">от {getRoundedPrice(item.minPrice)} млн. р.</span>
                </li>
                <li className="typical__header-item">
                  <span className="typical__header-title">Площадь</span>
                  <span className="typical__header-value">от {item.minSquare} м<sup>2</sup></span>
                </li>
              </ul>
              <ul className="typical__info">
                {!!item.ending && <li className="typical__info-item">
                  <span className="typical__info-key">Срок сдачи</span>
                  <span className="typical__info-value">{item.ending.join("—")}</span>
                </li>}
                {!!item.issuingKeys && <li className="typical__info-item">
                  <span className="typical__info-key">Выдача ключей</span>
                  <span className="typical__info-value">{item.issuingKeys.join("—")}</span>
                </li>}
                {!!item.floors && <li className="typical__info-item">
                  <span className="typical__info-key">Этаж</span>
                  <span className="typical__info-value">{uniq(item.floors).join("—")}</span>
                </li>}
                {!!item.decorations && <li className="typical__info-item">
                  <span className="typical__info-key">Отделка</span>
                  <span className="typical__info-value">{item.decorations}</span>
                </li>}
              </ul>

              <button className="button constructor__btn typical__btn" type="button" onClick={()=>openModal(item?.roomType?.id)}>Подобрать квартиру</button>
            </li>)}
          </ul>
          {hasNextPage && !loading && <button
            className="button constructor__btn constructor__btn_transparent"
            type="button"
            onClick={handleLoadMore}
          >
            Показать еще
          </button>}
          {loading && <LoadMoreSpinner loading={true} />}
        </div>

      </div>
    </section>
  )
};

export default Typical;
