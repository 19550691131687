import ru from "date-fns/locale/ru"; // the locale you want
import _ from "lodash";
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { viewApartment } from "../../actions/forms";
import { media } from "../../utils";
import Dropdown from "../core/Dropdown";
import SubmitButton from "../SubmitButton";
import FormAddClient from "./FormAddClient";



registerLocale("ru", ru);

const ViewApartment = (props) => {
  const { clients, id, phone, email, name, handleSend, city } = props;

  const defaultValues = {
    id: id,
    date: new Date(),
    time: new Date(),
    name: name,
    clientId: "-1",
    phone: phone,
    email: email,
    comment: "",
    agree: true,
  };

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [show, setShow] = useState(false);

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [fetching, setFetching] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const changeFile = (e) => {
    const tmpFiles = e.target.files;
    if (tmpFiles.length > 0) {
      let tFiles = [...files];
      let tNames = [...fileNames];
      [...tmpFiles].forEach((item) => {
        tFiles.push(item);
        tNames.push(item.name);
      });
      setFiles(tFiles);
      setFileNames(tNames);
    }
  };

  const handleAddClient = (id) => {
    setValue("clientId", id);
    setShow(false);
  };

  const onSubmit = (data) => {
    setFetching(true);

    let form = new FormData();
    let keys = _.keys(data);

    keys.forEach((item) => {
      let identity = item;
      if (Array.isArray(data[item])) {
        data[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        form.append(`${identity}`, data[item]);
      }
    });
    if (files.length) {
      files.map((file, index) => {
        form.append(`files[]`, file);
        form.append(`fileName[]`, fileNames[index]);
        return file;
      });
    }

    form.append("city", city? city : "spb")

    viewApartment(form).then(
      (data) => {
        setSuccess(true);
        handleSend();
      },
      (error) => {
        setError(true);
      }
    );
  };
  const emptyClient = [
    {
      id: "-1",
      title: "Без клиента",
    },
  ];
  return (
    <>
      {!media("isMobile") && <h2 className="modal__title">Запись на просмотр</h2>}
      <form
        className="modal-form"
        name="reserve-apartment"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal-form__wrapper">
          <input type="hidden" name="id" ref={register} />
          <div class="modal-form__gist modal-form__row">
            <div class="modal-form__row">
              <div class="modal-form__item">
                <label
                  class="input input_width_available input_type_form"
                  for="modal-viewing-date"
                >
                  <span class="input__label">Желаемая дата</span>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="d MMM yyyy"
                        minDate={new Date()}
                        showTimeSelect={false}
                        todayButton={false}
                        dropdownMode="select"
                        className={
                          "input__control" +
                          (errors.date ? " input__error" : "")
                        }
                        autoComplete="off"
                        shouldCloseOnSelect
                        locale="ru"
                      />
                    }
                    control={control}
                    register={register({ required: true })}
                    name="date"
                    valueName="selected" // DateSelect value's name is selected
                    onChange={([selected]) => {
                      return selected;
                    }}
                    autoComplete="off"
                    rules={{
                      required: true,
                    }}
                  />
                </label>
              </div>
              <div class="modal-form__item">
                <label
                  class="input input_width_available input_type_form"
                  for="modal-viewing-time"
                >
                  <span class="input__label">Желаемое время</span>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="HH:mm"
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        className={
                          "input__control" +
                          (errors.time ? " input__error" : "")
                        }
                        shouldCloseOnSelect
                        autoComplete="off"
                        timeCaption="Время"
                        timeFormat="p"
                        locale="ru"
                      />
                    }
                    control={control}
                    register={register({ required: true })}
                    name="time"
                    valueName="selected"
                    onChange={([selected]) => {
                      return selected;
                    }}
                    rules={{
                      required: true,
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-name"
              >
                <span className="input__label">Имя и фамилия</span>
                <input
                  id="modal-reservation-name"
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите фамилию",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-phone"
              >
                <span className="input__label">Телефон</span>
                <InputMask
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autocomplete="off"
                  maskChar=" "
                  name="phone"
                  defaultValue={phone}
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="select select_type_form">
                <label
                  className="custom-select-label"
                  htmlFor="modal-reservation-client"
                >
                  Клиент
                </label>
                <Controller
                  as={Dropdown}
                  name="clientId"
                  options={clients.concat(emptyClient)}
                  onChange={([value]) => value}
                  inputRef={register}
                  control={control}
                />
              </div>
              <div
                className="add-client js-show active"
                style={{ display: !show ? "flex" : "none" }}
                onClick={() => setShow(true)}
              >
                <button
                  className="button button_type_add"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                ></button>
                <span>Добавить клиента</span>
              </div>
            </div>
          </div>
          <div
            className="modal-form__gist modal-form__row modal-form__new-client"
            id="reservation-new-client"
          >
            <div className="new-client">
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Фамилия клиента</span>
                    <input className="input__control" name="lastName" />
                  </label>
                </div>
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Имя клиента</span>
                    <input className="input__control" name="firstName" />
                  </label>
                </div>
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Отчество клиента</span>
                    <input className="input__control" name="thirdName" />
                  </label>
                </div>
              </div>
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">Телефон</span>
                    <input
                      className="input__control js-phone-mask"
                      name="phone"
                    />
                  </label>
                </div>
                <div className="modal-form__item">
                  <label className="input input_width_available input_type_form">
                    <span className="input__label">E-mail</span>
                    <input className="input__control" name="email" />
                  </label>
                </div>
              </div>
              <div className="new-client__submit">
                <button className="button button_view_grey">Добавить</button>
              </div>
            </div>
          </div>
          <FormAddClient
            show={show}
            onAddClient={(id) => handleAddClient(id)}
          />
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="input input_type_upload-btn"
                htmlFor="modal-reservation-file-input"
              >
                <input
                  class="input__control"
                  type="file"
                  multiple
                  id="modal-reservation-file-input"
                  name="file"
                  onChange={changeFile}
                />
                Прикрепите файлы
              </label>
              <div
                className="modal-form__files js-upload-container"
                data-id="modal-reservation-file-input"
              >
                {fileNames.map((file, index) => (
                  <div data-num="0" class="file-up" key={index}>
                    <div class="file-up__inner">
                      <svg class="icon file-up__icon">
                        <use xlinkHref="#deals-01"></use>
                      </svg>
                      <span class="file-up__name">{file}</span>
                      <div class="file-up__controls">
                        <button class="button button_view_round-control file-up__del">
                          <svg class="icon">
                            <use xlinkHref="#x"></use>
                          </svg>
                        </button>
                        <button class="button button_view_round-control file-up__edit">
                          <svg class="icon">
                            <use xlinkHref="#edit"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    "checkbox__box" +
                    (errors.agree ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        "Необходимо согласиться с правилами обработки данных",
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Отправить заявку</span>
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  email: state.user.info.email,
  phone: state.user.info.phone,
  clients: state.user.clients.map((client) => ({
    id: client.id,
    title: `${client.name} ${client.lastName}`,
  })),
  name: `${state.user.info.name} ${state.user.info.lastName}`,
});

export default connect(mapStateToProps, null)(ViewApartment);
