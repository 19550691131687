import React from "react";
import { getUrl } from "../../api";
import emptyPlan from '../../assets/images/emptyPlan.svg';
import { media } from "../../utils";
import CardHeart from "../Cards/CardHeart";

const whitelistForCardClick = [
  "card__header",
  "card__content",
  "card__title",
  "card__address",
  "card__left",
  "card__right",
  "card__metro-station",
  "card__left-part",
  "card__metro-color card__metro-color_1",
  "card__dates-to",
  "card__dates-from",
  "card__pill",
  "card__option",

  "flats__card-features",
  "flats__card-feature_value",
  "flats__card-feature_title",
  "card__dates-from",
  "flats__card-info",
  "flats__card-price",
  "for-click-class",
];

const FlatCard = (props) => {
  const {
    showOnLeft,
    disableFavorites,

    link = "",
    imageUrl = "",
    title,
    subtitle,
    address,
    ending,
    company,
    countBuildings,
    features,
    subway,
    communications,
    communicationsTitle,
    id,
    type,
    city,
    favorite,
    whatPage = 'favorites'
  } = props;



  return (
    <div className="section__grid-col flat-card__col">
      <div
        className="flats__card"
        onClick={(e) => {
          e.stopPropagation()
          whitelistForCardClick.includes(e.target.classList.toString()) && window.open(link)
        }}
        style={{justifyContent: "unset"}}
      >
        <div className="flats__card-image">
          <img
            src={getUrl(imageUrl)}
            alt={address}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan;
            }}
            className="for-click-class"
          />
          {!disableFavorites && (
            <div className="flats__card-top">
              <div className="flats__card-status"></div>
              <CardHeart
                type={type}
                id={id}
                city={city}
                favorite={favorite}
                whatPage={whatPage}
                showOnLeft={showOnLeft}
              />
            </div>
          )}
        </div>
        <div className="flats__card-info">
          <div className="flats__card-price" style={{ display: "flex" }}>
            {title}
          </div>
          <div className="card__row card__row_distribute_between">
            <div className="card__place">
              <div className="card__title">{subtitle && subtitle}</div>
              <div className="card__address">{address && address}</div>
            </div>
            <div className="card__dates">
              <div className="card__dates-from">{ending && ending}</div>
              {/* <div className="card__dates-to"></div> */}
            </div>
          </div>
          <div className="card__company">
            <div className="card__grid">
              {company && (
                <div className={
                  `card__left ${(!countBuildings && media("isMobile")) ? "card__left-full-width" : ""}`
                }>
                  {company}
                </div>
              )}
              {countBuildings && (
                <div className="card__right">
                  {countBuildings}
                </div>
              )}
            </div>
          </div>
          <div className="flats__card-features">
            {features && features.map(feature => feature.value ? <div className="flats__card-feature">
              <div className="flats__card-feature_value">
                {feature.value}
              </div>
              <div className="flats__card-feature_title">{feature.name}</div>
            </div> : null)}
          </div>
          {communications && <div className="flats__card-feature" style={{margin: "4px 0"}}>
              <div className="flats__card-feature_value" >
                {communications}
              </div>
              <div className="flats__card-feature_title">{communicationsTitle}</div>
            </div>}
          <ul className="card__routes">
            {subway &&
              subway.map((subway, i) => (
                <li className="card__routes-item" key={subway.id + i}>
                  <span
                    className="card__metro-color card__metro-color_1"
                    style={{ backgroundColor: subway.color }}
                  ></span>
                  <span className="card__metro-station">м. {subway.name}</span>
                  {subway.distanceType && <span className="card__route-type">
                    <svg
                      className={`icon icon_${subway.distanceType === "byFoot" ? "pedestrian" : "auto"
                        }`}
                    >
                      <use
                        xlinkHref={`#${subway.distanceType === "byFoot"
                          ? "pedestrian"
                          : "auto"
                          }`}
                      ></use>
                    </svg>
                  </span>}
                  {subway.minutes && <span className="card__route-time">
                    {subway.minutes} мин.
                  </span>}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default FlatCard;