import emptyPlan from '../../assets/images/emptyPlanList.svg';

const casesColumns = [
    {
        title: "",
        dataIndex: "plan",
        width: 80,
        render: (plan) => {
            return (
                <div className="table-img">
                    <img src={plan?.src} alt="" onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = emptyPlan
                    }} />
                    <div className="table-img__preview">
                        <img src={plan?.src} alt="" onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = emptyPlan
                        }} />
                    </div>
                </div>
            )
        },
    },
    {
        title: "Название",
        dataIndex: "title",
        render: title => <p className="h6">{title}</p>
    },
    {
        title: "Объект",
        dataIndex: "block",
        render: (block, {builder}) => <>{block + ' от ' + builder}</>
    },
];
export default casesColumns
