import classNames from 'classnames';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import usePermission from '../../hooks/usePermission';
import { getPhone } from '../../utils';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Wrap from '../core/ModalWrap';
import ReserveAutopan from '../forms/ReserveAutopan.js';
import ReserveMeetingRoom from '../forms/ReserveMeetingRoom.js';

import BannerWebinar from './BannerWebinar';

import Menu from "../core/Menu";
import NottificationContainer from "../core/Nottifications/container";
import BottomMenu from './BottomMenu';
import './index.scss';
import SearchById from './SearchById';
import TopMenu from './TopMenu';

import { debounce } from 'lodash';
import { mapFilter } from '../../utils/special.js';
import { bottomMenuData, Burger, Logo, PhoneLink, rowServicesItems, subMenuData, topMenu } from './headerUtils.js';

const Header = memo(({ isPublic, redesign, isAssignments }) => {

  const dispatch = useDispatch() //выснить, зачем сохраняется хедер и вырезать это

  const setHeaderRef = (ref) => dispatch({ type: 'SET_REF', payload: { header: ref } })

  const togglerNode = useRef();

  const headerRef = useRef(null);

  const headerTopRef = useRef(null);

  const headerBottomRef = useRef(null);

  const isClientMode = usePermission('client');

  const isToprealtors = usePermission('toprealtors');

  const isMobile = useMedia('isMobile');
  const isLaptop = useMedia('isLaptop');
  const isTablet = useMedia('isTablet');

  const submenu = useMemo(() => mapFilter(subMenuData, v => !(isToprealtors && v?.title === "АвтоПАН") && v), [isToprealtors])

  const bottomMenu = useMemo(() => mapFilter(bottomMenuData, v => {
    if (v.title === "Сервисы") return { ...v, submenu }
    if (v.title === "Контакты" && isClientMode) return false
    return v
  }), [isClientMode, isToprealtors])


  const headerBanner = useSelector((state) => state.general.headerBanner)

  const servicesMenu = useSelector(state => state.general.servicesMenu)

  const count = useSelector(state => state.general.unreadCount)

  const { logo, favoritesCount, manager } = useSelector(state => state.user, shallowEqual)

  const [showReserveAutopan, hideReserveAutopan] = useModal(
    ({ in: open, onExited }) => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              closeClick={hideReserveAutopan}
              title="АвтоПАН"
              classes="modal_task"
              style={{ zIndex: '99999999' }}
            >
              {children}
            </MobileModal>
          ) : (
            <Modal
              close={hideReserveAutopan}
              classes="modal_task modal_task_lg"
            >
              {children}
            </Modal>
          )
        }
      >
        <ReserveAutopan
          defaultValues={{
            date: new Date(),
            agree: true,
          }}
          close={hideReserveAutopan}
        />
      </Wrap>
    ),
  );


  const [showReserveMeetingRoom, hideReserveMeetingRoom] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideReserveMeetingRoom} classes="modal_task">
        <ReserveMeetingRoom
          defaultValues={{
            date: new Date(),
            comment: '',
            agree: true,
          }}
          close={hideReserveMeetingRoom}
        />
      </Modal>
    ),
  );

  const showModal = modalKey => modalFunctions[modalKey]?.();

  const [showServices, hideServices] = useModal(({ in: open, onExited }) => (
    <MobileModal closeClick={hideServices} title="Сервисы">
      <ul className="mobile-services">
        {rowServicesItems(submenu, showModal)}
      </ul>
    </MobileModal>
  ), [submenu, showModal]);

  const modalFunctions = {
    "autopan": showReserveAutopan,
    "meetingRooms": showReserveMeetingRoom,
    "services": showServices
  }

  const fix = debounce((e) => {
    if (!headerRef.current) return;

    if (window.scrollY >= 44) return headerBottomRef.current.classList.add('header__bottom--hidden');

    headerBottomRef.current.classList.remove('header__bottom--hidden');

  }, 200);

  useEffect(() => {
    document.onreadystatechange = () => { // to do посмотреть зачем хедер и попытаться поправить без этого
      if (headerRef.current) setHeaderRef(headerRef);
    }
    !isTablet && window.addEventListener('scroll', fix);
    return () => {
      !isTablet && window.removeEventListener('scroll', fix);
    };
  }, []);


  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);

  const [nottificationsIsOpen, setNottificationsIsOpen] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => setIsSearchOpen(prev => !prev);

  const headerClasses = classNames({
    header: true,
    'client-mode': isClientMode,
    'header_redesign-mobile': redesign
  });

  const headerInnerClasses = classNames({
    "header__top-inner": true,
    'header__top-inner_with-wa': manager.phone || manager.telegram,
  });

  const mobileSearchClasses = classNames({
    "mobile-search-id": true,
    "is-open": isSearchOpen,
    "is-hidden": !isSearchOpen, //шляпа, лучше бы по умолчанию был display: none
  })



  return (
    <>
      <Menu isOpen={sideMenuIsOpen}
        setOpen={setSideMenuIsOpen}
        togglerNode={togglerNode}
        servicesMenu={servicesMenu}
      />

      <NottificationContainer isOpen={nottificationsIsOpen} setIsOpen={setNottificationsIsOpen} />

      {!isClientMode && headerBanner && (
        <BannerWebinar
          link={headerBanner.link}
          text={headerBanner.name}
          type={headerBanner.type.xmlId}
        />
      )}

      <header className={headerClasses} ref={headerRef}>
        <div className="header__top" ref={headerTopRef}>
          <div className="wrapper">
            {
              /*
              add 'new-year' class to wrapper
*/
              // <div className="new-year__lights">
              // <object type="image/svg+xml" data={lights} />
              // </div>
              /* add defenders-day class to header__top for 23 february */
              /* add march-8 class to header__top for 8 march */
              /* add may-1 class to header__top for 1 may */
              /* add may-9 class to header__top for 9 may */
            }

            <div className={headerInnerClasses}>

              {!isMobile && <div style={{ marginRight: 20 }}><Logo logo={logo} /></div>}

              <BottomMenu menu={bottomMenu} servicesMenu={servicesMenu} showModal={showModal} />

              {isMobile && <Logo logo={logo} />}

              <div className="header__top-rightblock">

                {!isTablet &&
                  <>
                    <div className="header__nottifications" onClick={() => { setNottificationsIsOpen(true) }}>
                      {count > 0 && <span className="header__nottifications-status">{count > 99 ? '99+' : count}</span>}
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.22156 9.77844C6.22156 9.0196 6.37102 8.2682 6.66142 7.56713C6.95181 6.86605 7.37745 6.22904 7.91403 5.69247C8.4506 5.15589 9.08761 4.73025 9.78869 4.43986C10.4898 4.14946 11.2412 4 12 4C12.7588 4 13.5102 4.14946 14.2113 4.43986C14.9124 4.73025 15.5494 5.15589 16.086 5.69247C16.6226 6.22904 17.0482 6.86605 17.3386 7.56713C17.629 8.2682 17.7784 9.0196 17.7784 9.77844V9.77844C17.7784 12.6596 18.3826 14.3336 18.9137 15.2511C18.9702 15.3488 18.9999 15.4597 19 15.5725C19.0001 15.6854 18.9705 15.7963 18.9142 15.8941C18.8579 15.992 18.7768 16.0733 18.6792 16.1299C18.5816 16.1865 18.4708 16.2165 18.3579 16.2168H5.64211C5.52924 16.2165 5.41843 16.1865 5.32079 16.1299C5.22316 16.0733 5.14213 15.992 5.08582 15.8941C5.02952 15.7963 4.99992 15.6854 5 15.5725C5.00008 15.4597 5.02982 15.3488 5.08626 15.2511C5.61742 14.3336 6.22156 12.6596 6.22156 9.77844Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.42462 16.2168V16.8606C9.42462 17.5437 9.69595 18.1987 10.1789 18.6817C10.6619 19.1647 11.3169 19.436 12 19.436C12.683 19.436 13.338 19.1647 13.821 18.6817C14.304 18.1987 14.5753 17.5437 14.5753 16.8606V16.2168" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>

                    <Link to={'/favorites'} className="header__favorites">
                      {favoritesCount > 0 && <span className="header__favorites-count">{favoritesCount > 99 ? '99+' : favoritesCount}</span>}
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.2192 18.9806C12.2192 18.9806 4.21924 14.5099 4.21924 9.08126C4.21924 8.12158 4.55243 7.19153 5.16214 6.44937C5.77184 5.7072 6.62038 5.19876 7.5634 5.01055C8.50641 4.82234 9.48564 4.96599 10.3345 5.41706C11.1833 5.86812 11.8494 6.59874 12.2192 7.4846C12.5891 6.59874 13.2551 5.86812 14.104 5.41706C14.9528 4.96599 15.9321 4.82234 16.8751 5.01055C17.8181 5.19876 18.6666 5.7072 19.2763 6.44937C19.886 7.19153 20.2192 8.12158 20.2192 9.08126C20.2192 14.5099 12.2192 18.9806 12.2192 18.9806Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Link>
                  </>
                }
                {!isClientMode && (
                  <PhoneLink
                    phone={
                      isAssignments ? '8 (812) 242-80-69' : getPhone()
                    }
                    managerPhone={manager?.phone}
                    managerTelegram={manager?.telegram}
                  />
                )}

                {!isLaptop && (
                  <button
                    ref={togglerNode}
                    className="dropdown__btn button header__top-profile-btn sidemenu-btn"
                    onClick={(e) => {
                      setSideMenuIsOpen(!sideMenuIsOpen);
                    }}
                  ></button>
                )}
              </div>

              {isMobile && (
                <div
                  onClick={toggleSearch}
                  className="header__mobile-search-id"
                >
                  <label className="input input_type_search_round input_width_available">
                    <div className="input__control" />
                    <svg className="icon icon_search">
                      <use xlinkHref="#search"></use>
                    </svg>
                  </label>
                </div>
              )}

              <Burger show={() => setSideMenuIsOpen(true)} open={sideMenuIsOpen} />

            </div>
          </div>
        </div>
        {
          !isPublic && (
            <Menu isOpen={sideMenuIsOpen}
              setOpen={setSideMenuIsOpen}
              togglerNode={togglerNode}
              servicesMenu={servicesMenu}
            />)
        }
      </header>
      <div className="header__bottom" ref={headerBottomRef}>
          <div className="wrapper">
            <div className="header__bottom-inner">
              <TopMenu menu={topMenu} showModal={showModal} />

              <div className="header__bottom-controls">
                <a className="header__bottom-controls_stock-link" href="https://bonus.panpartner.ru/" target="_blank" rel="noreferrer">Бонусы</a>
                <SearchById />
              </div>
            </div>
          </div>
        </div>
      <div className={mobileSearchClasses}>
        <div className="mobile-filters__title">
          <svg className="icon icon_arrow_up" onClick={toggleSearch}>
            <use xlinkHref="#arrow_up"></use>
          </svg>
          <h3>Поиск по ID</h3>
        </div>

        <SearchById mobile={true} align="left" onClick={toggleSearch} />
      </div>
    </>
  );
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderRef: (ref) =>
    dispatch({ type: 'SET_REF', payload: { header: ref } }),
});

const mapStateToProps = (state) => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));