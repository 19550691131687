export const themeColors = {
  "yellow": "#ffd336",
  "gray": "#a9a9b8",
  "green": "#2cba2e",
  "red": "#dd453b",
  "blue": "#62b8e7",
  "asphalt": "#5E617F",
  "purple": "#9377B7",
  "burgundy": "#781B1B",
  "nightsky": "#4545C2",
  "turquoise": "#5EB7A7",
}