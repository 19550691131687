import { defaultExclude, defaultFilter, defaultFullFilter } from "../utils/defaultFilters";
import { handleFavorite } from './handlers';

const initialState = {
  isFetching: false,
  error: false,
  data: [],
  top: [],
  subways: [],
  filterData: {},
  filter: defaultFullFilter,
  countToShow: 0,
  mapItems: [],

};

const commercial = (state = initialState, action) => {
  const { filter } = action;
  switch (action.type) {
    case "COMMERCIAL_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "COMMERCIAL_SUCCESS":

      let items = [];
      if (action.data) {
        action.data.map((item) => {
          if (item.coordinates) {
            items.push(item);
          }
          return item;
        });
      }
      let tempState = {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        top: action.top || state.top,
        filterData: action.filterData || state.filterData,
        subways: action.subways || state.subways,
        mapItems: items,
        isActive: action.isActive,
        agent: action.agent,
        isShowFilter: action.isShowFilter,
        savedFilter: action.savedFilter,
      };
      if (action.isGetFilter) {
        tempState.countToShow = action.count
      }
      return tempState
    case 'COMMERCIAL_FILTER_SUCCESS':
      return { ...state, filterData: action.filterData.filter };
    case "COMMERCIAL_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "COMMERCIAL_RESET":
      return { ...initialState };
    case "COMMERCIAL_SET_FILTER":
      let tmpFilter = {
        filter: { ...state.filter.filter, ...filter.filter },
        exclude: { ...state.filter.exclude, ...filter.exclude },
      };
      return { ...state, filter: tmpFilter };
    case "COMMERCIAL_REMOVE":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
      };

    case "COMMERCIAL_CLEAR_FILTER":
      return { ...state, filter: defaultFullFilter };
    case "COMMERCIAL_CLEAR_FILTER_ONLY":
      const tmpFilterOnly = {
        filter: { ...state.filter.filter, ...defaultFilter },
        exclude: { ...state.filter.exclude },
      };
      return { ...state, filter: tmpFilterOnly };
    case "COMMERCIAL_CLEAR_EXCLUDE_ONLY":
      const tmpExcludeOnly = {
        filter: { ...state.filter.filter },
        exclude: { ...state.filter.exclude, ...defaultExclude },
      };
      return { ...state, filter: tmpExcludeOnly };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'commercials') {
        return { ...state, mapItems: handleFavorite(state.mapItems, action) }
      }
      return state;
    case "COMMERCIAL_SET_COUNT":
      return { ...state, countToShow: action.count };
    default:
      return state;
  }
};

export default commercial;
