import { useMediaQuery } from 'beautiful-react-hooks';
import _filter from 'lodash/filter';
import without from 'lodash/without';
import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import useMedia from '../../hooks/useMedia';
import {
  filterToParams,
  isEmptyFilterOrExclude
} from '../../utils';
import { defaultFullFilterSecondaries } from '../../utils/defaultFilters';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Dragable from '../FilterRealty/Dragable';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilter from '../FilterRealty/SquareFilter';
import SquareFilterOnly from '../FilterRealty/SquareFilterOnly';
import SaveFilter from '../SavedFilters/SendFilter';
import AttentionBlock from "./AttentionBlock";

var inDebounce;
const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    getCount,
    clearFilterOnly,
    clearExcludeOnly,
    city,
    fetching,
    info,
    countToShow,
  } = props;
  const isMobile = useMedia("isMobile")
  const handleFilter = (data, identity) => {
    let tempValue = {
      filter: {
        [identity]: data.filter ? data.filter : filter.filter[identity],
      },
      exclude: {
        [identity]: data.exclude ? data.exclude : filter.exclude[identity],
      },
    };
    setFilter(tempValue);
    let tmpFilter = {
      filter: { ...filter.filter, ...tempValue.filter },
      exclude: { ...filter.exclude, ...tempValue.exclude },
    };
    clearTimeout(inDebounce);
    let params = filterToParams(tmpFilter);
    inDebounce = setTimeout(() => {
      getCount(params);
    }, 300);
  };

  const smallScreen = useMediaQuery('(max-width: 1280px)');
  const middleScreen = useMediaQuery('(min-width: 1280px) and (max-width: 1550px)');
  const handleClearFilter = (e) => {
    e.preventDefault();
    getCount(filterToParams(defaultFullFilterSecondaries));
    clearFilter();
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    let params = filterToParams(filter);
    onSubmit(params);
  };

  const handleToggle = (e) => {
    let tempFilter = [...filter.filter.status];
    if (e.target.checked) {
      tempFilter.push('3');
    } else {
      tempFilter = without(tempFilter, '3');
    }
    setFilter({
      filter: { status: tempFilter },
    });
    let params = filterToParams({
      ...filter,
      ...{ filter: { status: tempFilter } },
    });
    getCount(params);
  };

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSaveFilterModal} classes="modal_filter">
        <SaveFilter
          filter={filter}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
          filterType={"secondary"}
        />
      </Modal>
    ),
    [filter, filterData],
  );

  const [saveMobileFilterModal, hideMobileSaveFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideMobileSaveFilterModal} title="Сохранить фильтр">
        <SaveFilter
          filter={filter}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
          filterType={"secondary"}
        />
      </MobileModal>
    ),
    [filter, filterData],
  );

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    if(isMobile) document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  const [count, setCount] = useState(0);

  const countFilters = (filtersCount) => {
    setCount(filtersCount)
  }

  const filters = (
    <form className="form">
      <div className="index-search__filter-body">
        <div className="index-search__filter-top">
          <div className="form__row">
            {/* <div className="form__item form__item_2x form__item_main">
                  <div className="index-search__search">
                          <label className="input index-search__search-input" htmlFor="index-search">
                            <input className="input__control" type="text" id="index-search" placeholder="Поиск по 1 234 вариантам жилья" autocomplete="off">
                                    <svg className="icon icon_search">
                                      <use xlinkHref="#search"></use>
                                    </svg>
                            <div className="index-search__tooltip">
                              <button className="button button_view_outline-small">Исключить</button>
                            </div>
                          </label>
                            <div className="info">
                                      <svg className="icon icon_info">
                                        <use xlinkHref="#info"></use>
                                      </svg>
                              <div className="info__popup">
                                <p>Начинайте вводить параметры фильтра и&nbsp;выбирайте из&nbsp;предложенного списка. Вы&nbsp;также можете любой запрос добавить в&nbsp;исключения</p>
                              </div>
                            </div>
                  </div>
                </div> */}
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, 'objectType')
              }
              isExcludable={false}
              isAutocomplete={false}
              items={
                filterData.objectType
                  ? Object.values(filterData.objectType)
                  : []
              }
              filterValues={filter.filter.objectType}
              excludeValues={filter.exclude.objectType}
              data={{
                title: 'Тип объекта',
                autocompleteTitle: '',
                identity: 'objectType',
              }}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, 'districts')
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.districts
                  ? Object.values(filterData.districts)
                  : []
              }
              filterValues={filter.filter.districts}
              excludeValues={filter.exclude.districts}
              data={{
                title: 'Район',
                autocompleteTitle: 'Введите название района',
                identity: 'districts',
              }}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, 'subways')
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.subways
                  ? Object.values(filterData.subways)
                  : []
              }
              filterValues={filter.filter.subways}
              excludeValues={filter.exclude.subways}
              data={{
                title: 'Метро',
                autocompleteTitle: 'Введите название станции',
                identity: 'subways',
              }}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <PriceFilter
              filter={
                filter.filter.prices
                  ? filter.filter.prices
                  : { min: "", max: "" }
              }
              exclude={
                filter.exclude.prices
                  ? filter.exclude.prices
                  : { min: "", max: "" }
              }
              items={
                filterData.prices ? filterData.prices.items : []
              }
              data={{
                title: "Цена",
                minPlaceholder: "от ",
                maxPlaceholder: "до ",
                defaultValues: filterData.prices
                  ? filterData.prices.range
                  : 0,
              }}
              right={smallScreen}
              onChange={(a) => handleFilter(a, "prices")}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <FloorsFilter
              items={
                filterData.floors?.items ? filterData.floors.items : []
              }
              filter={filter.filter.floors}
              data={{
                title: 'Этаж',
                minPlaceholder: 'от ',
                maxPlaceholder: 'до ',
                defaultValues: filterData.floors
                  ? filterData.floors.range
                  : null,
              }}
              onChange={(a) => handleFilter(a, 'floors')}
              secondData={{
                title: filterData.isNoFirstFloor
                  ? filterData.isNoFirstFloor[0].title
                  : "Не первый",
                value: filter.filter.isNoFirstFloor.length
                  ? filter.filter.isNoFirstFloor[0].id
                  : "N",
              }}
              secondChange={(a) => {
                let tmp = a === 'Y' ? [{ id: a }] : [];
                handleFilter(
                  {
                    filter: tmp,
                    exclude: [
                      ..._filter(
                        filter.exclude.isNoFirstFloor,
                        (val) => val.id != a,
                      ),
                    ],
                  },
                  'isNoFirstFloor',
                );
              }}
              ident="floor"
              right={false}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <SquareFilterOnly
              autoPosition
              filter={filter.filter.squareTotal ?? { min: "", max: "" }}
              data={{
                title: 'S общая',
                minPlaceholder: 'от ',
                maxPlaceholder: 'до ',
                defaultValues: filterData.squareTotal?.range ?? null
              }}
              onChange={(a) => handleFilter(a, 'squareTotal')}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              fullFilter={filter}
              identity={"squareTotal"}
              items={filterData.squareTotal?.items}
              isMobile={isOpen && isMobile}
              showPlaceholderOnLists
            />
          </div>
        </div>
        <div className="index-search__filter-main">
          <div className="form__row">
            <SquareFilterOnly
              autoPosition
              filter={filter.filter.squareKitchen ?? { min: "", max: "" }}
              data={{
                title: 'S кухни',
                minPlaceholder: 'от ',
                maxPlaceholder: 'до ',
                defaultValues: filterData.squareKitchen?.range ?? null
              }}
              onChange={(a) => handleFilter(a, 'squareKitchen')}
              clearFilterOnly={clearFilterOnly}
              clearExcludeOnly={clearExcludeOnly}
              fullFilter={filter}
              identity={"squareKitchen"}
              items={filterData.squareKitchen?.items}
              isMobile={isOpen && isMobile}
              showPlaceholderOnLists
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a }, "rooms")
              }
              isExcludable={false}
              isAutocomplete={false}
              items={
                filterData.rooms
                  ? Object.values(filterData.rooms)
                  : []
              }
              filterValues={filter.filter.rooms}
              excludeValues={filter.exclude.rooms}
              data={{
                title: "К-во комнат",
                autocompleteTitle: "",
                identity: "rooms",
              }}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <SelectFilter
              onChange={(a, b) => handleFilter({ filter: a }, 'balcony')}

              isExcludable={false}
              isAutocomplete={false}
              items={
                filterData.balcony
                  ? Object.values(filterData.balcony)
                  : []
              }
              filterValues={filter.filter.balcony}
              excludeValues={[]}
              data={{
                title: "Балкон",
                autocompleteTitle: "",
                identity: "balcony",
              }}
              disabledItems={
                {
                  w: ["b", "l", "t"],
                  b: ["w"],
                  l: ["w"],
                  t: ["w"],
                }
              }

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, 'builders')
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.builders
                  ? Object.values(filterData.builders)
                  : []
              }
              filterValues={filter.filter.builders}
              excludeValues={filter.exclude.builders}
              data={{
                title: 'Застройщик',
                autocompleteTitle: 'Введите название застройщика',
                identity: 'builders',
              }}
              right={smallScreen}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
            <SelectFilter
              onChange={(a, b) =>
                handleFilter({ filter: a, exclude: b }, 'blocks')
              }
              isExcludable={true}
              isAutocomplete={true}
              items={
                filterData.blocks
                  ? Object.values(filterData.blocks)
                  : []
              }
              filterValues={filter.filter.blocks}
              excludeValues={filter.exclude.blocks}
              data={{
                title: 'Название ЖК',
                autocompleteTitle: 'Введите название ЖК',
                identity: 'blocks',
              }}

    					isMobile={isOpen && isMobile}
    					clearFilters={clearFilter}
            />
          </div>
        </div>
      </div>
      {filter && filterData && !isEmptyFilterOrExclude(filter, null, defaultFullFilterSecondaries) && (
        <Dragable
          filter={filter.filter}
          fullFilter={filter}
          exclude={filter.exclude}
          filterData={filterData}
          handleFilter={handleFilter}
          clearExcludeOnly={clearExcludeOnly}
          clearFilterOnly={clearFilterOnly}
          getCount={(filterOrExclude) =>
            getCount(filterToParams(filterOrExclude))
          }
          enableRemoving={true}
          defFullFilter={defaultFullFilterSecondaries}
          countFilters={countFilters}
        />
      )}
      <div className="index-search__filter-controls">
        {/* <div className="index-search__filter-control index-search__filter-voice">
          <button className="button button_view_round">
            <svg className="icon icon_mic">
              <use xlinkHref="#mic"></use>
            </svg>
          </button>
          <span>Голосовой помощник</span>
        </div> */}
        <div className="index-search__filter-control index-search__filter-options">
          <button
            className="button button_view_outline"
            onClick={(e) => {
              e.preventDefault();
              isMobile ? saveMobileFilterModal() : saveFilterModal();
            }}
          >
            Сохранить фильтр
          </button>
          {/* <button className="button button_type_settings">
              <svg className="icon icon_settings">
                <use xlinkHref="#settings"></use>
              </svg>
            </button> */}
        </div>
        <button
          onClick={handleClearFilter}
          className="button button_type_clear visible_mobile"
        >
          <svg className="icon icon_x">
            <use xlinkHref="#x"></use>
          </svg>
          <span>Очистить фильтр</span>
        </button>
        <a
          className="index-search__filter-control index-search__filter-submit"
          href="#filter-results"
        >
          {countToShow ? (
            <button
              className="button button_view_default"
              type="submit"
              onClick={handleFilterClick}
              disabled={fetching ? true : false}
            >
              Найдено{` ${countToShow} объектов`}
            </button>
          ) : (
              <button
                className="button button_view_default"
                type="submit"
                disabled
              >
                Ничего не найдено
              </button>
            )}
        </a>
        <div className="index-search__filter-control index-search__filter-options">
          <button
            onClick={handleClearFilter}
            className="button button_type_clear visible_note"
          >
            <svg className="icon icon_x">
              <use xlinkHref="#x"></use>
            </svg>
            <span>Очистить фильтр</span>
          </button>
        </div>
      </div>
      <div className="index-search__filter-controls-m">
        <div className="index-search__filter-controls-col">
          {/* <div className="index-search__filter-control index-search__filter-voice">
            <button className="button button_view_round">
              <svg className="icon icon_mic">
                <use xlinkHref="#mic"></use>
              </svg>
            </button>
          </div> */}
          {!isMobile &&
            <button
              onClick={handleClearFilter}
              className="button button_type_clear visible_mobile"
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
          }
        </div>
        {isMobile &&
          <div className="index-search__filter-save">
            <button
              className="button button_view_outline"
              onClick={(e) => {
                e.preventDefault();
                isMobile ? saveMobileFilterModal() : saveFilterModal();
              }}
              style={{ marginBottom: '80px' }}
            >
              Сохранить фильтр
            </button>
            {/* <button className="button button_type_settings">
              <svg className="icon icon_settings">
                <use xlinkHref="#settings"></use>
              </svg>
            </button> */}
          </div>
        }
        <div className="index-search__filter-controls-col">
          <div className="index-search__filter-control index-search__filter-submit">
            <div className="mobile-filters__show-all" style={{ margin: '0 10px' }}>
              {countToShow ? (
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    handleFilterClick(e)
                    toggleFilters()
                  }}
                  disabled={fetching ? true : false}
                >
                  Найдено{` ${countToShow} объектов`}
                </button>
              ) : (
                  <button
                    className="button button_view_default"
                    type="submit"
                    disabled
                  >
                    Ничего не найдено
                  </button>
                )}
            </div>
          </div>
        </div>
        <div className="index-search__filter-controls-col">
          <div className="index-search__filter-control index-search__filter-options">
            <button
              onClick={handleClearFilter}
              className="button button_type_clear visible_note"
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
            {!isMobile &&
              <div className="index-search__filter-save">
                <button
                  className="button button_view_outline"
                  onClick={(e) => {
                    e.preventDefault();
                    isMobile ? saveMobileFilterModal() : saveFilterModal();
                  }}
                >
                  Сохранить фильтр
                </button>
                {/* <button className="button button_type_settings">
                  <svg className="icon icon_settings">
                    <use xlinkHref="#settings"></use>
                  </svg>
                </button> */}
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  )

  return (
    <section className="section index-search index-search_dark">
      <div className="wrapper">
        <div className="section__grid">
          <div className="section__grid-col index-search__header">
            <h1 className="h2" style={{ whiteSpace: 'pre-wrap' }}>
              Вторичная недвижимость
            </h1>
            {<AttentionBlock openBuyModal={()=>window.open("/sell/secondary", "_blank")}  isForIpad={true} />}
          </div>
          <div className="section__grid-col index-search__filter for-secondary">

          {isMobile ?
            <button className="button-mobile for-main" onClick={toggleFilters}>
              Фильтры
              {count > 0 && ` (${count})`}
            </button> :
            filters
          }

          {isOpen && isMobile &&
    				<div className="mobile-filters for-clients">
    					<div className="mobile-filters__title">
    						<svg
    							className="icon icon_arrow_up"
    							onClick={() => {
    								// props.onClick()
    								toggleFilters()
    							}}
    						>
    							<use xlinkHref="#arrow_up"></use>
    						</svg>
    						<h3>Все фильтры</h3>
    					</div>
    					<div className="mobile-filters__content">
                {filters}
              </div>
            </div>
          }
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  city: ownProps.city ? ownProps.city : state.user.info.city,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearFilter: () => dispatch({ type: 'SECONDARIES_CLEAR_FILTER' }),
    clearFilterOnly: () => dispatch({ type: 'SECONDARIES_CLEAR_FILTER_ONLY' }),
    clearExcludeOnly: () => dispatch({ type: 'SECONDARIES_CLEAR_EXCLUDE_ONLY' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
