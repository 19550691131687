import React, { memo, useState } from "react";
import './style.scss'
import Button from "../core/Button";
import useMedia from "../../hooks/useMedia";
import { useLocation } from "react-router-dom";

const CookiesUp = memo(() => {
  const location = useLocation()

  const [isVisible, setIsVisible] = useState(!localStorage.getItem("cookiesAccepted") && !location.pathname.includes("/map-frame"));

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("cookiesAccepted", true)
  };

  const isTablet = useMedia('isTablet');

  return (
    <div className={`cookies-up ${isVisible ? "visible" : "hidden"}`}>
      <div className={`cookies-up__content ${isTablet ? 'cookies-up__content_mobile' : ''}`}>
        <div className="cookies-up__text">
          <div className="cookies-up__text-top">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11.9063" cy="12" r="8.5" transform="rotate(-180 11.9063 12)" stroke="#EF372E" />
              <line x1="11.8628" y1="16.5" x2="11.8628" y2="10.5" stroke="#EF372E" stroke-linecap="round" />
              <circle cx="11.8628" cy="8.5" r="0.5" transform="rotate(-180 11.8628 8.5)" fill="#EF372E" />
            </svg>
            <p>Мы используем файлы cookie для улучшения работы портала</p>
          </div>
          <p className="cookies-up__text-bottom">
            Используя сайт или кликая на кнопку «Хорошо, закрыть», Вы соглашаетесь с нашей политикой использования cookie. <a href="/cookies_policy" class="link" target="_blank">Узнать подробнее...</a>
          </p>
        </div>
        <Button onClick={handleClose}>Хорошо, закрыть</Button>
      </div>
    </div>
  )
})

export default CookiesUp;
