import {memo, useState} from 'react';
import useMedia from '../../../hooks/useMedia';
import Loader from '../../SkeletonLoader';
import CardSkeleton from './CardSkeleton';

const CardsList = memo(
  ({
    items,
    isLoading,
    limit,
    itemsRef,
    cardComponent: Card,
    getLink,
    listClass,
     withoutBtnsList,
    ...rest
  }) => {

    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const [isFetchingQuickCard, setIsFetchingQuickCard] = useState(true)
    const showQuickCard = (index) => {
      setActiveCardIndex((prevIndex) => (prevIndex === index ? null : index));
      setIsFetchingQuickCard(true)
    };

    const isLaptop = useMedia('isLaptop');
    if (!items) return null;
    if (isLoading)
      return (
        <div className="sf__cards-list" ref={itemsRef}>
          {Array(limit)
            .fill(1)
            .map((item, i) => (
              <CardSkeleton key={i} isLaptop={isLaptop} />
            ))}
        </div>
      );
    return (
      <div className={listClass ?? 'sf__cards-list'} ref={itemsRef}>
        {items.map((item, i) => (
          <>
            <Card
              {...item}
              link={getLink(item)}
              {...rest}
              key={`${item.id}-${i}`}
              index={i}
              withoutBtnsList={withoutBtnsList}
              houseType={item?.typeMessage ? item?.typeMessage : false}
              isActive={activeCardIndex === i}
              showQuickCard={() => showQuickCard(i)}
              isFetchingQuickCard={isFetchingQuickCard}
              setIsFetchingQuickCard={setIsFetchingQuickCard}
            />
          </>
        ))}
      </div>
    );
  },
);

export default CardsList;
