import { Table as AntdTable } from 'antd';
import 'antd/es/table/style/index.css';
import React, { useEffect, useState } from 'react';
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import LoadMoreSpinner from '../../LoadMoreSpinner';
import './index.scss';

export const ShowMore = (props) => {
	const { onClick, loading, innerRef, customMoreTitle } = props;
	return (
		<div className="table__show-more" ref={innerRef}>
			{loading ? (
				<LoadMoreSpinner loading={true} />
			) : (
				<div className="table__show-more_btn" onClick={() => onClick()}>
					{customMoreTitle || `Показать еще${' '}`}
					<svg className="icon icon_arrow-bottom">
						<use xlinkHref="#arrow_bottom"></use>
					</svg>
				</div>
			)}
		</div>
	);
};

const Table = (props) => {
	const {
		dataSource: items,
		customPagination,
		autoLoadMore,
		pageCount = 10,
		customMoreTitle,
		isLoadAll,
		firstPageCount,
		...rest
	} = props;

	const [page, setPage] = useState(0);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (!customPagination) return setData(items)
		load(firstPageCount || pageCount)
	}, [items]);

	const load = (end, start = 0) => {
		setData(items.slice(start, end))
		setLoading(false)
		setHasNextPage(items.length > end)
	}

	const loadMoreItems = () => {
		if (isLoadAll) {
			setData(items)
			setHasNextPage(false)
			return
		}
		const nextLen = data.length + ((page + 1) * pageCount)
		load(nextLen)
		setPage(prev => prev + 1)
	}

	const loadWithTimeout = () => {
		setLoading(true)
		setTimeout(loadMoreItems, 200)
	}

	const infiniteRef = useInfiniteScroll({
		loading: loading,
		hasNextPage,
		threshold: 50,
		onLoadMore: autoLoadMore ? loadWithTimeout : () => { },
		scrollContainer: "window",
	});

	return (
		<>
			<AntdTable  dataSource={data} rowClassName={(record, index) => ((index + 1) % 2 === 0 ? "ant-table-second-color" : "")} {...rest} />
			{customPagination && hasNextPage && (
				<ShowMore customMoreTitle={customMoreTitle} onClick={loadWithTimeout} loading={loading} innerRef={infiniteRef} />
			)}
		</>
	);
};

export default Table;
