export const steps = [
	{
		number: '01',
		name: 'Выбор квартиры',
		pill: '4 клика',
		text:
			'Понадобится, чтобы подобрать клиенту квартиру из сотни проверенных жилых комплексов  на нашем портале',
	},
	{
		number: '02',
		name: 'Бронирование',
		pill: 'До 90 дней',
		text:
			'Забронируйте квартиру в новостройке и зафиксируйте цену на нее на срок до 90 дней ',
	},
	{
		number: '03',
		name: 'Оценка стоимости',
		text:
			'Сделайте оценку рыночной стоимости старой квартиры клиента,  персональный менеджер в ПАН бесплатно и быстро проверит и, при необходимости, подкорректирует цену, чтобы вы могли продать объект быстрее',
	},
	{
		number: '04',
		name: 'Продажа старой квартиры',
		pill: '70 площадок',
		text:
			'Получите бесплатный доступ к CRM и рекламируйте объект клиента за счет ПАН. Наш рекламный менеджер выставит объект на 70 площадках, вам останется только принимать звонки с личного номера и продавать',
	},
	{
		number: '05',
		name: 'Заключение договора',
		text:
			'Клиент получает деньги с продажи старой квартиры и оплачивает стоимость новой, вы заключаете договор',
	},
	{
		number: '06',
		name: 'Выплата комиссии',
		pill: 'Комиссия до 2,5%',
		text: 'После заключения договора вы получаете комиссию со сделки.',
	},
];

export const benefits = [
	{
		name: 'Гарантия сделки',
		advantage:
			'Фиксируя клиента через ПАН,  вы точно доведете его до сделки и получите свою комиссию',
		disadvantage:
			'Клиент узнает, что можно обменять старую квартиру на новую от застройщика, и с высокой вероятностью подпишет договор напрямую с ним, чтобы не платить комиссию со сделки вам',
	},
	{
		name: 'Скидка клиенту',
		advantage:
			'Вы можете гарантировать клиенту 0% комиссии при покупке новостройки и до 90% скидки с комиссии за продажу, ведь в сотрудничестве с нами вашу работу оплачивает не клиент, а застройщик',
		disadvantage:
			'У вас нет прямого договора с застройщиком, вы не можете предложить клиенту по-настоящему выгодные условия сотрудничества, и теряете его',
	},
	{
		name: 'Фиксация стоимости',
		advantage:
			'С нами вы фиксируете для вашего клиента цену на новостройку на 90 дней, а мы держим руку на пульсе, пока трехсторонняя сделка не состоится',
		disadvantage:
			'Вы не можете обеспечить клиенту фиксацию стоимости в период продажи старой квартиры, в условиях быстро растущих цен вы теряете конкурентные преимущества',
	},
	{
		name: 'Бронирование объекта',
		advantage:
			'Вы можете забронировать для клиента понравившуюся новостройку на 90 дней, пока ищите клиентов на старую квартиру, и продаете',
		disadvantage:
			'Вы не можете гарантировать клиенту, что понравившуюся ему квартиру не купят',
	},
	{
		name: 'Преимущества, поддержка и гарантии',
		advantage:
			'Вы получаете преимущества и инфраструктуру стабильного агентства: юридическую поддержку клиентов в едином окне, рекламные инструменты и грамотных менеджеров, офис в центре города и переговорные для встреч с клиентами. Доверие клиентов растет, при этом ваши клиенты остаются вашими',
		disadvantage:
			'У вас нет ресурсов крупного агентства, нет крепких взаимоотношений с застройщиками, продажа квартиры может затянуться, а сделка — сорваться',
	},
	{
		name: 'Оптимизация работы',
		advantage:
			'Вы экономите время, нервы и деньги. Себе и клиентам. Да-да, экономия времени, нервов и денег — стратегически необходимое условие качественного сервиса, как в спокойное, так и в кризисное время. Особенно в кризисное.',
		disadvantage:
			'Вы разрываетесь между задачами и клиентами, проводите десятки встреч, которые не приводят к желаемой цели. Клиенты нервничают из-за недоверия, срывов сроков и потери денег.',
	},
];
