import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import TinySlider from 'tiny-slider-react';
import { becomePartners, clearTradeIn, fetchTradeIn } from '../actions/tradeIn';
import { getUrl } from '../api';
import Accordeon from '../components/core/Accordeon';
import Modal from '../components/core/Modal';
import TradeInPartner from '../components/forms/TradeInPartner';
import { benefits, steps } from '../components/TradeIn/data';
import useDocTitle from '../hooks/useDocTitle';
import useMedia from '../hooks/useMedia';
import { media, scrollToRef } from '../utils';





const TableItem = (props) => {
  const [open, toggle] = useState(false);

  const checkString = (string) => {
    return string.length == 0 ? `-` : string;
  }

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

//   <div className="motivation__table_object">
//
//   </div>
//   <div className="motivation__table_fee">
//     % за передачу клиента
//   </div>
// </div>
// <div className="custom-table__th motivation__table_conditions is-hidden-sm">
//   Срок оплаты
// </div>
// <div className="custom-table__th motivation__table_manager is-hidden-sm">
//   Примечание
// </div>
  if (isMobile) {
    const ITEMS_TO_SHOW = 3;
    return (
      <div className={`custom-table__item motivation__for-trade-in${props.id > ITEMS_TO_SHOW ? ` custom-table__item_hidden` : ``}`}>
        <div className="custom-table__tr">
          <div className="custom-table__td">
            <div className="motivation__builder">
              <div className="motivation__builder-name">{props.builderName}</div>
              {props.builderLogo && (
                <div className="motivation__builder-logo">
                  <img src={getUrl(props.builderLogo.src)} alt="" />
                </div>
              )}
            </div>
          </div>

        </div>
        <div className="custom-table__tr">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">% субагента, продает сам</span>
          </div>
          <div className="custom-table__td">
            <div className="custom-table__label label-item label_purple">
              {props.subAgentSale}
            </div>
          </div>
        </div>
        <div className="custom-table__tr">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">% за передачу клиента</span>
          </div>
          <div className="custom-table__td">
            <div className="custom-table__label label-item label_purple">
              {props.transferClient}
            </div>
          </div>
        </div>





        <div className="custom-table__tr custom-table__tr_reverse-width">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">Условия Trade-In застройщика</span>
          </div>
          <div className="custom-table__td">
            <div>
              {checkString(props.termsDeveloperTradeIn)}
            </div>
          </div>
        </div>

        <div className="custom-table__tr custom-table__tr_reverse-width">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">Застройщик подписывает с клиентом</span>
          </div>
          <div className="custom-table__td">
            <div>
              {checkString(props.developerSignsWithClient)}
            </div>
          </div>
        </div>

        <div className="custom-table__tr custom-table__tr_reverse-width">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">Продажа / покупка вторички для клиента</span>
          </div>
          <div className="custom-table__td">
            <div>
              {checkString(props.salePurchaseSecondaryClient)}
            </div>
          </div>
        </div>




        <div className="custom-table__tr custom-table__tr_reverse-width">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">Примечание</span>
          </div>
          <div className="custom-table__td">
            <div>
              {props.note}
            </div>
          </div>
        </div>


        <div className="custom-table__tr custom-table__tr_reverse-width">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">Срок оплаты</span>
          </div>
          <div className="custom-table__td">
            <div>
              {props.paymentPeriod}
            </div>
          </div>
        </div>

        <div className="custom-table__tr custom-table__tr_reverse-width">
          <div className="custom-table__td">
            <span className="custom-table__mobile-header">Вознаграждение за передачу клиента по вторичке</span>
          </div>
          <div className="custom-table__td">
            <div>
              {checkString(props.awardForTransferClientSecondary)}
            </div>
          </div>
        </div>


      </div>
    )
  }

  return (
    <div className="custom-table__item motivation__for-trade-in">
      <div className="custom-table__tr">

        {/* Застройщик */}
        <div className="custom-table__td motivation__table_builder">
          <div
            className={
              'motivation__table-control custom-table__drop-btn sm-show' +
              (open ? ' is-active' : '')
            }
            onClick={() => toggle(!open)}
          >
            <button className="button button_view_round-control"></button>
          </div>
          <div className="motivation__builder">
            <div className="motivation__builder-name">{props.builderName}</div>
            {props.builderLogo && (
              <div className="motivation__builder-logo">
                <img src={getUrl(props.builderLogo.src)} alt="" />
              </div>
            )}
          </div>
        </div>

        {/* % субагента, продает сам */}
        {/* % за передачу клиента */}

        <div className="motivation__table_objects">
          <div className="motivation__objects">
            <div className="motivation__objects-header">
              <div className="motivation__object-header motivation__table_object">
                <div className="custom-table__label label-item label_purple">
                  {props.subAgentSale}
                </div>
              </div>
              <div className="motivation__fee-header motivation__table_fee">
                <div className="custom-table__label label-item label_purple">
                  {props.transferClient}
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Условия Trade-In застройщика - termsDeveloperTradeIn */}
        <div className="custom-table__td motivation__table_terms is-hidden-sm">
          <p>{checkString(props.termsDeveloperTradeIn)}</p>
        </div>


        {/* Застройщик подписывает с клиентом */}
        <div className="custom-table__td motivation__table_signs is-hidden-sm">
          <p>{checkString(props.developerSignsWithClient)}</p>
        </div>


        {/* Продажа / покупка вторички для клиента */}
        <div className="custom-table__td motivation__table_purchase is-hidden-sm">
          <p>{checkString(props.salePurchaseSecondaryClient)}</p>
        </div>

        {/* Примечание */}

        <div className="custom-table__td motivation__table_manager is-hidden-sm">
          <p>{props.note}</p>
        </div>

        {/* Срок оплаты */}
        <div className="custom-table__td motivation__table_conditions is-hidden-sm">
          <div className="motivation__table-conditions label_with-icon label_with-icon_prefix">
            <p>{props.paymentPeriod}</p>
          </div>
        </div>

        {/* Вознаграждение за передачу клиента по вторичке */}
        <div className="custom-table__td motivation__table_award is-hidden-sm">
          <p>{checkString(props.awardForTransferClientSecondary)}</p>
        </div>


        {open && (
          <div
            className="custom-table__dropdown"
            style={{ display: 'block', flexBasis: '100%' }}
          >
            <div className="motivation__table-dropdown">
              <div className="motivation__hidden">
                <div className="motivation__hidden-header">
                  <div className="motivation__hidden-th">Условия Trade-In застройщика</div>
                  <div className="motivation__hidden-th">Застройщик подписывает с клиентом</div>
                  <div className="motivation__hidden-th">Продажа / покупка вторички для клиента</div>
                 </div>
                <div className="motivation__hidden-body">
                  <div className="motivation__hidden-block">

                    <p>{checkString(props.termsDeveloperTradeIn)}</p>
                  </div>
                  <div className="motivation__hidden-block">

                    <p>{checkString(props.developerSignsWithClient)}</p>
                  </div>
                  <div className="motivation__hidden-block">
                    <p>{checkString(props.salePurchaseSecondaryClient)}</p>
                  </div>
                </div>
              </div>
              <div className="motivation__hidden">
                <div className="motivation__hidden-header">
                  <div className="motivation__hidden-th">Примечание</div>
                  <div className="motivation__hidden-th">Срок оплаты</div>
                  <div className="motivation__hidden-th">Вознаграждение за передачу клиента по вторичке</div>
                </div>
                <div className="motivation__hidden-body">

                  <div className="motivation__hidden-block">

                    <p>{props.note}</p>
                  </div>

                  <div className="motivation__hidden-block">

                    <p>{props.paymentPeriod}</p>
                  </div>

                  <div className="motivation__hidden-block">
                    <p>{checkString(props.awardForTransferClientSecondary)}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TradeIn = (props) => {
  const { fetch, send, reset, data, user = {} } = props;

  const tarifRef = useRef(null);

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  useEffect(() => {
    fetch();

    return () => reset();
  }, []);

  const [openPartnerModal, closePartnerModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closePartnerModal} classes="modal_task">
        <TradeInPartner
          title="Стать партнером"
          hide={closePartnerModal}
          userInfo={user.info}
        />
      </Modal>
    ),
  );


  const [openProgramModal, closeProgramModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeProgramModal} classes="modal_task">
        <TradeInPartner
          title="Получить условия программы"
          hide={closeProgramModal}
          userInfo={user.info}
        />
      </Modal>
    ),
  );

  const [openQuestionsModal, closeQuestionsModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeQuestionsModal} classes="modal_task">
        <TradeInPartner
          title="Остались вопросы?"
          hide={closeQuestionsModal}
          userInfo={user.info}
        />
      </Modal>
    ),
  );
  useDocTitle("Трейд - Ин ПАН")
  const goToTarif = () => scrollToRef(tarifRef, 80);

  const node = useRef(null);
  const navRef = useRef(null);
  const [index, setIndex] = useState(1);

  const settings = {
    items: 1,
    loop: false,
    navAsThumbnails: true,
    navContainer: `#trade-in-slider-thumbnails`,
    controls: false,
  };
  //
  // const moveSlider = (way) => {
  //   if (node.current && node.current.slider) {
  //     node.current.slider.goTo(way);
  //   }
  // };

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  const [tariffShowAll, setTariffShowAll] = useState(false);

  const [isManagerVisible, setVisible] = useState(true);
  const offsetTop = 30;
  const managerShowRef = useRef(null);

  const handleScroll = () => {
    let currentScrollPosition = managerShowRef.current?.getBoundingClientRect().top;
    setVisible(currentScrollPosition > offsetTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      <section className="section section_view_white trade-in">
        <div className="wrapper">
          <div className={`trade-in__manager${isManagerVisible ? ` hidden` : ``}`}>
            <img  src={require('../assets/images/trade-in__manager3.jpg').default} alt="" className="trade-in__manager-img" style={{backgroundColor: 'white', objectFit: 'cover'}} />
            <div className="trade-in__manager-block">
              <span className="h6 trade-in__manager-name">Маргарита Пеклеванная</span>
              <p className="trade-in__manager-position">Управляющий проекта Trade in</p>
              <a href="tel:+79657691824" className="trade-in__manager-phone link-hover">+7 (965) 769 18 24</a>
            </div>
          </div>
          <div className="wrapper__center">
            <div className="trade-in__title">
              <h1>Зарабатывай с программой Трейд&nbsp;-&nbsp;Ин&nbsp;ПАН</h1>
            </div>
            <div className="trade-in__head">
              <div className="trade-in__head-block">
                <div className="trade-in__head-block_cloud">
                  <svg className="icon">
                    <use xlinkHref="#trade-in_cloud"></use>
                  </svg>
                </div>
                <div className="trade-in__head-block_pill">
                  <span className="pill">Комиссия до 2,5%</span>
                </div>
                <div className="trade-in__head-block_title">
                  <h3>
                    Есть клиент, который хочет обменять старую квартиру на
                    новую?
                  </h3>
                </div>
                <div className="trade-in__head-block_label">
                  <p>
                    Зафиксируйте клиента у застройщика через ПАН и
                    гарантированно получите комиссию{' '}
                  </p>
                </div>
                <div className="trade-in__head-block_btn">
                  <button
                    className="button button_view_default"
                    onClick={goToTarif}
                  >
                    <span>Наша тарифная карта</span>
                  </button>
                  <a className="link-hover label label_with-icon_prefix color-brand-3" href="https://panpartner.ru/upload/Trade-In_regulations_for_partners.pdf" target="_blank">
                    <div className="label__icon">
                      <svg className="icon icon_document">
                        <use xlinkHref="#document"></use>
                      </svg>
                    </div>
                    Регламент
                    </a>
                </div>
              </div>
              <div className="trade-in__head-image">
                <svg className="icon">
                  <use xlinkHref="#trade-in_head"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section_view_white">
        <div className="wrapper">
          <div className="wrapper__center">
            <div className="trade-in__steps">
              <div className="trade-in__steps-title" ref={managerShowRef}>
                <h2>Простые шаги партнера к выгодной сделке</h2>
              </div>

              {
              isMobile &&
              <div className="assignations-top__slider slider trade-in__slider">

                <div className="slider__wrap">
                  <TinySlider settings={settings} ref={node} onIndexChanged={handleChangeIndex}>
                    {steps.map((step, index) => (
                      <div className="slider__item" key={index}>
                        <div className="trade-in__step">
                          <div className="trade-in__step-num">
                            <span>{step.number}.</span>
                          </div>
                          <div className="trade-in__step-name">
                            <span>{step.name}</span>
                          </div>
                          {step.pill && (
                            <div className="trade-in__step-pill">
                              <span className="pill">{step.pill}</span>
                            </div>
                          )}
                          <div className="trade-in__step-text">
                            <p>{step.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TinySlider>


                <ul
                  className="complex__slider-thumbnails"
                  id="trade-in-slider-thumbnails"
                  ref={navRef}
                >
                  {steps.map((item, index) => (
                    <li className="complex__slider-thumbnails_item" key={`thumb-${index}`}>
                      <span aria-controls="base"></span>
                    </li>
                  ))}
                </ul>

                <span className="complex__slider-info">
                  {index} / {steps.length}
                </span>
</div>
              </div>
              }


              {

              !isMobile &&
                <div className="trade-in__steps-grid">
                  {steps.map((step, index) => (
                    <div className="trade-in__steps-col" key={index}>
                      <div className="trade-in__step">
                        <div className="trade-in__step-num">
                          <span>{step.number}.</span>
                        </div>
                        <div className="trade-in__step-name">
                          <span>{step.name}</span>
                        </div>
                        {step.pill && (
                          <div className="trade-in__step-pill">
                            <span className="pill">{step.pill}</span>
                          </div>
                        )}
                        <div className="trade-in__step-text">
                          <p>{step.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }


              <div className="trade-in__steps-conditions">
                <button
                  className="button button_view_default"
                  onClick={openProgramModal}
                >
                  <span>Получить условия программы</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section_view_white">
        <div className="wrapper">
          <div className="trade-in__benefits">
            <div className="trade-in__benefits-title">
              <h2>
                Почему работать с нами по схеме Трейд-Ин лучше, чем
                самостоятельно
              </h2>
            </div>
            <div className="trade-in__benefits-table">
              <div className="trade-in__benefits-table_row">
                <div className="trade-in__benefits-table_benefit"></div>
                <div className="trade-in__benefits-table_compare">
                  <div className="trade-in__benefits-table_th">
                    <span>Программа Трейд-Ин для партнеров ПАН</span>
                  </div>
                  <div className="trade-in__benefits-table_th">
                    <span>Самостоятельная работа с клиентом</span>
                  </div>
                </div>
              </div>
              {
                isMobile ?
                benefits.map((benefit, index) => (
                  <Accordeon title={benefit.name} withclassName={`trade-in__benefits-table_row`} key={index}>
                  <div className="trade-in__benefits-table_compare">
                    <div className="trade-in__benefits-compare">
                      <span className="trade-in__benefits-mobile-title">Программа Трейд-Ин для партнеров ПАН</span>
                      <div className="trade-in__benefits-compare_col">
                        <svg className="icon">
                          <use xlinkHref="#check-mark"></use>
                        </svg>
                        <div className="trade-in__benefits-compare_text">

                          <p>{benefit.advantage}</p>
                        </div>
                      </div>
                      <span className="trade-in__benefits-mobile-title">Самостоятельная работа с клиентом</span>
                      <div className="trade-in__benefits-compare_col">
                        <svg className="icon">
                          <use xlinkHref="#x-mark"></use>
                        </svg>
                        <div className="trade-in__benefits-compare_text">
                          <p>{benefit.disadvantage}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Accordeon>
                )) :

                benefits.map((benefit, index) => (
                  <div className="trade-in__benefits-table_row" key={index}>
                    <div className="trade-in__benefits-table_benefit">
                      <span>{benefit.name}</span>
                    </div>
                    <div className="trade-in__benefits-table_compare">
                      <div className="trade-in__benefits-compare">
                        <span className="trade-in__benefits-mobile-title">Программа Трейд-Ин для партнеров ПАН</span>
                        <div className="trade-in__benefits-compare_col">
                          <svg className="icon">
                            <use xlinkHref="#check-mark"></use>
                          </svg>
                          <div className="trade-in__benefits-compare_text">

                            <p>{benefit.advantage}</p>
                          </div>
                        </div>
                        <span className="trade-in__benefits-mobile-title">Самостоятельная работа с клиентом</span>
                        <div className="trade-in__benefits-compare_col">
                          <svg className="icon">
                            <use xlinkHref="#x-mark"></use>
                          </svg>
                          <div className="trade-in__benefits-compare_text">
                            <p>{benefit.disadvantage}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="trade-in__benefits-footer">
              <div className="trade-in__benefits-footer_text">
                <span>
                  Сегодня вы заботитесь о клиентах, завтра они рекомендуют вас и
                  становятся постоянными
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section_view_white" ref={tarifRef}>
        <div className="wrapper">
          <div className="trade-in__tariffs">
            <div className="trade-in__tariffs-title">
              <h2>Тарифная карта</h2>
            </div>
            <div className="trade-in__tariffs-label">
              <span>
                В таблице представлены варианты комиссионных вознаграждений в
                зависимости от способа сотрудничества.{' '}
              </span>
              <span className="link" onClick={openQuestionsModal}>
                Есть вопросы?
              </span>
            </div>
            <div className="tariff-map">
              <div className="custom-table">
                {
                  !isMobile &&
                  <div className="custom-table__head motivation__for-trade-in">
                    <div className="custom-table__tr">
                      <div className="custom-table__th motivation__table_builder">
                        Застройщик
                      </div>
                      <div className="custom-table__th motivation__table_objects">
                        <div className="motivation__table_object">
                          % субагента, продает сам
                        </div>
                        <div className="motivation__table_fee">
                          % за передачу клиента
                        </div>

                      </div>

                      <div className="custom-table__th motivation__table_terms is-hidden-sm">
                        Условия Trade-In застройщика
                      </div>
                      <div className="custom-table__th motivation__table_signs  is-hidden-sm">
                        {`Застройщик подписывает${!isDesktop ? ` с клиен` : ``}...`}
                      </div>
                      <div className="custom-table__th motivation__table_purchase is-hidden-sm">
                        {!isDesktop ? `Продажа / покупка вторички для клиента` : `Прод./покуп. вторички...`}
                      </div>

                      <div className="custom-table__th motivation__table_manager is-hidden-sm">
                        Примечание
                      </div>
                      <div className="custom-table__th motivation__table_conditions is-hidden-sm">
                        Срок оплаты
                      </div>

                      <div className="custom-table__th motivation__table_award is-hidden-sm">
                        {!isDesktop ? ` Вознаграждение за передачу клиента...` : `Вознагр. за передачу кл...`}

                      </div>
                    </div>
                  </div>
                }

                <div className={`custom-table__body ${tariffShowAll ? ` custom-table__body_show-all` : ``}`}>
                  {data &&
                    data.map((row) => <TableItem {...row} key={row.id} />)}
                </div>

                { isMobile &&
                  <button type="button" className={`custom-table__showmore-btn button ${tariffShowAll ? ` hidden` : ``}`} onClick={() => setTariffShowAll(true)}>
                    Показать еще
                    <svg className="icon icon_more">
                      <use xlinkHref="#chevron"></use>
                    </svg>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section_view_white">
        <div className="wrapper">
          <div className="trade-in__partnership">
            <div className="trade-in__partnership-image">
              <svg className="icon">
                <use xlinkHref="#trade-in_partnership"></use>
              </svg>
            </div>
            <div className="trade-in__partnership-block">
              <div className="trade-in__partnership-block_title">
                <h2>Проводите трейд-ин сделки с ПАН</h2>
              </div>
              <div className="trade-in__partnership-block_label">
                <p>
                  Покажите покупателям плюсы качественного сервиса и
                  увеличивайте персональный доход.
                </p>
              </div>
              <div
                className="trade-in__partnership-block_btn js-modal-btn"
                data-id="modal-partnership"
              >
                <button
                  className="button button_view_default"
                  onClick={openPartnerModal}
                >
                  <span>Оставить заявку</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.tradeIn.data,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (city) => dispatch(fetchTradeIn(city)),
  reset: () => dispatch(clearTradeIn()),
  send: (name, lastName, email, phone, comment, city) =>
    dispatch(becomePartners(name, lastName, email, phone, comment, city)),
});

TradeIn.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(TradeIn);
