
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFavoriteCount } from '../actions/favorites';
import request from '../api';
import axios from 'axios';

const useApi = (config = {}, dep = []) => {
    const { payload,
        successCallback,
        loadCondition = true,
        processData = r => r,
        favoritesParams,
        favoritePath
    } = config
    const [isLoading, setIsLoading] = useState(true)
    const [moreIsLoading, setMoreIsLoading] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [isSending, setIsSending] = useState(false)
    const [isFavoriteLoading, setIsFavoriteLoading] = useState(false)
    const dispatch = useDispatch()
    const mainAbortControllerRef = useRef(null)
    
    useEffect(() => {
        if (typeof loadCondition === 'function' && !loadCondition?.(data)) return
        if (!loadCondition) return
        if (mainAbortControllerRef.current) mainAbortControllerRef.current.cancel("Отмена предыдущего запроса")
        mainAbortControllerRef.current = axios.CancelToken.source();
        const params = [...payload]
        params[4] = params[4] ? { ...params[4], cancelToken: mainAbortControllerRef.current?.token } :
            { cancelToken: mainAbortControllerRef.current?.token };
        setIsLoading(true)
        request(...params).then(
            resp => {
                setData(prev => processData(resp, prev))
                 setIsLoading(false)
                if (successCallback) {
                    successCallback(resp)
                }
            },
            err =>{ 
                setErrors(prev => ({ ...prev, ...err }))
                setIsLoading(false)
            }
        )
    }, dep)

    const refresher = params => {
        setIsRefreshing(true)
        return request(...params ? params : payload).then(
            success => {
                setIsRefreshing(false)
                setData(prev => processData(success, prev, true))
                if (successCallback) {
                    successCallback(success)
                }
                return Promise.resolve(success)
            },
            err => {
                setIsRefreshing(false)
                return Promise.reject(err)
            })
    }

    const send = payload => {
        setIsSending(true)
        return request(...payload)
            .then(
                success => {
                    setIsSending(false)
                    return Promise.resolve(success)
                },
                err => {
                    setIsSending(false)
                    return Promise.reject(err)
                })
    }
    const loadMore = (payload, dataKey, processData) => {
        setMoreIsLoading(true)
        return request(...payload)
            .then(
                success => {
                    setData(prev => {
                        const tmp = [
                            ...prev[dataKey],
                            ...success[dataKey]? success[dataKey]: []
                        ]
                        return { ...prev, [dataKey]: tmp, ...processData? processData(success): [] }
                    })
                    setMoreIsLoading(false)
                    return Promise.resolve(success)
                },
                err => {
                    setMoreIsLoading(false)
                    return Promise.reject(err)
                })
    }
    const toggleFavorite = (additionalParams = {}, itemPath) => {
        const path = itemPath || favoritePath
        if (!path) throw new Error("Необходимо добавить favoritePath")
        setIsFavoriteLoading(true)
        const fav = _get(data, path)
        const method = fav?.inFavorite ? "removeFromFavorite" : "addToFavorite";
        fav.inFavorite = !fav?.inFavorite;
        const params = { ...favoritesParams, ...additionalParams }
        // debugger
        return request("panpartner:favorite.ajax", method, params).then(
            success => {
                setData(prev => {
                    const tmp = { ...prev }
                    _set(tmp, path, fav)
                    return tmp
                })
                getFavoriteCount()(dispatch);
                setIsFavoriteLoading(false)
                Promise.resolve(success)
            },
            err => {
                setIsFavoriteLoading(false)
                Promise.reject(err)
            }
        )
    }
    const dataSetter = (key, value) => setData(prev => ({
        ...prev,
        [key]: typeof value === 'function' ? value(prev[key]) : value
    }))

    return {
        data,
        errors,
        isLoading,
        isRefreshing,
        isSending,
        moreIsLoading,
        refresher,
        send,
        setData,
        dataSetter,
        setIsLoading,
        setIsRefreshing,
        setIsSending,
        loadMore,
        toggleFavorite,
        isFavoriteLoading,
        inFavorite: _get(data, favoritePath)?.inFavorite,
        get: path => _get(data, path),
    }
}

export default useApi
