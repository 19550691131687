import { memo } from "react"

export const Badge = memo(({ name, values }) => <div className="modal-form__filter">
	<div
		className="modal-form__filter-name"
		style={{ whiteSpace: 'normal' }}
	>
		{name}
	</div>
	<div className="modal-form__filter-value">
		{values}
	</div>
</div>)