import { memo } from 'react';
import CrossIcon from '../Public/markup/CrossIcon';


const SearchInput = memo(props => {
  const {
    crossClasses,
    inputClasses,
    inputRef,
    onChange,
    placeholder,
    readOnly,
    reset,
    value,
    wrapperClasses
  } = props;

  return <label className={`input search_input input_width_available ${wrapperClasses ?? ""}`} >
    <input
      className={`input__control ${inputClasses ?? ""}`}
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      ref={inputRef}
      value={value}
      readOnly={readOnly}
      isAutocomplete="off"
    />
    {!!value && <div
      className={`cross_icon ${crossClasses ?? ""}`}
      onClick={e => {
        e.stopPropagation();
        reset()
      }}
    ><CrossIcon /></div>}
  </label>
})

export default SearchInput;
