import React, {useEffect, useRef, useState} from "react";
import useApi from "../../hooks/useApi";
import ContactCard from "../core/ContactCard";
import Preloader from "../Preloader";
import {Map, RulerControl, YMaps, Placemark, RoutePanel, RouteEditor, withYMaps, Polyline} from "react-yandex-maps";
import {themeColors} from "../Constructor/constructorUtils";
import Button from "../core/Button";
import request from "../../api";
import {successToast} from "../toasts";
import SubmitButton from "../SubmitButton";

const Viewing = ({id}) => {
  const { data: { signUpToView, signUpToViewInfo, managerInfo, canBeCanceled}, send, isLoading, isSending } = useApi({
    payload: ["panpartner:deals.ajax", "getSignUpToView", { id }],
  })

  const [stateCanBeCanceled, setStateCanBeCanceled] = useState(null)
  const [stateSignUpToView, setStateSignUpToView] = useState(null)
  const [stateSignUpToViewInfo, setStateSignUpToViewInfo] = useState(null)
  const [stateManagerInfo, setStateManagerInfo] = useState(null)


  useEffect(() => {
    setStateCanBeCanceled(canBeCanceled)
    setStateSignUpToView(signUpToView)
    setStateSignUpToViewInfo(signUpToViewInfo)
    setStateManagerInfo(managerInfo)
  },[canBeCanceled, signUpToView, signUpToViewInfo, managerInfo])

  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  useEffect(() => {
    if (!mapInstance || !ymaps) return;
    const placemarkTemplate = ymaps.templateLayoutFactory.createClass(
      `
        <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 27C10.5 27 21 19.9565 21 10.5652C21 4.73021 16.299 0 10.5 0C4.70101 0 0 4.73021 0 10.5652C0 19.9565 10.5 27 10.5 27ZM10.5 14C12.433 14 14 12.433 14 10.5C14 8.567 12.433 7 10.5 7C8.567 7 7 8.567 7 10.5C7 12.433 8.567 14 10.5 14Z" fill="${themeColors.red}"/>
        </svg>
        `,
    );
    const placeMaprk = new ymaps.Placemark(
      signUpToView?.coordinates,
      {},
      {
        iconLayout: placemarkTemplate,
        iconOffset: [-24, -58],
      },
    );
    mapInstance.geoObjects.add(placeMaprk);
  }, [mapInstance, ymaps]);


  const mapRef = useRef(null);
  const coordinates = signUpToView?.coordinates;

  useEffect(() => {
    if (mapRef.current) {
      const mapInstance = mapRef.current.getMap();

      if (mapInstance) {
        mapInstance.setBounds(mapInstance.geoObjects.getBounds(), {
          checkZoomRange: true,
        });
      }
    }
  }, []);


  const [isCanceling, setIsCanceling] = useState(false)
  const cancelSignUpToView = (id) => {
    setIsCanceling(true)
    return request("panpartner:deals.ajax", "cancelSignUpToView", {id})
      .then((resp) => {
        // debugger
        setIsCanceling(false)
        setStateCanBeCanceled(resp.canBeCanceled)

        setStateSignUpToView(resp.signUpToView)
        setStateSignUpToViewInfo(resp.signUpToViewInfo)
        setStateManagerInfo(resp.managerInfo)

        successToast(<div>{resp.message}</div>)
      })
  }

  if (isLoading) return <Preloader loading/>
  const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16))

  return (
    <div className="fixation-modal">
      <div className="fixation-modal__header">
        {signUpToView && <p className="h3">{signUpToView.client}</p>}
        {
          stateSignUpToView?.endFixationPeriod ?
            <span className="fixation-modal__status" style={{color: '#FF2D23', backgroundColor: 'rgba(255, 45, 35, 0.2)'}}>{stateSignUpToView.endFixationPeriod}</span> :
            <span className="fixation-modal__status" style={{color: stateSignUpToView?.status.color, backgroundColor: `rgba(${hexToRgb(stateSignUpToView?.status.color)+',0.2'})`}}>{stateSignUpToView?.status.title}</span>
        }
      </div>

      {stateSignUpToViewInfo && stateSignUpToViewInfo.map((i) => {
        return (
          <div className="fixation-modal__field">
            <p className="fixation-modal__label">{i.title}</p>
            <p className="h6"  dangerouslySetInnerHTML={{__html: i.value}}/>
          </div>
        )
      })}

      {
        stateSignUpToView?.coordinates &&
        <div style={{width: '100%', height: 300, borderRadius: 20, marginBottom: 20, border: '1px solid #ECEDF0', overflow: 'hidden'}}>

          <YMaps>
            <Map
              defaultState={{
                center: stateSignUpToView?.coordinates,
                zoom: 16,
              }}
              modules={['templateLayoutFactory', 'Template', 'Placemark']}
              onLoad={setYmaps}
              instanceRef={setMapInstance}
              // instanceRef={mapRef}
              className="complex__map-wrap"
              options={{
                suppressMapOpenBlock: true,
                suppressObsoleteBrowserNotifier: true,
              }}
            >

              {/*<RoutePanel*/}
              {/*  options={{*/}
              {/*    preventDragUpdate: true,*/}
              {/*    reverseGeocoding: true,*/}
              {/*    types: { auto: true },*/}
              {/*  }}*/}
              {/*  instanceRef={(routePanel) => {*/}
              {/*    if (routePanel) {*/}
              {/*      routePanel.routePanel.state.set({*/}
              {/*        fromEnabled: false, */}
              {/*        to: coordinates, */}
              {/*      });*/}
              {/*    }*/}
              {/*  }}*/}
              {/*/>*/}
            </Map>
          </YMaps>


        </div>
      }

      {stateSignUpToView?.address && <p style={{}} className="fixation-modal__label">{stateSignUpToView?.address}</p>}
      {stateSignUpToView?.mapLink && <a href={stateSignUpToView?.mapLink} target='_blank' className="link" style={{display: 'inline-flex', alignItems: 'center', marginTop: 15, marginBottom: 15}}><svg style={{marginRight: 10}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.219 17.2001L4.51114 18.4174C4.45066 18.4327 4.38748 18.4341 4.3264 18.4213C4.26533 18.4086 4.20796 18.3821 4.15868 18.3438C4.10939 18.3055 4.06948 18.2565 4.04199 18.2005C4.01449 18.1445 4.00013 18.083 4 18.0206V6.76208C4.00073 6.64158 4.0414 6.52472 4.11563 6.4298C4.18987 6.33488 4.29348 6.26725 4.41026 6.23749L9.23918 5L14.707 7.75746L19.4889 6.53342C19.5489 6.51681 19.6119 6.51432 19.6731 6.52614C19.7342 6.53796 19.7918 6.56377 19.8413 6.60155C19.8908 6.63933 19.9309 6.68805 19.9584 6.7439C19.9859 6.79976 20.0002 6.86123 20 6.9235V18.182C20.0004 18.3028 19.9602 18.4202 19.8858 18.5154C19.8114 18.6106 19.7071 18.6779 19.5897 18.7066L14.6868 19.9441L9.219 17.2001Z" stroke="#EF372E" strokeMiterlimit="10"/>
<path d="M9.19209 5.04712V17.2674" stroke="#EF372E" strokeMiterlimit="10"/>
<path d="M14.6667 7.64307V19.8633" stroke="#EF372E" strokeMiterlimit="10"/>
</svg>
Построить маршрут</a>}

      {canBeCanceled && !!stateCanBeCanceled &&
        <div style={{marginBottom: 30}}>
          <SubmitButton
            className="button button_view_default"
            type="button"
            isLoading={isCanceling}
            onClick={() => {cancelSignUpToView(id)}}
            addStyle={{width: '100%'}}
          >
            <span>Отменить запись</span>
          </SubmitButton>
        </div>
      }



      <div className="fixation-modal__manager">
        <p className="fixation-modal__manager-title">Подробную информацию уточняйте менеджера</p>
        <ContactCard
          name={stateManagerInfo?.name}
          phone={stateManagerInfo?.phone}
          post="Специалист отдела партнерских продаж"
          telegram={stateManagerInfo?.telegram !== '' ? stateManagerInfo?.telegram : false}
          logo={stateManagerInfo?.photo.src}
        />
      </div>


    </div>


  )
}



export default Viewing
// 1c00f913-9b45-4a8e-921c-b61d324deb58
