import React, { useEffect, useRef, useState } from 'react';
import { getUrl } from '../../api';


const InputImage = (props) => {
  const { images, image, handleUpload, handleDelete, index } = props;

  const input = useRef(null);

  const [src, setSrc] = useState(null);

  useEffect(() => {
    image ? setSrc(getUrl(image.src)) : setSrc(null);
  }, [image]);

  const changeFile = (e) => {
    const tmpFiles = e.target.files?.[0];
    if(!tmpFiles) return
    handleUpload(tmpFiles, index)
  };

  const onDelete = () => {
    setSrc(null);

    if (!image) return;

    handleDelete(image.id);
  };

  return (
    <div className="presentation__filter-image">
      <div
        className={'presentation-image' + (!src ? ' input' : '')}
        style={{ backgroundImage: src ? `url(${src})` : '' }}
      >
        <label
          className="input input_type_form-files presentation-image__input"
          htmlFor={`presentation-image-${index}`}
        >
          <input
            className="input__control"
            type="file"
            ref={input}
            accept="image/png, image/jpeg"
            onChange={changeFile}
            id={`presentation-image-${index}`}
          />
          <div className="presentation-image__body">
            <div className="presentation-image__stub">
              <svg className="icon">
                <use xlinkHref="#photo-stub"></use>
              </svg>
            </div>
            <div className="button button_type_add presentation-image__control"></div>
          </div>
        </label>
        <div
          className="button button_type_delete presentation-image__delete"
          onClick={onDelete}
        ></div>
      </div>
    </div>
  );
};

export default InputImage;
