import React from 'react';
import { ReactComponent as ArrowUp } from "../images/svg/arrow-up.svg";
import Dropdown from './Dropdown';

const SortRealty = ({ sort, onChange, sortList }) => {


  const current = sortList.find((item) => item.id === sort);

  return (
    <div className="cards__sort">
      <label className="custom-select-label" htmlFor="sort">Сортировать</label>
      <Dropdown
        value={current}
        options={sortList}
        onChange={onChange}
        itemIcon={item => <span className={`cards__sort_icon ${item.current === "asc" ? "cards__sort_icon_desc" : ""}`} style={{ visibility: item.id === sort? "visible": "hidden" }}>
          <ArrowUp />
        </span>}
      />
    </div>
  );

};

export default SortRealty;