import { createSelector } from "reselect";
import { createFeature } from "./utils";

const assignmentsMapItemsSelector = state => state.assignments.mapItems;

export const mapAssignments = createSelector([assignmentsMapItemsSelector], (items = []) => {
  let features = [];
  for (const item of items) {
    item.coordinates && features.push(createFeature(item.id, item.coordinates, item.block?.name));
  }
  return features;
});