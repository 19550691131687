import React from "react";
import './index.scss';


const ContactsSchedule = () => {
  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="contacts-schedule">
          <div className="constacts-schedule__item">
            <b className="contacts-schedule__title">График работы офиса:</b>
          </div>

          <div className="constacts-schedule__item">
            <span className="contacts-schedule__days">Пн.—Пт.</span>
            <span className="contacts-schedule__time">с 9.00 до 21.00</span>
          </div>
          <div className="constacts-schedule__item">
            <span className="contacts-schedule__days">Сб.—Вс.</span>
            <span className="contacts-schedule__time">с 10.00 до 18.00</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactsSchedule;
