import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom";
import EstatePage from "../components/Public/markup/EstatePage";
import MainFilter from "../components/Public/markup/MainPageFilter";
import useFilter from "../hooks/useFilter";
import { sortlistPrimary } from "../hooks/useFilter/filterSets";
import { toSearchParams, getFilterStateFromParams } from "../hooks/useFilter/filterUtils";

const filterFromParams = getFilterStateFromParams()

const HomeNew = ({ history, location: { pathname }, ...props }) => {
  // debugger
  const { city: cityFromPath } = useParams();
  const userCity = useSelector(state => state.user?.info?.city ? state.user.info.city : state.general.city)

  const isCommercial = pathname === `/commercial/${cityFromPath}`


  const city = cityFromPath ?? (userCity === "msk" ? "msk" : "spb")

  const apiConfig = useMemo(() => ({
    component: 'panpartner:block.filter.ajax',
    getCountFunction: 'getCount',
    submitFunction: pathname === "/" ? 'getFilter' : "getBlocks",
    getMapItems: 'getBlocks',
  }), [pathname])

  const filterProps = useFilter({
    apiConfig,
    defaultFilter: {
      status: [1],
      ...(isCommercial ? { rooms: [90] } : [])
    },
    defaultParams: {
      city,
    },
    defaultOrder: 'start',
    defaultBy: 'desc',
    defaultSortId: isCommercial ? "0" : "7",
    sortEnabled: true,
    paginationEnabled: true,
    sortList: sortlistPrimary,
    isGetFilter: true,
    loadMoreEnabled: true,
    loadMoreDataKey: 'blocks',
    defaultLimit: 9,
    limit: 3,
    shadowFilters: ['status', ...(isCommercial ? ["rooms"] : [])],
    extractCount: ({ countApartments, countBlocks }) => ({
      countApartments,
      countBlocks,
    }),
    extractLoadMoreCount: ({ countBlocks }) => countBlocks,
    extractMapItems: ({ blocks }) => ({ blocks }),
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },
    favoritesParams: {
      city,
      type: 'block',
    },
    fields: {
      blocks: [],
      status: [],
      districts: [],
      rooms: [],
      endings: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
    },
    defaultLoadCondition: pathname,
    configMutation: (resp, config) => {
      config.apiConfig.submitFunction = "getBlocks";
    },
    processData: (resp, prev) => {
      if (prev.filter && prev.filter?.cityCode === resp.filter?.cityCode) delete resp.filter;
      return { ...prev, ...resp };
    },
    filterFromLS: filterFromParams,
    legacyRestoreFromLS: false,
    debug: true
  })

  useEffect(() => {
    filterProps.setLoadCondition(pathname);
    filterProps.setMapItems(false);
    if(filterFromParams) return
    filterProps.clearFilter();
    filterProps.clearExclude();
  }, [city, isCommercial])

  useEffect(() => {
    if(filterProps.isLoading) return 
    toSearchParams(filterProps.filter, filterProps.exclude)
  }, [filterProps.filter, filterProps.exclude])
  
  const submit = () => {
    filterProps.submit({ submitFunction: "getBlocks" })
    history.push(`/realty/${city}`)
  }

  return (
    <Switch>
      <Route exact path={'/'} render={() => <MainFilter city={city} {...filterProps} submit={submit} />} />
      {/*<Route exact path={['/realty/:city', "/commercial"]} render={() => <EstatePage {...filterProps} isCommercial={isCommercial} />} />*/}
      <Route exact path={['/realty/:city', "/commercial/:city"]} render={() => <EstatePage {...filterProps} isCommercial={isCommercial} />} />
    </Switch>
  );
};

export default HomeNew;
