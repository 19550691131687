import { useEffect, useRef } from 'react';

/*
функция которая сработает при клике мимо
const pastClickHandler = ()=>{
console.log("кликнули мимо")
}
использование хука, так же сохраняет рефку, можно использовать её и дальше
const nodeRef = usePastClick(pastClickHandler)

<div dummy-atribute="я родитель, если кликнуть не по моим потомкам - сработает pastClickHandler">
     <div  dummy-atribute="я потомок по мне и моим потомкам можно кликать безнаказано" ref={nodeRef}>
      ....
      </div>
</div>
*/

/**
 * Хук, вызывающий функцию при клике вне выбранного узла
 * const nodeRef = usePastClick(pastClickHandler)
 * <div  dummy-atribute="я потомок по мне и моим потомкам можно кликать безнаказано" ref={nodeRef}>
 * ...
 * @param {Function} handler - функция которая будет вызвана при клике мимо
 * @param {Function} alternativeHandler - необязательный параметр, нужна для обработки редких случаев
 * @param {Node} dafaultValue - дефолтное значение рефки
 * @return {object} - возвращает реф, который можно использовать
 */
const useClickPast = (handler, alternativeHandler, defaultValue = null) => {
    const ref = useRef(defaultValue)
    const handleClick = (e) => {
        if (alternativeHandler) {
            alternativeHandler(e, ref)
            return
        }
        if (!ref.current?.parentNode?.contains(e.target)) {
            handler();
        }
    };
    useEffect(() => {
        const handler = (e) => handleClick(e, ref)
        if (!ref.current) return () => document.removeEventListener('click', handler, true);
        document.addEventListener('click', handler, true);
        return () => document.removeEventListener('click', handler, true);
    }, [ref, alternativeHandler])
    return ref
}
export default useClickPast;