import { memo } from "react";
import { getUrl } from "../../../../api";
import Card from "../../../core/Card";

const SuburbanCardNew = memo((props) => {
    const { number, link, ending, plan, price, squareTotal } = props;

    return <Card
        {...props}
        isFlat
        isBordered
        topLabel={number}
        link={link}
        title={price}
        subtiltle={squareTotal}
        src={getUrl(plan?.src)}
        descValue={ending}
    />
});

export default SuburbanCardNew;
