import React from "react";
import "./index.scss";
import NewsShare from './Share.js';

const Morskaya = (props) => {

  return (

    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`Зафиксируйте клиента до старта продаж ЖК «Морская миля»`} />
        <div className="news__container container">
          <h1 className="h1 news__title">Зафиксируйте клиента до старта продаж ЖК «Морская миля»</h1>
          <img src={require('../../assets/images/news/hot.svg').default} alt="" className="news__promo-img" />
          <p className="h3 news__subtitle">
            <span role="img" aria-label="hot">🔥</span> Партнёры!
          </p>

          <p className="h3 news__subtitle">
            Появилась возможность зафиксировать клиента до старта продаж ЖК «Морская миля» от ЛСР
          </p>

          <p className="news__text"><span role="img" aria-label="close">🔒</span> Для этого необходимо прислать на почту <a className="link" href="mailto:trofimova@1-an.ru?subject=Фиксация клиента по ЖК «Морская миля»">trofimova@1-an.ru</a> ФИО клиента, телефон клиента и название ЖК</p>

        </div>
      </div>

    </section>
  )
};

export default Morskaya;
