import React, {useEffect, useRef, useState} from 'react';
import './style.scss'
import OfficeTrainingTopImg from "../../assets/images/OfficeTraining/office-training-bg2.png";
import useMedia from "../../hooks/useMedia";
import tab1bg from "../../assets/images/OfficeTraining/office-training-tab1bg.png"
import tab2bg from "../../assets/images/OfficeTraining/office-training-tab2bg.png"
import tab3bg from "../../assets/images/OfficeTraining/office-training-tab3bg.png"
import tab4bg from "../../assets/images/OfficeTraining/office-training-tab4bg.png"
import tab5bg from "../../assets/images/OfficeTraining/office-training-tab5bg.png"
import tab6bg from "../../assets/images/OfficeTraining/office-training-tab6bg.png"
import tab7bg from "../../assets/images/OfficeTraining/office-training-tab7bg.png"
import {useModal} from "react-modal-hook";
import PanModal from "../../components/core/PanModal";
import panschoolBottomLine from "../../assets/images/panschool/panschoolbottomline.png";
import InputMask from "react-input-mask";
import SubmitButton from "../../components/SubmitButton";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {errorToast, standartErrorToast, standartInfoToast, successToast} from "../../components/toasts";
import useUniversalForm from "../../hooks/useUniversalForm";
import {programsList} from "../SchoolOnRoad/data";
import useApi from "../../hooks/useApi";

const OfficeTraining = () => {

  const formRef = useRef(null);
  const is950 = useMedia('is950');
  const handleScroll = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [activeTab, setActiveTab] = useState(is950 ? null : 0);
  const tabs = [
    {'number': 'Программа 1', 'title': 'По застройщикам'},
    {'number': 'Программа 2', 'title': 'Индивидуальные экскурсии под ваш запрос'},
    {'number': 'Программа 3', 'title': 'По апартаментам и коммерции'},
    {'number': 'Программа 4', 'title': 'По загородной недвижимости'},
    {'number': 'Программа 5', 'title': 'По переуступкам'},
    {'number': 'Программа 6', 'title': 'По ипотеке'},
    {'number': 'Программа 7', 'title': 'Тренинги по продажам'},
  ];
  const tabContent = [
    {
      'text' : {
        0: 'Преимущества застройщиков и жилых комплексов, разбор локаций',
        1: 'Презентация объектов от эксперта',
        2: 'Выгодные кейсы, скидки, акции, инструменты продаж',
        3: 'Регламент взаимодействия с застройщиками, ответы на вопросы',
        4: 'Отработка возражений клиентов',
      },
      url: tab1bg
    },
    {
      'text' : {
        0: 'ЖК застройщика или ЖК в одной локации',
        1: 'Старты продаж',
        2: 'Экскурсии для начинающих агентов',
        3: 'Экскурсии для опытных специалистов рынка',
      },
      url: tab2bg
    },

    {
      'text' : {
        0: 'Теория и практика работы на рынке апартаментов, управляющие компании, программы доходности',
        1: 'Застройщики апартаментов: цены, предложения, работа с возражениями клиентов',
        2: 'Инструменты подбора коммерческого помещения под запрос клиента',
        3: 'Кейсы с разными вариантами оплаты: рассрочки, субсидированная ипотека, сниженная ставка на период строительства',
      },
      url: tab3bg
    },
    {
      'text' : {
        0: 'Вводное обучение по загородному рынку, типам объектов',
        1: 'Продающие локации и объекты, востребованные кейсы',
        2: 'Отдельные обучения по объектам и застройщикам, сборное обучение по определенным застройщикам',
        3: 'Подрядное строительство — что это и как продавать',
        4: 'Инвест-проекты загородного рынка',
      },
      url: tab4bg
    },
    {
      'text' : {
        0: '«Как заработать на переуступке с ПАН Партнер»',
        1: 'Как продать и купить переуступку',
        2: 'Алгоритм сделки, форма расчетов между сторонами',
        3: 'Виды обременений',
        4: 'Ипотека на уступки: банки, условия, процентные ставки',
      },
      url: tab5bg
    },
    {
      'text' : {
        0: 'Этапы сделок ',
        1: 'Страхование',
        2: 'Субсидии',
        4: 'Работа с ИП, нетрудоустроенными, военными',
        5: 'Материнский капитал ',
        6: 'Ипотека по двум документам ',
        7: 'Ипотека траншами',
        8: 'Загородная недвижимость',
        9: 'Субсидированные ставки',
        10: 'Выкуп из-под залога',
        11: 'Комбо-ипотека',
        12: 'Льготная ипотека',
      },
      url: tab6bg
    },
    {
      'text' : {
        0: 'Психология общения с клиентом',
        1: 'Повышение конверсии «звонок — встреча»',
        2: 'Повышение конверсии «встреча — договор»',
      },
      url: tab7bg
    },

  ];

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal customClass={'mobile-office-trainingModal'} closeModal={closeContactsModal} isSmall>
        <div className="office-training__tab-content">
          <ul className="office-training__tabcontent-list">
            {Object.values(tabContent[activeTab]?.text).map((text, index) => (
              <p className="office-training__tabcontent-listitem" key={index}>{text}</p>
            ))}
            <img className="office-training__tabcontent-listimage" src={tabContent[activeTab].url}/>
          </ul>
          <button className="btn btn_secondary h6 office-training__tab-btn" onClick={() => {
            closeContactsModal()
            handleScroll()
          }}>Записаться</button>
        </div>
      </PanModal>
    ),
    [activeTab],
  );





  const userInfo = useSelector(state => state.user?.info)

  const [agree, setAgree] = useState(true)
  const { send, setValue, form, isLoading, errors, getValue, clearForm } = useUniversalForm({
    formId: 45,
    getSettingsAction: "getFormSettingsPublic",
    sendAction: "sendFormPublic",
    defaultValues: {
      name: `${userInfo?.name} ${userInfo?.lastName}` || '',
      phone: userInfo?.phone || '',
    },
  })
  const handleSend = (params) => {
    if (!agree) return errorToast("Необходимо согласие на обработку персональных данных")
    if (getValue("name") === '') return errorToast("Введите имя")
    if (getValue("phone") === '') return errorToast("Введите телефон")
    send().then(() => successToast("Заявка отправлена"), standartErrorToast)

    clearForm()
  }


  return (
    <div className="wrapper wrapper-lands office-training">
      <div className="panschool-sec1">
        <img className="panschool-sec1__toplogo" src={OfficeTrainingTopImg} alt=""/>
        <p className="panschool-sec1__panschool">Обучение у вас в офисе</p>
        <p className="panschool-sec1__leveltext">Для сотрудников вашего агентства — от 5 человек</p>
        <h1 className="panschool-sec1__maintitle">Выездные <br/>обучения </h1>
        <p className="panschool-sec1__secondtitle">
          Усиливаем компетенции вашего отдела продаж
        </p>
        {!is950 &&
          <div className="panschool-sec1__btns">
            <button className="btn btn_secondary h6" onClick={handleScroll}>Оставить заявку</button>
            {/*<button className="btn btn_secondary h6"*/}
            {/*        onClick={() => window.location.href = ''}*/}
            {/*>Программа обучения</button>*/}
          </div>
        }

      </div>

      {is950 &&
        <div className="panschool-sec1__btns">
          <button className="btn btn_primary h6" onClick={handleScroll}>Оставить заявку</button>
          {/*<button className="btn btn_secondary h6"*/}
          {/*        onClick={() => window.location.href = ''}*/}
          {/*>Программа обучения</button>*/}
        </div>
      }

      <div className="panschool-sec2">
        <div className="panschool-sec2__item">
          <p className="panschool-sec2__title">Тренинг у вас
            в офисе уже завтра  </p>
          <p className="panschool-sec2__text">Обучение от практикующих специалистов ПАН Партнер по выбранному направлению</p>
        </div>
        <div className="panschool-sec2__item">
          <p className="panschool-sec2__title">Экономия времени  </p>
          <p className="panschool-sec2__text">Приезжаем к вам в офис с готовой программой по застройщикам, инструментам приобретения, регламентам или техникам продаж</p>
        </div>
        <div className="panschool-sec2__item">
          <p className="panschool-sec2__title">Конструктор обучения</p>
          <p className="panschool-sec2__text">Выберите дату, время и тему — составим программу для вашего агентства и проведем обучения</p>
        </div>
      </div>

      <div className="office-training__sec3 panschool-sec3">
        <div className="panschool-sec3__left">

          <p className="panschool-sec3__title">Ваша команда</p>
          <p className="panschool-sec3__title panschool-sec3__title_mark"><span><div>увеличит</div></span></p>
          <p className="panschool-sec3__title">продажи недвижимости</p>
        </div>
        <div className="panschool-sec3__right">
          <ul className="office-training__sec3-list">
            <li className="">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#EF372E"/>
                <path d="M13.3945 21.7102L19.9986 28.2534L32.6481 15.6836" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              <p className="office-training__sec3-listTitle">Узнают о преимуществах застройщиков и ЖК</p>
              <p className="office-training__sec3-listText">Обсудим историю, ценности и подачу девелопера</p>
              <p className="office-training__sec3-listText">Рассмотрим актуальные кейсы, которые можно использовать в работе</p>
            </li>
            <li className="">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#EF372E"/>
                <path d="M13.3945 21.7102L19.9986 28.2534L32.6481 15.6836" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              <p className="office-training__sec3-listTitle">Разберется с рассрочками
                и ипотекой здесь и сейчас</p>
              <p className="office-training__sec3-listText">Расскажем о самых выгодных и актуальных программах</p>
              <p className="office-training__sec3-listText">Научим быстро адаптироваться к изменениям рынка</p>
            </li>
            <li className="">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#EF372E"/>
                <path d="M13.3945 21.7102L19.9986 28.2534L32.6481 15.6836" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              <p className="office-training__sec3-listTitle">Повысит конверсию «обращение — договор»</p>
              <p className="office-training__sec3-listText">Детально рассмотрим структуру звонка и встречи</p>
              <p className="office-training__sec3-listText">Разберем инструменты для эффективной работы с клиентами</p>
            </li>
            <li className="">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#EF372E"/>
                <path d="M13.3945 21.7102L19.9986 28.2534L32.6481 15.6836" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              <p className="office-training__sec3-listTitle">Укрепит компетенции по уступкам, апартаментам, загородной и коммерческой недвижимости</p>
              <p className="office-training__sec3-listText">Разберем каждое направление недвижимости</p>
              <p className="office-training__sec3-listText">Усовершенствуем технику работы с аудиторией покупателей и инвесторов</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="office-training__sec4">
        <p className="panschool-sec1__maintitle">Выберите программу для своего агентства</p>


        <div className="office-training__overtabs">
          <div className="office-training__tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`office-training__tab ${activeTab === index && !is950 ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab(index)
                  is950 && openContactsModal()
                }}
              >
                <p className="office-training__tab-number">{tab.number}</p>
                <p className="office-training__tab-text">{tab.title}</p>
              </div>
            ))}
          </div>

          {!is950 &&
          <div className="office-training__tab-content">
              <ul className="office-training__tabcontent-list">
                {Object.values(tabContent[activeTab].text).map((text, index) => (
                  <p className="office-training__tabcontent-listitem" key={index}>{text}</p>
                ))}
                <img className="office-training__tabcontent-listimage" src={tabContent[activeTab].url}/>
              </ul>
            <button className="btn btn_secondary h6 office-training__tab-btn"  onClick={handleScroll}>Записаться</button>
          </div>
          }


        </div>

      </div>

      <div className="autopan-sec6 panschool-sec6" ref={formRef}>
        <p className="panschool-sec1__maintitle">Оставьте заявку — позвоним,<br/> чтобы обсудить детали</p>
        <div className="panschool-sec6__bottomline">
          <img src={panschoolBottomLine} alt=""/>
        </div>

        <div className="panschool-sec6__form">



          <form action="#" className="sor__form">
            <div className="sor__form-box">
              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    className={"input__control" + (errors.name ? " input__error" : "")}
                    placeholder="Ваше имя"
                    value={getValue("name")}
                    onChange={e => setValue(e.target.value, "name")}
                  />
                  {!!errors.name && (
                    <span className="input__error-label">
                          {errors.name}
                        </span>
                  )}
                </label>
              </div>

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <InputMask
                    id="modal-reservation-phone"
                    className={
                      "input__control" + (errors.phone ? " input__error" : "")
                    }
                    mask="+7 (999) 999-99-99"
                    autoComplete="off"
                    maskChar=" "
                    name="phone"
                    placeholder="Ваш телефон"
                    value={getValue("phone")}
                    onChange={e => setValue(e.target.value, "phone")}
                  />
                  {errors.phone && (
                    <span className="input__error-label">
                          {errors.phone}
                        </span>
                  )}
                </label>
              </div>
              <div className="sor__form-item">
                <SubmitButton
                  type="button"
                  className="btn_full button btn_secondary button_view_default"
                  onClick={handleSend}
                  isLoading={isLoading}
                >
                  Оставить заявку
                </SubmitButton>
              </div>

            </div>

            <div className="sor__form-footer">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    'checkbox__box' +
                    (errors.agreement ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    checked={agree}
                    onChange={e => setAgree(e.target.checked)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"

                >
                  Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default OfficeTraining
