import { memo } from "react";
import { getUrl } from "../../../../api";
import { evaulateEndings, getRoundedPrice, getSuffix } from "../../../../utils";
import Card from "../../../core/Card";


const PrimaryPublicCard = memo(props => {
    const { link, aeroPanorama, endings, photo, name, address, builder, countBuildings, apartmentsInfo} = props
    return <Card
        {...props}
        topLabel={!props.listFromSf && aeroPanorama ? "Аэропанорама" : false}
        link={link}
        bottomLabel={evaulateEndings(endings)}
        src={getUrl(photo?.src)}
        title={name}
        withoutBtnsList={props.listFromSf && true}
        subtiltle={address}
        descTitle={builder?.title}
        descValue={countBuildings ? `${countBuildings} корпус${getSuffix(countBuildings)}` : ""}
        tableItems={apartmentsInfo?.apartmentsByRoomType?.map?.(item=>({
            id: item.roomTypeId,
            title: item.roomTypeName,
            count: item.count, 
            middleTitle: item.minSquare? `от ${item.minSquare} м²` : "",
            subtitle: `от ${getRoundedPrice(item.minPrice)} млн.р.` 
        }))}
        
    />
});

export default PrimaryPublicCard;
