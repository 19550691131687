import React from 'react';
import { Link } from 'react-router-dom';
import Distributor from '../Poll/Distributor';
import './index.scss';


const Poll = props => {
  const {
    submit,
    formSettings,
    form,
    handleChange,
    errors,
    agreement,
    setAgreement, 
    isExistResult
  } = props

  return (
    <div className="training__poll">
      <div className="training__poll-header">
        <h3 className="h3 training__poll-title">Для начала обучения заполните анкету участника</h3>
      </div>
      <section className="poll">
        <form action="#" className="poll__form">
          {formSettings && <Distributor questions={formSettings} form={form} handleChange={handleChange} />}
          {<div className="poll__item" style={{ justifyContent: "center" }}>
            <div class="checkbox checkbox_view_invert">
              <div
                class={
                  "checkbox__box" +
                  (errors.agreement ? " checkbox__box-error" : "")
                }
              >
                <input
                  class="checkbox__control checkbox_view_flex-start"
                  type="checkbox"
                  id="modal-partnership-agreement"
                  name="agreement"
                  checked={agreement}
                  onChange={e => setAgreement(e.target.checked)}
                />
                <label
                  class="checkbox__input"
                  htmlFor="modal-partnership-agreement"
                ></label>
                <div class="checkbox__marker"></div>
              </div>
              <label
                class="checkbox__label"
                htmlFor="modal-partnership-agreement"
              >
                Отправляя эту форму Вы соглашаетесь с<br />
                <Link className="link" to={`/policy`} target="_blank">политикой конфиденциальности</Link>
              </label>
            </div>
          </div>}
          <button
            type="button"
            className="poll__submit poll__submit_big-mb button button_view_default"
            onClick={submit}
            disabled={!agreement || isExistResult}
          >
            {isExistResult ? 'Вы уже прошли опрос' : 'Отправить'}
          </button>

        </form>
      </section>
    </div>
  );
};

export default Poll;
