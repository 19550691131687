//import { filterParams, filterConfig } from "../../data/estateFilter";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getBanners } from "../../actions/banners";
import { fetchClients } from "../../actions/clients";
import {
  clearFilterEstate,
  fetchEstateItems,
  fetchEstateMap,
  getCountBlocks,
  getEstatePage,
  setFilterEstate,
  setPage,
  setPageCount,
  setSort,
  setView
} from "../../actions/estate";
import useDocTitle from "../../hooks/useDocTitle";
import { mapItems } from "../../selectors/estate";
import {
  filterToParams,
  getParserTypes,
  media,
  parseParams,
  serialize,
  typesWithArrayValues
} from '../../utils';
import Filter from "../FilterRealty";
import PrepaidBanner from "../PrepaidBanner";
import "./index.css";
import Items from "./Items";


const scrollToRef = (ref, offset = 80) => {
  try {
    window.scrollTo({
      top: media("isMobile") ? ref.current.offsetTop + offset : ref.current.offsetTop - offset,
      behavior: "smooth",
    });
  } catch {

  }
}


const Estate = (props) => {
  const {
    getEstatePage,
    fetchItems,
    filter,
    filterData,
    setFilter,
    location,
    features,
    mapItems,
    countApartments,
    page,
    pageCount,
    setPage,
    setPageCount,
    view,
    setView,
    data,
    getCountBlocks,
    fetching,
    sort,
    sortHandler,
    fetchEstateMap,
    fetchClients,
    isAgent,
    clientMode,
    getBanners,
    banners,
    sortList,

  } = props;
  // console.log(props)
  const { city } = useParams();

  let history = useHistory();

  const queryParams = location.search;
  useDocTitle(`Недвижимость ${city === "spb" ? "Санкт-Петербурга" : "Москвы"}`)
  useEffect(() => {
    fetchClients();
    getBanners(window.location.pathname.replace("/app", ""))
    let output = {};
    const customizer = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    if (queryParams && queryParams != "?scroll") {
      let params = new URLSearchParams(queryParams);
      const temp = {};
      typesWithArrayValues.forEach(
        (v) => (temp[v] = { filters: [], exclude: [] })
      );
      for (const [key, value] of params) {
        if (!getParserTypes(key)) {
          break
        }
        let type = getParserTypes(key)[0];
        let subType = getParserTypes(key)[1];
        if (typesWithArrayValues.includes(subType)) {
          _.mergeWith(
            output,
            parseParams(key, value, typesWithArrayValues, temp[subType][type]),
            customizer
          );
        } else {
          _.merge(output, parseParams(key, value, typesWithArrayValues));
        }
      }
    }
    let newFilter = { ...filter, ...output };
    setFilter(newFilter);
    let paramsFromFilter = filterToParams(newFilter);
    const selectedSort = sortList.find(item => item.id === sort)
    const sortObject = {
      order: sort,
      by: selectedSort.current === "asc" ? "desc" : "asc"
    }
    getEstatePage(city, paramsFromFilter, pageCount, page, 1, sortObject)
    getCountBlocks(paramsFromFilter);

  }, [city]);

  const itemsRef = useRef(null);
  const handleFilter = (newPage, isHandlePage) => {
    let params = filterToParams(filter);
    history.push({
      search: `?${serialize(params)}`,
      state: { detail: "some_value", noClearFilter: false },
    });
    if (!isHandlePage) {
      scrollToRef(itemsRef);
      setPage(1);
    }
    const selectedSort = sortList.find(item => item.id === sort)
    const sortObject = {
      order: sort,
      by: selectedSort.current === "asc" ? "desc" : "asc"
    }
    const page = newPage || 1
    fetchItems(city, params, pageCount, page, 0, sortObject)
    if (view === "map") {
      fetchEstateMap(city, params, sort);
    }
  };
  const handlePageClick = (page) => {
    scrollToRef(itemsRef);
    setPage(page);
    let params = filterToParams(filter);
    const selectedSort = sortList.find(item => item.id === sort)
    const sortObject = {
      order: sort,
      by: selectedSort.current === "asc" ? "desc" : "asc"
    }
    fetchItems(city, params, pageCount, page, 0, sortObject)
  };

  useEffect(() => {
    if (history.location.search === "?scroll") {
      scrollToRef(itemsRef)
    }
    if (view === "map") {
      scrollToRef(itemsRef);
    }
  }, []);

  const handleSort = val => {
    const selectedSort = sortList.find(item => item.id === val)
    const sortObject = {
      order: val,
      by: sort === val ? selectedSort.current : selectedSort.defaultSortBy
    }
    if (view === "list") {
      fetchItems(city, filterToParams(filter), pageCount, page, 0, sortObject)
    }
    if (view === "map") {
      fetchEstateMap(city, filterToParams(filter), sortObject);
    }
    sortHandler(val);
  };
  const setIsApartmentsFilter = () => {
    let tempValue = {
      filter: { isApartments: [{ id: filterData.isApartments[0].id }] },
      exclude: {
        isApartments: [],
      },
    };
    setFilter(tempValue);
  };
  const setisAssigmentsFilter = () => {
    let tempValue = {
      filter: { assignments: [filterData.assignments[0].id] },
      exclude: {
        assignments: [],
      },
    };
    setFilter(tempValue);
  };
  const setCountOnPage = (pageCount) => {
    setPageCount(pageCount);
    setPage(1);
    scrollToRef(itemsRef);
    const selectedSort = sortList.find(item => item.id === sort)
    const sortObject = {
      order: sort,
      by: selectedSort.current === "asc"? "desc": "asc"
    }
    fetchItems(city, filterToParams(filter), pageCount, 1, 0, sortObject)
  };

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleChildClick = () => setIsFiltersOpen(!isFiltersOpen)

  const handleCloseClick = () => setIsFiltersOpen(false)

  const isSkeletonData = useMemo(() => data.some(item => item.isFake), [data]);

  return (
    // <div className="redesign">
    <div>
      <Filter
        onSubmit={handleFilter}
        city={city}
        fetching={fetching}
        isFiltersOpen={isFiltersOpen}
        onClick={handleCloseClick}
      />
      {banners?.before && <div className="wrapper">
        <PrepaidBanner bannerId="before" banners={banners} />
      </div>}
      <Items
        items={data}
        innerRef={itemsRef}
        features={features}
        mapItems={mapItems}
        countBlocks={props.countBlocks}
        countApartmentsToShow={props.countApartments}
        countApartments={countApartments}
        view={view}
        handleView={setView}
        page={page}
        handlePage={handlePageClick}
        pageCount={pageCount}
        handlePageCount={setCountOnPage}
        fetching={fetching || isSkeletonData}
        sort={sort}
        sortHandler={handleSort}
        city={city}
        fetchEstateMap={fetchEstateMap}
        filter={filter}
        setIsApartmentsFilter={setIsApartmentsFilter}
        setisAssigmentsFilter={setisAssigmentsFilter}
        clientMode={clientMode}
        isAgent={isAgent}
        onClick={handleChildClick}
        banners={banners}
        sortList={sortList}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getEstatePage: (city, form, pageCount, page, isGetFilter = 1, sort) =>
    dispatch(getEstatePage(city, form, pageCount, page, isGetFilter, sort)),
  fetchItems: (city, form, pageCount, page, isGetFilter, sort) =>
    dispatch(fetchEstateItems(city, form, pageCount, page, isGetFilter, sort)),
  setFilter: (filter) => dispatch(setFilterEstate(filter)),
  setView: (view) => dispatch(setView(view)),
  setPage: (page) => dispatch(setPage(page)),
  setPageCount: (pageCount) => dispatch(setPageCount(pageCount)),
  clearFilter: () => dispatch(clearFilterEstate()),
  getCountBlocks: (params) =>
    dispatch(getCountBlocks(ownProps.match.params.city, params)),
  sortHandler: (sort) => {
    dispatch(setSort(sort));
  },
  fetchEstateMap: (city, form, sort) =>
    dispatch(fetchEstateMap(city, form, sort)),
  fetchClients: () => dispatch(fetchClients()),
  getBanners: (url) => dispatch(getBanners(url))
});

const mapStateToProps = (state) => {
  return {
    filterData: state.estate.filterData,
    filter: state.estate.filter,
    data: state.estate.items,
    view: state.estate.view,
    page: state.estate.page,
    pageCount: state.estate.pageCount,
    countApartments: state.estate.countApartments,
    countBlocks: state.estate.countBlocks,
    features: state.estate.features,
    mapItems: mapItems(state),
    fetching: state.estate.fetching,
    sort: state.estate.sort,
    sortList: state.estate.sortList,
    isAgent: !state.user.info.groups.some((group) => group === "master_account" || group === "general_director" || group === "agent_extended") || state.user.info.groups.includes("test_access"),
    clientMode: state.general.clientMode,
    banners: state.banners
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Estate);
