import React, {useState} from "react";
import './index.scss'
import {DownloadLeftoversIcon, FileRemainsIcon} from "../Public/markup/icons";
import {getUrl} from "../../api";
import useClickPast from "../../hooks/useClickPast";

const Remains = ({remains}) => {

  const [active, setAvtive] = useState(false)
  const node = useClickPast(() => {setAvtive(false)});
  return (
    <div className={`remains ${active ? 'active' : ''}`} ref={node} onClick={() => {setAvtive(!active)}}>
      <div className="remains__header">
        <DownloadLeftoversIcon/>
        Скачать остатки
      </div>
      {active &&
        <div className="remains__body">
          <p className="remains__title">{remains.title}</p>
          <ul className="remains__list">
            {
              remains.files.map((item) => {
                return (
                  <li className="remains__list-item">
                    <a className="remains__list-link" href={getUrl(item.value)}>
                      <div className="remains__item-overtext">
                        <FileRemainsIcon/>
                        <p className="remains__item-text">{item.title}</p>
                      </div>
                      <DownloadLeftoversIcon/>
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      }

    </div>
  )
}

export default Remains
