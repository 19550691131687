import classNames from 'classnames';
import React, { useRef } from 'react';
import SimpleBar from 'simplebar-react';
import useDropdown from '../../hooks/useDropdown';
import useElementPosition from "../../hooks/useElementPosition";
import { getEndingsTitle } from '../../utils';
import useMedia from '../../hooks/useMedia';
import SearchInput from './SearchInput';
const EndingsFilter = (props) => {
  const {
    onChange,
    filter,
    data,
    items,
    right,
    secondData,
    secondChange,
    // isMobile,
    ident,
    autoPosition,
    wrapperClass,
    openTop = false,
  } = props;
  const isMobile = useMedia("isTablet");
  const [node, open, show, hide] = useDropdown(false);
  const formItemRef = useRef(null)
  const position = useElementPosition(formItemRef, 150)
  const handleChange = (e, name) => {
    const { value } = e.target;
    onChange({ filter: { ...filter, [name]: value } });
  };
  const reset = name => {
    onChange({ filter: { ...filter, [name]: "" } })
  }
  const clearFilters = (e) => {
    e.preventDefault()
    if (props.getCount && typeof props.getCount === "function") {
      props.getCount({
        ...props.fullFilter,
        filter: props.defFullFilter
          ? props.defFullFilter.filter
          : props.defaultFilter,
      });
    }
    props.clearFilterOnly()
    props.clearExcludeOnly()
  }

  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right")),
    dropdown__block_align_top: openTop,
  });

  return (
    <div className={`form__item ${wrapperClass ? wrapperClass : ""}`} ref={formItemRef}>
      <div
        ref={node}
        className={'dropdown form__drop' + (open ? ' is-showing' : '')}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">{data?.title}</span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data.title}</h3>
                </div>
              }
              {secondData && (
                <div
                  className="checkbox checkbox_view_red"
                  style={{ margin: "5px 0 10px" }}
                >
                  <div className="checkbox__box first-order">
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="is-only-handed"
                      checked={
                        secondData && secondData.value == 'Y' ? true : false
                      }
                      onChange={(e) =>
                        secondChange(e.target.checked ? 'Y' : 'N')
                      }
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="is-only-handed"
                    ></label>
                    <div className="checkbox__marker forced-enable-z"></div>
                  </div>
                  <label className="checkbox__label second-order" htmlFor="is-only-handed">
                    {secondData?.title}
                  </label>
                </div>
              )}
              <div
                className="filter"
                style={{
                  filter:
                    secondData && secondData.value == 'Y'
                      ? 'opacity(0.5)'
                      : 'none',
                }}
              >
                <div className="filter__cols">
                  <div className="filter__col">
                    <div className="filter__filter ">
                      <SearchInput
                        placeholder={data.autocompleteTitle}
                        reset={() => reset("min")}
                        value={
                          filter.min && items.length > 0
                            ? getEndingsTitle(filter.min)
                            : ''
                        }

                        wrapperClasses="is-thin"
                        readOnly={true}
                      />
                    </div>
                    {items && (
                      <SimpleBar className="filter__checks">
                        <div className="checkbox-group">
                          {items.map((item) => (
                            <div
                              className="checkbox-group__item "
                              key={item.id}
                            >
                              <div
                                className="checkbox checkbox_type_btn"
                                data-title="Цена от"
                              >
                                <div className="checkbox__box">
                                  <input
                                    className="checkbox__control "
                                    type="radio"
                                    name="deadline-from"
                                    id={`deadline-from-${ident}-${item.id}`}
                                    value={item.id}
                                    onChange={(e) =>
                                      secondData && secondData.value == 'Y'
                                        ? null
                                        : handleChange(e, 'min')
                                    }
                                    checked={item.id == filter.min}
                                  />
                                  <label
                                    className="checkbox__label"
                                    htmlFor={`deadline-from-${ident}-${item.id}`}
                                    style={{
                                      cursor:
                                        secondData && secondData.value == 'Y'
                                          ? 'default'
                                          : 'pointer',
                                    }}
                                  >
                                    {item?.title}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>

                  <div className="filter__col">
                    <div className="filter__filter ">
                      <SearchInput
                        placeholder={data.autocompleteTitle}
                        reset={() => reset("max")}
                        value={
                          filter.max && items.length > 0
                            ? getEndingsTitle(filter.max)
                            : ''
                        }

                        wrapperClasses="is-thin"
                        readOnly={true}
                      />
                    </div>
                    {items && (
                      <SimpleBar className="filter__checks">
                        <div className="checkbox-group">
                          {items.map((item) => (
                            <div
                              className="checkbox-group__item "
                              key={item.id}
                            >
                              <div
                                className="checkbox checkbox_type_btn"
                                data-title="Цена до"
                              >
                                <div className="checkbox__box">
                                  <input
                                    className="checkbox__control "
                                    type="radio"
                                    name="deadline-to"
                                    id={`deadline-to-${ident}-${item.id}`}
                                    value={item.id}
                                    onChange={(e) =>
                                      secondData && secondData.value == 'Y'
                                        ? null
                                        : handleChange(e, 'max')
                                    }
                                    checked={item.id == filter.max}
                                  />
                                  <label
                                    className="checkbox__label"
                                    htmlFor={`deadline-to-${ident}-${item.id}`}
                                    style={{
                                      cursor:
                                        secondData && secondData.value == 'Y'
                                          ? 'default'
                                          : 'pointer',
                                    }}
                                  >
                                    {item?.title}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                </div>
              </div>

              {isMobile &&
                <div className="mobile-filters__submit">
                  <button
                    className="button button__mobile-filters"
                    onClick={(e) => {
                      clearFilters(e)
                      hide()
                    }}
                  >
                    Очистить
                  </button>
                  <button
                    className="button button__mobile-filters"
                    onClick={(e) => {
                      e.preventDefault()
                      hide()
                    }
                    }
                  >
                    Применить
                  </button>
                </div>
              }
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default EndingsFilter;
