import React, { useMemo, useState } from "react";
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import useMedia from "../../hooks/useMedia";
import Dropdown from '../core/Dropdown';
import PhotoBlock from '../PhotoBlock';
const PhotosBlock = (props) => {
  const {
    plan,
    windowViewUrl,
    selfFloorPlan,
    flatPathCoordinates,
    floorPlan,
    photos,
    planBasement,
    generalPlan
  } = props;
  const getCurrentTab = () => {
    if (plan) return "plan"
    if (photos && photos?.some?.(item=>!item.empty)) return "photo"
    if (generalPlan) return "generalPlan"
    if (planBasement) return "planBasement"
  }

  const [current, setCurrent] = useState(getCurrentTab());
  const isMobile = useMedia("isMobile")
  const navigation = useMemo(() => {
    let nav = [];
    if(planBasement){
      nav.push({
        id: "planBasement",
        title: "Цоколь",
        type: "planBasement",
        key: "planBasement",
      });
    }
    if(plan){
    nav.push({
      id: "plan",
      title: "Планировка",
      type: "plan",
      key: "plan",
    });
  }
    if(photos && photos?.some?.(item=>!item.empty)){
      nav.push({
        id: "photo",
        title: "Фотографии",
        type: "photo",
        key: "photos",
      });
    }

    if(generalPlan){
      nav.push({
        id: "generalPlan",
        title: "Ген.план",
        type: "generalPlan",
        key: "generalPlan",
      });
    }
    setCurrent(getCurrentTab())
    return nav;
  }, [windowViewUrl, selfFloorPlan, flatPathCoordinates, floorPlan, generalPlan, plan]);

  return (
    <>
      {navigation.length > 1 && (
        <div className="flat__nav">
          {!isMobile ?
            <ul className="flat__nav-list">
              {navigation.map((nav, index) => (
                <li
                  className={`flat__nav-item`}
                  data-tab={nav.id}
                  onClick={() => setCurrent(nav.id)}
                >
                  <span
                    className={`flat__nav-link ${
                      nav.id === current ? `active` : ``
                    }`}
                  >
                    {nav.title}
                    {props[nav.key]?.length && <span className="complex__toggle-counter">{props[nav.key]?.length}</span>}
                  </span>
                </li>
              ))}
            </ul> :

            <Dropdown value={current} options={navigation} onChange={(current) => setCurrent(current)} />
          }
        </div>
      )}
      <div className={`flat__images gallery ${navigation?.length === 0 ? 'is-empty': ""}`}>
        {navigation.map((nav, index) => (
          <div
            className={`flat__image gallery__wrapper ${
              nav.id === current || navigation?.length === 0 ? `active` : ``
            }`}
          >
            {nav.type === "planBasement" && <PhotoBlock photos={[planBasement?.src]} identity="planBasement" /> }
            {nav.type === "photo" && <PhotoBlock photos={photos?.map?.(photo=>photo.src) || []} identity="photos"/> }
            {nav.type === "plan" && <PhotoBlock photos={plan?.map?.(photo=>photo.src) || []} identity="plan" /> }
            
            {nav.type === "generalPlan" && <PhotoBlock photos={[generalPlan?.src]} identity="generalPlan" /> }
            {/* {nav.type === "windowView" && <PhotoBlock photos={[getUrl(windowView.src)]} /> } */}

            {/* {nav.type === "iframe" && (

              <iframe
                src={windowViewUrl}
                title="Направление из окон"
                width="100%"
                height="400px"
              ></iframe>
            )} */}
          </div>
        ))}
        {navigation?.length === 0 && <PhotoBlock photos={[houseEmpty]} identity="plan" />}
      </div>
    </>
  );
};

export default PhotosBlock;
