import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Confirmation from "./Confirmation";

const Personal = props => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}/requests/confirm`} render={props => <Confirmation type="confirm" {...props} />} />
        <Route exact path={`${path}/requests/cancel`} render={props => <Confirmation type="cancel" {...props} />} />
      </Switch>

    </>
  );
};

export default Personal;
