import React from "react";
import { getUrl } from "../../api";
import NotFound from "../../components/Public/markup/detail/NotFound";

const columns = (isSmallLaptop, tabs = []) => [
    {
        dataIndex: "photo",
        render: ({ src }) => <img src={getUrl(src)} alt="" className="videos__table-img" />
    },
    {
        title: "Название",
        dataIndex: "title",
        render: v => <p className="h6">
            {v}
        </p>,
    },
    {
        title: "Спикер",
        dataIndex: "speaker",
        render: (speaker, { speakerPost }) => <>
            <div className="videos__table-speaker-name">
                {speaker}
            </div>
            <div className="videos__table-speaker-post">
                {speakerPost}
            </div>
        </>,
    },
    ...(!isSmallLaptop ? [{
        title: "Продолжительность",
        dataIndex: "duration",
    }] : []),
    {
        title: "Дата проведени",
        dataIndex: "activeFrom",
        render: date => {
            let time = new Date(date).toLocaleDateString("ru", {
                day: "numeric",
                month: "long",
                hour: "numeric",
                minute: "numeric",
            })
            return time.replace(',', ' в')
        },
    },
    {
        dataIndex: "type",
        render: ({ xmlId, title }) => <span
            className="videos__table-status"
            style={tabs.find(({ type }) => type === xmlId)?.style}
        >
            {title}
        </span>,
    },
];

export default columns;

export const LocaleNotFound = {
    emptyText: <NotFound forTable />
};