import { memo } from 'react';
import SubmitButton from '../../../SubmitButton';
import SimpleBar from 'simplebar-react';
import PrimaryPublicFilters from '../PrimaryPublicFilters';

const PublicPrimaryMapFilter = memo(props => {
  const {
    count,
    isLoading,
    onSumbit,
    resetFilter,
    disableSubmit,
    loaderColor
  } = props;


  return (
    <div className="sf__filter-block">
      <div className="sf__filter sf__filter_on-map">
        <SimpleBar className="sf__map-scroll sf__map-scroll_filter">
          <PrimaryPublicFilters {...props} />
        </SimpleBar>
        <SubmitButton
          className="sf__filter-btn button button_view_default sf__map-btn"
          onClick={onSumbit}
          isLoading={isLoading}
          type="button"
          disabled={disableSubmit}
          loaderColor={loaderColor}
        >
          {count}
        </SubmitButton>
        <span
          className="sf__clear-filter-btn link link_color_brand-3"
          onClick={resetFilter}
        >
          Очистить фильтр
        </span>
      </div>
    </div>
  );
});

export default PublicPrimaryMapFilter;
