import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { editClients, getClientData } from "../../actions/clients";
import useDocTitle from "../../hooks/useDocTitle";
import Preloader from "../Preloader";
import Form from "./Form";





const Add = (props) => {
  const { add, history, user } = props;

  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);

  const defaultValues = {
    id: 0,
    name: "",
    lastName: "",
    patronymic: "",
    phone: [""],
    email: [""],
    comment: "",
    city: "",
    street: "",
    house: "",
    number: "",
    managerId: user?.toString(),
    status: "1",
    addToFavourite: false
  };

  useDocTitle("Добавление нового клиента")

  useEffect(() => {
    getClientData().then(
      (data) => {
        setData({
          agents: data.agents.map( agent => ({id: agent.id, title: `${agent.lastName} ${agent.name}`})),
          statuses: data.statuses.map( status => ({id: status.id, title: status.name, color: status.color})),
          defaultValues: defaultValues
        });
        setFetching(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
 
      {data && (
        <Form
          add={add}
          history={history}
          user={user}
          {...data}
        />
      )}
      <Preloader loading={fetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  add: (form) => dispatch(editClients(form)),
});

const mapStateToProps = (state) => ({
  user: state.user.info.id
});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
