import React, { memo } from "react";

const iconStyles = { marginRight: 3, maxWidth: 22 }

const Tabs = memo(({ tabs, active, setTab }) => {
    if (tabs.length < 2) return null
    return <ul className="complex__images-navs">
        {tabs.map((nav, i) => (
            <li
                className={`complex__images-nav ${i === active ? `active` : ``}`}
                onClick={() => setTab(i)}
                key={i}
            >
                {nav.icon && <object
                    type="image/svg+xml"
                    data={nav.icon}
                    style={iconStyles}
                />}
                {nav.title}
            </li>
        ))}
    </ul>
})
export default Tabs
