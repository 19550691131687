import React from 'react';
import useMedia from '../../hooks/useMedia';

const CircularProgressBar = ({ places, noProgress }) => {
    const isMobile = useMedia("isMobile");
  
    const strokeWidth = !noProgress ? 4 : 0;
    const sqSize = !isMobile ? 95 : 80;
  
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    const percent = places.left / places.max * 100
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * (percent) / 100;
    const getColorByPercent = percent => {
      if (percent > 69) return "#2CBA2E"
      if (percent > 29) return "#FFE600"
      return "#FF2D23"
    }
  
    return (
      <svg
        width={sqSize}
        height={sqSize}
        viewBox={viewBox}>
  
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`} />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          stroke={getColorByPercent(percent)}
        />
        <text
          className="h2 pe__sign-number"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle">
          {`${places.left}`}
        </text>
      </svg>
    );
  };
  export default CircularProgressBar
