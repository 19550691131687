import { useState } from 'react';
import { memo } from 'react';
import useMedia from '../../../../hooks/useMedia';
import AboutShowMore from './AboutShowMore';
import Audio from './Audio';

const About = memo(({ title, text, advantages, audioReview }) => {
  const isTablet = useMedia('isTablet');
  const isSmallMobile = useMedia('isSmallMobile');
  const [showFullText, setShowFullText] = useState(false);
  const toggleText = () => setShowFullText((prev) => !prev);
  // const [previewText, fullText] = isTablet
  const [previewText, fullText] = isSmallMobile
    ? [text.split('.')?.[0], text.split('.')?.slice(1)]
    : [text];

  return (
    <section className="sf__about">
      <h3 className="h3-l sf__h3">{title}</h3>


      {
        audioReview && <Audio audioReview={audioReview} />
      }

      <div className="sf__about-text medium-text-l">
        {previewText && (
          <p
            dangerouslySetInnerHTML={{
              __html: previewText,
            }}
          />
        )}
        {fullText && showFullText && (
          <p
            dangerouslySetInnerHTML={{
              __html: fullText,
            }}
          />
        )}
      </div>

      {!isTablet && !!advantages?.length && (
        <div className="sf__advantages">
          {advantages.map((advantage) => (
            <div className="sf__advantage">{advantage?.description ?? advantage}</div>
          ))}
        </div>
      )}

      {isTablet && (
        <AboutShowMore
          onClick={toggleText}
          text={showFullText ? 'Скрыть' : 'Подробнее'}
        />
      )}
    </section>
  );
});

export default About;
