// Copy imports /////////////////////////////////////////////////////////////
import uniq from "lodash.uniq";
import React, { useEffect, useMemo, useState } from "react";
import InputMask from 'react-input-mask';
import { useModal } from "react-modal-hook";
import { useParams } from "react-router";
import { Map, SearchControl, YMaps } from 'react-yandex-maps';
import useMedia from "../../../../hooks/useMedia";
import { formatMoney } from "../../../../utils";
import Checkbox from "../../../core/Checkbox";
import Dropdown from '../../../core/Dropdown';
import DropdownCustomColor from "../../../core/DropdownCustomColor";
import DropdownMulti from '../../../core/DropdownMulti';
import Modal from "../../../core/Modal";
import SelectFilter from "../../../FilterRealty/SelectFilter";
import Preloader from '../../../Preloader';
import SubmitButton from '../../../SubmitButton';
import { errorToast, standartErrorToast, standartSuccessToast } from '../../../toasts';
import { editObject, getData } from "./api";
import { FileUploader, getDefaultConfig, processFiles, RenderTabs } from "./Components";


const quarters = [{
  id: 1,
  title: `I квартал`
},
{
  id: 2,
  title: `II квартал`
},
{
  id: 3,
  title: `III квартал`
},
{
  id: 4,
  title: `IV квартал`
}]

const replaceToNumber = (e, setter) => {
  const value = e.target.value.replace(/[^\d\.]/gi, ".");
  if (!isNaN(+value)) setter(value);
}

const AddNewObj = ({ history }) => {
  const { id } = useParams();

  const [isLoading, setisLoading] = useState(true)
  const [submitOnProcess, setSubmitOnProcess] = useState(false)

  const [data, setData] = useState({})
  const [config, setConfiguration] = useState({})

  const [mapInstance, setMapInstance] = useState(null);
  const mapCenter = [59.9342802, 30.3350986]
  const [ymaps, setYmaps] = useState(null);
  const [searchRef, setSearchRef] = useState(null)
  const isMobile = useMedia("isMobile")
  const isLaptop = useMedia("isLaptop");
  const [errors, setErrors] = useState([])
  const [timeStamp, setTimeStamp] = useState(Date.now()) //костыль но иначе переделывать супердофига, а завтра дедлайн

  const hasError = (fieldName, currentValue) => {
    if (currentValue) return ""
    if (errors.includes(fieldName)) return "input__error"
  }

  useEffect(() => {
    getData({ id }).then(resp => {
      if (id) {
        setConfiguration({
          ...resp.object,
          plan: resp.object?.plan ? processFiles([resp.object.plan]) : [],
          photos: processFiles(resp.object?.photos),
          oldPlan: resp.object?.plan ? processFiles([resp.object.plan]) : [], // тк для удаления надо помечать del="Y"
          oldPhotos: processFiles(resp.object?.photos),
          endingYear: resp.object.ending ? resp.object.ending.toString().slice(0, 4) : "",
          quarter: resp.object.ending ? +resp.object.ending.toString().slice(-1) : "",
          inFlat: resp.object.inFlat ? resp.object.inFlat : undefined,
          year: resp.object.year ? resp.object.year : undefined,
          features: resp.object.features?.map(id=>id.toString()) || []
        })
      } else {
        setConfiguration(getDefaultConfig(resp.data))
      }
      setData(resp.data)
    }).finally(() => setisLoading(false))
  }, [])

  useEffect(() => {
    if (!ymaps || !mapInstance) return
    const onChange = coordinates => setConfig(coordinates, "coordinates")

    const placeMark = new ymaps.Placemark(!!config.coordinates?.length ? config.coordinates : mapInstance.getCenter(), {
    }, {
      iconLayout: "default#image",
      iconImageHref: "/assets/images/place.png",
      iconImageSize: [25, 40],
      iconImageOffset: [-12.5, -40],
      draggable: true
    })
    mapInstance.geoObjects
      .removeAll()
    mapInstance.geoObjects
      .add(placeMark)
    placeMark.events.add(
      ['dragend'],
      () => onChange(placeMark.geometry.getCoordinates())
    );
    mapInstance.events.add('click', function (e) {
      var coords = e.get('coords');
      placeMark.geometry.setCoordinates(coords)
      onChange(coords)
    });
    if (searchRef) {
      searchRef.events.add(
        ['resultselect'],
        (e) => {
          searchRef.getResult(0)
          searchRef.getResult(0).then(resp => {
            const newCords = resp.geometry.getCoordinates()
            onChange(newCords)
            placeMark.geometry.setCoordinates(newCords)
            searchRef.clear()
          }
          )
        }
      );
    }
  }, [ymaps, mapInstance, searchRef, config])
  const [showMapModal, closeMapModal] = useModal(({ }) => (
    <Modal hideDefaultClose={true} classes="modal_task c-modal c-modal_preview" close={closeMapModal} closeByPastClick={true}>
      <div style={isMobile ? { width: '100%', height: 280 } : { width: 720, height: 600 }}>
        {!isMobile &&
          <button type="button"
            className="button c-modal__close c-modal__icon-btn c-modal__icon-btn_map"
            aria-label="Закрыть окно"
            onClick={closeMapModal}
          >
            <span className="c-modal__icon">
              <svg className="icon icon_cross">
                <use xlinkHref="#cross" />
              </svg>
            </span>
          </button>
        }
        <YMaps
          query={{ apikey: "1c00f913-9b45-4a8e-921c-b61d324deb58" }}
        >
          <Map
            defaultState={{
              center: mapCenter,
              zoom: 16,
            }}
            className="complex__map-wrap"
            instanceRef={setMapInstance}
            modules={["Placemark",]}
            onLoad={setYmaps}
          >
            <SearchControl
              instanceRef={setSearchRef}
            />
          </Map>
        </YMaps>
      </div>
    </Modal>
  ), []);

  const isRent = useMemo(() => {
    if (!data.saleTypes) return
    const item = data.saleTypes.find(item => item.id == config.saleType)
    if (item?.xmlId === "rent") return true
  }, [config, data])

  const isSecondary = useMemo(() => {
    if (!data.objectTypes) return
    const item = data.objectTypes.find(item => item.id == config.objectType)
    if (item?.xmlId === "secondary") return true
  }, [config, data])

  const isAssignment = useMemo(() => !isRent && !isSecondary, [isSecondary, isRent])

  const filteredTypes = useMemo(() => {
    if (!data.types) return []
    return data.types.filter(item => {
      if (isSecondary) return true
      if (item.title === "Апартаменты" || item.title === "Квартира") return true
    })
  }, [isSecondary, data])

  const statusTitleKey = useMemo(() => {
    if (!data.saleTypes || !config.saleType) return "title"
    const currentSaleType = data.saleTypes.find(type => type.id == config.saleType)
    return currentSaleType.xmlId === "sale" ? "title" : "rentTitle"
  }, [config.saleType, data])

  const isRoom = useMemo(() => {
    if (!data.types) return false
    const typeObject = data.types.find(type => type.id == config.type)
    return typeObject?.xmlId === "room"
  }, [config.type, data])

  useEffect(() => {
    if (isRoom) {
      setConfig("", "roomType")
    }
  }, [isRoom])

  const isHouse = useMemo(() => {
    if (!data.types || !config.type) return false
    const item = data.types.find(item => item.id === config.type)
    return item.xmlId === "house" || item.xmlId === "town_house"
  }, [config, data])
  useEffect(() => {
    if (isHouse) {
      setConfig("", "floor")
    }
  }, [isHouse])

  const setConfig = (value, ident) => {
    setConfiguration(prev => ({ ...prev, [ident]: value }))
  };

  const mergeConfig = (value, ident) => setConfiguration(prev => ({ ...prev, [ident]: [...prev[ident], ...value] }))


  const appendFiles = (files, formData, ident) => {
    for (const file of files) {
      if ("fileObj" in file) {
        formData.append(ident, file.fileObj, file.name)
      }
    }
    return formData
  }
  const processDeletedFiles = (files, oldFiles, formData, ident) => {
    if (!oldFiles?.length) return;
    const oldFilesWithFlag = oldFiles.map(oldFile => {
      oldFile.isDeleted = !files.some(file => file.id == oldFile.id)
      return oldFile
    })
    for (let i = 0; i < oldFilesWithFlag.length; i++) {
      if (ident === "plan") {
        formData.append(`${ident}[id]`, oldFilesWithFlag[i].id)
        if (oldFilesWithFlag[i].isDeleted) {
          formData.append(`${ident}[del]`, "Y")
        }
        break
      }
      formData.append(`${ident}[${i}][id]`, oldFilesWithFlag[i].id)
      if (oldFilesWithFlag[i].isDeleted) {
        formData.append(`${ident}[${i}][del]`, "Y")
      }
    }
  }

  const submit = () => {
    setSubmitOnProcess(true)

    const tmpData = { ...config };
    delete tmpData.plan;
    delete tmpData.photos;
    delete tmpData.oldPlan;
    delete tmpData.oldPhotos;
    delete tmpData.endingYear;
    delete tmpData.quarter;
    delete tmpData.description;
    delete tmpData.ending;

    tmpData.coordinates = tmpData.coordinates ? tmpData.coordinates.toString() : null;
    const formData = new FormData();
    // удаляем лишние данные
    if(!isRent){
      delete tmpData.rentType
    }
    if(isRent){
      delete tmpData.objectType;
      delete config.isMortgage
    }
    if(isHouse){
      delete tmpData.repair
      delete tmpData.year
      delete tmpData.balcony;
      delete tmpData.squareKitchen;
      delete tmpData.squareLiving;
      delete tmpData.squareTotal;
      delete tmpData.roomType
    }
    if(!isHouse){
      delete tmpData.squareHouse;
      delete tmpData.countBedrooms;
      delete tmpData.lendStatus;
      delete tmpData.squareParcel;
      delete tmpData.heating;
      delete tmpData.features;
    }
    if(config.isHanded){
      delete tmpData.endingYear;
      delete tmpData.quarter;
    }
    if (!config.isHanded && isAssignment && (config.endingYear > 2050 || config.endingYear < 1850 || !config.quarter)) {
      errorToast("Заполните срок сдачи")
      setErrors(prev => [...prev, "endingYear", "quarter"])
      setSubmitOnProcess(false)
      return
    }

    if (!config.isHanded && isAssignment) {
      formData.append("ending", `${config.endingYear}${config.quarter}`)
    }
    for (const [key, values] of Object.entries(tmpData)) {
      if (Array.isArray(values)) {
        for (const value of values) {
          formData.append(`${key}[]`, value)
        }
        continue;
      }
      if (!!values) {
        formData.append(key, values)
      }
    }
    formData.append("description", config.description ? config.description : "")
    processDeletedFiles(config.photos, config.oldPhotos, formData, "photos");
    processDeletedFiles(config.plan, config.oldPlan, formData, "plan");
    appendFiles(config.plan, formData, "plan");
    appendFiles(config.photos, formData, "photos[]");

    editObject(formData)
      .then(res => {
        standartSuccessToast(res)
        if (!id) {
          setConfiguration(getDefaultConfig(data))
          setTimeStamp(Date.now())
        }
        setErrors([])
      }, errors => {
        standartErrorToast(errors)
        setErrors(errors[0]?.customData?.fields || [])
      })
      .finally(() => setSubmitOnProcess(false));
  }

  const {
    balcony,
    inFlat,
    objectTypes,
    rentTypes,
    repairs,
    restrooms,
    roomTypes,
    saleTypes,
    statuses,
    types,
    clients,
    materialHouses,
    heating,
    lendStatus,
    features,
    decoration
  } = data


  if (isLoading) return <Preloader loading={true} />;


  const endOfSquare = isLaptop ? `.` : `ощадь`;

  return (
    <div className="constructor aa">
      <section className="constructor__main for-admin">
        <section className="admin constructor__section">
          <div className="constructor__container">
            <div className="admin__wrapper constructor__wrapper">
              <div className="admin__header-wrapper">
                <div className="admin__header">
                  {
                    isLaptop && (
                      <button
                        className="button button_view_outline-with-icon aa__back"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        <svg className="icon icon_back">
                          <use xlinkHref="#back"></use>
                        </svg>
                        <span>Вернуться</span>
                      </button>
                    )
                  }


                  <h1 className="h1 admin__h1">{id ? "Редактирование объявления" : "Новое объявление"}</h1>

                  <a
                    href="https://pan-promo.ru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="admin__showpromo-anchor admin__anchor"
                  >
                    Посмотреть промо
                  </a>
                </div>
              </div>


              <div className="aa__box">
              {
                !isLaptop && (
                  <button
                    className="button button_view_outline-with-icon aa__back"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    <svg className="icon icon_back">
                    <use xlinkHref="#back"></use>
                    </svg>
                    <span>Вернуться</span>
                  </button>
                )
              }

                <div className="aa__content">
                  <div className="aa__content-left">
                    <RenderTabs
                      title="Тип объявления"
                      items={saleTypes}
                      value={config.saleType}
                      onClick={value => setConfig(value, "saleType")}
                    />
                    {!isRent && <RenderTabs
                      title="Тип недвижимости"
                      items={objectTypes}
                      value={config.objectType}
                      onClick={value => setConfig(value, "objectType")}
                    />}
                    {isRent && <RenderTabs
                      title="Тип аренды"
                      items={rentTypes}
                      value={config.rentType}
                      onClick={value => setConfig(value, "rentType")}
                    />}
                    <RenderTabs
                      title="Тип объекта"
                      items={filteredTypes}
                      value={config.type}
                      onClick={value => setConfig(value, "type")}
                      addClass="aa__switchers-mob"
                    />
                    <div className="aa__block">
                      <h5 className="h55 aa__title">Характеристики объекта</h5>
                      <div className="modal-form">
                        <div className="modal-form__wrapper">

                          <div className="modal-form__row">
                            <div className="modal-form__item">
                              <label className="input input_width_available input_type_form">
                                <span className="input__label">Адрес <span className="is-red-text sell__required">*</span></span>
                                <input
                                  className={`input__control ${hasError("address", config.address)}`}
                                  value={config.address ?? ""}
                                  onChange={e => setConfig(e.target.value, "address")}
                                  placeholder="Например, Королева пр-кт., 20, к.6"
                                  type="search"
                                />

                              </label>
                            </div>

                            <button type="button" onClick={showMapModal} className="button aa__btn aa__btn_map">На карте</button>
                          </div>

                          <div className="modal-form__row">
                            <div className="modal-form__item modal-form__item_half">

                              <label className="input input_width_available input_type_form input_preudo_rub">
                                <span className="input__label">{isRent ? "Стоимость аренды" : "Цена за объект"} <span className="is-red-text sell__required">*</span></span>
                                <input
                                  className={`input__control ${hasError("price", config.price)}`}
                                  value={formatMoney(config.price)}
                                  onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "price")}
                                  type="search"
                                />
                              </label>

                            </div>

                            {!isRent && <div className="modal-form__item modal-form__item_half modal-form__item_flex">
                              <div className="aa__mortgage-checkbox">
                                <Checkbox
                                  id={'mortgage'}
                                  view="invert"
                                  labelText={`Возможна ипотека`}
                                  onChange={event => setConfig(event.target.checked ? 1 : 0, "isMortgage")}
                                  checked={!!config.isMortgage}
                                />
                              </div>

                            </div>}
                          </div>

                          {
                            !isHouse &&
                            <>
                              <div className="modal-form__row">
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">{`Кол-во комн${isLaptop ? `.` : `ат`}`} {!isRoom && <span className="is-red-text sell__required">*</span>}</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={roomTypes}
                                        value={config.roomType}
                                        onChange={val => setConfig(val, "roomType")}
                                        placeholder="–"
                                        error={hasError("roomType", config.roomType)}
                                        disabled={isRoom}
                                      />
                                    </div>
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Этаж {!isHouse && <span className="is-red-text sell__required">*</span>}</span>
                                    <input
                                      className={`input__control ${hasError("floor", config.floor)}`}
                                      value={config.floor ?? ""}
                                      onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "floor")}
                                      placeholder="Этаж"
                                      type="search"
                                      disabled={isHouse}
                                    />
                                  </label>
                                </div>

                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Этажность <span className="is-red-text sell__required">*</span></span>
                                    <input
                                      className={`input__control ${hasError("floors", config.floors)}`}
                                      value={config.floors ?? ""}
                                      onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "floors")}
                                      placeholder="Этажность"
                                      type="search"
                                    />
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">{`Общая пл${endOfSquare}`} <span className="is-red-text sell__required">*</span></span>
                                    <input
                                      className={`input__control ${hasError("squareTotal", config.squareTotal)}`}
                                      value={config.squareTotal ?? ""}
                                      onChange={e => replaceToNumber(e, val => setConfig(val, "squareTotal"))}
                                      placeholder="S общая"
                                      type="search"
                                    />
                                  </label>
                                </div>


                              </div>
                              <div className="modal-form__row">
                                <div className="modal-form__item modal-form__item_145">
                                    <label className="input input_width_available input_type_form">
                                      <span className="input__label">{`Жилая пл${endOfSquare}`}</span>
                                      <input
                                        className={`input__control ${hasError("squareLiving", config.squareLiving)}`}
                                        value={config.squareLiving ?? ""}
                                        onChange={e => replaceToNumber(e, val => setConfig(val, "squareLiving"))}
                                        placeholder="S жилая"
                                        type="search"
                                      />
                                    </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">{`Пл${endOfSquare}`} кухни</span>
                                    <input
                                      className={`input__control ${hasError("squareKitchen", config.squareKitchen)}`}
                                      value={config.squareKitchen ?? ""}
                                      onChange={e => replaceToNumber(e, val => setConfig(val, "squareKitchen"))}
                                      placeholder="S кухни"
                                      type="search"
                                    />
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Балкон</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={balcony}
                                        value={config.balcony}
                                        onChange={val => setConfig(val, "balcony")}
                                        placeholder="–"
                                        error={hasError("balcony", config.balcony)}
                                      />
                                    </div>
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Санузел</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={restrooms}
                                        value={config.restroom}
                                        onChange={val => setConfig(val, "restroom")}
                                        placeholder="–"
                                        error={hasError("restroom", config.restroom)}
                                      />
                                    </div>
                                  </label>
                                </div>



                              </div>
                              <div className="modal-form__row">
                              {(isSecondary || isRent) && <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Ремонт <span className="is-red-text sell__required">*</span></span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={repairs}
                                        value={config.repair}
                                        onChange={val => setConfig(val, "repair")}
                                        placeholder="–"
                                        error={hasError("repair", config.repair)}
                                      />
                                    </div>
                                  </label>
                                </div>}

                                {isSecondary && !isRent && <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Год постройки</span>
                                    <input
                                      className={`input__control ${hasError("year", config.year)}`}
                                      value={config.year ?? ""}
                                      onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "year")}
                                      placeholder="Год постройки"
                                      maxLength={4}
                                      type="search"
                                    />
                                  </label>
                                </div>}
                                {isRent && <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form" htmlFor="nothing">
                                    <span className="input__label">В квартире</span>
                                    <div className="select select_type_form">
                                      {/* <DropdownMulti
                                        data={{ title: "В квартире", identity: "inflat" }}
                                        items={inFlat}
                                        isMobile={isMobile}
                                        onChange={val => setConfig(val, "inFlat")}
                                        value={config.inFlat}
                                      />  */}
                                      <SelectFilter
                                        onChange={val => setConfig(val, "inFlat")}
                                        isExcludable={false}
                                        isAutocomplete={false}
                                        items={inFlat}
                                        filterValues={config?.inFlat?.map(v=>v.toString())}
                                        excludeValues={[]}
                                        data={{
                                          title: 'В квартире',
                                          autocompleteTitle: '',
                                          identity: 'inFlat',
                                        }}
                                        isMobile={isMobile}
                                      />
                                    </div>
                                  </label>
                                </div>}
                                {
                                isAssignment &&
                                <>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Отделка</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={decoration}
                                        value={config.decoration}
                                        onChange={val => setConfig(val, "decoration")}
                                        placeholder="–"
                                        error={hasError("decoration", config.decoration)}
                                      />
                                    </div>
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Год сдачи <span className="is-red-text sell__required">*</span></span>
                                    <InputMask
                                      className={`input__control ${hasError("endingYear", config.endingYear)}`}
                                      value={config.endingYear ?? ""}
                                      onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "endingYear")}
                                      mask="9999"
                                      maskPlaceholder="-"
                                      disabled={config.isHanded}
                                    />
                                  </label>
                                </div>
                                  <div className="modal-form__item modal-form__item_145">
                                    <label className="input input_width_available input_type_form">
                                      <span className="input__label">Квартал сдачи <span className="is-red-text sell__required">*</span></span>
                                      <div className="select select_type_form">
                                        <Dropdown
                                          options={quarters}
                                          value={config.quarter}
                                          onChange={val => setConfig(val, "quarter")}
                                          placeholder="–"
                                          error={hasError("quarter", config.quarter)}
                                          disabled={config.isHanded}
                                        />
                                      </div>
                                    </label>
                                  </div>
                                  <div className="modal-form__item modal-form__item_145 modal-form__item_flex">
                                    <div className="aa__mortgage-checkbox">
                                      <Checkbox
                                        id={'isHanded'}
                                        view="invert"
                                        labelText={`Сдан`}
                                        onChange={event => setConfig(event.target.checked ? 1 : 0, "isHanded")}
                                        checked={!!config.isHanded}

                                      />
                                    </div>

                                  </div>
                                </>

                                }
                              </div>
                            </>
                          }

                          {
                            isHouse &&
                            <>
                              <div className="modal-form__row">
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">{`Пл${endOfSquare} дома`} <span className="is-red-text sell__required">*</span></span>
                                    <input
                                      className={`input__control ${hasError("squareHouse", config.squareHouse)}`}
                                      value={config.squareHouse ?? ""}
                                      onChange={e => replaceToNumber(e, val => setConfig(val, "squareHouse"))}
                                      placeholder="S дома"
                                      type="search"
                                    />
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">{`Пл${endOfSquare} участка`} <span className="is-red-text sell__required">*</span></span>
                                    <input
                                      className={`input__control ${hasError("squareParcel", config.squareParcel)}`}
                                      value={config.squareParcel ?? ""}
                                      onChange={e => replaceToNumber(e, val => setConfig(val, "squareParcel"))}
                                      placeholder="S участка"
                                      type="search"
                                    />
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_half">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Материал дома</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={materialHouses}
                                        value={config.materialHouses}
                                        onChange={val => setConfig(val, "materialHouses")}
                                        placeholder="–"
                                        error={hasError("materialHouses", config.materialHouses)}
                                      />
                                    </div>
                                  </label>
                                </div>

                              </div>

                              <div className="modal-form__row">

                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Этажей в доме <span className="is-red-text sell__required">*</span></span>
                                    <input
                                      className={`input__control ${hasError("floors", config.floors)}`}
                                      value={config.floors ?? ""}
                                      onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "floors")}
                                      placeholder="Этажность"
                                      type="search"
                                    />
                                  </label>
                                </div>

                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">{`Кол-во спален`}</span>
                                    <input
                                      className={`input__control ${hasError("countBedrooms", config.countBedrooms)}`}
                                      value={config.countBedrooms ?? ""}
                                      onChange={e => replaceToNumber(e, val => setConfig(val, "countBedrooms"))}
                                      placeholder="-"
                                      type="search"
                                    />
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Санузел</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={restrooms}
                                        value={config.restroom}
                                        onChange={val => setConfig(val, "restroom")}
                                        placeholder="–"
                                        error={hasError("restroom", config.restroom)}
                                      />
                                    </div>
                                  </label>
                                </div>
                                <div className="modal-form__item modal-form__item_145">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Отопление</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                        options={heating}
                                        value={config.heating}
                                        onChange={val => setConfig(val, "heating")}
                                        placeholder="–"
                                        error={hasError("heating", config.heating)}
                                      />
                                    </div>
                                  </label>
                                </div>

                              </div>

                              <div className="modal-form__row">
                                <div className="modal-form__item modal-form__item_half">
                                  <label className="input input_width_available input_type_form">
                                    <span className="input__label">Статус земли</span>
                                    <div className="select select_type_form">
                                      <Dropdown
                                          options={lendStatus}
                                          value={config.lendStatus}
                                          onChange={val => setConfig(val, "lendStatus")}
                                          placeholder="–"
                                          error={hasError("lendStatus", config.lendStatus)}
                                        />
                                    </div>
                                  </label>
                                </div>

                                <div className="modal-form__item modal-form__item_half">
                                  <div className="input input_width_available input_type_form">
                                    <span className="input__label">Дополнительно</span>
                                    <div className="select select_type_form">

                                      <DropdownMulti
                                        data={{
                                          title: "Дополнительно",
                                          identity: "features",
                                        }}
                                        items={features}
                                        onChange={val => setConfig(val, "features")}
                                        value={config.features}
                                    />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }


                          {/* <div className="modal-form__row">
                            <div className="modal-form__item modal-form__item_half">

                              <label className="input input_width_available input_type_form input_preudo_rub">
                                <span className="input__label">Цена за объект <span className="is-red-text sell__required">*</span></span>
                                <input
                                  className="input__control"
                                  value={formatMoney(config.price)}
                                  onChange={e => setConfig(e.target.value.replace(/[\s\D]/g, ""), "price")}
                                />
                              </label>

                            </div>
                            <div className="modal-form__item modal-form__item_145">
                              <label className="input input_width_available input_type_form">
                                <span className="input__label">Кол-во комнат <span className="is-red-text sell__required">*</span></span>
                                <div className="select select_type_form">
                                  <Dropdown
                                    options={roomTypes}
                                    value={config.roomType}
                                    onChange={val => setConfig(val, "ending")}
                                    placeholder="–"
                                  />
                                </div>
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="aa__block">
                      <h5 className="h55 aa__title">Фотографии объекта</h5>

                      <div className={`aa__info`}>
                        <div className="aa__info-icon">
                          <svg className="icon icon_info">
                            <use xlinkHref="#info"></use>
                          </svg>
                        </div>

                        <p className="color-brand-3">Объявления с планировкой и фотографиями более привлекательны для арендаторов и&nbsp;покупателей</p>
                      </div>

                      <div className="modal-form">
                        <div className="modal-form__row modal-form__row_mt_32">
                          <FileUploader
                            label={`Добавить планировку`}
                            onFilesChange={files => setConfig(files, "plan")}
                            filesList={config.plan}
                            ident="plan"
                            maxFiles={1}
                            key={timeStamp + 1}
                          />
                          <FileUploader
                            label={`Добавить фотографии`}
                            key={timeStamp + 2}
                            onFilesChange={(files, type, index) => {
                              type === "delete" ? setConfiguration(prev => {
                                const photos = [...prev.photos]
                                photos.splice(index, 1);
                                return {
                                  ...prev,
                                  photos
                                }
                              }) : setConfiguration(prev => {
                                const photos = uniq([...prev.photos, ...files])
                                return {
                                  ...prev,
                                  photos
                                }
                              })
                            }}
                            filesList={config.photos}
                            ident="photos"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="aa__block">
                      <h5 className="h55 aa__title">Видео</h5>

                      <div className={`aa__info`}>
                        <div className="aa__info-icon">
                          <svg className="icon icon_info">
                            <use xlinkHref="#info"></use>
                          </svg>
                        </div>

                        <p className="color-brand-3">Объекты с видео-обзором более привлекательны</p>
                      </div>

                      <div className="modal-form">
                        <div className="modal-form__row">
                          <div className="modal-form__item">
                            <label className="input input_width_available input_type_form">
                              <input
                                className={`input__control ${hasError("videoYoutube")}`}
                                placeholder="Ссылка на youtube"
                                value={config.videoYoutube ?? ""}
                                onChange={e => setConfig(e.target.value, "videoYoutube")}
                                maxLength={60}
                                type="text"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="aa__block">
                      <h5 className="h55 aa__title">Размещение</h5>

                      <div className="modal-form aa__placing">
                        <div className="modal-form__row">
                          <div className="modal-form__item">

                            <div className="aa__flex">
                              <Checkbox
                                id={'highlight'}
                                view="red"
                                labelText={`Выделить объявление`}
                                onChange={event => setConfig(event.target.checked ? 1 : 0, "isHighlight")}
                                checked={!!config.isHighlight}
                              />
                              <div className="info aa__info-popup">
                                <svg className="icon icon_info">
                                  <use xlinkHref="#info" />
                                </svg>
                                <div className="info__popup">
                                  <p>Данное объявление будет выделено цветом и заметно отличатся от остальных</p>
                                </div>
                              </div>
                            </div>


                            <div className="aa__images aa__flex">
                              <img src={require('../../../../assets/images/constructor/placing-1.svg').default} alt="" />
                              <img src={require('../../../../assets/images/constructor/placing-2.svg').default} alt="" />
                            </div>
                          </div>
                          <div className="modal-form__item">
                            <div className="aa__flex">
                              <Checkbox
                                id={'online-show'}
                                view="red"
                                labelText={`On-line показ`}
                                onChange={event => setConfig(event.target.checked ? 1 : 0, "isOnlineShow")}
                                checked={!!config.isOnlineShow}
                              />
                              <div className="info aa__info-popup">
                                <svg className="icon icon_info">
                                  <use xlinkHref="#info" />
                                </svg>
                                <div className="info__popup">
                                  <p>На странице объекта будет показана информация о Вашей готовности показать объект по видео-связи</p>
                                </div>
                              </div>
                            </div>

                            <div className="aa__images aa__flex">
                              <img src={require('../../../../assets/images/constructor/placing-3.svg').default} alt="" />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="aa__block">
                      <h5 className="h55 aa__title">Заголовок и описание</h5>
                      <div className="modal-form">
                        <div className="modal-form__row">
                          <div className="modal-form__item">
                            <label className="input input_width_available input_type_form">
                              <span className="input__label">Заголовок (не более 30 символов)</span>
                              <input
                                className={`input__control ${hasError("title", config.title)}`}
                                placeholder="Краткое описание объекта"
                                value={config.title ?? ""}
                                onChange={e => setConfig(e.target.value, "title")}
                                maxLength={30}
                                type="search"
                              />
                            </label>
                          </div>
                        </div>

                        <div className="modal-form__row">
                          <div className="modal-form__item">
                            <label className="input input_width_available input_type_form">
                              <span className="input__label">Описание</span>
                              <textarea
                                className="text-area text-area_full"
                                placeholder="Хорошо заполненное и интересное описание помогает продать объект быстрее"
                                value={config.description ?? ""}
                                onChange={e => setConfig(e.target.value, "description")}
                                rows={10}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="aa__content-right">
                    <div className="aa__block">
                      <h5 className="h55 aa__title">Закрепить за клиентом</h5>
                      <div className="modal-form">
                        <div className="modal-form__wrapper">
                          <div className="modal-form__row">
                            <div className="modal-form__item">
                              <label className="input input_width_available input_type_form">
                                <div className="select select_type_form">
                                  <Dropdown
                                    options={clients}
                                    value={config.client}
                                    onChange={val => setConfig(val, "client")}
                                    placeholder="–"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="add-client js-show active"
                        style={{ display: "flex" }}
                        onClick={() => window.open("/personal/clients/add", "_blank")}
                      >
                        <button
                          className="button button_type_add"
                          type="button"
                        ></button>
                        <span>Добавить клиента</span>
                      </div>
                    </div>

                    <div className="aa__block">
                      <h5 className="h55 aa__title">Статус объекта</h5>
                      <div className="modal-form">
                        <div className="modal-form__wrapper">
                          <div className="modal-form__row">
                            <div className="modal-form__item">
                              <label className="input input_width_available input_type_form">
                                <div className="select select_type_form">
                                  <DropdownCustomColor
                                    options={statuses}
                                    value={config.status}
                                    onChange={val => setConfig(val, "status")}
                                    titleKey={statusTitleKey}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <SubmitButton
                className="button button_view_default"
                onClick={submit}
                isLoading={submitOnProcess}
                type="button"
              >
                {id ? "Сохранить" : "Добавить объект"}
              </SubmitButton>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};


export default AddNewObj;
