import React, { useState, useEffect, Suspense } from "react";
import {connect, useSelector} from "react-redux";
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
  useLocation, useHistory,
} from "react-router-dom";

import List from "./List";
import Apartment from "./Apartment";
import Complex from "./Complex";
import Preloader from "../Preloader";
import PublicHeader from "./PublicHeader";
import MobileFooter from "../Footer/MobileFooter";
import Header from "./markup/Header";

import { media } from "../../utils";
import useApi from "../../hooks/useApi";
import useRootCSS from "../../hooks/useRootCSS";

const Public = (props) => {
  const { path } = useRouteMatch();
  const { savedFilterXmlId, city, id } = useParams();

  const { agent, isFilterDisabled} = props;
  const searchParam = new URLSearchParams(useLocation().search)
  let filterType = searchParam.get("filtertype")? searchParam.get("filtertype"): "apartments";

  let location = useLocation();
  let splitUrl = location.pathname.split('/')
  const savedFilterSettings = useSelector(state => {
    if (splitUrl[5] === 'complex') {
      return state.complex.savedFilterSettings ?? {}
    } else if (splitUrl[5] === 'apartment') {
      return state.apartment.savedFilterSettings ?? {}
    }
  })

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  return (
    <div>
      <Suspense fallback={<Preloader loading={true} />}>
        {agent && !isFilterDisabled && !savedFilterSettings?.hideHeader && (
          <div className="sf wrapper sf_inner-public">
            <Header {...agent} savedFilterSettings={savedFilterSettings} />
          </div>
          
          // <PublicHeader agent={agent} savedFilterXmlId={savedFilterXmlId} filterType={filterType}/>
        )}
        <Switch>
          {/* <Route exact path={`${path}`} component={List} /> */}
          <Route
            exact
            path={`/public/apartments/:savedFilterXmlId/:city/apartment/:id`}
            component={Apartment}
          />
          <Route
          exact
            path={`/public/apartments/:savedFilterXmlId/:city/complex/:id`}
            render={(props) => (
              <Complex {...props} filterType={filterType}/>
            )}
          />
        </Switch>

        {/* {agent && media("isMobile") && (
          <MobileFooter
            agent={agent}
            savedFilterXmlId={savedFilterXmlId}
            filterType={filterType}
          />
        )} */}
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    agent: state.estate.agent || state.complex.agent || state.apartment.agent,
    isFilterDisabled:
      !state.estate.isActive ||
      !state.complex.isActive ||
      !state.apartment.isActive,
    savedFilterSettings: state.estate.savedFilterSettings || state.complex.savedFilterSettings || state.apartment.savedFilterSettings,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Public);
