import React from 'react';
import AddNewObject from '../components/Constructor/Admin/MyObjects/AddNewObject';
import '../components/Constructor/index.scss';



const ConstructorAddObj = (props) => {
  return  <AddNewObject {...props} />
};

export default ConstructorAddObj;
