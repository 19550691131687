import React from 'react';
import { useModal } from 'react-modal-hook';
import MobileModal from '../components/core/MobileModal';
import Modal from '../components/core/Modal';
import Wrap from '../components/core/ModalWrap';
import FixateClient from '../components/forms/FixateClient.js';

export const useReserveModals = (isMobile) => {
    const [showFixateClient, hideFixateClient] = useModal(
        ({ in: open, onExited }) => (
            <Wrap wrapper={children => isMobile
                ? <MobileModal closeClick={hideFixateClient} title="Фиксация клиента">{children}</MobileModal>
                : <Modal close={hideFixateClient} classes="modal_task">{children}</Modal>
            } >
                <FixateClient
                    defaultValues={{
                        date: new Date(),
                        agree: true,
                    }}
                    close={hideFixateClient}
                />
            </Wrap >
        ), [isMobile]
    );
    return [showFixateClient, hideFixateClient]
};
