import intersectionBy from 'lodash/intersectionBy';
import minBy from 'lodash/minBy';
import React, {
  useEffect,
  useMemo,
  useRef, useState
} from 'react';
import {
  Button, Map,
  ObjectManager, YMaps,
  ZoomControl
} from 'react-yandex-maps';
import { media } from '../../../utils';
import Loader from '../../SkeletonLoader';
import StartBlock from '../StartBlock/StartBlock.js';
import Card from './Card';
import cluster_point from './cluster-icons/cluster_point.svg';
import cluster_point_blue from './cluster-icons/cluster_point_blue.svg';
import cluster_point_gray from './cluster-icons/cluster_point_gray.svg';
import cluster_point_green from './cluster-icons/cluster_point_green.svg';
import cluster_point_yellow from './cluster-icons/cluster_point_yellow.svg';
import cluster_point_asphalt from './cluster-icons/cluster_point_asphalt.svg';
import cluster_point_purple from './cluster-icons/cluster_point_purple.svg';
import cluster_point_burgundy from './cluster-icons/cluster_point_burgundy.svg';
import cluster_point_nightsky from './cluster-icons/cluster_point_nightsky.svg';
import cluster_point_turquoise from './cluster-icons/cluster_point_turquoise.svg';
import MapList from './MapList';
import map_point from './map_point.svg';
import SelfCardContainer from './SelfCardContainer';


let paintIsStarted = false;

const getClusterPointIcon = (color) => {
  switch (color) {
    case "yellow":
      return cluster_point_yellow
    case "green":
      return cluster_point_green
    case "gray":
      return cluster_point_gray
    case "blue":
      return cluster_point_blue
    case "asphalt":
      return cluster_point_asphalt
    case "purple":
      return cluster_point_purple
    case "burgundy":
      return cluster_point_burgundy
    case "nightsky":
      return cluster_point_nightsky
    case "turquoise":
      return cluster_point_turquoise
    default:
      return cluster_point;
  }
}
const CardComponent = props => props.isSelfObject? <SelfCardContainer {...props} />: <Card {...props} />

const Items = (props) => {
  const {
    items,
    mapItems,
    view,
    fetching,
    city,
    isFetching,
    theme,
    openCallbackModal,
    isSelfObjects
  } = props;

  console.log(props)

  const [isInitMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [objectManagerRef, setObjectManager] = useState(null);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);

  const [mapCenter, setMapCenter] = useState(
    props.city == 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587],
  );
  const clusterMinPriceFilter = (dataManager, features) => {
    const min = minBy(features, feature=> feature.properties.minPrice)
    return min.properties.minPrice
  }

  const [mapZoom, setMapZoom] = useState(9);
  const clusterTemplate = useMemo(() => {
    if(ymaps){
      ymaps.template.filtersStorage.add('price', clusterMinPriceFilter)
      return ymaps.templateLayoutFactory.createClass(
        `<div style="font-family: Formular;color: #fff;font-weight: 700;font-size: 12px;top: -40px;position: absolute;width: 110px;height: 34px;">
        <div style="width: 33px; text-align: center; height: inherit; top: 0;"  >{{ properties.geoObjects.length }}</div>
        <div style="font-family: Formular;color: #fff;font-weight: 400;font-size: 12px;top: 0px;left: 38px;position: absolute;height: 15px;white-space: nowrap;">от {{ properties.geoObjects|price }} млн.</div>
        </div>`)
    }
    return null;
  }, [ymaps]);
  const iconTemplate = useMemo(() => {
    return ymaps
      ? ymaps.templateLayoutFactory.createClass(
        `<div style="font-family: Formular;color: #fff;font-weight: 400;font-size: 12px;top: 6px;left: 10px;position: absolute;white-space: nowrap;"><span>от {{properties.minPrice}} млн.</div>
       `
      )
      : null;
  }, [ymaps]);
  // useEffect(() => {
  //   if (view == 'map') {
  //     props.fetchEstateMap(
  //       props.city,
  //       filterToParams(props.filter),
  //       null,
  //       null,
  //     );
  //   }

  //   setMapCenter(
  //     city == 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587],
  //   );
  // }, [view]);

  useEffect(() => {
    if (view == 'map') {
      setPoint(null);
      setCluster(null);
      setMapZoom(9);
    }

    setMapCenter(
      city == 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587],
    );
  }, [props.filter]);

  const getObjectManager = () => {
    return objectManagerRef;
  };
  const featuresRef = useRef(props.features)
  const mapItemsRef = useRef(props.mapItems)
  useEffect(() => {
    featuresRef.current = props.features
    mapItemsRef.current = props.mapItems
  }, [props.features, props.mapItems])
  useEffect(() => {
    if (ymaps && !ymaps.modules.isDefined('ext.paintOnMap')) {
      ymaps.modules.define(
        'ext.paintOnMap',
        ['meta', 'util.extend', 'pane.EventsPane', 'Event'],
        function (provide, meta, extend, EventsPane, Event) {
          'use strict';

          var EVENTS_PANE_ZINDEX = 500;
          var DEFAULT_UNWANTED_BEHAVIORS = ['drag', 'scrollZoom'];
          var DEFAULT_STYLE = {
            strokeColor: '#ff0000',
            strokeWidth: 1,
            strokeOpacity: 1,
          };
          var DEFAULT_TOLERANCE = 16;

          var badFinishPaintingCall = function () {
            throw new Error(
              '(ymaps.ext.paintOnMap) некорректный вызов PaintingProcess#finishPaintingAt. Рисование уже завершено.',
            );
          };

          function paintOnMap(map, positionOrEvent, config) {
            config = config || {};
            var style = extend(DEFAULT_STYLE, config.style || {});

            var unwantedBehaviors =
              config.unwantedBehaviors === undefined
                ? DEFAULT_UNWANTED_BEHAVIORS
                : config.unwantedBehaviors;

            var pane = new EventsPane(map, {
              css: { position: 'absolute', width: '100%', height: '100%' },
              zIndex: EVENTS_PANE_ZINDEX + 50,
              transparent: true,
            });

            map.panes.append('ext-paint-on-map', pane);

            if (unwantedBehaviors) {
              map.behaviors.disable(unwantedBehaviors);
            }

            // Создаём canvas-элемент.
            var canvas = document.createElement('canvas');
            var ctx2d = canvas.getContext('2d');
            var rect = map.container.getParentElement().getBoundingClientRect();
            canvas.width = rect.width;
            canvas.height = rect.height;

            ctx2d.globalAlpha = style.strokeOpacity;
            ctx2d.strokeStyle = style.strokeColor;
            ctx2d.lineWidth = style.strokeWidth;

            canvas.style.width = '100%';
            canvas.style.height = '100%';

            pane.getElement().appendChild(canvas);

            var firstPosition = positionOrEvent
              ? toPosition(positionOrEvent)
              : null;
            var coordinates = firstPosition ? [firstPosition] : [];

            var bounds = map.getBounds();
            var latDiff = bounds[1][0] - bounds[0][0];
            var lonDiff = bounds[1][1] - bounds[0][1];

            canvas.onmousemove = function (e) {
              coordinates.push([e.offsetX, e.offsetY]);

              ctx2d.clearRect(0, 0, canvas.width, canvas.height);
              ctx2d.beginPath();

              ctx2d.moveTo(coordinates[0][0], coordinates[0][1]);
              for (var i = 1, len = coordinates.length; i < len; i++) {
                ctx2d.lineTo(coordinates[i][0], coordinates[i][1]);
              }

              ctx2d.stroke();
            }.bind(this);

            // Создаём косвенное обращение, чтобы не сдерживать сборщик мусора.
            var paintingProcess = {
              finishPaintingAt: function (positionOrEvent) {
                paintingProcess.finishPaintingAt = badFinishPaintingCall;

                // Получаем координаты, прежде чем удалить пейн.
                if (positionOrEvent) {
                  coordinates.push(toPosition(positionOrEvent));
                }

                map.panes.remove(pane);
                if (unwantedBehaviors) {
                  map.behaviors.enable(unwantedBehaviors);
                }

                var tolerance =
                  config.tolerance === undefined
                    ? DEFAULT_TOLERANCE
                    : Number(config.tolerance);
                if (tolerance) {
                  coordinates = simplify(coordinates, tolerance);
                }
                // Преобразовываем координаты canvas-элемента в геодезические координаты.
                return coordinates.map(function (x) {
                  var lon = bounds[0][1] + (x[0] / canvas.width) * lonDiff;
                  var lat = bounds[0][0] + (1 - x[1] / canvas.height) * latDiff;

                  return meta.coordinatesOrder === 'latlong'
                    ? [lat, lon]
                    : [lon, lat];
                });
              },
            };

            return paintingProcess;
          }

          function toPosition(positionOrEvent) {
            return positionOrEvent instanceof Event
              ? [positionOrEvent.get('offsetX'), positionOrEvent.get('offsetY')]
              : positionOrEvent;
          }

          function simplify(coordinates, tolerance) {
            var toleranceSquared = tolerance * tolerance;
            var simplified = [coordinates[0]];

            var prev = coordinates[0];
            for (var i = 1, len = coordinates.length; i < len; i++) {
              var curr = coordinates[i];
              if (
                Math.pow(prev[0] - curr[0], 2) +
                Math.pow(prev[1] - curr[1], 2) >
                toleranceSquared
              ) {
                simplified.push(curr);
                prev = curr;
              }
            }

            return simplified;
          }

          provide(paintOnMap);
        },
      );
      let paintProcess,
        currentIndex = 0;
      mapInstance.events.add('mousedown', function (e) {
        if (paintIsStarted) {
          if (mapInstance.geoObjects.get(1)) {
            mapInstance.geoObjects.remove(mapInstance.geoObjects.get(1));
          }
          if (currentIndex == 0) {
            currentIndex = 0;
          } else {
            currentIndex += 1;
          }
          paintProcess = ymaps.ext.paintOnMap(mapInstance, e, {
            unwantedBehaviors: ['drag'],
          });
        } else {
          // mapInstance.cursors.push("grab");
        }

        // mapInstance.behaviors.enable("scrollZoom");
      });
      mapInstance.events.add('mouseup', function (e) {
        if (paintProcess) {
          const coordinates = paintProcess.finishPaintingAt(e);
          paintProcess = null;

          var drawArea = new ymaps.Polygon(
            [coordinates],
            {},
            {
              fillColor: 'rgb(255, 45, 35)',
              fillOpacity: 0.1,
              strokeStyle: 'dot',
              strokeColor: 'rgb(255, 45, 35)',
              strokeWidth: 3,
            },
          );

          mapInstance.geoObjects.add(drawArea);
          mapInstance.setBounds(drawArea.geometry.getBounds());

          paintIsStarted = false;
          const objectManager = new ymaps.ObjectManager({
            clusterize: true,
            gridSize: 64,
          });
          objectManager.add(featuresRef.current);
          var clusterIcons = [
            {
              href: cluster_point,
              size: [110, 34],
              offset: [-20, -20],
            },
          ],
            clusterNumbers = [100]
          var clusterOptions = {
            clusterIcons: clusterIcons,
            clusterIconContentLayout: clusterTemplate,
          };

          objectManager.objects.options.set({
            iconLayout: 'default#imageWithContent',
            iconImageHref: map_point,
            iconContentLayout: iconTemplate,
            iconImageSize: [83, 34],
            iconImageOffset: [-34, -24],
          });
          objectManager.clusters.options.set(clusterOptions);
          mapInstance.geoObjects.add(objectManager);
          const objects = ymaps.geoQuery(objectManager.objects);
          objects.setOptions('visible', true);
          objects.searchInside(drawArea).setOptions('visible', true);
          let tempArray = [];

          objects.searchInside(drawArea).each((v) => {
            tempArray.push(
              mapItemsRef.current.find((feature) => {
                return feature.coordinates[0] == v.geometry._coordinates[0];
              }),
            );
          });

          setSelectedItems(tempArray);
          objectManager.events.add('click', clickOnMap);

        }
      });
    }
  }, [ymaps]);

  let ButtonLayout;

  if (ymaps) {
    ButtonLayout = ymaps.templateLayoutFactory.createClass(
      [
        '<button title="{{ data.title }}" class="button button_view_ghost {% if state.selected %}is-active{% endif %}"><svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.1875 13.8726C0.1875 6.30243 6.31378 0.176147 13.8839 0.176147V1.67615C7.14221 1.67615 1.6875 7.13086 1.6875 13.8726H0.1875ZM7.96882 9.25391C7.77128 8.4709 8.48187 7.76032 9.26487 7.95786L9.30777 7.96868L20.1179 12.0992C20.8766 12.333 21.1285 13.3002 20.5556 13.8731L20.5285 13.9002L18.5628 15.5007L21.3688 18.3067C21.8064 18.7443 21.8083 19.4539 21.3695 19.8927L19.9037 21.3585C19.4649 21.7973 18.7553 21.7955 18.3176 21.3578L15.5116 18.5519L13.9112 20.5176L13.8841 20.5447C13.3118 21.117 12.3433 20.8664 12.1105 20.1054L7.97965 9.29684L7.96882 9.25391ZM9.7572 9.74617L13.2679 18.9319L15.3975 16.3164L19.111 20.0299L20.0409 19.1L16.3273 15.3865L18.9437 13.2563L9.7572 9.74617Z"/></svg>',
        '<span class="my-button__text">{{ data.content }}</span>',
        '</button>',
      ].join(''),
    );
  }

  if (objectManagerRef) {
  }
  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };
  const clickOnMap = (e) => {
    let objManager = e.originalEvent.currentTarget;
    // console.log("e", e);
    // console.log("objectManager", objManager);
    let objectId = e.get('objectId');
    if (typeof objectId == 'number') {
      //клик по ЖК
      setClusterOrPoint('point');
      setSelectedItems(null);
      //console.log("is point", objectId);
      setPoint(objManager.objects.getById(objectId));
    }
    if (typeof objectId == 'string') {
      //клик по кластеру
      setClusterOrPoint('cluster');
      setSelectedItems(null);
      //console.log("isCluser", objectId);
      //console.log(objectManager.clusters.getById(objectId)); // вот тут список всех объектов которые находятся на кликнутом кластере
      setCluster(objManager.clusters.getById(objectId));
    }
    // let cluster = null
    // let it = objManager.clusters.getIterator();
    //  while ((cluster = it.getNext()) != it.STOP_ITERATION) {
    //     console.log(cluster);
    //   }
  };
  const filterByCluster = (items) => {
    if (selectedItems) {
      return intersectionBy(items, selectedItems, 'id');
    }
    if (currentPoint && isClusterOrPoint === 'point') {
      paintIsStarted = false;
      return intersectionBy(items, [currentPoint], 'id');
    }
    if (currentCluster && isClusterOrPoint === 'cluster') {
      paintIsStarted = false;
      return intersectionBy(items, currentCluster.features, 'id');
    }

    return items;
  };
  if (isInitMap) {
    mapInstance.events.add('boundschange', function (e) {
      if (e.get('newZoom') !== e.get('oldZoom')) {
        // console.log("zoomchange", e);
      }
    });
  }
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };
  const handlePaintStart = () => {
    paintIsStarted = !paintIsStarted;
    if (mapInstance.geoObjects.get(1)) {
      objectManagerRef.setFilter(function () {
        return true;
      });
      mapInstance.geoObjects.remove(mapInstance.geoObjects.get(1));
      mapInstance.setBounds(objectManagerRef.getBounds());
    }
    mapInstance.cursors.push('arrow');
  };

  return (
    <section
      className={`section  cards ${(view == 'list' && !media("isMobile")) ? 'section_view_gray' : 'section_view_white'
        }`}
    >
      <div className={`wrapper wrapper__for-list ${media("isMobile") && `wrapper__main-bg`}`}>
      </div>
      {view === 'list' && (
        <div className="wrapper">
          <div className="section__grid">
            {fetching && items.length < 3 && [0,1,2].map(i=><Loader key={i} />)}
            {(!fetching || items?.length > 2) && items &&
              items.map((item, i) => {
                if(fetching) return <Loader key={i} />
                if (i === 3) return <>
                  <StartBlock openCallbackModal={openCallbackModal} />
                  <CardComponent
                    {...item}
                    showBadges={true}
                    key={i}
                    city={city}
                    isAgent={true}
                    isPublic={true}
                    isSelfObject={isSelfObjects}
                  />
                </>
                return <CardComponent
                  {...item}
                  showBadges={true}
                  key={i}
                  city={city}
                  isAgent={true}
                  isPublic={true}
                  isSelfObject={isSelfObjects}
                />
              })}

          </div>
          {//пагинация была тут
          }
        </div>
      )}
      {(isInitMap || (view === 'map' && props.features.length > 0)) && (
        <div
          className="section__map"
          style={{ display: view === 'map' ? 'block' : 'none', filter: isFetching? "blur(5px)": "none" }}
        >
          <div className="map">
            <MapList
              items={filterByCluster(mapItems)}
              city={city}
              setMapCenter={setMapCenterHandler}
              isAgent={true}
              isPublic={true}
            />
            <div className={`map__container map__container_${theme.color}`}>
              <YMaps>
                <Map
                  defaultState={{
                    center: [59.9342802, 30.3350986],
                    zoom: 9,
                    behaviors: [
                      'drag',
                      'dblClickZoom',
                      'multiTouch',
                      'rightMouseButtonMagnifier',
                    ],
                  }}
                  state={{
                    center: mapCenter,
                    zoom: mapZoom,
                    behaviors: [
                      'drag',
                      'dblClickZoom',
                      'multiTouch',
                      'rightMouseButtonMagnifier',
                    ],
                  }}
                  modules={[ 'templateLayoutFactory',  'template.filtersStorage', 'Template']}
                  id="myMap"
                  className="myMap"
                  onLoad={mapLoad}
                  instanceRef={(inst) => setMapInstance(inst)}
                >
                  <ObjectManager
                    instanceRef={(inst) => setObjectManager(inst)}
                    options={{
                      clusterize: true,
                      gridSize: 64,
                      clusterHasBalloon: false,
                    }}
                    clusters={{
                      clusterIcons: [
                        {
                          href: getClusterPointIcon(theme.color),
                          size: [110, 34],
                          offset: [-20, -20],
                        },
                      ],
                      clusterIconContentLayout: clusterTemplate,
                    }}
                    objects={{
                      iconLayout: 'default#imageWithContent',
                      iconImageHref: map_point,
                      iconContentLayout: iconTemplate,
                      iconImageSize: [83, 34],
                      iconImageOffset: [-34, -24],
                    }}
                    features={props.features}
                    onClick={clickOnMap}
                    modules={[
                      'package.full',
                      'ext.paintOnMap',
                      'Polygon',
                      'ObjectManager',
                      'geoQuery',
                    ]}
                  />
                  <ZoomControl options={{ float: 'right' }} />
                  <Button
                    data={{
                      content: 'Выделить область',
                      title: 'Выделить область',
                    }}
                    options={{
                      layout: ButtonLayout,
                      maxWidth: [170, 190, 220],
                    }}
                    onClick={() => handlePaintStart()}
                    state={{ selected: paintIsStarted }}
                  />
                </Map>
              </YMaps>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Items;
