import React from "react";
import './index.scss'
import {AptFixationIcon, AptStatusIcon} from "../../Public/markup/icons";
import useAppModal from "../../../hooks/useAppModal";

const NewAlert = ({title, text, bgColor, iconColor, link, secondText,  linkColor, oneLine, sf, customIcon: customIcon, customModalLink, bordered, dangerouslyHTML}) => {
  const [openAppModal, closeAptModal] = useAppModal(() => closeAptModal())
  return (
     <div className={`new-alert ${bordered ? `new-alert_bordered-${bordered}` : ''}`} style={sf ? {backgroundColor: bgColor, marginBottom: 0} : {backgroundColor: bgColor}}>
      <div className="new-alert__icon">
        {!customIcon ? <AptStatusIcon color={iconColor}/> : customIcon}
      </div>
      <div className="new-alert__content" style={oneLine ? {paddingTop: 3} : {}}>
        {title && <h6 className="new-alert__title">{title}</h6>}
        <p className="new-alert__text">
          {dangerouslyHTML && <span dangerouslySetInnerHTML={{__html: dangerouslyHTML}}/>}
          {text}
          {secondText && <span style={{marginTop: 20}}><br/><br/> {secondText}</span>}
          {link && <span style={{color: linkColor}} className="new-alert__link" onClick={!customModalLink ? openAppModal : customModalLink}>{link}</span>}
        </p>
      </div>
    </div>
  )
}

export default NewAlert
