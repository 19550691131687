import React, {useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import Preloader from "../../components/Preloader";

const useEditNote = () => {

  const [state, setState] = useState({});
  const edit = (noteId, key, value) => {
    setState(prev => {
      // debugger
      return {
        // ...prev,
        [noteId]: {
          ...(prev[noteId] || []),
          [key]: value
        }
      }
      // debugger
    })
  }
  return { state, edit };
}

const JustNotes = () => {

  const { data: { favorites = [], filter }, isLoading, send, refresher, isSending, setData } = useApi({
    payload: ["panpartner:favorite.ajax", "getFavoriteList", {city: 'all'}],
  }, [])


  const { state, edit } = useEditNote()


  if (isLoading) return <Preloader loading />;


  return (
    <>

      <div style={{width: '792px', minHeight: '100vh', padding: '50px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 auto', alignItems: 'center', gap: '20px', overflow: 'auto'}}>

        {favorites.map((item) => {
          const {status, value = ""} = state[item.id] || {};

          if(status === "edit") return <>

            <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 20, borderRadius: 20, backgroundColor: '#f6f6f6'}}>
              <div className="favorite-note" style={{marginLeft: 0}}>
                <div className="favorite-note" style={{marginLeft: 0}}>
                  <div className="favorite-note__create-note" style={{marginTop: 0}}>
                    <span className="favorite-note__label">Заметка</span>
                    <textarea
                      className="textarea__control"
                      placeholder="Текст Вашей заметки"
                      value={value}
                      onChange={(e) =>edit(item.id, "value", e.target.value)}
                      onClick={(e) => {e?.stopPropagation?.();}}
                    />
                    <div className="favorite-note__actions">
                      <button
                        id="save-note"
                        className={!value ? 'favorite-note__actions_disable-btn' : ''}
                        onClick={(e) => {
                          e?.stopPropagation?.();
                          edit(item.id, "status", "saved")
                        }}
                      >
                        Сохранить
                      </button>
                      <button
                        id="cancel-note"
                        onClick={(e) => {
                          edit(item.id, "status", "canceled")
                        }}
                      >
                        Отменить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
          return ( item.note !== '' ?
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 20, borderRadius: 20, backgroundColor: '#f6f6f6'}}>
              <div className="favorite-note" style={{marginLeft: 0}}>
                
                <div className="favorite-note__ready-note " style={{marginTop: 0}}>
                  <div className="favorite-note__ready-content"><b>Заметка:<br/>{item.note}</b></div>
                  <div className="favorite-note__ready-actions">
                  <span id="edit-note" onClick={e=> edit(item.id, "status", "edit")}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </span>
                    <span id="cancel-note">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </span>
                  </div>
                </div>
              </div>
            </div> :
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 20, borderRadius: 20, backgroundColor: '#f6f6f6'}}>
              <div className="items-filters" style={{marginTop: 0, justifyContent: 'center'}}>
                <div className="items-filters__buttons">
                  <span className="link" onClick={e=> edit(item.id, "status", "edit")}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    Заметка
                  </span>
                </div>
              </div>
            </div>
          )
        })}

        {/*<div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 20, borderRadius: 20, backgroundColor: '#f6f6f6'}}>*/}
        {/*  <div className="items-filters" style={{marginTop: 0, justifyContent: 'center'}}>*/}
        {/*    <div className="items-filters__buttons">*/}
        {/*    <span className="link">*/}
        {/*      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>*/}
        {/*      Заметка*/}
        {/*    </span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="favorite-note" style={{marginLeft: 0}}>*/}
        {/*    <div className="favorite-note__create-note" style={{marginTop: 0}}>*/}
        {/*      <span className="favorite-note__label">Заметка</span>*/}
        {/*      <textarea className="textarea__control" placeholder="Текст Вашей заметки"/>*/}
        {/*      <div className="favorite-note__actions">*/}
        {/*        <button id="save-note" className="favorite-note__actions_disable-btn">Сохранить</button>*/}
        {/*        <button id="cancel-note">Отменить</button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="favorite-note__ready-note " style={{marginTop: 0}}>*/}
        {/*      <div className="favorite-note__ready-content"><b>Заметка:<br/></b></div>*/}
        {/*      <div className="favorite-note__ready-actions">*/}
        {/*        <span id="edit-note">*/}
        {/*          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path></svg>*/}
        {/*        </span>*/}
        {/*        <span id="cancel-note">*/}
        {/*          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"></path></svg>*/}
        {/*        </span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

      </div>
    </>
  )
}

export default JustNotes

