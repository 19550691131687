import { useMediaQuery } from "beautiful-react-hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TinySlider from "tiny-slider-react";
// import { Swiper, SwiperSlide } from 'swiper/react';
import useMedia from "../../../hooks/useMedia";
// import 'swiper/swiper.scss';

// // SwiperCore.use([Navigation]);
const Advantages = (props) => {
  const { advantages, openCallbackModal } = props
  const node = useRef(null);
  const navRef = useRef(null);


  const [index, setIndex] = useState(1);

  const isMobile = useMedia("isMobile");

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          gutter: 0,
          nav: isMobile ? true : false,
          // nav: false,
          controls: false,
          center: true,
          loop: isMobile ? false : true,
          mouseDrag: true,
          navAsThumbnails: isMobile && false,
          navContainer: isMobile ? `#complex-slider-advantages-thumbnails` : false,
          responsive: {
            768: {
              items: 2,
              gutter: 20,
            },
            1600: {
              items: 3,
            },
          },
        }
        : {},
    [navRef]
  );

  // const settings = {
  //   items: 1,
  //   gutter: 0,
  //   nav: false,
  //   controls: false,
  //   center: true,
  //   loop: true,
  //   mouseDrag: true,
  //   responsive: {
  //     768: {
  //       items: 2,
  //       gutter: 20,
  //     },
  //     1600: {
  //       items: 3,
  //     },
  //   },
  // };
  //

  const len = advantages.length
  const showControls = (useMediaQuery('(max-width: 1600px)') && len > 2) || len > 3;
  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const [sliderHeight, setSliderHeight] = useState(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    document.onreadystatechange = () => {
      if (sliderRef.current) {
        setSliderHeight(sliderRef.current.clientHeight);
      }
    };
  })

  return (
    <section className="advantages constructor__section">
      <div className="constructor__container">
        <div className="advantages__wrapper constructor__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title advantages__title">
              Преимущества
            </h2>
          </div>
          <div className="advantages__list constructor__box">

            <div className="constructor__slider">
              <div className="constructor__slider-wrap" ref={sliderRef}>
                <TinySlider settings={settings} ref={node} onIndexChanged={handleChangeIndex}>
                  {advantages.map((adv, i) => {
                    return (
                      <div className={`advantages__item ${isMobile ? '' : ' block'}`} key={"preim-" + i} style={{ height: sliderHeight - 50 }}>
                        <div className="advantages__item-inner">
                          <h4 className="h4 block__title block__title_underline">{adv.title}</h4>
                          <p className="text block__text"
                              dangerouslySetInnerHTML={{
                                __html: adv.text.replace(/\n/g, "<br />"),
                              }}
                          >
                          </p>
                        </div>
                      </div>

                    )
                  })}
                </TinySlider>

                {isMobile &&
                  <>
                    <ul
                      class="complex__slider-thumbnails for-constructor"
                      id="complex-slider-advantages-thumbnails"
                      ref={navRef}
                    >
                      {advantages.map((item, index) => (
                        <li class="complex__slider-thumbnails_item" key={index}>
                          <span aria-controls="base"></span>
                        </li>
                      ))}
                    </ul>

                    <span class="complex__slider-info for-contstructor">
                      {index} / {advantages.length}
                    </span>
                  </>
                }
              </div>
            </div>

            {/* <Swiper
              navigation={{
                nextEl: '.next-slide-advantages',
                prevEl: '.prev-slide-advantages'
              }}
              spaceBetween={0}
              slidesPerView={1}
              setWrapperSize={true}
              slidesPerGroup={1}
              breakpoints={{
                767: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                1279: {
                  slidesPerView: 4,
                  slidesPerGroup: 4
                },
              }}
            >
              {advantages.map(adv => {
                return (
                  <SwiperSlide key={"preim-" + adv.id}>
                    <div className="advantages__item block" >
                      <h4 className="h4 block__title block__title_underline">{adv.title}</h4>
                      <p className="text block__text">
                        {adv.text}
                      </p>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper> */}
          </div>
          {!isMobile && showControls &&
            <div class="slider-nav">
              <ul class="slider-nav__wrapper">
                <li
                  class="slider-nav__item prev prev-slide-advantages"
                  onClick={() => moveSlider("prev")}
                >
                  <svg class="icon icon-dark_stroke">
                    <use xlinkHref="#icon-left_arrow"></use>
                  </svg>
                </li>
                <li
                  class="slider-nav__item next next-slide-advantages"
                  onClick={() => moveSlider("next")}
                >
                  <svg class="icon icon-dark_stroke">
                    <use xlinkHref="#icon-right_arrow"></use>
                  </svg>
                </li>
              </ul>
            </div>}

          <button className="button constructor__btn advantages__btn" type="button" onClick={openCallbackModal}>
            Подобрать недвижимость
          </button>

        </div>

      </div>
    </section>
  )
};

export default Advantages;
