import React, { useEffect, useRef, useState } from "react";
import CardSkeleton from "../../Public/markup/CardSkeleton";
import './index.scss';

export const NotificationsListSkeleton = () => {
    const skeletonContainerRef = useRef(null);
    const [visibleSkeletons, setVisibleSkeletons] = useState(0);

    useEffect(() => {
        const calculateVisibleSkeletons = () => {
            const skeletonContainerHeight = skeletonContainerRef.current.offsetHeight;
            const skeletonHeight = 58;
            const gapHeight = 24;
            const availableHeight = skeletonContainerHeight - gapHeight;
            const maxVisibleSkeletons = Math.floor(availableHeight / (skeletonHeight + gapHeight));
            setVisibleSkeletons(maxVisibleSkeletons);
        };

        calculateVisibleSkeletons();
        window.addEventListener("resize", calculateVisibleSkeletons);

        return () => {
            window.removeEventListener("resize", calculateVisibleSkeletons);
        };
    }, []);

    return (
        <div className="nottifications-skeleton" ref={skeletonContainerRef}>
            {Array.from({ length: visibleSkeletons + 1 }, (_, index) => (
                <CardSkeleton key={index} small />
            ))}
        </div>
    );
}

export const NotifyAllReaded = () => <div className="nottifications__stub">
    <svg className="nottifications__stub-icon" width="34" height="34" viewBox="0 0 34 34" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.81392 15.2695C8.81392 14.1944 9.02566 13.1299 9.43705 12.1368C9.84844 11.1436 10.4514 10.2411 11.2116 9.48099C11.9717 8.72084 12.8742 8.11785 13.8673 7.70646C14.8605 7.29507 15.925 7.08333 17 7.08333C18.0751 7.08333 19.1395 7.29507 20.1327 7.70646C21.1259 8.11785 22.0284 8.72084 22.7885 9.48099C23.5487 10.2411 24.1516 11.1436 24.563 12.1368C24.9744 13.1299 25.1862 14.1944 25.1862 15.2695V15.2695C25.1862 19.3511 26.042 21.7226 26.7945 23.0223C26.8745 23.1608 26.9166 23.3179 26.9167 23.4778C26.9168 23.6377 26.8749 23.7948 26.7951 23.9334C26.7154 24.072 26.6006 24.1871 26.4622 24.2674C26.3239 24.3476 26.167 24.3901 26.0071 24.3905H7.99302C7.83312 24.3901 7.67615 24.3476 7.53783 24.2674C7.39952 24.1871 7.28472 24.072 7.20496 23.9334C7.1252 23.7948 7.08327 23.6377 7.08337 23.4778C7.08348 23.3179 7.12562 23.1608 7.20557 23.0223C7.95806 21.7226 8.81392 19.3511 8.81392 15.2695Z"
            stroke="#777A89" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M13.3517 24.3905V25.3026C13.3517 26.2702 13.7361 27.1982 14.4203 27.8824C15.1045 28.5666 16.0325 28.951 17.0001 28.951C17.9677 28.951 18.8957 28.5666 19.5799 27.8824C20.2641 27.1982 20.6485 26.2702 20.6485 25.3026V24.3905"
            stroke="#777A89" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    <p className="nottifications__stub-title">Уведомлений нет</p>
    <p className="nottifications__stub-text">Здесь вы будете получать уведомления о постановке брони, выплате
        авансирования и другие</p>
</div>
