import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';

const SecondaryBottomFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    clearExcludeOnly,
    clearFilterOnly,
    wrapperClass
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    <SquareFilterOnly
      autoPosition
      filter={filter.squareTotal ?? { min: "", max: "" }}
      data={{
        title: 'Площадь кухни',
        minPlaceholder: 'S кухни от ',
        maxPlaceholder: 'S кухни до ',
        defaultValues: filterData.squareTotal?.range ?? null
      }}
      onChange={({ filter }) => handleFilter("squareKitchen", filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareKitchen"}
      items={filterData.squareTotal?.items}
      showPlaceholderOnLists
      debounced
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("rooms")}
      data={{
        title: "К-во комнат",
        identity: "rooms",
      }}
      isAutocomplete={false}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("balcony")}
      data={{
        title: "Балкон",
        autocompleteTitle: "",
        identity: "balcony",
      }}
      disabledItems={{
        w: ["b", "l", "t"],
        b: ["w"],
        l: ["w"],
        t: ["w"],
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("blocks")}
      data={{
        title: "Название ЖК",
        autocompleteTitle: "Введите название ЖК",
        identity: "blocks",
      }}
      wrapperClass={wrapperClass}
    />
  </>
});

export default SecondaryBottomFilters;