import request from "../api";
import { actionFactory } from "./actionUtils";

const component = "panpartner:assignments.ajax"

const requestAssignments = actionFactory("ASSIGNMENTS_REQUEST");

const successAssignments = actionFactory("ASSIGNMENTS_SUCCESS");

const failureAssignments = actionFactory("ASSIGNMENTS_FAILURE");

export const clearAssignments = actionFactory("ASSIGNMENTS_RESET");

const setCount = actionFactory("ASSIGMENTS_SET_COUNT")

export const setFilterAssignments = filter => ({
  type: "ASSIGNMENTS_SET_FILTER",
  filter,
});

export const clearFilterAssignments = actionFactory("ASSIGNMENTS_CLEAR_FILTER")

export const fetchAssignments = (city, isGetFilter, form, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestAssignments());
    return request(component, "getAssignments", {
      city: "spb",
      ...form,
      savedFilterXmlId,
      isGetFilter
    }).then(
      (data) => {
        const {
          assignments,
          top,
          filter,
          subways,
          isActive,
          agent,
          isShowFilter,
          savedFilter,
          addAssignmentsVideo,
        } = data;
        assignments.floorCount = data.count;
        assignments.addAssignmentsVideo = addAssignmentsVideo;
        dispatch(
          successAssignments({
            ...data,
            data: assignments,
            filterData: filter,
            top: top,
            subways: subways,
            isActive,
            agent,
            isShowFilter,
            savedFilter,
            isGetFilter: isGetFilter == 1,
          }),
        );
        return Promise.resolve(data);
      },
      (error) => {
        dispatch(failureAssignments());
        return Promise.reject(error);
      }
    );
  };
export const successAssignmentsMap = (data) => ({
  type: "ASSIGNMENTS_MAP_SUCCESS",
  ...data,
})
export const fetchAssignmentsMap = (form) => dispatch => {
  dispatch(requestAssignments());
  return request(component, "getMapAssignments", {
    city: "spb",
    onlyTypes: 0,
    isGetFilter: 0,
    ...form,
  }).then(resp => {
    dispatch(successAssignmentsMap({
      assignments: resp.assignments
    }))
  }, err => dispatch(failureAssignments()))
}

export const successAssignmentsPart = (data) => ({
  type: "ASSIGNMENTS_PART_SUCCESS",
  ...data,
})

export const fetchAssignmentsPart = (form, roomTypeId) => dispatch => {
  return request(component, "getAssignments", {
    city: "spb",
    onlyTypes: 0,
    isGetFilter: 0,
    ...form,
  }).then(resp => {
    dispatch(successAssignmentsPart({
      assignments: resp.assignments.types,
      roomTypeId
    }))
  }, err => dispatch(failureAssignments()))
}

const requestAssignmentsFilter = actionFactory("ASSIGNMENTS_FILTER_REQUEST")

const successAssignmentsFilter = actionFactory("ASSIGNMENTS_FILTER_SUCCESS")

export const fetchAssignmentsFilter = (savedFilterXmlId, city = "spb") =>
  dispatch => {
    dispatch(requestAssignmentsFilter());
    return request(component, "getFilter", {
      city: "spb",
      savedFilterXmlId,
    }).then(
      data => {
        dispatch(successAssignmentsFilter({ filterData: data.filter }));
        return Promise.resolve(data);
      }
    );
  };

export const getCount = (params, savedFilterXmlId) =>
  dispatch =>
    request(component, "getCount", {
      ...params,
      savedFilterXmlId
    }).then(
      data => {
        dispatch(setCount({ count: data.count }));
        return Promise.resolve(data);
      }
    );
