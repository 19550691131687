import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { media } from "../../utils";
import SubmitButton from "../SubmitButton";
import { standartErrorToast, standartSuccessToast } from "../toasts";

const CashbackForm = (props) => {
  const {
    phone = '',
    name = '',
    handleSend,
    closeModal
  } = props;

  const defaultValues = {
    name: name,
    phone: phone,
    agree: true,
  };

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [fetching, setFetching] = useState(false);

  const onSubmit = (data) => {
    handleSend(data).then(
      (data) => {
        standartSuccessToast(data)
        setFetching(false);
        closeModal()
      },
      (error) => {
        standartErrorToast(error)
        setFetching(false);
      }
    );

  };


  return (
    <>
      {!media("isMobile") && <h2 className="modal__title">Рассчитать вознаграждение</h2>}
      <form className="modal-form" name="reserve-apartment">
        <div className="modal-form__wrapper">
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-name"
              >
                <span className="input__label">Имя и фамилия</span>
                <input
                  id="modal-reservation-name"
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  placeholder="Введите имя и фамилию"
                  ref={register({
                    required: "Введите имя и фамилию",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name.message}
                  </span>
                )}
              </label>
            </div>
            <div className="modal-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-reservation-phone"
              >
                <span className="input__label">Телефон</span>
                <InputMask
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autocomplete="off"
                  maskChar=" "
                  name="phone"
                  defaultValue={phone}
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone.message}
                  </span>
                )}
              </label>
            </div>
          </div>

          <div className="modal-form__row">
            <div className="modal-form__item">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    "checkbox__box" +
                    (errors.agree ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        "Необходимо согласиться с правилами обработки данных",
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Отправить заявку</span>
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

export default CashbackForm;
