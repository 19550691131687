import React, { useState } from "react"
import {ChatSearchIcon, SmallCloseIcon} from "../../Public/markup/icons";
import "./index.scss"
import Loader from "../../Loader";
import {animated} from "react-spring";

const SearchInp = props => {
  const { placeholder, onChange, reset, value, renderInput, isSearching, searchIcon } = props
  return <div className={`section-search ${searchIcon && 'section-search_icon'}`}>
    {!renderInput && <>{searchIcon && <div className="chat-search-icon"><ChatSearchIcon/></div>}<input className="section-search__inp" value={value} placeholder={placeholder} onChange={onChange} /></>}
    {!!renderInput && renderInput(props)}
    {value !== '' &&
      <div className="section-search__icon" style={isSearching ? {width: 40} : {}} onClick={reset}>
        {isSearching ? <animated.div>
          <Loader  loaderColor={'#8f95ac'}/>
        </animated.div> : <SmallCloseIcon />}
      </div>
    }
  </div>
}

export default SearchInp;
