import React from 'react'

/**
 * Принимает компонент и возвращает его, передает ему в качестве просов первый существующий в списке вариант
 * @param   {React.Component} component true - рендерим компонент
 * @param   {boolean} condition true - рендерим компонент
 * @param   {Array} variants  список вариантов, если объект/массив - нужно передать функцию variantBy
 * @param   {string} variantKey ключ для конечного варианта 
 * @param   {function} variantBy функция для поиска нужного совпадения в массиве variants, принимает значение варианта,(должна вернуть true/false)
 * @param   {function} finaly callback для обработки итогового значения пропса
 * @return  {component} 
 * @example
 * 
 *  <OneOf
 *      condition={nav.type === "svg"}
 *      variants={[selfFloorPlan?.src, floorPlan.src]}
 *      variantKey={"photos"}
 *      finaly={(photo) => [getUrl(photo)]}
 *      component={PhotoBlock}
 *  />
 */
export const OneOf = (props) => {
    const { condition, variants, variantKey, variantBy, finaly = (value) => value, component: Component, } = props
    let result = variants.find(variantBy ? variantBy : (variant) => !!variant)

    if (!condition || !result) {
        return ""
    }
    let newProps = { [variantKey]: finaly(result) }
    return  <Component {...props} {...newProps} />
}
