import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useDropdown from '../../hooks/useDropdown';

const DropMenuItem = (props) => {
  const { link, isNew,  title, external, onClick, modal, showModal } = props;
  return (
    <li
      className="header__top-drop-item"
      onClick={() => (onClick ? onClick() : null)}
    >
      {modal ? (
        <div
          style={{ cursor: 'pointer' }}
          className="header__bottom-drop-link"
          onClick={() => showModal(modal)}
        >
          {title}
        </div>
      ) : external ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="header__top-drop-link"
        >
          {title}
        </a>
      ) : (
        <a className="header__top-drop-link" href={link}>
          {title}
          {isNew &&
          <span className="menu-nav__item-new">New</span>
          }
        </a>
      )}
    </li>
  );
};

DropMenuItem.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const DropMenu = (props) => {
  const { title, menu, showModal } = props;

  const [node, open, show, hide] = useDropdown(false);

  const dropdownClass = classNames({
    dropdown: true,
    'is-showing': open,
  });

  return (
    <div className={dropdownClass} ref={node}>
      <button
        className="dropdown__btn button"
        onClick={(e) => (open ? hide() : show())}
      >
        <span className="header__top-menu-link">
          {title}
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </span>
      </button>
      <div className="dropdown__block">
        <div className="dropdown__inner">
          <div className="header__top-menu-drop">
            <ul className="header__top-drop-list">
              {menu.map((item, index) => (
                <DropMenuItem key={index} {...item} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

DropMenu.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.array,
};

export default DropMenu;
