import React from 'react';

const Comment = ({ data }) => {

  if (!data) return 'Loading...';

  return (
    <div className="presentation__info pdf-block">
      <div className={`presentation__box ${data.detailText?.length > 0 ? '' : ' hidden'}`}>
        <div className="presentation__title">Комментарий</div>
        <div className="presentation__info-description">
          <p>{data.detailText}</p>
        </div>
      </div>
    </div>
  );
};

export default Comment;