import './index.scss';
import Header from '../../components/Public/markup/Header';
import Title from '../../components/Public/markup/Title';
import CardsList from '../../components/Public/markup/CardsList';
import MapBtn from '../../components/Public/markup/MapBtn';
import Tags from '../../components/Public/markup/Tags';
import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import MapBlock from '../../components/Public/markup/MapBlock';
import TopBar from '../../components/Public/markup/TopBar';

import useMedia from '../../hooks/useMedia';
import useFilter from '../../hooks/useFilter';
import { getSuffix, media } from '../../utils';
import SubmitButton from '../../components/SubmitButton';

import Dropdown from '../../components/core/Dropdown';
import { sortlistPrimary, sortlistVillages } from '../../hooks/useFilter/filterSets';
import Preloader from '../../components/Preloader';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import RealtyMap from '../../components/Public/markup/Map';
import { extractSuburbansMapItems } from '../../hooks/useFilter/filterUtils';
import PrimaryPublicCard from '../../components/Public/markup/Containers/PrimaryPublicCard';

import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import Card from '../../components/core/Card';
import { useModal } from 'react-modal-hook';
import Wrap from '../../components/core/ModalWrap';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import FilterDisabled from '../../components/Public/FilterDisabled';

import { useMemo } from 'react';
import VillagesPublicFilter from '../../components/Public/markup/VillagesPublicFilter';
import MobileMapFilter from '../../components/Public/markup/MapFilters/MobileMapFilter';
import VillageCardContainer from '../../components/Public/markup/Containers/VillageCardContainer';
import useRootCSS from '../../hooks/useRootCSS';
import MapFilters from '../../components/Public/markup/MapFilters/Index';

const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count}
      loaderColor={loaderColor}
    >
      {`${count} объект${getSuffix(count)}`}
    </SubmitButton>
  );
};

const PublicVillages = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  const { savedFilterXmlId } = useParams();
  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      villages = [],
      filter: filterData = {},
      agent,
      city,
      isActive,
      isShowFilter,
      savedFilter,
      savedFilterSettings,
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent,
    get,
    favoritesProps
  } = useFilter({
    apiConfig: {
      component: 'panpartner:suburbans.ajax',
      getCountFunction: 'getCountVillages',
      submitFunction: 'getVillages',
      getMapItems: 'getVillages',
    },
    defaultFilter: {
      status: [1],
    },
    defaultParams: {
      savedFilterXmlId,
    },
    defaultOrder: 'start',
    defaultBy: 'desc',
    defaultSortId: "0",
    sortList: sortlistVillages,
    sortEnabled: true,
    paginationEnabled: true,
    isGetFilter: true,
    loadMoreEnabled: true,
    loadMoreDataKey: 'villages',
    shadowFilters: ['status'],
    extractCount: ({ countVillages }) => countVillages,
    extractLoadMoreCount: ({ countVillages }) => countVillages,
    extractMapItems: ({ villages }) => villages,
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },
    fields: {
      villages: [],
      status: [],
      districts: [],
      rooms: [],
      endings: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
    },
  });
  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null); 

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })
  const saveToLocalSotage = id => {
    const tmpFilter = savedFilter?.filter ? {...savedFilter.filter, ...filter} : filter;
    const tmpExclude = savedFilter?.exclude ? {...savedFilter.exclude, ...exclude} : exclude;
    localStorage.setItem("suburbansFilter", JSON.stringify({ [id]: tmpFilter }))
  }

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <MobileMapFilter
            component={VillagesPublicFilter}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={() => {
              closeFilter();
              submit();
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            isMobile={isMobile}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );
  const badges = useMemo(() => isShowFilter || !savedFilter?.filter ? filterBadges : createBadges(savedFilter?.filter), [filterBadges, savedFilter])
  if (isLoading) return <Preloader loading={true} />;
  if (!isActive) return <FilterDisabled agent={agent} />;
  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
          }
          {...mapState}
          items={mapItems}
          extractFeatures={extractSuburbansMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}
          savedFilterSettings={savedFilterSettings}
        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={VillageCardContainer}
          getLink={(item) =>`/public/suburban/${savedFilterXmlId}/spb/${item.id}`}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={VillagesPublicFilter}
          count={`${count} объект${getSuffix(count)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          isShowFilter={isShowFilter}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          loaderColor={savedFilterSettings?.colorButtonText}
          onClick={saveToLocalSotage}
          {...favoritesProps}
          get={get}
          isPublic
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      {!savedFilterSettings?.hideHeader && (
        <Header {...agent} savedFilterSettings={savedFilterSettings} />
      )}

      {!savedFilterSettings?.hideTitle && (
        <Title
          title={
            savedFilterSettings?.title
              ? savedFilterSettings?.title
              : `Загородная недвижимость`
          }
        />
      )}
      {!isTablet && (
        <>
          {isShowFilter && (
            <div className="sf__filter form">
              <VillagesPublicFilter
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                count={`${count} объект${getSuffix(
                  count,
                )}`}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
              />
            </div>
          )}
          <div style={{marginTop: 25}}>
          <Tags
            badges={badges}
            removeBadge={removeBadge}
            resetFilter={() => resetFilter(true)}
            readonly={!isShowFilter}
          />
          </div>
        </>
      )}

      {isLaptop && !isTablet && (
        <FilterSubmitButton
          onClick={submit}
          isLoading={isCountLoading || isLoading}
          count={count}
          loaderColor={savedFilterSettings?.colorButtonText}
        />
      )}
      <div className="sf__row">
        {!isTablet && (
          <div className="special-filter-drop">
            <Dropdown
              innerClass={``}
              value={sortId}
              options={sortlistVillages}
              onChange={handleSort}
            />
          </div>
        )}
        <div/>
        <div className="sf__filter-btns">
          {isTablet && <FilterToggleBtn onClick={openFilter} />}
          <MapBtn
            setShowMap={() => setView('map')}
            iconColor={savedFilterSettings?.colorButton}
          />
          {!isTablet && (
            <FilterSubmitButton
              onClick={submit}
              isLoading={isCountLoading || isLoading}
              count={count}
              loaderColor={savedFilterSettings?.colorButtonText}
            />
          )}
        </div>
      </div>

      <CardsList
        items={villages}
        limit={limit}
        isLoading={showSkeleton}
        itemsRef={infiniteRef}
        cardComponent={VillageCardContainer}
        getLink={(item) =>`/public/suburban/${savedFilterXmlId}/spb/${item.id}`}
        savedFilterXmlId={savedFilterXmlId}
        listClass="sf__cards-list"
        onClick={saveToLocalSotage}
        {...favoritesProps}
        get={get}
        isPublic
      />
      <LoadMoreSpinner loading={moreIsLoading} />
      {/* <div className="section__grid"> рабочий код, подходит полностью под хук useFilter но Роман хотел автоподгрузку тут 
      <div className="section__grid-col cards__pagination">
        <div className="pagination">
          <Pagination
            currentPage={page}
            itemsCount={count}
            itemsPerPage={limit}
            onChange={changePage}
          />
          {!(limit > count) && (
            <CountRealty
              itemsCount={count}
              itemsPerPage={limit}
              onChange={changeLimit}
            />
          )}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default PublicVillages;
