import React, { useMemo, useState } from "react";
import { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import ApartmentMap from "../ApartmentMap";
import Dropdown from '../core/Dropdown';
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import PhotoBlock from '../PhotoBlock';
import { OneOf } from '../Support';

const PhotosBlock = (props) => {
  const {
    planSmall,
    planLarge,
    windowView,
    windowViewUrl,
    selfFloorPlan,
    flatPathCoordinates,
    floorPlan,
    viewFromWindow = {},
    onPhotoOpen,
    onPhotoClosed,
  } = props;

  const [current, setCurrent] = useState("plan");
  const isMobile = useMedia("isMobile")
  const navigation = useMemo(() => {
    let nav = [];
    nav.push({
      id: "plan",
      title: "Планировка",
      type: "photo",
      key: "plan",
    });



    if (selfFloorPlan || floorPlan) {
      nav.push({
        id: "flat",
        title: "План этажа",
        type: "svg",
        key: "flat",
      });
    }
    if (viewFromWindow) {
      nav.push({
        id: "windowViewNew",
        title: "Вид из окна",
        type: "windowViewNew",
        key: "windowViewNew",
      });
      return nav
    }
    if (windowViewUrl) {
      nav.push({
        id: "view",
        title: "Вид из окна",
        type: "iframe",
        key: "view",
      });
    }
    if (windowView && !windowViewUrl) {
      nav.push({
        id: "view",
        title: "Вид из окна",
        type: "windowView",
        key: "windowView",
      });
    }

    return nav;
  }, [windowViewUrl, selfFloorPlan, flatPathCoordinates, floorPlan, windowView]);

  return (
    <>
      {navigation.length > 1 && (
        <div className="flat__nav">
          {!isMobile ?
            <ul className="flat__nav-list">
              {navigation.map((nav, index) => (
                <li
                  className={`flat__nav-item`}
                  data-tab={nav.id}
                  onClick={() => setCurrent(nav.id)}
                >
                  <span
                    className={`flat__nav-link ${nav.id === current ? `active` : ``
                      }`}
                  >
                    {nav.title}
                  </span>
                </li>
              ))}
            </ul> :

            <Dropdown value={current} options={navigation} onChange={(current) => setCurrent(current)} />
          }
        </div>
      )}
      <div className="flat__images gallery">
        {navigation.map((nav, index) => (
          <div
            className={`flat__image gallery__wrapper ${nav.id === current ? `active` : ``
              }`}
            style={{ ...(nav.type === "windowViewNew" || nav.type === "iframe" ? { padding: 10, minHeight: 300 } : []) }}
            key={nav.key}
          >
            {nav.type === "photo" && <PhotoBlock
              photos={[getUrl(planSmall.src)]}
              onPhotoOpen={onPhotoOpen}
              onPhotoClosed={onPhotoClosed}
              largePhotos={planLarge?.src? [getUrl(planLarge.src)]: []}
            />}
            {nav.type === "windowView" && <PhotoBlock
              photos={[getUrl(windowView.src)]}
              onPhotoOpen={onPhotoOpen}
              onPhotoClosed={onPhotoClosed}
            />}
          
            {nav.type === "windowViewNew" &&   <ErrorPlug stopSending={true} ><ApartmentMap  {...viewFromWindow} /> </ErrorPlug>}
            {nav.type === "iframe" && (

              <iframe
                src={windowViewUrl}
                title="Направление из окон"
                width="100%"
                height="100%"
              ></iframe>
            )}
            <OneOf
              condition={nav.type === "svg"}
              variants={[selfFloorPlan?.src, floorPlan.src]}
              variantKey={"photos"}
              finaly={(photo) => [getUrl(photo)]}
              component={PhotoBlock}
              onPhotoOpen={onPhotoOpen}
              onPhotoClosed={onPhotoClosed}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default PhotosBlock;
