import React, {useEffect, useMemo, useRef, useState} from "react";
import './index..scss'
import Tabs from "../../components/core/Tabs";
import Dropdown from "../../components/Dropdown";
import { getFavoriteCount } from "../../actions/favorites";
import {CompilationsStubIcon, SmallCloseIcon} from "../../components/Public/markup/icons";
import {Link} from "react-router-dom";
import {sortlistFavorites, sortlistVillages} from "../../hooks/useFilter/filterSets";
import useApi from "../../hooks/useApi";
import Preloader from "../../components/Preloader";
import request, {getUrl} from "../../api";
import NewTabsNavigation from "../../components/core/TabsNew/TabsNavigation";
import {useModal} from "react-modal-hook";
import Modal from "../../components/core/Modal";
import Button from "../../components/core/Button";
import {
  applyToFormData,
  compareWords,
  getSuffix,
  getSuffixUniversal,
  objectsArrayToFormData,
  searchInFieldsByString,
  toFormData
} from "../../utils";
import MortgageForm from "../../components/forms/MortgageForm";
import PdfSettingsModal from "./PdfSettingsModal";
import PanModal from "../../components/core/PanModal";
import CompilationModal, {CompilationViewingNoteModal} from "./compilationModal";
import CompilationSettingsModal from "./CompilationSettingsModal";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import { CheckboxControlled } from "../../components/core/Checkbox";
import {removeFromFavorites} from "../../actions/favorites";
import {useDispatch, useSelector} from "react-redux";
import { CompilationControls, compilationsColumns, goToUrl, sortComplex } from "./datasets";
import { standartErrorToast, standartSuccessToast, successToast } from "../../components/toasts";
import Table from "../../components/core/Table";
import { LocaleNotFound } from "../../components/core/Table/tableUtils";
import usePermission from "../../hooks/usePermission";
import { getURLparam } from "../../utils/location";
import useModalWithData from "../../hooks/useModalWithData";
import FilterSkeleton from "../Crm/FilterSkeleton";
import Loader from "../../components/Loader";

const NewFavorites = ({fromCrm}) => {

  const dispatch = useDispatch();;
  const isNotAgent = usePermission("not-agent");
  const userId = useSelector(state => state.user?.info?.id);
  // fromCrm && console.log(fromCrm)
  const {
    state: {
      selectedCity,
      selectedType,
      selectedTab,
      selectedDate,
      selectedAgent,
      onlyMine,
      tabs,
      search,
      selectedFavorites,
      compilations,
      sort,
      pdfItem,
      editableCompilation
    },
    setValue,
    reset,
    setValues
  } = useUniversalReducer({
    selectedCity: { title: 'Все города' , code: "all"},
    selectedType: { title: 'Тип недвижимости' },
    selectedDate: { title: 'За 30 дней' },
    selectedAgent: { title: 'Сотрудник' },
    onlyMine: false,
    // selectedTab: 0,
    selectedTab: fromCrm ? 2 : 0,
    search: "",
    tabs: [],
    selectedFavorites: [],
    compilations: [],
    sort: 2,
    pdfItem: {},
    editableCompilation: false,

  });
useEffect(() => {
  fromCrm && setValue("selectedTab", 2)
}, [selectedTab])
  const { data: { favorites = [], filter }, isLoading, send, refresher, isSending, setData } = useApi({
    payload: ["panpartner:favorite.ajax", "getFavoriteList", {
      types: tabs.find((v, i) => i === selectedTab)?.id,
      city: selectedCity.code
    }],
    processData: (resp = {}, prev) => {
      if (!!resp.tabs?.length) {
        const tabName = getURLparam("tab");
        const tabIndex = resp.tabs.findIndex(({ id }, i) => id === tabName);
        setValues({
          tabs: resp.tabs,
          selectedTab: tabIndex !== -1 ? tabIndex : 0
        })
      }
      return { ...prev, ...resp };
    }
  }, [selectedTab, selectedCity.code])

  const [checkCompState, setCheckCompState] = useState(false)
  const [defaultCompilationSettings, setDefaultCompilationSettings] = useState({})
  const getCompilations = () => {
    send(["panpartner:favorite.ajax", "getCompilations", {}])
    .then((resp) => {
      setValue("compilations", resp.compilations)
    })
  }
  useEffect(() => {
    if (selectedTab === 0) getCompilations()
  }, [selectedTab])


  const filteredFavorites = useMemo(() => {
    const filtered = favorites.filter(({ type, city, dateAdd, title, builderTitle, agentId }) => {
      const itemType = type === "project" ? "suburban" : type
      const typeCondition = selectedType?.xmlId ? itemType === selectedType.xmlId : true;
      const cityCondition = selectedCity?.code ? city.code === selectedCity.code || selectedCity.code === "all" : true;
      const dateCondition = dateAdd && selectedDate?.id ? new Date(dateAdd) > new Date(selectedDate.id) : true;
      const searchCondition = (title || builderTitle) && !!search.replace(/ /g, "") ? compareWords(title ?? "", search) || compareWords(builderTitle ?? "", search) : true;
      const onlyMineCondition = !onlyMine || userId === agentId;
      return typeCondition && cityCondition && dateCondition && searchCondition && onlyMineCondition;
    })
    const currentSort = sortComplex[sort]
    if (fromCrm) {
      return filtered
        .map(item => ({
          ...item,
          link: item.link.includes("?") ? `${item.link}&fromCrm=true` : `${item.link}?fromCrm=true`
        }))
        .sort((a, b) => new Date(b.dateAdd) - new Date(a.dateAdd));
    } else
      return [...filtered].sort((a, b) => new Date(b.dateAdd) - new Date(a.dateAdd));
  }, [favorites, selectedCity, selectedType, selectedDate, onlyMine, search, sort])
  const [noteText, setNoteText] = useState("");
  useEffect(() => {
    if (!filteredFavorites?.length) return
    setValue("selectedFavorites", selectedFavorites.filter(selected => filteredFavorites.some(({ id }) => selected.id === id)));
  }, [filteredFavorites, noteText])

  const [showPdfModal, hidePdfModal] = useModal(() => (
    <PanModal isBig title={'Настроить PDF'} closeModal={closePdfModal}>
      <PdfSettingsModal item={pdfItem} />
    </PanModal>
  ), [pdfItem]);
  const openPdfModal = item => {
    setValue("pdfItem", item)
    showPdfModal()
  }
  const closePdfModal = (params) => {
    setValue("pdfItem", {})
    hidePdfModal()
  }
  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={favorite? [{objectId: favorite.id, type: favorite.type, city: favorite.city?.code}]:
      selectedFavorites.map(({ id, type, city }) => ({ objectId: id, type, city: city?.code }))}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })

  const [openCompilationSettingsModal, closeCompilationSettingsModal] = useModal(() => {
    const closeModal = () => {
      closeCompilationSettingsModal()
      setValue("editableCompilation", false)
    }
    return <PanModal isSmall title={editableCompilation ? 'Настроить Подборку' : 'Создание новой подборки'} closeModal={closeModal}>
      <CompilationSettingsModal
        refresher={refresher}
        closeModal={closeModal}
        id={editableCompilation}
      />
    </PanModal>
  }, [editableCompilation, refresher]);

  const [openDeleteModal, hideDeleteModal] = useModal(({ }) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={hideDeleteModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
          <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
        </svg>
        {tabs[selectedTab]?.id === 'compilations' ? "Удалить выбранные подборки?" : "Удалить выбранные объекты из избранного?"}
      </div>
      <div className="crm-table__sf-controlsmodaltext">
        После удаления вся информация будет утеряна
      </div>

      {false && tabs[selectedTab]?.id === 'compilations' && isAllSelected ?
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={() => {
            deleteAllCompilations()
            hideDeleteModal()
          }}>Да</Button>
          <Button buttonType="line" onClick={hideDeleteModal}>Нет</Button>
        </div> :
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={tabs[selectedTab]?.id === 'compilations' ? () => deleteCompilations() : removeMultiple}>Да</Button>
          <Button buttonType="line" onClick={hideDeleteModal}>Нет</Button>
        </div>
      }

    </Modal>
  ), [selectedFavorites, selectedTab, tabs]);

  const onSelectItem = (checked, id) => {
    if (id == "all") return setValue("selectedFavorites", checked ? filteredFavorites : []);
    const checkedItem = filteredFavorites.find((item) => item.id == id);
    const selected = checked ? [...selectedFavorites, ...(checkedItem ? [checkedItem] : [])] : [...selectedFavorites.filter((item) => item.id != id)];
    setValue("selectedFavorites", selected);
  };

  const remove = ({ city, id, type }, e) => {
    e?.stopPropagation?.();
    send(["panpartner:favorite.ajax", "removeFromFavorite", { city: city?.code, id, type }])
      .then(resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.favorites = tmp.favorites.filter(item => item.id !== id)
          return tmp;
        })
      }, standartErrorToast)
      .finally(() => dispatch(getFavoriteCount()))
  }

  const removeMultiple = () => {
    const sanitizedSelectedObjects = selectedFavorites.map(({ id, type, city }) => ({ id, type, city: city?.code }))
    const formData = objectsArrayToFormData(sanitizedSelectedObjects, "objects")
    hideDeleteModal()
    send(["panpartner:favorite.ajax", "removeFromFavorites", formData])
      .then(resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.favorites = tmp.favorites.filter(item => !selectedFavorites.some(({ id }) => item.id === id))
          return tmp;
        })
        setValue("selectedFavorites", [])
      }, standartErrorToast)
      .finally(() => {
        dispatch(getFavoriteCount())
      })
  }
  const deleteCompilations = id => {
    const payload = id ? { id } : toFormData({ id: selectedFavorites.map(({ id }) => id) });
    hideDeleteModal()
    return request("panpartner:favorite.ajax", "removeCompilation", payload)
      .then(resp => {
        setData(prev => {
          const tmp = { ...prev };
          tmp.favorites = tmp.favorites.filter(item => item.id !== id)
          return tmp;
        })
        standartSuccessToast(resp)
        if (tabs[selectedTab]?.id === 'compilations') refresher()
        send(["panpartner:favorite.ajax", "getCompilations", {}])
          .then(resp => setValue("compilations", resp.compilations))
        return Promise.resolve(resp)
      }, standartErrorToast)
  }

  const deleteAllCompilations = () => {

    let allCompilations = []
    selectedFavorites.map((item) => {
      allCompilations.push(item.id)
    })

    return request("panpartner:favorite.ajax", "removeCompilation", {id: allCompilations})
      .then((resp) => {

        refresher()
        successToast(<div>Все ваши подборки успешно удалены</div>)

      }, standartErrorToast)
  }

  const patchedColumns = useMemo(() => {
    const tmp = [...compilationsColumns]
    tmp.push({
      title: "",
      dataIndex: "lastView",
      render: (text, record, isActive) => <div className="crm-table__sf-action">
        <CompilationControls
          openCompilationSettingsModal={()=>{
            setValue("editableCompilation", record.id)
            openCompilationSettingsModal()
          }}
          deleteCompilation={deleteCompilations}
          {...record}
          links={record.links} city={record.city} url={record.url} type={record.filterType} userId={record.client?.agentId} xmlId={record.xmlId}
        />
      </div>
    })
    return tmp.filter?.(column => !column.permission || (column.permission === "not-agent" && isNotAgent))
  }, [])

  const [displayNote, setDisplayNote] = useState(null)
  const [displayNoteText, setDisplayNoteText] = useState(null)
  const [displayNoteId, setDisplayNoteId] = useState(null)

  const handleViewNote = () => {
    return request('panpartner:favorite.ajax', "saveNote", {id: displayNoteId, note: displayNoteText, displayNote: displayNote})
      .then((resp) => {
        closeNoteViewModal()
        successToast(<div>Настройки отображения видимости заметки изменены</div>)
        refresher()
      })
  }

  const [openNoteViewModal, closeNoteViewModal] = useModal(() => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation" closeByPastClick close={closeNoteViewModal}>
      <CompilationViewingNoteModal
        close={closeNoteViewModal}
        displayNote={displayNote}
        setDisplayNote={setDisplayNote}
        handleViewNote={handleViewNote}
      />
    </Modal>
  ), [displayNote]);

  const [activeNote, setActiveNote] = useState(null)
  const [noteStatus, setNoteStatus] = useState("initial");
  const [fstArea, setFstArea] = useState(false)
  const [editCancelText, setEditCancelText] = useState('')


  const handleNoteBtnClick = (id) => {
    setNoteStatus("edit");
    setFstArea(true)
    setActiveNote(id)
    setNoteText('')
  };

  useEffect(() => {
    setEditCancelText(noteText)
  }, [editCancelText, filteredFavorites])
  const handleSaveNoteClick = (id, note) => {
    console.log(noteText)
    if (noteText.trim() !== "") {

      return request('panpartner:favorite.ajax', "saveNote", {id, note})
        .then((resp) => {
          setNoteStatus("ready");
          setActiveNote(null)
          filteredFavorites.map((i) => {
            if (i.id === activeNote) {
              i.note = editCancelText
            }
          })
        })

    }
  };

  const handleRemoveNoticle = (favoriteId, id) => {
    setNoteStatus("initial");
    setNoteText('')

    const index = filteredFavorites.findIndex((item) => item.id === id);
    if (index !== -1) {
      filteredFavorites[index].note = '';
      setNoteText('')
      setActiveNote(filteredFavorites[index].id)
    }

    return request('panpartner:favorite.ajax', "saveNote", {id: favoriteId, note: ''})

  }

  const handleCancelNoteClick = (id) => {
    if (noteStatus === "edit" && fstArea) {
      setNoteStatus("initial");
      setNoteText('')
    } else if (noteStatus === "edit"  && !fstArea) {
      setNoteStatus("ready");
      setNoteText(editCancelText)
      setActiveNote(null)
    }

    else if (noteStatus === "ready") {
      setNoteStatus("initial");
      setNoteText('')
    }
  };

  const handleEditNoteClick = (id) => {
    setFstArea(false)
    setNoteStatus("edit");
    setEditCancelText(noteText)
  };

  useEffect(() => {
    !fromCrm && tabs.pop()
  }, [tabs])

  if (isLoading && fromCrm) return <section  className="section section_view_white orders"><div className="wrapper" style={{textAlign: 'center'}}><FilterSkeleton /><Loader loaderColor='#2c2e3e'/></div></section>
  if (isLoading) return <Preloader loading />;

  const isAllSelected = !!filteredFavorites?.length && selectedFavorites?.length === filteredFavorites?.length;


  return (
      <div className="wrapper" style={fromCrm ? {marginTop: '-15px'} : {}}>
        {!fromCrm &&
        <div className="common-header">
          <div className="common-header__title">
            <h3 className="common-title">
              Избранное
            </h3>
          </div>
        </div>}

        {!fromCrm &&
      <NewTabsNavigation
        tabs={tabs}
        activeTabId={selectedTab}
        onNavClick={i => setValues({
          selectedTab: i,
          selectedCity: { title: 'Все города' , code: "all"},
          selectedType: { title: 'Тип недвижимости' },
          selectedDate: { title: 'За 30 дней' },
          onlyMine: false,
          selectedFavorites: [],
          search: "",
          sort: 2,
        })}
      />}
        {tabs.find((item, index) => index === selectedTab)?.id === 'ads' && favorites!== undefined &&
            <>

              {favorites.length !== 0 ?
              <>
                <div className="common-filters">
                  <div className="common-filters__dropdowns">
                    <Dropdown
                      value={selectedCity}
                      options={filter.city}
                      onChange={val => setValue("selectedCity", filter.city.find(({id}) => id === val))}
                    />
                    <Dropdown
                      value={selectedType}
                      options={filter.type}
                      onChange={val => setValue("selectedType", filter.type.find(({id}) => id === val))}
                    />
                    <Dropdown
                      value={selectedDate}
                      options={filter.dateAdd}
                      onChange={val => setValue("selectedDate", filter.dateAdd.find(({id}) => id === val))}
                    />

                  </div>
                </div>
                <div className="items-filters">
                  <div className="items-filters__left">
                    <CheckboxControlled
                      view="red"
                      checked={isAllSelected}
                      onChange={e => onSelectItem(e.target.checked, "all")}
                      id={"all"}
                    />
                    <label className="checkbox__label" htmlFor="all-complexs">
                      <b style={{
                        color: 'black',
                        paddingLeft: 5
                      }}>{filteredFavorites?.length} {`объявлени${getSuffixUniversal({
                        "1-1": "е",
                        "2-4": "я",
                        default: "й"
                      }, filteredFavorites?.length,)}`}</b>
                    </label>
                    <div className="special-filter-drop special-filter-drop_favorites">

                      <Dropdown
                        value={sortComplex[sort]}
                        options={sortComplex}
                        onChange={sortId => setValue("sort", sortId)}
                      />
                    </div>
                  </div>
                  <div className="item-filters__right">
                    <div className="items-filters__buttons">
                      {!!selectedFavorites?.length && <button className="items-filters__addcompilation-btn" onClick={()=>openCompilationModal({})}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 12L16 12" stroke="white" strokeLinecap="round"/>
                          <path d="M12 8L12 16" stroke="white" strokeLinecap="round"/>
                        </svg>
                        В подборку
                      </button>}
                      {!!selectedFavorites?.length && <span className="link" onClick={openDeleteModal}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                        d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6"
                        stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6"
                        stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                      Удалить выбранные
                    </span>}
                      {/*<span className="link" onClick={openCompilationSettingsModal}>*/}
                      {/*  модалка настройки подборки*/}
                      {/*</span>*/}
                    </div>
                  </div>
                </div>
              </> :
                <div className="compilations-stub">
                  <div className="compilations-stub__icon">
                    <CompilationsStubIcon/>
                  </div>
                  <p className="compilations-stub__text">В вашем списке нет <br/> избранных объектов</p>
                </div>
              }


              <ul className="favorites-list">
                {
                    filteredFavorites.map((item, index) => {
                      // console.log(item)
                    return (
                      <li className={`favorites-list__over-item`}  id={`myIdIs-${item.id}`} key={item.id}>
                        <CheckboxControlled
                          view="invert"
                          checked={!!selectedFavorites.find(({id}) => item.id == id)}
                          onChange={e => onSelectItem(e.target.checked, item.id)}
                          id={`row-item-${item.id}`}
                        />
                        <div className="favorites-list__item" onClick={e => goToUrl(item?.link, e)}>
                          {item?.status?.name === 'Продано' || item?.status?.name === 'Бронь' ? <div className="favorites-list__item_sold"/> : <></>}
                          <div className="favorites-list__item-left">
                            <img className="favorites-list__item-img favorites-list__item-img_apartments" src={getUrl(item?.photo?.src)}/>
                            <div className="favorites-list__item-content">
                              <div className="favorites-list__item-info">
                                <div className="favorites-list__item-title h4" style={{display: 'flex', alignItems: 'center'}}>
                                  {item?.minPrice}

                                  <div className="favorites-list__item-dynamic">
                                    {!!item?.dynamicPrices?.[2]?.flag && <svg style={item?.flag === 'down' ? {cursor: 'pointer', transform: 'rotate(180deg)'} : {marginBottom: 3, cursor: 'pointer'}} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.92578 0.0742188L11.0577 8.96289H0.793904L5.92578 0.0742188Z" fill={item?.dynamicPrices[2]?.flag === 'up' ? '#2CBA2E' : '#ff2d23'}/></svg>}
                                    <div className="sf favorites-list__item-dynamic_hint">
                                      {item.dynamicPrices && item?.dynamicPrices &&
                                      <div className={`sf__block-hint`}>
                                        <p className="h5-l" style={{marginBottom: 20}}>Динамика цены</p>
                                        {item?.dynamicPrices.map(({ title, value, flag }) => (
                                          <div className="sf__info-row" key={item.id}>
                                            <div className="sf__info-key">{title}</div>
                                            <div className="sf__info-value" style={flag && flag === 'up' ? {color: '#2CBA2E'} : flag && flag === 'down' && {color: '#ff2d23'} }>{ value}</div>
                                          </div>
                                        ))}
                                      </div>}
                                    </div>
                                  </div>
                                </div>
                                <div className="favorites-list__item-price">{item?.title}</div>
                                {item.type === 'secondary' ?
                                  <div className="favorites-list__item-address">{item?.address}</div> :
                                  <div className="favorites-list__item-address">{item?.builderTitle}</div>
                                }
                                <div className="favorites-list__item-deadline">{item?.ending}</div>
                              </div>

                              <div className="favorites-list__item-action">
                                <button className="favorites-list__item-addcompilation"
                                  onClick={(e) => {
                                    e?.stopPropagation?.();
                                    openCompilationModal({favorite: item})
                                  }}
                                >
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 12L16 12" stroke="#8790E5" strokeLinecap="round"/><path d="M12 8L12 16" stroke="#8790E5" strokeLinecap="round"/></svg>
                                  В подборку
                                </button>
                                <div className="items-filters" style={{marginTop: 0}}>
                                  <div className="items-filters__buttons">
                                  {/*<span className="link"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0563 6.66455H6.94372C6.42252 6.66455 6 7.0622 6 7.55273V18.1878C6 18.6783 6.42252 19.076 6.94372 19.076H15.0563C15.5775 19.076 16 18.6783 16 18.1878V7.55273C16 7.0622 15.5775 6.66455 15.0563 6.66455Z" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/><path d="M9 13L13 13" stroke="#2C2E3E" strokeLinecap="round"/><path d="M11 11L11 15" stroke="#2C2E3E" strokeLinecap="round"/><path d="M9 4H16.0728C16.7341 4 17.3684 4.26269 17.836 4.7303C18.3036 5.1979 18.5663 5.83212 18.5663 6.49342V16.5" stroke="#2C2E3E" strokeMiterlimit="10" strokeLinecap="round"/></svg>Сравнить</span>*/}
                                {/*<span className="link" onClick={openPdfModal}>*/}
                                <span className="link" onClick={(e) =>{
                                  e?.stopPropagation?.();
                                  openPdfModal(item)
                                }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12.4286V5.61905C6 5.45487 6.06522 5.29741 6.18131 5.18131C6.29741 5.06522 6.45487 5 6.61905 5H14.0476L18.381 9.33333V12.4286" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.0476 5V9.33333H18.3809" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 18.0001H7.2381C7.56646 18.0001 7.88137 17.8697 8.11356 17.6375C8.34575 17.4053 8.47619 17.0904 8.47619 16.762C8.47619 16.4337 8.34575 16.1187 8.11356 15.8866C7.88137 15.6544 7.56646 15.5239 7.2381 15.5239H6V19.2382" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.9999 15.5239H16.8333V19.2382" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.6904 17.6904H16.8333" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.1905 19.2382C12.6831 19.2382 13.1554 19.0425 13.5037 18.6943C13.852 18.346 14.0477 17.8736 14.0477 17.3811C14.0477 16.8885 13.852 16.4162 13.5037 16.0679C13.1554 15.7196 12.6831 15.5239 12.1905 15.5239H11.1072V19.2382H12.1905Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                  Скачать PDF
                                </span>


                                    {item.note === '' &&
                                      <span id="note-btn" className="link" onClick={
                                        (e) => {
                                          e?.stopPropagation?.();
                                          handleNoteBtnClick(item.id)
                                        }
                                      }>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                        Заметка
                                      </span>
                                    }


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="favorites-list__item-right">
                            <ul className="favorites-list__item-tags">
                              <li className={`label-item ${item?.status?.color === 'green' ? ' label_green' : item?.status?.color === 'gray' ?  ' label_red' : item?.status?.color === 'red' ?  ' label_red' : ''}`}>{item?.status?.name}</li>
                              {item.types && item.types.map((i) => <li key={i}>{i}</li>)}
                            </ul>
                            <div className="favorites-list__item-remove">
                              <span onClick={e => remove(item, e)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2192 18.9806C12.2192 18.9806 4.21924 14.5099 4.21924 9.08126C4.21924 8.12158 4.55243 7.19153 5.16214 6.44937C5.77184 5.7072 6.62038 5.19876 7.5634 5.01055C8.50641 4.82234 9.48564 4.96599 10.3345 5.41706C11.1833 5.86812 11.8494 6.59874 12.2192 7.4846C12.5891 6.59874 13.2551 5.86812 14.104 5.41706C14.9528 4.96599 15.9321 4.82234 16.8751 5.01055C17.8181 5.19876 18.6666 5.7072 19.2763 6.44937C19.886 7.19153 20.2192 8.12158 20.2192 9.08126C20.2192 14.5099 12.2192 18.9806 12.2192 18.9806Z" fill="#EF372E"/></svg>
                                Удалить из избранного
                              </span>
                            </div>
                          </div>



                          <div className="favorite-note">
                            {noteStatus === "edit" && activeNote === item.id && (
                              <div className="favorite-note__create-note">
                                <span className="favorite-note__label">Заметка</span>
                                <textarea
                                  className="textarea__control"
                                  placeholder="Текст Вашей заметки"
                                  value={noteText}
                                  onChange={(e) => {
                                    setNoteText(e.target.value)
                                    setEditCancelText(e.target.value)
                                  }}
                                  onClick={(e) => {e?.stopPropagation?.();}}
                                />

                                <div className="favorite-note__actions">
                                  <button id="save-note" className={noteText === '' || noteText === item.note ? 'favorite-note__actions_disable-btn' : ''} onClick={(e) => {
                                    e?.stopPropagation?.();
                                    handleSaveNoteClick(item.favoriteId, noteText)
                                    item.note = editCancelText
                                  }}>Сохранить</button>
                                  <button id="cancel-note" onClick={
                                    (e) => {
                                      e?.stopPropagation?.();
                                      handleCancelNoteClick()
                                    }
                                  }>Отменить</button>
                                </div>

                              </div>
                            )}

                            {item.note !== '' &&
                            <div className={`favorite-note__ready-note ${activeNote === item.id ? 'favorite-note__ready-note_hide' : ''}`}>
                              <div className='favorite-note__ready-content'>
                                <b>Заметка:{"\n"}</b>
                                {item.note}

                              </div>
                              <div className="favorite-note__ready-actions">

                                <span id="edit-note" onClick={(e) => {
                                  e?.stopPropagation?.();
                                  handleEditNoteClick(item.id)
                                  setActiveNote(item.id)
                                  setNoteText(item.note)
                                }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.95923 20.0012H4.70846C4.52057 20.0012 4.34037 19.9265 4.2075 19.7937C4.07464 19.6608 4 19.4806 4 19.2927V15.3336C4.00015 15.1481 4.07307 14.97 4.20309 14.8377L14.83 4.21076C14.9619 4.07714 15.1414 4.00135 15.3292 4.00002C15.5169 3.99869 15.6975 4.07194 15.8313 4.20367L15.8384 4.21076L19.7881 8.16043C19.8545 8.22568 19.9074 8.30338 19.9437 8.3891C19.9801 8.47481 19.9992 8.56685 20 8.65995C20.0007 8.75306 19.9832 8.8454 19.9482 8.9317C19.9133 9.018 19.8616 9.09656 19.7963 9.1629L19.7881 9.16999L8.95923 20.0012Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.5015 6.53931L17.4607 11.4985" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M19.5862 20.0001H8.95928L4.04492 15.0857" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14.981 9.0188L6.47949 17.5203" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                </span>
                                <span id="cancel-note" onClick={(e)=>{
                                  e?.stopPropagation?.();
                                  handleRemoveNoticle(item.favoriteId, item.id)
                                }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg></span>
                              </div>
                            </div>
                            }



                          </div>






                          <div></div>

                        </div>

                      </li>
                    )
                  })
                }

              </ul>
            </>
        }

        {
          tabs.find((item, index) => index === selectedTab)?.id === 'blocks' && favorites!== undefined &&
            <>
              {favorites.length !== 0 ?
              <>
                <div className="common-filters">
                  <div className="common-filters__search">
                    <div className="section-search">
                      <input className="section-search__inp" value={search} placeholder="Поиск по названию комплекса или застройщика" onChange={e=>setValue("search", e.target.value)}/>
                      {search !== '' && <div className="section-search__icon" onClick={()=>setValue("search","")}><SmallCloseIcon/></div>}
                    </div>
                  </div>
                  <div className="common-filters__dropdowns">
                    <Dropdown
                      value={selectedCity}
                      options={filter.city}
                      onChange={val => setValue("selectedCity", filter.city.find(({ id }) => id === val))}
                    />

                  </div>
                </div>
                <div className="items-filters">
                  <div className="items-filters__left">
                <div className="checkbox checkbox_view_red">
                  <div className="checkbox__box">
                    <input className="checkbox__control"
                      type="checkbox"
                      id="all-complexs"
                      checked={!!filteredFavorites?.length && selectedFavorites?.length === filteredFavorites?.length}
                      onChange={e => onSelectItem(e.target.checked, "all")}
                    />
                    <label className="checkbox__input" htmlFor="all-complexs" />
                    <div className="checkbox__marker" />
                  </div>
                  <label className="checkbox__label" htmlFor="all-complexs">
                    <b style={{ color: 'black', paddingLeft: 5 }}>{filteredFavorites?.length} {`комплекс${getSuffix(filteredFavorites?.length,)}`}</b>
                  </label>
                </div>

                <div className="special-filter-drop special-filter-drop_favorites">
                  <Dropdown
                    value={sortComplex[sort]}
                    options={sortComplex}
                    onChange={sortId => setValue("sort", sortId)}
                  />
                </div>

                  </div>

                  <div className="item-filters__right">
                    <div className="items-filters__buttons">
                   {!!selectedFavorites?.length && <span className="link" onClick={openDeleteModal}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/><path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/></svg>
                      Удалить выбранные
                    </span>}
                    </div>
                  </div>
                </div>
              </> :
                <div className="compilations-stub">
                <div className="compilations-stub__icon">
                <CompilationsStubIcon/>
                </div>
                <p className="compilations-stub__text">В вашем списке нет <br/> избранных объектов</p>
                </div>
              }

              <ul className="favorites-list">
              {filteredFavorites.map((item, index) => {
                  return (
                    <li className={`favorites-list__over-item`} id={`myIdIs-${item.id}`} key={item.id}>

                      <CheckboxControlled
                        view="invert"
                        checked={!!selectedFavorites.find(({ id }) => item.id == id)}
                        onChange={e => onSelectItem(e.target.checked, item.id)}
                        id={`item-${item.id}`}
                      />
                      <div className="favorites-list__item" onClick={e => goToUrl(item?.link, e)}>
                        <div className="favorites-list__item-left">
                          <img className="favorites-list__item-img" src={getUrl(item?.photo?.src)}/>

                          <div className="favorites-list__item-content">
                            <div className="favorites-list__item-info">
                              <div className="favorites-list__item-title h4">{item?.title}</div>
                              <div className="favorites-list__item-price">{item?.minPrice}</div>
                              <div className="favorites-list__item-address">{item?.builderTitle}</div>
                              <div className="favorites-list__item-deadline">{item?.ending}</div>
                            </div>
                            <div className="favorites-list__item-remove">
                        <span onClick={e => remove(item, e)}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2192 18.9806C12.2192 18.9806 4.21924 14.5099 4.21924 9.08126C4.21924 8.12158 4.55243 7.19153 5.16214 6.44937C5.77184 5.7072 6.62038 5.19876 7.5634 5.01055C8.50641 4.82234 9.48564 4.96599 10.3345 5.41706C11.1833 5.86812 11.8494 6.59874 12.2192 7.4846C12.5891 6.59874 13.2551 5.86812 14.104 5.41706C14.9528 4.96599 15.9321 4.82234 16.8751 5.01055C17.8181 5.19876 18.6666 5.7072 19.2763 6.44937C19.886 7.19153 20.2192 8.12158 20.2192 9.08126C20.2192 14.5099 12.2192 18.9806 12.2192 18.9806Z" fill="#EF372E"/></svg>
                          Удалить из избранного
                        </span>
                            </div>
                          </div>
                        </div>

                        <div className="favorites-list__item-right">
                          <ul className="favorites-list__item-tags">
                            {item?.types && item?.types.map((i) => {return (<li>{i}</li>)})}
                          </ul>
                        </div>

                      </div>

                    </li>
                  )
                })}
              </ul>
            </>
        }

        {
          tabs[selectedTab]?.id === 'compilations' && favorites?.length === 0 &&
          <>
            <div className="compilations-stub">
              <div className="compilations-stub__icon">
                <CompilationsStubIcon/>
              </div>
              <p className="compilations-stub__text">В вашем списке нет подборок</p>
              <div className="compilations-stub__btn" style={{marginBottom: 40}}>
                <Button buttonType="primary" onClick={openCompilationSettingsModal}>Добавить подборку</Button>
              </div>
            </div>
          </>
        }
      {tabs[selectedTab]?.id === 'compilations'  &&  !!favorites?.length && <>
        <div className="common-filters common-filters_compilations">
          <div style={{display: 'flex', gap: '20px'}}>
            <div className="common-filters__search">
              <div className="section-search">
                <input className="section-search__inp" value={search} placeholder="Поиск по названию подборки" onChange={e => setValue("search", e.target.value)} />
                {search !== '' && <div className="section-search__icon" onClick={() => setValue("search", "")}><SmallCloseIcon /></div>}
              </div>
            </div>
            <div className="common-filters__dropdowns">
              <Dropdown
                value={selectedAgent}
                options={filter.agent}
                onChange={val => setValue("selectedAgent", filter.agent.find(({ id }) => id === val))}
              />
              <Dropdown
                value={selectedDate}
                options={filter.dateAdd}
                onChange={val => setValue("selectedDate", filter.dateAdd.find(({ id }) => id === val))}
              />
            </div>
          </div>
          <div className="compilations-stub__btn">
            <Button onClick={openCompilationSettingsModal} buttonType="primary">Добавить подборку</Button>
          </div>

        </div>



        <div className="items-filters" style={{marginBottom: 20, marginTop: 30, position: 'relative'}}>

          <div className="items-filters__left">
            <CheckboxControlled
              view="red"
              id={"myCompilation"}
              checked={onlyMine}
              onChange={e => setValue("onlyMine", e.target.checked)}
            />
            <label className="checkbox__label" htmlFor="myCompilation">
              Только мои подборки
            </label>
          </div>


          {tabs[selectedTab]?.id === 'compilations' && !!selectedFavorites?.length &&
            <div className="item-filters__right" style={{position: 'absolute', right: 0}}>

              <div className="items-filters__buttons">

                  <span className="link" onClick={openDeleteModal}>
                  {/*<span className="link" onClick={() => {*/}
                  {/*  deleteAllCompilations()*/}
                  {/*}}>*/}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 6L19 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" /><path d="M14 10L14 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" /><path d="M10 10L10 16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" /><path d="M6 6L6 19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3L18 6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" /><path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4L13.8 4C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round" /></svg>
                    Удалить выбранные
                  </span>



              </div>

            </div>
          }
        </div>

        <div className="crm-table crm-table_filters favorites-list">

          <Table
            rowSelection={{
              columnTitle: (...ars) => {
                return <CheckboxControlled
                  view="red"
                  checked={!!isAllSelected}
                  onChange={e => onSelectItem(e.target.checked, "all")}
                  id={"all"}
                />
              },
              renderCell: (checked, { id, agentId }, index, originNode) => {
                return <CheckboxControlled
                  view="invert"
                  id={`row-item-${id}`}
                  checked={!!selectedFavorites.find(item => item.id == id)}
                  onChange={e => onSelectItem(e.target.checked, id)}
                // disabled={agentId != user.id}
                />
              },
            }}
            columns={patchedColumns}
            dataSource={filteredFavorites}
            pagination={false}

            customPagination={true}
            firstPageCount={10}
            // pageCount={50}
            locale={LocaleNotFound}
            rowKey="id"
            onRow={row => ({
              // onClick: e => onTableClick(row, e)
            })}
          />
        </div>
      </>}
    </div>
  )
}

export default NewFavorites

