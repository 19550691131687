import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import request from "../../api";
import { PenIcon } from "../Public/markup/icons";
import SubmitButton from "../SubmitButton";
import Tabs from "../core/Tabs";
import { standartErrorToast, standartSuccessToast } from '../toasts';

const alfaBiks = [
  '044525593',
  '042202824',
  '045004774',
  '046015207',
  '044030786',
  '046577964',
  '040702752',
  '040813770'
];

const Requisites = ({refresher, requisites, bankRequisites, companyId, showForm, showRequisites }) => {


  const {handleSubmit, register,  watch, errors, control} = useForm();
  const [activeTab, setActiveTab] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  // errors && console.log(errors)

  function formatReadableDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    return formattedDate;
  }

  const bikForSz = watch('requisitsSzCompanyBankBik')


  const onSubmit = values => {
    setIsFetching(true)
    let payload;
    if (activeTab === 'Организация') payload = {
      companyId: companyId,
      presetId: 1,
      [`requisites[companyName]`]: values.requisitsOrganizationCompanyName,
      [`requisites[companyFullName]`]: values.requisitsOrganizationCompanyFullName,
      [`requisites[inn]`]: values.requisitsOrganizationCompanyInn,
      [`requisites[kpp]`]: values.requisitsOrganizationCompanyKpp,
      [`requisites[ogrn]`]: values.requisitsOrganizationCompanyOgrn,
      [`requisites[director]`]: values.requisitsOrganizationCompanyDirector,
      [`requisites[address]`]: values.requisitsOrganizationCompanyAddress,
      [`bankRequisites[bankName]`]: values.requisitsOrganizationCompanyBankName,
      [`bankRequisites[bik]`]: values.requisitsOrganizationCompanyBankBik,
      [`bankRequisites[accNum]`]: values.requisitsOrganizationCompanyAccNum,
      [`bankRequisites[corAccNum]`]: values.requisitsOrganizationCompanyСorAccNum,
    }

    if (activeTab === 'ИП') payload = {
        companyId: companyId,
        presetId: 2,
        [`requisites[firstName]`]: values.requisitsIpFirstname,
        [`requisites[lastName]`]: values.requisitsIpLastname,
        [`requisites[secondName]`]: values.requisitsIpSecondname,
        [`requisites[inn]`]: values.requisitsIpInn,
        [`requisites[ogrnip]`]: values.requisitsIpOgrn,
        [`requisites[address]`]: values.requisitsIpAddress,
        [`bankRequisites[bankName]`]: values.requisitsIpCompanyBankName,
        [`bankRequisites[bik]`]: values.requisitsIpCompanyBankBik,
        [`bankRequisites[accNum]`]: values.requisitsIpCompanyAccNum,
        [`bankRequisites[corAccNum]`]: values.requisitsIpCompanyСorAccNum,
      }

    if (activeTab === 'Самозанятый') payload = {
        companyId: companyId,
        presetId: 4,
        [`requisites[firstName]`]: values.requisitsSzFirstname,
        [`requisites[lastName]`]: values.requisitsSzLastname,
        [`requisites[secondName]`]: values.requisitsSzSecondname,
        [`requisites[inn]`]: values.requisitsSzInn,
        [`requisites[address]`]: values.requisitsSzAddress,
        [`requisites[identDocSer]`]: values.requisitsSzPassportSeries,
        [`requisites[identDocNum]`]: values.requisitsSzPassportNumber,
        [`requisites[identDocIssuedBy]`]: values.requisitsSzDepartment,
        [`requisites[identDocDate]`]: formatReadableDate( values.requisitsSzPassportDate),
        [`requisites[identDocDepCode]`]: values.requisitsSzPassportCode,
        [`bankRequisites[bankName]`]: values.requisitsSzCompanyBankName,
        [`bankRequisites[bik]`]: values.requisitsSzCompanyBankBik,
        [`bankRequisites[accNum]`]: values.requisitsSzCompanyAccNum,
        [`bankRequisites[corAccNum]`]: values.requisitsSzCompanyСorAccNum,
      }

      return request('panpartner:b24.ajax', 'addRequisites', payload)
      .then(standartSuccessToast, standartErrorToast)
      .finally(()=>{
        refresher()
        setIsFetching(false)
      })
  }

  return (
    <>
          {showRequisites && <div className="lk-form__row _bg-gray requisites">
            <div className="lk-form__row-title">Реквизиты</div>
            <div className="lk-form__row-content">
              <p className="requisites__hint">
                Реквизиты будут использоваться для формирования счетов и других финансовых документов. <br/>Пожалуйста,
                указывайте корректные данные.
              </p>
              <div className="requisites__text-block">
                <div className="requisites__info">
                  <div className="requisites__info-title">Реквизиты компании</div>
                  <div className="requisites__info-text">

                    {requisites && requisites.map((item, index) => {
                      return (
                        <p>{index !== 0 ? item.title + ": " + item.value : item.value}</p>
                      )
                    })}


                  </div>
                </div>
                <div className="requisites__info">
                  <div className="requisites__info-title">Банковские реквизиты</div>
                  <div className="requisites__info-text">

                    {bankRequisites && bankRequisites.map((item) => {
                      return (
                        <p>
                          {item.title + ": " + item.value}
                        </p>
                      )
                    })}

                  </div>
                </div>
              </div>
              <div className="requisites__bottom-hints">
                <p className="requisites__hint">
                  Чтобы изменить реквизиты, напишите, пожалуйста личному менеджеру
                </p>
                <span className="requisites__write-manager">
                  <PenIcon/>
                  Написать менеджеру
                </span>
              </div>
            </div>
          </div>}
          {showForm && <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{paddingBottom: '60px', marginTop: '80px'}} className="lk-form__row _bg-gray requisites">
              <div className="lk-form__row-title">Реквизиты</div>
              <div className="lk-form__row-content">
                <p className="requisites__hint">
                  Реквизиты будут использоваться для формирования счетов и других финансовых документов. <br/>Пожалуйста,
                  указывайте корректные данные.
                </p>
                <p className="requisites__form-title">Реквизиты компании</p>

                <Tabs getActiveTab={setActiveTab}>
                  <section label={'Организация'}>
                    <div className="requisites__form">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available"
                               htmlFor="requisitsOrganizationCompanyName">
                          <span className="input__label">Название организации</span>
                          <input
                            id="requisitsOrganizationCompanyName"
                            type="text"
                            name="requisitsOrganizationCompanyName"
                            className={"input__control" + (errors.requisitsOrganizationCompanyName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название организации",
                              })
                            }
                            autoComplete="off"
                          />
                          {errors.requisitsOrganizationCompanyName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Полное наименование организации</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyFullName"
                            className={"input__control" + (errors.requisitsOrganizationCompanyFullName ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }
                          />

                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ИНН</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyInn"
                            className={"input__control" + (errors.requisitsOrganizationCompanyInn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Инн должен состоять из 10 цифр",
                                minLength: {value: 10},
                                maxLength: {value: 10}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyInn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyInn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">КПП</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyKpp"
                            className={"input__control" + (errors.requisitsOrganizationCompanyKpp ? " input__error" : "")}
                            ref={
                              register({
                                required: "Кпп должен состоять из 9 цифр",
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyKpp &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyKpp.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ОГРН</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyOgrn"
                            className={"input__control" + (errors.requisitsOrganizationCompanyOgrn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите ОГРН организации",
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyOgrn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyOgrn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Ген. директор</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyDirector"
                            className={"input__control" + (errors.requisitsOrganizationCompanyDirector ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }


                          />
                          {errors.requisitsOrganizationCompanyDirector &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyDirector.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Юридический адрес</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyAddress"
                            className={"input__control" + (errors.requisitsOrganizationCompanyAddress ? " input__error" : "")}
                            ref={
                              register({
                                required: 'Укажите юредический адрес организации',
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyAddress &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyAddress.message}</p>}
                        </label>
                      </div>


                    </div>
                    <p className="requisites__form-title requisites__form-title_bank">Банковские реквизиты</p>
                    <div className="requisites__form requisites__form_bank">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Наименование банка</span>
                          <input
                            type="text"
                            name="requisitsOrganizationCompanyBankName"
                            className={"input__control" + (errors.requisitsOrganizationCompanyBankName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название банка",
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyBankName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyBankName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">БИК</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyBankBik"
                            className={"input__control" + (errors.requisitsOrganizationCompanyBankBik ? " input__error" : "")}
                            ref={
                              register({
                                required: "БИК банка должен состоять из 9 цифр",
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyBankBik &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyBankBik.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Расчетный счёт</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyAccNum"
                            className={"input__control" + (errors.requisitsOrganizationCompanyAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Расчетный счет банка должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyAccNum.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Кор. счёт</span>
                          <input
                            type="number"
                            name="requisitsOrganizationCompanyСorAccNum"
                            className={"input__control" + (errors.requisitsOrganizationCompanyСorAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Корпоративный счет банка должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }

                          />
                          {errors.requisitsOrganizationCompanyСorAccNum &&
                            <p
                              style={{color: '#ff2d23'}}>{errors.requisitsOrganizationCompanyСorAccNum.message}</p>}
                        </label>
                      </div>

                    </div>
                  </section>
                  <section label={'ИП'}>
                    <div className="requisites__form">


                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Фамилия</span>
                          <input
                            type="text"
                            name="requisitsIpLastname"
                            className={"input__control" + (errors.requisitsIpLastname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите Фамилию",
                              })
                            }
                          />
                          {errors.requisitsIpLastname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpLastname.message}</p>}
                        </label>
                      </div>

                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Имя</span>
                          <input
                            type="text"
                            name="requisitsIpFirstname"
                            className={"input__control" + (errors.requisitsIpFirstname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите имя",
                              })
                            }
                          />
                          {errors.requisitsIpFirstname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpFirstname.message}</p>}
                        </label>
                      </div>

                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Отчество</span>
                          <input
                            type="text"
                            name="requisitsIpSecondname"
                            className={"input__control" + (errors.requisitsIpSecondname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите имя",
                              })
                            }
                          />
                          {errors.requisitsIpSecondname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpSecondname.message}</p>}
                        </label>
                      </div>
                       {/*required: "Укажите ИНН (12 цифр)",*/}
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ИНН</span>
                          <input
                            type="number"
                            name="requisitsIpInn"
                            className={"input__control" + (errors.requisitsIpInn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите ИНН (12 цифр)",
                                // pattern: {
                                //   value: /^[0-9]{12}$/,
                                //   message: 'Укажите ИНН (12 цифр)',
                                // },
                                minLength: {value: 12},
                                maxLength: {value: 12}
                              })
                            }
                          />
                          {errors.requisitsIpInn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpInn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ОГРНИП</span>
                          <input
                            type="number"
                            name="requisitsIpOgrn"
                            className={"input__control" + (errors.requisitsIpOgrn ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите ОГРНИП (15 цифр)",
                                minLength: {value: 15},
                                maxLength: {value: 15}
                              })
                            }
                          />
                          {errors.requisitsIpOgrn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpOgrn.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Адрес регистрации</span>
                          <input
                            type="text"
                            name="requisitsIpAddress"
                            className={"input__control" + (errors.requisitsIpAddress ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <p className="requisites__form-title requisites__form-title_bank">Банковские реквизиты</p>
                    <div className="requisites__form requisites__form_bank">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Наименование банка</span>
                          <input
                            type="text"
                            name="requisitsIpCompanyBankName"
                            className={"input__control" + (errors.requisitsIpCompanyBankName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название банка",
                              })
                            }
                          />
                          {errors.requisitsIpCompanyBankName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyBankName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">БИК</span>
                          <input
                            type="number"
                            name="requisitsIpCompanyBankBik"
                            className={"input__control" + (errors.requisitsIpCompanyBankBik ? " input__error" : "")}
                            ref={
                              register({
                                required: "БИК банка должен состоять из 9 цифр",
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }
                          />
                          {errors.requisitsIpCompanyBankBik &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyBankBik.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Расчетный счёт</span>
                          <input
                            type="number"
                            name="requisitsIpCompanyAccNum"
                            className={"input__control" + (errors.requisitsIpCompanyAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите счет должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }
                          />
                          {errors.requisitsIpCompanyAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyAccNum.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Кор. счёт</span>
                          <input
                            type="number"
                            name="requisitsIpCompanyСorAccNum"
                            className={"input__control" + (errors.requisitsIpCompanyСorAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Корпоративный счет должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }
                          />
                          {errors.requisitsIpCompanyСorAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsIpCompanyСorAccNum.message}</p>}
                        </label>
                      </div>

                    </div>
                  </section>
                  <section label={'Самозанятый'}>
                    <div className="requisites__form">

                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Фамилия</span>
                          <input
                            type="text"
                            name="requisitsSzLastname"
                            className={"input__control" + (errors.requisitsSzLastname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите фамилию",
                              })
                            }
                          />
                          {errors.requisitsSzLastname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzLastname.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Имя</span>
                          <input
                            type="text"
                            name="requisitsSzFirstname"
                            className={"input__control" + (errors.requisitsSzFirstname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите имя",
                              })
                            }
                          />
                          {errors.requisitsSzFirstname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzFirstname.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Отчество</span>
                          <input
                            type="text"
                            name="requisitsSzSecondname"
                            className={"input__control" + (errors.requisitsSzSecondname ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите отчество",
                              })
                            }
                          />
                          {errors.requisitsSzSecondname &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzSecondname.message}</p>}
                        </label>
                      </div>

                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">ИНН</span>
                          <input
                            type="number"
                            name="requisitsSzInn"
                            className={"input__control" + (errors.requisitsSzInn ? " input__error" : "")}
                            ref={
                              register({
                                required: "ИНН должен состоять из 12 цифр",
                                minLength: {value: 12},
                                maxLength: {value: 12}
                              })
                            }
                          />
                          {errors.requisitsSzInn &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzInn.message}</p>}
                        </label>
                      </div>

                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Адрес</span>
                          <input
                            type="text"
                            name="requisitsSzAddress"
                            className={"input__control" + (errors.requisitsSzAddress ? " input__error" : "")}
                            ref={
                              register({
                                required: false,
                              })
                            }
                          />

                        </label>
                      </div>


                      <p className="requisites__form-title requisites__form-title_bank">Данные паспорта</p>
                      <div className="lk-form__fr lk-form__fr_quarter">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Серия</span>
                          <input
                            type="number"
                            name="requisitsSzPassportSeries"
                            className={"input__control" + (errors.requisitsSzPassportSeries ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите серию паспорта",
                                minLength: {value: 4},
                                maxLength: {value: 5}
                              })
                            }
                          />
                          {errors.requisitsSzPassportSeries &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzPassportSeries.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_quarter">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Номер</span>
                          <input
                            type="number"
                            name="requisitsSzPassportNumber"
                            className={"input__control" + (errors.requisitsSzPassportNumber ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите номер паспорта",
                                minLength: {value: 6},
                                maxLength: {value: 6}
                              })
                            }
                          />
                          {errors.requisitsSzPassportNumber &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzPassportNumber.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_quarter">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Код подразделения</span>
                          <input
                            type="text"
                            name="requisitsSzPassportCode"
                            className={"input__control" + (errors.requisitsSzPassportCode ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите код подразделения",
                              })
                            }
                          />
                          {errors.requisitsSzPassportCode &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzPassportCode.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_quarter">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Дата выдачи</span>


                          <Controller
                            as={
                              <DatePicker
                                dateFormat="d MMM yyyy"
                                maxDate={new Date()}
                                showTimeSelect={false}
                                dropdownMode="select"
                                locale="ru"
                                className={"input__control" + (errors.requisitsSzPassportDate ? " input__error" : "")}
                                shouldCloseOnSelect
                                showYearDropdown
                                showMonthDropdown
                              />
                            }
                            control={control}
                            register={register({ required:"Укажите дату выдачи паспорта" })}
                            name="requisitsSzPassportDate"
                            valueName="selected"
                            onChange={([selected]) => {
                              return selected;
                            }}
                            rules={{
                              required:"Укажите дату выдачи паспорта",
                            }}
                          />

                          {errors.requisitsSzPassportDate &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzPassportDate.message}</p>}


                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_full">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Кем выдан</span>
                          <input
                            type="text"
                            name="requisitsSzDepartment"
                            className={"input__control" + (errors.requisitsSzDepartment ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите кем выдан паспорт",
                              })
                            }
                          />
                          {errors.requisitsSzDepartment &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzDepartment.message}</p>}
                        </label>
                      </div>
                    </div>
                    <p className="requisites__form-title requisites__form-title_bank">Банковские реквизиты</p>
                    <p className="requisites__hint requisites__hint_sz">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.8477" cy="12.5" r="8.5" stroke="#777A89"/>
                        <line x1="12.8906" y1="8" x2="12.8906" y2="14" stroke="#777A89" stroke-linecap="round"/>
                        <circle cx="12.8906" cy="16" r="0.5" fill="#777A89"/>
                      </svg>

                      С самозанятыми работаем только через Альфа-Банк
                    </p>
                    <div className="requisites__form requisites__form_bank">
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Наименование банка</span>
                          <input
                            type="text"
                            name="requisitsSzCompanyBankName"
                            className={"input__control" + (errors.requisitsSzCompanyBankName ? " input__error" : "")}
                            ref={
                              register({
                                required: "Укажите название банка",
                              })
                            }
                          />
                          {errors.requisitsSzCompanyBankName &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzCompanyBankName.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">БИК</span>
                          <input
                            type="number"
                            name="requisitsSzCompanyBankBik"
                            className={"input__control" + (errors.requisitsSzCompanyBankBik ? " input__error" : "")}
                            ref={
                              register({
                                validate: () => alfaBiks.includes(bikForSz) || 'БИК банка должен состоять из 9 цифр и принадлежать Альфа-Банку',
                                minLength: {value: 9},
                                maxLength: {value: 9}
                              })
                            }
                          />
                          <span className="input__alert requisites-bikHint">
                            <svg className="icon icon_info">
                              <use xlinkHref="#info"></use>
                            </svg>

                            <span className="requisites-bikHint__tooltip">
                              <p className="requisites-bikHint__tooltip-title"><strong>Альфа-Банк имеет 8 кодов БИК:</strong></p>
                              <p className="requisites-bikHint__tooltip-string">044525593 - Филиал Московский</p>
                              <p className="requisites-bikHint__tooltip-string">044030786 - Филиал Санкт-Петербургский</p>
                              <p className="requisites-bikHint__tooltip-string">042202824 - Филиал Нижегородский</p>
                              <p className="requisites-bikHint__tooltip-string">045004774 - Филиал Новосибирский</p>
                              <p className="requisites-bikHint__tooltip-string">046015207 - Филиал Ростовский</p>
                              <p className="requisites-bikHint__tooltip-string">046577964 - Филиал Екатеринбургский</p>
                              <p className="requisites-bikHint__tooltip-string">040702752 - Филиал Ставропольский</p>
                              <p className="requisites-bikHint__tooltip-string">040813770 - Филиал Хабаровский</p>
                            </span>

                          </span>
                          {errors.requisitsSzCompanyBankBik &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzCompanyBankBik.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Расчетный счёт</span>
                          <input
                            type="number"
                            name="requisitsSzCompanyAccNum"
                            className={"input__control" + (errors.requisitsSzCompanyAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Расчетный счет банка должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }
                          />
                          {errors.requisitsSzCompanyAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzCompanyAccNum.message}</p>}
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label className="input input_type_form input_width_available">
                          <span className="input__label">Кор. счёт</span>
                          <input
                            type="number"
                            name="requisitsSzCompanyСorAccNum"
                            className={"input__control" + (errors.requisitsSzCompanyСorAccNum ? " input__error" : "")}
                            ref={
                              register({
                                required: "Корпоративный счет банка должен состоять из 20 цифр",
                                minLength: {value: 20},
                                maxLength: {value: 20}
                              })
                            }
                          />
                          {errors.requisitsSzCompanyСorAccNum &&
                            <p style={{color: '#ff2d23'}}>{errors.requisitsSzCompanyСorAccNum.message}</p>}
                        </label>
                      </div>

                    </div>
                  </section>
                </Tabs>

                <div style={{position: 'absolute', bottom: '30px', right: '20px'}}>
                  <SubmitButton
                    className="button button_view_default"
                    isLoading={isFetching}
                    type="submit"
                  >
                    Добавить реквизиты
                  </SubmitButton>
                </div>


              </div>

            </div>
          </form>}
    </>
  )
}

export default Requisites
