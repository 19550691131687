import React, { useState } from 'react';
import { restorePassword } from '../../actions/user';
import SubmitButton from '../../components/SubmitButton';
import { standartErrorToast, successToast } from '../../components/toasts';
import useTimer from '../../hooks/useTimer';
import { restoreLastPath, validateMail } from '../../utils';

import { changePasswordPublic } from '../../actions/profile';
import useUniversalReducer from '../../hooks/useUniversalReducer';

const Recovery = (props) => {

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code")
  const loginParam = params.get("login")


  const { state, setValue, reset, setValues, dispatch } = useUniversalReducer({
    login: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    isLoading: false,
    errors: {

    }
  });
  const {
    login,
    password,
    isLoading,
    newPassword,
    confirmPassword,
    errors
  } = state
  const { history, user } = props;

  const [isFetching, setIsFetching] = useState(false)
  const [paswordIsSended, setPaswordIsSended] = useState(!!code)


  const { timeout, timerIsStarted, runTimer } = useTimer(60)



  const sendFn = () => {
    if (!login.replace(/ /, "gi")) return setValue("errors", { login: "Введите логин" })
    if (login.match(/\d/g)?.length >= 10) {
      runTimer()
      setIsFetching(true)
      restorePassword({ login: login }).then(resp => {
        successToast("Новый пароль отправлен вам на телефон")
        history.replace({ pathname: "/login" });
      }, standartErrorToast).finally(() => setIsFetching(false))
      return
    }
    if (validateMail.test(login)) {
      setIsFetching(true)
      restorePassword({ login: login }).then(resp => {
        successToast("Письмо с инструкциями отправлено на вашу почту")
        history.replace({ pathname: restoreLastPath(user.history) });
      }, standartErrorToast).finally(() => setIsFetching(false))
      return
    }
    return setValue("errors", { login: "Введите логин" })
  }
  const saveNewPassword = () => {
    if (!newPassword.replace(/ /, "gi")) return setValue("errors", { newPassword: "Введите новый пароль" })
    if (newPassword !== confirmPassword) return setValue("errors", { ...password, confirmPassword: "Пароли не совпадают" })
    setIsFetching(true)
    changePasswordPublic({ login: loginParam, password: newPassword, checkWord: code, confirmPassword }).then(resp => {
      successToast("Новый пароль сохранён")
      history.replace({ pathname: "/login" });
    }, standartErrorToast).finally(() => setIsFetching(false))
  }

  let content = ""
  if (!code && !loginParam && !timerIsStarted) content = <form className="form" >

    <div className="hp__form-item">
      <label htmlFor="sign-in_password" className="hp__form-label text">Телефон или email</label>
      <div
        className="input input_width_available input_type_form"
      >
        <input
          name="login"
          type="login"
          placeholder="Введите телефон или email"
          className={`input__control${errors.login ? ' input__error' : ''}`}
          onChange={e => setValue("login", e.target.value)}
        />
        {errors.login && <span className="input__error-label">    {errors.login}   </span>}
      </div>
    </div>

    <div className="hp__form-item hp__form-item_space-between">
      <SubmitButton
        className="hp__submit button button_view_default"
        isLoading={isFetching}
        type="button"
        onClick={sendFn}
      >
        <span>Отправить</span>
      </SubmitButton>
    </div>
  </form>


  if (code && loginParam) content = <form className="form">
    <div className="hp__form-item">
      <label htmlFor="sign-in_password" className="hp__form-label text">Введите новый пароль</label>
      <div
        className="input input_width_available input_type_form"
      >
        <input
          name="new-password"
          type="password"
          autocomplete="new-password"
          placeholder="Введите новый пароль"
          className={`input__control${errors.login ? ' input__error' : ''}`}
          onChange={e => setValue("newPassword", e.target.value)}
        />
        {errors.newPassword && <span className="input__error-label">{errors.newPassword}</span>}
      </div>
    </div>

    <div className="hp__form-item">
      <label htmlFor="sign-in_password" className="hp__form-label text">Введите новый пароль</label>
      <div
        className="input input_width_available input_type_form"
      >
        <input
          name="new-password"
          type="password"
          autocomplete="new-password"
          placeholder="Повторите новый пароль"
          className={`input__control${errors.login ? ' input__error' : ''}`}
          onChange={e => setValue("confirmPassword", e.target.value)}
        />
        {errors.confirmPassword && <span className="input__error-label">{errors.confirmPassword}</span>}
      </div>
    </div>
    <div className="hp__form-item hp__form-item_space-between">
      <SubmitButton
        className="hp__submit button button_view_default"
        isLoading={isFetching}
        type="button"
        onClick={saveNewPassword}
      >
        <span>Отправить</span>
      </SubmitButton>
    </div>
  </form>

  return (
    <div className="hp__page">
      <section className="hp__become-p hp__promo">
        <div className="hp__page-form">
          <h1 className="hp__h1 h1 text-center">Восстановить пароль</h1>
          <p className="hp__sign hp__sign_sm color-brand-3 big-text text-center">
            {paswordIsSended ? `Введите полученный пароль` : `Для восстановления введите телефон или email, которые были указаны при регистрации`}
          </p>
          <div className="hp__form-content">
            {content}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Recovery