import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useParams } from "react-router";
import { Map, YMaps } from 'react-yandex-maps';
import { getFormSettings, sendForm } from '../../../actions/constructor';
import { sendConstructorEvent, sendVKEvent } from "../../../utils";
import { getFormWithUTM, getSubdomain, getUrlWithProtocol } from '../../../utils/location';
import { infoToast, standartErrorToast, successToast } from "../../toasts";


const themeColors = {
  "yellow": "#2C2E3E",
  "gray": "#3F3F57",
  "green": "#183024",
  "red": "#301120",
  "blue": "#173343",
  "asphalt": "#183024",
  "purple": "#173343",
  "burgundy": "#2C2E3E",
  "nightsky": "#14143A",
  "turquoise": "#102E28",
}

const Consultation = (props) => {

  const {
    darkTheme,
    isPreview,
    coordinates,
    address,
    site,
    phoneAgency,
    phone,
    theme
  } = props;
  const subDomain = getSubdomain()
  const { handleSubmit, register, errors } = useForm();
  const [callbackForm, setCallbackForm] = useState({})
  const [formSettings, setFormSettings] = useState([])
  const { id } = useParams()
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  useEffect(() => {
    if(!mapInstance || !ymaps || !theme) return

    const placemarkTemplate2 =  ymaps.templateLayoutFactory.createClass(
      `<svg width="25" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 40S25 24.873 25 13.044C25 5.839 19.403 0 12.5 0 5.596 0 0 5.84 0 13.043 0 25.155 12.5 40 12.5 40zm5.682-26.25c0-3.451-2.545-6.25-5.682-6.25-3.137 0-5.682 2.799-5.682 6.25S9.363 20 12.5 20c3.137 0 5.682-2.799 5.682-6.25z" fill="${themeColors[theme.color]}"/></svg>`,
    )
    const placeMaprk  = new ymaps.Placemark(coordinates, {
  }, {
      iconLayout: placemarkTemplate2,
      iconOffset: [-12.5, -40],

  });
  mapInstance.geoObjects
  .add(placeMaprk);
  }, [mapInstance, theme, ymaps])

  const getFieldName = (code) => {
    for (let setting of formSettings) {
      if (setting.code === code) {
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const setForm = (value, code, arrayValue) => {
    const tmp = {
      ...callbackForm
    }

    if (arrayValue && value) {
      if (!tmp[code]) {
        tmp[code] = []
      }
      tmp[code].push(arrayValue)
    }
    else if (arrayValue && !value) {
      tmp[code] = tmp[code].filter(val => val !== arrayValue)
    } else {
      const ident = getFieldName(code)
      tmp[ident] = value
    }
    setCallbackForm(prev => ({ ...prev, ...tmp }))
  }

  const submit = (successCallback = () => { }) => {
    let formData = getFormWithUTM();
    let keys = Object.keys(callbackForm);
    if (isPreview) {
      infoToast(<div>В режиме предварительного просмотра отправка форм невозможна</div>)
      return
    }
    keys.forEach((key) => {
      if (Array.isArray(callbackForm[key])) {
        callbackForm[key].forEach((appendValue) => {
          formData.append(`${key}`, appendValue);
        });
      } else {
        formData.append(`${key}`, callbackForm[key]);
      }
    });
    if (id) {
      const buildingField = getFieldName("building_name")
      formData.append(buildingField, id)
    }
    formData.append("subDomain", subDomain)
    formData.append("title", "Получить консультацию")
    formData.append("url", window.location.href)
    sendForm(34, formData).then(
      () => {
        successToast(<div>Спасибо! Форма успешно отправлена</div>)
        sendConstructorEvent(id ? "tr_buiding_form_send" : "tr_main_form_send", id ? { complexId: id } : null, true);
        sendVKEvent('conversion');
      },

      standartErrorToast
    );
  }
  useEffect(() => {
    getFormSettings(34, "spb", subDomain)
      .then(
        resp => setFormSettings(resp.questions),
        standartErrorToast
      )
  }, [])
  useEffect(() => {
    if (!formSettings) return
    const defaultPolicyValue = formSettings.find(setting => setting.code === "policy")?.items?.[0]?.value
    setForm([defaultPolicyValue], "policy")
  }, [formSettings])


  return (
    <section className={`consultation constructor__section${darkTheme ? ` consultation_dark constructor__section_gray` : ` constructor__section_no-margin`}`}>

      <div className="constructor__container">

        <div className="constructor__wrapper consultation__wrapper">

          <div class="consultation__row">
            <form className="consultation__form form consultation__form_half">
              <h2 className="h2 constructor__title advantages__title">
                Получить консультацию
              </h2>
              <div className="form__row">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-partnership-firstName"
                >

                  <input
                    id="modal-partnership-firstName"
                    name="name"
                    className={
                      "input__control" + (errors.name ? " input__error" : "")
                    }
                    ref={register({
                      required: "Введите имя",
                    })}
                    placeholder="Ваше имя"
                    value={callbackForm[getFieldName("name")]}
                    onChange={e => setForm(e.target.value, "name")}
                  />
                  {errors.name && (
                    <span className="input__error-label">
                      {errors.name.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form__row">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-partnership-name"
                >

                  <InputMask
                    id="modal-partnership-name"
                    className={
                      "input__control" + (errors.phone ? " input__error" : "")
                    }
                    mask="+7 (999) 999-99-99"
                    autoComplete="off"
                    maskChar=" "
                    name="phone"
                    inputRef={register({
                      required: "Введите телефон",
                    })}
                    placeholder="Ваш телефон"
                    value={callbackForm[getFieldName("phone")]}
                    onChange={e => setForm(e.target.value, "phone")}
                  />
                  {errors.phone && (
                    <span className="input__error-label">
                      {errors.phone.message}
                    </span>
                  )}
                </label>
              </div>
              {formSettings?.find(setting => setting.code === "policy")?.items?.map((item, i) => {
                return <div className="form__row" key={`consultation-item-${i}`}>
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        "checkbox__box" +
                        (errors.agreement ? " checkbox__box-error" : "")
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-partnership-agreement"
                        name="agreement"
                        ref={register({
                          required: true,
                        })}
                        value={item.value}
                        onChange={(e) => setForm(e.target.checked, item?.fieldName, e.target.value)}
                        checked={!!callbackForm?.[item?.fieldName]?.includes(item.value)}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-partnership-agreement"
                      ></label>
                      <div className="checkbox__marker"></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-partnership-agreement"
                    >
                      Отправляя эту форму, Вы соглашаетесь с<br />
                      <a className="link" href="https://toprealtors.ru/policy" target="_blank">политикой конфиденциальности</a>
                    </label>
                  </div>
                </div>
              })}


              <button className="button constructor__btn form__submit" type="button" onClick={() => submit()}>
                Отправить
              </button>
            </form>

            <div className="consultation__map">
              <YMaps

              >
                <Map
                  defaultState={{
                    center: coordinates,
                    zoom: 16,
                  }}
                  className="complex__map-wrap"
                  modules={['templateLayoutFactory', 'Template', "Placemark"]}
                  onLoad={setYmaps}
                  instanceRef={setMapInstance}
                >
                </Map>
              </YMaps>
              <div className="consultation__map-info">
                <p className="consultation__map-title">Наш офис</p>
                <p className="consultation__map-adress">{address}</p>

                <div className="consultation__map-blocks">
                  {(phoneAgency || phone) && <div className="consultation__map-block">
                    <svg className="consultation__map-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.4392 1.99365C20.008 2.95396 22.7958 5.74177 23.7561 9.31058" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15.3684 5.9917C17.5097 6.56754 19.182 8.23988 19.7579 10.3812" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.78419 12.9604C8.85656 15.1534 10.6358 16.9241 12.834 17.9878C13.1616 18.143 13.546 18.1137 13.846 17.912L17.0838 15.7517C17.3752 15.5569 17.7441 15.5241 18.0648 15.662L24.1214 18.2568C24.5438 18.4361 24.7973 18.874 24.7421 19.3291C24.3456 22.4273 21.7095 24.7496 18.5855 24.7496C8.8738 24.7496 1 16.8758 1 7.16413C0.998277 4.04185 3.32059 1.40403 6.41873 1.0075C6.87389 0.95233 7.3118 1.20577 7.4911 1.62816L10.0893 7.68997C10.2255 8.00893 10.1944 8.37443 10.0048 8.66407L7.85316 11.9501C7.65489 12.2518 7.6273 12.6346 7.78419 12.9604Z" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="consultation__map-anchors">
                      {phoneAgency && <a href={`tel:+${phoneAgency}`}>{phoneAgency}</a>}
                      {phone && phoneAgency != phone && <a href={`tel:+${phone}`}>{phone}</a>}
                    </div>
                  </div>}

                  {site && <div className="consultation__map-block">
                    <svg className="consultation__map-icon" width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.08497 5.33301H21.5836C22.1812 5.33301 22.6669 5.81867 22.6669 6.41627V21.5819C22.6669 22.1795 22.1812 22.6651 21.5836 22.6651H2.08497C1.48737 22.6651 1.00171 22.1795 1.00171 21.5819V6.41627C1.00171 5.81867 1.48556 5.33301 2.08497 5.33301Z" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.33496 5.33303V2.08326C5.33496 1.48566 5.82062 1 6.41822 1H25.9169C26.5145 1 27.0001 1.48566 27.0001 2.08326V17.2489C27.0001 17.8465 26.5145 18.3321 25.9169 18.3321H22.6671" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1 9.66602H22.6652" strokeWidth="2" strokeMiterlimit="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="consultation__map-anchors">
                      <a href={getUrlWithProtocol(site)} target="_blank" rel="noopener noreferrer nofollow">{site.replace("https://","")}</a>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
};

export default Consultation;
