import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";

const LoadMoreSpinner = ({ loading, styles = "" }) => {
  const override = css`
    margin: 0 auto;
    border-color: red;
    text-align: center;
    display: block;
    ${styles}
  `;

  return <BeatLoader
    css={override}
    size={6}
    margin={2}
    color={"#2c2e3e"}
    loading={!!loading}
  />
};

export default LoadMoreSpinner;