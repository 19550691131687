import React, {useMemo, useState} from "react";
import './index.scss'
import {NavLink, useParams} from "react-router-dom";
import useApi from "../../hooks/useApi";
import {crmFiltrateItems, defaultCrmFilter, fixationPageConfig} from "../../pages/Crm/utils";
import {compareWords} from "../../utils";
import Preloader from "../Preloader";
import FilterSkeleton from "../../pages/Crm/FilterSkeleton";
import {CrmFilterBottomRow, MainCrmFilter} from "../../pages/Crm/CrmFilter";
import {FatalErrorComponent} from "../ErrorBoundry/FatalError";
import Table from "../core/Table";
import {fixationColumns, viewingColumns} from "../../pages/Crm/columns";
import {LocaleNotFound} from "../core/Table/tableUtils";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import FixationModal from "../FixationModal";
import Viewing from "./viewing";

const ClientViewings = ({ location: { state: defaultFilter = defaultCrmFilter } }) => {
  const { id } = useParams()
  // getClientSignUpToViews(clientId)
  // getSignUpToView(id)
  const { data: {items, title, filter: filterData, buttonLink, buttonTitle }, errors, isLoading } = useApi({
    payload: ["panpartner:deals.ajax", "getClientSignUpToViews", { clientId: id }],
  })
  const [filter, setFilter] = useState(defaultFilter)
  const setFilterValue = (key, value) => setFilter(prev => ({ ...prev, [key]: value }));
  const { topFilters, bottomFilters } = fixationPageConfig;

  const error = errors?.[0]?.message
  const filteredItems = useMemo(() => {
    if (isLoading || !items?.length) return []
    return crmFiltrateItems({
      alternativeData: items,
      currentTab: fixationPageConfig,
      filter,
    }).filter(item => {
      if (!filter?.search?.replace(/ /gi, "")) return true
      return compareWords(item.blockName, filter?.search)
    })
  }, [filter, items, isLoading])

  const [viewId, setViewId] = useState('')
  const [openViewModal, closeViewModal] = useModal(
    () => (
      <PanModal
        title='Детали записи'
        closeModal={closeViewModal}
        isBig
        hashParams={`viewId=${viewId}`}
        docTitle='Детали записи'
      >
        <Viewing id={viewId}/>
      </PanModal>
    ), [viewId]
  );

  if (isLoading) return <Preloader loading />

  return (
    <div className="wrapper">
      <div className="common-header">
        <div className="common-header__title">
          <NavLink
            to={'/crm/viewing'}
            className="common-header__chevron"
          >
            <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round" />
            </svg>
          </NavLink>
          <h3 className="common-title">
            {title}
          </h3>
        </div>
      </div>
      {isLoading && <FilterSkeleton />}
      {!isLoading && <div className="crm-filters-header">
        {filterData && <MainCrmFilter
          filterData={filterData}
          filter={filter}
          setFilter={setFilterValue}
          tabFilters={topFilters}
          searchPlaceholder={"Поиск по названию ЖК"}
          link={{ buttonLink, buttonTitle }}
        />}
        <br/>
      </div>}

      <div className="crm-table crm-table_client">
        {/*{error && <FatalErrorComponent*/}
        {/*  title={<h1 className="h1 fatal-title">Ошибка</h1>}*/}
        {/*  text={error}*/}
        {/*  showButton={false}*/}
        {/*  style={{ height: "100%" }}*/}
        {/*/>}*/}
        {/*{!error && <Table*/}

        <Table
          rowKey="id"
          columns={viewingColumns}
          dataSource={filteredItems}
          pagination={false}
          locale={LocaleNotFound}
          onRow={() => ({
            onClick: (e) => {
              setViewId(e.currentTarget.getAttribute("data-row-key"))
              openViewModal()
            }
          })}
        />

      </div>

    </div>
  )
}

export default ClientViewings
