const initialState = {
	isFetching: false,
	error: false,
  data: [],
  rooms: [],
	filterData: {
		agents: [],
		clients: [],
		date: {
			min: '',
			max: '',
		},
		status: [],
	},
	filter: {
		agents: [],
		clients: [],
		date: {
			startDate: null,
			endDate: null,
		},
		status: [],
	},
};

const meetings = (state = initialState, action) => {
	switch (action.type) {
		case 'MEETINGS_REQUEST':
			return {
				...state,
				isFetching: true,
				error: false,
			};
		case 'MEETINGS_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				data: action.data,
				rooms: action.meetingRooms,
				filterData: action.filterData,
			};
		case 'MEETINGS_SET_FILTER':
			return { ...state, filter: { ...state.filter, ...action.filter } };
		case 'MEETINGS_REMOVE':
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.id),
			};
		case 'MEETINGS_CLEAR_FILTER':
			return { ...state, filter: initialState.filter };
		case 'MEETINGS_FAILURE':
			return {
				...state,
				isFetching: false,
				error: true,
			};
		case 'MEETINGS_RESET':
			return { ...initialState };
		default:
			return state;
	}
};

export default meetings;
