import { Link } from 'react-router-dom';
import { CarIcon, WalkIcon } from './icons';
import './index.scss';
import classNames from 'classnames';
import StatusCircle from '../StatusCircle';
import {CardBottomStatusIcon, CardChromeBtnIcon, CardCubeBtnIcon, CardHeartBtnIcon} from "../../Public/markup/icons";

const Card = ({
                link,
                topLabel,
                bottomLabel,
                src,
                title,
                subtiltle,
                descTitle,
                descValue,
                tableItems,
                subway,
                isShort,
                isFlat,
                isBordered,
                onMouseMove,
                id,
                bottomText,
                onClick,
                status,
                bottomLabelList,
                quickViewEnabled,
                aeroPanorama,
                isAutoPan,
                bottomStatus,
                withoutBtnsList,
                customTable,
                deadline,
                handleFavorite,
                inFavorite,
                updateFlat,
                isPublic,
                houseType
              }) => {
  const cardClass = classNames({
    'sf-card': true,
    'sf-card_short': isShort,
    'sf-card_flat': isFlat,
    'sf-card_bordered': isBordered,
    'sf-card_flat-update': updateFlat,
  });
  const wrap = content => link ? <Link
    to={link}
    target={!quickViewEnabled && '_blank'}
    className={cardClass}
    onMouseMove={() => onMouseMove?.(id)}
    onClick={() => onClick?.(id)}
  >{content}</Link> : <div
    className={cardClass}
    onMouseMove={() => onMouseMove?.(id)}
    onClick={() => onClick?.(id)}
  >{content}</div>

  return wrap(<><div className="sf-card__header">
      {status && <StatusCircle status={status} addClass="sf-card__status" />}
      {!!topLabel && (
        <span className="sf-card__hashtag sf-card__label">{topLabel}</span>
      )}
      {!!bottomLabel && (
        <span className="sf-card__date sf-card__label">{bottomLabel}</span>
      )}

      {!withoutBtnsList &&
        <ul className="sf-card__btn-list">
          {!isPublic && <li className="sf-card__btn sf-card__btn_heart" onClick={handleFavorite}>
            <CardHeartBtnIcon inFavorite={inFavorite}/>
          </li>}
          {aeroPanorama && aeroPanorama !== undefined && aeroPanorama !== false && aeroPanorama !== null &&
            <li className="sf-card__btn sf-card__btn_cube">
              <CardCubeBtnIcon/>
            </li>
          }
          {isAutoPan && isAutoPan !== undefined && isAutoPan !== false && isAutoPan !== null &&
            <li className="sf-card__btn sf-card__btn_chrome">
              <Link to="/autopan" target="_blank"><CardChromeBtnIcon/></Link>
            </li>
          }
        </ul>
      }

      {!!bottomLabelList && (
        <div className="sf-card__label-list">
          {bottomLabelList.map((label) => (
            <span className="sf-card__label" key={label}>{label}</span>
          ))}
        </div>
      )}

      {deadline &&
        <div className="sf-card__label-list">
          <span className="sf-card__label">{deadline}</span>
        </div>
      }
      <img className="sf-card__pic" src={src} alt="" />
    </div>
      <div className="sf-card__body">
        {
          houseType && <p className="sf-card__before-title">{houseType}</p>
        }
        {!!title && (
          <h3
            className={`${isShort ? 'h6' : isFlat ? 'h55' : 'h3'
            } sf-card__title`}
          >
            {title}
          </h3>
        )}
        {!!subtiltle && <p className="text sf-card__text">{subtiltle}</p>}

        {(descTitle || descValue) && (
          <p className="sf-card__descr-row">
            {descTitle && <p className="text sf-card__descr">{descTitle}</p>}
            {!isShort && descValue && (
              <p className="text sf-card__descr">{descValue}</p>
            )}
          </p>
        )}

        {!!tableItems && (
          <div className={`sf-card__table ${customTable ? 'sf-card__table_custom' : ""}`}>
            {tableItems.map(({ title, count, middleTitle, subtitle, id }) => (
              <div className="sf-card__row" key={id}>
                <div className="sf-card__col">
                  <b className="sf-card__type">{title}</b>
                  {count && <span className="sf-card__counter">{count}</span>}
                </div>
                {!isShort && (
                  <div className="sf-card__col">
                    <span className="sf-card__param">{middleTitle}</span>
                  </div>
                )}

                <div className="sf-card__col">
                  <span className="sf-card__param">{subtitle}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {subway && !isFlat && (
          <div className="sf-card__metro-list">
            {subway.map(({ color, distanceType, minutes, name }) => (
              <div className={`sf-card__metro`} key={name}>
              <span className="sf-card__metro-station">
                <span
                  className="sf-card__metro-circle"
                  style={{ backgroundColor: color }}
                ></span>
                м. {name}
              </span>
                {!!distanceType && !!minutes && (
                  <div className="sf-card__metro-time">
                    {distanceType === 'byTransport' ? (
                      <CarIcon />
                    ) : (
                      <WalkIcon />
                    )}
                    <span className="sf-card__metro-route">{minutes} мин.</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {bottomText && (
          <span className="sf-card__descr sf-card__bottom-text">
          {bottomText}
        </span>
        )}
        {
          bottomStatus && (
            <div className="sf-card__bottom-status">
              <CardBottomStatusIcon classname={bottomStatus.color}/> {bottomStatus.value}
            </div>
          )
        }
      </div></>
  )
};

export default Card;
