import request from "../api";
import { actionFactory } from "./actionUtils";

const requestComplex = actionFactory("COMPLEX_REQUEST");

const successComplex = actionFactory("COMPLEX_SUCCESS");

export const clearComplex = actionFactory("COMPLEX_RESET");

const failureComplex = actionFactory("COMPLEX_FAILURE")

const setCount = actionFactory("COMPLEX_APARTMENTS_COUNT");

const successComplexApartments = actionFactory("COMPLEX_APARTMENTS_SUCCESS");

export const fetchComplex = params =>
  dispatch => {
    dispatch(requestComplex());
    return request("panpartner:block.detail.ajax", "getBlock", params)
      .then(
        data => {
          dispatch(
            successComplex(data)
          );
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureComplex());
          return Promise.reject(error);
        },
      );
  };

export const getApartments = (city, filter, savedFilterXmlId, params = {}) =>
  dispatch =>
    request("panpartner:block.detail.ajax", "getApartments", { ...filter, city, savedFilterXmlId, ...params })
      .then(data => dispatch(successComplexApartments({...data, ...params})));

export const getCount = (city, filter, savedFilterXmlId) =>
  dispatch =>
    request("panpartner:block.detail.ajax", "getCount", { ...filter, city, savedFilterXmlId })
      .then(data => dispatch(setCount(data)));

export const getDynamicPrices = form => request("panpartner:block.filter.ajax", "getDynamicPrices", form);

export const getViewWindow = (component, form) => request(component, "getViewWindow", form);

export const getBankPrograms = form => request("panpartner:block.detail.ajax", "getBankPrograms", form);
