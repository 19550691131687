import { uniqBy, without } from "lodash";
import React, { useState } from "react";
import { memo } from "react";
import useFileManager from "../../../hooks/useFileManager";
import FileButton from "../../../pages/SellAssigment/FileButton";
import { FileUploader } from "../../Constructor/Admin/MyObjects/Components";
import { standartErrorToast } from "../../toasts";
import './index.scss'



const UploaderDocs = memo(({ files = [], title, deleteFileRequest, uploadFileRequest }) => {
  const [loadableFiles, setLoadableFiles] = useState([])
  const handleLoad = (allFiles, type) => { 
    if (type !== "upload" || !uploadFileRequest) return
    const newFiles = allFiles.filter(item => !files.some(file => file.fakeId === item.fakeId))
    setLoadableFiles(prev => uniqBy([...prev, ...newFiles], "fakeId"))
    if(!newFiles?.length) return
    uploadFileRequest(newFiles, type)
      .then(() => setLoadableFiles(
        prev => prev.filter(
          item => !newFiles.some(file => file.fakeId === item.fakeId)
        )
      ), err => {
        setLoadableFiles([])
        Promise.reject(err)
      }).finally(()=>setFiles([]))
  }
  const { uploadFiles, deleteFile, setFiles } = useFileManager({
    maxFiles: 40,
    onFilesChange: handleLoad,
    defaultFIles: files,
  });

  return <div className="uploadedr-docs">
    <h6 className="uploadedr-docs__title">{title}</h6>
    <div className="modal-form">
      <FileButton
        label={`Добавить файл`}
        loadFiles={e => uploadFiles(e)}
        files={files}
        additionalWrapperClass="modal-form__item--client-docs"
        ident="contractWithClient"
        removeFile={(ident, i, id) => deleteFile(i, id)}
        deleteFileRequest={deleteFileRequest}
        loadableFiles={loadableFiles}
      />
    </div>
  </div>
});

export default UploaderDocs