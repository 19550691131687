import React, { useEffect } from "react";
import useClickPast from "../../../hooks/useClickPast";

const Modal = (props) => {
  const { close, children, hideDefaultClose, classes = '', closeByPastClick, withoutBG } = props;
  const modalRef = useClickPast(close, closeByPastClick? (e, ref)=>{
    if (!ref.current?.contains(e.target)) {
      close();
  }
  } : ()=>{})
  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  });


  return (
    <div className={`modal ${withoutBG ? "" : "modal_task"} is-opened ${classes}`}>
      <div className="modal__shadow" ></div>
      <div className="modal__content">
        <div className="modal__inner" ref={modalRef}>
          {!hideDefaultClose && <div className="modal__close-outer" onClick={close}></div>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
