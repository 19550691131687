const Iframe = ({ src, title }) => {
  if (!src) return null
  return (
    <>
      <h3 className="h3-l sf__h3">{title}</h3>
      <iframe
        className="sf-iframe"
        frameBorder="0"
        src={src}
        allow="true"
        loading="lazy"
        allowFullScreen={true}></iframe>
    </>
  );
};

export default Iframe;
