import React, { memo } from 'react';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import PriceFilter from "../../FilterRealty/PriceFilter";
import useMedia from "../../../hooks/useMedia";

const PrimaryTopFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  const isLaptop = useMedia('isLaptop');
  const isSmallLaptop = useMedia('isSmallLaptop');

  return <>
    <PriceFilter
      autoPosition
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена, от и до",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      clearFilterOnly={() => clearByIdent("prices")}
      clearExcludeOnly={() => clearByIdent("prices", "exclude")}
      wrapperClass={wrapperClass}
    />
    {
      !isSmallLaptop &&
      <SelectFilter
        {...createSelectProps("rooms")}
        data={{
          title: "К-во комнат",
          identity: "rooms",
        }}
        isAutocomplete={false}
        wrapperClass={wrapperClass}
      />
    }
    {
      !isLaptop &&
      <EndingsFilter
        autoPosition
        ident={"endings"}
        filter={filter.endings ?? { min: "", max: "" }}
        items={filterData.endings?.items ?? []}
        data={{
          title: "Срок сдачи",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
        }}
        onChange={v => setFilterAndExclude(v, "endings")}
        secondData={{
          title: "Сданные дома",
          value: filter.isOnlyHanded ?? "N"
        }}
        secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
        clearFilterOnly={() => clearByIdent("endings")}
        clearExcludeOnly={() => clearByIdent("endings", "exclude")}
        wrapperClass={wrapperClass}
      />
    }

  </>
});

export default PrimaryTopFilters;
