import React, {useRef} from 'react';
import './style.scss'
import panschoolTopImg from '../../assets/images/panschool/panschool-top.png'
import panschoolSec3Lady from '../../assets/images/panschool/panschool-sec3-lady.jpg'
import panschoolBottomLine from '../../assets/images/panschool/panschoolbottomline.png'
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {sendEducationRequest} from "../../actions/sendEducationRequest";
import {errorToast, infoToast} from "../../components/toasts";
import ym from "react-yandex-metrika";
import {useSelector} from "react-redux";
import useMedia from "../../hooks/useMedia";


const Panschool = () => {

  const formRef = useRef(null);
  const userInfo = useSelector(state => state.user?.info);
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (data) => {
    data.lastName = userInfo.lastName || '';
    data.course = "Консультация";
    sendEducationRequest(data).then(
      data => {
        infoToast(<div>{data.message}</div>)
        ym("reachGoal", "sendEducationRequest");
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const handleScroll = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const is950 = useMedia('is950');
  const isSmallLaptop = useMedia('isSmallLaptop');


  return (
    <div className="wrapper wrapper-lands panschool-new">
      <div className="panschool-sec1">
        <img className="panschool-sec1__toplogo" src={panschoolTopImg} alt=""/>
        <p className="panschool-sec1__panschool">PAN SCHOOL</p>
        <p className="panschool-sec1__leveltext">Уровень: начинающий, средний</p>
        <h1 className="panschool-sec1__maintitle">Прогрессивные<br/> продажи<br/> недвижимости </h1>
        <p className="panschool-sec1__secondtitle">
          Обучаем профессии специалиста по недвижимости <br/>
          и растим ваш самый ценный актив — компетенции.
        </p>
        {!is950 &&
          <div className="panschool-sec1__btns">
            <button className="btn btn_primary h6" onClick={handleScroll}>Оставить заявку</button>
            <button className="btn btn_secondary h6"
              onClick={() => window.location.href = 'https://view.officeapps.live.com/op/view.aspx?src=https://panpartner.ru/upload/План_обучения_теория_практика2.pptx'}
            >Программа обучения</button>
          </div>
        }

      </div>

      {is950 &&
        <div className="panschool-sec1__btns">
          <button className="btn btn_primary h6" onClick={handleScroll}>Оставить заявку</button>
          <button className="btn btn_secondary h6"
            onClick={() => window.location.href = 'https://view.officeapps.live.com/op/view.aspx?src=https://panpartner.ru/upload/План_обучения_теория_практика2.pptx'}
          >Программа обучения</button>
        </div>
      }

      <div className="panschool-sec2">
        <div className="panschool-sec2__item">
          <p className="panschool-sec2__title">Передаем опыт практикующих специалистов </p>
          <p className="panschool-sec2__text">Адаптируем программу каждого потока<br/>
            под изменения рынка недвижимости</p>
        </div>
        <div className="panschool-sec2__item">
          <p className="panschool-sec2__title">Понимаем, что результаты нужны сразу </p>
          <p className="panschool-sec2__text">Рабочие инструменты продаж<br/> и практика — принцип нашего обучения</p>
        </div>
        <div className="panschool-sec2__item">
          <p className="panschool-sec2__title">Удобный формат обучения</p>
          <p className="panschool-sec2__text">2 недели — офлайн-занятия, экскурсии<br/>
            1 неделя теории, 1 неделя практики</p>
        </div>
      </div>

      <div className="panschool-sec3">
        <div className="panschool-sec3__left">
          <div className="panschool-sec3__lady">
            <img src={panschoolSec3Lady} alt=""/>
          </div>
          <p className="panschool-sec3__title">Грамотный специалист
            по недвижимости все время
            повышает компетенции</p>
          <p className="panschool-sec3__text">Условия продаж меняются каждый день,
            поэтому эксперты в недвижимости
            не останавливаются на достигнутом: исследуют рынок, ищут новые каналы продвижения и развивают техники продаж.</p>
          <div>
            <div className="panschool-sec3__btn">
              <button className="btn btn_secondary h6" onClick={handleScroll}>Оставить заявку</button>
            </div>
          </div>
        </div>
        <div className="panschool-sec3__right">
          <p className="panschool-sec3__right-title">
            Обучаем востребованным
            навыкам в профессии
          </p>
          <ul className="panschool-sec3__list">
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>

              Что нужно знать о работе с застройщиками, подрядчиками на строительство</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Как продавать и что продавать</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Как работать на рынке новостроек,
              апартаментов, уступок, загородной недвижимости</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Как грамотно подобрать ипотеку/
              рассрочку для клиента</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Что делать, чтобы не срывались клиенты </li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Как работать с возражениями
              и закрыть клиента на договор</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Как повышать чек на свои услуги</li>
          </ul>
        </div>
      </div>

      <div className="panschool-sec4">
        <p className="panschool-sec1__maintitle">Кому подойдет курс </p>
        <div className="panschool-sec4__list">
          <li>
            <p className="panschool-sec4__item-number">1</p>
            <p className="panschool-sec4__item-title">Начинающим
              специалистам</p>
            <p className="panschool-sec4__item-text">Изучите рынок новостроек, уступок, загородной недвижимости, получите инструменты для переговоров и продаж, научитесь использовать техники продаж для закрытия сделок</p>
          </li>
          <li>
            <p className="panschool-sec4__item-number">2</p>
            <p className="panschool-sec4__item-title">Практикующим агентам
              в недвижимости</p>
            <p className="panschool-sec4__item-text">Усовершенствуете технику переговоров, узнаете новые подходы к выбору объектов
              для продажи, начнете последовательно увеличивать средний чек сделок за счет повышения экспертизы</p>
          </li>
          <li>
            <p className="panschool-sec4__item-number">3</p>
            <p className="panschool-sec4__item-title">Продавцам вторичной
              недвижимости</p>
            <p className="panschool-sec4__item-text">Изучите рынок новостроек, уступок, загородной недвижимости, получите инструменты для переговоров и продаж, научитесь использовать техники продаж для закрытия сделок</p>
          </li>

          {isSmallLaptop &&
            <li style={{backgroundColor: '#EF372E', display: 'flex', alignItems: 'center'}}>
              <p className="panschool-sec4__item-title" style={{color: 'white'}}>
                Чтобы оставаться в рынке, быстро адаптироваться в новых условиях, сохранять экспертные позиции, получать все преференции профессии — пройдите курс прогрессивных продаж.
              </p>
            </li>
          }
        </div>

        <div className="panschool-sec4__bottom">

          {!isSmallLaptop &&
            <div className="panschool-sec4__bottom-left">
              Чтобы оставаться в рынке, быстро адаптироваться в новых условиях, сохранять экспертные позиции, получать все преференции профессии — пройдите курс прогрессивных продаж.
            </div>
          }

          <div className="panschool-sec4__bottom-right">
            <button className="btn btn_primary h6" onClick={handleScroll}>Оставить заявку</button>
          </div>
        </div>

      </div>


      <div className="panschool-sec5">
        {is950 &&
          <div className="panschool-sec5__item">
            <p className="panschool-sec5__bigtitle" style={{textAlign: 'center', fontSize: 20, lineHeight: '24px', fontWeight: 700}}>Помогаем развивать важные компетенции специалиста
              по недвижимости </p>
          </div>
        }
        <div className="panschool-sec5__row">
          <div className="panschool-sec5__item">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
            <p className="panschool-sec5__title">Этично <br/>продавать</p>
            <p className="panschool-sec5__text">Расскажем, как устроена «кухня» застройщиков в разных сферах недвижимости, как работать, не нарушая регламенты, поэтапно повышая размер комиссии</p>
          </div>
          <div className="panschool-sec5__item">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
            <p className="panschool-sec5__title">Разбираться в проектах  и девелоперах</p>
            <p className="panschool-sec5__text">Начнёте грамотно выбирать новостройки, апартаменты  и загородные проекты точно
              под запрос, сравнивать, анализировать и аргументированно презентовать объекты клиентам</p>
          </div>
          {is950 &&
            <div className="panschool-sec5__item">
              <div className="panschool-sec5__item">
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
                  <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
                </svg>
                <p className="panschool-sec5__title">Работать с ипотекой</p>
                <p className="panschool-sec5__text">Научитесь подбирать объекты с учетом ипотечных программ и ставок.</p>
              </div>
            </div>
          }
          {!is950 &&
            <div className="panschool-sec5__item">
              <p className="panschool-sec5__bigtitle">Помогаем развивать важные компетенции специалиста
                по недвижимости </p>
            </div>
          }

        </div>
        <div className="panschool-sec5__row">
          {!is950 &&
            <div className="panschool-sec5__item">
              <div className="panschool-sec5__item">
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
                  <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
                </svg>
                <p className="panschool-sec5__title">Работать с ипотекой</p>
                <p className="panschool-sec5__text">Научитесь подбирать объекты с учетом ипотечных программ и ставок.</p>
              </div>
            </div>
          }
          <div className="panschool-sec5__item">
            <div className="panschool-sec5__item">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
            <p className="panschool-sec5__title">Выстраивать доверительные отношения с клиентами</p>
            <p className="panschool-sec5__text">Поймете, чего покупатели ждут от продавцов, освоите техники переговоров, с помощью которых можно легко перемещать клиента по воронке — от первого звонка до встречи и сделки</p>
          </div>
          </div>
          <div className="panschool-sec5__item">
            <div className="panschool-sec5__item">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.9686" cy="21.9686" r="21.9686" fill="#E3F0F7"/>
              <path d="M13.3943 21.7102L19.9985 28.2534L32.648 15.6836" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
            <p className="panschool-sec5__title">Использовать современные инструменты продаж</p>
            <p className="panschool-sec5__text">Покажем, какие сервисы помогают  быстрее решать рабочие вопросы,  экономить время, сократить расходы
              на сделке — легче и грамотнее
              закрывать клиента на договор</p>
          </div>
          </div>
        </div>
      </div>

      <div className="panschool-sec6">
        <p className="panschool-sec1__maintitle">Скачайте план обучения<br/>
          или получите консультацию</p>
        <div className="panschool-sec6__bottomline">
          <img src={panschoolBottomLine} alt=""/>
        </div>

        <div className="panschool-sec6__form">
          <form action="#" ref={formRef} className="sor__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="sor__form-box">
              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    // defaultValue={userInfo.name}
                    className={
                      "input__control"
                    }
                    placeholder="Ваше имя"
                    ref={register({
                      required: "Введите имя",
                    })}
                  />
                  {/*{errors.name && (*/}
                  {/*  <span className="input__error-label">*/}
                  {/*          {errors.name.name}*/}
                  {/*        </span>*/}
                  {/*)}*/}
                </label>
              </div>

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <InputMask
                    id="modal-reservation-phone"
                    className={
                      "input__control"
                    }
                    mask="+7 (999) 999-99-99"
                    autoComplete="off"
                    maskChar=" "
                    name="phone"
                    placeholder="Ваш телефон"
                    // defaultValue={userInfo.phone}
                    inputRef={register({
                      required: "Введите телефон",
                    })}
                  />
                  {/*{errors.name && (*/}
                  {/*  <span className="input__error-label">*/}
                  {/*          {errors.name.phone}*/}
                  {/*        </span>*/}
                  {/*)}*/}
                </label>
              </div>


              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-message"
                >
                  <span className="input__label">Комментарий</span>
                  <input
                    id="modal-reservation-message"
                    name="comment"
                    className={
                      "input__control"
                    }
                    placeholder="Текст комментария"
                    ref={register}
                  />
                  {/*{errors.name && (*/}
                  {/*  <span className="input__error-label">*/}
                  {/*          {errors.name.message}*/}
                  {/*        </span>*/}
                  {/*)}*/}
                </label>
              </div>
              <div className="sor__form-item">
                <button type="submit" className="button button_view_default btn_full">Оставить заявку</button>
              </div>

            </div>

            <div className="sor__form-footer">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    'checkbox__box' +
                    (errors.agreement ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required: true,
                    })}
                    defaultChecked
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"

                >
                  Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Panschool
