import includes from "lodash/includes";
import sortBy from "lodash/sortBy";
import { createSelector } from "reselect";
import request from "../api";
import { actionFactory } from "./actionUtils";

const component = "panpartner:agents.ajax"

const agentsSelector = (state) => state.agents.data;
const filterSelector = (state) => state.agents.filter;

const isFilterSet = createSelector([filterSelector], (filter) => (
  filter.agents.length > 0 ||
  filter.rights.length > 0 ||
  filter.active.length > 0
));
// TO DO отрефакторить за Олегом и вообще убрать селекторы нахуй отсюда
const filteredAgents = createSelector(
  [agentsSelector, filterSelector],
  (agents, filter) => {
    let result = agents;

    if (filter.search) {
      result = result.filter(({ search }) =>
        search.some((elem) => elem.includes(filter.search.toLowerCase())),
      );
    }

    if (filter.agents.length) {
      result = result.filter((item) => includes(filter.agents, `${item.id}`));
    }
    if (filter.rights.length) {
      result = result.filter((item) => includes(filter.rights, item.rightId));
    }

    if (filter.active.length) {
      result = result.filter((item) =>
        includes(filter.active, item.active ? "1" : "0"),
      );
    }

    return result;
  },
);

const requestAgents = actionFactory("AGENTS_REQUEST")

const successAgents = actionFactory("AGENTS_SUCCESS")

const setActiveAgents = actionFactory("AGENTS_SET_ACTIVE")

const removeAgents = actionFactory("AGENTS_REMOVE")

const clearAgents = actionFactory("AGENTS_RESET")

const failureAgents = actionFactory("AGENTS_FAILURE")

const setFilterAgents = actionFactory("AGENTS_SET_FILTER")

const clearFilterAgents = actionFactory("AGENTS_CLEAR_FILTER")

// TO DO и вот это тоже
const fetchAgents = () =>
  dispatch => {
    dispatch(requestAgents());
    return request(component, "getAgents", { city: "spb" })
      .then(
        data => {
          let { filter, agents } = data;
          filter.agents = sortBy(filter.agents, "title");
          filter.active = sortBy(filter.active, "title");
          filter.rights = sortBy(filter.rights, "title");

          agents = agents.map((item) => {
            let title = `${item.name}${item.lastName ? ` ${item.lastName}` : ``
              }`;

            let search = [
              item.name,
              item.lastName,
              item.patronymic,
              item.phone,
              item.email,
              title,
            ];
            search = search.map((text) => text? text.toLowerCase(): "");

            return { ...item, search: search };
          });

          dispatch(
            successAgents({
              data: agents,
              filterData: filter,
            }),
          );
        },
        error => {
          dispatch(failureAgents());
          return Promise.reject(error);
        },
      );
  };

const handleActiveAgents = (id, active) =>
  dispatch => request(component, "setActive", {
    city: "spb",
    id: id,
    active: active ? "Y" : "N",
  })
    .then(
      data => {
        dispatch(setActiveAgents({ data: { id, active } }));
        return Promise.resolve(data);
      },
    );

const removeAgent = (id, active) =>
  dispatch => request(component, "removeAgent", { city: "spb", id })
    .then(
      data => {
        dispatch(removeAgents({ id }));
        return Promise.resolve(data);
      }
    );

const checkAgent = email => request(component, "checkEmail", { city: "spb", email })
  .then((data) => data.exist === "N");

const editAgents = form => request(component, "editAgent", form)

const resetPassword = params => request(component, "resetAgentPassword", params)

const getAgent = id => request(component, "getAgent", { city: "spb", id })

const getAccessRights = () => request(component, "getAccessRights")

export {
  checkAgent,
  clearAgents,
  clearFilterAgents,
  editAgents,
  fetchAgents,
  filteredAgents,
  getAccessRights,
  getAgent,
  handleActiveAgents,
  isFilterSet,
  removeAgent,
  resetPassword,
  setFilterAgents
};

