import _floor from 'lodash/floor';
import minBy from 'lodash/minBy';
import React from 'react';
import { useParams } from "react-router-dom";
import { getUrl } from '../../api';
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import usePermission from '../../hooks/usePermission';
import { evaulateEndings, formatMoney, getPricePerMeter, getSuffix } from '../../utils';
const TopItem = (props) => {
  const {
    id,
    commission,
    clickSideEffect = () => { },
    address,
    endings,
    name,
    countSuburbans,
    suburbansInfo,
    photos = [],
    clientMode,
    district
  } = props;
  const getCostPerMeter = (item) => {
    return getPricePerMeter(item.minPrice, item.minSquareHome + item.minSquareParcel)
  }
  const { city = "spb" } = useParams();
  const minByCostPerMeter = minBy(suburbansInfo.suburbansByType, getCostPerMeter)

  const clickHandler = () => {
    clickSideEffect()
    window.open(`/suburban/${city}/${id}?fromTop`);
  }
  const showMotivation = usePermission("motivation")
  const photo = photos?.find?.(item=>item.src)?.src
  return (
    <div className="slider__item" onClick={clickHandler}>
      <div className="flats__card flats__card_compact">
        <div className="flats__card-image">
          <img
            src={getUrl(photo)}
            alt={``}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = houseEmpty;
            }}
          />
          <div className="flats__card-top">
            <div className="flats__card-status"></div>
          </div>
        </div>
        <div className="flats__card-info">
          {/* {!isAgent && !clientMode && ( */}
          {showMotivation && !! commission && (
            <div
              className="card__pill"
              style={{
                width: 'fit-content',
                marginBottom: '20px',
              }}
            >
              Комиссия: {commission}
            </div>
          )}
          <div className="flats__card-price top__title"><span>{name}</span></div>
          <div className="card__company">{countSuburbans} объект{getSuffix(countSuburbans)}<br/> от {formatMoney(_floor(getCostPerMeter(minByCostPerMeter), -3))}  руб./м²</div>
          {/*<div className="card__address card__address_mt_5px">{address}</div>*/}
          <div className="card__address card__address_mt_5px">{district.title}</div>
          <div className="flats__card-price suburbans"><span>от {formatMoney(minByCostPerMeter.minPrice)} р.</span></div>
          <div className="card__dates"> {(endings.from || endings.to) && (
            <div className="card__dates" style={{display: "flex"}}>
              {evaulateEndings(endings, true).map((ending, i)=> <div key={`ending-${i}`}>{ending}&nbsp;</div>)}
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopItem
