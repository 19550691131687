import _keys from "lodash/keys";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { forgot } from "../../actions/user";
import { validateMail } from '../../utils';

const Forgot = () => {

  const { handleSubmit, register, errors } = useForm();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    let form = new FormData();
    let keys = _keys(data);

    keys.forEach((item) => {
      let identity = item === "email" ? "login" : item;
      form.append(`${identity}`, data[item]);
    });

    forgot(form).then(
      (data) => {
        setSuccess(true);
      },
      (error) => {
        setError(error);
      }
    );
  };

  return (
    <>
      <h3 className="modal__title">Восстановление пароля</h3>
      {success && (
        <p className="modal__hint">
          Контрольная строка, а также ваши регистрационные данные были высланы
          на email. Пожалуйста, дождитесь письма, так как контрольная строка
          изменяется при каждом запросе.
        </p>
      )}
      {!success && (
        <>
          <p className="modal__hint">
            После заполнения формы мы отправим специальную ссылку на указанный
            электронный адрес, перейдя по которой вы сможете задать новый пароль
          </p>
          {!!error &&  (
            <div className="form__error-text">
              {error}
            </div>
          )}

          <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-form__wrapper">
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-pass-recover-name"
                  >
                    <input
                      name="email"
                      id="modal-pass-recover-name"
                      placeholder="Ваш e-mail"
                      className={
                        "input__control" + (errors.email ? " input__error" : "")
                      }
                      ref={register({
                        required: "Введите e-mail",
                        pattern: {
                          value: validateMail,
                          message: "Неправильный формат e-mail",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="input__error-label">
                        {errors.email.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-form__submit">
              <button className="button button_view_default">Отправить</button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Forgot;
