import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.min.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import useMedia from "../../hooks/useMedia";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import CashbackForm from "../forms/CashbackForm";
import InstallmentForm from "../forms/InstallmentForm";
import { successToast } from "../toasts";
import { formatNumbers, priceFormat } from "./calcUtils";
import './index.scss';



const Calculator = (props) => {
  const [tab, setTab] = useState("mortgage");

  const isMobile = useMedia("isMobile");

  const {
    id,
    price,
    period,
    prepay,
    percent,
    city,
    type,
    isPublic,
    showInstallment = true,
    submitCashback,
    innerCbRef
  } = props;

  const [calc, setCalc] = useState({
    price: price,
    period: period,
    prepay: prepay,
    percent: percent,
    total: 0,
  });


  const [total, setTotal] = useState();

  const prepayRef = useRef(null);

  const getTotal = useMemo(() => total, [total]);

  const [maxPrepay, setMaxPrepay] = useState(0);

  const changeTotal = (tmpCalc) => {
    let { price, prepay } = tmpCalc;
    let payment = price - prepay;
    // let m = percent / 100 / 12;
    // let koef =
    //   (m * Math.pow(1 + m, period * 12)) / (Math.pow(1 + m, period * 12) - 1);
    let result = (payment / 100 * 0.3).toFixed();
    setTotal(result > 0 ? result : 0);
    handleChange("total", result);
  };

  const handleChange = (name, value) => {
    let tmpCalc = calc;
    tmpCalc[name] = value;
    setCalc(tmpCalc);
    if (name !== "total") {
      changeTotal(tmpCalc);
    }
    if (name === "price") {
      setMaxPrepay(value);
    }
  };

  useEffect(() => {
    initInputSliders();
  }, []);

  /*
  useEffect(() => {
    if (prepayRef !== undefined) {
      prepayRef.current.noUiSlider.updateOptions({
        range: {
          min: 100000,
          max: maxPrepay,
        },

      });
    }
  }, [maxPrepay]);*/

  const initInputSliders = () => {
    const slidersContainers = [...document.querySelectorAll(".slider-input")];

    slidersContainers.forEach((container) => {
      const valueInput = container.querySelector(".slider-input__control");
      const slider = container.querySelector(".slider__input");
      const postfix = container.dataset.postfix;
      const isPrice = container.dataset.isPrice !== undefined;
      const start = Number(container.dataset.start);
      const step = Number(container.dataset.step);
      let min = Number(container.dataset.min);
      let max = Number(container.dataset.max);
      const name = valueInput.name;
      if(min === max){
        min += 1
      }
      noUiSlider.create(slider, {
        start,
        connect: [true, false],
        range: {
          min,
          max,
        },
        step,
        format: {
          to: function (value) {
            let result = step % 1 > 0 ? value.toFixed(1) : parseInt(value);
            return formatNumbers(result) + postfix;
          },
          from: function (value) {
            if (postfix)
              return Number(value.replace(postfix, "").replace(/\ /g, ""));
            let n = value.replace(/\,/g, ".");
            return Number(n.replace(/\ /g, ""));
          },
        },
      });

      slider.noUiSlider.on("update", function (values, handle) {
        valueInput.value = values[handle];
        handleChange(
          name,
          Number(values[handle].replace(postfix, "").replace(/\ /g, ""))
        );
      });

      valueInput.addEventListener("change", function () {
        slider.noUiSlider.set(this.value);
      });

      valueInput.addEventListener("input", function () {
        // Сохраняем позицию курсора
        let pos = this.selectionStart;
        const prevLength = this.value.length;

        this.value = formatNumbers(this.value);
        const newLength = this.value.length;

        // Возвращаем курсор к своей настоящей позиции
        pos = pos + (newLength - prevLength);
        this.setSelectionRange(pos, pos);
      });
    });

    const allPriceSliders = [...document.querySelectorAll(".price-input")];
    allPriceSliders.forEach((slider) => {
      let sliderName = slider.name;
      if (sliderName !== "price") {
        slider.addEventListener("focus", function () {
          this.value = this.value.replace(" р.", "");
        });

        slider.addEventListener("blur", function () {
          if (!this.value) return;
          this.value = this.value.replace(" р.", "") + " р.";
        });
      }
    });
  };


  const [openCashbackForm, closeCashbackForm] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={closeCashbackForm} classes="modal_task">
        <CashbackForm
          handleSend={(data) => submitCashback({...calc, name: data.name, phone: data.phone})}
          id={id}
          calc={calc}
          total={getTotal}
          city={city}
          type={type}
          closeModal={closeCashbackForm}
        />
      </Modal>
    )
  );

  const [openCashbackMobileForm, closeCashbackMobileForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={closeCashbackMobileForm} title="Рассчитать вознаграждение">
        <CashbackForm
          handleSend={(data) => submitCashback({...calc, name: data.name, phone: data.phone})}
          id={id}
          calc={calc}
          total={getTotal}
          city={city}
          type={type}
          closeModal={closeCashbackMobileForm}
        />
      </MobileModal>
    )
  );

  
  innerCbRef.current = isMobile ? openCashbackMobileForm : openCashbackForm;

  return (

    <div className="calculators light__calc calculators_cashback">
      <div className="calculators__container">

        <div className="calculator calculator_cashback">
          <div className="calculator__grid">
            <div className="calculator__form">
              <div
                className="calculator__item slider-input"
                data-start={8000000}
                data-min={0}
                data-max={price}
                data-step={100000}
                data-postfix=" р."
                data-is-price
              >
                <label htmlFor="mortgage-price">Стоимость {type ==="commercial"? "помещения" : "квартиры"}</label>
                <div className="calculator__item-wrapper">
                  <input
                    className="slider-input__control price-input"
                    type="text"
                    id="mortgage-price"
                    name="price"
                    readOnly
                  />
                </div>
                <div
                  className="slider__input"
                ></div>
              </div>

              <div
                className="calculator__item slider-input"
                data-start={2000000}
                data-min={0}
                data-max={price}
                data-step={20000}
                data-postfix=" р."
                data-is-price
              >
                <label htmlFor="mortgage-prepay">
                  Первоначальный взнос
                </label>
                <div className="calculator__item-wrapper">
                  <input
                    className="slider-input__control price-input"
                    type="text"
                    id="mortgage-prepay"
                    name="prepay"
                  />
                </div>
                <div className="slider__input" ref={prepayRef}></div>
              </div>

            </div>
            <div className="calculator__result calculator__result_no-padding">
              <div className="calculator__result-flex">
                <div className="calculator__result-block">
                  <div className="calculator__result-info">
                    Предварительное вознаграждение составит
                  </div>
                  <div className="calculator__result-value">
                    {priceFormat(total)}
                  </div>
                </div>

                <div
                  className="calculator__result-btn js-modal-btn"
                  data-id="modal-mortgage"
                >
                  <button
                    className="button button_view_default"
                    onClick={isMobile ? openCashbackMobileForm : openCashbackForm}
                  >
                    Оставить заявку
                  </button>
                </div>
              </div>


            </div>
          </div>

          <p className="calculator__sign">
            Окончательный размер вознаграждения может измениться в зависимости от выбранного банка, ипотечной программы, применения скидок и прочих условий
          </p>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
