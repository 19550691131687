import React from "react";
import { useMediaQuery } from 'beautiful-react-hooks';
import { media } from '../../utils';
import Popup from '../core/Popup';

const Pannels = (props) => {
  const smallScreen = useMediaQuery("(max-width: 1505px)");

  return (
      <div className="assignations-pannels">
        <div className="assignations-pannels__item">
          <img
            src={require('../../assets/images/assignments-01.svg')}
            alt=""
          />
          <p className="h5">Авансирование</p>

          <div className={`info${smallScreen ? ` info_align_right` : ``}`}>
            {media('isMobile') ?
              <Popup
                trigger={
                  <svg className="icon icon_info">
                    <use xlinkHref="#info" />
                  </svg>
                }
              >
                <p><b>ПАН авансирует партнеров!</b> <span role="img" aria-label="смайлик">💵</span></p>
                <p>
                  Проводите сделку с нами.
                </p>
                <p>
                  Партнёр может получить комиссионное вознаграждение уже на следующий день после проведения сделки при соблюдении следующих условий:
                </p>
                <p>
                  1. Документы по сделке отправлены на регистрацию в Росреестр.<br />
                  2. Покупатель произвёл денежные расчёты с продавцом и агентством (аванс, довнесение, безопасные расчёты)<br />
                  3. Наличие Гарантийного обязательства от компании ПАН на получение комиссии по конкретной сделке. *Получить денежные средства имеет право руководитель АН или сотрудник с доверенностью от руководителя.
                </p>
                <p>
                  <b>Авансирование партнеров</b> производится с ПН по ПТ с 10.00 до 18.00
                </p>
                <p>
                  <b>Руководитель отдела переуступок:</b><br />
                  Анастасия Коршунова <a href="tel:+79643359105" className="link">+7(964) 335-91-05</a>
                </p>
              </Popup> :
              <svg className="icon icon_info">
                <use xlinkHref="#info" />
              </svg>
            }
            {!media('isMobile') &&
              <div className="info__popup info__popup_lg">

                <p><b>ПАН авансирует партнеров!</b> <span role="img" aria-label="смайлик">💵</span></p>
                <p>
                  Проводите сделку с нами.
                </p>
                <p>
                  Партнёр может получить комиссионное вознаграждение уже на следующий день после проведения сделки при соблюдении следующих условий:
                </p>
                <p>
                  1. Документы по сделке отправлены на регистрацию в Росреестр.<br />
                  2. Покупатель произвёл денежные расчёты с продавцом и агентством (аванс, довнесение, безопасные расчёты)<br />
                  3. Наличие Гарантийного обязательства от компании ПАН на получение комиссии по конкретной сделке. *Получить денежные средства имеет право руководитель АН или сотрудник с доверенностью от руководителя.
                </p>
                <p>
                  <b>Авансирование партнеров</b> производится с ПН по ПТ с 10.00 до 18.00
                </p>
                <p>
                  <b>Руководитель отдела переуступок:</b><br />
                  Анастасия Коршунова <a href="tel:+79643359105" className="link">+7(964) 335-91-05</a>
                </p>

              </div>
            }
          </div>

        </div>

        <div className="assignations-pannels__item">
          <img
            src={require('../../assets/images/assignments-02.svg')}
            alt=""
          />
          <p className="h5">Передача объекта</p>

          <div className="info">
            {media('isMobile') ?
              <Popup
                trigger={
                  <svg className="icon icon_info">
                    <use xlinkHref="#info" />
                  </svg>
                }
              >
                <p><b>Принимаем удар на себя!</b> <span role="img" aria-label="смайлик">🙂</span></p>
                <p>
                  Возможно продажа переуступки Вашего клиента - это непрофильная задача?<br />
                  Вы работаете по другим видам сделок в недвижимости?<br />
                  Вам не хватает времени на эту задачу?
                </p>
                <p>
                  <b>ПЕРЕдайте клиента в переПАН!</b>
                </p>
                <p>
                  Мы берём всю работу по продаже переуступки на себя и платим вам комиссию в размере 1% от стоимости объекта после его продажи.
                  Например: мы продали переуступку за 5 200 000 руб. В этом случает вы получаете 52 000 руб. на руки!
                </p>

                <p>
                  Для этого нужно:<br />
                  1. Передать клиента в отдел уступок ПАН.<br />
                  {/*Email: <a className="link" href="mailto:pereustupki@1-an.ru">pereustupki@1-an.ru</a><br />*/}
                  Тел.: <a className="link" href="tel:88122428069">8 (812) 242-80-69</a><br />
                  2. Получить гарантийное обязательство о выплате комиссии.<br />
                  3. Ожидать звонка своего довольного клиента и ехать за комиссией в ПАН!
                </p>
              </Popup> :
              <svg className="icon icon_info">
                <use xlinkHref="#info" />
              </svg>
            }
            {!media('isMobile') &&
              <div className="info__popup info__popup_lg">
                <p><b>Принимаем удар на себя!</b> <span role="img" aria-label="смайлик">🙂</span></p>
                <p>
                  Возможно продажа переуступки Вашего клиента - это непрофильная задача?<br />
                  Вы работаете по другим видам сделок в недвижимости?<br />
                  Вам не хватает времени на эту задачу?
                </p>
                <p>
                  <b>ПЕРЕдайте клиента в переПАН!</b>
                </p>
                <p>
                  Мы берём всю работу по продаже переуступки на себя и платим вам комиссию в размере 1% от стоимости объекта после его продажи.
                  Например: мы продали переуступку за 5 200 000 руб. В этом случает вы получаете 52 000 руб. на руки!
                </p>

                <p>
                  Для этого нужно:<br />
                  1. Передать клиента в отдел уступок ПАН.<br />
                  {/*Email: <a className="link" href="mailto:pereustupki@1-an.ru">pereustupki@1-an.ru</a><br />*/}
                  Тел.: <a className="link" href="tel:88122428069">8 (812) 242-80-69</a><br />
                  2. Получить гарантийное обязательство о выплате комиссии.<br />
                  3. Ожидать звонка своего довольного клиента и ехать за комиссией в ПАН!
                </p>
              </div>
            }
          </div>
        </div>
      </div>
  )
};

export default Pannels;
