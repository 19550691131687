import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { ru as locale } from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useDropdown from '../../../hooks/useDropdown';
require('moment/locale/ru');
moment.locale('ru');

const DatepickerDropdown = (props) => {
  const { onChange, value, label, identity, isMobile, clearFilters, keepEndDateOnFirstSelection, crmIntervalFilter, motivationIntervalFilter} = props;

  const [state, setState] = useState([
    {
      ...value,
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setState({
      ...value,
      key: 'selection',
    });
  }, []);

  const [node, open, show, hide] = useDropdown(false);

  const handleChange = (dates) => {
    onChange({ startDate: dates.startDate, endDate: dates.endDate });
  };

  return (
    <div className={crmIntervalFilter ? `form__item custom-select` : motivationIntervalFilter ? `form__item sf__filter-item` : `form__item`}>
      <div
        ref={node}
        className={'dropdown form__drop' + (open ? ' is-showing' : '')}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">
            {label ? label : 'Дата добавления'}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        <div className="dropdown__block">
          <div className="dropdown__inner">
            <div className={`form__drop-content ${isMobile ? '' : `centered`} no-max-height`}>
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{label ? label : 'Дата добавления'}</h3>
                </div>
              }
              <DateRange
                editableDateInputs={true}
                onChange={(item) => {
                  handleChange(item.selection);
                }}
                keepEndDateOnFirstSelection={keepEndDateOnFirstSelection}
                ranges={[{ ...{endDate:  value.endDate? moment(value.endDate).toDate(): null, startDate: value.startDate? moment(value.startDate).toDate(): null}, key: 'selection' }]}
                rangeColors={['#2c2e3e']}
                locale={locale}
                startDatePlaceholder={'Дата от'}
                endDatePlaceholder={'Дата до'}
              />
              {(crmIntervalFilter !== undefined || motivationIntervalFilter !== undefined) &&
                <div className="mobile-filters__submit">
                  <button
                    className="button button__mobile-filters"
                    style={{flexBasis: '100%', width: '100%', marginRight: '0px'}}
                    onClick={(e) => {
                      clearFilters(e)
                      hide()}
                    }
                  >
                    За все время
                  </button>
                </div>
              }
              {isMobile &&
                <div className="mobile-filters__submit">
                  <button
                    className="button button__mobile-filters"
                    onClick={(e) => {
                      clearFilters(e)
                      hide()}
                    }
                  >
                    Очистить
                  </button>
                  <button
                    className="button button__mobile-filters"
                    onClick={(e) => {
                      e.preventDefault()
                      hide()}
                    }
                  >
                    Применить
                  </button>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatepickerDropdown;
