const initState = 9;

const countView = (state = initState, action) => {
  switch (action.type) {
    case "SET_COUNT_VIEW":
      return action.countView;
    default:
      return state;
  }
};

export default countView;
