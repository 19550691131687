import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getContestResults, getContests, isContestParticipant } from '../../actions/contest';
import Table from '../../components/core/Table';
import Preloader from '../../components/Preloader';
import './index.scss';
const Items = (props) => {
  const { items } = props;

  let columns = [
    {
      title: 'Место',
      dataIndex: 'position',
      key: 'position',
      width: 250,
      render: (position) => {
        return <span className="nowrap" style={{
          fontWeight: 700,
          display: 'flex',
          justifyContent: 'center',
        }}>{position}</span>
      },
    },
    {
      title: 'Участник',
      dataIndex: 'agent',
      key: 'agent',
      width: 250,
      render: (agent) => {
        return <span className="nowrap">{`${agent.name} ${agent.lastName[0]}.`}</span>;
      },
    },
    {
      title: 'ID участника',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: (id) => {
        return <span className="nowrap">{id}</span>;
      },
    },
    {
      title: 'Кол-во баллов',
      dataIndex: 'score',
      key: 'score',
      width: 250,
      render: (score, column) => {
        return <span className="nowrap" >{<ScoreDif score={score} dif={column.difFPosition} />}</span>;
      },
    },

  ];

  return (
    <div className="orders">
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={columns}
        dataSource={items}
        pagination={false}
        locale={{ emptyText: 'Ничего не найдено' }}
        customPagination={false}
      />
    </div>
  );
};
const ScoreDif = props => {
  const { dif = 0, score, count, hideScore } = props
  if (dif === 0) {
    return !hideScore ? <div className="ten__score-change">
      {!!score?.toString() && <span className="ten__score-count">{score}</span>}
      <span className="ten__score-icon" style={{ fontSize: 20, backgroundColor: "unset" }}>
        —
      </span>
      {count && <span>{count}</span>}
    </div> : ""
  }
  return dif > 0 ? <Plus score={score} count={count ? "+" + count : null} hideScore={hideScore} /> : <Minus {...props} />
}
const Minus = ({ count, score, hideScore }) => {
  return (<div className="ten__score-change minus" style={{ margin: "none" }}>
    {!hideScore && score?.toString() && <span className="ten__score-count">{score}</span>}
    <span className="ten__score-icon">
      <svg className="icon icon_arrow-right">
        <use xlinkHref="#arrow-right-clean"></use>
      </svg>
    </span>
    {count && <span>{count}</span>}
  </div>)
}
const Plus = ({ count, score, hideScore }) => {
  return (<div className="ten__score-change plus" style={{ margin: "none" }}>
    {!hideScore && score?.toString() && <span className="ten__score-count">{score}</span>}
    <span className="ten__score-icon">
      <svg className="icon icon_arrow-right">
        <use xlinkHref="#arrow-right-clean"></use>
      </svg>
    </span>
    {count && <span>{count}</span>}
  </div>)
}
const Rating = (props) => {

  const { user = {}, userId } = props;
  const [contest, setContest] = useState({});
  const [isFetching, setFetching] = useState(true)
  const [contestResults, setContestResults] = useState([]);
  const [agentResult, setAgentResult] = useState({})
  useEffect(() => {
    getContests().then(resp => {
      setFetching(true);
      setContest(resp.contests[0])
      return resp.contests[0]
    }).then(contest => {
      getContestResults(contest.id).then(resp => {
        setContestResults(resp.results)
        const agentRes = resp.results.find((result) => result?.agent?.id === userId)
        setAgentResult(agentRes ? agentRes : {})
      })
      isContestParticipant(contest.id).then(resp => {
        setContest(prev => ({ ...prev, isContestParticipant: resp.isContestParticipant }))
        setFetching(false);
      })
    })
  }, [])
  if (isFetching) {
    return <Preloader loading={true} />
  }
  if (!contest.isContestParticipant) {
    return <TenRatingPlug />
  }
  return (
    <section className="ten section section_view_white">
      <div className="wrapper">
        <div className="clients__header">
          <div className="clients__title ten__rating-title">
            <div className="h1">Ваш рейтинг в гонках</div>
            <span className="ten-years-label">
              10 лет
            </span>
          </div>
        </div>

        <div className="ten__score">
          <table>
            <tr className="big-text">
              <td className="ten__score-key">Ваше место в рейтинге:</td>
              <td className="ten__score-value"><b>{agentResult.position}</b></td>
              <ScoreDif score={agentResult.score} dif={agentResult.difFPosition} hideScore={true} count={agentResult.difFPosition} />
            </tr>
            <tr className="big-text">
              <td className="ten__score-key">Баллов накоплено:</td>
              <td className="ten__score-value"><b>{agentResult.score}</b></td>
              <td></td>
            </tr>
          </table>
        </div>
        <div
          className="ten__table"
        >
          <Items items={contestResults} />
        </div>

      </div>

    </section>
  );
};

const TenRatingPlug = (props) => {

  return (
    <section className="ten section section_view_white">
      <div className="wrapper">
        <div className="ten__placeholder">
          <div className="clients__header">
            <div className="clients__title">
              <div className="h1">
                <span>
                  Вы не участвуете в конкурсе
                </span>

                <span className="ten-years-label ten-years-label_placeholder">
                  ПАН 10 лет
                </span>
              </div>
            </div>
          </div>
          {/* <p className="big-text">А пока принимай участие в розыгрыше!</p> */}

          <img
            className="ten__placeholder-img"
            src={require(`../../assets/images/ten/placeholder.svg`).default}
            alt=""
          />
          {
            // <Link to="/ten-years" className="button button_view_default">
            //   Участвовать
            // </Link>
          }

        </div>

      </div>
    </section>
  );
};
const Temporary = (props) => {
  const [contest, setContest] = useState({});
  const [isFetching, setFetching] = useState(true)
  useEffect(() => {
    getContests().then(resp => {
      setFetching(true);
      setContest(resp.contests[0])
      return resp.contests[0]
    }).then(contest => {
      getContestResults(contest.id).then(resp => {
      })
      isContestParticipant(contest.id).then(resp => {
        setContest(prev => ({ ...prev, isContestParticipant: resp.isContestParticipant }))
        setFetching(false);
      })
    })
  }, [])
  if (isFetching) {
    return <Preloader loading={true} />
  }
  return (
    <section className="ten section section_view_white">
      <div className="wrapper">
        <div className="ten__placeholder">
          <div className="clients__header">
            <div className="clients__title">
              <div className="h1">
                <span>
                  Скоро здесь будет рейтинг участников
                </span>

                <span className="ten-years-label ten-years-label_placeholder">
                  ПАН 10 лет
                </span>
              </div>
            </div>
          </div>
          {!contest.isContestParticipant && <p className="big-text">А пока принимай участие в розыгрыше!</p>}

          <img
            className="ten__placeholder-img"
            src={require(`../../assets/images/ten/placeholder.svg`).default}
            alt=""
          />
          {!contest.isContestParticipant && <Link to="/ten-years" className="button button_view_default">
            Участвовать
          </Link>}

        </div>

      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  userId: state.user?.info?.id
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
