import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { showPresentation } from "../actions/presentation";
import FilterDisabled from "../components/Public/FilterDisabled";

const PresentationView = (props) => {
  const { location, history } = props;
  const [isError, setIsError] = useState(false)
  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let id = sp.get("id");
    let clientId = sp.get("clientId");
    showPresentation(clientId, id).then((data) => {
      history.push(data.filePath);
      window.location.reload()
    },
    () => setIsError(true));
  }, []);
  if(isError) return <FilterDisabled text="К сожалению, данная презентация больше недоступна" />
  return ""; 
};



export default withRouter(PresentationView);
