import request from '../api';
import { actionFactory } from './actionUtils';

const requestProfile = actionFactory("PROFILE_REQUEST");

const successProfile = actionFactory("PROFILE_SUCCESS");

export const clearProfile = actionFactory("PROFILE_RESET");

const failureProfile = actionFactory("PROFILE_FAILURE");

export const fetchProfile = () =>
  dispatch => {
    dispatch(requestProfile());
    return request('panpartner:profile.ajax', 'getProfile', { city: "spb" })
      .then(
        data => {
          dispatch(
            successProfile({
              profile: data,
            }),
          );
          return Promise.resolve(data);
        },
        error => {
          dispatch(failureProfile());
          return Promise.reject(error);
        },
      );
  };

export const saveProfile = form => request('panpartner:profile.ajax', 'editProfile', form);

export const changePassword = form => request('panpartner:profile.ajax', 'changePassword', { city: "spb", ...form });

export const getInviteCode = () => request('panpartner:system.auth.form', 'getInviteCode');

export const getInviter = inviteCode => request('panpartner:system.auth.form', 'getInviter', { inviteCode });

export const changePasswordPublic = form => request('panpartner:system.auth.form', 'changePassword', form);