import React from 'react';
import LidPage from '../components/Constructor/InnerPage/LidPage/lidPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../components/Constructor/index.scss';
import { getSubdomain } from '../utils/location';
import { fetchAgency } from '../actions/constructor';

const ConstructorLid = ({setFullScreenLoading, state}) => {

  return  <LidPage setFullScreenLoading={setFullScreenLoading} />
};


const mapStateToProps = (state) => ({
  state: state.agency,
});

const mapDispatchToProps = (dispatch) => {
  const subDomain = getSubdomain()
  return {
    fetch: (subdomain) => dispatch(fetchAgency(subdomain ? subdomain : subDomain)),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConstructorLid));