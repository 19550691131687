import React, { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import { getSelectPropsCreator } from '../utils';
import { SuburbanNavFilterIcon } from "./icons";
import EndingsFilter from "../../FilterRealty/EndingsFilter";
import RadioDropdown from "../../core/RadioDropdown";
import classNames from 'classnames';

const VillagesParcelfilter = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass,
    clearExcludeOnly,
    clearFilterOnly,
    hideTitle,
    disabledFilters
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    {!hideTitle && <div className="filter-title">
      <SuburbanNavFilterIcon />
      Об участке
    </div>}

    <SelectFilter
      {...createSelectProps("landStatus")}
      data={{
        title: "Статус земли",
        autocompleteTitle: "Введите срок сдачи",
        identity: "landStatus",
      }}
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      autoPosition
      wrapperClass={wrapperClass}
      filter={filter.squareParcel ?? { min: "", max: "" }}
      data={{
        title: 'S участка',
        minPlaceholder: 'S уч. от ',
        maxPlaceholder: 'S уч. до ',
        defaultValues: filterData.squareParcel?.range ?? null
      }}
      onChange={({ filter }) => handleFilter('squareParcel', filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareParcel"}
      items={filterData.squareParcel?.items}
      showPlaceholderOnLists
      debounced
      placeholderMes="сот."
    />

    <RadioDropdown
      autoPosition
      onChange={v => handleFilter('onlySelfBuild', v)}
      items={filterData.onlySelfBuild ?? []}
      wrapperClass={classNames({ [wrapperClass]: true, "disabled-filter-row": disabledFilters?.includes?.("onlySelfBuild") })}
      value={filter.onlySelfBuild}
      alternativeTitle={'Возможности строительства'}
      data={{
        title: 'Возможности строительства',
        identity: 'onlySelfBuild',
      }}
    />

  </>
});

export default VillagesParcelfilter;
