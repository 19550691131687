import _keys from 'lodash/keys';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import ym from "react-yandex-metrika";
import { fixApartment, getBuilders } from '../../actions/forms';
import { media } from '../../utils';
import Dropdown from '../core/Dropdown';
import DropdownMulti from '../core/DropdownMulti';
import MobileModal from '../core/MobileModal';
import SubmitButton from '../SubmitButton';
import { errorToast, infoToast } from '../toasts';
import FormAddClient from './FormAddClient';


const FixateClient = (props) => {
  const {
    clients,
    id,
    phone,
    email,
    name,
    handleSend,
    isPublic,
    savedFilterXmlId,
    client,
    isCommercial,
    getBuilders,
    builders = [],
    close,
    city
  } = props;

  const defaultValues = {
    name: isPublic ? `${client?.name} ${client?.lastName}` : name,
    clientId: isPublic ? client?.id : clients.length > 0 ? clients[0].id : 0,
    phone: isPublic ? client?.phone?.[0] : phone,
    email: isPublic ? client?.email?.[0] : email,
    comment: '',
    builderId: [],
    agree: true,
    fromClient: isPublic ? 'Y' : 'N',
  };
  const { handleSubmit, register, errors, control, setValue, getValues, clearError, setError } = useForm({
    defaultValues: defaultValues,
  });
  useEffect(() => {
    getBuilders();
  }, []);
  const [show, setShow] = useState(false);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [fetching, setFetching] = useState(false);

  const [success, setSuccess] = useState(false);

  const changeFile = (e) => {
    const tmpFiles = e.target.files;
    if (tmpFiles.length > 0) {
      let tFiles = [...files];
      let tNames = [...fileNames];
      [...tmpFiles].forEach((item) => {
        tFiles.push(item);
        tNames.push(item.name);
      });
      setFiles(tFiles);
      setFileNames(tNames);
    }
  };

  const handleAddClient = (id) => {
    setValue('clientId', id);
    setShow(false);
  };

  const onSubmit = (data) => {

    if(getValues().builderId.length === 0){
      setError(
        "builderId",
        "notMatch",
        "Выберите застройщика"
      );
      return
    }
    setFetching(true);
    let form = new FormData();
    let keys = _keys(data);

    keys.forEach((item) => {
      let identity = item;
      if (Array.isArray(data[item])) {
        data[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        form.append(`${identity}`, data[item]);
      }
    });
    if (files.length) {
      files.map((file, index) => {
        form.append(`files[]`, file);
        return file;
      });
    }
    // form.append("city", city)

    fixApartment(form).then(
      (data) => {
        setSuccess(true);
        setFetching(false);
        ym('reachGoal','fixation_btn')
        infoToast( <div>Ваша заявка успешно отправлена!</div>)
        close();
      },
      (error) => {
        setError(true);
        setFetching(false);
        errorToast(<div>{error?.[0]?.message}</div>)
      },
    );
  };

  const [showAddClientModal, hideClientModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        closeClick={hideClientModal}
        title="Добавить клиента"
      >
        <FormAddClient
          show={true}
          onAddClient={(id) => handleAddClient(id)}
          onClose={hideClientModal}
        />
      </MobileModal>
    ),
  );

  return (
    <>
      <div className="fixate-client">
        {!media('isMobile') && <h2 className="modal__title">Фиксация клиента</h2>}
        <form className="modal-form" name="reserve-apartment" onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-form__wrapper">
            <input type="hidden" name="id" ref={register} />
            <div className="modal-form__row">
              <div className="modal-form__item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя и фамилия</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    className={
                      'input__control' + (errors.name ? ' input__error' : '')
                    }
                    ref={register({
                      required: 'Введите фамилию',
                    })}
                  />
                  {errors.name && (
                    <span className="input__error-label">
                      {errors.name.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="modal-form__item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <Controller
                    as={
                      <InputMask
                        className={
                          'input__control' +
                          (errors.phone ? ' input__error' : '')
                        }
                        placeholder="Введите телефон"
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                      />
                    }
                    name="phone"
                    control={control}
                    rules={{
                      required: 'Введите телефон',
                    }}
                  />
                  {errors.phone && (
                    <span className="input__error-label">
                      {errors.phone.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            {!isPublic && (
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <div className="select select_type_form">
                    <label
                      className="custom-select-label"
                      htmlFor="modal-reservation-client"
                    >
                      Клиент
                    </label>
                    <Controller
                      as={Dropdown}
                      name="clientId"
                      options={clients}
                      onChange={([value]) => value}
                      inputRef={register}
                      control={control}
                    />
                  </div>
                  <div
                    className="add-client active"
                    style={{ display: !show ? 'flex' : 'none' }}
                    onClick={() => media('isMobile') ? showAddClientModal() : setShow(true)}
                  >
                    <button
                      className="button button_type_add"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    ></button>
                    <span>Добавить клиента</span>
                  </div>
                </div>
              </div>
            )}
            {!isPublic && (
              <FormAddClient
                show={show}
                onAddClient={(id) => handleAddClient(id)}
              />
            )}

            {!isPublic && (
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_type_upload-btn"
                    htmlFor="modal-reservation-file-input"
                  >
                    <input
                      class="input__control"
                      type="file"
                      multiple
                      id="modal-reservation-file-input"
                      name="file"
                      onChange={changeFile}
                    />
                    Прикрепите файлы
                  </label>
                  <div
                    className="modal-form__files js-upload-container"
                    data-id="modal-reservation-file-input"
                  >
                    {fileNames.map((file, index) => (
                      <div data-num="0" class="file-up" key={index}>
                        <div class="file-up__inner">
                          <svg class="icon file-up__icon">
                            <use xlinkHref="#deals-01"></use>
                          </svg>
                          <span class="file-up__name">{file}</span>
                          <div class="file-up__controls">
                            <button class="button button_view_round-control file-up__del">
                              <svg class="icon">
                                <use xlinkHref="#x"></use>
                              </svg>
                            </button>
                            <button class="button button_view_round-control file-up__edit">
                              <svg class="icon">
                                <use xlinkHref="#edit"></use>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {!isPublic && (
              <div className="modal-form__row">
                <div className={`modal-form__item ${media('isMobile') ? 'modal-form__full-width' : ''}`}>
                  <Controller
                    as={DropdownMulti}
                    name="builderId"
                    items={builders}
                    data={{
                      title: 'Выберите застройщика',
                      identity: 'builderId',
                    }}
                    onChange={([value]) => {
                      setSelectedBuilders(value);
                      if (value.length === 0) {
                        setError(
                          "builderId",
                          "notMatch",
                          "Выберите застройщика"
                        );
                      } else {
                        clearError("builderId");
                      }
                      return value;
                    }}
                    innerRef={register({ required: true })}
                    control={control}
                    isMobile={media('isMobile')}
                  />
                    {errors.builderId && (
                    <span className="input__error-label">
                      {errors.builderId.message}
                    </span>
                  )}
                </div>
              </div>
            )}
          <div className={"fixation-badges-container"}>
            {selectedBuilders.map((builderId, index) => (
              <div class="filtered fixation-badges" key={'builder-' + index}>
                  <div className="filtered__inner">
                    <span className="filtered__type"></span>
                    <span className="filtered__value"> {builders.find((builder) => builder.id == builderId)?.title}</span>
                    <button
                    class="button filtered__del"
                    type="button"
                    onClick={() =>
                      setSelectedBuilders((prev) => {
                        let tmpBuilders = prev.filter((id) => id != builderId);
                        setValue('builderId', tmpBuilders);
                        return tmpBuilders;
                      })
                    }
                  >
                      <svg
                        className="icon icon_x"
                        width="7"
                        height="8"
                        viewBox="0 0 7 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
                        <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
                      </svg>
                    </button>
                  </div>
                </div>
            ))}
            {selectedBuilders.length > 0 && (
              <button
                class="button button_type_filtered filtered-clear fixation-badges"
                onClick={() => {
                  setSelectedBuilders([]);
                  setValue('builderId', []);
                }}
              >
                <span>Очистить параметры</span>
              </button>
            )}
            </div>
            {!isPublic && (
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="textarea textarea_type_form"
                    htmlFor="modal-reservation-commentary"
                  >
                    <span className="textarea__label">Комментарий</span>
                    <textarea
                      className="textarea__control"
                      id="modal-reservation-commentary"
                      ref={register}
                      name="comment"
                    ></textarea>
                  </label>
                </div>
              </div>
            )}

            <div className="modal-form__row">
              <div className="modal-form__item">
                <div className="checkbox checkbox_view_invert">
                  <div
                    className={
                      'checkbox__box' +
                      (errors.agree ? ' checkbox__box-error' : '')
                    }
                  >
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="modal-reservation-agreement"
                      name="agree"
                      ref={register({
                        required:
                          'Необходимо согласиться с правилами обработки данных',
                      })}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="modal-reservation-agreement"
                    ></label>
                    <div className="checkbox__marker"></div>
                  </div>
                  <label
                    className="checkbox__label"
                    htmlFor="modal-reservation-agreement"
                  >
                    Cогласие на обработку персональных данных
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-form__submit">
            <SubmitButton
              className="button button_view_default"
              isLoading={fetching}
            >
              <span>Отправить заявку</span>
            </SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.user?.info?.email,
    phone: state.user?.info?.phone,
    clients: ownProps.client
      ? [ownProps.client]
      : state.user?.clients?.map((client) => ({
          id: client.id,
          title: `${client.name} ${client.lastName}`,
        })),
    name: state.user?.info?.name
      ? `${state.user?.info?.name} ${state.user?.info?.lastName}`
      : '',
    builders: state.forms?.builders?.map && state.forms?.builders?.map((builder) => ({
      id: builder.id,
      title: builder.name,
    })),
    city: state.user?.info?.city
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBuilders: () => dispatch(getBuilders()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FixateClient);
