import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';

 export const history = createBrowserHistory()

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(routerMiddleware(history)),
    ),
  );


  const persistor = persistStore(store, null, () => {});

  return { store, persistor };
}
