import { formatMoney, getEndingsTitle, getRoundedPrice } from "../../utils"
import { badgeTitles, specialBadgeValuesTitles } from "./filterSets"
import min from "lodash/min";
import minBy from "lodash/minBy";


export const isEmptyObject = (obj, shadowFilters) => {
    const tmp = { ...obj }
    shadowFilters.forEach(fieldName => delete tmp[fieldName])
    return Object.keys(tmp).length === 0
}

export const setter = (prev, tmp, key) => {
    const tmpFilter = { ...prev }
    if (!tmp) {
        delete tmpFilter[key]
        return tmpFilter
    }
    tmpFilter[key] = tmp
    return tmpFilter
}
export const getTmpValue = (object, key, subKey, value, fields) => {
    const type = Array.isArray(fields[key]) ? "array" : typeof fields[key];
    const inFilter = key in object
    let tmp;

    switch (type) {
        case "array":
            if (!value) {
                tmp = object[key]
                break;
            }
            if (!inFilter) {
                tmp = [value]
                break;
            }
            if (object[key].includes(value)) {
                tmp = [...object[key]]
                const index = tmp.indexOf(value)
                tmp.splice(index, 1)
                if (tmp.length === 0) tmp = undefined
                break
            }
            tmp = [...object[key], value]
            break;
        case "object":
            if (!value && inFilter) {
                tmp = { ...object[key] }
                delete tmp[subKey]
                if (isEmptyObject(tmp)) tmp = undefined
                break
            }
            if (inFilter) {
                tmp = { ...object[key] }
                tmp[subKey] = value
                break
            }
            break
        default:
            if (!value) return
            tmp = value
    }
    return tmp
}

export const filterReducer = (state, { type, payload = {} }) => {
    switch (type) {
        case "setData":
            return { ...state, ...payload }
        case "setState":
            return payload
        default:
            throw new Error(`Неверный тип в экшене: ${type}`);
    }
};


const meterFormat = val => `${val} м.`
const meterSquareFormat = val => `${val} м²`
const hundredSquareFormat = val => `${val} сот.`
const getSpecValue = (value, ident) => specialBadgeValuesTitles?.[ident]?.[value]
const getObjectTitle = (value, ident, filterData) => {
    const items = filterData[ident]?.items || filterData[ident]
    if (ident === "endings" || ident === "endingDate") return getEndingsTitle(value)
    if (!items) return value
    return items.find(item => item.id == value)?.title
}
const formatters = ({ currency = "₽", data }) => (new Proxy({
    cellHeight: meterFormat,
    encumbrances: getSpecValue,
    endings: getObjectTitle,
    endingDate: getObjectTitle,
    assignments: getSpecValue,
    isTwoLevels: getSpecValue,
    masterBedroom: getSpecValue,
    isApartments: getSpecValue,
    isFirstFloor: getSpecValue,
    residencePermit: getSpecValue,
    onlySelfBuild: getSpecValue,
    isNoFirstFloor: getSpecValue,
    isOnlyHanded: getSpecValue,
    isHanded: getSpecValue,
    issuingKeys: getObjectTitle,
    prices: val => formatMoney(val, ` ${currency}`),
    priceParcel: val => formatMoney(val, ` ${currency}`),
    priceHouse: val => formatMoney(val, ` ${currency}`),
    squareHouse: meterSquareFormat,
    squareKitchen: meterSquareFormat,
    squareParcel: hundredSquareFormat,
    squareTotal: meterSquareFormat,
    tradeIn: v => "да",
    price: val => formatMoney(val, ` ${currency}`),

    hasTerrace: v => "да",
    hasKitchenLiving: v => "да",
    hasPanoramicWindows: v => "да",
    hasWardrobe: v => "да",
    hasSecondLight: v => "да",
    hasAtticFloor: v => "да",
    hasSauna: v => "да",

    cottageSettlement: getObjectTitle,


}, {
    get(target, prop) {
        if (!data || typeof target[prop] === "function") return target[prop]
        return (v, key) => {
            return data.find(({ id, type }) => id === v && type === key)?.title
        }

    },
}))

export const createBadges = (filter, filterData, shadowFilters, formattersParams = {}, specialTitles = {}) => {
    if (!filterData) return []
    const result = [];
    const push = (ident, title, value, id, subKey) => result.push({
        ident,
        subKey,
        title,
        value,
        id
    })
    for (const [key, value] of Object.entries(filter)) {
        const type = Array.isArray(value) ? "array" : typeof value;
        const formatter = formatters(formattersParams)[key]
        if (shadowFilters.includes(key)) continue
        if (type === "array") {
            const fData = filterData[key]?.items || filterData[key] || [] // потому что по другому прилетает с бэка иногда фильтр типа countBedrooms в КП
            const valueItems = fData.filter(item => value.some(id => id == item.id))
            if (!valueItems) continue
            valueItems.forEach(valueItem => push(key, specialTitles[key] ?? badgeTitles[key], valueItem.title ?? valueItem.id, valueItem.id))
            continue
        }
        if (type === "object") {
            const { min, max } = filter[key]
            min && push(
                key,
                specialTitles[key]?.min ?? badgeTitles[key]?.min,
                formatter ? formatter(min, key, filterData) : min,
                min,
                "min"
            );

            max && push(
                key,
                specialTitles[key]?.max ?? badgeTitles[key]?.max,
                formatter ? formatter(max, key, filterData) : max,
                max,
                "max"
            );
            continue
        }
        const formatted = formatter ? formatter(value, key, filterData) : value
        push(key, specialTitles[key] ?? badgeTitles[key], formatted, value)
    }
    return result
}
const createMapItemsExtractor = (priceFn, hintFn) => items => {
    const result = []
    for (let i = 0, len = items.length; i < len; i++) {
        const item = items[i];
        if (!item.coordinates) continue
        result.push({
            type: 'Feature',
            id: item.id,
            geometry: {
                type: 'Point',
                coordinates: item.coordinates,
            },
            properties: {
                hintContent: hintFn(item),
                minPrice: priceFn(item)
            },
        })
    }
    return result
}

export const extractPrimaryMapItems = createMapItemsExtractor(item => item.apartmentsInfo ?
    getRoundedPrice(min(item.apartmentsInfo.apartmentsByRoomType.map(val => val.minPrice)))
    : getRoundedPrice(item.price), item => item.name
)
export const extractSuburbansMapItems = createMapItemsExtractor(item => item.suburbansInfo ?
    getRoundedPrice(min(item.suburbansInfo.suburbansByType.map(val => val.minPrice)))
    : getRoundedPrice(item.price), item => item.name
)
export const extractCommercialMapItems = createMapItemsExtractor(item => getRoundedPrice(item.costWithDiscount), item => item.blockName);
export const extractAssignmentsMapItems = createMapItemsExtractor(item => getRoundedPrice(item.costWithDiscount), item => item.block?.name);
export const extractSecondaryMapItems = createMapItemsExtractor(item => getRoundedPrice(item.baseCost), ({ objectType, roomType, squareTotal }) => `${objectType?.title}, ${roomType?.title}, ${squareTotal} м²`);
export const extractDubaiMapItems = createMapItemsExtractor(item => item.apartmentsInfo ?
    minBy(item.apartmentsInfo.apartmentsByRoomType.map(val => {
        const result = {
            price: val.minPrice.RUB
        };
        Object.entries(val.minPrice).forEach(([key, price]) => result[key] = getRoundedPrice(price))
        return result
    }), item => item.price)
    : getRoundedPrice(item.minPrice), item => item.name
)
export const realtyMapReducer = (state, { type, payload = {} }) => {
    switch (type) {
        case "setData":
            return { ...state, ...payload }
        default:
            throw new Error(`Неверный тип в экшене: ${type}`);
    }
};

export const extractFavoritesMapItems = createMapItemsExtractor(item => getRoundedPrice(item.price), item => item.title);

export const getDeleteCond = (object, ident) => !object?.[ident] ||
    (Array.isArray(object?.[ident]) && !object?.[ident]?.length)

export const toSearchParams = (filter, exclude, other) => window.history.pushState(
    null,
    "",
    `${window.location.pathname}?filter=${JSON.stringify(filter)}&exclude=${JSON.stringify(exclude)}${other ?? ""}`)

const fromSearchParams = (key) => {
    const params = new URLSearchParams(window.location.search)
    return params.get(key)
}

/*useEffect(() => {
     if(filterProps.isLoading) return
    toSearchParams(filterProps.filter, filterProps.exclude)
  }, [filterProps.filter, filterProps.exclude]) */ 

const parseJSON = v => {
    try {
        const parsed = JSON.parse(v)
        return parsed
    } catch (error) {
        return ""
    }
}//hotfix

export const getFilterStateFromParams = () => {

    const filterFromParams = parseJSON(fromSearchParams("filter"));

    const excludeFromParams = parseJSON(fromSearchParams("exclude"));

    return filterFromParams || excludeFromParams ? { filter: filterFromParams, exclude: excludeFromParams } : false;
}