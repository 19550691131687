import React from 'react'
import emptyPlan from "../assets/images/emptyPlanList.svg";

export const icons = {
    "ig": {
      name: "Instagram", logo:
        <svg className="icon icon_social-ig">
          <use xlinkHref="#social-ig" />
        </svg>
    },
    "vk": {
      name: "Вконтакте", logo:
        <svg className="icon icon_social-vk">
          <use xlinkHref="#social-vk" />
        </svg>

    },
    "fb": {
      name: "Facebook", logo:
        <svg className="icon icon_social-tg">
          <use xlinkHref="#social-fb" />
        </svg>

    },
    "tg": {
      name: "Telegram", logo:
        <svg className="icon icon_social-tg">
          <use xlinkHref="#social-tg" />
        </svg>
    },
    "wa": {
      name: "Whatsapp", logo:
        <svg className="icon icon_social-wa">
          <use xlinkHref="#social-wa" />
        </svg>

    },
    "vb": {
      name: "Viber", logo:
        <svg className="icon icon_social-viber">
          <use xlinkHref="#social-viber" />
        </svg>

    },
  }

export const onImgError = ({ target }) => {
  // console.log('target :>> ', target);
  target.onerror = null;
  target.src = emptyPlan
  target.style.objectFit = "cover"
}

// как array.prototype.map только удаляет пустые результаты (0 оставляем)
export const mapFilter = (arr = [], callback) => {
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    const value = callback(arr[i], i, arr)
    if (!value && value !== 0) continue;
    result.push(value)
  }
  return result;
};
