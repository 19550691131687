import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getSpecialOffers } from "../actions/suburbans.js";
import MobileModal from '../components/core/MobileModal';
import Modal from "../components/core/Modal";
import Wrap from '../components/core/ModalWrap';
import SpecialForm from "../components/forms/Special.js";
import Preloader from "../components/Preloader.js";
import DetailCard from "../components/Special/DetailCard.js";
import DetailHeader from "../components/Special/DetailHeader.js";
import '../components/Special/index.scss';
import SpecialModal from "../components/Special/SpecialModal.js";
import { standartErrorToast } from "../components/toasts.js";
import useApi from "../hooks/useApi.js";
import useMedia from "../hooks/useMedia.js";
import DetailCardNew from "../components/Special/DetailCardNew";
import DetailCardNewHouse from "../components/Special/DetailCardNewHouse";

const SpecialDetailNew = props => {
  const { id, savedFilterXmlId, xmlId } = useParams()
  const isMobile = useMedia("isMobile");
  const [isShowRoom, setIsShowRoom] = useState(false);
  const [specialModal, setSpecialModal] = useState(false);
  const [specialModalData, setSpecialModalData] = useState({});
// console.log(id)
  const user = useSelector(state => state.user?.info)
  const isPublic = !!savedFilterXmlId;

  const {
    data: { contractConstruction, semiObjects, objectInfo, priceInfo, dateUpdateInfo, suburban, projectInfo, equipments, otherProjects, filesInfo, contacts, schedule, fixationLink, bookingLink },
    isLoading: loading,
    refresher,
    isRefreshing,
    loadMore,
    moreIsLoading,
    inFavorite,
    isFavoriteLoading,
    toggleFavorite
  } = useApi({
    payload: ["panpartner:new.suburbans.ajax", "getSuburban", {id}],
    favoritesParams: {
      id,
      type: 'project',
    },
    favoritePath: 'suburban.favorite',
  }, [id])

  const openSpecialModal = (houseName, manager) => {
    setSpecialModalData({ houseName, manager })
    setSpecialModal(true)
  }

  const [openSpecialModalForm, hideSpecialModalForm] = useModal(
    ({ in: open, onExited }) => (

      <Wrap wrapper={children => isMobile
        ? <MobileModal title={isShowRoom ? `Записаться в шоу-рум` : `Получить консультацию`} closeClick={hideSpecialModalForm} className="special-form-modal">{children}</MobileModal>
        : <Modal close={() => {
          hideSpecialModalForm();
          setIsShowRoom(false);
        }}>{children}</Modal>
      } >
        <SpecialForm close={() => {
          hideSpecialModalForm();
          setIsShowRoom(false);
        }} specialOfferId={id} isShowRoom={isShowRoom} setIsShowRoom={setIsShowRoom}/>
      </Wrap >
    ), [id, isShowRoom]
  );

  if (loading) return <Preloader loading />;
  return (
    <>
      <div className="wrapper">
         <div className="special__detail-wrapper">
          <div className="special__detail-container">
          {/*<DetailCard {...contractConstruction} openSpecialModal={openSpecialModal} semiObjects={semiObjects} hidePrice isPublic={isPublic}/>*/}
            <DetailCardNewHouse
              dateUpdateInfo={dateUpdateInfo}
              objectInfo={objectInfo}
              priceInfo={priceInfo}
              openSpecialModal={openSpecialModal}
              semiObjects={semiObjects}
              inFavorite={suburban.favorite.inFavorite}
              isFavoriteLoading={isFavoriteLoading}
              toggleFavorite={toggleFavorite}
              hidePrice
              suburban={suburban}
              projectInfo={projectInfo}
              equipments={equipments}
              otherProjects={otherProjects}
              filesInfo={filesInfo}
              contacts={contacts}
              schedule={schedule}
              fixationLink={fixationLink}
              bookingLink={bookingLink}
            />
          </div>
        </div>
      </div>
      <SpecialModal name={user?.name} setSpecialModal={setSpecialModal} specialModal={specialModal} {...specialModalData} sendReq={openSpecialModalForm} />
      {/*<Preloader loading={loading} />*/}
    </>
  );
};


export default SpecialDetailNew;
