import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import useApi from "../../hooks/useApi";
import DetailHeader from "../Public/markup/detail/DetailHeader";
import AptModal from "../AptModal";
import {
  AddToCompilationIcon,
  AptStatusIcon,
  HeartIcon,
  LockIcon,
  PdfIcon,
  PresentationIcon,
  TopArrowIcon
} from "../Public/markup/icons";
import Slider from "../Public/markup/detail/Slider";
import Preloader from "../Preloader";
import InfoCard from "../Public/markup/detail/InfoCard";
import SubmitButton from "../SubmitButton";
import {getUrl} from "../../api";
import AddPresenatation from "../AddPresentation";
import Button from "../core/Button";
import {useMapModal} from "../../hooks/useMapModal";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import ContactsModal from "../Public/markup/detail/ContactsModal";
import StatelessTabs from "../core/Tabs/StatelessTabs";
import Tabs from "../core/Tabs";
import useMedia from "../../hooks/useMedia";
import { useMemo } from 'react';
import useDocTitle from "../../hooks/useDocTitle";
import {downloadLink, objectsArrayToFormData, toFormData} from '../../utils';
import {successToast} from "../toasts";
import useModalWithData from "../../hooks/useModalWithData";
import CompilationModal from "../../pages/NewFavorites/compilationModal";

const SuburbanObject = (props) => {

  const {id, city, savedFilterXmlId} = useParams()
  const {
    data:
      {
        suburban: {
          plan,
          photos,
          generalPlan,
          compilationObjectType,
          ...suburban
        } = {},
        objectInfo,
        tabs,
        dateUpdateInfo,
        blockParamsInfo,
        priceInfo,
        meterPriceInfo,
        contacts,
        schedule,
        bookingLink,
        fixationLink,
      },
    error,
    isLoading,
    isSending,
    inFavorite,
    isFavoriteLoading,
    toggleFavorite
  } = useApi({
    payload: ['panpartner:suburbans.ajax', 'getSuburban', { id, city }],
    favoritesParams: {
      city,
      id,
      type: 'suburban',
    },
    favoritePath: 'suburban.favorite',
  })

  const [filePath, setfilePath] = useState(false)

  useDocTitle(suburban?.name)
  const [openMapModal, closeMapModal] = useMapModal({
    cords: suburban?.coordinates,
    mapCenter: suburban?.coordinates,
  })

  const images = useMemo(() => [
    ...(plan ? plan : []),
    ...(photos ? photos : []),
    ...(generalPlan && !generalPlan?.empty ? [generalPlan] : [])
  ], [suburban])

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          params={{
            type: "suburban",
            objectId: id,
            city
          }}
        />
      </PanModal>
    ),
    [contacts],
  );

  const cols = [
    {
      title: priceInfo?.title,
      value: priceInfo?.value,
    },
    {
      title: meterPriceInfo?.title,
      value: meterPriceInfo?.value,
    },
  ];

  const isLaptop = useMedia("isLaptop");

  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      addToCompilationParams={[{ objectId: id, type: compilationObjectType, city }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })



  if (isLoading) return <Preloader loading />;

  const renderPresentations = ({ fetching, finish, link, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={finish ? () => window.open(link) : handleSend}
    >
      <PresentationIcon />
      {finish || suburban?.presentation?.id ? "К презентации" : "В презентации"}
    </SubmitButton>
  }
  const renderPdf = ({ fetching, finish, resp, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={filePath ? () => window.open(filePath) : () => handleSend().then(resp => {
        downloadLink(getUrl(resp?.filePath))
        setfilePath(getUrl(resp?.filePath))
      })}
    >
      <PdfIcon />
      Скачать PDF
    </SubmitButton>
  }
  return (
    <div className="sf wrapper">
      <div className="apt-modal__title">
        <h3 className="h3">{suburban?.name}</h3>
        <div className={`label-item apt-modal__status label_${suburban?.status?.color}`}>
          <AptStatusIcon />
          {suburban?.status.name}
        </div>
      </div>

      <div className="sf__detail-bar" style={{marginTop: 7, marginBottom: 0}}>
        <a href={blockParamsInfo[0]?.link} className="link link_color-red">{blockParamsInfo[0]?.value}</a>
        {suburban?.address && <p className="text sf__detail-descr sf__detail-descr_center" style={{color: '#2C2E3E'}}>{suburban?.address}</p>}
        {suburban?.coordinates && (
          <span className="link link_color-red" onClick={openMapModal}>
          На карте
        </span>
        )}

      </div>
      <div className="apt-modal__header-second">
        <div className="sf sf--apt-modal">
          <div className="sf__elems">
            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => !isFavoriteLoading && toggleFavorite()}
              style={{width: 192}}
            >
              <HeartIcon isActive={inFavorite} />
              {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
            </button>

            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={openCompilationModal}
            >
              <AddToCompilationIcon/>
              В подборку
            </button>

            <AddPresenatation
              type={'suburban'}
              id={suburban?.xmlId}
              city={city}
              presentation={suburban?.presentation}
              render={renderPresentations}
            />
            <AddPresenatation
              type={'suburban'}
              id={suburban?.xmlId}
              city={city}
              method="getPdf"
              render={renderPdf}
            />
            {!!fixationLink && <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => window.open(fixationLink)}
            >
              <LockIcon />
              {!isLaptop && 'Зафиксировать клиента'}
            </button>}
          </div>
        </div>
      </div>

      <div className="sf">
        <div className="sf__detail-row sf__detail-row--common-page">
          <div className="sf__detail-col">
            <Slider
              items={images}
              withBorder
            />

            {tabs.length > 0 &&
              <section style={{marginTop: 35}} className={`sf__section ${tabs.length === 1 ? 'sf__section_single-tab' : ''}`}>
                <Tabs title={'Описание'}>
                  {tabs.map(({title, value}) => {
                      return (
                        <div label={title}>
                          <p
                            className="sf__about-text medium-text-l"
                            dangerouslySetInnerHTML={{
                              __html: value,
                            }}
                          />
                        </div>
                      )
                    }
                  )}
                </Tabs>
              </section>
            }

          </div>
          <div className="sf__detail-col">
            <InfoCard items={objectInfo} topDate={dateUpdateInfo.title + ' ' + dateUpdateInfo.value} cols={cols}/>
            <section className="sf__section">
              {!!bookingLink &&<Button buttonType="primary" isFull onClick={()=>window.open(bookingLink)}>
                Забронировать
              </Button>}
              <Button buttonType="line" isFull onClick={openContactsModal}>
                Контакты
              </Button>
            </section>
          </div>
        </div>
      </div>

    </div>
  )
}

export default SuburbanObject;
