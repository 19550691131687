import React, { useEffect } from 'react';
const InputSwitcher = (props) => {
  const { name, label, handleChange, disabled, value, valueId , setHovered, hovered, depends, formData, isMortgage} = props;
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (!disabled && value) {
      setChecked(true);
      handleChange(true, name, valueId);
    } else if (handleChange && !value) {
      handleChange(false, name, valueId);
    }
  }, [value]);

  useEffect(() => {
    if (depends && !formData?.settings?.[depends]) {
      setChecked(false);
    }
  }, [formData])

  const onChange = (e) => {
    setChecked(e.target.checked);
    handleChange(e.target.checked, name, valueId);
  };

  return (
    <label
      className={
        'switch pdf-control' +
        (disabled ? ' disabled info_plain info_align_top' : !checked && isMortgage ? ' disabled_mortgage_label' : '')
      }
      htmlFor={valueId}
      onMouseOver={()=>{
        if(hovered || name !=="agent_certificate" ){
          return
        }
        setHovered(true)
        // ym('reachGoal','how_get_certificate_pres')
      }}
    >
      <div className="switch__box">
        <input
          className="switch__control"
          type="checkbox"
          id={valueId}
          onChange={onChange}
          checked={checked}
          name={name}
          disabled={disabled}
        />
        <div className="switch__input"></div>
        <div className="switch__marker"></div>
      </div>
      <span className="switch__label">{label}</span>
      {props.children}
    </label>
  );
};

export default InputSwitcher;
