import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import "./index.scss";
import List from "./List";
import View from "./View";
import AssignationFlat from "./New/AssignationFlat";
import ListNew from "./ListNew";



const Assignation = (props) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      {/*<Route exact path={`${path}`} component={List} />*/}
      <Route exact path={`${path}`} component={ListNew} />
      {/*<Route exact path={`${path}/:id`} component={View} />*/}
      <Route exact path={`${path}/:id`} component={AssignationFlat} />
    </Switch>
  );
};

export default Assignation;
