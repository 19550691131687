import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import {
  successLogin, successTestAccessSms,
  testAccessConfirm, testAccessSms
} from '../../actions/user';
import useTimer from '../../hooks/useTimer';
import SubmitButton from '../SubmitButton';
import { errorToast, standartErrorToast } from '../toasts';
import BannerThin from './BannerThin';
import DotsSVG from './DotsSVG';
const TrialRequest = (props) => {
  const { handleSubmit, register, errors, control, reset, watch } = useForm();
  const { smsSuccess, userId, history, successLogin } = props;
  const [phone, setPhone] = useState('');
  const [fetching, setFetching] = useState(false);
  const [submitTupe, setSubmitType] = useState('request');
  const {
    timeout,
    timerIsStarted,
    runTimer
  } = useTimer(60)

  const onSubmit = (data) => {
    if (submitTupe === 'request') {
      let params = new URLSearchParams(window.location.search);
      let utm = {
        utm_campaign: params.get('utm_campaign'),
        utm_content: params.get('utm_content'),
        utm_medium: params.get('utm_medium'),
        utm_source: params.get('utm_source'),
        utm_term: params.get('utm_term'),
      };

      let form = new FormData();
      Object.keys(utm).map((key) => {
        form.append(`utm[${key}]`, utm[key]);
      });
      form.append(`phone`, data.phone);
      if (window.location.host !== 'localhost:3000') {
        ym('reachGoal', 'trial_request');
        window.fbq('track', 'Lead');
      }
      setFetching(true);
      testAccessSms(form, utm).then(
        (resp) => {
          smsSuccess(resp);
          setFetching(false);
          runTimer();
          setSubmitType('login');
          setPhone(data.phone);
        },
        (error) => {
          setFetching(false);
          errorToast(<div>{error}</div>)
        },
      );
    } else {
      let form = new FormData();
      form.append(`phone`, phone);
      form.append(`userId`, userId);
      form.append(`code`, data.password);

      testAccessConfirm(form).then(
        (resp) => {
          setFetching(false);
          successLogin(resp);
          if (window.location.host !== 'localhost:3000') {
            ym('reachGoal', 'success_trial_login');
          }

          history.push('/');
        },
        (error) => {
          setFetching(false);
          errorToast(<div>{error}</div>)
        },
      );
    }
  };
  const resendSms = () => {
    let params = new URLSearchParams(window.location.search);
    let utm = {
      utm_campaign: params.get('utm_campaign'),
      utm_content: params.get('utm_content'),
      utm_medium: params.get('utm_medium'),
      utm_source: params.get('utm_source'),
      utm_term: params.get('utm_term'),
    };
    let form = new FormData();
    Object.keys(utm).map((key) => {
      form.append(`utm[${key}]`, utm[key]);
    });
    form.append(`phone`, phone);
    if (window.location.host !== 'localhost:3000') {
      ym('reachGoal', 'trial_request');
    }

    testAccessSms(form).then(
      (resp) => {
        smsSuccess(resp);
        setFetching(false);
        runTimer();
        setPhone(phone);
      },
      (error) => {
        setFetching(false);
        standartErrorToast(error)
      },
    );
  };
  const request = (
    <form className="form" name="sign-trial" onSubmit={handleSubmit(onSubmit)}>
      <div className="sign-in__title">
        Для получения пробного доступа введите свой номер телефона
      </div>

      <div className="sign-up__row">
        <div className="sign-up__input">
          <label
            className="input input_width_available input_type_form"
            htmlFor="sign-in_phone"
          >
            <Controller
              as={
                <InputMask
                  className={
                    'input__control' + (errors.phone ? ' input__error' : '')
                  }
                />
              }
              control={control}
              autoComplete="off"
              placeholder="Введите телефон"
              mask="+7 (999) 999-99-99"
              name="phone"
              inputRef={register({
                required: 'Введите телефон',
              })}
              defaultValue=""
              rules={{
                required: 'Введите телефон',
              }}
            />
            {errors.phone && (
              <span className="input__error-label">{errors.phone.message}</span>
            )}
          </label>
        </div>
      </div>

      <div className="sign-up__row">
        <div className="sign-up__submit preview__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <span>Получить пароль</span>
          </SubmitButton>
        </div>
      </div>
    </form>
  );
  const login = (
    <form className="form" name="sign-trial">
      <div className="sign-in__title">Введите полученный пароль</div>

      <div className="sign-in__row">
        <div className="sign-in__input">
          <label
            className="input input_width_available input_type_form"
            htmlFor="sign-in_password"
          >
            <input
              name="password"
              type="password"
              placeholder="Пароль"
              className={
                'input__control' + (errors.password ? ' input__error' : '')
              }
              id="sign-in_password"
              ref={register({
                required: 'Введите пароль',
              })}
            />

            {errors.password && (
              <span className="input__error-label">
                {errors.password.message}
              </span>
            )}
          </label>
        </div>
      </div>
      {!timerIsStarted && (
        <div className="sign-in__row">
          <span className="preview__anchor" onClick={resendSms}>
            Запросить новый пароль
          </span>
        </div>
      )}
      {timerIsStarted && (
        <div className="sign-in__row preview__countdown">
          <p>
            Запросить новый пароль можно будет через:{' '}
            <span className="preview__counter">
              0:{timeout < 10 && '0'}
              {timeout}
            </span>
          </p>
        </div>
      )}
      <div className="sign-up__row">
        <div className="sign-in__submit">
          <SubmitButton
            className="button button_view_default"
            isLoading={fetching}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <span>Войти</span>
          </SubmitButton>
        </div>
      </div>
    </form>
  );
  return (
    <section className="section preview__auth">
      <div className="wrapper">
        <div className="auth__content">
          <div className="auth__title">
            <h1 className="h1">
              Получить пробный
              <br />
              доступ
            </h1>
          </div>
          <div className="preview__auth-dots">
            <DotsSVG />
          </div>
          <div className="auth__form preview__auth-form">
            <div className="auth__sign-up">
              <div className="sign-up">
                {submitTupe === 'request' ? request : login}
              </div>
            </div>
          </div>
        </div>
        <div className="preview__auth-banner">
          <BannerThin />
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  smsSuccess: (data) => dispatch(successTestAccessSms(data)),
  successLogin: (data) => dispatch(successLogin(data)),
});

const mapStateToProps = (state) => ({
  userId: state.user.userId,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialRequest);
