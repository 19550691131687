import { memo } from 'react';
import RadioDropdown from '../../core/RadioDropdown';
import { PriceCurency } from '../../FilterRealty/Components';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import { getSelectPropsCreator } from '../utils';


const DubaiComplexFilterForm = memo(props => {
  const {
    clearExcludeOnly,
    clearFilterOnly,
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    currency,
    setCurrency,
    currencyList = {},
  } = props

  const createSelectProps = getSelectPropsCreator(props);
  return (
    <div className="sf__filter sf__filter_dubai form">
      {!!filterData.prices?.[currency] && <PriceFilter
        filter={filter.prices ?? { min: "", max: "" }}
        items={filterData.prices?.[currency]?.items ?? []}
        wrapperClass="sf__filter-item"
        data={{
          title: "Цена от-до",
          minPlaceholder: "от ",
          maxPlaceholder: "до ",
          defaultValues: filterData.prices?.[currency]?.range ?? 0,
        }}
        onChange={v => setFilterAndExclude(v, "prices")}
        renderTopContent={PriceCurency}
        debounced
        autoPosition
        currencyList={currencyList}
        currency={currency}
        setCurrency={setCurrency}
      />}
      {!!filterData?.type?.length && <SelectFilter
        {...createSelectProps("type")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Тип недвижимости",
          identity: "type",
        }}
      />}

      {!!filterData?.countBedrooms?.length > 0 && <SelectFilter
        {...createSelectProps("countBedrooms")}
        wrapperClass="sf__filter-item"
        data={{
          title: "Кол-во спален",
          identity: "countBedrooms",
        }}
      />}
      {filterData.squareTotal && <SquareFilterOnly
        autoPosition
        wrapperClass="sf__filter-item"
        filter={filter.squareTotal ?? { min: "", max: "" }}
        data={{
          title: 'S общая',
          minPlaceholder: 'S общая от ',
          maxPlaceholder: 'S общая до ',
          defaultValues: filterData.squareTotal?.range ?? null,
        }}
        placeholderMes={'м²'}
        onChange={v => setFilterAndExclude(v, 'squareTotal')}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        fullFilter={filter}
        identity={"squareTotal"}
        items={filterData.squareTotal?.items}
        showPlaceholderOnLists
        debounced
      />}
      {!!filterData?.residencePermit?.length && <RadioDropdown
        autoPosition
        onChange={v => handleFilter('residencePermit', v)}
        items={filterData.residencePermit ?? []}
        wrapperClass="sf__filter-item"
        value={filter.residencePermit}
        alternativeTitle={'ВНЖ'}
        data={{
          title: 'ВНЖ',
          identity: 'residencePermit',
        }}
      />}
    </div>
  );
});

export default DubaiComplexFilterForm;
