
import classNames from 'classnames';
import { ListIcon, TileIcon, ChessIcon } from './icons';
import { memo } from 'react';
import './index.scss';

const Tab = memo(({ label, isActive, onClick, largeBtn, icon: Icon, withNew }) => {
  const buttonClasses = classNames({
    'button tab-btn': true,
    h6: !largeBtn,
    'h3-l': largeBtn,
    'tab-btn_active': isActive,
  });

  return <button type="button" className={buttonClasses} id={withNew && 'tab-new-parking'} onClick={onClick}>
    {Icon && <Icon />}
    {label}
  </button>
});

const StatelessTabs = memo(props => {
  const { title, tabs, isInline, withIcons = false, activeTab, setActiveTab, onClick } = props;
  const tabsClasses = classNames({
    'tab-bar': true,
    'tab-bar_flex': title,
    'tab-bar_inline': isInline,
    'tab-bar_with-icons': withIcons,
  });

  return (
    <div className="tabs">
      <div className={tabsClasses}>
        {title && <h3 className="h3 tab-title">{title}</h3>}
        <div className="tab-list">
          {tabs.map((tab = {}, i) => {
            return (
              <Tab
                {...props}
                {...tab}
                key={i}
                onClick={onClick ? () => onClick(i, tab) : () => setActiveTab(i)}
                isActive={activeTab === i}
                label={tab.title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default StatelessTabs;
