import ru from 'date-fns/locale/ru';
import dateFormat from 'dateformat';
import _keys from 'lodash/keys';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { media } from '../../../utils';
import Confirm from '../../core/Confirm';
import Dropdown from '../../core/Dropdown';
import MobileConfirm from '../../core/MobileConfirm';
import { infoToast } from '../../toasts';

registerLocale('ru', ru);

const times = [
  {
    id: '1',
    title: 'За час до события',
    short: 'За час',
  },
  {
    id: '3',
    title: 'За 3 часа до события',
    short: 'За 3 часа',
  },
  {
    id: '24',
    title: 'За день до события',
    short: 'За день',
  },
];

const TaskForm = (props) => {
  const {
    edit,
    data,
    deals,
    close,
    defaultValues,
    handleEdit,
    client,
    removeTask,
  } = props;

  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: defaultValues,
  });

  const isNotify = watch('notify', defaultValues.notify);

  const onSubmit = (data) => {
    let form = new FormData();
    let keys = _keys(data);

    keys.forEach((item) => {
      let identity = item === 'email' ? 'login' : item;
      switch (identity) {
        case 'notify':
        case 'countHours':
          break;
        case 'dealTime':
          let time = dateFormat(data[item], 'HH:MM:ss');
          form.append(`${identity}`, time);
          break;
        case 'dealDate':
          let date = dateFormat(data[item], 'yyyy-mm-dd');
          form.append(`${identity}`, date);
          break;
        default:
          form.append(`${identity}`, data[item]);
      }
    });
    if (data.notify) {
      if (data.notifyId) {
        form.append(`notifies[0][id]`, data.notifyId);
      }
      form.append(`notifies[0][active]`, true);
      form.append(`notifies[0][countHours]`, data.notifyHours);
    }

    handleEdit(form).then(
      () => {
        close();
        infoToast(edit ? <div>Задача обновлена</div> : <div>Задача добавлена</div>)
      },
    );
  };

  const confirm = () => {
    removeTask(defaultValues.id, defaultValues.clientId).then(() => {
      hideModal();
      close();
      infoToast(<div>Задача удалена</div>)
    });
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить задачу?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [confirmMobileModal, hideMobileModal] = useModal(({ in: open, onExited }) => (
    <MobileConfirm
      closeClick={hideMobileModal}
      title={`Вы действительно хотите удалить задачу?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));


  return (
    <>
      {!media('isMobile') &&
        <h2 className="modal__title">
          {edit ? `Редактировать задачу` : `Создать задачу`}
        </h2>
      }
      <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
        {edit && <input type="hidden" ref={register} name="id" />}
        <input type="hidden" ref={register} name="clientId" />
        {defaultValues.notifyId > 0 && (
          <input type="hidden" ref={register} name="notifyId" />
        )}
        <div className="modal-form__row">
          <div className="modal-form__item">
            <div className="select select_type_form">
              <label className="custom-select-label" for="modal-task">
                Задача
              </label>
              <Controller
                as={Dropdown}
                name="dealType"
                control={control}
                options={deals}
                ref={register}
                onChange={([value]) => value}
              />
            </div>
          </div>
        </div>
        <div className="modal-form__row">
          <div className="modal-form__item">
            <label
              className="input input_width_available input_type_form"
              for="modal-task-date"
            >
              <span className="input__label">Дата</span>
              <Controller
                as={
                  <DatePicker
                    dateFormat="d MMM yyyy"
                    minDate={new Date()}
                    showTimeSelect={false}
                    todayButton="Сегодня"
                    dropdownMode="select"
                    locale="ru"
                    className="input__control"
                    shouldCloseOnSelect
                  />
                }
                control={control}
                register={register({ required: true })}
                name="dealDate"
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => {
                  return selected;
                }}
                rules={{
                  required: true,
                }}
              />
            </label>
          </div>
          <div className="modal-form__item">
            <label
              className="input input_width_available input_type_form"
              for="modal-task-time"
            >
              <span className="input__label">Время</span>
              <Controller
                as={
                  <DatePicker
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    className="input__control"
                    shouldCloseOnSelect
                  />
                }
                control={control}
                register={register({ required: true })}
                name="dealTime"
                valueName="selected"
                onChange={([selected]) => {
                  return selected;
                }}
                rules={{
                  required: true,
                }}
              />
            </label>
          </div>
        </div>
        <div className="modal-form__row">
          <div className="modal-form__item">
            <label
              className="textarea textarea_type_form textarea_width_available"
              for="cl-comment"
            >
              <span className="textarea__label">Комментарий</span>
              <textarea
                className="textarea__control"
                id="cl-comment"
                name="comment"
                placeholder="Текст комментария"
                rows="5"
                ref={register}
              ></textarea>
            </label>
          </div>
        </div>
        <div className="modal-form__row">
          <div className="modal-form__wrap">
            <div className="checkbox js-activator">
              <div className="checkbox__box">
                <input
                  className="checkbox__control"
                  type="checkbox"
                  id="notify-time"
                  name="notify"
                  ref={register}
                />
                <label className="checkbox__input" for="notify-time"></label>
                <div className="checkbox__marker"></div>
              </div>
              <label className="checkbox__label" for="notify-time">
                Включить напоминание
              </label>
            </div>
            <div className="select select_type_form">
              <label className="custom-select-label" for="modal-select-notify">
                Выбрать время напоминания
              </label>
              <Controller
                as={Dropdown}
                name="notifyHours"
                control={control}
                options={times}
                ref={register}
                disabled={!isNotify}
                onChange={([value]) => value}
              />
            </div>
          </div>
        </div>
        <div className="modal-form__submit">
          <button className="button button_view_default" type="submit">
            {edit ? `Сохранить` : `Создать задачу`}
          </button>
          {edit && (
            <button
              className="button button_view_outline"
              onClick={(e) => {
                e.preventDefault();
                media('isMobile') ? confirmMobileModal() : confirmModal();
              }}
            >
              Удалить задачу
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default TaskForm;
