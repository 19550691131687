import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import RadioDropdown from '../../core/RadioDropdown';

const AssignmentsBottomFilters = memo(props => {
  const {
    filter,
    clearFilterOnly,
    clearExcludeOnly,
    filterData,
    handleFilter,
    wrapperClass,
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>
    <SelectFilter
      {...createSelectProps("realtyType")}
      data={{
        title: "Тип договора",
        identity: "realtyType",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("decoration")}
      data={{
        title: "Отделка",
        identity: "decoration",
      }}
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      filter={filter.squareTotal ?? { min: "", max: "" }}
      data={{
        title: 'Площадь',
        minPlaceholder: 'S общ. от ',
        maxPlaceholder: 'S общ. до ',
        defaultValues: filterData.squareTotal?.range ?? null
      }}
      onChange={({ filter }) => handleFilter("squareTotal", filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareTotal"}
      items={filterData.squareTotal?.items}
      showPlaceholderOnLists
      debounced
      autoPosition
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      autoPosition
      filter={filter.squareKitchen ?? { min: "", max: "" }}
      data={{
        title: 'Площадь кухни',
        minPlaceholder: 'S кухни от ',
        maxPlaceholder: 'S кухни до ',
        defaultValues: filterData.squareKitchen?.range ?? null
      }}
      onChange={({ filter }) => handleFilter("squareKitchen", filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareKitchen"}
      items={filterData.squareKitchen?.items}
      showPlaceholderOnLists
      debounced
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("subways")}
      data={{
        title: "Метро",
        identity: "subways",
      }}
      wrapperClass={wrapperClass}
    />
    <FloorsFilter
      {...createSelectProps("floor")}
      filter={filter?.floor}
      data={{
        title: "Этаж",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.floor?.range ?? null,
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("availability")}
      data={{
        title: "До метро",
        identity: "availability",
      }}
      wrapperClass={wrapperClass}
    />
    {filterData.isApartments && (
      <RadioDropdown
        onChange={v => handleFilter("isApartments", v)}
        items={filterData.isApartments}
        value={filter.isApartments}
        data={{
          title: 'Апартаменты',
          identity: 'isApartments',
        }}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        wrapperClass={wrapperClass}
      />
    )}
    <SelectFilter
      {...createSelectProps("payment")}
      data={{
        title: "Способ оплаты",
        autocompleteTitle: "Например, сельская ипотека",
        identity: "payment",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("registrations")}
      data={{
        title: "Прописка",
        identity: "registrations",
      }}
      wrapperClass={wrapperClass}
    />
    {filterData.encumbrances && (
      <RadioDropdown
        onChange={v => handleFilter("encumbrances", v)}
        items={filterData.encumbrances}
        value={filter.encumbrances}
        data={{
          title: 'Обременения',
          identity: 'encumbrances',
        }}
        clearFilterOnly={clearFilterOnly}
        clearExcludeOnly={clearExcludeOnly}
        wrapperClass={wrapperClass}
      />
    )}
    <div className="form__row">
      <div className="index-search__show-booked" style={{ marginTop: 0 }}>
        <label className="switch" htmlFor="filter-booked">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="filter-booked"
              checked={filter.status?.includes?.("3")}
              onChange={e => handleFilter("status", "3")}
            />
            <div
              className="switch__input"
              htmlFor="filter-booked"
            ></div>
            <div className="switch__marker"></div>
          </div>
          <span className="switch__label">
            Забронированные
          </span>
        </label>
      </div>
    </div>
  </>
});

export default AssignmentsBottomFilters;