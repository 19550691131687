import _keys from 'lodash/keys';
import React, {cloneElement, forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { DebounceInput } from 'react-debounce-input';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signup, checkEmail, checkPhone } from '../../actions/register';
import { validateMail } from '../../utils';
import Dropdown from '../../components/Dropdown';
import { errorToast } from '../../components/toasts';

import BackBtn from '../../components/HomePage/BackBtn';
import SuccessBlock from '../../components/HomePage/SuccessBlock';

import { getFormWithUTM } from '../../utils/location';
import ym from 'react-yandex-metrika';
import {GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import SubmitButton from "../../components/SubmitButton";
import { debounce } from 'lodash';


const citiesList = [
  {
    id: 'spb',
    title: 'Санкт-Петербург',
  },
  {
    id: 'msk',
    title: 'Москва',
  },
  {
    id: 'other',
    title: 'Другой город',
  },
];


const ReCaptcha = ({ setRecaptchaValue, isSending }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [intervalId, setIntervalId] = useState(null);
  const handleReCaptchaVerifyRef = useRef();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('yourAction');
    token && setRecaptchaValue(token);
  }, [executeRecaptcha, setRecaptchaValue]);

  useEffect(() => {
    handleReCaptchaVerifyRef.current = handleReCaptchaVerify;
    handleReCaptchaVerifyRef.current();

    const id = setInterval(() => {
      handleReCaptchaVerifyRef.current();
    }, 90000);

    setIntervalId(id);
    return () => clearInterval(id);
  }, [handleReCaptchaVerify]);

  return (
    <>
    {
      isSending ?
      <SubmitButton
        className="hp__submit button button_view_default"
        type="button"
        isLoading={isSending}
      >
        <span>Отправить заявку</span>
      </SubmitButton> :
        <button
          className="hp__submit button button_view_default"
          onClick={() => handleReCaptchaVerifyRef.current()}
        >
          <span>Отправить заявку</span>
        </button>
    }
    </>

  );
};





const RegisterForm = (props) => {

  const [recaptchaValue, setRecaptchaValue] = useState('');
  const location = useLocation();
  const [isSending, setIsSending] = useState(false)

  const handleRecaptcha = useCallback((value) => {
    setRecaptchaValue(value);
  }, []);


  let { who } = useParams();

  const utms = useLocation().search;

  const whoTitle = {
    agency: `владелец агентства`,
    agent: `начинающий агент`,
    realtor: `риелтор`,
    builder: `застройщик`,
    // selfemployed: `самозанятый`,
    developer: `самозанятый`,
  }

  const checkCorrectWho = () => Object.values(whoTitle).indexOf(whoTitle[who]) > -1;

  if (!checkCorrectWho()) {
    who = 'realtor';
  }

  const { handleSubmit, register, errors, control, reset, clearError, setError, getValues, setValue } = useForm({
    defaultValues: {
      agree: true,
      // phone: '',
    },
  });


  useEffect(() => {
    const t = document.createElement('script');
    t.type = "text/javascript"
    t.async = !0
    t.src = "https://vk.com/js/api/openapi.js?168"
    t.onload = function () {
      window.VK.Retargeting.Init("VK-RTRG-1392517-49vFw")
      window.VK.Retargeting.Hit()
    }
    document.head.appendChild(t)
    return () => {
      document.head.removeChild(t);
    };
  }, []);
  const { signup, history, fetching, error } = props;
  const inviteCode = localStorage.getItem("inviteCode")
  const [cityName, setCity] = useState('spb');

  const cityNameTitle = {
    spb: `Санкт-Петербург`,
    msk: `Москва`,
    other: `Другой город`
  }

  const [successBlock, setSuccessBlock] = useState(false);

  // const [openSuccessModal, closeSuccessModal] = useModal(
  //   ({ in: open, onExited }) => (
  //     <Modal close={closeSuccessModal} classes="modal_small pass-recover">
  //       <h3 className="modal__title">Вы успешно зарегистрировались!</h3>
  //       <p className="modal__hint">
  //         После подтверждения Вашей заявки менеджером, Вы получите доступ к
  //         системе.
  //       </p>
  //     </Modal>
  //   ),
  // );

  const onSubmit = (values) => {
    setIsSending(true)
    let form = getFormWithUTM();

    let keys = _keys(values);
    keys.forEach((item) => {
      let identity = (item === 'email') ? 'login' : item;
      form.append(`${identity}`, values[item]);
    });

    if (cityName !== 'other') {
      form.append(`cityName`, cityValue().title);
    }
    if (inviteCode) {
      form.append(`inviteCode`, inviteCode);
    }

    if (who) {
      form.append(`type`, who);
    }
    form.append('recaptcha_response', recaptchaValue);

    signup(form).then(
      (data) => {
        reset();
        // openSuccessModal();

        if (window.location.host !== 'localhost:3000' && window.location.host !== 'dev2.panpartner.ru') {
          ym('reachGoal', 'reg_success');
        }


        setSuccessBlock(true);
        window.VK.Goal('complete_registration')
        setIsSending(false)
        // infoToast(<div>{data.message}</div>)
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );

  };


  const [emailUnavailable, setEmailUnavailable] = useState(false);
  const [phoneUnavailable, setPhoneUnavailable] = useState(false);

  const emailCheckHandler = (value) => {
    value ?
      checkEmail({ email: value }).then((resp) => {
        if (resp.exist === 'Y') {
          clearError("email")
          return setEmailUnavailable(true)
        }
        setEmailUnavailable(false);
      },
        ([error]) => {
          setError(
            "email",
            "notMatch",
            error?.message
          )
        }) : setEmailUnavailable(false);
  };

  const phoneCheckHandler = (value) => {
    if(value?.replace?.(/\D/gi, "")?.length < 11) {
      clearError("phone")
      return setPhoneUnavailable(false)
    }
    value ?
      checkPhone({ phone: value }).then((resp) => {
          resp.exist === 'Y' ? setPhoneUnavailable(true) : setPhoneUnavailable(false);
          clearError("phone")
        },
        ([error]) => {
          setError(
            "phone",
            "notMatch",
            error?.message
          )
        }

      ) : setPhoneUnavailable(false);
  };

  const cityValue = () => citiesList.find((item) => item.id === cityName);


  const PhoneInput = () => (
    <Controller
      as={
        <InputMask
          className={
            'input__control' +
            (errors.phone ? ' input__error' : '')
          }
        />
      }
      control={control}
      autoComplete="off"
      placeholder="Введите телефон"
      mask="+7 (999) 999-99-99"
      name="phone"
      inputRef={register({
        required: 'Введите телефон',
      })}
      defaultValue=""
      rules={{
        required: 'Введите телефон',
      }}
    />
  );

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 10) {
      return phoneNumber;
    }
    const formattedNumber = `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 8)}-${phoneNumber.slice(8, 10)}`;
    return formattedNumber;
  };
  const [phoneValue, setPhoneValue] = useState('')
  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    let sanitizedData = pasteData.replace(/[^\d]/g, '');
    if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('7') || sanitizedData.startsWith('+7')) {
      sanitizedData = sanitizedData.substring(2);
    }
    setValue('phone', formatPhoneNumber(sanitizedData));
    setPhoneValue(formatPhoneNumber(sanitizedData))
  };

  const CustomDebounceInput = forwardRef((props, ref) => (
    <DebounceInput
      {...props}
      element={(inputProps) =>
        cloneElement(
          <InputMask {...inputProps} />,
          { onPaste: handlePaste, ref }
        )
      }
    />
  ));




  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdurE4pAAAAAL4U54UxGy3_XxhSPMKJ4SEIgKvG">
      <div className="hp__page">

        <section className="hp__become-p hp__promo">



          <div className="hp__page-form">
            {
              who === 'developer' ? <h1 className="hp__h1 h1 text-center">Зарегистрироваться как застройщик</h1> :
                <h1 className="hp__h1 h1 text-center">Стать партнером</h1>
            }
            {/*<h1 className="hp__h1 h1 text-center">Стать партнером как {whoTitle[who]}</h1>*/}
            {
              !successBlock &&
              <>
                {/*<BackBtn utms={utms} />*/}

                <div className="hp__form-content">
                  <form
                    className="form"
                    name="sign-up"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="hp__form-item">
                      <label htmlFor="sign-up_name" className="hp__form-label text">Ваше имя</label>

                      <div
                        className="input input_width_available input_type_form"
                        htmlFor="sign-in_name"
                      >
                        <input
                          name="name"
                          placeholder="Ваше имя"
                          id="sign-up_name"
                          className={
                            'input__control' +
                            (errors.name ? ' input__error' : '')
                          }
                          ref={register({
                            required: 'Введите имя',
                          })}
                        />
                        {errors.name && (
                          <span className="input__error-label">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="hp__form-item">
                      <label htmlFor="sign-up_surname" className="hp__form-label text">Ваша фамилия</label>
                      <div
                        className="input input_width_available input_type_form"
                      >
                        <input
                          name="lastName"
                          placeholder="Ваша фамилия"
                          id="sign-up_surname"
                          className={
                            'input__control' +
                            (errors.lastName ? ' input__error' : '')
                          }
                          ref={register({
                            required: 'Введите фамилию',
                          })}
                        />
                        {errors.surname && (
                          <span className="input__error-label">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                    </div>


                    {who !== 'agent' && who !== 'selfemployed' &&

                      <div className="hp__form-item">
                        <label htmlFor="sign-up_agency" className="hp__form-label text">Название компании</label>
                        <div
                          className="input input_width_available input_type_form"
                        >
                          <input
                            name="company"
                            placeholder="Название компании"
                            id="sign-up_agency"
                            className={
                              'input__control' +
                              (errors.company ? ' input__error' : '')
                            }
                            ref={register({
                              required: 'Введите название компании',
                            })}
                          />
                          {errors.company && (
                            <span className="input__error-label">
                              {errors.company.message}
                            </span>
                          )}
                        </div>
                      </div>
                    }


                    <div className="hp__form-item">
                      <label htmlFor="sign-up_phone" className="hp__form-label text">Телефон</label>
                      <div
                        className="input input_width_available input_type_form"
                      >


                        {/*<Controller*/}
                        {/*  as={*/}
                        {/*    <CustomDebounceInput*/}
                        {/*      debounceTimeout={500}*/}
                        {/*    />*/}
                        {/*  }*/}
                        {/*  className={*/}
                        {/*    'input__control' +*/}
                        {/*    (errors.phone ? ' input__error' : '')*/}
                        {/*  }*/}
                        {/*  control={control}*/}
                        {/*  autoComplete="off"*/}
                        {/*  placeholder="Введите телефон"*/}
                        {/*  mask="+7 (999) 999-99-99"*/}
                        {/*  name="phone"*/}
                        {/*  inputRef={register({*/}
                        {/*    required: 'Введите телефон',*/}
                        {/*  })}*/}
                        {/*  defaultValue=""*/}
                        {/*  rules={{*/}
                        {/*    required: 'Введите телефон',*/}
                        {/*  }}*/}
                        {/*  onChange={([e]) => {*/}
                        {/*    phoneCheckHandler(e.target.value)*/}
                        {/*    return e.target.value*/}
                        {/*  }}*/}
                        {/*/>*/}

                        <InputMask
                          className={
                            'input__control' +
                            (errors.phone ? ' input__error' : '')
                          }
                          name="phone"
                          inputRef={register({
                            required: 'Введите телефон',
                          })}
                          placeholder='Введите телефон'
                          mask="+7 (999) 999-99-99"
                          autoComplete="off"
                          maskChar="_"
                          value={phoneValue}
                            onChange={(e) => {
                              phoneCheckHandler(e.target.value)
                              setValue(e.target.value, "phone")
                              setPhoneValue(e.target.value)
                              return e.target.value
                            }}
                          onPaste={handlePaste}
                        />




                        {errors.phone && (
                          <span className="input__error-label">
                            {errors.phone.message}
                          </span>
                        )}

                        {
                          phoneUnavailable && (
                            <span className="input__error-label">
                              {`Этот телефон уже используется`}
                            </span>
                          )
                        }
                      </div>
                    </div>
                    {
                      who !== 'builder' && who !== 'agent' && who !== 'selfemployed' && <div className="hp__form-item">
                        <label htmlFor="sign-up_email" className="hp__form-label text">E-mail</label>

                        <div
                          className="input input_width_available input_type_form"
                        >
                          <Controller
                            as={
                              <DebounceInput
                                element={InputMask}
                                debounceTimeout={500}
                              />
                            }
                            control={control}
                            autoComplete="off"
                            name="email"
                            id="sign-up_email"
                            placeholder="Ваш e-mail"
                            className={
                              'input__control' +
                              (errors.email ? ' input__error' : '')
                            }
                            inputRef={register({
                              required: 'Введите e-mail',
                            })}
                            defaultValue=""
                            rules={{
                              required: 'Введите e-mail',
                              validate: data=>{
                               if(!validateMail.test(data)) return 'Неправильный формат e-mail'
                               clearError("email")
                               return true
                              }
                            }}
                            // value={value}
                            onChange={([e]) => {
                              emailCheckHandler(e.target.value)
                              return e.target.value
                            }}

                          />


                          {errors.email && (
                            <span className="input__error-label">
                              {errors.email.message}
                            </span>
                          )}

                          {
                            emailUnavailable && (
                              <span className="input__error-label">
                                {`Этот email уже используется`}
                              </span>
                            )
                          }
                        </div>
                      </div>
                    }

                    <div className="hp__form-item">
                      <label htmlFor="sign-up_cityName" className="hp__form-label text">Ваш город</label>
                      <div className="select select_type_form">
                        <Dropdown
                          value={cityValue()}
                          options={citiesList}
                          onChange={(value) => setCity(value)}
                        />
                      </div>
                    </div>
                    {cityName === 'other' && (
                      <div className="hp__form-item">
                        <label
                          className="input input_width_available input_type_form"
                          htmlFor="sign-up_cityName"
                        >
                          <input
                            name="cityName"
                            placeholder="Название города"
                            id="sign-up_cityName"
                            className={
                              'input__control' +
                              (errors.company ? ' input__error' : '')
                            }
                            ref={register({
                              required: 'Введите название города',
                            })}
                          />
                          {errors.cityName && (
                            <span className="input__error-label">
                              {errors.cityName.message}
                            </span>
                          )}
                        </label>
                      </div>
                    )}

                    <div className="hp__form-item">
                      <div className="checkbox checkbox_view_red checkbox_view_flex-start">
                        <div
                          className={
                            'checkbox__box' +
                            (errors.agree ? ' checkbox__box-error' : '')
                          }
                        >
                          <input
                            className="checkbox__control"
                            type="checkbox"
                            id="modal-reservation-agreement"
                            name="agree"
                            ref={register({
                              required:
                                'Необходимо согласиться с правилами обработки данных',
                            })}
                          />
                          <label
                            className="checkbox__input"
                            htmlFor="modal-reservation-agreement"
                          ></label>
                          <div className="checkbox__marker"></div>
                        </div>
                        <label
                          className="checkbox__label"
                          htmlFor="modal-reservation-agreement"
                        >
                          Я принимаю условия <Link to="/policy" className="link link_color_brand-3" target="_blank">
                          пользовательского соглашения
                        </Link>
                        </label>
                      </div>
                      {errors.agree && (
                        <span className="input__error-label">
                          {errors.agree.message}
                        </span>
                      )}
                    </div>

                    <div className="hp__form-item">


                      <ReCaptcha setRecaptchaValue={setRecaptchaValue} isSending={isSending}/>

                      {/*<button className="hp__submit button button_view_default" onClick={handleRecaptcha} disabled={phoneUnavailable}>*/}
                      {/*  <span>Отправить заявку</span>*/}
                      {/*</button>*/}

                    </div>
                    <div className="sign-in__row">
                      <div className="sign-in__footer">
                        Уже зарегистрированы?{' '}
                        <Link to={`/login${window.location.search}`}>
                          <span className="sign-in__sign-up link-hover">
                            Войти
                          </span>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            }
            {
              successBlock && <SuccessBlock />
            }
          </div>




        </section>
      </div>
    </GoogleReCaptchaProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signup: (form) => dispatch(signup(form)),
});

const mapStateToProps = (state) => ({
  fetching: state.user.fetching,
  error: state.user.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
