import React, { memo } from 'react';
import EndingsFilter from "../../FilterRealty/EndingsFilter";
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import { SuburbanGeneralFilterIcon } from "./icons";

const VillagesGeneralFilter = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass,
    clearExcludeOnly,
    clearFilterOnly,
    hideTitle
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    {!hideTitle && <div className="filter-title">
      <SuburbanGeneralFilterIcon />
      Общее
    </div>}
    <SelectFilter
      autoPosition
      {...createSelectProps("paymentType")}
      data={{
        title: 'Способы оплаты',
        identity: 'paymentType',
      }}
      wrapperClass={wrapperClass}
    />
    <EndingsFilter
      autoPosition
      ident={"endingDate"}
      filter={filter.endingDate ?? { min: "", max: "" }}
      items={filterData.endingDate ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: null,
      }}
      onChange={v => setFilterAndExclude(v, "endingDate")}
      wrapperClass={wrapperClass}
      secondData={{
        title: "Сдан",
        value: filter.isHanded ?? "N"
      }}
      secondChange={v => handleFilter("isHanded", v === "Y" && v)}
    />
  </>
});

export default VillagesGeneralFilter;