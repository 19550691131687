import { useMediaQuery } from "beautiful-react-hooks";
import dateFormat from "dateformat";
import formatter from "format-number";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import minBy from "lodash/minBy";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import usePermission from "../../hooks/usePermission";
import { media, scrollToRef } from "../../utils";
import AutopanTrigger from '../AutopanTrigger/AutopanTrigger.js';
import PopoverBlock from "../Cards/PopoverBlock";
import Photos from "./Photos";
import {useSelector} from "react-redux";
import {MapIconCustom} from "../Public/markup/icons";

import map_point from '../../assets/images/mapCustomPoint.svg';


const updateFormat = `dd.mm.yyyy в HH:MM`;

const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);

const SubwayList = (props) => {
  const { items } = props;

  const routeType = (type) => {
    if(!type){
      return ""
    }
    const way = type === "byTransport" ? "auto" : "pedestrian";
    return (
      <span className="complex__route-type">
        <svg className={`icon icon_${way}`}>
          <use xlinkHref={`#${way}`}></use>
        </svg>
      </span>
    );
  };

  return (
    <div className="complex__info-routes">
      <ul className="complex__routes">
        {items.map((subway) => (
          <li className="complex__routes-item" key={subway.id}>
            <span
              className="complex__metro-color"
              style={{ backgroundColor: subway.color }}
            ></span>
            <span className="complex__metro-station">{subway.name}</span>
            {routeType(subway.distanceType)}
            {subway.minutes && (
              <span className="complex__route-time">{subway.minutes} мин.</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

// const IconSvg = () => (
//   <svg
//     width="18"
//     height="22"
//     viewBox="0 0 18 22"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       fill={'#2C2E3E'}
//       fill-rule="evenodd"
//       clip-rule="evenodd"
//       d="M9.00003 21.59C9.00003 21.59 17.0652 16.2132 17.0652 9.0441C17.0652 4.5898 13.4543 0.978882 9.00003 0.978882C4.54573 0.978882 0.934814 4.5898 0.934814 9.0441C0.934814 16.2132 9.00003 21.59 9.00003 21.59ZM9.00004 11.7326C10.4848 11.7326 11.6884 10.5289 11.6884 9.04415C11.6884 7.55938 10.4848 6.35574 9.00004 6.35574C7.51528 6.35574 6.31164 7.55938 6.31164 9.04415C6.31164 10.5289 7.51528 11.7326 9.00004 11.7326Z"
//     />
//   </svg>
// );




const Block = (props) => {
  const {
    block,
    handleFavorites,
    isFavorite,
    navigation,
    isClientMode,
    clients,
    addClientToFavorite,
    removeClientFromFavorite,
    fetchClients,
    isPublic,
    haveApartments,
    savedFilterXmlId,
    pricesRef,
    aboutRef,
    chartRef,
    mortgageRef,
    newsRef,
    otherRef,
    banksRef,
    promoRef
  } = props;




  const [activeNav, setActiveNav] = useState(null);
  const menuHeight = 165;
  const handleScroll = (e) => {
    let refs = compact([
      pricesRef?.current && {
        name: "prices",
        bottom: pricesRef.current.getBoundingClientRect().bottom,
      },
      aboutRef?.current && {
        name: "about",
        bottom: aboutRef.current.getBoundingClientRect().bottom,
      },
      chartRef?.current && {
        name: "chart",
        bottom: chartRef.current.getBoundingClientRect().bottom,
      },
      mortgageRef?.current && {
        name: "mortgage",
        bottom: mortgageRef.current.getBoundingClientRect().bottom,
      },
      newsRef?.current && {
        name: "news",
        bottom: newsRef.current.getBoundingClientRect().bottom,
      },
      otherRef?.current && {
        name: "other",
        bottom: otherRef.current.getBoundingClientRect().bottom,
      },
      banksRef?.current && {
        name: "banks",
        bottom: banksRef.current.getBoundingClientRect().bottom,
      },
      promoRef?.current && {
        name: "promo",
        bottom: promoRef.current.getBoundingClientRect().bottom,
      },
    ]);
    refs = minBy(refs, (ref) => {
      if (ref.bottom - menuHeight > 0) {
        return ref.bottom;
      }
    });
    setActiveNav(refs?.name);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      localStorage.removeItem("blocksFilter")
    };
  }, []);



  const [initMap, setInitMap] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const modalMap = useRef(null);
  const middleScreen = useMediaQuery('(max-width: 1599px)');
  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };
 const permission = usePermission("motivation")
  useEffect(() => {
    if (!modalMap) return;
    dynamicMapImport()
    if (showMap) {
      modalMap.current.classList.add("is-opened");
      document.body.classList.add("no-scroll");
    } else {
      modalMap.current.classList.remove("is-opened");
      document.body.classList.remove("no-scroll");
    }
  }, [showMap]);
  const [Maps, setMaps] = useState(null)
  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({YMaps, Map, Placemark, RulerControl})
  };
  const typesList = (types) => {
    return types.map((item) => item.name).join(", ");
  };

  const endingsList = (endings) => {
    if (!endings || !endings.from) {
      return "";
    }
    let value = `${endings.from.quarter} кв. ${endings.from.year}`;
    if (!isEqual(endings.from, endings.to)) {
      value += ` - ${endings.to.quarter} кв. ${endings.to.year}`;
    }
    return value;
  };

  const navRef = useRef(null);

  const fixComplexNav = () => {
    const navigation = document.querySelector(".navigation__block");

    if (navigation) {
      let fixPosition;
      // Пока слайдеры грузятся, отступ элемента недействителен
      setTimeout(() => {
        fixPosition = navigation.offsetTop + 100; // getCoords(navigation);
      }, 500);
      const getCSS = () => {
        if(isPublic){
          return `top: -80px;`
        }
        if (isClientMode){
          return `top: -10px;`
        }
        return `top: ${middleScreen ? -10 : 0}px;`
      }

      const fix = (e) => {
        const isFixed = navigation.classList.contains("nav_fixed");
        const pageYOffset = window.scrollY;
        if (pageYOffset >= fixPosition && !isFixed) {
          navigation.classList.add("nav_fixed");
          let css = getCSS()
          navigation.style.cssText = css;
          setIsFixed(true);
        } else if (pageYOffset < fixPosition && isFixed) {
          navigation.classList.remove("nav_fixed");
          setIsFixed(false);
          navigation.style.cssText = "";
        }
      };

      let timer = false;
      document.addEventListener("scroll", (event) => {
        if (!timer)
          timer = setTimeout(() => {
            fix(event);
            timer = false;
          }, 200);
      });
    }
  };

  useEffect(() => {
    fixComplexNav();
  }, []);

  const favButton = (
    <div className="complex__actions-item is-fav" style={{width: 104}}>
      <div
        className={`add-fav-btn dark ${
          block.favorite?.inFavorite ? "is-fav" : ""
        }`}
        onClick={() =>
          handleFavorites(block.favorite?.inFavorite)
        }
      >
        <button className="button button_type_settings">
          <svg className="icon icon_settings">
            <use xlinkHref="#fav"></use>
          </svg>
        </button>
        {
          //!media("isMobile") && (block.favorite.inFavorite ? "В избранном" : "В избранное")
        }
        <PopoverBlock
          type="block"
          id={block.id}
          favorite={block.favorite}
          city={block.city == 3 ? "msk" : "spb"}
          allClients={clients}
          addClientToFavorite={addClientToFavorite}
          removeClientFromFavorite={removeClientFromFavorite}
          fetchClients={fetchClients}
          whatPage={"complexMain"}
          showOnLeft={true}
        />
      </div>
    </div>
  )

  const savedFilterSettings = useSelector(state => state.complex.savedFilterSettings ?? {})
  const svgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
    <path fill="{color}" fill-rule="evenodd" clip-rule="evenodd" d="M9.00003 21.59C9.00003 21.59 17.0652 16.2132 17.0652 9.0441C17.0652 4.5898 13.4543 0.978882 9.00003 0.978882C4.54573 0.978882 0.934814 4.5898 0.934814 9.0441C0.934814 16.2132 9.00003 21.59 9.00003 21.59ZM9.00004 11.7326C10.4848 11.7326 11.6884 10.5289 11.6884 9.04415C11.6884 7.55938 10.4848 6.35574 9.00004 6.35574C7.51528 6.35574 6.31164 7.55938 6.31164 9.04415C6.31164 10.5289 7.51528 11.7326 9.00004 11.7326Z"/>
  </svg>
`.replace(/\{color\}/g, savedFilterSettings?.colorButton);

  const iconLayout = 'default#image';
  const iconImageHref = `data:image/svg+xml;utf8,${encodeURIComponent(svgIcon)}`;

  return (
    <div>
      <section className="section section__block">
        <div className="wrapper">
          <div className="complex__block">
            <div className="complex__block-row">
              <div className="complex__block-col info__col info__col-top">
                {!media("isMobile") && block.commission && !isClientMode && !isPublic && permission &&(
                  <ul className="complex__badges">
                    <li className="complex__badges-item">
                      <div className="badge complex__badge-badge">
                        Комиссия: {block.commission.join(", ")}
                      </div>
                    </li>
                  </ul>
                )}
                {!isPublic && (
                  <div className="complex__info-update">
                    {`Обновлено ${dateFormat(
                      block.dateUpdate,
                      updateFormat,
                      "isoTime",
                    )} `}
                  </div>
                )}
              </div>
              {!isPublic && (
                <div className="complex__block-col text__col sm-hide">
                  <div
                    className="complex__address"
                    style={{ alignItems: "center" }}
                  >
                    <div className="complex__address-value">
                      {block.address}
                    </div>
                    <div className="complex__address-map">
                      <span
                        className="show__map"
                        onClick={() => setShowMap(true)}
                      >
                        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 17.0333C6 17.0333 11.5 10.7808 11.5 5.89129C11.5 2.9135 9.03747 0.5 6 0.5C2.96217 0.5 0.5 2.9135 0.5 5.89129C0.5 10.8973 6 17.0333 6 17.0333ZM8.5 6.18331C8.5 4.75688 7.38042 3.59998 6 3.59998C4.61958 3.59998 3.5 4.75688 3.5 6.18331C3.5 7.60974 4.61958 8.76665 6 8.76665C7.38042 8.76665 8.5 7.60974 8.5 6.18331Z" fill="#FF2D23"/>
                        </svg>

                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="complex__info-main mobile sm-show">
              {!media("isMobile") &&
                <div className="complex__address">
                  <div className="complex__address-value">{block.address}</div>
                  <div className="complex__address-map">
                    <div
                      className="link show__map-mobile"
                      onClick={() => setShowMap(true)}
                    >
                      {!media("isMobile") &&
                        <Fragment>
                          {/*<svg className="icon icon_map-pin">*/}
                          {/*  <use xlinkHref="#test"></use>*/}
                          {/*</svg>*/}
                          <MapIconCustom color={savedFilterSettings?.colorButton}/>
                          <span style={{color: savedFilterSettings?.colorButton, marginLeft: 10, marginTop: 2}}>Показать на карте</span>
                        </Fragment>
                      }
                    </div>
                  </div>
                </div>
              }
              <div className="complex__main">
                <h1 className={`${!media('isMobile') ? 'h2' : ''} complex__title`}>
                  <span>{`ЖК «${block.name}»`}</span>
                  {block.isAutoPan && !savedFilterXmlId && <AutopanTrigger />}
                </h1>
                {!media('isMobile') && block.subway && block.subway.length > 0 && (
                  <SubwayList items={block.subway} />
                )}
              </div>

              {media("isMobile") && block.commission && !isClientMode && !isPublic && permission &&(
                <ul className="complex__badges">
                  <li className="complex__badges-item">
                    <div className="badge complex__badge-badge">
                      Комиссия: {block.commission.join(", ")}
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div className="complex__block-row">
              <div className="complex__block-col info__col">
                {<Photos photos={block.photos} tour={block?.builder?.typical3DTour} />}
              </div>
              {media("isMobile") &&
                <div className="complex__mobile-address">
                  <div className="complex__address">
                    <div>
                      <div className="complex__address-value">{block.address}</div>
                      {block.subway && block.subway.length > 0 && (
                        <SubwayList items={block.subway} />
                      )}
                    </div>
                    <div className="complex__address-map">
                      <div
                        className="link show__map-mobile"
                        onClick={() => setShowMap(true)}
                      >
                        {!media("isMobile") &&
                          <Fragment>
                            <svg className="icon icon_map-pin">
                              <use xlinkHref="#test"></use>
                            </svg>
                            Показать на карте
                          </Fragment>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="complex__block-col text__col">
                <div className="complex__info-main sm-hide">
                  <div className="complex__main">
                    {isPublic && (
                      <div
                        className="complex__block-col info__col-top"
                        style={{ margin: 0 }}
                      >
                        <div className="complex__address-value">
                          {block.address}
                        </div>
                        <div className="complex__address-map">
                          <span
                            className="show__map"
                            onClick={() => setShowMap(true)}
                          >
                            <MapIconCustom color={savedFilterSettings?.colorButton}/>
                            {/*<svg className="icon icon_map-pin">*/}
                            {/*  <use xlinkHref="#test"></use>*/}
                            {/*</svg>*/}
                          </span>
                        </div>
                      </div>
                    )}
                    <h1 className='h2 complex__title'>
                      <span>{`ЖК «${block.name}»`}</span>
                      {block.isAutoPan && !savedFilterXmlId && <AutopanTrigger />}
                    </h1>

                    {block.subway && block.subway.length > 0 && (
                      <SubwayList items={block.subway} />
                    )}
                  </div>
                </div>
                <div className="complex__bottom">
                  <div className="complex__border">
                    {haveApartments && (
                      <div className="complex__price">
                        <div className="complex__price-row">
                          <div className="complex__price-col complex__price-left">
                            <div className="complex__price-value">{`от ${priceFormat(
                              block.apartmentsInfo?.minPrice,
                            )}`}</div>
                            <div className="complex__info-item-desc">
                              Цена при 100% оплате
                            </div>
                          </div>
                          <div className="complex__price-col complex__price-right">
                            <div className="complex__price-value">
                              {block.apartmentsInfo.count}
                            </div>
                            <div className="complex__info-item-desc">
                              Квартир в продаже
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {media("isMobile") && favButton}
                  </div>
                  <div
                    className={
                      "complex__additional" + (isPublic ? " no-border" : "")
                    }
                  >
                    <div className="complex__additional-row">
                      <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {block.builder.name}
                        </div>
                        <div className="complex__info-item-desc">
                          Застройщик
                        </div>
                      </div>
                      <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {endingsList(block.endingPeriod)}
                        </div>
                        <div className="complex__info-item-desc">
                          Срок сдачи
                        </div>
                      </div>
                      <div className="complex__additional-col complex__additional-item">
                        <div className="complex__info-item-value">
                          {block.blockClass.name}
                        </div>
                        <div className="complex__info-item-desc">
                          Класс жилья
                        </div>
                      </div>
                      {block.decorationTypes &&
                        block.decorationTypes.length > 0 && (
                          <div className="complex__additional-col complex__additional-item">
                            <div className="complex__info-item-value">
                              {typesList(block.decorationTypes)}
                            </div>
                            <div className="complex__info-item-desc">
                              Отделка
                            </div>
                          </div>
                        )}
                      {block.buildingTypes && block.buildingTypes.length > 0 && (
                        <div className="complex__additional-col complex__additional-item">
                          <div className="complex__info-item-value">
                            {typesList(block.buildingTypes)}
                          </div>
                          <div className="complex__info-item-desc">
                            Тип дома
                          </div>
                        </div>
                      )}
                      {
                        block.registration.name && block.registration.name.length > 0 && (
                        <div className="complex__additional-col complex__additional-item">
                          <div className="complex__info-item-value">
                            {block.registration.name}
                          </div>
                          <div className="complex__info-item-desc">Прописка</div>
                        </div>
                        ) 
                      }

                      {block.paymentTypes && block.paymentTypes.length > 0 && (
                        <div className="complex__additional-col complex__additional-full">
                          <div className="complex__info-item-value">
                            {typesList(block.paymentTypes)}
                          </div>
                          <div className="complex__info-item-desc">Оплата</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isPublic && haveApartments && (
                  <div className="complex__actions">
                    {Boolean(block.files) && (
                      <div className="complex__actions-item">
                        <Link to={`/files/${block.files}`} target="_blank">
                          <div className="button button_view_outline-invert button__files">
                            Файлы
                          </div>
                        </Link>
                      </div>
                    )}
                    {block.promo && (
                      <div className="complex__actions-item complex__actions-item_basis_0">
                        <Link to={`/files/${block.promo}`} target="_blank">
                          Рекламные материалы
                        </Link>
                      </div>
                    )}
                    {block?.regulations > 0 && (
                      <div className="complex__actions-item complex__actions-item_basis_0">
                        <Link to={`/files/${block.regulations}`} target="_blank">
                          Регламент взаимодействия
                        </Link>
                      </div>
                    )}
                    {!media("isMobile") && favButton}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!media("isMobile") &&
          <div className="navigation__block">
            <div className="wrapper">
              <div className="navigation__block-inner">
                <ul className="complex__navigation">
                  {navigation.map((nav) => (
                    <li key={nav.title}>
                      <span
                        className={`complex__navigation-item ${
                          activeNav === nav.type && isFixed ? "active" : ""
                        }`}
                        onClick={() => scrollToRef(nav.ref, 70)}
                      >
                        {nav.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        }
      </section>
      <div className="modal" ref={modalMap}>
        <div className="modal__shadow " data-id="dist-map"></div>
        <div className="modal__content">
          <div className="modal__inner">
            <div
              className="modal__close "
              data-id="dist-map"
              onClick={() => setShowMap(false)}
            >
              <svg className="icon">
                <use xlinkHref="#x"></use>
              </svg>
            </div>
            <h3 className="modal__title">{`ЖК «${block.name}» на карте`}</h3>
            <div className="dist-map" id="dist-map-container">
              {initMap || showMap ? (
                <Maps.YMaps>
                  <Maps.Map
                    defaultState={{
                      center: block.coordinates,
                      zoom: 16,
                    }}
                    className="complex__map-wrap"
                    onLoad={mapLoad}
                  >
                    <Maps.Placemark
                      geometry={block.coordinates}
                      options={{
                        iconLayout,
                        iconImageHref,
                        iconImageSize: [25, 40],
                        iconImageOffset: [-25, -20],
                      }}
                    />
                    <Maps.RulerControl
                      options={{ position: { top: 10, left: 10 } }}
                    />
                  </Maps.Map>
                </Maps.YMaps>
              ) : (
                <div className="dist-map" id="dist-map-container" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block;


