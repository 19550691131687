import { useMediaQuery } from "beautiful-react-hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TinySlider from "tiny-slider-react";
import { getUrl } from '../../../api';
import useMedia from "../../../hooks/useMedia";
import ServiceIcon from "./ServiceIcon.js";

const Service = (props) => {
  const { item, index, itemHeight, isMobile, theme } = props;

  return (
    <div className={`services__item ${isMobile ? '' : 'block'}`} style={{ height: itemHeight - 50 }}>
      <div className="services__item-inner">
        <ServiceIcon id={item.fakeId + 1} color={theme.colorHash}  />
        {/* {item?.icon?.src &&  <object data={getUrl(item?.icon?.src)} type="image/svg+xml" className="block__img"></object> } */}
        <h4 className="h4 block__title">{item.title}</h4>
        <p className="text block__text"
          dangerouslySetInnerHTML={{
            __html: item.text?.replace(/\n/g, "<br />"),
          }}
        >
        </p>
      </div>
    </div>
  )
};

const Services = (props) => {
  const { services, openCallbackModal, innerRef, theme } = props
  const isMobile = useMedia("isMobile")
  const [index, setIndex] = useState(1);
  const node = useRef(null);
  const navRef = useRef(null);

  const handleChangeIndex = (info) => {
    setIndex(info.displayIndex);
  };

  const settings = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          gutter: 0,
          nav: !isMobile ? false : true,
          controls: false,
          center: true,
          loop: isMobile ? false : true,
          "mouseDrag": true,
          navAsThumbnails: isMobile && false,
          navContainer: isMobile ? `#complex-slider-thumbnails` : false,
          responsive: {
            768: {
              items: 2,
              gutter: 20,
            },
            1600: {
              items: 3,
            },
          },
        }
        : {},
    [navRef]
  );
  const len = services.length
  const showControls = (useMediaQuery('(max-width: 1600px)') && len > 2) || len > 3;
  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const [sliderHeight, setSliderHeight] = useState(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    document.onreadystatechange = () => {
      if (sliderRef.current) {
        setSliderHeight(sliderRef.current.clientHeight);
      }
    };
  })

  return (
    <section className="services constructor__section" ref={innerRef} id="services">
      <div className="constructor__container">

        <div className="constructor__wrapper sectives__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title services__title">
              Услуги
            </h2>
          </div>
          <div className="services__list constructor__box">

            <div className="constructor__slider" style={{ width: "100%" }}>
              <div className="constructor__slider-wrap" ref={sliderRef}>
                <TinySlider settings={settings} ref={node} onIndexChanged={handleChangeIndex}>
                  {services.map((item, index) => <Service item={item} index={index} key={`service-i-${index}`} itemHeight={sliderHeight} isMobile={isMobile} theme={theme} />)}
                </TinySlider>

                {isMobile &&
                  <>
                    <ul
                      className="complex__slider-thumbnails for-constructor"
                      id="complex-slider-thumbnails"
                      ref={navRef}
                    >
                      {services.map((item, index) => (
                        <li className="complex__slider-thumbnails_item" key={`services-thumb-${index}`}>
                          <span aria-controls="base"></span>
                        </li>
                      ))}
                    </ul>

                    <span className="complex__slider-info for-contstructor">
                      {index} / {services.length}
                    </span>
                  </>
                }
              </div>
            </div>

          </div>
          {!isMobile && showControls &&
            <div className="slider-nav">
              <ul className="slider-nav__wrapper">
                <li
                  className="slider-nav__item prev"
                  onClick={() => moveSlider("prev")}
                >
                  <svg className="icon icon-dark_stroke">
                    <use xlinkHref="#icon-left_arrow"></use>
                  </svg>
                </li>
                <li
                  className="slider-nav__item next"
                  onClick={() => moveSlider("next")}
                >
                  <svg className="icon icon-dark_stroke">
                    <use xlinkHref="#icon-right_arrow"></use>
                  </svg>
                </li>
              </ul>
            </div>
          }

          <button className="button constructor__btn services__btn" type="button" onClick={openCallbackModal}>
            Получить консультацию
          </button>
        </div>

      </div>
    </section>
  )
};

export default Services;
