import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { clearAssignment, fetchAssignment } from "../../actions/assignment";
import { fetchClients } from "../../actions/clients";
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from "../../actions/favorites";
import Banks from "../Apartment/Banks";
import MortgageBlock from "../Apartment/MortgageBlock";
import Preloader from "../Preloader";
import AssignationAbout from "./AssignationAbout";
import AssignationInfo from "./AssignationInfo";
import Sliders from "./Sliders";


const View = (props) => {
  const { id } = useParams();
  const city = "spb";

  const {
    fetch,
    reset,
    isFetching,
    data,
    manager,
    banks,
    error,
    subways,
    clientMode,
    addToFavorites,
    addClientToFavorite,
    removeClientFromFavorite,
    removeFromFavorites,
    clients,
    fetchClients,
  } = props;


  useEffect(() => {
    if(!data) return
    const blockName = " в ЖК «" + data.block?.name + "»"
    document.title = `${data.roomType}, ${data.squareTotal} м², № ${data.number}${blockName}`;
  }, [data]);
  
  useEffect(() => {
    let isCount = window.location.search.includes("fromTop")
    fetch(id, city, null, isCount);
    fetchClients();
    return () => {
      reset();
    };
  }, [id]);


  if (error) return "Error";
  const handleFavorites = (inFavorite) => {
    inFavorite
      ? removeFromFavorites("spb", id, "assignment", "assignment")
      : addToFavorites("spb", id, "assignment", "assignment");
  };

  if(isFetching || !data) return <Preloader loading={true} />
  return (
    <>

      {
        // всё кроме калькулятора
      }
      {data && (
        <AssignationInfo
          data={data}
          manager={manager}
          subwaysList={subways}
          city={city}
          addToFavorites={addToFavorites}
          clientMode={clientMode}
          addClientToFavorite={addClientToFavorite}
          removeClientFromFavorite={removeClientFromFavorite}
          handleFavorites={handleFavorites}
          clients={clients}
          fetchClients={fetchClients}
          // isAgent={props.isAgent}
          isAgent={false}
          presentationType={"apartments_for_assignment"}
        />
      )}

      {
        // Описание
      }
      {
        !!data.block?.about?.previewText && <AssignationAbout previewText={data.block?.about?.previewText} title={data.blockName} adv={data.block.about.advantages} />
      }


      {
      !!data && (
        <Sliders data={data} />
      )
      }


      {
        // Калькулятор
      }
      {data && (
        <MortgageBlock
          price={Number(data.costWithDiscount)}
          city={city}
          type="assignment"
          id={id}
          period={15}
          prepay={Number(data.costWithDiscount) * 0.2}
          percent={6.5}
          showInstallment={false}
        />
      )}
      {!!data.block?.banks?.length &&
         <Banks items={data.block.banks} type={'assignment'} data={{block: data?.block, city: data?.city}} /> 
      }

      {/*
      <AssignationInfrastructure />
      <AssignationMortgage />
    <AssignationBanks />*/}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (id, city, savedFilterXmlId, isCount) => dispatch(fetchAssignment(id, city, savedFilterXmlId, isCount)),
  reset: () => dispatch(clearAssignment()),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, whatPage)),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type)
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type
  ) =>
    dispatch(
      removeClientFromFavorite(id, clientId, client, favoriteId, whatPage, type)
    ),
  fetchClients: () => dispatch(fetchClients()),
});

const mapStateToProps = (state) => ({
  data: state.assignment.data,
  isFetching: state.assignment.isFetching,
  error: state.assignment.error,
  manager: state.assignment.manager,
  banks: state.assignment.banks,
  subways: state.assignment.subways,
  clientMode: state.general.clientMode,
  clients: state.clients.data,
  isAgent: state.user.info.groups.some((group) => group === "agent") && state.user.info.groups.lenght === 1,
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
