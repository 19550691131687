import { handleFavorite } from './handlers';

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  // активность сохр. фильтра по ссылкам /public/*
  isActive: true,
  agent: null,
  filterData: {},
  banks: [],
  news: [],
  actions: [],
  otherBlocks: [],
  apartments: [],
  count: "initial",
};


const suburbanLand = (state = initialState, dirtyAction) => {
  const {
    type,
  } = dirtyAction;
  const action = { ...dirtyAction }
  delete action.type
  switch (type) {
    case "GET_LAND_SUCCESS":
      return {
        ...state,
        ...action
      };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'land') {
        return { ...state, suburban: handleFavorite(state.suburban, dirtyAction, true) }
      }
      return { ...state }
    default:
      return state;
  }
};

export default suburbanLand;
