import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { media } from '../../utils';
import { errorToast, infoToast } from '../toasts';

const Form = (props) => {
  const { editPassword } = props;

  const [error] = useState(false);
  const [message] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);

  const { handleSubmit, watch, register, reset, errors } = useForm();

  const onSubmit = (values, e) => {
    editPassword(values).then(
      data => {
        reset();
        infoToast(<div>{data.message}</div>)
      },
        error => errorToast(<div>{error?.[0]?.message}</div>)
    );
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: media('isMobile') ? '' : '80px' }}>
      {error && (
        <div
          className="form__error-text"
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
      )}
      <div className="lk-form__row _bg-gray profile__password-form">
        <div className="lk-form__row-title">Изменить пароль</div>
        <div className="lk-form__row-content" style={{paddingBottom: '50px'}}>
          <div style={{height: '91px'}} className="lk-form__fr lk-form__fr_auto">
            <label
              className="input input_type_form input_type_form_icon-right input_width_available js-form-password"
              htmlFor="lk-form-pass"
            >
              <span className="input__label">Введите новый пароль:</span>
              <input
                type={showPass ? 'text' : 'password'}
                id="lk-form-pass"
                autoComplete="off"
                name="password"
                className={
                  'input__control' + (errors.password ? ' input__error' : '')
                }
                ref={register({
                  required: 'Введите пароль',
                })}
              />

              <div
                className="lk-form__password input__icon"
                onClick={() => setShowPass(!showPass)}
              >
                {showPass && (
                  <span className="lk-form__password-visible">
                    <svg className="icon icon_watch">
                      <use xlinkHref="#input-hide"></use>
                    </svg>
                  </span>
                )}
                {!showPass && (
                  <span className="lk-form__password-hidden">
                    <svg className="icon icon_watch">
                      <use xlinkHref="#icon_watch"></use>
                    </svg>
                  </span>
                )}
              </div>
            </label>
            {errors.password && (
              <span className="input__error-label">
                {errors.password.message}
              </span>
            )}
          </div>
          <div style={{height: '91px'}} className="lk-form__fr lk-form__fr_auto">
            <label
              className="input input_type_form input_type_form_icon-right input_width_available js-form-password"
              htmlFor="lk-form-passRepeat"
            >
              <span className="input__label">Повторите пароль:</span>
              <input
                className="input__control"
                type={showRepeat ? 'text' : 'password'}
                id="lk-form-passRepeat"
                autoComplete="off"
                name="confirmPassword"
                className={
                  'input__control' +
                  (errors.confirmPassword ? ' input__error' : '')
                }
                ref={register({
                  required: 'Подтвердите пароль',
                  validate: (value) =>
                    value === watch('password') || 'Пароли не совпадают',
                })}
              />
              <div
                className="lk-form__password input__icon"
                onClick={() => setShowRepeat(!showRepeat)}
              >
                {showRepeat && (
                  <span className="lk-form__password-visible">
                    <svg className="icon icon_watch">
                      <use xlinkHref="#input-hide"></use>
                    </svg>
                  </span>
                )}
                {!showRepeat && (
                  <span className="lk-form__password-hidden">
                    <svg className="icon icon_watch">
                      <use xlinkHref="#icon_watch"></use>
                    </svg>
                  </span>
                )}
              </div>
            </label>
            {errors.confirmPassword && (
              <span className="input__error-label">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
          <div style={{height: '91px'}} className="lk-form__fr lk-form__fr_auto">
            <div className="profile__password-form--submit">
              <button style={{width: '100%'}} className="button button_view_default" type="submit">
                <span>Изменить пароль</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
