import { useMediaQuery } from 'beautiful-react-hooks';
import _filter from 'lodash/filter';
import React from 'react';
import { connect } from 'react-redux';
import {
  filterToParams,
  isEmptyFilterOrExclude
} from '../../utils';
import { defaultFullFilterSecondaries } from '../../utils/defaultFilters';
import Dragable from '../FilterRealty/Dragable';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilter from '../FilterRealty/SquareFilter';

var inDebounce;
const Filter = (props) => {
  const {
    filter,
    filterData,
    setFilter,
    clearFilter,
    onSubmit,
    getCount,
    clearFilterOnly,
    clearExcludeOnly,
    city,
    fetching,
    info,
    countToShow,
    savedFilterXmlId,
    isShowFilter,
    isPublic
  } = props;

  const handleFilter = (data, identity) => {
    let tempValue = {
      filter: {
        [identity]: data.filter ? data.filter : filter.filter[identity],
      },
      exclude: {
        [identity]: data.exclude ? data.exclude : filter.exclude[identity],
      },
    };
    setFilter(tempValue);
    let tmpFilter = {
      filter: { ...filter.filter, ...tempValue.filter },
      exclude: { ...filter.exclude, ...tempValue.exclude },
    };
    clearTimeout(inDebounce);
    let params = filterToParams(tmpFilter);
    inDebounce = setTimeout(() => {
      getCount(params, savedFilterXmlId);
    }, 300);
  };

  const smallScreen = useMediaQuery('(max-width: 1280px)');
  const middleScreen = useMediaQuery('(min-width: 1280px) and (max-width: 1550px)');
  const handleClearFilter = (e) => {
    e.preventDefault();
    getCount(filterToParams(defaultFullFilterSecondaries), savedFilterXmlId);
    clearFilter();
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    let params = filterToParams(filter);
    onSubmit(params);
  };

  return (
    <section className="section index-search index-search_dark">
      <div className="wrapper">
        <div className="section__grid">
          <div className="section__grid-col index-search__header">
            <h1 className="h2" style={{ whiteSpace: 'pre-wrap' }}>
              Вторичная недвижимость
            </h1>
          </div>
          <div className="section__grid-col index-search__filter">
            <form className="form">
              {isShowFilter && <div className="index-search__filter-body">
                <div className="index-search__filter-top">
                  <div className="form__row">
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter({ filter: a }, "rooms")
                      }
                      isExcludable={false}
                      isAutocomplete={false}
                      items={
                        filterData.rooms
                          ? Object.values(filterData.rooms)
                          : []
                      }
                      filterValues={filter.filter.rooms}
                      excludeValues={filter.exclude.rooms}
                      data={{
                        title: "К-во комнат",
                        autocompleteTitle: "",
                        identity: "rooms",
                      }}
                    />
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter({ filter: a, exclude: b }, 'districts')
                      }
                      isExcludable={true}
                      isAutocomplete={true}
                      items={
                        filterData.districts
                          ? Object.values(filterData.districts)
                          : []
                      }
                      filterValues={filter.filter.districts}
                      excludeValues={filter.exclude.districts}
                      data={{
                        title: 'Район',
                        autocompleteTitle: 'Введите название района',
                        identity: 'districts',
                      }}
                    />
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter({ filter: a, exclude: b }, 'subways')
                      }
                      isExcludable={true}
                      isAutocomplete={true}
                      items={
                        filterData.subways
                          ? Object.values(filterData.subways)
                          : []
                      }
                      filterValues={filter.filter.subways}
                      excludeValues={filter.exclude.subways}
                      data={{
                        title: 'Метро',
                        autocompleteTitle: 'Введите название станции',
                        identity: 'subways',
                      }}
                    />
                    <PriceFilter
                      filter={
                        filter.filter.prices
                          ? filter.filter.prices
                          : { min: "", max: "" }
                      }
                      exclude={
                        filter.exclude.prices
                          ? filter.exclude.prices
                          : { min: "", max: "" }
                      }
                      items={
                        filterData.prices ? filterData.prices.items : []
                      }
                      data={{
                        title: "Цена",
                        minPlaceholder: "от ",
                        maxPlaceholder: "до ",
                        defaultValues: filterData.prices
                          ? filterData.prices.range
                          : 0,
                      }}
                      right={smallScreen}
                      onChange={(a) => handleFilter(a, "prices")}
                    />
                    <FloorsFilter
                      items={
                        filterData.floors?.items ? filterData.floors.items : []
                      }
                      filter={filter.filter.floors}
                      data={{
                        title: 'Этаж',
                        minPlaceholder: 'от ',
                        maxPlaceholder: 'до ',
                        defaultValues: filterData.floors
                          ? filterData.floors.range
                          : null,
                      }}
                      onChange={(a) => handleFilter(a, 'floors')}
                      secondData={{
                        title: filterData.isNoFirstFloor
                          ? filterData.isNoFirstFloor[0].title
                          : "Не первый",
                        value: filter.filter.isNoFirstFloor?.length
                          ? filter.filter.isNoFirstFloor[0].id
                          : "N",
                      }}
                      secondChange={(a) => {
                        let tmp = a === 'Y' ? [{ id: a }] : [];
                        handleFilter(
                          {
                            filter: tmp,
                            exclude: [
                              ..._filter(
                                filter.exclude.isFirstFloor,
                                (val) => val.id != a,
                              ),
                            ],
                          },
                          'isFirstFloor',
                        );
                      }}
                      ident="floor"
                   
                    />
                    <SquareFilter
                      filter={filter.filter.squareTotal}
                      data={{
                        title: 'S общая',
                        minPlaceholder: 'от ',
                        maxPlaceholder: 'до ',
                        defaultValues: filterData.squareTotal
                          ? filterData.squareTotal.range
                          : null,
                      }}
                      right={true}
                      onChange={(a) => handleFilter(a, 'squareTotal')}
                    />
                  </div>
                </div>
              </div>}
              {filter && filterData && !isEmptyFilterOrExclude(filter, null, defaultFullFilterSecondaries) && (
                <Dragable
                  filter={filter.filter}
                  fullFilter={filter}
                  exclude={filter.exclude}
                  filterData={filterData}
                  handleFilter={handleFilter}
                  clearExcludeOnly={clearExcludeOnly}
                  clearFilterOnly={clearFilterOnly}
                  getCount={(filterOrExclude) =>
                    getCount(filterToParams(filterOrExclude))
                  }
                  readonly={false || isPublic}
                  enableRemoving={true && (isShowFilter || !isPublic)}
                  hideParams={isShowFilter && isPublic}
                  defFullFilter={defaultFullFilterSecondaries}
                />
              )}
              {isShowFilter && <>
                <div className="index-search__filter-controls">
                  <button
                    onClick={handleClearFilter}
                    className="button button_type_clear visible_mobile"
                  >
                    <svg className="icon icon_x">
                      <use xlinkHref="#x"></use>
                    </svg>
                    <span>Очистить фильтр</span>
                  </button>
                  <a
                    className="index-search__filter-control index-search__filter-submit"
                    href="#filter-results"
                  >
                    {countToShow ? (
                      <button
                        className="button button_view_default"
                        type="submit"
                        onClick={handleFilterClick}
                        disabled={fetching ? true : false}
                      >
                        Найдено{` ${countToShow} объектов`}
                      </button>
                    ) : (
                        <button
                          className="button button_view_default"
                          type="submit"
                          disabled
                        >
                          Ничего не найдено
                        </button>
                      )}
                  </a>
                  <div className="index-search__filter-control index-search__filter-options">
                    <button
                      onClick={handleClearFilter}
                      className="button button_type_clear visible_note"
                    >
                      <svg className="icon icon_x">
                        <use xlinkHref="#x"></use>
                      </svg>
                      <span>Очистить фильтр</span>
                    </button>
                  </div>
                </div>
                <div className="index-search__filter-controls-m">
                  <div className="index-search__filter-controls-col">
                    <button
                      onClick={handleClearFilter}
                      className="button button_type_clear visible_mobile"
                    >
                      <svg className="icon icon_x">
                        <use xlinkHref="#x"></use>
                      </svg>
                      <span>Очистить фильтр</span>
                    </button>
                  </div>
                  <div className="index-search__filter-controls-col">
                    <div className="index-search__filter-control index-search__filter-submit">
                      {countToShow ? (
                        <button
                          className="button button_view_default"
                          type="submit"
                          onClick={handleFilterClick}
                          disabled={fetching ? true : false}
                        >
                          Найдено{` ${countToShow} объектов`}
                        </button>
                      ) : (
                          <button
                            className="button button_view_default"
                            type="submit"
                            disabled
                          >
                            Ничего не найдено
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="index-search__filter-controls-col">
                    <div className="index-search__filter-control index-search__filter-options">
                      <button
                        onClick={handleClearFilter}
                        className="button button_type_clear visible_note"
                      >
                        <svg className="icon icon_x">
                          <use xlinkHref="#x"></use>
                        </svg>
                        <span>Очистить фильтр</span>
                      </button>
                    </div>
                  </div>
                </div>
              </>}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  city: ownProps.city ? ownProps.city : state?.user?.info?.city,

});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearFilter: () => dispatch({ type: 'SECONDARIES_CLEAR_FILTER' }),
    clearFilterOnly: () => dispatch({ type: 'SECONDARIES_CLEAR_FILTER_ONLY' }),
    clearExcludeOnly: () => dispatch({ type: 'SECONDARIES_CLEAR_EXCLUDE_ONLY' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
