import React from 'react';
import dateFormat from 'dateformat';
import Table from '../core/Table';

const times = [
  {
    id: '1',
    title: 'За час',
  },
  {
    id: '3',
    title: 'За 3 часа',
  },
  {
    id: '24',
    title: 'За день',
  },
];

dateFormat.i18n = {
  dayNames: [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  monthNames: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
  timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
};

const dealDateFormat = `dd.mm в HH:MM`;

const BlockTasks = (props) => {
  const { items, deals, innerRef } = props;

  const getColumns = () => [
    {
      title: 'Задача',
      dataIndex: 'dealTypeId',
      key: 'dealTypeId',
      width: 140,
      render: (text, record) =>
        deals.find?.((deal) => deal.id == text)['shortName'],
    },
    {
      title: 'Дата',
      width: 150,
      dataIndex: 'dealDateTime',
      key: 'dealDateTime',
      render: (text, record) => dateFormat(text, `dd mmmm yyyy`),
    },
    {
      width: 70,
      title: 'Время',
      dataIndex: 'dealDateTime',
      key: 'dealTime',
      render: (text, record) => dateFormat(text, `HH:MM`),
    },
    {
      title: 'Уведомление',
      width: 200,
      dataIndex: 'notifies',
      key: 'notifies',
      render: (text, record) =>
        text.length > 0 ? (
          <button
            className="button button_type_task-assigned"
            data-id="task-edit"
          >
            <span className="icon-block">
              <svg className="icon icon_alert-bell">
                <use xlinkHref="#alert-bell"></use>
              </svg>
            </span>
            <span>
              {times.find((time) => time.id == text[0].countHours)['title']}
            </span>
          </button>
        ) : (
          `Без уведомления`
        ),
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) => comment && `—`,
    },
  ];

  return (
    <div className="client__section has-offset" ref={innerRef}>
      <div className="client__section-header">
        <h3 className="client__section__title">Задачи</h3>
      </div>
      <div className="client__table">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={getColumns()}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: 'ничего не найдено' }}
          customPagination={true}
        />
      </div>
    </div>
  );
};

export default BlockTasks;
