
const initialState = {
  isAuthorized: false,
  error: false,
  acceptOffers: false,
  fetching: false,
  clients: [],
  compare: [],
  filters: [],
  notifications: [],
  favoritesCount: 0,
  userId: null,
  history:[],
  manager: {},
  isSaveSitePopoverVisible: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, fetching: true };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthorized: true,
        error: false,
        fetching: false,
        access: action.data.access,
        acceptOffers: action.data.user?.offersAccepted || action.data.user.groups.includes("test_access"),
        info: action.data.user,
        clients: action.data.clients,
        compare: action.data.clients,
        logo: action.data.user.logo,
        manager: action.data.manager,
        favoritesCount: action.data.favorites,
        isSaveSitePopoverVisible: false,
        siteDomain: action.data.siteDomain
      };
    case "USER_EDIT":
      return { ...state, info: { ...state.info, ...action.data } };
    case "TEST_ACCESS_SMS_SUCCESS":
        return { ...state, userId: action.data.userId };
    case "LOGIN_FAILURE":
      return { ...state, error: true, fetching: false, isAuthorized: false };
    case "ACCEPT_OFFERS":
      return { ...state, acceptOffers: true };
    case "ADD_USER_CLIENT": {
      return { ...state, clients: [...state.clients, action.data] };
    }
    case "LOGOUT":
      window.open("/", "_self")
      return { ...initialState };
    case "SUCCESS_GET_FAVORITE_COUNT":
      return { ...state, favoritesCount: action.count };
    case "@@router/LOCATION_CHANGE":
      let historyItem = window.location.host + action.payload.location.pathname
      return { ...state, history: [historyItem, ...state.history] };
    case "SET_USER_STATE":
      return { ...state, ...action.payload }
    default:
      return state;
  }
};

export default user;
