import React, { useState } from 'react';
import request, { getUrl } from '../../api';
import { errorToast, standartErrorToast, standartSuccessToast } from '../../components/toasts';
import { downloadLink } from '../../utils';
import './index.scss';


const Additional = ({ optionalPoints = {}, setOptionalPoints, setLastActiveShedule }) => {

  const [link, setLink] = useState("")
  const [review, setReview] = useState("")
  const submitHandler = type => {
    const params = {
      type,
      text: type === "review" ? review : link
    }
    if (!params.text.replace(/\W/gi)) return errorToast(`Введите ${type === "review" ? "текст отзыва" : "ссылку на соц сеть"}`)
    request("panpartner:learning.ajax", "sendOptionalRequest", params).then(data => {
      setOptionalPoints(prev => ({ ...prev, [type]: true }))
      standartSuccessToast(data)
      if (optionalPoints[type === "review" ? "link" : "review"]) {
        setLastActiveShedule()
      }
    }
      ,
      standartErrorToast
    )
  }

  return (
    <div className="training__block">
      <div className="training__poll">
        <div className="training__poll-header training__poll-header_column training__poll-header_additional">

          <span className="training__result-text training__additional-sign">За каждое выполненное задание вы можете получить +6 баллов</span>
        </div>

        {!optionalPoints?.link && <div className="training__additional">
          <div className="training__additional-box">
            <h4 className="h4 training__poll-title">Задание 1</h4>

            <p className="training__additional-text">
              <span className="training__additional-counter">1.</span>
              Поделитесь на своей странице в социальной сети Инстаграм
              информацией о предстоящем мероприятии и пригласите своих
              подписчиков на обучение, используйте для этого наш баннер.
            </p>

            <button type="button" class="training__download button button_view_outline-with-icon" onClick={() => downloadLink(getUrl("/upload/app_images/teaching_banner.jpg"))}>
              <svg class="icon icon_download">
                <use xlinkHref="#download"></use>
              </svg>
              <span>Скачать баннер</span>
            </button>

            <p className="training__additional-text">
              <span className="training__additional-counter">2.</span>
              ОБЯЗАТЕЛЬНО добавьте хэштег #ПАН Партнер и отметьте аккаунт @pan_school.
              Без отметок ваше задание принято не будет.
            </p>

            <p className="training__additional-text">
              <span className="training__additional-counter">3.</span>
              Прикрепите скриншот приглашения в личном кабинете и получите ___ бонусов!
            </p>

            <div>
              <span className="training__result-text">Баннер</span>
              <input
                type="text"
                className="text-input"
                placeholder="Ссылка на соц. сеть"
                onChange={e => setLink(e.target.value)}
                value={link}
              />
            </div>

            <button
              type="button"
              className="training__submit button button_view_default"
              onClick={() => submitHandler("link")}
            >Отправить</button>
          </div>
        </div>}


        {!optionalPoints?.review && <div className="training__additional-review textarea textarea_type_form">
          <h4 className="h4 training__poll-title">Задание 2</h4>
          <p className="training__additional-text" style={{ marginBottom: 22 }}>
            После завершения теоретической части обучения, здесь можно разместить свой отзыв. Нам важна ваша обратная связь. Оставьте отзыв и заработайте дополнительные баллы
          </p>
          <div className="training__review-info">
            <span className="training__result-text">Отзыв</span>
            <div className="info">
              <svg className="icon icon_info">
                <use xlinkHref="#info" />
              </svg>
              <div className="info__popup">
                Отзыв
              </div>
            </div>
          </div>
          <textarea
            className="textarea__control"
            id="cl-comment"
            name="comment"

            rows="5"
            placeholder="Текст отзыва"

            onChange={e => setReview(e.target.value)}
            value={review}
          ></textarea>

          <button
            type="button"
            className="training__submit button button_view_default"
            onClick={() => submitHandler("review")}
          >Отправить</button>
        </div>}
      </div>


    </div>
  );
};

export default Additional;
