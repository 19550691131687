import React from 'react';
import { getUrl } from '../../../api';

const ImagesGrid = ({ imgs, title, dataId, wrapperStyle = {} }) => {
  if (!imgs) return null
  let images = imgs
  if(imgs?.length > 4){
    images.length = 4
  }
  return <div className="pdf-block">
    <div className="presentation__box" style={wrapperStyle} data-id={dataId}>
      <div className="presentation__title">{title}</div>
      <div className="presentation__more">
        <div className="presentation__more-images presentation__info-grid">
          {images.map((image, index) => (
            <div className="presentation__more-image presentation__info-col" key={index}>
              <img src={getUrl(image.src)} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
}

export default ImagesGrid