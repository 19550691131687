export const Toggle = ({ title, filter, handleFilter, ident, ...props }) => <div className="index-search__show-booked">
    <label className="switch" htmlFor={`filter-booked-${ident}`}>
        <div className="switch__box">
            <input
                className="switch__control"
                type="checkbox"
                id={`filter-booked-${ident}`}
                checked={filter[ident] === "Y"}
                onChange={e => handleFilter(ident, filter[ident] === "Y" ? "" : "Y")}
                {...props}
            />
            <div
                className="switch__input"
                htmlFor={`filter-booked-${ident}`}
            />
            <div className="switch__marker" />
        </div>
        <span className="switch__label">
            {title}
        </span>
    </label>
</div>