
import { useEffect } from 'react';

const useDomEvent = (event, callBack, element = window) => {
    useEffect(() => {
        element.addEventListener(event, callBack)
        return () => element.removeEventListener(event, callBack)
    }, [])
}
export default useDomEvent
