import React from 'react';
import InputMask from "react-input-mask";

const InputField = (props) => {
    const { error, name, handleChange, fieldName, form } = props
    return (
        <div className="poll__item">
            <div className="poll__q h4">{name}</div>
            <div className="poll__a">
                <InputMask
                    className={
                        "text-input poll__input" + (error ? " input__error" : "")
                    }
                    placeholder="дд.мм.гггг"
                    mask="99.99.9999"
                    autocomplete="off"
                    maskChar=" "
                    value={form[fieldName]}
                    onChange={e => handleChange(e.target.value, fieldName)}
                />
            </div>
        </div>
    )
}
export default InputField