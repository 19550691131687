export const handleResponse = (response, sanitize = false) => {
  if (!response) {
    let errors = [{ message: "Сервер не отвечает или отсутствует подключение к сети" }]
    return Promise.reject(errors);
  }
  if (typeof response.data === "string") {
    let errors = [{ message: "Ошибка сервера" }]
    return Promise.reject(errors);
  }
  const { status, data, errors } = response.data;
  if (status === 'error') {
    let error = errors.length ? errors : response.statusText;
    if(sanitize){
      error = errors.length ? errors[0].message : response.statusText;
      error = error.replace(/&lt;br&gt;/gi, "\n\r");
      error = error.replace(/<br>/gi, "\n\r");
    }
    return Promise.reject(error);
  }
  
  return data;
};

export const actionFactory = (type, log = false) => (data = {}) => {
  if (log) {
    console.log(`%c ACTION TYPE:`, "color: #ff2d23", type);
    console.log(`%c ACTION DATA:`, "color: #2CBA2E", data);
  }
  return { type, ...data }
}

export const unPack = (data = {}, fields = []) => {
  const result = {}
  for (const field of fields) {
    result[field] = data[field]
  }
  return result
}