import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { getConsultation } from '../../actions/user';
import { errorToast } from '../toasts';

const ConsultationRequest = (props) => {
  const { closeModal } = props;
  const { handleSubmit, register, errors, control } = useForm();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    let params = new URLSearchParams(window.location.search);
    let utm = {
      utm_campaign: params.get('utm_campaign'),
      utm_content: params.get('utm_content'),
      utm_medium: params.get('utm_medium'),
      utm_source: params.get('utm_source'),
      utm_term: params.get('utm_term'),
    };

    let form = new FormData();
    Object.keys(utm).map(key=>{
      form.append(`utm[${key}]`, utm[key]);
    })
    form.append(`phone`, data.phone);
    getConsultation(form).then(
      (resp) => {
        setSuccess(true);
        window.fbq('track', 'Lead');
      },
      errorToast
    );
  };

  return (
    <>
      <h3 className="modal__title">Получить консультацию</h3>
      {success && (
        <p className="modal__hint">
          Заявка отправлена. Наш менеджер свяжется с вами в ближайшее время.
        </p>
      )}
      {!success && (
        <>
          <p className="modal__hint">
            Оставьте номер телефона и наш менеджер свяжется с вами.
          </p>
          {!!error && <div className="form__error-text">{error}</div>}

          <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-form__wrapper">
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="sign-in_phone"
                  >
                    <Controller
                      as={
                        <InputMask
                          className={
                            'input__control' +
                            (errors.phone ? ' input__error' : '')
                          }
                        />
                      }
                      control={control}
                      autoComplete="off"
                      placeholder="Введите телефон"
                      mask="+7 (999) 999-99-99"
                      name="phone"
                      inputRef={register({
                        required: 'Введите телефон',
                      })}
                      defaultValue=""
                      rules={{
                        required: 'Введите телефон',
                      }}
                    />
                    {errors.phone && (
                      <span className="input__error-label">
                        {errors.phone.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-form__submit">
              <button className="button button_view_default">Отправить</button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(ConsultationRequest);
