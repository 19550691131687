import React, { memo, useState } from "react";
import Avatar from "react-avatar";
import bigStub from '../../assets/images/chat/BIGstub.jpg';
import {
    ChatAddUserIcon,
    ChatBackArrowIcon,
    ChatDownloadIcon,
    ChatFooterFileIcon,
    ChatFooterImageIcon,
    ChatUsersIcon,
    MenuCloseIcon
} from "../Public/markup/icons";
import { Accordion } from './Components';
import './index.scss';
import stubhouse from './stubhouse.png';
import UserInChatItem from "./UserInChatItem";
import {PhotoPopup} from "../Viewer/PhotoSlider";

const ChatInfo = memo(props => {
    const {
        close,
        avatar_url,
        full_name,
        email,
        name,
        description
    } = props;

    const [{ showUsers }, setState] = useState({
        showUsers: false,

    })

    const [openPhoto, setOpenPhoto] = useState(false);



    if (showUsers) return (
        <div className="chat-users">
            <div className="chat-users__back" onClick={() => setState(prev => ({ ...prev, showUsers: false }))}>
                <ChatBackArrowIcon />
            </div>

            <ul className="chat-users__list">
                <UserInChatItem
                    name='Андрей Бармалей'
                    post='Агент с расширенным доступом'
                    phone='+7 (987) 654-32-10'
                    logo={bigStub}
                />
                <UserInChatItem
                    name='Котик Тортик'
                    post='Суперагент'
                    phone='+7 (987) 654-32-10'
                />

            </ul>
        </div>
    );

    return (
        <div className="chat-info">
            <div className="chat-info__close" onClick={close}><MenuCloseIcon /></div>
            <div className="chat-info__user">
                <div className="chat-info__user-logo">
                    {!avatar_url ? (
                        <Avatar
                            name={full_name ?? name}
                            size="110"
                            round={true}
                            textSizeRatio={2.857}
                        />
                    ) : (
                        <img className="chat-info__user-image" src={avatar_url} alt="" />
                    )}
                </div>
                <div className="chat-info__user-name">{full_name ?? name}</div>
                {description && <div className="chat-info__user-post">{description}</div>}
                {/* <a href={`tel:+79876543210`} className="chat-info__user-phone">+7 (987) 654-32-10</a> */}
                <a href={`mailto:${email}`} className="chat-info__user-email">{email}</a>
            </div>

            <div className="chat-info__users-list">
                <ul>
                    <li>
                        <span className="chat-info__users-icon" onClick={() => setState(prev => ({ ...prev, showUsers: true }))}>
                            <ChatUsersIcon />10 участников
                        </span>
                        <span className="chat-info__adduser-icon">
                            <ChatAddUserIcon />
                        </span>
                    </li>
                </ul>
            </div>

            <div className="chat-info__action-list">
                <Accordion title={`Фото и видео`} Icon={ChatFooterImageIcon} number={1}>
                    <div className="chat-info__uploaded-images">
                        <img className="chat-info__uploaded-image" onClick={() => setOpenPhoto(true)} src={stubhouse} alt="" />
                    </div>
                </Accordion>
                <Accordion title={`Файлы`} Icon={ChatFooterFileIcon} number={2}>
                    <div className="chat-info__uploaded-files">
                        <div className="chat-info__uploaded-file">
                            <div className="chat-info__uploaded-filetitle">
                                Секретный документ.pdf
                            </div>
                            <div className="chat-info__uploaded-filedate">
                                <span>24.12.2023</span>
                                <span className="chat-info__uploaded-icon"><ChatDownloadIcon /></span>
                            </div>
                        </div>
                        <div className="chat-info__uploaded-file">
                            <div className="chat-info__uploaded-filetitle">
                                Секретный суперсекретный засекреченый документ.pdf
                            </div>
                            <div className="chat-info__uploaded-filedate">
                                <span>24.12.2023</span>
                                <span className="chat-info__uploaded-icon"><ChatDownloadIcon /></span>
                            </div>
                        </div>
                    </div>
                </Accordion>
            </div>

            <div className="chat-info__leave-links">
                <div className="chat-info__leave-link">Удалить группу</div>
                <div className="chat-info__leave-link">Выйти из чата</div>
            </div>
            <PhotoPopup
              open={openPhoto}
              items={[{"src": stubhouse}]}
              index={0}
              onClose={() => setOpenPhoto(false)}
            />
        </div>
    )
});
export default ChatInfo;
