import { useMediaQuery } from "beautiful-react-hooks";
import React, { useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { getBankPrograms } from "../../actions/complex";
import { getUrl } from "../../api";
import useMedia from "../../hooks/useMedia";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import Wrap from "../core/ModalWrap";
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import SwiperSlider from '../core/SwiperSlider';
import BankInfo from "./BankInfo";
const Bank = (props) => {
  const {
    name,
    logo,
    maxPeriod,
    minInitialPayment,
    minPercent,
    id,
    data
  } = props;

  const isMobile = useMedia("isMobile");
  const [bankInfo, setBankInfo] = useState({})

  const getProgram = () => {
    if(bankInfo.bankPrograms) return openBankInfo()
    getBankPrograms({ blockId: data.block.id, city: data.block.city, bankId: id }).then(data => {
      setBankInfo(data)
    }).finally(openBankInfo)
  }

  const [openBankInfo, closeBankInfo] = useModal(({ in: open, onExited }) => (
    <Wrap wrapper={children => isMobile
      ? <MobileModal closeClick={closeBankInfo} title="Аккредитация">{children}</MobileModal>
      : <Modal close={closeBankInfo} classes="modal_task modal-banks">{children}</Modal>
    } >
      <BankInfo {...bankInfo} />
    </Wrap >
  ), [bankInfo]);

  return (
    <div className="banks__slider-item">
      <div className="banks__slider-inner">
        <div className="banks__slider-image">
          <img src={getUrl(logo.src)} alt={name} />
        </div>
        <div className="banks__slider-content">
          <div className="banks__slider-conditions">
            <div className="banks__slider-condition">Процентная ставка</div>
            <div className="banks__slider-value">от {minPercent}%</div>
          </div>
          <div className="banks__slider-conditions">
            <div className="banks__slider-condition">Первоначальный взноc</div>
            <div className="banks__slider-value">от {minInitialPayment}%</div>
          </div>
          <div className="banks__slider-conditions">
            <div className="banks__slider-condition">Срок ипотеки</div>
            <div className="banks__slider-value">до {maxPeriod} лет</div>
          </div>
          <div className="banks__slider-more">
            {" "}
            <span
              className="banks__slider-link js-modal-btn"
              onClick={getProgram}
            >
              Подробнее
              <svg className="icon icon_more">
                <use xlinkHref="#chevron"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Banks = (props) => {
  const { items, data } = props;
  const isMobile = useMedia("isMobile");
  const navRef = useRef(null);
  const responsive1024 = useMediaQuery("(min-width: 1024px)");
  const responsive768 = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const responsiveOther = useMediaQuery("(max-width: 767px)");

  const [index, setIndex] = useState(1);


  const showNavigation = useMemo(() => {
    const count = items.length;
    if (count > 3 && responsive1024) {
      return true;
    } else if (count > 2 && responsive768) {
      return true;
    } else if (count > 1 && responsiveOther) {
      return true;
    }

    return false;
  }, [responsive1024, responsive768, responsiveOther, items]);

  return (
    <ErrorPlug wrappedComponent="comp-ts/Apartment/Banks.js - с новым слайдером">
      <section className="section section_view_white flat__banks">
        <div className="wrapper">
          <div className="wrapper__center">
            <div className="banks__slider flat__banks">
              {!isMobile && showNavigation && (
                <div className="banks__slider-nav">
                  <ul className="banks__slider-nav_wrap" id="banksSlider-nav">
                    <li
                      className="slider__nav-item prev banks-prev"
                    >
                      <svg className="icon icon-dark_stroke">
                        <use xlinkHref="#icon-left_arrow"></use>
                      </svg>
                    </li>
                    <li
                      className="slider__nav-item next banks-next"
                    >
                      <svg className="icon icon-dark_stroke">
                        <use xlinkHref="#icon-right_arrow"></use>
                      </svg>
                    </li>
                  </ul>
                </div>
              )}

              <div className="banks__slider-wrap" id="banksSlider">
                <SwiperSlider
                  items={items}
                  wrap={item => <Bank {...item} data={data}/>}
                  nextEl='.banks-next'
                  prevEl='.banks-prev'
                  options={{
                    breakpoints: {
                      767: {
                        slidesPerView: 3,

                      },
                      1599: {
                        slidesPerView: 4,

                      },
                    }
                  }}
                />

                {isMobile &&
                  <>
                    <ul
                      className="complex__slider-thumbnails"
                      id="complex-slider-thumbnails"
                      ref={navRef}
                    >
                      {items.map((item, index) => (
                        <li className="complex__slider-thumbnails_item" key={index}>
                          <span aria-controls="base"></span>
                        </li>
                      ))}
                    </ul>

                    <span className="complex__slider-info">
                      {index} / {items.length}
                    </span>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </ErrorPlug>
  );
};

export default Banks;
