import React, {useEffect, useRef, useState} from 'react';
import './style.scss'
import useMedia from "../../hooks/useMedia";
import autopanSec3Lady from "../../assets/images/autopan/autopan-sec3-lady.jpg";
import autopanSec4Log1 from "../../assets/images/autopan/autopan-sec4-log1.png";
import autopanSec4Log2 from "../../assets/images/autopan/autopan-sec4-log2.png";
import autopanSec4Log3 from "../../assets/images/autopan/autopan-sec4-log3.png";
import autopanSec4Girl1 from "../../assets/images/autopan/autopan-sec4-girl1.png";
import autopanSec4Girl2 from "../../assets/images/autopan/autopan-sec4-girl2.png";
import autopanSec4Girl3 from "../../assets/images/autopan/autopan-sec4-girl3.png";
import panschoolBottomLine from "../../assets/images/panschool/panschoolbottomline.png";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {errorToast, infoToast, standartErrorToast, standartInfoToast} from "../../components/toasts";
import SubmitButton from "../../components/SubmitButton";
import request from "../../api";

const AutoPanNew = () => {

  const isTablet = useMedia('isTablet');
  const isSmallLaptop = useMedia('isSmallLaptop');
  const formRef = useRef(null);
  const managerShowRef = useRef(null);
  const offsetTop = 30;

  const handleScroll = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const showManager = () => {
    let currentScrollPosition = managerShowRef.current?.getBoundingClientRect().top;
    setVisible(currentScrollPosition > offsetTop);
  }

  useEffect(() => {
    window.addEventListener('scroll', showManager);
    return () => {
      window.removeEventListener('scroll', () => showManager);
    };
  }, []);

  const formatDay = (date) => {
    return date.toISOString().split('T')[0];
  };

  const checkFields = largeForm => {
    const err = {
      phone: !phone ? "Введите телефон" : false,
      name: !userName ? "Введите имя" : false,
      agreement: !agree ? "Необходимо согласие на обработку персональных данных" : false,
      agreement2: !agree2 ? "Необходимо ознакомиться с регламентом сервиса" : false
    }
    if (largeForm) {
      err.timeFrom = !selectedTime ? "Выберите время" : false
    }
    setErrors(err)
    return Object.values(err).some(err => !!err)
  }

  const CustomSucessMessage = () => (
    <div className="autopan-viewing-customToast">
      <b>Благодарим за обращение! В ближайшее время наш Администратор свяжется с Вами.</b><br/>
      (Заявки, направленные в выходные и праздничные дни будут обработаны в ближайший рабочий день)
    </div>
  );

  const user = useSelector(state => state.user?.info)

  const [userName, setUserName] = useState(user ? `${user.name} ${user.lastName}` : "")
  const [phone, setPhone] = useState(user ? user.phone : "")
  const [chosenDay, setChosenDay] = useState();
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [agree, setAgree] = useState(true);
  const [agree2, setAgree2] = useState(true);
  const [errors, setErrors] = useState({})
  const [isManagerVisible, setVisible] = useState(true);



  const submitRequestsForm = (title, largeForm) => {
    if (checkFields(largeForm)) return errorToast("Заполните обязательные поля")
    if (!agree) return errorToast("Необходимо согласиться на обработку персональных данных")
    if (!agree2) return errorToast("Необходимо ознакомиться с регламентом сервиса")
    const form = {
      title,
      name: userName,
      phone,
    }
    return request('panpartner:forms.ajax', "sendAutoPanoramaRequest", form)
      .then(standartInfoToast({message: <CustomSucessMessage/>}, {autoClose: 10000}), standartErrorToast)
      .finally(() => setErrors({}))
  }

  return (
    <div className="wrapper wrapper-lands autopan">

      <div className={`trade-in__manager${isManagerVisible ? ` hidden` : ``}`}>
        <img  src={require('../../assets/images/auto/manager.jpg').default} alt="" className="trade-in__manager-img" />
        <div className="trade-in__manager-block">
          <span className="h6 trade-in__manager-name">Меланич Наталья</span>
          <p className="trade-in__manager-position">Администратор обучающего центра</p>
          <a href="tel:+79657874839" className="trade-in__manager-phone link-hover">+7 (965) 787-48-39</a>
        </div>
      </div>

      <div className="autopan-sec1">
        <p className="autopan-sec1__intensive">Индвивидуальный интенсив по новостройкам</p>
        {!isTablet &&<p className="autopan-sec1__leveltext">Уровень: начинающий, средний, продвинутый</p>}
        <h1 className="autopan-sec1__title">АвтоПАНорама</h1>
        <p className="autopan-sec1__secondtitle">Повышаем продажи и компетенции — за 3 часа <br/>
          разбираем до 5 локаций Санкт-Петербурга <br/>
          в автомобиле комфорт-класса </p>

        {isTablet &&<p className="autopan-sec1__leveltext">Уровень: начинающий, средний, продвинутый</p>}

        {!isTablet &&
          <div className="autopan-sec1__btns">
            <button className="btn btn_primary h6" onClick={() => window.open('https://docs.google.com/spreadsheets/d/1ao8CL4JnxbNbjHCzJ3K1Bl3Z5qB19VrCd4PtME3czKA/edit?gid=186001220#gid=186001220', '_blank')}>Посмотреть расписание</button>
            <button className="btn btn_primary h6" onClick={handleScroll}>Записаться на поездку</button>
            <button className="btn btn_secondary h6" onClick={() => window.location.href = 'https://panpartner.ru/files/4468'}>Посмотреть локации</button>
          </div>
        }

      </div>
      {isTablet &&
        <div className="panschool-sec1__btns">
          <button className="btn btn_primary h6" onClick={() => window.open('https://docs.google.com/spreadsheets/d/1ao8CL4JnxbNbjHCzJ3K1Bl3Z5qB19VrCd4PtME3czKA/edit?gid=186001220#gid=186001220', '_blank')}>Посмотреть расписание</button>
          <button className="btn btn_primary h6" onClick={handleScroll}>Записаться на поездку</button>
          <button className="btn btn_secondary h6" onClick={() => window.location.href = 'https://panpartner.ru/files/4468'}>Посмотреть локации</button>
        </div>
      }
      <div className="autopan-sec2">
        <ul className="autopan-sec2__list">
          <li>
            <div className="autopan-sec2__list-img">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.17468 35.0189C8.17468 35.0189 17.2058 14.6902 17.2231 14.6729C17.6264 14.0732 18.1588 13.5713 18.7813 13.2041C19.4037 12.8368 20.1004 12.6135 20.8204 12.5504C21.5404 12.4874 22.2653 12.5863 22.9421 12.8398C23.6189 13.0932 24.2304 13.495 24.7318 14.0154L25.147 38.4791" fill="#EF372E"/>
                <path d="M8.17468 35.0189C8.17468 35.0189 17.2058 14.6902 17.2231 14.6729C17.6264 14.0732 18.1588 13.5713 18.7813 13.2041C19.4037 12.8368 20.1004 12.6135 20.8204 12.5504C21.5404 12.4874 22.2653 12.5863 22.9421 12.8398C23.6189 13.0932 24.2304 13.495 24.7318 14.0154L25.147 38.4791" stroke="#EF372E" stroke-miterlimit="10"/>
                <path d="M16.3235 47.2852C21.1966 47.2852 25.1471 43.3348 25.1471 38.4617C25.1471 33.5886 21.1966 29.6382 16.3235 29.6382C11.4504 29.6382 7.5 33.5886 7.5 38.4617C7.5 43.3348 11.4504 47.2852 16.3235 47.2852Z" fill="#9C0000" stroke="#EF372E" stroke-miterlimit="10"/>
                <path d="M51.8425 35.0189L42.7941 14.6729C42.3917 14.0642 41.857 13.5543 41.2299 13.1811C40.6028 12.808 39.8995 12.5814 39.1726 12.5182C38.4456 12.455 37.7138 12.5568 37.0317 12.8161C36.3496 13.0754 35.7349 13.4854 35.2335 14.0155L34.8529 38.4791" fill="#EF372E"/>
                <path d="M51.8425 35.0189L42.7941 14.6729C42.3917 14.0642 41.857 13.5543 41.2299 13.1811C40.6028 12.808 39.8995 12.5814 39.1726 12.5182C38.4456 12.455 37.7138 12.5568 37.0317 12.8161C36.3496 13.0754 35.7349 13.4854 35.2335 14.0155L34.8529 38.4791" stroke="#EF372E" stroke-miterlimit="10"/>
                <path d="M43.6764 47.2852C48.5495 47.2852 52.5 43.3348 52.5 38.4617C52.5 33.5886 48.5495 29.6382 43.6764 29.6382C38.8033 29.6382 34.8529 33.5886 34.8529 38.4617C34.8529 43.3348 38.8033 47.2852 43.6764 47.2852Z" fill="#9C0000" stroke="#EF372E" stroke-miterlimit="10"/>
                <path d="M24.8702 21.1089H35.0086" stroke="#EF372E" stroke-miterlimit="10"/>
              </svg>
            </div>
            <p className="autopan-sec2__list-title">
              Смотрим максимум объектов в районах
            </p>
            <p className="autopan-sec2__list-text">
              Проводим анализ ЖК от разных застройщиков — и с разных точек зрения.
            </p>
          </li>
          <li>
            <div className="autopan-sec2__list-img">
              <svg width="45" height="42" viewBox="0 0 45 42" style={{marginBottom: 10}} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.4483 2.30386L29.0852 11.5639C29.2617 11.9274 29.5248 12.2435 29.8521 12.4856C30.1794 12.7276 30.5614 12.8885 30.9654 12.9544L41.3419 14.4283C41.8036 14.489 42.2387 14.676 42.5976 14.968C42.9566 15.26 43.225 15.6451 43.3723 16.0798C43.5196 16.5144 43.5399 16.981 43.4309 17.4264C43.3218 17.8718 43.0879 18.2785 42.7555 18.5996L35.2347 25.8018C34.9479 26.0806 34.7339 26.4235 34.6108 26.8012C34.4878 27.1789 34.4593 27.5802 34.5279 27.971L36.295 38.1347C36.3742 38.5846 36.324 39.0473 36.1498 39.4705C35.9757 39.8937 35.6847 40.2609 35.3097 40.5299C34.9346 40.799 34.4906 40.9595 34.0276 40.9933C33.5647 41.0271 33.1014 40.9327 32.6901 40.721L23.4022 35.9102C23.0427 35.7318 22.6456 35.6387 22.2429 35.6387C21.8403 35.6387 21.4432 35.7318 21.0837 35.9102L11.7958 40.721C11.3845 40.9327 10.9212 41.0271 10.4583 40.9933C9.99535 40.9595 9.55125 40.799 9.17621 40.5299C8.80118 40.2609 8.51014 39.8937 8.33602 39.4705C8.1619 39.0473 8.11163 38.5846 8.19089 38.1347L9.97215 27.971C10.0414 27.579 10.0119 27.1763 9.88621 26.7982C9.76051 26.42 9.54246 26.0779 9.25115 25.8018L1.74448 18.5996C1.41217 18.2785 1.17818 17.8718 1.06913 17.4264C0.960087 16.981 0.980366 16.5144 1.12768 16.0798C1.27498 15.6451 1.54341 15.26 1.90238 14.968C2.26135 14.676 2.69645 14.489 3.15817 14.4283L13.5488 12.9544C13.9503 12.8864 14.3293 12.7248 14.654 12.4829C14.9788 12.2409 15.2397 11.9257 15.4148 11.5639L20.0659 2.30386C20.2754 1.91062 20.5904 1.58113 20.9766 1.35134C21.3629 1.12155 21.8057 1 22.2571 1C22.7085 1 23.1513 1.12155 23.5375 1.35134C23.9238 1.58113 24.2387 1.91062 24.4483 2.30386Z" fill="#EF372E" stroke="#9C0000" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
            </div>
            <p className="autopan-sec2__list-title">
              Изучаем <br/>преимущества ЖК
            </p>
            <p className="autopan-sec2__list-text">
              Учитываем потребности потенциального клиента и создаем живой видео- и фото-контент на коротких фотостопах.
            </p>
          </li>
          <li>
            <div className="autopan-sec2__list-img">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.9485 18.1475C22.5026 18.6019 21.911 19.1123 21.2502 19.7003C16.5794 9.11627 26.6398 7.5 26.6398 7.5C28.9797 13.5221 24.7624 16.2611 22.9485 18.1475Z" fill="#EF372E"/>
                <path d="M21.2502 19.7003C16.5794 9.11627 26.6398 7.5 26.6398 7.5C28.9797 13.5221 24.7624 16.2611 22.9485 18.1475C20.536 20.6054 13.8609 24.7048 15.0309 33.8559C15.0206 38.0706 18.2 46.7 31 47.5" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M14.9493 28.795C14.9493 28.795 18.9398 18.5285 11.1764 15.0095C11.1764 15.0095 4.56473 22.0202 14.9493 28.795Z" fill="#EF372E" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M17.1077 40.5127C17.1077 40.5127 13.1172 30.2643 5.00004 32.9217C5.00004 32.9217 4.88212 42.5533 17.1077 40.5127Z" fill="#EF372E" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M24.6082 46.2266C24.6082 46.2266 14.7224 41.4287 10.5686 48.8747C10.5686 48.8747 17.0261 56.0307 24.6082 46.2266Z" fill="#EF372E" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M38.1822 18.4097C38.608 18.8419 39.1653 19.3249 39.7884 19.8771C44.4591 9.29307 34.5 7.76221 34.5 7.76221C32.151 13.7843 36.3683 16.5233 38.1822 18.4097Z" fill="#EF372E"/>
                <path d="M39.7884 19.8771C44.4591 9.29307 34.5 7.76221 34.5 7.76221C32.151 13.7843 36.3683 16.5233 38.1822 18.4097C40.6037 20.8676 47.2789 24.967 46.1089 34.1181C45.396 45.0126 35.7393 47.5787 31.0001 47.5" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M45.7789 28.3002C45.7789 28.3002 41.0603 18.5285 48.8238 15.0095C48.8238 15.0095 55.8845 21.5254 45.5 28.3002" fill="#EF372E"/>
                <path d="M45.7789 28.3002C45.7789 28.3002 41.0603 18.5285 48.8238 15.0095C48.8238 15.0095 55.8845 21.5254 45.5 28.3002" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M45 40C45 40 46.8827 30.2643 54.9999 32.9217C54.9999 32.9217 56.2347 42.5406 44 40.5" fill="#EF372E"/>
                <path d="M45 40C45 40 46.8827 30.2643 54.9999 32.9217C54.9999 32.9217 56.2347 42.5406 44 40.5" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M36 46.9798C36 46.9798 45.2775 41.4286 49.4313 48.8747C49.4313 48.8747 43.0821 56.3041 35.5 46.5" fill="#EF372E"/>
                <path d="M36 46.9798C36 46.9798 45.2775 41.4286 49.4313 48.8747C49.4313 48.8747 43.0821 56.3041 35.5 46.5" stroke="#9C0000" stroke-width="1.5" stroke-miterlimit="10"/>
              </svg>
            </div>
            <p className="autopan-sec2__list-title">
              Погружаем в историю районов Петербурга
            </p>
            <p className="autopan-sec2__list-text">
              Формируем целостное представление о городе — удобно для региональных и начинающих специалистов.
            </p>
          </li>
          <li>
            <div className="autopan-sec2__list-img">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5 34.5041C21.5 34.5041 28.5363 34.5041 37.7087 42.1878C37.9127 42.3563 38.1604 42.4635 38.4229 42.497C38.6854 42.5304 38.952 42.4889 39.1918 42.377C39.4316 42.2651 39.6348 42.0876 39.7779 41.865C39.9209 41.6423 39.9979 41.3837 40 41.1191V13.8891C39.9979 13.6245 39.9209 13.3659 39.7779 13.1432C39.6348 12.9206 39.4316 12.7431 39.1918 12.6312C38.952 12.5194 38.6854 12.4778 38.4229 12.5113C38.1604 12.5448 37.9127 12.652 37.7087 12.8204C28.5387 20.5041 21.5 20.5041 21.5 20.5041H14.5C12.6435 20.5041 10.863 21.2416 9.55025 22.5544C8.2375 23.8671 7.5 25.6476 7.5 27.5041C7.5 29.3606 8.2375 31.1411 9.55025 32.4539C10.863 33.7666 12.6435 34.5041 14.5 34.5041H21.5Z" fill="#EF372E" stroke="#9C0000" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.5 34.5042L16.81 46.2792C16.8497 46.4344 16.9152 46.5819 17.0037 46.7155C17.2101 47.0239 17.5306 47.2376 17.8946 47.3098C18.2587 47.382 18.6365 47.3067 18.945 47.1005L20.87 45.8242C21.0644 45.6981 21.224 45.5252 21.3341 45.3213C21.4442 45.1175 21.5013 44.8892 21.5 44.6575L21.5 34.5L21.5 20.5042" fill="#EF372E"/>
                <path d="M21.5 20.5042L21.5 34.5M21.5 34.5L21.5 44.6575C21.5013 44.8892 21.4442 45.1175 21.3341 45.3213C21.224 45.5252 21.0644 45.6981 20.87 45.8242L18.945 47.1005C18.6365 47.3067 18.2587 47.382 17.8946 47.3098C17.5306 47.2376 17.2101 47.0239 17.0037 46.7155C16.9152 46.5819 16.8497 46.4344 16.81 46.2792L14.5 34.5042C16.6667 34.5014 21.1 34.4967 21.5 34.5Z" stroke="#9C0000" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M50 20C50.7913 20.9826 51.4193 22.1496 51.8483 23.4345C52.2772 24.7194 52.4987 26.097 52.5 27.4885C52.5013 28.88 52.2825 30.2582 51.8559 31.5443C51.4294 32.8305 50.8036 33.9994 50.0142 34.9843L50 35" stroke="#9C0000" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 13C17.1046 13 18 12.1046 18 11C18 9.89543 17.1046 9 16 9" stroke="#9C0000" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p className="autopan-sec2__list-title">
              Делимся эксклюзивной информацией
            </p>
            <p className="autopan-sec2__list-text">
              От эксперта по новостройкам
              в неформальной обстановке.
            </p>
          </li>
          <li>
            <div className="autopan-sec2__list-img">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5477 27.6299V40.1702C16.5477 43.8471 22.5701 46.8274 29.9999 46.8274C37.4296 46.8274 43.4521 43.8471 43.4521 40.1702V27.6299" fill="#EF372E"/>
                <path d="M16.5477 27.6299V40.1702C16.5477 43.8471 22.5701 46.8274 29.9999 46.8274C37.4296 46.8274 43.4521 43.8471 43.4521 40.1702V27.6299" stroke="#9C0000" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M28.9457 32.9232L8.21861 23.9174C8.0052 23.8253 7.82343 23.6727 7.69573 23.4785C7.56804 23.2842 7.5 23.0569 7.5 22.8244C7.5 22.592 7.56804 22.3646 7.69573 22.1704C7.82343 21.9761 8.0052 21.8235 8.21861 21.7314L28.9457 12.7194C29.2783 12.5747 29.6372 12.5 30 12.5C30.3628 12.5 30.7217 12.5747 31.0543 12.7194L51.7814 21.7252C51.9948 21.8173 52.1766 21.9699 52.3042 22.1642C52.4319 22.3584 52.5 22.5858 52.5 22.8182C52.5 23.0507 52.4319 23.278 52.3042 23.4723C52.1766 23.6665 51.9948 23.8191 51.7814 23.9112L31.0543 32.917C30.7221 33.0627 30.3634 33.1385 30.0007 33.1395C29.6379 33.1406 29.2788 33.067 28.9457 32.9232Z" fill="#EF372E" stroke="#9C0000" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M7.5 22.5L7.5 40" stroke="#9C0000" stroke-miterlimit="10" stroke-linecap="round"/>
              </svg>
            </div>
            <p className="autopan-sec2__list-title">
              Обучаем в группах и индивидуально
            </p>
            <p className="autopan-sec2__list-text">
              Просоединяйтесь к смешаной группе или бронируйте АвтоПАНораму под запрос только для вашего агентства.
            </p>
          </li>
          <li>
            <div className="autopan-sec2__list-img">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0475 43.0002L11.2779 46.0435C11.1267 46.0819 10.9687 46.0852 10.816 46.0533C10.6633 46.0215 10.5199 45.9552 10.3967 45.8595C10.2735 45.7639 10.1737 45.6414 10.105 45.5013C10.0362 45.3613 10.0003 45.2075 10 45.0515V16.9052C10.0018 16.604 10.1035 16.3118 10.2891 16.0745C10.4747 15.8372 10.7337 15.6681 11.0256 15.5937L23.0979 12.5L36.7675 19.3937L48.7221 16.3335C48.8722 16.292 49.0298 16.2858 49.1826 16.3153C49.3355 16.3449 49.4794 16.4094 49.6032 16.5039C49.727 16.5983 49.8272 16.7201 49.896 16.8598C49.9649 16.9994 50.0004 17.1531 50 17.3087V45.455C50.0011 45.757 49.9005 46.0506 49.7145 46.2885C49.5284 46.5264 49.2677 46.6948 48.9743 46.7665L36.7171 49.8602L23.0475 43.0002Z" fill="#EF372E" stroke="#9C0000" stroke-miterlimit="10"/>
                <path d="M22.9802 12.6177V43.1683" stroke="#9C0000" stroke-miterlimit="10"/>
                <path d="M36.6667 19.1079V49.6586" stroke="#9C0000" stroke-miterlimit="10"/>
              </svg>
            </div>
            <p className="autopan-sec2__list-title">
              Дарим PDF-<br/>путеводитель
            </p>
            <p className="autopan-sec2__list-text">
              Присылаем актуальную информацию по объектам перед поездкой.
            </p>
          </li>
        </ul>
      </div>
      <div className="autopan-sec3 panschool-sec3">
        <div className="panschool-sec3__left">
          <div className="panschool-sec3__lady">
            <img src={autopanSec3Lady} alt=""/>
          </div>
          <p className="panschool-sec3__title">«Чтобы уверенно ориентироваться в рынке новостроек, нужно посмотреть объекты своими глазами»</p>
        </div>
        <div className="panschool-sec3__right">
          <div className="autopan-sec3__right-title">
            <div className="autopan-sec3__right-col1">
              <p className="autopan-sec3__right-name">
                Тамара Горохова
              </p>
              <p className="autopan-sec3__right-text autopan-sec3__right-text_post">Эксперт АвтоПАНорама</p>
            </div>
            <div className="autopan-sec3__right-col2">
              <p className="autopan-sec3__right-text">Автор интенсива по новостройкам АвтоПАНорама,
                и АвтоПан — сервиса продающего показа для агентов с клиентом, специалист по продажам первичной недвижимости.</p>
            </div>
          </div>
          <ul className="panschool-sec3__list">
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>

              В недвижимости с 2011 года
            </li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Проводит 200+ обучений по новостройкам в год</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Амбассадор преподавания в экскурсионном и выездном формате</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Дипломированный специалист по истории и градостроительству Петербурга</li>
            <li>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.74173L7.65459 13.2849L20.4008 0.715088" stroke="#EF372E" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
              Автор 10 интерактивных обучающих программ, которые прошли 10 000+ специалистов по недвижимости </li>

          </ul>
        </div>
      </div>
      <div className="autopan-sec4">
        <p className="autopan-sec4__title">1000+ партнеров повышают компетенции с АвтоПАНорамой</p>
        <ul className="autopan-sec4__list">
          <li>
            <div className="autopan-sec4__list-logo">
              <img src={autopanSec4Log1} alt=""/>
            </div>
            {isTablet && <img className="autopan-sec4__girls" src={autopanSec4Girl1} alt=""/>}
            <p className="autopan-sec4__list-title">Начинающие специалисты</p>
            <p className="autopan-sec4__list-text">Получают целостное понимание<br/>
              о каждой локации и портрет<br/> покупателя — всего за 3 часа<br/>
              с опытным наставником.</p>
          </li>
          <li>
            <div className="autopan-sec4__list-logo">
              <img src={autopanSec4Log2} alt=""/>
            </div>
            {isTablet && <img className="autopan-sec4__girls" src={autopanSec4Girl2} alt=""/>}
            <p className="autopan-sec4__list-title">Региональные партнеры</p>
            <p className="autopan-sec4__list-text">Эффективно знакомятся <br/> с рынком Санкт-Петербурга <br/>и подбирают лучший объект <br/>под запрос клиента.</p>
          </li>
          <li>
            <div className="autopan-sec4__list-logo">
              <img src={autopanSec4Log3} alt=""/>
            </div>
            {isTablet && <img className="autopan-sec4__girls" src={autopanSec4Girl3} alt=""/>}
            <p className="autopan-sec4__list-title">Профессионалы в недвижимости</p>
            <p className="autopan-sec4__list-text">Поддерживают связь с ведущими участниками рынка и первыми узнают о стартах продаж, готовящихся изменениях в сфере недвижимости.</p>
          </li>

          {isSmallLaptop &&
            <li className="autopan-sec4__list-lastitem">
              <p className="panschool-sec4__item-title">
                Записаться на групповую АвтоПАНораму могут 1-3 специалиста — от начинающих до профессионалов.
                <br/>
                <br/>
                Забронируем для вас место в группе или организуем  выезд только для сотрудников вашего агентства.
              </p>
            </li>
          }
        </ul>

        <div className="panschool-sec4__bottom">
          {!isSmallLaptop &&
            <div className="panschool-sec4__bottom-left">
              Записаться на групповую АвтоПАНораму могут 1-3 специалиста — от начинающих до профессионалов.
              <br/>
              <br/>
              Забронируем для вас место в группе или организуем  выезд только для сотрудников вашего агентства.
            </div>
          }
          <div className="panschool-sec4__bottom-right">
            <button className="btn btn_primary h6"  onClick={handleScroll}>Оставить заявку</button>
          </div>
        </div>

      </div>
      <div className="autopan-sec5">
        <div className="autopan-sec5__left">
          <p className="autopan-sec5__title">Повысите навык презентации
            и увеличите конверсию в сделки</p>
          {!isSmallLaptop && <div>
            <button className="btn btn_primary h6"
                    onClick={() => window.open('https://docs.google.com/spreadsheets/d/1ao8CL4JnxbNbjHCzJ3K1Bl3Z5qB19VrCd4PtME3czKA/edit?gid=186001220#gid=186001220', '_blank')}
            >Посмотреть расписание</button>
          </div>}
        </div>
        <div className="autopan-sec5__right">
          <div className="autopan-sec5__parzl">
            Объективно
            оцените жилые
            комплексы и локации
          </div>
          <div className="autopan-sec5__parzl">
            Изучите рынок новостроек Петербурга
          </div>
          <div className="autopan-sec5__parzl">
            Получите
            эксклюзивную информацию об объектах и застройщиках
          </div>
          <div className="autopan-sec5__parzl">
            Первыми узнаете
            о новых стартах продаж
          </div>
        </div>

        {isSmallLaptop && <div className="autopan-sec5__mobile-btn">
          <button className="btn btn_primary h6"
                  onClick={() => window.open('https://docs.google.com/spreadsheets/d/1ao8CL4JnxbNbjHCzJ3K1Bl3Z5qB19VrCd4PtME3czKA/edit?gid=186001220#gid=186001220', '_blank')}

          >Посмотреть расписание</button>
        </div>}
      </div>

      <div className="autopan-sec6 panschool-sec6" ref={formRef}>
        <p className="panschool-sec1__maintitle">Оставьте заявку — позвоним,<br/> чтобы обсудить детали</p>
        <div className="panschool-sec6__bottomline">
          <img src={panschoolBottomLine} alt=""/>
        </div>

        <div className="panschool-sec6__form">



          <form action="#" className="sor__form">
            <div className="sor__form-box">
              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-name"
                >
                  <span className="input__label">Имя</span>
                  <input
                    id="modal-reservation-name"
                    name="name"
                    value={userName}
                    className={"input__control" + (errors.name ? " input__error" : "")}
                    placeholder="Ваше имя"
                    onChange={e => setUserName(e.target.value, "name")}
                  />
                  {!!errors.name && (
                    <span className="input__error-label">
                          {errors.name}
                        </span>
                  )}
                </label>
              </div>

              <div className="sor__form-item">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="modal-reservation-phone"
                >
                  <span className="input__label">Телефон</span>
                  <InputMask
                    id="modal-reservation-phone"
                    className={
                      "input__control" + (errors.phone ? " input__error" : "")
                    }
                    mask="+7 (999) 999-99-99"
                    autoComplete="off"
                    maskChar=" "
                    name="phone"
                    placeholder="Ваш телефон"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                  {errors.phone && (
                    <span className="input__error-label">
                          {errors.phone}
                        </span>
                  )}
                </label>
              </div>
              <div className="sor__form-item">
              <SubmitButton
                type="button"
                className="btn_full button button_view_default"
                onClick={() => submitRequestsForm("Заявка на консультацию")}
              >
                Оставить заявку
              </SubmitButton>
              </div>

            </div>

            <div className="sor__form-footer">
              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    'checkbox__box' +
                    (errors.agreement ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    checked={agree}
                    onChange={e => setAgree(e.target.checked)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"

                >
                  Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                </label>
              </div>

              <div className="checkbox checkbox_view_invert" style={{marginTop: 10}}>
                <div
                  className={
                    'checkbox__box' +
                    (errors.agreement2 ? " checkbox__box-error" : "")
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement2"
                    name="agree2"
                    checked={agree2}
                    onChange={e => setAgree2(e.target.checked)}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement2"
                  ></label>
                  <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement2"

                >
                  Ознакомлен с <Link to="/files/4470" target="_blank" rel="noopener noreferrer" className="link">регламентом </Link> сервиса
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>


      <div className="autopan-sec7">
          <h2 className="autopan-sec1__title">Часто задаваемые вопросы</h2>

        <div className="sor__prog-list sor__prog-list_autopan">
          <Accordeon title={`Сколько стоит АвтоПАНорама?`} number={1}>
            <div className="sor__prog-text">
              <ol className="sor__prog-ol">
                <li>Место в смешанной группе из 3-х человек - 2500</li>
                <li>Место в смешанной группе из 2-х человек - 3750</li>
                <li>Поездка для вашего агентства, 3 места - 7500</li>
                <li>Поездка 1 на 1 с экспертом – 7500</li>
              </ol>
            </div>
          </Accordeon>

          <Accordeon title={`Какие локации можно посмотреть?`} number={2}>
            <div className="sor__prog-text">
              <p>
                Мы предлагаем 18 маршрутов, а также можем составить индивидуальный маршрут под ваш запрос.
              </p>
              <p>Топ-5 экскурсий:</p>
              <ol className="sor__prog-ol">
                <li>• «Северные локации»<br/>
                  Приморский, Выборгский, Всеволожский (Бугры, Мурино) и Красногвардейский районы</li><br/>
                <li>• «Восточные локации»<br/>
                  Всеволожский (Кудрово, Янино, Новосаратовка) и Невский районы</li><br/>
                <li>•  «Центральные локации 1»<br/>
                  Московский и Адмиралтейский районы</li><br/>
                <li>•  «Центральные локации 2»<br/>
                  Петроградский и Василеостровский районы</li><br/>
                <li>•  «Южные локации»<br/>
                  Московский, Пушкинский, Ломоносовский (Новоселье, Новогорелово) и Красносельский районы</li>
              </ol>
            </div>
          </Accordeon>

          <Accordeon title={`Специалистам какого уровня подходит АвтоПАНорама?`} number={3}>
            <div className="sor__prog-text">
              <p>
                Поехать в смешанной группе могут партнеры
                любого уровня: начинающие, региональные
                и опытные специалисты. В этом случае
                информация дается усредненно.
              </p>
              <br/>
              <p>
                Мы можем провести АвтоПАНораму конкретно
                под ваш запрос — для этого обратитесь
                к администратору обучающего центра
                Наталье Меланич.
              </p>
            </div>

          </Accordeon>

          <Accordeon title={`Будет ли посещение объектов?`} number={4}>
            <div className="sor__prog-text">
              <p>
                Нет, АвтоПАНорама — обзорная поездка, цель которой — посмотреть максимум объектов
                и локаций за 3 часа и получить целостное
                представление о районах города.
              </p>
              <p>
                Во время интенсива предусмотрены фотостопы на 1-2 минуты, чтобы отснять фото- и видео-контент для клиентов.
              </p>
            </div>

          </Accordeon>



          <Accordeon title={`Могу ли я отменить поездку?`} number={5}>
            <div className="sor__prog-text">
              <p>
                Да, но не позднее, чем за 3 рабочих дня.
              </p>

            </div>

          </Accordeon>

        </div>
      </div>

    </div>
  )
}
const Accordeon = ({ number, title, children }) => {

  const defaultState = () => number === 1;
  const [isOpened, setIsOpened] = useState(defaultState);


  return (
    <div className="sor__prog-item" key={`sor-prog-${number}`}>
      <div className="sor__prog-header" onClick={() => setIsOpened(prevState => !prevState)}>

        <h4 className="h4 sor__h4">{title}</h4>

        <button
          type="button"
          className={`sor__accordion-btn${isOpened ? ` opened` : ``}`}
          aria-label="Открыть панель">
          <svg className="icon icon_more">
            <use xlinkHref="#chevron"></use>
          </svg>
        </button>
      </div>
      <div className={`sor__prog-body${isOpened ? ` opened` : ``}`}>
        {children}
      </div>
    </div>
  );
};
export default AutoPanNew
