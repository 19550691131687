import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import request from "../../api";
import Dropdown from '../../components/Dropdown';
import { errorToast, standartErrorToast, successToast } from '../../components/toasts';
import './index.scss';

// import "../../pages/SellAssigment/index.scss";

const AddPartner = (props) => {
  const citiesList = [
    {
      id: 'spb',
      title: 'Санкт-Петербург',
    },
    {
      id: 'msk',
      title: 'Москва',
    },
    {
      id: 'other',
      title: 'Другой город',
    },
  ];

  const [cityName, setCity] = useState(0);
  const cityValue = () => citiesList.find((item) => item.id === cityName) || { id: 0, title: 'Не выбран'};

  const [formattedCooperations, setFormattedCooperations] = useState([]);
  const [selectedCooperation, setSelectedCooperation] = useState(0);
  // const [cooperationValue, setCooperationValue] = useState({ id: 0, title: 'Не выбран' });

  const cooperationValue = () => formattedCooperations.find((item) => item.id === selectedCooperation) || { id: 0, title: 'Не выбран' };

  const user = useSelector(state => state.user?.info)
  const [name, setName] = useState(user?.name);
  const [lastName, setLastName] = useState(user?.lastName);
  const [phone, setPhone] = useState(user?.phone);
  const [email, setEmail] = useState(user?.email);
  const [agency, setAgency] = useState(user?.workCompany);
  const [checkedPolicy, setCheckedPolicy] = useState(true);
  const [errors, setErrors] = useState({
    name: false,
    lastName: false,
    phone: false,
    email: false,
    checkedPolicy: false,
    agency: false,
    selectedCooperation: false,
    city: false,
  })

  useEffect(() => {
    request("panpartner:b24.ajax", "getFormsCooperation", { city: cityName }).then(
      data => setFormattedCooperations(Object.entries(data?.formsCooperation).map(([key, value]) => ({ id: key, title: value }))),
      // errors => console.log(errors)
    )
  }, [cityName])

  const resetForm = () => {
    setName(user?.name)
    setLastName(user?.lastName)
    setPhone(user?.phone)
    setEmail(user?.email)
    setAgency(user?.workCompany)
    setCity(0)
    setSelectedCooperation(0)
  }
  
  // submit

  const handleSubmit = () => {
    setErrors({
      name: !name,
      lastName: !lastName,
      phone: !phone,
      email: !email,
      checkedPolicy: !checkedPolicy,
      agency: !agency,
      selectedCooperation: !selectedCooperation,
      city: !cityName,
    })
    if (!name || !phone || !lastName || !email || !checkedPolicy || (!agency && !selectedCooperation) || !cityName) {
      errorToast(<div>Заполните обязательные поля</div>)
      return
    }

    const cooperation = formattedCooperations.find(item => item.id === selectedCooperation).title

    const form = {
      "title": `Регистрация на toprealtors / ${cooperation} / ${cityValue().title}`,
      "name": name,
      "lastName": lastName,
      "phones": [phone],
      "emails": [email],
      "city": cityName,
      "formCooperation": selectedCooperation,
      "company": agency,
    }

    request("panpartner:b24.ajax", "addLead", form).then(resp => {
      resetForm()
      successToast(
        <div className="Toastify__text">
          Заявка отправлена
        </div>
      )
    },
      standartErrorToast
    )
  }

  return (
    <section className="sell section section_view_white add-partner">
      <div className="container container_sm">
        <div className="add-partner">
          <div className="sell__title">
            <h1 className="h1">Стать партнером</h1>
          </div>

          <form
            className="add-partner-form"
            // name="reserve-apartment"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <div className="add-partner-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="add-partner-name"
              >
                <span className="input__label">Имя</span>
                <input
                  onChange={e => setName(e.target.value)}
                  name="lastName"
                  id="add-partner-name"
                  className="input__control"
                  placeholder="Имя"
                  value={name}
                />

                {errors.name && !name &&(
                  <span className="input__error-label">
                    Введите имя
                  </span>
                )}
              </label>
            </div>
            <div className="add-partner-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="add-partner-surname"
              >
                <span className="input__label">Фамилия</span>

                <input
                  onChange={e => setLastName(e.target.value)}
                  name="lastName"
                  id="add-partner-lastname"
                  className="input__control"
                  placeholder="Фамилия"
                  value={lastName}
                />

                {errors.lastName && !lastName &&(
                  <span className="input__error-label">
                    Введите фамилию
                  </span>
                )}

              </label>
            </div>

            <div className="add-partner-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="add-partner-phone"
              >
                <span className="input__label">Телефон</span>

                <InputMask
                  onChange={e => setPhone(e.target.value)}
                  className={
                    'input__control'
                  }
                  name="phone"
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  value={phone}
                />
                {errors.phone && !phone && (
                  <span className="input__error-label">
                    Введите телефон
                  </span>
                )}

              </label>
            </div>

            <div className="add-partner-form__item">
              <label
                className="input input_width_available input_type_form"
                htmlFor="add-partner-mail"
              >
                <span className="input__label">E-mail</span>
                <input
                  onChange={e => setEmail(e.target.value)}
                  name="lastName"
                  id="add-partner-email"
                  className="input__control"
                  placeholder="Email"
                  value={email}
                />

                {errors.email && !email &&(
                  <span className="input__error-label">
                    Введите e-mail
                  </span>
                )}
              </label>
            </div>

            <div className="add-partner-form__item">
              <span className="add-partner-form__label">Ваш город</span>
              <Dropdown
                value={cityValue()}
                options={citiesList}
                onChange={(value) => setCity(value)}
              />

              {errors.city && !cityName &&(
                <span className="input__error-label">
                  Выберите город
                </span>
              )}
            </div>

            <div className="add-partner-form__item">
              <span className="add-partner-form__label">Вариант сотрудничества</span>
              <Dropdown
                value={cooperationValue()}
                options={formattedCooperations && formattedCooperations}
                onChange={(value) => setSelectedCooperation(value)}
              />

              {errors.selectedCooperation && !selectedCooperation &&(
                <span className="input__error-label">
                  Выберите вариант
                </span>
              )}
            </div>

            {(selectedCooperation === '1875' || selectedCooperation === '1876') &&
              <div className="add-partner-form__item full-width">
                <label
                  className="input input_width_available input_type_form"
                  htmlFor="add-partner-mail"
                >
                  <span className="input__label">Название компании</span>
                  <input
                    onChange={e => setAgency(e.target.value)}
                    name="agency"
                    id="add-partner-agency"
                    className="input__control"
                    value={agency}
                  />

                  {errors.agency && !agency &&(
                    <span className="input__error-label">
                      Введите название компании
                    </span>
                  )}
                </label>
              </div>
            }

            <div className="checkbox checkbox_view_invert">
              <div
                className={
                  'checkbox__box' +
                  (errors.agree ? ' checkbox__box-error' : '')
                }
              >
                <input
                  className="checkbox__control"
                  type="checkbox"
                  id="modal-reservation-agreement"
                  name="agree"
                  checked={checkedPolicy}
                  onChange={(e) => setCheckedPolicy(e.target.checked)}
                />
                <label
                  className="checkbox__input"
                  htmlFor="modal-reservation-agreement"
                ></label>
                <div className="checkbox__marker"></div>
              </div>
              <label
                className="checkbox__label"
                htmlFor="modal-reservation-agreement"
              >
                Отправляя нам данную форму вы соглашаетесь с
                &nbsp;
                <Link to="/policy" className="preview__footer-policy" target="_blank">
                  политикой конфиденциальности
                </Link>
              </label>
            </div>

            {errors.checkedPolicy && !checkedPolicy &&(
              <span className="input__error-label">
                Необходимо согласиться с правилами обработки данных
              </span>
            )}

            <div className="add-partner-form__submit">
              <button
                className="button button_view_default btn"
                type="button"
                onClick={handleSubmit}
              >
                Отправить заявку
              </button>
            </div>
          </form>
        </div>


      </div>
    </section>
  );
};

export default AddPartner;
