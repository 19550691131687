import React from "react";

const LawyersChecked = () => {
  return (
  <div className="lawyers-checked">
    <div className="lawyers-checked__block">
      <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 7.94043L1.8808 6.66074L8.64815 10.295L20.3168 0.316406L21.5 1.38098L9.15807 16.3164L0.5 7.94043Z" fill="#2CBA2E"/>
      </svg>
      <span>Объект проверен ПАН Юрист</span>

      <svg className="lawyers-checked__background" width="110" height="66" viewBox="0 0 110 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <path d="M78.8061 28.9918L61.3961 8.61434C60.8808 7.94727 60.1619 8.09808 59.5208 8.62013L44.7276 22.1877C44.0865 22.7097 44.2422 23.4522 44.7575 24.1193L51.1439 31.3062L13.475 62.8497C13.475 62.8497 10.581 66.9449 14.0319 70.8719C17.4827 74.7989 22.3299 71.0691 22.3299 71.0691L55.8589 37.681L62.2453 44.868C62.7606 45.5351 63.4795 45.3842 64.1206 44.8622L78.9138 31.2947C79.4771 30.4014 79.3213 29.6589 78.8061 28.9918Z" fill="#8F95AC"/>
          <path d="M58.8195 5.27992L41.5398 21.3069C39.6165 22.873 37.3038 22.583 35.836 20.953C34.3682 19.3231 34.5421 16.5736 36.1059 15.0828L53.3856 -0.944122C54.9494 -2.43487 57.6215 -2.22024 59.0893 -0.590272C60.635 1.41093 60.3833 3.78917 58.8195 5.27992Z" fill="#8F95AC"/>
          <path d="M88.1354 38.118L70.8557 54.145C69.2919 55.6358 66.6198 55.4211 65.152 53.7912C63.6841 52.1612 63.858 49.4117 65.4218 47.921L82.7015 31.894C84.2653 30.4033 86.9375 30.6179 88.4053 32.2479C89.8731 33.8778 89.6992 36.6273 88.1354 38.118Z" fill="#8F95AC"/>
          <path opacity="0.5" d="M103.081 64.2082L102.147 59.7534C101.602 57.1547 98.9774 55.3797 96.461 55.9076L67.3428 62.0155C64.8264 62.5434 63.1367 65.2232 63.6818 67.8219L64.6163 72.2768L60.5959 78.1585L69.9424 76.198L99.0606 70.09L109.126 67.9786L103.081 64.2082Z" fill="#8F95AC"/>
        </g>
      </svg>

    </div>
  </div>
  );
};

export default LawyersChecked;

