import { meanBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getViewWindow } from '../actions/complex';
import ApartmentMap from '../components/ApartmentMap';
import { standartErrorToast } from '../components/toasts';
import { SuburbanPresentationMap } from './SuburbanPresentation/SuburbanPresentationMap';

const YandexMapManager = (props) => {
    const { id, city, type = "block.detail.ajax" } = useParams();
    const [data, setData] = useState(null)
    const urlParams = new URLSearchParams(window.location.search)
    const mapZoom = urlParams.get("mapZoom")
    const enableResetButton = urlParams.get("enableResetButton")
    const suburbanMap = urlParams.get("suburbanMap")

    useEffect(() => {
        getViewWindow(`panpartner:${type}`, { id, city }).then(data => setData(suburbanMap? data: data.viewFromWindow), standartErrorToast)
    }, [])

    if (!data) return ""
    const getCenter = () => {
        const flattedCords = data.cords.flat()
        const averageX = meanBy(flattedCords, item => item[0])
        const averageY = meanBy(flattedCords, item => item[1])
        return [averageX, averageY]
    }

    return <div style={{
        width: "100%",
        height: "100%",
        position: "absolute",
    }}>
        {suburbanMap ? <SuburbanPresentationMap zoom={mapZoom || 16} cords={data.coords} mapCenter={data.coords} address={data.title} /> :
            <ApartmentMap {...data} center={getCenter()} mapZoom={mapZoom || 16} enableResetButton={enableResetButton} />
        }

    </div>
}
export default YandexMapManager