import take from 'lodash/take';
import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getUrl } from '../../api';
import emptyPlan from '../../assets/images/emptyPlan.svg';
import useMedia from '../../hooks/useMedia';
import { formatMoney, getPricePerMeter } from '../../utils';
import Table from '../core/Table2';
import LoadMoreSpinner from '../LoadMoreSpinner';
import PriceTitle from '../PriceTitle';
import SquareTitle from '../SquareTitle';
import ComplexView from './ComplexView';

import {
  getColumns,
  getMobileColumns,
  loadItems,
  priceFormat,
  whitelistForCardClick,
} from './Utils';
import Card from '../core/Card';
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import AptModal from "../AptModal";
const viewTypes = ['table', 'cards'];

const getRowLink = (record, isPublic, isAssignment, savedFilterXmlId, city ) => {
  if (isAssignment) {
    return `/assignments/${record.id}${window.location.search}`;
  }
  return isPublic
    ? `/public/${savedFilterXmlId}/${city}/apartment/${record.id}`
    : `/realty/${city}/apartment/${record.id}${window.location.search}`;
};


export const CardsApartments = (props) => {
  const { items, expand, isPublic, savedFilterXmlId, loading, innerRef, quickViewEnabled, defaultTitle, columnsType } =
    props;

    const { city } = useParams();

  const [selectedItem, setSelectedItem] = useState(false)

  const [openAptModal, closeAptModal] = useModal(
    () => (
      <PanModal title={'Быстрый просмотр'} closeModal={closeAptModal} isBig>
        <AptModal selectedItem={selectedItem} city={city} closeModal={closeAptModal}  defaultTitle={defaultTitle} columnsType={columnsType} />
      </PanModal>
    ),
    [selectedItem, defaultTitle]);

  return (
    <div
      className="sf__cards-list sf__cards-list_flats"
      style={{ display: expand ? 'grid' : 'none' }}
      ref={innerRef}
    >
      {items.map((item) => {
        const {
          id,
          flatType,
          link = getRowLink(
            item,
            isPublic,
            item.objectType === 'assignment',
            savedFilterXmlId,
            city
          ),
          plan,
          status,
          decoration,
          costWithDiscount,
          squareTotal,
          floor,
          number,
        } = item;
        const cardTitle = `${priceFormat(costWithDiscount)}`;
        const cardSubtitle = `${squareTotal} м², ${floor} эт., № ${number}`;

        return (
          <Card
            key={id}
            topLabel={flatType}
            isBordered
            isFlat
            link={!quickViewEnabled && link}
            title={cardTitle}
            src={getUrl(plan.src)}
            status={status}
            bottomText={decoration}
            subtiltle={cardSubtitle}
            quickViewEnabled={quickViewEnabled}
            onClick={()=>{
              setSelectedItem(item)
              quickViewEnabled && openAptModal()
            }}
          />
        );
      })}
      {loading && <LoadMoreSpinner loading={true} />}
    </div>
  );
};

const TableApartments = (props) => {
  const isMobile = useMedia('isMobile');
  const isLaptop = useMedia('isLaptop');
  const isDesktop = useMedia('isDesktop');

  const { active, items, isPublic, savedFilterXmlId } = props;

  const { city } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const tableRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isShowToopltip, setIsShowToopltip] = useState(false);

  return (
    <div
      className={`flats__row-table ${active ? `is-active` : ``}`}
      style={{ paddingBottom: !isMobile && isOpen && 15 }}
      ref={tableRef}
    >
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={
          isLaptop ? getMobileColumns(city) : getColumns(isDesktop, city)
        }
        dataSource={items}
        pagination={false}
        locale={{ emptyText: 'Загрузка данных...' }}
        customPagination={true}
        firstPageCount={10}
        pageCount={50}
        onChange={() => {}}
        // expandable={isMobile ? {
        //   expandedRowRender: record => expandedRow(record),
        // } : false}
        rowClassName={(record, index) => {
          if (record.isAction) return 'apartments-action-row';
          return (index + 1) % 2 === 0 ? 'ant-table-second-color' : '';
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              window.open(
                getRowLink(
                  record,
                  isPublic,
                  record.objectType === 'assignment',
                  savedFilterXmlId,
                  city
                ),
              );
            },
            onMouseMove: (e) => {
              if (
                !record.isAction ||
                isMobile ||
                e.target.tagName === 'IMG' ||
                e.target.classList.toString().includes('status')
              )
                return setIsShowToopltip(false);
              const targetBounds = tableRef.current.getBoundingClientRect();
              setTooltipPosition({
                top: e.clientY - targetBounds.y,
                left: e.clientX - targetBounds.x + 25,
              });
              setIsShowToopltip(true);
            },
            onMouseLeave: () => {
              setIsShowToopltip(false);
            },
          };
        }}
      />
      <div
        className="action-row-tooltip"
        style={{
          visibility: isShowToopltip ? 'visible' : 'hidden',
          ...tooltipPosition,
        }}
      >
        На данную квартиру распространяется скидка от застройщика. Размер скидки
        и другие подробности акции уточняйте у менеджера.
      </div>
    </div>
  );
};

const ApartmentRow = (props) => {
  const isLaptop = useMedia('isLaptop');
  const {
    roomTitle,
    count,
    minSquareTotal,
    maxSquareTotal,
    minCostWithDiscount,
    maxCostWithDiscount,
    view,
    items,
    isPublic,
    savedFilterXmlId,
    onClick,
    buildingId,
    roomTypeId,
    minMeterPrice,
    fetchItems,
  } = props;

  const [active, setActive] = useState(false);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setPage(0);
    setData(take(items, 15));
    setHasNextPage(items.length > 15);
  }, [items]);

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage((p + 1) * 15 < items.length);
      setLoading(false);
    });
  }
  const infiniteRef = useInfiniteScroll({
    loading: loading || !active,
    hasNextPage,
    threshold: 50,
    onLoadMore: handleLoadMore,
    scrollContainer: 'window',
  });

  return (
    <div className="complex__appartments-tab active">
      <div className="complex__appartments-row">
        <div
          className={`flats__row ${active ? `is-active` : ``}`}
          onClick={() => {
            setActive(!active);
            onClick();
            if (items?.length > 0) return;
            fetchItems({
              'filter[buildings]': buildingId,
              'filter[rooms]': roomTypeId,
            });
          }}
        >
          <div className="flats__row-col flats__row-rooms">{roomTitle}</div>
          <div className="flats__row-concat">
            {!isLaptop && (
              <div className="flats__row-col flats__row-count">{count}</div>
            )}
            <div className="flats__row-col flats__row-square">
              <SquareTitle
                minSquare={minSquareTotal}
                maxSquare={maxSquareTotal}
              />
            </div>
            <div className="flats__row-col flats__row-price">
              <PriceTitle
                maxPrice={maxCostWithDiscount}
                minPrice={minCostWithDiscount}
              />
            </div>
            <div className="flats__row-col flats__row-price">
              от {formatMoney(minMeterPrice)} тыс. руб. / м²
            </div>
          </div>
          {
            // isMobile
            //   ? <div className="flats__row-col flats__row-count">{items.length}</div>
            //   : <div className="flats__row-col flats__row-info">Показать все</div>
          }

          <div className="flats__row-col flats__row-action">
            <button className="button button_view_round button_with_icon">
              <svg className="icon icon_collapse">
                <use xlinkHref="#arrow_bottom"></use>
              </svg>
            </button>
          </div>
        </div>
        {active && (
          <>
            {view === 'cards' ? (
              <CardsApartments
                items={data}
                expand={active}
                isPublic={isPublic}
                savedFilterXmlId={savedFilterXmlId}
                innerRef={infiniteRef}
                loading={loading}
              />
            ) : (
              <TableApartments
                items={items.map((item) => {
                  let tmp = { ...item };
                  tmp.costPerMeter = parseInt(
                    getPricePerMeter(item.costWithDiscount, item.squareTotal),
                  );
                  return tmp;
                })}
                active={active}
                isPublic={isPublic}
                savedFilterXmlId={savedFilterXmlId}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ApartmentList = (props) => {
  const {
    items,
    innerRef,
    isPublic,
    savedFilterXmlId,
    onClick,
    getApartments,
    city,
    id,
  } = props;

  const isLaptop = useMedia('isLaptop');

  const [view, setView] = useState('table');
  const [data, setData] = useState(items);
  const [active, setActive] = useState(false);
  const [currentBuilding, setCurrentBuilding] = useState(items?.[0]);

  const handleClick = () => setActive(!active);

  const loadApartments = (params) => getApartments(params);

  const changeBuildings = (building) => {
    let tmp = [building];
    if (building.buildingId === 'all') {
      tmp = [...items];
    }

    setCurrentBuilding(building);
    setData(tmp);
  };
  useEffect(() => {
    setData(items);
    return; // убрать эти две строки если нужен будет дропдаун с выбором корпуса
    if (currentBuilding.buildingId === 'all') {
      setData(items);
      return;
    }
    const findedBuilding = items.find(
      (item) => item.buildingId == currentBuilding.buildingId,
    );
    setData([findedBuilding]);
  }, [items]);

  return (
    <div
      className="complex-results-wrapper complex-results-wrapper_table2"
      key={'complex-results' + items?.length}
    >
      {items?.length > 0 && (
        <section className="section section__complex-results" ref={innerRef}>
          <div id="anchor-flats"></div>
          <div className="wrapper">
            <div className="complex__appartments-navigation">
              {!isLaptop && (
                <div className="complex__views complex__views_space-between">
                  {/*
                  <div className="complex__select">
                     <Dropdown
                      options={items}
                      customHandler={changeBuildings}
                      value={currentBuilding?.buildingId}
                      valueKey={"buildingId"}
                  </div>
                    />
                    */}

                  <ul className="complex__view omplex__view_height-auto">
                    {viewTypes.map((item) => (
                      <li
                        className={`complex__view-item view__${item} ${
                          item === view ? `active` : ``
                        }`}
                        key={item}
                        onClick={() => setView(item)}
                      >
                        <button type="button" className="button view__btn">
                          <svg className="view__icon">
                            <use
                              xlinkHref={`#view_${
                                item === 'cards' ? `cards2` : `rows2`
                              }`}
                            ></use>
                          </svg>
                          <span>
                            {item === 'cards' ? `Планировки` : `Список`}
                          </span>
                        </button>
                      </li>
                    ))}

                    <li
                      className={`complex__view-item view__chess`}
                      onClick={() => {}}
                    >
                      {' '}
                      {!isPublic && (
                        <Link
                          to={`/realty/${city}/chess/${id}`}
                          target="_blank"
                        >
                          <button type="button" className="button view__btn">
                            <svg className="view__icon">
                              <use xlinkHref={`#view_chess`}></use>
                            </svg>
                            <span>Шахматка</span>
                          </button>
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      {data && data.length > 0 && (
        <section className="section">
          <div className="wrapper">
            {data.map(
              (building) =>
                building?.types && (
                  <div
                    className="complex__appartments table2"
                    key={building.buildingId}
                  >
                    <div className="complex__title-info">
                      <h4 className="h4">{building.title}</h4>
                      <div className="complex__title-text">
                        {building.ending && (
                          <span>Срок сдачи — {building.ending}</span>
                        )}
                        {building.issuingKeys && (
                          <span>Ключи — {building.issuingKeys}</span>
                        )}
                      </div>
                    </div>
                    {building.types.map((type) => {
                      // type.items.forEach((subItem) => {
                      //   if (!subItem.section || subItem.section.length === 0) {
                      //     subItem.section = "-";
                      //   }
                      // });
                      return (
                        <ApartmentRow
                          {...type}
                          key={`${type.roomTitle}`}
                          view={view}
                          isPublic={isPublic}
                          savedFilterXmlId={savedFilterXmlId}
                          onClick={handleClick}
                          buildingId={building.buildingId}
                          fetchItems={loadApartments}
                        />
                      );
                    })}
                  </div>
                ),
            )}
            {isLaptop && active && (
              <ComplexView
                viewTypes={viewTypes}
                view={view}
                setView={setView}
                onClick={onClick}
              />
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default ApartmentList;
