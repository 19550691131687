import minBy from 'lodash/minBy';
import { media } from '../../../../utils';

const clusterTemplateMarkup = `<div style="font-family: Formular;color: #fff;font-weight: 700;font-size: 12px;top: -40px;position: absolute;width: 110px;height: 34px;">
<div style="width: 33px; text-align: center; height: inherit; top: 0;" class="map-circle-text">{{ properties.geoObjects.length }}</div>
<div style="font-family: Formular;color: #fff;font-weight: 400;font-size: 12px;top: 0px;left: 38px;position: absolute;height: 15px;white-space: nowrap;">от {{ properties.geoObjects|price }} млн.</div>
</div>`;
const iconTemplateMarkupNew = currency => `<div style="font-family: Formular;color: #fff;font-weight: 400;font-size: 12px;top: 6px;left: 20px;position: absolute;white-space: nowrap;"><span>{{${currency ? `properties.minPrice.${currency}` : "properties.minPrice"}}} млн.</div>`;
const clusterTemplateMarkupNew = `<div style="font-family: Formular;color: #fff;font-weight: 700;font-size: 12px;top: -37px;position: absolute;left: 25px;width: 60px;height: 60px;">
<div style="width: 60px; text-align: center; height: inherit; top: 0; " class="map-circle-text">{{ properties.geoObjects.length }}</div>
</div>`;
const iconTemplateMarkup = currency => `<div style="font-family: Formular;color: #fff;font-weight: 400;font-size: 12px;top: 6px;left: 10px;position: absolute;white-space: nowrap;"><span>от {{${currency ? `properties.minPrice.${currency}` : "properties.minPrice"}}} млн.</div>`;

const mapButtonTemplate = `<button title="{{ data.title }}" class="button button_view_ghost {% if state.selected %}is-active{% endif %}">
<svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.1875 13.8726C0.1875 6.30243 6.31378 0.176147 13.8839 0.176147V1.67615C7.14221 1.67615 1.6875 7.13086 1.6875 13.8726H0.1875ZM7.96882 9.25391C7.77128 8.4709 8.48187 7.76032 9.26487 7.95786L9.30777 7.96868L20.1179 12.0992C20.8766 12.333 21.1285 13.3002 20.5556 13.8731L20.5285 13.9002L18.5628 15.5007L21.3688 18.3067C21.8064 18.7443 21.8083 19.4539 21.3695 19.8927L19.9037 21.3585C19.4649 21.7973 18.7553 21.7955 18.3176 21.3578L15.5116 18.5519L13.9112 20.5176L13.8841 20.5447C13.3118 21.117 12.3433 20.8664 12.1105 20.1054L7.97965 9.29684L7.96882 9.25391ZM9.7572 9.74617L13.2679 18.9319L15.3975 16.3164L19.111 20.0299L20.0409 19.1L16.3273 15.3865L18.9437 13.2563L9.7572 9.74617Z"/></svg>
<span class="my-button__text">{{ data.content }}</span>
</button>`;
const createRoundButton = (
  content,
  tooltip,
) => `<button title="{{ data.title }}" class="button button_view_ghost ymaps_maps-button map_round_button map-button-with-tooltip {% if state.selected %}is-active{% endif %}">
${content}
<span class="tooltip" style="">{% if !state.selected %}${tooltip}{% endif %}{% if state.selected %}Сбросить{% endif %}</span>
</button>
`;
const roundMapSelectButton = createRoundButton(
  `
{% if !state.selected %}<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8586 9.24538L12.8586 4.75693C12.8586 4.28076 12.6706 3.8241 12.3357 3.4874C12.0009 3.1507 11.5468 2.96155 11.0733 2.96155C10.5998 2.96155 10.1457 3.1507 9.81089 3.4874C9.47607 3.8241 9.28797 4.28076 9.28797 4.75693L9.28797 14.3921L7.33362 10.9785C7.2164 10.7742 7.06029 10.5951 6.87423 10.4514C6.68817 10.3077 6.47579 10.2023 6.24922 10.1412C6.02265 10.0801 5.78632 10.0644 5.55373 10.0951C5.32115 10.1258 5.09685 10.2023 4.89366 10.3202C4.69047 10.4381 4.51235 10.5951 4.36949 10.7822C4.22662 10.9693 4.1218 11.1829 4.06102 11.4107C4.00023 11.6386 3.98466 11.8762 4.01521 12.1101C4.04575 12.344 4.12181 12.5696 4.23904 12.7739C7.14557 18.9404 8.91305 21.0949 12.8586 21.0949C16.8042 21.0949 20 19.4192 20 15.4526L20 13.4095C20 10.5129 12.8586 9.22383 12.8586 9.24538Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.7334 2.96155C16.7859 3.37326 17.7289 4.07808 18.4748 5.01051C19.2208 5.94295 19.7455 7.07274 20.0001 8.29488" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
</svg>{% endif %}
{% if state.selected %}<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 7L12 12L17 7" stroke="#fff" strokeLinecap="round"/>
<path d="M7 17L12 12L17 17" stroke="#fff" strokeLinecap="round"/>
</svg>
{% endif %}
`,
  'Выделить область',
);

const simplifyMapCords = (coordinates, tolerance) => {
  const toleranceSquared = tolerance ** 2;
  const result = [coordinates[0]];
  let prev = coordinates[0];

  for (let i = 1, len = coordinates.length; i < len; i++) {
    const curr = coordinates[i];
    if (
      Math.pow(prev[0] - curr[0], 2) + Math.pow(prev[1] - curr[1], 2) >
      toleranceSquared
    ) {
      result.push(curr);
      prev = curr;
    }
  }
  return result;
};

const defaultLineStyles = {
  strokeColor: '#FF2D23',
  strokeWidth: 1,
  strokeOpacity: 1,
};
const polygonStyles = {
  fillColor: '#FF2D23',
  fillOpacity: 0.1,
  strokeColor: '#FF2D23',
  strokeWidth: 1,
};

const paintOnMapFunction = (provide, meta, extend, EventsPane, MapEvent) => {
  function paintOnMap(map, positionOrEvent, config = {}) {
    const unwantedBehaviors = ['drag',
      'dblClickZoom',
      'multiTouch',
      'rightMouseButtonMagnifier',
      'scrollZoom',]; // что нельзя делать с картой пока рисуешь
    const style = extend(defaultLineStyles, config.style || {});

    const pane = new EventsPane(map, {
      css: { position: 'absolute', width: '100%', height: '100%' },
      zIndex: 550,
      transparent: true,
    });

    map.panes.append('ext-paint-on-map', pane);
    map.behaviors.disable(unwantedBehaviors);

    // Создаём canvas-элемент.
    var canvas = document.createElement('canvas');
    var ctx2d = canvas.getContext('2d');
    var rect = map.container.getParentElement().getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;

    ctx2d.globalAlpha = style.strokeOpacity;
    ctx2d.strokeStyle = style.strokeColor;
    ctx2d.lineWidth = style.strokeWidth;

    canvas.style.width = '100%';
    canvas.style.height = '100%';
    pane.getElement().appendChild(canvas);

    const coordinates =
      positionOrEvent && !media('isMobile')
        ? [toPosition(positionOrEvent)]
        : [];

    var bounds = map.getBounds();
    var latDiff = bounds[1][0] - bounds[0][0];
    var lonDiff = bounds[1][1] - bounds[0][1];
    const fn = function (e) {
      coordinates.push(
        e.offsetX
          ? [e.offsetX, e.offsetY]
          : [e.touches[0].pageX, e.touches[0].pageY],
      );

      ctx2d.clearRect(0, 0, canvas.width, canvas.height);
      ctx2d.beginPath();

      ctx2d.moveTo(coordinates[0][0], coordinates[0][1]);
      coordinates.forEach((cord) => {
        ctx2d.lineTo(cord[0], cord[1]);
      });
      ctx2d.stroke();
    };
    canvas.onmousemove = fn;
    document.ontouchmove = fn;

    // Создаём косвенное обращение, чтобы не сдерживать сборщик мусора.
    var paintingProcess = {
      finishPaintingAt: function (positionOrEvent) {
        paintingProcess.finishPaintingAt = () => {
          throw new Error(
            '(ymaps.ext.paintOnMap) некорректный вызов PaintingProcess#finishPaintingAt. Рисование уже завершено.',
          );
        };

        // Получаем координаты, прежде чем удалить пейн.
        if (positionOrEvent) {
          coordinates.push(toPosition(positionOrEvent)); // на телефонах toPosition не пашет
        }
        delete document.ontouchmove;
        map.panes.remove(pane);
        map.behaviors.enable(unwantedBehaviors);

        // Преобразовываем координаты canvas-элемента в геодезические координаты.
        const clearCords = coordinates.filter((v) => !!v);
        return simplifyMapCords(clearCords, 16).map((x) => {
          // мб всё убрать внутрь функции? #ref
          const lon = bounds[0][1] + (x[0] / canvas.width) * lonDiff;
          const lat = bounds[0][0] + (1 - x[1] / canvas.height) * latDiff;
          return meta.coordinatesOrder === 'latlong' ? [lat, lon] : [lon, lat];
        });
      },
    };

    return paintingProcess;
  }

  function toPosition(positionOrEvent) {
    if (positionOrEvent instanceof MapEvent) {
      return positionOrEvent.get('offsetX') ?
        [positionOrEvent.get('offsetX'), positionOrEvent.get('offsetY')] :
        positionOrEvent._sourceEvent.originalEvent.pagePixels
    }
    return positionOrEvent;
  }

  provide(paintOnMap);
};
const clusterMinPriceFilter = (dataManager, features, currency) => {
  const min = minBy(features, (feature) => currency ? feature.properties.minPrice.price : feature.properties.minPrice);
  return currency ? min.properties.minPrice[currency] : min.properties.minPrice;
};
// что можно делать с картой
const behaviors = [
  'drag',
  'dblClickZoom',
  'multiTouch',
  'rightMouseButtonMagnifier',
  'scrollZoom',
];

export {
  behaviors,
  clusterMinPriceFilter,
  clusterTemplateMarkup,
  iconTemplateMarkup,
  mapButtonTemplate,
  paintOnMapFunction,
  polygonStyles,
  simplifyMapCords,
  roundMapSelectButton,
  iconTemplateMarkupNew,
  clusterTemplateMarkupNew
};

