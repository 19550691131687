import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useModal } from 'react-modal-hook';
import { useSelector } from 'react-redux';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import SubmitButton from '../../components/SubmitButton';
import { standartErrorToast, standartSuccessToast } from '../../components/toasts';
import useMedia from '../../hooks/useMedia';
import useUniversalForm from '../../hooks/useUniversalForm';
import { getFormWithUTM } from '../../utils/location';
import './index.scss';



const Form = () => {
  const { handleSubmit, register, errors } = useForm();

  return (
    <form action="#" className="ele-form ele" onSubmit={() => { }}>
      <h2 className="h2 text-center ele__h3">Если ты в деле, спеши заполнить форму</h2>
      <div className="ele-form__container">
        <div className="zl-form__item">
          <label
            className="input input_width_available input_type_form"
            htmlFor="modal-reservation-name"
          >
          <input
            id="modal-reservation-name"
            name="name"
            defaultValue={``}
            className={
              "input__control ele__blue ele__h4" + (errors.name ? " input__error" : "")
            }
            placeholder="Твое имя"
            ref={register({
              required: "Введите имя",
            })}
            />
            {errors.name && (
              <span className="input__error-label">
                {errors.name.name}
              </span>
            )}
          </label>
        </div>

      <div className="zl-form__item">
        <label
          className="input input_width_available input_type_form"
          htmlFor="modal-reservation-phone"
        >
          <InputMask
            id="modal-reservation-phone"
            className={
              "input__control ele__blue ele__h4" + (errors.phone ? " input__error" : "")
            }
            mask="+7 (999) 999-99-99"
            autoComplete="off"
            maskChar=" "
            name="phone"
            placeholder="Твой телефон"
            defaultValue={``}
            inputRef={register({
              required: "Введите телефон",
            })}
          />
          {errors.name && (
            <span className="input__error-label">
              {errors.name.phone}
            </span>
          )}
        </label>
      </div>

        <button type="submit" className="ele__submit ele__btn ele__h4 button button_view_default">Отправляю</button>
      </div>

    </form>
  );
};


const ElevenPan = () => {

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
 
  const user = useSelector(state => state.user)

  const {
    isLoading,
    response,
    send,
  } = useUniversalForm({
    formId: 51,
    isOneResult: 1,
    defaultValues: {
      name: user.info ? `${user.info.name} ${user.info.lastName}` : null,
      phone: user?.info?.phone,
      agentId: user?.info?.id,
      ...getFormWithUTM(true)
    },
  })
  const [Maps, setMaps] = useState(null)
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({ YMaps, Map, Placemark, RulerControl })
  };
  useEffect(()=>dynamicMapImport(), [])
  const [isParticipating, setIsParticipating] = useState(null);
  const onSend = () => {
    send().then(standartSuccessToast, standartErrorToast).then(() => setIsParticipating(true));
  };
  const [openModal, closeModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeModal} title="Если ты в деле, спеши заполнить форму">{children}</MobileModal>
        : <Modal close={closeModal} classes="modal_task modal_eleven">{children}</Modal>
      } >
        <Form />
      </Wrap >
    ));

  const [openMapModal, closeMapModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeMapModal} title="" className="ele-map">{children}</MobileModal>
        : <Modal close={closeMapModal} classes="modal_task modal_eleven">{children}</Modal>
      } >
        <div className="ele-map__container">
          <p className="ele-map__title ele__h4"><b>Адрес мероприятия:</b> ПетроКонгресс, ул. Лодейнопольская, 5</p>
          {Maps && <Maps.YMaps>
            <Maps.Map
              defaultState={{
                center: [59.963791, 30.289368],
                zoom: 17,
              }}
              modules={['templateLayoutFactory', 'Template', "Placemark"]}
              onLoad={setYmaps}
              instanceRef={setMapInstance}
              className="complex__map-wrap"
            >
            </Maps.Map>
          </Maps.YMaps>}
        </div>
      </Wrap >
    ), [Maps]);



  useEffect(() => {
    if (!mapInstance || !ymaps) return
    const placemarkTemplate = ymaps.templateLayoutFactory.createClass(
      `<svg width="58" height="78" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.8.478A28.756 28.756 0 0 0 8.437 8.94 28.934 28.934 0 0 0 0 29.368 56.916 56.916 0 0 0 7.434 56.81a56.704 56.704 0 0 0 19.767 20.385 3.448 3.448 0 0 0 3.52 0 57.702 57.702 0 0 0 19.466-20.506 57.914 57.914 0 0 0 7.415-27.322c0-7.661-3.035-15.01-8.436-20.427A28.756 28.756 0 0 0 28.801.478Zm0 41.73c-2.53 0-5.006-.754-7.11-2.165a12.831 12.831 0 0 1-4.715-5.762 12.876 12.876 0 0 1-.729-7.418 12.851 12.851 0 0 1 3.504-6.574 12.764 12.764 0 0 1 13.95-2.783 12.81 12.81 0 0 1 5.744 4.728 12.868 12.868 0 0 1-1.592 16.212 12.78 12.78 0 0 1-9.051 3.761Z" fill="url(#a)"/><defs><linearGradient id="a" x1="-18.796" y1="1.351" x2="124.952" y2="13.604" gradientUnits="userSpaceOnUse"><stop stopColor="#A34DE3"/><stop offset="1" stopColor="#188FE6"/><stop offset="1" stopColor="#188FE6"/></linearGradient></defs></svg>`,
    )
    const placeMaprk = new ymaps.Placemark([59.963791, 30.289368], {
    }, {
      iconLayout: placemarkTemplate,
      iconOffset: [-29, -78],
    });
    mapInstance.geoObjects
      .add(placeMaprk);
  }, [mapInstance, ymaps])



  return (
    <section className={`ele`}>

      <div className="wrapper">
        <div className="wrapper__center">
          <section className="ele__promo">
              <img className="ele__promo-img ele__promo-img_boy" src={require(`../../assets/images/eleven/boy.png`).default} alt="" />
              <div className="ele__promo-content">
                <h1 className="ele__h1">ПАН 11&nbsp;лет.</h1>
                <p className="ele__subtitle">Ничего лишнего. Много нового.</p>
              <img className="ele__promo-img ele__promo-img_girl" src={require(`../../assets/images/eleven/girl.png`).default} alt="" />
            </div>

            <div className="ele__info">
              <div className="ele__info-item ele__blue ele__h4">
                <b>Бизнес-завтрак</b>
                <span>в кругу коллег с подарками</span>
              </div>
              <div className="ele__info-item ele__pink ele__h4">
                <b>Анонс</b>
                <span>бомбического розыгрыша</span>

                <p className="ele__info-sub">P.S. таких мы еще не делали</p>
              </div>
              <div className="ele__info-item ele__h4">
                <div className="ele__info-box">
                  <b className="ele__time">16 ноября, 9:00</b>
                  <div className="ele__info-about">
                    <span className="ele__blue">Вторник</span>
                    <p className="ele__purple">
                      Встречаемся
                      <button
                        type="button"
                        className="ele__map-opener button ele__purple ele__h4"
                        onClick={openMapModal}
                        >
                        тут
                      </button>
                    </p>
                  </div>
                </div>
                <SubmitButton
                  type="button"
                  className="ele__btn ele__info-btn button ele__h4"
                  onClick={onSend}
                  isLoading={isLoading}
                  disabled={isParticipating || response.isExistResult}
                >
                  {isParticipating || response.isExistResult ? `Уже участвуешь` : `Я в деле`}
                </SubmitButton>
              </div>
            </div>
          </section>

          <section className="ele__whom">
            <div className="ele__whom-box">
              <h2 className="ele__h2">Кому подойдет мероприятие</h2>
              <p className="ele__whom-text">В свой День Рождения мы ждем абсолютно всех</p>
            </div>
            <img className="ele__whom-img" src={require(`../../assets/images/eleven/group.png`).default} alt="" />
          </section>

          <section className="ele__section ele__about">
            <h2 className="ele__h2 ele__about-title">Как будет проходить?</h2>

            <div className="ele__about-item ele__about-item_1">
              <div className="ele__about-left">
                <div className="ele__about-timing">
                  {/* 9:00 */}
                  <img src={require(`../../assets/images/eleven/9_00.svg`).default} alt="" className="ele__clock" />
                  <span className="ele__about-label">Welcome</span>
                </div>
              </div>
              <div className="ele__about-content">
                <p className="ele__h3">Собираемся в&nbsp;Конференц-Холл</p>
              </div>
            </div>

            <div className="ele__about-item ele__about-item_2">
              <div className="ele__about-left">
                <div className="ele__about-timing">
                  {/* 9:30 */}
                  <img src={require(`../../assets/images/eleven/9_30.svg`).default} alt="" className="ele__clock" />
                  <span className="ele__about-label">видео-манифест</span>
                </div>
                <img className="ele__img" src={require(`../../assets/images/eleven/micro.png`).default} alt="" />
              </div>
              <div className="ele__about-content">
                <p className="ele__h3">Начнем наш <span className="ele__purple">Elevator Speech.</span></p>

                <p className="ele__text">
                  За несколько минут поприветствуем друг-друга,
                  поздравим нашу компанию с Днем Рождения, а еще посмотрим крутой видео-манифест,
                  который мы специально подготовили ко Дню Рождения
                </p>
              </div>
            </div>

            <div className="ele__about-item ele__about-item_3 ele__about-item_col">
              <div className="ele__about-header">
                {/* Едем дальше... */}
                <img src={require(`../../assets/images/eleven/title-1.svg`).default} alt="" className="ele__title-svg" />
                <span className="ele__about-label">Digital-сервисы</span>
                <img className="ele__svg-right" src={require(`../../assets/images/eleven/folder.png`).default} alt="" />
              </div>
              <div className="ele__about-container">
                <div className="ele__about-left">
                  <img className="ele__img" src={require(`../../assets/images/eleven/constructor.png`).default} alt="" />
                </div>
                <div className="ele__about-content">
                  <p className="ele__h3">Запускаем цикл нескучных обзоров <span className="ele__blue">digital сервисов.</span></p>

                  <p className="ele__text">
                    Расскажем, как за несколько кликов каждый риелтор может стать лучше, чем он был до этого
                  </p>
                </div>
              </div>

            </div>

            <div className="ele__about-item ele__about-item_4 ele__about-item_col">
              <div className="ele__about-header">
                {/* Лайфхак */}
                <img src={require(`../../assets/images/eleven/title-2.svg`).default} alt="" className="ele__title-svg" />
                <span className="ele__about-label">iPhone 13</span>

              </div>

              <div className="ele__about-container">
                <div className="ele__about-left">
                  <img className="ele__img" src={require(`../../assets/images/eleven/lifehack.png`).default} alt="" />
                </div>
                <div className="ele__about-content">
                  <p className="ele__h3">Как получить новинку от Apple без кредитов.</p>
                  <p className="ele__text">
                    Долгожданный розыгрыш iPhone 13
                  </p>
                </div>
              </div>

            </div>

            <div className="ele__about-item ele__about-item_5 ele__about-item_col">
              <div className="ele__about-header">
                {/* Взлетаем */}
                <img src={require(`../../assets/images/eleven/title-3.svg`).default} alt="" className="ele__title-svg" />
                <span className="ele__about-label">Телеграм-бот</span>
              </div>
              <div className="ele__about-container">
                <div className="ele__about-left">
                  <img className="ele__img" src={require(`../../assets/images/eleven/rocket.png`).default} alt="" />
                </div>
                <div className="ele__about-content">
                  <p className="ele__h3">Технологии уровня Илона Маска. </p>

                  <p className="ele__text">
                    Чаты, боты и все, что упрощает жизнь риелтору
                  </p>
                </div>
              </div>
            </div>

            <div className="ele__about-item ele__about-item_6 ele__about-item_col">
              <div className="ele__about-header">
                {/* Бууум... */}
                <img src={require(`../../assets/images/eleven/title-4.svg`).default} alt="" className="ele__title-svg" />
                <span className="ele__about-label">Анонс</span>
              </div>

              <div className="ele__about-container">
                <div className="ele__about-left">
                  <img className="ele__img" src={require(`../../assets/images/eleven/boom.png`).default} alt="" />
                </div>
                <div className="ele__about-content">
                  <p className="ele__h3">Анонс розыгрыша, который взорвет рынок</p>
                </div>
              </div>
            </div>

            <div className="ele__about-item ele__about-item_7 ele__about-item_col">
              <div className="ele__about-header">
                {/* Наконец-то завтрак! */}
                <img src={require(`../../assets/images/eleven/title-5.svg`).default} alt="" className="ele__title-svg" />
              </div>

              <div className="ele__about-container">
                <div className="ele__about-left">
                  <img className="ele__img" src={require(`../../assets/images/eleven/breakfast.png`).default} alt="" />
                </div>
                <div className="ele__about-content">
                  <p className="ele__h3">Деловое общение с коллегами или приятный нетворкинг</p>
                </div>
              </div>
            </div>

            <div className="ele__about-item ele__about-item_8">
              <div className="ele__about-left">
                <div className="ele__about-timing">
                  {/* 12:00 */}
                  <img src={require(`../../assets/images/eleven/12_00.svg`).default} alt="" className="ele__clock" />
                  <span className="ele__about-label">До новых встреч!</span>
                </div>
                <img className="ele__img" src={require(`../../assets/images/eleven/money.png`).default} alt="" />

              </div>

              <div className="ele__about-content">
                <p className="ele__h3">Заканчиваем наше мероприятие и спешим закрывать клиентов на сделки с ПАН</p>
              </div>
            </div>

            <div className="ele__about-item ele__about-item_last">
              <div className="ele__about-content">
                <p className="ele__h3">
                  Профессионал ты или новичок рынка,
                  современный техногик,
                  или до сих пор пользуешься Viber’ом,
                  в свой День Рождения мы ждем абсолютно всех!
                </p>
                <p className="ele__text">
                  Жми на кнопку ниже, ведь конференц-зал не резиновый
                </p>
                <SubmitButton
                  type="button"
                  className="ele__btn button ele__h4"
                  onClick={onSend}
                  isLoading={isLoading}
                  disabled={isParticipating || response.isExistResult}
                >
                  {isParticipating || response.isExistResult ? `Уже участвуешь` : `Я в деле`}
                </SubmitButton>
              </div>
            </div>
          </section>

          <section className="ele__banner">
            <div className="ele__banner-inner">
              <p className="ele__banner-text ele__blue ele__h4">
                Наш День Рождения <b>одобрен РосПотребНадзором</b>, но маски все равно не забывайте
              </p>
            </div>

            <img src={require(`../../assets/images/eleven/boy-mask.png`).default} alt="" className="ele__banner-img" />
          </section>
        </div>
      </div>
    </section>
  );
};

export default ElevenPan;