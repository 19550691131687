import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { sendEducationRequest } from "../../actions/sendEducationRequest";
import request from '../../api';
import { errorToast, infoToast } from '../../components/toasts';
import { validateMail } from "../../utils";
import './index.scss';


const Faq = (props) => {
  const formRef = useRef();
  
  const {userInfo = {name: "", phone: "", lastName: "", email: ""}} = props;
  const { handleSubmit, register, errors } = useForm();

  const [faqCategories, setFaqCategories] = useState([]);

  const [activeCategory, setActiveCategory] = useState(-1);
  const [activeTab, setActiveTab] = useState(0);
  

  useEffect(() => {
    request("panpartner:faq.ajax", "getItems").then(resp => setFaqCategories(resp.sections), error=>console.log(error));
  }, []);

  const onSubmit = (data) => {
    return false;
    
    data.lastName = userInfo.lastName;
    //data.course = "Консультация";
    sendEducationRequest(data).then(
      data => {
        infoToast(<div>{data.message}</div>)
        //ym("reachGoal", "sendEducationRequest");
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

 
  return (
    <section className="section section_view_white">
      <section className="sor faq">

        {
          activeCategory === -1 &&
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="faq__category">

                <h1 className="h1 faq__h1">FAQ</h1>
                <p className="big-text faq__sub">Ответы на часто задаваемые вопросы</p>

                <ul className="faq__category-list">
                  {faqCategories?.map((category, index) => {
                    return (
                      <li className="faq__category-item">
                      <button onClick={() => setActiveCategory(index)} type="button" className="faq__category-btn">
                        <img src={require(`../../assets/images/faq/category-0${index + 1}.svg`).default} alt="" className="faq__category-img" />
                        <h3 className="h3 faq__category-name">{category.name}</h3>
                      </button>
                    </li>
                    );
                  })}
                </ul>

              </div>
            </div>
          </div>
        }
        
        {
          activeCategory > -1 && (
            <>
              <div className="wrapper">
                <section className="faq__result">
                  <header className="faq__result-header">
                    <button  onClick={() => setActiveCategory(-1)} type="button" className="button button_view_outline-with-icon">
                      <svg className="icon icon_back">
                        <use xlinkHref="#back"></use>
                      </svg>
                      Вернуться
                    </button>

                    <h2 className="h2 faq__h2">{faqCategories[activeCategory]?.sections[activeTab].name}</h2>
                  </header>

                  <div className="faq__result-box">

                    <aside className="faq__menu">
                      <ul>

                      {
                        faqCategories[activeCategory]?.sections.map((section, index) => {
                          return (
                            <li
                              className="faq__menu-item"
                              key={section.id}
                            >
                              <button
                                type="button"
                                className={`faq__menu-btn${activeTab === index ? ` active` : ``}`}
                                onClick={() => setActiveTab(index)}
                              >
                                {section.name}
                              </button>
                            </li>
                          );
                        })
                      }
                      </ul>
                    </aside>

                    <section className="faq__result-content">
                      {
                        faqCategories[activeCategory]?.sections[activeTab].items.map((item, index) => {
                          return (
                            <div className="faq__result-section">
                              {
                                item.name &&
                                <div className="h3"
                                  dangerouslySetInnerHTML={
                                    {
                                    __html: item.name,
                                    }
                                  }>
                                </div>
                              }

                              {
                                item.text &&
                                <div
                                  dangerouslySetInnerHTML={
                                    {
                                    __html: item.text,
                                    }
                                  }>
                                </div>
                              }
                            </div>
                          );
                        })
                      }


                    </section>
                  </div>
                </section>
              </div>

              <section className="sor__footer" ref={formRef}>
                <div className="wrapper">
                  <div className="wrapper__center">
                    <div className="sor__prog-t">
                      <h3 className="h3">Нужна еще помощь? Напишите нам свой вопрос</h3>
                    </div>
                    <form action="#" className="sor__form" onSubmit={handleSubmit(onSubmit)}>
                      <div className="sor__form-box">
                        <div className="sor__form-item">
                            <label
                              className="input input_width_available input_type_form"
                              htmlFor="modal-reservation-name"
                              >
                              <span className="input__label">Имя</span>
                              <input
                                id="modal-reservation-name"
                                name="name"
                                defaultValue={userInfo.name}
                                className={
                                  "input__control" + (errors.name ? " input__error" : "")
                                }
                                placeholder="Ваше имя"
                                ref={register({
                                  required: "Введите имя",
                                })}
                                />
                              {errors.name && (
                                <span className="input__error-label">
                                  {errors.name.name}
                                </span>
                              )}
                            </label>
                          </div>

                          <div className="sor__form-item">
                          <label
                            className="input input_width_available input_type_form"
                            htmlFor="modal-partnership-email"
                          >
                            <span className="input__label">E-mail</span>
                            <input
                              id="modal-partnership-email"
                              name="email"
                              defaultValue={userInfo.email}
                              className={
                                "input__control" + (errors.email ? " input__error" : "")
                              }
                              ref={register({
                                required: "Введите e-mail",
                                pattern: {
                                  value: validateMail,
                                  message: "Неправильный формат e-mail",
                                },
                              })}
                            />
                            {errors.email && (
                              <span className="input__error-label">
                                {errors.email.message}
                              </span>
                            )}
                          </label>
                          </div>


                          <div className="sor__form-item">
                            <label
                              className="input input_width_available input_type_form"
                              htmlFor="modal-reservation-message"
                              >
                              <span className="input__label">Ваш вопрос</span>
                              <input
                                id="modal-reservation-message"
                                name="comment"
                                className={
                                  "input__control" + (errors.name ? " input__error" : "")
                                }
                                placeholder="Текст вопроса"
                                ref={register}
                                />
                              {errors.name && (
                                <span className="input__error-label">
                                  {errors.name.message}
                                </span>
                              )}
                            </label>
                          </div>
                              
                        <button type="submit" className="sor__submit button button_view_default">Отправить вопрос</button>


                      </div>

                      <div className="sor__form-footer">
                        <div className="checkbox checkbox_view_invert">
                          <div
                            className={
                              'checkbox__box' +
                              (errors.agreement ? " checkbox__box-error" : "")
                            }
                            >
                            <input
                              className="checkbox__control"
                              type="checkbox"
                              id="modal-reservation-agreement"
                              name="agree"
                              ref={register({
                                required: true,
                              })}
                              defaultChecked
                              />
                            <label
                              className="checkbox__input"
                              htmlFor="modal-reservation-agreement"
                              ></label>
                            <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                          </div>
                          <label
                            className="checkbox__label"
                            htmlFor="modal-reservation-agreement"
                            
                            >
                            Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </>
          )
        }
      </section>


      
    </section>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = () => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
