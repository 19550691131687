import React from 'react';
import Admin from '../components/Constructor/Admin/Admin.js';
import '../components/Constructor/index.scss';



const ConstructorAdmin = (props) => {
  return  <Admin />
};

export default ConstructorAdmin
