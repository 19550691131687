import React, { useState } from 'react';
import img from '../../assets/images/emptyPlanList.svg';
import {getUrl} from "../../api";
const TablePhoto = props => {
    const { plan, isNewSuburban } = props
    const [isLoaded, setIsLoaded] = useState(false)
    if (!isLoaded) {
        return <img
            src={img}
            alt={'plan'}
            className='table-image'
            onLoad={() => setIsLoaded(true)}
        />
    }
    return (<img
        src={isNewSuburban ? getUrl(plan?.src) : `${plan?.src}`}
        alt={'plan'}
        className={`${plan?.empty ? 'table-image' : 'table-image-scalable'
            }`}
        onError={(e) => {
            e.target.onerror = null;
            e.target.src = img;
            e.target.className  = 'table-image'
        }}
        onLoad={() => setIsLoaded(true)}
    />)
}
export default TablePhoto
