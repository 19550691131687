import { Link } from 'react-router-dom';
import { YamusicIcon } from '../icons';
import { getUrl } from '../../../../api';
const Audio = ({audioReview}) => {
  return (
    <section className="sf__audio">
      <div className="sf__audio-header">
        <h6 className="h6">Аудио-обзор</h6>
        <a className="sf__audio-link link link_color_brand-3" href={'https://music.yandex.ru/album/23835897'} target="_blank" rel="noreferrer">
          Слушать в Яндекс.Музыка
          <YamusicIcon />
        </a>
      </div>

      <div className="sf__audio-body">
        <audio src={getUrl(audioReview.src)} controls></audio>
      </div>
    </section>
  );
};

export default Audio;
