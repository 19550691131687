import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import { formatMoney } from '../utils';
const secondariesSelector = (state) => state.secondaries.data;

export const mapSecondaries = createSelector([secondariesSelector], (data) => {
  let features = [];

  if (data && data.length > 0) {
    let tmpData = [...data];
    tmpData.map((item) => {
      if (item.coordinates) {
        features.push({
          type: 'Feature',
          id: item.id,
          geometry: {
            type: 'Point',
            coordinates: item.coordinates,
          },
          properties: {
            hintContent: item.roomType.name + ", " + formatMoney(item.baseCost) + "р.",
          },
        });
      }

      return item;
    });
  }

  return features;
});

export const mapItemsSecondaries = createSelector(
  [secondariesSelector],
  (data) => {
    let items = [];
    if (data && data.length > 0) {
      let tmpData = cloneDeep(data);
      tmpData.map((item) => {
        item.region = item?.region?.name;
        item.builderName = item?.builder?.name
        item.costWithDiscount = item.baseCost
        item.roomType = item.roomType.name
        items.push(item);
      });
    }
    return items;
  },
);
