import React from "react";
import Card from "./Card";
import FlatCard from "./FlatCard";
import SliderCard from "./SliderCard";
import SuburbansCard from './SuburbansCard';
import SecondaryCard from "./SecondaryCard";
import DubaiFavoritesCard from "../Public/markup/Containers/DubaiFavoritesCard";
const Cards = (props) => {
    let card;
    switch (props.currentTab) {
        case "block":
            card = <Card {...props} />
            break;
        case "blockEntity":
            card = <DubaiFavoritesCard {...props} />
            break;
        case "apartment":
            card = <FlatCard {...props} />
            break
        case "secondary":
            card = <SecondaryCard {...props} />
            break
        case "village":
            card = <SuburbansCard {...props} />
            break
    }
    return card

};
export default Cards;