import { useMediaQuery } from 'beautiful-react-hooks';
import formatter from 'format-number';
import intersectionBy from 'lodash/intersectionBy';
import min from 'lodash/min';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUrl } from "../../api";
import emptyPlan from '../../assets/images/emptyPlanList.svg';
import useMedia from '../../hooks/useMedia';
import { getRoundedPrice, getSuffix } from '../../utils';
import MapList from '../Assignation/MapList';
import Table, { ShowMore } from "../core/Table2";
import Maps from '../Estate/Maps';
import TablePhoto from './TablePhoto';
const priceFormat = (price) => {
  if (price?.toString()?.includes("По") || price?.toString()?.includes("по")) {
    return price
  }
  return formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
};

const Card = props => {
  const {
    id,
    squareTotal,
    floor,
    isPublic,
    savedFilterXmlId,
    squareKitchen,
    decoration,
    section,
    plan,
    corpus,
    costWithDiscount,
    number,
    objectType,
    squareLiving,
    city="spb",
    flatType,
    status,
    roomType,
    endingDate,
    baseCost,
    buildingYear,
    objectTypeChecker,
  } = props;



  return (
    <div className="complex__appartments-card complex__appartments-card_tile">
      <div
        className="card-tile card-tile_img-full"
        onClick={(e) => {
          let link = isPublic
            ? `/public/${savedFilterXmlId}/secondary/${id}`
            : `/secondary/${id}${window.location.search}`
          if (objectTypeChecker === "secondary") {
            link = `/secondary/${id}${window.location.search}`
          }
          window.open(link);
        }}

      >
        <div className="card-tile__image">
          <img src={
            getUrl(plan.src)}
            alt={""}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }}
          />
        </div>

        <div className="card-tile__status table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
        </div>

        <div className="card-tile__body">
          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__value-small">{floor} этаж</span>
              <span className="card-tile__value-lg">{priceFormat(baseCost)}</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__value-small">Тип: {objectType.name}</span>
              <span className="card-tile__value-lg">{squareTotal} м²</span>
            </div>
          </div>

          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__key">S жилая</span>
              <span className="card-tile__value">{squareLiving} м²</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">S кухня</span>
              <span className="card-tile__value">{squareKitchen} м²</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">Год постройки</span>
              <span className="card-tile__value">{buildingYear}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};


const CardsApartments = (props) => {
  const { items=[], expand, isPublic, savedFilterXmlId, innerRef, objectType } = props;

  const [loadedItems, setloadedItems] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setloadedItems(items.slice(0, 10))
  }, [items])
  const loadMore = () => {
    setLoading(true)
    setloadedItems(prev=>{
      const prevLen = prev.length
      return [...prev, ...items.slice(prevLen, prevLen + 10)]
    }
      )
    setLoading(false)
  }
  return (
    <div
      className="flats__row-results"
      style={{ display: expand ? "block" : "none" }}
      ref={innerRef}
    >
      <div className="complex__appartments-cards" >
        {loadedItems.map((item) => (
          <Card
            {...item}
            key={item.id}
            isPublic={isPublic}
            savedFilterXmlId={savedFilterXmlId}
            objectTypeChecker={objectType}
          />
        ))}
      </div>
      {loadedItems.length < items.length && <ShowMore customMoreTitle={"Показать ещё"} onClick={loadMore} loading={loading} />}
    </div>
  );
};

const TableRow = (props) => {
  const { active, items, isAgent, clientMode } = props;
  const media1080 = useMediaQuery('(max-width: 1080px)');

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");


  const handleSurface = (e) => {
    if (isLaptop) e.stopPropagation();
  };
  const history = useHistory();
  const [lastSort, setLastSort] = useState(null);

  const getMobileColumns = () => [
    {
      title: 'Эт',
      dataIndex: 'floor',
      key: 'floor',
      render: (floor) => floor?  floor :"—"
    },
    {
      title: 'Комнат',
      dataIndex: 'roomType',
      key: 'roomType',
      render: (roomType) => (
        <span>{roomType.countRooms}</span>
      ),
    },
    {
      title: 'Цена',
      dataIndex: 'baseCost',
      key: 'baseCost',
      render: (price) => <span className="nowrap">{getRoundedPrice(price)} млн.р.</span>,
    },
  ]

  const getColumns = () => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      sorter: (a, b) => a.id - b.id,
      sortOrder: lastSort && lastSort.columnKey === 'id' && lastSort.order,
      render: (id) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{id}</div>
        </div>
      ),
    },
    {
      title: "Фото",
      dataIndex: "plan",
      key: "plan",
      width: isDesktop ? 50 : 80,
      render: (plan) => {
        return (
          <div className="table-img">
            <img src={plan?.src} alt="" onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }} />
            <div className="table-img__preview">
              <img src={plan?.src} alt="" onError={(e) => {
                e.target.onerror = null;
                e.target.src = emptyPlan
              }} />
            </div>
          </div>
        )
      },
    },
    {
      title: isDesktop ? 'Тип' : 'Тип объекта',
      dataIndex: 'objectType',
      key: 'objectType',
      width: isDesktop ? 60 : 80,
      render: (objectType) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{objectType.name}</div>
        </div>
      ),
      sorter: (a, b) => {
        if (a.objectType?.name < b.objectType?.name) {
          return -1;
        }
        if (a.objectType?.name > b.objectType?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === 'objectType' && lastSort.order,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      width: isDesktop ? 70 : 120,
      render: (address) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{address}</div>
        </div>
      ),
      sorter: (a, b) => {
        if (a.address < b.address) {
          return -1;
        }
        if (a.address > b.address) {
          return 1;
        }
        return 0;
      },
      sortOrder: lastSort && lastSort.columnKey === 'address' && lastSort.order,
    },
    {
      title: 'Район',
      dataIndex: 'region',
      width: isLaptop ? 50 : 90,
      key: 'region',
      render: (region) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{region.name}</div>
        </div>
      ),
      sorter: (a, b) => {
        if (a.region?.name < b.region?.name) {
          return -1;
        }
        if (a.region?.name > b.region?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder: lastSort && lastSort.columnKey === 'region' && lastSort.order,
    },
    {
      title: 'Метро',
      dataIndex: 'subway',
      key: 'subway',
      width: isLaptop ? 50 : 100,
      render: (subway) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">
            {subway ? subway.map((v) => v.name).join(', ') : ''}
          </div>
        </div>
      ),
      sorter: (a, b) => a.subway.length - b.subway.length,
      sortOrder: lastSort && lastSort.columnKey === 'subway' && lastSort.order,
    },
    {
      title: 'Эт.',
      dataIndex: 'floor',
      key: 'floor',
      width: isLaptop ? 50 : 80,
      render: (floor) => floor?  floor :"—",
      sorter: (a, b) => {
        let aFloor = a.floor.match(/\d+/)?.[0]
        let bFloor = b.floor.match(/\d+/)?.[0]
        return aFloor - bFloor
      },
      sortOrder: lastSort && lastSort.columnKey === 'floor' && lastSort.order,
    },
    {
      title: isLaptop ? 'Ком.' : 'Комн.',
      dataIndex: 'roomType',
      key: 'roomType',
      width: isDesktop ? 60 : 80,
      className: !isLaptop ? "show" : "hidden",
      render: (roomType) => (
        <span>{roomType.countRooms}</span>
      ),
      sorter: (a, b) => a.roomType.sort - b.roomType.sort,
      sortOrder: lastSort && lastSort.columnKey === 'roomType' && lastSort.order,
    },
    {
      title: 'S общ.',
      dataIndex: 'squareTotal',
      key: 'squareTotal',
      width: isDesktop ? 70 : 80,
      className: !isLaptop ? "show" : "hidden",
      sorter: (a, b) => a.squareTotal - b.squareTotal,
      sortOrder:
        lastSort && lastSort.columnKey === 'squareTotal' && lastSort.order,
    },
    {
      title: 'S жил.',
      dataIndex: 'squareLiving',
      key: 'squareLiving',
      width: isDesktop ? 70 : 80,
      className: !isLaptop ? "show" : "hidden",
      sorter: (a, b) => a.squareLiving - b.squareLiving,
      sortOrder:
        lastSort && lastSort.columnKey === 'squareLiving' && lastSort.order,
    },

    {
      title: 'S кух.',
      dataIndex: 'squareKitchen',
      key: 'squareKitchen',
      width: isDesktop ? 70 : 80,
      className: !isLaptop ? "show" : "hidden",
      sorter: (a, b) => a.squareKitchen - b.squareKitchen,
      sortOrder:
        lastSort && lastSort.columnKey === 'squareKitchen' && lastSort.order,
    },
    {
      title: 'Цена',
      dataIndex: 'baseCost',
      key: 'baseCost',
      fixed: 'right',
      width: 100,
      render: (price) => <span className="nowrap">{priceFormat(price)}</span>,
      // render: (price) =>
      // <div className="surface" onClick={handleSurface}>
      //   <div className="surface__wrap">{priceFormat(price)}</div>
      // </div>,
      // width: 120,
      sorter: (a, b) => a.baseCost - b.baseCost,
      sortOrder:
        lastSort && lastSort.columnKey === 'baseCost' && lastSort.order,
    },

    ...(!isAgent && !clientMode
      ? [
        {
          title: 'Комиссия',
          dataIndex: 'commission',
          key: 'commission',
          fixed: 'right',
          width: 100,
          render: (commission) => (
            <div className="surface" onClick={handleSurface}>
              <div className="surface__wrap">  {commission ? priceFormat(commission) : "По договорённости"}</div>
            </div>
          ),
          sorter: (a, b) => {
            return (
              min(
                a.commission
                  .replace(/,/g, '.')
                  .replace(/ /g, '')
                  .replace(/%/g, '')
                  .split('-'),
              ) -
              min(
                b.commission
                  .replace(/,/g, '.')
                  .replace(/ /g, '')
                  .replace(/%/g, '')
                  .split('-'),
              )
            );
          },
          sortOrder:
            lastSort && lastSort.columnKey === 'commission' && lastSort.order,
        },
      ]
      : []),
    {
      title: 'Год',
      width: isDesktop ? 60 : 80,
      dataIndex: 'buildingYear',
      key: 'buildingYear',
      responsive: ['md'],
      render: (buildingYear) => buildingYear
        ?
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{buildingYear}</div>
        </div>
        : "—",

      // width: 120,
      sorter: (a, b) => a.buildingYear - b.buildingYear,
      sortOrder:
        lastSort && lastSort.columnKey === 'buildingYear' && lastSort.order,
    },

    {
      title: !isDesktop ? "Статус" : "Ст.",
      dataIndex: "status",
      key: "status1",
      sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
      width: isDesktop ? 60 : 100,
      render: (status, record) => {
        return (
          <div className="table2-status">
            <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
              <div className="status-circle__tooltip">{status?.name}</div>
            </div>
            <div className="status-anchors">
              <a href={`/flat-booking/secondary/${record.id}/spb`} target="_blank" onClick={e => e.stopPropagation()}>
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M16.4409 2.59855C14.2024 0.312851 10.4717 0.312851 8.23322 2.59855C6.74092 4.12236 6.24348 6.40806 6.86528 8.56678L0.274261 15.2969C0.149902 15.4239 0.149902 15.5509 0.149902 15.6779V18.7255C0.149902 18.9794 0.39862 19.2334 0.647338 19.2334H3.63195C3.75631 19.2334 3.75631 19.2334 3.88067 19.2334V17.2017H5.87041V15.1699H7.73579V13.1382H9.72553L10.4717 12.3763C12.5858 13.0112 14.8242 12.5033 16.3165 10.9795C18.8037 8.69376 18.8037 4.88426 16.4409 2.59855ZM14.9486 6.40806C14.3268 7.04298 13.3319 7.04298 12.7101 6.40806C12.0883 5.77314 12.0883 4.75727 12.7101 4.12236C13.3319 3.48744 14.3268 3.48744 14.9486 4.12236C15.5704 4.75727 15.5704 5.77314 14.9486 6.40806Z" />
                </svg>
              </a>
            </div>
          </div>
        )
      },
    },
  ];

  const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">ID:</span>
            {record.id}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Фото:</span>
            <TablePhoto plan={record.plan} />
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Тип объекта:</span>
            {record.objectType.name}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Адрес:</span>
            <span style={{ whiteSpace: 'normal' }}>{record.address}</span>
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Район:</span>
            {record.region.name}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Метро:</span>
            {record.subway ? record.subway.map((v) => v.name).join(', ') : ''}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">S общ.:</span>
            {record.squareTotal}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">S жил.:</span>
            {record.squareLiving}
          </div>

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">S кух.:</span>
            {record.squareKitchen}
          </div>

          {!isAgent && !clientMode && (
            <div className="lk-presentation__clients">
              <span className="flats__row-table-expanded-title">Комиссия:</span>
              {record.commission ? priceFormat(record.commission) : "По договорённости"}
            </div>
          )}

          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Год:</span>
            {record.buildingYear || '–'}
          </div>

        </div>
      </div>
    )
  }

  function onChange(pagination, filters, sorter, extra) {
    //console.log("params", pagination, filters, sorter, extra);
    setLastSort({
      order: sorter.order,
      columnKey: sorter.field,
    });
  }
  const onLoadEndCallBack = () => { };
  return (
    <div className={`flats__row-table for-secondary ${active ? `is-active` : ``}`}>
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={isLaptop ? getMobileColumns() : getColumns()}
        expandable={isLaptop ? {
          expandedRowRender: record => expandedRow(record),
        } : false}
        dataSource={items}
        pagination={false}
        locale={{ emptyText: 'ничего не найдено' }}
        customPagination={true}
        autoLoadMore={true}
        onChange={onChange}
        onLoadEndCallBack={onLoadEndCallBack}
        firstPageCount={30}
        pageCount={50}
        // scroll={{ x: 1140 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              window.open(`/secondary/${record.id}`);
            },
          };
        }}
      />
    </div>
  );
};

let paintIsStarted = false;
const Items = (props) => {
  const { items, innerRef, features, mapItems, isAgent, clientMode, isPublic, savedFilterXmlId } = props;
  const [viewType, setViewType] = useState('list');
  const [isInitMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState([59.9342802, 30.3350986]);
  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);

  const isLaptop = useMedia("isLaptop");

  const filterByCluster = (items) => {
    if (selectedItems) {
      return intersectionBy(items, selectedItems, 'id');
    }
    if (currentPoint && isClusterOrPoint === 'point') {
      paintIsStarted = false;
      return intersectionBy(items, [currentPoint], 'id');
    }
    if (currentCluster && isClusterOrPoint === 'cluster') {
      paintIsStarted = false;
      return intersectionBy(items, currentCluster.features, 'id');
    }

    return items;
  };
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  return (
    <section
      className={`section`}
      ref={innerRef}
    >
      <div className="assignations-flats for-commercial assignations-flats_table2">
        <div id="filter-results"></div>
        <div className="wrapper">
          <div className="assignations-flats__header">
            <div className="assignations-flats__title assignations-flats__title_flex">
              <h2 className="h2">
                Найдено {items.length} объект{getSuffix(items.length)}
              </h2>

              <div className="assignations-flats__reglament">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.5436 18H2.11975C1.2941 18 0.571655 17.3915 0.571655 16.5801V1.67133C0.571655 0.859968 1.2941 0.150024 2.11975 0.150024H12.5436C13.3692 0.150024 14.0917 0.859968 14.0917 1.67133V16.5801C14.0917 17.3915 13.3692 18 12.5436 18Z" fill="#CCD0DD" />
                  </g>
                  <path d="M18.3542 15.7543H9.82927C9.36847 15.7543 9.13806 15.5279 9.13806 15.0751V8.28258C9.13806 7.82975 9.36847 7.60333 9.82927 7.60333H18.3542C18.815 7.60333 19.0454 7.82975 19.0454 8.28258V15.0751C19.0454 15.3015 18.815 15.7543 18.3542 15.7543Z" fill="#8F95AC" />
                  <path d="M15.4646 10.6873C15.4646 9.90231 14.8589 9.28259 14.0917 9.28259C13.3246 9.28259 12.7189 9.90231 12.7189 10.6873C12.7189 11.1831 12.9611 11.5962 13.3246 11.8441L13.0015 14.0751H15.2627L14.9397 11.8441C15.2223 11.5962 15.4646 11.1831 15.4646 10.6873Z" fill="#CCD0DD" />
                  <path d="M11.4419 6.01842V4.88634C11.4419 3.52784 12.5939 2.39575 13.9763 2.39575C15.3588 2.39575 16.5108 3.52784 16.5108 4.88634V7.82976" stroke="#8F95AC" strokeMiterlimit="10" />
                  <defs>
                    <clipPath id="clip0">
                      <rect width="14" height="18" fill="white" transform="translate(0.0916748)" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="https://view.officeapps.live.com/op/view.aspx?src=https://panpartner.ru/upload/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82.docx" className="link link_color_brand-3" target="_blank" rel="noopener noreferrer">Регламент взаимодействия</a>
              </div>
            </div>
            {!isLaptop && (
              <div className="assignations-flats__btns">

                <div className={`complex__view-item${viewType == `list` ? ` active` : ``}`}>
                  <button
                    className="view__btn button"
                    onClick={() => setViewType("list")}
                  >
                    <svg className="view__icon">
                      <use xlinkHref="#view_rows2"></use>
                    </svg>
                    <span>Список</span>
                  </button>
                </div>

                <div className={`complex__view-item${viewType == `cards` ? ` active` : ``}`}>
                  <button
                    className="view__btn button"
                    onClick={() => setViewType("cards")}
                  >
                    <svg className="view__icon">
                      <use xlinkHref="#view_cards2"></use>
                    </svg>
                    <span>Планировки</span>
                  </button>
                </div>


                <div className={`complex__view-item view__map ${viewType == `map` ? ` active` : ``}`}>
                  <button
                    className={`view__btn button`}
                    onClick={() => setViewType("map")}
                  >
                    <svg className="view__icon view__icon_map">
                      <use xlinkHref="#marker"></use>
                    </svg>
                    <span>На карте</span>
                  </button>
                </div>


              </div>)
              }

          </div>
        </div>
        {viewType === 'list' && (
          <div className="section__cards">
            <div className="wrapper">
              <div className="assignations-flats__tables table2">
                {items && (
                  <TableRow
                    items={items}
                    isAgent={isAgent}
                    active={true}
                    clientMode={clientMode}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {
        viewType === "cards" && (
          <div className="section__cards">
            <div className="wrapper">
              <div className="assignations-flats__tables table2">
                <CardsApartments
                  items={items}
                  isPublic={isPublic}
                  expand={true}
                  savedFilterXmlId={savedFilterXmlId}
                  objectType="commercial"
                />
              </div>
            </div>
          </div>
          )
        }

        {(isInitMap || viewType === 'map') && (
          <div
            className="section__map"
            style={{ display: viewType === 'map' ? 'block' : 'none' }}
          >
            <div className="map">
              <MapList
                items={filterByCluster(mapItems)}
                setMapCenter={setMapCenterHandler}
                fromPage={"secondaries"}
                favType={"secondary"}
              />
              <div className="map__container">
                <Maps
                  features={features}
                  mapItems={mapItems}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  setCluster={setCluster}
                  setPoint={setPoint}
                  setClusterOrPoint={setClusterOrPoint}
                  setInitMap={setInitMap}
                  paintIsStarted={paintIsStarted}
                  type="assignments"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Items;
