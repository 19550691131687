import classNames from "classnames";
import React, { useRef } from "react";
import SimpleBar from "simplebar-react";
import useDropdown from "../../hooks/useDropdown";
import useElementPosition from "../../hooks/useElementPosition";
import useDebounceState from "../../hooks/useDebounceState";

const SquareFilterOnly = (props) => {
  const {
    onChange,
    filter,
    data,
    right,
    identity,
    isMobile,
    clearFilterOnly,
    clearExcludeOnly,
    getCount,
    fullFilter,
    defFullFilter,
    defaultFilter,
    placeholderMes = "м²",
    items,
    showPlaceholderOnLists = false,
    autoPosition,
    wrapperClass,
    debounced
  } = props;
  const [node, open, show, hide] = useDropdown(false);
  const formItemRef = useRef(null)
  const position = useElementPosition(formItemRef, 150)
  const handleChange = (e, name, ident) => {
    const { value, id } = e.target;
    onChange({ filter: { ...filter, [name]: value } }, identity);
  };
  const [debouncedValues, debouncedHandleChange] = useDebounceState({
    setState: (prev, ...args) => ({ ...prev, [args[1]]: args[0]?.target?.value?.replace(/\s/g, "") }),
    fn: handleChange,
    defaultState: { min: "", max: "" },
    observableValues: filter,
  }
  )
  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right") ),
  });

  const clearFilters = (e) => {
    e.preventDefault()
    if (getCount && typeof getCount === "function") {
      getCount({
        ...fullFilter,
        filter: defFullFilter
          ? defFullFilter.filter
          : defaultFilter,
      });
    }
    clearFilterOnly()
    clearExcludeOnly()
  }

  return (
    <div className={`form__item ${wrapperClass?? ""}`} ref={formItemRef}>
      <div
        ref={node}
        className={"dropdown form__drop" + (open ? " is-showing" : "")}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">
            {data.title}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">

              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data?.title}</h3>
                </div>
              }

              <div className="filter">
                <div className="filter__fields">
                  <div className="filter__field ">
                    <label
                      className="input input_type_default input_width_available"
                      htmlFor="square-1"
                    >
                      <input
                        className="input__control"
                        type="number"
                        value={debounced ? debouncedValues?.min : filter.min}
                        placeholder={`${data.minPlaceholder}${
                          data.defaultValues
                            ? `${data.defaultValues.min} ${placeholderMes}`
                            : ""
                        }`}
                        onChange={(e) => debounced ? debouncedHandleChange(e, "min", "squareTotal") : handleChange(e, "min", "squareTotal")}
                      />
                                
                    </label>
                    {items && (
                      <SimpleBar className="filter__checks" style={{paddingTop: 10}}>
                        <div className="checkbox-group">
                          {items.map((item) => (
                            <div
                              className="checkbox-group__item "
                              key={"square-item-" + item.id}
                            >
                              <div
                                className="checkbox checkbox_type_btn"
                              >
                                <div className="checkbox__box" style={{width: "100%"}}>
                                  <input
                                    className="checkbox__control "
                                    type="radio"
                                    name="square-from"
                                    id={`square-from-${identity}-${item.id}`}
                                    value={item.id}
                                    onChange={(e) => handleChange(e, "min")}
                                    checked={item.id?.toString() == filter.min}
                                  />
                                  <label
                                    className="checkbox__label"
                                    htmlFor={`square-from-${identity}-${item.id}`}
                                  >
                                    {showPlaceholderOnLists? `${item.id}` : item.id}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                  <div className="filter__field ">
                    <label
                      className="input input_type_default input_width_available"
                      htmlFor="square-2"
                    >
                      <input
                        className="input__control"
                        type="number"
                        id="squareTotal"
                        value={debounced ? debouncedValues?.max : filter.max}
                        placeholder={`${data.maxPlaceholder}${
                          data.defaultValues
                            ? `${data.defaultValues.max} ${placeholderMes}`
                            : ""
                        }`}
                        onChange={(e) => debounced ? debouncedHandleChange(e, "max", "squareTotal") : handleChange(e, "max", "squareTotal")}
                      />
                    </label>
                    {items && (
                      <SimpleBar className="filter__checks" style={{paddingTop: 10}}>
                        <div className="checkbox-group">
                          {items.map((item) => (
                            <div
                              className="checkbox-group__item "
                              key={"square-item-2-" + item.id}
                            >
                              <div
                                className="checkbox checkbox_type_btn"
                              >
                                <div className="checkbox__box">
                                  <input
                                    className="checkbox__control "
                                    type="radio"
                                    name="square-to"
                                    id={`square-to-${identity}-${item.id}`}
                                    value={item.id}
                                    onChange={(e) => handleChange(e, "max")}
                                    checked={item.id?.toString() == filter.max}
                                  />
                                  <label
                                    className="checkbox__label"
                                    htmlFor={`square-to-${identity}-${item.id}`}
                                  >
                                    {showPlaceholderOnLists? `${item.id}` : item.id}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                </div>
                {isMobile &&
                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        clearFilters(e)
                        hide()
                      }}
                    >
                      Очистить
                    </button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        hide()
                      }
                      }
                    >
                      Применить
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default SquareFilterOnly;
