const initialState = {
	isFetching: false,
	error: false,
	data: [],
	filterData: {
		agents: [],
		cities: [],
		clients: [],
	},
	filter: {
		agents: [],
		cities: [],
		clients: [],
	},
	clients: [],
	filePath: null
};

const presentations = (state = initialState, action) => {
	switch (action.type) {
		case 'PRESENTATIONS_REQUEST':
			return {
				...state,
				isFetching: true,
			};
		case 'PRESENTATIONS_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				data: action.data,
				filterData: action.filterData,
				clients: action.clients,
			};
		case 'PRESENTATIONS_FAILURE':
			return {
				...state,
				isFetching: false,
				error: true,
			};
		case 'PRESENTATIONS_RESET':
			return { ...initialState };
		case 'PRESENTATIONS_SET_FILTER':
			return { ...state, filter: { ...state.filter, ...action.filter } };
		case 'PRESENTATIONS_REMOVE':
			return {
				...state,
				data: state.data.filter((item) =>
					action.ids.every((id) => id != item.id),
				),
			};
		case 'PRESENTATIONS_CLEAR_FILTER':
			return { ...state, filter: initialState.filter };
		default:
			return state;
	}
};

export default presentations;
