import _keys from 'lodash/keys';
import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { editClients } from '../../actions/clients';
import { media, validateMail } from '../../utils';
import SubmitButton from '../SubmitButton';
import { errorToast, infoToast } from '../toasts';

const sanitizePhoneInput = (pasteData) => {
	let sanitizedData = pasteData.replace(/[^\d]/g, '');
	if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
		sanitizedData = sanitizedData.substring(1);
	} else if (sanitizedData.startsWith('+7')) {
		sanitizedData = sanitizedData.substring(2);
	}
	return `+7 (${sanitizedData.slice(0, 3)}) ${sanitizedData.slice(3, 6)}-${sanitizedData.slice(6, 8)}-${sanitizedData.slice(8, 10)}`;
};

const Input = ({ value, onChange, inputRef, name, ...rest }) => {
	const handlePaste = (e) => {
		e.preventDefault();
		const pasteData = (e.clipboardData || window.clipboardData).getData('text');
		const formattedData = sanitizePhoneInput(pasteData);
		onChange({
			target: {
				name,
				value: formattedData,
			},
		});
	};

	return (
		<InputMask
			{...rest}
			mask="+7 (999) 999-99-99"
			maskChar="_"
			value={value}
			onChange={onChange}
			onPaste={handlePaste}
		>
			{(inputProps) => <input ref={inputRef} {...inputProps} name={name} />}
		</InputMask>
	);
};

const phoneRequired = (v) =>
	v && v.indexOf('_') === -1 ? undefined : 'Введите телефон';

const NewClientForm = (props) => {
	const { add, onAdd, id } = props;
	const { handleSubmit, register, errors } = useForm();
	const [inProcess, setInProcess] = useState(false);
	const [phone, setPhone] = useState("");
	const handleChange = (e) => {
		setPhone(e.target.value);
	};

	const onSubmit = (values) => {
		setInProcess(true);

		let form = new FormData();
		let keys = _keys(values);

		keys.forEach((item) => {
			let identity = item;
			if (Array.isArray(values[item])) {
				values[item].forEach((appendValue) => {
					form.append(`${identity}[]`, appendValue);
				});
			} else {
				form.append(`${identity}`, values[item]);
			}
		});
		form.append("city", "spb")
		add(form).then(
			data => {
				infoToast(
					<div className="Toastify__text">
							Клиент{' '}
							<strong>
								{values.lastName} {values.name}
							</strong>{' '}
							успешно добавлен
						</div>
				)
				onAdd(data.id);
				setInProcess(false);
			},
			error => {
				errorToast(<div>{error[0]?.message}</div>)
				setInProcess(false);
			},
		);
	};
	useEffect(()=>{
		if(Object.keys(errors).length === 0){
			return
		}
		let messages = []
		for (let key in errors) {
			messages.push(`${Array.isArray(errors[key]) && errors[key] && errors[key][0] ? errors[key][0]["message"] : errors[key]?.message}`)
		}
		errorToast(<div>Заполните обязательные поля:<br/> {messages.map(v=>{
			let msg = v.replace("Введите ", "")
			return <div>{`${msg[0].toUpperCase()}${msg.slice(1)} клиента`}<br/></div>
		})}</div>)
	}, [errors])
	return (
		<form
			className="new-client"
			name="new-client"
			onSubmit={handleSubmit(onSubmit)}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<div className="modal-form__row">
				<div className="modal-form__item">
					<label className="input input_type_form input_width_available">
						<span className="input__label">Фамилия клиента</span>
						<input
							name="lastName"
							className={
								'input__control' + (errors.lastName ? ' input__error' : '')
							}
							ref={register({
								required: 'Введите фамилию',
							})}
							autoComplete="off"
						/>
						{errors.lastName && (
							<span className="input__error-label">
								{errors.lastName.message}
							</span>
						)}
					</label>
				</div>
			</div>
			<div className="modal-form__row">
				<div className="modal-form__item">
					<label className="input input_type_form input_width_available">
						<span className="input__label">Имя клиента</span>
						<input
							name="name"
							className={
								'input__control' + (errors.name ? ' input__error' : '')
							}
							ref={register({
								required: 'Введите имя',
							})}
							autoComplete="off"
						/>
						{errors.name && (
							<span className="input__error-label">
								{errors.name.message}
							</span>
						)}
					</label>
				</div>
				<div className="modal-form__item">
					<label
						className="input input_type_form input_width_available"
						htmlFor="cl-f-name"
					>
						<span className="input__label">Отчество клиента</span>
						<input
							name="patronymic"
							autoComplete="off"
							className={
								'input__control' + (errors.patronymic ? ' input__error' : '')
							}
						/>
						{errors.patronymic && (
							<span className="input__error-label">
								{errors.patronymic.message}
							</span>
						)}
					</label>
				</div>
			</div>
			<div className="modal-form__row">
				<div className="modal-form__item">
					<label
						className="input input_type_form input_width_available"
						key="phone"
					>
						<span className="input__label">Телефон:</span>
						<Input
							name="phone[0]"
							value={phone}
							onChange={handleChange}
							className="input__control"
								inputRef={register({
									required: 'Введите телефон',
									validate: { inputTelRequired: phoneRequired },
								})}
						/>
						{errors.phone?.[0] && (
							<span className="input__error-label">
								{errors.phone?.[0]?.message}
							</span>
						)}
					</label>
				</div>
				<div className="modal-form__item">
					<label className="input input_type_form input_width_available">
						<span className="input__label">E-mail:</span>
						<input
							name="email[0]"
							className={
								'input__control' + (errors.email ? ' input__error' : '')
							}
							type="email"
							autoComplete="off"
							ref={register({
								// required: 'Введите e-mail',
								pattern: {
									value: validateMail,
									message: 'Неправильный формат e-mail',
								},
							})}
						/>
						{errors.email?.[0] && (
							<span className="input__error-label">
								{errors.email?.[0]?.message}
							</span>
						)}
					</label>
				</div>
			</div>
			<div className={media("isMobile") ? "mobile-filters__show-all" : "new-client__submit"}>
				<SubmitButton
					className={media("isMobile") ? "button button_view_default" : "button button_view_grey"}
					isLoading={inProcess}
					type="submit"
				>
					<span>Добавить</span>
				</SubmitButton>
			</div>
		</form>
	);
};

const mapDispatchToProps = (dispatch) => ({
	add: (form) => dispatch(editClients(form)),
});

export default connect(null, mapDispatchToProps)(NewClientForm);
