import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilterOnly from "../../FilterRealty/SquareFilterOnly";
import { getSelectPropsCreator } from '../utils';
import FloorsFilter from "../../FilterRealty/FloorsFilter";

const ProjectsTopFilter = memo(props => {

  const {
    filter,
    filterData,
    handleFilter,
    clearExcludeOnly,
    clearFilterOnly,
    wrapperClass = "sf__filter-item filter_item_temp"
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    <SelectFilter
      {...createSelectProps("projectCountBedrooms")}
      data={{
        title: "Кол-во спален",
        autocompleteTitle: "",
        identity: "projectCountBedrooms",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("projectCountBathrooms")}
      data={{
        title: "Кол-во санузлов",
        autocompleteTitle: "",
        identity: "projectCountBathrooms",
      }}
      wrapperClass={wrapperClass}
    />


    {/*<FloorsFilter*/}
    {/*  autoPosition*/}
    {/*  items={filterData.projectCountBedrooms?.items ?? []}*/}
    {/*  filter={filter.projectCountBedrooms ?? {}}*/}
    {/*  wrapperClass={wrapperClass}*/}
    {/*  data={{*/}
    {/*    title: 'Кол-во спален',*/}
    {/*    minPlaceholder: 'от ',*/}
    {/*    maxPlaceholder: 'до ',*/}
    {/*    defaultValues: filterData.projectCountBedrooms?.range ?? null,*/}
    {/*  }}*/}
    {/*  onChange={({ filter }) => handleFilter('projectCountBedrooms', filter)}*/}
    {/*/>*/}
    {/*<FloorsFilter*/}
    {/*  autoPosition*/}
    {/*  items={filterData.projectCountBathrooms?.items ?? []}*/}
    {/*  filter={filter.projectCountBathrooms ?? {}}*/}
    {/*  wrapperClass={wrapperClass}*/}
    {/*  data={{*/}
    {/*    title: 'Кол-во санузлов',*/}
    {/*    minPlaceholder: 'от ',*/}
    {/*    maxPlaceholder: 'до ',*/}
    {/*    defaultValues: filterData.projectCountBathrooms?.range ?? null,*/}
    {/*  }}*/}
    {/*  onChange={({ filter }) => handleFilter('projectCountBathrooms', filter)}*/}
    {/*/>*/}


    <SquareFilterOnly
      autoPosition
      wrapperClass={wrapperClass}
      filter={filter.squareTotal ?? { min: "", max: "" }}
      data={{
        title: 'S дома, от и до',
        minPlaceholder: 'S дома от ',
        maxPlaceholder: 'S дома до ',
        defaultValues: filterData.squareTotal?.range ?? null
      }}
      onChange={({ filter }) => handleFilter('squareTotal', filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareTotal"}
      items={filterData.squareTotal?.items}
      showPlaceholderOnLists
      debounced
      placeholderMes="сот."
    />
  </>
});

export default ProjectsTopFilter;
