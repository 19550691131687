import { useMediaQuery } from "beautiful-react-hooks";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { editFilter } from "../../actions/savedFilters";
import useMedia from '../../hooks/useMedia';
import { filterToParams, isEmptyFilterOrExclude } from "../../utils";
import Dropdown from "../core/Dropdown";
import Dragable from "../FilterRealty/Dragable";
import NewClient from "../forms/NewClient";
import { errorToast, infoToast } from "../toasts";
import FilterSuburbans from './FilterSuburbans';
import FilterUniversal from './FilterUniversal';
const EditFilter = (props) => {
  const {
    filterData,
    defaultFilter,
    defaultExclude,
    filter,
    fetch,
    setFilter,
    clearFilter,
    clearFilterOnly,
    clearExcludeOnly,
    clients,
    filterId,
    editFilter,
    filterShow = false,
    hide,
    clientId,
    clientName,
    filterType,
    defFilter
  } = props;

  const [showClientForm, setShowClientForm] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [client, setClient] = useState(-1);
  const smallScreen = useMediaQuery("(max-width: 1280px)");
  const isMobile = useMedia("isMobile")
  useEffect(() => {
    clearFilter();
    setFilter({
      filter: { ...defaultFilter },
      exclude: { ...defaultExclude },
    });

    setClient(clientId);
    setIsShowFilter(filterShow);

    return () => clearFilter();
  }, []);

  useEffect(() => {
    setClient(clientId);
  }, [clientId]);

  const handleFilter = (data, identity) => {
    setFilter({
      filter: { [identity]: data.filter },
      exclude: { [identity]: data.exclude },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = filterToParams(filter);
    let city = filterType === "assignment" || filterType == "commercial" || "secondary" ? "spb" : filterData.cityCode
    const options = {
      id: filterId,
      clients: [client],
      isShowFilter: isShowFilter ? "Y" : "N",
      ...params,
      city: city,
      ...(filterType ? { filterType: filterType } : [])
    };

    const clientInfo = clients.find((person) => person.id == client);

    editFilter(options).then(
      () => {
        hide();
        infoToast(
          <div>
            {`Фильтр для клиента ${clientName ? clientName : clientInfo?.title} сохранен`}
          </div>
        )
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    clearFilter();
  };


  const handleAddClient = (id) => {
    fetch().then(() => {
      setShowClientForm(false);
      setClient(id);
    });
  };

  const isSuburban = filterType == "suburban"
  return (
    <>
      {!isMobile && <h2 className="modal__title">Редактировать фильтр</h2>}
      <div className="modal-form">
        <div className="modal-form__row">
          <div className="modal-form__wrap">
            <div className="modal-form__row modal-form__row_align_bottom">
              <div className="modal-form__item modal-form__item_2x for-saved-filters">
                <div className="select select_type_form">
                  <label className="custom-select-label">Клиент</label>
                  <Dropdown
                    options={clients}
                    value={client}
                    onChange={(value) => {
                      setClient(value);
                    }}
                  />
                </div>
              </div>
              <div className="modal-form__item">
                <div
                  className={
                    "modal-form__add js-add-client" +
                    (showClientForm ? " is-hidden" : "")
                  }
                  onClick={() => setShowClientForm(true)}
                >
                  <button className="button button_type_add"></button>
                  <span>Добавить клиента</span>
                </div>
              </div>
              <div className="modal-form__item">
                <label className="checkbox for-saved-filters">
                  <div className="checkbox__box">
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      checked={isShowFilter}
                      onChange={(e) => setIsShowFilter(e.target.checked)}
                      id="filter-edit-inc-search"
                    />
                    <div className="checkbox__input"></div>
                    <div className="checkbox__marker"></div>
                  </div>
                  <div className="checkbox__label">
                    Включить поиск в подборке
                  </div>
                </label>
              </div>
            </div>
            {showClientForm && (
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <NewClient onAdd={handleAddClient} />
                </div>
                <div className="modal-form__item"></div>
              </div>
            )}
          </div>
        </div>
        {isSuburban ?
          <FilterSuburbans {...props} handleFilter={handleFilter} /> : <FilterUniversal {...props} handleFilter={handleFilter} />
        }
        {filter && filterData && !isEmptyFilterOrExclude(filter, null, defFilter) && (
          <>
            <div className="modal-form__row">
              <div className="modal-form__item">
                <Dragable
                  filter={filter.filter}
                  exclude={filter.exclude}
                  filterData={filterData}
                  handleFilter={handleFilter}
                  clearFilterOnly={clearFilterOnly}
                  clearExcludeOnly={clearExcludeOnly}
                  enableRemoving={true}
                  defFullFilter={defFilter}
                />
              </div>
            </div>
          </>
        )}
        {/* {(showBadges('filter') || showBadges('exclude')) && (
					<div className="modal-form__row">
						<div className="modal-form__item">
							<div className="filters__filter-applied">
								<div className="filters__filter-inc ">
									{renderBadges('filter')}
								</div>
								<div className="filters__filter-exc ">
									{renderBadges('exclude')}
								</div>
							</div>
						</div>
					</div>
				)} */}
        <div className={`modal-form__submit ${isMobile ? 'mobile-filters__show-all' : ''}`}>
          {!isMobile &&
            <button
              className="button button_view_outline-with-icon js-modal-btn clients__back-btn"
              onClick={hide}
            >
              <svg className="icon icon_back">
                <use xlinkHref="#back"></use>
              </svg>
              <span>Вернуться</span>
            </button>
          }
          <button className="button button_view_default" onClick={handleSubmit}>
            Сохранить
          </button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({

  editFilter: (params) => dispatch(editFilter(params)),
});

const mapStateToProps = (state) => ({
  clients: state.savedFilters.clients,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFilter);
