import min from "lodash/min";
import { createSelector } from "reselect";
import { getRoundedPrice } from '../utils';
const agencySelector = (state) => state.agency.data;
const blocksSelector = (state) => state.agency.complexes;
const setsSelector = (state) => state.agency.sets;
const currentCitySelector = (state) => state.agency?.currentSetCity;
const citiesSelector = (state) => state.agency.cities;
const currentSetCitySelector = (state) => state.agency.currentSetCity;
const currentActiveSetTab = (state) => state.agency.setTabs.find(tab=>tab.isActive);

export const sortedOrders = createSelector([agencySelector], (data) => {
  return data?.theme?.sections?.orders?.sort((a, b) => a.order - b.order)
});
export const getActiveSet = createSelector([setsSelector], (sets) => {
  return sets.find(set=>set.isActive)
});
export const getSetsByCity = createSelector([setsSelector, currentActiveSetTab], (sets, activeTab) => {
  return sets.filter(set => set.city === activeTab?.cityCode && activeTab.type === set.type)
});
export const cityObjectSelector = createSelector([citiesSelector, currentSetCitySelector], (cities, currentCity) => {
  return cities.find(city=>city.code === currentCity)
});

export const mapItems = createSelector([blocksSelector, currentCitySelector], (data, city, ) => {
  let items = [];
  if (data) {
    data.map((item) => {
      if (item.coordinates) {
        items.push(item);
      }
      return item;
    });
  }
  return items;
});
export const getFeatures = createSelector([blocksSelector], (data) => {
  const features = []
  data.map((item) => {
    if (item.coordinates) {
      features.push({
        type: 'Feature',
        id: item.id,
        geometry: {
          type: 'Point',
          coordinates: item.coordinates,
        },
        properties: {
          hintContent: item.name || item.roomType,
          minPrice: item.apartmentsInfo? getRoundedPrice(min(item.apartmentsInfo.apartmentsByRoomType.map(val=>val.minPrice))) : getRoundedPrice(item.price)
        },
      });
    }
  
    return item;
  });
  return features
});
