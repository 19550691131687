const initialState = {
	isFetching: false,
	error: false,
	data: null,
};

const tradeIn = (state = initialState, action) => {
	switch (action.type) {
		case 'TRADEIN_REQUEST':
			return {
				...state,
				isFetching: true,
			};
		case 'TRADEIN_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				data: action.data,
			};
		case 'TRADEIN_FAILURE':
			return {
				...state,
				isFetching: false,
				error: true,
			};
		case 'TRADEIN_RESET':
			return { ...initialState };
		default:
			return state;
	}
};

export default tradeIn;
