import { createSelector } from "reselect";

const clientModeSelector = (state) => state.general.clientMode;
const bannersSelector = (state) => state.home.banners;
const isMasterSelector = (state) =>
  state.user.access == "master" ||
  state.user.access == "general" ||
  state.user.access == "extended"
export const homeBannersSelector = createSelector(
  clientModeSelector,
  isMasterSelector,
  bannersSelector,
  (clientMode, isMaster, banners) =>
    clientMode || !isMaster ? banners.filter((banner) => !banner.hideClientMode) : banners
);
