import { getUrl, isDev } from '../api';
import { initialSettings } from '../utils';

const initialState = {
  isFetching: false,
  error: false,
  data: null,
  clients: null,
  settings: initialSettings,
  settingsData: initialSettings,
  formData: {
    settings: initialSettings,
    description: '',
    comment: '',
  },
  filePath: null
};

const presentation = (state = initialState, action) => {
  switch (action.type) {

    case 'PRESENTATION_REQUEST':
      return {
        ...state,
        isFetching: true,
      };
    case 'PRESENTATION_REQUEST_END':
      return {
        ...state,
        isFetching: false,
      };
    case 'PRESENTATION_SUCCESS':
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        settings: action.settings,
        settingsData: action.settingsData,
        formData: action.formData,
        filePath: getUrl(action.filePath, isDev? null: "https://panpartner.ru")
      };
    case 'PRESENTATION_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: action.clients,
      };
    case 'PRESENTATION_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case 'PRESENTATION_SET_FORMDATA':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.data,
        },
      };
    case 'PRESENTATION_RESET':
      return { ...initialState };
    case 'PRESENTATION_PHOTO_UPLOAD_SUCCESS':
      const tmpData = { ...state.data }
      tmpData.additionalPhotos = action.additionalPhotos
      return {
        ...state,
        data: tmpData
      }
    case 'PRESENTATION_PHOTO_REMOVE_SUCCESS':
      const tmpStateData = { ...state.data }
      tmpStateData.additionalPhotos = state.data.additionalPhotos.filter(photo => photo.id !== action.id)
      return {
        ...state,
        data: tmpStateData
      }
      case 'PRESENTATION_GET_PDF_SUCCESS':
          return { ...state, filePath: getUrl(action.filePath, isDev? null: "https://panpartner.ru") };
    default:
      return state;
  }
};

export default presentation;
