import { MortgageIcon } from '../icons';
import { Link } from 'react-router-dom';
import PanModal from '../../../core/PanModal';
import { useModal } from 'react-modal-hook';
import Button from '../../../core/Button';

const Mortgage = () => {
  const [openModal, closeModal] = useModal(() => (
    <PanModal closeModal={closeModal}>1231</PanModal>
  ));

  return (
    <section className="sf__promo sf__block sf__mortgage">
      <div className="sf__promo-icon">
        <MortgageIcon />
      </div>

      <div className="sf__promo-body sf__mortgage-body">
        <div className="sf__mortgage-content">
          <p className="h3-l sf__promo-title" onClick={openModal}>
            Нужна ипотека?
          </p>
          <p className="sf__promo-timing sf__mortgage-timing">
            <span className="sf__mortgage-text text">
              Получите информацию о реальных ипотечных ставках
            </span>
          </p>
        </div>

        <Button
          isLink
          isFull
          addClass="sf__mortgage-btn"
          to={'/'}
          target={'_blank'}
        >
          Оставить заявку
        </Button>
      </div>
    </section>
  );
};

export default Mortgage;
