const mocItemsData = {
  "items": [
      {
          "id": 63180,
          "name": "Двухкомнатная квартира в ЖК «Солнечный город»",
          "city": "spb",
          "cityName": "Санкт-Петербург",
          "clientId": [],
          "showCount": 0,
          "dateCreate": "2021-08-23T15:53:16.000Z",
          "$arProps": {
              "AGENT": {
                  "ID": "169",
                  "IBLOCK_ID": "23",
                  "NAME": "Агент",
                  "ACTIVE": "Y",
                  "SORT": "20",
                  "CODE": "AGENT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "Y",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": "UserID",
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Агент",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": 5435,
                  "PROPERTY_VALUE_ID": "2432179",
                  "DESCRIPTION": "",
                  "~VALUE": 5435,
                  "~DESCRIPTION": ""
              },
              "P_APARTAMENT": {
                  "ID": "170",
                  "IBLOCK_ID": "23",
                  "NAME": "Квартира(xml_id)",
                  "ACTIVE": "Y",
                  "SORT": "30",
                  "CODE": "P_APARTAMENT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "Y",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Квартира(xml_id)",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": "abc1862910",
                  "PROPERTY_VALUE_ID": "2432181",
                  "DESCRIPTION": null,
                  "~VALUE": "abc1862910",
                  "~DESCRIPTION": null
              },
              "SHOW_COUNT": {
                  "ID": "167",
                  "IBLOCK_ID": "23",
                  "NAME": "Количество просмотров",
                  "ACTIVE": "Y",
                  "SORT": "40",
                  "CODE": "SHOW_COUNT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Количество просмотров",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": "0",
                  "PROPERTY_VALUE_ID": "2432182",
                  "DESCRIPTION": null,
                  "~VALUE": "0",
                  "~DESCRIPTION": null
              },
              "TYPE": {
                  "ID": "338",
                  "IBLOCK_ID": "23",
                  "NAME": "Тип",
                  "ACTIVE": "Y",
                  "SORT": "500",
                  "CODE": "TYPE",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "L",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Тип",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": "Новостройка",
                  "VALUE_XML_ID": "APARTMENTS",
                  "VALUE_SORT": "500",
                  "VALUE": "Новостройка",
                  "PROPERTY_VALUE_ID": "2432207",
                  "VALUE_ENUM_ID": "48",
                  "DESCRIPTION": null,
                  "~VALUE": "Новостройка",
                  "~DESCRIPTION": null
              }
          },
          "showCount1": 0,
          "type": "Новостройка",
          "typeXmlId": "apartments",
          "xmlId": "abc1862910",
          "agentId": 5435,
          "object": {
              "id": 1232375,
              "plan": {
                  "id": 0,
                  "src": "https://dev2.panpartner.ru/upload/app_images/emptyPlan.svg",
                  "size": 0,
                  "contentType": "",
                  "height": 0,
                  "width": 0,
                  "empty": true
              },
              "active": false,
              "baseCost": 0,
              "baseCostFormated": "0 р.",
              "roomTypeName": "2 ккв",
              "floor": 0,
              "blockName": "",
              "costWithDiscount": 6850055,
              "costWithDiscountFormated": "6 850 055 р.",
              "agentCertificate": false,
              "decorations": [],
              "corpus": "18"
          }
      },
      {
          "id": 63179,
          "name": "Трехкомнатная квартира (Евро) в ЖК «Три кита 2»",
          "city": "spb",
          "cityName": "Санкт-Петербург",
          "clientId": [],
          "showCount": 0,
          "dateCreate": "2021-08-20T13:17:40.000Z",
          "$arProps": {
              "AGENT": {
                  "ID": "169",
                  "IBLOCK_ID": "23",
                  "NAME": "Агент",
                  "ACTIVE": "Y",
                  "SORT": "20",
                  "CODE": "AGENT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "Y",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": "UserID",
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Агент",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": 5435,
                  "PROPERTY_VALUE_ID": "2432150",
                  "DESCRIPTION": "",
                  "~VALUE": 5435,
                  "~DESCRIPTION": ""
              },
              "P_APARTAMENT": {
                  "ID": "170",
                  "IBLOCK_ID": "23",
                  "NAME": "Квартира(xml_id)",
                  "ACTIVE": "Y",
                  "SORT": "30",
                  "CODE": "P_APARTAMENT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "Y",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Квартира(xml_id)",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": "abc432671",
                  "PROPERTY_VALUE_ID": "2432152",
                  "DESCRIPTION": null,
                  "~VALUE": "abc432671",
                  "~DESCRIPTION": null
              },
              "SHOW_COUNT": {
                  "ID": "167",
                  "IBLOCK_ID": "23",
                  "NAME": "Количество просмотров",
                  "ACTIVE": "Y",
                  "SORT": "40",
                  "CODE": "SHOW_COUNT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Количество просмотров",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": "0",
                  "PROPERTY_VALUE_ID": "2432153",
                  "DESCRIPTION": null,
                  "~VALUE": "0",
                  "~DESCRIPTION": null
              },
              "TYPE": {
                  "ID": "338",
                  "IBLOCK_ID": "23",
                  "NAME": "Тип",
                  "ACTIVE": "Y",
                  "SORT": "500",
                  "CODE": "TYPE",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "L",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Тип",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": "Новостройка",
                  "VALUE_XML_ID": "APARTMENTS",
                  "VALUE_SORT": "500",
                  "VALUE": "Новостройка",
                  "PROPERTY_VALUE_ID": "2432177",
                  "VALUE_ENUM_ID": "48",
                  "DESCRIPTION": null,
                  "~VALUE": "Новостройка",
                  "~DESCRIPTION": null
              }
          },
          "showCount1": 0,
          "type": "Новостройка",
          "typeXmlId": "apartments",
          "xmlId": "abc432671",
          "agentId": 5435,
          "object": {
              "id": 1080203,
              "plan": {
                  "id": 0,
                  "src": "https://dev2.panpartner.ru/upload/app_images/emptyPlan.svg",
                  "size": 0,
                  "contentType": "",
                  "height": 0,
                  "width": 0,
                  "empty": true
              },
              "active": false,
              "baseCost": 0,
              "baseCostFormated": "0 р.",
              "roomTypeName": "3 ккв (Евро)",
              "floor": 0,
              "blockName": "",
              "costWithDiscount": 6786150,
              "costWithDiscountFormated": "6 786 150 р.",
              "agentCertificate": false,
              "decorations": [],
              "corpus": "2"
          }
      },
      {
          "id": 63178,
          "name": "Однокомнатная квартира в ЖК «Три кита 2»",
          "city": "spb",
          "cityName": "Санкт-Петербург",
          "clientId": [],
          "showCount": 0,
          "dateCreate": "2021-08-19T15:36:54.000Z",
          "$arProps": {
              "AGENT": {
                  "ID": "169",
                  "IBLOCK_ID": "23",
                  "NAME": "Агент",
                  "ACTIVE": "Y",
                  "SORT": "20",
                  "CODE": "AGENT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "Y",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": "UserID",
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Агент",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": 5435,
                  "PROPERTY_VALUE_ID": "2432121",
                  "DESCRIPTION": "",
                  "~VALUE": 5435,
                  "~DESCRIPTION": ""
              },
              "P_APARTAMENT": {
                  "ID": "170",
                  "IBLOCK_ID": "23",
                  "NAME": "Квартира(xml_id)",
                  "ACTIVE": "Y",
                  "SORT": "30",
                  "CODE": "P_APARTAMENT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "Y",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Квартира(xml_id)",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": "abc433191",
                  "PROPERTY_VALUE_ID": "2432123",
                  "DESCRIPTION": null,
                  "~VALUE": "abc433191",
                  "~DESCRIPTION": null
              },
              "SHOW_COUNT": {
                  "ID": "167",
                  "IBLOCK_ID": "23",
                  "NAME": "Количество просмотров",
                  "ACTIVE": "Y",
                  "SORT": "40",
                  "CODE": "SHOW_COUNT",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "S",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Количество просмотров",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": null,
                  "VALUE_XML_ID": null,
                  "VALUE_SORT": null,
                  "VALUE": "0",
                  "PROPERTY_VALUE_ID": "2432124",
                  "DESCRIPTION": null,
                  "~VALUE": "0",
                  "~DESCRIPTION": null
              },
              "TYPE": {
                  "ID": "338",
                  "IBLOCK_ID": "23",
                  "NAME": "Тип",
                  "ACTIVE": "Y",
                  "SORT": "500",
                  "CODE": "TYPE",
                  "DEFAULT_VALUE": "",
                  "PROPERTY_TYPE": "L",
                  "ROW_COUNT": "1",
                  "COL_COUNT": "30",
                  "LIST_TYPE": "L",
                  "MULTIPLE": "N",
                  "XML_ID": "",
                  "FILE_TYPE": "",
                  "MULTIPLE_CNT": "5",
                  "LINK_IBLOCK_ID": "0",
                  "WITH_DESCRIPTION": "N",
                  "SEARCHABLE": "N",
                  "FILTRABLE": "N",
                  "IS_REQUIRED": "N",
                  "VERSION": "1",
                  "USER_TYPE": null,
                  "USER_TYPE_SETTINGS": false,
                  "HINT": "",
                  "~NAME": "Тип",
                  "~DEFAULT_VALUE": "",
                  "VALUE_ENUM": "Новостройка",
                  "VALUE_XML_ID": "APARTMENTS",
                  "VALUE_SORT": "500",
                  "VALUE": "Новостройка",
                  "PROPERTY_VALUE_ID": "2432148",
                  "VALUE_ENUM_ID": "48",
                  "DESCRIPTION": null,
                  "~VALUE": "Новостройка",
                  "~DESCRIPTION": null
              }
          },
          "showCount1": 0,
          "type": "Новостройка",
          "typeXmlId": "apartments",
          "xmlId": "abc433191",
          "agentId": 5435,
          "object": {
              "id": 1080213,
              "plan": {
                  "id": 0,
                  "src": "https://dev2.panpartner.ru/upload/app_images/emptyPlan.svg",
                  "size": 0,
                  "contentType": "",
                  "height": 0,
                  "width": 0,
                  "empty": true
              },
              "active": false,
              "baseCost": 0,
              "baseCostFormated": "0 р.",
              "roomTypeName": "1 ккв",
              "floor": 0,
              "blockName": "",
              "costWithDiscount": 4835250,
              "costWithDiscountFormated": "4 835 250 р.",
              "agentCertificate": false,
              "decorations": [],
              "corpus": "5"
          }
      }
  ],
  "clients": [
      {
          "id": 402,
          "title": "Тестовый 1",
          "email": "—",
          "phone": "+7 (821) 332-13-12"
      },
      {
          "id": 401,
          "title": "Evgeny Bervyachenok",
          "email": "213123@gmail.com",
          "phone": "+7 (111) 111-11-11"
      }
  ]
}

export default mocItemsData;