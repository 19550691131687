import { Link } from 'react-router-dom';
import { CarIcon, WalkIcon } from './icons';
import './index.scss';
import classNames from 'classnames';
import StatusCircle from '../StatusCircle';
import {CardBottomStatusIcon, CardChromeBtnIcon, CardCubeBtnIcon, CardHeartBtnIcon} from "../../Public/markup/icons";
import Slider from "../../Public/markup/detail/Slider";
import React, {useEffect, useState} from "react";
import request, {getUrl} from "../../../api";
import CardSkeleton from "../../Public/markup/CardSkeleton";

const CardNew = ({
  link,
  topLabel,
  bottomLabel,
  src,
  title,
  subtiltle,
  descTitle,
  descValue,
  tableItems,
  subway,
  isShort,
  isFlat,
  isBordered,
  onMouseMove,
  id,
  bottomText,
  onClick,
  status,
  bottomLabelList,
  quickViewEnabled,
  aeroPanorama,
  isAutoPan,
  bottomStatus,
  withoutBtnsList,
  customTable,
  deadline,
  handleFavorite,
  inFavorite,
  updateFlat,
  isPublic,
  houseType,
  withQuickView,
  showQuickCard,
  isActive,
  isFetchingQuickCard,
  setIsFetchingQuickCard
}) => {
  const cardClass = classNames({
    'sf-card': true,
    'sf-card_short': isShort,
    'sf-card_flat': isFlat,
    'sf-card_bordered': isBordered,
    'sf-card_flat-update': updateFlat,
  });

  const [quickCardData, setQuickCardData] = useState({})
  const fetchQuickCard = (id) => {
    setIsFetchingQuickCard(true)
    return request('panpartner:new.suburbans.ajax', 'fastViewCottageSettlement', { id })
      .then((resp) => {
        setIsFetchingQuickCard(false)
        setQuickCardData(resp)
      })
  }


  const wrap = content => link ? <Link
    to={link}
    target={!quickViewEnabled && '_blank'}
    className={cardClass}
    onMouseMove={() => onMouseMove?.(id)}
    onClick={() => onClick?.(id)}
  >{content}</Link> : <div
    className={cardClass}
    onMouseMove={() => onMouseMove?.(id)}
    onClick={() => onClick?.(id)}
  >{content}</div>

  return wrap(<><div className="sf-card__header">
    {status && <StatusCircle status={status} addClass="sf-card__status" />}
    {!!topLabel && (
      <span className="sf-card__hashtag sf-card__label">{topLabel}</span>
    )}
    {!!bottomLabel && (
      <span className="sf-card__date sf-card__label">{bottomLabel}</span>
    )}

      {!withoutBtnsList &&
      <ul className="sf-card__btn-list sf-card__btn-list-bottom">
        {!isPublic && <li className="sf-card__btn sf-card__btn_heart" onClick={handleFavorite}>
          <CardHeartBtnIcon inFavorite={inFavorite}/>
        </li>}
        {aeroPanorama && aeroPanorama !== undefined && aeroPanorama !== false && aeroPanorama !== null &&
          <li className="sf-card__btn sf-card__btn_cube">
            <CardCubeBtnIcon/>
          </li>
        }
        {isAutoPan && isAutoPan !== undefined && isAutoPan !== false && isAutoPan !== null &&
          <li className="sf-card__btn sf-card__btn_chrome">
            <Link to="/autopan" target="_blank"><CardChromeBtnIcon/></Link>
          </li>
        }
      </ul>
      }

      {withQuickView &&
        <div className="quick-overcard">
          <div onClick={(e) => {
            e.preventDefault()
            showQuickCard()
            setIsFetchingQuickCard(true)
            fetchQuickCard(id)
          }} className="quick-icon">
            <svg className="icon icon_info" style={{width: 25, height: 25, padding: 6, borderRadius: '50px', backgroundColor: '#f4f4f6', fill: '#ef362e'}}>
              <use xlinkHref="#info"></use>
            </svg>
          </div >
          {isActive && isFetchingQuickCard &&
            <div className="quick-card quick-card_skeleton">
              <CardSkeleton small />
            </div>

          }

          {isActive && !isFetchingQuickCard && Object.entries(quickCardData).length !== 0 &&
            <div className="quick-card" onClick={(e) => e.preventDefault()}>

              <div className="quick-card__close" onClick={(e) => {
                e.preventDefault()
                showQuickCard();
              }}>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_352_6015)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M34 17C34 7.61147 26.3885 0 17 0C7.61147 0 0 7.61147 0 17C0 26.3885 7.61147 34 17 34C26.3885 34 34 26.3885 34 17Z" fill="#F6F7F8"/>
                    <path d="M14 14L17 17L20 14" stroke="#2C2E3E" stroke-linecap="round"/>
                    <path d="M14 20L17 17L20 20" stroke="#2C2E3E" stroke-linecap="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_352_6015">
                      <rect width="34" height="34" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div className="quick-card__left">
                <Slider
                  items={quickCardData.village.photo}
                />
              </div>
              <div className="quick-card__right">

                <div>
                  <p className="quick-card__title">{quickCardData.village.title}</p>
                  <p className="quick-card__address">{quickCardData.village.address}</p>
                  <p className="quick-card__builder">{quickCardData.village.builderVillage.title}</p>
                  <ul className="quick-card__info-list">
                    {quickCardData.objectParams.map((i) => (
                      <li>
                        <p>{i.value}</p>
                        <p>{i.title}</p>
                      </li>
                    ))}
                  </ul>
                  <ul className="quick-card__second-info">
                    {quickCardData.objectsData.map((i) => (
                      <li>
                        <p>{i[0]}</p>
                        <p>{i[1]}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <button className="btn btn_primary h6 quick-card__btn" onClick={() => window.open(getUrl(link), '_blank')}>Подробнее</button>
              </div>
            </div>
          }


        </div>
      }

      {!!bottomLabelList && (
      <div className="sf-card__label-list">
        {bottomLabelList.map((label) => (
          <span className="sf-card__label" key={label}>{label}</span>
        ))}
      </div>
    )}

      {deadline &&
      <div className="sf-card__label-list">
        <span className="sf-card__label">{deadline}</span>
      </div>
      }
    <img className="sf-card__pic" src={src} alt="" />
  </div>
    <div className="sf-card__body">
      {
        houseType && <p className="sf-card__before-title">{houseType}</p>
      }
      {!!title && (
        <h3
          className={`${isShort ? 'h6' : isFlat ? 'h55' : 'h3'
            } sf-card__title`}
        >
          {title}
        </h3>
      )}
      {!!subtiltle && <p className="text sf-card__text">{subtiltle}</p>}

      {(descTitle || descValue) && (
        <p className="sf-card__descr-row">
          {descTitle && <p className="text sf-card__descr">{descTitle}</p>}
          {!isShort && descValue && (
            <p className="text sf-card__descr">{descValue}</p>
          )}
        </p>
      )}

      {!!tableItems && (
        <div className={`sf-card__table ${customTable ? 'sf-card__table_custom' : ""}`}>
          {tableItems.map(({ title, count, middleTitle, subtitle, id }) => (
            <div className="sf-card__row" key={id}>
              <div className="sf-card__col">
                <b className="sf-card__type">{title}</b>
                {count && <span className="sf-card__counter">{count}</span>}
              </div>
              {!isShort && (
                <div className="sf-card__col">
                  <span className="sf-card__param">{middleTitle}</span>
                </div>
              )}

              <div className="sf-card__col">
                <span className="sf-card__param">{subtitle}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {subway && !isFlat && (
        <div className="sf-card__metro-list">
          {subway.map(({ color, distanceType, minutes, name }) => (
            <div className={`sf-card__metro`} key={name}>
              <span className="sf-card__metro-station">
                <span
                  className="sf-card__metro-circle"
                  style={{ backgroundColor: color }}
                ></span>
                м. {name}
              </span>
              {!!distanceType && !!minutes && (
                <div className="sf-card__metro-time">
                  {distanceType === 'byTransport' ? (
                    <CarIcon />
                  ) : (
                    <WalkIcon />
                  )}
                  <span className="sf-card__metro-route">{minutes} мин.</span>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {bottomText && (
        <span className="sf-card__descr sf-card__bottom-text">
          {bottomText}
        </span>
      )}
      {
        bottomStatus && (
          <div className="sf-card__bottom-status">
            <CardBottomStatusIcon classname={bottomStatus.color}/> {bottomStatus.value}
          </div>
        )
      }
    </div></>
  )
};

export default CardNew;
