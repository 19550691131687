import {useParams} from "react-router-dom";
import useApi from "../../hooks/useApi";
import React, {useEffect, useState} from "react";
import request, {getUrl} from "../../api";
import useDocTitle from "../../hooks/useDocTitle";
import {useMapModal} from "../../hooks/useMapModal";
import {useModal} from "react-modal-hook";
import PanModal from "../core/PanModal";
import ContactsModal from "../Public/markup/detail/ContactsModal";
import useMedia from "../../hooks/useMedia";
import {connect, useSelector} from "react-redux";
import useUniversalForm from "../../hooks/useUniversalForm";
import Preloader from "../Preloader";
import SubmitButton from "../SubmitButton";
import {AptStatusIcon, HeartIcon, PdfIcon, PresentationIcon} from "../Public/markup/icons";
import AddPresenatation from "../AddPresentation";
import Slider from "../Public/markup/detail/Slider";
import CommercialCalc from "../Commercial/Redesign/CommercialCalc";
import InfoCard from "../Public/markup/detail/InfoCard";
import Button from "../core/Button";
import {addToFavorites, removeFromFavorites} from "../../actions/favorites";

const SecondaryObject = ({removeFromFavorites, addToFavorites}) => {

  // const {id} = useParams()
  const {id} = 312
  const city = "spb";
  const {
    data: {
      secondary: {
        plan,
        photos,
        generalPlan,
        squareTotal,
        viewFromWindow,
        presentation,
        xmlId,
        ...secondary
      } = {},
      objectInfo,
      tabs,
      dateUpdateInfo,
      blockParamsInfo,
      priceInfo,
      meterPriceInfo,
      contacts,
      schedule,
      bookingLink,
      keyRate,
    },
    error,
    isLoading,
    isSending,
    send,

  } = useApi({
    payload: ['panpartner:secondaries.ajax', 'getSecondary', { id: 312, city,}],
    favoritesParams: {
      city,
      id: 312,
      type: 'secondary',
    },
    favoritePath: 'favorite',
  }, [id])


  const [fetchingPdf, setFetchingPdf] = useState(false);
  const getPdf = (xmlID, city, firstPay, percent, period) => {
    setFetchingPdf(true)
    return request('panpartner:presentations.ajax', 'getPdf', {
      objectXmlId: xmlID,
      city: city,
      type: 'secondary',
      'mortgage[initialPayment]': firstPay,
      'mortgage[percent]': percent,
      'mortgage[period]': period,
    })
      .then((data) => {
        // debugger
        setFetchingPdf(false)
        let path = getUrl(data.filePath)
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      })
  }



  const [filePath, setfilePath] = useState(false)

  useDocTitle(secondary?.name)
  const [openMapModal, closeMapModal] = useMapModal({
    cords: secondary?.coordinates,
    mapCenter: secondary?.coordinates,
  })

  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          params={{
            type: "secondary",
            objectId: id,
            city
          }}
        />
      </PanModal>
    ),
    [contacts],
  );


  const isLaptop = useMedia("isLaptop");
  const [favorites, setFavorites] = useState(secondary?.favorite?.inFavorite);
  useEffect(() => {
    setFavorites(secondary?.favorite?.inFavorite)
  }, secondary?.favorite?.inFavorite)
  const handleFavorites = (inFavorite) => {
    inFavorite
      ? removeFromFavorites("spb", id, "secondary", "secondary")
      : addToFavorites("spb", id, "secondary", "secondary");
    setFavorites(!favorites);
  };

  const {data: {files, managers, otherLinks, mortgage}} = useApi({
    payload: ["panpartner:calc.ajax", "getData"]
  })
  const [priceEstate, setPriceEstate] = useState(0)
  const [firstPayEstate, setFirstPayEstate] = useState(0)
  const [timeEstate, setTimeEstate] = useState(30)
  const [rateEstate, setRateEstate] = useState(0)

  const userInfo = useSelector(state => state.user?.info);
  const { send: sendUniveralForm } = useUniversalForm({
    formId: 65,
    defaultValues: {
      name: userInfo?.name,
      secondName: userInfo?.lastName,
      phone: userInfo?.phone || '',
      company: userInfo?.workCompany,
    },
  });

  useEffect(() => {
    priceInfo && setPriceEstate(parseInt(priceInfo[0].value.replace(/[\s|₽]/g, "")));
    setRateEstate(keyRate?? 10)
  }, [priceEstate, rateEstate, priceInfo])


  if (isLoading) return <Preloader loading />;
  const renderPresentations = ({ fetching, finish, link, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={finish ? () => window.open(link) : handleSend}
    >
      <PresentationIcon />
      {finish || presentation?.id ? "К презентации" : "В презентации"}
    </SubmitButton>
  }

  return (
    <div className="sf wrapper">
      <div className="apt-modal__title">
        <h3 className="h3">{`${secondary.roomType?.name ? secondary.roomType?.name : secondary.objectType?.name},\n ${secondary.squareTotal} м²`}</h3>
        <div className={`label-item apt-modal__status label_${secondary?.status?.color}`}>
          <AptStatusIcon />
          {secondary?.status.name}
        </div>
      </div>

      <div className="sf__detail-bar" style={{marginTop: 7, marginBottom: 0}}>
        {/*<a href={blockParamsInfo[0].link} className="link link_color-red">{blockParamsInfo[0].value}</a>*/}
        {secondary?.address && <p className="text sf__detail-descr sf__detail-descr_center" style={{color: '#2C2E3E'}}>{secondary?.address}</p>}
        {secondary?.coordinates && (
          <span className="link link_color-red" onClick={openMapModal}>
          На карте
        </span>
        )}

      </div>
      <div className="apt-modal__header-second">
        <div className="sf sf--apt-modal">
          <div className="sf__elems">
            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => handleFavorites(favorites)}
              style={{width: 192}}
            >
              <HeartIcon isActive={favorites} />
              {favorites ? 'Убрать из избранного' : 'Добавить в избранное'}
            </button>
            <AddPresenatation
              type={'secondary'}
              id={xmlId}
              city={city}
              presentation={presentation}
              render={renderPresentations}
            />
            {/*{fetchingPdf ? <div>loading</div> :*/}
            {/*  <button*/}
            {/*    className="sf__elem-btn button link"*/}
            {/*    onClick={() => {getPdf(xmlId, city, firstPayEstate, rateEstate, timeEstate)}}>*/}
            {/*    <PdfIcon />*/}
            {/*    Скачать PDF*/}
            {/*  </button>*/}
            {/*}*/}

            <SubmitButton
              className="sf__elem-btn button link"
              onClick={() => {getPdf(xmlId, city, firstPayEstate, rateEstate, timeEstate)}}
              isLoading={fetchingPdf}
            >
              <PdfIcon />
              Скачать PDF
            </SubmitButton>

          </div>
        </div>
      </div>

      <div className="sf">
        <div className="sf__detail-row sf__detail-row--common-page">
          <div className="sf__detail-col">
            <Slider
              items={photos}
              withBorder
              showButton={!!viewFromWindow}
              viewFromWindow={viewFromWindow}
              windowView
            />
            {mortgage &&
            <CommercialCalc
              price={priceEstate}
              city={"spb"}
              type="apartment"
              id={'calcId1'}
              period={timeEstate}
              prepay={firstPayEstate}
              percent={rateEstate}
              btnClass={`constructor__btn`}
              mortgage={mortgage}
              setPriceEstate={setPriceEstate}
              setFirstPayEstate={setFirstPayEstate}
              setTimeEstate={setTimeEstate}
              setRateEstate={setRateEstate}
              sendUniveralForm={sendUniveralForm}
              getPdf={() => {
                getPdf(xmlId, city, firstPayEstate, rateEstate, timeEstate)
              }}
              fetchingPdf={fetchingPdf}
            />
            }
          </div>
          <div className="sf__detail-col">
            <InfoCard items={objectInfo} cols={priceInfo}/>
            <section className="sf__section" style={{marginTop: -10}}>
              {!!bookingLink &&<Button buttonType="primary" isFull onClick={()=>window.open(bookingLink)}>
                Забронировать
              </Button>}
              <Button buttonType="line" isFull onClick={openContactsModal}>
                Контакты
              </Button>
            </section>
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, "secondary")),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, "secondary")),
});

const mapStateToProps = (state) => ({
  data: state.commercialUnit.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryObject);
