import React from "react";
import useDropdown from "../hooks/useDropdown";


const Dropdown = ({ value, options, onChange, titleIcon, itemIcon, forEvents }) => {
  const [node, open, show, hide] = useDropdown(false);

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    hide();
  };

  if (!options.length) return "";

  return (
    <div ref={node} className={`custom-select ${open ? `is-showing` : ``}`}>
      <div
        className="custom-select__selected"
        onClick={(e) => (open ? hide() : show())}
      >
        {value.title} {titleIcon && titleIcon()}
      </div>
      <ul className="custom-select__options">
        {options.map((item) => (
          <li
            className={
              "custom-select__option" +
              (value.id === item.id ? " is-active" : "")
            }
            key={item.id}
            onClick={() => handleChange(forEvents ? item.title : item.id)}
          >
            {itemIcon && itemIcon(item)} {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
