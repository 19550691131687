import 'antd/es/table/style/index.css';
import isEqual from 'lodash/isEqual';
import React from 'react';
import useMedia from '../../../../hooks/useMedia';
import { formatMoney, formatDate } from '../../../../utils';
import Checkbox from '../../../core/Checkbox';
import Dropdown from '../../../core/Dropdown';
import Table from '../../../core/Table';
import { MyObectsAction, MyObjectClients, MyObjectName } from './Components';


const formattedNumericDate = (date) => {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  return new Date(date).toLocaleDateString('ru', options);
};


const Items = (props) => {
  const {
    items,
    handleDelete,
    clients,
    fetchClients,
    handleAttachClient,

    toggleItems,
    selectedItems,
    statuses,
    statusTitleKey,
    changeStatus,
    domain,
    dealType,
  } = props;

  const isMobile = useMedia("isMobile");
  const isTablet = useMedia("isTablet");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  const TOOLTIP_CUT_VALUE = !isDesktop ? 25 : 15;

  const getMobileColumns = () => [
      {
        title: () => {
          const allIds = items.map(item => item.id).sort()
          const checked = isEqual(allIds, [...selectedItems].sort())
          return <Checkbox
            id={'all'}
            view="invert"
            checked={checked}
            onChange={e => toggleItems(allIds, e.target.checked)}
          />
        },
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (id) => (
          <Checkbox
            id={id}
            view="invert"
            checked={selectedItems.includes(id)}
            onChange={e => toggleItems(id, e.target.checked)}
          />
        ),
      },
      {
        title: "Описание",
        dataIndex: 'name',
        key: 'title',
        render: (text, record) => {
          return <MyObjectName {...record} domain={domain} />;
        },
      },
  
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => {
          return <span>{text.slice(0, 5)}</span>;
        },
      },
  
      {
        title: 'Клиент',
        dataIndex: 'object',
        key: 'object',
        render: (object, record) => {
          return (
            <MyObjectClients
              clients={clients}
              data={record}
              fetch={fetchClients}
              handleAttachClient={handleAttachClient}
            />
          );
        },
      },
  
      {
        title: "Стат",
        dataIndex: "status",
        key: "status1",
        render: (status) => {
          return (
            <div class="table2-status">
              <div className={`status-circle status-circle_${status.color}`}></div>
            </div>
          )
        },
      },
    
  ];



  const getColumns = () => [
    {
      title: () => {
        const allIds = items.map(item => item.id).sort()
        const checked = isEqual(allIds, [...selectedItems].sort())
        return <Checkbox
          id={'all'}
          view="invert"
          checked={checked}
          onChange={e => toggleItems(allIds, e.target.checked)}
        />
      },
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
        <Checkbox
          id={id}
          view="invert"
          checked={selectedItems.includes(id)}
          onChange={e => toggleItems(id, e.target.checked)}
        />
      ),
    },
    {
      title: "Фото / планировка",
      dataIndex: 'name',
      key: 'title',
      width: isDesktop ? (isLaptop ? 'auto' : 250) : 380,
      //width: isDesktop ? 250 : 400,
      render: (text, record) => {
        return <MyObjectName {...record} domain={domain} />;
      },
    },

    {
      title: 'Тип объекта',
      dataIndex: 'type',
      key: 'type',
      width: isLaptop ? 'auto' : 130,
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },

    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      className: !isLaptop ? "show" : "hidden",
      width: isDesktop ? "auto" : 220,
      render: (address) => {

          return address.length > TOOLTIP_CUT_VALUE ? (
            <div className="table2-with-tooltip">
              <span>{`${address?.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
              <div className="table2-with-tooltip__content">{address}</div>
            </div>
          ) : (
            <span>{address}</span>
          )

      },
    },

    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      className: !isLaptop ? "show" : "hidden",
      sorter: (a, b) => {
        if (!a.object) return 0;
        if (!b.object) return 1;

        return a.object.baseCost - b.object.baseCost;
      },
      render: (price, record) => {
        let cost = price ? formatMoney(price, dealType === "rent" ? record.rentTypeXmlId === "daily" ? " р./сутки" : "р./мес" : " р.") : '—';
        return <span className="nowrap">{cost}</span>;
      },
    },
    {
      title: 'Клиент',
      dataIndex: 'object',
      key: 'object',
      
      width: isLaptop ? 'auto' : 240,
      render: (object, record) => {
        return (
          <MyObjectClients
            clients={clients}
            data={record}
            fetch={fetchClients}
            handleAttachClient={handleAttachClient}
          />
        );

        // clients.map((item) => (
        // 	<a className="clients__phone" key={item} href={`mailto:${item}`}>
        // 		{item}
        // 	</a>
        // ));
      },
    },

    {
      title: '',
      dataIndex: 'views',
      key: 'countViews',
      width: 60,
      render: (countViews, record) => {
        return (
          <div className="table-view">
            <svg className="icon icon_view">
              <use xlinkHref="#view"></use>
            </svg>
            <span>{`${record.countViews}`}</span>
          </div>
        );

      },
    },

    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <Dropdown
            options={statuses}
            value={status.id}
            onChange={status => changeStatus(record.id, status)}
            titleKey={statusTitleKey}
            colored
            openTop={selectedItems.length}
          />
        );

      },
    },


    // {
    //   title: 'Дата создания',
    //   dataIndex: 'date',
    //   key: 'date',
    //   sorter: (a, b) =>
    //     new Date(a.dateCreate) > new Date(b.dateCreate) ? 1 : -1,
    //   render: (text, record) => {
    //     return (
    //       <span className="nowrap">{formattedDate(record.dateCreate)}</span>
    //     );
    //   },
    // },
    {
      title: '',
      key: 'action',
      width: isLaptop ? 30 : 50,
      render: (text, record) => (
        <MyObectsAction
          {...record}
          deleteClient={handleDelete}
          clients={clients}
          fetch={fetch}
          handleAttachClient={handleAttachClient}
        />
      ),
    },
  ];

  return (
    <div className="pan-table">

      <div className="flats__row-table is-active">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={isMobile ? getMobileColumns() : getColumns()}
          // columns={getColumns()}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: 'Ничего не найдено' }}
          customMoreTitle={isMobile && "Развернуть все"}
          customPagination={true}
          // expandable={isMobile ? {
          //   expandedRowRender: record => expandedRow(record),
          // } : false}
          initialCount={isMobile && 5}
          isLoadAll={true}
        />
{/* 
        {isMobile &&
          <button className="presentation-filters-btn" onClick={props.onClick}>
            <Filters />
            Фильтры: 3
          </button>
        } */}
      </div>
    </div>
  );
};

export default Items;