import React from "react";
import FilesBreadcrumbs from "./FilesBreadcrumbs";

const FilesHeader = (props) => {
  return (
    <section className="section section_view_white">
      <div className="wrapper">
        <div className="files__header">
          <div className="files__title">
            <h1 className="h1">Документы и файлы</h1>
          </div>
          <FilesBreadcrumbs {...props} />
        </div>
      </div>
    </section>
  );
};

export default FilesHeader;
