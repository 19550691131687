import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import { getPdf } from '../../actions/presentations';
import { getUrl } from '../../api';
import { downloadLink, media, settingsToParams } from '../../utils';
import Confirm from '../core/Confirm';
import Popup from '../core/Popup';
import SubmitButton from '../SubmitButton';
import { errorToast, infoToast } from '../toasts';
import PresentationControls from './PresentationControls';
import {AptStatusIcon} from "../Public/markup/icons";
import PanModal from "../core/PanModal";
import ContactsModal from "../Public/markup/detail/ContactsModal";
import Modal from "../core/Modal";
import Button from "../core/Button";

const PresentationHeader = (props) => {
  const { object, handleDelete, id, formData, save, handleSave, canSave, isFetching, name, data, settings } = props;
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromCrm = queryParams.get('fromCrm');

  const confirmDelete = () => {
    handleDelete([id]).then(
      (data) => {
        hideModal();
        infoToast(<div>Презентация успешно удалена</div>)
        setTimeout(() => history.push('/personal/presentations'), 1000);
      },
      (error) => {
        hideModal();
        errorToast(<div>{error?.[0]?.message}</div>)
      },
    );
  };

  const [confirmModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Confirm
        close={hideModal}
        title={`Вы действительно хотите удалить презентацию?`}
        confirm={confirmDelete}
        cancel={hideModal}
      />
    ),
    [confirmDelete],
  );

  const [openDeleteModal, closeDeleteModal] = useModal(
    () => (
      <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={closeDeleteModal}>
        <div className="crm-table__sf-controlsmodaltitle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
            <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
            <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
          </svg>
          Удалить презентацию?
        </div>
        <div className="crm-table__sf-controlsmodaltext">
          После удаления вся информация будет утеряна
        </div>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={confirmDelete}>Да</Button>
          <Button buttonType="line" onClick={closeDeleteModal}>Нет</Button>
        </div>
      </Modal>
    ),
    [confirmDelete]);


  const onSave = () => {
    handleSave();
    save().then(
      () => infoToast(<div>Изменения успешно сохранены</div>),
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const [closePopup, setClosePopup] = useState(false);

  const closeClick = () => {
    setClosePopup(true)
  }
  const [isPreviewLoading, setisPreviewLoading] = useState(false)

  const [previewContent, setPreviewContent] = useState(false)
  const [openPreviewModal, closePreviewModal] = useModal(
    () => (
      <PanModal title={'Предварительный просмотр'} closeModal={closePreviewModal} isBig>
        <iframe className="presentation__frame" src={previewContent} frameborder="0"/>
      </PanModal>
    ),
    [previewContent],
  );

  const getPreview = () => {
    // setisPreviewLoading(true)
    openPreviewModal()
    const form = {
      ...settingsToParams(formData.settings),
      id,
      description: formData.description,
      comment: formData.comment,
      city: object.city
    }
    getPdf(form).then((data) => {
      // downloadLink(getUrl(data.filePath));
      setPreviewContent(getUrl(data.filePath))
      // setisPreviewLoading(false)
    })
  }

  return (
    <>
      <div className="sf wrapper">
        <div className="apt-modal__title">
          <NavLink
            to={!fromCrm ? '/personal/presentations' : '/crm/presentations'}
            className="common-header__chevron"
          >
            <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round"/>
            </svg>
          </NavLink>
          <h3 className="common-title">{name}</h3>

        </div>

        <div className="apt-modal__header-second" style={{position: 'relative'}}>
          <div className="sf sf--apt-modal" style={{marginTop: 10}}>

            <ul className="presentation__action">
              <li className="presentation__action-item" onClick={getPreview}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" fill="white"/>
                  <path d="M12 7C6.28571 7 4 12 4 12C4 12 6.28571 17 12 17C17.7143 17 20 12 20 12C20 12 17.7143 7 12 7Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Просмотреть
              </li>
              <li className="presentation__action-item" onClick={onSave}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 9.16178V18.4442C19 18.613 18.933 18.7748 18.8136 18.8942C18.6943 19.0135 18.5324 19.0806 18.3636 19.0806H5.63636C5.46759 19.0806 5.30573 19.0135 5.18639 18.8942C5.06705 18.7748 5 18.613 5 18.4442L5 5.71693C5 5.54816 5.06705 5.38629 5.18639 5.26695C5.30573 5.14761 5.46759 5.08057 5.63636 5.08057L14.9188 5.08057C15.0858 5.08043 15.2462 5.14595 15.3653 5.26299L18.8176 8.71526C18.9346 8.8344 19.0001 8.99477 19 9.16178Z" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 19.0806L8 13.7472C8 13.5704 8.07024 13.4009 8.19526 13.2758C8.32029 13.1508 8.48986 13.0806 8.66667 13.0806L15.3333 13.0806C15.5101 13.0806 15.6797 13.1508 15.8047 13.2758C15.9298 13.4009 16 13.5704 16 13.7472V19.0806" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14 8.08057H9" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                Сохранить
              </li>
              <li className="presentation__action-item" onClick={openDeleteModal}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 6H19" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14 10V16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10 10V16" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6 6V19.3C6 19.4857 6.07024 19.6637 6.19526 19.795C6.32029 19.9263 6.48986 20 6.66667 20H17.3333C17.5101 20 17.6797 19.9263 17.8047 19.795C17.9298 19.6637 18 19.4857 18 19.3V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 6V5C9 4.73478 9.12643 4.48043 9.35147 4.29289C9.57652 4.10536 9.88174 4 10.2 4H13.8C14.1183 4 14.4235 4.10536 14.6485 4.29289C14.8736 4.48043 15 4.73478 15 5V6" stroke="#2C2E3E" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                Удалить
              </li>
            </ul>
            <PresentationControls data={data} settings={settings} {...props} />

          </div>
        </div>

      </div>




    {/*  <section className="section section_view_white no-header-profile">*/}
    {/*  <div className="wrapper">*/}
    {/*    <div className="presentation__header">*/}
    {/*      {!media("isMobile") &&*/}
    {/*        <h1 className="h1">{name}</h1>*/}
    {/*      }*/}

    {/*      <div className="presentation__controls">*/}
    {/*        <div className="presentation__control">*/}
    {/*          <SubmitButton*/}
    {/*            className="button button_view_outline"*/}
    {/*            onClick={getPreview}*/}
    {/*            isLoading={isPreviewLoading}*/}
    {/*          >*/}
    {/*            <span>Просмотреть</span>*/}
    {/*          </SubmitButton>*/}
    {/*        </div>*/}

    {/*        <div className="presentation__control">*/}
    {/*          <button className="button button_view_default" onClick={onSave} disabled={!canSave}>*/}
    {/*            <span>Сохранить</span>*/}
    {/*          </button>*/}
    {/*        </div>*/}

    {/*        {!media('isMobile') &&*/}
    {/*          <div className="presentation__control" onClick={confirmModal}>*/}
    {/*            <div className="button_view_round-with-label">*/}
    {/*              {!media("isMobile") &&*/}
    {/*                <div className="button button_view_round-control">*/}
    {/*                  <svg className="icon button__icon">*/}
    {/*                    <use xlinkHref="#delete"></use>*/}
    {/*                  </svg>*/}
    {/*                </div>*/}
    {/*              }*/}
    {/*              <span className="button__label">Удалить</span>*/}
    {/*            </div>*/}
    {/*          </div>*/}
    {/*        }*/}

    {/*        {media("isMobile") &&*/}
    {/*          <>*/}
    {/*          <Popup*/}
    {/*            trigger={*/}
    {/*              <div className="presentation__control">*/}
    {/*                <div className="button_view_round-with-label">*/}
    {/*                  {!media("isMobile") &&*/}
    {/*                    <div className="button button_view_round-control">*/}
    {/*                      <svg className="icon button__icon">*/}
    {/*                        <use xlinkHref="#delete-outline"></use>*/}
    {/*                      </svg>*/}
    {/*                    </div>*/}
    {/*                  }*/}
    {/*                  <span className="button__label">Удалить</span>*/}
    {/*                </div>*/}
    {/*              </div>*/}
    {/*            }*/}
    {/*            closePopup={closePopup}*/}
    {/*          >*/}
    {/*            <div className="presentation__content-controls">*/}
    {/*              <div*/}
    {/*                className="button label_with-icon_prefix link-hover"*/}
    {/*                onClick={confirmDelete}*/}
    {/*              >*/}
    {/*                <span>Да</span>*/}
    {/*              </div>*/}
    {/*              <div*/}
    {/*                className="button label_with-icon_prefix link-hover"*/}
    {/*                onClick={closeClick}*/}
    {/*              >*/}
    {/*                <span>Нет</span>*/}
    {/*              </div>*/}
    {/*            </div>*/}
    {/*          </Popup>*/}

    {/*          <Popup*/}
    {/*            trigger={*/}
    {/*              <div className="presentation__control" onClick={togglePopup}>*/}
    {/*                <div className="button_view_round-with-label is-round">*/}
    {/*                  <span className="dot" />*/}
    {/*                  <span className="dot" />*/}
    {/*                  <span className="dot" />*/}
    {/*                </div>*/}
    {/*              </div>*/}
    {/*            }*/}
    {/*          >*/}
    {/*            <PresentationControls {...props} isFetching={isFetching} />*/}
    {/*          </Popup>*/}
    {/*          </>*/}
    {/*        }*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</section>*/}

    </>
  );
};

export default PresentationHeader;
