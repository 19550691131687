import { defaultFullFilter } from '../utils/defaultFilters';
const initialState = {
	isFetching: false,
	error: false,
	data: [],
	filterData: {
		isActive: [],
		agents: [],
		clients: [],
		dates: {
			min: null,
			max: null,
		},
	},
	filter: {
		isActive: [],
		agents: [],
		clients: [],
		dates: {
			startDate: null,
			endDate: null,
		},
	},
	clients: [],
	filterSend: defaultFullFilter,
	isShowFilter: "N"
};

const savedFilters = (state = initialState, action) => {
	switch (action.type) {
		case 'SAVEDFILTERS_REQUEST':
			return {
				...state,
				isFetching: true,
			};
		case 'SAVEDFILTERS_SUCCESS':
			return {
				...state,
				isFetching: false,
				error: false,
				data: action.data,
				filterData: action.filterData,
				clients: action.clients,
				isShowFilter: "N"
			};
		case 'SAVEDFILTERS_SET_FILTERSEND':
			return {
				...state,
				filterSend: action.data,
			};
		case 'SAVEDFILTERS_CLEAR_FILTERSEND':
			return {
				...state,
				filterSend: defaultFullFilter,
			};
		case 'SAVEDFILTERS_FAILURE':
			return {
				...state,
				isFetching: false,
				error: true,
			};
		case 'SAVEDFILTERS_RESET':
			return { ...initialState };
		case 'SAVEDFILTERS_SET_FILTER':
			return { ...state, filter: { ...state.filter, ...action.filter } };
		case 'SAVEDFILTERS_REMOVE':
			return {
				...state,
				data: state.data.filter((item) =>
					action.ids.every((id) => id != item.id),
				),
			};
		case 'SAVEDFILTERS_CLEAR_FILTER':
			return { ...state, filter: initialState.filter };
	 	case 'TOGGLE_SHOW_FILTER': 
	 		return { ...state, isShowFilter: action.data }
		default:
			return state;
	}
};

export default savedFilters;
