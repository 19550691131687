import React from "react";
import TinySlider from "tiny-slider-react";
import { getUrl } from "../../api";
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import { formatMoney, getPricePerMeter } from "../../utils";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import CardHeart from "./CardHeart";


const whitelistForCardClick = [
  "card_slider__header",
  "card_slider__content",
  "card_slider__title",
  "card_slider__address",
  "card_slider__left",
  "card_slider__right",
  "card_slider__metro-station",
  "card_slider__left-part",
  "card_slider__metro-color card_slider__metro-color_1",
  "card_slider__dates-to",
  "card_slider__dates-from",
  "card_slider__pill",
  "card_slider__option is-apartments",
  "card_slider__option is-assignments",
  "aero-icon",
  "aero-svg",
];
const SliderCard = (props) => {
  const {
    id,
    photo,
    name,
    address,
    builder,
    countBuildings,
    roomType,
    subway,
    whatPage,
    city,
    favorite,
    isPublic,
    savedFilterXmlId,
    showOnLeft,
    commission,
    clientMode,
    isAgent,
    sideEffectOnClick,
    aeroPanorama,
    images,
    plan,
    squareTotal,
    floor,
    floors,
    baseCost,
    block,
    corpus,
    endingYear
  } = props;
  const settings = {
    items: 1,
    nav: false,
    loop: true,
    controls: false,
  };
  return (
    <div className="section__grid-col card_slider__item shadow">
      <div
        className="card card_slider"
        onClick={(e) => {
          if (whitelistForCardClick.includes(e.target.classList.toString())) {
            if (sideEffectOnClick) {
              sideEffectOnClick(props, e.target.classList.toString());
            }
            window.open(
              isPublic && savedFilterXmlId
                ? `/public/apartments/${savedFilterXmlId}/${city}/complex/${id}`
                : `/secondary/${id}${window.location.search}`
            );
          }
        }}
        style={props.fullWidth ? { width: "100%" } : {}}
      >
        <div className="card_slider__header">
          {!clientMode && !isAgent && !isPublic && commission && (
            <ul className="card_slider__pills">
              <li className="card_slider__pill">
                Комиссия: {commission}
              </li>
            </ul>
          )}
          {!isPublic && (
            <CardHeart
              favorite={favorite}
              type="secondary"
              id={id}
              city={city}
              whatPage={whatPage}
              showOnLeft={showOnLeft}
            />
          )}
        </div>
        <div className="card_slider__body">
          <div className="card_slider__image">
            {images ? <ErrorPlug>
              <TinySlider settings={settings} >
                {images.map((image) => (
                  <img
                    src={getUrl(image.src)}
                    alt={name}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = houseEmpty;
                    }}
                  />
                ))}
              </TinySlider>
            </ErrorPlug> :
              <img
                src={getUrl(plan?.src?.src)}
                alt={name}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = houseEmpty;
                }}
                style={{ maxHeight: 340 }} // какая-то ерунда со стилями при ширине экрана <1600
              />
            }
          </div>
          <div className="card_slider__content">
            <div
              className="card_slider__title h3"
              style={{ display: "flex", alignItems: "center" }}
            >
              {`${roomType.name}, ${squareTotal} м²`}
              {aeroPanorama && (
                <div className="aero-icon">
                  <svg
                    width="28"
                    height="24"
                    viewBox="0 0 28 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="aero-svg"
                  >
                    <path
                      d="M15.6654 20.1593L11.9736 17.6091C11.7284 17.4248 11.4389 17.4248 11.4389 17.8509C11.4389 17.9393 11.4389 18.6126 11.4389 19.4477C5.20173 19.4094 1.05662 17.4067 1.05662 15.5073C1.05662 13.5898 3.80382 12.7376 3.80382 12.7376V12.631C1.58493 13.0572 0 14.442 0 15.5073C0 20.2414 9.96284 21.2321 11.4389 21.3525C11.4389 22.21 11.4389 22.8193 11.4389 22.9642C11.4389 23.3903 11.8257 23.3978 11.9736 23.2486L15.6654 20.6984C15.8133 20.5503 15.8133 20.3085 15.6654 20.1593Z"
                      fill="#8F95AC"
                    />
                    <path
                      d="M17.4336 19.7631C17.4336 19.8589 17.4336 20.6888 17.4336 21.1149C17.4336 21.3141 17.5255 21.3546 17.6925 21.3343C19.3144 21.1298 27.9998 19.8589 27.9998 16.0836C27.9998 13.8497 24.9356 12.8345 23.9846 12.631V12.8345C23.9846 12.8345 26.996 13.7996 26.996 15.9323C26.996 18.0139 21.1592 19.4669 17.6861 19.5351C17.6005 19.5362 17.4336 19.6331 17.4336 19.7631Z"
                      fill="#8F95AC"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.3485 3.90414L13.8712 1.27459L8.39397 3.90414L13.8712 6.53369L19.3485 3.90414ZM5.74146 3.90414L13.8712 0L22.001 3.90414L22.0125 3.90414L22.0125 13.4463L13.8712 17.3504L5.74146 13.4463L5.74694 13.4348H5.74146V3.90414ZM6.88973 4.45557V12.2133C7.13682 12.135 7.40581 12.2508 7.52128 12.4818C7.61089 12.6691 7.57913 12.8796 7.46289 13.0312L13.3201 15.8455V7.54359L6.88973 4.45557ZM20.8642 12.1722V4.45006L14.4684 7.52153V15.8238L20.3605 12.997C20.2538 12.8428 20.2195 12.642 20.3016 12.4703C20.4132 12.2369 20.6325 12.1111 20.8642 12.1722ZM9.17478 12.2176C8.97958 12.2176 8.79585 12.1028 8.70399 11.9191C8.57768 11.655 8.69251 11.345 8.94513 11.2186L9.15182 11.1153C9.41592 10.989 9.73744 11.1038 9.86375 11.3679C9.99006 11.632 9.87523 11.9421 9.62261 12.0684L9.40444 12.1717C9.32406 12.2062 9.25516 12.2176 9.17478 12.2176ZM11.5058 11.1038C11.3106 11.1038 11.1269 10.989 11.035 10.8053C10.8972 10.5412 11.012 10.2311 11.2761 10.1048L11.4828 10.0015C11.7469 9.87517 12.057 9.98999 12.1833 10.2426C12.3096 10.5067 12.1947 10.8168 11.9421 10.9431L11.7354 11.0464C11.6551 11.0809 11.5747 11.1038 11.5058 11.1038ZM18.3954 12.1373C18.4758 12.1832 18.5447 12.1947 18.6251 12.1947C18.8318 12.1947 19.0155 12.0913 19.0959 11.8961C19.2222 11.632 19.1189 11.322 18.8548 11.1957L18.6481 11.0923C18.384 10.966 18.0739 11.0694 17.9476 11.3335C17.8213 11.5976 17.9247 11.9076 18.1888 12.0339L18.3954 12.1373ZM16.0989 11.0349C16.1793 11.0809 16.2482 11.0923 16.3286 11.0923C16.5238 11.0923 16.7075 10.9775 16.7993 10.7938C16.9257 10.5297 16.8108 10.2197 16.5467 10.0933L16.34 9.99C16.0759 9.86369 15.7659 9.97851 15.6396 10.2426C15.5133 10.4952 15.6281 10.8168 15.8922 10.9431L16.0989 11.0349Z"
                      fill="#8F95AC"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="card_slider__floor card__floor">{`${floor} этаж из ${floors}`}</div>
          {block && <div className="card_slider__info">
              <p className="card_slider__address card__address">
                {block.address}
              </p>
              <p className="card_slider__subinfo card__subinfo">
                <span className="card_slider__important card__important">ЖК «{block.name}»</span>{corpus && ", " + corpus}{!!endingYear && ", " + endingYear + " год постр."}
              </p>
            </div>}


            <p className="card_slider__price card__price h3">{formatMoney(baseCost) + " р."}</p>
            <p className="card_slider__price-per-m card__price-per-m">{formatMoney(parseInt(getPricePerMeter(baseCost, squareTotal)))} руб./м²</p>
            {/*
            <div className="card_slider__variants">
               {apartmentsByRoomType &&
                apartmentsByRoomType.map((room, i) => (
                  <div className="card_slider__grid" key={room.roomTypeName + `${i}`}>
                    <div className="card_slider__left">
                      <div className="card_slider__left-inner">
                        <div className="card_slider__left-part">
                          <strong>{room.roomTypeName}</strong>
                          <span> / {room.count}</span>
                        </div>
                        <div className="card_slider__left-part">
                          от {room.minSquare} м2
                        </div>
                      </div>
                    </div>
                    <div className="card_slider__right">
                      от {getRoundedPrice(room.minPrice)} млн. р.
                    </div>
                  </div>
                ))}
            </div> */
            }
            <ul className="card_slider__routes">
              {subway &&
                subway.map((subway, i) => {
                  if (!subway) {
                    return;
                  }
                  return (
                    <li
                      className="card_slider__routes-item"
                      key={subway.name + `${i}`}
                    >
                      <span
                        className="card_slider__metro-color card_slider__metro-color_1"
                        style={{ backgroundColor: subway.color }}
                      ></span>
                      <span className="card_slider__metro-station">
                        м. {subway.name}
                      </span>
                      {subway.distanceType && <span className="card_slider__route-type">
                        <svg
                          className={`icon icon_${subway.distanceType === "byFoot"
                            ? "pedestrian"
                            : "auto"
                            }`}
                        >
                          <use
                            xlinkHref={`#${subway.distanceType === "byFoot"
                              ? "pedestrian"
                              : "auto"
                              }`}
                          ></use>
                        </svg>
                      </span>}
                      {subway.minutes && <span className="card_slider__route-time">
                        {subway.minutes} мин.
                      </span>}
                    </li>
                  );
                })}
            </ul>
            {/* <ul className="card_slider__options">
              {isApartment && (
                <li className="card_slider__option is-apartments">Апартаменты</li>
              )}
              {isAssignment && (
                <li className="card_slider__option is-assignments">
                  Переуступки застр.
                </li>
              )}
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
