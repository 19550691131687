import React, { useEffect, useState } from "react";
import { media } from '../../../utils';

import BgLeft1 from "./BgLeft1.js";
import BgLeft2 from "./BgLeft2.js";

import BgRight1 from "./BgRight1.js";
import BgRight2 from "./BgRight2.js";
import BgRight3 from "./BgRight3.js";
import BgRight4 from "./BgRight4.js";
import BgRight5 from "./BgRight5.js";

const Background = (props) => {
  const {cardTheme, theme} = props;
  const [bgTheme, setbgTheme] = useState(theme?.style || 1);

  let BgRight;
  let bgClassName;
useEffect(() => {
  setbgTheme(theme?.style || 1)
  // setbgTheme(5)
}, [theme])
    switch (bgTheme.toString()) {
      case "1":
        BgRight = BgRight1;
        bgClassName = 'background background_rect';
        break;

        case "2":
        BgRight = BgRight2;
        bgClassName = 'background background_sphere';
        break;

        case "3":
        BgRight = BgRight3;
        bgClassName = 'background background_oval';
        break;

        case "4":
          BgRight = BgRight4;
          bgClassName = 'background background_paint';
          break;

        case "5":
          BgRight = BgRight5;
          bgClassName = 'background background_pyramid';
          break;


        default:
          BgRight = BgRight1;
        }

  return (
    <>
    <section className={bgClassName}>
      {bgTheme == 1 && <BgLeft1 />}
      {bgTheme == 3 && !media('isMobile') && <BgLeft2 />}
      {
        !cardTheme && (
          <BgRight />
        )
      }

    </section>

    {
      // Delete on release

      // <div className="theme-test">
      //   <button type="button" onClick={() => setbgTheme(1)}>Rect</button>
      //   <button type="button" onClick={() => setbgTheme(2)}>Sphere</button>
      //   <button type="button" onClick={() => setbgTheme(3)}>Oval</button>
      //   <button type="button" onClick={() => setbgTheme(4)}>Paint</button>
      //   <button type="button" onClick={() => setbgTheme(5)}>Pyramid</button>
      // </div>
    }

    </>
  )
};

export default Background;
