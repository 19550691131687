import { handleFavorite } from './handlers';
const initState = {
  isFetching: true,
  error: false,
  countView: 10,
  filter: {
    city: { id: 1, title: "" },
    agent: [],
    client: [],
    roomType: [],
  },
  filterData: { city: [] },
  block: [],
  blockEntity: [],
  apartment: [],
  assignment: [],
  commercial: [],
  secondary: [],
  village: [],
  suburban: [],
  parking: []
};
const defaultFilter = {
  city: { id: 1, title: "" },
  agent: [],
  client: [],
  roomType: [],
};
const favorites = (state = initState, action) => {
  switch (action.type) {
    case "FAVORITES_REQUEST":
      return { ...state, isFetching: true };
    case "FAVORITES_SUCCESS":

      return {
        ...state,
        error: false,
        isFetching: false,
        filterData: action.data.filter,
        block: action.data.favorites.block,
        blockEntity: action.data.favorites.blockEntity,
        // apartment: apartments,
        apartment: action.data.favorites.apartment,
        assignment: action.data.favorites.assignment,
        village: action.data.favorites.village || [],
        commercial: action.data.favorites.commercial,
        secondary: action.data.favorites?.secondary,
        suburban: action.data.favorites.suburban,
        parking: action.data.favorites.parking,
      };
    case "FAVORITES_FAILURE":
      return { ...state, isFetching: false, error: true };
    case "REMOVE_FROM_FAVORITES":
      return { ...state };
    case "REMOVE_FROM_FAVORITES_FAILURE":
      return { ...state };
    case "SET_COUNT_VIEW":
      return { ...state, countView: action.countView };
    case "SET_FAVORITES_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "CLEAR_FAVORITES_FILTER":
      let tmpFilter = { ...defaultFilter, city: [action.city] };
      return { ...state, filter: tmpFilter };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
    case 'REMOVE_FROM_FAVORITES_SUCCESS': 
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'favorites') {
        return { ...state, [action.data.type]: handleFavorite(state[action.data.type], action) }
      }
      return state
    default:
      return state;
  }
};

export default favorites;