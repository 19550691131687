import { media } from '../../utils';

export default () => {
	const pdf = document.querySelector('.pdf');
	if (!pdf) return;

	const blocks = [...document.querySelectorAll('.pdf-block')];
	const footer = document.querySelector('.pdf-footer');
	const currBody = document.querySelector('.pdf-body');
	const hideFooter = footer.offsetHeight < 30;
	const heightWithNoFooter = media('isMobile') ? 530 : 1095
	const heightWithFooter = media('isMobile') ? 500 : 1030
	let PDF_HEIGHT = hideFooter ? heightWithNoFooter : heightWithFooter;

	const newBody = document.createElement('div');
	newBody.classList.add('pdf-body');

	/**
	 * Render blocks on lists
	 */
	let currHeight = 0;
	let newList = document.createElement('div');
	newList.classList.add('pdf-list');
	newBody.appendChild(newList);

	blocks.forEach((block) => {
		/**
		 * Check if block is empty
		 * TODO: refactor
		 */
		block.classList.remove('hidden');
		currHeight += block.offsetHeight;
		
		if (currHeight > PDF_HEIGHT) {
			currHeight = block.offsetHeight;
			newList = document.createElement('div');
			newList.classList.add('pdf-list');
			newBody.appendChild(newList);
		}
		newList.appendChild(block);
	});

	/**
	 * Add footer on every list
	 */
	const lists = newBody.querySelectorAll('.pdf-list');

	if (hideFooter) {
		footer.classList.add('hidden');
	} else {
		footer.classList.remove('hidden');
	}

	lists.forEach((list) => {
		list.appendChild(footer.cloneNode(true));
	});

	/**
	 * Render lists
	 */
	currBody.remove();
	pdf.appendChild(newBody);
};
