import React, { useEffect, useState } from 'react';
import { changeResponsible, joinToQueue } from '../../../../actions/constructor';
import useClickPast from '../../../../hooks/useClickPast';
import useMedia from "../../../../hooks/useMedia";
import useUniversalForm from '../../../../hooks/useUniversalForm';
import { parseDate } from '../../../../utils';
import Dropdown from "../../../core/Dropdown";
import Popup from '../../../core/Popup';
import Table from "../../../core/Table";
import { errorToast, infoToast } from '../../../toasts';
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as Key } from "./key.svg";
import { ReactComponent as Queue } from "./queue.svg";

const getDate = (string) => {
    if (!string) return "—"
    const [date] = parseDate(string, true)
    return date
}
const messages = [
    {message: "Заявка на аренду кампании успешно отправлена. Наш менеджер свяжется с Вами"},
    {message: "Заявка на продление кампании успешно отправлена. Наш менеджер свяжется с Вами"},
    {message: "Вы добавлены в очередь на аренду кампании"},
    {message: "Ответственный за получение лидов успешно изменён"},
]
const toasts = {
    info: data => infoToast(
        <div className="Toastify__text">
            {data.message}
        </div>, { autoClose: 7000, }
    ),
    error: error => errorToast(error?.[0]?.message, { autoClose: 7000, })
}

const Adv = (props) => {
    const { landings, user, agents, forcedSetState } = props
    const isMobile = useMedia("isMobile")
    const { send } = useUniversalForm({
        formId: 37,
        defaultValues: {
            user: user.id,
            phone: user.phone,
            email: user.email
        }
    })

    const setResp = (landingId, agentId, advertisingId) => {
        changeResponsible({
            advertisingId: advertisingId,
            userId: agentId
        }).then(
            (data) => {
                toasts.info(messages[3])
                const tmp = [...landings]
                const landingIndex = tmp.findIndex(landing => landing.id === landingId)
                tmp[landingIndex] = {
                    ...tmp[landingIndex],
                    responsible: agents.find(agent => agent.id == agentId)
                }
                forcedSetState({
                    landings: tmp
                })
            },
            (error)=> toasts.error(error)
        )

    }
    const options = agents.map(agent => {
        const tmp = { ...agent }
        if (tmp.id === user.id) {
            tmp.name = "Вы"
            tmp.lastName = " "
        }
        return tmp
    })

    const getMobileColumns = () => [
        {
            title: "Название",
            dataIndex: "domain",
            key: "domain",
            render: (domain, record) => <a
                className="clients__table-link clients__table-client"
                href={`https://${domain}`}
                target="_blank"
            >
                {record.name}
            </a>,
        },
        {
            title: "Лидов",
            dataIndex: "countLeads",
            key: "countLeads",
            render: (count, record) => !!count && record.status?.xmlId !== "in_rent" && record.status?.xmlId !== "free" ? count : "—"
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
                <AdvAction {...record} user={user} send={send} />
            ),
        },
    ]

    const getColumns = () => [
        {
            title: "Название",
            dataIndex: "domain",
            key: "domain",
            render: (domain, record) => <a
                className="clients__table-link clients__table-client"
                href={`https://${domain}`}
                target="_blank"
            >
                {record.name}
            </a>,
        },
        {
            title: "Ответственный",
            dataIndex: "responsible",
            key: "responsible",

            render: (respo, record) => {
                if (record.status?.xmlId === "in_rent" || record.status?.xmlId === "free") return "—"
                return <Dropdown
                    value={options.find(item => item.id == respo?.id)?.id}
                    options={options}
                    placeholder="—"
                    onChange={(value) => {
                        setResp(record.id, value, record.advertisingId);
                    }}
                    wrapperClass=" landings-dropdown"
                />
            },
        },
        {
            title: "Начало кампании",
            dataIndex: "dateStart",
            key: "dateStart",
            render: (date) => getDate(date),
        },
        {
            title: "Конец кампании",
            dataIndex: "dateEnd",
            key: "dateEnd",
            render: (date) => getDate(date),
        },
        {
            title: "Лидов получено",
            dataIndex: "countLeads",
            key: "countLeads",
            render: (count, record) => !!count && record.status?.xmlId !== "in_rent" && record.status?.xmlId !== "free" ? count : "—"
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (status) => <span className={`label-item`} style={{ color: status.color, background: `${status.color}33` }}>
                {status.name}
            </span>,
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
                <AdvAction {...record} user={user} send={send} />
            ),
        },
    ];

    const expandedRow = (record) => {

        return (
            <div className="flats__row-table-expanded">
                <div className="flats__row-table-expanded-column">
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Начало кампании:</span>
                        {getDate(record.dateStart)}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Конец кампании:</span>
                        {getDate(record.dateEnd)}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title is-centered">Ответственный:</span>

                        {record.status?.xmlId === "in_rent"  || record.status?.xmlId === "free"? "—" :
                            <Dropdown
                                value={options.find(item => item.id == record.responsible?.id)?.id}
                                options={options}
                                placeholder="—"
                                onChange={(value) => {
                                    setResp(record.id, value, record.advertisingId);
                                }}
                                wrapperClass="landings-dropdown"
                            />}
                    </div>
                    <div className="lk-presentation__clients">
                        <span className="flats__row-table-expanded-title">Статус:</span>
                        <span className={`label-item`} style={{ color: record.status.color, background: `${record.status.color}33` }}>
                            {record.status.name}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="orders__content for-agents">
            <div className="constructor__container">
                <Table
                    rowKey="id"
                    showSorterTooltip={false}
                    columns={isMobile ? getMobileColumns() : getColumns()}
                    expandable={isMobile ? {
                        expandedRowRender: record => expandedRow(record),
                    } : false}
                    dataSource={landings}
                    pagination={false}
                    locale={{ emptyText: "ничего не найдено" }}
                    customPagination={true}
                />
            </div>
        </div>
    );
}
const AdvAction = (props) => {
    const { send, name, status, id } = props;
    const isMobile = useMedia("isMobile")
    const [open, setOpen] = useState(false);

    const node = useClickPast(() => setOpen(false));


    const sendForm = (params, message,  isJoinToQueue) => {
        if (isJoinToQueue) {
            joinToQueue(params).then(
                (data) => {
                    setOpen(false);
                    toasts.info(message)
                },
                (error) => {
                    setOpen(false);
                    toasts.error(error);
                },
            );
            return
        }
        send(params).then(
            (data) => {
                setOpen(false);
                toasts.info(message)
            },
            (error) => {
                setOpen(false);
                toasts.error(error);
            },
        );
    };

    const [closePopup, setClosePopup] = useState(false);


    useEffect(() => {
        setClosePopup(true)
    }, [closePopup]);

    const texts = [
        {
            subject: `Заявка на аренду рекламной кампании`,
            comment: `Хочу арендовать рекламную кампанию ${name}`
        },
        {
            subject: `Заявка на продление рекламной кампании`,
            comment: `Хочу продлить рекламную кампанию ${name}`
        },
    ]
    const options = [
        ...(status.xmlId === "free" ? [<div className="dt__more-control" onClick={() => sendForm(texts[0], messages[0])}>
            <Key className="icon" />
            <span>Взять в аренду</span>
        </div>] : []),

        ...(status.xmlId === "end" ? [<div className="dt__more-control" onClick={() => sendForm(texts[1], messages[1])}>
            <Clock className="icon" />
            <span>Продлить аренду</span>
        </div>] : []),

        ...(status.xmlId === "in_rent" ? [<div className="dt__more-control" onClick={() => sendForm({ landingId: id, },messages[2], true)}>
            <Queue className="icon" />
            <span>Встать в очередь</span>
        </div>] : []),

    ]
    const items = <div className="dt__more">
        <hr />
        {options}
    </div>
    if(options.length === 0) return ""
    return (
        <div className="control_more">
            <div className="form__item">
                <div
                    ref={node}
                    className={
                        "dropdown js-dropdown form__drop" + (open ? " is-showing" : "")
                    }
                >
                    {isMobile ?
                        <Popup
                            trigger={
                                <button
                                    type="link"
                                    className="dropdown__btn button form__drop-btn button_type_round"
                                    onClick={(e) => setOpen(!open)}
                                >
                                    <span className="form__drop-name"></span>
                                    <svg className="icon icon_elipsis">
                                        <use xlinkHref="#elipsis"></use>
                                    </svg>
                                </button>
                            }
                            closePopup={closePopup}
                        >
                            {items}
                        </Popup> :

                        <>
                            <button
                                type="link"
                                className="dropdown__btn button form__drop-btn button_type_round"
                                onClick={(e) => setOpen(!open)}
                            >
                                <span className="form__drop-name"></span>
                                <svg className="icon icon_elipsis">
                                    <use xlinkHref="#elipsis"></use>
                                </svg>
                            </button>
                            <div className="dropdown__block">
                                <div className="dropdown__inner">
                                    <div className="form__drop-content">
                                        {items}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Adv
