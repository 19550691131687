import React, { useState } from "react";
import "react-photoswipe/lib/photoswipe.css";
import ErrorPlug from "../components/ErrorBoundry/ErrorPlug";
import LoadMoreSpinner from "../components/LoadMoreSpinner";
import Preloader from "../components/Preloader";
import Promo from "../components/Special/Promo.js";
import '../components/Special/index.scss';

import DraggableZone from "../components/FilterRealty/Draggable";
import { FilterSearch } from "../components/FilterRealty/FilterSearch";
import { CardWithFavorite } from '../components/Special/Card';
import Dropdown from "../components/core/Dropdown/index.js";
import EmptyTable from "../components/core/EmptyTable";
import { sortListProjects } from "../hooks/useFilter/filterSets.js";
import useFilter from "../hooks/useFilter/index.js";
import usePermission from "../hooks/usePermission.js";

import useMedia from "../hooks/useMedia.js";
import ProjectsTopFilter from "../components/Public/markup/ProjectsTopFilter.jsx";
import ProjectsBottomFilter from "../components/Public/markup/ProjectsBottomFilter.jsx";
import { getSuffix } from "../utils/index.js";
import SubmitButton from "../components/SubmitButton.js";

import { ReactComponent as SavedFilterIcon } from "../assets/images/icons/SaveFilter.svg";
import { useModal } from 'react-modal-hook';
import Wrap from "../components/core/ModalWrap/index.js";
import MobileModal from "../components/core/MobileModal/index.js";
import Modal from "../components/core/Modal/index.js";
import SendFilter from "../components/SavedFilters/SendFilter.js";
import FilterToggleBtn from "../components/Public/markup/FilterToggleBtn.jsx";
import MapBtn from "../components/Public/markup/MapBtn.jsx";

const Special = () => {

  const showComission = usePermission("motivation")

  const [showFilter, toggleFilter] = useState(false);

  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');

  const {
    data: { filter: filterData, contractConstructions },
    handleFilter,
    submit,
    isLoading,
    infiniteRef,
    filter = {},
    exclude = {},
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    loadSubItems,
    isCountLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,
    count,
    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    limit,
    moreIsLoading,
    createBadges,
    handleSort,
    sortId,
    favoritesProps,
    get
  } = useFilter({
    apiConfig: {
      component: "panpartner:suburban.special.offers.ajax",
      getCountFunction: 'getCount',
      submitFunction: 'getContractConstructions',
    },
    defaultFilter: {
      status: [1],
    },
    defaultParams: {
      city: "spb",
    },
    shadowFilters: ['status'],
    fields: {
      buildings: [],
      blocks: [],
      status: [],
      decoration: [],
      rooms: [],
      endings: {},
      prices: {},
      isOnlyHanded: '',
    },
    sortEnabled: true,
    paginationEnabled: true,
    loadMoreEnabled: true,
    isGetFilter: 1,
    loadMoreDataKey: 'contractConstructions',
    processData: (resp, prev) => ({ ...prev, ...resp }),
    defaultView: "list",
    defaultPage: 1,
    defaultLimit: 3,
    defaultSortId: 0,
    defaultOrder: "sort",
    defaultBy: "asc",
    sortList: sortListProjects,
    badgeFormattersParams: {

    },
    specialBadgeTitles: {
      floors: "Этажей: "
    },
    refreshWhenFilterChange: false,
    favoritesParams: {
      type: 'project',
    },
  });

  const [openSF, closeSF] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeSF} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={closeSF} classes="modal_filter">{children}</Modal>
      } >
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="project"
          hide={closeSF}
          city={"spb"}
          filterBadges={filterBadges}
          excludeBadges={excludeBadges}
        />
      </Wrap >
    ),
    [filter, exclude, filterData, filterBadges, excludeBadges],
  );

  return (
    <section className="special">
      <Promo />
      <section className="special__cards">
        <div className="wrapper">

          <div className="special__filter-section section">
            <h3 className="h3 special__h3 special__filter-title">Поиск дома по параметрам</h3>

            {
              !isLoading &&
              <>
                <div className={`sf__filter filter__max form smartfilter-wrapper smartfilter-wrapper_primary`}>
                  {<FilterSearch
                    filterData={filterData}
                    config={{
                      fields: {
                        projectTitle: {
                          key: "id",
                          converter: item=>({...item, title: item.id}),
                          excludable: false,
                        },
                        material: {
                          excludable: false,
                        }
                      }
                    }}

                    placeholder="Название проекта, материал"
                    toggleFilter={() => toggleFilter(prev => !prev)}
                    filterValues={filter}
                    excludeValues={exclude}
                    handleFilter={setFilterAndExclude}
                    hideInfoButton
                    submit={submit}
                  />}

                  <ProjectsTopFilter
                    filterData={filterData}
                    handleFilter={handleFilter}
                    filter={filter}
                    setFilterAndExclude={setFilterAndExclude}
                    disableButton={!count}
                    onSumbit={submit}
                    isLoading={isCountLoading || isLoading}
                    wrapperClass={' '}
                  />
                  <button
                    className="button button_type_reveal-filter white-button"
                    type="button"
                    onClick={() => toggleFilter(!showFilter)}
                  >
                    <span >Все фильтры</span>
                  </button>
                </div>


                {(isLaptop || (!isTablet && showFilter)) && <div style={{ padding: "0 16px" }}>
                  <div className="sf__filter filter__max form smartfilter-wrapper_primary smartfilter-wrapper_primary-bottom">
                    {!isTablet && showFilter &&
                      <ProjectsBottomFilter
                        filter={filter}
                        filterData={filterData}
                        handleFilter={handleFilter}
                        setFilterAndExclude={setFilterAndExclude}
                        clearExcludeOnly={clearExclude}
                        clearFilterOnly={clearFilter}
                        wrapperClass={"sf__filter-item filter_item_temp"}
                      />

                    }
                  </div>
                </div>
                }

                <DraggableZone
                  filterBadges={filterBadges}
                  excludeBadges={excludeBadges}
                  clearFilter={clearFilter}
                  clearExclude={clearExclude}
                  isExcludeEmpty={isExcludeEmpty}
                  isFilterEmpty={isFilterEmpty}
                  removeBadge={removeBadge}
                  switchValues={switchFilterToExclude}
                />

                <div className="sf__row">
                  {!isTablet && <div className="villages-save-filter" onClick={openSF}>
                    <SavedFilterIcon />
                    <span>Сохранить фильтр</span>
                  </div>}
                  <div />
                  <div className="sf__filter-btns">
                    {/* {isTablet && <FilterToggleBtn />}
                    <MapBtn
                      setShowMap={() => setView('map')}

                    /> */}
                    {!isTablet && (
                      <span style={isLaptop ? { marginLeft: 20 } : {}}>
                        <SubmitButton
                          className="sf__filter-btn btn btn_primary h6"
                          onClick={submit}
                          isLoading={isLoading}
                          type="button"
                          disabled={!count}
                        >
                          {`${count ?? 0} объект${getSuffix(count ?? 0)}`}
                        </SubmitButton>
                      </span>
                    )}
                  </div>
                </div>

              </>
            }

            <div className="special-filter-drop">
              <Dropdown
                innerClass={``}
                value={sortId}
                options={sortListProjects}
                onChange={handleSort}
              />
            </div>

            {contractConstructions?.length > 0 ? <ErrorPlug stopSending={true}>
              <div className="special__cards-list" ref={infiniteRef}>
                {contractConstructions.map((item, i) => <CardWithFavorite key={item.id} {...item} {...favoritesProps} showComission={showComission} index={i} get={get} />)}
              </div>
            </ErrorPlug> :
              <EmptyTable title="Ничего не найдено" />
            }
            {moreIsLoading && <div className="table__show-more"> <LoadMoreSpinner loading={true} /></div>}
          </div>
        </div>
      </section>
      <Preloader loading={isLoading} />
    </section>
  );
};


export default Special;