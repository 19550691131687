const host = window.location.host

export const haveSubdomain = /[a-z0-9\-]+\.[a-z0-9\-]+\.[a-z]+/.test(host)

const excludedUrls = ["panpartner.ru", "pro-rieltor.ru", "toprealtors.ru", "localhost:3000"]

export const getSubdomain = (returnHost = true) => {
    const search = new URLSearchParams(window.location.search)
    const subdomain = search.get("sub") ? search.get("sub") : false
    const isReturnHost = !excludedUrls.some(url => host.includes(url))
    
    if (isReturnHost) return host

    if (subdomain) return subdomain

    return haveSubdomain ? host.match(/[^\.]+[^\.]/)[0] : returnHost ? host : false
}
const blackList = ["dev2", "new", "evgeniy", "robert", "aibeck", "artur"]
export const checkSubdomain = (ignore) => {
    const subdomain = getSubdomain(false)
    if (!subdomain || ignore) return false
    if (subdomain.length > 3 && !blackList.includes(subdomain)) {
        return true
    }
    return false
}

export const isValidSubdomain = (subdomain) => {
    const sb = subdomain || getSubdomain(false)
    if (!sb) return false
    return sb.length > 3 && sb.length < 31 && !/[^0-9a-z\-]+|--|^-|-$/i.test(sb)
}

export const getFormWithUTM = onlyUtm => {
    const params = new URLSearchParams(window.location.search);
    const utm = {
        utm_campaign: params.get('utm_campaign'),
        utm_content: params.get('utm_content'),
        utm_medium: params.get('utm_medium'),
        utm_source: params.get('utm_source'),
        utm_term: params.get('utm_term'),
    };
    if(onlyUtm) return utm
    const form = new FormData();
    Object.keys(utm).forEach((key) => {
        if (utm[key]) {
            form.append(`utm[${key}]`, utm[key]);
        }
    });
    return form
}

export const getUrlWithProtocol = (url) => {
    if (!url) return ""
    return url.includes("https://") || url.includes("http://") ? url : `https://${url}`
}

export const applyToFormData = (formData, object) => {
    if (!formData || !object) return formData
    for (const [key, value] of Object.entries(object)) {
      formData.append(key, value)
    }
    return formData
  }

export const getURLparam = (key, asObject, search = window.location.search) => {
    const params = new URLSearchParams(search)
    const value = params.get(key)
    if (asObject) return value ? { [key]: value } : {}
    return value
}