import {memo, useEffect, useRef, useState} from 'react';
import CurrencysSelect from "../../../CurrencysSelect";
import {useSelector} from "react-redux";

const InfoCard = memo(
  ({
    items = [
      // { title: 'title', value: 'value' },
      // { title: 'title', value: 'value' },
    ],
    cols,
    dateAdd,
    topDate,
    choiceCurrency,
    priceFormat,
    activeCurrency,
     withHint
  }) => {

    const hintRef = useRef(null);
    const [height, setHeight] = useState(null);
    const [hint, showHint] = useState(false);

    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    useEffect(() => {

      if (hintRef.current) {
        const { height } = hintRef.current.getBoundingClientRect();
        setHeight(height);
      }

    }, [windowHeight, documentHeight, scrollTop]);
    const handleMouseEnter = () => {
      showHint(true);
    };

    const handleMouseLeave = () => {
      showHint(false);
    };

    const clientMode = useSelector(state => state.general.clientMode);
    clientMode && console.log(clientMode)

    return (
    <section className="sf__info sf__block">
      {withHint && <div className={`sf__block-hint ${hint && 'sf__block-hint_active'}`} ref={hintRef} style={height && scrollTop < 60 ? {top: -height + 70} : {top: 90}}>
        <p className="h5-l" style={{marginBottom: 20}}>
          Динамика цены
        </p>

        <div className="sf__info-body">
          {withHint.map(({ title, value, priceInfo, flag }) => (
            <div className="sf__info-row" key={title}>
              <div className="sf__info-key">{title}</div>
              <div className="sf__info-value" style={flag && flag === 'up' ? {color: '#2CBA2E'} : flag && flag === 'down' && {color: '#ff2d23'} }>
                {choiceCurrency && priceInfo ? priceInfo[activeCurrency] : value}
              </div>
            </div>
          ))}
        </div>

      </div>}
      {topDate && <div className="sf__italic-text">{topDate}</div>}
      {cols && (
        <header className={`sf__info-header ${withHint ? ' sf__info-header_with-hint' : ''}`}>
          {cols.map(({ title, value, hideForClient }) => (
            <div className="sf__header-col" key={title} style={hideForClient && clientMode ? {display: 'none'} : {}}>
              <p className="h3-l sf__price">{value} {!!withHint?.[2]?.flag && <svg onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={withHint[2]?.flag === 'down' ? {marginBottom: 3, cursor: 'pointer', transform: 'rotate(180deg)'} : {marginBottom: 3, cursor: 'pointer'}} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.92578 0.0742188L11.0577 8.96289H0.793904L5.92578 0.0742188Z" fill={withHint[2]?.flag === 'up' ? '#2CBA2E' : '#ff2d23'}/></svg>}</p>
              <p className="sf__descr text">{title}</p>
            </div>
          ))}
        </header>
      )}

      {choiceCurrency && (
        <header className="sf__info-header">
          <div className="sf__header-col">
              <p className="h3-l sf__price" style={{ whiteSpace: 'nowrap' }}>{choiceCurrency.price?.prices?.[activeCurrency]?.value}</p>
              <p className="sf__descr text">
                {/*<CurrencysSelect fromInfocard prices={choiceCurrency.price} activeCurrency={activeCurrency} priceFormat={priceFormat} />*/}
                Цена
              </p>
          </div>
          <div className="sf__header-col">
            <p className="h3-l sf__price">{choiceCurrency?.count?.value}</p>
            <p className="sf__descr text">{choiceCurrency?.count?.title}</p>
          </div>
        </header>
      )}

      <div className="sf__info-body">
        {dateAdd && <div className="sf__italic-text">{dateAdd}</div>}
        {items.map(({ title, value, priceInfo, description }) => (
          <div className="sf__info-row" key={title}>
            <div className="sf__info-key">{title}</div>
            <div className="sf__info-value">
              {description &&
              <span className="sf__info-description">
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="8.5" stroke="#FF2D23"/><line x1="12.0435" y1="7.5" x2="12.0435" y2="13.5" stroke="#FF2D23" strokeLinecap="round"/><circle cx="12.0435" cy="15.5" r="0.5" fill="#FF2D23"/></svg>
                <span>{description}</span>
              </span>}
              {choiceCurrency && priceInfo ? priceInfo[activeCurrency] : value}
            </div>
          </div>
        ))}
      </div>
    </section>
  )},
);
export default InfoCard;
