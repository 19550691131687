import React, { useEffect, useState } from "react";
import { media } from '../../utils';

const AttentionBlock = (props) => {
  const {isForIpad, openBuyModal} = props;
  return (
    <div className="wrapper">
      <div className={`assignations-attention${isForIpad ? ` assignations-attention_static` : ``}`}>
        <div className="assignations-attention__btns" style={{top: `50px`}}>
          <div
            className="assignations-attention__btn js-modal-btn"
            data-id="modal-assignation-sell"
          >
            <button className="button" onClick={openBuyModal}>
              <span className={`assignations-attention__icon ${media('isMobile') ? 'no-animation' : ''}`}>
                <svg className="icon icon_arrow-right">
                  <use xlinkHref="#arrow-right-clean"></use>
                </svg>
              </span>
              Добавить объект в базу
            </button>
          </div>
          {

          <div className="assignations-attention__btn">
            <a
              className="link-hover label_with-icon_prefix color-brand-3"
              href="https://view.officeapps.live.com/op/view.aspx?src=https://panpartner.ru/upload/Regulations_secondary.docx"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <div className="label__icon">
                <svg className="icon icon_document">
                  <use xlinkHref="#document"></use>
                </svg>
              </div>
              Регламент
            </a>
          </div>
          }
        </div>
      </div>
    </div>
  )
};

export default AttentionBlock;
