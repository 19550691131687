
const FilterSkeleton = ({ isLaptop }) => <div

  className="crm-filters-header skeleton-box"
  style={{ backgroundColor: "transparent", height: "fit-content", userSelect: "none" }}
>
  <div className="crm-filters-header__first-row">
    <div className="crm-filters-header__left-cell" style={{height: 62}}>
      <div className="crm-filters-header__select crm-filters-header__search">
        <div className="custom-select skeletoned">
          <div style={{ height: "100%", backgroundColor: "#f6f7f8" }}>
          </div>
        </div>
      </div>
      <div className="crm-filters-header__select">
        <div className="custom-select skeletoned">
          <div className="custom-select__selected">
          111111
          </div>
        </div>
      </div>
      <div className="crm-filters-header__select">
        <div className="custom-select skeletoned">
          <div className="custom-select__selected">
          111111
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="crm-filters-header__second-row" >
  <div className="checkbox checkbox_view_red">
        <div className="checkbox__box">
          <div
            className="checkbox__control" type="checkbox" id="crm-checkbox-selfClient"
            name="notify" /><label className="checkbox__div" for="crm-checkbox-selfClient"></label>
          <div className="checkbox__marker"></div>

        </div>

      </div>
      <div style={{ position: "relative" }}><span className="loading" data-loading-text="Дополнительные параметры" style={{ left: 74, whiteSpace: "nowrap" }}></span></div>
    </div>

</div>

export default FilterSkeleton;