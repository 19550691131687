import request from '../api';
import { actionFactory } from './actionUtils';

const requestAutopan = actionFactory('AUTOPAN_REQUEST');

const successAutopan = actionFactory('AUTOPAN_SUCCESS');

const successClients = actionFactory('AUTOPAN_CLIENTS_SUCCESS');

export const fetchAutopan = (city = 'spb') =>
  dispatch => {
    dispatch(requestAutopan());
    return request('panpartner:autopan.ajax', 'getAutoPan', { city })
      .then(
        data => {
          dispatch(
            successAutopan({
              data: handleData(data),
            }),
          );
          return Promise.resolve(data);
        },
      );
  };

export const addRequest = form => request('panpartner:autopan.ajax', 'addRequest', form)

export const getClients = (name, city) =>
  dispatch => {
    dispatch(requestAutopan());
    return request('panpartner:saved.filter.ajax', 'getClients', { name, city })
      .then(
        data => {
          dispatch(successClients({ clients: handleClients(data) }));
          return Promise.resolve(data);
        },
      );
  };

const handleClients = data => data.clients.map(client => ({
  id: client.id,
  title: `${client.name} ${client.lastName}`,
}));

const handleData = (data) => ({
  ...data,
  districts: data.districts.map(district => ({
    id: district.id,
    title: district.name,
  }))
});

export const getAutoPan = (city = 'spb') => request('panpartner:autopan.ajax', "getAutoPan", {city})

export const getReviews = (city = 'spb') => request('panpartner:autopan.ajax', "getReviews", {city})

export const getTimes = (city = 'spb') => request('panpartner:autopan.ajax', "getTimes", {city})