import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFormWithUTM } from '../../utils/location';
import SuccessBlock from '../../components/HomePage/SuccessBlock';
import SubmitButton from '../../components/SubmitButton';
import { addLeadActionPublic } from '../../actions/clients';
import { errorToast, standartErrorToast } from '../../components/toasts';

const TrialRequest = (props) => {
  const { handleSubmit, register, errors, control, reset, watch } = useForm();
  const { smsSuccess, userId, history, successLogin } = props;
  const [phone, setPhone] = useState('');
  const [fetching, setFetching] = useState(false);

  const [errorsForm, setErrorsForm] = useState({
    phone: false,
  })

  const [successBlock, setSuccessBlock] = useState(false);

  
  const dispatch = useDispatch()
  
  const onSubmit = (data) => {
    
    const form = getFormWithUTM();
    setErrorsForm({
      phone: !phone,
    })
    if (!phone) return errorToast(<div>Укажите телефон</div>);

    form.append("phones[]", phone);
    form.append("title", "Форма помощи при входе");
    // form.append("comment", agency);

    addLeadActionPublic(form).then(
      resp => setSuccessBlock(true),
      standartErrorToast
    )
  }

  return (
    <div className="hp__page">
      <section className="hp__become-p hp__promo">
        <div className="hp__page-form">
          <h1 className="hp__h1 h1 text-center">Помощь</h1>

          {

          
            !successBlock &&
            <>
              <p className="hp__sign hp__sign_sm color-brand-3 big-text text-center">
                Мы перезвоним Вам на этот номер
              </p>

              <div className="hp__form-content">
                <form className="form" name="sign-trial" onSubmit={handleSubmit(onSubmit)}>

                  <div className="hp__form-item">
                    <label htmlFor="help_phone" className="hp__form-label text">Номер телефона</label>
                    <div
                      className="input input_width_available input_type_form"
                      htmlFor="help_phone"
                    >
                      <InputMask
                        onChange={e => setPhone(e.target.value)}
                        className={
                          'input__control'
                        }
                        name="phone"
                        placeholder="Введите телефон"
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                        value={phone}
                      />
                      {errors.phone && !phone && (
                        <span className="input__error-label">
                          Введите телефон
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="hp__form-item">
                    <SubmitButton
                      className="hp__submit hp__submit_mb-10 button button_view_default"
                      isLoading={fetching}
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <span>Получить помощь</span>
                    </SubmitButton>

                    <Link to={`/login`}  className="hp__form-link link link-red text-center">Назад к странице входа</Link>
                  </div>
                </form>
              </div>
            </>
          }

          {
            successBlock && <SuccessBlock />
          }
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // smsSuccess: (data) => dispatch(successTestAccessSms(data)),
  // successLogin: (data) => dispatch(successLogin(data)),
});

const mapStateToProps = (state) => ({
  userId: state.user.userId,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialRequest);
