import React, { useMemo } from 'react';

const Title = ({ data = {}, activeSettings, }) => {
  const { object, typeXmlId } = data;

  const isSuburban = typeXmlId === "suburban"
  const isSecondary = typeXmlId === "secondary"
  const title = useMemo(() => {
    const title = object?.type?.title || object?.roomTypeName
    if (!activeSettings?.complex_name && isSuburban) {
      return title
    }
    if(activeSettings?.complex_name && isSuburban){
      return `${title} в «${object.blockName}»`
    }
    if(activeSettings?.complex_name && isSecondary){
      return `${title} в ЖК «${object.blockName}»`
    }
    if (activeSettings?.complex_name) {
      return `${title} в ЖК «${object.blockName}»`
    }
    return title
  }, [isSuburban,isSecondary,  activeSettings])

  if (!data || !object) return null;
  return (
    <div className="presentation__info pdf-block">
      <div className="presentation__info-head">
        <div className="presentation__info-main">
          <div className="presentation__info-name" >
            {title}
          </div>
        </div>
        <div className="presentation__info-id">
          ID: {object.id}
        </div>
      </div>
    </div>
  );
};

export default Title;