import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import FloorsFilter from '../../FilterRealty/FloorsFilter';

const CommercialBottomFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    wrapperClass
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>
    <SelectFilter
      {...createSelectProps("blocks")}
      data={{
        title: "Название ЖК",
        autocompleteTitle: "Введите название ЖК",
        identity: "blocks",
      }}
      wrapperClass={wrapperClass}
    />
    <FloorsFilter
      autoPosition
      items={filterData.floors?.items ?? []}
      filter={filter.floor ?? {}}
      data={{
        title: 'Этаж',
        minPlaceholder: 'от ',
        maxPlaceholder: 'до ',
        defaultValues: filterData.floors.range ?? null,
      }}
      onChange={({ filter }) => handleFilter('floor', filter)}
      secondData={{
        title: filterData.isNoFirstFloor?.[0]?.title ?? 'Не первый',
        value: filter.isNoFirstFloor ?? 'N',
      }}
      secondChange={v => handleFilter('isNoFirstFloor', v === 'Y' ? v : "")}
      wrapperClass={wrapperClass}
    />

    <SelectFilter
      {...createSelectProps("decoration")}
      autoPosition
      data={{
        title: "Отделка",
        identity: "decoration",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("payment")}
      data={{
        title: "Способ оплаты",
        autocompleteTitle: "Например, сельская ипотека",
        identity: "payment",
      }}
      wrapperClass={wrapperClass}
    />
    <div className="form__row">
      <div className="index-search__show-booked" style={{ marginTop: 0 }}>
        <label className="switch" htmlFor="filter-booked">
          <div className="switch__box">
            <input
              className="switch__control"
              type="checkbox"
              id="filter-booked"
              checked={filter.status.includes("3")}
              onChange={e => handleFilter("status", "3")}
            />
            <div
              className="switch__input"
              htmlFor="filter-booked"
            ></div>
            <div className="switch__marker"></div>
          </div>
          <span className="switch__label">
            Забронированные
          </span>
        </label>
      </div>
    </div>
  </>
});

export default CommercialBottomFilters;