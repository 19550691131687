import React, {useEffect, useState} from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {CalculatorFixationsLockIcon} from "../../components/Public/markup/icons";
import Button from "../../components/core/Button";
import Modal from "../../components/core/Modal";
import StatelessTabs from "../../components/core/Tabs/StatelessTabs";
import FixationForm from "./FixationForm";
import './index.scss';

const tabs = [
  { title: 'В Санкт-Петербурге' },
  { title: 'В Москве' },
];

const Fixations = ({ history }) => {
  const cityParam = new URLSearchParams(window.location.search).get('city')
  const userInfo = useSelector(state => state.user.info)
  const [activeTab, setActiveTab] = useState(0)
  const [modalData, setModalData] = useState({})
  const openModalWithData = (data) => {
    setModalData(data)
    openModal()
  }
  const closeModal = () => {
    setModalData({})
    hideModal()
  }
  useEffect(() => {
    if (cityParam === 'msk') {
      setActiveTab(1)
    }
  }, [cityParam])
  const [openModal, hideModal] = useModal(({ }) => (
    <Modal withoutBG classes="crm-table__sf-controlsmodal crm-table__sf-controlsmodal_fixation" closeByPastClick close={hideModal}>
      <div className="crm-table__sf-controlsmodaltitle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#2C2E3E" />
          <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#2C2E3E" strokeLinecap="round" />
          <circle cx="12.043" cy="15.5" r="0.5" fill="#2C2E3E" />
        </svg>
        {modalData.title}
      </div>
      <div className="crm-table__sf-controlsmodaltext"
        dangerouslySetInnerHTML={{
          __html: modalData.text,
        }}
      >
        {/* Срок фиксации: 10 дней<br />Возможность продления на 10 дней<br /><br />Необходимые данные: ФИО, телефон клиента */}
      </div>
      <div className="crm-table__sf-controlsmodalbtns">
        <Button buttonType="line" onClick={closeModal}>Понятно</Button>
      </div>
    </Modal>
  ), [modalData]);

  return (
    <div className="wrapper fixations">
      <div className="common-header">
        <div className="common-header__title common-header__title_withlink">
          <h3 className="common-title">
            Фиксация клиента
          </h3>
          <Link to={'/crm'} target="_blank" className="common-header__fullinfo-link" style={{ display: 'flex', alignItems: 'center' }}>
            <CalculatorFixationsLockIcon/>
            <span style={{ marginLeft: 7 }}>Все фиксации</span>
          </Link>
        </div>
      </div>
      <div className="fixations__over">
        <StatelessTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isInline
        />
        <FixationForm
          userInfo={userInfo}
          openBuilderModal={openModalWithData}
          formId={35}
          showContent={activeTab === 0}
        />
        <FixationForm
          userInfo={userInfo}
          openBuilderModal={openModal}
          formId={36}
          showContent={activeTab === 1}
          city="msk"
          loadCondition={activeTab === 1}
        />
      </div>
    </div>
  )
}

export default Fixations;
