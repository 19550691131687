import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AppPopover from "../core/Popover";
import './shareblock.scss';

const ShareBlock = (props) => {
  const {description = '', noOk = false, noText} = props;
  return (
    <div className="share-block">
      {
        !noText && <span className="text color-brand-3">Поделиться</span>
      }
      
      <AppPopover trigger="click" content="Ссылка скопирована" overlayClassName={'share-popover'}>
        <CopyToClipboard
            text={window.location.href}
          >
            <div className={`share-block__copy${noText ? ` share-block__copy_m_0` : ``}`}>
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.9541 8.69911C6.21341 9.04518 6.53828 9.33673 6.91016 9.55712C7.28204 9.77752 7.69366 9.92244 8.12149 9.98362C8.54933 10.0448 8.98502 10.021 9.40368 9.91367C9.82234 9.80631 10.2158 9.61747 10.5615 9.35792C10.6876 9.26374 10.8064 9.16024 10.917 9.04828L12.8916 7.07187C13.491 6.4506 13.8228 5.61853 13.8153 4.75484C13.8078 3.89116 13.4617 3.06498 12.8515 2.45424C12.2413 1.8435 11.4159 1.49707 10.553 1.48957C9.69008 1.48206 8.85876 1.81408 8.23807 2.41411L7.10596 3.54726" stroke="#8F95AC" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.58688 7.38151C8.32757 7.03545 8.0027 6.7439 7.63081 6.5235C7.25893 6.30311 6.84732 6.15819 6.41948 6.09701C5.99164 6.03583 5.55596 6.0596 5.1373 6.16696C4.71863 6.27432 4.3252 6.46316 3.97945 6.72271C3.85341 6.81688 3.7346 6.92039 3.62402 7.03235L1.64941 9.00876C1.04993 9.63002 0.718212 10.4621 0.72571 11.3258C0.733209 12.1895 1.07932 13.0157 1.6895 13.6264C2.29968 14.2371 3.12511 14.5836 3.98801 14.5911C4.8509 14.5986 5.68222 14.2666 6.30291 13.6665L7.42844 12.54" stroke="#8F95AC" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <span>Скопировать ссылку</span>
            </div>
          </CopyToClipboard>

      </AppPopover>

    <div className="share">
      <ul className="share__list">

        {/* <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg width="9" height="20" viewBox="0 0 9 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5748 6.74099H5.79953V4.76753C5.79953 4.02639 6.25294 3.85361 6.57167 3.85361H8.53015V0.59546L5.83293 0.584045C2.83876 0.584045 2.15738 3.01408 2.15738 4.56916V6.74099H0.425781V10.0983H2.15738V19.5984H5.79953V10.0983H8.25716L8.5748 6.74099Z" fill="#4661CC"/>
          </svg>

          </a>
        </li> */}

        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://vk.com/share.php?url=${window.location.href}&title=${description}`}
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2863 11.983C10.9024 11.983 10.9024 11.119 10.9024 10.7488C10.9024 10.1317 11.5186 9.51456 12.1347 9.51456C12.7509 9.51456 13.7983 10.5636 14.5993 11.3659C15.2154 11.983 15.2154 11.983 15.8315 11.983H17.6791C17.6791 11.983 18.9113 11.9213 18.9113 10.7488C18.9113 10.3785 18.4809 9.69969 17.0638 8.28036C15.8315 7.04615 15.2154 7.66325 17.0638 5.19484C18.1729 3.65298 19.0355 2.29446 18.9113 1.9242C18.7881 1.55483 15.6467 0.936832 15.2154 1.49312C13.9831 3.34353 13.7367 3.77551 13.367 4.57774C12.7509 5.81284 12.6883 6.42905 12.1347 6.42905C11.5802 6.42905 11.5186 5.25655 11.5186 4.57774C11.5186 2.5413 11.8266 1.12196 10.9024 0.875122H9.05402C8.0682 0.875122 7.20561 1.49312 7.20561 1.49312C7.20561 1.49312 6.46714 2.10933 6.58947 2.10933C6.77431 2.10933 7.82175 1.86338 7.82175 2.72643V3.96064C7.82175 3.96064 7.82175 6.42905 7.20561 6.42905C6.58947 6.42905 5.35719 3.96064 4.12581 2.10933C3.69362 1.3697 3.50878 1.49312 2.89353 1.49312H1.04423C0.428981 1.49312 0.367367 1.9242 0.42898 2.10933C1.66126 5.19484 2.52385 7.10786 4.86518 9.57627C7.02077 11.7978 8.43788 11.9213 9.67105 11.983H10.2863Z" fill="#21368C"/>
          </svg>

          </a>
        </li>

        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://t.me/share/url?url=${window.location.href}&text=${description}`}
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6982 12.212C6.71122 12.2081 6.72555 12.2042 6.73858 12.2016C6.7464 12.1677 6.75682 12.1325 6.75943 12.1C6.83629 11.2701 6.91836 10.4429 6.9848 9.61174C6.99913 9.42676 7.06296 9.29518 7.20235 9.17142C8.30446 8.18395 9.40136 7.19127 10.4996 6.2025C11.7163 5.1056 12.9318 4.00871 14.1433 2.9079C14.2189 2.84146 14.2501 2.72552 14.3009 2.63172C14.1876 2.61869 14.0651 2.5731 13.9609 2.59915C13.8202 2.63563 13.686 2.719 13.5597 2.79717C10.8774 4.48811 8.19503 6.18036 5.51011 7.86739C5.35769 7.96249 5.32903 8.04456 5.38765 8.21131C5.58567 8.7793 5.76544 9.3538 5.95173 9.9257L6.6982 12.212ZM9.38455 11.1007C8.7384 11.7312 8.09745 12.3487 7.46302 12.974C7.24156 13.1929 6.99144 13.314 6.67227 13.2958C6.45602 13.284 6.33486 13.1889 6.26712 12.9831C5.7812 11.4746 5.28747 9.9686 4.80415 8.45743C4.75726 8.31152 4.68821 8.23987 4.5397 8.19428C3.3933 7.84384 2.2508 7.4869 1.10961 7.12474C0.933745 7.06872 0.756574 6.99447 0.605457 6.89155C0.370966 6.73262 0.335793 6.47468 0.546835 6.28708C0.740941 6.11512 0.972827 5.96531 1.21383 5.87021C2.61427 5.31525 4.02121 4.77852 5.42686 4.23659C8.99373 2.8596 12.5606 1.48392 16.1262 0.106935C16.8049 -0.154914 17.326 0.213759 17.2556 0.947195C17.21 1.41618 17.0889 1.87734 16.9912 2.34112C16.2174 5.99657 15.4409 9.65464 14.6632 13.3114C14.4782 14.179 13.8685 14.4057 13.1546 13.8781C11.9574 12.9974 10.7615 12.1116 9.56563 11.2283C9.50831 11.188 9.44969 11.1476 9.38455 11.1007Z" fill="#2391E1"/>
          </svg>

          </a>
        </li>

        {
          !noOk && 
          <li className="share__item">
            <a
              className="share__anchor button button_view_round-control"
              href={`https://connect.ok.ru/offer?url=${window.location.href}","Поделиться в Одноклассниках","width=420,height=230,resizable=yes,scrollbars=yes,status=yes`}
              target="_blank"
              rel="noopener noreferrer"
            >
            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.82163 11.7256C8.91161 12.3138 7.65955 12.5471 6.83235 12.6347L7.5268 13.3313L10.0605 15.91C10.9872 16.8763 9.47959 18.3872 8.54442 17.4528C7.91025 16.7973 6.97989 15.8509 6.01034 14.8639L3.47478 17.4502C2.53813 18.3796 1.03055 16.8553 1.96682 15.9055C2.61135 15.25 3.53135 14.3036 4.50091 13.3271L5.18684 12.6306C4.36964 12.5429 3.0976 12.3232 2.1776 11.7218C1.09489 11.0118 0.620096 10.5963 1.03868 9.72747C1.28422 9.23414 1.95832 8.81834 2.85059 9.53481C2.85059 9.53481 4.05644 10.5132 6.00257 10.5132C7.94871 10.5132 9.15419 9.53481 9.15419 9.53481C10.0476 8.82172 10.7187 9.23377 10.9661 9.72747C11.378 10.5963 10.9032 11.0118 9.81978 11.7248L9.82163 11.7256ZM1.69134 4.82019C1.69134 4.2445 1.80277 3.67444 2.01926 3.14256C2.23575 2.61069 2.55307 2.12742 2.9531 1.72034C3.35312 1.31326 3.82802 0.99035 4.35068 0.770041C4.87334 0.549732 5.43352 0.43634 5.99925 0.43634C6.56497 0.43634 7.12515 0.549732 7.64781 0.770041C8.17047 0.99035 8.64537 1.31326 9.04539 1.72034C9.44542 2.12742 9.76274 2.61069 9.97923 3.14256C10.1957 3.67444 10.3072 4.2445 10.3072 4.82019C10.3072 7.23338 8.371 9.1935 5.99962 9.1935C3.62823 9.1935 1.69134 7.23338 1.69134 4.82019ZM3.88338 4.82019C3.88543 5.24545 4.0112 5.66057 4.24482 6.01318C4.47845 6.36578 4.80947 6.64007 5.19612 6.80144C5.58276 6.96281 6.00771 7.00403 6.41736 6.9199C6.827 6.83577 7.20298 6.63006 7.49786 6.32872C7.79274 6.02738 7.99331 5.64392 8.07427 5.22671C8.15523 4.80949 8.11294 4.37723 7.95275 3.98444C7.79256 3.59166 7.52164 3.25596 7.17418 3.01969C6.82671 2.78342 6.41826 2.65717 6.00035 2.65686C5.72167 2.65711 5.44577 2.7133 5.18846 2.82222C4.93114 2.93113 4.69747 3.09063 4.50083 3.29159C4.30418 3.49254 4.14842 3.73099 4.04247 3.9933C3.93652 4.2556 3.88246 4.53659 3.88338 4.82019V4.82019Z" fill="#FF8B21"/>
            </svg>
            </a>
          </li>
        }



        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://wa.me/?text=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon icon_wa">
              <use xlinkHref="#social-wa"></use>
            </svg>
          </a>
        </li>


      </ul>
    </div>
    </div>
  );
};

export default ShareBlock;