import _keys from 'lodash/keys';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {Link, useLocation} from 'react-router-dom';
import useMedia from '../../hooks/useMedia';
import { validateMail } from '../../utils';
import Dropdown from '../core/Dropdown';
import DropdownCustomColor from '../core/DropdownCustomColor';
import { errorToast, infoToast, successToast } from '../toasts';

const phoneRequired = (v) =>
  v && v.indexOf('_') === -1 ? undefined : 'Введите телефон';

const Input = (props) => {

  const { name, inputRef, defaultValue, onChange, ...rest } = props;
  const [inputValue, setInputValue] = useState(defaultValue || '');


  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    let sanitizedData = pasteData.replace(/[^\d]/g, '');
    if (sanitizedData.startsWith('7') || sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('8')) {
      sanitizedData = sanitizedData.substring(1);
    } else if (sanitizedData.startsWith('7') || sanitizedData.startsWith('+7')) {
      sanitizedData = sanitizedData.substring(2);
    }

    const formattedData = `+7 (${sanitizedData.slice(0, 3)}) ${sanitizedData.slice(3, 6)}-${sanitizedData.slice(6, 8)}-${sanitizedData.slice(8, 10)}`;
    setInputValue(formattedData);
    if (onChange) {
      onChange(formattedData);
    }
    // console.log(sanitizedData)
  };
  return (
    <InputMask {...rest} mask="+7 (999) 999-99-99" maskChar="_" value={inputValue} onPaste={handlePaste} onChange={(e) => setInputValue(e.target.value)}>
      {(inputProps) => (
        <input name={name} ref={inputRef} {...inputProps} />
      )}
    </InputMask>
  );
};

const Form = (props) => {
  const { add, history, defaultValues, agents, statuses, edit = false, advertisingId } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromCrm = queryParams.get('fromCrm');

  const isMobile = useMedia("isMobile")
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = (values) => {
    let form = new FormData();
    let keys = _keys(values);

    keys.forEach((item) => {
      let identity = item;
      if (Array.isArray(values[item])) {
        values[item].forEach((appendValue) => {
          form.append(`${identity}[]`, appendValue);
        });
      } else {
        form.append(`${identity}`, values[item]);
      }
    });
    if (files.length) {
      files.map((file, index) => {
        form.append(`file[]`, file);
        form.append(`fileName[]`, fileNames[index]);
        return file;
      });
    }
    form.append("advertisingId", advertisingId)
    form.append("city", "spb")
    add(form).then(
      (data) => {
        edit ?
          infoToast(<div className="Toastify__text">
            Данные клиента{' '}
            <strong>
              {values.lastName} {values.name}
            </strong>{' '}
        обновлены
      </div>)
          :
          successToast(<div className="Toastify__text">
            Клиент{' '}
            <strong>
              {values.lastName} {values.name}
            </strong>{' '}
        успешно добавлен
      </div>)
        fromCrm ? history.push(`/crm/clients`) : history.push(`/personal/clients`);

      },
    );
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      return
    }
    let messages = []
    for (let key in errors) {
      messages.push(`${Array.isArray(errors[key]) && errors[key] && errors[key][0] ? errors[key][0]["message"] : errors[key]?.message}`)
    }

    errorToast(
      <div>Заполните обязательные поля:<br /> {messages.map(v => {
        let msg = v.replace("Введите ", "")
        return <div>{`${msg[0].toUpperCase()}${msg.slice(1)} клиента`}<br /></div>
      })}</div>
    )

  }, [errors])
  const changeFile = (e) => {
    const tmpFiles = e.target.files;
    if (tmpFiles.length > 0) {
      let tFiles = [...files];
      let tNames = [...fileNames];
      [...tmpFiles].forEach((item) => {
        tFiles.push(item);
        tNames.push(item.name);
      });
      setFiles(tFiles);
      setFileNames(tNames);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phone',
  });

  const {
    fields: fieldsEmail,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'email',
  });

  return (
    <section className="section section_view_white clients no-header-profile">
      <div className="wrapper">
        <div className="personal_content">
          <div className="personal__clients">
            <div className="clients__header">
              <div className="clients__title">
                {isMobile &&
                  <Link to={!fromCrm ? `/personal/clients` : `/crm/clients`}>
                    <button className="button button_view_outline-with-icon js-go-back clients__back-btn is-small">
                      <svg className="icon icon_back">
                        <use xlinkHref="#back"></use>
                      </svg>
                    </button>
                  </Link>
                }
                <div className="h1">
                  {edit ? `Редактирование клиента` : `Создать нового клиента`}
                </div>
              </div>
            </div>
            <div className="clients__grid">
              {!isMobile &&
                <div className="clients__grid-col clients__back">
                  <Link to={!fromCrm ? `/personal/clients` : `/crm/clients`}>
                    <button className="button button_view_outline-with-icon js-go-back clients__back-btn">
                      <svg className="icon icon_back">
                        <use xlinkHref="#back"></use>
                      </svg>
                      <span>Вернуться</span>
                    </button>
                  </Link>
                </div>
              }
              <div className="clients__grid-col clients__new">
                <form
                  className="clients__form js-form-address"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {edit && (
                    <>
                      <input type="hidden" name="clientId" ref={register} />
                      <input type="hidden" name="oldManagerId" ref={register} />
                    </>
                  )}
                  <div className="lk-form__row _bg-gray">
                    {!isMobile && <div className="lk-form__row-title"></div>}
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr">
                        <div className="select select_type_form">
                          <label className="custom-select-label" for="cl-status">
                            Статус:
                          </label>
                          <Controller
                            as={DropdownCustomColor}
                            name="status"
                            control={control}
                            options={statuses}
                            ref={register}
                            onChange={([value]) => {
                              return value;
                            }}
                          />
                        </div>
                        <div className="checkbox lk-form__fr">
                          <div className="checkbox__box">
                            <input
                              className="checkbox__control"
                              type="checkbox"
                              id="cl-is-fav"
                              name="addToFavourite"
                              ref={register}
                            />
                            <label
                              className="checkbox__input"
                              for="cl-is-fav"
                            ></label>
                            <div className="checkbox__marker"></div>
                          </div>
                          <label className="checkbox__label" for="cl-is-fav">
                            Добавлен в избранное
                          </label>
                        </div>
                      </div>
                      <div className="lk-form__fr">
                        <div className="select select_type_form">
                          <label className="custom-select-label" for="cl-manager">
                            {isMobile ? 'Фамилия и имя менеджера' : 'Менеджер:'}
                          </label>
                          <Controller
                            as={Dropdown}
                            name="managerId"
                            control={control}
                            options={agents}
                            ref={register}
                            onChange={([value]) => {
                              return value;
                            }}
                            withSearch
                            searchPlaceholder={"Поиск"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__row">
                    <div className="lk-form__row-title">О клиенте</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-sec-name"
                        >
                          <span className="input__label">
                            {isMobile ? 'Фамилия клиента' : 'Фамилия:'}
                          </span>
                          <input
                            name="lastName"
                            className={
                              'input__control' +
                              (errors.lastName ? ' input__error' : '')
                            }
                            id="cl-sec-name"
                            ref={register({
                              required: 'Введите фамилию',
                            })}
                            autocomplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-name"
                        >
                          <span className="input__label">
                            {isMobile ? 'Имя клиента' : 'Имя:'}
                          </span>
                          <input
                            name="name"
                            className={
                              'input__control' +
                              (errors.name ? ' input__error' : '')
                            }
                            ref={register({
                              required: 'Введите имя',
                            })}
                            id="cl-name"
                            autocomplete="off"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="input input_type_form input_width_available"
                          htmlFor="cl-f-name"
                        >
                          <span className="input__label">
                            {isMobile ? 'Отчество клиента' : 'Отчество:'}
                          </span>
                          <input
                            name="patronymic"
                            id="cl-f-name"
                            autocomplete="off"
                            className={
                              "input__control" +
                              (errors.patronymic ? " input__error" : "")
                            }
                            ref={register({
                              ...(!!advertisingId || true ? [] : { required: "Введите отчество", })
                            })}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="lk-form__row js-fias _bg-gray">
                    <div className="lk-form__row-title">Адрес клиента</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr">
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-city"
                        >
                          <span className="input__label">Город:</span>
                          <input
                            className="input__control js-form-city"
                            id="cl-city"
                            autocomplete="off"
                            name="city"
                            ref={register}
                            data-kladr-type="city"
                          />
                        </label>
                      </div>
                      <div className="lk-form__fr">
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-street"
                        >
                          <span className="input__label">Улица:</span>
                          <input
                            className="input__control js-form-street"
                            id="cl-street"
                            autocomplete="off"
                            name="street"
                            ref={register}
                            data-kladr-type="street"
                          />
                        </label>
                      </div>
                      <div className={isMobile ? "lk-form__fr is-half" : "lk-form__fr"}>
                        <label
                          className="input input_type_form input_width_available"
                          for="cl-house"
                        >
                          <span className="input__label">Номер дома:</span>
                          <input
                            className="input__control"
                            id="cl-house"
                            autocomplete="off"
                            name="house"
                            ref={register}
                            data-kladr-type="building"
                          />
                        </label>
                      </div>
                      <div className={isMobile ? "lk-form__fr is-half" : "lk-form__fr"}>
                        <label
                          className="input input_type_form input_width_small"
                          for="cl-apt"
                        >
                          <span className="input__label">Офис, квартира:</span>
                          <input
                            className="input__control"
                            id="cl-apt"
                            autocomplete="off"
                            name="number"
                            ref={register}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="lk-form__row">
                    <div className="lk-form__row-title">Контактные данные</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr">
                        {fields.map((item, index) => (
                          <label
                            className="input input_type_form input_width_available"
                            htmlFor={`cl-phone${index}`}
                            key={`phone${index}`}
                          >
                            <span className="input__label">Телефон:</span>
                            <Input
                              name={`phone[${index}]`}
                              className={
                                'input__control' +
                                (errors.phone && errors.phone[index]
                                  ? ' input__error'
                                  : '')
                              }
                              type="text"
                              id={`cl-phone${item.id}`}
                              inputRef={register({
                                required: 'Введите телефон',
                                validate: { inputTelRequired: phoneRequired },
                              })}
                              defaultValue={item.value}
                              key={item.id}
                              mask="+7 (999) 999-99-99"
                            />

                            {index === 0 ? (
                              <span className="input__alert info">
                                <svg className="icon icon_alert">
                                  <use xlinkHref="#alert"></use>
                                </svg>
                                <div className="info__popup">
                                  <p>
                                    <strong>Приоритетный номер</strong>
                                  </p>
                                  <p>
                                    При заполненни формы обратной связи, этот
                                    номер будет автоматически подставляться в
                                    форму.
                                  </p>
                                </div>
                              </span>
                            ) : (
                                <button
                                  className="input__remove"
                                  onClick={(e) => {
                                    remove(index);
                                    e.preventDefault();
                                  }}
                                >
                                  <svg
                                    className="icon"
                                    width="7"
                                    height="8"
                                    viewBox="0 0 7 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.66667 1.3L0.333333 6.7"
                                      strokeLinecap="round"
                                    ></path>
                                    <path
                                      d="M0.333333 1.3L5.66667 6.7"
                                      strokeLinecap="round"
                                    ></path>
                                  </svg>
                                </button>
                              )}
                          </label>
                        ))}
                        <div className="lk-form__add-param">
                          <button
                            className="button button_type_add"
                            onClick={(e) => {
                              e.preventDefault();
                              append('');
                            }}
                          ></button>
                          <span>Добавить ещё телефон</span>
                        </div>
                      </div>
                      <div className="lk-form__fr">
                        {fieldsEmail.map((item, index) => (
                          <label
                            className="input input_type_form input_width_available"
                            htmlFor={`cl-email${index}`}
                          >
                            <span className="input__label">E-mail:</span>
                            <input
                              name={`email[${index}]`}
                              className={
                                'input__control' +
                                (errors.email && errors.email[index]
                                  ? ' input__error'
                                  : '')
                              }
                              type="email"
                              id={`cl-email${index}`}
                              autocomplete="off"
                              ref={register({
                                // ...(!!advertisingId ? [] : { required: 'Введите e-mail', }),
                                pattern: {
                                  value: validateMail,
                                  message: 'Неправильный формат e-mail',
                                },
                              })}
                            />
                            {index === 0 ? (
                              <span className="input__alert info">
                                <svg className="icon icon_alert">
                                  <use xlinkHref="#alert"></use>
                                </svg>
                                <div className="info__popup">
                                  <p>
                                    <strong>Приоритетный e-mail</strong>
                                  </p>
                                  <p>
                                    При заполненни формы обратной связи, этот
                                    e-mail будет автоматически подставляться в
                                    форму.
                                  </p>
                                </div>
                              </span>
                            ) : (
                                <button
                                  className="input__remove"
                                  onClick={(e) => {
                                    removeEmail(index);
                                    e.preventDefault();
                                  }}
                                >
                                  <svg
                                    className="icon"
                                    width="7"
                                    height="8"
                                    viewBox="0 0 7 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.66667 1.3L0.333333 6.7"
                                      strokeLinecap="round"
                                    ></path>
                                    <path
                                      d="M0.333333 1.3L5.66667 6.7"
                                      strokeLinecap="round"
                                    ></path>
                                  </svg>
                                </button>
                              )}
                          </label>
                        ))}
                        <div className="lk-form__add-param">
                          <button
                            className="button button_type_add"
                            onClick={(e) => {
                              e.preventDefault();
                              appendEmail('');
                            }}
                          ></button>
                          <span>Добавить ещё e-mail</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__row _bg-gray">
                    <div className="lk-form__row-title">
                      Комментарий к&nbsp;клиенту
                    </div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr lk-form__fr_auto">
                        <label
                          className="textarea textarea_type_form textarea_width_available"
                          for="cl-comment"
                        >
                          <span className="textarea__label">
                            Текст комментария:
                          </span>
                          <textarea
                            className="textarea__control"
                            id="cl-comment"
                            name="comment"
                            ref={register}
                            rows="5"
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__row">
                    <div className="lk-form__row-title">Загрузить документы</div>
                    <div className="lk-form__row-content">
                      <div className="lk-form__fr lk-form__fr_full">
                        <label
                          className="input input_type_upload-btn"
                          for="file-input"
                        >
                          <input
                            className="input__control"
                            type="file"
                            multiple
                            id="file-input"
                            name="file"
                            onChange={changeFile}
                          />
                          Прикрепите файлы
                        </label>
                      </div>

                      <div className="lk-form__fr lk-form__fr_full">
                        <div className="clients__files">
                          {fileNames.map((file, index) => (
                            <div data-num="0" className="file-up" key={index}>
                              <div className="file-up__inner">
                                <svg className="icon file-up__icon">
                                  <use xlinkHref="#deals-01"></use>
                                </svg>
                                <span className="file-up__name">{file}</span>
                                <div className="file-up__controls">
                                  <button className="button button_view_round-control file-up__del">
                                    <svg className="icon">
                                      <use xlinkHref="#x"></use>
                                    </svg>
                                  </button>
                                  <button className="button button_view_round-control file-up__edit">
                                    <svg className="icon">
                                      <use xlinkHref="#edit"></use>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lk-form__submit">
                    <div className="lk-form__btns">
                      <Link to={!fromCrm ? `/personal/clients` : `/crm/clients`}>
                        <button className="button button_view_outline-with-icon js-go-back clients__back-btn">
                          <svg className="icon icon_back">
                            <use xlinkHref="#back"></use>
                          </svg>
                          <span>Вернуться</span>
                        </button>
                      </Link>
                      <button className="button button_view_default" type="submit">
                        {edit ? `Сохранить` : `Создать клиента`}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
