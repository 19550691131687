import { useMediaQuery } from 'beautiful-react-hooks';
import formatter from 'format-number';
import intersectionBy from 'lodash/intersectionBy';
import min from 'lodash/min';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUrl } from "../../api";
import emptyPlan from '../../assets/images/emptyPlan.svg';
import useMedia from '../../hooks/useMedia';
import { ReactComponent as Filters } from "../../images/svg/filters.svg";
import { getRoundedPrice, getSuffix, media } from '../../utils';
import MapList from '../Assignation/MapList';
import Table, { ShowMore } from "../core/Table2";
import Maps from '../Estate/Maps';

const priceFormat = (price) => {
  return formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
};

const Card = props => {
  const {
    id,
    squareTotal,
    floor,
    isPublic,
    savedFilterXmlId,
    squareKitchen,
    decoration,
    section,
    plan,
    corpus,
    costWithDiscount,
    number,
    objectType,
    squareLiving,
    city="spb",
    flatType,
    status,
    roomType,
    endingDate
  } = props;

  return (
    <div className="complex__appartments-card complex__appartments-card_tile">
      <div
        className="card-tile"
        onClick={(e) => {
          let link = isPublic
            ? `/public/${savedFilterXmlId}/${city}/commercial/${id}`
            : `/realty/${city}/commercial/${id}${window.location.search}`
          if (objectType === "commercial") {
            link = `/commercial/${id}${window.location.search}`
          }
          window.open(link);
        }}

      >
        <div className="card-tile__image">
          <img src={
            getUrl(plan.src)}
            alt={""}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }}
          />
        </div>

        <div className="card-tile__status table2-status">
          <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
            <div className="status-circle__tooltip">{status?.name}</div>
          </div>
        </div>

        <div className="card-tile__body">
          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__value-small">{floor} этаж</span>
              <span className="card-tile__value-lg">{priceFormat(costWithDiscount)}</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__value-small">№ помещения: {number}</span>
              <span className="card-tile__value-lg">{squareTotal} м²</span>
            </div>
          </div>

          <div className="card-tile__row">
            <div className="card-tile__col">
              <span className="card-tile__key">Отделка</span>
              <span className="card-tile__value">{decoration}</span>
            </div>
            <div className="card-tile__col">
              <span className="card-tile__key">Срок сдачи</span>
              <span className="card-tile__value">{endingDate}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

const CardsApartments = (props) => {
  const { items=[], expand, isPublic, savedFilterXmlId, innerRef, objectType } = props;

  const [loadedItems, setloadedItems] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setloadedItems(items.slice(0, 10))
  }, [items])
  const loadMore = () => {
    setLoading(true)
    setloadedItems(prev=>{
      const prevLen = prev.length
      return [...prev, ...items.slice(prevLen, prevLen + 10)]
    }
      )
    setLoading(false)
  }
  return (
    <div
      className="flats__row-results"
      style={{ display: expand ? "block" : "none" }}
      ref={innerRef}
    >
      <div className="complex__appartments-cards" >
        {loadedItems.map((item) => (
          <Card
            {...item}
            key={item.id}
            isPublic={isPublic}
            savedFilterXmlId={savedFilterXmlId}
            objectType={objectType}
          />
        ))}
      </div>
      {loadedItems.length < items.length && <ShowMore customMoreTitle={"Показать ещё"} onClick={loadMore} loading={loading} />}
    </div>
  );
};

const TableRow = (props) => {
  const {active, items, isAgent, clientMode, viewType, setViewType } = props;

  const media1280 = useMediaQuery('(max-width: 1280px)');
  const handleSurface = (e) => {
    if (media1280) e.stopPropagation();
  };
  const history = useHistory();
  const [lastSort, setLastSort] = useState(null);

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");
  const isDesktop = useMedia("isDesktop");

  const TOOLTIP_CUT_VALUE = !isDesktop ? 11 : 5;
  
  const getColumns = () => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 55,
      sorter: (a, b) => a.id - b.id,
      sortOrder: lastSort && lastSort.columnKey === 'id' && lastSort.order,
      render: id => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{id}</div>
        </div>
      ),
    },
    {
      title: "План.",
      dataIndex: "plan",
      key: "plan",
      width: isDesktop ? 50 : 80,
      render: (plan) => {
        return (
          <div className="table-img">
            <img src={plan?.src} alt="" onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyPlan
            }} />
            <div className="table-img__preview">
              <img src={plan?.src} alt="" onError={(e) => {
                e.target.onerror = null;
                e.target.src = emptyPlan
              }} />
            </div>
          </div>
        )
      },
    },
    {
      title: 'Район',
      dataIndex: 'region',
      key: 'region',
      width: !isDesktop ? 130 : 80,
      render: (region) => {
        return region?.name?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span>{`${region?.name.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{region?.name}</div>
          </div>
        ) : (
          <span>{region?.name}</span>
        )
      },
      sorter: (a, b) => {
        if (a.region?.name < b.region?.name) {
          return -1;
        }
        if (a.region?.name > b.region?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder: lastSort && lastSort.columnKey === 'region' && lastSort.order,
    },
    {
      title: 'Метро',
      dataIndex: 'subway',
      key: 'subway',
      render: (subway) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">
            {subway ? subway.map((v) => v?.name).join(', ') : ''}
          </div>
        </div>
      ),
      sorter: (a, b) => a.subway.length - b.subway.length,
      sortOrder: lastSort && lastSort.columnKey === 'subway' && lastSort.order,
    },
    {
      title: !isLaptop ? "Застройщик" : "Застр.",
      dataIndex: 'builder',
      key: 'builder',
      render: (builder) => (
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{builder?.name}</div>
        </div>
      ),
      sorter: (a, b) => {
        if (a.builder?.name < b.builder?.name) {
          return -1;
        }
        if (a.builder?.name > b.builder?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === 'builder' && lastSort.order,
    },
    {
      title: 'ЖК',
      dataIndex: 'block',
      key: 'block',
      width: !isDesktop ? 130 : 70,

      render: (block) => {
        return block?.name?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span style={{overflowWrap: "normal"}}>{`${block?.name.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{block?.name}</div>
          </div>
        ) : (
          <span>{block?.name}</span>
        )
      },

      sorter: (a, b) => {
        if (a.block?.name < b.block?.name) {
          return -1;
        }
        if (a.block?.name > b.block?.name) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === 'block' && lastSort.order,
    },
    {
      title: !isLaptop ? "Корп." : "Кор.",
      dataIndex: 'corpus',
      key: 'corpus',
      width: 70,
      ellipsis: true,
      sorter: (a, b) => a.corpus - b.corpus,
      sortOrder: lastSort && lastSort.columnKey === 'corpus' && lastSort.order,
    },
    {
      title: !isDesktop ? "Этаж" : 'Эт.',
      dataIndex: 'floor',
      key: 'floor',
      width: 50,
      sorter: (a, b) => a.floor - b.floor,
      sortOrder: lastSort && lastSort.columnKey === 'floor' && lastSort.order,
      render: floor => (floor === "Цоколь"?
        <div className="surface" onClick={handleSurface}>
          <div className="surface__wrap">{floor}</div>
        </div>: floor
      ),
    },
    {
      title: !isDesktop ? "S общ." : "S об.",
      dataIndex: 'squareTotal',
      key: 'squareTotal',
      width: 80,
      sorter: (a, b) => a.squareTotal - b.squareTotal,
      sortOrder:
        lastSort && lastSort.columnKey === 'squareTotal' && lastSort.order,
    },
    {
      title: !isDesktop ? "Отделка" : "Отд.",
      dataIndex: 'decoration',
      key: 'decoration',
      width: !isDesktop ? 110 : 80,
      sorter: (a, b) => {
        if (a.decoration < b.decoration) {
          return -1;
        }
        if (a.decoration > b.decoration) {
          return 1;
        }
        return 0;
      },
      render: (decoration) => {
        return decoration?.length > TOOLTIP_CUT_VALUE ? (
          <div className="table2-with-tooltip">
            <span>{`${decoration.slice(0, TOOLTIP_CUT_VALUE)}...`}</span>
            <div className="table2-with-tooltip__content">{decoration}</div>
          </div>
        ) : (
          <span>{decoration}</span>
        )
      },
      sortOrder:
        lastSort && lastSort.columnKey === 'decoration' && lastSort.order,
    },
    {
      title: 'Цена',
      dataIndex: 'costWithDiscount',
      key: 'costWithDiscount',
      fixed: 'right',
      render: (price) => <span className="nowrap">{priceFormat(price)}</span>,
      width: 100,
      sorter: (a, b) => a.baseCost - b.baseCost,
      sortOrder:
        lastSort && lastSort.columnKey === 'costWithDiscount' && lastSort.order,
    },
    ...(
      !isAgent && !clientMode
      ? [
          {
            title: !isLaptop ? "Комиссия" : "Ком.",
            dataIndex: 'commission',
            key: 'commission',
            fixed: 'right',
            render: (commission) => (
              <span className="nowrap">{commission}</span>
            ),
            sorter: (a, b) =>{
              return  min(a.commission.replace(/,/g,".").replace(/ /g,"").replace(/%/g,"").split("-")) - min(b.commission.replace(/,/g,".").replace(/ /g,"").replace(/%/g,"").split("-"))
            },
            sortOrder:
              lastSort && lastSort.columnKey === 'commission' && lastSort.order,
          },
        ]
      : []),
    {
      title: 'Сдача',
      width: 100,
      dataIndex: 'endingDate',
      key: 'endingDate',
      fixed: 'right',
      render: (endingDate) => <span className="nowrap">{endingDate}</span>,
      sorter: (a, b) => {
        if (a.endingDate < b.endingDate) {
          return -1;
        }
        if (a.endingDate > b.endingDate) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        lastSort && lastSort.columnKey === 'endingDate' && lastSort.order,
    },
    {
      title: !isDesktop ? "Статус" : "Ст.",
      dataIndex: "status",
      key: "status1",
      sorter: (a, b) => a.status?.name?.length - b.status?.name?.length,
      width: !isDesktop ? 100 : 80,
      render: (status, record) => {
        return (
          <div className="table2-status">
            <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
              <div className="status-circle__tooltip">{status?.name}</div>
            </div>
            <div className="status-anchors">
              <a href={`/flat-booking/commercial/${record.id}/spb`} target="_blank" onClick={e => e.stopPropagation()}>
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M16.4409 2.59855C14.2024 0.312851 10.4717 0.312851 8.23322 2.59855C6.74092 4.12236 6.24348 6.40806 6.86528 8.56678L0.274261 15.2969C0.149902 15.4239 0.149902 15.5509 0.149902 15.6779V18.7255C0.149902 18.9794 0.39862 19.2334 0.647338 19.2334H3.63195C3.75631 19.2334 3.75631 19.2334 3.88067 19.2334V17.2017H5.87041V15.1699H7.73579V13.1382H9.72553L10.4717 12.3763C12.5858 13.0112 14.8242 12.5033 16.3165 10.9795C18.8037 8.69376 18.8037 4.88426 16.4409 2.59855ZM14.9486 6.40806C14.3268 7.04298 13.3319 7.04298 12.7101 6.40806C12.0883 5.77314 12.0883 4.75727 12.7101 4.12236C13.3319 3.48744 14.3268 3.48744 14.9486 4.12236C15.5704 4.75727 15.5704 5.77314 14.9486 6.40806Z" />
                </svg>
              </a>
            </div>
          </div>
        )
      },
    },
  ];

  const getMobileColumns = () => [

    {
      title: 'Эт.',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'S общ.',
      dataIndex: 'squareTotal',
      key: 'squareTotal',
    },
    {
      title: 'Цена',
      dataIndex: 'costWithDiscount',
      key: 'costWithDiscount',
      fixed: 'right',
      render: (price) => <span className="nowrap">{getRoundedPrice(price)} млн.</span>,
    },
    ...(
      !isAgent && !clientMode
      ? [
          {
            title: 'Комм.',
            dataIndex: 'commission',
            key: 'commission',
            fixed: 'right',
            render: (commission) => (
              <span className="nowrap">{commission}</span>
            ),
          },
        ]
      : []),
      {
        title: "Стат",
        dataIndex: "status",
        key: "status1",
        render: (status) => {
          return (
            <div className="table2-status">
              <div className={`status-circle status-circle_${status.color === "green" ? "positive" : "negative"}`}>
                <div className="status-circle__tooltip">{status?.name}</div>
              </div>
            </div>
          )
        },
      },
  ];

  const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">ID</span>
            {record.id}
          </div>
          {record.region &&
            <div className="lk-presentation__clients">
              <span className="flats__row-table-expanded-title">Район</span>
              <span className="flats__row-table-expanded-list">{record.region?.name}</span>
            </div>
          }
          {record.subway &&
            <div className="lk-presentation__clients">
              <span className="flats__row-table-expanded-title">Метро</span>
              <span className="flats__row-table-expanded-list">{record.subway.map((v) => v?.name).join(', ')}</span>
            </div>
          }
          {record.builder &&
            <div className="lk-presentation__clients">
              <span className="flats__row-table-expanded-title">Застройщ.</span>
                <span className="flats__row-table-expanded-list">{record.builder?.name}</span>
            </div>
          }
          {record.block &&
            <div className="lk-presentation__clients">
              <span className="flats__row-table-expanded-title">ЖК</span>
                <span className="flats__row-table-expanded-list">{record.block?.name}</span>
            </div>
          }
          {record.corpus &&
            <div className="lk-presentation__clients">
              <span className="flats__row-table-expanded-title">Корпус</span>
                <span className="flats__row-table-expanded-list">{record.corpus}</span>
            </div>
          }
        </div>
      </div>
    )
  }

  function onChange(pagination, filters, sorter, extra) {
    //console.log("params", pagination, filters, sorter, extra);
    setLastSort({
      order: sorter.order,
      columnKey: sorter.field,
    });
  }
  const onLoadEndCallBack = () => {};
  return (
    <div className={`flats__row-table ${active ? `is-active` : ``}`}>
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={isLaptop ? getMobileColumns() : getColumns()}
        dataSource={items}
        pagination={false}
        locale={{ emptyText: 'ничего не найдено' }}
        customPagination={true}
        customMoreTitle={isMobile && "Показать еще 10"}
        autoLoadMore={isMobile ? false : true}
        onChange={onChange}
        onLoadEndCallBack={onLoadEndCallBack}
        // scroll={{ x: 1140 }}
        firstPageCount={10}
        pageCount={50}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              window.open(`/commercial/${record.id}`);
            },
          };
        }}
        expandable={isMobile ? {
          expandedRowRender: record => expandedRow(record),
        } : false}
      />

      {isMobile &&
      <div className="controls-wrapper" style={{ bottom: 0, position: 'sticky' }}>
        
        <div className={`complex__view-item view__map active`}>
          <button
            className={`view__btn button`}
            onClick={() => setViewType("map")}
          >
            <svg className="view__icon view__icon_map">
              <use xlinkHref="#marker"></use>
            </svg>
            <span>На карте</span>
          </button>
        </div>
        <button className="presentation-filters-btn" onClick={props.onClick}>
          <Filters />
          фильтры
          {props.count > 0 && `: ${props.count}`}
        </button>
      </div>
      }
    </div>
  );
};

let paintIsStarted = false;
const Items = (props) => {
  const { items, innerRef, features, mapItems, isAgent, clientMode, isPublic, savedFilterXmlId } = props;
  const [viewType, setViewType] = useState('list');
  const [isInitMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState([59.9342802, 30.3350986]);
  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);

  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");

  const filterByCluster = (items) => {
    if (selectedItems) {
      return intersectionBy(items, selectedItems, 'id');
    }
    if (currentPoint && isClusterOrPoint === 'point') {
      paintIsStarted = false;
      return intersectionBy(items, [currentPoint], 'id');
    }
    if (currentCluster && isClusterOrPoint === 'cluster') {
      paintIsStarted = false;
      return intersectionBy(items, currentCluster.features, 'id');
    }

    return items;
  };
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  useEffect(() => {
    if (isMobile && viewType == "map") {
      window.scrollTo({ top: 0 })
      document.body.classList.add('no-scroll')
    }
    if (isMobile && viewType == "list") {
      document.body.classList.remove('no-scroll')
    }
  }, [viewType]);

  return (
    <section
      className={`section`}
      ref={innerRef}
    >
      <div className="assignations-flats for-commercial assignations-flats_table2">
        <div id="filter-results"></div>
        <div className="wrapper">
          <div className="assignations-flats__header">
            <div className="assignations-flats__title">
              <h2 className="h2">
                Найдено {items.length} объект{getSuffix(items.length)}:
              </h2>
            </div>
            {!isLaptop && (
              <div className="assignations-flats__btns">

                <div className={`complex__view-item${viewType == `list` ? ` active` : ``}`}>
                  <button
                    className="view__btn button"
                    onClick={() => setViewType("list")}
                  >
                    <svg className="view__icon">
                      <use xlinkHref="#view_rows2"></use>
                    </svg>
                    <span>Список</span>
                  </button>
                </div>

                <div className={`complex__view-item${viewType == `cards` ? ` active` : ``}`}>
                  <button
                    className="view__btn button"
                    onClick={() => setViewType("cards")}
                  >
                    <svg className="view__icon">
                      <use xlinkHref="#view_cards2"></use>
                    </svg>
                    <span>Планировки</span>
                  </button>
                </div>


                <div className={`complex__view-item view__map ${viewType == `map` ? ` active` : ``}`}>
                  <button
                    className={`view__btn button`}
                    onClick={() => setViewType("map")}
                  >
                    <svg className="view__icon view__icon_map">
                      <use xlinkHref="#marker"></use>
                    </svg>
                    <span>На карте</span>
                  </button>
                </div>


              </div>


            )}
          </div>
        </div>
        {viewType === 'list' && (
          <div className="section__cards">
            <div className="wrapper">
              <div className="assignations-flats__tables table2">
                {items && (
                  <TableRow
                    items={items}
                    isAgent={isAgent}
                    active={true}
                    clientMode={clientMode}
                    onClick={props.onClick}
                    count={props.count}
                    viewType={viewType}
                    setViewType={setViewType}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {
        viewType === "cards" && (
          <div className="section__cards">
            <div className="wrapper">
              <div className="assignations-flats__tables table2">
                <CardsApartments
                  items={items}
                  isPublic={isPublic}
                  expand={true}
                  savedFilterXmlId={savedFilterXmlId}
                  objectType="commercial"
                />
              </div>
            </div>
          </div>
          )
        }
        {(isInitMap || viewType === 'map') && (
          <div
            className="section__map"
            style={{ display: viewType === 'map' ? 'block' : 'none' }}
          >
            <div className="map">
              <MapList
                items={filterByCluster(mapItems)}
                setMapCenter={setMapCenterHandler}
                fromPage={"commercials"}
                favType={"commercial"}
              />
              <div className="map__container">
                <Maps
                  features={features}
                  mapItems={mapItems}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  setCluster={setCluster}
                  setPoint={setPoint}
                  setClusterOrPoint={setClusterOrPoint}
                  setInitMap={setInitMap}
                  paintIsStarted={paintIsStarted}
                  type="assignments"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Items;
