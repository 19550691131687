import { memo } from 'react';
import RadioDropdown from '../../core/RadioDropdown';
import { PriceCurency } from '../../FilterRealty/Components';
import DistrictsFilter from '../../FilterRealty/DistrictsFilter';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';

const DubaiListFilterForm = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    currency,
    currencyList,
    setCurrency
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    <DistrictsFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
      isExcludable
      isAutocomplete
      city={"spb"} // поменять на dxb и модифицировать DistrictsMap
      setDistricts={filter => setFilterAndExclude({ filter }, "districts")}
    />
    {!!filterData.prices?.[currency] && <PriceFilter
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.[currency]?.items ?? []}
      wrapperClass="sf__filter-item"
      data={{
        title: "Цена от-до",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.[currency]?.range ?? 0,
      }}
      onChange={v => setFilterAndExclude(v, "prices")}
      renderTopContent={PriceCurency}
      debounced
      autoPosition
      currencyList={currencyList}
      currency={currency}
      setCurrency={setCurrency}
    />}
    <SelectFilter
      {...createSelectProps("rooms")}
      data={{
        title: "К-во комнат",
        identity: "rooms",
      }}
      isAutocomplete={false}
    />
    <EndingsFilter
      autoPosition
      ident={"endings"}
      filter={filter.endings ?? { min: "", max: "" }}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      wrapperClass="sf__filter-item"
      clearFilterOnly={() => clearByIdent("endings")}
      clearExcludeOnly={() => clearByIdent("endings", "exclude")}
    />



    <EndingsFilter
      autoPosition
      filter={filter.issuingKeys ?? { min: "", max: "" }}
      items={filterData.issuingKeys?.items ?? []}
      wrapperClass="sf__filter-item"
      data={{
        title: "Выдача ключей",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.issuingKeys?.ranges ?? null,
      }}
      onChange={v => setFilterAndExclude(v, "issuingKeys")}
      ident={"issuingKeys"}
    />
    {!!filterData?.type?.length && <SelectFilter
      {...createSelectProps("type")}
      wrapperClass="sf__filter-item"
      data={{
        title: "Тип недвижимости",
        identity: "type",
      }}
    />}
    {!!filterData?.residencePermit?.length && <RadioDropdown
      autoPosition
      onChange={v => handleFilter('residencePermit', v)}
      items={filterData.residencePermit ?? []}
      wrapperClass="sf__filter-item"
      value={filter.residencePermit}
      alternativeTitle={'ВНЖ'}
      data={{
        title: 'ВНЖ',
        identity: 'residencePermit',
      }}
    />}
    {!!filterData?.toSea?.length && <SelectFilter
      {...createSelectProps("toSea")}
      wrapperClass="sf__filter-item"
      data={{
        title: "До моря",
        identity: "toSea",
      }}
    />}
  </>
});

export default DubaiListFilterForm;