import React, { useState } from "react";
import useUniversalForm from '../../hooks/useUniversalForm';
import { standartErrorToast, standartSuccessToast } from "../toasts";
import "./index.scss";
import NewsShare from './Share.js';

const HotNews = (props) => {

  const {
    isLoading,
    response,
    send,
  } = useUniversalForm({
    formId: 46,
    isOneResult: 1,
  });

  const [isParticipating, setIsParticipating] = useState(null);

  const onSend = () => {
    send().then(standartSuccessToast, standartErrorToast).then(() => setIsParticipating(true));
  };

  return (

    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`Команда ПАН Партнёр объявляет набор на «Смену 15:15»`} />
        <div className="news__container container news__container_smena">
          <h1 className="h1 news__title">Команда ПАН Партнёр объявляет набор на «Смену 15:15»</h1>
          <img src={require('../../assets/images/news/smena.png').default} alt="" className="news__promo-img" />
          <p className="h3 news__subtitle">
            Что будет?
          </p>

          <p className="news__text big-text">Мы делаем этот лагерь для себя и наших партнёров. Пора подзарядить наши батарейки душевным теплом. Нас ждут большой party-дом, высокие сосны и чистый воздух. Мы вспомним, что такое «снежный ком» и зарядка по утрам с физруком. Проверим скрытые возможности зубной пасты и душевных посиделок у костра c гитарой. Побываем на лекции, дискотеке и даже постоим на гвоздях, а результат закрепим SPA-мероприятиями. И это не всё <span role="img" aria-label="smile">😋</span></p>
          <p className="news__text news__big-margin"><span role="img" aria-label="happy">⛱️</span> Смена пройдёт со 2 по 5 сентября. За нами проживание и питание, автобус туда/обратно, и насыщенная программа.</p>
          <p className="news__text news__big-margin"><span role="img" aria-label="angel">😇</span> «Смена 15/15» создана для того, чтобы перезагрузиться и найти новых друзей.</p>

          <p className="news__text h5 news__big-margin">Как стать участником смены «Смены 15/15»?</p>


          <p className="news__text"><span role="img" aria-label="deal">💵</span> Проведите с 1 июля по 15 августа сделки на сумму от 15 млн. и выиграйте путёвку в лагерь как в детстве, только  для взрослых.</p>

          <p className="news__text news__big-margin"><span role="img" aria-label="balloon">🎈</span> 20 августа мы объявим результаты и подарим путёвки победителям, а по мере приближения мероприятия будем радовать вас новыми подробностями.</p>


          <p className="h3 news__subtitle news__big-margin">Чтобы стать участником, жмите «Хочу участвовать»</p>

          <button type="button" rel="noopener noreferrer" className="news__btn news__btn_left news__btn_mt-0 button button_view_default" onClick={onSend} disabled={isLoading || isParticipating || response.isExistResult}>
            {
              isParticipating || response.isExistResult ? `Уже участвуете` : `Хочу учавствовать`
            }
          </button>

        </div>
      </div>

    </section>
  )
};

export default HotNews;
