import {memo} from 'react';
import {PriceCurency} from '../../FilterRealty/Components';
import DistrictsFilter from '../../FilterRealty/DistrictsFilter';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import {getSelectPropsCreator} from '../utils';

const DubaiTopFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    currency,
    currencyList,
    setCurrency,
    wrapperClass = "sf__filter-item filter_item_temp",
    city
  } = props;


  const createSelectProps = getSelectPropsCreator(props);
  return <>
    {!!filterData?.districts?.length > 0 && <DistrictsFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
      isExcludable
      isAutocomplete
      // city={"dxb"} // поменять на dxb и модифицировать DistrictsMap
      city={city} // поменять на dxb и модифицировать DistrictsMap
      setDistricts={filter => setFilterAndExclude({filter}, "districts")}
      wrapperClass={wrapperClass}
      hideMapButton
    />}
    {!!filterData.prices?.[currency] && <PriceFilter
      filter={filter.prices ?? {min: "", max: ""}}
      items={filterData.prices?.[currency]?.items ?? []}
      wrapperClass={wrapperClass}
      data={{
        title: "Цена от-до",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.[currency]?.range ?? 0,
      }}
      onChange={v => setFilterAndExclude(v, "prices")}
      renderTopContent={PriceCurency}
      debounced
      autoPosition
      currencyList={currencyList}
      currency={currency}
      setCurrency={setCurrency}

    />}
    {!!filterData?.rooms?.length > 0 && <SelectFilter
      {...createSelectProps("rooms")}
      data={{
        title: "К-во комнат",
        identity: "rooms",
      }}
      isAutocomplete={false}
      wrapperClass={wrapperClass}
    />}
    {!!filterData?.countBedrooms?.length > 0 && <SelectFilter
      {...createSelectProps("countBedrooms")}
      data={{
        title: "Кол-во спален",
        identity: "countBedrooms",
      }}
      isAutocomplete={false}
      wrapperClass={wrapperClass + `form__item_bedroom-top`}
    />}
    {!!filterData.endings?.items && <EndingsFilter
      autoPosition
      ident={"endings"}
      filter={filter.endings ?? {min: "", max: ""}}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      wrapperClass={wrapperClass}
      clearFilterOnly={() => clearByIdent("endings")}
      clearExcludeOnly={() => clearByIdent("endings", "exclude")}

    />}
    {!!filterData?.type?.length && city !== 'dxb' &&
    <>
      <SelectFilter
        {...createSelectProps("type")}
        wrapperClass={wrapperClass + `${city === 'cyp' ? 'form__item_cyp-top-type' : ''}`}
        data={{
          title: "Тип недвижимости",
          identity: "type",
        }}
      />
      <SelectFilter
        {...createSelectProps("toSea")}
        wrapperClass={wrapperClass}
        data={{
          title: "До моря",
          identity: "toSea",
        }}
      />
    </>
    }
  </>
});

export default DubaiTopFilters;
