import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getUrl } from "../../../api";
import './style.scss';
import axios from 'axios'
import { imageTypes } from "../../../utils";

const DealAct = ({ act, justAct }) => {

  const [uploadedFile, setUploadedFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const isCrmFile = act?.src?.includes?.("file.ajax")
  useEffect(() => {
    if (!act) return
    if (isCrmFile && !uploadedFile) {
      setIsLoading(true)
      axios({
        method: 'get',
        withCredentials: true,
        url: getUrl(act.src),
      }).then(resp => {
        setUploadedFile(resp.data?.data?.src)
      },
        err => {
          setUploadedFile(null)
        }).finally(() => setIsLoading(false))
    }

  }, [act])
  if (!act || !uploadedFile || isLoading) return null
  return (
    <div className={`act-info-header ${justAct && "act-info-header--just-act"}`}>
      <h4 className="act-info-header__title">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="8.5" stroke="#2CBA2E" />
          <line x1="12.0435" y1="7.5" x2="12.0435" y2="13.5" stroke="#2CBA2E"
            strokeLinecap="round" />
          <circle cx="12.0435" cy="15.5" r="0.5" fill="#2CBA2E" />
        </svg>
        Акт по сделке
      </h4>
      <p className="act-info-header__description">
        Поздравляем с закрытием сделки. Скачать закрывающий акт можно <a className="act-info-header__link" download href={uploadedFile}
          target={"_blank"}>по ссылке.</a>
      </p>
    </div>
  )
}

export default DealAct
