import React from 'react';
import './index.scss';

const Placeholder = () => {

  return (
    <section className="section section_view_white chart flat">
      <div className="wrapper chart__wrapper">
        <div className="wrapper__center">
          <div className="chart__placeholder">
            <p>Скоро здесь будет график динамики изменения цены</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="1160" height="209" viewBox="0 0 1160 209" fill="none">
              <path d="M1155 67.3354L956.102 5.39615L800.072 139.995L648.889 62.2887L565.718 93.7413L360.967 203.518L191.369 190.566L9 80.0854" stroke="#8F95AC" strokeMiterlimit="10"/>
              <line x1="0.25" y1="129.755" x2="1154.75" y2="129.755" stroke="#8F95AC" strokeWidth="0.5" strokeMiterlimit="11.4737" strokeLinecap="round" stroke-dasharray="1 3"/>
              <line x1="0.25" y1="192.167" x2="1154.75" y2="192.167" stroke="#8F95AC" strokeWidth="0.5" strokeMiterlimit="11.4737" strokeLinecap="round" stroke-dasharray="1 3"/>
              <line x1="0.25" y1="67.3424" x2="1154.75" y2="67.3425" stroke="#8F95AC" strokeWidth="0.5" strokeMiterlimit="11.4737" strokeLinecap="round" stroke-dasharray="1 3"/>
              <line x1="0.25" y1="4.93039" x2="1154.75" y2="4.93047" stroke="#8F95AC" strokeWidth="0.5" strokeMiterlimit="11.4737" strokeLinecap="round" stroke-dasharray="1 3"/>
              <circle cx="191.899" cy="189.333" r="4.43376" fill="white" stroke="#8F95AC"/>
              <circle cx="4.93376" cy="78.21" r="4.43376" fill="white" stroke="#8F95AC"/>
              <circle cx="361.584" cy="203.518" r="4.43376" fill="white" stroke="#8F95AC"/>
              <circle cx="568.186" cy="93.0015" r="4.43376" fill="white" stroke="#8F95AC"/>
              <circle cx="648.975" cy="62.6588" r="4.43376" fill="#8F95AC" stroke="#8F95AC"/>
              <circle cx="801.922" cy="139.379" r="4.43376" fill="white" stroke="#8F95AC"/>
              <circle cx="956.102" cy="4.93376" r="4.43376" fill="white" stroke="#8F95AC"/>
              <circle cx="1155" cy="67.5925" r="4.43376" fill="white" stroke="#8F95AC"/>
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Placeholder;
