import { useReducer } from "react"
import { getUniversalReducer } from "./utils"

/**
 * @param {Object} defaultState
 * @example
 *  
      const { state, setValue, reset, setValues, dispatch } = useUniversalReducer({
        userId: null,
        userName: ""
      });
      
      setValue("userId", 123);
      setValues({
        userId: 123,
        userName: "Vasya"
      });
 */
const useUniversalReducer = defaultState => {
  const [state, dispatch] = useReducer(getUniversalReducer(defaultState), defaultState)
  const setValue = (key, value) => dispatch({ type: "set", payload: { key, value } });
  const reset = () => dispatch({ type: "reset", payload: {} });
  const setValues = payload => dispatch({ type: "apply", payload: typeof payload === "function" ? payload(state): payload });
  return { state, setValue, reset, setValues, dispatch };
}

export default useUniversalReducer;