import React, { memo } from 'react';
import FloorsFilter from '../../FilterRealty/FloorsFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import { getSelectPropsCreator } from '../utils';
import { SuburbanHouseFilterIcon } from "./icons";
import { Toggle } from './ToggleFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';


const SuburbanProjectsBottomFilter = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearByIdent,
    wrapperClass,
    clearExcludeOnly,
    clearFilterOnly,
  } = props;

  const createSelectProps = getSelectPropsCreator(props);

  return <>
    {!!filterData?.materialHouse?.length && <SelectFilter
      {...createSelectProps("materialHouse")}
      wrapperClass={wrapperClass}
      data={{
        title: "Материалы дома",
        identity: "materialHouse",
      }}
    />}
    <PriceFilter
      filter={filter.price ?? { min: "", max: "" }}
      items={filterData.price?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.price?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "price")}
      debounced
      wrapperClass={wrapperClass}
      clearFilterOnly={() => clearByIdent("price")}
      clearExcludeOnly={() => clearByIdent("price", "exclude")}
    />
    <FloorsFilter
      {...createSelectProps("countFloors")}
      onChange={v => setFilterAndExclude(v, 'countFloors')}
      filter={filter?.countFloors ?? { min: "", max: "" }}
      items={filterData.countFloors?.items ?? []}
      data={{
        title: "Кол-во этажей",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.countFloors?.range ?? null,
      }}
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      autoPosition
      wrapperClass={wrapperClass}
      filter={filter.squareLiving ?? { min: "", max: "" }}
      data={{
        title: 'S жилая',
        minPlaceholder: 'S жилая от ',
        maxPlaceholder: 'S жилая до ',
        defaultValues: filterData.squareLiving?.range ?? null
      }}
      onChange={({ filter }) => handleFilter('squareLiving', filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareLiving"}
      items={filterData.squareLiving?.items}
      showPlaceholderOnLists
      debounced
    // placeholderMes="сот."
    />

    <SelectFilter
      {...createSelectProps("decorationHouse")}
      data={{
        title: "Отделка",
        identity: "decorationHouse",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("constructionPeriod")}
      data={{
        title: "Срок строительства",
        identity: "constructionPeriod",
      }}
      wrapperClass={wrapperClass}
    />
    <div style={{gridColumn: '1 / -1', display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 20}}>
      <Toggle
        title="Терраса"
        ident="hasTerrace"
        {...props}
      />
      <Toggle
        title="Кухня-гостиная"
        ident="hasKitchenLiving"
        {...props}
      />
      <Toggle
        title="Панорамные окна"
        ident="hasPanoramicWindows"
        {...props}
      />
      <Toggle
        title="Гардероб"
        ident="hasWardrobe"
        {...props}
      />
      <Toggle
        title="Второй свет"
        ident="hasSecondLight"
        {...props}
      />
      <Toggle
        title="Мансардный этаж"
        ident="hasAtticFloor"
        {...props}
      />
      <Toggle
        title="Сауна"
        ident="hasSauna"
        {...props}
      />
    </div>
  </>
});

export default SuburbanProjectsBottomFilter;
