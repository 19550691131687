import forIn from 'lodash/forIn';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import {
	editFilter, fetchSavedFilters,
	sendFilter
} from '../../actions/savedFilters';
import {
	combineFormData, specialValues, typesWithArrayValues,
	typesWithObjectValues
} from '../../utils';
import BotBar from '../BotBar/BotBar';
import Dropdown from '../core/Dropdown';
import NewClient from '../forms/NewClient';
import { errorToast, infoToast } from '../toasts';
import { toFormData } from '../../utils';
import { applyToFormData } from '../../utils';
import useMedia from '../../hooks/useMedia';

const titles = {
	assignments: "Переуступки:",
	blocks: 'ЖК:',
	districts: 'Район:',
	microDistricts: 'Микрорайон:',
	parkingType: 'Тип паркинга:',
	rooms: 'К-во комнат:',
	builders: 'Застройщик:',
	availability: 'Доступность к метро:',
	banks: 'Банк:',
	decoration: 'Отделка:',
	endings: { min: 'Сдача от:', max: 'Сдача до:' },
	issuingKeys: { min: 'Выдача ключей от:', max: 'Выдача ключей до:' },
	floor: { min: 'Этаж от:', max: 'Этаж до:' },
	isApartments: 'Апартаменты:',
	payment: 'Способы оплаты:',
	purpose: 'Статус земли:',
	prices: { min: 'Цена от:', max: 'Цена до:' },
	cellHeight: { min: "Потолок от:", max: "Потолок до:" },
	registrations: 'Прописка:',
	squareKitchen: { min: 'S кухни от:', max: 'S кухни до:' },
	squareTotal: { min: 'Площадь от:', max: 'Площадь до:' },
	squareParcel: { min: 'S участка от:', max: 'S участка до:' },
	subways: 'Метро:',
	isNoFirstFloor: 'Этаж:',
	blockClass: 'Класс ЖК:',
	isOnlyHanded: 'Сданные дома: ',
	isHanded: 'Сдан: ',
	encumbrances: 'Обременения: ',
	balconyType: 'Балкон: ',
	balcony: 'Балкон: ',
	floors: { min: 'Этаж от:', max: 'Этаж до:' },
	tradeIn: "Trade-In:",
	countFloors: { min: 'Этажность от:', max: 'Этажность до:' },
	villages: "Посёлок",
	materials: "Материалы дома:",
	features: "Особенности:",
	communications: "Коммуникации:",
	bathroomTypes: "",
	type: "Тип:",
	squareHouse: { min: 'S дома от:', max: 'S дома до:' },
	nearby: "Рядом:",
	realtyType: "Тип договора:",
	toSea: 'До моря:',
	residencePermit: "ВНЖ:",
	section: "Тип:",
	constructionPeriod: "Срок строительства:",
	price: { min: 'Цена от:', max: 'Цена до:' },


	material: "Материалы дома:",
	projectCountBathrooms: "Сан узлов:",
    projectCountBedrooms: "Спален:",
	countBedrooms: "Спален:",
	countBathrooms: "Сан узлов:",
	constructionPeriod: "Срок строительства:",
	price: { min: 'Цена от:', max: 'Цена до:' },
};

const specialValuesTitles = {
	isNoFirstFloor: { Y: 'не первый' },
	isFirstFloor: { Y: 'не первый' },
	isApartments: { Y: 'Да', N: 'Нет' },
	isOnlyHanded: { Y: 'Да' },
	isHanded: { Y: 'Да' },
	encumbrances: { Y: 'Есть', N: 'Нет' },
	assignments: { Y: 'Да', N: 'Нет' },
	tradeIn: { Y: 'Да' },
	residencePermit: {
        "2years": "2 года",
        "5years": "5 лет"
    },
};

const Badge = ({ name, values }) => <div className="modal-form__filter">
	<div
		className="modal-form__filter-name"
		style={{ whiteSpace: 'normal' }}
	>
		{name}
	</div>
	<div className="modal-form__filter-value">
		{values}
	</div>
</div>


const SendFilter = (props) => {
	const {
		filterId,
		filter,
		filterData,
		filterView,
		fetch,
		clients,
		clientId,
		sendOnly,
		send,
		save,
		hide,
		toggleIsShowFilter,
		isShowFilter,
		city,
		filterType,
		filterBadges,
		excludeBadges
	} = props;

	const isMobile = useMedia("isMobile");

	const [showClientForm, setShowClientForm] = useState(false);
	const [client, setClient] = useState(0);
	const [url, setUrl] = useState('');
	const [inProcess, setInProcess] = useState(false);
	const [saved, setSaved] = useState(false);
	const [savedId, setSavedId] = useState(null);
	const fakeInput = useRef(null)
	useEffect(() => {
		if (!clients || !clients.length) fetch();

		setClient(clientId);
	}, []);

	useEffect(() => {
		if (!clients.length || client || clientId) return;

		setClient(clients[0].id);
	}, [clients]);

	const handleAddClient = (id) => {
		fetch().then(() => {
			setShowClientForm(false);
			setClient(id);
		});
	};
	const onSend = () => {
		setInProcess(true);
		send([filterId || savedId], [client])
			.then(
				data => {
					setInProcess(false);
					setUrl(data.link);
					infoToast(<div>{data.message}</div>)
					hide();
				},
				error => errorToast(<div>{error?.[0]?.message}</div>)
			)
	};

	const onSave = () => {
		setInProcess(true);

		const person = clients.find((item) => item.id == client);

		if (!person) return errorToast(<div>Нужно выбрать клиента или добавить его</div>)

		const filterFormData = toFormData(filter.filter, "filter", true)
		const excludesFormData = toFormData(filter.exclude, "exclude", true)

		applyToFormData(excludesFormData, {
			...(filterId? {id: filterId} : []),
			clients: [person.id],
			isShowFilter,
			city: city ? city : filterData.cityCode,
			...(filterType ? { filterType: filterType } : [])
		})
		const params = combineFormData(filterFormData, excludesFormData)

		save(params).then(
			(data) => {
				setSaved(true);
				setSavedId(data.id[0]);
				setUrl(data.links[0].url);
				infoToast(<div>{data.message}</div>)
				return Promise.resolve()
			},
			error => {
				errorToast(<div>{error?.[0]?.message}</div>)
				return Promise.reject()
			}
		).then(() => {
			setTimeout(() => {
				fakeInput.current.focus();
				fakeInput.current.select();
				try {
					document.execCommand('copy');
					infoToast(<div>Ссылка скопирована в буфер обмена</div>)
				} catch (err) {
					console.log('Не удалось скопировать ссылку');
				}
			}, 300);
		});;
	};

	const handleSubmit = () => {
		if (sendOnly) return onSend();
		else onSave();
	};

	const clientPhone = () => {
		if (!clients || !clients.length) return '';

		let person = clients.find((item) => item.id == client);
		if (!person) return '';

		return person.phone.replace(/[+() -]/g, '');
	};

	const titleById = (where, id, key) => {
		if (key === 'isApartments') {
			return;
		}
		if (!where) {
			return;
		}
		const item = where.find((data) => id == data.id);
		if (!item) {
			return;
		}
		return item.title;
	};
	const getFilterData = (type) => {
		if (filterView) return filterView[type];
		let output = [];
		forIn(filter[type], (value, key) => {
			//key == "blocks" /"districts" / etc
			if (value) {
				if (typesWithObjectValues.includes(key)) {
					if (value.min && value.min.length) {
						output.push({
							name: titles[key]['min'],
							values:
								key == 'endings' || key == 'issuingKeys'
									? titleById(filterData[key].items, value.min, key)
									: value.min,
							key: key,
							type: key,
							id: value.min,
						});
					}
					if (value.max && value.max.length) {
						output.push({
							name: titles[key]['max'],
							values:
								key == 'endings' || key == 'issuingKeys'
									? titleById(filterData[key].items, value.max, key)
									: value.max,
							key: key,
							type: key,
							id: value.max,
						});
					}
					return;
				}
				if (
					typesWithArrayValues.includes(key) &&
					value.length &&
					key !== 'status'
				) {
					return value.forEach((v) => {
						output.push({
							name: titles[key],
							values: titleById(filterData[key], v, key),
							key: key,
							type: key,
							id: v,
						});
					});
				}
				if (specialValues.includes(key) && value.length > 0 && Array.isArray(value)) {
					return value.forEach((v) => {
						output.push({
							name: titles[key],
							values: specialValuesTitles[key][v.id],
							key: key,
							type: key,
							id: v.id,
						});
					});
				}
				if (typeof titles[key] === "string" && specialValuesTitles[key]?.[value]) return output.push({
					name: titles[key],
					values: specialValuesTitles[key]?.[value],
					key,
					type: key,
					id: value,
				});
			}
		});
		return output;
	};
	const setIsShowFilter = e => {
		toggleIsShowFilter(e.target.checked ? "Y" : "N")
	}

	const excludes = excludeBadges || getFilterData("exclude");
	const filters = filterBadges || getFilterData("filter");

	return (
		<>
			<input ref={fakeInput} className="copy-fake-input" value={url}></input>
			{!isMobile &&
				<h2 className="modal__title">
					{sendOnly ? 'Отправить клиенту' : 'Сохранить фильтр'}
				</h2>
			}
			<div className="modal-form">
				<div className="modal-form__grid for-saved-filter">
					<div className="modal-form__filters">
						<SimpleBar style={{ maxHeight: '300px', marginBottom: '15px' }}>
							{!!filters?.length && <div className="modal-form__block" style={{ paddingRight: '15px' }} >
								<h4>Параметры фильтра</h4>
								{filters.map((item, i) => <Badge key={i} values={item.value ?? item.values} name={item.title ?? item.name} />)}
							</div>}
							{!!excludes?.length && <div className="modal-form__block" style={{ paddingRight: '15px' }} >
								<h4>Исключения</h4>
								{excludes.map((item, i) => <Badge key={i} values={item.value ?? item.values} name={item.title ?? item.name} />)}
							</div>}
						</SimpleBar>

						{!isMobile &&
							<button
								className="button button_view_outline-with-icon clients__back-btn js-modal-btn"
								onClick={hide}
							>
								<svg className="icon icon_back">
									<use xlinkHref="#back"></use>
								</svg>
								<span>Вернуться</span>
							</button>
						}
					</div>
					<div className="modal-form__col">
						<div className="modal-form__row">
							<div className="modal-form__wrap">
								<div className="select select_type_form">
									<label className="custom-select-label">Клиент</label>
									<Dropdown
										options={clients}
										value={client}
										onChange={(value) => {
											setClient(value);
										}}
									/>
								</div>
								{!showClientForm && (
									<div
										className="modal-form__add"
										style={{ width: 'auto' }}
										onClick={() => setShowClientForm(true)}
									>
										<button className="button button_type_add"></button>
										<span>Добавить клиента</span>
									</div>
								)}
								{showClientForm && <NewClient onAdd={handleAddClient} />}
								<hr className="modal-form__divider" />
								<div className="checkbox checkbox_view_red">
									<div className="checkbox__box">
										<input
											className="checkbox__control"
											type="checkbox"
											id="include-search-inside"
											onChange={setIsShowFilter}
										/>
										<label
											className="checkbox__input"
											htmlFor="include-search-inside"
										></label>
										<div className="checkbox__marker"></div>
									</div>
									<label
										className="checkbox__label"
										htmlFor="include-search-inside"
									>
										Включить поиск в подборке
									</label>
								</div>
								<div className="modal-form__send">
									<button
										className="button button_view_default"
										onClick={handleSubmit}
										disabled={saved}
									>
										<span>{sendOnly ? 'Отправить' : 'Сохранить'}</span>
									</button>
								</div>
							</div>
						</div>
						{!sendOnly && saved && (
							<div className="modal-form_row" style={{ padding: '26px 32px' }}>
								<div
									className="send-filter"
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<h4
										className="send-filter__label"
										style={{ margin: '15px 0' }}
									>
										Отправить фильтр клиенту?
									</h4>
									<ul
										className="subscribe__socials"
										style={{ justifyContent: 'flex-start' }}
									>
										<li
											className="subscribe__socials-item"
											onClick={() => onSend()}
											style={{ width: '40px', height: '40px' }}
										>
											<div
												className="subscribe__socials-link"
												style={{
													fontSize: '20px',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<svg
													className="icon icon_social-mail"
													style={{ transform: 'none' }}
												>
													<use xlinkHref="#social-mail"></use>
												</svg>
											</div>
										</li>
										<li
											className="subscribe__socials-item"
											style={{ width: '40px', height: '40px' }}
										>
											<a
												className="subscribe__socials-link"
												style={{
													fontSize: '20px',
													display: 'flex',
													alignItems: 'center',
												}}
												href={`https://web.whatsapp.com/send?phone=${clientPhone()}&text=${url}`}
												rel="noopener noreferrer"
												target="_blank"
											>
												<svg
													className="icon icon_social-wa"
													style={{ transform: 'none' }}
												>
													<use xlinkHref="#social-wa"></use>
												</svg>
											</a>
										</li>
										<li
											className="subscribe__socials-item"
											style={{ width: '40px', height: '40px' }}
										>
											<a
												className="subscribe__socials-link"
												style={{
													fontSize: '20px',
													display: 'flex',
													alignItems: 'center',
												}}
												href={`tg://msg_url?url=${url}`}
												rel="noopener noreferrer"
												target="_blank"
											>
												<svg
													className="icon icon_social-tg"
													style={{ transform: 'none' }}
												>
													<use xlinkHref="#social-tg"></use>
												</svg>
											</a>
										</li>
										<li
											className="subscribe__socials-item"
											style={{ width: '40px', height: '40px' }}
										>
											<a
												className="subscribe__socials-link"
												style={{
													fontSize: '20px',
													display: 'flex',
													alignItems: 'center',
												}}
												href={`viber://pa?chatURI=${clientPhone()}&text=${url}`}
												rel="noopener noreferrer"
												target="_blank"
											>
												<svg
													className="icon icon_social-viber"
													style={{ transform: 'none' }}
												>
													<use xlinkHref="#social-viber"></use>
												</svg>
											</a>
										</li>
									</ul>

								</div>
							</div>
						)}
						{/* <div className="modal-form__row">
							<div className="modal-form__wrap">
								<label
									className="input input_width_available input_type_form"
									htmlFor="gen-link"
								>
									<span className="input__label">
										Для вас сгененрирована ссылка
									</span>
									<input
										className="input__control"
										id="gen-link"
										defaultValue="https://disk.yandex.ru/client/disk/PAN"
									/>
								</label>
								<div className="modal-form__send">
									<button className="button button_view_another">
										Отправить по почте
									</button>
								</div>
							</div>
						</div> */}
					</div>
				</div>
				{saved && <BotBar />}

			</div>
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	fetch: (city) => dispatch(fetchSavedFilters(city)),
	send: (id, clientId, city) => dispatch(sendFilter(id, clientId, city)),
	save: (params) => dispatch(editFilter(params)),
	toggleIsShowFilter: (val) => dispatch({ type: "TOGGLE_SHOW_FILTER", data: val })
});

const mapStateToProps = (state) => ({
	clients: state.savedFilters.clients,
	isShowFilter: state.savedFilters.isShowFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(SendFilter);
