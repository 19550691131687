import _ from 'lodash';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import { media, searchRuSymbols } from '../../../utils';
import { defaultFullSmartFilter } from '../../../utils/defaultFilters';
import Exclude from './Exclude';
import { filterByKey } from './filterByKey';
import SearchResult from './SearchResult';

const intersections = {
  floors: ['squareTotal', 'rooms'],
  rooms: ['endings'],
  squareTotal: [],
  endings: ['rooms', 'prices'],
};

class SmartFilter extends React.Component {
  constructor(props) {
    super(props);
    this.fakeInputRef = React.createRef();
    this.lastWordRef = React.createRef();
    this.editableRef = React.createRef();
    this.last = '';
    this.state = {
      html: `
        <div class="contenteditable-placeholder">
          ${props.isMobile && props.countApartmentsTotal
            ? `${props.placholder? props.placholder : `Поиск по ${props.countApartmentsTotal} вариантам жилья`}`
            : `${props.placholder? props.placholder : "ЖК, метро, район, застройщик"}`
          }
        </div>`,
      value: '',
      searchResults: [],
      leftPostiton: 4,
      lastWord: '',
      isVisible: false,
      itemsIds: [],
      selected: null,
      items: [],
      lastFilter: '',
      showExcludeButton: false,
      match: null,
      currentExcludeMatch: null,
    };
    this.lastItem = null;
    this.intersection = {
      type: null,
      value: '',
    };
  }
  clearFilter = () => {
    this.props.clearFilter();
  };
  clearFilterOnly = () => {
    this.props.clearFilterOnly();
  };
  clearExcludeOnly = () => {
    this.props.clearExcludeOnly();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.isCleared !== this.props.isCleared && nextProps.isCleared) {
      if (nextProps.isCleared === 'full') {
        this.setState({
          html: `<div class="contenteditable-placeholder">${this.props.placholder? this.props.placholder : "ЖК, метро, район, застройщик"}</div>`,
          value: '',
          searchResults: [],
          leftPostiton: 4,
          lastWord: '',
          isVisible: false,
          itemsIds: [],
          selected: null,
          items: [],
          lastFilter: '',
          showExcludeButton: false,
          match: null,
          currentExcludeMatch: null,
        });
        this.lastItem = null;
        this.intersection = {
          type: null,
          value: '',
        };
      }
      if (nextProps.isCleared === 'exclude') {
        let tmpItems = [...this.state.items].filter(
          (item) => item.type !== 'exclude',
        );
        this.setState({
          value: '',
          searchResults: [],
          leftPostiton: 4,
          lastWord: '',
          isVisible: false,
          itemsIds: [],
          selected: null,
          items: tmpItems,
          lastFilter: '',
          showExcludeButton: false,
          match: null,
          currentExcludeMatch: null,
        });
        this.lastItem = null;
        this.intersection = {
          type: null,
          value: '',
        };
        let htmlString = `${tmpItems
          .map(
            (item) =>
              `<div class="smart-filter-item ${item.type ? item.type : ''}">` +
              item.title +
              ' </div>',
          )
          .join('')}`;
        this.setState({
          html: (tmpItems.length > 0 && media("isMobile"))
            ? htmlString
            : `<div class="contenteditable-placeholder">${this.props.placholder? this.props.placholder : "ЖК, метро, район, застройщик"}</div>`,
        });
      }
      if (nextProps.isCleared === 'filter') {
        let tmpItems = [...this.state.items].filter(
          (item) => item.type !== 'filter',
        );
        this.setState({
          value: '',
          searchResults: [],
          leftPostiton: 4,
          lastWord: '',
          isVisible: false,
          itemsIds: [],
          selected: null,
          items: tmpItems,
          lastFilter: '',
          showExcludeButton: false,
          match: null,
          currentExcludeMatch: null,
        });
        this.lastItem = null;
        this.intersection = {
          type: null,
          value: '',
        };
        let htmlString = `${tmpItems
          .map(
            (item) =>
              `<div class="smart-filter-item ${item.type ? item.type : ''}">` +
              item.title +
              ' </div>',
          )
          .join('')}`;
        this.setState({
          html: (tmpItems.length > 0 && media("isMobile"))
            ? htmlString
            : `<div class="contenteditable-placeholder">${this.props.placholder? this.props.placholder : "ЖК, метро, район, застройщик"}</div>`,
        });
      }

      this.props.resetCleared();
    }
  }
  componentDidMount() {
    if (this.editableRef) {
      this.editableRef.current.addEventListener('input', this.handler);
    }
  }
  closeExcludeButton() {
    this.setState({ showExcludeButton: false });
  }
  openExcludeButton() {
    this.setState({ showExcludeButton: true });
  }
  toggleExcludeButton() {
    this.setState((prev) => ({ showExcludeButton: !prev.showExcludeButton }));
  }

  setItems = (id, ident, title, type = 'filter') => {
    let htmlString = `${[
      ...this.state.items,
      {
        id: id,
        identity: ident,
        title: title,
        type: type,
      },
    ]
      .map(
        (item) =>
          `<div class="smart-filter-item ${item.type ? item.type : ''}">` +
          item.title +
          ' </div>',
      )
      .join('')}`;
    this.setState((prev) => ({
      html: htmlString,
    }));
    this.setState((prev) => ({
      items: [
        ...prev.items,
        {
          id: id,
          identity: ident,
          title: title,
          type: type,
        },
      ],
    }));
  };
  setCurrentExcludeMatch(id, ident, title) {
    this.setState({
      currentExcludeMatch: !id
        ? null
        : {
          id: id,
          identity: ident,
          title: title,
          type: 'exclude',
        },
    });
  }
  removeLastItem() {
    let copy = [...this.state.items];
    copy.pop();
    this.setState({
      items: copy,
    });
  }
  clearLast() {
    this.last = '';
  }
  setSelected = (val) => {
    this.setState({ selected: val });
  };
  showSearchResult = (flag) => {
    // console.trace();
    this.setState({ isVisible: flag });
  };
  setAliasesValue = (title, id, identity, lastWord) => {
    this.setState((prevState) => ({
      value: prevState.value.replace(lastWord, ' ' + title) + ' ',
    }));
    // this.setState((prevState) => ({ items: [...prevState.items, title] }));
  };
  getTitle(id, filterData) {
    return filterData.find((v) => v.id == id)?.title;
  }
  associations = {
    rooms: {
      regExp: /(кухня |re\[yz |-|)([0-9]e|[0-9]е|\b[0-9]|cnelbz|cn|студия|ст)(?=\s)(?! эта?ж?)/giu,
      beforeApplyRegExp: /(кухня |re\[yz |-|)([0-9]e|[0-9]е|\b[0-9])(?!.)(?! эта?ж?)|ст(?!.)|cn(?!.)|студия(?!.)|cnelbz(?!.)/giu,
      stop: [
        /re\[yz \b[1-9]{1}[0-9]/gi,
        /кухня \b[1-9]{1}[0-9]/gi,
        /-[1-9]{1}/gi,
      ], // спасибо сафари
      type: 'severalValues',
      filter: 'rooms',
    },
    blockClass: {
      regExp: /бизнес(?= )|эконом(?= )|элит(?= )|комфорт(?= )|,bpytc(?= )|rjvajhn(?= )|'rjyjv(?= )|'kbn(?= )/giu,
      beforeApplyRegExp: /бизнес|эконом|элит|комфорт|,bpytc|rjvajhn|'rjyjv|'kbn/giu,
      type: 'severalValues',
      filter: 'blockClass',
    },
    prices: {
      regExp: /[0-9]{4,}-[0-9]{4,}(?=\s)|[0-9]{4,}(?=\s)/g,
      type: 'pricesRagne',
      filter: 'prices',
    },
    square: {
      regExp: /(кухня |re\[yz |-|)\b[1-9]{1}[0-9]{1,2}(?=\s)(?!эта?ж?)|(кухня |re\[yz |)\b[1-9]{1}[0-9]{0,2}-[1-9]{1}[0-9]{1,2}(?=\s)(?!эта?ж?)/giu, // спасибо сафари
      type: 'squareRagne',
      filter: 'squareTotal',
      stop: [
        /re\[yz \b[1-9]{1}[0-9]/gi,
        /кухня \b[1-9]{1}[0-9]/gi,
        /\b[0-9]-[0-9]{2,}/,
      ], // спасибо сафари
    },
    squareKitchen: {
      regExp: /(кухня |re\[yz )\b[1-9]{1}[0-9]{1,2}(?=\s)(?!эта?ж?)|(кухня |re\[yz )\b[1-9]{1}[0-9]{0,2}-[1-9]{1}[0-9]{1,2}(?=\s)(?!эта?ж?)/giu, // спасибо сафари
      type: 'squareRagne',
      filter: 'squareKitchen',
      replacer: [/re\[yz /, /кухня /], // спасибо сафари
    },
    floors: {
      regExp: /[0-9]{1,2}((?=\sэта?ж? )|(?=\s'nf?;? ))|[0-9]{0,2}-[0-9]{1,2}((?=\sэта?ж? )|(?=\s'nf?;? ))/giu,
      beforeApplyRegExp: /[0-9]{1,2}((?=\sэта?ж?)|(?=\s'nf?;?))|[0-9]{0,2}-[0-9]{1,2}((?=\sэта?ж?)|(?=\s'nf?;?))/giu,
      type: 'floorsRange',
      filter: 'floors',
    },
    payment: {
      regExp: /ипотека(?= )|мат капитал(?= )|военная(?= )(?!ипотека)|военная ипотека(?= )|рассрочка(?= )|bgjntrf(?= )|vfn rfgbnfk(?= )|djtyyfz(?= )(?!bgjntrf)|djtyyfz bgjntrf(?= )|hfcchjxrf(?= )/giu,
      type: 'severalValues',
      filter: 'payment',
    },
    decoration: {
      regExp: /без отделки(?= )|чистовая(?= )|подчистовая(?= )|,tp jnltkrb(?= )|xbcnjdfz(?= )|gjlxbcnjdfz(?= )/giu,
      beforeApplyRegExp: /без отделки|чистовая|подчистовая|,tp jnltkrb|xbcnjdfz|gjlxbcnjdfz/giu,
      type: 'severalValues',
      filter: 'decoration',
    },
    text: {
      regExp: /[',.\]\[;`a-zA-Zа-яА-Я]{3,}/giu,
      type: 'textSearch',
      filter: this.props.textFilters || ['districts', 'subways', 'blocks', 'banks', 'builders', "villages", "address"],
      stop: [
        /^ипо$/gi,
        /^дду$/gi,
        /^пдк$/gi,
        /^мат$/gi,
        /^кап$/gi,
        /^вое$/gi,
        /^рас$/gi,
        /^bgj$/gi,
        /^vfn$/gi,
        /^rfg$/gi,
        /^djt$/gi,
        /^bgj$/gi,
        /^hfc$/gi,
        /^биз$/gi,
        /эко/gi,
        /^эли$/gi,
        /ком/gi,
        /^,bp$/gi,
        /^rjv$/gi,
        /'rj/gi,
        /^'kb$/gi,
        /^'kbn $/gi,
        /^'kbn$/gi,
        /^без$/gi,
        /^под$/gi,
        /^чис$/gi,
        /^,tp$/gi,
        /^xbc$/gi,
        /^gjl$/gi,
      ],
    },
    assignments: {
      regExp: /уступки да(?= )|ecnegrb lf(?= )/giu,
      type: 'assignments',
      filter: 'assignments',
    },
    availability: {
      regExp: /(пешком|транспорт|gtirjv|nhfycgjhn)(?=\s)/giu,
      type: 'availability',
      filter: 'availability',
    },
    isApartments: {
      regExp: /(апарт да|апарт нет|fgfhn lf|fgfhn ytn)(?=\s)/giu,
      type: 'isApartments',
      filter: 'isApartments',
    },
    isOnlyHanded: {
      regExp: /сдан(?=\s)|clfy(?=\s)/giu,
      beforeApplyRegExp: /(сдан|clfy)(?!\s)/giu,
      type: 'isOnlyHanded',
      filter: 'isOnlyHanded',
    },
    registrations: {
      regExp: /город(?=\s)|область(?=\s)|ujhjl(?=\s)|j,kfcnm(?=\s)/giu,
      beforeApplyRegExp: /(город|область|ujhjl|j,kfcnm)(?!\s)/giu,
      type: 'registrations',
      filter: 'registrations',
    },
    endings: {
      regExp: /(?:([1-4]{1})\s?(?:кв|rd)|([1-4]{1})-([1-4]{1}))(?:кв|rd|\s)?(20[0-9]{2})/i,
      type: 'endings',
      filter: 'endings',
    },
  };
  
  specialRules = {
    "л1" : "text",
    "k1" : "text",
    "б15": "text",
    "б1": "text",
    ",15": "text",
    ",1": "text",
    "а101": "text",
    "а1": "text",
    "f101": "text",
    "f1": "text"
  }
  handleFilter = this.props.handleFilter;
  removeAllTextNodes(target) {
    target.childNodes.forEach((node) => {
      if (node.nodeName === '#text') {
        node.remove();
      }
    });
  }
  moveCursor() {
    let range = document.createRange();
    let sellection = window.getSelection();
    range.setStart(
      this.editableRef.current.lastChild,
      this.editableRef.current.lastChild.length,
    );
    range.collapse(true);
    sellection.removeAllRanges();
    sellection.addRange(range);
  }
  removeEmptyTextNodes(target) {
    if (!target) {
      return;
    }
    target.childNodes.forEach((node) => {
      if (node.nodeName === '#text' && node.nodeValue == '') {
        node.remove();
      }
    });
  }
  changeLastItem(newItem) {
    if (this.state.items.length === 0) {
      return;
    }

    let tmp = [...this.state.items];
    let last = tmp.splice(-1, 1, newItem);
    // if(!intersections[newItem.identity].includes(last.identity)){
    //   return
    // }
    this.setState({ items: tmp });
    this.closeExcludeButton();
    this.setCurrentExcludeMatch(null);
    this.clearLast();
    this.showSearchResult(false);
    this.moveCursor();
    this.setIntersection(false);
  }
  checkIntersection() {
    const { items } = this.state;
    if (items.length === 0) {
      return false;
    }
    let tmp = { ...items[items.length - 1] };
    return tmp.identity === this.intersection.type;
  }
  setIntersection = (type, value, filter) => {
    this.intersection = {
      type: type ? type : null,
      value: type ? value : '',
      filter,
    };
  };
  handleStop(matches, current) {
    if (!current.stop || !matches) {
      return false;
    }
    let result = matches.filter((match) => {
      return !current.stop.some((regExp) => regExp.test(match));
    });
    if (result.length > 0) {
      return result;
    }
    return null;
  }
  evaluateFilterFromItems(items) {
    let tempFilter = _.cloneDeep({
      ...defaultFullSmartFilter,
      filter: this.props.filterValues,
      exclude: this.props.excludeValues,
    });
    if (this.lastItem) {
      let lst = this.lastItem;
      tempFilter[lst.type][lst.identity] =
      defaultFullSmartFilter[lst.type][lst.identity];
    }
    let textFilters = this.associations.text.filter;
    let textItems = [];
    items.map((item) => {
      if (textFilters.includes(item.identity)) {
        textItems.push(item);
        return;
      }
      tempFilter[item.type][item.identity] = item.id;
    });
    //каеф):
    textItems = textItems.reduce(
      (acc, current) => {
        let type = current.type;
        let ident = current.identity;
        return {
          ...acc,
          [type]: {
            ...acc[type],
            [ident]: [
              ...(acc[type][ident] ? acc[type][ident] : []),
              current.id,
            ],
          },
        };
      },
      { filter: [], exclude: [] },
    );

    Object.keys(textItems).map((type) => {
      Object.keys(textItems[type]).map((ident) => {
        tempFilter[type][ident] = textItems[type][ident];
      });
    });
    this.props.setFilter(tempFilter);
    this.props.getCount(tempFilter);
  }
  handler = (e) => {
    // this.removeEmptyTextNodes(e.srcElement)

    let lastLength = this.last.length;
    if (e.data && e.inputType !== 'insertCompositionText') {
      this.last += e.data;
    }
    if (e.inputType === 'insertCompositionText') {
      this.last = e.data
    }
    if (e.inputType === 'deleteContentBackward') {
      this.last =
        this.last.length === 1 ? '' : this.last.slice(0, this.last.length - 1);
    }

    let val = this.last;
    console.log(`%c Выполняется поиск src/components/FilterRealty/SmartFilter.js 515:`, "color: #2CBA2E", val);
    console.log(`%c Выполняется поиск https://gitlab.com/pan-partner/frontend/-/raw/master/src/components/FilterRealty/SmartFilter/index.js 516:`, "color: #2CBA2E", val);
    if (
      e.inputType === 'deleteContentBackward' &&
      lastLength === 0 &&
      this.state.items.length != 0
    ) {
      const windowSelection = window.getSelection();
      const textNodeParent = windowSelection.focusNode.parentElement // это див filterItem на котором стоит курсор
      const allNodes = windowSelection.focusNode.parentElement.parentElement.children // а это все потомки нашего contenteditable -  input__control smart-filter
      const childPositionIndex = Array.prototype.findIndex.call(allNodes, item=> textNodeParent === item) // ищем по ссылке
      const childPosition = childPositionIndex !== -1? childPositionIndex : this.state.items.length - 1;
      if (this.state.items.length >= 1) {
        this.lastItem = this.state.items[childPosition];
      } else {
        this.lastItem = null;
      }
      let tmpItems = [...this.state.items];
      tmpItems.splice(childPosition, 1);
      this.setState({ items: tmpItems });
      this.evaluateFilterFromItems(tmpItems);
    }
    let tempFilter = _.cloneDeep({
      ...defaultFullSmartFilter,
      filter: this.props.filterValues,
      exclude: this.props.excludeValues,
    });

    this.closeExcludeButton();
    this.setCurrentExcludeMatch(null);
    let searchResult = [];
    Object.keys(this.associations).map((key) => {
      let current = this.associations[key];
      let isIntersect = intersections[current.filter]?.includes(
        this.intersection.type,
      );
      let matches = isIntersect
        ? `${this.intersection.value} ${val}`
          .replace('  ', ' ')
          .match(current.regExp)
        : val.match(current.regExp);
      let matchesForExclude = current.beforeApplyRegExp
        ? val.match(current.beforeApplyRegExp)
        : null;
      if (current.stop) {
        matches = this.handleStop(matches, current);
        matchesForExclude = this.handleStop(matchesForExclude, current);
      }
      Object.entries(this.specialRules).map (
        ([key, type])=>{
          if(val.toLowerCase() === key || val.replace(/ /gi, "") === key){
            current = this.associations[type]
            matches = [val]
          }
        }
      )
      if (!matches && current.type === 'textSearch') {
        this.setState({ searchResults: [] });
        this.showSearchResult(false);
        return;
      }
      if (!matches && !matchesForExclude) {
        return;
      }

      let currentFilter = Array.isArray(current.filter)
        ? current.filter.map((type) => ({
          type: this.props.filterValues[type],
        }))
        : this.props.filterValues[current.filter];
      let currentFilterData = Array.isArray(current.filter)
        ? current.filter.map((type) => {
          if(!this.props.filterData[type]) return null
          return {
            [type]: this.props.filterData[type].map((item) => ({
              id: item['id'],
              title: item['title'],
              ...(item['color'] ? { color: item['color'] } : []),
              ...(item['aliases'] ? { aliases: item['aliases'] } : []),
            })),
          };
        })
        : this.props.filterData[current.filter];
        currentFilterData = _.compact(currentFilterData)
      switch (current.type) {
        case 'severalValues':
          if (matchesForExclude) {
            let id = filterByKey[key][
              matchesForExclude[0].toLowerCase()
            ]?.toString();

            if (id) {
              this.setCurrentExcludeMatch(
                id,
                key,
                this.getTitle(id, currentFilterData),
              );
              this.openExcludeButton();
            }
          }

          if (matches) {
            if (current.filter === 'rooms') {
              this.setIntersection(current.filter, val, currentFilter);
            }
            let tempSeveral = [...currentFilter];
            matches.map((item) => {
              if (filterByKey[key][item.toLowerCase()]) {
                tempSeveral.push(
                  filterByKey[key][item.toLowerCase()].toString(),
                );
                this.setItems(_.uniq(tempSeveral), current.filter, val);
                this.closeExcludeButton();
                this.clearLast();
                val = '';
                e.target.lastChild.nodeValue = ' ';
              }
            });

            tempFilter.filter[current.filter] = _.uniq(tempSeveral);
          }
          break;
        case 'pricesRagne':
          matches = matches.join('');
          matches = matches.split('-');
          matches = matches.map((match) => {
            let isCash = match > 200000;
            return isCash ? match : match * 1000;
          });
          let minPrice = matches.length > 1 ? _.min(matches) : false;
          let maxPrice = matches.length > 1 ? _.max(matches) : matches[0];
          tempFilter.filter.prices = {
            ...currentFilter,
            ...(minPrice ? { min: minPrice.toString() } : []),
            max: maxPrice.toString(),
          };
          this.setItems(tempFilter.filter.prices, current.filter, val);
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;
        case 'squareRagne':
          this.setIntersection(current.filter, val, currentFilter);

          matches = matches.join('');
          if (current.filter === 'squareKitchen') {
            current.replacer.forEach(
              (repl) => (matches = matches.replace(repl, '')),
            );
          }
          matches = matches.split('-');
          let minSquare = matches.length > 1 ? _.min(matches) : matches[0];
          let maxSquare = matches.length > 1 ? _.max(matches) : false;
          this.setItems(
            {
              ...currentFilter,
              ...(maxSquare ? { max: maxSquare.toString() } : []),
              min: minSquare.toString(),
            },
            current.filter,
            val,
          );
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          tempFilter.filter[current.filter] = {
            ...currentFilter,
            ...(maxSquare ? { max: maxSquare.toString() } : []),
            min: minSquare.toString(),
          };
          break;
        case 'floorsRange':
          if (!matches) {
            return;
          }
          matches = matches.map((match) => match.split('-')).flat();
          let minfloors =
            matches.length > 1
              ? _.minBy(matches, (val) => Number.parseInt(val))
              : matches[0];
          let maxfloors =
            matches.length > 1
              ? _.maxBy(matches, (val) => Number.parseInt(val))
              : false;
          let tmp = `${minfloors && maxfloors ? minfloors + '-' : minfloors}${maxfloors ? maxfloors : ''
            } этаж`;
          if (!isIntersect || !this.checkIntersection()) {
            this.setItems(
              {
                ...currentFilter,
                ...(maxfloors ? { max: maxfloors.toString() } : []),
                min: minfloors.toString(),
              },
              'floors',
              val,
            );
          } else {
            tempFilter.filter[
              this.intersection.type
            ] = this.intersection.filter;
            this.changeLastItem({
              id: tmp,
              identity: 'floors',
              title: tmp,
              type: 'filter',
            });
          }
          tempFilter.filter[current.filter] = {
            ...currentFilter,
            ...(maxfloors ? { max: maxfloors.toString() } : []),
            min: minfloors.toString(),
          };

          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;
        case 'textSearch':
          this.setState({ itemsIds: [] });
          matches.forEach((match) => {
            searchResult = currentFilterData
              .map((filterData) => {
                return Object.keys(filterData).map((identity) => {
                  return {
                    [identity]: filterData[identity].filter((item) => {
                      let condition = searchRuSymbols(
                        item.title.toLowerCase().replace(/ё/g, 'е'),
                      ).includes(
                        searchRuSymbols(match.toLowerCase().replace(/ё/g, 'е')),
                      );
                      let aliasesSearch = null;
                      if (item.aliases) {
                        aliasesSearch = item.aliases.some(
                          (alias) =>
                            alias
                              .toLowerCase()
                              .includes(
                                match.toLowerCase().replace(/ё/g, 'е'),
                              ) ||
                            alias
                              .toLowerCase()
                              .includes(
                                searchRuSymbols(
                                  match.toLowerCase().replace(/ё/g, 'е'),
                                ),
                              ),
                        );
                      }
                      if (condition || aliasesSearch) {
                        this.setState((prev) => ({
                          itemsIds: [
                            ...prev.itemsIds,
                            {
                              id: item.id,
                              identity: identity,
                              title: item.title,
                            },
                          ],
                        }));
                      }
                      return (
                        (condition &&
                          !this.state.searchResults.includes(item.title)) ||
                        aliasesSearch
                      );
                      // &&
                      // !searchResults.includes(item.title) &&
                      // !value.includes(item.title)
                    }),
                  };
                });
              })
              .flat();
            // searchResult.map((type) => {
            //   return Object.keys(type).map((identity) => {
            //     return type[identity].map((item, i) => {
            //       setItemsIds((prev) => [
            //         ...prev,
            //         { id: item.id, identity: identity, title: item.title },
            //       ]);
            //     });
            //   });
            // });
            this.setState({ searchResults: searchResult });
          });
          break;
        case 'assignments':
          tempFilter.filter.assignments = ['7'];
          this.setItems(['7'], 'assignments', 'уступки да');
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;
        case 'availability':
          matches = matches
            .filter((match) => filterByKey['availability'][match])
            .map((match) => filterByKey['availability'][match]);
          if (matches.length === 0) {
            return;
          }

          tempFilter.filter[current.filter] = [...currentFilter, ...matches];
          this.setItems(tempFilter.filter[current.filter], 'availability', val);
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;
        case 'isApartments':
          matches = matches
            .filter((match) => filterByKey['isApartments'][match])
            .map((match) => filterByKey['isApartments'][match]);
          if (matches.length === 0) {
            return;
          }

          tempFilter.filter[current.filter] = [{ id: matches[0] }];
          this.setItems(tempFilter.filter[current.filter], 'isApartments', val);
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;

        case 'isOnlyHanded':
          this.setCurrentExcludeMatch('Y', 'isOnlyHanded', `сданные дома: да`);
          this.openExcludeButton();
          if (matches) {
            tempFilter.filter[current.filter] = [{ id: 'Y' }];
            this.setItems(
              tempFilter.filter[current.filter],
              'isOnlyHanded',
              val,
            );
            this.clearLast();
            this.closeExcludeButton();
            val = '';
            e.target.lastChild.nodeValue = ' ';
          }
          break;
        case 'registrations':
          if (matchesForExclude) {
            let id = filterByKey['registrations_' + this.props.city][
              matchesForExclude[0].toLowerCase()
            ]?.toString();

            if (id) {
              this.setCurrentExcludeMatch(
                id,
                key,
                this.getTitle(id, currentFilterData),
              );
              this.openExcludeButton();
            }
          }
          if (!matches) {
            return;
          }
          matches = matches
            .filter(
              (match) => filterByKey['registrations_' + this.props.city][match],
            )
            .map(
              (match) => filterByKey['registrations_' + this.props.city][match],
            );
          if (matches.length === 0) {
            return;
          }

          tempFilter.filter[current.filter] = [...currentFilter, ...matches];
          this.setItems(
            tempFilter.filter[current.filter],
            'registrations',
            val,
          );
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;
        case 'endings':
          let singleQuarter = matches[1] ? matches[4] + matches[1] : null
          let firstQuarter = matches[2] ? matches[4] + matches[2] : null
          let secondQuarter = matches[3] ? matches[4] + matches[3] : null
          let quarterTo = _.max([singleQuarter, firstQuarter, secondQuarter, currentFilter?.min, currentFilter?.max])
          let quarterFrom = _.min([firstQuarter, secondQuarter])
          let tmpEndings = { ...currentFilter }
          if (quarterFrom) {
            tmpEndings.min = quarterFrom
          }
          tmpEndings.max = quarterTo

          if (!isIntersect || !this.checkIntersection()) {

            this.setItems(tmpEndings, current.filter, val);
          } else {
            tempFilter.filter[
              this.intersection.type
            ] = this.intersection.filter;
            this.changeLastItem({
              id: matches[0],
              identity: current.filter,
              title: matches[0],
              type: 'filter',
            });
          }
          tempFilter.filter.endings = tmpEndings;
          this.clearLast();
          val = '';
          e.target.lastChild.nodeValue = ' ';
          break;
        default:
          return
      }
    });
    this.props.setFilter(tempFilter);
    this.props.getCount(tempFilter);
    this.setState({ value: val });
    let htmlString = `${this.state.items
      .map(
        (item) =>
          `<div class="smart-filter-item ${item.type ? item.type : ''}">` +
          item.title +
          ' </div>',
      )
      .join('')}${val}`;
    this.setState((prev) => ({
      html: htmlString,
    }));
    let matchLastWord = val;
    if (
      !!matchLastWord &&
      searchResult.some((obj) => Object.values(obj).flat().length > 0)
    ) {
      this.showSearchResult(true);
    } else {
      this.setState({ searchResults: [] });
      this.showSearchResult(false);
    }
    searchResult = [];
    this.setState({
      lastWord: matchLastWord ? matchLastWord[0].replace(/\s/g, '') : '',
    });
    let wordsWidth = this.fakeInputRef.current.getBoundingClientRect().width;
    let lastWordWidth = this.lastWordRef.current.getBoundingClientRect().width;
    this.setState({ leftPostiton: wordsWidth - lastWordWidth });
    console.log(`%c Поиск завершен src/components/FilterRealty/SmartFilter.js 952:`, "color: #2CBA2E", this.state.searchResults);
    console.log(`%c Выполняется поиск https://gitlab.com/pan-partner/frontend/-/raw/master/src/components/FilterRealty/SmartFilter/index.js 953:`, "color: #2CBA2E", val);
  };
  keyDownHandler = (e) => {
    let key = e.which;
    let itLenCond = this.state.itemsIds.length !== 0;
    if (key === 38 && itLenCond) {
      // стрелочка вверх
      e.preventDefault();
      if (this.state.selected) {
        let prevIndex = this.state.itemsIds.findIndex(
          (item) => item.id == this.state.selected.id,
        );
        prevIndex === 0
          ? this.setSelected(
            this.state.itemsIds[this.state.itemsIds.length - 1],
          )
          : this.setSelected(this.state.itemsIds[prevIndex - 1]);
      } else {
        this.setSelected(this.state.itemsIds[this.state.itemsIds.length - 1]);
      }
    }
    if (key === 40 && itLenCond) {
      // стрелка вниз
      e.preventDefault();
      if (this.state.selected) {
        let prevIndex = this.state.itemsIds.findIndex(
          (item) => item.id == this.state.selected.id,
        );
        prevIndex === this.state.itemsIds.length - 1
          ? this.setSelected(this.state.itemsIds[0])
          : this.setSelected(this.state.itemsIds[prevIndex + 1]);
      } else {
        this.setSelected(this.state.itemsIds[0]);
      }
    }

    if (key === 13 && this.state.isVisible && itLenCond) {
      //enter
      e.preventDefault();
      if (this.state.selected) {
        let tempFilter = _.cloneDeep({
          ...defaultFullSmartFilter,
          filter: this.props.filterValues,
          exclude: this.props.excludeValues,
        });
        tempFilter.filter[this.state.selected.identity] = _.uniqBy(
          [
            ...this.props.filterValues[this.state.selected.identity],
            this.state.selected.id.toString(),
          ],
          (id) => id.toString(),
        );
        this.props.setFilter(tempFilter);
        this.props.getCount(tempFilter);

        let item = {
          id: this.state.selected.id,
          identity: this.state.selected.identity,
          title: this.state.selected.title,
          type: 'filter',
        };
        let items = [...this.state.items, item];
        this.setState((prev) => ({ items: [...prev.items, item] }));
        this.showSearchResult(false);
        let htmlString = `${items
          .map(
            (item) =>
              `<div class="smart-filter-item ${item.type ? item.type : ''}">` +
              item.title +
              ' </div>',
          )
          .join('')}`;

        this.setState((prev) => ({
          html: htmlString,
          itemsIds: [],
        }));
        this.clearLast();
        // this.removeAllTextNodes(e.target)
        this.setAliasesValue(
          this.state.selected.title,
          this.state.selected.id,
          this.state.selected.identity,
          this.state.lastWord,
        );
        this.setSelected(null);
      }
    }
    if (key === 13 && !this.state.isVisible) {
      this.props.submit(false);
    }
    //backspace:
    if (key === 8 && this.state.isVisible) {
    }
    //стрелка вправо
    if (key === 39 && this.state.isVisible && this.state.selected) {
      this.excludeHandler({
        identity: this.state.selected.identity,
        id: this.state.selected.id,
        title: this.state.selected.title,
      });
    }
  };
  excludeHandler = (data) => {
    if (!data && !this.state.currentExcludeMatch) {
      return;
    }
    const identity = data
      ? data.identity
      : this.state.currentExcludeMatch.identity;
    const id = data ? data.id : this.state.currentExcludeMatch.id;
    const title = data ? data.title : this.state.currentExcludeMatch.title;
    let tempFilter = _.cloneDeep({
      ...defaultFullSmartFilter,
      filter: this.props.filterValues,
      exclude: this.props.excludeValues,
    });

    if (identity === 'isOnlyHanded') {
      tempFilter.exclude[identity] = [{ id: id.toString() }];
    } else {
      tempFilter.exclude[identity] = _.uniqBy(
        [...this.props.excludeValues[identity], id.toString()],
        (id) => id.toString(),
      );
    }

    this.props.setFilter(tempFilter);
    this.props.getCount(tempFilter);
    this.setItems(id, identity, title, 'exclude');
    this.closeExcludeButton();
    this.setCurrentExcludeMatch(null);
    this.clearLast();
    this.showSearchResult(false);
    this.moveCursor();
  };

  handleChange = (evt) => {
    this.setState({
      html: `<div>${this.state.items
        .map((item) => '<div>' + item + '</div>')
        .join('')}</div>`,
    });
  };
  searchResultHandler = (
    title,
    id,
    identity,
    lastWord,
    filterType = 'filter',
  ) => {
    this.setAliasesValue(title, id, identity, lastWord);
    this.setItems(id, identity, title);
    this.showSearchResult(false);
    let excludeOrFilter =
      filterType === 'filter'
        ? this.props.filterValues
        : this.props.excludeValues;
    this.handleFilter(
      {
        [filterType]: _.uniqBy(
          [...excludeOrFilter[identity], id.toString()],
          (id) => id.toString(),
        ),
      },
      identity,
    );
    this.clearLast();
    this.moveCursor();
    this.setState({ itemsIds: [] });
  };
  focusHandler = () => {
    !media("isMobile") && this.props.toggleFilter(false);
    if (this.state.value.length === 0 && this.state.items.length === 0) {
      this.setState({ html: '' });
    }
  };
  showPlaceholder = () => {
    if (this.state.value.length === 0 && this.state.items.length === 0) {
      this.setState({ html: `${this.props.placholder? this.props.placholder : "ЖК, метро, район, застройщик"}` });
    }
  };
  render() {
    const { showFilter } = this.props;
    const { showExcludeButton } = this.state;
    return (
      <div
        className="form__item form__item_2x form__item_main"
        style={{
          ...(showFilter ? [] : { flex: 1, marginBottom: 0 }),
        }}
      >
        <div className="index-search__search">
          <label
            className="input index-search__search-input for-smart-filter"
            htmlFor="index-search"
          >
            {/* <input
              className="input__control"
              type="text"
              id="index-search"
              placeholder="Поиск по 1 234 вариантам жилья"
              autoComplete="off"
              onChange={this.handler}
              value={this.state.value}
              name="country"
              onKeyDown={this.keyDownHandler}
              onFocus={() => toggleFilter(false)}
            /> */}
            <pre style={{ width: '100%', minHeight: '20px' }}>
              <ContentEditable
                className="input__control smart-filter"
                html={this.state.html}
                // style={{ display: 'flex' }}
                // onChange={this.handler}
                innerRef={this.editableRef}
                onKeyDown={this.keyDownHandler}
                onFocus={this.focusHandler}
                onBlur={this.showPlaceholder}
              />
            </pre>
            <span
              style={{
                visibility: 'hidden',
                padding: '2px',
                position: 'absolute',
              }}
              ref={this.fakeInputRef}
            >
              {this.state.value}
            </span>
            <span
              style={{
                visibility: 'hidden',
                padding: '2px',
                position: 'absolute',
              }}
              ref={this.lastWordRef}
            >
              {this.state.lastWord}
            </span>
            {/*<svg className="icon icon_search">*/}
            {/*  <use xlinkHref="#search"></use>*/}
            {/*</svg>*/}
            <svg className="just-new-icon-search" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M10.6919 17.1657C14.0056 17.1657 16.6919 14.4004 16.6919 10.9892C16.6919 7.57801 14.0056 4.81271 10.6919 4.81271C7.37819 4.81271 4.69189 7.57801 4.69189 10.9892C4.69189 14.4004 7.37819 17.1657 10.6919 17.1657Z" stroke="#2C2E3E"/>
              <path d="M15.3081 15.0696L19.1919 18.7098" stroke="#2C2E3E" strokeLinecap="square"/>
            </svg>

            <Exclude
              showExcludeButton={showExcludeButton}
              left={this.state.leftPostiton}
              currentExcludeMatch={this.state.currentExcludeMatch}
              onClick={this.excludeHandler}
            />
            <SearchResult
              results={this.state.searchResults}
              left={this.state.leftPostiton}
              isVisible={this.state.isVisible}
              value={this.state.value}
              lastWord={this.state.lastWord}
              selected={this.state.selected}
              searchResultHandler={this.searchResultHandler}
              excludeHandler={this.excludeHandler}
            />
          </label>
          {!this.props.isMobile &&
            <div className="info">
              <svg className="icon icon_info">
                <use xlinkHref="#info"></use>
              </svg>
              <div className="info__popup">
                <p>
                  Начинайте вводить параметры фильтра и&nbsp;выбирайте
                  из&nbsp;предложенного списка. Вы&nbsp;также можете любой запрос
                  добавить в&nbsp;исключения
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default SmartFilter;
