import React, {useEffect} from "react";
import Nottifications from "./index";
import useClickPast from "../../../hooks/useClickPast";

const NottificationContainer = ({isOpen, setIsOpen}) => {

  useEffect(() => {
    isOpen ? window.BX?.SiteButton.hide() : window.BX?.SiteButton.show()
  }, [isOpen])

  const nottificationsRef = useClickPast(null, ({target}, {current}) => {
    if (current?.contains?.(target) || target?.closest?.('.nottifications-container')) return
    setIsOpen(false)
  })
  return (
    <div className={"nottifications-container" + (isOpen ? ' is-showing' : '')} ref={nottificationsRef}>
      {isOpen && <Nottifications isOpen={isOpen} setIsOpen={setIsOpen}/>}
    </div>
  )
}

export default NottificationContainer
