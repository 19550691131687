import React from "react";
import { getUrl } from '../../../api'

const Banner = (props) => {
  const { socials } = props

  return (
    <div className="constructor c-banner">

      <div className="c-banner__content">

        <p className="c-banner__text">{`Всегда на связи удобным для Вас способом!`}</p>

        <ul className="constructor__socials constructor__socials_banner">
          <li className={`c-banner__circle ig`}>
            <svg className="icon icon_social-ig">
                <use xlinkHref="#social-ig" />
            </svg>
          </li>

          <li className={`c-banner__circle vk`}>
            <svg className="icon icon_social-vk">
                <use xlinkHref="#social-vk" />
            </svg>
          </li>

          <li className={`c-banner__circle wa`}>
            <svg className="icon icon_social-wa">
                <use xlinkHref="#social-wa" />
            </svg>
          </li>
        </ul>

        <div className="c-banner__img">
          <svg xmlns="http://www.w3.org/2000/svg" width="572" height="224" viewBox="0 0 572 224" fill="none">
            <path opacity="0.15" d="M107.119 401.699L528.568 187.167C540.275 181.208 542.552 168.243 537.805 158.852L393.02 -127.546C387.086 -139.283 374.16 -141.558 364.794 -136.791L-56.6549 77.7402C-66.0204 82.5075 -70.6389 96.665 -65.8919 106.055L78.8936 392.453C84.8275 404.191 97.7533 406.466 107.119 401.699Z" />
            <path opacity="0.25" d="M253.694 155.894L385.806 -132.022C385.806 -132.022 365.098 -136.945 355.429 -132.022L-44.9482 71.7821C-60.2175 79.1769 -66.3254 95.7885 -66.3254 95.7885L253.694 155.894Z" />
          </svg>
        </div>
      </div>

      <div className="c-preview__note">
        <p>
          <span role="img" aria-label="flag">🚩</span>
          &nbsp;
          Добавьте на сайт блок с вашими мессенджерами и ссылками на социальные сети, чтобы упростить процесс коммуникации с вашими клиентами
        </p>
      </div>
    </div>
  )
};

export default Banner;
