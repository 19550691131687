import { useMediaQuery } from "beautiful-react-hooks";
import React, { useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { getUrl } from "../../api";
import { media } from "../../utils";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import ErrorPlug from '../ErrorBoundry/ErrorPlug';
import BankInfo from "./BankInfo";
SwiperCore.use([Navigation]);

const Bank = (props) => {
  const {
    name,
    logo,
    maxPeriod,
    minInitialPayment,
    minPercent,
    ...bankInfo
  } = props;

  const isMobile = useMediaQuery("(max-width: 767px)");

  const [openBankInfo, closeBankInfo] = useModal(({ in: open, onExited }) => (
    <Modal close={closeBankInfo} classes="modal_task modal-banks">
      <BankInfo {...bankInfo} />
    </Modal>
  ));

  const [openModalBankInfo, closeModalBankInfo] = useModal(({ in: open, onExited }) => (
    <MobileModal closeClick={closeModalBankInfo} title="Аккредитация">
      <BankInfo {...bankInfo} />
    </MobileModal>
  ));

  return (
    <div className="banks__slider-item">
      <div className="banks__slider-inner">
        <div className="banks__slider-image">
          <img src={getUrl(logo.src)} alt={name} />
        </div>
        <div className="banks__slider-content">
          <div className="banks__slider-conditions">
            <div className="banks__slider-condition">Процентная ставка</div>
            <div className="banks__slider-value">от {minPercent}%</div>
          </div>
          <div className="banks__slider-conditions">
            <div className="banks__slider-condition">Первоначальный взноc</div>
            <div className="banks__slider-value">от {minInitialPayment}%</div>
          </div>
          <div className="banks__slider-conditions">
            <div className="banks__slider-condition">Срок ипотеки</div>
            <div className="banks__slider-value">до {maxPeriod} лет</div>
          </div>
          <div className="banks__slider-more">
            {" "}
            <span
              className="banks__slider-link js-modal-btn"
              onClick={isMobile ? openModalBankInfo : openBankInfo}
            >
              Подробнее
              <svg className="icon icon_more">
                <use xlinkHref="#chevron"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Banks = (props) => {
  const { items } = props;

  const navRef = useRef(null);
  const responsive1024 = useMediaQuery("(min-width: 1024px)");
  const responsive768 = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const responsiveOther = useMediaQuery("(max-width: 767px)");

  const node = useRef(null);
  const getMaxCount = (width) => {
    let len = items.length;
    if (width === 768) {
      return len === 1 ? 1 : 2;
    }
    if (width === 1024) {
      return len < 3 ? len : 3;
    }
  };

  const [index, setIndex] = useState(1);

  const handleChangeIndex = (info) => {
    setIndex(info.index + 1);
  };

  const settings = {
    items: 1,
    nav: !media("isMobile") ? false : true,
    loop: false,
    controls: false,
    navAsThumbnails: media("isMobile") ? false : true,
    navContainer: `#complex-slider-thumbnails`,
    responsive: {
      768: {
        items: getMaxCount(768),
        gutter: 2,
      },
      1024: {
        items: getMaxCount(1024),
        gutter: 2,
      },
    },
  };

  const settings2 = useMemo(
    () =>
      navRef
        ? {
          items: 1,
          nav: !media("isMobile") ? false : true,
          loop: media("isMobile") ? false : true,
          controls: false,
          navAsThumbnails: media("isMobile") && false,
          navContainer: media("isMobile") ? `#complex-slider-thumbnails` : false,
          responsive: {
            600: {
              items: 2,
              gutter: 2,
            },
            768: {
              items: 2,
              gutter: 2,
            },
            1024: {
              items: getMaxCount(1024),
              gutter: 2,
            },
            1600: {
              items: 4,
              gutter: 2,
            },
          },
        }
        : {},
    [navRef]
  );

  const moveSlider = (way) => {
    if (node.current && node.current.slider) {
      node.current.slider.goTo(way);
    }
  };

  const showNavigation = useMemo(() => {
    const count = items.length;
    if (count > 3 && responsive1024) {
      return true;
    } else if (count > 2 && responsive768) {
      return true;
    } else if (count > 1 && responsiveOther) {
      return true;
    }

    return false;
  }, [responsive1024, responsive768, responsiveOther, items]);

  return (
    <ErrorPlug wrappedComponent="comp-ts/Apartment/Banks.js - с новым слайдером">
    <section className="section section_view_white flat__banks">
      <div className="wrapper">
        <div className="wrapper__center">
          <div className="banks__slider flat__banks">
            {!media("isMobile") && showNavigation && (
              <div className="banks__slider-nav">
                <ul className="banks__slider-nav_wrap" id="banksSlider-nav">
                  <li
                    className="slider__nav-item prev banks-prev"
                    // onClick={() => moveSlider("prev")}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-left_arrow"></use>
                    </svg>
                  </li>
                  <li
                    className="slider__nav-item next banks-next"
                    // onClick={() => moveSlider("next")}
                  >
                    <svg className="icon icon-dark_stroke">
                      <use xlinkHref="#icon-right_arrow"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            )}

            <div className="banks__slider-wrap" id="banksSlider">
            <Swiper
              navigation={{
                nextEl: '.banks-next',
                prevEl: '.banks-prev'
              }}
              spaceBetween={0}
              slidesPerView={1}
              setWrapperSize={true}
              slidesPerGroup={1}
              breakpoints={{
                767: {
                  slidesPerView: 3,

                },
                1279: {
                  slidesPerView: 4,

                },
              }}
            >
                {items && items.map((item) => <SwiperSlide key={"bank-" + item.id}><Bank {...item} /></SwiperSlide>)}
                </Swiper>

              {media("isMobile") &&
                <>
                  <ul
                    className="complex__slider-thumbnails"
                    id="complex-slider-thumbnails"
                    ref={navRef}
                  >
                    {items.map((item, index) => (
                      <li className="complex__slider-thumbnails_item" key={index}>
                        <span aria-controls="base"></span>
                      </li>
                    ))}
                  </ul>

                  <span className="complex__slider-info">
                    {index} / {items.length}
                  </span>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
    </ErrorPlug>
  );
};

export default Banks;
