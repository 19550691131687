const CrossIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L4 4L7 1" stroke="#8F95AC" strokeLinecap="round" />
    <path d="M1 7L4 4L7 7" stroke="#8F95AC" strokeLinecap="round" />
  </svg>
);

export default CrossIcon;