import React from "react";

const BgLeft2 = (props) => {
  return (
    <div className="background__left">
    <svg viewBox="0 0 249 589" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
    <ellipse cx="-43.0186" cy="280.478" rx="210.583" ry="150.843" transform="rotate(130.306 -43.0186 280.478)" className="background__fill-main" />
    </g>
    <defs>
    <filter id="filter0_d" x="-334.271" y="-7.68817" width="582.506" height="596.332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="10"/>
    <feGaussianBlur stdDeviation="20"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.579288 0 0 0 0 0.523998 0 0 0 0 0.800449 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>
    </div>
  )
};

export default BgLeft2;
