import compact from 'lodash/compact';
import React, { useEffect, useState } from "react";
import { getUrl } from '../../../api';
import useMedia from '../../../hooks/useMedia';
import { getUrlWithProtocol } from '../../../utils/location';
import { icons } from '../../../utils/special';

import {Bg4Svg} from '../Background/BgRight4';



const Promo = (props) => {
  const { agent, theme, agencyName, openCallbackModal, promo, multiLink, site } = props;
  const isMobile = useMedia("isMobile");

  const getImage = () => {
    if (agent.photo) {
      return agent?.photo?.src ? getUrl(agent?.photo?.src) : agent?.photo
    }
    return require('../../../assets/images/constructor/photo-upload.svg').default
  }

  const [isOneSocial, setIsOneSocial] = useState(false);

  useEffect(() => {
    if (compact(promo.map(i => i.active ? i : null)).length === 1) {
      setIsOneSocial(true)
    }
  }, [promo])

  return (
    <section className={`promo for-theme-${theme?.style} ${multiLink ? ` promo_no-mb` : ``}`}>
      {/* <section className={`promo for-theme-5`}> */}
      <div className="constructor__container">

        <div className="constructor__box">
          <div className="promo__wrapper constructor__wrapper">
            <div className="promo__info">
              <span className="promo__label text">{agencyName}</span>
              <p className="h1 promo__text" dangerouslySetInnerHTML={{
                __html: theme?.header?.replace(/\n/g, "<br />"),
              }}></p>
              <div className="promo__sign big-text" dangerouslySetInnerHTML={{
                __html: theme?.text?.replace(/\n/g, "<br />"),
              }}></div>

              {
                !multiLink ? (
                  <button className="button constructor__btn promo__btn button-blick" type="button" onClick={openCallbackModal}>
                   Получить консультацию
                  </button>
                ) : (
                  <div className="promo__bottom-info">
                   <button className="button constructor__btn promo__btn button-blick" type="button" onClick={openCallbackModal}>
                    Получить консультацию
                  </button>
                  
                  {promo.some(soc => soc.active) &&
                    <ul className="constructor__socials constructor__socials_promo">
  
                      {promo.map((social, i) => {
                        if (!social.active) return null
                        return (
                          <li
                            className={`constructor__social-btn constructor__social-btn_${social?.type?.xmlId} ${isOneSocial ? 'is-one-social' : ''}`}
                            key={`promo-social-${i}`}
                          >
                            <a
                              href={
                                social.type.xmlId === 'tg'
                                  ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                                  : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                                    : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                                      : social.link
                              }
                              target="_blank"
                            >
                            </a>
                            {icons[social.type.xmlId] ? icons[social.type.xmlId]?.logo : icons[social.type].logo}
                          </li>
                          
                        )
                      })}

                      {
                        site && (
                          <li className="constructor__social-btn constructor__social-btn_site">
                            <a target="_blank" rel="noopener noreferrer nofollow" href={getUrlWithProtocol(site)}></a>
                            <svg className="icon icon_site">
                              <use xlinkHref="#globe"></use>
                            </svg>
                          </li>
                        )
                      }
                      
                    </ul>
                  }
                </div>  
                )
              }


            </div>

            <div className="promo__author">
              {
                theme?.style == 4 &&
                <div className="promo__paint-bg">
                  <Bg4Svg />
                </div>
              }
              <img
                className="promo__img"
                src={getImage()}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require('../../../assets/images/constructor/photo-upload.svg').default
                }}
              />
              {(agent.name || agent.lastName || agent.post) && <div className={`promo__descr`}>
                <span className="promo__name h3">{agent.name} {agent.lastName}</span>
                <p className="promo__position text">{agent.post}</p>
              </div>}
            </div>

            {isMobile && !multiLink && promo.some(soc => soc.active) &&
              <ul className="constructor__socials constructor__socials_promo">

                {promo.map((social, i) => {
                  if (!social.active) return null
                  return (
                    <li
                      className={`constructor__social-btn constructor__social-btn_${social?.type?.xmlId} ${isOneSocial ? 'is-one-social' : ''}`}
                      key={`promo-social-${i}`}
                    >
                      <a
                        href={
                          social.type.xmlId === 'tg'
                            ? ` https://t.me/${social.link.slice(1, social.link.length)}`
                            : social.type.xmlId === 'wa' ? `https://wa.me/${`${social.link.replace(/\D/g, "")}`}`
                              : social.type.xmlId === 'vb' ? `viber://chat?number=${`${social.link.replace(/\D/g, "")}`}`
                                : social.link
                        }
                        target="_blank"
                      >
                      </a>
                      {icons[social.type.xmlId] ? icons[social.type.xmlId]?.logo : icons[social.type].logo}
                    </li>
                  )
                })}
              </ul>
            }
          </div>
        </div>

      </div>
    </section>
  )
};

export default Promo;
