import classNames from "classnames";
import React, { useRef } from "react";
import useDebounceState from "../../hooks/useDebounceState";
import useDropdown from "../../hooks/useDropdown";
import useElementPosition from "../../hooks/useElementPosition";

const SquareFilter = (props) => {
  const {
    onChange,
    filter,
    data,
    right,
    isArea,
    dataSecond,
    filterSecond,
    alternativeTitle,
    secondData,
    secondChange,
    isAreaOnly,
    isMobile,
    autoPosition,
    debounced, // внимание при использовании debounced! если будет 2 площади то см observableValues
    wrapperClass
  } = props;
  const [node, open, show, hide] = useDropdown(false);
  const formItemRef = useRef(null)
  const position = useElementPosition(formItemRef, 150)
  const handleChange = (e, name, ident) => {
    const { value, id } = e.target;
    let tmpFilter = ident == "squareTotal" ? filterSecond : filter;
    onChange({ filter: { ...tmpFilter, [name]: value } }, ident);
  };
  const [debouncedValues, debouncedHandleChange] = useDebounceState({
    setState: (prev, ...args) => ({ ...prev, [args[1]]: args[0]?.target?.value?.replace(/\s/g, "") }),
    fn: handleChange,
    defaultState: { min: "", max: "" },
    observableValues: filter,
  }
  )
  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right") ),
  });

  const clearFilters = (e) => {
    e.preventDefault()
    if(props.getCount && typeof props.getCount === "function"){
      props.getCount({
        ...props.fullFilter,
        filter: props.defFullFilter
          ? props.defFullFilter.filter
          : props.defaultFilter,
      });
    }
    props.clearFilterOnly()
    props.clearExcludeOnly()
  }

  return (
    <div className={`form__item ${wrapperClass?? ""}`} ref={formItemRef}>
      <div
        ref={node}
        className={"dropdown form__drop" + (open ? " is-showing" : "")}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">
            {alternativeTitle ? alternativeTitle : data.title}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">

            {isMobile &&
              <div className="mobile-filters__title">
                <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                  <use xlinkHref="#arrow_up"></use>
                </svg>
                <h3>{data?.title || dataSecond?.title}</h3>
              </div>
            }

            {secondData && (
                <div className="checkbox checkbox_view_red" style={{margin: "10px 0"}}>
                <div className="checkbox__box">
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="include-search-inside-floor"
                    checked ={secondData.value == "Y"? true: false}
                    onChange={(e) => secondChange(e.target.checked? "Y":"N")}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="include-search-inside-floor"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="include-search-inside-floor"
                >
                  {secondData.title}
                </label>
             </div>
              )}
              <div className="filter">
              {isArea && (
                  <div className="filter__fields">
                    <div className="filter__field ">
                      <label
                        className="input input_type_default input_width_available"
                        htmlFor="square-1"
                      >
                        <input
                          className="input__control"
                          type="text"
                          value={filterSecond?.min}
                          placeholder={`${dataSecond.minPlaceholder}${
                            dataSecond.defaultValues
                              ? ` ${dataSecond.defaultValues?.min} м²`
                              : ""
                          }`}
                          onChange={(e) =>
                            handleChange(e, "min", "squareTotal")
                          }
                        />
                      </label>
                    </div>
                    <div className="filter__field ">
                      <label
                        className="input input_type_default input_width_available"
                        htmlFor="square-2"
                      >
                        <input
                          className="input__control"
                          type="text"
                          id="squareTotal"
                          value={filterSecond?.max}
                          placeholder={`${dataSecond.maxPlaceholder}${
                            dataSecond.defaultValues
                              ? ` ${dataSecond.defaultValues?.max} м²`
                              : ""
                          }`}
                          onChange={(e) =>
                            handleChange(e, "max", "squareTotal")
                          }
                        />
                      </label>
                    </div>
                  </div>
                )}
              {!isAreaOnly && <div className="filter__fields">
                  <div className="filter__field ">
                    <label
                      className="input input_type_default input_width_available"
                      htmlFor="square-1"
                    >
                      <input
                        className="input__control"
                        type="text"
                        value={debounced ? debouncedValues?.min : filter?.min}
                        placeholder={`${data.minPlaceholder}${
                          data.defaultValues
                            ? ` ${data.defaultValues?.min} м²`
                            : ""
                        }`}
                        onChange={(e) => debounced ? debouncedHandleChange(e, "min", "squareKitchen") : handleChange(e, "min", "squareKitchen")}
                      />
                    </label>
                  </div>
                  <div className="filter__field ">
                    <label
                      className="input input_type_default input_width_available"
                      htmlFor="square-2"
                    >
                      <input
                        className="input__control"
                        type="text"
                        value={debounced ? debouncedValues?.max :filter?.max}
                        placeholder={`${data.maxPlaceholder}${
                          data.defaultValues
                            ? ` ${data.defaultValues?.max} м²`
                            : ""
                        }`}
                        onChange={(e) => debounced ? debouncedHandleChange(e, "max", "squareKitchen") : handleChange(e, "max", "squareKitchen")}
                      />
                    </label>
                  </div>
                </div>}

                {isMobile &&
                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        clearFilters(e)
                        hide()
                      }}
                    >
                      Очистить
                    </button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        hide()}
                      }
                    >
                      Применить
                    </button>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default SquareFilter;
