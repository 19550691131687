import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { checkAgent, editAgents, getAgent } from "../../actions/agents";
import Form from "./Form";


const Edit = ({ history }) => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState(null);
  const [groups, setGroups] = useState(null)
  
  useEffect(() => {
    getAgent(id).then(
      (data) => {
        const newData = {
          id: data.id,
          name: data.name,
          lastName: data.lastName,
          patronymic: data.patronymic,
          phone: data.phones && data.phones.length ? data.phones : [""],
          email: data.emails && data.emails.length ? data.emails : [""],
          comment: data.comment || '',
          active: data.active,
          role: data.rightId || "agent"
        };
        setGroups(data.accessRights)
        setDefaultValues(newData);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [id]);

  return (
    <>
      {defaultValues && (
        <Form
          add={editAgents}
          history={history}
          defaultValues={defaultValues}
          groups={groups}
          edit={true}
          checkEmail={checkAgent}
        />
      )}
    </>
  );
};

export default Edit
