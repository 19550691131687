import { useEffect, useState } from "react";


const useElementPosition = (ref, limit) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const resizeHandler = () => {
        setWindowWidth(window.innerWidth)
    }
    useEffect(() => {
        if (!ref.current) return
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    }, [ref])
    const refRect = ref?.current?.getBoundingClientRect?.()
    let left = refRect?.left || 0
    let right = refRect?.right || 0
    const result = {
        right: windowWidth - right,
        left
    }
    if (limit) {
        const limited = []
        if (left < limit) limited.push("left")
        if (result.right < limit) limited.push("right")
        return limited
    }
    return result
};

export default useElementPosition;
