import React from "react";
import { getUrl } from "../../api";
import houseEmpty from '../../assets/images/bcg_houses-01-01.svg';
import panoicon from '../../assets/images/icons/aeropano.svg';
import useMedia from "../../hooks/useMedia";
import { getRoundedPrice, getSuffix } from "../../utils";
import AutopanTrigger from '../AutopanTrigger/AutopanTrigger.js';
import CardHeart from "./CardHeart";

const whitelistForCardClick = [
  "card__header",
  "card__content",
  "card__title",
  "card__address",
  "card__left",
  "card__right",
  "card__metro-station",
  "card__left-part",
  "card__metro-color card__metro-color_1",
  "card__dates-to",
  "card__dates-from",
  "card__pill",
  "card__option is-apartments",
  "card__option is-assignments",
  "aero-icon",
  "aero-svg",
];
const Card = (props) => {
  const {
    id,
    photo,
    name,
    address,
    builder,
    countBuildings,
    apartmentsInfo = {},
    subway,
    whatPage,
    city,
    favorite,
    endings,
    isPublic,
    savedFilterXmlId,
    showOnLeft,
    commission,
    clientMode,
    isAgent,
    sideEffectOnClick,
    aeroPanorama,
    isAutoPan,
  } = props;

  const { apartmentsByRoomType, isApartment, isAssignment, isPanAssignment } = apartmentsInfo;
  const isMobile = useMedia("isMobile")
  return (
    <div className="section__grid-col cards__item shadow">
      <div
        className="card"
        onClick={(e) => {
          e.stopPropagation()
          if (whitelistForCardClick.includes(e.target.classList.toString())) {
            if (sideEffectOnClick) {
              sideEffectOnClick(props, e.target.classList.toString());
            }
            window.open(
              isPublic && savedFilterXmlId
                ? `/public/apartments/${savedFilterXmlId}/${city}/complex/${id}`
                : `/realty/${city}/complex/${id}${window.location.search?.replace?.("?scroll", "")}`
            );
          }
        }}
        style={props.fullWidth ? { width: "100%" } : {}}
      >
        <div className="card__header">
          {!clientMode && !isAgent && !isPublic && commission && (
            <ul className="card__pills">
              <li className="card__pill">
                Комиссия: {commission.join(", ")}
              </li>
            </ul>
          )}
          {!isPublic && (
            <CardHeart
              favorite={favorite}
              type="block"
              id={id}
              city={city}
              whatPage={whatPage}
              showOnLeft={showOnLeft}
            />
          )}

          {!isMobile &&
            <ul className="card__options">
              {isApartment && (
                <li className="card__option is-apartments">Апартаменты</li>
              )}
              {isAssignment && (
                <li className="card__option is-assignments">
                  Уступки застр.
                </li>
              )}
              {isPanAssignment &&(
                <li className="card__option is-assignments">
                  Уступки ПАН
                </li>
              )}
            </ul>
          }
        </div>
        <div className="card__body">
          <div className="card__image">
            <img
              src={getUrl(photo.src)}
              alt={name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = houseEmpty
              }}
            />
          </div>
          <div className="card__content">
            <div className="card__row card__row_distribute_between">
              <div className="card__place">
                <div className={!isMobile && "card"}>
                  <div
                    className="card__title"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {name}
                    {aeroPanorama && (
                      <div className="aero-icon">
                        <img  src={panoicon} alt="XY" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="card__address">{address}</div>
              </div>
              {endings && (
                <div className="card__dates">
                  {endings.from && (
                    <div className="card__dates-from">{`${endings.from.quarter} кв. ${endings.from.year}`}</div>
                  )}
                  {endings.to && (
                    <div className="card__dates-to">{` — ${endings.to.quarter} кв. ${endings.to.year}`}</div>
                  )}
                </div>
              )}
            </div>
            <div className="card__company">
              <div className="card__grid">
                <div className="card__left">Застройщик: {builder.name}</div>
                {countBuildings && (
                  <div className="card__right">
                    {countBuildings} корпус{getSuffix(countBuildings)}
                  </div>
                )}
              </div>
            </div>
            <div className="card__variants">
              {apartmentsByRoomType &&
                apartmentsByRoomType.map((room, i) => (
                  <div className="card__grid" key={room.roomTypeName + `${i}`}>
                    <div className="card__left">
                      <div className="card__left-inner">
                        <div className="card__left-part">
                          <strong>{room.roomTypeName}</strong>
                          <span> / {room.count}</span>
                        </div>
                        <div className="card__left-part">
                          от {room.minSquare} м2
                        </div>
                      </div>
                    </div>
                    <div className="card__right">
                      от {getRoundedPrice(room.minPrice)} млн. р.
                    </div>
                  </div>
                ))}
            </div>

            {isAutoPan && !savedFilterXmlId && <AutopanTrigger />}

            {subway &&
              <ul className="card__routes">
                {subway.map((subway, i) => {
                  if (!subway) {
                    return;
                  }
                  return (
                    <li
                      className="card__routes-item"
                      key={subway.name + `${i}`}
                    >
                      {subway.color &&
                        <span
                          className="card__metro-color card__metro-color_1"
                          style={{ backgroundColor: subway.color }}
                        ></span>
                      }
                      {subway.name &&
                        <span className="card__metro-station">
                          м. {subway.name}
                        </span>
                      }
                      {subway.distanceType &&
                        <span className="card__route-type">
                          <svg
                            className={`icon icon_${subway.distanceType === "byFoot"
                              ? "pedestrian"
                              : "auto"
                              }`}
                          >
                            <use
                              xlinkHref={`#${subway.distanceType === "byFoot"
                                ? "pedestrian"
                                : "auto"
                                }`}
                            ></use>
                          </svg>
                        </span>
                      }
                      {subway.minutes &&
                        <span className="card__route-time">
                          {subway.minutes} мин.
                        </span>
                      }
                    </li>
                  );
                })}
              </ul>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
