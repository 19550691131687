import React, { useRef, useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import useMedia from "../../hooks/useMedia";
import useInnerCallBack from "../../hooks/useInnerCall";
import useUniversalForm from '../../hooks/useUniversalForm';
import { scrollToRef } from "../../utils";
import request from '../../api';
import Preloader from "../../components/Preloader";
import Calculator from "../../components/Calculator/CalculatorCashback";

import './index.scss';

const Cashback = (props) => {

  const city = 'spb';
  
  const [isLoading, setIsLoading] = useState(true);
  const [cashbackItems, setCashbackItems] = useState(null);

  const { send, setValue, getValue } = useUniversalForm({
    formId: 57,
    defaultValues: {

    },
    getSettingsAction: "getFormSettingsPublic",
    sendAction: "sendFormPublic", 

  })

  const submitCashbackForm = (calc) => {
    return send({
      name: calc.name,
      phone: calc.phone,
      cost: calc.price,
      init_payment: calc.prepay
    })
  };



  const formRef = useRef();
  const calcRef = useRef(null);
 
  const isMobile = useMedia("isMobile");
  const innerCbRef = useInnerCallBack();

  useEffect(() => {
    request("panpartner:motivation.ajax", "getCashBack", {city})
    .then((data) => {
      setCashbackItems(data.cashBack);
    })
    .finally(() => {
      
      setIsLoading(false);
    })
  }, []);

  // useEffect(() => {
  //   if (cashbackItems.conditions) {
  //     try {
  //       setCustomHtml(cashbackItems.conditions);
  //     } catch (error) {
        
  //     }
  //   } 
  // }, [cashbackItems]);
  
  
  if (isLoading) return <Preloader loading={true} />;

  return (
    <section className="section section_view_white">

      <section className="sor sor_cashback">
        <header className="sor__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__box">
                <div className="sor__content">
                  <span class="sor__sup">Для партнеров ПАН</span>
                  <h1 className="sor__h1 rie__title">Вознаграждение по&nbsp;ипотеке</h1>
                  <p className="sor__sign big-text">Проводите сделки по новостройкам и получайте бонусное вознаграждение 0,3% от суммы кредита</p>

                </div>


                <div className="sor__promo-pic">
                  <img src={require('../../assets/images/cashback/img1.png').default} alt="" className="sor__promo-img-lg" />
                </div>
              </div>

              <div className="sor__promo-btns">
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => innerCbRef.current()}>Оставить заявку</button>
                <button type="button" className="sor__promo-btn button button_view_outline-with-icon-invert" onClick={() => scrollToRef(calcRef, 0)}>Рассчитать</button>
              </div>

              <ul className="sor__faq">
                <li className="sor__faq-item">
                  <span className="sor__faq-title">банки</span>
                  <p className="h4">По приоритетным банкам</p>
                </li>
                <li className="sor__faq-item">
                  <span className="sor__faq-title">застройщики</span>
                  <p className="h4">По всем застройщикам Санкт-Петербурга</p>
                  <span className="sor__faq-label h4">Кроме Arsenal, Setl City, RBI, Becar, ЦДС</span>
                </li>
              </ul>
            </div>
          </div>
        </header>

        <div className="wrapper">
          <div className="wrapper__center">
            <section className="sor__table sor__section">
            <h2 className="h2 sor__h2">Приоритетные банки</h2>

              {
                cashbackItems.map((item) => {
                  return (
                    <div className="sor__table-item">
                      <div className="sor__table-name">{item.title}</div>
                      <div className="sor__table-percent">{`${item.percent}%`}</div>
                      <div className="sor__table-info" dangerouslySetInnerHTML={{__html: item.conditions}}></div>
                    </div>
                  )
                })
              }
            </section>
          </div>
        </div>

        
        <section className="sor__footer sor__how  sor__section" ref={formRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <h2 className="h2 sor__h2">Как получить вознаграждение</h2>
              
              <div className="sor__how-list">
                <div className="sor__how-item">
                  <span className="sor__how-counter">01</span>
                  <p className="h4">Забронируйте квартиру через ПАН Партнер</p>
                </div>
                <div className="sor__how-item">
                  <span className="sor__how-counter">02</span>
                  <p className="h4">Отправьте документы в ипотечный центр ПАН</p>
                </div>
                <div className="sor__how-item">
                  <span className="sor__how-counter">03</span>
                  <p className="h4">Проведите сделку</p>
                </div>
                <div className="sor__how-item">
                  <span className="sor__how-counter">04</span>
                  <p className="h4">Получите вознаграждение!</p>
                </div>
              </div>

              <p className="sor__how-sign">Выплаты проводятся каждую первую неделю календарного месяца</p>
            </div>
          </div>
        </section>

        <section className="sor__calc sor__section" ref={calcRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <h2 className="h2 sor__h2">Калькулятор вознаграждения</h2>
              <Calculator
                  price={20000000}
                  city={"spb"}
                  type="assignment"
                  id={1}
                  period={15}
                  prepay={100000}
                  showInstallment={false}
                  isPublic={true}
                  submitCashback={submitCashbackForm}
                  innerCbRef={innerCbRef}
              />
            </div>
          </div>
        </section>

        <div className="wrapper">
          <div className="wrapper__center">
            <section className="sor__contacts sor__section">
              
              <p className="bold">Руководитель ипотечного центра ПАН</p>
              <p className="bold">Иван Солин</p>

              <div className="sor__contacts-block">
                <a href="tel:+79626857621" className="sor__contacts-link link link_color_black">
                <svg class="icon">
                  <use xlinkHref="#phone"></use>
                </svg>

                  +7 (962) 685-76-21
                  </a>
                <a href="mailto:solin@1-an.ru" className="sor__contacts-link link link_color_black">
                  <svg class="icon">
                    <use xlinkHref="#envelope"></use>
                  </svg>

                  solin@1-an.ru
                  </a>
              </div>
            </section>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Cashback;
