
import { useState } from "react";
import { errorToast } from "../components/toasts";
import { imageTypes } from "../utils";


const renameFile = (file, name) => ({
    fileObj: new File([file.fileObj], name, { type: file.fileObj.type }),
    fileName: name,
    id: file.id
})
/* files является [] объектов, один из которых использован в функции ВЫШЕ

*/
const useFileManager = config => {

    const { maxFiles = 10, onFilesChange = () => { }, onlyImages = false, defaultFIles = [] } = config
    const [files, setFiles] = useState(defaultFIles)

    const uploadFiles = (e, isDrop) => {
        const tmpFiles = []
        const targetFiles = isDrop ? e.dataTransfer.items : e.target.files
        const now = Date.now()
        for (let i = 0, len = targetFiles.length; i < len; i++) {
            const file = isDrop ? targetFiles[i].getAsFile() : targetFiles[i]
            if (onlyImages && !imageTypes.includes(file.type)) {
                continue
            }
            tmpFiles.push({
                fileObj: file,
                fileName: file.name,
                id: files.length + i,
                fakeId: now + i,
            })
        }
        setFiles(prev => {
            const files = [...prev, ...tmpFiles]
            if (files.length > maxFiles) {
                errorToast(`Максимальное количество файлов: ${maxFiles}`)
                files.length = maxFiles
            }
            onFilesChange(files, "upload")
            return files
        })
    }

    const editFileName = (name, index) => setFiles(prev => {
        prev.splice(index, 1, renameFile(files[index], name))
        const tmp = [...prev]
        onFilesChange(tmp, "editName")
        return tmp
    })

    const deleteFile = (index, fileId, fakeId) => setFiles(prev => {
        const i = index ?? prev.findIndex(({fileObj})=>fakeId === fileObj.fakeId);
        const tmp = [...prev]
        tmp.splice(i, 1)
        onFilesChange(tmp, "delete", index, fileId)
        return tmp
    })

    const getFileName = str => {
        if(!str) return ""
        return str.split("/").reverse()[0]
    }

    return {
        deleteFile,
        editFileName,
        files,
        setFiles,
        uploadFiles,
        getFileName
    }
};

export const appendFiles = (files, formData, key) => {
    for (const file of files) {
        if ("fileObj" in file) formData.append(key, file.fileObj, file.name);
    }
    return formData;
}

export default useFileManager;