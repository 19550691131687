import request from '../api';
import { store } from '../index.js';
import { actionFactory } from './actionUtils';

const buildingsDataSelector = state => state.chess.data.buildings;

const requestChess = actionFactory("CHESS_REQUEST");

const successChess = actionFactory("CHESS_SUCCESS");

export const clearChess = actionFactory("CHESS_RESET");

const failureChess = actionFactory("CHESS_FAILURE");

export const fetch = params => dispatch => {
  dispatch(requestChess());
  return request('panpartner:chess.ajax', 'getChess', params).then(
    resp => {
      const [data, filter] = normalize(resp, params.isGetFilter, !!params["filter[buildingId]"]);
      dispatch(successChess({ data, filter }));
    },
    error => {
      dispatch(failureChess());
      return Promise.reject(error);
    },
  );
}

export const getCountChess = params => request('panpartner:chess.ajax', 'getCount', params);

const normalize = (data, isGetFilter, haveBuildingId) => {

  let buildings = isGetFilter ? data.filter.buildings : buildingsDataSelector(store.getState());

  buildings = buildings.map(building => ({
    ...building,
    count: data.count[building.xmlId]?.toString() || (haveBuildingId ? building.count : 0)
  }))

  const sections = Object.entries(data.chess.sections).map(([key, value]) => ({
    ...value,
    count: Math.max(...Object.entries(value.apartments).flatMap(([key, value]) => Object.keys(value)))
  }))

  return [{ buildings, sections, maxFloor: data.chess.maxFloor }, data.filter];
}