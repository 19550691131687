import classNames from 'classnames';
import { chunk } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useDropdown from '../../hooks/useDropdown';
import useApi from '../../hooks/useApi';

import SubMenu from './SubMenu/SubMenu';

const IconLock = () => (
  <svg
    width="13"
    height="19"
    viewBox="0 0 13 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 7.96997H1.6C0.716344 7.96997 0 8.68632 0 9.56997V16.5C0 17.3836 0.716344 18.1 1.6 18.1H11C11.8837 18.1 12.6 17.3836 12.6 16.5V9.56997C12.6 8.68632 11.8837 7.96997 11 7.96997Z"
      fill="white"
    />
    <path
      d="M7.62009 12.0699C7.62542 11.8923 7.59535 11.7155 7.53165 11.5496C7.46794 11.3837 7.37187 11.2321 7.24903 11.1036C7.12619 10.9752 6.97903 10.8725 6.81612 10.8015C6.6532 10.7305 6.47779 10.6925 6.30009 10.6899C6.12239 10.6925 5.94698 10.7305 5.78406 10.8015C5.62115 10.8725 5.47399 10.9752 5.35115 11.1036C5.22831 11.2321 5.13224 11.3837 5.06853 11.5496C5.00483 11.7155 4.97477 11.8923 4.98009 12.0699C4.97751 12.2913 5.029 12.5099 5.13007 12.7068C5.23115 12.9037 5.37875 13.073 5.56009 13.2L5.25009 15.38H7.43009L7.12009 13.2C7.27694 13.0563 7.40234 12.8819 7.48839 12.6874C7.57445 12.4929 7.61929 12.2826 7.62009 12.0699Z"
      fill="#2C2E3E"
    />
    <path
      d="M9.67044 8.21997V4.75C9.67044 3.85622 9.31539 2.99906 8.68339 2.36707C8.0514 1.73507 7.19422 1.38 6.30045 1.38C5.85836 1.38 5.42062 1.46724 5.01231 1.63672C4.60401 1.8062 4.23316 2.05461 3.92102 2.36768C3.60888 2.68074 3.3616 3.05225 3.19333 3.46106C3.02506 3.86987 2.93913 4.30792 2.94044 4.75V5.84998"
      stroke="white"
      strokeMiterlimit="10"
    />
  </svg>
);

const DropMenu = (props) => {
  const { title, menu, showModal } = props;

  const [node, open, show, hide] = useDropdown(false);

  const rowItems = (row) => {
    const items = [];

    row.forEach((item, i) => {
      items.push(
        item.modal ? (
          <div
            className="profile-drop__item"
            key={`drop-menu-item-${i}`}
            onClick={() => showModal(item.modal)}
          >
            <DropItemContent item={item} />
          </div>
        ) : item.external ? (
          <a
            href={item.link}
            className="profile-drop__item"
            target="_blank"
            rel="noopener noreferrer"
            key={`drop-menu-item-${i}`}
          >
            <DropItemContent item={item} />
          </a>
        ) : (
          <Link
            className="profile-drop__item"
            to={item.link}
            key={`drop-menu-item-${i}`}
          >
            <DropItemContent item={item} />
          </Link>
        ),
      );
    });

    return items;
  };

  const dropLinks = chunk(menu, 2).map((row, index) => (
    <div className="profile-drop__row" key={`menuDrop-${index}`}>
      <div className="profile-drop__wrap">{rowItems(row)}</div>
    </div>
  ));

  const dropdownClass = classNames({
    dropdown: true,
    'is-showing': open,
  });

  return (
    <div className={dropdownClass} ref={node}>
      <button
        className="dropdown__btn button"
        onClick={(e) => (open ? hide() : show())}
      >
        <span className="header__bottom-menu-link dropdown-view">
          {title}
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </span>
      </button>
      <div className="dropdown__block">
        <div className="dropdown__inner">
          <div className="header__bottom-menu-drop">
            <div className="profile-drop menu-drop">
              <div className="profile-drop__content">{dropLinks}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DropItemContent = (props) => {
  const { item } = props;
  return (
    <>
      <div className="profile-drop__item-title">
        <div className="profile-drop__icon">
          {item.icon && <MenuIcon icon={item.icon} />}
        </div>
        <div className="profile-drop__subtitle">{item.title}</div>
      </div>
      <div className="profile-drop__desc">{item.text}</div>
    </>
  );
};

const MenuIcon = (props) => {
  const { icon } = props;

  return (
    <svg className={`icon icon_${icon}`}>
      <use xlinkHref={`#${icon}`}></use>
    </svg>
  );
};

const BottomMenu = (props) => {
  const { menu, showModal, servicesMenu } = props;

  const [node, open, show, hide] = useDropdown(false);


  const topLinks = menu.map(
    (item) =>
      !item.hide &&
      !item.tabletOnly && (
        <li
          className={`header__bottom-menu-item${
            item.isHighlighted ? ` header__bottom-menu-item_highlighted` : ``
          }`}
          key={item.link}
        >
          {item.modal ? (
            <div
              className="header__bottom-menu-link"
              onClick={() => showModal(item.modal)}
            >
              {item.icon && <MenuIcon icon={item.icon} />}
              {item.title}
            </div>
          ) : item.submenu && !item.openSub ? (
            <DropMenu
              title={item.title}
              menu={item.submenu}
              showModal={showModal}
            />
          ) : item.openSub ? (
            <div ref={node}>
              <div
                className={`header__bottom-menu-link${
                  open ? ` is-active` : ``
                }`}
                onClick={(e) => (open ? hide() : show())}
              >
                {item.icon && <MenuIcon icon={item.icon} />}
                {item.title}

                <svg className="icon icon_chevron">
                  <use xlinkHref="#chevron"></use>
                </svg>
              </div>

              {servicesMenu && (
                <SubMenu
                  menu={servicesMenu}
                  showModal={showModal}
                  openSub={open}
                />
              )}
            </div>
          ) : (
            <Link to={item.link} className="header__bottom-menu-link">
              {item.isHighlighted && <IconLock />}
              {item.icon && <MenuIcon icon={item.icon} />}
              {item.title}
            </Link>
          )}
        </li>
      ),
  );

  return <ul className="header__bottom-menu">{topLinks}</ul>;
};

const mapStateToProps = (state) => ({
  clientMode: state.general.clientMode,
});

export default connect(mapStateToProps)(BottomMenu);
