import React from "react";

const About = (props) => {
  const { title, previewText, detailText, advantages, innerRef } = props;
  if (!previewText || !advantages) return '';
  return (
    <section className="about constructor__section">
      <div className="constructor__container">
        <div className="about__wrapper constructor__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title about__title">
            {title}
            </h2>
          </div>

          <div className="constructor__box">
            <div className="about__info">
              <p
               className="about__text big-text"
               dangerouslySetInnerHTML={{ __html: previewText }}
               >
              </p>
            </div>
            {advantages?.length > 0 && <div className="about__features">
              <ul className="about__features-list">
              {advantages.map((advantage) => (
                  <li class="about__feature" key={advantage}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>}
          </div>

        </div>
      </div>
    </section>
  )
};

export default About;
