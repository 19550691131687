import { memo } from 'react';
import Tag from './Tag';

const Tags = memo(({ badges, removeBadge, resetFilter, readonly, checkValues }) => {
  if (!badges.length) return '';
  return (
    <ul className="sf__tags">
      {/*{badges.map(({ id, ident, subKey, ...rest }) => (*/}
      {/*  <Tag*/}
      {/*    key={`${ident}-${subKey}-${id}`}*/}
      {/*    removeBadge={() => removeBadge(ident, id, subKey)}*/}
      {/*    readonly={readonly}*/}
      {/*    {...rest}*/}
      {/*  />*/}
      {/*))}*/}
      {badges.map(({ id, ident, subKey, ...rest }) => {
        if (checkValues && rest.value === undefined) {
          return null;
        }
        return (
          <Tag
            key={`${ident}-${subKey}-${id}`}
            removeBadge={() => removeBadge(ident, id, subKey)}
            readonly={readonly}
            {...rest}
          />
        )
        }
      )}


      {!readonly && <li className="sf__tag sf__tag_clear">
        <span
          className="sf__clear-filter-btn link link_color_brand-3"
          onClick={resetFilter}
        >
          Очистить фильтр
        </span>
      </li>}
    </ul>
  );
});

export default Tags;
