import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  clearAgents,
  clearFilterAgents,
  fetchAgents,
  filteredAgents,
  handleActiveAgents,
  isFilterSet,
  removeAgent,
  setFilterAgents
} from "../../actions/agents";
import { media } from '../../utils';
import EmptyTable from "../core/EmptyTable";
import Preloader from "../Loaders/FilterTableLoader";
import Filter from "./Filter";
import Items from "./Items";
import useMedia from "../../hooks/useMedia";



const List = (props) => {
  const {
    filterData,
    filter,
    fetch,
    reset,
    isFetching,
    items,
    setFilter,
    setActive,
    clearFilter,
    remove,
    data,
  } = props;

  const [firstRender, setFirstRender] = useState(true);
  const isMobile = useMedia("isMobile")
  useEffect(() => {
    fetch("spb").then(() => {
      setFirstRender(false);
    });
    return () => {
      reset();
    };
  }, []);

  if (isFetching) return <Preloader />;

  return (
    <section class="section section_view_white orders no-header-profile">
      <div class="wrapper">
        <div class="clients__header">
          <div class="clients__title">
            <div class="h1">
              Сотрудники
            </div>
          </div>
          <Link
            className="button button_view_default clients__add-new"
            to={`/personal/agents/add`}
          >
            {isMobile ? 'Добавить' : 'Добавить сотрудника'}
          </Link>
        </div>
        {!firstRender &&
          (items.length > 0 ? (
            <>
              <Filter
                filter={filter}
                filterData={filterData}
                setFilter={setFilter}
                clearFilter={clearFilter}
                isFilterSet={isFilterSet}
              />
              <Items
                items={data}
                handleActive={setActive}
                handleDelete={remove}
              />
            </>
          ) : (
            <EmptyTable
              title="Вы еще не добавили сотрудников"
              link="/personal/agents/add"
            />
          ))}
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchAgents()),
  reset: () => dispatch(clearAgents()),
  setActive: (id, active) => dispatch(handleActiveAgents(id, active)),
  remove: (id) => dispatch(removeAgent(id)),
  setFilter: (filter) => dispatch(setFilterAgents({ filter })),
  clearFilter: () => dispatch(clearFilterAgents()),
});

const mapStateToProps = (state) => ({
  filterData: state.agents.filterData,
  filter: state.agents.filter,
  isFilterSet: isFilterSet(state),
  isFetching: state.agents.isFetching,
  error: state.agents.error,
  data: filteredAgents(state),
  items: state.agents.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
