import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchClients } from "../actions/clients";
import {
  clearFavoritesFilter, fetchFavorites,
  setFavoritesFilter
} from "../actions/favorites";
import FavoritesTab from "../components/FavoritesTab";
import FavoritesSwitcher from "../components/FavoritesTab/FavoritesSwitcher";
import Preloader from "../components/Preloader";
import { filteredApartments, filteredBlocks, filteredSecondary, filteredSuburbans, filteredVillages } from "../selectors/favorites";
const Favorites = (props) => {
  const {
    fetch,
    fetchClients,
    isFetching,
    filterData,
    filter,
    city,
    setFavoritesFilter,
    blocks,
    apartments,
    isAgent,
    clientMode,
    clearFilter,
    secondary,
    villages,
    suburbans
  } = props;
  const [currentTab, setTab] = useState("block");

  useEffect(() => {
    fetch(props.city);
    fetchClients();
    return () => {};
  }, []);
  return (
    <div className="favorites">
      <FavoritesSwitcher setTab={setTab} currentTab={currentTab} cityLen={filterData.city?.length} />
      {isFetching ? (
        <Preloader loading={isFetching} />
      ) : (
        <FavoritesTab
          blocks={blocks}
          apartments={apartments}
          filterData={filterData}
          currentTab={currentTab}
          city={city}
          filter={filter}
          setFavoritesFilter={setFavoritesFilter}
          isAgent={isAgent}
          clientMode={clientMode}
          clearFilter={clearFilter}
          access={props.access}
          secondary={secondary}
          villages={villages}
          suburbans={suburbans}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  favorites: state.favorites.favorites,
  blocks: filteredBlocks(state),
  apartments: filteredApartments(state),
  secondary: filteredSecondary(state),
  villages: filteredVillages(state),
  suburban: filteredSuburbans(state),
  filterData: state.favorites.filterData,
  filter: state.favorites.filter,
  isFetching: state.favorites.isFetching,
  city: state.user.info.city,
  isAgent: state.user.info.groups.some((group) => group === "agent" || group === "test_access"),
  clientMode: state.general.clientMode,
  access: state.user.access,
}};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city, type) => dispatch(fetchFavorites(city, type)),
  setFavoritesFilter: (filter) => {
    dispatch(setFavoritesFilter(filter));
  },
  fetchClients: () => dispatch(fetchClients()),
  clearFilter: (city) => dispatch(clearFavoritesFilter(city)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
