import React from 'react';


const ComplexAbout = ({ data: { object, previewText }, activeSettings }) => {

  return  <div className="presentation__info pdf-block presentation__info_flat" >
  <div className="presentation__box" data-id="complex_about" style={{margin: "50px 0"}}>
    <div className="presentation__title">Описание ЖК {activeSettings?.complex_name && `«${object.blockName}»`}</div>
    <div className="presentation__info-description">
      <p>{previewText || object?.blockPreviewText}</p>
    </div>
  </div>
</div>
};

export default ComplexAbout;