import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';

import { ListIcon, TileIcon } from '../core/Tabs/icons';
import StatelessTabs from '../core/Tabs/StatelessTabs';
import CardsList from '../Public/markup/CardsList';
import NotFound from '../Public/markup/detail/NotFound';
import Tags from '../Public/markup/Tags';
import VillageFilterFormNew from '../Public/markup/VillageFilterFormNew';


import { TableApartments } from './ApartmentList';
import SuburbanCardNew from '../Public/markup/Containers/SuburbanCardNew';
import { ShowMore } from '../core/Table2';
import VillagesNewTopFilter from "../Public/markup/VillagesNewTopFilter";
import SelectFilter from "../FilterRealty/SelectFilter";
import {getSelectPropsCreator} from "../Public/utils";

const viewTabs = [
  { title: 'Список', icon: ListIcon },
  { title: 'Плитка', icon: TileIcon },
  // { title: 'Шахматка', icon: ChessIcon },
];

const SuburbanContentNew = memo(props => {
  const [activeView, setActiveView] = useState(0);
  const { savedFilterXmlId } = useParams();

  const {
    handleFilter,
    isLoading,
    filter = {},
    data: {
      filter: filterData = {},
      headers,
      items,
      limit,
    },
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    removeBadge,
    resetFilter,
    hasNextPage,
    handleLoadMore,
    moreIsLoading,
    tab,
    villageId,
    city="spb",
    card: Card,
    externalSorting
  } = props;


  const getLink = ({xmlId}) => `/${savedFilterXmlId? "public/": ""}${tab.typeId === 'parcel' ? `lands/${city}/${xmlId}` : `house/${xmlId}`}${savedFilterXmlId? `/${savedFilterXmlId}`: ""}`

  return (
    <>
      <VillageFilterFormNew
        filterData={filterData}
        filter={filter}
        setFilterAndExclude={setFilterAndExclude}
        handleFilter={handleFilter}
        isLoading={isLoading}
        {...tab}
        searchPlaceholder={'Название КП, застройщик, район'}
        activeTab={props.tab}
      />
      <Tags
        badges={filterBadges}
        removeBadge={removeBadge}
        resetFilter={resetFilter}
      />





      {!!items?.length && (
        <StatelessTabs
          tabs={viewTabs}
          activeTab={activeView}
          setActiveTab={setActiveView}
          withIcons
          onClick={(i) =>
            i === 2
              ? window.open(
                `/realty/${city}/chess/${villageId}`,
                '_blank',
              )
              : setActiveView(i)
          }
        />
      )}

      <div className="complex-results-wrapper">
        {!!items?.length && (
          <div className="complex__appartments table2">
            <div className="complex__appartments-tab active">
              <div style={{ display: 'block' }}>
                {activeView === 1 ? (
                  <CardsList
                    withoutBtnsList
                    items={items}
                    limit={limit}
                    isLoading={showSkeleton}
                    cardComponent={Card ?? SuburbanCardNew}
                    getLink={getLink}
                    listClass="sf__cards-list sf__cards-list_flats"
                  />
                ) : (
                  <TableApartments
                    getLink={getLink}
                    tabTitle={tab.title}
                    items={items}
                    headers={headers}
                    isNewSuburban
                    externalSorting={externalSorting}
                  />
                )}
                {hasNextPage && <ShowMore onClick={handleLoadMore} loading={moreIsLoading} />}
              </div>
            </div>
          </div>
        )}
      </div>
      {!items?.length && <NotFound />}
    </>
  );
});

export default SuburbanContentNew;
