import React from 'react';
import './index.scss';

const Social = (props) => {
  const {type, link, addClass, iconOnly = false, noBg = false} = props;

  const defaultLink = {
    tg: `https://tlgg.ru/panpartner`,
    ig: `https://www.instagram.com/panpartner/`,
    fb: `https://www.facebook.com/PANpartnerspb`,
    vk: `https://vk.com/panpartnerspb`,
    mail: ``,
    wa: `https://wa.me/79626863547?&text=%D0%9E%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D0%BD%D0%B5%20%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D1%8F%D1%8F%20%D1%8D%D1%82%D0%BE%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5%20subscribe_a3eb60cd-4810-a7cb-cc96-01758cfdd70e`,
    viber: `viber://pa?chatURI=panpartner&context=subscribe_a3eb60cd-4810-a7cb-cc96-01758cfdd70e`,
  };

  const classOptions = `social ${!noBg && ` social_${type}`} ${addClass ? ` ${addClass}` : ``}`;

  return (
    <>
      {iconOnly ?
        <span className={classOptions}>
          <svg className={`icon icon_social-${type}`}>
            <use xlinkHref={`#social-${type}`}></use>
          </svg>
        </span>
        :
        <a
          href={link || defaultLink[type]}
          target="_blank"
          rel="noopener noreferrer"
          className={classOptions}>
          <svg className={`icon icon_social-${type}`}>
            <use xlinkHref={`#social-${type}`}></use>
          </svg>
        </a>
      }
    </>

  );
};

export default Social;
