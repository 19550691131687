import { useMediaQuery } from 'beautiful-react-hooks';
import React, { memo, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';
import useDropdown from '../../hooks/useDropdown';
import { media } from '../../utils';
import Popup from '../core/Popup';
import Preloader from '../Preloader';
import PrepaidBanner from "../PrepaidBanner";

/* @param {Date} start - date to start
 ** @param {number} count - number of months to generate dates for
 ** @returns {Array} monthly Dates from start for count months
 */
const getMonthlyDates = (start, count) => {
	let result = [];
	let temp;
	let year = start.getFullYear();
	let month = start.getMonth();
	let startDay = start.getDate();

	if (media('isMobile')) {
		const next = new Date(year, month + 1, startDay);
		const prev = new Date(year, month - 1, startDay);
		const current = new Date(year, month, startDay);
		result.push(prev, current, next);
	} else {
		for (let i = 0; i < count; i++) {
			temp = new Date(year, month + i, startDay);
			if (temp.getDate() != startDay) temp.setDate(0);
			result.push(temp);
		}
	}
	return result;
};

const timestamp = (date) => new Date(date).setHours(0, 0, 0, 0);

const EventCard = memo(({ event }) => {
	return (
		<div className="event-card">
			<div className="event-card__head">
				<div className="event-card__time events__event-time">{event.time}</div>
			</div>
			<Link
				className="event-card__name bold link-hover"
				to={`/events/${event.id}`}
			>
				{event.name}
			</Link>
			{event.speaker && (
				<div className="event-card__speaker">Спикер: {event.speaker}</div>
			)}
		</div>
	);
});

const DayEvents = memo((props) => {
	const { events, type, openDay, date, onHide, user, popoverAlign } = props;
	const [node, open, show, hide] = useDropdown(false);

	const isSubscribed = (event) => {
		if (!Array.isArray(event.subscribedAgents)) return false;

		return event.subscribedAgents.find((agent) => agent.id == user.info.id);
	};

	return (
		<div ref={node}>
			<div className={'popover' + (open ? ' is-showing' : '')}>
				<div className="events-calendar__events">
					{events.map((event, index) => (
						<div
							key={index}
							className={`events-calendar__event events-calendar__event_${
								isSubscribed(event) && type == 'upcoming' ? 'active' : type
							}`}
						></div>
					))}
				</div>
				{!media('isMobile') &&
					<div
						className={`popover__block popover__block_align_${popoverAlign} popover__block_align_center events-calendar__popover`}
					>
						<div className="popover__inner">
							{events.map((event, index) => (
								<EventCard event={event} key={index} />
							))}
						</div>
					</div>
				}
			</div>
			{media('isMobile') ?
				<Popup
					trigger={
						<div className="events-calendar__day" onClick={() => show()}></div>
					}
				>
					<div className="popover__inner no-padding">
						{events.map((event, index) => (
							<EventCard event={event} key={index} />
						))}
					</div>
				</Popup> :
				<div className="events-calendar__day" onClick={() => show()}></div>
			}
		</div>
	);
});

const CalendarSlider = memo(({ navRef, data, sortedData, user }) => {
	const mediumScreen = useMediaQuery('(max-width: 992px)');
	const smallScreen = useMediaQuery('(max-width: 768px)');
	const [calendarSlider, setCalendarSlider] = useState(null);
	const [dateNow, setDateNow] = useState(new Date());
	const [months, setMonths] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [activeIndex, setActiveIndex] = useState(11);
	const [currentTab, setCurrentTab] = useState(false);

	const settings = useMemo(
		() =>
			navRef
				? {
						items: 2,
						controls: false,
						gutter: 10,
						nav: true,
						loop: false,
						responsive: {
							992: {
								items: 3,
							},
							640: {
								items: 1,
							},
							320: {
								items: 1,
							}
						},
				  }
				: {},
		[navRef],
	);

	useEffect(() => {
		setMonths(
			getMonthlyDates(
				new Date(
					media('isMobile') ? dateNow.getFullYear() : dateNow.getFullYear() - 1,
					dateNow.getMonth(), 1
				),
				media('isMobile') ? 2 : 18,
			),
		);
	}, []);

	useEffect(() => {
		if (calendarSlider) {
			calendarSlider.slider?.goTo?.('prev');
		}
	}, [calendarSlider]);

	const setSliderRef = (node) => {
		setCalendarSlider(node);
		if (isLoading){
			setIsLoading(false);
			if (mediumScreen) setActiveIndex(12);
		}
	};

	const capitalize = (s) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	const renderEvents = ({ activeStartDate, date, view }, currIndex) => {
		if (!data) return;

		let type = 'upcoming';
		let items = [];

		type = 'upcoming';
		items = data.actual.filter(
			(event) => timestamp(event.date) == timestamp(date),
		);

		if (!items.length) {
			type = 'past';
			items = data.past.filter((event) => {
				return timestamp(event.date) == timestamp(date);
			});
		}
		if (!items.length) return;
		const popoverAlign = () => {
			let index = activeIndex + (mediumScreen ? 1 : 2);

			return index == currIndex ? 'left' : 'right';
		};

		return (
			<DayEvents
				events={items}
				type={type}
				date={date}
				user={user}
				popoverAlign={popoverAlign()}
			/>
		);
	};

	// Сделал через debounce, т.к. setActiveIndex блокирует анимацию слайдера
	const debounceSlide = (func, wait, immediate, way) => {
		let timeout;
		return function () {
			calendarSlider?.slider?.goTo?.(way);

			let context = this,
				args = arguments;
			let later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	const onSliderChange = () => {
		let info = calendarSlider.slider.getInfo();
		setActiveIndex(info.index);
	};

	return (
		<section className="section section_view_white">
			<div className="wrapper">
				<div className="events__calendar">
					<div
						className="events__header"
						style={{ flexDirection: 'row', alignItems: 'center' }}
					>
						<div className="events__title">
							<h1 className="h1">Мероприятия</h1>
						</div>
						<div className="calendar__nav">
							{/* <div
								className="calendar__nav-list"
								style={{
									overflow: 'hidden',
								}}
								id="calendar-months-nav"
								// ref={navRef}
							>
								<TinySlider
									settings={{
										autoWidth: true,
										controls: false,
										nav: false,
										loop: false,
										mouseDrag: true,
									}}
									startIndex={11}
								>
									{months.map((date) => (
										<div className="calendar__nav-month">
											{capitalize(
												date.toLocaleString('default', { month: 'long' }),
											)}
										</div>
									))}
								</TinySlider>
							</div> */}
							{/* <li className="calendar__nav-month active_left" data-index="0">
                Январь
              </li>
              <li className="calendar__nav-month active" data-index="1">
                Февраль
              </li>
              <li className="calendar__nav-month active_right" data-index="2">
                Март
              </li> */}
							{!media('isMobile') &&
								<div className="calendar__nav-controls">
									<ul className="tabs__navs" id="calendar-nav">
										<li
											className="tabs__navs-item prev"
											onClick={debounceSlide(
												() => onSliderChange(),
												1000,
												false,
												'prev',
											)}
										>
											<svg className="icon icon-dark_stroke">
												<use xlinkHref="#icon-left_arrow"></use>
											</svg>
										</li>
										<li
											className="tabs__navs-item next"
											onClick={debounceSlide(
												() => onSliderChange(),
												1000,
												false,
												'next',
											)}
										>
											<svg className="icon icon-dark_stroke">
												<use xlinkHref="#icon-right_arrow"></use>
											</svg>
										</li>
									</ul>
								</div>
							}
						</div>
					</div>

					{media('isMobile') &&
						<ul className="calendar__mobile-nav-controls">
							{months.map((item, index) => (
								<li
									className="calendar__mobile-nav-item"
									key={index}
								>
									{new Date(item).toLocaleString('ru', { month: 'long' })}
								</li>
							))}
						</ul>
					}

					<TinySlider
						settings={settings}
						ref={(node) => setSliderRef(node)}
						startIndex={media('isMobile') ? 1 : mediumScreen ? 12 : 11}
					>
						{months.map((item, index) => (
							<div className="calendar__slider-item" key={index}>
								<div
									className={'calendar__month' + (index == 12 ? ' active' : '')}
								>
									<Calendar
										navigationLabel={({ date, label, locale, view }) =>
											capitalize(
												date.toLocaleString('ru', { month: 'long' }),
											)
										}
										activeStartDate={new Date(item)}
										minDetail={'month'}
										view={'month'}
										prevLabel=""
										nextLabel=""
										showNeighboringMonth={false}
										className="events-calendar"
										tileContent={(data) => renderEvents(data, index)}
									/>
								</div>
							</div>
						))}
					</TinySlider>
					<Preloader loading={isLoading} />

					<div className="calendar__designations">
						<ul className="calendar__designations-list">
							<li className="calendar__designations-item">
								Прошедшие мероприятия
							</li>
							<li className="calendar__designations-item">
								Предстоящие мероприятия
							</li>
							<li className="calendar__designations-item">
								Вы записаны на мероприятие
							</li>
						</ul>
					</div>

					<PrepaidBanner bannerId={"events"} addClass={'events-banner'} />
				</div>
			</div>
		</section>
	);
});

export default CalendarSlider;
