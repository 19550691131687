import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import useDisableCopy from "../../hooks/useDisableCopy";
import "./index.scss";
import List from "./List";
import View from "./View";
import SecondaryObject from "./SecondaryObject";

const Secondaries = props => {
  let { path } = useRouteMatch();
  useDisableCopy()
  return (
    <Switch>
      <Route exact path={`${path}`} component={List} />
      <Route exact path={`${path}/:id`} component={View} />
      {/*<Route exact path={`${path}/:id`} component={SecondaryObject} />*/}
    </Switch>
  );
};

export default Secondaries;
