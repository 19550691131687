import { memo } from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from '../../../../api';
import Button from '../../../core/Button';
import { CopyLinkIcon, DowloadLinkIcon, FileIcon, FolderIcon } from '../icons';

const Files = memo(({ items, innerRef, customTitle }) => (
  <section className="sf__section">
    <h3 className="h3-l sf__h3" ref={innerRef}>
      {customTitle ? customTitle : 'Файлы'}
    </h3>
    <section className="sf__files-list">
      {items.map(({ title, value: link, file }) => <div className="sf__files-item" key={link}>
        <a href={link} target="_blank" className="link link_color_black" rel="noreferrer">
          {!file ? <FolderIcon /> : <FileIcon />}
          <span>{title}</span>
        </a>
        {!!file && <div className="sf__files-actions">
          <Button buttonType="clean" copy={getUrl(link)}>
            <CopyLinkIcon />
          </Button>

          <Button buttonType="clean" isLink isDownload to={link} target="_blank">
            <DowloadLinkIcon />
          </Button>
        </div>}
      </div>)}
    </section>
  </section>
));

export default Files;
