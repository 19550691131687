import classNames from "classnames";
import React from "react";
import SimpleBar from "simplebar-react";
import useDropdown from "../../../hooks/useDropdown";
import useMedia from "../../../hooks/useMedia";

const ChecboxItem = (props) => {
  const { identity, id, title, name, checked = false, onChange, disabled = false } = props;

  const handleSelect = checked => onChange(checked, id);

  return (
    <div className="checkbox-group__item" style={{ padding: "6px 12px", ...(disabled ? { filter: "opacity(0.5)" } : []) }}>
      <div className={"checkbox"}>
        <div className="checkbox__box">
          <input
            className="checkbox__control"
            type="checkbox"
            id={`${identity}-${id}`}
            checked={checked}
            onChange={(e) => handleSelect(e.target.checked)}
            disabled={disabled}
          />
          <label
            className="checkbox__input"
            htmlFor={`${identity}-${id}`}
          ></label>
          <div className="checkbox__marker"></div>
        </div>
        <label className="checkbox__label" htmlFor={`${identity}-${id}`}>
          {title || name}
        </label>
      </div>
    </div>
  );
};

const DropdownGrouped = (props) => {
  const {
    data,
    value = [],
    onChange,
    right = false,
    options = [],
    selectSubItemKey,
    redTitle
  } = props;
  const [node, open, show, hide] = useDropdown(false);
  const isMobile = useMedia("isMobile")

  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right,
    dropdown__block_payment: true,
  });

  return (
    <div className="form__item" style={{width: "auto"}}>
      <div
        ref={node}
        className={"dropdown form__drop" + (open ? " is-showing" : "")}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={open ? hide : show}
        >
          <span className="form__drop-name">
            {data.title}
            {value.length > 0 && <span style={{
              backgroundColor: "#d0d3da",
              color: "#fff",
              width: 25,
              height: 25,
              display: "flex",
              justifyContent: "center",
              borderRadius: "50%",
              alignItems: "center",
              lineHeight: 0,
            }}>{value.length}</span>}
          </span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={hide}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data.title}</h3>
                </div>
              }
              <div className="filter">
                <SimpleBar className="filter__checks">
                  <div className="checkbox-group">
                    {options.map((option) => (
                      <div>
                        {redTitle ? <p style={{margin: "8px 0", color: '#FF2D23'}}>{option.title}</p> :
                          <p style={{margin: "8px 0"}}>{option.title}</p>}
                        {option.items.map(item => <ChecboxItem
                          {...item}
                          identity={data.identity}
                          checked={value.some(val => val[selectSubItemKey] === item.id)}
                          onChange={(checked, id) => onChange(checked, id, option.id)}
                          key={item.id}
                        />)}
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownGrouped;
