
const caseCompareColumns = [
  {
    title:
      <div className="custom-table__first-header">
        <div className="custom-table__title">Сравнение</div>
        <div className="custom-table__fst-name">С субсидированием</div>
      </div>,
    dataIndex: "title",
    render: (title, {values}) => {
      return (
        <div className="custom-table__first-cell">
          <div className="custom-table__fst-lable">{title}</div>
          <div className="custom-table__fst-val">{values[0]}</div>
        </div>
      )
    },
  },
  {
    title: "Без субсидирования",
    dataIndex: "values",
    render: (values) => {
      return (
        <div className="">
          {values[1]}
        </div>
      )
    },
  },
];
export default caseCompareColumns
