import compact from "lodash/compact";
import minBy from "lodash/minBy";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {connect, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { fetchClients } from "../../actions/clients";
import { clearComplex, fetchComplex, getApartments, getCount } from "../../actions/complex";
import {
  addClientToFavorite, addToFavorites,
  removeClientFromFavorite, removeFromFavorites
} from "../../actions/favorites";
import useMedia from "../../hooks/useMedia";
import { ReactComponent as Bank } from "../../images/svg/bank.svg";
import { ReactComponent as Desc } from "../../images/svg/desc.svg";
import { cleanFilter } from "../../reducers/complex";
import { getEndingsTitle, media } from '../../utils';
import About from "../Complex/About";
import Apartments from "../Complex/Apartments";
import Banks from "../Complex/Banks";
import Block from "../Complex/Block";
import "../Complex/index.scss";
import OtherBlocks from "../Complex/OtherBlocks";
import { filterToParam } from '../Complex/Utils';
import Collapse from '../core/Collapse';
import Preloader from "../Preloader";
import FilterDisabled from "./FilterDisabled";

var inDebounce;
const Complex = (props) => {
  const { id, city, savedFilterXmlId } = useParams();

  const {
    fetch,
    reset,
    isFetching,
    block,
    otherBlocks,
    realtyFilter,
    isClientMode,
    fetchClients,
    clients,
    agent,
    addClientToFavorite,
    removeClientFromFavorite,
    isActive,
    filterType,

    setFilter,
    filter,
    clearFilter,
    filterData,
    apartments,
    getApartments,
    count,
    getCount,
  } = props;
  let localSavedFilter = JSON.parse(localStorage.getItem("blocksFilter"));
  let endings = {
    min: localSavedFilter && localSavedFilter[id] && getEndingsTitle(localSavedFilter[id].endings?.min) ? localSavedFilter[id].endings?.min : null,
    max: localSavedFilter && localSavedFilter[id] && getEndingsTitle(localSavedFilter[id].endings?.max) ? localSavedFilter[id].endings.max : null,
  } //ппц
  let issuingKeys = {
    min: localSavedFilter && localSavedFilter[id] && getEndingsTitle(localSavedFilter[id].issuingKeys?.min) ? localSavedFilter[id]?.issuingKeys?.min : null,
    max: localSavedFilter && localSavedFilter[id] && getEndingsTitle(localSavedFilter[id].issuingKeys?.max) ? localSavedFilter[id]?.issuingKeys?.max : null,
  } //ппц
  const initialFilter = {
    buildings: [],
    bathroom: [],
    roomType: [],
    decoration:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].decoration
        : [],
    squareKitchen:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].squareKitchen
        : { min: "", max: "" },
    endings: endings,
    issuingKeys,
    squareTotal:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].squareTotal
        : { min: "", max: "" },
    floors:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].floor
        : { min: "", max: "" },
    prices:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].prices
        : { min: "", max: "" },
    cellHeight:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].cellHeight
        : { min: "", max: "" },
    banks:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].banks
        : [],
    rooms:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].rooms
        : [],
    status:
      localSavedFilter && localSavedFilter[id]
        ? localSavedFilter[id].status
        : [1],
    isOnlyHanded:
      localSavedFilter &&
        localSavedFilter[id] &&
        localSavedFilter[id].isOnlyHanded?.length
        ? localSavedFilter[id].isOnlyHanded
        : "",

    payment: [],
    number: "",
    isNoFirstFloor:
      localSavedFilter &&
        localSavedFilter[id] &&
        localSavedFilter[id]?.isNoFirstFloor?.length
        ? localSavedFilter[id].isNoFirstFloor[0].id
        : "",

    isApartments:
      localSavedFilter?.[id]?.isApartments
        ? localSavedFilter[id]?.isApartments
        : [],
    assignments:
      localSavedFilter &&
        localSavedFilter[id] &&
        localSavedFilter[id].assignments?.length > 0
        ? localSavedFilter[id].assignments
        : [],
    balconyType:
      localSavedFilter && localSavedFilter[id] && localSavedFilter[id].balconyType
        ? localSavedFilter[id].balconyType
        : [],
    isTwoLevels: localSavedFilter?.[id]?.isTwoLevels ?? "",
    masterBedroom: localSavedFilter?.[id]?.masterBedroom ?? "", 
  }

  const isMobile = useMedia("isMobile")
  useEffect(() => {
    setFilter(initialFilter)
    fetch({
      id,
      city,
      savedFilterXmlId,
      ...filterToParam(initialFilter, id, true),
      isGetApartments: true,
      isGetFilter: true
    });
    return () => {
      reset();
    };
  }, [id, city, savedFilterXmlId]);
  useEffect(() => {
    if (apartments.length > 0 && !haveApartments) {
      setHaveApartments(true)
    }
  }, [apartments]);

  const getComplexApartments = (params = []) => {
    getApartments(city, filterToParam(filter, id, true), savedFilterXmlId, params)
  }
  const handleResetFilter = () => {
    clearFilter()
    if (!isMobile) {
      getApartments(city, filterToParam(cleanFilter, id, true), savedFilterXmlId)
      return
    }
  }

  const handleSetFilter = (data, savedFilterXmlId) => {
    let tmpFilter = { ...filter, ...data }
    setFilter(tmpFilter);
    if (!isMobile) {
      getApartments(city, filterToParam(tmpFilter, id, true), savedFilterXmlId)
      return
    }
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      getCount(city, filterToParam(tmpFilter, id, true), savedFilterXmlId)
    }, 300);
  };
  const pricesRef = useRef(null);
  const aboutRef = useRef(null);
  const otherRef = useRef(null);
  const banksRef = useRef(null);
  const [activeNav, setActiveNav] = useState(null);
  const [haveApartments, setHaveApartments] = useState(false);
  const menuHeight = 165;

  const handleScroll = () => {
    let refs = compact([
      pricesRef.current && {
        name: "prices",
        bottom: pricesRef.current.getBoundingClientRect().bottom,
      },
      aboutRef.current && {
        name: "about",
        bottom: aboutRef.current.getBoundingClientRect().bottom,
      },
      otherRef.current && {
        name: "other",
        bottom: otherRef.current.getBoundingClientRect().bottom,
      },
      banksRef.current && {
        name: "banks",
        bottom: banksRef.current.getBoundingClientRect().bottom,
      },
    ]);
    refs = minBy(refs, (ref) => {
      if (ref.bottom - menuHeight > 0) {
        return ref.bottom;
      }
    });
    setActiveNav(refs?.name);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigation = useMemo(() => {
    let nav = [];
    if (filterType === "apartments") {
      nav.push({
        title: "Планировки и цены",
        ref: pricesRef,
        type: "prices",
      });
    }
    if (block && block.about.previewText.length > 0) {
      nav.push({
        title: "Описание",
        ref: aboutRef,
        type: "about",
      });
    }

    // if (otherBlocks.length > 0) {
    //   nav.push({
    //     title: "Инфраструктура",
    //     ref: aboutRef,
    //   });
    // }

    if (block && block.banks.length > 0) {
      nav.push({
        title: "Банки",
        ref: banksRef,
        type: "banks",
      });
    }
    if (otherBlocks?.length > 0) {
      nav.push({
        title: "Другие ЖК застройщика",
        ref: otherRef,
        type: "other",
      });
    }
    return nav;
  }, [block]);



  if (!isActive) return <FilterDisabled agent={agent} />;
  if (!block) return null;

  return (
    <div class="complex">
      <Block
        block={block}
        navigation={navigation}
        isClientMode={isClientMode}
        clients={clients}
        isPublic={true}
        addClientToFavorite={addClientToFavorite}
        removeClientFromFavorite={removeClientFromFavorite}
        fetchClients={fetchClients}
        activeNav={activeNav}
        haveApartments={haveApartments}
        savedFilterXmlId={savedFilterXmlId}
      />
      {filterType === "apartments" && <Apartments
        city={city}
        id={id}
        manager={block.manager}
        realtyFilter={realtyFilter}
        innerRef={pricesRef}
        isPublic={true}
        savedFilterXmlId={savedFilterXmlId}
        agent={agent}
        setHaveApartments={setHaveApartments}
        filter={filter}
        setFilter={handleSetFilter}
        clearFilter={handleResetFilter}
        filterData={filterData}
        apartments={apartments}
        getApartments={getComplexApartments}
        count={count}
      />}
      {media("isMobile") ?
        <div className="complex__collapse">
          <Collapse
            title={
              <>
                <span className="complex__collapse-icon">
                  <Desc />
                </span>
                Описание
              </>
            }
          >
            <About
              title={`О ЖК ${block.name}`}
              previewText={block.about.previewText}
              detailText={block.about.previewText}
              advantages={block.about.advantages}
              innerRef={aboutRef}
              isPublic={true}
            />
          </Collapse>
          {block.banks.length > 0 && (
            <Collapse
              title={
                <>
                  <span className="complex__collapse-icon">
                    <Bank />
                  </span>
                  Аккредитованные банки
                </>
              }
            >
              <Banks items={block.banks} innerRef={banksRef} blockId={id}/>
            </Collapse>
          )}
        </div> :

        <>
          <About
            title={`О ЖК ${block.name}`}
            previewText={block.about.previewText}
            detailText={block.about.previewText}
            advantages={block.about.advantages}
            innerRef={aboutRef}
            isPublic={true}
          />

          {block.banks.length > 0 && (
            <Banks items={block.banks} innerRef={banksRef} blockId={id} />
          )}
        </>
      }

      {false && otherBlocks.length > 0 ? (
        <OtherBlocks
          items={otherBlocks}
          innerRef={otherRef}
          city={city}
          isPublic={true}
          savedFilterXmlId={savedFilterXmlId}
          filterType={filterType}
        />
      ) : (
        <div className="margin-sect"></div>
      )}
      <Preloader loading={isFetching} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: filter => dispatch({ type: "COMPLEX_SET_FILTER", filter }),
  clearFilter: () => dispatch({ type: "COMPLEX_CLEAR_FILTER" }),
  fetch: params => dispatch(fetchComplex(params)),
  getApartments: (city, filter, savedFilterXmlId, params) => dispatch(getApartments(city, filter, savedFilterXmlId, params)),
  getCount: (city, filter, savedFilterXmlId) => dispatch(getCount(city, filter, savedFilterXmlId)),

  reset: () => dispatch(clearComplex()),
  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  removeFromFavorites: (city, id, type, whatPage) =>
    dispatch(removeFromFavorites(city, id, type, whatPage)),
  fetchClients: () => dispatch(fetchClients()),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type),
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type,
  ) =>
    dispatch(
      removeClientFromFavorite(
        id,
        clientId,
        client,
        favoriteId,
        whatPage,
        type,
      ),
    ),
});

const mapStateToProps = (state) => ({
  isFetching: state.complex.isFetching,
  error: state.complex.error,
  block: state.complex.data,
  isActive: state.complex.isActive,
  news: state.complex.news,
  agent: state.complex.agent,
  actions: state.complex.actions,
  otherBlocks: state.complex.otherBlocks,
  isClientMode: state.general.clientMode,
  userInfo: state.user.info,
  clients: state.clients.data,
  filter: state.complex.filter,
  filterData: state.complex.filterData,
  apartments: state.complex.apartments,
  count: state.complex.count,
});

export default connect(mapStateToProps, mapDispatchToProps)(Complex);
