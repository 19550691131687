import React from "react";
import { connect } from "react-redux";
import { fetchClients } from "../../actions/clients";
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from "../../actions/favorites";
import PopoverBlock from "./PopoverBlock";

const CardHeart = (props) => {
  const {
    removeFav,
    type,
    id,
    favorite,
    city,
    whatPage,
    addToFavorites
  } = props;


  return (
    <div
      className={`card__fav  card__fav_attached ${
        favorite.inFavorite ? "is-fav" : ""
      }`}
    >
      <div
        className="card__fav-add"
        onClick={(e) => {
          e.stopPropagation();
          if (favorite.inFavorite) {
            removeFav(city, id, type, whatPage);
            return;
          }
          addToFavorites(city, id, type, whatPage);
        }}
      >
        <svg className="icon icon_fav">
          <use xlinkHref="#fav"></use>
        </svg>
      </div>
      <PopoverBlock {...props} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  active: state.sort,
  allClients: state.clients.data,
});

const mapDispatchToProps = (dispatch) => ({
  removeFav: (city, id, type, whatPage) => {
    dispatch(removeFromFavorites(city, id, type, whatPage));
  },

  addToFavorites: (city, id, type, whatPage) =>
    dispatch(addToFavorites(city, id, type, whatPage)),
  addClientToFavorite: (id, clientId, client, favoriteId, whatPage, type) =>
    dispatch(
      addClientToFavorite(id, clientId, client, favoriteId, whatPage, type)
    ),
  removeClientFromFavorite: (
    id,
    clientId,
    client,
    favoriteId,
    whatPage,
    type
  ) =>
    dispatch(
      removeClientFromFavorite(id, clientId, client, favoriteId, whatPage, type)
    ),
  fetchClients: (city) => dispatch(fetchClients(city)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardHeart);
