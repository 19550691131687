import React from "react";
import PrimaryTopFilters from "./PrimaryTopFilters";
import PrimaryBottomFilters from "./PrimaryBottomFilters";

const PrimaryMapsFilters = props => <div className="primary-map-filter">
  <PrimaryTopFilters {...props} />
  <PrimaryBottomFilters {...props} />
</div>

export default PrimaryMapsFilters
