import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';
import promoSvg from '../../assets/images/hp/self.svg';
import { selfData } from './selfData';
import Accordeon from '../../components/core/Accordeon';
import useMedia from '../../hooks/useMedia';


const CapaItem = ({item}) => {
  const {header, list} = item;
  return (
    <div className="hp__capa-item">
      <h3 className="hp__capa-header h3">
        {header}
      </h3>
      <div className="hp__capa-list">
        {
          list.map((it) => (
            <div className={`hp__capa-list-item hp__capa-list-item_${it.icon}`}>
              <h4 className="hp__h4 hp__capa-title">{it.title}</h4>
              <p className="hp__capa-text hp__become-text medium-text">{it.text}</p>
            </div>
          ))
        }
      </div>
   </div>
  );
};

const Self = () => {
  const isMobile = useMedia("isMobile");

  const utms = useLocation().search;
  const [firstPicChecker, setFirstPicChecker] = useState(false);
  const [secondPicChecker, setSecondPicChecker] = useState(1);

  useEffect(() => {
    const handleScroll = event => {
      console.log('window.scrollY', window.scrollY);

      setFirstPicChecker(window.scrollY > picBreakpoint.first);

      if (window.scrollY < picBreakpoint.second) {
        setSecondPicChecker(1);
      }

      if (window.scrollY > picBreakpoint.second) {
        setSecondPicChecker(2);
      }

      if (window.scrollY > picBreakpoint.third) {
        setSecondPicChecker(3);
      }

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const picBreakpoint = {
    'first': 1100,
    'second': 2500,
    'third': 2850,
  };


  return (
    <>
      <section className="hp__promo">
        <div className="wrapper">
          <object type="image/svg+xml" className="hp__promo-img hp__promo-img_self" data={promoSvg} alt="" />

          <div className="wrapper__center">
            <div className="hp__promo-content hp__promo-content_self">
              <span className="hp__label color-red caps">пан партнер</span>
              <h1 className="hp__h1 h1">ТОП-5 сервисов для самозанятого риелтора</h1>
              <p className="hp__sign color-brand-3 big-text">Зарабатывайте легко, быстро и безопасно!</p>
              <Link to={`/trial${utms}`} className="hp__promo-btn button button_view_default" type="button">Доступ без регистрации</Link>
            </div>
          </div>
        </div>
      </section>


      <div className="wrapper">
        <div className="wrapper__center">
          <section className="hp__capa hp__section-margin">

            <div className="hp__title">
              <h2 className="h2 hp__h2">Откройте для себя новые возможности</h2>
            </div>

            <div className="hp__scrolling">
              <div className="hp__scrolling-pic">
                <img src={require(`../../assets/images/hp/self/screen-01.svg`).default} alt="" className={`hp__scrolling-img${firstPicChecker ? ` hp__scrolling-img_hidden` : ``}`} />
                <img src={require(`../../assets/images/hp/self/screen-02.svg`).default} alt="" className={`hp__scrolling-img${!firstPicChecker ? ` hp__scrolling-img_hidden` : ``}`} />
              </div>

              <div className="hp__scrolling-info">
                {isMobile && <img src={require(`../../assets/images/hp/self/screen-01.svg`).default} alt="" className={`hp__scrolling-img`} />}
                <CapaItem item={selfData[0]} />
                {isMobile && <img src={require(`../../assets/images/hp/self/screen-02.svg`).default} alt="" className={`hp__scrolling-img`} />}
                <CapaItem item={selfData[1]} />
              </div>
            </div>

          </section>

          <section className="hp__banner hp__banner_type_2">
            <div className="hp__banner-content">
              <h2 className="h2 hp__banner-h2">Получите 100% комиссионное вознаграждение за сделки!</h2>
            </div>

            <div className="hp__banner-nav">
              <Link to={`/register/selfemployed${utms}`} className="hp__banner-btn button button_view_default">Узнать больше</Link>
            </div>
          </section>

          <section className="hp__capa hp__section-margin">

            <div className="hp__scrolling">

              <div className="hp__scrolling-info">
                {isMobile && <img src={require(`../../assets/images/hp/self/comp-01.svg`).default} alt="" className={`hp__scrolling-img`} />}
                <CapaItem item={selfData[2]} />

                {isMobile && <img src={require(`../../assets/images/hp/self/comp-02.svg`).default} alt="" className={`hp__scrolling-img`} />}
                <CapaItem item={selfData[3]} />

                {isMobile && <img src={require(`../../assets/images/hp/self/comp-03.svg`).default} alt="" className={`hp__scrolling-img`} />}
                <CapaItem item={selfData[4]} />
              </div>

              <div className="hp__scrolling-pic">
                <img src={require(`../../assets/images/hp/self/comp-01.svg`).default} alt="" className={`hp__scrolling-img${secondPicChecker !== 1 ? ` hp__scrolling-img_hidden` : ``}`} />
                <img src={require(`../../assets/images/hp/self/comp-02.svg`).default} alt="" className={`hp__scrolling-img${secondPicChecker !== 2 ? ` hp__scrolling-img_hidden` : ``}`} />
                <img src={require(`../../assets/images/hp/self/comp-03.svg`).default} alt="" className={`hp__scrolling-img${secondPicChecker !== 3 ? ` hp__scrolling-img_hidden` : ``}`} />
              </div>
            </div>

            <Link to={`/register/selfemployed${utms}`} className="hp__scrolling-btn button button_view_default">Открыть доступ</Link>


          </section>


          <section className="hp__develop hp__section-margin">

            <div className="hp__title">
              <h2 className="h2 hp__h2">Больше сервисов для комфортной работы</h2>
              {/* <a href="#" className="hp__anchor hp__develop-anchor link">
                Все сервисы
                <div className="hp__develop-icon">
                  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.72626 7.325L11.7739 4.175" stroke="#FF2D23" strokeLinecap="round"/>
                    <path d="M11.7737 3.825L8.72612 0.675" stroke="#FF2D23" strokeLinecap="round"/>
                    <path d="M11.9643 3.99999L0.75 3.99999" stroke="#FF2D23" strokeLinecap="round"/>
                  </svg>
                </div>
              </a> */}
            </div>
            <div className="hp__develop-grid hp__develop-grid_self">
            {
              DevelopItems.map(item => (
                <a className="hp__develop-item" href={item.link} target={`_blank`} key={`about-${item.id}`}>
                  <div className="hp__develop-square">
                    <img className="hp__develop-pic" src={require(`../../assets/images/hp/develop/${item.id}.svg`).default} alt="" />
                    <img className="hp__develop-pic-revert" src={require(`../../assets/images/hp/develop-revert/${item.id}.svg`).default} alt="" />
                  </div>

                  <div className="hp__develop-content">
                    <h4 className="h5 hp__develop-h4">{item.title}</h4>
                    <p className="big-text-sm hp__develop-descr">{item.descr}</p>
                    <span className="hp__develop-item-anchor link">
                      Подробнее
                    </span>
                  </div>
                </a>
                ))
              }

            </div>

            {
              // isMobile && <a href="#" className="button hp__develop-all" target={`_blank`}>Все сервисы</a>
            }

          </section>

          <section className="hp__banner hp__banner_type_3">
            <div className="hp__banner-content">
              <h2 className="h2 hp__banner-h2">Работать на себя в сфере недвижимости теперь проще!</h2>
            </div>

            <div className="hp__banner-nav">
              <Link to={`/register/selfemployed${utms}`} className="hp__banner-btn button button_view_default">Получить доступ</Link>
            </div>
          </section>

          <section className="hp__news hp__section-margin">
            <div className="hp__title">
              <h2 className="h2 hp__h2">Вопросы и ответы</h2>
            </div>

            <div className="hp__faq">
              <img className="hp__faq-pic" src={require(`../../assets/images/hp/faq.svg`).default} alt="" />

              <div className="hp__faq-block">
                {
                  FaqItems.map(item =>
                    <Accordeon title={item.title} withClass="hp__accord">
                      <p>{item.text}</p>
                   </Accordeon>
                  )
                }
              </div>
            </div>
          </section>

        </div>
      </div>
    </>

  );
};

const FaqItems = [
  {
    title: `Могу ли я проводить сделки по всем типам недвижимости, используя эти сервисы?`,
    text: `Да, можно проводить сделки по новостройкам, вторичной, загородной, коммерческой  недвижимости, уступкам и апартаментам`
  },
  {
    title: `Есть ли возможность работать со всеми застройщиками?`,
    text: `С большинством застройщиков — да, но есть некоторые ограничения, т.к. не все застройщики работают с самозанятыми`
  },
  {
    title: `Могу ли я пользоваться сервисами для самозанятых, если уже работаю риелтором в другом агентстве?`,
    text: `Нет. Данный сервис предоставляется исключительно самозанятым риелторам, которые не сотрудничают с агентствами недвижимости, банками или застройщиками напрямую`
  },
  {
    title: `Как быстро я получу комиссионное вознаграждение по сделкам с помощью этого ресурса?`,
    text: `Вы получаете авансирование в течение 3 дней — с момента подтверждения оплаты вашим клиентом`
  },
  // {
  //   title: `Кто предоставляет доступ к сервисам?`,
  //   text: `Данный проект — это коллаборация ПАН (ПЕРВОЕ АГЕНТСТВО НЕДВИЖИМОСТИ) и разработчиков платформы Top:Realtors`
  // },
];

const DevelopItems = [

  {
    id: `06`,
    title: `Бесплатная оценка недвижимости`,
    descr: `Возращаем до 3 000 р. за услугу оценки`,
    link: `https://pan-ocenka.ru/`,
  },
  {
    id: `05`,
    title: `Ипотечное вознаграждение`,
    descr: `Получите дополнительные 0,3% за сделки с ипотекой`,
    link: `https://panpartner.ru/cashback`,
  },
  {
    id: `03`,
    title: `Продающий показ новостройки`,
    descr: `Закройте клиента на сделку прямо на объекте`,
    link: `https://panpartner.ru/autopan`,
  },
  {
    id: `09`,
    title: `Обучающие экскурсии`,
    descr: `Все о новостройках — от экспертов рынка`,
    link: `https://panpartner.ru/autopanorama`,
  },
  {
    id: `10`,
    title: `Расширенное бронирование`,
    descr: `Закройте клиента на сделку вместе с опытным брокером`,
    link: `https://panpartner.ru/adv-booking`,
  },
  {
    id: `01`,
    title: `Бесплатные переговорные комнаты`,
    descr: `В 7 районах города — и рядом с метро`,
    link: `https://panpartner.ru/pan-comfort/`,
  },

];

export default Self;
