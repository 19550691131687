import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';

import { ListIcon, TileIcon } from '../core/Tabs/icons';
import StatelessTabs from '../core/Tabs/StatelessTabs';
import CardsList from '../Public/markup/CardsList';
import NotFound from '../Public/markup/detail/NotFound';
import Tags from '../Public/markup/Tags';
import VillageFilterForm from '../Public/markup/VillageFilterForm';


import { TableApartments } from './ApartmentList';
import SuburbanCardNew from '../Public/markup/Containers/SuburbanCardNew';
import { ShowMore } from '../core/Table2';
import useMedia from '../../hooks/useMedia';
import SearchFilter from '../FilterRealty/SearchFilter';
import { FilterSearch } from '../FilterRealty/FilterSearch';
import SuburbanProjectsTopFilter from '../Public/markup/SuburbanProjectsTopFilter';
import SuburbanProjectsBottomFilter from '../Public/markup/SuburbanProjectsBottomFilter';
import ProjectCard from '../Public/markup/Containers/ProjectCard';

const viewTabs = [
  { title: 'Список', icon: ListIcon },
  { title: 'Плитка', icon: TileIcon },
  // { title: 'Шахматка', icon: ChessIcon },
];

const SuburbanProjectContent = memo(props => {
  const [activeView, setActiveView] = useState(0);
  const [showFilter, toggleFilter] = useState(false);
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  const {
    handleFilter,
    isLoading,
    filter = {},
    data: {
      filter: filterData = {},
      headers,
      items,
      limit,
      suburban
    },
    showSkeleton,
    setFilterAndExclude,
    filterBadges,
    removeBadge,
    resetFilter,
    hasNextPage,
    handleLoadMore,
    moreIsLoading,
    tab,
    villageId,
    city,
    externalSorting
  } = props;


  return (
    <>

      <div className={`sf__filter filter__max form smartfilter-wrapper ${!isLaptop ? "" : "flex"}`}>

        <SearchFilter
          placeholder="Название проекта, материал"
          // handleChange={v => handleFilter(searchFilter, v)}
          // value={filter[searchFilter] ?? ""}
          // wrapperClass="sf__filter-item"
        />

        {/*<FilterSearch*/}
        {/*  placeholder="Название проекта, материал"*/}
        {/*  filterData={filterData}*/}
        {/*  config={{*/}
        {/*    fields: {*/}
        {/*      blocks: {*/}
        {/*        addLink: ({ xmlId, id }) => `/realty/${city}/complex/${city === "dxb" ? xmlId : id}${window.location.pathname.includes("commercial") ? "?type=commercial" : ""}`*/}
        {/*      },*/}
        {/*      villages: {*/}
        {/*        addLink: ({ id }) => `/suburban/spb/${id}`*/}
        {/*      },*/}
        {/*      builders: {},*/}
        {/*      districts: {},*/}
        {/*      microDistricts: {},*/}
        {/*      subways: {},*/}

        {/*    }*/}
        {/*  }}*/}

        {/*  toggleFilter={() => toggleFilter(prev => !prev)}*/}
        {/*  filterValues={filter}*/}
        {/*  handleFilter={setFilterAndExclude}*/}
        {/*  hideInfoButton*/}

        {/*/>*/}
        <SuburbanProjectsTopFilter
          filterData={filterData}
          filter={filter}

          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          isLoading={isLoading}
          wrapperClass=" "
        />

        <button
          className="button button_type_reveal-filter white-button"
          type="button"
          onClick={() => toggleFilter(!showFilter)}
        // style={{ display: showFilter ? "none" : "block" }}
        >
          <span >Все фильтры</span>
        </button>
        {showFilter && <SuburbanProjectsBottomFilter
          filterData={filterData}
          filter={filter}

          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          isLoading={isLoading}
          wrapperClass=" "
        />}
      </div>
      <div className="form__row" style={{ margin: "16px 0" }}>
        <div className="index-search__show-booked">
          <label className="switch" htmlFor="filter-booked">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                id="filter-booked"
                checked={filter.status.includes("3")}
                onChange={e => handleFilter("status", "3")}
              />
              <div
                className="switch__input"
                htmlFor="filter-booked"
              />
              <div className="switch__marker" />
            </div>
            <span className="switch__label">
              Показать забронированные
            </span>
          </label>
        </div>
      </div>

      <Tags
        badges={filterBadges}
        removeBadge={removeBadge}
        resetFilter={resetFilter}
      />
      {!!items?.length && (
        <StatelessTabs
          tabs={viewTabs}
          activeTab={activeView}
          setActiveTab={setActiveView}
          withIcons
          onClick={(i) =>
            i === 2
              ? window.open(
                `/realty/${city}/chess/${villageId}`,
                '_blank',
              )
              : setActiveView(i)
          }
        />
      )}

      <div className="complex-results-wrapper">
        {!!items?.length && (
          <div className="complex__appartments table2">
            <div className="complex__appartments-tab active">
              <div style={{ display: 'block' }}>
                {activeView === 1 ? (
                  <CardsList
                    withoutBtnsList
                    items={items}
                    limit={limit}
                    isLoading={showSkeleton}
                    cardComponent={ProjectCard}
                    getLink={record=>`/build-house/${record.xmlId}`}
                    listClass="sf__cards-list sf__cards-list_flats"
                  />
                ) : (
                  <TableApartments tabTitle={'ТаунХаус'} items={items} headers={headers} getLink={record=>`/build-house/${record.xmlId}`} isNewSuburban parcelId={suburban.id} externalSorting={externalSorting} />
                )}
                {hasNextPage && <ShowMore onClick={handleLoadMore} loading={moreIsLoading} />} 
              </div>
            </div>
          </div>
        )}
      </div>
      {!items?.length && <NotFound />}
    </>
  );
});

export default SuburbanProjectContent;
