import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { addContestParticipant, getContests, isContestParticipant } from '../../actions/contest';
import { isExistResult } from '../../actions/forms';
import SubmitButton from "../../components/SubmitButton";
import { infoToast, standartErrorToast } from '../../components/toasts';
import { scrollToRef } from "../../utils";
import './index.scss';


SwiperCore.use([Navigation]);

const PathDot = (props) => {
  const {value, isActive, index, onClickHandler} = props;
  return (
    <li
      className={`ten__path-dot${isActive ? ` active` : ``}`}
      onClick={() => onClickHandler(index)}
    >
      {value}
    </li>
  );
};

const PathBlock = (props) => {
  const {year, title, text, isActive} = props;
  return (
    <div className={`ten__path-block${isActive ? ` active` : ``}`}>
      <span className="ten__path-year">{year}</span>
      {
        //<button type="button" className="button ten__path-btn">+</button>
      }
      <p className="ten__path-title h4">{title}</p>
      <p className="ten__path-text big-text">{text}</p>
    </div>
  );
};

const Prize = (props) => {
  const {place, text, imgName} = props;
  return (
    <div className="ten__slider-item">
      <span className={`ten__label h4${place <= 3 ? ` ten__label_blue` : ``}`}>{`${place} место`}</span>

      <div className="ten__slider-imgbox">
        <img
          src={require(`../../assets/images/ten/${imgName}`).default}
          alt={text}
        />
      </div>
      <p className="ten__slider-title h4">{text}</p>
    </div>
  );
};

const Blur = (props) => {
  return (
    <div className="ten__box-blurwrapper">
      <p className="ten__box-blurtext">Здесь будет текст</p>
        <div className="ten__box-blur">
          {props.children}
        </div>
      </div>
  )
};


const Ten = (props) => {

  const {user = {}, state, isAuthorized, isTest } = props;

  // const [selectedCourse, setSelectedCourse] = useState(null);

  // const [openSchoolModal, closeSchoolModal] = useModal(
  //   ({ in: open, onExited }) => (
  //     <Modal close={closeSchoolModal} classes="modal_task">
  //     </Modal>
  //   ),[selectedCourse]
  // );

  const [accordState, setAccordState] = useState(null);
  const [accordState2, setAccordState2] = useState(null);
  const [accordState3, setAccordState3] = useState(null);
  const [accordState4, setAccordState4] = useState(null);
  const [accordState5, setAccordState5] = useState(null);
  const [isExistRes, setiIsExistRes] = useState(false)
  // const [isMouseOver, setIsMouseOver] = useState(false);

  // const setBodyState = () => {
  //   isMouseOver ?
  //   document.body.classList.add('stop-scrolling') :
  //   document.body.classList.remove('stop-scrolling');
  // };
  const deb = useRef(null)
  const scrollTimer = useRef(null)
  const scrollTo = (offset)=> {
    window.scroll({
      top: offset,
      behavior: "smooth",
    });
  }
  const [contest, setContest] = useState({});
  const [isFetching, setFetching] = useState(true)
  const addParticipant = (id)=> {
    setFetching(true);
    addContestParticipant(id).then(
      data => {
        setContest(prev=>({...prev, isContestParticipant: true}))
        setFetching(false);
        infoToast(<div>{data.message}</div>)
      },
      error => {
        setFetching(false);
        standartErrorToast(error)
      },
    );
  }
  useEffect(() => {
    if(isAuthorized){
      isExistResult(29).then(resp =>setiIsExistRes(resp.isExistResult))
      getContests().then(resp=> {
        setFetching(true);
        setContest(resp.contests[0])
        return resp.contests[0]
      }).then(contest=>{
        isContestParticipant(contest.id).then(resp=> {
          setContest(prev=>({...prev, isContestParticipant: resp.isContestParticipant}))
          setFetching(false);
        })
      })
    }
  }, [isAuthorized])
  useEffect(() => {
    const headerHeight = 70
    const refOffset = pathRef.current.offsetTop
    const refHeight = pathRef.current.offsetHeight
    const callBack = evt => {
      let deltaY = evt.deltaY
      let currentMousePosition = evt.pageY + deltaY
      let isMouseOver = (currentMousePosition - refOffset) > 0 && (currentMousePosition - refOffset) < refHeight
      let scrollCondition = isMouseOver && ((pathStep !== 10 && evt.deltaY > 0) || (pathStep !== 0 && evt.deltaY < 0))
      let pathYCoord = pathRef.current.getBoundingClientRect().y

      if (scrollCondition) {
        evt.preventDefault()
        if (pathYCoord < 55 || pathYCoord > 75 && !scrollTimer.current) { // убираем лишний скролл, если уже пролистали до видимой области, 20 пикселей небольшой запас
          try {
            scrollTo(pathRef.current.offsetTop + headerHeight)
            scrollTimer.current = setTimeout(() => {
              scrollTimer.current = null
            }, 300);
          } catch {
          }
          return
        }
        if (!deb.current) {
          deb.current = setTimeout(() => {
            deb.current = null
          }, 300);
          evt.deltaY > 0 ? nextStep() : prevStep();
        }
      }
    };
    document.addEventListener("wheel", callBack, { passive: false });

    return () => {
      document.removeEventListener("wheel", callBack);
    };
  });

const [pathStep, setPathStep] = useState(0);
const trackRef = useRef(null)

const prevStep = () => {
  setPathStep(prevState => {
    if (prevState > 0) {
      return --prevState;
    }
    return prevState;
  });
};

const nextStep = () => {
  setPathStep(prevState => {
    if (prevState < pathData.length - 1) {
      return ++prevState;
    }
    return prevState;
  });
};

const pathData = [
  {
    year: `2010`,
    title: `Выход на рынок новостроек.`,
    text: `Начало пути. На старт, внимание, поехали!`,
    backgroundCoord: 520,
  },
  {
    year: `2011`,
    title: `Стабильно продаем новостройки. Но этого мало. `,
    text: `Забегаем в рынок вторички. И делаем шаг, который определит все будущие годы: открываем отдел партнерских продаж ПАН Партнер.`,
    backgroundCoord: -480,
  },
  {
    year: `2012`,
    title: `Нас отмечают на «Санкт-Петербургском Всероссийском жилищном форуме» — мы горды, и набираем скорость.`,
    text: `Отпраздновали 300-го клиента. Да это же 10+ клиентов в месяц стабильно! `,
    backgroundCoord: -1380,
  },
  {
    year: `2013`,
    title: `Начинаем развивать направление ипотеки.`,
    text: `Помогаем клиентам получить кредит в ведущих банках Петербурга.`,
    backgroundCoord: -2280,
  },
  {
    year: `2014`,
    title: `Запускаем ипотечный центр.`,
    text: `Уже на следующий год запускаем полноценный Ипотечный центр. Набираем кейсы, и знаем, как одобрить даже самого сложного клиента.`,
    backgroundCoord: -3020,
  },
  {
    year: `2015`,
    title: `Нам 5 лет, нас 30 человек.`,
    text: `Мы не только живы после кризиса 2014-го и мощного спада спроса, мы становимся сильнее и “едем” дальше. На Москву.`,
    backgroundCoord: -3880,
  },
  {
    year: `2016`,
    title: `Добрались до Москвы.`,
    text: `Запартнёрились с московскими застройщиками и открыли продажи московских новостроек.`,
    backgroundCoord: -4730,
  },
  {
    year: `2017`,
    title: `Отдел партнерских продаж стабилен, как швейцарские часы.`,
    text: `Прирастает от месяца к месяцу, и признан среди партнерских агентств первым сервисом с «человеческим лицом».`,
    backgroundCoord: -5680,
  },
  {
    year: `2018`,
    title: `У первых в Питере по-настоящему заработал квартирный Trade-In.`,
    text: `Продаем старые квартиры клиентов и помогаем покупать новостройки без комиссии.`,
    backgroundCoord: -6450,
  },
  {
    year: `2019`,
    title: `Ура, у нас есть портал Panpartner.ru — система бронирования новостроек для профессионалов.`,
    text: `Портал хочет стать лучшей системой бронирования.`,
    backgroundCoord: -7250,
  },
  {
    year: `2020`,
    title: `Пандемия и кризис сделали нас сильнее.`,
    text: `Мы поняли, что легко перенесем процессы в онлайн без потери качества сервиса.`,
    backgroundCoord: -8190,
  },
];

const prizesData = [
  {
    place: 1,
    text: `Умные часы Apple Watch`,
    imgName: `watch.png`,
  },
  {
    place: 2,
    text: `Планшет Samsung Galaxy Tab A 10.1`,
    imgName: `tablet.png`,
  },
  {
    place: 3,
    text: `Беспроводные наушники Apple AirPods 2`,
    imgName: `airpods.png`,
  },
  {
    place: 4,
    text: `Электронная книга PocketBook 616 8 ГБ`,
    imgName: `ebook.png`,
  },
  {
    place: 5,
    text: `Портативная колонка JBL Charge 4`,
    imgName: `jbl.png`,
  },
  {
    place: 6,
    text: `Видеорегистратор VIPER G55 GPS/Glonass`,
    imgName: `viper.png`,
  },
  {
    place: 7,
    text: `Умная колонка Яндекс.Станция Мини`,
    imgName: `ya-station.png`,
  },
  {
    place: 8,
    text: `Умный браслет Xiaomi Mi Band 5`,
    imgName: `bracelet.png`,
  },
  {
    place: 9,
    text: `Аккумулятор Xiaomi, Power Bank`,
    imgName: `powerbank.png`,
  },
  {
    place: 10,
    text: `Подарочная ручка ПАН`,
    imgName: `pen.png`,
  },
];

const regRef = useRef();
const pathRef = useRef();


useEffect(() => {
  switch (window.location.hash) {
  case '#track-2':
    setAccordState(true);
    break;

  case '#track-3':
    setAccordState2(true);
    break;

  case '#track-4':
    setAccordState3(true);
    break;

  case '#track-5':
    setAccordState4(true);
    break;

  case '#track-6':
    setAccordState5(true);
    break;

  default:
    break;
  }
}, []);

  return (
    <section className="ten section section_view_white">
        <main className="ten__main">
          <div className="ten__dark">
            <div className="ten__container">
              <div className="ten__first-screen">
                <section className="ten__promo">
                  <div className="ten__h1 h1">

                    <div className="ten__h1-content">
                      <span className="ten__h1-highlighted">10 лет</span>
                      <span className="ten__h1-content-text">ПАН</span>
                    </div>

                    <div className="ten__promo-slogan rotating">
                      <img
                        src={require('../../assets/images/ten/slogan.svg').default}
                        alt=""
                      />
                    </div>

                  </div>
                </section>
                <div className="ten__mouse">
                  <img
                    className=""
                    src={require('../../assets/images/ten/mouse.svg').default}
                    alt=""
                  />
                  <span className="ten__mouse-arrow">
                    <svg className="icon icon_arrow">
                      <use xlinkHref="#arrow-right-clean"></use>
                    </svg>
                  </span>
                </div>
              </div>
              <section className="ten__start">
                <div className="ten__start-block">
                  <p className="ten__title ten__start-title">Будь первым вместе с&nbsp;нами</p>
                  <p className="ten__start-text big-text">Прими участие в космической гонке за первенство и лучший приз</p>
                  <button type="button" className=" ten__start-btn button button_view_default h4" onClick={() => scrollToRef(regRef, 0)}>На старт</button>
                </div>
              </section>

            </div>


            <section className="ten__info">
              <div className="ten__container">

                <p className="ten__title ten__info-title">Мы уже 10 лет в гонке, и вот какие у нас результаты</p>

                <div className="ten__info-content">
                  <div className="ten__info-block">
                    <p className="ten__info-descr">
                      <b>В&nbsp;ноябре 2010 года</b> трое амбициозных профессионалов <b>рынка недвижимости</b> начали свой путь <b>к первенству</b> в Петербурге, <b>назвавшись «Первым агентством недвижимости».</b>
                    </p>
                    <p className="ten__info-descr">
                      <b>
                        С того дня и до сегодняшнего мы росли и крепли, собрали мощную выносливую команду,
                        отладили бизнес-процессы и создали сеть из 13 345 партнерских агентств.
                      </b>
                    </p>
                  </div>

                  <div className="ten__info-aside">
                    <b className="ten__info-counter">13 345</b>
                    <p className="ten__info-sign">Партнерских агентств сегодня с нами.</p>
                  </div>
                </div>

              </div>

            </section>
          </div>

          <section
            className="ten__path"
            ref={pathRef}
            onMouseEnter={(evt) => {
              // setIsMouseOver(true);
              //setBodyState();
            }}
            onMouseLeave={(evt) => {
              // setIsMouseOver(false);
              //setBodyState();
            }}
          >
            <div className="ten__path-slogan rotating">
            <img
            src={require('../../assets/images/ten/slogan-2.svg').default}
            alt=""
            />
            </div>

            <div
              className={`ten__path-container ten__path-container_${pathData[pathStep].year}`}

              onScroll={() => nextStep()}
            >
              <div className="wrapper">
                {
                  pathData.map((item, i) => {
                    return (
                      <PathBlock
                        year={item.year}
                        title={item.title}
                        text={item.text}
                        key={`pathblock-${i}`}
                        isActive={i == pathStep}
                      />
                    )

                  })
                }
                <div className="ten__path-block">
                  <span className="ten__path-year">2020</span>
                  <button type="button" className="button ten__path-btn">+</button>
                  <p className="ten__path-title h4">Пандемия и кризис сделали нас сильнее.</p>
                  <p className="ten__path-text big-text">Мы поняли, что легко перенесем процессы в онлайн без потери качества сервиса.</p>
                </div>


              </div>

            </div>

            <div className="ten__path-rail">
              <ul className="ten__path-dots">
                {
                  pathData.map((item, i) => {
                    return (
                      <PathDot
                        value={item.year}
                        index={i}
                        key={`pathdot-${i}`}
                        isActive={i == pathStep}
                        onClickHandler={setPathStep}
                      />
                    )
                  })
                }

              </ul>
              <div className="ten__path-bar" style={{width: `${100 / pathData.length * (pathStep + 1)}%`}}></div>
            </div>

            <div
              className="ten__mouse ten__mouse_static ten__mouse_black"
              onClick={() => scrollToRef(regRef, 0)}
            >
              <p className="ten__mouse-text">Пропустить</p>
              <img
                className=""
                src={require('../../assets/images/ten/mouse-black.svg').default}
                alt=""
              />
              <span className="ten__mouse-arrow">
                <svg className="icon icon_arrow">
                  <use xlinkHref="#arrow-right-clean"></use>
                </svg>
              </span>

            </div>

          </section>

          <section className="ten__regulations" ref={regRef}>
            <article className="ten__box">
              <div className="ten__box-header-wrapper">
                <div className="ten__box-header ten__box-header_start">
                  <h3 className="ten__box-title">Правила участия в гонках</h3>
                </div>
              </div>

              <div className="ten__box-body">

                <div className="ten__box-row">
                  <div className="ten__box-col">
                    <p className="ten__box-sign ten__gray big-text">Старт</p>
                    <p className="h3">Гонки стартуют 9 ноября и длятся до 10 декабря включительно</p>
                    <div className="ten__box-info">
                      <p>Гонками мы назвали акции ПАН в&nbsp;ноябре, а самых активных участников наградим призами после окончания срока акции.</p>
                    </div>
                  </div>
                  <div className="ten__box-col">
                    <p className="ten__box-sign ten__gray big-text">Выберите любую гоночную трассу</p>
                    <p className="h3">1 трасса — 1 главный приз и несколько дополнительных призов</p>
                    <div className="ten__box-info">
                      <p>Гоночными трассами мы назвали отделы ПАН, которые организуют акции.</p>
                    </div>
                  </div>
                </div>

                <button type="button" onClick={()=>scrollTo(trackRef.current.offsetTop)} className="button button_view_default ten__button">К выбору трасс</button>


              </div>
            </article>

            <span className="track-anchor" id="track-1"></span>
            <article className="ten__box ten__top" ref={trackRef}>
              <div className="ten__box-header ten__box-header_top">
                <h3 className="ten__box-title">Трасса партнерских продаж</h3>
                <p className="ten__box-subtitle">Награждаем ТОП—10 участников гонки</p>
                <img
                  className="ten__box-bg-img"
                  src={require('../../assets/images/ten/bg-top10.svg').default}
                  alt=""
                />
              </div>
              <div className="ten__box-body">

                <div className="ten__box-row">
                  <span className="ten__box-counter">01.</span>
                  <div className="ten__box-item">
                    <p className="ten__box-action">Зарабатывай баллы на <span className="ten__red">Panpartner.ru</span></p>
                    <div className="ten__box-inforow">
                      <div className="ten__box-info">
                        <span className="ten__label h4 ten__label_bold">100 баллов</span>
                        <p className="ten__box-graytext big-text">Одна сделка через ПАН</p>
                      </div>
                      <div className="ten__box-info">
                        <span className="ten__label h4 ten__label_bold">от 10 до 30 баллов</span>
                        <p className="ten__box-graytext big-text">Каждое действие на портале Panpartner.ru</p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="ten__box-row">
                  <span className="ten__box-counter">02.</span>
                  <div className="ten__box-item">
                    <p className="ten__box-action">Еженедельно сверяйся с общим рейтингом на портале <span className="ten__red">Panpartner.ru</span></p>
                  </div>
                </div>

                <div className="ten__box-row">
                  <span className="ten__box-counter">03.</span>
                  <div className="ten__box-item">
                    <p className="ten__box-action">Меняй баллы на призы</p>



                    {
                      // <div className="ten__slider">
                      //   <div className="ten__slider-item">
                      //     <span className="ten__label h4 ten__label_blue">1 место</span>
                      //     <p className="ten__slider-title h4">Умные часы Apple Watch</p>
                      //     <div className="ten__slider-imgbox">
                      //       <img
                      //         src={require('../../assets/images/ten/watch.png')}
                      //         alt=""
                      //       />
                      //     </div>
                      //
                      //   </div>
                      //
                      //   <div className="ten__slider-item">
                      //     <span className="ten__label h4 ten__label_blue">2 место</span>
                      //     <p className="ten__slider-title h4">Планшет Samsung Galaxy Tab A 10.1</p>
                      //     <div className="ten__slider-imgbox">
                      //       <img
                      //         src={require('../../assets/images/ten/tablet.png')}
                      //         alt=""
                      //       />
                      //     </div>
                      //   </div>
                      //
                      //   <div className="ten__slider-item">
                      //     <span className="ten__label h4 ten__label_blue">3 место</span>
                      //     <p className="ten__slider-title h4">Беспроводные наушники Apple AirPods 2</p>
                      //     <div className="ten__slider-imgbox">
                      //       <img
                      //         src={require('../../assets/images/ten/airpods.png')}
                      //         alt=""
                      //       />
                      //     </div>
                      //   </div>
                      //
                      //   <div className="ten__slider-item">
                      //     <span className="ten__label h4">4 место</span>
                      //     <p className="ten__slider-title h4">Электронная книга PocketBook 616 8 ГБ</p>
                      //     <div className="ten__slider-imgbox">
                      //       <img
                      //         src={require('../../assets/images/ten/ebook.png')}
                      //         alt=""
                      //       />
                      //     </div>
                      //   </div>
                      // </div>
                    }

                  </div>
                </div>

                <div className="ten__box-row">
                  <div class="slider-nav">
                    <ul class="slider-nav__wrapper">
                      <li
                        class="slider-nav__item prev prev-slide"
                      >
                        <svg class="icon icon-dark_stroke">
                          <use xlinkHref="#icon-left_arrow"></use>
                        </svg>
                      </li>
                      <li
                        class="slider-nav__item next next-slide"
                      >
                        <svg class="icon icon-dark_stroke">
                          <use xlinkHref="#icon-right_arrow"></use>
                        </svg>
                      </li>
                    </ul>
                  </div>
                  <Swiper
                    navigation= {{
                      nextEl: '.next-slide',
                      prevEl: '.prev-slide'
                    }}
                    spaceBetween={0}
                    slidesPerView={4}
                    className={"ten__slider"}
                  >

                  {prizesData.map((it, i) =>
                    <SwiperSlide>
                      <Prize text={it.text} place={it.place} imgName={it.imgName} key={`slide-${i}`} />
                    </SwiperSlide>)}
                  </Swiper>
                </div>



                {isAuthorized && <SubmitButton type="button" disabled={contest.isContestParticipant} isLoading={isFetching} onClick={() => addParticipant(contest.id)} className="button button_view_default ten__button">
              {contest.isContestParticipant? "Уже участвуете" : "Участвовать"}
              </SubmitButton>}
                {/* <button type="button"  onClick={()=>addParticipant(contest.id)}className="button button_view_default ten__button">Участвовать</button> */}

              </div>
            </article>

            <span className="track-anchor" id="track-2"></span>
            <article className="ten__box ten__resale ten__accordion">
              <div className="ten__box-header ten__box-header_resale ten__accordion-header" onClick={() => setAccordState(prevState => !prevState)}>
                <img
                  className="ten__box-bg-img"
                  src={require('../../assets/images/ten/bg-depart.svg').default}
                  alt=""
                />
                <h3 className="ten__box-title">Трасса департамента вторичной недвижимости</h3>
                <button

                  type="button"
                  className={`ten__accordion-btn${accordState ? ` opened` : ``}`}
                  aria-label="Открыть панель">
                  <svg className="icon icon_more">
                    <use xlinkHref="#chevron"></use>
                  </svg>
                </button>
              </div>
              <div className={`ten__box-body ten__accordion-panel${accordState ? `` : ` hidden`}`}>

                <div className="ten__box-subheader">
                  <b className="h3">Включайся в гонку Переуступок</b>
                  <span className="separator"></span>
                  <p className="big-text">Скорее жми на педаль газа и вырывайся вперёд</p>
                </div>

                <div className="ten__box-content">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Размещай переуступки, в период с 9 ноября по 10 декабря, на портале <span className="ten__red">Panpartner.ru</span> через форму «Продать переуступку»</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Выложи как можно больше переуступок и займи одно из 3-х призовых мест</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">03.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Меняй баллы на рекламу своих объектов в e-mail рассылках, Телеграм-канале и мессенджерах ПАН</p>
                      </div>
                    </div>
                  </div>

                  <div className="ten__box-half">
                    <div className="ten__box-prize">
                      <span className="ten__label h4 ten__label_blue">1 место</span>
                      <p className="h4">6 месяцев рекламы</p>
                    </div>
                    <div className="ten__box-prize">
                      <span className="ten__label h4 ten__label_blue">2 место</span>
                      <p className="h4">3 месяца рекламы</p>
                    </div>
                    <div className="ten__box-prize">
                      <span className="ten__label h4 ten__label_blue">3 место</span>
                      <p className="h4">1 месяц рекламы</p>
                    </div>
                  </div>
                </div>

                <div className="ten__box-subheader">
                  <b className="h3">Formula Трейд-Ин</b>
                  <span className="separator"></span>
                  <p className="big-text">Сервис полного цикла в одном окне</p>
                </div>

                <div className="ten__box-content">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Подбери клиенту новостройку у любого застройщика из тарифной карты ПАН Трейд-Ин</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Проведи Трейд-ин сделку через ПАН в&nbsp;ноябре</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">03.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Получи для клиента бесплатную электронную регистрацию сделки</p>
                      </div>
                    </div>
                  </div>

                  <div className="ten__box-half">
                    <div className="ten__box-bonus">
                      <span className="ten__current-price">0 р.</span>
                      <span className="ten__old-price h4">9 990 р.</span>
                      <p className="ten__sub-price">За электронную регистрацию сделки</p>
                    </div>
                  </div>
                </div>

                <div className="ten__box-subheader">
                  <b className="h3">Едем на повышенных оборотах</b>
                </div>

                <div className="ten__box-content">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Подбери клиенту новостройку из объектов ЛСР</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Проведи Трейд-ин сделку через ПАН в&nbsp;ноябре</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">03.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Получи повышенную комиссию за сделку</p>
                      </div>
                    </div>
                  </div>

                  <div className="ten__box-half">
                    <div className="ten__box-bonus">
                      <span className="ten__current-price">2,8%</span>
                      <span className="ten__old-price h4">2,5 %</span>
                      <p className="ten__sub-price">Cо сделки по Трейд-Ин через ПАН и ЛСР</p>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <span className="track-anchor" id="track-3"></span>
            <article className="ten__box ten__education ten__accordion">
              <div className="ten__box-header ten__box-header_education ten__accordion-header" onClick={() => setAccordState2(prevState => !prevState)}>
                <img
                  className="ten__box-bg-img"
                  src={require('../../assets/images/ten/bg-study.svg').default}
                  alt=""
                />
                <h3 className="ten__box-title">Обучающая трасса</h3>
                <button

                  type="button"
                  className={`ten__accordion-btn${accordState2 ? ` opened` : ``}`}
                  aria-label="Открыть панель">
                  <svg className="icon icon_more">
                    <use xlinkHref="#chevron"></use>
                  </svg>
                </button>
              </div>
              <div className={`ten__box-body ten__accordion-panel${accordState2 ? `` : ` hidden`}`}>

                <div className="ten__box-subheader">
                  <b className="h3">Обучающий центр ПАН</b>
                  <span className="separator"></span>
                  <p className="big-text">Опереди соперников и получи степень бакалавра, магистра или даже аспиранта обучающего центра ПАН</p>
                </div>

                <div className="ten__box-content ten__box-content_pr-sm">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Регистрируйся и посещай обучения ПАН Партнёр</p>
                        <span className="ten__label h4 ten__label_bold">В период с 9 ноября по 10 декабря</span>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Собирай баллы за посещения</p>
                        <span className="ten__label h4 ten__label_bold">Одно посещение = 10 баллов</span>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">03.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Войди в ТОП-3 и получи приз за лучшую посещаемость мероприятий — набор самых актуальных книг по развитию</p>
                      </div>
                    </div>
                  </div>

                  <div className="ten__box-half ten__box-half_wide">
                    <div className="ten__box-prize">
                      <span className="ten__label h4 ten__label_blue">1 место</span>
                      <p className="h4">Аспирант — набор из 3 книг</p>
                      <ul className="ten__books">
                        <li>
                          <b>«Та самая книга для девелопера»,</b><br />
                          <span>Игорь Манн и Иван Черемных</span>
                        </li>
                        <li>
                          <b>«Создайте личный бренд»,</b><br />
                          <span>Юрген Саленбахер</span>
                        </li>
                        <li>
                          <b>«СПИН продажи»,</b>
                          <span> Нил Рекхэм</span>
                        </li>
                      </ul>
                    </div>
                    <div className="ten__box-prize">
                      <span className="ten__label h4 ten__label_blue">2 место</span>
                      <p className="h4">Магистр — набор из 2 книг</p>
                      <ul className="ten__books">
                        <li>
                          <b>«Девелопер и риелтор», </b>
                          <span>Сергей Разуваев, Анна Печеркина и Анна Шишкина</span>
                        </li>
                        <li>
                          <b>«Метод Story Brand. Расскажите о своем бренде так, чтобы в него влюбились», </b>
                          <span>Дональд Миллер</span>
                        </li>
                      </ul>
                    </div>
                    <div className="ten__box-prize">
                      <span className="ten__label h4 ten__label_blue">3 место</span>
                      <p className="h4">Бакалавр — набор из 2 книг</p>
                      <ul className="ten__books">
                        <li>
                          <b>«45 татуировок менеджера», </b>
                          <span>Максим Батырев</span>
                        </li>
                        <li>
                          <b>«Мой продуктивный год», </b>
                          <span>Крис Бэйли</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="ten__box-subheader">
                  <b className="h3">АвтоПАН + АвтоПАНорама</b>
                  <span className="separator"></span>
                  <p className="big-text">Отправляйся на экскурсионнные гонки по новостройкам Питера</p>
                </div>

                <div className="ten__box-content ten__box-content_pr-sm">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action"><b>Закажи АвтоПАН в период 9 ноября по 10 декабря.</b></p>
                        <p className="ten__box-action">АвтоПАН - бесплатные экскурсии по новостройкам с независимым экспертом ПАН для риелторов и их клиентов.</p>
                        <span className="ten__label h4 ten__label_bold">Для риелторов с клиентами</span>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action"><b>Получи скидку 50% на персональную обучающую экскурсию АвтоПАНОРАМА.</b></p>
                        <p className="ten__box-action">АвтоПАНОРАМА - персональные обучающие экскурсии по новостройкам Петербурга на автомобиле комфорт-класса. Вас ждёт трехчасовой интенсив для 1-3 агентов. Успейте попробовать новый сервис со скидкой 50%!</p>
                        <span className="ten__label h4 ten__label_bold">Для риелторов</span>
                      </div>
                    </div>

                  </div>

                  <div className="ten__box-half ten__box-half_wide">
                    <div className="ten__box-prize">
                      <span className="ten__current-price">— 50%</span>
                      <p className="ten__sub-price">На персональную обучающую экскурсию АвтоПАНОРАМА при заказе АвтоПАН</p>
                      <span className="ten__label h4 ten__label_bold">с 9 ноября по 10 декабря</span>
                    </div>
                  </div>
                </div>

                <div className="ten__box-subheader">
                  <b className="h3">Школа риелторов ПАН</b>
                  <span className="separator"></span>
                  <p className="big-text">Экономь 50% на обучающее топливо</p>
                </div>

                <div className="ten__box-content">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Оставь заявку на обучение в Школе риелторов</p>
                        <span className="ten__label h4 ten__label_bold">В период с 9 ноября по 10 декабря</span>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Приводи на обучение друга или коллегу. Учиться в компании близких единомышленников проще, веселее и эффективнее </p>
                      </div>
                    </div>

                  </div>

                  <div className="ten__box-half">
                    <div className="ten__box-bonus">
                    <span className="ten__current-price">— 50%</span>
                    <p className="ten__sub-price">На обучение в Школе риелторов для вас и для вашего друга</p>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <span className="track-anchor" id="track-4"></span>
            <article className="ten__box ten__track ten__accordion">
              <div className="ten__box-header ten__box-header_track ten__accordion-header" onClick={() => setAccordState3(prevState => !prevState)}>
                <img
                  className="ten__box-bg-img"
                  src={require('../../assets/images/ten/bg-ipo.svg').default}
                  alt=""
                />
                <h3 className="ten__box-title">Ипотечная трасса</h3>
                <button
                  type="button"
                  className={`ten__accordion-btn${accordState3 ? ` opened` : ``}`}
                  aria-label="Открыть панель">
                  <svg className="icon icon_more">
                    <use xlinkHref="#chevron"></use>
                  </svg>
                </button>
              </div>
              <div className={`ten__box-body ten__accordion-panel${accordState3 ? `` : ` hidden`}`}>

                <div className="ten__box-subheader">
                  <b className="h3">Ипотечный центр</b>
                  <span className="separator"></span>
                  <p className="big-text"> Ставки сделаны</p>
                </div>

                <div className="ten__box-content ten__box-content_pr-sm">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Проведите минимум 2 ипотечные сделки через ПАН и примите участие в розыгрыше призов</p>
                        <span className="ten__label h4 ten__label_bold">В период с 9 ноября по 10 декабря</span>
                      </div>
                    </div>


                  </div>

                  <div className="ten__box-half ten__box-half_wide">
                    <div className="ten__box-prize">
                      <p className="h3">3 бесплатных</p>
                      <p className="ten__text">одобрения ипотеки для клиентов агентства на вторичку до конца года</p>
                      <span className="ten__label h4 ten__label_blue ten__label_or">или</span>
                      <p className="h3">3 бесплатных</p>
                      <p className="ten__text">рефинансирования ипотечных кредитов до конца года для клиентов агентства</p>
                      <span className="ten__label h4 ten__label_bold">Выберем 2-х победителей</span>
                    </div>
                  </div>
                </div>

              </div>
            </article>

            <span className="track-anchor" id="track-5"></span>
            <article className="ten__box ten__track ten__accordion">
              <div className="ten__box-header ten__box-header_track ten__accordion-header" onClick={() => setAccordState4(prevState => !prevState)}>
                <img
                  className="ten__box-bg-img"
                  src={require('../../assets/images/ten/bg-cities.svg').default}
                  alt=""
                />
                <h3 className="ten__box-title">Трасса от Питера до Москвы</h3>
                <button
                  type="button"
                  className={`ten__accordion-btn${accordState4 ? ` opened` : ``}`}
                  aria-label="Открыть панель">
                  <svg className="icon icon_more">
                    <use xlinkHref="#chevron"></use>
                  </svg>
                </button>
              </div>
              <div className={`ten__box-body ten__accordion-panel${accordState4 ? `` : ` hidden`}`}>

                <div className="ten__box-subheader ten__box-subheader_pr-sm">
                  <b className="h3">Выходи на рынок московских новостроек с ПАН Москва</b>
                  <span className="separator"></span>
                  <p className="big-text">Авансирование за 3 дня</p>
                </div>

                <div className="ten__box-content">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Подберите региональному клиенту новостройку у ГК ФСК и ЛСР, Москва</p>
                        <p className="ten__box-add">* Региональный клиент — клиент с немосковской пропиской</p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Проведите ипотечную сделку или сделку по 100% оплате через ПАН Москва</p>
                        <p className="ten__box-add">* Обязательна предварительная запись на консультацию/встречу минимум за сутки</p>
                      </div>
                    </div>

                  </div>

                  <div className="ten__box-half ten__box-half_wide">
                    <div className="ten__box-prize">
                      <span className="ten__current-price">до 2,2%</span>
                      <p className="ten__sub-price">комиссии со сделки<br />за 3 дня</p>
                      <p className="ten__text ten__text_mt-15">* Подробные условия акции уточняйте по общему номеру<br />ПАН Москва: <a href="tel:+74993221499" className="link">+7 (499) 322-14-99</a></p>

                    </div>
                  </div>
                </div>
              </div>
            </article>

            <span className="track-anchor" id="track-6"></span>
            <article className="ten__box ten__track ten__accordion">
              <div className="ten__box-header ten__box-header_track ten__accordion-header" onClick={() => setAccordState5(prevState => !prevState)}>
                <img
                  className="ten__box-bg-img"
                  src={require('../../assets/images/ten/bg-digital.svg').default}
                  alt=""
                />
                <h3 className="ten__box-title">Digital трасса ПАН</h3>
                <button
                  type="button"
                  className={`ten__accordion-btn${accordState5 ? ` opened` : ``}`}
                  aria-label="Открыть панель">
                  <svg className="icon icon_more">
                    <use xlinkHref="#chevron"></use>
                  </svg>
                </button>
              </div>
              <div className={`ten__box-body ten__accordion-panel${accordState5 ? `` : ` hidden`}`}>

                <div className="ten__box-subheader">
                  <b className="h3">Дарим 10 целевых лидов</b>
                  <span className="separator"></span>
                  <p className="big-text">Заправь свой бизнес доступными лидами</p>
                </div>

                <div className="ten__box-content ten__box-content_no-pb">
                  <div className="ten__box-half ten__box-half_50">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Пройди 4-минутный опрос от&nbsp;отдела маркетинга ПАН</p>
                      </div>
                    </div>
                  </div>

                  <div className="ten__box-half ten__box-half_50">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">
                          <b>Прими участие в розыгрыше приза</b> - 10-ти бесплатных заявок на новостройки по продукту «<a href="leads.pdf" download className="ten__red ten__underline">Доступные лиды</a>».
                          При прохождении опроса вы автоматически становитесь участником розыгрыша.
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              {!isTest && <button type="button" onClick={()=> window.open(`/poll`)} className="button button_view_default ten__inside-button" disabled={isExistRes}>{isExistRes ? `Вы уже прошли опрос` : `Пройти опрос`} </button>}


                <div className="ten__box-subheader">
                  <b className="h3">Билет на матч Зенит - Боруссия (Д)</b>
                  <span className="separator"></span>
                  <p className="big-text">От отдела разработки Panpartner.ru</p>
                </div>

                <div className="ten__box-content">
                  <div className="ten__box-half">
                    <div className="ten__box-row">
                      <span className="ten__box-counter">01.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Предложи классное улучшение функционала на портале <span className="ten__red ten__underline">Panpartner.ru</span></p>
                      </div>
                    </div>

                    <div className="ten__box-row">
                      <span className="ten__box-counter">02.</span>
                      <div className="ten__box-item">
                        <p className="ten__box-action">Отправь его на почту <a className="ten__red ten__underline" href="mailto:support@1-an.ru" target="_blank" rel="noopener noreferrer">support@1-an.ru</a> с пометкой «улучшения на Panpartner»</p>
                      </div>
                    </div>

                  </div>

                  <div className="ten__box-half ten__box-half_ticket">
                    <div className="ten__box-bonus">
                      <span className="ten__current-price">Билет</span>
                      <p className="ten__sub-price">На футбольный матч за самое интересное и полезное улучшение</p>
                    </div>
                  </div>
                </div>

              </div>
            </article>


          </section>

          <section className="ten__info ten__info_socisals">
            <div className="ten__container">
              <p className="ten__title ten__info-title">Следи за ходом акционных гонок в социальных сетях</p>

              <div className="ten__info-content">
                <div className="ten__info-block ten__info-block_socials">
                  <p className="ten__info-descr ten__info-descr_socials">
                    <a href="https://tlgg.ru/panpartner" target="_blank" rel="noopener noreferrer" className="ten__social">
                      <svg className={`icon icon_social-tg}`}>
                        <use xlinkHref={`#social-tg`}></use>
                      </svg>
                    </a>
                    <b>В телеграм-канале <a href="https://tlgg.ru/panpartner" target="_blank" rel="noopener noreferrer" className="ten__red ten__underline">@panpartner</a> по хештегу #ПАН10</b>
                  </p>
                  <p className="ten__info-descr ten__info-descr_socials">
                    <a href="https://www.instagram.com/panpartner/" target="_blank" rel="noopener noreferrer" className="ten__social">
                      <svg className={`icon icon_social-ig}`}>
                        <use xlinkHref={`#social-ig`}></use>
                      </svg>
                    </a>
                    <b>
                      В инстаграм <a href="https://www.instagram.com/panpartner/" target="_blank" rel="noopener noreferrer" className="ten__red ten__underline">@panpartner</a> в разделе ПАН10 в актуальном
                    </b>
                  </p>
                </div>

                <div className="ten__info-aside">
                  <b className="ten__info-counter">И в любом удобном мессенджере</b>
                  <ul className="ten__messengers">
                    <li>
                      <a href="https://wa.me/79626863547?&text=%D0%9E%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D0%BD%D0%B5%20%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D1%8F%D1%8F%20%D1%8D%D1%82%D0%BE%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5%20subscribe_a3eb60cd-4810-a7cb-cc96-01758cfdd70e" target="_blank" rel="noopener noreferrer" className="ten__social ten__social_sm">
                        <svg className={`icon icon_social-wa}`}>
                          <use xlinkHref={`#social-wa`}></use>
                        </svg>
                      </a>
                    </li>

                    <li>
                      <a href="https://telegram.me/panpartnerbot?start=subscribe_a3eb60cd-4810-a7cb-cc96-01758cfdd70e" target="_blank" rel="noopener noreferrer" className="ten__social ten__social_sm">
                        <svg className={`icon icon_social-tg}`}>
                          <use xlinkHref={`#social-tg`}></use>
                        </svg>
                      </a>
                    </li>

                    <li>
                      <a href="viber://pa?chatURI=panpartner&context=subscribe_a3eb60cd-4810-a7cb-cc96-01758cfdd70e" target="_blank" rel="noopener noreferrer" className="ten__social ten__social_sm">
                        <svg className={`icon icon_social-viber}`}>
                          <use xlinkHref={`#social-viber`}></use>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {
            !isAuthorized && (
              <section className="ten__register">
                <div className="ten__container">
                  <div className="ten__register-flex">
                    <div className="ten__register-info">
                      <b className="big-text">Ускорь продажи вместе с ПАН</b>
                      <p>В акциях могут участвовать только партнеры ПАН. Чтобы стать партнером, зарегистрируйтесь на портале Panpartner.ru</p>
                    </div>
                    <button
                      type="button"
                      className="ten__register-btn button button_view_default h4"
                      onClick={()=>window.open("/welcome")}
                      >

                      Стать партнером
                    </button>
                  </div>
                </div>
              </section>
            )
          }


        </main>

    </section>
  );
};

const mapStateToProps = (state) => ({
  state: state.contest,
  isAuthorized: state.user.isAuthorized,
  isTest: state.user?.info?.groups?.some((group) => group === "test_access")
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Ten);
