import floor from 'lodash/floor';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import round from 'lodash/round';
import React, { useEffect, useRef, useState } from "react";
import { Line } from 'react-chartjs-2';
import { formatMoney, getPricePerMeter, getRoundedPrice, media, months } from '../../utils';
import Popup from '../core/Popup';


const getData = (data) => {
  return data.map(item => ({ ...item, x: new Date(item.date), y: item.costWithDiscount }))
}
const getStepSIze = (data) => {
  const minCost = minBy(data, (item) => item.costWithDiscount)?.costWithDiscount
  const maxCost = maxBy(data, (item) => item.costWithDiscount)?.costWithDiscount
  const delta = maxCost - minCost

  if(delta > 999999){
    return 500000
  }
  if(delta < 100000){
    return 10000
  }
  return 100000
}

const getCosts = (data) => {
  const stepSize = getStepSIze(data)
  let minByCost = minBy(data, (item) => item.costWithDiscount)
  let maxByCost = maxBy(data, (item) => item.costWithDiscount)
  let minYLabel = floor(minByCost.costWithDiscount / stepSize) * stepSize
  let maxYLabel = (floor(maxByCost.costWithDiscount / stepSize)+ 1) * stepSize
  return {
    min: minYLabel,
    max: maxYLabel
  }
  // const labels = []
  // let currentPrice = minYLabel
  // do {
  //   labels.push(currentPrice)
  //   currentPrice += 500000
  // } while (currentPrice < maxYLabel)
  // return labels
}

const getDate = (data) => {
  let minByDate = minBy(data, (item) => new Date(item.date))
  let maxByDate = maxBy(data, (item) => new Date(item.date))
  return {
    min: minByDate,
    max: maxByDate
  }
  // const labels = []
  // let currentPrice = minYLabel
  // do {
  //   labels.push(currentPrice)
  //   currentPrice += 500000
  // } while (currentPrice < maxYLabel)
  // return labels
}



const Prices = (props) => {
  const { info } = props
  const _chartRef = useRef();
  const [tooltipData, setTooltipData] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)

  const options = {
    // onHover: (...args)=> console.log('args', args),
    bounds: "ticks",
    scales: {

      yAxes: [
        {
          ticks: {
            ...getCosts(info.prices),
            beginAtZero: true,
            stepSize: getStepSIze(info.prices),
            callback: function (value, index, values) {
              return getRoundedPrice(value).toString().replace(".", ",") + " млн. р.";
            },
            fontSize: 14,
            fontFamily: "Formular",
            padding: 0
          },
          gridLines: {
            borderDash: [1, 2],
            color: 'rgba(0, 0, 0, 0.2)',
            zeroLineColor: 'rgba(199, 202, 213, 1)',
            zeroLineWidth: 1,
            drawTicks: false,
            drawBorder: true,
          },

        }
      ],
      xAxes: [
        {
          type: 'time',
          display: true,
          gridLines: {
            display: false,
            zeroLineColor: 'rgba(199, 202, 213, 1)',
          },
          distribution: 'series',
          time: {
            unit: 'day',
            displayFormats: {
              // day: 'YYYY-M-DD'
              day: 'DD.M.YY'
            }

          },
          ticks: {
              ...getDate(info.prices),
              callback: function (value, index, values) {
                // const date = new Date(value)
                // const month = months[date.getMonth()]
                // const day = date.getDate()
                // return `${day} ${month}`;
                return value
              },
              fontSize: 14,
              fontFamily: "Formular",
              padding: 20
        },
      }
      ],
    },
    legend: {
      display: false
    },
    tooltips: {
      "enabled": false,
      "mode": "point",
      "intersect": false,
      "custom": (tooltipModel) => {
        const chart = _chartRef.current;
        if (!chart) {
          return;
        }
        if (tooltipModel.opacity === 0) {
          setTimeout(setShowTooltip, 100) // бред, но если сетить из этой функции состояние, то она не срабатывает баг chart js
          return;
        }

        // const position = chart.chartInstance.canvas.getBoundingClientRect();
        // const left = position.left + tooltipModel.caretX;
        // const top = position.top + tooltipModel.caretY;
        const left = tooltipModel.caretX + 10;
        const top = tooltipModel.caretY;

        const date = new Date(tooltipModel.dataPoints[0].xLabel);
        const value = tooltipModel.dataPoints[0].yLabel;
        let setTooltip = () => {
          setTooltipData({ top, left, date, value });
          setShowTooltip(true);
        }
        setTimeout(setTooltip, 100)

      },

    },
    layout: {
      padding: {
        left: 0,
        right: media('isMobile') ? 0 : 100,
        top: 0,
        bottom: 0
      }
    },

  }
  const data = {
    datasets: [
      {
        data: getData(info.prices),
        fill: true,
        backgroundColor: 'rgba(113, 113, 113, 0.03)',
        borderColor: 'rgba(143, 149, 172, 1)',
        lineTension: 0,
        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
        pointHoverBackgroundColor: 'rgba(45, 186, 47, 1)',
        pointHoverBorderColor: 'rgba(45, 186, 47, 1)',
        pointRadius: 4,
        pointHitRadius: 4,
        borderWidth: 1,
      },
    ],
  }

  const Tooltip = props => {
    const { left, top, value, date, squareTotal } = props
    const day = date.getDate()
    const month = date.getMonth()
    const pricePerMeter = formatMoney(parseInt(getPricePerMeter(value, squareTotal)))
    const [previousObject, setPreviousObject] = useState(null)
    const [dynamicPercent, setDynamicPercent] = useState(null)
    useEffect(() => {
      const selfTime = date.getTime()
      const currentIndex = info.prices.findIndex(item => selfTime === new Date(item.date).getTime())
      if (!currentIndex || currentIndex === 0) {
        return
      }
      // const prevObject  = info.prices[currentIndex - 1]
      // setPreviousObject(prevObject)
      const first = info.prices[0]
      const moneyPerPercent = first.costWithDiscount / 100
      const dynamic = round(value / moneyPerPercent - 100, 2)
      setDynamicPercent(dynamic)

    }, [date])
    return (
      <div style={{ left, top, minWidth: "max-content" }} className="chart__tooltip chart__tooltip_lg">
        <div className="flat__info-item_desc chart__tooltip-date">
          {`${day} ${months[month]}`}
        </div>
        <div className="chart__info-value chart__info-value_sm">
          {formatMoney(value) + ' руб.'}
          {dynamicPercent && <span className={`chart__percent ${dynamicPercent > 0 ? "chart__percent_plus" : "chart__percent_minus"}`}>{dynamicPercent > 0 ? "+" + dynamicPercent : dynamicPercent}%</span>}
        </div>
        <span className="flat__info-item_desc chart__tooltip-detail">{pricePerMeter} руб./м²</span>
      </div>
    )
  }
  return (
    <>
      {media('isMobile') ?
        <Popup
          trigger={
            <Line data={data} options={options} ref={_chartRef}/>
          }
          onClose={()=>setShowTooltip(false)}
        >
          {showTooltip && <Tooltip {...tooltipData} squareTotal={info.squareTotal} />}
        </Popup> :
        <div onMouseLeave={()=>setShowTooltip(false)}>
          <Line data={data} options={options} ref={_chartRef} height={100} />
          {showTooltip && <Tooltip {...tooltipData} squareTotal={info.squareTotal} />}
        </div>
      }
    </>
  );
};

export default Prices;
