import { Popover } from 'antd';

import './index.scss';
import React from 'react';
import classNames from 'classnames';

const AppPopover = ({
  content,
  trigger = 'hover',
  overlayClassName = null,
  placement = 'top',
  title = null,
  addBtnClass = '',
  children,
  complexHeaderMotivation
}) => {
  const btnClass = classNames({
    'app-popover-btn': true,
    [`${addBtnClass}`]: true,
  });
  return (
    <Popover
      content={complexHeaderMotivation && Array.isArray(content) ? content.map((item, index) => (<span key={index}>{item}</span>)) : content}
      title={title}
      trigger={trigger}
      overlayClassName={overlayClassName}
      placement={placement}
    >
      <button className={btnClass} type="button">
        {children}
      </button>
    </Popover>
  );
};

export default AppPopover;
