import React from "react";
import { Link } from "react-router-dom";
import { ColumnAction, ColumnManager, ColumnStatus } from './Components';


const Row = ({ title, value }) => <div className="lk-presentation__clients">
    <span className="flats__row-table-expanded-title">{title}</span>
    {value}
</div>

export const expandedRow = (record, showAgents )=> (
    <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
            {showAgents && <Row title="Агент:" value={record.agent} />}
            {/* <Row title="Цена:" value={record.price} /> */}
            <Row
                title="Дата бр.:"
                value={new Date(record.dateCreate).toLocaleDateString("ru",
                    {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric"
                    })}
            />
            <Row title="Статус:" value={[<ColumnStatus status={record.stage} statusColor={record.stageColor} />]} />
            <Row title="Менеджер ПАН:" value={[<ColumnManager title={record.manager} phone={record.managerPhone} />]} />
            {/* <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Форма оплаты:</span>
            {record.payType ? <ColumnPay items={record.payType} /> : '–'}
          </div> */}
        </div>
    </div>
)

export const getMobileColumns = (handleDelete, handleSend, showAgents) => [
    {
      title: "Клиент",
      dataIndex: "client",
      key: "client",
      width: 30,
      render: (column, record) => (
        // <Link
        //   className="orders__table-link orders__table-client"
        //   to={`/personal/clients/${record.clientId}`}
        // >
        //   {column}
        // </Link>
        <div className="orders__table-client" style={{whiteSpace: "normal"}} >
          {column}
        </div>
      ),
    },
    {
      title: "Объект",
      key: "object",
      dataIndex: "object",
      render: (object, record) => (
        <div className="requests-complex">
          <div>
            {object}
          </div>
          {!!record.url && (
            <Link
              className="orders__table-label"
              to={record.url}
            >
              Карточка объекта
            </Link>
          )}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      // width: 50,
      render: (text, record) => (
        <ColumnAction
          {...record}
          deleteRequest={handleDelete}
          sendRequest={handleSend}
        />
      ),
    },
  ]

 export const getColumns = (handleDelete, handleSend, showAgents ) => [
    {
      title: "Клиент",
      dataIndex: "client",
      key: "client",
      render: (column, record) => (
        // <Link
        //   className="orders__table-link orders__table-client"
        //   to={`/personal/clients/${record.clientId}`}
        // >
        //   {column}
        // </Link>
        <div style={{fontWeight: "bold"}}>
          {column}
        </div>
      ),
    },
    {
      title: "Объект",
      key: "object",
      dataIndex: "object",
      render: (object, record) => (
        <>
          <div>
            {object}
          </div>
          {!!record.url && (
            <Link
              className="orders__table-label"
              to={record.url}
            >
              Карточка объекта
            </Link>
          )}
        </>
      ),
    },
    {
      title: "Дата бронир.",
      dataIndex: "dateCreate",
      key: "dateCreate",
    //   sorter: (a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1),
      render: (column) =>
        new Date(column).toLocaleDateString("ru", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
    },
    ...(showAgents? [{
      title: "Сотрудник",
      dataIndex: "agent",
      key: "agent",
    //   sorter: (a, b) => (a.agent?.lastName > b.agent?.lastName ? 1 : -1),
    }]: []),


    {
      title: "Менеджер ПАН",
      dataIndex: "manager",
      key: "manager",
      render: (manager, record) => <ColumnManager title={manager} phone={record.managerPhone}/>,
    },
    {
      title: "Статус",
      dataIndex: "stage",
      key: "stage",
    //   sorter: (a, b) => a.stageColor > b.stageColor,
      render: (stage, { stageColor }) => (
        <ColumnStatus status={stage} statusColor={stageColor} />
      ),
    },

    ...(false ? [{
      title: "",
      key: "action",
      width: 50,
      render: (text, record) => (
        <ColumnAction
          {...record}
          deleteRequest={handleDelete}
          sendRequest={handleSend}
        />
      ),
    }] : []),
  ];