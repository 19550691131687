import React, {memo, useEffect, useState} from "react";
import SubmitButton from "../../components/SubmitButton";
import NewAlert from "../../components/core/NewAlert";
import { standartErrorToast, standartSuccessToast } from "../../components/toasts";
import useApi from "../../hooks/useApi";
import useUniversalReducer from "../../hooks/useUniversalReducer";
import { FavoritesCheckboxItem } from "./components";

const checkboxKeys = [
  "showBlockTitle",
  "showBuilderTitle",
  "showPrice",
  "showDynamicPrice",
  "showPaymentGraphic",
  "showButtonGetPdf",
  "showContacts",
  "showContractType",
  "showCompletionDate",
  "showKeyIssueDate",
  "showPaymentMethod",
  "showBuildingType",
  "showArea",
  "showFloor",
  "showFinish",
  "showMeterPrice",
  "showBuildingAndSection",
  "enableApartmentComparison",
  "showDeveloper",
  "showDifference",
  "showCeilingHeight",
  "showFloorPlan"
];

const inputStyle = { background: "#f6f7f8", boxShadow: 'none' };

const CompareSettingsModal = memo(({ closeModal, refresher, id }) => {

  const {
    state,
    setValue,
    setValues
  } = useUniversalReducer({
    name: '',
    comment: '',
    vkPixel: '',
    yandexMetric: '',

    showBlockTitle: true,
    showBuilderTitle: true,
    showPrice: true,
    showDynamicPrice: true,
    showPaymentGraphic: true,
    showButtonGetPdf: true,
    showContacts: true,
    showFloorPlan: true,
    showContractType: true,
    showCompletionDate: true,
    showKeyIssueDate: true,
    showPaymentMethod: true,
    showBuildingType: true,
    showArea: true,
    showFloor: true,
    showFinish: true,
    showMeterPrice: true,
    showBuildingAndSection: true,
    enableApartmentComparison: true,
    showDeveloper: true,
    showCeilingHeight: true,
    showDifference: false,

  })

  const { compilation, isLoading, isSending, send } = useApi({
    payload: ["panpartner:favorite.ajax", "getCompilation", { id, noStatistic: "Y" }],
    loadCondition: id,
    processData: ({ compilation }) => {
      const settings = { ...compilation.settings };
      settings.name = compilation.title;
      settings.comment = compilation.comment;
      settings.vkPixel = compilation.vkPixel;
      settings.yandexMetric = compilation.yandexMetric;
      setValues(settings);
      return compilation;
    },
  })

  const createSetter = (key, isCheckbox) => e => setValue(key, isCheckbox ? e.target.checked : e.target.value);

  const createProps = (key, isCheckbox = true) => ({
    onChange: createSetter(key, isCheckbox),
    [isCheckbox ? "checked" : "value"]: state[key]
  })

  const onSuccessAction = resp => {
    standartSuccessToast(resp)
    refresher()
    closeModal()
  }

  const [showDifferenceParam, setShowDifferenceParam] = useState(false)
  const [displayValue, setDisplayValue] = useState(state.showDifference ? showDifferenceParam : 0)

  const submit = (customValue) => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(state)) {
      if (key === "showDifference") {
        formData.append(`settings[${key}]`, customValue ? displayValue : 0);
      } else if (checkboxKeys.includes(key)) {
        formData.append(`settings[${key}]`, value ? 1 : 0);
      } else {
        formData.append(key, value);
      }
    }
    if (id) formData.append("id", id);
    send(["panpartner:favorite.ajax", "saveCompilation", formData])
      .then(onSuccessAction, standartErrorToast);
  }

  return (
    <div className="hp" style={{ padding: "20px 120px", minHeight: "auto" }}>
      <div className="compare-settings-container">
        <div className="compare-view-settings" style={{ marginTop: 0 }}>
          <h4
            className="h4"
            style={{
              textAlign: "center",
              marginBottom: 16
            }}>
            Подборка
          </h4>

          <ul className="compare-view-settings__items-list">
            <FavoritesCheckboxItem
              id={"showBlockTitle"}
              createProps={createProps}
              title="Показать название жк"
            />
            <FavoritesCheckboxItem
              id={"showBuilderTitle"}
              createProps={createProps}
              title="Показать название застройщика"
            />
            <FavoritesCheckboxItem
              id={"showPrice"}
              createProps={createProps}
              title="Показать цены"
            />
            {/* <FavoritesCheckboxItem
              id={"enableApartmentComparison"}
              createProps={createProps}
              title="Включить сравнение квартир"
            /> */}
            <FavoritesCheckboxItem
              id={"showContacts"}
              createProps={createProps}
              title="Показывать контакты"
            />
          </ul>
          <div className="error-report__area" style={{ marginTop: 20, marginBottom: 0 }}>
            <label
              className="textarea textarea_type_form"
              htmlFor="modal-reservation-commentary"
            >
              <span className="textarea__label">Комментарий к подборке </span>
              <textarea
                style={{ minHeight: 87 }}
                className="textarea__control"
                placeholder="По желанию Вы можете добавить приветственное сообщение"
                {...createProps("comment", false)}
              />
            </label>
            <div className="hp__form-item" style={{ width: '100%', marginBottom: 20, marginTop: 20 }}>
              <label className="input input_width_available input_type_form">
                <div className="hint-label">
                  <span className="input__label">Счетчик Яндекс.Метрика</span>
                  <a className="input__label input__label_hint" href="https://practicum.yandex.ru/blog/kak-rabotaet-yandex-metrika/" target="_blank">Что такое счетчик</a>
                </div>
                <input
                  className={'input__control'}
                  style={inputStyle}
                  placeholder="98765432"
                  {...createProps("yandexMetric", false)}
                />
              </label>

            </div>
            <SubmitButton
              className="btn h6 btn_primary btn_full"
              type="button"
              // onClick={submit}
              onClick={() => submit(displayValue)}
              isLoading={(isLoading && !!id) || isSending}
            >
              Сохранить
            </SubmitButton>
          </div>
        </div>
        <div className="compare-view-settings"
         style={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column"
         }}
         >
          <h4 className="h4"
            style={{
              textAlign: "center",
              marginBottom: 16
            }}>
            Сравнение
          </h4>
          <ul className="compare-view-settings__items-list" style={{ height: "100%", flexGrow: 1 }}>
            <FavoritesCheckboxItem
              id={"showMeterPrice"}
              createProps={createProps}
              title="Показывать цену за метр"
            />
            <FavoritesCheckboxItem
              id={"showArea"}
              createProps={createProps}
              title="Показывать площади"
            />
            <FavoritesCheckboxItem
              id={"showFloor"}
              createProps={createProps}
              title="Показывать этаж"
            />
            <FavoritesCheckboxItem
              id={"showFinish"}
              createProps={createProps}
              title="Показывать отделку"
            />
            <FavoritesCheckboxItem
              id={"showBuildingAndSection"}
              createProps={createProps}
              title="Показывать корпус и секцию"
            />
            <FavoritesCheckboxItem
              id={"showContractType"}
              createProps={createProps}
              title="Показывать форму договора"
            />
            <FavoritesCheckboxItem
              id={"showCompletionDate"}
              createProps={createProps}
              title="Показывать срок сдачи"
            />
            <FavoritesCheckboxItem
              id={"showKeyIssueDate"}
              createProps={createProps}
              title="Показывать срок выдачи ключей"
            />
            <FavoritesCheckboxItem
              id={"showPaymentMethod"}
              createProps={createProps}
              title="Показывать способ оплаты"
            />
            <FavoritesCheckboxItem
              id={"showBuildingType"}
              createProps={createProps}
              title="Показывать тип дома"
            />
            <FavoritesCheckboxItem
              id={"showDeveloper"}
              createProps={createProps}
              title="Показывать застройщика"
            />

            <FavoritesCheckboxItem
              id={"showCeilingHeight"}
              createProps={createProps}
              title="Показывать высоту потолков"
            />

            <>
              <FavoritesCheckboxItem
                id={"showDifference"}
                createProps={createProps}
                title="Показывать разницу от избранного объекта"
                onToggle={(checked) => {
                  if (!checked) {
                    setShowDifferenceParam(false)
                    setDisplayValue(0)
                  }
                 }
                }
              />
              {state.showDifference &&
                <CustomRadio backValue={state.showDifference} displayValue={displayValue} setDisplayValue={setDisplayValue} showDifferenceParam={showDifferenceParam} setShowDifferenceParam={setShowDifferenceParam}/>
              }
            </>


          </ul>
        </div>
      </div>


      {false && <NewAlert
        text="Чтобы отслеживать количество заявок с подборки, используйте название panCollectionCallRequest при создании цели."
        bgColor="#EEEFFB"
        iconColor="#8790E5"
      />}


    </div>
  )
})

export default CompareSettingsModal;

const CustomRadio = ({displayValue, setDisplayValue, setShowDifferenceParam, backValue}) => {
  const [selectedOption, setSelectedOption] = useState(displayValue);
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    backValue && setSelectedOption(backValue)
  }, [])

  useEffect(() => {
    if (selectedOption === 'percent') {
      setShowDifferenceParam('percent')
      setDisplayValue('percent')
    } else if (selectedOption === 'absolute') {
      setShowDifferenceParam('absolute')
      setDisplayValue('absolute')
    }
  }, [selectedOption])

  return (
    <div className="custom-radio custom-radio_compare">
      <label>
        <input
          type="radio"
          value="percent"
          checked={selectedOption === 'percent'}
          onChange={handleOptionChange}
        />
        Проценты
      </label>
      <label>
        <input
          type="radio"
          value="absolute"
          checked={selectedOption === 'absolute'}
          onChange={handleOptionChange}
        />
        Сумма
      </label>
    </div>
  );
};
