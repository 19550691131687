import React from "react";

const ThemeBg3 = (props) => {
  const {currentColor} = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386 227" fill="none">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="386" height="227">
      <rect y="0.25708" width="386" height="226.006" rx="5" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0)">
      <g filter="url(#filter0_d)">
      <ellipse cx="242.436" cy="-19.5273" rx="324.284" ry="232.289" transform="rotate(25.4952 242.436 -19.5273)" fill="white"/>
      </g>
      <g filter="url(#filter1_d)">
      <ellipse cx="270.806" cy="109.709" rx="171.607" ry="122.924" transform="rotate(-29.8028 270.806 109.709)" fill={currentColor}/>
      </g>
      </g>
      <defs>
      <filter id="filter0_d" x="-210.255" y="-418.779" width="905.382" height="818.504" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="10"/>
      <feGaussianBlur stdDeviation="30"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.579288 0 0 0 0 0.523998 0 0 0 0 0.800449 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <filter id="filter1_d" x="20.7998" y="-112.249" width="500.012" height="463.916" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="10"/>
      <feGaussianBlur stdDeviation="20"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.579288 0 0 0 0 0.523998 0 0 0 0 0.800449 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
};

export default ThemeBg3;
