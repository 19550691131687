import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Preloader from '../Preloader';
import PublicHeader from '../Public/PublicHeader';
import List from './List';
import View from './View';
import Header from '../Public/markup/Header';
import PublicAssignments from '../../pages/PublicAssignments';
import useRootCSS from "../../hooks/useRootCSS";

// import "./index.css";

const Public = (props) => {
  const { path } = useRouteMatch();
  const { savedFilterXmlId } = useParams();
  const { agent, isFilterDisabled, savedFilterSettings } = props;
  let filterType = 'assignments';
  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover,
  });
  return (
    <div>
      <Suspense fallback={<Preloader loading={true} />}>
        {agent && !isFilterDisabled && (
          // <PublicHeader agent={agent} savedFilterXmlId={savedFilterXmlId} filterType={filterType}/>
          <div className="sf wrapper sf_inner-public">
            <Header {...agent} savedFilterSettings={savedFilterSettings} />
          </div>
        )}
        <Switch>
          <Route exact path={`${path}`} component={PublicAssignments} />
          <Route
            path={`/public/assignments/:savedFilterXmlId/:city/:id`}
            component={View}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("state", state );
  return {
    agent: state.assignments.agent || state.assignment.agent,
    isFilterDisabled: !state.assignments.isActive || !state.assignment.isActive,
    savedFilterSettings: state.assignments.savedFilterSettings || state.assignment.savedFilterSettings,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Public);
