import { uniqBy } from "lodash";
import React, {memo, useEffect, useState} from "react";
import { useParams, useHistory } from "react-router-dom";
import request, {getUrl} from "../../api";
import { processFiles } from "../../components/Constructor/Admin/MyObjects/Components";
import ContactCard from "../../components/core/ContactCard";
import DealAct from "../../components/core/DealAct";
import DealInfo from "../../components/core/DealInfo";
import Tabs from "../../components/core/Tabs";
import UploaderDocs from "../../components/core/UploaderDocs";
import Preloader from "../../components/Preloader";
import { standartErrorToast } from "../../components/toasts";
import useApi from "../../hooks/useApi";
import { appendFiles, toFormData } from "../../utils";
import './index.scss';
import '../../components/core/TabsNew/index.scss';
import Slider from "../../components/Public/markup/detail/Slider";
import InfoCard from "../../components/Public/markup/detail/InfoCard";
import useMedia from "../../hooks/useMedia";

const updateDeal = form => request("panpartner:requests.ajax", "updateDeal", form)

const Deal = memo(props => {
    const { id } = useParams()
    const { data: { deal = {}, hideDocuments, photos = [], viewFromWindow }, isLoading, setData } = useApi({
        payload: ["panpartner:requests.ajax", "getDeal", { id, getFile: 1 }],
        processData: resp => {
            const tmp = { ...resp }
            const files = tmp?.deal?.documents
            if (!files) return resp
            tmp.deal.documents.clientDocuments = processFiles(files.clientDocuments || [])
            tmp.deal.documents.paymentOrder = processFiles(files.paymentOrder || [])
            return tmp
        }
    }, [id])
    const history = useHistory()
    props.setWithoutTabs && props.setWithoutTabs(id)

    const {
        title,
        stage,
        clientPhone,
        object,
        bookingFrom,
        bookingTo,
        paymentType,
        contractType,
        contractNumber,
        documents,
        inviteChat,
        contacts,
        commissionPercent,
        commission,
        generalInfo,
        objectInfo,
        contactsInfo,
        comment,
        
    } = deal
    const [activeTab, setActiveTab] = useState(false);


    const deleteDoc = (ident, fileId, fakeFileId) => updateDeal({ id, [`${ident}[]`]: fileId })
    .then(resp=>{
        setData(prev=>{
            const tmp = {...prev}
            tmp.deal.documents[ident] =   tmp.deal.documents[ident].filter(file=>file.fakeId !== fakeFileId)
            return tmp
        })
        Promise.resolve(resp)
    })
    const onFilesChange = (ident, files, type, resp) => { 
        if(type !== "upload") return 
        setData(prev=>{
            const tmp = {...prev}
            const respDocs = processFiles(resp.deal.documents[ident])
            tmp.deal.documents[ident] = tmp.deal.documents[ident] ? uniqBy([...tmp.deal.documents[ident] , ...respDocs], "id"): files;
            return tmp
        })
     }
     const uploadFiles = (ident, files, type) => { 
        if(type !== "upload") return 
        const formData = toFormData({id})
        appendFiles(files, formData, `${ident}[]`)
        return updateDeal(formData).then(resp=>{
            onFilesChange(ident, files, type, resp)
            Promise.resolve(resp)
        }, err=> {
            standartErrorToast(err)
            Promise.reject(err)
        })
     }
    const isDesktop = useMedia("isDesktop");
    if (isLoading) return <Preloader loading />
    const sf = photos.map(src => ({ src }));
    return (
        <div className="wrapper">
            <div className="common-header">
                <div className="common-header__title">
                    <span
                        onClick={history.goBack}
                        className="common-header__chevron"
                    >
                        <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round" />
                        </svg>
                    </span>
                    <h3 className="common-title">
                        {title}
                    </h3>
                </div>
            </div>



            <div className="deal-wrapper">
                {inviteChat && <a className="common-link" href={inviteChat} target="_blank">
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.58181 11.7839L8.50264 13.5854C8.4622 13.6516 8.4091 13.7091 8.34638 13.7548C8.28365 13.8004 8.21254 13.8331 8.13711 13.8512C8.06169 13.8693 7.98344 13.8723 7.90686 13.86C7.83028 13.8478 7.75686 13.8205 7.69083 13.7799C7.61175 13.7311 7.54516 13.6645 7.49639 13.5854L6.41722 11.7839C6.36637 11.6976 6.29389 11.626 6.20695 11.5762C6.12 11.5264 6.02158 11.5002 5.92139 11.5H1.58333C1.42862 11.5 1.28025 11.4385 1.17085 11.3291C1.06146 11.2197 1 11.0714 1 10.9167L1 1.58333C1 1.42862 1.06146 1.28025 1.17085 1.17085C1.28025 1.06146 1.42862 1 1.58333 1L14.4167 1C14.5714 1 14.7198 1.06146 14.8291 1.17085C14.9385 1.28025 15 1.42862 15 1.58333L15 10.9167C15 11.0714 14.9385 11.2197 14.8291 11.3291C14.7198 11.4385 14.5714 11.5 14.4167 11.5H10.0786C9.97839 11.5007 9.88003 11.5271 9.79301 11.5768C9.70599 11.6266 9.63325 11.6979 9.58181 11.7839V11.7839Z" stroke="#FF2D23" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Открыть чат по сделке
                </a>}
                <div className={"deal-content"} style={activeTab === 'Про объект' && photos.length > 0 ? {maxWidth: '100%'} : {maxWidth: '728px'}}>
                    <Tabs isInline getActiveTab={setActiveTab}>
                        <div label={'Общее'}>
                            <DealInfo
                                items={generalInfo}
                                act={documents?.transactionReport}
                                withAct
                            />
                            {comment && <div className="deal-description">
                                <h5 className="small-five-title">Описание сделки</h5>
                                <div
                                    className="deal-description-list"
                                    dangerouslySetInnerHTML={{ __html: comment }}
                                >
                                    {/* <li className="deal-description-list__item">Выкуп из-под ипотеки</li> */}
                                </div>
                            </div>}
                            <h5 className="small-five-title">Контакты по сделке</h5>
                            <div className="deal-contacts-grid">
                                {contactsInfo.map(item => <ContactCard {...item} />)}
                            </div>
                        </div>
                        <div label={'Про объект'}>
                            {photos.length > 0 ?
                              <div className={`sf`}>
                                  {/*<div className="sf__detail-row sf__detail-row--common-modal">*/}
                                  <div className={`sf__detail-row ${isDesktop && `sf__detail-row--common-modal`}`}>
                                      <div className="sf__detail-col">
                                          <Slider
                                            items={sf}
                                            withBorder
                                            showButton={!!viewFromWindow}
                                            viewFromWindow={viewFromWindow}
                                            windowView
                                          />
                                      </div>
                                      <div className="sf__detail-col sf__detail-col_for-object">
                                          <InfoCard items={objectInfo}/>

                                          <ContactCard
                                            name={contactsInfo[0].name}
                                            phone={contactsInfo[0].phone}
                                            telegram={contacts[0].telegram}
                                            logo={contactsInfo[0].logo}
                                            post={contactsInfo[0].post}
                                          />
                                          <br/>
                                          <br/>
                                      </div>
                                  </div>
                              </div> : <DealInfo items={objectInfo} />
                            }



                        </div>
                        {!hideDocuments && <div label={'Документы'}>
                            {!!documents?.transactionReport && <DealAct justAct act={documents?.transactionReport} />}
                            <UploaderDocs
                                files={documents?.paymentOrder ? documents?.paymentOrder : []}
                                title="Скан платежного поручения"
                                deleteFileRequest={(...args)=>deleteDoc("paymentOrder", ...args)}
                                uploadFileRequest={(...args) => uploadFiles("paymentOrder", ...args)}
                            />
                            <UploaderDocs
                                files={documents?.clientDocuments ? documents?.clientDocuments : []}
                                title="Документы клиента"
                                deleteFileRequest={(...args)=>deleteDoc("clientDocuments", ...args)}
                                uploadFileRequest={(...args) => uploadFiles("clientDocuments", ...args)}
                            />
                        </div>}
                    </Tabs>
                </div>
            </div>

        </div>
    )
});

export default Deal;
