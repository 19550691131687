import React, { useEffect, useState } from "react";
import './index.scss'
import { NavLink, useParams } from "react-router-dom";
import SearchInp from "../core/SearchInput";
import Dropdown from "../core/Dropdown";
import Button from "../core/Button";
import Table from "../core/Table";
import useApi from "../../hooks/useApi";
import Preloader from "../Preloader";
import { useModal } from "react-modal-hook";
import PanModal from "../core/PanModal";
import FixationModal from "../FixationModal";
import { FatalErrorComponent } from "../ErrorBoundry/FatalError";
import { LocaleNotFound } from "../core/Table/tableUtils";
import { fixationColumns } from "../../pages/Crm/columns";
import { CrmFilterBottomRow, MainCrmFilter } from "../../pages/Crm/CrmFilter";
import { crmFiltrateItems, defaultCrmFilter, fixationPageConfig } from "../../pages/Crm/utils";
import { compareWords } from "../../utils";
import { useMemo } from "react";
import FilterSkeleton from "../../pages/Crm/FilterSkeleton";


const Fixation = ({ location: { state: defaultFilter = defaultCrmFilter } }) => {
  const { id } = useParams()
  const { data: { fixes, filter: filterData, buttonLink, buttonTitle }, errors, isLoading } = useApi({
    payload: ["panpartner:deals.ajax", "getClientFixDeals", { clientId: id }],
  })

  const [filter, setFilter] = useState({...defaultFilter, search: ""})
  const setFilterValue = (key, value) => setFilter(prev => ({ ...prev, [key]: value }));

  const { topFilters, bottomFilters } = fixationPageConfig;
  const searchHashParams = new URLSearchParams(window.location.hash);

  const [fixationId, setFixationId] = useState('')

  const [openFixesModal, closeFixesModal] = useModal(
    () => (
      <PanModal
        title='Детали фиксации'
        closeModal={closeFixesModal}
        isBig
        hashParams={`fixationId=${fixationId}`}
        docTitle='Детали фиксации'
      >
        <FixationModal id={fixationId} />
      </PanModal>
    ), [fixationId]
  );
  useEffect(() => {
    const id = searchHashParams.get('fixationId');
    if (!id) return;
    setFixationId(id)
    openFixesModal()
  }, []);
  const error = errors?.[0]?.message
  const filteredItems = useMemo(() => {
    if (isLoading || !fixes?.length) return []
    return crmFiltrateItems({
      alternativeData: fixes,
      currentTab: fixationPageConfig,
      filter,
    }).filter(item => {
      if (!filter?.search?.replace(/ /gi, "")) return true
      return compareWords(item.builder.title, filter?.search)
    })
  }, [filter, fixes, isLoading])

  if (isLoading) return <Preloader loading />

  return (
    <div className="wrapper">
      <div className="common-header">
        <div className="common-header__title">
          <NavLink
            to={'/crm/fixations'}
            className="common-header__chevron"
          >
            <svg className="" width="7" height="12" viewBox="0 0 7 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 11L1.5 6L6.5 1" stroke="#2C2E3E" strokeLinecap="round" />
            </svg>
          </NavLink>
          <h3 className="common-title">
            {fixes?.[0]?.client}
          </h3>
        </div>
      </div>
      {isLoading && <FilterSkeleton />}
      {!isLoading && <div className="crm-filters-header">
        {filterData && <MainCrmFilter
          filterData={filterData}
          filter={filter}
          setFilter={setFilterValue}
          tabFilters={topFilters}
          searchPlaceholder={"Название застройщика"}
          link={{ buttonLink, buttonTitle }}
        />}
        {filterData && <CrmFilterBottomRow
          filters={bottomFilters}
          setFilter={setFilterValue}
          filter={filter}
        />}
      </div>}

      <div className="crm-table crm-table_client">
        {error && <FatalErrorComponent
          title={<h1 className="h1 fatal-title">Ошибка</h1>}
          text={error}
          showButton={false}
          style={{ height: "100%" }}
        />}
        {!error && <Table
          rowKey="id"
          columns={fixationColumns}
          dataSource={filteredItems}
          pagination={false}
          locale={LocaleNotFound}
          onRow={() => ({
            onClick: (e) => {
              setFixationId(e.currentTarget.getAttribute("data-row-key"))
              openFixesModal()
            }
          })}
        />}
      </div>
    </div>
  )
}

export default Fixation;
