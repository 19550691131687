import ru from 'date-fns/locale/ru';
import dateFormat from 'dateformat';
import _keys from 'lodash/keys';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ym from 'react-yandex-metrika';
import { getFormSettings } from '../../actions/forms';
import {
    fetchMeetingRoomsTypes,
    getClients,
    getDates,
    getMeetingRoomsByTypeDate,
    saveEventMeeting
} from '../../actions/meetingRooms';
import {
    fetchMeetings
} from '../../actions/meetings';
import { getUrl } from '../../api';
import DropdownMulti from '../../components/core/DropdownMulti';
import { ReactComponent as Desktop } from "../../images/svg/desktop.svg";
import { ReactComponent as MeetingRoom } from "../../images/svg/meetingroom.svg";
import { ReactComponent as Photo } from "../../images/svg/photo.svg";
import { ReactComponent as Welcome } from "../../images/svg/welcome.svg";
import { media } from '../../utils';
import Dropdown from '../core/Dropdown';
import MobileModal from '../core/MobileModal';
import SubmitButton from '../SubmitButton';
import { errorToast, infoToast, successToast } from '../toasts';
import FormAddClient from './FormAddClient';



registerLocale('ru', ru);
setDefaultLocale('ru');
const MeetingRoomPhoto = ({ photo, isDisabled }) => {
  const node = useRef();

  const [showTooltip, setShowTooltip] = useState(false);
  const handleClick = (e) => {
    if (!node.current.parentNode.parentNode.contains(e.target)) {
      setShowTooltip(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  },[])
  return <span className="meeting-room__photo" onClick={() =>isDisabled? ()=>{} : setShowTooltip(prev => !prev)}   ref={node} >
    <Photo />

    {showTooltip &&
      <div className="meeting-room__description">
        <img src={getUrl(photo)} alt="meeting room photo" style={{ maxWidth: 400 }} />

        {/* Вы будете в курсе обращений клиента в другое агентство (в случае если другое агентство так же отправило фиксацию по клиенту). Имея такую информацию, вы сможете правильно построить свою работу с клиентом. */}
      </div>}

  </span>
}

const ReserveMeetingRoom = (props) => {
  const {
    isFetching,
    data,
    dates,
    clients,
    fetch,
    fetchClients,
    fetchDates,
    close,
    defaultValues,
    handleReserve,
    fetchMeetings,
    user
  } = props;

  const [show, setShow] = useState(false);
  // const [isWeekend, setIsWeekend] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [excludeDates, setExcludeDates] = useState([])

  const [checked, setChecked] = useState(0);
  const [isFixateEnabled, setIsFixateEnabled] = useState(false)

  const lastIndex = dates && Object.keys(dates).length - 1
  const firstDay = dates && Object.keys(dates)[0]
  const lastday = dates && Object.keys(dates)[lastIndex]

  const [date, setDate] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null)
  const [availableMeetingRooms, setAvailableMeetingRooms] = useState([])
  const [formSettings, setFormSettings] = useState(null)
  const [builders, setBuilders] = useState([])
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [isClientSelected, setIsClientSelected] = useState(false)
  const isPeregoworking = user?.groups?.includes?.("PEREGOVORKING")

  const getFieldName = (code, isMulti) => {
    if (!formSettings) return
    for (let setting of formSettings) {
      if (setting.code === code) {
        if (isMulti) {
          return [...setting.items]
        }
        return setting.fieldName ? setting.fieldName : setting.items?.[0]?.fieldName
      }
    }
    return ""
  }
  const selectRoom = (val) => {
    let room = availableMeetingRooms.find(room => room.id == val)
    setIsFixateEnabled(room?.isFixateEnabled)
    setSelectedRoom(val)
  }
  
  let formatedDate = moment(date).format("YYYY-MM-DD")
  const currentDate = dates?.[formatedDate]
  const freeDates = currentDate?.filter(obj => !obj.isBooked)

  const getDate = timeObject => {
    const date = timeObject.date.split("-").join("/") + " " + timeObject.time
    return new Date(date)
  }
  const freeIntervals = currentDate?.reduce((acc, current, i, arr) => {
    if (!current.isBooked && arr[i - 1]?.isBooked) {
      let lastAvailableDate;
      for (let index = i; index < arr.length; index++) {
        if (arr[index].isBooked) {
          lastAvailableDate = arr[index]
          break;
        }
      }
      return [...acc, { start: current, end: lastAvailableDate }]
    }
    return acc
  }, []).map(interval => ({
    start: getDate(interval.start),
    end: getDate(interval.end)
  }))

  const filterTimeFrom = (time) => {
    if (!freeDates?.length) return false
    let mins = time.getMinutes()
    let hours = time.getHours()

    let selectedDate = new Date(date)
    selectedDate.setHours(hours)
    selectedDate.setMinutes(mins)
    if (selectedDate < new Date()) return false
    const timeTo = getValues("timeTo")
    if (!timeTo) {
      return freeIntervals.some(interval => interval.start <= selectedDate && interval.end > selectedDate)
    }
    let minsTo = timeTo.getMinutes()
    let hoursTo = timeTo.getHours()
    let dateTo = new Date(date)
    dateTo.setHours(hoursTo)
    dateTo.setMinutes(minsTo)
    if (selectedDate >= dateTo) return false
    return freeIntervals.some(interval => interval.start <= selectedDate && interval.end > selectedDate)
  };

  const filterTimeTo = (time) => {
    // порядок важен
    let limit = new Date()
    limit.setMilliseconds(1000 * 60 * 30)
    let mins = time.getMinutes()
    let hours = time.getHours()

    let selectedDate = new Date(date)
    selectedDate.setHours(hours)
    selectedDate.setMinutes(mins)
    if (selectedDate < new Date()) return false
    if (selectedDate < limit) return false
    if (!freeDates.length) return false
    const dateFrom = getValues("timeFrom") || getDate(freeDates?.[0])

    if (selectedDate < dateFrom) return false

    const timeFrom = moment(dateFrom).format("HH:mm")
    const timeTo = moment(selectedDate).format("HH:mm")
    if (timeFrom === timeTo) return false
    const timeFromIndex = currentDate.findIndex(item => item.time === timeFrom)
    const timeToIndex = currentDate.findIndex(item => item.time === timeTo)

    if (timeToIndex === (timeFromIndex + 1)) return true
    const lastAvailableTimeIndex = currentDate.findIndex(item => item.time === freeDates[freeDates.length - 1]?.time)

    if (timeToIndex === lastAvailableTimeIndex + 1) return true

    return freeIntervals.some(interval => interval.start < selectedDate && interval.end >= selectedDate)
  };

  useEffect(() => {
    if (date && timeFrom && timeTo && checked) {
      const day = moment(date).format("YYYY-MM-DD") + " "
      getMeetingRoomsByTypeDate("spb", checked, day + timeFrom, day + timeTo).then(resp => {
        setAvailableMeetingRooms(resp.meetingRooms)
      })
    }
  }, [date, timeFrom, timeTo])

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    getValues,
    setError,
    clearError,
    reset
  } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (data && data.meetingRooms && data.meetingRooms[0]) {
      setValue('meetingRoomId', data.meetingRooms[0].id);
    }
  }, [data]);
  useEffect(() => {
    if (selectedRoom) {
      fetchClients()
    }
  }, [selectedRoom])
  useEffect(() => {
    if (isClientSelected) {
      getFormSettings(35).then(resp => {
        setFormSettings(resp.questions)
        setBuilders(resp.builders)
      })
    }
  }, [isClientSelected])
  const resetForm = () => {
    reset()
    setAvailableMeetingRooms([])
    setSelectedBuilders([])
    selectRoom(null)
    setTimeTo(null)
    setTimeFrom(null)
    setValue("timeFrom", null)
    setValue("timeTo", null)
    setIsClientSelected(false)
  }

  useEffect(() => {
    if (checked) {
      resetForm()
      fetchDates(checked)
    }
  }, [checked]);

  useEffect(() => {
    setDate(new Date(firstDay))
  }, [dates]);

  let history = useHistory();

  const handleAddClient = (id) => {
    setValue('clientId', id);
    setShow(false);
  };

  const onSubmit = (data) => {
    let form = new FormData();
    let keys = _keys(data);

    keys.forEach((item) => {
      let identity = item;
      switch (identity) {
        case 'timeFrom':
        case 'timeTo':
          let time = dateFormat(data[item], 'HH:MM');
          form.append(`${identity}`, time);
          break;
        case 'date':
          let date = dateFormat(data[item], 'dd.mm.yyyy');
          // let date = new Date(data[item]);
          form.append(`${identity}`, date);
          break;
        case 'clientId':
          if (!!data[item]) {
            form.append(`${identity}`, data[item]);
            const clientIdField = getFieldName("clientId")
            form.append(clientIdField, data[item])
          }
          break;
        case "builderId":
          break;
        default:
          form.append(`${identity}`, data[item]);
      }
    });

    form.append("meetingRoomId", selectedRoom)
    if (isClientSelected) {
      form.append("formId", 35)
      const userFieldName = getFieldName("name")
      form.append(userFieldName, `${user.name} ${user.lastName}`)
      const agreeFields = getFieldName("policy", true)?.[0]
      form.append(agreeFields?.fieldName, agreeFields?.value)
      const buildersFormSettings = getFieldName("builders", true)
      for (let i = 0, len = selectedBuilders.length; i < len; i++) {
        const builderId = selectedBuilders[i];
        if (i < len) {
          form.append(buildersFormSettings[i].fieldName, builderId)
        } else {
          form.append("builders_text[]")
        }
      }
    }
    setLoading(true);
    saveEventMeeting(form).then(
      (data) => {
        fetchMeetings()
        close();
        setLoading(false);
        successToast(<div>{data.message}</div>)
        if (isClientSelected) return
        infoToast(
               <div className="Toastify__text">
              Хотите зафиксировать клиента?

              <ul className="Toastify__list">
                <li
                  onClick={() => history.push("/client-fixation")}
                  className="Toastify__list-active"
                >
                  Да
                </li>
                <li>Нет</li>
              </ul>
            </div>, { delay: 500 }
        )
        if (handleReserve) handleReserve();
        try {
          ym('reachGoal', 'meeting_room_booking');
        } catch (error) {
          console.log({ error });
        }
      },
      (error) => {
        setLoading(false);
        errorToast(<div>{error?.[0]?.message}</div>)
      },
    );
  };
 
  // отключение вручную, без бэка
  // exludeDates("2020/12/31", "2021/01/07")
  // excludeTtimes("2020/12/30 16:30", 8, times)
  // const excludeTtimes = (startDate, hours) => {
  //   let selectedDate = getValues('date') // не трогаем, выбранная юзером дата
  //   let day = selectedDate.getDate()
  //   let month = selectedDate.getMonth()
  //   let year = selectedDate.getFullYear()

  //   let manualExclusion = []
  //   const startTime = new Date(startDate) //с какого числа и времени отключить бронирование "2020/12/30 16:30"
  //   const step = 60000 * 30 //шаг, мс * минуты
  //   const multiplier = hours * 2  // 16 - 8 часов при шаге  60000 * 30 (30 мин), меняем только его и startTime

  //   let condition = day === startTime.getDate() && month === startTime.getMonth() && year === startTime.getFullYear() // при каком условии выключать
  //   if (condition) {
  //     for (let i = 0; i < multiplier; i++) {
  //       let timeString = dateFormat(startTime, 'yyyy/mm/dd HH:MM')
  //       manualExclusion.push(new Date(timeString))
  //       startTime.setMilliseconds(step)
  //     }
  //   }
  //   setExcludeTimes(prev => ([...prev, ...manualExclusion]));
  // }

  // const exludeDates = (startDate, endDate) => {
  //   const oneDay = 1000 * 60 * 60 * 24
  //   const start = new Date(startDate)
  //   const end = new Date(endDate)
  //   const delta = (end.getTime() - start.getTime()) / oneDay + 1
  //   const manualExclusion = []
  //   for (let i = 0; i < delta; i++) {
  //     let timeString = dateFormat(start, 'yyyy/mm/dd')
  //     manualExclusion.push(new Date(timeString))
  //     start.setMilliseconds(oneDay)
  //   }
  //   setExcludeDates(manualExclusion)
  // }


  // const handleDate = (value) => {
  //   setIsWeekend(value.getDay() == 0 || value.getDay() == 6);
  //   setValue("timeFrom", undefined)
  //   setValue("timeTo", undefined)

  // };

  const handleDate = (value) => {
    setValue("timeFrom", null)
    setValue("timeTo", null)
    setTimeTo(null)
    setTimeFrom(null)
    setAvailableMeetingRooms([])
    setDate(value)
  };
  const handleTime = (value, type) => {
    let time = moment(value).format("HH:mm:ss")
    type === "from" ? setTimeFrom(time) : setTimeTo(time)
  };

  const filterDates = (date) => {
    return true
    // const day = getDay(date);
    // return day !== 0;
  };

  const [showAddClientModal, hideClientModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        closeClick={hideClientModal}
        title="Добавить клиента"
      >
        <FormAddClient
          show={true}
          onAddClient={(id) => handleAddClient(id)}
          onClose={hideClientModal}
        />
      </MobileModal>
    ),
  );



  return (
    <>
      <div className="assignation-reservation for-meetings">
        {!media('isMobile') &&
          <>
            <div
              className="assignation-reservation__header"
              style={{
                backgroundColor: 'rgba(143, 149, 172, 0.08)',
                height: '290px',
                padding: 0,
              }}
            >
              <div
                className="assignation-reservation__header-bg"
                style={{ paddingTop: '25px' }}
              >
                <svg className="icon">
                  <use xlinkHref="#meeting_rooms"></use>
                </svg>
              </div>
            </div>
            <h2 className="modal__title">Забронировать переговорную</h2>
          </>
        }
        <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-form__row numeric-form-item">
            <div className="modal-form__item">
              <div className="select select_type_form">
                <label className="numeric-form-item__label" htmlFor="modal-task">
                  <span className="numeric-form-item__number">01</span>
                  Выберите тип переговорной
                </label>
                {/*
                <Controller
                  as={Dropdown}
                  name="meetingRoomId"
                  control={control}
                  options={data.meetingRooms}
                  ref={register({ required: true })}
                />*/}

                <div className={`meeting-room-types ${data?.meetingRoomsTypes?.length < 3 ? 'for-less' : ''}`}>
                  {data?.meetingRoomsTypes?.map((item, index) => {
                    const isDisabled = !isPeregoworking && item.xmlId === "PEREGOVORKING"
                    return (
                    <div className={`${isDisabled ? "with-tooltip" : "with-tooltip-disabled"}`}>
                      <label className={`meeting-room ${checked === item.id ? 'is-active' : ''}`} key={index}>
                        <div className="meeting-room__plan" style={{...(isDisabled? {opacity: 0.5 }: [])}}>
                          {item.xmlId === 'WORKTOP' ? <Desktop /> : item.xmlId === 'MEETING_ROOM' ? <Welcome /> : <MeetingRoom />}
                        </div>
                        <div style={{ display: "flex", ...(isDisabled? {opacity: 0.5 }: []) }}>
                          {item.photos?.[0]?.src && <MeetingRoomPhoto photo={item.photos?.[0]?.src} isDisabled={isDisabled} />}
                          <span className="meeting-room__title">{item.title}</span>
                        </div>
                        <input
                          className="meeting-room__input"
                          type="radio"
                          name={item.title}
                          checked={checked === item.id}
                          onChange={(e) => isDisabled ? ()=>{} : setChecked(item.id)}
                        />

                      </label>
                      <span className="tooltip" style={{maxWidth: 200}} >Бронирование доступно только для пользователей переговоркинга.<br/> За более подробной информацией обратитесь к своему управляющему отдела развития</span>
                    </div>
                  )})}
                </div>
              </div>
            </div>
          </div>

          <div className={`modal-form__row for-shedule numeric-form-item ${checked > 0 ? '' : 'is-disabled'}`}>
            <label className="numeric-form-item__label">
              <span className="numeric-form-item__number">02</span>
              Укажите дату и время
            </label>
            <div className="modal-form__item for-date">
              <label
                className="input input_width_available input_type_form"
                htmlFor="modal-task-date"
              >
                <span className="input__label">Дата</span>
                <Controller
                  as={
                    <DatePicker
                      dateFormat="d MMM yyyy"
                      minDate={new Date(firstDay)}
                      maxDate={new Date(lastday)}
                      showTimeSelect={false}
                      filterDate={filterDates}
                      todayButton="Сегодня"
                      dropdownMode="select"
                      autoComplete="off"
                      className="input__control"
                      onSelect={(val) => handleDate(val)}
                      shouldCloseOnSelect
                      value={date}
                    />
                  }
                  control={control}
                  register={register({ required: true })}
                  name="date"
                  valueName="selected" // DateSelect value's name is selected
                  onChange={([selected]) => {
                    return selected;
                  }}
                  rules={{
                    required: true,
                  }}
                />
              </label>
            </div>
            <div className="modal-form__item for-time">
              <div className="modal-form__row">
                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-task-time"
                  >
                    <span className="input__label">Время от</span>
                    <Controller
                      as={
                        <DatePicker
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          filterTime={filterTimeFrom}
                          timeCaption="Время"
                          autoComplete="off"
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          className={
                            'input__control' +
                            (errors.timeFrom ? ' input__error' : '')
                          }
                          shouldCloseOnSelect
                        />
                      }
                      control={control}
                      register={register({ required: true })}
                      name="timeFrom"
                      valueName="selected"
                      onChange={([selected]) => {
                        handleTime(selected, "from")
                        return selected;
                      }}
                      rules={{
                        required: 'Выберите время',
                      }}
                    />
                    {errors.timeFrom && (
                      <span className="input__error-label">
                        {errors.timeFrom.message}
                      </span>
                    )}
                  </label>
                </div>

                <div className="modal-form__item">
                  <label
                    className="input input_width_available input_type_form"
                    htmlFor="modal-task-time"
                  >
                    <span className="input__label">Время до</span>
                    <Controller
                      as={
                        <DatePicker
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          timeCaption="Время"
                          autoComplete="off"
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          filterTime={filterTimeTo}
                          className={
                            'input__control' +
                            (errors.timeTo ? ' input__error' : '')
                          }
                          shouldCloseOnSelect
                        />
                      }
                      control={control}
                      register={register({ required: true })}
                      name="timeTo"
                      valueName="selected"
                      onChange={([selected]) => {
                        handleTime(selected, "to")
                        return selected;
                      }}
                      rules={{
                        required: 'Выберите время',
                      }}
                    />
                    {errors.timeTo && (
                      <span className="input__error-label">
                        {errors.timeTo.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={`modal-form__row numeric-form-item for-table ${availableMeetingRooms.length > 0 ? '' : 'is-disabled'}`}>
            <div className="modal-form__item">
              <div className="select select_type_form">
                <label className="numeric-form-item__label">
                  <span className="numeric-form-item__number">03</span>
                  Выберите место
                </label>

                <div className="numeric-form-item__list-wrap">
                  <ul className="numeric-form-item__list">
                    {availableMeetingRooms.map(room => {
                      return <li className={`numeric-form-item__radio ${selectedRoom === room.id ? "is-active" : ""}`} onClick={() => selectRoom(room.id)} key={`room-${room.id}`}>
                        {room.name}
                        <input type="radio" />
                      </li>
                    })}
                  </ul>
                </div>

              </div>
            </div>
          </div>
          {isFixateEnabled && <>
            <div className={`modal-form__row numeric-form-item ${selectedRoom ? "" : "is-disabled"}`}>
              <label className="numeric-form-item__label">
                <span className="numeric-form-item__number">04</span>
              Зафиксируйте клиента
            </label>
              <div className="modal-form__item for-clients">
                {clients && clients.length > 0 && (
                  <div className="select select_type_form for-clients">
                    <label
                      className="custom-select-label"
                      htmlFor="modal-reservation-client"
                    >
                      Клиент
                  </label>
                    <Controller
                      as={Dropdown}
                      name="clientId"
                      options={clients}
                      onChange={([value]) => {
                        setIsClientSelected(!!value)
                        return value
                      }}
                      inputRef={register}
                      control={control}
                      placeholder="Не выбран"
                    />
                  </div>
                )}

                <div
                  className="add-client js-show active"
                  style={{ display: !show ? 'flex' : 'none' }}
                  onClick={() => media('isMobile') ? showAddClientModal() : setShow(true)}
                >
                  <button
                    className="button button_type_add"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  ></button>
                  <span>Добавить клиента</span>
                </div>

                {!media('isMobile') &&
                  <FormAddClient
                    show={show}
                    onAddClient={(id) => handleAddClient(id)}
                    closeClick={() => setShow(false)}
                    forFixate
                  />
                }

                {clients?.length > 0 && selectedRoom && isClientSelected && <div className="modal-form__row for-builders">
                  <div className={`modal-form__item ${media('isMobile') ? 'modal-form__full-width' : ''}`}>
                    <Controller
                      as={DropdownMulti}
                      name={"builderId"}
                      items={builders}
                      data={{
                        title: 'Выберите застройщика',
                        identity: 'builderId',
                      }}
                      onChange={([value]) => {
                        setSelectedBuilders(value);
                        if (value.length === 0) {
                          setError(
                            "builderId",
                            "notMatch",
                            "Выберите застройщика",
                          );
                          setIsClientSelected(false)
                        } else {
                          setIsClientSelected(true)
                          clearError("builderId");
                        }
                        return value;
                      }}
                      isAutocomplete
                      innerRef={register({ required: true })}
                      control={control}
                      isMobile={media('isMobile')}
                      autocompleteTitle={"Введите название застройщика"}
                    />
                    {errors.builderId && (
                      <span className="input__error-label">
                        {errors.builderId.message}
                      </span>
                    )}
                  </div>
                </div>}
                <div className={"modal-form__row fixation-badges-container "} style={{ display: selectedBuilders?.length > 0 ? "flex" : "none" }}>
                  {selectedBuilders.map((builderId, index) => (
                    <div class="filtered fixation-badges" key={'builder-' + index}>
                      <div className="filtered__inner">
                        <span className="filtered__type"></span>
                        <span className="filtered__value"> {builders.find((builder) => builder.id == builderId)?.name}</span>
                        <button
                          class="button filtered__del"
                          type="button"
                          onClick={() =>
                            setSelectedBuilders((prev) => {
                              let tmpBuilders = prev.filter((id) => id != builderId);
                              setValue('builderId', tmpBuilders);
                              return tmpBuilders;
                            })
                          }
                        >
                          <svg
                            className="icon icon_x"
                            width="7"
                            height="8"
                            viewBox="0 0 7 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
                            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                  {selectedBuilders.length > 0 && (
                    <button
                      class="button button_type_filtered filtered-clear fixation-badges"
                      onClick={() => {
                        setSelectedBuilders([]);
                        setValue('builderId', []);
                      }}
                    >
                      <span>Очистить параметры</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>}
          <div className={`modal-form__row numeric-form-item no-border ${selectedRoom ? "" : "is-disabled"}`}>
            <div className="modal-form__item">
              <label
                className="textarea textarea_type_form textarea_width_available"
                htmlFor="cl-comment"
              >
                <span className="textarea__label">Комментарий</span>
                <textarea
                  className="textarea__control"
                  id="cl-comment"
                  name="comment"
                  placeholder="Текст комментария"
                  rows="5"
                  ref={register}
                ></textarea>
              </label>

              <div className="checkbox checkbox_view_invert">
                <div
                  className={
                    'checkbox__box' +
                    (errors.agree ? ' checkbox__box-error' : '')
                  }
                >
                  <input
                    className="checkbox__control"
                    type="checkbox"
                    id="modal-reservation-agreement"
                    name="agree"
                    ref={register({
                      required:
                        'Необходимо согласиться с правилами обработки данных',
                    })}
                  />
                  <label
                    className="checkbox__input"
                    htmlFor="modal-reservation-agreement"
                  ></label>
                  <div className="checkbox__marker"></div>
                </div>
                <label
                  className="checkbox__label"
                  htmlFor="modal-reservation-agreement"
                >
                  Cогласие на обработку персональных данных
                </label>
              </div>
              {errors.agree && (
                <span className="input__error-label">
                  {errors.agree.message}
                </span>
              )}

            </div>
          </div>

          <div className={`modal-form__submit numeric-form-item ${selectedRoom ? "" : "is-disabled"}`}>
            <SubmitButton
              className="button button_view_default"
              type="submit"
              isLoading={loading}
            >
              <span>Отправить</span>
            </SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
};



const mapStateToProps = (state) => {
  const cleanClient = {
    id: 0,
    lastName: "Не выбран",
    name: "",
    patronymic: "",
  }
  return ({
    isFetching: state.meetingRooms.isFetching,
    data: state.meetingRooms.data,
    clients: state.meetingRooms.clients,
    dates: state.meetingRooms.dates,
    user: state.user.info,
  })
};

const mapDispatchToProps = (dispatch) => ({
  fetchMeetings: () => dispatch(fetchMeetings()),
  fetch: (city) => dispatch(fetchMeetingRoomsTypes(city)),
  fetchClients: (city) => dispatch(getClients(city)),
  fetchDates: (type) => dispatch(getDates(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReserveMeetingRoom);
