
import React from 'react';
import useFileManager from '../hooks/useFileManager';
import Dropzone from './core/Dropzone';

const SandBox = props => {

  const { files, uploadFiles, deleteFile, editFileName } = useFileManager({
    maxFiles: 10,
  })
  return <Dropzone
    uploadFiles={uploadFiles}
    style={{
      width: 1000,
      height: 500,
      background: "#c53636ba"
    }}
  >
    <h3>Дропай сюда</h3>
  </Dropzone>
}
export default SandBox
