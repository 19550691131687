import React, {memo, useState} from "react";
import Card from "../../../core/Card";
import {getUrl} from "../../../../api";
import {evaulateEndings, getRoundedPrice} from "../../../../utils";

const MainCardContainer = memo(props => {
  const { name, link, photo, address, builder, apartmentsInfo, nearby, endings, toggleFavorite, id, index, get, favoritesPath } = props
  const path = favoritesPath ? favoritesPath(index): `blocks[${index}].favorite`
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const handleFavorite = e => {
    e.preventDefault()
    if (isFavoriteLoading) return
    setIsFavoriteLoading(true)

    toggleFavorite({id}, `topStarts[${index}].favorite`).finally(setIsFavoriteLoading)
  };

  return <Card
    {...props}
    link={link}
    title={name}
    subtiltle={address}
    src={getUrl(photo.src)}
    topLabel={evaulateEndings(endings)}
    descTitle={builder.name}
    tableItems={apartmentsInfo?.apartmentsByRoomType?.map?.(item => ({
      id: item.roomTypeId,
      title: item.roomTypeName,
      count: item.count,
      middleTitle: item.minSquare ? `от ${item.minSquare} м²` : "",
      subtitle: `от ${getRoundedPrice(item.minPrice)} млн.р.`
    }))}
    // bottomLabelList={nearby.map(item => item.title)}
    handleFavorite={handleFavorite}
    inFavorite={get(`topStarts[${index}].favorite.inFavorite`)}
  />
});

export default MainCardContainer
