import classNames from 'classnames';
import React, { useRef } from 'react';
import SimpleBar from 'simplebar-react';
import useDropdown from '../../hooks/useDropdown';
import useElementPosition from "../../hooks/useElementPosition";
import { media } from '../../utils';
import useMedia from '../../hooks/useMedia';

const FloorsFilter = (props) => {
  const {
    onChange,
    filter,
    data,
    items,
    right,
    secondData,
    secondChange,
    ident,
    isCommercial,
    autoPosition,
    wrapperClass,
  } = props;
  const isMobile = useMedia("isMobile");
  const [node, open, show, hide] = useDropdown(false);
  const isNotFirstFloor = secondData && secondData.value == 'Y';
  const formItemRef = useRef(null)
  const position = useElementPosition(formItemRef, 150)
  const handleChange = (e, name) => {
    let val = '';
    const { value, id } = e.target;
    if (!isCommercial) {
      if (isNotFirstFloor && value == 1) {
        val = '2';
      } else {
        val = value;
      }
    } else if (isCommercial && isNotFirstFloor && value != 1) {
      val = '1';
    } else {
      val = value;
    }
    onChange({ filter: { ...filter, [name]: val } }, ident);
  };

  let dropdownClasses = classNames({
    dropdown__block: true,
    dropdown__block_align_right: right || (autoPosition && position.includes("right") ),
  });
  const handleSecond = (e) => {
    let checked = e.target.checked;
    let conditionMax = filter?.max == 1;
    let conditionMin = filter?.min == 1;
    let change = (type, val) =>
      onChange({ filter: { ...filter, [type]: val } }, ident);

    let tmpFilter =  {filter: { ...filter}}
    if (isCommercial) {
      if (filter?.max && !conditionMax && checked) {
        tmpFilter.filter.max = "1"
      }
      if (filter?.min && !conditionMin && checked) {
        tmpFilter.filter.min = "1"
      }
      onChange(tmpFilter, ident);
    } else {

      if (conditionMax && checked) {
        tmpFilter.filter.max = "2"
      }
      if (conditionMin && checked) {
        tmpFilter.filter.min = "2"
      }
      onChange(tmpFilter, ident);
    }
    secondChange(e.target.checked ? 'Y' : 'N');
  };

  const clearFilters = (e) => {
    e.preventDefault()
    if(props.getCount && typeof props.getCount === "function"){
      props.getCount({
        ...props.fullFilter,
        filter: props.defFullFilter
          ? props.defFullFilter.filter
          : props.defaultFilter,
      });
    }
    props.clearFilterOnly()
    props.clearExcludeOnly()
  }

  return (
    <div  className={`form__item ${wrapperClass? wrapperClass: ""}`} ref={formItemRef}>
      <div
        ref={node}
        className={'dropdown form__drop' + (open ? ' is-showing' : '')}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">{data.title}</span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        {open && <div className={dropdownClasses}>
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data.title}</h3>
                </div>
              }

              {secondData && (
                <div
                  className="checkbox checkbox_view_red"
                  style={media("isMobile") ? { padding: '10px' } : { margin: '10px 0' }}
                >
                  <div className="checkbox__box first-order">
                    <input
                      className="checkbox__control"
                      type="checkbox"
                      id="no-first-floor"
                      checked={isNotFirstFloor}
                      onChange={handleSecond}
                    />
                    <label
                      className="checkbox__input"
                      htmlFor="no-first-floor"
                    ></label>
                    <div className="checkbox__marker forced-enable-z"></div>
                  </div>
                  <label className="checkbox__label second-order" htmlFor="no-first-floor">
                    {secondData.title}
                  </label>
                </div>
              )}
              <div className="filter">
                <div className="filter__cols for-floors">
                  <div className="filter__col">
                    <div className="filter__filter ">
                      <label
                        className="input input_type_default input_width_available"
                        htmlFor="deadline-1"
                      >
                        <input
                          className="input__control is-thin"
                          type="number"
                          min={isCommercial ? '-5' : '1'}
                          max="200"
                          value={
                            isNotFirstFloor && filter?.min == 1 && !isCommercial
                              ? -(-filter?.min - 1)
                              : filter?.min
                          }
                          placeholder={`${data.minPlaceholder}${
                            data.defaultValues
                              ? ` ${data.defaultValues?.min}`
                              : ''
                          }`}
                          onChange={(e) => handleChange(e, 'min')}
                        />
                      </label>
                    </div>
                    {items && (
                      <SimpleBar className="filter__checks">
                        <div className="checkbox-group">
                          {items.map((item) => (
                            <div
                              className="checkbox-group__item "
                              key={item.id}
                            >
                              <div className="checkbox checkbox_type_btn full-width-list-item">
                                <div
                                  className="checkbox__box full-width-list-item"
                                  style={{ textAlign: 'center' }}
                                >
                                  <input
                                    className="checkbox__control full-width-list-item"
                                    type="radio"
                                    name="deadline-from"
                                    id={`deadline-from-${item.id}`}
                                    value={item.id}
                                    onChange={(e) => handleChange(e, 'min')}
                                    checked={String(item.id) == filter?.min}
                                    disabled={
                                      isNotFirstFloor &&
                                      (item.id == 1 || isCommercial)
                                    }
                                  />
                                  <label
                                    className="checkbox__label full-width-list-item"
                                    htmlFor={`deadline-from-${item.id}`}
                                    style={{
                                      cursor: 'pointer',
                                      ...(isCommercial &&
                                      isNotFirstFloor &&
                                      item.id != 1
                                        ? { filter: 'opacity(0.3)' }
                                        : []),
                                      ...(!isCommercial &&
                                      isNotFirstFloor &&
                                      item.id == 1
                                        ? { filter: 'opacity(0.3)' }
                                        : []),
                                    }}
                                  >
                                    {item.title}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>

                  <div className="filter__col">
                    <div className="filter__filter ">
                      <label
                        className="input input_type_default input_width_available"
                        htmlFor="deadline-2"
                      >
                        <input
                          className="input__control is-thin"
                          type="number"
                          min={isCommercial ? '-5' : '1'}
                          max="200"
                          id="deadline-2"
                          value={
                            isNotFirstFloor && filter?.max == 1 && !isCommercial
                              ? -(-filter?.max - 1)
                              : filter?.max
                          }
                          onChange={(e) => handleChange(e, 'max')}
                          placeholder={`${data.maxPlaceholder}${
                            data.defaultValues
                              ? ` ${data.defaultValues?.max}`
                              : ''
                          }`}
                        />
                      </label>
                    </div>
                    {items && (
                      <SimpleBar className="filter__checks">
                        <div className="checkbox-group">
                          {items.map((item) => (
                            <div
                              className="checkbox-group__item "
                              key={item.id}
                            >
                              <div className="checkbox checkbox_type_btn full-width-list-item">
                                <div
                                  className="checkbox__box full-width-list-item"
                                  style={{ textAlign: 'center' }}
                                >
                                  <input
                                    className="checkbox__control full-width-list-item"
                                    type="radio"
                                    name="deadline-to"
                                    id={`deadline-to-${item.id}`}
                                    value={item.id}
                                    onChange={(e) => handleChange(e, 'max')}
                                    checked={String(item.id)  == filter?.max}
                                    disabled={
                                      isNotFirstFloor &&
                                      (item.id == 1 || isCommercial)
                                    }
                                  />
                                  <label
                                    className="checkbox__label full-width-list-item"
                                    htmlFor={`deadline-to-${item.id}`}
                                    style={{
                                      cursor: 'pointer',
                                      ...(isCommercial &&
                                      isNotFirstFloor &&
                                      item.id != 1
                                        ? { filter: 'opacity(0.3)' }
                                        : []),
                                      ...(!isCommercial &&
                                      isNotFirstFloor &&
                                      item.id == 1
                                        ? { filter: 'opacity(0.3)' }
                                        : []),
                                    }}
                                  >
                                    {item.title}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                </div>

                {isMobile &&
                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        clearFilters(e)
                        hide()
                      }}
                    >
                      Очистить
                    </button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        hide()}
                      }
                    >
                      Применить
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default FloorsFilter;
