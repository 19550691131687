import { createSelector } from "reselect";

const commercialSelector = (state) => state.commercial.data;


export const mapCommercial = createSelector([commercialSelector], (data) => {
  let features = [];

  if (data && data.length > 0) {
    let tmpData =[...data];
    tmpData.map((item) => {
        if (item.coordinates) {
          features.push({
            type: "Feature",
            id: item.id,
            geometry: {
              type: "Point",
              coordinates: item.coordinates,
            },
            properties: {
              hintContent: item.block?.name,
            },
          });
        }

        return item;
      });

  }

  return features;
});

