import React from "react";
import { useModal } from "react-modal-hook";
import Modal from "../core/Modal";
import HotNewsForm from "../forms/HotNews.js";
import "./index.scss";
import NewsShare from './Share.js';

const HotNews = (props) => {

  const [hotNewsModal, hideHotNewsModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideHotNewsModal}>
        <HotNewsForm close={hideHotNewsModal} />
      </Modal>
    )
  );

  return (

    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`Закрытый старт продаж самого ожидаемого well-being проекта в Москве`} />
        <div className="news__container container">
          <h1 className="h1 news__title">Закрытый старт продаж самого ожидаемого well-being проекта в Москве</h1>
          <img src={require('../../assets/images/news/hot.svg').default} alt="" className="news__promo-img" />
          <p className="h3 news__subtitle">
            <span role="img" aria-label="hot">🔥</span> Эксклюзивно для партнёров ПАН-Москва открыто предбронирование нового жилого квартала
            в Хорошево-Мневниках
          </p>

          <p className="news__text"><span role="img" aria-label="close">🔒</span> Предбронирование закрыто для прямых клиентов. Заявки на бронь принимаются только через ПАН и отдел партнёрских продаж.</p>
          <p className="news__text"><span role="img" aria-label="happy">🤗</span> Бронируйте с нами ещё до официального старта продаж в начале декабря и получите авансирование в 3 дня и максимальный процент со сделки.</p>
          <p className="news__text"><span role="img" aria-label="house">🏠</span> ЖК в Хорошево-Мневниках — это квартиры бизнес-класса на набережной Москвы-реки рядом с новой станцией метро "Пресня".</p>

          <ul className="news__list">
            <li><span role="img" aria-label="dash">➖</span>Площадь участка – 19,3 га.</li>
            <li><span role="img" aria-label="dash">➖</span>В проекте предусмотрены 13 кварталов и 33 корпуса,</li>
            <li><span role="img" aria-label="dash">➖</span>Парк размером 1,5 га, набережная, детский сад с самыми современными методиками обучения и развития детей, школа, поликлиника, супермаркеты, спа-центр, спортивная инфраструктура и многое другое.</li>
          </ul>

          <p className="news__text"><span role="img" aria-label="eyes">👀</span> Благодаря переменной этажности в проекте много видовых квартир. Многослойность отделки выглядит эстетично и премиально. Предусмотрено центральное кондиционирование и вентиляция с системой очистки по 7 показателям и фильтрация воды.</p>


          <p className="news__text"><span role="img" aria-label="square">▪</span> Продуманы функциональные планировки с отделкой класса де-люкс. Клиенты оценят повышенную звуковую и шумоизоляцию всех перекрытий.</p>

          <p className="news__text"><span role="img" aria-label="dollar">💲</span> Минимальные цены в проекте:</p>

          <ul className="news__list">
            <li><span role="img" aria-label="dash">➖</span>Однокомнатные — от 11 998 520 руб.</li>
            <li><span role="img" aria-label="dash">➖</span>Двухкомнатные — от 14 436 880 руб.</li>
            <li><span role="img" aria-label="dash">➖</span>Трёхкомнатные — от 20 112 105 руб.</li>
            <li><span role="img" aria-label="dash">➖</span>Четырёхкомнатные — от 32 832 625 руб.</li>
          </ul>

          <p className="news__text"><span role="img" aria-label="on">🔛</span> На рынок выходит проект, превосходящий ожидания бизнес-класса, с решениями, которые не всегда присутствуют даже в премиальных домах.  </p>


          <p className="h3 news__subtitle"><span role="img" aria-label="clock">⏱</span> Успейте забронировать самый ожидаемый проект в ноябре через ПАН Партнёр на очень выгодных условиях. </p>

          <button type="button" rel="noopener noreferrer" className="news__btn button button_view_default" onClick={hotNewsModal}>Узнать подробнее</button>

        </div>
      </div>

    </section>
  )
};

export default HotNews;
