import React from "react";
import {useParams} from "react-router-dom";
import VillagesDubai from "./ComplexList";
import Estate from "../../Estate";
import ComplexDubai from "./index";
import Complex from "../index";
import Dubai from "../../../pages/Filter/Dubai";
import NewEstate from "../../FilterRealty/Redesign/NewEstate";
import EstatePage from "../../Public/markup/EstatePage";
import ComplexNew from "../ComplexNew";

const ForDubaiRoute = props => <Dubai {...props} />

export default ForDubaiRoute

export const ForDubaiRouteSecond = props => {
  const { city } = useParams();
  return (
    <>
      {
        city === 'dxb' || city === "cyp" ?
          // <ComplexDubai {...props}/> : <Complex {...props}/>
          <ComplexDubai {...props}/> : <ComplexNew {...props}/>
      }
    </>
  )
}
