import React, { useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import {Link, NavLink, Route, Switch, useLocation} from "react-router-dom";
import request from "../../api";
import Button from "../../components/core/Button";
import { CheckboxControlled } from "../../components/core/Checkbox";
import Modal from "../../components/core/Modal";
import Table from "../../components/core/Table";
import { LocaleNotFound } from "../../components/core/Table/tableUtils";
import Fixation from "../../components/Fixation";
import Loader from "../../components/Loader";
import { standartErrorToast, successToast } from "../../components/toasts";
import useApi from "../../hooks/useApi";
import usePermission from "../../hooks/usePermission";
import CrmEditSf from "../CrmEditSf";
import Deal from "../Deals";
import { CrmFilterBottomRow, MainCrmFilter } from "./CrmFilter";
import FilterSkeleton from "./FilterSkeleton";
import { crmFiltrateItems, CRM_TABS, defaultCrmFilter, getCurrentCRMTab } from "./utils";
import _get from 'lodash/get'
import './index.scss';
import ClientViewings from "../../components/ClientViewings";
import ClientsList from "../../components/Clients/ClientsList";
import List from "../../components/Presentations/List";
import BackDeals from "../../components/BackDeals";
import NewFavorites from "../NewFavorites";
import MotivationLast from "../../components/Motivation/MotivationLast";

const links = [ // удалить когда надо будет вернуть табы, раскоментить CRM_TABS
  // { id: 2, title: "Клиенты", url: "/crm/clients" },
  // { id: 3, title: "Презентации", url: "/personal/presentations" },
  // { id: 4, title: "Подборка фильтров", url: "/personal/filters" },
  // { id: 5, title: "Подборки", url: "/compilations" },
]

const Crm = ({ match: { path }, location: { pathname }, history }) => {
  const currentTab = getCurrentCRMTab(pathname)
  const payload = currentTab?.payload
  const [filter, setFilter] = useState(defaultCrmFilter)
  const [selectedItems, setSelectedItems] = useState([])
  const user = useSelector(state => state.user?.info)
  const isNotAgent = usePermission("not-agent")
  const resetFilter = () => setFilter(defaultCrmFilter)
  const setFilterValue = (key, value) => setFilter(prev => ({ ...prev, [key]: value }));

  const { data, isLoading, refresher, isRefreshing } = useApi({
    payload,
    processData: (resp, prev) => ({ ...prev, [pathname]: resp }),
    loadCondition: data => !data[pathname] && payload,
    successCallback: resp => document.title = resp.meta?.title ?? "ПАН партнер",
  }, [payload])
  const currentTabData = data[pathname] ?? {}

  const filteredItems = useMemo(() => {
    return isLoading || isRefreshing ? [] : crmFiltrateItems({
      currentTab,
      data: currentTabData,
      filter,
      userId: user.id,
    }) ?? []
  }, [currentTabData, isLoading, isRefreshing, filter]);
  const onTableClick = ({ clientId, id }, rowIndex) => {
    if (currentTab.selectionEnabled) return;
    const path = `${currentTab?.isDefault ? currentTab.url : pathname}/${clientId ? clientId : id}`.replace(/\/\//g, "/")
    history.push(path, filter)
  }
  const columns = currentTab?.columns?.filter?.(column => {
    const permissionCond = !column.permission || (column.permission === "not-agent" && isNotAgent)
    let filterCondition = true;
    if (column.filterBy) {
      const [path, comparator] = column.filterBy;
      const value = _get(currentTabData, path);
      filterCondition = comparator(value);
    }
    return permissionCond && filterCondition;
  })

  const onSelectItem = (checked, id) => {
    if (id == "all") {
      setSelectedItems(checked ? filteredItems.filter((item) => user.id == item?.agentId) : []);
      return;
    }

    const checkedItem = filteredItems.find((item) => item.id == id)
    setSelectedItems(
      checked
        ? [...selectedItems, ...(checkedItem ? [checkedItem] : [])]
        : [...selectedItems.filter((item) => item.id != id)]
    );
  };
  const deleteSf = () => {
    request("panpartner:saved.filter.ajax", "delete", {
      id: selectedItems.map(item => item.id),
    }).then(
      resp => {
        successToast(resp.message);
        closeDeleteModal();
        refresher();
        setSelectedItems([]);
      },
      standartErrorToast
    )
  }
  const [openDeleteModal, closeDeleteModal] = useModal(
    () => (
      <Modal withoutBG classes="crm-table__sf-controlsmodal" closeByPastClick close={closeDeleteModal}>
        <div className="crm-table__sf-controlsmodaltitle">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="8.5" stroke="#FF2D23" />
            <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke="#FF2D23" strokeLinecap="round" />
            <circle cx="12.043" cy="15.5" r="0.5" fill="#FF2D23" />
          </svg>
          Удалить подборки?
        </div>
        <div className="crm-table__sf-controlsmodaltext">
          После удаления вся информация будет утеряна
        </div>
        <div className="crm-table__sf-controlsmodalbtns">
          <Button buttonType="line" onClick={deleteSf}>Да</Button>
          <Button buttonType="line" onClick={closeDeleteModal}>Нет</Button>
        </div>
      </Modal>
    ),
    [selectedItems]);

  return (
    <>
      <div className="wrapper" key={Date.now()}>
        <div className="common-header"><h3 className="h3">CRM</h3></div>
        <ul className="tabs-nav tabs-nav_crm">
          {CRM_TABS.map(({ title, url, isDefault }, i) => {
            return (
              <li key={i} className="tabs-nav-item">
                <NavLink
                  className={isActive => `tabs-nav-item__button ${isActive ? "tabs-nav-item__button--active" : ''}`}
                  to={loc => isDefault && loc?.pathname === path ? path : url}
                >
                  {title}
                </NavLink>
              </li>
            )
          })}
          {links.map(({ title, url }, i) => {
            return (
              <li key={i} className="tabs-nav-item">
                <Link
                  className={'tabs-nav-item__button'}
                  to={url}
                  // target="_blank"
                >
                  {title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
        <div className="wrapper fixations">
          {(isLoading || isRefreshing) && payload && <FilterSkeleton/>}
          {!isLoading && !isRefreshing && <div className="crm-filters-header">
            {payload && <MainCrmFilter
              filterData={currentTabData?.filter ?? currentTabData?.filters}
              filter={filter}
              setFilter={setFilterValue}
              tabFilters={currentTab?.topFilters}
              searchPlaceholder={currentTab?.searchPlaceholder}
              link={currentTabData}
              permissions={{"not-agent": isNotAgent}}
              url={currentTab?.url}
              showDeleteButton={!!selectedItems?.length && currentTab?.showDeleteButton}
              onDelete={openDeleteModal}
            />}
            {payload && currentTab?.bottomFilters && <CrmFilterBottomRow
              filters={currentTab?.bottomFilters}
              setFilter={setFilterValue}
              filter={filter}
              permissions={{"not-agent": isNotAgent}}
            />}
          </div>}

          {isLoading && payload && <div className="crm-table crm-table_deals">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader loaderColor='#2c2e3e'/>
              {/*<object*/}
              {/*  style={{ width: "120px" }}*/}
              {/*  type="image/svg+xml"*/}
              {/*  data="/assets/images/pan-preloader.svg"*/}
              {/*>*/}
              {/*  <img src="/assets/images/pan-preloader.svg" alt="preloader" />*/}
              {/*</object>*/}
            </div>
          </div>}
          {!isLoading && data[pathname] && <div className="crm-table crm-table_filters">
            <Table
              rowSelection={currentTab.selectionEnabled ? {
                columnTitle: (...ars) => {
                  return <CheckboxControlled
                    view="invert"
                    checked={!!filteredItems?.length && selectedItems?.length === filteredItems?.filter?.((item) => user.id == item?.agentId)?.length}
                    onChange={e => onSelectItem(e.target.checked, "all")}
                    id={"all"}
                  />
                },
                renderCell: (checked, {id, agentId}, index, originNode) => {
                  return <CheckboxControlled
                    view="invert"
                    id={`row-item-${id}`}
                    checked={!!selectedItems.find(item => item.id == id)}
                    onChange={e => onSelectItem(e.target.checked, id)}
                    disabled={agentId != user.id}
                  />
                },
              } : null}
              columns={columns}
              dataSource={filteredItems}
              pagination={false}

              customPagination={true}
              firstPageCount={10}
              // pageCount={50}
              locale={LocaleNotFound}
              rowKey="id"
              onRow={row => ({
                onClick: e => onTableClick(row, e)
              })}
            />
          </div>}
        </div>

      {/* TO DO: подумать, мб вынести этот роут отсюда  и убрать услови payload */}
      <Switch>
        <Route exact path={`${path}/deals`} render={() => <BackDeals fromCrm/>} />
        <Route exact path={`${path}/clients`} render={() => <ClientsList fromCrm/>} />
        <Route exact path={`${path}/presentations`} render={() => <List fromCrm/>} />
        <Route exact path={`${path}/compilations`} render={() => <NewFavorites fromCrm/>} />
        <Route exact path={`${path}/motivation`} render={() => <MotivationLast fromCrm/>} />
        <Route exact path={`${path}/fixations/:id`} component={Fixation} />
        <Route exact path={`${path}/viewing/:id`} component={ClientViewings} />
        <Route exact path={`${path}/deals/:id`} component={Deal} />
        <Route exact path={`${path}/filters/:type/:savedFilterXmlId/:city?`} component={CrmEditSf} />
      </Switch>
    </>
  )
}

export default Crm;
