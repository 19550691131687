import React from "react";
import "./showroom-tooltip.scss";

const ShowroomTooltip = () => {
  return (
    <div className="showroom-tooltip">
      <div className="showroom-tooltip__decor">
        <div className="showroom-tooltip__icon">
          <svg className="icon icon_info">
            <use xlinkHref="#info"></use>
          </svg>
        </div>
      </div>
      Типовой <br/> шоу-рум от застройщика
    </div>
  )
};

export default ShowroomTooltip;
