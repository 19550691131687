import { BurgerIcon } from './icons';
import Button from '../../core/Button';
const TopBar = ({ setShowMap }) => {
  return (
    <Button
      addClass="sf__backbutton"
      buttonType="line"
      onClick={() => setShowMap(false)}
    >
      <BurgerIcon />
      <span>Показать объекты списком</span>
    </Button>
  );
};

export default TopBar;
