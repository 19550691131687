import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchClients } from "../../actions/clients";
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from "../../actions/favorites";

import useApi from "../../hooks/useApi";
import useDocTitle from "../../hooks/useDocTitle";
import usePermission from "../../hooks/usePermission";
import useRootCSS from "../../hooks/useRootCSS";
import MortgageBlock from "../Apartment/MortgageBlock";
import AssignationText from "../Assignation/AssignationText";
import Preloader from "../Preloader";
import Header from "../Public/markup/Header";
import SecondaryInfo from "../Secondaries/SecondaryInfo";
import {fetchSecondary} from "../../actions/secondary";



const View = (props) => {
  const { id, savedFilterXmlId } = useParams();
  const city = "spb";
  const dispatch = useDispatch()
  const clients = useSelector(state => state.clients?.data)
  const showMotivation = usePermission("motivation")

  const {
    data: {
      secondary: data = {},
      subways,
      client,
      agent,
      isFilterDisabled,
      savedFilterSettings
    },
    isLoading,
  } = useApi({
    payload: ["panpartner:secondaries.ajax", "getSecondary", { id, savedFilterXmlId }],
  }, [id])

  useDocTitle(data.squareTotal ?
    `${data.roomType?.name ? data.roomType?.name : data.objectType?.name}. ${data.squareTotal} м² ${data.address ? "по адресу " + data.address : ""}`
    : ""
  )
  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })
// debugger
  console.log(savedFilterSettings)

  const handleFavorites = inFavorite => inFavorite ? removeFromFavorite() : addToFavorite();
  const addToFavorite = () => dispatch(addToFavorites("spb", id, "secondary", "secondary"));
  const removeFromFavorite = () => dispatch(removeFromFavorites("spb", id, "secondary", "secondary"));
  const addClientToFavorites = (...args) => {
    const [id, clientId, client, favoriteId, whatPage, type] = args
    dispatch(addClientToFavorite(id, clientId, client, favoriteId, "secondary", type),
    )
  }
  const removeClientFromFavorites = (...args) => {
    const [id, clientId, client, favoriteId, whatPage, type] = args
    dispatch(removeClientFromFavorite(id, clientId, client, favoriteId, "secondary", type),
    )
  }
  return (
    <>
      {agent && !isFilterDisabled && (<div className="sf wrapper sf_inner-public">
        <Header {...agent} savedFilterSettings={savedFilterSettings} />
      </div>)}
      {data && !isLoading && (
        <SecondaryInfo
          data={data}
          manager={data.manager}
          subwaysList={subways}
          city={city}
          addToFavorites={addToFavorite}
          addClientToFavorite={addClientToFavorites}
          removeClientFromFavorite={removeClientFromFavorites}
          handleFavorites={handleFavorites}
          clients={clients}
          fetchClients={() => dispatch(fetchClients())}
          isAgent={showMotivation}
          isSecondary={true}
          presentationType={"secondary"}
          isPublic={true}
          savedFilterXmlId={savedFilterXmlId}
          client={client}
          agent={agent}
          savedFilterSettings={savedFilterSettings}
        />
      )}
      {data && !!data.note && <AssignationText note={data.note} />}
      {data && !isLoading && (
        <MortgageBlock
          price={Number(data.costWithDiscount)}
          city={city}
          type="secondary"
          id={id}
          period={15}
          prepay={Number(data.costWithDiscount) * 0.2}
          percent={6.5}
          showInstallment={false}
          isPublic={true}
        />
      )}
      <Preloader isLoading={isLoading || !data} />
    </>
  );
};

export default View;
