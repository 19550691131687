import React, {useEffect, useState} from "react";
import useApi from "../../../hooks/useApi";
import Slider from "../../Public/markup/detail/Slider";
import NewAlert from "../../core/NewAlert";
import InfoCard from "../../Public/markup/detail/InfoCard";
import ResidenceHint from "../../ResidenceHint";
import ContactCard from "../../core/ContactCard";
import SubmitButton from "../../SubmitButton";
import {useSelector} from "react-redux";
import AddPresenatation from "../../AddPresentation";
import {
  AddToCompilationIcon,
  CardBottomStatusIcon,
  LockIcon,
  PdfIcon,
  PresentationIcon
} from "../../Public/markup/icons";
import {getUrl} from "../../../api";
import Preloader from "../../Preloader";
import {useParams} from "react-router-dom";
import Header from "../../Public/markup/Header";
import useMedia from "../../../hooks/useMedia";
import {successToast} from "../../toasts";
import {objectsArrayToFormData, toFormData} from "../../../utils";
import useModalWithData from "../../../hooks/useModalWithData";
import CompilationModal from "../../../pages/NewFavorites/compilationModal";
const FastViewDubaiModal = ({planId, activeCurrency, priceFormat, planTitle, selectedItem, publicManager, isPublicModal, xmlId, city}) => {

  const { id, savedFilterXmlId } = useParams();
  const isPublic = !!savedFilterXmlId
  // const isPublic = isPublicModal

  const {data: {photos,
                objectInfo,
                residencePermit,
                residencePermitInfo,
                priceInfo,
                managerInfo,
                comment,
                title,
                presentation,
                fixationLink,
                coordinates,
                countInfo,
                agent,
                savedFilter,
                savedFilterSettings,

  }, error, send, isLoading, isSending} = useApi({
    payload: ["panpartner:primary.ajax", "fastView", isPublic ? {id, city, savedFilterXmlId: xmlId} : {id: planId, city, savedFilterXmlId: xmlId}],
  })

  const isMobile = useMedia('isMobile');
  !isPublic && title && planTitle(title)
  const userInfo = useSelector(state => state.user?.info);
  const [callbackStatus, setCallbackStatus] = useState(false)
  const callback = () => send(["panpartner:forms.ajax", "callback", {
    urgently: true,
    managerId: isPublicModal && publicManager? publicManager?.id:  managerInfo?.id,
    name: `${userInfo?.name} ${userInfo?.lastName}`,
    type: 'global',
    objectId: isPublic ? id : planId,
    city: city,
    savedFilterXmlId: xmlId
  }]).then(
    (success) => {
      setCallbackStatus({ type: "success" })},
    err => setCallbackStatus({ type: "error", message: err[0]?.message })
  )

  const renderPresentations = ({ fetching, finish, link, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={finish ? () => window.open(link) : handleSend}
    >
      <PresentationIcon />
      {finish || presentation?.id ? "К презентации" : "В презентации"}
    </SubmitButton>
  }


  const [filePath, setfilePath] = useState(false)
  const renderPdf = ({ fetching, finish, resp, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={filePath ? () => window.open(filePath) : () => handleSend().then(resp => {
        window.open(getUrl(resp?.filePath))
        setfilePath(getUrl(resp?.filePath))
      })}
    >
      <PdfIcon />
      Скачать PDF
    </SubmitButton>
  }

  const isSmallLaptop = useMedia('isSmallLaptop');



  if (isLoading) return <Preloader loading />;
  return (
    <div className={`sf ${isPublic ? 'wrapper' : ''}`}>

      {isPublic && !savedFilterSettings?.hideHeader && (
        <>
          <Header {...agent} savedFilterSettings={savedFilterSettings} />
          <br/>
          <br/>
        </>
      )}

      {!isPublic && !isMobile &&
      <div className="sf sf--apt-modal">
        <div className="sf__elems" style={{marginTop: 35}}>
          {presentation &&
          <AddPresenatation
            type={'global'}
            id={planId}
            city={city}
            presentation={presentation}
            render={renderPresentations}
          />
          }


          <AddPresenatation
            type={'global'}
            id={planId}
            city={city}
            method="getPdf"
            render={renderPdf}
          />
          {/*{!!fixationLink && <button*/}
          {/*  type="button"*/}
          {/*  className="sf__elem-btn button link"*/}
          {/*  onClick={() => window.open(fixationLink)}*/}
          {/*>*/}
          {/*  <LockIcon />*/}
          {/*  Зафиксировать клиента*/}
          {/*</button>}*/}
        </div>
      </div>
      }


      <div className="sf__detail-row sf__detail-row--common-modal">
        <div className="sf__detail-col">
          {isSmallLaptop &&
          <div className="sf__card-units">
            <CardBottomStatusIcon classname={countInfo?.color}/> {countInfo?.value}
          </div>}

          {!!photos?.length && (
            <Slider
              items={photos}
              withBorder
              showButton
              complexMap={coordinates}
            />
          )}
          {comment &&
          <div className="fv-comment" style={!isSmallLaptop ? {marginTop: 32} : {marginTop: 32, marginBottom: -20}}>
            <NewAlert
              text={comment.value}
              title={comment.title}
              bgColor="#f6f7f8"
              iconColor="#2CBA2E"
              linkColor="#2CBA2E"
              openAppModal
              oneLine
            />
          </div>
          }

        </div>
        <div className="sf__detail-col">

          {
            countInfo && !isSmallLaptop &&
            <div className="sf__card-units">
              <CardBottomStatusIcon classname={countInfo?.color}/> {countInfo?.value}
            </div>
          }

          {<InfoCard items={objectInfo} priceFormat={priceFormat} activeCurrency={activeCurrency} choiceCurrency={priceInfo} />}

          {
            residencePermit &&
            <ResidenceHint
              title={residencePermitInfo?.[0]?.title}
              text={residencePermitInfo?.[0]?.value}
              withBottomBorder={!isSmallLaptop}
            />
          }
          {managerInfo && !isPublicModal ?  <ContactCard
            forAptModal
            logo
            {...managerInfo}
          /> :
            <ContactCard
              forAptModal
              logo
              {...publicManager}
            />
          }
          {callbackStatus?.type === "success" && <NewAlert
            title="Заявка отправлена"
            text={`Ожидайте звонок. ${managerInfo?.name ? managerInfo?.name + " скоро свяжется с Вами." : "Мы свяжемся с Вами в рабочее время."}`}
            bgColor="#DFF5E0"
            iconColor="#2CBA2E"
          />}
          {callbackStatus?.type === "error" && <NewAlert
            title="Ошибка"
            text={callbackStatus?.message}
            bgColor="#FFEAE9"
            iconColor="#FF2D23"
          />}
          <section className="sf__section">
            {!(callbackStatus?.type === "success") && <SubmitButton
              type="button"
              className="btn h6 btn_primary btn_full"
              onClick={callback}
              isLoading={isSending}
            >
              Перезвоните мне
            </SubmitButton>}
          </section>
        </div>
      </div>
    </div>
  )
}

export default FastViewDubaiModal
