import { memo } from "react";
import { getUrl } from "../../../../api";
import Card from "../../../core/Card";

const SuburbanHouseCard = memo((props) => {
    const { number, link, endingDate, plan, priceDiscount, squareParcel, squareHouse, materialHouse } = props;

    return <Card
        {...props}
        isFlat
        isBordered
        topLabel={number}
        link={link}
        title={priceDiscount}
        subtiltle={squareHouse}
        src={getUrl(plan?.src ?? plan)}
        descValue={endingDate}
        bottomText={materialHouse}
    />
});

export default SuburbanHouseCard;