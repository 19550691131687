import {TelegramIcon, WhatsupIcon} from '../icons';
import { getUrl } from '../../../../api';
import React from "react";

const ManagerModal = ({
  name = '',
  phone = null,
  photo = null,
  post = null,
  telegram = null,
  whatsApp = null,
  email = null,
}) => (
  <section className="sf__manager sf__block sf__manager_modal">
    <header className="sf__manager-header">
      {photo && (
        <div className="sf__manager-pic">
          <img src={getUrl(photo.src)} alt="" />
        </div>
      )}
    </header>

    <div className="sf__manager-right">
      <div className="sf__manager-info">
        <p className="sf__manager-name h6">{name}</p>
        {post && <p className="sf__manager-pos">{post}</p>}
        {email && <a href={`mailto:${email}`} className="link link_color_black">{email}</a>}
      </div>
 
      <div className="sf__manager-contacts">
        <div className="sf__manager-col">
          {phone && (
            <a href={`tel:${phone}`} className="link link_color_black">
              {phone}
            </a> 
          )}

          {telegram && (
            <a
              href={`https://t.me/${telegram}`}
              className="sf__manager-social"
              target={'_blank'}
              title={`${name} в Telegram`}
              rel="noreferrer"
            >
              <TelegramIcon />
            </a>
          )}

          {whatsApp && (
            <a
              href={`https://wa.me/${whatsApp}`}
              className="sf__manager-social"
              target={'_blank'}
              rel="noreferrer"
              style={{marginLeft: '10px'}}
            >
              <WhatsupIcon />
            </a>
          )}

        </div>
      </div>
    </div>
  </section>
);

export default ManagerModal;
