import React, { useEffect, useState } from 'react';
import { YMaps, Map } from 'react-yandex-maps';
import useMedia from '../../hooks/useMedia';

export const SuburbanPresentationMap = ({
  cords = [],
  mapCenter = [],
  address,
  zoom = 9
}) => {
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const isLaptop = useMedia("isLaptop")

  useEffect(() => {
    if (!mapInstance || !ymaps) return;
    const placemarkTemplate = ymaps.templateLayoutFactory.createClass(
      `<div class="pan-comfort__marker" style="display: flex; align-items: center; width: max-content;">
      <svg style="min-width: 45px" width="45" height="48" viewBox="0 0 45 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.5145 9.09831L37.9125 7.92167C32.1342 3.65701 27.1348 0 22.5276 0V0.646337L22.4448 0C17.8707 0 12.8769 3.68463 7.08201 7.95482L5.49104 9.12596C0.955685 12.4405 0 16.0919 0 18.5612V30.3388C0 35.8298 3.86694 38.7024 7.64548 38.7024H9.85516C11.4461 38.7024 13.3077 38.7024 14.3518 38.7024V42.6025C14.3322 43.5823 14.6102 44.5451 15.1491 45.3637C15.688 46.1822 16.4625 46.8181 17.3703 47.1873C18.2781 47.5565 19.2766 47.6418 20.2338 47.4318C21.1911 47.2217 22.0622 46.7263 22.732 46.0109L29.361 38.9676C29.4423 38.8803 29.5406 38.8108 29.6499 38.7633C29.7592 38.7158 29.8771 38.6913 29.9963 38.6914H37.3877C41.807 38.6914 45 35.1725 45 30.3222V18.5336C45 16.0643 44.0498 12.4183 39.509 9.09831M14.7606 34.2499H7.88302C4.86682 34.2499 4.62927 32.1839 4.62927 31.5541V17.8652C4.62927 16.4621 5.6402 15.0147 7.63995 13.5508L9.51816 12.1698C13.8602 8.96576 19.7987 4.59059 22.4392 4.55192H22.5773C25.1737 4.55192 31.1453 8.97126 35.515 12.1753L37.3877 13.5508C39.3819 15.0147 40.3928 16.4621 40.3928 17.8652V31.532C40.3928 31.532 40.2326 34.2278 37.6307 34.2278H29.3445C28.8127 34.2252 28.286 34.3322 27.7975 34.5422C27.3089 34.7523 26.8689 35.0609 26.505 35.4487L19.2573 43.1825C19.2119 43.2365 19.1502 43.2743 19.0815 43.2903C19.0127 43.3062 18.9407 43.2994 18.8761 43.2709C18.8098 43.2466 18.7528 43.2021 18.7131 43.1436C18.6734 43.0851 18.6532 43.0156 18.6552 42.945V38.1169C18.6479 37.0935 18.2382 36.1141 17.5146 35.3905C16.7909 34.6669 15.8116 34.2572 14.7882 34.2499" fill="#EF372E"/></svg>
      ${!isLaptop ? `<div class="pan-comfort__marker-text" style="margin-left: 8px; font-size: 20px; border-radius: 50px; font-weight: 700; color: white; background-color: #EF372E; padding: 5px 15px 6px 15px;">${address}</div>` : ''}
    </div>`,
    );
    const placeMaprk = new ymaps.Placemark(
      cords,
      {},
      {
        iconLayout: placemarkTemplate,
        iconOffset: [-22.5, -48],
      },
    );
    mapInstance.geoObjects.add(placeMaprk);
  }, [mapInstance, ymaps]);



  return <YMaps>
    <Map
      defaultState={{
        center: mapCenter,
        zoom,
      }}
      modules={['templateLayoutFactory', 'Template', 'Placemark']}
      onLoad={setYmaps}
      instanceRef={setMapInstance}
      className="complex__map-wrap"
    />
  </YMaps>
};
