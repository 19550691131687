import React, { useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import { resetPassword } from "../../actions/agents";
import { media } from '../../utils';
import Confirm from "../core/Confirm";
import MobileConfirm from "../core/MobileConfirm";
import Popup from '../core/Popup';
import Table from "../core/Table";
import { errorToast, infoToast } from "../toasts";
import Dropdown from "./Dropdown";


const activeList = [
  {
    id: 0,
    title: "Не активен",
    label: "label_red",
  },
  {
    id: 1,
    title: "Активен",
    label: "label_purple",
  },
];

const AgentName = (props) => {
  const getTitle = () => {
    let title = `${props.lastName} ${props.name}`;
    if (props.patronymic) title += ` ${props.patronymic.charAt(0)}.`;
    return title;
  };

  return (
    <Link to={`/personal/agents/edit/${props.id}`} className="clients__table-link clients__table-client">
      {getTitle()}
      <svg className="icon icon_external-link">
        <use xlinkHref="#external_link"></use>
      </svg>
    </Link>
  );
};

const AgentAction = (props) => {
  const { id, deleteClient } = props;
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const showConfirm = () => {
    confirmModal();
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const confirm = () => {
    let person = `${props.lastName} ${props.name}`;
    deleteClient(props.id).then(() => {
      hideModal();
      infoToast(
        <div className="Toastify__text">
        Агент&nbsp;<strong>{person}</strong>&nbsp;удален
      </div>
      )
    }, errors=> {
      hideModal();
      errorToast(<div>{errors[0]?.message}</div>)
    });
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить агента ${props.name} ${props.lastName}?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [confirmMobileModal, hideMobileModal] = useModal(({ in: open, onExited }) => (
    <MobileConfirm
      closeClick={hideMobileModal}
      title={`Вы действительно хотите удалить агента ${props.name} ${props.lastName}?`}
      mobileTitle="Удаление"
      confirm={confirm}
      cancel={hideMobileModal}
    />
  ));

  const onResetPassword = () => {
    resetPassword({ agentId: id }).then(
      (data) => {
        setOpen(false);
        infoToast(
          <div className="Toastify__text">
            {data.message}
          </div>
        )
      },
      error => errorToast(error?.[0]?.message)
    );
  };

  const [closePopup, setClosePopup] = useState(false);
  const closeClick = () => {
    setClosePopup(true)
    setClosePopup(false)
  }

  const mobileRemoveAgent = () => {
    confirmMobileModal()
    closeClick()
  }

  useEffect(() => {
    setClosePopup(true)
  }, [closePopup]);

  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={
            "dropdown js-dropdown form__drop" + (open ? " is-showing" : "")
          }
        >
          {media('isMobile') ?
            <Popup
              trigger={
                <button
                  type="link"
                  className="dropdown__btn button form__drop-btn button_type_round"
                  onClick={(e) => setOpen(!open)}
                >
                  <span className="form__drop-name"></span>
                  <svg className="icon icon_elipsis">
                    <use xlinkHref="#elipsis"></use>
                  </svg>
                </button>
              }
              closePopup={closePopup}
            >
              <div className="dt__more">
                <hr />
                <div>
                  <Link
                    className="dt__more-control"
                    to={`/personal/agents/edit/${props.id}`}
                    onClick={closeClick}
                  >
                    <svg className="icon icon_edit">
                      <use xlinkHref="#edit"></use>
                    </svg>
                    <span>Редактировать</span>
                  </Link>
                </div>
                <div
                  className="dt__more-control"
                  onClick={() => {
                    onResetPassword()
                    closeClick()
                  }}
                >
                  <svg className="icon icon_lock">
                    <use xlinkHref="#icon_lock"></use>
                  </svg>
                  <span>Отправить пароль</span>
                </div>
                <div
                  className="dt__more-control"
                  onClick={media('isMobile') ? mobileRemoveAgent : showConfirm}
                >
                  <svg className="icon icon_delete">
                    <use xlinkHref="#delete"></use>
                  </svg>
                  <span>Удалить</span>
                </div>
              </div>
            </Popup> :

            <>
              <button
                type="link"
                className="dropdown__btn button form__drop-btn button_type_round"
                onClick={(e) => setOpen(!open)}
              >
                <span className="form__drop-name"></span>
                <svg className="icon icon_elipsis">
                  <use xlinkHref="#elipsis"></use>
                </svg>
              </button>
              <div className="dropdown__block">
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    <div className="dt__more">
                      <hr />
                      <div>
                        <Link
                          className="dt__more-control"
                          to={`/personal/agents/edit/${props.id}`}
                        >
                          <svg className="icon icon_edit">
                            <use xlinkHref="#edit"></use>
                          </svg>
                          <span>Редактировать</span>
                        </Link>
                      </div>
                      <div className="dt__more-control" onClick={onResetPassword}>
                        <svg className="icon icon_lock">
                          <use xlinkHref="#icon_lock"></use>
                        </svg>
                        <span>Отправить пароль</span>
                      </div>
                      <div className="dt__more-control" onClick={showConfirm}>
                        <svg className="icon icon_delete">
                          <use xlinkHref="#delete"></use>
                        </svg>
                        <span>Удалить</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  const { items, handleActive, handleDelete } = props;

  const getMobileColumns = () => [
    {
      title: "Сотрудник",
      dataIndex: "name",
      key: "client",
      render: (text, record) => {
        return <AgentName {...record} />;
      },
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => (
        <a className="clients__phone" key={phone} href={`tel:${phone}`}>
          {phone}
        </a>
      ),
    },
    {
      title: "",
      key: "action",
      width: 50,
      render: (text, record) => (
        <AgentAction {...record} deleteClient={handleDelete} />
      ),
    },
  ]

  const getColumns = () => [
    {
      title: "Сотрудник",
      dataIndex: "name",
      key: "client",
      width: 150,
      render: (text, record) => {
        return <AgentName {...record} />;
      },
    },
    {
      title: "Руководитель",
      dataIndex: "master",
      key: "master",
      width: 150,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      width: 220,
      render: (phone) => (
        <a className="clients__phone" key={phone} href={`tel:${phone}`}>
          {phone}
        </a>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      width: 250,
      key: "email",
    },
    {
      title: "Права",
      dataIndex: "rights",
      width: 200,
      key: "rights",
    },
    {
      title: "Активность",
      dataIndex: "active",
      key: "active",
      width: 100,
      render: (active, record) => (
        <Dropdown
          value={activeList[+active]}
          options={activeList}
          onChange={(value) => {
            handleActive(record.id, value);
          }}
        />
      ),
    },
    {
      title: "",
      key: "action",
      width: 50,
      render: (text, record) => (
        <AgentAction {...record} deleteClient={handleDelete} />
      ),
    },
  ];

  const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Руководитель: </span>
            {record.master}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">E-mail: </span>
            {record.email}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Права: </span>
            <span style={{ whiteSpace: 'normal', maxWidth: '120px' }}>{record.rights}</span>
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title is-centered">Статус: </span>
            <Dropdown
              value={activeList[+record.active]}
              options={activeList}
              onChange={(value) => {
                handleActive(record.id, value);
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="orders__content for-agents">
      <div className="flats__row-table is-active">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={media("isMobile") ? getMobileColumns() : getColumns()}
          expandable={media("isMobile") ? {
            expandedRowRender: record => expandedRow(record),
          } : false}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: "ничего не найдено" }}
          customPagination={true}
        />
      </div>
    </div>
  );
};

export default Items;
