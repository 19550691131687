import request from '../api';
import { actionFactory } from './actionUtils';

const component = "panpartner:commercials.ajax"

const requestCommercial = actionFactory("COMMERCIAL_REQUEST");

const successCommercial = actionFactory("COMMERCIAL_SUCCESS");

export const clearCommercial = actionFactory("COMMERCIAL_RESET");

const failureCommercial = actionFactory("COMMERCIAL_FAILURE");

export const setFilterCommercial = filter => ({
  type: 'COMMERCIAL_SET_FILTER',
  filter,
});

export const clearFilterCommercial = actionFactory("COMMERCIAL_CLEAR_FILTER");

export const fetchCommercial = (city, isGetFilter, form, savedFilterXmlId) =>
  dispatch => {
    dispatch(requestCommercial());
    return request(component, "getCommercials", {
      city: 'spb',
      ...form,
      savedFilterXmlId,
      isGetFilter
    })
      .then(
        (data) => {
          const {
            commercials,
            top,
            filter,
            subways,
            isActive,
            agent,
            isShowFilter,
            savedFilter,
            count
          } = data;
          dispatch(
            successCommercial({
              data: commercials,
              filterData: filter,
              top: top,
              subways: subways,
              isActive,
              agent,
              isShowFilter,
              savedFilter,
              count,
              isGetFilter: isGetFilter == 1
            }),
          );
          return Promise.resolve(data);
        },
        (error) => {
          dispatch(failureCommercial());
          return Promise.reject(error);
        },
      );
  };

const setCount = actionFactory("COMMERCIAL_SET_COUNT")

export const getCount = (params, savedFilterXmlId) =>
  dispatch =>
    request(component, "getCount", { ...params, savedFilterXmlId })
      .then(data => dispatch(setCount({ count: data.count })));

const successCommercialFilter = actionFactory("COMMERCIAL_FILTER_SUCCESS");

export const fetchCommercialFilter = (savedFilterXmlId, city = "spb") =>
  dispatch =>
    request(component, "getFilter", { city, savedFilterXmlId })
      .then(data => dispatch(successCommercialFilter({ filterData: data })));