import React from "react";
import useApi from "../../hooks/useApi";
import Preloader from "../Preloader";
import {Link} from "react-router-dom";
import Slider from "../Public/markup/detail/Slider";
import InfoCard from "../Public/markup/detail/InfoCard";
import {InfoListAdvIcon, InfoListAlarmIcon, TopArrowIcon} from "../Public/markup/icons";
import ContactCard from "../core/ContactCard";
import ('./index.scss')


const CaseModal = ({caseId, closeCaseModal}) => {

  const {data: {case: data = {}}, error, isLoading} = useApi({
    payload: ["panpartner:cases.ajax", "getItem", {id: caseId}],

  })

  console.log(data)
  // debugger

  if (isLoading) return <Preloader loading/>
  return (
    <>
        <div className="common-header">
          <div className="common-header__title common-header__title_withlink">
            <h3 className="common-title">
              {data?.title}
            </h3>
            <Link to={'/cases/' + caseId} target="_blank" className="common-header__fullinfo-link">
              Показать полную информацию
              <TopArrowIcon/>
            </Link>
          </div>
        </div>
        <div className="sf">
          <div className="sf__detail-row sf__detail-row--common-modal">
            <div className="sf__detail-col">
              <Slider
                items={data?.photos}
                viewFromWindow={data?.viewFromWindows}
                withBorder
                windowView
                showButton={!!data?.viewFromWindows}
              />
            </div>
            <div className="sf__detail-col">
              <InfoCard items={data?.mortgage} dateAdd={data?.dateAdd}/>

              <ul className="info-list">
                <li className="info-list__item">
                  <div className="info-list__icon"><InfoListAlarmIcon color={'#8790E5'}/></div>
                  <div className="info-list__content">
                    <p className="info-list__title h6" style={{color: '#8790E5'}}>Внимание</p>
                    <p className="info-list__text">Актуальность кейса уточняйте у менеджера</p>
                  </div>
                </li>
                <li className="info-list__item">
                  <div className="info-list__icon"><InfoListAdvIcon color={'#2CBA2E'}/></div>
                  <div className="info-list__content">
                    <p className="info-list__title h6" style={{color: '#2CBA2E'}}>Можно рекламировать</p>
                    <p className="info-list__text">Кейс соответствует рекламному регламенту</p>
                  </div>
                </li>
              </ul>
              {data.manager &&
                <ContactCard
                  name={data?.manager?.name + ' ' + data?.manager?.lastName}
                  phone={data?.manager?.phone}
                  telegram={data?.manager?.telegram !== '' ? data?.manager?.telegram : false}
                  logo
                  forAptModal
                  photo={data?.manager?.photo}
                  post={data?.manager?.post}
                />
              }

            </div>
          </div>
        </div>
    </>
  )
}

export default CaseModal
