import { memo } from "react";
import { getUrl } from "../../../../api";
import { evaulateEndings, getRoundedPrice, getSuffix } from "../../../../utils";
import Card from "../../../core/Card";


const DubaiPublicCard = memo(props => {

    const { currency = "RUB", link, photo, address, builder, apartmentsInfo, title, endings, onClick} = props
    return <Card
        {...props}
        src={getUrl(photo?.src)}
        title={title}
        descTitle={builder?.title}
        deadline={endings}
        subtiltle={address}
        customTable
        tableItems={apartmentsInfo?.apartmentsByRoomType.map?.(item => ({
            id: item.roomTypeId,
            title: item.roomTypeName,
            middleTitle: item.minSquare ? `от ${item.minSquare} м²` : "",
            subtitle: `от ${getRoundedPrice(item.minPrice[currency])} млн. ${currency}`
        }))}
        // link={`${link}?currency=${currency}`}
        link={link}
        onClick={()=>onClick?.(link.split("/").reverse()[0])}
    />
});

export default DubaiPublicCard;
