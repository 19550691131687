import { memo } from 'react';
import { Link } from 'react-router-dom';
import { defineSuffix } from '../../../../utils';
import { PromoIcon } from '../icons';
import { useModal } from 'react-modal-hook';
import PanModal from '../../../core/PanModal';
import PromoCardModal from './PromoCardModal';



const PromoBar = memo(({ id, managerInfo, count, activeTo, title, onClick }) => {

  const [openModal, closeModal] = useModal(
    () => (
      <PanModal title={'Акция'} closeModal={closeModal} isSmall>
        <PromoCardModal id={id} managerInfo={managerInfo} />
      </PanModal>
    ),
    [id],
  );

  return (
    <section className="sf__promo sf__block" onClick={openModal}>
      <div className="sf__promo-icon">
        <PromoIcon />
      </div>

      <div className="sf__promo-body">
        <p className="h4-l sf__promo-title">{title}</p>
        <p className="sf__promo-timing">
          <span className="sf__promo-key text">Акция действует:</span>
          <p className="sf__promo-value h6">{activeTo}</p>
        </p>
      </div>

      {count > 1 && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
          className="sf__promo-link link link_color_brand-3"
        >
          Смотреть еще {count - 1} акци
          {defineSuffix({ '1-1': 'ю', '2-4': 'и', default: 'й' }, count - 1)}
        </div>
      )}
    </section>
  );
});

export default PromoBar;
