import SimpleBar from 'simplebar-react';
import { memo } from 'react';

const MapList = memo(({ items, cardComponent: Card, getLink, onMouseMove, isShowFilter, ...props }) => {
  if (!items?.length) return null
  return (
    <div className="sf__map-list" style={!isShowFilter ? { 'height': '100%' } : {}}>
      <SimpleBar className="sf__map-scroll">
        {items.map((item, i) => <Card
          {...props}
          {...item}
          onMouseMove={onMouseMove}
          key={`${item.id}-${i}`}
          link={getLink(item)}
          isShort
          index={i}
        />)}
      </SimpleBar>
    </div>
  );
});

export default MapList;