import 'antd/es/table/style/index.css';
import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import {
  getPdf
} from '../../actions/presentations';
import useDropdown from '../../hooks/useDropdown';
import { downloadLink, media } from '../../utils';
import Checkbox from '../core/Checkbox';
import Confirm from '../core/Confirm';
import Dropdown from '../core/Dropdown';
import MobileConfirm from '../core/MobileConfirm';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import Popup from '../core/Popup';
import Table from '../core/Table';
import NewClientForm from '../forms/NewClient';
import SubmitButton from '../SubmitButton';
import { errorToast, infoToast, standartErrorToast } from '../toasts';
import SendPresentation from './SendPresentation';

const formattedDate = (date) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(date).toLocaleDateString('ru', options);
};

const formattedNumericDate = (date) => {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  return new Date(date).toLocaleDateString('ru', options);
};

const PresentationName = ({ cityName, name, object = {}, id, fromCrm, type }) => {
  const { blockName, corpus = '', roomTypeName = '', floor = '' } = object;

  // let roomType = roomTypeName && type !== "Вторичка"
  //   ? ', ' + roomTypeName.replace('ккв', 'к.кв.')
  //   : '';
  // let corp = corpus ? ', ' + corpus + ' корпус' : '';
  // let flatFloor = floor ? ', ' + floor + ' этаж' : '';
  // let block = blockName ? ', ' + blockName : ', ' + name;

  // let title = object
  //   ? `${type}, ${cityName}${block}${corp}${flatFloor}`
  //   : name;

  return (
    <>
      {media("isMobile") ?
        <div className="lk-presentations__name">
          <Link
            className="color-brand-1 link-hover"
            to={`/personal/presentations/edit/${id}`}
          >
            {name}
          </Link>
        </div> :
        <Link
          className="bold color-brand-1 link-hover"
          to={`/personal/presentations/edit/${id}${fromCrm ? '?fromCrm=true' : ''}`}
        >
          {name}
        </Link>
      }
    </>
  );
};

const PresentationClients = (props) => {
  const [node, open, show, hide] = useDropdown(false);
  const { way = 'left', clients, handleAttachClient, fetch, data } = props;

  const [activeTab, setActiveTab] = useState('attachClient');
  const [attachingClientId, setAttachingClientId] = useState(0);
  const [attaching, setAttaching] = useState(false);
  const [attachedClients, setAttachedClients] = useState([]);

  useEffect(() => {
    if (clients?.[0]) setAttachingClientId(clients[0].id);
  }, []);

  useEffect(() => {
    const items = [];

    if (data.clientId) {
      data.clientId.forEach((id) =>
        items.push(clients.find((client) => client.id == id)),
      );
    }

    setAttachedClients(items);
  }, [data]);

  const submitAttach = (clientId, action) => {
    setAttaching(true);
    handleAttachClient(clientId, data.id, data.city, action == 'add').then(
      (data) => {
        setAttaching(false);
        hide();
        infoToast(<div>{data.message}</div>)
      },
      (error) => {
        setAttaching(false);
        errorToast(<div>{error?.[0]?.message}</div>)
      },
    );
  };

  const handleAddClient = id => {
    setAttachingClientId(id)
    fetch();
    setActiveTab('attachClient');
  };

  const [attachMobileModal, hideAttachMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideAttachMobileModal} title="Прикрепить к своему клиенту">
        <div
          className="button-mobile is-add-client"
          onClick={openMobileAddForm}
        >
          <button className="button button_type_add"></button>
          <span>Добавить клиента</span>
        </div>

        <Dropdown
          options={clients}
          value={attachingClientId}
          onChange={setAttachingClientId}
        />

        <div className="mobile-filters__show-all">
          <SubmitButton
            className="button button_view_default"
            isLoading={attaching}
            onClick={() => submitAttach(attachingClientId, 'add')}
            disabled={!attachingClientId}
          >
            <span>Прикрепить</span>
          </SubmitButton>
        </div>
      </MobileModal>
    ),
    [
      clients,
      attachingClientId,
      setAttachingClientId,
      attaching,
      attachingClientId,
      setActiveTab,
      submitAttach
    ],
  );

  const [openMobileAddForm, closeMobileAddForm] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        title="Добавить клиента"
        closeClick={closeMobileAddForm}
        fixedTitle={true}
      >
        <NewClientForm onAdd={handleAddClient} />
      </MobileModal>
    ), [handleAddClient]
  );

  return (
    <div className="lk-presentations__clients">
      {attachedClients.length > 0 && (
        <ul className="lk-presentations__clients-list">
          {attachedClients.map((client, index) => {
            const name = client.title

            return (
              <li className="lk-presentations__client" key={client.id}>
                <div className="label_with-icon_postfix">
                  <div className="lk-presentations__client-name">
                    {media("isMobile")
                      ? (`${name.slice(name.indexOf(' '))} ${name?.[0]}.`)
                      : name
                    }
                  </div>
                  <button
                    className="button button_type_delete label__icon"
                    onClick={() => submitAttach(client.id, 'remove')}
                  ></button>
                </div>
              </li>
            )
          })}
        </ul>
      )}
      <div className="lk-presentations__clients-attach" ref={node}>
        {attachedClients.length > 0 && (
          <svg className="icon icon_plus">
            <use xlinkHref="#plus"></use>
          </svg>
        )}
        <div
          className={
            'popover js-popover fav-attach' + (open ? ' is-showing' : '')
          }
        >
          <div
            className="fav-attach__btns"
            onClick={() => {
              media('isMobile') ? attachMobileModal() : show()
            }}
          >
            <div className="fav-attach__btn">
              <svg className="icon icon_user">
                <use xlinkHref="#user"></use>
              </svg>
            </div>
            {attachedClients.length == 0 && (
              <div className="lk-presentations__clients-attach_new link-hover">
                <span>Прикрепить к клиенту</span>
              </div>
            )}
          </div>
          <div className={`popover__block popover__block_align_${way}`}>
            <div className="popover__inner">
              <div className="form-attach">
                <div
                  className={
                    'form-attach__tab' +
                    (activeTab == 'attachClient' ? ' active' : '')
                  }
                >
                  <div className="form-attach__body">
                    <div className="bold">
                      Хотите прикрепить к своему клиенту?
                    </div>
                    <div className="select select_type_form">
                      <label className="custom-select-label">Клиент</label>
                      <Dropdown
                        options={clients}
                        value={attachingClientId}
                        onChange={setAttachingClientId}
                      />
                    </div>
                    <div
                      className="add-client"
                      onClick={() => setActiveTab('newClient')}
                    >
                      <button className="button button_type_add"></button>
                      <span>Добавить клиента</span>
                    </div>
                    <div className="form-attach__submit">
                      <SubmitButton
                        className="button button_view_grey"
                        isLoading={attaching}
                        onClick={() => submitAttach(attachingClientId, 'add')}
                        disabled={!attachingClientId}
                      >
                        <span>Прикрепить</span>
                      </SubmitButton>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'form-attach__tab' +
                    (activeTab == 'newClient' ? ' active' : '')
                  }
                >
                  <div className="form-attach__return">
                    <button
                      className="button button_view_outline-with-icon"
                      onClick={() => setActiveTab('attachClient')}
                    >
                      <svg className="icon icon_arrow-return">
                        <use xlinkHref="#arrow_return"></use>
                      </svg>
                      Вернуться
                    </button>
                  </div>
                  <div className="form-attach__new-client">
                    <NewClientForm onAdd={handleAddClient} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PresentationAction = (props) => {
  const {
    deleteClient,
    clientId,
    id,
    fetch,
    clients,
    city,
    handleAttachClient,
    fromCrm
  } = props;
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const showConfirm = () => {
    confirmModal();
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const [sendPresentationModal, hideSendPresentationModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSendPresentationModal} classes="modal_task modal_pres">
        <SendPresentation
          presentationIds={[id]}
          fetch={fetch}
          city={city}
          clientId={clientId?.[0]}
          clients={clients}
          attachClient={handleAttachClient}
          hide={hideSendPresentationModal}
        />
      </Modal>
    ),
    [clientId, city, handleAttachClient],
  );

  const [sendPresentationMobileModal, hideSendPresentationMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideSendPresentationMobileModal} title="Отправить клиенту">
        <SendPresentation
          presentationIds={[id]}
          fetch={fetch}
          city={city}
          clientId={clientId?.[0]}
          clients={clients}
          attachClient={handleAttachClient}
          hide={hideSendPresentationMobileModal}
        />
      </MobileModal>
    ),
    [clientId, city, handleAttachClient],
  );

  const confirm = () => {
    deleteClient(props.id).then(
      (data) => {
        hideModal();
        infoToast(<div>{data.message}</div>)
      },
      (error) => {
        hideModal();
        errorToast(<div>{error?.[0]?.message}</div>)
      },
    );
  };

  const [confirmModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Confirm
      close={hideModal}
      title={`Вы действительно хотите удалить презентацию ID ${props.id}?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [confirmMobileModal, hideMobileModal] = useModal(({ in: open, onExited }) => (
    <MobileConfirm
      closeClick={hideMobileModal}
      mobileTitle="Удаление"
      title={`Вы действительно хотите удалить презентацию ID ${props.id}?`}
      confirm={confirm}
      cancel={hideModal}
    />
  ));

  const [closePopup, setClosePopup] = useState(false);

  const handlePopupClick = () => {
    sendPresentationMobileModal()
    setClosePopup(true)
  }

  const handleConfirmClick = () => {
    confirmMobileModal()
    setClosePopup(true)
  }

  const content = (
    <div className="dt__more">
      <hr />
      <div>
        <Link
          className="dt__more-control"
          to={!fromCrm ? `/personal/presentations/edit/${props.id}` : `/personal/presentations/edit/${props.id}?fromCrm=true`}
        >
          <svg className="icon icon_edit">
            <use xlinkHref="#edit"></use>
          </svg>
          <span>Редактировать</span>
        </Link>
      </div>
      <div
        className="dt__more-control"
        onClick={media("isMobile") ? handlePopupClick : sendPresentationModal}
      >
        <svg className="icon icon_social-mail">
          <use xlinkHref="#mail"></use>
        </svg>
        <span>Отправить клиенту</span>
      </div>
      <div className="dt__more-control" onClick={media('isMobile') ? handleConfirmClick : showConfirm}>
        <svg className="icon icon_delete">
          <use xlinkHref="#delete"></use>
        </svg>
        <span>Удалить</span>
      </div>
    </div>
  )

  const button = (
    <button
      type="link"
      className="dropdown__btn button form__drop-btn button_type_round"
      onClick={(e) => setOpen(!open)}
    >
      <span className="form__drop-name"></span>
      <svg className="icon icon_elipsis">
        <use xlinkHref="#elipsis"></use>
      </svg>
    </button>
  )

  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={
            'dropdown js-dropdown form__drop' + (open ? ' is-showing' : '')
          }
        >
          {media("isMobile") ?
            <Popup
              trigger={button}
              closePopup={closePopup}
            >
              {content}
            </Popup> :

            <>
              {button}
              <div className="dropdown__block">
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    {content}
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  const {
    items,
    handleDelete,
    clients,
    fetch,
    handleAttachClient,
    fromCrm
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);

  const onSelectItem = (checked, id) => {
    if (id == 'all') {
      setSelectedItems(checked ? [...items.map((item) => item.id)] : []);
      return;
    }

    const newSelectedItems = checked
      ? [...selectedItems, id]
      : [...selectedItems.filter((item) => item != id)];

    setSelectedItems(newSelectedItems);
  };

  const handleDeleteModal = () => {
    if (!selectedItems.length) return errorToast(<div>Не выбраны презентации</div>)
    confirmModal();
  };

  const confirm = () => {
    handleDelete(selectedItems).then(
      (data) => {
        hideModal();
        infoToast( <div>{data.message}</div>)
      },
      (error) => {
        hideModal();
        errorToast(<div>{error?.[0]?.message}</div>)
      },
    );
  };

  const [confirmModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Confirm
        close={hideModal}
        title={`Вы действительно хотите удалить выбранные презентации?`}
        confirm={confirm}
        cancel={hideModal}
      />
    ),
    [confirm],
  );

  const Price = (item) => {
    if (!item.object) return '';

    let cost = item.object.costWithDiscount
      ? item.object.costWithDiscountFormated
      : item.object.baseCost
      ? item.object.baseCostFormated
      : '—';

    return <span className="nowrap">{cost}</span>;
  }

  const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Клиент:</span>
            <PresentationClients
              clients={clients}
              data={record}
              fetch={fetch}
              handleAttachClient={handleAttachClient}
            />
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Цена:</span>
            <Price object={record.object} />
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Дата созд.:</span>
            {formattedNumericDate(record.dateCreate)}
          </div>
        </div>
      </div>
    )
  }

  const getMobileColumns = () => [
    {
      title: "Об объекте",
      dataIndex: "object",
      key: "object",
      render: (text, record) => {
        return <PresentationName {...record} />;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <PresentationAction
          {...record}
          deleteClient={handleDelete}
          clients={clients}
          fetch={fetch}
          handleAttachClient={handleAttachClient}
        />
      ),
    },
  ]

  const getColumns = () => [
    {
      title: () => (
        <Checkbox
          id={'all'}
          view="invert"
          onChange={(event) => onSelectItem(event.target.checked, 'all')}
        />
      ),
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
        <Checkbox
          id={id}
          view="invert"
          checked={selectedItems.includes(id)}
          onChange={(event) => onSelectItem(event.target.checked, id)}
        />
      ),
    },
    {
      title: 'Информация об объекте',
      dataIndex: 'name',
      key: 'title',
      render: (text, record) => {
        return <PresentationName fromCrm={fromCrm} {...record} />;
      },
    },
    {
      title: 'Стоимость',
      dataIndex: 'object',
      key: 'object',
      sorter: (a, b) => {
        if (!a.object) return 0;
        if (!b.object) return 1;

        return a.object.baseCost - b.object.baseCost;
      },
      render: (object) => {
        if (!object) return '';

        let cost = object.costWithDiscount
          ? object.costWithDiscountFormated
          : object.baseCost
          ? object.baseCostFormated
          : '—';

        return <span className="nowrap">{cost}</span>;
      },
    },
    {
      title: 'Клиенты',
      dataIndex: 'object',
      key: 'object',
      render: (object, record) => {
        return (
          <PresentationClients
            clients={clients}
            data={record}
            fetch={fetch}
            handleAttachClient={handleAttachClient}
          />
        );

        // clients.map((item) => (
        // 	<a className="clients__phone" key={item} href={`mailto:${item}`}>
        // 		{item}
        // 	</a>
        // ));
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) =>
        new Date(a.dateCreate) > new Date(b.dateCreate) ? 1 : -1,
      render: (text, record) => {
        return (
          <span className="nowrap">{formattedDate(record.dateCreate)}</span>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (text, record) => (
        <PresentationAction
          {...record}
          deleteClient={handleDelete}
          clients={clients}
          fetch={fetch}
          handleAttachClient={handleAttachClient}
          fromCrm={fromCrm}
        />
      ),
    },
  ];

  const download = () => {
    if (!selectedItems.length) return errorToast(<div>Не выбраны презентации</div>)
    getPdf({ id: selectedItems }).then(data => downloadLink(data.filePath), standartErrorToast);
  };


  const handleSendModal = () => {
    if (selectedItems.length > 10) return errorToast("Вы не можете отправить более 10 презентаций за один раз")
    if (!selectedItems.length) return errorToast(<div>Не выбраны презентации</div>)
    sendPresentationModal();
  };

  const getSelectedItemsCities = () => {
    let res = [];

    selectedItems.map((id) => {
      const currItem = items.find((item) => item.id == id);
      if (currItem) res.push(currItem.city);
    });

    return res;
  };

  const [sendPresentationModal, hideSendPresentationModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSendPresentationModal} classes="modal_task modal_pres">
        <SendPresentation
          presentationIds={selectedItems}
          city={getSelectedItemsCities()}
          fetch={fetch}
          clients={clients}
          hide={hideSendPresentationModal}
          attachClient={handleAttachClient}
        />
      </Modal>
    ),
    [selectedItems, clients],
  );


  return (
    <div className="ot-table ot-table_bordered-header">
      <div className="pan-table">
        {!media("isMobile") &&
          <div className={`table__controls ${fromCrm && ' table__controls_fromCrm'}`}>
            <div className="button button_view_control" onClick={handleSendModal}>
              <svg className="icon icon_mail">
                <use xlinkHref="#mail"></use>
              </svg>
              <span>Отправить клиенту</span>
            </div>
            {/* <div className="button button_view_control">
              <svg className="icon icon_attach">
                <use xlinkHref="#attach-filled"></use>
              </svg>
              <span>Отправить ссылкой</span>
            </div> */}
            <div className="button button_view_control" onClick={download}>
              <svg className="icon icon_download">
                <use xlinkHref="#download"></use>
              </svg>
              <span>Скачать</span>
            </div>
            <div className="button button_view_control" onClick={handleDeleteModal}>
              <svg className="icon icon_delete">
                <use xlinkHref="#delete"></use>
              </svg>
              <span>Удалить</span>
            </div>
          </div>
        }
        <div className="flats__row-table is-active">
          <Table
            rowKey="id"
            showSorterTooltip={false}
            columns={media("isMobile") ? getMobileColumns() : getColumns()}
            dataSource={items}
            pagination={false}
            locale={{ emptyText: 'Ничего не найдено' }}
            customMoreTitle={media("isMobile") && "Развернуть все"}
            customPagination={true}
            expandable={media("isMobile") ? {
              expandedRowRender: record => expandedRow(record),
            } : false}
            initialCount={media("isMobile") && 5}
            isLoadAll={true}
          />

          {/* {media("isMobile") &&
            <button className="presentation-filters-btn" onClick={props.onClick}>
              <Filters />
              Фильтры: 3
            </button>
          } */}
        </div>
      </div>
    </div>
  );
};

export default Items;
