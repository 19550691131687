import React, { useState, useEffect } from 'react'
import Checkbox from "../../components/core/Checkbox";
import Social from "../../components/core/Social";
import { chunk } from 'lodash'
const iconNames = {
    "Telegram": "tg",
    "E-mail": "mail",
    "What’s App": "wa",
    "VK": "vk",
    "Facebook": "fb",
    "Instagram": "ig"
}
const SocialIcons = ({ type }) => {
    return (
        <Social
            addClass="poll__social"
            type={type}
            iconOnly
        />
    )
}
const Item = props => {
    const { item, handleChange, checked, fakeIndex } = props
    return (<div className="poll__elem">
        <div className="poll__box">
            <Checkbox
                id={`poll-checkbox-${item?.fieldName}-${fakeIndex}`}
                labelText={item.name}
                value={item.value}
                onChange={(e) => handleChange(e.target.checked, item?.fieldName, e.target.value)}
                checked={checked}
            />
            {iconNames[item.name] && <SocialIcons
                type={iconNames[item.name]}
            />}
        </div>

    </div>)
}
const Checkboxes = (props) => {
    const { name, items, handleChange, form } = props
    const [checkBoxItems, setCheckBoxItems] = useState([])
    const [inputItem, setInputItem] = useState(null)
    useEffect(() => {
        if (items) {
            let tmpItems = items.filter(item => item.type !== "textarea")
            let divider = (tmpItems.length % 2) > 0 ? 4 : tmpItems.length / 2
            setCheckBoxItems(chunk(tmpItems, divider))
            setInputItem(items.find(item => item.type === "textarea"))
        }

    }, [items])
    return (
        <div className="poll__item">
            <div className="poll__q h4">{name}</div>
            <div className="poll__a">
                <div className="poll__row">
                    {checkBoxItems.map((half, i) => (
                        <div className="poll__col poll__half" key={`half-${name}"-"${i}`}>
                            {half.map((item, idx) => (<Item
                                item={item} key={`${name}"-"${(idx + 4) * (i + 1)}`}
                                fakeIndex={(idx + 4) * (i + 1)}
                                handleChange={handleChange}
                                checked={!!form?.[item?.fieldName]?.includes(item.value)}
                            />))}
                        </div>
                    ))}
                </div>
                {inputItem && <div className="poll__row">
                    <input value={form[inputItem.fieldName]} onChange={(e) => { handleChange(e.target.value, inputItem.fieldName) }} type="text" className="text-input text-input_full" placeholder="Ваш вариант ответа" />
                </div>}
            </div>
        </div>
    )
}
export default Checkboxes