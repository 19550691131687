import React, { memo } from 'react';
import { getUrl } from '../../../api';
import houseEmpty from '../../../assets/images/bcg_houses-01-01.svg';
import SwiperSlider from "../../core/SwiperSlider";

const SelfObjectCard = memo(({ address, title, subTitle, price, pricePerMeter, description, photos, isHighlight, onClick, suburban, openCallbackModal }) => <div className={`section__grid-col cards__item${isHighlight ? ` cards__item_highlight` : ``}`} onClick={onClick}>
  <div className="obj-card text">
    <div className="obj-card__slider">
      <div className="swiper-pan">
        <SwiperSlider
          items={photos}
          wrap={item => <div className="obj-card__slide"><img
            src={getUrl(item.src)}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = houseEmpty
            }}
            alt={title}
          /></div>}
          navigation={photos?.length > 1}
        />
      </div>
    </div>
    <div className="obj-card__content">
      {suburban ?
        <div className="obj-card__suburban">
          <div className="obj-card__suburban-block">
            {suburban.importantParams.map((i) => {
                return (
                  <div className="obj-card__suburban-info">
                    <div><b>{i.title}</b></div>
                    <div><b>{i.value}</b></div>
                  </div>
                )
              })}
          </div>
          <div className="obj-card__suburban-block">
            {suburban.params.map((i) => {
                return (
                  <div className="obj-card__suburban-info">
                    <div style={{fontSize: 14}}>{i.title}</div>
                    <div style={{fontSize: 14}}>{i.value}</div>
                  </div>
                )
              })}
          </div>
          <button className="button constructor__btn services__btn" onClick={() => openCallbackModal()} style={{marginTop: 0}} type="button" >
            Подобрать
            {suburban.type === 'parcel' && ' участок'}
            {suburban.type === 'house' && ' дом'}
            {suburban.type === 'project' && ' проект'}
          </button>
        </div> :
        <>
          <div className="obj-card__body">
            <p className="h4 obj-card__title">
              <span>{title}</span>
            </p>
            <p className="h55 obj-card__subtitle">{subTitle}</p>
            <p className="obj-card__body-descr color-brand-3">{address}</p>
          </div>
          <div className="obj-card__footer">
            <p className="obj-card__price h3">{price}</p>
            <p className="obj-card__price-per-m color-brand-3">{pricePerMeter}</p>
            <p className="obj-card__descr">
              {description}
            </p>
          </div>
        </>
      }
    </div>
  </div>
</div>)

export default SelfObjectCard;
