const settingsData = config => {
  return [
    {
      id: 1001,
      name: "Личные данные",
      shortName: "Личные данные",

      switchers: [
        {
          label: "Логотип",
          name: "agency_logo",
        },
        // {
        //   label: "Показать контактные данные менеджера",
        //   name: "manager_contacts",
        // },

        // Добавить??
        {
          label: "Фамилия и имя",
          name: "name",
        },
        {
          label: "Телефон",
          name: "phone",
        },
        {
          label: "WhatsApp",
          name: "whatsapp",
        },
        {
          label: "Telegram",
          name: "telegram",
        },
        {
          label: "E-mail",
          name: "email",
        },

        {
          label: "Сертификат",
          name: "agent_certificate",
        },
      ],
    },
    {
      id: 1002,
      name: "Объект",
      shortName: "Объект",
      switchers: [
        {
          label: "Планировка",
          name: "layout_plan",
        },
        {
          label: "Информация о квартире",
          name: "flat_area",
        },
        {
          label: "Генплан",
          name: "on_genplan",
        },
        {
          label: "Фотографии объекта",
          name: "object_photos",
        },
        {
          label: "Показать цену",
          name: "price",
        },
        {
          label: config?.noteName || "Примечания",
          name: "note",
        },
        {
          label: "Квартира на этаже",
          name: "layout_description",
        },
        {
          label: "Вид из окон",
          name: "view_for_windows",
        },
        ...(config.hideShowRoom ? [] : [{
          label: "Шоу-рум",
          name: "typical_3d_tour",
        }]),
        {
          label: "Динамика цены",
          name: "dynamic_price",
          depends: "price"
        },
        {
          label: "Расчет ипотеки",
          name: "mortgage",
          depends: "price"
        },
      ],
      button: {
        label: "Добавить комментарий",
        modal: "modal-manager-comment",
        icon: "#comment",
        length: "300",
        identity: "comment",
        key: "detailText",
      },
    },
    {
      id: 1004,
      name: "Дополнительные фотографии",
      shortName: "Доп. фото",
      // switchers: [
      // 	{
      // 		label: 'Дополнительное фото',
      // 		name: 'main_photo',
      // 	},
      // ],
      images: 4,
    },
    {
      id: 1003,
      name: "Отделка",
      shortName: "Отделка",
      switchers: [
        {
          label: "Фотографии отделки",
          name: "layout_picture_ready",
        },

      ],
      // images: [
      //   {
      //     id: 100,
      //     src: null
      //   },
      //   {
      //     id: 101,
      //     src: require('../../assets/images/complex-image.jpg')
      //   },
      //   {
      //     id: 102,
      //     src: require('../../assets/images/complex-image.jpg')
      //   }
      // ]
    },

    {
      id: 1005,
      name: "Комплекс",
      shortName: "ЖК",
      // button: {
      // 	label: 'Редактировать описание',
      // 	modal: 'modal-manager-comment',
      // 	icon: '#edit',
      // 	length: 1000,
      // 	identity: 'description',
      // },
      button: {
        label: "Изменить описание",
        modal: "modal-manager-comment",
        icon: "#edit",
        length: 400,
        identity: "description",
        key: "previewText",
      },
      switchers: [
        {
          label: "Фотографии ЖК",
          name: "main_photo",
        },
        {
          label: "Название",
          name: "complex_name",
        },
        {
          label: "Застройщик",
          name: "builder",
        },
        {
          label: "Информация о ЖК",
          name: "house_characteristics",
        },
        {
          label: "Описание ЖК",
          name: "complex_about",
        },
        {
          label: "Особенности",
          name: "complex_advantages",
        },
        {
          label: "Инфраструктура",
          name: "building_on_map",
        },
      ],
      // images: [
      // 	{
      // 		id: 100,
      // 		src: require('../../assets/images/complex-image.jpg'),
      // 	},
      // 	{
      // 		id: 101,
      // 		src: require('../../assets/images/complex-image.jpg'),
      // 	},
      // 	{
      // 		id: 102,
      // 		src: require('../../assets/images/complex-image.jpg'),
      // 	},
      // ],
    },
    // {
    //   id: 1006,
    //   name: "Дополнительно",
    //   shortName: "Дополнит.",
    //   switchers: [
    //     {
    //       label: "Инфраструктура",
    //       name: "building_on_map",
    //     },

    //   ],
    // },
  ]
};
export default settingsData