import React, { useState } from "react";
import "./index.scss";
import NewsShare from './Share.js';

const IphoneCds = (props) => {

  return (

    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`iPhone 13 за сделки по ЦДС`} />
        <div className="news__container container news__container_smena">
          <h1 className="h1 news__title h2"><span role="img" aria-label="gift">🎁</span> iPhone 13 за сделки по ЦДС</h1>
          <img src={require('../../assets/images/news/iphone.jpg').default} alt="" className="news__promo-img" />

          <p className="news__text h3"><span role="img" aria-label="fire">🔥</span> Разыгрываем iPhone 13 вместе с ЦДС</p>
          <p className="news__text"><span role="img" aria-label="check">👉</span> Проведи до конца октября от 2 сделок по ЦДС  — и участвуй в розыгрыше iPhone 13.</p>
          <p className="news__text"> В розыгрыше участвует <b>каждая</b> сделка. Больше сделок - больше шансов <span role="img" aria-label="medal">🥇</span></p>
          <p className="news__text"><span role="img" aria-label="money">💰</span> А еще <b>Турбо-Авансирование</b> по акции: деньги за сделку в течение 3 часов.</p>

          <p className="news__text big-text news__big-margin"><span role="img" aria-label="phone">📞</span>  Есть вопросы? Звоните своему управляющему отдела развития или на общий номер: <a href="tel:+78124261863" className="link link-hover">426-18-63</a></p>

        </div>
      </div>

    </section>
  )
};

export default IphoneCds;
