import {useParams} from "react-router-dom";
import useApi from "../../../hooks/useApi";
import React, {useEffect, useMemo, useRef, useState} from "react";
import request, {getUrl} from "../../../api";
import {useMapModal} from "../../../hooks/useMapModal";
import useDocTitle from "../../../hooks/useDocTitle";
import {connect, useSelector} from "react-redux";
import useUniversalForm from "../../../hooks/useUniversalForm";
import {useModal} from "react-modal-hook";
import PanModal from "../../core/PanModal";
import ContactsModal from "../../Public/markup/detail/ContactsModal";
import ErrorReport from "../../Apartment/NewAppartment/errorReport";
import useMedia from "../../../hooks/useMedia";
import {objectsArrayToFormData, scrollToRef, toFormData} from "../../../utils";
import SubmitButton from "../../SubmitButton";
import {
  AddToCompilationIcon,
  AptStatusIcon,
  HeartIcon,
  LockIcon,
  PdfIcon,
  PresentationIcon
} from "../../Public/markup/icons";
import Preloader from "../../Preloader";
import AddPresenatation from "../../AddPresentation";
import Slider from "../../Public/markup/detail/Slider";
import PromoBar from "../../Public/markup/detail/PromoBar";
import CommercialCalc from "../../Commercial/Redesign/CommercialCalc";
import Furnish from "../../Public/markup/detail/Furnish";
import Iframe from "../../Public/markup/detail/Iframe";
import PromoList from "../../Public/markup/detail/PromoList";
import InfoCard from "../../Public/markup/detail/InfoCard";
import Button from "../../core/Button";
import {
  addClientToFavorite,
  addToFavorites,
  removeClientFromFavorite,
  removeFromFavorites
} from "../../../actions/favorites";
import {clearAssignment, fetchAssignment} from "../../../actions/assignment";
import {fetchClients} from "../../../actions/clients";
import NewAlert from "../../core/NewAlert";
import About from "../../Public/markup/detail/About";
import {successToast} from "../../toasts";
import useModalWithData from "../../../hooks/useModalWithData";
import CompilationModal from "../../../pages/NewFavorites/compilationModal";

const AssignationFlat = () => {
  const { id, savedFilterXmlId } = useParams();
  const city = "spb";

  const {data: {
    status,
    assignment,
    priceInfo,
    objectShortInfo,
    objectInfo,
    bookingLink,
    schedule,
    contacts,
    countViewsInfo,
    meta,
  },
    isLoading,
    toggleFavorite,
    inFavorite,
    isFavoriteLoading,
  } = useApi({
    payload: ["panpartner:assignments.ajax", "getAssignment", {id, city}],
    favoritesParams: {
      city,
      id,
      type: 'assignment',
    },
    favoritePath: 'assignment.favorite',
  }, [id])

  const [openMapModal, closeMapModal] = useMapModal({
    cords: assignment?.coordinates,
    mapCenter: assignment?.coordinates,
  })
  useDocTitle(meta?.title)
  const [openContactsModal, closeContactsModal] = useModal(
    () => (
      <PanModal title={'Контакты'} closeModal={closeContactsModal} isSmall>
        <ContactsModal
          contacts={contacts}
          schedule={schedule}
          blockId={assignment.blockId}
          params={{
            type: "assignment",
            objectId: id,
            city: city
          }}
        />
      </PanModal>
    ),
    [contacts],
  );

  // const handleFavorites = (inFavorite) => {
  //   inFavorite
  //     ? removeFromFavorites("spb", id, "assignment", "assignment")
  //     : addToFavorites("spb", id, "assignment", "assignment");
  // };
  const renderPresentations = ({ fetching, finish, link, handleSend }) => {
    return <SubmitButton
      type="button"
      className="sf__elem-btn button link"
      isLoading={fetching}
      onClick={finish ? () => window.open(link) : handleSend}
    >
      <PresentationIcon />
      {finish || assignment?.presentation?.id ? "К презентации" : "В презентации"}
    </SubmitButton>
  }

  const [fetchingPdf, setFetchingPdf] = useState(false);
  const getPdf = (xmlID, city, firstPay, percent, period) => {
    setFetchingPdf(true)
    return request('panpartner:presentations.ajax', 'getPdf', {
      objectXmlId: xmlID,
      city: city,
      type: 'assignment',
    })
      .then((data) => {
        setFetchingPdf(false)
        window.open(getUrl(data.filePath))

      })
  }

  const myBlockRef = useRef(null);
  const [scroll, setScroll] = useState(0);
  const [show, setShow] = useState(false);
  const [widthStick, setWidthStick] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);


  useEffect(() => {
    setPageHeight(document.body.scrollHeight);
    if (myBlockRef.current) {
      const height = myBlockRef.current.offsetHeight;
      const width = myBlockRef.current.offsetWidth;
      setWidthStick(width)
      const handleScroll = () => {
        const position = window.scrollY;
        setScroll(position);
        if (scroll > (height + 350)) {
          setShow(true)
        } else {
          setShow(false)
        }

        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (scrollTop + windowHeight >= documentHeight - 100 && windowHeight < 600) {
          // setShow(false)
        }


      }
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isLoading, scroll]);



  const decorationSliders = useMemo(() => {
    return [
      { title: 'Без отделки', items: assignment?.finishing },
      { title: 'Предчистовая', items: assignment?.finishingPreReady },
      { title: 'Чистовая', items: assignment?.finishingReady},
      { title: 'С мебелью', items: assignment?.interiorPhotos},
    ].filter((item) => !!item?.items?.length);
  }, [assignment?.finishing, assignment?.finishingPreReady, assignment?.finishingReady, assignment?.interiorPhotos, isLoading]);


  const {
    openModal: openCompilationModal,
  } = useModalWithData({
    component: ({ closeModal, favorite }) => <CompilationModal
      close={closeModal}
      adPresentatinonParams={[{ objectId: id, type: assignment.compilationObjectType, city }]}
    />,
    modalProps: {
      withoutBG: true,
      closeByPastClick: true,
      classes: "crm-table__sf-controlsmodal crm-table__sf-controlsmodal_compilation",
    },
    dep: []
  })

  if (isLoading) return <Preloader loading />;

  return (
    <div className="sf wrapper">
      <div className="apt-modal__title" style={{position: 'relative'}}>
        <h3 className="h3">{assignment.roomType + ', ' + assignment.squareTotal + ' м²'}</h3>
        <div className={`label-item apt-modal__status label_${assignment.status?.color}`}>
          <AptStatusIcon />
          {assignment.status?.name}
        </div>
        {countViewsInfo && <span style={{position: 'absolute', display: 'flex', right: 0, paddingTop: 5, color: '#777A89'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5, marginTop: -3}}><path d="M12 7C6.28571 7 4 12 4 12C4 12 6.28571 17 12 17C17.7143 17 20 12 20 12C20 12 17.7143 7 12 7Z" stroke="#777A89" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" stroke="#777A89" strokeLinecap="round" strokeLinejoin="round"/></svg>{countViewsInfo}</span>}
      </div>

      <div className="sf__detail-bar" style={{marginTop: 7, marginBottom: 0}}>
        <a href={getUrl(`/realty/spb/complex/${assignment?.block.id}`)}target="_blank" className="link link_color-red">{assignment?.block.name}</a>
        <p className="text sf__detail-descr sf__detail-descr_center" style={{color: '#2C2E3E'}}>{assignment?.address}</p>
        <span className="link link_color-red" onClick={openMapModal}>
            На карте
          </span>
      </div>

      <div className="apt-modal__header-second" style={{position: 'relative'}}>
        <div className="sf sf--apt-modal">
          <div className="sf__elems">
            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={() => !isFavoriteLoading && toggleFavorite()}
              // onClick={() => handleFavorites(assignment?.favorite.inFavorite)}
              style={{width: 192}}
            >
              {/*<HeartIcon isActive={assignment?.favorite.inFavorite} />*/}
              {/*{assignment?.favorite.inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}*/}
              <HeartIcon isActive={inFavorite} />
              {inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
            </button>

            <button
              type="button"
              className="sf__elem-btn button link"
              onClick={openCompilationModal}
            >
              <AddToCompilationIcon/>
              В подборку
            </button>

            <AddPresenatation
              type={'assignment'}
              id={assignment?.xmlId}
              city={city}
              presentation={assignment?.presentation}
              render={renderPresentations}
            />

            <SubmitButton
              className="sf__elem-btn button link"
              isLoading={fetchingPdf}
              onClick={() => {getPdf(assignment?.xmlId, city)}}
            >
              <PdfIcon />
              Скачать PDF
            </SubmitButton>



          </div>
        </div>
      </div>

      <div className="sf">
        <div className="sf__detail-row sf__detail-row--common-page">
          <div className="sf__detail-col">
            <Slider
              items={assignment.photos}
              withBorder
              showButton={!!assignment.viewFromWindow}
              viewFromWindow={assignment.viewFromWindow}
              windowView
            />

            {
              assignment?.note &&
                <div style={{marginTop: 30}} >
                  <NewAlert
                    title="Примечание"
                    bgColor="#F6F7F8"
                    iconColor="#2CBA2E"
                    dangerouslyHTML={assignment?.note}
                  />
                </div>

            }
            <Furnish items={decorationSliders} complexTitle />
            {assignment?.builder?.typical3DTour && assignment?.builder?.typical3DTour !== '' && (
              <div style={{marginBottom: 20}}><Iframe title="Шоу-рум" src={assignment?.builder?.typical3DTour} /></div>
            )}

            {assignment?.block?.aeroPanorama && <section className="sf__section">
              <Iframe title="Аэропанорама" src={`${assignment?.block?.aeroPanorama}`} />
            </section>}

            {assignment?.block?.about?.previewText !== '' && <About
              title={`О комплексе «${assignment?.block ? assignment?.block.name : ''}»`}
              text={assignment?.block?.about?.previewText}
              advantages={assignment?.block?.about?.advantages}
              audioReview={assignment?.audioReview}
            />}


          </div>


          <div className="sf__detail-col" style={show ? {position: 'sticky', top: 70} : {}}>
            <div ref={myBlockRef}  id="one" style={show ? {display: 'none'} : {}}>
              <InfoCard items={objectInfo} cols={priceInfo}/>
              <section className="sf__section" style={{marginTop: -10}}>
                {!!bookingLink &&<Button buttonType="primary" isFull onClick={()=>window.open(bookingLink)}>
                  Забронировать
                </Button>}
                <Button buttonType="line" isFull onClick={openContactsModal}>
                  Контакты
                </Button>
              </section>
            </div>

            <div  className="stick"  style={show ? {display: 'block'} : {display: 'none'}}>
              <section className="sf__section">
                <InfoCard items={objectShortInfo} cols={priceInfo}/>
                {!!bookingLink &&<Button buttonType="primary" isFull onClick={()=>window.open(bookingLink)}>
                  Забронировать
                </Button>}
                <Button buttonType="line" isFull onClick={openContactsModal}>
                  Контакты
                </Button>
              </section>
            </div>

          </div>


        </div>
      </div>



    </div>
  )
}
export default AssignationFlat

