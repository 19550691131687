import React from "react";

const BgLeft1 = (props) => {
  return (
    <div className="background__left">
      <svg viewBox="0 0 161 642" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-267.865 641.027L-267.864 107.855L160.305 -448.37L160.306 84.8005L-267.865 641.027Z" className="background__fill-main"/>
      </svg>
    </div>
  )
};

export default BgLeft1;
