import _keys from 'lodash/keys';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { sendError } from '../../actions/forms';
//пробуем отрендерить несколько (3) раз, если и в этом случае ничего не получается, возвращаем пустую строку
const Recovery = (props) => {
  const { errorCount, resetError } = props;
  useEffect(() => {
    if (errorCount < 4) {
      resetError();
    }
  }, []);
  return '';
};
class ErrorPlug extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      comment: '',
      errorInfo: '',
      error: '',
      errorCount: 0,
    };
  }
  resetError = () => {
    this.setState({ hasError: false });
    this.setState((state) => ({ errorCount: state.errorCount + 1 }));
  };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    //тут можно отправлять ошибки на сервер
    if (this.state.errorCount === 3) {
      this.setState({ error: error, errorInfo: errorInfo });
      let data = {
        'fields[errorId]': this.state.errorId,
        'fields[pageUrl]': window.location.href,
        'fields[error]': error,
        'fields[errorInfo]': JSON.stringify(errorInfo),
        'fields[comment]':
          'ОШИБКА ОТПРАВЛНА ДО КОММЕНТИРОВАНИЯ, более трёх рендеров',
        'fields[userInfo]': JSON.stringify(this.props.user.info),
        "fields[userHistory]": JSON.stringify(this.props.user.history),
        'fields[userAgent]': window.navigator.userAgent,
        'fields[screenWxH]': window.screen.width + 'x' + window.screen.height,
        'fields[windowWxH]': window.innerWidth + 'x' + window.innerHeight,
      };
      let form = new FormData();
      let keys = _keys(data);
      keys.forEach((key) => {
        form.append(`${key}`, data[key]);
      });
      if(this.props.wrappedComponent){
        form.append('fields[ErrorInComponent]', this.props.wrappedComponent);
      }
      if (window.location.host !== 'localhost:3000' && !this.props.stopSending) {
        sendError(form);
      }
    }
    console.error(error, errorInfo);
  }
  
  render() {
    if (this.state.hasError && !this.props.stopRerender) {
      return (
        <Recovery
          resetError={this.resetError}
          errorCount={this.state.errorCount}
        />
      );
    }
    return (
      <React.Fragment key={this.state.errorCount}>
        {this.props.children}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorPlug),
);
