import request from '../api';
import { actionFactory } from "./actionUtils";

const component = "panpartner:meeting.rooms.ajax"

const requestMeetingRooms = actionFactory("MEETING_ROOMS_REQUEST");

const requestMeetingRoomsTypes = actionFactory("MEETING_ROOMS_TYPES_REQUEST");

const successMeetingRooms = actionFactory("MEETING_ROOMS_SUCCESS");

const successMeetingRoomTypes = actionFactory("MEETING_ROOMS_TYPES_SUCCESS");

const successDates = actionFactory("MEETING_DATES_SUCCESS");

const successClients = actionFactory("MEETING_ROOMS_CLIENTS_SUCCESS");

export const fetchMeetingRooms = (city = 'spb') =>
  dispatch => {
    dispatch(requestMeetingRooms());
    return request(component, "getEventMeetings", { city })
      .then(
        data => {
          dispatch(
            successMeetingRooms({
              data: handleData(data),
            }),
          );
          return Promise.resolve(data);
        },
      );
  };

export const saveEventMeeting = form => request(component, "saveEventMeeting", form);

export const getClients = (name, city) =>
  dispatch => {
    dispatch(requestMeetingRooms());
    return request("panpartner:saved.filter.ajax", "getClients", { name, city })
      .then(
        data => {
          dispatch(successClients({ clients: handleClients(data) }));
          return Promise.resolve(data);
        }
      );
  };

const handleClients = data => data.clients.map(client => ({
  id: client.id,
  title: `${client.name} ${client.lastName}`,
}));

const handleData = (data) => {
  let rooms = data.meetingRooms.map((room) => ({
    id: room.id,
    title: room.name,
  }));

  return { ...data, meetingRooms: rooms };
};

const handleTypesData = (data) => {
  let types = data.types.map((item) => ({
    id: item.id,
    title: item.name,
    xmlId: item.xmlId,
    photos: item.photos
  }));

  return { meetingRoomsTypes: types };
};

export const fetchMeetingRoomsTypes = (city = 'spb') =>
  dispatch => {
    dispatch(requestMeetingRoomsTypes());
    return request(component, "getEventMeetingTypes", { city })
      .then(
        data => {
          dispatch(
            successMeetingRoomTypes({
              data: handleTypesData(data),
            }),
          );
          return Promise.resolve(data);
        },
      );
  };

export const getDates = (type) =>
  dispatch => request(component, "getDates", { type })
    .then(
      data => {
        dispatch(
          successDates({
            dates: data.dates
          }),
        );
        return Promise.resolve(data);
      },
    );

export const getMeetingRoomsByTypeDate = (city = "spb", type, dateFrom, dateTo) =>
  request(component, "getMeetingRoomsByTypeDate", {
    city,
    type,
    dateFrom,
    dateTo
  })