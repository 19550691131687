

const initState = {
  blocks: [],
  rooms: [],
  districts: [],
  builders: [],
  endings: {
    from: null,
    to: null
  },
  show_booked: false
};

const filter = (state = initState, action) => {
  const {type, filter} = action;
  switch (type) {
    case "SET_FILTER":
      return { ...state, ...filter };
    // case "SET_REF":
    //   return { ...state, refs: action.refs }
    default:
      return state;
  }
};

export default filter;
