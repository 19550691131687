import React, { useEffect } from 'react';
import { getUrl } from '../../api';
import renderPDF from './renderPDF';

const Footer = ({ data, agent, activeSettings }) => {
  useEffect(() => {
    setTimeout(() => {
      renderPDF();
    }, 500);
  }, []);

  const agentName = () => {
    return agent.name + ' ' + agent.lastName;
  };

  const showFooter = () => {
    return activeSettings['agency_logo'] || activeSettings['manager_contacts'];
  };

  return (
    <div
      className={
        'presentation__footer pdf-footer' + (showFooter() ? '' : ' hidden')
      }
    >
      {
        //   <div
        //   className={
        //     'presentation__footer-logo' + (!agent.workLogo ? ' hidden' : '')
        //   }
        //   data-id="agency_logo"
        // >
        //   {
        //     agent.workLogo && (
        //       <img
        //         src={getUrl(agent.workLogo.src)}
        //         alt=""
        //         style={{ maxHeight: '100%' }}
        //       />
        //     )
        //   }
        // </div>
      }

      <div className={`presentation__footer-logo`} data-id="agency_logo">
        <img src={getUrl(agent.workLogo?.src)} alt="" style={{ maxHeight: '100%' }} />
      </div>

      <div
        className="presentation__footer-row"
        data-id="manager_contacts"
      >
        <div className={`presentation__footer-item`} data-id="name" >
          <div className="presentation__footer-item_desc">
            <span>Ваш агент</span>
          </div>
          <div className="presentation__footer-item_value">{agentName()}</div>
        </div>
        <div className={`presentation__footer-item`} data-id="phone">
          <div className="presentation__footer-item_desc">
            <span>Телефон</span>
          </div>
          <div className="presentation__footer-item_value">
            <a className="link-hover" href={'tel:' + agent.phone}>
              {agent.phone}
            </a>
          </div>
        </div>
        <div className={`presentation__footer-item`} data-id="email">
          <div className="presentation__footer-item_desc">
            <span>E-mail</span>
          </div>
          <div className="presentation__footer-item_value">
            <a className="link-hover" href={'mailto:' + agent.email}>
              {agent.email}
            </a>
          </div>
        </div>

        <div className="presentation__footer-item presentation__footer-socials">
          <div className="presentation__footer-social" data-id="whatsapp">
            <a
              className="button button_view_round-control"
              href={`https://wa.me/${agent.whatsapp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="icon">
                <use xlinkHref="#social-wa"></use>
              </svg>
            </a>
          </div>
          <div className="presentation__footer-social" data-id="telegram">
            <a
              className="button button_view_round-control"
              href={`https://t.me/${agent?.telegram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="icon">
                <use xlinkHref="#social-tg"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const PagePDF = ({ children, data, activeSettings }) => {
  if (!data) return 'Loading...';
  const { agent } = data;

  return (
    <div className="pdf-list presentation__pdf-list">
      {children}
      <Footer data={data} agent={agent} activeSettings={activeSettings} />
    </div>
  );
};

export default PagePDF;
