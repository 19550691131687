import CardsList from '../../components/Public/markup/CardsList';
import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import Header from '../../components/Public/markup/Header';
import MapBlock from '../../components/Public/markup/MapBlock';
import MapBtn from '../../components/Public/markup/MapBtn';
import Tags from '../../components/Public/markup/Tags';
import Title from '../../components/Public/markup/Title';
import TopBar from '../../components/Public/markup/TopBar';
import './index.scss';

import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Dropdown from '../../components/core/Dropdown';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import Preloader from '../../components/Preloader';
import PrimaryPublicCard from '../../components/Public/markup/Containers/PrimaryPublicCard';
import RealtyMap from '../../components/Public/markup/Map';
import PublicMobileFilter from '../../components/Public/markup/MapFilters/PublicMobileFilter';
import PublicPrimaryMapFilter from '../../components/Public/markup/MapFilters/PublicPrimaryMapFilter';
import SubmitButton from '../../components/SubmitButton';
import useFilter from '../../hooks/useFilter';
import { sortlistDxb } from '../../hooks/useFilter/filterSets';
import { extractDubaiMapItems } from '../../hooks/useFilter/filterUtils';
import useMedia from '../../hooks/useMedia';
import { getSuffix, media } from '../../utils';

import { useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import FilterDisabled from '../../components/Public/FilterDisabled';
import DubaiPublicFilters from '../../components/Public/markup/DubaiPublicFilters';
import useRootCSS from '../../hooks/useRootCSS';
import MapFilters from '../../components/Public/markup/MapFilters/Index';
import CurrencysSelect from '../../components/CurrencysSelect';
import DubaiPublicCard from '../../components/Public/markup/Containers/DubaiPublicCard';

const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6 sf__filter-btn_dubai"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count}
      loaderColor={loaderColor}
    >
      {`${count} объект${getSuffix(count)}`}
    </SubmitButton>
  );
};

const DubaiPublic = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  // const [currency, setCurrency] = useState('USD');
  const [currency, setCurrency] = useState('RUB');
  const { savedFilterXmlId } = useParams();
  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      blocks = [],
      filter: filterData = {},
      prices,
      agent,
      city,
      isActive,
      isShowFilter,
      savedFilter,
      savedFilterSettings,
      coordinates, 
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent
  } = useFilter({
    apiConfig: {
      component: 'panpartner:primary.ajax',
      getCountFunction: 'getCount',
      submitFunction: 'getBlocks',
      getMapItems: 'getBlocks',
    },
    defaultFilter: {
      status: [1],
    },
    badgeFormattersParams: {
      currency: currency === "RUB" ? "₽" : currency.toLowerCase()
    },
    defaultParams: {
      savedFilterXmlId,
      "filter[currency]": currency
    },
    defaultOrder: 'pop',
    defaultBy: 'asc',
    defaultSortId: "0",
    sortList: sortlistDxb,
    sortEnabled: true,
    paginationEnabled: true,
    isGetFilter: true,
    loadMoreEnabled: true,
    loadMoreDataKey: 'blocks',
    shadowFilters: ['status'],
    extractCount: ({ countBlocks }) => countBlocks,
    extractLoadMoreCount: ({ countBlocks }) => countBlocks,
    extractMapItems: ({ blocks }) => blocks,
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },
    fields: {
      blocks: [],
      status: [],
      districts: [],
      rooms: [],
      endings: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
    },
  });

  // savedFilter && console.log(savedFilter.filter)

  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <div className="dubai-complex-filter">
            <PublicMobileFilter
              component={DubaiPublicFilters}
              filterData={filterData}
              filter={filter}
              exclude={exclude}
              clearByIdent={clearByIdent}
              setFilterAndExclude={setFilterAndExclude}
              handleFilter={handleFilter}
              count={`${count} объект${getSuffix(
                count,
              )}`}
              resetFilter={() => resetFilter(true)}
              onSumbit={() => {
                closeFilter();
                submit();
              }}
              isLoading={isCountLoading || isLoading}
              disableButton={!count}
              isMobile={isMobile}
              loaderColor={savedFilterSettings?.colorButtonText}
            />
          </div>
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );
  const badges = useMemo(() => isShowFilter || !savedFilter?.filter ? filterBadges : createBadges(savedFilter?.filter), [filterBadges, savedFilter])
  // badges && console.log(badges)
  // const saveToLocalSotage = id => {
  //   const tmpFilter = savedFilter?.filter ? { ...savedFilter.filter, ...filter } : filter;
  //   const tmpExclude = savedFilter?.exclude ? { ...savedFilter.exclude, ...exclude } : exclude;
  //   // localStorage.setItem("blocksFilter", JSON.stringify({ [id]: { filter: tmpFilter, exclude: tmpExclude } })) // для новой карточки ЖК нужно это
  //   localStorage.setItem("dubaiFilter", JSON.stringify({ [id]: tmpFilter }))
  // }



  const saveToLocalSotage = id => localStorage.setItem(`${city}Filter`, JSON.stringify({ [id]: {  filter: isShowFilter? filter: savedFilter.filter } }))



  if (isLoading) return <Preloader loading={true} />;
  if (!isActive) return <FilterDisabled agent={agent} />;
  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={coordinates ?? [25.135752, 55.229623]}
          {...mapState}
          items={mapItems}
          extractFeatures={extractDubaiMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}
          savedFilterSettings={savedFilterSettings}
          currency={currency}
          setCurrency={setCurrency}
          currencyList={prices}

        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={DubaiPublicCard}
          getLink={v => v.link}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={DubaiPublicFilters}
          count={`${count} объект${getSuffix(count)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          onClick={saveToLocalSotage}
          isShowFilter={isShowFilter}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          loaderColor={savedFilterSettings?.colorButtonText}
          currency={currency}
          setCurrency={setCurrency}
          currencyList={prices}
          dubai
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      {!savedFilterSettings?.hideHeader && (
        <Header {...agent} savedFilterSettings={savedFilterSettings} />
      )}

      {!savedFilterSettings?.hideTitle && (
        <div style={{marginBottom: 20}}>
          <Title
            title={
              savedFilterSettings?.title
                ? savedFilterSettings?.title
                : `Новостройки Дубая`
            }
          />
        </div>
      )}
      {!isTablet && (
        <>
          {isShowFilter && (
            <div className={`${!isLaptop ? 'sf__filter form' : 'sf__filter filter__max form form_dubai-secondfilter'}`}>
              <DubaiPublicFilters
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                count={`${count} объект${getSuffix(count)}`}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
                currency={currency}
                setCurrency={setCurrency}
                currencyList={prices}
              />
            </div>
          )}
          <Tags
            badges={badges}
            removeBadge={removeBadge}
            resetFilter={() => resetFilter(true)}
            readonly={!isShowFilter}
          />
          {/* <DraggableZone
            filterBadges={filterBadges}
            excludeBadges={excludeBadges}
            clearFilter={clearFilter}
            clearExclude={clearExclude}
            isExcludeEmpty={isExcludeEmpty}
            isFilterEmpty={isFilterEmpty}
            removeBadge={removeBadge}
            switchValues={switchFilterToExclude}
          /> */}
        </>
      )}

      {/*{isLaptop && !isTablet && (*/}
      {/*  <FilterSubmitButton*/}
      {/*    onClick={submit}*/}
      {/*    isLoading={isCountLoading || isLoading}*/}
      {/*    count={count}*/}
      {/*    loaderColor={savedFilterSettings?.colorButtonText}*/}
      {/*  />*/}
      {/*)}*/}
      <div className="sf__row sf__row_adaptive">
      {!isTablet && (
          <div className="sf__special-filter sf__special-filter_dubai">
            <div className="special-filter-drop special-filter-drop_white-select">
              <Dropdown
                value={sortId}
                options={sortlistDxb}
                onChange={handleSort}
              />
            </div>
            {/*<div>*/}
            {/*  {prices && <CurrencysSelect forCardlist prices={{ prices }} activeCurrency={currency} priceFormat={setCurrency} />}*/}
            {/*</div>*/}

          </div>
        )}

        <div className="sf__filter-btns">
          {isTablet && isShowFilter && <FilterToggleBtn onClick={openFilter} />}
          <MapBtn
            setShowMap={() => setView('map')}
            iconColor={savedFilterSettings?.colorButton}
          />
          {!isTablet && (
            <FilterSubmitButton
              onClick={submit}
              isLoading={isCountLoading || isLoading}
              count={count}
              loaderColor={savedFilterSettings?.colorButtonText}
            />
          )}
        </div>
      </div>

      <CardsList
        items={blocks}
        limit={limit}
        isLoading={showSkeleton}
        itemsRef={infiniteRef}
        cardComponent={DubaiPublicCard}
        getLink={item => item.link}
        listClass="sf__cards-list sf__cards-list_dubai-complex-list"
        onClick={saveToLocalSotage}
        currency={currency}
        withoutBtnsList
      />
      <LoadMoreSpinner loading={moreIsLoading} />
      {/* <div className="section__grid"> рабочий код, подходит полностью под хук useFilter но Роман хотел автоподгрузку тут 
      <div className="section__grid-col cards__pagination">
        <div className="pagination">
          <Pagination
            currentPage={page}
            itemsCount={count}
            itemsPerPage={limit}
            onChange={changePage}
          />
          {!(limit > count) && (
            <CountRealty
              itemsCount={count}
              itemsPerPage={limit}
              onChange={changeLimit}
            />
          )}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default DubaiPublic;
