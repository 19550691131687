import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSearch } from '../../actions/search';
import useDropdown from '../../hooks/useDropdown';
import { media } from '../../utils';


const SearchById = (props) => {
  const { user, mobile, align = 'right', onClick } = props;

  const [node, open, show, hide] = useDropdown(false);
  const [searchId, setSearchId] = useState(null);
  const [result, setResult] = useState([]);

  const handleSubmit = (value, e) => {
    if (e) e.preventDefault();
    if(!value){
      setSearchId(null)
      hide()
      return
    }
    setSearchId(value);
    fetchSearch(user.info.city, value).then((data) => {
      setResult(data.objects);
      show();
    });
  };
  const getLink = (item) => {
    if(item.link) return item.link;
    switch (item.type) {
      case "assignment":
        return `/assignments/${item.id}`
      case "commercial":
        return `/commercial/${item.id}`
      case "parking":
        return `/realty/${item.city}/parking/${item.id}`
      case "apartment":
        return `/realty/${item.city}/apartment/${item.id}`
      case "suburban":
        return `/old/lands/${item.city}/${item.id}`
      default:
        return `/${item.type}/${item.id}`
    }
  }

  return (
    <div
      ref={node}
      className={
        'search-id ' +
        (mobile ? 'header__mobile-search-id' : 'header__bottom-search-id')
      }
      // style={{ position: 'relative' }}
      style={props.fullWidth && { width: '100%', marginLeft: 0 }}
    >
      <form onSubmit={(e) => handleSubmit(searchId, e)}>
        <label
          className="input input_type_search_round input_type_search_round_gray input_width_available"
          htmlFor={`header-search-id` + (mobile ? '-mobile' : '')}
        >
          <DebounceInput
            className="input__control no-appearance"
            id={`header-search-id` + (mobile ? '-mobile' : '')}
            placeholder={media("isMobile") ? "Введите id объекта" : "Поиск по ID"}
            value={searchId}
            minLength={1}
            debounceTimeout={800}
            autoComplete="off"
            onChange={(event) => handleSubmit(event.target.value)}
            type="number"
          />
          <svg className="icon icon_search">
            <use xlinkHref="#search"></use>
          </svg>
        </label>
        {open && (
          <div className={`search-id__result align_${align}`}>
            {result.length == 0 && (
              <div className="search-id__result-empty">
                <span>По запросу ID {searchId} ничего не найдено</span>
              </div>
            )}
            {result.length > 0 && (
              <div className="search-id__result-list">
                {result.map((item) => (
                  <Link
                    to={getLink(item)}
                  >
                    <div
                      className="search-id__result-item"
                      onClick={() => {
                        hide()
                        onClick && onClick()
                      }}
                    >
                      <div className="search-id__result-item--type">
                        <span>
                          {item.typeName ? item.typeName : 'Переуступка'}
                        </span>
                      </div>
                      <div
                        className="search-id__result-item--desc"
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          paddingRight: '15px',
                        }}
                      >
                        <span className="search-id__result-item--rooms">
                          {item.roomTypeName && item.roomTypeName + ', '}
                        </span>
                        <span
                          className="search-id__result-item--details"
                          dangerouslySetInnerHTML={{
                            __html: item.title
                              .replace('корпус', 'к.')
                              .replace('этаж', 'эт.'),
                          }}
                        ></span>
                      </div>
                      <div className="search-id__result-item--id">
                        <span>ID {item.id}</span>
                      </div>
                      <div className="search-id__result-item--arrow">
                        <svg className="icon">
                          <use xlinkHref="#arrow_bottom"></use>
                        </svg>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchById);
