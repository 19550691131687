import React from "react";
import Manager from "../Manager";
import "./FilterDisabled.css";

const FilterDisabled = (props) => {
  const {
    agent,
    isPublic = true,
    text = "К сожалению, данный фильтр больше неактивен, обратитесь к своему агенту"
  } = props;

  return (
    <div className="filter-disabled">
      <svg className="icon_filter-disabled">
        <use xlinkHref="#filter_disabled"></use>
      </svg>
      <h2 className="filter-disabled__title">
        {text}
      </h2>
      {agent && <div className="filter-disabled__agent">
        <Manager data={agent} isPublic={isPublic} />
      </div>}
    </div>
  );
};

export default FilterDisabled;