import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
// import { sendError } from "../actions/forms";
import { ReactComponent as NotFound } from "../assets/images/not_found.svg";
const NotFoundPage = ({ history, userInfo, setFullScreenLoading }) => {
  
  // useEffect(() => {
  //   let data = {
  //     "fields[pageUrl]": window.location.href,
  //     "fields[error]": "Страница не найдена",
  //     "fields[comment]": "Страница не найдена",
  //     "fields[userInfo]": JSON.stringify(userInfo)
  //   };

  //   let form = new FormData();
  //   let keys = _.keys(data);
  //   keys.forEach((key) => {
  //     form.append(`${key}`, data[key]);
  //   });
  //   sendError(form);
  // }, []);
  useEffect(() => {
    if(setFullScreenLoading){
      setFullScreenLoading(false)
    }
  }, [])
  return (
    <div className="not-found-wrapper">
      <div className="not-found-content">
        <NotFound />
        <h3>К сожалению, произошла ошибка и такой страницы не существует.</h3>
        <span className="not-found-second-text">
          Попробуйте еще раз или перейдите по следующим ссылкам.
        </span>
        <button
          className="button button_view_default not-found-button"
          onClick={() => history.push("/")}
        >
          На главную
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default withRouter(connect(mapStateToProps, null)(NotFoundPage));
