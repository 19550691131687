import {useRef, useState, useEffect} from 'react';
import './index.scss';
import classNames from 'classnames';
import { ListIcon, TileIcon, ChessIcon } from './icons';

const Tabs = ({ title, largeBtn = false, children, isInline, withIcons = false, complexTitle, getActiveTab, withScroll, filter, firstActiveTab }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);
  getActiveTab && getActiveTab(activeTab)
  const tabsClasses = classNames({
    'tab-bar': true,
    'tab-bar_flex': title,
    'tab-bar_inline': isInline,
    'tab-bar_with-icons': withIcons,
  });
  const childs = children.filter((v) => !!v);

  const parentRef = useRef(null);

  const scrollToChildIfNeeded = (childElement) => {
    const parentElement = parentRef.current;
    const parentRect = parentElement.getBoundingClientRect();
    const childRect = childElement.getBoundingClientRect();

    if (childRect.left < parentRect.left) {
      parentElement.scrollLeft -= parentRect.left - childRect.left + 40;
    } else if (childRect.right > parentRect.right) {
      parentElement.scrollLeft += childRect.right - parentRect.right + 60;
    }
  };

  useEffect(() => {
    if (firstActiveTab) {
      setActiveTab(children[0].props.label)
    }
  },[firstActiveTab])

  return (
    <div className="tabs">
      <div className={tabsClasses}>
        {title && <h3 className={`${complexTitle ? 'h3-l' : 'h3'} tab-title`}>{title}</h3>}
        <div className="tab-list" id="parentBlock" ref={parentRef}>
          {childs.map((child) => {
            const label = child.props.label;
            const count = child.props.count;
            return (
              <>
                {filter ? <div onClick={() => {
                  filter('class', child.key)
                }}>
                  <Tab
                    activeTab={activeTab}
                    key={label}
                    label={label}
                    setActiveTab={setActiveTab}
                    largeBtn={largeBtn}
                    withIcons={withIcons}
                    count={count}
                    scrollToChildIfNeeded={withScroll ? scrollToChildIfNeeded : false}
                  />
                </div> :
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  setActiveTab={setActiveTab}
                  largeBtn={largeBtn}
                  withIcons={withIcons}
                  count={count}
                  scrollToChildIfNeeded={withScroll ? scrollToChildIfNeeded : false}
                />
                }
              </>
            );
          })}
        </div>
      </div>

      <div className="tab-content">
        {childs.map((child) => {
          const label = child.props.label;
          return label === activeTab ? child : null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, activeTab, setActiveTab, largeBtn, withIcons, count, scrollToChildIfNeeded }) => {
  const buttonClasses = classNames({
    'button tab-btn': true,
    h6: !largeBtn,
    'h3-l': largeBtn,
    'tab-btn_active': activeTab === label,
  });

  const handleButtonClick = (event) => {
    event.preventDefault();
    setActiveTab(label);
    scrollToChildIfNeeded && scrollToChildIfNeeded(event.target);
  };

  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={handleButtonClick}
    >
      {withIcons && getIcon(label)}

      {label}
      {count && <span style={{pointerEvents: 'none'}} className="tab-count">{count}</span>}
    </button>
  );
};

const getIcon = (label) => {
  switch (label) {
    case 'Список':
      return <ListIcon />;

    case 'Плитка':
      return <TileIcon />;

    case 'Шахматка':
      return <ChessIcon />;

    default:
      return <ListIcon />;
  }
};

export default Tabs;
