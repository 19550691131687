import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	clearFilterPresentations, clearPresentations,
	deletePresentations,
	fetchPresentations,
	filteredPresentations,
	handleAttachClient,
	setFilterPresentations
} from '../../actions/presentations';
import EmptyTable from '../core/EmptyTable';
import Preloader from '../Preloader';
import Filter from './Filter';
import Items from './Items';
import FilterSkeleton from "../../pages/Crm/FilterSkeleton";
import Loader from "../Loader";


const List = (props) => {
	const {
		fetch,
		reset,
		isFetching,
		filter,
		items,
		filterData,
		setFilter,
		clearFilter,
		handleAttachClient,
		remove,
		clients,
		data,
		fromCrm
	} = props;

	const [firstRender, setFirstRender] = useState(true);
	useEffect(() => {
		fetch().then(() => {
			setFirstRender(false);
		});
		return () => {
			reset();
		};
	}, []);
	useEffect(() => {
    if (items.length > 0) {
      setFirstRender(false);
    }
  }, [data]);

	const [isFiltersOpen, setIsFiltersOpen] = useState(false);

	const handleChildClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
	}

	return (
		<>
			<section style={props.fromCrm ? {marginTop: '-15px'} : {}} className={`section section_view_white ${!fromCrm && 'no-header-profile'}`}>
				<div className="wrapper">
					{!fromCrm &&
					<div className="clients__header">
						<div className="lk-presentations__title">
							<div className="h1">Презентации</div>
						</div>
						{/* <div className="lk-presentations__create">
							<div className="button button_view_default">
								Создать презентацию
							</div>
						</div> */}
					</div>
					}
					{!firstRender &&
						(items.length > 0 ? (
							<div className={fromCrm ? 'clients__crm-data' : ''}>
								<Filter
									filter={filter}
									filterData={filterData}
									setFilter={setFilter}
									clearFilter={clearFilter}
									isFiltersOpen={isFiltersOpen}
									onClick={handleChildClick}
									fromCrm={fromCrm}
								/>
								<Items
									items={data}
									handleDelete={remove}
									clients={clients}
									fetch={fetch}
									handleAttachClient={handleAttachClient}
									onClick={handleChildClick}
									fromCrm={fromCrm}
								/>
							</div>
						) : (
							<EmptyTable hideBorder={fromCrm} title="У вас нет презентаций" />
						))}

					{!(items.length > 0) && !fromCrm ? <Preloader loading={!(items.length > 0) && isFetching} /> :
						firstRender && fromCrm && <section style={props.fromCrm ? {marginTop: '-15px'} : {}} className="section section_view_white orders"><div style={{textAlign: 'center'}}><FilterSkeleton /><Loader loaderColor='#2c2e3e'/></div></section>}

				</div>
			</section>
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	fetch: () => dispatch(fetchPresentations()),
	reset: () => dispatch(clearPresentations()),
	remove: (ids) => dispatch(deletePresentations(ids)),
	setFilter: (filter) => dispatch(setFilterPresentations({ filter })),
	clearFilter: () => dispatch(clearFilterPresentations()),
	handleAttachClient: (clientId, presentationId, city, isAttaching) =>
		dispatch(handleAttachClient(clientId, presentationId, city, isAttaching)),
});

const mapStateToProps = (state) => ({
	isFetching: state.presentations.isFetching,
	error: state.presentations.error,
	filterData: state.presentations.filterData,
	filter: state.presentations.filter,
	items: state.presentations.data,
	data: filteredPresentations(state),
	clients: state.presentations.clients,
});

List.propTypes = {
	fetch: PropTypes.func,
	reset: PropTypes.func,
	data: PropTypes.array,
	isFetching: PropTypes.bool,
	error: PropTypes.bool,
	clients: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
