import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchClients } from "../../actions/clients";
import {
  clearFilterEstate,
  fetchEstateItems,
  fetchEstateMap,
  getCountBlocks,
  getEstatePage,
  setFilterEstate,
  setPage,
  setPageCount,
  setSort,
  setView
} from "../../actions/estate";
import { mapItems } from "../../selectors/estate";
import {
  filterToParams, scrollToRef, serialize
} from "../../utils";
import FilterError from '../ErrorBoundry/FilterError';
import Preloader from "../Preloader";
import Filter from "./Filter";
import FilterDisabled from "./FilterDisabled";
import Items from "./Items";


const List = (props) => {
  const {
    getEstatePage,
    fetchItems,
    filter,
    filterData,
    setFilter,
    location,
    features,
    mapItems,
    countApartments,
    page,
    pageCount,
    setPage,
    setPageCount,
    view,
    setView,
    data,
    getCountBlocks,
    fetching,
    sort,
    sortHandler,
    fetchEstateMap,
    savedFilter,
    isShowFilter,
    agent,
    isActive,
  } = props;
  const { savedFilterXmlId } = useParams();
  let history = useHistory();

  const [isFetching, setFetching] = useState(true);
  const [city, setCity] = useState("");
  const itemsRef = useRef(null);

  const queryParams = location.search;

  useEffect(() => {
    setFetching(true);
   
    const noStatistic = new URLSearchParams(queryParams).get("noStatistic")
    getEstatePage(city, null, pageCount, page, 1, sort, savedFilterXmlId, noStatistic ).then(
      data => {
        setFetching(false);
        setCity(data.filter?.cityCode);
      },
      error => setFetching(false)
    );
    // getCountBlocks(null, null, savedFilterXmlId, noStatistic);
  }, []);

  const correctedFilter = (filter) => {
    if (!filter)
      return {
        filter: {},
        exclude: {},
      };

    const specials = ["isNoFirstFloor", "isApartments", "isOnlyHanded", "assignments"];
    const result = filter;

    specials.forEach((key) => {
      Object.entries(result).forEach(([identity, value]) => {
        if (Array.isArray(value?.[key]) || !value?.[key] || !result[identity]?.[key]) return;
        result[identity][key] = [{ id: value[key] }];
      });
    });

    return result;
  };

  const handleFilter = (newPage, isHandlePage) => {
    let params = filterToParams(filter);
    history.push({
      search: `?${serialize(params)}`,
      state: { detail: "some_value", noClearFilter: false },
    });
    if (!isHandlePage) {
      scrollToRef(itemsRef, 80);
      setPage(1);
    }
    setFetching(true);
    fetchItems(
      city,
      params,
      pageCount,
      newPage,
      0,
      sort,
      savedFilterXmlId,
    ).then(() => {
      setFetching(false);
    });
    if (view === "map") {
      fetchEstateMap(city, params, sort, savedFilterXmlId);
    }
    localStorage.removeItem("blocksFilter")
  };

  const handlePageClick = (page) => {
    setPage(page);
    scrollToRef(itemsRef, 80);
    handleFilter(page, true);
  };
  const handleSort = (val) => {
    if (sort === val) {
      return;
    }

    if (view === "list") {
      setFetching(true);
      fetchItems(
        city,
        filterToParams(filter),
        pageCount,
        page,
        0,
        val,
        savedFilterXmlId,
      ).then(() => {
        setFetching(false);
      });
    }
    if (view === "map") {
      fetchEstateMap(city, filterToParams(filter), sort, savedFilterXmlId);
    }
    sortHandler(val);
  };
  const setIsApartmentsFilter = () => {
    let tempValue = {
      filter: { isApartments: [{ id: filterData.isApartments[0].id }] },
      exclude: {
        isApartments: [],
      },
    };
    setFilter(tempValue);
  };
  const setisAssigmentsFilter = () => {
    let tempValue = {
      filter: { assignments: [filterData.assignments[0].id] },
      exclude: {
        assignments: [],
      },
    };
    setFilter(tempValue);
  };
  const setCountOnPage = (pageCount) => {
    setPageCount(pageCount);
    setPage(1);
    scrollToRef(itemsRef, 80);
    setFetching(true);
    fetchItems(
      city,
      filterToParams(filter),
      pageCount,
      1,
      0,
      sort,
      savedFilterXmlId,
    ).then(() => {
      setFetching(false);
    });
  };
  return (
    <div>
      {isActive ? (
        <>
          <FilterError filterData={filterData} filter={filter}>
          <Filter
            onSubmit={(params) => {
              handleFilter(params);
            }}
            city={city}
            fetching={fetching}
            isPublic={true}
            savedFilterXmlId={savedFilterXmlId}
            savedFilter={correctedFilter(savedFilter)}
            getCountBlocks={getCountBlocks}
            isShowFilter={isShowFilter}
          />
          </FilterError>
          <Items
            items={data}
            innerRef={itemsRef}
            features={features}
            mapItems={mapItems}
            countBlocks={props.countBlocksToShow}
            countApartments={countApartments}
            view={view}
            handleView={setView}
            page={page}
            handlePage={handlePageClick}
            pageCount={pageCount}
            handlePageCount={setCountOnPage}
            fetching={fetching}
            sort={sort}
            sortHandler={handleSort}
            city={city}
            fetchEstateMap={fetchEstateMap}
            filter={filter}
            isPublic={true}
            savedFilterXmlId={savedFilterXmlId}
            savedFilter={correctedFilter(savedFilter)}
            setIsApartmentsFilter={setIsApartmentsFilter}
            setisAssigmentsFilter={setisAssigmentsFilter}
          />
        </>
      ) : (
        <FilterDisabled agent={agent} />
      )}
      <Preloader loading={isFetching} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getEstatePage: (
    city,
    form,
    pageCount,
    page,
    isGetFilter = 1,
    sort,
    savedFilterXmlId,
    noStatistic
  ) =>
    dispatch(
      getEstatePage(
        city,
        form,
        pageCount,
        page,
        isGetFilter,
        {},
        savedFilterXmlId,
        noStatistic
      ),
    ),
  fetchItems: (
    city,
    form,
    pageCount,
    page,
    isGetFilter,
    sort,
    savedFilterXmlId,
  ) =>
    dispatch(
      fetchEstateItems(
        city,
        form,
        pageCount,
        page,
        isGetFilter,
        {},
        savedFilterXmlId,
      ),
    ),
  setFilter: (filter) => dispatch(setFilterEstate(filter)),
  setView: (view) => dispatch(setView(view)),
  setPage: (page) => dispatch(setPage(page)),
  setPageCount: (pageCount) => dispatch(setPageCount(pageCount)),
  clearFilter: () => dispatch(clearFilterEstate()),
  getCountBlocks: (city, params, savedFilterXmlId, noStatistic) =>
    dispatch(getCountBlocks(city, params, savedFilterXmlId, noStatistic)),
  clearFilterOnly: () => dispatch({ type: "ESTATE_CLEAR_FILTER_ONLY" }),
  clearExcludeOnly: () => dispatch({ type: "ESTATE_CLEAR_EXCLUDE_ONLY" }),
  sortHandler: (sort) => {
    dispatch(setSort(sort));
  },
  fetchEstateMap: (city, form, sort, savedFilterXmlId) =>
    dispatch(fetchEstateMap(city, form, {}, savedFilterXmlId)),
  fetchClients: () => dispatch(fetchClients()),
});

const mapStateToProps = (state) => {
  return {
    filterData: state.estate.filterData,
    filter: state.estate.filter,
    data: state.estate.items,
    view: state.estate.view,
    page: state.estate.page,
    agent: state.estate.agent,
    isActive: state.estate.isActive, 
    pageCount: state.estate.pageCount,
    countApartments: state.estate.countApartments,
    countBlocks: state.estate.countBlocks,
    countBlocksToShow: state.estate.countBlocks,
    features: state.estate.features,
    mapItems: mapItems(state),
    fetching: state.estate.fetching,
    sort: state.estate.sort,
    savedFilter: state.estate.savedFilter,
    isShowFilter: state.estate.isShowFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
