import React from 'react';
import Dropdown from './Dropdown';

const countList = [
  {
    id: '9',
    title: '9',
  },
  {
    id: '18',
    title: '18',
  },
  {
    id: '36',
    title: '36',
  }
];

const CountRealty = ({ itemsCount, itemsPerPage, onChange }) => {

  const current = countList.find((item) => item.id == itemsPerPage);

  if (!current) return '';

  return (
    <div className="pagination__view-results">
      <div className="pagination__view-results-inner">
        <Dropdown value={current} options={countList} onChange={onChange} />
        <label className="pagination__view-results-label" htmlFor="view-results">
          Показывать&nbsp;<span>{itemsPerPage}</span> из {itemsCount}
        </label>
      </div>  
    </div>
  );

};

export default CountRealty;