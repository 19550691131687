const initialState = {
	builders: []
};

const forms = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_BUILDERS_SUCCESS':
			return {
				...state,
				builders: action.data,
			};
		default:
			return state;
	}
};

export default forms;
