import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import { formatMoney, getPricePerMeter, months } from '../../utils';
import './index.scss';
import Placeholder from './Placeholder';
import Prices from './Prices';

const Chart = (props) => {
  const { info } = props
  const today = new Date()
  const date = today.getDate()
  const month = months[today.getMonth()]
  const pricePerMeter = formatMoney(getPricePerMeter(info.costWithDiscount, info.squareTotal))
  const [start, setStart] = useState(null)
  const currentCost = info.costWithDiscount
  const startCost = info?.prices?.[0]?.costWithDiscount
  const totalDynamic = currentCost - startCost
  const zeroDynamic = currentCost === startCost
  const moneyPerPercent = startCost / 100
  const totalDynamicPercent = round(currentCost / moneyPerPercent - 100, 2)
  const dynamicPerMeter = round(totalDynamic / info.squareTotal)
  const [noStart, setNoStart] = useState(false)
  useEffect(() => {
    if(info?.prices){
      let startOject = info?.prices.find(price=>price.isStart)
      if(!startOject){
        startOject = info?.prices[0]
        setNoStart(true)
      }
      if(!startOject){
        return
      }
      const startObjectDate = new Date(startOject.date)
      startOject.day = startObjectDate.getDate()
      startOject.month = months[startObjectDate.getMonth()]
      startOject.year = startObjectDate.getFullYear()
      setStart(startOject)
    }
  }, [info])

  if (!(info?.prices?.length > 1)){
    return  <Placeholder />
  }

  return (
    <section className="section section_view_white chart flat" >
      <div className="wrapper chart__wrapper">
        <div className="wrapper__center">
          <div className="chart__content">
            <h3 className="h3 chart__title">График изменения цены на квартиру</h3>

            <div className="chart__block">

              <div className="chart__info">
                <div className="chart__info-block">
                  <div className="chart__info-row chart__info-row_paddings chart__info-row_wrap_sm">
                    {start && <div className="chart__info-col">
                      <div className="chart__info-item flat__info-item">
                        <div className="flat__info-item_desc">{noStart? "Цена на":"Старт продаж,"} {start.day} {start.month} {start.year}</div>
                      </div>
                      <div className="chart__info-item flat__info-item">
                        <div className="chart__info-value">
                          {formatMoney(start.costWithDiscount)} руб.
                          {/* <span className="chart__diff chart__diff_minus">
                            <svg class="icon icon_triangle-down">
                              <use xlinkHref="#triangle-down"></use>
                            </svg>
                          </span> */}
                        </div>
                        <div className="flat__info-item_desc">{formatMoney(getPricePerMeter(start.costWithDiscount, info.squareTotal))} руб./м²</div>
                      </div>
                    </div>}

                    <div className="chart__info-col">
                      <div className="chart__info-item flat__info-item">
                        <div className="flat__info-item_desc">Цена на сегодня, {date} {month}</div>
                      </div>
                      <div className="chart__info-item flat__info-item">
                        <div className="chart__info-value">
                        {formatMoney(info.costWithDiscount)} руб.
                          <span className={`chart__diff  ${totalDynamic > 0 ? "chart__diff_plus" : "chart__diff_minus"}`}>
                            <svg class="icon icon_triangle-down">
                              <use xlinkHref="#triangle-down"></use>
                            </svg>
                          </span>
                        </div>
                        <div className="flat__info-item_desc">{pricePerMeter} руб./м²</div>
                      </div>
                    </div>

                    {!zeroDynamic &&<div className="chart__info-col">
                      <div className="chart__info-item flat__info-item">
                        <div className="flat__info-item_desc">Общая динамика</div>
                      </div>
                      <div className="chart__info-item flat__info-item">
                        <div className="chart__info-value chart__info-value_sm">
                          {totalDynamic > 0 ? "+" + formatMoney(totalDynamic) : formatMoney(totalDynamic)} руб.
                          <span className={`chart__percent ${totalDynamicPercent > 0 ? "chart__percent_plus" : "chart__percent_minus"}`}>{totalDynamicPercent > 0 ? "+" + totalDynamicPercent : totalDynamicPercent}%</span>
                        </div>
                      </div>
                    </div>}

                    {!zeroDynamic &&<div className="chart__info-col">
                      <div className="chart__info-item flat__info-item">
                        <div className="flat__info-item_desc">Динамика за&nbsp;м²</div>
                      </div>
                      <div className="chart__info-item flat__info-item">
                        <div className="chart__info-value chart__info-value_sm">
                        {dynamicPerMeter > 0 ? "+" + formatMoney(dynamicPerMeter) : formatMoney(dynamicPerMeter)} руб./м²
                          {/* <span className="chart__percent chart__percent_minus">-15%</span> */}
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>

                {/* <div className="chart__info-block">
                  <div className="chart__info-row chart__info-row_align-center">
                    <div className="chart__info-item flat__info-item">
                      <div className="flat__info-item_desc">Средний рост цены в год</div>
                    </div>
                    <div className="chart__info-item flat__info-item">
                      <div className="chart__pie">
                        <div className="chart__pie-label">+15%</div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="chart__box chart__box_width_full">
                <div className="chart__container" style={{ width: '100%' }}>
                  <div className="chart__chart">
                    <Prices {...props} />
                  </div>
                  {/* пока без этих кнопок/ */}
                   <div className="chart__dates">
                    {/* <button type="button" className="chart__date chart__date_active">
                      <span className="chart__date-month chart__date-month">Май</span>
                      <span className="chart__date-year">2020</span>
                    </button>

                    <button type="button" className="chart__date">
                      <span className="chart__date-month">Июнь</span>
                    </button>

                    <button type="button" className="chart__date">
                      <span className="chart__date-month">Июль</span>
                    </button>

                    <button type="button" className="chart__date">
                      <span className="chart__date-month">Август</span>
                    </button>

                    <button type="button" className="chart__date">
                      <span className="chart__date-month">Сентябрь</span>
                    </button>

                    <button type="button" className="chart__date">
                      <span className="chart__date-current">Сегодня</span>
                    </button> */}
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chart;
