import './index.scss';

const Toggler = ({addClass, clickHandler}) => {
  return (
    <button
      type="button"
      className={`toggler button${addClass ? ` ${addClass}` : ``}`}
      onClick={clickHandler}
    >
      Открыть меню
    </button>
  );
};

export default Toggler;