import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../components/Constructor/index.scss';

import { ReactComponent as SecondarySvg } from "../assets/images/constructor/SecondarySvg.svg";
import { ReactComponent as AssignmentsSvg } from "../assets/images/constructor/AssignmentsSvg.svg";
import { ReactComponent as RentSvg } from "../assets/images/constructor/RentSvg.svg";

import SwiperSlider from "../components/core/SwiperSlider";

const photos = [
  {
    id: 0,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 1,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 2,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 3,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 4,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 5,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 6,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 7,
    src: `https://placekitten.com/470/320`,
  },
  {
    id: 8,
    src: `https://placekitten.com/470/320`,
  },
];

const ConstructorObjects = (props) => {

  return (
    <section className="property constructor__section" style={{marginTop: `100px`}}>
      <div className="constructor__container">
        <div className="property__wrapper constructor__wrapper">
          <div className="constructor__section-title">
            <h2 className="h2 constructor__title property__title">Подборки недвижимости</h2>
          </div>
        </div>
        <div className="property__tabs_wrapper">
          <div className="types-tabs__wrap">
            <div className="small-types-tabs">
              <ul className="types-tabs">
                <li className="types-tabs__item is-active">
                  <svg width={15} height={21} viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49168 19.625V9.85394L1.20834 13.446V19.625H13.6667V1.39868L7.49168 5.37763V10.9013" stroke="#22292E" strokeMiterlimit={10} />
                    <path d="M3.21249 12.2451L3.21249 19.625" stroke="#22292E" strokeMiterlimit={10} />
                    <path d="M9.54999 4.08881V19.625" stroke="#22292E" strokeMiterlimit={10} />
                  </svg>
                  <div className="types-tabs__name">Новостройки СПБ</div>
                </li>
                <li className="types-tabs__item">
                  <svg width={15} height={21} viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49168 19.625V9.85394L1.20834 13.446V19.625H13.6667V1.39868L7.49168 5.37763V10.9013" stroke="#22292E" strokeMiterlimit={10} />
                    <path d="M3.21249 12.2451L3.21249 19.625" stroke="#22292E" strokeMiterlimit={10} />
                    <path d="M9.54999 4.08881V19.625" stroke="#22292E" strokeMiterlimit={10} />
                  </svg>
                  <div className="types-tabs__name">Новостройки МСК</div>
                </li>
                <li className="types-tabs__item">
                  <SecondarySvg />
                  <div className="types-tabs__name">Вторичное жилье</div>
                </li>
                <li className="types-tabs__item">
                  <AssignmentsSvg />
                  <div className="types-tabs__name">Переуступки</div>
                </li>
                <li className="types-tabs__item">
                  <RentSvg />
                  <div className="types-tabs__name">Аренда</div>
                </li>
                <li className="types-tabs__background" style={{transform: 'translateX(-5px)', width: '191.188px'}} />
              </ul>
            </div>


            <div className="types-tabs__subtabs">
              <ul className="property__tabs">
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn property__btn_active">До 3-х млн. руб.</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Комфорт класс</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Бизнес класс</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">С отделкой</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Без отделки</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Рядом с метро</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Ипотека</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Мат. капитал</button></li>
                <li className="property__tabs-item"><button type="button" className="property__btn constructor__btn">Сданные дома</button></li>
              </ul>
              <div className="public__map-button false">
                <button className="button button_view_outline-with-icon-invert" style={{border: '1px solid rgb(221, 69, 59)', backgroundColor: 'rgb(221, 69, 59)', borderRadius: '5px', color: 'rgb(255, 255, 255)'}}>
                  <svg className="icon icon_marker" style={{fill: 'rgb(255, 255, 255)'}}><use xlinkHref="#marker" /></svg><span>На карте</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <section className="section cards section_view_gray">
          <div className="wrapper">
            <div className="section__grid">
              <div className="section__grid-col cards__item">
                <div className="obj-card text">
                  <div className="obj-card__slider">
                    <div className="swiper-pan">
                      <SwiperSlider
                        items={photos}
                        wrap={item => <div className="obj-card__slide"><img src={item.src} /></div>}
                        navigation
                      />
                    </div>
                  </div>
                  <div className="obj-card__content">
                    <div className="obj-card__body">
                      <p className="h4 obj-card__title">Лучшая цена, прекрасный вид</p>
                      <p className="h55 obj-card__subtitle">1-к. квартира, 34 м²</p>
                      <p className="obj-card__descr color-brand-3">Всеволожск г., Дорога Жизни ш.</p>
                    </div>
                    <div className="obj-card__footer">
                      <p className="obj-card__price h3">3 400 000 руб.</p>
                      <p className="obj-card__price-per-m color-brand-3">3 400 000 руб.</p>
                      <p className="obj-card__descr">
                      2-х комнатная квартира В ЖК бизнес-класса RIVIERE NOIRE у набережной Черной речки. У застройщика аналогов не осталось ...
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="section__grid-col cards__item">
                <div className="obj-card text">
                  <div className="obj-card__slider">
                    <div className="swiper-pan">
                      <SwiperSlider
                        items={photos}
                        wrap={item => <div className="obj-card__slide"><img src={item.src} /></div>}
                        navigation
                      />
                    </div>
                  </div>
                  <div className="obj-card__content">
                    <div className="obj-card__body">
                      <p className="h4 obj-card__title">Лучшая цена, прекрасный вид</p>
                      <p className="h55 obj-card__subtitle">1-к. квартира, 34 м²</p>
                      <p className="obj-card__descr color-brand-3">Всеволожск г., Дорога Жизни ш.</p>
                    </div>
                    <div className="obj-card__footer">
                      <p className="obj-card__price h3">3 400 000 руб.</p>
                      <p className="obj-card__price-per-m color-brand-3">3 400 000 руб.</p>
                      <p className="obj-card__descr">
                      2-х комнатная квартира В ЖК бизнес-класса RIVIERE NOIRE у набережной Черной речки. У застройщика аналогов не осталось ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </section>
  );
};

export default ConstructorObjects;
