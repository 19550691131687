import { useState, useEffect } from 'react';
import { isMobile } from '../utils';

const useDownloadApp = () => {
  const [downloadApp, setDownloadApp] = useState(isMobile);

  useEffect(() => {
    if (downloadApp) {
      document.body.style.overflow = 'hidden';
    }
  }, [downloadApp]);

  return [downloadApp, setDownloadApp];
};

export default useDownloadApp;