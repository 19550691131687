import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import request, { getUrl } from '../api';
import houseEmpty from '../assets/images/bcg_houses-01-01.svg';
import CallbackModal from "../components/Constructor/CallbackModal/CallbackModal.js";
import '../components/Constructor/index.scss';
import Breadcrumbs from '../components/Constructor/InnerPage/Breadcrumbs/Breadcrumbs';
import DropInfo from '../components/Constructor/InnerPage/DropInfo/DropInfo';
import ShareBlock from '../components/ShareBlock/ShareBlock';
import SwiperSlider from "../components/core/SwiperSlider";
import Preloader from '../components/Preloader';
import useMedia from "../hooks/useMedia";
import { useYaMap } from '../hooks/useYaMap';
import { formatMoney, makeComa } from '../utils';
import { getSubdomain } from '../utils/location';


const ColItem = memo(({ title, value, hide, single = false }) => value && !hide ? <div className={`co__item${single ? ` co__item_single` : ``}`}>
  <span className="h55 co__value">{value}</span>
  <span className="co__key text color-brand-3">{title}</span>
</div> : null)

const OnlinePic = () => (
  <svg width="106" height="81" viewBox="0 0 106 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.5241 73.7684C33.5185 73.8175 33.5067 73.8651 33.4871 73.9105C33.3651 74.1933 32.8568 75.2927 32.0486 75.9885L30.2425 77.3356C29.0859 78.1983 27.6686 80.2877 29.0777 80.5982C29.1472 80.6136 29.2233 80.6217 29.3066 80.6217H64.2825C64.305 80.6217 64.327 80.6211 64.3486 80.62C65.903 80.535 64.5517 78.1528 63.3039 77.222L61.6501 75.9885C60.8434 75.2914 60.3353 74.1933 60.2133 73.9106C60.1936 73.8651 60.1818 73.8175 60.1762 73.7683C60.144 73.4855 59.9047 73.272 59.6201 73.272H34.0803C33.7956 73.272 33.5563 73.4856 33.5241 73.7684Z" fill="#ECECEC"/>
    <path d="M28.502 79.4102V79.8464C28.502 79.8464 28.4904 80.6484 29.5872 80.6484H64.3357C64.3357 80.6484 65.1897 80.4806 65.1897 80.0343V79.5427L28.502 79.4102Z" fill="#D0D0D0"/>
    <path d="M47.4656 66.7297H46.2336H34.3153L33.5801 73.2702H60.119L59.3838 66.7297H47.4656Z" fill="#D0D0D0"/>
    <path d="M90.3567 0.842285H3.34447C1.89433 0.842285 0.71875 2.02545 0.71875 3.48497V64.0871C0.71875 65.5466 1.89433 66.7298 3.34447 66.7298H90.3567C91.8069 66.7298 92.9824 65.5466 92.9824 64.0871V3.48497C92.9824 2.02545 91.8069 0.842285 90.3567 0.842285Z" fill="#ECECEC"/>
    <path d="M3.13802 1.12525H90.387C90.6782 1.12525 90.9665 1.18305 91.2354 1.29535C91.5043 1.40764 91.7486 1.57222 91.9543 1.77967C92.1599 1.98711 92.3229 2.23334 92.4339 2.50427C92.5448 2.77519 92.6016 3.06549 92.601 3.35853V58.1753H1.05078V3.22094C1.05078 2.9453 1.1048 2.67238 1.20976 2.41779C1.31471 2.1632 1.46854 1.93195 1.66242 1.73728C1.85631 1.54261 2.08644 1.38835 2.33965 1.28333C2.59285 1.17831 2.86416 1.12459 3.13802 1.12525Z" fill="#2C2E3E"/>
    <path d="M90.6358 2.97656H3.02344V53.7261H90.6358V2.97656Z" fill="white"/>
    <g filter="url(#filter0_d_3065_8125)">
    <rect x="40.7012" y="15.1057" width="56.2584" height="36.0475" rx="4" transform="rotate(-9.0928 40.7012 15.1057)" fill="white"/>
    </g>
    <path d="M64.4642 31.0394L63.9232 27.9507C63.4828 25.437 62.1335 24.7252 59.9057 25.1154L59.3777 25.2079L58.9748 22.9078C58.9332 22.6704 58.939 22.4273 58.9918 22.192C59.0446 21.9568 59.1434 21.7341 59.2826 21.5368C59.4217 21.3394 59.5985 21.1712 59.8029 21.0418C60.0072 20.9124 60.235 20.8244 60.4734 20.7826L78.9225 17.5507C79.1609 17.5089 79.4051 17.5143 79.6412 17.5665C79.8774 17.6188 80.1008 17.7169 80.2988 17.8552C80.4967 17.9934 80.6653 18.1693 80.795 18.3725C80.9246 18.5758 81.0127 18.8026 81.0543 19.0399L81.4428 21.2579L80.8983 21.3533C80.4285 21.4128 79.9766 21.5708 79.5724 21.8168C79.1681 22.0629 78.8207 22.3914 78.553 22.7807C78.2853 23.17 78.1035 23.6112 78.0194 24.0753C77.9353 24.5395 77.951 25.016 78.0653 25.4733L78.6064 28.562L64.4642 31.0394Z" className="co__online-icon"/>
    <path d="M81.6557 36.1726C81.5112 36.1979 81.3627 36.165 81.2428 36.0812C81.1229 35.9974 81.0413 35.8696 81.0161 35.7258L80.8435 34.74L64.5066 37.6019L64.6793 38.5877C64.7045 38.7315 64.6713 38.8794 64.587 38.999C64.5027 39.1185 64.3741 39.2 64.2297 39.2253L62.431 39.5404C62.3602 39.5528 62.2877 39.5509 62.2177 39.5349C62.1478 39.5189 62.0818 39.489 62.0236 39.447C61.9655 39.4051 61.9165 39.352 61.8795 39.2907C61.8424 39.2295 61.8181 39.1615 61.808 39.0907L61.6353 38.1049L60.8927 38.235C60.4113 38.3193 59.9162 38.2098 59.5164 37.9305C59.1166 37.6513 58.8449 37.2251 58.7609 36.7458L57.3219 28.5312C57.1636 27.6275 57.5738 27.2509 58.3989 27.1063L60.7422 26.6958C60.9209 26.6527 61.1069 26.6497 61.2867 26.687C61.4666 26.7243 61.6359 26.801 61.7822 26.9115C61.9285 27.022 62.0483 27.1636 62.1329 27.326C62.2174 27.4884 62.2646 27.6675 62.2711 27.8504L63.0165 32.1056C63.0503 32.2988 63.1588 32.4712 63.3187 32.5856C63.4786 32.7 63.6773 32.7474 63.8721 32.7177L79.7634 29.9339C79.9604 29.8994 80.1356 29.7884 80.2506 29.6254C80.3655 29.4623 80.4108 29.2605 80.3765 29.0645L79.7059 25.2365C79.5332 24.2507 79.8843 23.3425 80.8745 23.1691L82.9207 22.8106C83.9108 22.6372 84.4508 23.3892 84.6235 24.375L85.9646 32.0311C86.0486 32.5104 85.9379 33.0035 85.6568 33.4021C85.3758 33.8006 84.9474 34.0719 84.466 34.1562L83.7234 34.2863L83.8961 35.2721C83.9213 35.4159 83.8881 35.5638 83.8038 35.6834C83.7194 35.803 83.591 35.8844 83.4465 35.9097L81.6557 36.1726Z" className="co__online-icon"/>
    <g clip-path="url(#clip0_3065_8125)">
    <path d="M48.103 27.0772C48.0874 25.9376 47.4665 24.8861 46.4878 24.3417C44.7146 23.3535 37.0778 21.4691 37.0778 21.4691C37.0778 21.4691 29.441 19.5847 27.4077 19.6371C26.2881 19.6637 25.2494 20.3057 24.7054 21.3072C23.718 23.1217 22.7173 27.177 22.7173 27.177C22.7173 27.177 21.7167 31.2323 21.7467 33.2979C21.7623 34.4375 22.3831 35.489 23.3619 36.0334C25.1382 37.0224 32.775 38.9068 32.775 38.9068C32.775 38.9068 40.4118 40.7912 42.4443 40.7421C43.5639 40.7155 44.6027 40.0735 45.1467 39.0719C46.1341 37.2574 47.1347 33.2021 47.1347 33.2021C47.1347 33.2021 48.1354 29.1468 48.103 27.0772Z" fill="#ED1F24"/>
    <path d="M31.5618 33.3243L38.8268 31.1521L33.4062 25.8496L31.5618 33.3243Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_3065_8125" x="37.2832" y="6.79614" width="68.084" height="51.3228" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.835697 0 0 0 0 0.835697 0 0 0 0 0.835697 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3065_8125"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3065_8125" result="shape"/>
    </filter>
    <clipPath id="clip0_3065_8125">
    <rect width="25.1498" height="17.9641" fill="white" transform="translate(24.8691 18.4565) rotate(13.861)"/>
    </clipPath>
    </defs>
  </svg>
);

const MortgagePic = () => (
  <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M11.8582 6.53752C17.8885 6.53752 22.7769 5.25763 22.7769 3.67879C22.7769 2.09996 17.8885 0.820068 11.8582 0.820068C5.82794 0.820068 0.939453 2.09996 0.939453 3.67879C0.939453 5.25763 5.82794 6.53752 11.8582 6.53752Z" className="mortgage__icon"/>
    <path opacity="0.3" d="M12.6821 17.5953H11.8483C7.15321 17.5953 3.15298 16.821 1.61443 15.7291C1.17768 15.4214 0.939453 15.7292 0.939453 15.9575V19.5805C0.939453 21.1687 5.82311 22.4491 11.8582 22.4491C12.5232 22.4491 13.1585 22.4491 13.7839 22.4491C13.0904 20.9229 12.7155 19.2713 12.6821 17.5953Z" className="mortgage__icon"/>
    <path opacity="0.3" d="M15.3919 9.48551C14.2802 9.5947 13.089 9.64433 11.8483 9.64433C7.15321 9.64433 3.15298 8.87009 1.61443 7.77822C1.17768 7.47051 0.939453 7.77823 0.939453 8.00653V11.6296C0.939453 13.2078 5.82311 14.4982 11.8582 14.4982H12.9997C13.4087 12.6683 14.2265 10.9545 15.3919 9.48551Z" className="mortgage__icon"/>
    <path d="M25.1483 26.5585C30.2795 26.5585 34.4391 22.3989 34.4391 17.2677C34.4391 12.1365 30.2795 7.97681 25.1483 7.97681C20.0171 7.97681 15.8574 12.1365 15.8574 17.2677C15.8574 22.3989 20.0171 26.5585 25.1483 26.5585Z" className="mortgage__icon"/>
  </svg>

);

const ConstructorObject = ({setFullScreenLoading}) => {

  const isLaptop = useMedia("isLaptop");
  const { selfObjectId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({});
  const [semiObjects, setSemiObjects] = useState({});
  const subDomain = getSubdomain()
  const dispatch = useDispatch()
  const { agency, agent, theme } = useSelector(state => state.agency)
  const [swiperRef, setSwiperRef] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const videoIframeRef = useRef(null)

  useEffect(() => {
    request("panpartner:agent.sites.ajax", "getObject", { id: selfObjectId, subDomain })
      .then(resp => {
        setData(resp?.object || {})
        setSemiObjects(resp?.semiObjects || {})
        let agency = resp.site?.agency
        let agent = resp.site?.agent
        let socials = resp.site?.socials
        let theme = resp.site?.theme
        let yandexMetric = resp.site?.yandexMetric
        dispatch({
          type: "AGENCY_SET_STATE", payload: {
            agent,
            agency,
            socials,
            theme,
            yandexMetric,
          }
        })
      })
      .finally(() => {
        setIsLoading(false)
        setFullScreenLoading(false)
      })
  }, [])

  const [openModal, closeCallbackModal] = useModal(() => (
    <CallbackModal closeCallbackModal={closeCallbackModal} title={"Оставить заявку"} id={selfObjectId} selfObjectId={selfObjectId} />
  ), []);

  const [openMapModal, closeMapModal] = useYaMap({
    title: data?.address,
    markerColor: theme?.color,
    cords: data?.coordinates,
    mapCenter: data?.coordinates,
  })

  const mergedPhotos = useMemo(() => {
    const result = []
    if (data?.videoYoutube) {
      try{
        const searchParams = new URLSearchParams(new URL(data?.videoYoutube).search)
        const videoId = searchParams.get("v")
        videoId && result.push({
          videoYoutube: videoId,
          thumb: `https://img.youtube.com/vi/${videoId}/0.jpg`
        })
      }catch(error){
        console.log(error)
      }
    }

    if (!data?.photos && !data?.plan) return result
    if (!data?.photos?.length) return [...result, data.plan]
    if (data.plan?.empty && !data.photos.length) return [...result, data.plan]
    if (!data.plan || data.plan?.empty) return [...result, ...data.photos]
    return [...result, data.plan, ...data.photos]
  }, [data])

  const isRent = useMemo(() => {
    if (!data?.saleType) return false
    return data.saleType === "Аренда"
  }, [data])
  if (isLoading) return <Preloader loading={true} />
  const {
    title,
    address,
    coordinates,
    type,
    saleType,
    status,
    roomType,
    squareTotal,
    squareLiving,
    squareKitchen,
    plan,
    photos,
    balcony,
    restroom,
    rentType,
    objectType,
    client, 
    floor,
    floors,
    ending,
    dateUpdate,
    description,
    clients,
    price,
    meterPrice,
    isApartment,
    isHanded,
    year,
    repair,
    inFlat,
    rentTypeXmlId,
    videoYoutube,
    isMortgage,
    isOnlineShow,
    squareHouse,
    squareParcel,
    materialHouses,
    countBedrooms,
    heating,
    lendStatus,
    features,
    decoration,
  } = data

  const slideTo = index => {
    if(!swiperRef) return
    swiperRef.slideTo(index)
    setTimeout(() => {
      swiperRef.thumbs.update()
    }, 100);
  }
  const stopVideo = () => videoIframeRef.current?.contentWindow?.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
  

  const onSlideChangeHandler = swiper => {
    setActiveIndex(swiper.activeIndex)
    if(!videoIframeRef.current) return
    stopVideo()
  }


  return (
    <section className="property constructor__section co">
      <div className="constructor__container">
        <section className="section co section_view_gray">
          <div className="wrapper">

            <Breadcrumbs currentPage={title} />
            <div className="co__container">

              {
                isLaptop &&
                <div className="co__header">
                  {false && <DropInfo type={type} objectType={objectType} />}
                  <h2 className="h2 co__title">{title}</h2>
                  <div className="co__header-content">
                    <p className="co__adress">{address}</p>
                    {coordinates && <button type="button" className="co__on-map button text" onClick={openMapModal}>
                      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 17.1661C5.5 17.1661 11 10.9136 11 6.0241C11 3.04631 8.53747 0.632812 5.5 0.632812C2.46217 0.632812 0 3.04631 0 6.0241C0 11.0301 5.5 17.1661 5.5 17.1661ZM8 6.31612C8 4.88969 6.88042 3.73279 5.5 3.73279C4.11958 3.73279 3 4.88969 3 6.31612C3 7.74256 4.11958 8.89946 5.5 8.89946C6.88042 8.89946 8 7.74256 8 6.31612Z" className="co__on-map-icon" />
                      </svg>
                      На карте
                    </button>}
                  </div>

                </div>
              }
            <div className="co__left-col">
              <div className="swiper-pan co__slider">
                {videoYoutube && activeIndex !== 0 && 
                  <div onClick={()=>slideTo(0)} className="co__video-panel">
                    <svg width="18" height="12" viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg" className="co__video-icon">
                      <path d="M9.8639 0.797363L2.6907 0.797363C2.17185 0.797363 1.67425 1.00348 1.30737 1.37036C0.940487 1.73724 0.734375 2.23484 0.734375 2.75369L0.734375 9.27478C0.734375 9.79363 0.940487 10.2912 1.30737 10.6581C1.67425 11.025 2.17185 11.2311 2.6907 11.2311H9.8639C10.3827 11.2311 10.8803 11.025 11.2472 10.6581C11.6141 10.2912 11.8202 9.79363 11.8202 9.27478V2.75369C11.8202 2.23484 11.6141 1.73724 11.2472 1.37036C10.8803 1.00348 10.3827 0.797363 9.8639 0.797363Z" fill="white"/>
                      <path d="M17.3637 0.861762C17.2669 0.79809 17.1535 0.76416 17.0377 0.76416C16.9218 0.76416 16.8084 0.79809 16.7116 0.861762L13.4511 2.81809C13.3509 2.87968 13.2683 2.96604 13.2112 3.06883C13.1541 3.17163 13.1244 3.28739 13.125 3.40499V8.62185C13.1244 8.73945 13.1541 8.85522 13.2112 8.95801C13.2683 9.0608 13.3509 9.14716 13.4511 9.20875L16.7116 11.1651C16.8093 11.2242 16.9247 11.2473 17.0377 11.2303C17.1504 11.2404 17.2636 11.2178 17.3637 11.1651C17.4639 11.1035 17.5465 11.0171 17.6036 10.9143C17.6607 10.8115 17.6904 10.6958 17.6898 10.5782V1.44866C17.6904 1.33107 17.6607 1.2153 17.6036 1.11251C17.5465 1.00971 17.4639 0.923357 17.3637 0.861762V0.861762Z" fill="white"/>
                      <circle cx="4.2725" cy="3.97562" r="0.917028" className="co__video-circle" />
                      <circle cx="4.2725" cy="3.97562" r="0.917028" fill="transparent"/>
                    </svg>
                    <span>Видео</span>
                  </div>
                }
                  <SwiperSlider
                    items={mergedPhotos}
                    wrap={(item, isTumb) => {
                      if (item.videoYoutube && isTumb) return <div className="co__slide"><img src={item.thumb} loading="lazy" /></div>
                      if (item.videoYoutube) return <div className="co__slide">
                        <iframe
                          src={`https://www.youtube.com/embed/${item.videoYoutube}?controls=1&iv_load_policy=3&enablejsapi=1`}
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          title="video"
                          style={{
                            width: "100%"
                          }}
                          ref={videoIframeRef}
                        />
                      </div>
                      return <div className="co__slide"><img src={getUrl(item?.src)} loading="lazy" /></div>
                    }}
                    thumbs={mergedPhotos.length > 1}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = houseEmpty
                    }}
                    navigation={mergedPhotos.length > 1}
                    options={{
                      onSwiper: setSwiperRef,
                      onActiveIndexChange: onSlideChangeHandler
                    }}
                    withCounter
                  />

                </div>

                <ShareBlock />

                {
                  isOnlineShow && (
                    <div class="co__online">
                      <div className="co__online-pic">
                        <OnlinePic />

                      </div>
                      <div className="co__online-box">
                        <h4 className="h4">On-line показ</h4>
                        <p className="text">Возможен показ по видео-связи</p>
                      </div>
                    </div>
                  )
                }

                
                {
                  !isLaptop && !!description && (
                    <div className="co__description">
                      <h4 className="h4 co__description-title">Описание</h4>
                      <div className="co__description-content big-text"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }} />
                    </div>
                  )
                }

            </div>


              <div className="co__info">
                {
                  !isLaptop &&
                  <div className="co__header">
                     {false && <DropInfo type={type} objectType={objectType} />}
                    <h2 className="h2 co__title">{title}</h2>
                    <p className="co__adress">{address}</p>
                    {coordinates &&<button type="button" className="co__on-map button text" onClick={openMapModal}>
                      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 17.1661C5.5 17.1661 11 10.9136 11 6.0241C11 3.04631 8.53747 0.632812 5.5 0.632812C2.46217 0.632812 0 3.04631 0 6.0241C0 11.0301 5.5 17.1661 5.5 17.1661ZM8 6.31612C8 4.88969 6.88042 3.73279 5.5 3.73279C4.11958 3.73279 3 4.88969 3 6.31612C3 7.74256 4.11958 8.89946 5.5 8.89946C6.88042 8.89946 8 7.74256 8 6.31612Z" className="co__on-map-icon" />
                      </svg>
                      На карте
                    </button>}
                  </div>
                }

                <div className="co__info-body">
                  <div className="co__price">
                    <span className="h3 co__price-full">{formatMoney(price, isRent ? rentTypeXmlId === "daily" ? " р. / сутки" : " р. / мес" : " р.")}</span>
                    {!isRent && meterPrice && <span className="co__price-per-month text color-brand-3">{formatMoney(meterPrice)} руб./м²</span>}
                  </div>

                  <div className="co__manager">
                    <div className="co__manager-info">
                      <p className="co__manager-name bold">{agent?.name} {agent.lastName}</p>
                      {
                        agency.phone && (
                          <a href={`tel:${agency.phone}`} class="co__manager-link color-brand-3">{agency.phone}</a>
                        )
                      }

                    </div>
                    <button className="button co__manager-btn" type="button" onClick={openModal}>Оставить заявку</button>
                  </div>
                </div>

                <div className="co__items">
                  <ColItem title="Тип" value={type} />
                  <ColItem title="Общая площадь" value={`${squareTotal} м²`} hide={!squareTotal} />
                  <ColItem title="Жилая площадь" value={`${squareLiving} м²`} hide={!squareLiving} />
                  <ColItem title="Площадь кухни" value={`${squareKitchen} м²`} hide={!squareKitchen} />
                  <ColItem title="Площадь дома" value={`${squareHouse} м²`} hide={!squareHouse} />
                  <ColItem title="Площадь участка" value={`${squareParcel} сот.`} hide={!squareParcel} />
                  <ColItem title="Отопление" value={`${heating}`} hide={!heating} />
                  <ColItem title="Кол-во спален" value={`${countBedrooms}`} hide={!countBedrooms} />
                  
                  
                  <ColItem title="Этаж" value={floors ? `${floor}/${floors}` : floor} hide={!floor} />
                  
                  <ColItem title="Этажность" value={floors} hide={!!floor} />

                  <ColItem title="Материал дома" value={`${materialHouses}`} hide={!materialHouses} />
                  <ColItem title="Статус земли" value={`${lendStatus}`} hide={!lendStatus} />
                  <ColItem title="Отделка" value={`${decoration}`} hide={!decoration} />
                  

                  <ColItem title="Балкон" value={balcony} />
                  <ColItem title="Санузел" value={restroom} />
                  <ColItem title="Ремонт" value={repair} />
                  <ColItem title="Год постройки" value={year} />
                  <ColItem title="Срок сдачи" value={!isHanded ? ending : `Сдан`} />
                  
                </div>
                <ColItem single title="В квартире" value={inFlat && inFlat.map(makeComa)} />
                <ColItem single title="Дополнительно" value={features && features.map(makeComa)} />


                {
                  isMortgage && (
                    <div className="co__mortgage">
                      <MortgagePic />
                      <div className="co__mortgage-box">
                        <h5 className="h55">Нужна ипотека?</h5>
                        <p className="text">Получите информацию о реальных ипотечных ставках</p>
                        <button className="button co__mortgage-btn co__manager-btn" type="button" onClick={openModal}>Оставить заявку</button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

            {
              isLaptop && !!description && (
                <div className="co__description">
                  <h4 className="h4 co__description-title">Описание</h4>
                  <div className="co__description-content big-text"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }} />
                </div>
              )
            }

          </div>
        </section>
        {
          semiObjects.length > 0 && (

          <>
          <section className="section co property__wrapper constructor__wrapper">
            <div class="constructor__section-title">
              <h2 class="h2 constructor__title property__title">Другие предложения</h2>
            </div>
          </section>

          <section className="obj-card-grid">
            {

              semiObjects.map((item) => {
                return (
                  <a href={item.link} target="_blank" className="obj-card obj-card_for-grid text">
                    <div className="obj-card__slider">
                      {
                        item.photos.length > 0 &&
                        <img
                          src={`https://panpartner.ru${item.photos[0].src}`}
                          alt="" /> 
                      }

                      {
                        item.photos.length == 0 && item.plan &&
                        <img
                          src={`${item.plan.src}`}
                          alt="" /> 
                      }
                      
                    </div>
                    <div className="obj-card__content">
                      <div className="obj-card__body">
                        <p className="h4 obj-card__title"><span>{item.title}</span></p>
                        <p className="h55 obj-card__subtitle">{item.subTitle}</p>
                        <p className="obj-card__body-descr color-brand-3">{item.address}</p>
                      </div>
                      <div className="obj-card__footer">
                        <p className="obj-card__price h3">{formatMoney(item.price, item.saleTypeXmlId === "rent" ? item.rentType === "daily" ? " р./сутки" : " р./мес" : " руб.")}</p>
                        {item.meterPrice && <p className="obj-card__price-per-m color-brand-3">{formatMoney(item.meterPrice)} руб./м²</p>}
                        {/* <p className="obj-card__descr">{item.description}</p> */}
                      </div>
                    </div>
                  </a>
                )
              })
            }
          </section>
          </>
          )
        }
      </div>
    </section>
  );
};

export default ConstructorObject;