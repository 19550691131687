const AttentionIcon = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="8.5" stroke={color} />
    <line x1="12.043" y1="7.5" x2="12.043" y2="13.5" stroke={color} strokeLinecap="round" />
    <circle cx="12.043" cy="15.5" r="0.5" fill={color} />
</svg>

const colors = {
    green: {
        color: "#2C2E3E",
        backgroundColor: "#DFF5E0",
        iconColor: "#2CBA2E",
    }
}
const Attention = ({ style = {}, text = "", color = "green" }) => <div
    style={{
        display: "flex",
        flexDirection: "row",
        padding: "16px 30px",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        borderRadius: 20,
        ...colors[color],
        ...style
    }}
>
    <AttentionIcon color={colors[color].iconColor} />
    <span style={{ marginLeft: 10 }}>{text}</span>
</div>
export default Attention;