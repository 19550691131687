import React from "react";
import { Link } from "react-router-dom";

const EmptyTable = (props) => {
  const { title = null, desc = null, button = null, link = null, hideBorder } = props;
  return (
    <section className="section empty__section">
      <div className="empty-block" {...(hideBorder ? { style: { border: "none" } } : {})}>
        <div className="empty-block__image">
          <svg>
            <use xlinkHref="#no_data"></use>
          </svg>
        </div>
        {title && <h2 className="h2 empty-block__title">{title}</h2>}
        {desc && <p className="empty-block__text">{desc}</p>}
        {button && link && (
          <Link to={link}>
            <button className="button button_view_default">{button}</button>
          </Link>
        )}
      </div>
    </section>
  );
};

export default EmptyTable;
