import ManagerModal from './ManagerModal';
import useApi from '../../../../hooks/useApi';
import Preloader from '../../../Preloader';
const PromoCardModal = ({ id }) => {
  const {
    data: {
      action: { title, previewText, activeTo, discount } = {},
      managers = null,
    },
    isLoading,
  } = useApi(
    {
      payload: ['panpartner:builder.actions.ajax', 'getAction', { id }],
    },
    [id],
  );

  if (isLoading) return <Preloader loading />;

  return (
    <div className="sf__video-content">
      <div className="sf__video-header">
        <h3 className="h3-l sf__video-h3">{title}</h3>
      </div>
      <div className="sf__video-body">
        <div className="sf__actions-flex">
          <div className="sf__video-item">
            <p className="sf__video-key">Акция действует:</p>
            <p className="h5-l">{activeTo}</p>
          </div>

          <div className="sf__video-item">
            <p className="sf__video-key">Размер скидки:</p>
            <p className="h5-l">{discount}</p>
          </div>
        </div>

        {previewText && <div className="sf__video-descr pre-line" dangerouslySetInnerHTML={{__html: previewText}}/>}

        <h5 className="h5-l sf__actions-title">
          Подробную информацию уточняйте у менеджеров
        </h5>

        {managers && managers.map((manager) => <ManagerModal {...manager} />)}
      </div>
    </div>
  );
};

export default PromoCardModal;