import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useModal } from 'react-modal-hook';
// Import Swiper React components
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import request from '../../api';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import { standartErrorToast, standartSuccessToast } from '../../components/toasts';
import useDocTitle from '../../hooks/useDocTitle';
import useMedia from '../../hooks/useMedia';
import { scrollToRef } from "../../utils";
import { diplomas, slides, slidesLove, team } from './data.js';
import './index.scss';




const PAN_COORDINATES = [59.92793879832461, 30.346149034998835]

SwiperCore.use([Pagination,Navigation]);

const addDealSuburban = form => request("panpartner:b24.ajax", "addDealSuburban", form)

const Slide = (props) => {
  const {item} = props;
  return (
    <div className="zl__houses-slide zl__h4">
      <img src={require(`../../assets/images/zl/block-slide-${item.id}.jpg`).default} alt="" />
      <div className="zl__houses-name">
        <span>{item.name}</span>
      </div>
    </div>
  );
};

const Form = ({closeModal, formRequestType}) => {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      agree: true,
    },
  });

  const sendForm = data => addDealSuburban({ ...data, comment: formRequestType }).then(standartSuccessToast, standartErrorToast).finally(closeModal)

  return (
  <form action="#" className="zl-form" onSubmit={handleSubmit(sendForm)}>
    <div className="zl-form__container">
      <h2 className="h2 text-center zl-form__title">Оставить заявку</h2>
      <div className="zl-form__item">
        <label
          className="input input_width_available input_type_form"
          htmlFor="modal-reservation-name"
          >
          <input
            id="modal-reservation-name"
            name="name"
            defaultValue={``}
            className={
              "input__control" + (errors.name ? " input__error" : "")
            }
            placeholder="Ваше имя"
            ref={register({
              required: "Введите имя",
            })}
            />
          {errors.name && (
            <span className="input__error-label">
              {errors.name.message}
            </span>
          )}
        </label>
      </div>

      <div className="zl-form__item">
        <label
          className="input input_width_available input_type_form"
          htmlFor="modal-reservation-phone"
        >
          <InputMask
            id="modal-reservation-phone"
            className={
              "input__control" + (errors.phone ? " input__error" : "")
            }
            mask="+7 (999) 999-99-99"
            autoComplete="off"
            maskChar=" "
            name="phone"
            placeholder="Ваш телефон"
            defaultValue={``}
            inputRef={register({
              required: "Введите телефон",
            })}
          />
          {errors.phone && (
            <span className="input__error-label">
              {errors.phone.message}
            </span>
          )}
        </label>
      </div>

      <div className="checkbox checkbox_view_invert">
        <div
          className={
            'checkbox__box' +
            (errors.agree ? " checkbox__box-error" : "")
          }
          >
          <input
            className="checkbox__control"
            type="checkbox"
            id="modal-reservation-agreement" 
            name="agree"
            ref={register({
              required: "Необходимо согласиться с правилами обработки данных",
            })}
            />
          <label
            className="checkbox__input"
            htmlFor="modal-reservation-agreement"
            ></label>
          <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
        </div>
        <label
          className="checkbox__label"
          htmlFor="modal-reservation-agreement"
          
          >
          Отправляя заявку вы соглашаетесь на обработку <a href="https://1-an.ru/privacy-policy/" target="_blank" rel="noopener noreferrer" className="link zl-form__link">персональных данных</a>
        </label>
      </div>
      <button type="submit" className="zl-form__submit button button_view_default">Отправить</button>
    </div>
  </form>
  );
};


const ZagorodLanding = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const [formRequestType, setformRequestType] = useState('Купить');

  useDocTitle("Покупка и продажа загородной недвижимости")
  
  useEffect(() => {
    (function(d,s){var js,fjs=d.getElementsByTagName(s)[0];
      js=d.createElement(s);
      js.async=1;js.src="//widget.flamp.ru/loader.js";
      fjs.parentNode.insertBefore(js,fjs);}
      (document,"script"));
      dynamicMapImport()
   }, [])
 
  const isMobile = useMedia("isMobile");
  const isLaptop = useMedia("isLaptop");

  const sertifRef = useRef(null);
  const teamRef = useRef(null);
  const keysRef = useRef(null);
  const serviceRef = useRef(null);
  const contactsRef = useRef(null);

  const scrollToFunc = (ref) => {
    scrollToRef(ref, 80);
    setMenuOpened(false);
  };

  const [openModal, closeModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeModal} title="Отправить заявку">{children}</MobileModal>
        : <Modal close={closeModal} classes="modal_task modal_zl">{children}</Modal>
      } >
        <Form closeModal={closeModal}  formRequestType={formRequestType} />
      </Wrap >
    ), [formRequestType]);
    
  const openModalWithComment = (comment = "Обратный звонок") => {
    setformRequestType(comment)
    openModal()
  }
  
  const [Maps, setMaps] = useState(null)
  const [ymaps, setYmaps] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  useEffect(() => {
    if (!mapInstance || !ymaps) return
    const placemarkTemplate = ymaps.templateLayoutFactory.createClass(
      `<svg width="62" height="88" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31 0C13.8836 0 0 13.794 0 30.8C0 53.9 31 88 31 88C31 88 62 53.9 62 30.8C62 13.794 48.1164 0 31 0ZM31 41.8C24.8886 41.8 19.9286 36.872 19.9286 30.8C19.9286 24.728 24.8886 19.8 31 19.8C37.1114 19.8 42.0714 24.728 42.0714 30.8C42.0714 36.872 37.1114 41.8 31 41.8Z" fill="#0E2716"/>
      </svg>`,
    )
    const placeMaprk = new ymaps.Placemark(PAN_COORDINATES, {
    }, {
      iconLayout: placemarkTemplate,
      iconOffset: [-31, -88],
    });
    mapInstance.geoObjects
      .add(placeMaprk);
  }, [mapInstance, ymaps])

  const dynamicMapImport = async () => {
    const { YMaps, Map, Placemark, RulerControl } = await import("react-yandex-maps")
    setMaps({ YMaps, Map, Placemark, RulerControl })
  };

  return (
    <section className={`zl${menuOpened ? ` zl_overlay` : ``}`}>
      <header className={`zl__header`}>
        <div className="zl__container">
          <div className="zl__bar">

            <img src={require('../../assets/images/zl/logo.svg').default} alt="Пан" className="zl__logo" />
            <nav className="zl__nav">
              <button type="button" className={`zl__burger`} onClick={() => setMenuOpened(!menuOpened)}></button>
              {
              !isMobile && 
                <ul className={`zl__nav-menu caps${menuOpened ? ` opened` : ``}`}>
                  <li><a className="link" onClick={() => scrollToFunc(keysRef)}>Проекты</a></li>
                  <li><a className="link" onClick={() => scrollToFunc(serviceRef)}>Trade-In</a></li>
                  <li><a className="link" onClick={() => scrollToFunc(teamRef)}>команда</a></li>
                  <li><a className="link" onClick={() => scrollToFunc(sertifRef)}>сертификаты</a></li>
                  <li><a className="link" onClick={() => scrollToFunc(contactsRef)}>контакты</a></li>
                </ul>
              }
              <button type="button" className="zl__callback-btn zl__btn button caps" onClick={()=>openModalWithComment()}>Обратный звонок</button>
            </nav>

          </div>
        </div>
      </header>

      {isMobile && 
        <nav className={`zl__mobile-nav${menuOpened ? ` opened` : ``}`}>
          <button type="button" className={`zl__burger opened`} onClick={() => setMenuOpened(false)}></button>
          <ul className={`zl__nav-menu caps`}>
            <li><a className="link" onClick={() => scrollToFunc(keysRef)}>Проекты</a></li>
            <li><a className="link" onClick={() => scrollToFunc(serviceRef)}>Trade-In</a></li>
            <li><a className="link" onClick={() => scrollToFunc(teamRef)}>команда</a></li>
            <li><a className="link" onClick={() => scrollToFunc(sertifRef)}>сертификаты</a></li>
            <li><a className="link" onClick={() => scrollToFunc(contactsRef)}>контакты</a></li>
          </ul>
          <button type="button" className="zl__btn button caps" onClick={() => {
            setMenuOpened(false);
            openModalWithComment();
          }}>
            Обратный звонок
          </button>

        </nav>
       }

      <main className="zl__main">
        <div className="zl__container">
          <section className="zl__promo">
            <span className="zl__label">пан загород</span>
            <h1 className="h1 zl__h1">Покупка и продажа загородной недвижимости</h1>
            <p className="big-text zl__sub">Дома, коттеджи, участки</p>

            <div className="zl__panel">
              <div className="zl__panel-label">Я хочу</div>
              <ul className="zl__panel-list">
                <li>
                  <button type="button" className="active" onClick={() => openModalWithComment('Купить')}>
                    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="zl__panel-icon">
                      <path d="M18.1408 16.4331V7.63627C18.1403 7.53835 18.1192 7.44162 18.079 7.35234C18.0388 7.26306 17.9803 7.1832 17.9073 7.11792L10.9026 0.749438C10.7737 0.63229 10.6057 0.567383 10.4315 0.567383C10.2573 0.567383 10.0894 0.63229 9.96044 0.749438L2.96387 7.11792C2.89088 7.1832 2.83239 7.26306 2.79217 7.35234C2.75195 7.44162 2.7309 7.53835 2.73038 7.63627V16.4331" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M0.628906 16.4331H20.2422" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12.5369 16.4329V11.5296C12.5369 11.3438 12.4631 11.1656 12.3317 11.0343C12.2004 10.9029 12.0222 10.8291 11.8364 10.8291H9.03452C8.84874 10.8291 8.67057 10.9029 8.53921 11.0343C8.40785 11.1656 8.33405 11.3438 8.33405 11.5296V16.4329" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Купить
                  </button>
                  </li>
                <li>
                  <button type="button" onClick={() => openModalWithComment('Продать')}>
                    <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="zl__panel-icon">
                      <path d="M22.8604 1.125H2.1416C1.7015 1.125 1.34473 1.48177 1.34473 1.92188V13.0781C1.34473 13.5182 1.7015 13.875 2.1416 13.875H22.8604C23.3005 13.875 23.6572 13.5182 23.6572 13.0781V1.92188C23.6572 1.48177 23.3005 1.125 22.8604 1.125Z" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12.501 10.6875C14.2614 10.6875 15.6885 9.26041 15.6885 7.5C15.6885 5.73959 14.2614 4.3125 12.501 4.3125C10.7406 4.3125 9.31348 5.73959 9.31348 7.5C9.31348 9.26041 10.7406 10.6875 12.501 10.6875Z" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M17.2822 1.125L23.6572 6.70312" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M17.2822 13.875L23.6572 8.29688" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M7.71973 1.125L1.34473 6.70312" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M7.71973 13.875L1.34473 8.29688" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Продать
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() =>  openModalWithComment('Обменять')}>
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="zl__panel-icon">
                      <path d="M5.63632 14.1364H20.1818C20.4229 14.1364 20.6541 14.0406 20.8246 13.8701C20.9951 13.6996 21.0909 13.4684 21.0909 13.2273V1.40909C21.0909 1.16799 20.9951 0.936754 20.8246 0.766266C20.6541 0.595779 20.4229 0.5 20.1818 0.5H7.4545C7.2134 0.5 6.98217 0.595779 6.81168 0.766266C6.64119 0.936754 6.54541 1.16799 6.54541 1.40909V2.31818" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M8.36359 11.4077L5.63632 14.135L8.36359 16.8623" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M16.5454 6.86377H1.99991C1.75881 6.86377 1.52757 6.95955 1.35709 7.13004C1.1866 7.30052 1.09082 7.53175 1.09082 7.77286V19.591C1.09082 19.8321 1.1866 20.0634 1.35709 20.2339C1.52757 20.4044 1.75881 20.5001 1.99991 20.5001H14.7272C14.9683 20.5001 15.1995 20.4044 15.37 20.2339C15.5405 20.0634 15.6363 19.8321 15.6363 19.591V18.682" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M13.8181 9.58931L16.5454 6.86204L13.8181 4.13477" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Обменять
                  </button>
                </li>
              </ul>
            </div>
          </section>

          <section className="zl__descr zl__ml">
            <h2 className="zl__descr-title zl__title">Высокое качество и&nbsp;профессиональный сервис</h2>
            <p className="zl__descr-text big-text">Оказываем полный перечень услуг по сделкам с загородной недвижимостью</p>
          </section>

          <section className="zl__block">
            <img src={require('../../assets/images/zl/house-block.jpg').default} alt="" className="zl__block-img" />
            <div className="zl__block-box">
              <span className="zl__block-label caps">База коттеджных поселков</span>
              <h2 className="h2 zl__block-title">В продаже строящиеся и готовые коттеджные поселки</h2>
              <p className="zl__block-text big-text">
                Развиваем собственную базу загородной невдижимости с актуальной информацией о коттеджных поселках,
                и предлагаем к покупке только проверенные нами объекты.
              </p>

              <div className="zl__block-anchors">
                <a href="https://panpartner.ru/public/suburbans/39080cc831c8c24a25a7ad209aaa2a83/" target="_blank" className="zl__block-btn zl__btn button">Смотреть объекты</a>
              </div>
            </div>
          </section>
        </div>

        <div className="zl__container">
          <section className="zl__houses" ref={keysRef}>
            <div className="zl__houses-box">
              <div className="zl__houses-content">

                <h2 className="h2 zl__houses-title">Загородные участки</h2>
                <p className="zl__houses-text text">
                  Продадим вашу загородную недвижимость или поможем приобрести новую. Мы не тратим время и работаем максимально быстро,
                  чтобы провести сделку в кратчайшие сроки, а также предоставляем еженедельные отчеты о проделанной работе.
                </p>
                
                <a href="http://panpartner.ru/public/suburbans/a5ab62ad70d0abd27171a69dab05f078/" target="_blank" className="zl__houses-btn zl__btn button">Подробнее</a>
              </div>
            </div>

            <div className="zl__houses-slider zl__houses-slider_slider-1">
              <div>
                <Swiper
                  navigation= {{
                    nextEl: '.zl__houses-slider_slider-1 .next-slide',
                  }}

                  spaceBetween={0}
                  loop={true}
                  breakpoints={{
                    1024: {
                      slidesPerView: 2,
                    },
                  }}
                  className="mySwiper">
                    
                  {slides.map((slide) => <SwiperSlide><Slide item={slide} /></SwiperSlide>)}
                  <button type="button" className="next-slide">
                    <img src={require('../../assets/images/zl/icons/slider-nav.svg').default} alt="" />
                  </button>
                </Swiper>
              </div>

            </div>
          </section>
        </div>

        <div className="zl__container">
          <section className="zl__build zl__ml">
            <h2 className="zl__build-title zl__title">Строительство домов&nbsp;по технологии фахверк</h2>
            <div className="zl__build-scheme">
              <img src={require('../../assets/images/zl/scheme.svg').default} alt="" className="zl__build-img" />

              <div className="zl__build-item zl__build-item_1">
                <div className="zl__build-box">
                  <img src={require('../../assets/images/zl/build-1.png').default} alt="" className="zl__build-pic" />
                  <p className="zl__build-text text">Прочная крыша из&nbsp;черепицы</p>
                  <span className="zl__build-line"></span>
                  <span className="zl__build-counter zl__counter">1</span>
                </div>
                <p className="zl__build-text text">Прочная крыша из&nbsp;черепицы</p>
              </div>

              <div className="zl__build-item zl__build-item_2">
                <div className="zl__build-box">
                  <img src={require('../../assets/images/zl/build-2.png').default} alt="" className="zl__build-pic" />
                  <span className="zl__build-line"></span>
                  <span className="zl__build-counter zl__counter">2</span>
                </div>
                <p className="zl__build-text text">Панорамное остекление. В доме всегда будет много света и тепла</p>
              </div>

              <div className="zl__build-item zl__build-item_3">
                <div className="zl__build-box">
                  <img src={require('../../assets/images/zl/build-3.png').default} alt="" className="zl__build-pic" />
                  <p className="zl__build-text text">Деревянные балки из клеенного бруса</p>
                  <span className="zl__build-line"></span>
                  <span className="zl__build-counter zl__counter">3</span>
                </div>
                <p className="zl__build-text text">Деревянные балки из клеенного бруса</p>
              </div>

              <div className="zl__build-item zl__build-item_4">
                <div className="zl__build-box">
                  <img src={require('../../assets/images/zl/build-4.png').default} alt="" className="zl__build-pic" />
                  <span className="zl__build-line"></span>
                  <span className="zl__build-counter zl__counter">4</span>
                </div>
                <p className="zl__build-text text">Строительный и отделочный материал: кирпич, дерево, камень, штукатурка</p>
              </div>
            </div>
          </section>
        </div>


        <div className="zl__container">
          <section className="zl__houses zl__houses_with-love">
            <div className="zl__houses-box">
              <div className="zl__houses-content">
                <h2 className="h2 zl__houses-title">Строим с любовью и вниманием к деталям</h2>
                <p className="zl__houses-text">
                  Для кого-то строительство собственного тёплого и уютного дома является мечтой.
                  Мечтой, которая может затянуться на многие годы, если строишь самостоятельно.
                  Мы идем в ногу со временем и строим современные, уютные и красивые дома по технологии фахверк.
                </p>
                <p className="zl__houses-text">
                  Это способ строительства домов, состоящий из вертикальных и наклонных балок, которые удерживают крышу.
                  После возведения каркаса свободные пространства заполняются строительными материалами,
                  при этом сами балки остаются снаружи, что придает дому уникальный и по-своему красивый образ.
                </p>
                <button type="button" className="zl__houses-btn zl__btn button" onClick={()=>openModalWithComment()}>Заказать расчет</button>
              </div>
            </div>

            <div className="zl__houses-slider zl__houses-slider_with-love">
              <Swiper
                navigation= {{
                  nextEl: '.next-slide',
                }}
                spaceBetween={0}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                  },
                  1280: {
                    slidesPerView: 1,
                  },
                }}
                className="mySwiper">
                  {slidesLove.map((slide) => {
                    return (
                      <SwiperSlide>
                        <div className="zl__houses-slide">
                          <img src={require(`../../assets/images/zl/love-${slide.id}.jpg`).default} alt="" />
                        </div>
                      </SwiperSlide>
                    )
                  })}
                  <button type="button" className="next-slide">
                    <img src={require('../../assets/images/zl/icons/slider-nav.svg').default} alt="" />
                  </button>
              </Swiper>

            </div>
          </section>
        </div>
        
        <div className="zl__container">

          <section className="zl__tradein zl__ml zl__build zl__tradein_bg">
            <h2 className="zl__tradein-title zl__tradein-title_width zl__title">Загородный Trade-in</h2>
            <p className="zl__tradein-sub big-text" ref={serviceRef}>
              Обменяем городскую квартиру на просторный загородный дом.
              Мы выставляем вашу квартиру на продажу и одновременно занимаемся поиском нового загородного жилья.
              Как только продажа завершится, вы сразу же переезжаете в новый дом.
            </p>


            {

            !isMobile &&
            <div className="zl__tradein-list">
              <div className="zl__tradein-item zl__tradein-item_1">
                <span className="zl__tradein-counter zl__counter">1</span>
                <img src={require('../../assets/images/zl/trade-1.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Мы подготавливаем вашу квартиру к продаже, делаем фотографии и размещаем объявления
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_2">
                <span className="zl__tradein-counter zl__counter">2</span>
                <img src={require('../../assets/images/zl/trade-2.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  После размещения объявления сразу же приступаем к поиску загордного дома.
                  Вместе ходим на просмотр и выбираем понравившийся дом или участок
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_3">
                <span className="zl__tradein-counter zl__counter">3</span>
                <img src={require('../../assets/images/zl/trade-3.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Вы вносите первый платеж (как правило это 5% от стоимости выбранного дома)
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_4">
                <span className="zl__tradein-counter zl__counter">4</span>
                <img src={require('../../assets/images/zl/trade-4.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Фиксируем договором стоимость выбранного дома на срок от 3 до 6 месяцев
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_5">
                <span className="zl__tradein-counter zl__counter">5</span>
                <img src={require('../../assets/images/zl/trade-5.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Как только мы продадим вашу квартиру, вы получаете деньги и мы вносим платеж на загородный дом, оформляем все сопутствующие документы
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_6">
                <span className="zl__tradein-counter zl__counter">6</span>
                <img src={require('../../assets/images/zl/trade-6.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Поздравляем! Теперь вы обладатель просторного дома загородом.
                </p>
              </div>

            </div>
            }

            {
              isMobile &&
              <div className="zl__tradein-list">
              <Swiper
                pagination={{"clickable": true}}
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={30}
                autoHeight={true}
                className="mySwiper">
                  <SwiperSlide>
                    <div className="zl__tradein-item zl__tradein-item_1">
                      <span className="zl__tradein-counter zl__counter">1</span>
                      <img src={require('../../assets/images/zl/trade-1.svg').default} alt="" className="zl__tradein-img" />
                      <p className="zl__tradein-text text">
                        Мы подготавливаем вашу квартиру к продаже, делаем фотографии и размещаем объявления
                      </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="zl__tradein-item zl__tradein-item_2">
                      <span className="zl__tradein-counter zl__counter">2</span>
                      <img src={require('../../assets/images/zl/trade-2.svg').default} alt="" className="zl__tradein-img" />
                      <p className="zl__tradein-text text">
                        После размещения объявления сразу же приступаем к поиску загордного дома.
                        Вместе ходим на просмотр и выбираем понравившийся дом или участок
                      </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="zl__tradein-item zl__tradein-item_3">
                      <span className="zl__tradein-counter zl__counter">3</span>
                      <img src={require('../../assets/images/zl/trade-3.svg').default} alt="" className="zl__tradein-img" />
                      <p className="zl__tradein-text text">
                        Вы вносите первый платеж (как правило это 5% от стоимости выбранного дома)
                      </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="zl__tradein-item zl__tradein-item_4">
                      <span className="zl__tradein-counter zl__counter">4</span>
                      <img src={require('../../assets/images/zl/trade-4.svg').default} alt="" className="zl__tradein-img" />
                      <p className="zl__tradein-text text">
                        Фиксируем договором стоимость выбранного дома на срок от 3 до 6 месяцев
                      </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="zl__tradein-item zl__tradein-item_5">
                      <span className="zl__tradein-counter zl__counter">5</span>
                      <img src={require('../../assets/images/zl/trade-5.svg').default} alt="" className="zl__tradein-img" />
                      <p className="zl__tradein-text text">
                        Как только мы продадим вашу квартиру, вы получаете деньги и мы вносим платеж на загородный дом, оформляем все сопутствующие документы
                      </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="zl__tradein-item zl__tradein-item_6">
                      <span className="zl__tradein-counter zl__counter">6</span>
                      <img src={require('../../assets/images/zl/trade-6.svg').default} alt="" className="zl__tradein-img" />
                      <p className="zl__tradein-text text">
                        Поздравляем! Теперь вы обладатель просторного дома загородом.
                      </p>
                    </div>
                  </SwiperSlide>
              </Swiper>
              </div>
            }
          </section>
        </div>

        <div className="zl__wrapper">
          <section className="zl__about">
            <div className="zl__about-box zl__houses">

              <div className="zl__about-left zl__houses-box">
                <div className="zl__houses-content">

                  <h2 className="h2 zl__about-title">Мы создаем лучший сервис в сфере недвижимости в России</h2>

                  <div className="zl__about-list">
                    <div className="zl__about-item">
                      <span className="zl__about-value h2">{`> 11 лет`}</span>
                      <span className="zl__about-descr text">На рынке недвижимости</span>
                    </div>
                    <div className="zl__about-item">
                      <span className="zl__about-value h2">110+</span>
                      <span className="zl__about-descr text">Сотрудников в штате</span>
                    </div>

                    <div className="zl__about-item">
                      <span className="zl__about-value h2">35</span>
                      <span className="zl__about-descr text">Построенных домов</span>
                    </div>
                    <div className="zl__about-item">
                      <span className="zl__about-value h2">1754</span>
                      <span className="zl__about-descr text">Проведенных сделки с загородной недвижимостью</span>
                    </div>
                    {
                    !isLaptop &&
                      <div className="zl__about-anchors">
                        <a href="https://1-an.ru/company/" target="_blank" className="button zl__btn zl__about-btn">Подробнее о нас на 1-an.ru</a>
                        <div className="zl__about-socials">
                          <a href="https://www.instagram.com/pan_ndv/" className="zl__social" target="_blank">
                            <img src={require('../../assets/images/zl/icons/ig.svg').default} alt="" />
                          </a>
                          <a href="https://vk.com/pan_spb" className="zl__social" target="_blank">
                            <img src={require('../../assets/images/zl/icons/vk.svg').default} alt="" />
                          </a>
                        </div>
                      </div>
                    }
                 </div>
                </div>
              </div>

              <div className="zl__about-right">
                <div className="zl__about-checklist">

                  <div className="zl__about-checkitem">
                    <h4 className="h4 zl__about-h4">
                      Отчеты о работе
                    </h4>
                    <p className="zl__about-text text">
                      Мы еженедельно информируем вас о продвижении рекламного объявления, подводим итоги и оптимизируем план продаж.
                    </p>
                  </div>

                  <div className="zl__about-checkitem">
                    <h4 className="h4 zl__about-h4">
                      Честная цена
                    </h4>
                    <p className="zl__about-text text">
                      Мы обговариваем все детали и рассказываем про всю схему сделки.
                      Итоговая стоимость наших услуг зафиксирована в договоре и не может быть изменена.
                    </p>
                  </div>

                  <div className="zl__about-checkitem">
                    <h4 className="h4 zl__about-h4">
                      Юридическое сопровождение
                    </h4>
                    <p className="zl__about-text text">
                      Чтобы выявить “подводные камни”, каждый объект проверяет юрист.
                      Также, он помогает с составлением документов и консультирует по ходу сделки.
                    </p>
                  </div>

                  <div className="zl__about-checkitem">
                    <h4 className="h4 zl__about-h4">
                      Компания №1
                    </h4>
                    <p className="zl__about-text text">
                      Выбирая нас, вы получаете профессиональный сервис, команду специалистов и гарантию качественной сделки.
                      Нас рекомендуют друзьям и знакомым, а с некоторыми клиентами мы успешно сотрудничаем на протяжении нескольких лет.
                    </p>
                  </div>

                </div>
              </div>

              {
                isLaptop &&
                  <div className="zl__about-anchors">
                    <a href="https://1-an.ru/company/" target="_blank" className="button zl__btn zl__about-btn">Подробнее о нас на 1-an.ru</a>
                    <div className="zl__about-socials">
                      <a href="https://www.instagram.com/pan_ndv/" className="zl__social" target="_blank">
                        <img src={require('../../assets/images/zl/icons/ig.svg').default} alt="" />
                      </a>
                      <a href="https://vk.com/pan_spb" className="zl__social" target="_blank">
                        <img src={require('../../assets/images/zl/icons/vk.svg').default} alt="" />
                      </a>
                    </div>
                  </div>
                }
            </div>
          </section>


        </div>
        
        <div className="wrapper">
          <div className="wrapper__center">
            <section className="zl__manager">
              <img src={require('../../assets/images/zl/manager.jpg').default} alt="" className="zl__manager-avatar" />
              <p className="zl__manager-text big-text">
                «Мы специализируемся на загородной недвижимости и продаем только проверенные лично нами объекты.
                Нас рекомендуют друзьям, коллегам и знакомым как надежного партнера, которому можно доверять.
                Я обещаю, что после первой встречи вы убедитесь в этом сами и даю гарантию надежной и честной сделки»
              </p>

              <span className="zl__manager-name zl__h4">Ольга Арсланова,</span>
              <p className="zl__manager-position big-text">Директор департамента вторичного жилья ПАН</p>
            </section>
          </div>
        </div>


        <div className="zl__container">

        
          <section className="zl__tradein  zl__ml zl__build">
            <h2 className="zl__tradein-title zl__title">Схема работы с нами</h2>
            {
            !isMobile &&
            <div className="zl__tradein-list zl__tradein-list_with-5">
              <div className="zl__tradein-item zl__tradein-item_1">
                <span className="zl__tradein-counter zl__counter">1</span>
                <img src={require('../../assets/images/zl/work-1.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Вы оставляете заявку на сайте или звоните нам по телефону: <a href="tel:+78124458587" className="link zl__link nowrap">8 (812) 445-85-87</a>
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_2">
                <span className="zl__tradein-counter zl__counter">2</span>
                <img src={require('../../assets/images/zl/work-2.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Мы вникаем в вашу задачу, предлагаем решения и договариваемся о встрече
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_3">
                <span className="zl__tradein-counter zl__counter">3</span>
                <img src={require('../../assets/images/zl/work-3.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                На встрече подробно обсуждаем все нюансы, фиксируем стоимость услуг и подписываем договор
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_empty"></div>

              <div className="zl__tradein-item zl__tradein-item_4">
                <span className="zl__tradein-counter zl__counter">4</span>
                <img src={require('../../assets/images/zl/work-4.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  В зависимости от вашей задачи, продаем или покупаем объект недвижимости,
                  обмениваем квартиру по загородному трейд-ину или приступаем к строительству дома
                </p>
              </div>

              <div className="zl__tradein-item zl__tradein-item_5">
                <span className="zl__tradein-counter zl__counter">5</span>
                <img src={require('../../assets/images/zl/work-5.svg').default} alt="" className="zl__tradein-img" />
                <p className="zl__tradein-text text">
                  Завершаем сделку и вы получаете итоговый результат!
                </p>
              </div>

            </div>
            }

            {
            isMobile &&
              <div className="zl__tradein-list zl__tradein-list_with-5">
                <Swiper
                  pagination={{"clickable": true}}
                  slidesPerView={'auto'}
                  centeredSlides={true}
                  spaceBetween={30}
                  autoHeight={true}
                  className="mySwiper">
                    
                <SwiperSlide>
                <div className="zl__tradein-item zl__tradein-item_1">
                  <span className="zl__tradein-counter zl__counter">1</span>
                  <img src={require('../../assets/images/zl/work-1.svg').default} alt="" className="zl__tradein-img" />
                  <p className="zl__tradein-text text">
                    Вы оставляете заявку на сайте или звоните нам по телефону: <a href="tel:+78124458587" className="link zl__link nowrap">8 (812) 445-85-87</a>
                  </p>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="zl__tradein-item zl__tradein-item_2">
                  <span className="zl__tradein-counter zl__counter">2</span>
                  <img src={require('../../assets/images/zl/work-2.svg').default} alt="" className="zl__tradein-img" />
                  <p className="zl__tradein-text text">
                    Мы вникаем в вашу задачу, предлагаем решения и договариваемся о встрече
                  </p>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="zl__tradein-item zl__tradein-item_3">
                  <span className="zl__tradein-counter zl__counter">3</span>
                  <img src={require('../../assets/images/zl/work-3.svg').default} alt="" className="zl__tradein-img" />
                  <p className="zl__tradein-text text">
                  На встрече подробно обсуждаем все нюансы, фиксируем стоимость услуг и подписываем договор
                  </p>
                </div>
                </SwiperSlide>
                <div className="zl__tradein-item zl__tradein-item_empty"></div>
                <SwiperSlide>
                <div className="zl__tradein-item zl__tradein-item_4">
                  <span className="zl__tradein-counter zl__counter">4</span>
                  <img src={require('../../assets/images/zl/work-4.svg').default} alt="" className="zl__tradein-img" />
                  <p className="zl__tradein-text text">
                    В зависимости от вашей задачи, продаем или покупаем объект недвижимости,
                    обмениваем квартиру по загородному трейд-ину или приступаем к строительству дома
                  </p>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="zl__tradein-item zl__tradein-item_5">
                  <span className="zl__tradein-counter zl__counter">5</span>
                  <img src={require('../../assets/images/zl/work-5.svg').default} alt="" className="zl__tradein-img" />
                  <p className="zl__tradein-text text">
                    Завершаем сделку и вы получаете итоговый результат!
                  </p>
                </div>
                </SwiperSlide>
              </Swiper>
              </div>
            }



          </section>
        </div>


        <div className="zl__container">
          <section className="zl__team" ref={teamRef}>
            <div className="zl__team-block">
              <h2 className="h2 zl__team-h2">Наша команда</h2>
              <div className="zl__team-nav">
              <button type="button" className="prev-slide">
                <img src={require('../../assets/images/zl/icons/slider-nav.svg').default} alt="" />
              </button>
              <button type="button" className="next-slide">
                <img src={require('../../assets/images/zl/icons/slider-nav.svg').default} alt="" />
              </button>
              </div>
            </div>
            
            <div className="zl__team-slider">
              <Swiper
                  navigation= {{
                    nextEl: '.zl__team-nav .next-slide',
                    prevEl: '.zl__team-nav .prev-slide',
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                  }}
                  spaceBetween={0}
                  loop={true}
                  className="mySwiper">
                    {
                      team.map((member) => (
                        <SwiperSlide>
                          <div className={`zl__team-item${member.withGradient ? ` zl__team-item_with-gradient` : ``}`}>
                            <img src={require(`../../assets/images/zl/team/${member.id}.jpg`).default} alt="" className={`${member.setSize ? `zl__team-img` : ``}`} />
                            <div className="zl__team-content">
                              <span className="zl__team-name zl__h4">{member.name}</span>
                              <p className="zl__team-position text">{member.pos}</p>
                              <span className="zl__exp">{member.exp}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    }
                </Swiper>

            </div>
          </section>
        </div>


        <div className="zl__container">
          <section className="zl__houses zl__houses_diplomas" ref={sertifRef}>

            <div className="zl__houses-slider">
              <Swiper
                    navigation= {{
                      nextEl: '.zl__houses_diplomas .next-slide',
                    }}
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    className="mySwiper">
                      {
                        diplomas.map((di) => (
                          <SwiperSlide>
                            <div className="zl__houses-slide">
                              <img src={require(`../../assets/images/zl/diplomas/${di.id}.jpg`).default} alt="" />
                            </div>
                          </SwiperSlide>
                        ))
                      }

                  </Swiper>
            </div>

            <div className="zl__houses-right">
              <div className="zl__block-box">
                <span className="zl__houses-sup zl__block-label caps">Мы можем подтвердить свой статус</span>
                <h2 className="h2 zl__houses-title zl__block-title">Грамоты, Дипломы и&nbsp;Сертификаты</h2>
                <p className="zl__houses-text zl__block-text big-text">
                  Компания ПАН является сертифицированным партнером многих застройщиков и банков,
                  а наши специалисты из года в год подтверждают свой статус профессионала,
                  проходя сертификацию в Национальной Палате Недвижимости
                </p>
                <button type="button" className="next-slide">
                  <img src={require('../../assets/images/zl/icons/slider-nav.svg').default} alt="" />
                </button>
              </div>
            </div>


          </section>
        </div>


        <div className="zl__container">

          <section className="zl__reviews zl__ml zl__build">
            <h2 className="zl__reviews-title zl__title">Отзывы</h2>
            <div className="zl__reviews-widget">
              <a class="flamp-widget" href="//spb.flamp.ru/firm/pervoe_agentstvo_nedvizhimosti_ooo-5348553838509440"  data-flamp-widget-type="responsive-new" data-flamp-widget-id="5348553838509440" data-flamp-widget-width="100%" data-flamp-widget-count="1">Отзывы о нас на Флампе</a>
            </div>
          </section>
        </div>

      </main>

      <footer className="zl__footer" ref={contactsRef}>
        <div className="zl__footer-block zl__footer-contacts">
          <div className="zl__houses-content">
            <p className="zl__adress zl__h4">
              Санкт-Петербург, Владимирский проспект 23, лит. А, 7 этаж, офис 701, <span className="nowrap">БЦ “Renaissance Hall”</span>
            </p>

            <p className="zl__footer-item">
              <a href="tel:+78124458587" className="zl__footer-anchor big-text">+7 (812) 445-85-87</a>
            </p>

            <p className="zl__footer-item">
              <a href="mailto:info@1-an.ru" className="zl__footer-anchor big-text">info@1-an.ru</a>
            </p>

            <p className="zl__footer-item zl__footer-days big-text">
              Пн.—Пт.: 09—21<br />
              Сб—Вс.: 11—19
            </p>
          </div>
        </div>

        <div className="zl__footer-block zl__map">
          {Maps && <Maps.YMaps>
            <Maps.Map
              defaultState={{
                center: PAN_COORDINATES,
                zoom: 19,
              }}
              modules={['templateLayoutFactory', 'Template', "Placemark"]}
              onLoad={setYmaps}
              instanceRef={setMapInstance}
              className="complex__map-wrap"
            >
            </Maps.Map>
          </Maps.YMaps>}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7996.996751932515!2d30.3458607!3d59.9280072!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7ff7253ec9353578!2z0JHRg9C60LLQvtC10LQ!5e0!3m2!1sru!2sru!4v1633603939260!5m2!1sru!2sru" width="100%" height="518" allowfullscreen="" loading="lazy"></iframe> */}
          {/* <img src={require(`../../assets/images/zl/map.png`).default} alt="" className="zl__map-img" /> */}
          {/* <img src={require('../../assets/images/zl/icons/pin.svg').default} alt="" className="zl__map-pin" /> */}
        </div>
      </footer>


    </section>
  );
};

export default ZagorodLanding;