import React, { memo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useMedia from "../../hooks/useMedia";
import Tag from "../Public/markup/Tag";

const nonExcludableDef = [
  "assignments",
  "availability",
  "isNoFirstFloor",
  "isFirstFloor",
  "isApartments",
  "encumbrances",
  "endings",
  "balconyType",
  "issuingKeys",
  "cellHeight",
  // "realtyType"
  "hasTerrace",
  "hasKitchenLiving",
  "hasPanoramicWindows",
  "hasWardrobe",
  "hasSecondLight",
  "hasAtticFloor",
  "hasSauna"
];
const dashedWrapperStyle ={
  padding: "11px 16px",
  borderRadius: 20,
  alignItems: "center",
  gap: 10
}

const DraggableZone = memo(props => {
  const {
    readonly,
    enableRemoving = true,
    clearFilter,
    clearExclude,
    nonExcludable = nonExcludableDef,
    filterBadges,
    excludeBadges,
    switchValues,
    removeBadge

  } = props;
  const isMobile = useMedia("isMobile")
  const isFilterEmpty = filterBadges?.length === 0
  const isExcludeEmpty = excludeBadges?.length === 0

  const showFilterClearCond = (!readonly || enableRemoving) && !isFilterEmpty
  const showExcludeClearCond = !readonly && !isExcludeEmpty
  const showFilter = !isFilterEmpty || !readonly
  const showExclude = !isExcludeEmpty || !readonly
  const showExcludeText = !readonly && !isMobile && isExcludeEmpty;
  const showFilterText = !readonly && !isMobile && isFilterEmpty;

  const onDragEnd = ({ source, destination, draggableId }) => {
    const returnCond = !destination ||
      source.droppableId === destination.droppableId;
    if (returnCond) return;
    const parsed = JSON.parse(draggableId)
    switchValues(destination.droppableId, parsed)
  }
  if (isFilterEmpty && isExcludeEmpty) return null

  return <div>
    {readonly && <div className="index-search__filter-applied for-dragable">
      {showFilter && <h4 className="index-search__filter-params">Параметры</h4>}
      {showExclude && <div style={{ padding: "0 0 12px 16px", flex: 1 }}>
        <h4>Исключения</h4>
      </div>}
    </div>}
    <ul className={`index-search__filter-applied sf__tags for-dragable ${readonly ? " readonly" : ""}`}>
      <DragDropContext onDragEnd={onDragEnd}>
        {showFilter && <Droppable droppableId="filter">
          {({ innerRef, placeholder }) => <div
            ref={innerRef}
            className={`index-search__filter-inc ${readonly ? "clear-after" : ""}`}
            style={dashedWrapperStyle}
          >
            {filterBadges.map(({ id, ident, subKey, ...rest }, i) => <Draggable
              key={`${ident}-${id}-${i}`}
              draggableId={JSON.stringify({ id, ident, subKey })}
              index={i}
              isDragDisabled={nonExcludable.includes(ident) || readonly}
            >
              {provided => <Tag
                key={`${ident}-${id}`}
                removeBadge={() => !readonly && enableRemoving && removeBadge(ident, id, subKey)}
                {...rest}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                innerRef={provided.innerRef}
                className="sf__tag sf__tag-filter"
                id={id}
              />}
            </Draggable>)}
            {showFilterClearCond && <span onClick={clearFilter} className="index-search__clear-link">Очистить</span>}
            {showFilterText && <p class="index-search__filter-tip">
              Вы можете перенести сюда параметры для поиска
            </p>}
            {placeholder}
          </div>
          }
        </Droppable>
        }
        {showExclude && <Droppable droppableId="exclude">
          {provided => <div
            ref={provided.innerRef}
            className="index-search__filter-exc"
            style={dashedWrapperStyle}
          >
            {excludeBadges.map(({ id, ident, subKey, ...rest }, i) => <Draggable
              key={`${ident}-${id}-${i}`}
              draggableId={JSON.stringify({ id, ident, subKey })}
              index={i}
              isDragDisabled={nonExcludable.includes(ident) || readonly}
            >
              {provided => <Tag
                key={`${ident}-${id}`}
                removeBadge={() => !readonly && enableRemoving && removeBadge(ident, id, subKey, "exclude")}
                {...rest}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                innerRef={provided.innerRef}
                className="sf__tag sf__tag-exclude"
                id={id}
              />}
            </Draggable>)}
            {showExcludeClearCond && <span onClick={clearExclude} className="index-search__clear-link">Очистить</span>}
            {showExcludeText && <p class="index-search__filter-tip">
              Вы можете перенести сюда параметры для исключения
            </p>}
            {provided.placeholder}
          </div>}
        </Droppable>}
      </DragDropContext>
    </ul>
  </div>
})

export default DraggableZone;
