import React, { memo, Suspense, useState } from 'react';
import { ModalProvider } from 'react-modal-hook';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { YMInitializer } from "react-yandex-metrika";
import Constructor from '../pages/Constructor';
import ConstructorCard from '../pages/ConstructorCard';
import ConstructorContacts from '../pages/ConstructorContacts';
import ConstructorLid from '../pages/ConstructorLid';
import ConstructorMortgage from '../pages/ConstructorMortgage';
import ConstructorObject from '../pages/ConstructorObject';
import ConstructorReviews from '../pages/ConstructorReviews';
import NotFoundPage from '../pages/NotFoundPage';
import AppRoute from './AppRoute';
import ConstructorLayout from './layout/ConstructorLayout';
import EmptyLayout from './layout/EmptyLayout';
import Preloader from './Preloader';
import ScrollRouter from './ScrollRouter';


import Dashboard from './layout/Dashboard';


import Public from './Public';
import PublicAssignments from './PublicAssignment';
import PublicCommercial from './PublicCommercial';
import PublicSecondary from './PublicSecondary';
import Suburban from './Suburban';
import Land from './SuburbanLand';
import Suburbans from './Suburbans';
import ConstructorCardSuburban from "../pages/ConstructorCardSuburban";

const AppConstructor = memo(({ yandexMetric }) => {
  const [fullScreenLoading, setFullScreenLoading] = useState(!window.location.pathname.includes("public"))
  return (
    <ModalProvider>
      <Switch>
        <Suspense fallback={<Preloader loading={true} />}>
          <ScrollRouter />
          <AppRoute
            exact
            path="/"
            layout={ConstructorLayout}
            component={Constructor}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
            layoutProps={{ isMainPage: true }}
          />
          <AppRoute
            exact
            path="/complexes/:city/:id"
            layout={ConstructorLayout}
            component={ConstructorCard}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            exact
            path="/suburbans/:city/:id"
            layout={ConstructorLayout}
            component={ConstructorCardSuburban}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            exact
            path="/object/:selfObjectId"
            layout={ConstructorLayout}
            component={ConstructorObject}
            mobileVersionEnabled
            isPublic
            isLoading={fullScreenLoading}
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            layout={EmptyLayout}
            path="/not-found"
            exact
            component={NotFoundPage}
            isPublic
            is404={true}
            mobileVersionEnabled
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            exact
            path="/reviews"
            layout={ConstructorLayout}
            component={ConstructorReviews}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            exact
            path="/contacts"
            layout={ConstructorLayout}
            component={ConstructorContacts}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            exact
            path="/ipoteka"
            layout={ConstructorLayout}
            component={ConstructorMortgage}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
          />
          <AppRoute
            exact
            path="/lead-form"
            layout={ConstructorLayout}
            component={ConstructorLid}
            layoutProps={{ isEmpty: true }}
            mobileVersionEnabled
            isPublic
            setFullScreenLoading={setFullScreenLoading}
          />


          <AppRoute
            path="/public/apartments/:savedFilterXmlId/"
            auth={false}
            layout={Dashboard}
            component={Public}
            isPublic
            hideHeader
            hideFooter
            layoutProps={{
              disableTitle: true
            }}
          />
          <AppRoute
            path="/public/assignments/:savedFilterXmlId/"
            auth={false}
            layout={Dashboard}
            component={PublicAssignments}
            isPublic
            hideHeader
            hideFooter
            layoutProps={{
              disableTitle: true
            }}
          />
          <AppRoute
            path="/public/commercials/:savedFilterXmlId/"
            auth={false}
            layout={Dashboard}
            component={PublicCommercial}
            isPublic
            hideHeader
            hideFooter
            layoutProps={{
              disableTitle: true
            }}
          />
          <AppRoute
            path="/public/secondary/:savedFilterXmlId/"
            auth={false}
            layout={Dashboard}
            component={PublicSecondary}
            isPublic
            hideHeader
            hideFooter
            layoutProps={{
              disableTitle: true
            }}
          />
          <AppRoute
            path="/public/suburbans/:savedFilterXmlId/"
            auth={false}
            layout={Dashboard}
            component={Suburbans}
            isPublic
            hideHeader
            hideFooter
            showPublicHeader
            layoutProps={{
              showPublicHeader: true,
              filterType: "suburbans",
              reducer: "suburbans",
              disableTitle: true
            }}
          />

          <AppRoute
            path="/public/suburban/:savedFilterXmlId/:city/:id"
            auth={false}
            layout={Dashboard}
            component={Suburban}
            isPublic
            hideHeader
            hideFooter
            layoutProps={{
              showPublicHeader: true,
              filterType: "suburbans",
              reducer: "suburban",
              disableTitle: true
            }}
          />

          <AppRoute
            exact
            path="/public/lands/:savedFilterXmlId/:city/:id"
            auth={false}
            layout={Dashboard}
            component={Land}
            isPublic
            hideHeader
            hideFooter
            showPublicHeader
            layoutProps={{
              showPublicHeader: true,
              filterType: "suburbans",
              reducer: "suburbanLand",
              disableTitle: true
            }}
          />
          <Route
            exact
            path={`/public/:savedFilterXmlId/:city/apartment/:id`}
            render={(props) => (
              <Redirect
                to={`/public/apartments/${props.match.params.savedFilterXmlId}/${props.match.params.city}/apartment/${props.match.params.id}`}
              />
            )}
          />
          <Route
            exact
            path={`/public/:savedFilterXmlId/:city/complex/:id`}
            render={(props) => (
              <Redirect
                to={`/public/apartments/${props.match.params.savedFilterXmlId}/${props.match.params.city}/complex/${props.match.params.id}`}
              />
            )}
          />

          <Route
            exact
            path="/public/:savedFilterXmlId/"
            render={(props) => (
              <Redirect
                to={`/public/apartments/${props.match.params.savedFilterXmlId}`}
              />
            )}
          />
        </Suspense>
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        transition={Slide}
      />
      <Preloader loading={fullScreenLoading} />
      {yandexMetric && (
        <YMInitializer
          accounts={[yandexMetric]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: false,
          }}
        />
      )}
    </ModalProvider>
  );
});

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  clientMode: state.general.clientMode,
  user: state.user.isAuthorized,
  acceptOffers: state.user.acceptOffers,
  access: state.user.access,
  yandexMetric: state.agency.yandexMetric,
});

export default connect(mapStateToProps)(AppConstructor);
