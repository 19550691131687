import React from "react";
import AssignmentsTopFilters from "./AssignmentsTopFilters";
import AssignmentsBottomFilters from "./AssignmentsBottomFilters";

const AssignmentsMapFilters = props => <div className="primary-map-filter">
  <AssignmentsTopFilters {...props} />
  <AssignmentsBottomFilters {...props} />
</div>

export default AssignmentsMapFilters
