import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import useDropdown from "../../../hooks/useDropdown";

const Item = (props) => {
  const { identity, id, title, checked = false, onChange } = props;

  const handleSelect = (value) => {
    onChange(value);
  };


  return (
    <div className="checkbox-group__item">
      <div className={"radio"}>
        <div className="radio__box">
          <input
            className="radio__control"
            type="radio"
            id={`${identity}-${id}`}
            name={identity}
            checked={checked}
            value={id}
            onChange={(e) => handleSelect(e.target.value)}
          />
          <label className="radio__input" htmlFor={`${identity}-${id}`}></label>
          <div className="radio__marker"></div>
        </div>
        <label className="radio__label" htmlFor={`${identity}-${id}`}>
          {title}
        </label>
      </div>
    </div>
  );
};

const RadioDropdown = (props) => {
  const {
    isAutocomplete = false,
    data,
    items,
    value,
    onChange,
    alternativeTitle,
    right,
    isMobile,
    noSubmit,
    wrapperClass,
  } = props;

  const [node, open, show, hide] = useDropdown(false);

  const [search, setSearch] = useState("");
  const [title, setTitle] = useState(data.title);
  const [filterable, setFilterable] = useState(items || []);

  const handleChange = (id) => {
    onChange(id);
  };

  useEffect(() => {
    if (alternativeTitle) {
      setTitle(alternativeTitle);
      return;
    }
    let tmpValue = value !== "unset" && items?.find((item) => item.id === value);
    setTitle(tmpValue ? tmpValue.title : data.title);
  });

  useEffect(() => {
    setFilterable(
      search
        ? items.filter((item) => item.title.toLowerCase().includes(search))
        : items
    );
  }, [search, items]);


  const clearFilters = (e) => {
    e.preventDefault()
    if(props.getCount && typeof props.getCount === "function"){
      props.getCount({
        ...props.fullFilter,
        filter: props.defFullFilter
          ? props.defFullFilter.filter
          : props.defaultFilter,
      });
    }
    props.clearFilterOnly && props.clearFilterOnly()
    props.clearExcludeOnly && props.clearExcludeOnly()
  }

  return (
    <div className={`form__item ${wrapperClass ?? ""}`}>
      <div
        ref={node}
        className={"dropdown form__drop" + (open ? " is-showing" : "")}
      >
        <div
          className="dropdown__btn button form__drop-btn"
          onClick={(e) => (open ? hide() : show())}
        >
          <span className="form__drop-name">{title}</span>
          <svg className="icon icon_chevron">
            <use xlinkHref="#chevron"></use>
          </svg>
        </div>
        <div
          className={`dropdown__block ${
            right ? "dropdown__block_align_right" : ""
          }`}
        >
          <div className="dropdown__inner">
            <div className="form__drop-content">
              {isMobile &&
                <div className="mobile-filters__title">
                  <svg className="icon icon_arrow_up" onClick={(e) => hide()}>
                    <use xlinkHref="#arrow_up"></use>
                  </svg>
                  <h3>{data.title}</h3>
                </div>
              }
              <div className="filter">
                {isAutocomplete && (
                  <div className="filter__filter">
                    <label className="input input_type_default input_width_available js-filter-list-input">
                      <input
                        className="input__control"
                        type="text"
                        placeholder={data.autocompleteTitle}
                        onChange={(e) =>
                          setSearch(e.target.value.toLowerCase())
                        }
                      />
                    </label>
                  </div>
                )}
                <SimpleBar className="filter__checks">
                  <div className="checkbox-group">
                    {filterable.map((item) => (
                      <Item
                        {...item}
                        identity={data.identity}
                        checked={item.id == value}
                        onChange={handleChange}
                        key={"motivation-month" +item.id}
                      />
                    ))}
                  </div>
                </SimpleBar>

                {isMobile && !noSubmit &&
                  <div className="mobile-filters__submit">
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => clearFilters(e)}
                    >
                      Очистить
                    </button>
                    <button
                      className="button button__mobile-filters"
                      onClick={(e) => {
                        e.preventDefault()
                        hide()}
                      }
                    >
                      Применить
                    </button>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Item as Radio };
export default RadioDropdown;
