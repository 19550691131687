import formatter from "format-number";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import MapList from "../Assignation/MapList";
import Card from "../Cards";
import Maps from "../Estate/Maps";
import ViewRealty from '../Estate/ViewRealty';
import LoadMoreSpinner from "../LoadMoreSpinner";

const loadItems = (prevArray = [], page = 0) =>
  new Promise((resolve) => {
    setTimeout(() => {
      let newArray = [];
      if (prevArray.length > page * 10) {
        newArray = _.chunk(prevArray, 10)[page];
      }
      resolve(newArray);
    }, 300);
  });
const priceFormat = (price) => {
  return formatter({ integerSeparator: " ", suffix: " р." })(price);
};

const RowItem = (props) => {
  const [active, setActive] = useState(false);
  const { items } = props;
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setPage(0);
    setData(_.take(items, 10));
    setHasNextPage(items.length > 10);
  }, [items]);

  function handleLoadMore() {
    setLoading(true);
    let p = page + 1;
    loadItems(items, p).then((newArray) => {
      setData([...data, ...newArray]);
      setPage(p);
      setHasNextPage((p + 1) * 10 < items.length);
      setLoading(false);
    });
  }

  const infiniteRef = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    threshold: 50,
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  return (
    <div class="assignations-flats__row">
      <div
        className={`flats__row-table public-assignments-wrapper is-active ${
          active ? `is-active` : ``
        }`}
        style={{ transition: "none" }}
        ref={infiniteRef}
      >
        {data.map((item, i) => {
          return (
            <Card
              key={"commercial-" + i}
              currentTab="apartment"
              {...item}
              planSmall={{...item.plan}}
              builder={{ name: item.builder.name }}
              disableFavorites={true}
              alternativeLink={props.alternativeLink}
              block={{address: item.address, name: item.block.name}}
              ending={item.endingDate}
              isCommercial={true}
            />
          );
        })}
      </div>
      {loading && <LoadMoreSpinner loading={true} />}
    </div>
  );
};
let paintIsStarted = false;
const Items = (props) => {
  const { items, innerRef, features, mapItems, isAgent, clientMode, alternativeLink } = props;
  const [viewType, setViewType] = useState("list");
  const [isInitMap, setInitMap] = useState(false);
  const [ymaps, setYmaps] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState([59.9342802, 30.3350986]);
  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);


  const template = useMemo(() => {
    return ymaps
      ? ymaps.templateLayoutFactory.createClass(
          '<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}</div>',
        )
      : null;
  }, [ymaps]);

  const mapLoad = (ymaps) => {
    setYmaps(ymaps);
    setInitMap(true);
  };
  const filterByCluster = (items) => {
    if (selectedItems) {
      return _.intersectionBy(items, selectedItems, "id");
    }
    if (currentPoint && isClusterOrPoint === "point") {
      paintIsStarted = false;
      return _.intersectionBy(items, [currentPoint], "id");
    }
    if (currentCluster && isClusterOrPoint === "cluster") {
      paintIsStarted = false;
      return _.intersectionBy(items, currentCluster.features, "id");
    }
    return items;
  };
  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  return (
    <section
      className={`section ${
        viewType === "list" ? "" : "section_view_white section_view_top"
      }`}
      ref={innerRef}
    >
      <div class="assignations-flats assignations-flats_public">
        <div id="filter-results"></div>
        <div class="wrapper">
          <div class="assignations-flats__header">
            <div class="assignations-flats__title">
              <h2 class="h2">Найдено {items.length} объектов:</h2>
            </div>

            <ViewRealty
              view={viewType}
              onChange={setViewType}
            />

          </div>
        </div>
        {viewType === "list" && (
          <div class="section__cards">
            <div class="wrapper">
              <div class="assignations-flats__tables">
                {items.length > 0 &&
                  <RowItem
                    items ={items}
                    isAgent={isAgent}
                    clientMode={clientMode}
                    alternativeLink={alternativeLink}
                  />
                }
              </div>
            </div>
          </div>
        )}
        {(isInitMap || viewType === "map") && (
          <div
            className="section__map"
            style={{ display: viewType === "map" ? "block" : "none" }}
          >
            <div class="map">
              <MapList
                items={filterByCluster(mapItems)}
                setMapCenter={setMapCenterHandler}
                disableFavorites={true}
                alternativeLink={"commercials"}
                fromPage={"commercials"}
                favType={"commercial"}
              />
              <div class="map__container">
                <Maps
                  features={features}
                  mapItems={mapItems}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  setCluster={setCluster}
                  setPoint={setPoint}
                  setClusterOrPoint={setClusterOrPoint}
                  setInitMap={setInitMap}
                  paintIsStarted={paintIsStarted}
                  type="assignments"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Items;
