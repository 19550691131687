import { NotFoundIcon } from "../icons";

const NotFound = ({forTable}) => (
  <div className="sf__notfound" style={forTable && {margin: '15px auto', width: 320, textAlign: 'left'}}>
    <NotFoundIcon />
    <div className="sf__notfound-content">
      <h5 className="h5-l">Ничего не найдено</h5>
      <p className="text-xs-l color-brand-3">Проверьте правильность запроса или измените условия поиска</p>
    </div>
  </div>
);

export default NotFound;
