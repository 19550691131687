import cloneDeep from "lodash/cloneDeep";


const defaultFavorite = {
    inFavorite: false,
    clients: [],
};
export const handleFavorite = (items, action, isSingle = false) => {
    const tmpItems = cloneDeep(items)
    const current = {
        result: null,
        index: tmpItems?.findIndex?.((item) => item.id == action.data.id)
    }
    current.result = isSingle ? tmpItems : tmpItems[current.index]

    if (action.type === "ADD_TO_FAVORITES" || action.type === "REMOVE_FROM_FAVORITES_SUCCESS") {
        current.result = {
            ...current.result,
            favorite: {
                ...defaultFavorite,
                inFavorite: action.type === "ADD_TO_FAVORITES" ? true : false,
                ...(action.type === "ADD_TO_FAVORITES" ? { favoriteId: action?.data.data.id } : [])
            },
        }
    } else {
        action.type === "ADD_CLIENT_TO_FAVORITES_SUCCESS" ?
            current.result.favorite.clients.push(action.data.client) :
            current.result.favorite.clients = current.result.favorite.clients.filter(client => client.id != action.data.client.id)
    }
    if (!isSingle) {
        tmpItems.splice(current.index, 1, current.result)
        current.result = tmpItems
    }
    return current.result
}
