import React from "react";

const About = (props) => {
  const { title, previewText, detailText, advantages, innerRef } = props;

  if (!previewText) return '';

  return (
    <section class="section section_view_white complex__section-about" ref={innerRef}>
      <div id="anchor-about"></div>
      <div class="wrapper">
        <div class="wrapper__center">
          <div class="section__title">{title}</div>
          <div class="complex__about">
            {previewText && (
              <div
                class="complex__about-col complex__about-info"
                dangerouslySetInnerHTML={{ __html: previewText }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
