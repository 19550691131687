import { memo } from "react";
import {Link, useRouteMatch} from "react-router-dom";


const NewTabsNavigation = memo(({ tabs, activeTabId, onNavClick, withRouting }) => {
    return (
    <ul className="tabs-nav">
        {tabs.map(({ title, url }, i) => {
            return (
            <li key={i} className="tabs-nav-item">
                {withRouting ?
                  <Link
                    className={`tabs-nav-item__button ${activeTabId === i ? "tabs-nav-item__button--active" : ''}`}
                    onClick={() => onNavClick(i)}
                    to={url}
                  >
                      {title}
                  </Link>
                :
                  <button
                    className={`tabs-nav-item__button ${activeTabId === i ? "tabs-nav-item__button--active" : ''}`}
                    onClick={() => onNavClick(i)}
                  >
                      {title}
                  </button>
                }
            </li>
        )})}
    </ul>)})

export default NewTabsNavigation;
