import _keys from "lodash/keys";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { editClients } from "../../actions/clients";
import { media, validateMail } from '../../utils';
import { errorToast, successToast } from "../toasts";

const FormAddClient = (props) => {
  const { show, onAddClient, add, onClose } = props;
  const { handleSubmit, register, errors, reset } = useForm({
    name: "",
    lastName: "",
    patronymic: "",
    phone: "",
    email: "",
  });
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      return
    }
    let messages = []
    for (let key in errors) {
      messages.push(`${Array.isArray(errors[key]) && errors[key] && errors[key][0] ? errors[key][0]["message"] : errors[key]?.message}`)
    }
    errorToast(
      <div>Заполните обязательные поля:<br /> {messages.map(v => {
        let msg = v.replace("Введите ", "")
        return <div>{`${msg[0].toUpperCase()}${msg.slice(1)} клиента`}<br /></div>
      })}
      </div>
    )
  }, [errors])
  const [error, setError] = useState(false);

  const onSubmit = (data) => {
    let form = new FormData();
    let keys = _keys(data);

    keys.forEach((item) => {
      let identity = item;
      if (identity === "phone" || identity === "email") {
        form.append(`${identity}[]`, data[item]);
      } else {
        form.append(`${identity}`, data[item]);
      }
    });
    form.append("city", "spb")
    add(form).then(
      data => {
        onAddClient(data.id);
        reset();
        successToast(
          <div className="Toastify__text">
            Клиент{" "}
            <strong>
              {data.lastName} {data.name}
            </strong>{" "}
          успешно добавлен
        </div>
        )
      },
      () => setError(true)
    );
    onClose && onClose()
  };

  return (
    <div
      className="modal-form__gist modal-form__row modal-form__new-client"
      id="reservation-new-client"
      style={{ display: show ? "block" : "none" }}
    >
      {props.forFixate && !media('isMobile') && <h4 className="sell__subtitle h4">Добавить клиента</h4>}

      {props.closeClick && !media('isMobile') &&
        <span className="close-button" onClick={props.closeClick}>
          <svg className="icon icon_x">
            <use xlinkHref="#x"></use>
          </svg>
        </span>
      }

      <form>
        <div className="new-client">
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">Фамилия клиента</span>
                <input
                  name="lastName"
                  className={
                    "input__control" + (errors.lastName ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите фамилию",
                  })}
                />
                {errors.lastName && (
                  <span className="input__error-label">
                    {errors.lastName?.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">Имя клиента</span>
                <input
                  name="name"
                  className={
                    "input__control" + (errors.name ? " input__error" : "")
                  }
                  ref={register({
                    required: "Введите имя",
                  })}
                />
                {errors.name && (
                  <span className="input__error-label">
                    {errors.name?.message}
                  </span>
                )}
              </label>
            </div>
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">Отчество клиента</span>
                <input
                  name="patronymic"
                  className={
                    'input__control' + (errors.patronymic ? ' input__error' : '')
                  }
                  ref={register({
                  })}
                />
                {errors.patronymic && (
                  <span className="input__error-label">
                    {errors.patronymic?.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="modal-form__row">
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">Телефон</span>
                <InputMask
                  className={
                    "input__control" + (errors.phone ? " input__error" : "")
                  }
                  placeholder="Введите телефон"
                  mask="+7 (999) 999-99-99"
                  autoComplete="off"
                  maskChar=" "
                  name="phone"
                  inputRef={register({
                    required: "Введите телефон",
                  })}
                />
                {errors.phone && (
                  <span className="input__error-label">
                    {errors.phone?.message}
                  </span>
                )}
              </label>
            </div>
            <div className="modal-form__item">
              <label className="input input_width_available input_type_form">
                <span className="input__label">E-mail</span>
                <input
                  name="email"
                  className={
                    "input__control" + (errors.email ? " input__error" : "")
                  }
                  ref={register({
                    pattern: {
                      value: validateMail,
                      message: "Неправильный формат e-mail",
                    },
                  })}
                />
                {errors.email && (
                  <span className="input__error-label">
                    {errors.email?.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="new-client__submit">
            <button type="button" onClick={handleSubmit(onSubmit)} className="button button_view_grey">Добавить</button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  add: (form) => dispatch(editClients(form)),
});

export default connect(null, mapDispatchToProps)(FormAddClient);
