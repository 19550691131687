import React from "react";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as SuccessBookingSvg } from "../../assets/images/successBooking.svg";

const SuccessBooking = () => {
  const location = useLocation()
  const history = useHistory()
  location.state = location.state || {}
  const { link, from } = location.state
  console.log('location.state, history :>> ', location.state, history);
  return (
    <div className="not-found-wrapper fb-success-wrapper">
      <div className="not-found-content fb-success">
        <SuccessBookingSvg />
        <h1 className="h1 fb-success__title">Ваша заявка на бронирование отправлена</h1>

        <div className="fb-success__btns">
          {link && <button
            className="button button_view_outline fb-success__btn"
            onClick={() => history.push(link)}
          >
            <svg class="icon icon_back">
              <use xlinkHref="#back"></use>
            </svg>
            Страница объекта
          </button>}
         {from && <button
            className="button button_view_default fb-success__btn"
            onClick={() => history.goBack()}
          >
            Забронировать повторно
          </button>}
        </div>
      </div>
    </div>
  );
};

export default SuccessBooking;
