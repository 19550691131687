import axios from "axios";
import { sendError } from "../actions/forms";
import { store } from '../index.js';
import { getURLparam } from "../utils/location";
import { toFormData, objectToQueryParams } from "../utils/index.js";
import { standartErrorToast } from "../components/toasts.js";

//export const baseUrl = 'https://panpartner.ru';
const host = window.location.host
const prodDomains = ["panpartner.ru", "new.panpartner.ru", ]

const devHosts = ["localhost:3000", "dev2.panpartner.ru", "robert.panpartner.ru", "artur.panpartner.ru", "aibeck.panpartner.ru", "evgeniy.panpartner.ru", "migel.panpartner.ru", "pro-rieltor.ru", "pantest.ru", "sci-msk.ru"]

export const isProd = prodDomains.includes(host) || host.includes("toprealtors.ru")

export const isDev = !isProd && (devHosts.some(devHost => devHost.includes(host) || host.includes(devHost)))

export const baseUrl = host === "promo.panpartner.ru" ? window.location.origin :
  isDev
    ? "https://dev2.panpartner.ru"
    : "https://panpartner.ru";

export const apiUrl = `${baseUrl}/bitrix/services/main/ajax.php`;

export const getUrl = (url, base) => {
  if (!url || !url?.includes) return ""
  return url.includes("https://") || url.includes("http://") ? url : `${base ? base : baseUrl}${url}`
}


export const handleResponse = (response, sanitize = false, withoutHandling = false) => {
  if (!response) {
    let errors = [{ message: "Сервер не отвечает или отсутствует подключение к сети" }]
    return Promise.reject(errors);
  }
  if (withoutHandling) {
    return response
  }
  const { status, data, errors } = response.data;
  if (status === 'error') {
    let error = errors.length ? errors : response.statusText;
    const isStopSending = errors[0]?.customData?.notSendErrorReport
    const condition = response.status == 401 || response.status == 403 || isStopSending
    sendBackendError(errors, condition)
    if (sanitize) {
      error = errors.length ? errors[0].message : response.statusText;
      error = error.replace(/&lt;br&gt;/gi, "\n\r");
      error = error.replace(/<br>/gi, "\n\r");
    }
    return Promise.reject(error);
  }

  return data;
};

/**
 * @param {string} component - компонент на бэке к которому обращаемся
 * @param {string} action - метод на бэке который хотим вызвать
 * @param {Object} form - аргументы для метода, простой объект или FormData
*/
const request = (component, action, form, sanitize, opt = {}) => {
  let options = {
    params: {
      c: component,
      mode: 'class',
      action,
      ...getURLparam("noStatistic", true)
    },
    ...opt
  }
  if (form instanceof FormData) {
    options.data = form
  }

  if (typeof form === "object") {
    options.params = {
      ...options.params,
      ...form,
    }
  }

  return axios({
    method: 'post',
    withCredentials: true,
    url: apiUrl,
    ...options
  }).then((resp) => handleResponse(resp, sanitize, opt.withoutHandling))

}

const sendBackendError = (errorInfo, stop) => {
  if (stop) return
  const user = store.getState()?.user
  let data = {
    "fields[pageUrl]": window.location.href,
    "fields[errorInfo]": JSON.stringify(errorInfo),
    "title": "Ошибка на бэке",
    "fields[userAgent]": window.navigator.userAgent,
    "fields[screenWxH]": window.screen.width + "x" + window.screen.height,
    "fields[windowWxH]": window.innerWidth + "x" + window.innerHeight,
    "fields[userInfo]": JSON.stringify(user?.info),
    "fields[userHistory]": JSON.stringify(user?.history),
  };
  let form = new FormData();
  let keys = Object.keys(data)
  keys.forEach((key) => {
    form.append(`${key}`, data[key]);
  });

  if (window.location.host !== "localhost:3000") {
    sendError(form)
  }
}

export const youtubeAPIRequest = (channelId) => axios({
  "referrerPolicy": "strict-origin-when-cross-origin",
  "method": "GET",
  "mode": "cors",
  "credentials": "omit",
  url: 'https://www.googleapis.com/youtube/v3/search',
  headers: {
    "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    "accept-language": "ru,en-GB;q=0.9,en-US;q=0.8,en;q=0.7,de;q=0.6",
  },
  params: {
    key: 'AIzaSyAChJ9mTO879z1JKxJbbvxHjfxlTXqUu0c',
    channelId,
    part: 'snippet',
    order: 'date',
    maxResults: 10
  }
})

/**
 * 
 * @param {Array} config 
 * @param {Object|FormData} payload 
 * @param {Boolean} convertPayload  
 * @returns {Promise}
 * @example 
 * GET METHOD api(['panpartner:chat.ajax', "getEvents"], {someParam: "some query param value"}, false).then(resp=>console.log(resp), standartErrorToast)
 * POST METHOD api(['panpartner:chat.ajax', "getEvents"], {someFormDataParam: "some value"}).then(resp=>console.log(resp), standartErrorToast)
 */
export const api = async (config, payload, { convertPayload = true }, ...options) => {

  const payloadType = payload instanceof FormData ? "formData" : typeof payload;

  const body = payloadType === "formData" || convertPayload ? toFormData(payload) : null;

  const [c, action, url = apiUrl] = config;

  const queryParams = objectToQueryParams({ mode: "class", c, action, ...(payloadType === "object" && !convertPayload ? payload : []) });

  const resp = await fetch(`${url}${queryParams}`, {
    body,
    method: body ? "POST" : "GET",
    mode: "cors",
    credentials: "include",
    ...options
  })
    .then(resp => resp.json())
    .then(resp => resp.status === "success" ? Promise.resolve(resp.data) : Promise.reject(resp));

  return resp
}

export default request


export class API {

  constructor({ apiUrl, interceptors }) {
    this.apiUrl = apiUrl;
    this.interceptors = interceptors;
    this.controllers = {};

  }

  request = async (config, payload, { convertPayload = true, interceptors = this.interceptors, errorToast = true }, ...options) => {

    const controller = new AbortController();

    const signal = controller.signal;

    const payloadType = payload instanceof FormData ? "formData" : typeof payload;

    const body = payloadType === "formData" || convertPayload ? toFormData(payload, null, true) : null;

    const [c, action, url = apiUrl] = config;

    const queryParams = objectToQueryParams({ mode: "class", c, action, ...(payloadType === "object" && !convertPayload ? payload : []) });

    const resp = await fetch(`${url}${queryParams}`, {
      body,
      method: body ? "POST" : "GET",
      mode: "cors",
      credentials: "include",
      signal,
      ...options
    })

    const json = await resp.json()
      .then(resp => resp.status === "success" ? Promise.resolve(resp.data) : Promise.reject(resp))
      .then(r => r, resp => {
        if (errorToast) standartErrorToast(resp?.errors)
        return Promise.resolve(resp)
      });

    if (interceptors) interceptors({ resp, controller, signal, config, json, });

    return Promise.resolve({ resp: json, controller, signal, config, payload });

  }

  POST = async (c, action, payload = {}, params = {}, ...options) => {
    const { resp, controller, signal, config } = await this.request([c, action, this.apiUrl], payload, { ...params, interceptors: this.interceptors }, { ...options });
    const signalID = `${c}-${action}-POST-${Date.now()}`;
    this.controllers[signalID] = controller;
    return Promise.resolve({ resp, signal, signalID, controller, payload, config });
  }

  GET = async (c, action, payload = {}, params = {}, ...options) => {
    const { resp, controller, signal, config } = await this.request([c, action, this.apiUrl], payload, { ...params, convertPayload: false, interceptors: this.interceptors }, { ...options });
    const signalID = `${c}-${action}-GET-${Date.now()}`;
    this.controllers[signalID] = controller;
    return Promise.resolve({ resp, signal, signalID, controller, config });
  }

  POSTFILES = async (c, action, payload = {}, onProgressChange) => new Promise((resolve, reject) => {
    const queryParams = objectToQueryParams({ mode: "class", c, action });
    const xhr = new XMLHttpRequest();
    const formData = toFormData(payload, null, true);
    xhr.upload.addEventListener('progress', e => onProgressChange(e.loaded / e.total));
    xhr.addEventListener('load', () => resolve({ status: xhr.status, body: xhr.responseText }));
    xhr.addEventListener('error', () => reject(new Error('File upload failed')));
    xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
    xhr.withCredentials = true;
    xhr.open('POST', `${this.apiUrl}${queryParams}`, true);
    xhr.send(formData);
  });

  abort = signalID => {
    this.controllers[signalID]?.abort?.();
    delete this.controllers[signalID];
  }

  abortAll = () => {
    Object.entries(this.controllers).forEach(([signalID, controller]) => controller?.abort?.());
    this.controllers = {};
  }

}

export const newApi = new API({
  apiUrl,
  interceptors: ({ resp, controller, signal, config, payload, json }) => {
    if (resp.status === 401) {
      store.dispatch({ type: "SET_USER_STATE", payload: { isAuthorized: false } });
      window.location.href = '/';
    }
    if (resp.status === 404) window.location.href = '/not-found';

    if (json?.needReload) window.location.reload(true);

    if (json?.clearLS) localStorage.clear();

  }
});