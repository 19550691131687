import React, {useState} from "react";
import { useModal } from "react-modal-hook";
import {connect, useSelector} from 'react-redux';
import useMedia from "../hooks/useMedia";
import { getManager } from "../utils";
import MobileModal from "./core/MobileModal";
import Modal from "./core/Modal";
import Wrap from "./core/ModalWrap";
import Callback from "./forms/Callback";
import { successToast } from "./toasts";
import useRootCSS from "../hooks/useRootCSS";

const Manager = (props) => {
  const { data, isPublic, onSuccessCallback, isAssignment, isCommercial, clientMode, userInfo, managerIsAgent, customType, objectId, savedFilterSettings } = props;


  const state = useSelector(state => state ?? {})

  useRootCSS({
    '--colorButton': savedFilterSettings?.colorButton,
    '--colorHeader': savedFilterSettings?.colorHeader,
    '--colorButtonText': savedFilterSettings?.colorButtonText,
    '--colorHeaderText': savedFilterSettings?.colorHeaderText,
    '--colorHover': savedFilterSettings?.colorHover
  })

  const isMobile = useMedia("isMobile")

  const submitCallback = () => {
    hideCallbackModal();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
    if (onSuccessCallback) {
      onSuccessCallback()
    }
  };
  const [callbackModal, hideCallbackModal] = useModal(
    ({ in: open, onExited }) => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal title="Обратный звонок" closeClick={hideCallbackModal}>{children}</MobileModal>
        : <Modal  close={hideCallbackModal}>{children}</Modal>
      } >
        <Callback
          handleClickSubmit={() => submitCallback()}
          isAssignment={isAssignment}
          manager={data}
          isCommercial={isCommercial}
          customType={customType}
          objectId={objectId}
          savedFilterSettings={savedFilterSettings !== undefined ? savedFilterSettings : false}
        />
      </Wrap >
    ), [savedFilterSettings]
  );

  const { fullName, photo, phone, email, position, telegram } = getManager(data, userInfo, clientMode)
  return (
    <div className="manager">
      <div className="manager-avatar">
        <div className="manager-avatar_wrapper">
          <img
            src={photo}
            alt={fullName}
          />
        </div>
      </div>
      <div className="manager-content">
        {isMobile && (isPublic ? "Ваш агент" : "Ваш менеджер")}
        <div className="manager-title">{fullName}</div>
        <div className="manager-post">{position}</div>
        <div className="manager-contacts">
          <ul className="manager-contacts_list">
            <li className="manager-contacts_item">
              <a className="link-hover" href={`tel:${phone}`}>
                {phone}
              </a>
            </li>
            <li className="manager-contacts_item">
              <a className="link-hover" href={`mailto:${email}`}>
                {email}
              </a>
            </li>
            {!!telegram && <li class="manager-contacts_item">
              <a class="link-hover" href={`https://t.me/${telegram}`} target="_blank">@{telegram}</a>
            </li>}
          </ul>
        </div>
        {!isPublic && !isMobile && !managerIsAgent && (
          <div className="manager-action">
            <button
              className="button button_view_outline js-modal-btn"
              onClick={callbackModal}
            >
              Обратный звонок
            </button>
          </div>
        )}
      </div>

      {!isPublic && isMobile && !managerIsAgent && (
        <div className="manager-action">
          <button
            className="button button_view_outline js-modal-btn"
            onClick={callbackModal}
          >
            Обратный звонок
          </button>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  clientMode: state.general.clientMode,
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(Manager);
