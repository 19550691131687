import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getInviteCode } from '../../actions/profile';
import './index.scss';
const InviteFriend = () => {
  const [inviteLink, setInviteLink] = useState("panpartner.ru/invite/")
  const [tooltip, setTooltip] = useState(false);
  useEffect(() => {
    if (tooltip) {
      delay(2000).then(() => setTooltip(false));
    }
  }, [tooltip]);

  useEffect(() => {
    getInviteCode().then(data=> setInviteLink("panpartner.ru/invite/" + data.inviteCode))
  }, [])

  const delay = (ms) =>
    new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  return (
    <div className="invite invite_friend">
      <div className="wrapper">
        <section className="section invite__section">
          <div className="invite__block">

            <h1 className="invite__title h1">Дарим шампанское всем нашим партнерам!</h1>
            <p className="invite__text big-text">Просто поделись этой ссылкой со своим другом-риэлтором и получи бутылку шампанского за его регистрацию. А друг получит бутылку шампанского за первую сделку!</p>


            <div className="clip">
              <div
                className={`popover js-popover ${tooltip ? ' is-showing' : ''}`}
              >
                <div className="js-copy">
                  <CopyToClipboard
                    text={inviteLink}
                    onCopy={() => setTooltip(true)}
                  >
                  <div className="invite__link-box">
                    <span className="invite__link">{inviteLink}</span>
                    <div className="invite__link-icon">
                      <img
                        src={require('../../assets/images/invite/link.svg').default}
                        alt=""
                      />
                    </div>
                  </div>
                  </CopyToClipboard>
                </div>
                <div
                  className="popover__block popover__block_align_right clip__tooltip"
                  data-align="right"
                >
                  <div className="popover__inner">
                    <div className="clip__tooltip-text">
                      Ссылка скопирована
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className="invite__sign">ссылка для приглашения</p>


          </div>

          <img
            className="invite__img"
            src={require('../../assets/images/invite/invite-friend.svg').default}
            alt=""
          />
        </section>


      </div>
    </div>
  );
};

export default InviteFriend;
