import request from '../api';

const component = "panpartner:forms.ajax"

export const reserve = (action, form) => request(component, action, form)

export const fixApartment = form => {
  if (form.get("clientId") === "-1") {
    form.delete("clientId");
  }
  return request(component, "fixClient", form)
};

export const viewApartment = form => {
  if (form.get("clientId") === "-1") {
    form.delete("clientId");
  }
  return request(component, "signUpForViewing", form)
};

export const callback = form => request(component, "callback", form)

export const subscribe = form => request("panpartner:subscribes.ajax", "subscribe", form)

export const unsubscribe = form => request("panpartner:subscribes.ajax", "unsubscribe", form)

export const sendMortgage = form => request(component, "mortgageCalculation", form)

export const sendInstallment = form => request(component, "installmentCalculation", form)

export const sendError = form => request(component, "sendError", form)

export const saleAssignment = form => request(component, "saleAssignment", form)

export const getBuildersSuccess = (data) => ({
  type: "GET_BUILDERS_SUCCESS",
  data,
});

export const getBuilders = () =>
  dispatch =>
    request(component, "getFixBuilders")
      .then(
        data => {
          dispatch(
            getBuildersSuccess(data?.builders),
          );
          return Promise.resolve(data?.builders);
        },
      );

export const sendEditAssignmentRequest = (id, comment, idKey) => request(component, "sendEditAssignmentRequest", {
  [idKey]: id,
  comment
})

export const getFormSettings = (formId, city = "spb", isOneResult = 0) => request(component, "getFormSettings", {
  formId,
  city,
  isOneResult
})

export const getFormSettingsPublic = (formId, city = "spb", isOneResult = 0) => request(component, "getFormSettingsPublic", {
  formId,
  city,
  isOneResult
})

export const isExistResult = formId => request(component, "isExistResult", { formId })

export const sendForm = (formId, form, city = "spb") => {
  form.append("formId", formId)
  form.append("city", city)
  return request(component, "sendForm", form)
};

export const sendFormPublic = (formId, form, city = "spb") => {
  form.append("formId", formId)
  form.append("city", city)
  return request(component, "sendFormPublic", form, true)
};

export const learnMore = form => request("panpartner:news.ajax", "learnMore", form)

export const sendLeadMagnet = form => request(component, "sendLeadMagnet", form)