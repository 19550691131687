import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import SwiperSlider from "../../components/core/SwiperSlider";
import SubmitButton from '../../components/SubmitButton';
import { errorToast, standartErrorToast, successToast } from '../../components/toasts';
import useMedia from "../../hooks/useMedia";
import useUniversalForm from '../../hooks/useUniversalForm';
import { scrollToRef } from "../../utils";
import './index.scss';

const Booking = (props) => {

  const userInfo = useSelector(state => state.user?.info);
  const errors = false;

  const city = 'spb';
  

  const [agree, setAgree] = useState(true);
  const { send, setValue, getValue,  clearForm } = useUniversalForm({
    formId: 60,
    defaultValues: {
      name: userInfo?.name ? `${userInfo.name} ${userInfo.lastName}` : "",
      phone: userInfo?.phone || "",
    },
    getSettingsAction: "getFormSettingsPublic",
    sendAction: "sendFormPublic", 

  })

  const handleSend = (params) => {
    if (!agree) return errorToast("Необходимо согласие на обработку персональных данных")
    send().then(() => {
      successToast("Заявка отправлена");
      clearForm();
    }, standartErrorToast)
  }


  const stepsItems = [
    {
      number: `01`,
      text: `Вы оставляете заявку на первичную консультацию клиента* с нашим специалистом — минимум, за 2 дня до желаемой даты.`,
    },
    {
      number: `02`,
      text: `Встреча проходит в офисе ПАН, где наш эксперт поможет вам выявить потребности и возможности клиента, подобрать лучшие варианты.`,
    },
    {
      number: `03`,
      text: `Если вы из другого региона — специалист ПАН проведет встречу с клиентом за вас!`,
    },

  ];

  const stepsItems2 = [
    {
      number: `04`,
      text: `Помогаем вести клиента на каждом этапе сделки: от первой встречи до подписания договора.`,
    },
    {
      number: `05`,
      text: `Узнаем потребности, возможности клиента, подбираем варианты — среди застройщиков на рынке Петербурга.`,
    },
    {
      number: `06`,
      text: `Бронирование и показ объектов (шоурумов) проходят при полной поддержке специалиста ПАН*. `,
    },
    {
      number: `07`,
      text: `После фиксации (брони) клиента, вы ведете телефонные переговоры с клиентом самостоятельно — специалист ПАН помогает вам по всем вопросам, консультирует и руководит процессом сделки.`,
    },
    {
      number: `08`,
      text: `На подписание договора вместе с вами выезжает наш подписант.`,
    },

  ];

  const formRef = useRef();
 
  const isMobile = useMedia("isMobile");

  return (
    <section className="section section_view_white">

      <section className="sor sor_cashback sor_auto booking">
        <header className="sor__promo">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__box">
                <div className="sor__content">
                  <span class="sor__sup">Для партнеров ПАН</span>
                  <h1 className="sor__h1 rie__title">Расширенное бронирование</h1>
                  <p className="sor__sign big-text">Закройте клиента на сделку с помощью эксперта ПАН!</p>

                </div>


                <div className="sor__promo-pic">
                  <img src={require('../../assets/images/booking/img1.png').default} alt="" className="sor__promo-img-lg" />
                </div>
              </div>

              <div className="sor__promo-btns">
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef, 50)}>Оставить заявку</button>
              </div>

            </div>
          </div>
        </header>

        <div className="wrapper">
          <div className="wrapper__center">
            <section className="sor__table sor__section">
              <h2 className="h2 sor__h2">У вас есть клиент, который готов приобрести квартиру в новостройке, и вы:</h2>
              
              <div className="booking__grid">
                <div className="booking__item">
                  <div className="booking__client-img">
                    <img src={require('../../assets/images/booking/client-1.png').default} alt="" />
                  </div>
                  <h4 className="h4 sor__h4 booking__client-title">Начинающий специалист или выпускник PAN School</h4>
                  <p className="big-text">Хотите избежать ошибок в работе с клиентом и нуждаетесь в профессиональном кураторе</p>
                </div>
                <div className="booking__item">
                  <div className="booking__client-img">
                    <img src={require('../../assets/images/booking/client-2.png').default} alt="" />
                  </div>
                 <h4 className="h4 sor__h4 booking__client-title">Занимаетесь продажами на вторичном рынке — и начали работать с «первичкой»</h4>
                  <p className="big-text">Еще не успели изучить рынок первичной недвижимости, но хотите сохранить клиента</p>
                </div>
                <div className="booking__item">
                  <div className="booking__client-img">
                    <img src={require('../../assets/images/booking/client-3.png').default} alt="" />
                  </div>
                  <h4 className="h4 sor__h4 booking__client-title">Агент из другого региона</h4>
                  <p className="big-text">Работаете с недвижимостью Санкт-Петербурга, но вам неудобно постоянно приезжать на каждый показ и встречу с клиентом</p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="sor__footer" ref={formRef}>
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="sor__prog-t">
                <h2 className="h2 sor__h2 sor__footer-title">Воспользуйтесь сервисом расширенного бронирования — и будьте уверены в результате!</h2>
              </div>
              <form action="#" className="sor__form">
                <div className="sor__form-box">

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-name"
                    >
                      <span className="input__label">Имя</span>
                      <input
                        id="modal-reservation-name"
                        name="name"
                        value={getValue("name")}
                        className={
                          "input__control" + (errors.name ? " input__error" : "")
                        }
                        placeholder="Ваше имя"
                        onChange={e => setValue(e.target.value, "name")}
                      />
                      {errors.name && (
                        <span className="input__error-label">
                          {errors.name.name}
                        </span>
                      )}
                    </label>
                  </div>

                  <div className="sor__form-item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-phone"
                    >
                      <span className="input__label">Телефон</span>
                      <InputMask
                        id="modal-reservation-phone"
                        className={
                          "input__control" + (errors.phone ? " input__error" : "")
                        }
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                        name="phone"
                        placeholder="Ваш телефон"
                        value={getValue("phone")}
                        onChange={e => setValue(e.target.value, "phone")}
                      />
                      {errors.name && (
                        <span className="input__error-label">
                          {errors.name.phone}
                        </span>
                      )}
                    </label>
                  </div>


                  <SubmitButton type="button" className="sor__submit button button_view_default" onClick={handleSend}>Оставить заявку</SubmitButton>


                </div>

                <div className="sor__form-footer">
                  <div className="checkbox checkbox_view_invert">
                    <div
                      className={
                        'checkbox__box'
                      }
                    >
                      <input
                        className="checkbox__control"
                        type="checkbox"
                        id="modal-reservation-agreement"
                        name="agree"
                        checked={agree}
                        onChange={e => setAgree(e.target.checked)}
                      />
                      <label
                        className="checkbox__input"
                        htmlFor="modal-reservation-agreement"
                      ></label>
                      <div className="checkbox__marker" style={{ zIndex: 0 }}></div>
                    </div>
                    <label
                      className="checkbox__label"
                      htmlFor="modal-reservation-agreement"

                    >
                      Отправляя заявку вы соглашаетесь на обработку <Link to="/policy" target="_blank" rel="noopener noreferrer" className="link">персональных данных</Link>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <div className="wrapper">
          <div className="wrapper__center">

            <section className="sor__section sor__steps">

              <h2 className="sor__h2 h2">Как работает сервис:</h2>

              <span className="sor__step color-red caps">Шаг 1</span>

              <h3 className="h3 sor__h3">Первичная консультация клиента</h3>

                  {
                    !isMobile && (
                      <div className="sor__grid sor__grid_steps">
                        {
                          stepsItems.map((item, i) =>
                            <div className="sor__about-item" key={i}>
                              <p className="sor__about-header">
                                <span className="sor__about-counter">{item.number}</span>
                              </p>
                              <p className="sor__about-text">{item.text}</p>
                            </div>
                          )
                        }

                      </div>
                    )
                  }

                  {
                    isMobile && (
                      <div className="swiper-pan sor__grid sor__grid_steps">
                        <SwiperSlider
                          items={stepsItems}
                          wrap={item => (
                            <div className="sor__about-item">
                              <p className="sor__about-header">
                                <span className="sor__about-counter">{item.number}</span>
                              </p>
                              <p className="sor__about-text">{item.text}</p>
                            </div>
                          )
                          }
                          withCounter

                          options={{
                            slidesPerView: 1,
                            spaceBetween: 15,
                            pagination: true,
                          }}
                        />
                      </div>
                    )
                  }
              <p className="sor__about-sign color-red text">* Обратите внимание, услуга не распространяется на «холодные лиды» — ваш клиент должен быть нацелен на покупку.</p>

            </section>
          </div>
        </div>

        <section className="booking__banner">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="booking__banner-inner">
                <h3 className="h3 booking__banner-title">И это бесплатно для всех партнеров, если вы проводите сделку через ПАН!</h3>
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef, 50)}>Записаться</button>
              </div>
            </div>
          </div>
        </section>

        <div className="wrapper">
          <div className="wrapper__center">

            <section className="sor__section sor__steps  sor__steps_step-2">

              <span className="sor__step color-red caps">Шаг 2</span>

              <h3 className="h3 sor__h3">Расширенное бронирование</h3>

                  {
                    !isMobile && (
                      <div className="sor__grid sor__grid_steps">
                        {
                          stepsItems2.map((item, i) =>
                            <div className="sor__about-item" key={i}>
                              <p className="sor__about-header">
                                <span className="sor__about-counter">{item.number}</span>
                              </p>
                              <p className="sor__about-text">{item.text}</p>
                            </div>
                          )
                        }

                      </div>
                    )
                  }

                  {
                    isMobile && (
                      <div className="swiper-pan sor__grid sor__grid_steps">
                        <SwiperSlider
                          items={stepsItems2}
                          wrap={item => (
                            <div className="sor__about-item">
                              <p className="sor__about-header">
                                <span className="sor__about-counter">{item.number}</span>
                              </p>
                              <p className="sor__about-text">{item.text}</p>
                            </div>
                          )
                          }
                          withCounter

                          options={{
                            slidesPerView: 1,
                            spaceBetween: 15,
                            pagination: true,
                          }}
                        />
                      </div>
                    )
                  }
              <p className="sor__about-sign color-red text">* Если вы работаете из другого региона, показ объектов клиенту возможен без вашего участия — это сделает за вас эксперт ПАН.</p>
              
              <div className="booking__last-step">
                
                
                <div className="booking__cost">
                  <div className="booking__cost-title">
                    <span className="sor__step color-red caps">Шаг 3</span>
                    <h3 className="h3 sor__h3">Cтоимость услуги</h3>
                  </div>
                  <div className="booking__badge">
                    <span className="booking__badge-lg">50%</span>
                    <span className="booking__badge-text big-text">от вашего комиссионного вознаграждения</span>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>

        
        <section className="booking__banner booking__banner_second">
          <div className="wrapper">
            <div className="wrapper__center">
              <div className="booking__banner-inner">
                <h3 className="h3 booking__banner-title">В итоге, вы получаете новый опыт и знания, довольного клиента и зарабатываете!</h3>
                <button type="button" className="sor__promo-btn button button_view_default" onClick={() => scrollToRef(formRef, 50)}>Оставить заявку</button>
              </div>
            </div>
          </div>
        </section>

      </section>
    </section>
  );
};

export default Booking;
