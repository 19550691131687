import { defaultExclude, defaultFilter, defaultFullFilter } from "../utils/defaultFilters";
import { handleFavorite } from './handlers';
const initialState = {
  isFetching: false,
  error: false,
  data: [],
  top: [],
  subways: [],
  filterData: {},
  filter: defaultFullFilter,
  countToShow: 0,
  mapItems: [],
  isActive: true,
  regulationsLink: ""
};

const assignments = (state = initialState, action) => {
  const { filter } = action;
  switch (action.type) {
    case "ASSIGNMENTS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "ASSIGNMENTS_SUCCESS":
      let tmpState = {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
        top: action.top || state.top,
        filterData: action.filterData || state.filterData,
        subways: action.subways || state.subways,
        isActive: action.isActive,
        agent: action.agent,
        isShowFilter: action.isShowFilter,
        savedFilter: action.savedFilter,
        mapItems: [],
        regulationsLink: action.regulationsLink
      };
      if (action.isGetFilter) {
        tmpState.countToShow = action.data.count
      }
      return tmpState
    case 'ASSIGNMENTS_FILTER_SUCCESS':
      return { ...state, filterData: action.filterData };
    case "ASSIGNMENTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "ASSIGNMENTS_SET_FAVORITE":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.data.id) {
            return { ...item, isFavorite: action.data.active };
          }
          return item;
        }),
      };
    case "ASSIGNMENTS_RESET":
      return { ...initialState };
    case "ASSIGNMENTS_SET_FILTER":
      let tmpFilter = {
        filter: { ...state.filter.filter, ...filter.filter },
        exclude: { ...state.filter.exclude, ...filter.exclude },
      };
      return { ...state, filter: tmpFilter };
    case "ASSIGNMENTS_REMOVE":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
      };

    case "ASSIGNMENTS_CLEAR_FILTER":
      return { ...state, filter: defaultFullFilter };
    case "ASSIGNMENTS_CLEAR_FILTER_ONLY":
      const tmpFilterOnly = {
        filter: { ...state.filter.filter, ...defaultFullFilter.filter },
        exclude: { ...state.filter.exclude },
      };
      return { ...state, filter: tmpFilterOnly };
    case "ASSIGNMENTS_CLEAR_EXCLUDE_ONLY":
      const tmpExcludeOnly = {
        filter: { ...state.filter.filter },
        exclude: { ...state.filter.exclude, ...defaultFullFilter.exclude },
      };
      return { ...state, filter: tmpExcludeOnly };
    case "ASSIGMENTS_SET_COUNT":
      return { ...state, countToShow: action.count };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
      if (action.data.whatPage === 'assignments') {
        return { ...state, mapItems: handleFavorite(state.mapItems, action) }
      }
      return state;
    case "ASSIGNMENTS_PART_SUCCESS":
      const tmpTypes = [...state.data.types]
      const typeIndex = tmpTypes.findIndex(type => type.roomTypeId === action.roomTypeId)
      tmpTypes.splice(typeIndex, 1, action.assignments[0])
      state.data.types = tmpTypes
      return {
        ...state,
        isFetching: false,
      }
    case "ASSIGNMENTS_MAP_SUCCESS":
      return {
        ...state,
        isFetching: false,
        mapItems: action.assignments,
      }
    default:
      return state;
  }
};

export default assignments;