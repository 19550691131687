
const initialState = {
  isFetching: false,
  error: false,
  data: [],
  defaultData: {},
  filterData: {
    cities: [],
    years: [],
    months: [],
    builders: [],
    types: [],
    motivation: []
  },
  filter: {
    city: 'spb',
    year: '2021',
    month: '',
    builders: [],
    type: "3",
    motivation: [],
    prepayment: false
  },
  files: null
};


const motivation = (state = initialState, action) => {
  switch (action.type) {
    case "MOTIVATION_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MOTIVATION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.motivations,
        filterData: action.filterData,
        defaultData: action.defaultData,
        files: action.files
      };
    case "MOTIVATION_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "MOTIVATION_RESET":
      return { ...initialState }; 
    case "MOTIVATION_SET_FILTER":
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case "MOTIVATION_CLEAR_FILTER":
      return { ...state, filter: state.defaultData};
    default:
      return state;
  }
};

export default motivation;
