import React from "react";
import './index.scss'

const AdvantagesFixation = () => {
  return (
    <ul className="advantages-list">
      <li className="advantages-list__item">Вы будете в курсе обращений клиента в другое агентство (в случае если другое агентство так же отправило фиксацию по клиенту). Имея такую информацию, вы сможете правильно построить свою работу с клиентом.</li>
      <li className="advantages-list__item">Вы можете быть уверенными в том, что, в случае обращения клиента в прямой отдел продаж застройщика, вы будете информированы об этом.</li>
      <li className="advantages-list__item">Часть застройщиков, где есть отделы агентских продаж, помогают риелторам довести клиента до договора.</li>
      <li className="advantages-list__item">Если при фиксации клиента пришел отрицательный ответ (ранее уже был зафиксирован за прямым отделом продаж), вы сможете переориентировать клиента на другой ЖК / застройщика.</li>
      <li className="advantages-list__item">При фиксации клиента и последующем пересечении с прямым отделом продаж (если фиксация за ОП будет позже вас) появится возможность продолжить работу с клиентом и довести его до сделки.</li>
      <li className="advantages-list__item">Повышает доверие клиентов, т.к. если покупатель звонит застройщику, то менеджер ОП подтверждает, что клиент уже работает с застройщиком через партнёров.</li>
    </ul>
  )
}

export default AdvantagesFixation


