import { memo } from 'react';
import EndingsFilter from '../../FilterRealty/EndingsFilter';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SelectFilter from '../../FilterRealty/SelectFilter';
import SquareFilter from '../../FilterRealty/SquareFilter';
import SquareFilterOnly from '../../FilterRealty/SquareFilterOnly';
import { getSelectPropsCreator } from '../utils';

const CommercialPublicFilters = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    isMobile,
    clearFilterOnly,
    clearExcludeOnly
  } = props;

  const createSelectProps = getSelectPropsCreator(props)

  return <>
    <SelectFilter
      {...createSelectProps("blocks")}
      data={{
        title: "Название ЖК",
        autocompleteTitle: "Введите название ЖК",
        identity: "blocks",
      }}
    />
    <SelectFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
    />
    <SquareFilterOnly
      filter={filter.squareTotal ?? { min: "", max: "" }}
      data={{
        title: 'Площадь',
        minPlaceholder: 'S общ. от ',
        maxPlaceholder: 'S общ. до ',
        defaultValues: filterData.squareTotal?.range ?? null
      }}
      onChange={({filter}) => handleFilter("squareTotal", filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareTotal"}
      items={filterData.squareTotal?.items}
      showPlaceholderOnLists
      debounced
      wrapperClass="sf__filter-item"
    />
    <EndingsFilter
      ident={"endings"}
      filter={filter.endings ?? { min: "", max: "" }}
      items={filterData.endings?.items ?? []}
      data={{
        title: "Срок сдачи",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
      }}
      onChange={v => setFilterAndExclude(v, "endings")}
      secondData={{
        title: "Сданные дома",
        value: filter.isOnlyHanded ?? "N"
      }}
      secondChange={v => handleFilter("isOnlyHanded", v === "Y" && v)}
      wrapperClass="sf__filter-item"
    />
    <PriceFilter
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      wrapperClass="sf__filter-item"
    />
    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
    />
  </>
});

export default CommercialPublicFilters;
