import React, { useState } from 'react';
import { sendEditAssignmentRequest } from "../../actions/forms";
import { errorToast, infoToast } from '../toasts';

const ObjectEditRequest = (props) => {
  const { close, id, type } = props;
  const [comment, setComment] = useState("")
  const idKey = type === "Вторичка"? "secondaryId": "assignmentId" 
  const onSubmit = (data) => {
    sendEditAssignmentRequest(id, comment, idKey).then(
      data => {
        close();
        infoToast(<div>{data.message}</div>)
        //ym('reachGoal','autopan_request')
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  return (
    <>
      <h2 className="modal__title">Изменить данные по&nbsp;объекту</h2>
      <p className="modal__subtitle">Опишите какие данные Вы хотели бы изменить по объекту.</p>

      <div className="modal__warning">
        <div className="info">
          <svg className="icon icon_info">
            <use xlinkHref="#info" />
          </svg>
        </div>
        <p>Внимание! Изменения вступят в силу после модерации менеджером.</p>
      </div>

      <form className="modal-form" >

        <div className="modal-form__row">
          <div className="modal-form__item">
            <label
              className="textarea textarea_type_form textarea_width_available"
              htmlFor="cl-comment"
            >
              <span className="textarea__label">Описание изменений</span>
              <textarea
                className="textarea__control"
                id="cl-comment"
                name="comment"
                placeholder="Например: цену изменить на 3 000 000 р"
                rows="5"
                onChange={(e)=>setComment(e.target.value)}
                value={comment}
              ></textarea>
            </label>
          </div>
        </div>
        <div className="modal-form__submit">
          <button className="button button_view_default" type="button" onClick={onSubmit}>
            Отправить
          </button>
        </div>
      </form>
    </>
  );
};

export default ObjectEditRequest