import uniq from 'lodash/uniq';
import without from "lodash/without";
import React, { memo, useEffect, useMemo, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import {
    exportClients
} from "../../../actions/clients";
import request from '../../../api';
import usePermission from "../../../hooks/usePermission";
import { toFormData } from "../../../utils";
import Dropdown from "../../core/Dropdown";
import DropdownCustomColor from "../../core/DropdownCustomColor";
import EmptyTable from "../../core/EmptyTable";
import Preloader from "../../Loaders/FilterTableLoader";
import { successToast } from "../../toasts";
import "../index.css";
import Filter from "./Filter";
import Items from "./Items";
import { applyFilter, clientsInitilaState, clientsReducer } from "./utils";
import FilterSkeleton from "../../../pages/Crm/FilterSkeleton";
import Loader from "../../Loader";
const getClients = form => request("panpartner:client.edit.form", "getClients", form);
const changeStatus = form => request("panpartner:client.edit.form", "setStatus", form);
const setResponsible = form => request("panpartner:client.edit.form", "setResponsible", form);
const removeClient = form => request("panpartner:client.edit.form", "removeClient", form);

const ClientsList = memo(props => {

    const accessToExport = usePermission("marketer");

    const [search, setSearch] = useState("")
    const [selectedObjects, setSelectedObjects] = useState([]);
    const [multipleStatus, setStatusMultiple] = useState(null);
    const [responsibleMultiple, setResponsibleMultiple] = useState(null)
    const [state, dispatch] = useReducer(clientsReducer, clientsInitilaState)
    const createSetter = type => (payload = {}) => dispatch({ type, payload })

    const {
        clients,
        filter,
        filterData,
        isFetching
    } = state


    useEffect(() => {
        getClients().then(createSetter("getClients/success"))
    }, [])

    const filteredClients = useMemo(() => applyFilter(filter, clients, search), [filter, clients, search])

    if (isFetching && props.fromCrm) {return <section  style={props.fromCrm ? {marginTop: '-15px'} : {}} className="section section_view_white orders">
        <div className="wrapper" style={{textAlign: 'center'}}><FilterSkeleton /><Loader loaderColor='#2c2e3e'/></div></section>} else if (isFetching) {
        return <Preloader />
    }



    const saveXLSX = () => exportClients(toFormData({
        "filter[id]": filteredClients.map(v => v.id)
    })).then(resp => {
        const  link = document.createElement('a');
        link.href = URL.createObjectURL(resp.data);
        link.download = "clients.xlsx";
        link.click();
        link.remove()
    });

    const selectItems = id => setSelectedObjects(prev => uniq(prev.concat(id)))
    const deselectItems = id => setSelectedObjects(prev => without(prev, ...(Array.isArray(id) ? id : [id])))
    const toggleItems = (id, checked) => checked ? selectItems(id) : deselectItems(id)

    const clearFilter = createSetter("resetFilter")


    const remove = clientId => removeClient({ clientId }).then(resp => {
        dispatch({ type: "removeClient", payload: { clientId } })
        return Promise.resolve(resp.message)
    });

    const changeValuesMultiple = () => {
        if (!multipleStatus && !responsibleMultiple) return;

        const changeClientsMultiple = createSetter("changeClientsMultiple")

        const statusPromise = multipleStatus ? changeStatus(toFormData({ id: selectedObjects, status: multipleStatus })) : true
        const responsiblePromise = responsibleMultiple ? setResponsible(toFormData({ id: selectedObjects, responsible: responsibleMultiple })) : true
        Promise.all([statusPromise, responsiblePromise]).then(resp => {
            changeClientsMultiple({ selectedObjects, multipleStatus, responsibleMultiple })
            successToast(`${multipleStatus? "Статус клиентов обновлен": ""} \n ${responsibleMultiple? "Ответственный изменен": ""}`)
        }, err => {

        })
    }
    const setStatus = (id, status) => {
        const changeClientStatus = createSetter("modifyClient")
        changeStatus(toFormData({ id, status })).then(resp => {
            changeClientStatus({ clientId: id, ident: "status", value: status })
            successToast(`Статус клиента обновлен`)
        })
    }

    return <section style={props.fromCrm ? {marginTop: '-15px'} : {}} className={`section section_view_white orders ${!props.fromCrm && 'no-header-profile'}`}>
        <div className="wrapper">
            {!props.fromCrm &&
              <div className="clients__header">
                  <div className="clients__title">
                      <div className="h1">Клиенты</div>
                  </div>

                  <div className="clients__header-box">
                      {accessToExport && !!filteredClients?.length &&
                        <div className="clients__download" onClick={saveXLSX}>
                            <svg className="clients__download-icon" width="19" height="18" viewBox="0 0 19 18"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M12.0031 17.851H1.58146C0.755984 17.851 0.0336914 17.2424 0.0336914 16.431V1.52139C0.0336914 0.709981 0.755984 0 1.58146 0H12.0031C12.8286 0 13.5509 0.709981 13.5509 1.52139V16.431C13.5509 17.2424 12.8286 17.851 12.0031 17.851Z"
                                  fill="#CCD0DD"/>
                                <rect x="3.18262" y="4.38831" width="14.8511" height="9.07423" rx="1" fill="#8F95AC"/>
                                <path
                                  d="M11.8041 11.5302L10.7906 9.61467H10.4156L9.38181 11.5302H7.88184L9.37168 8.85454L8.03386 6.3208H9.50343L10.4359 8.11469H10.8108L11.7635 6.3208H13.2128L11.8547 8.83427L13.3345 11.5302H11.8041Z"
                                  fill="white"/>
                            </svg>
                            Экспорт клиентов
                        </div>
                      }

                      <Link
                        className="button button_view_default"
                        to={`/personal/clients/add`}
                      >
                          Добавить клиента
                      </Link>
                  </div>
              </div>
            }
            {clients?.length > 0 ? (
                <div className={props.fromCrm ? 'clients__crm-data' : ''}>
                    <Filter
                        filter={filter}
                        filterData={filterData}
                        setFilter={createSetter("setFilter")}
                        clearFilter={clearFilter}
                        setSearch={setSearch}
                        search={search}
                        removeFilter={createSetter("removeFilter")}
                        fromCrm={props.fromCrm}
                    />
                    {props.fromCrm &&
                      <Link
                        className="button button_view_default button_addClientFromCrm"
                        to={`/personal/clients/add?fromCrm=true`}
                      >
                          Добавить клиента
                      </Link>
                    }
                    {accessToExport && !!filteredClients?.length && props.fromCrm ?
                      <div className="clients__download" onClick={saveXLSX} style={{marginTop: 30, marginBottom: 15}}>
                          <svg className="clients__download-icon" width="19" height="18" viewBox="0 0 19 18"
                               fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.0031 17.851H1.58146C0.755984 17.851 0.0336914 17.2424 0.0336914 16.431V1.52139C0.0336914 0.709981 0.755984 0 1.58146 0H12.0031C12.8286 0 13.5509 0.709981 13.5509 1.52139V16.431C13.5509 17.2424 12.8286 17.851 12.0031 17.851Z"
                                fill="#CCD0DD"/>
                              <rect x="3.18262" y="4.38831" width="14.8511" height="9.07423" rx="1" fill="#8F95AC"/>
                              <path
                                d="M11.8041 11.5302L10.7906 9.61467H10.4156L9.38181 11.5302H7.88184L9.37168 8.85454L8.03386 6.3208H9.50343L10.4359 8.11469H10.8108L11.7635 6.3208H13.2128L11.8547 8.83427L13.3345 11.5302H11.8041Z"
                                fill="white"/>
                          </svg>
                          Экспорт клиентов
                      </div> : !!filteredClients?.length && props.fromCrm ? <div style={{height: '30px'}}></div> : <></>
                    }
                    <Items
                        items={filteredClients}
                        handleDelete={remove}
                        statuses={filterData.statuses}
                        agents={filterData.agents}
                        toggleItems={toggleItems}
                        selectedItems={selectedObjects}
                        changeStatus={setStatus}
                        statusTitleKey="name"
                        fromCrm={props.fromCrm}
                    />


                </div>
            ) : (
              <>{props.fromCrm &&
                  <div className={'clients__crm-data'}>
                    <Link
                      className="button button_view_default button_addClientFromCrm"
                      to={`/personal/clients/add?fromCrm=true`}
                    >
                        Добавить клиента
                    </Link>
                  </div>
                }
                  <EmptyTable
                    hideBorder={props.fromCrm}
                    title="Вы еще не добавили клиентов"
                  />
              </>

            )}

            {!!selectedObjects.length &&
                <div className="ot-change-status">
                    <div className="ot-change-status__item">
                        <b>Сменить статус</b>
                        <DropdownCustomColor
                            options={[{ id: null, name: "Без изменений" }, ...filterData.statuses]}
                            value={multipleStatus}
                            onChange={setStatusMultiple}
                            titleKey={'name'}
                            openTop
                        />
                    </div>
                    <div className="ot-change-status__item">
                        <b>Назначить ответственного</b>
                        <Dropdown
                            options={[{ id: null, name: "Без изменений", lastName: "" }, ...filterData.agents]}
                            value={responsibleMultiple}
                            onChange={setResponsibleMultiple}
                            openTop
                        />
                    </div>
                    <button
                        type="button"
                        className="button button_view_grey"
                        onClick={changeValuesMultiple}
                    >Применить</button>
                </div>}
        </div>
    </section>
});

export default ClientsList
