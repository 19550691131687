import React, { useState, useEffect, useRef } from "react";
import { media } from '../../utils';

const EditForm = (props) => {
  const {
    title,
    defaultValue,
    length,
    handleSubmit,
    hide,
    setFormData,
    button
  } = props;
  const [text, setText] = React.useState("");
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    setText(defaultValue);
  }, []);

  const handleChange = (e) => {
    const { value } = e.target
    const { identity } = button

    setFormData({
      [identity]: value,
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(text);
    hide();
  };

  const textRef = useRef(defaultValue)

  useEffect(() => {
    return () => {
      isChanged && handleSubmit(textRef.current)
    }
  }, [isChanged])

  const changeText = (value) => {
    textRef.current = value
    setText(value)
    setChanged(true)
  }

  return (
    <>
      {!media("isMobile") &&
        <h2 className="modal__title">{title}</h2>
      }
      <form className="modal-form" onSubmit={onSubmit}>
        <div className="modal-form__row">
          <div className="modal-form__item">
            <label
              className="textarea textarea_type_form"
              htmlFor="modal-furnish-description-text"
            >
              {!media("isMobile") &&
                <span className="textarea__label">
                  Максимальное количество символов — {length}
                </span>
              }
              <textarea
                className="textarea__control"
                maxLength={length}
                value={text}
                onChange={(e) => {
                  media('isMobile')
                    ? changeText(e.target.value)
                    :
                      setText(e.target.value)
                      handleChange(e)
                }}
                id="modal-furnish-description-text"
                placeholder="Добавить комментарий"
              ></textarea>
            </label>
          </div>
        </div>
        {!media("isMobile") &&
          <div className="modal-form__submit">
            <button className="button button_view_default">Сохранить</button>
          </div>
        }
      </form>
    </>
  );
};

export default EditForm;
