import { handleFavorite } from './handlers';
export const cleanFilter = {
  assignments: "",
  balconyType: [],
  banks: [],
  bathroom: [],
  buildings: [],
  cellHeight: {
    max: "",
    min: ""
  },
  decoration: [],
  endings: {
    max: null,
    min: null
  },
  floors: {
    max: "",
    min: ""
  },
  isApartments: [],
  isNoFirstFloor: "",
  isOnlyHanded: [],
  issuingKeys: {
    max: null,
    min: null
  },
  number: "",
  payment: [],
  prices: {
    max: "",
    min: ""
  },
  rooms: [],
  roomType: [],
  squareKitchen: {
    max: "",
    min: ""
  },
  squareTotal: {
    max: "",
    min: ""
  },
  isAction: "",
  status: [1]
};
const initialState = {
  isFetching: false,
  error: false,
  data: null,
  // активность сохр. фильтра по ссылкам /public/*
  isActive: true,
  agent: null,
  filterData: {},
  banks: [],
  news: [],
  actions: [],
  otherBlocks: [],
  filter: cleanFilter,
  apartments: [],
  count: "initial",
};


const complex = (state = initialState, action) => {
  switch (action.type) {
    case "COMPLEX_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "COMPLEX_SUCCESS":
      const {
        actions,
        agent,
        apartments=[],
        banks,
        isActive,
        news,
        otherBlocks,
        savedFilterSettings
      } = action
      apartments.unshift({
        buildingId: "all",
        title: "Все корпуса"
      })
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.block,
        actions,
        agent,
        apartments,
        banks,
        isActive,
        news,
        otherBlocks,
        filterData: action.filter,
        count: action?.apartments?.length > 0 ? "initial" : 0,
        savedFilterSettings
      };
    case "COMPLEX_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "COMPLEX_RESET":
      return { ...initialState };
    case 'ADD_TO_FAVORITES':
    case 'REMOVE_FROM_FAVORITES_SUCCESS':
    case 'ADD_CLIENT_TO_FAVORITES_SUCCESS':
    case 'REMOVE_CLIENT_FROM_FAVORITES_SUCCESS':
      const page = action.data.whatPage
      const type = action.data.type
      const condition = page === 'complexMain' || page === 'complex' || page === "complexApartment"
      let isSingle = true
      if (condition) {
        let pageIdentity = "data"
        if (page !== "complexMain") {
          pageIdentity = type === 'block' ? 'otherBlocks' : 'apartments';
          isSingle = false
        }
        return { ...state, [pageIdentity]: handleFavorite(state[pageIdentity], action, isSingle) }
      }
      return state
    case "COMPLEX_SET_FILTER":
      return { ...state, filter: action.filter }
    case "COMPLEX_CLEAR_FILTER":
      return { ...state, filter: cleanFilter, count: "initial", apartments: [] }
    case "COMPLEX_APARTMENTS_COUNT":
      return { ...state, count: action.count }
    case "COMPLEX_APARTMENTS_SUCCESS":     
      if(action["filter[buildings]"]){
        const tmp = [...state.apartments]
        const findedBuildingIndex = tmp.findIndex(building=> building.buildingId == action["filter[buildings]"])
        const findedTypeIndex = tmp[findedBuildingIndex].types.findIndex(type=> type.roomTypeId == action["filter[rooms]"])
        tmp[findedBuildingIndex].types.splice(findedTypeIndex, 1, ...action.apartments[0].types)
        return {
          ...state,
          apartments: tmp
        }
      }
      return { ...state, apartments: !action.apartments?.length? [] : action.apartments }
    default:
      return state;
  }
};

export default complex;
