import React from "react";

const BgRight2 = (props) => {
  return (
    <div className="background__right">
      <svg viewBox="0 0 873 812" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M679.615 615.946C681.73 615.946 683.444 614.227 683.444 612.106C683.444 609.985 681.73 608.266 679.615 608.266C677.501 608.266 675.786 609.985 675.786 612.106C675.786 614.227 677.501 615.946 679.615 615.946Z" fill="white"/>
      <path d="M154.156 657.941C156.271 657.941 157.985 656.222 157.985 654.101C157.985 651.98 156.271 650.261 154.156 650.261C152.042 650.261 150.327 651.98 150.327 654.101C150.327 656.222 152.042 657.941 154.156 657.941Z" fill="white"/>
      <path d="M216.829 134.181C218.944 134.181 220.658 132.462 220.658 130.341C220.658 128.22 218.944 126.501 216.829 126.501C214.714 126.501 213 128.22 213 130.341C213 132.462 214.714 134.181 216.829 134.181Z" fill="white"/>
      <path d="M0 812.5L558 0H873V812.5H0Z" fill="url(#paint0_linear)" class="background__gradient" />
      <path d="M507.304 260.181C515.102 260.181 521.424 253.841 521.424 246.021C521.424 238.201 515.102 231.861 507.304 231.861C499.505 231.861 493.184 238.201 493.184 246.021C493.184 253.841 499.505 260.181 507.304 260.181Z" fill="white"/>
      <path d="M507.304 346.821C515.102 346.821 521.424 340.481 521.424 332.661C521.424 324.841 515.102 318.501 507.304 318.501C499.505 318.501 493.184 324.841 493.184 332.661C493.184 340.481 499.505 346.821 507.304 346.821Z" fill="white"/>
      <path d="M544.638 281.541C552.436 281.541 558.758 275.201 558.758 267.381C558.758 259.56 552.436 253.221 544.638 253.221C536.839 253.221 530.518 259.56 530.518 267.381C530.518 275.201 536.839 281.541 544.638 281.541Z" fill="white"/>
      <path d="M470.208 325.461C478.007 325.461 484.328 319.121 484.328 311.301C484.328 303.481 478.007 297.141 470.208 297.141C462.41 297.141 456.088 303.481 456.088 311.301C456.088 319.121 462.41 325.461 470.208 325.461Z" fill="white"/>
      <path d="M545.116 324.501C552.914 324.501 559.236 318.161 559.236 310.341C559.236 302.521 552.914 296.181 545.116 296.181C537.318 296.181 530.996 302.521 530.996 310.341C530.996 318.161 537.318 324.501 545.116 324.501Z" fill="white"/>
      <path d="M469.491 282.501C477.289 282.501 483.611 276.161 483.611 268.341C483.611 260.521 477.289 254.181 469.491 254.181C461.692 254.181 455.371 260.521 455.371 268.341C455.371 276.161 461.692 282.501 469.491 282.501Z" fill="white"/>
      <path d="M435.268 258.261C441.48 258.261 446.516 253.211 446.516 246.981C446.516 240.751 441.48 235.701 435.268 235.701C429.055 235.701 424.02 240.751 424.02 246.981C424.02 253.211 429.055 258.261 435.268 258.261Z" fill="white"/>
      <path d="M582.211 342.981C588.423 342.981 593.459 337.931 593.459 331.701C593.459 325.471 588.423 320.421 582.211 320.421C575.999 320.421 570.963 325.471 570.963 331.701C570.963 337.931 575.999 342.981 582.211 342.981Z" fill="white"/>
      <path d="M424.02 300.501C430.232 300.501 435.268 295.451 435.268 289.221C435.268 282.991 430.232 277.941 424.02 277.941C417.807 277.941 412.771 282.991 412.771 289.221C412.771 295.451 417.807 300.501 424.02 300.501Z" fill="white"/>
      <path d="M593.46 300.741C599.672 300.741 604.708 295.691 604.708 289.461C604.708 283.231 599.672 278.181 593.46 278.181C587.247 278.181 582.211 283.231 582.211 289.461C582.211 295.691 587.247 300.741 593.46 300.741Z" fill="white"/>
      <path d="M435.028 342.741C441.241 342.741 446.277 337.691 446.277 331.461C446.277 325.231 441.241 320.181 435.028 320.181C428.816 320.181 423.78 325.231 423.78 331.461C423.78 337.691 428.816 342.741 435.028 342.741Z" fill="white"/>
      <path d="M582.211 258.501C588.423 258.501 593.459 253.451 593.459 247.221C593.459 240.991 588.423 235.941 582.211 235.941C575.999 235.941 570.963 240.991 570.963 247.221C570.963 253.451 575.999 258.501 582.211 258.501Z" fill="white"/>
      <path d="M466.379 374.181C472.592 374.181 477.628 369.131 477.628 362.901C477.628 356.671 472.592 351.621 466.379 351.621C460.167 351.621 455.131 356.671 455.131 362.901C455.131 369.131 460.167 374.181 466.379 374.181Z" fill="white"/>
      <path d="M550.86 227.061C557.072 227.061 562.108 222.011 562.108 215.781C562.108 209.551 557.072 204.501 550.86 204.501C544.648 204.501 539.612 209.551 539.612 215.781C539.612 222.011 544.648 227.061 550.86 227.061Z" fill="white"/>
      <path d="M508.5 385.701C514.712 385.701 519.748 380.651 519.748 374.421C519.748 368.191 514.712 363.141 508.5 363.141C502.288 363.141 497.252 368.191 497.252 374.421C497.252 380.651 502.288 385.701 508.5 385.701Z" fill="white"/>
      <path d="M508.739 215.541C514.951 215.541 519.987 210.491 519.987 204.261C519.987 198.031 514.951 192.981 508.739 192.981C502.527 192.981 497.491 198.031 497.491 204.261C497.491 210.491 502.527 215.541 508.739 215.541Z" fill="white"/>
      <path d="M550.86 374.421C557.072 374.421 562.108 369.371 562.108 363.141C562.108 356.911 557.072 351.861 550.86 351.861C544.648 351.861 539.612 356.911 539.612 363.141C539.612 369.371 544.648 374.421 550.86 374.421Z" fill="white"/>
      <path d="M466.619 226.821C472.831 226.821 477.867 221.771 477.867 215.541C477.867 209.311 472.831 204.261 466.619 204.261C460.407 204.261 455.371 209.311 455.371 215.541C455.371 221.771 460.407 226.821 466.619 226.821Z" fill="white"/>
      <path d="M404.635 237.621C409.128 237.621 412.771 233.968 412.771 229.461C412.771 224.954 409.128 221.301 404.635 221.301C400.141 221.301 396.498 224.954 396.498 229.461C396.498 233.968 400.141 237.621 404.635 237.621Z" fill="white"/>
      <path d="M612.126 357.381C616.62 357.381 620.263 353.728 620.263 349.221C620.263 344.714 616.62 341.061 612.126 341.061C607.632 341.061 603.989 344.714 603.989 349.221C603.989 353.728 607.632 357.381 612.126 357.381Z" fill="white"/>
      <path d="M390.514 276.261C395.008 276.261 398.651 272.608 398.651 268.101C398.651 263.594 395.008 259.941 390.514 259.941C386.02 259.941 382.377 263.594 382.377 268.101C382.377 272.608 386.02 276.261 390.514 276.261Z" fill="white"/>
      <path d="M626.246 318.741C630.74 318.741 634.383 315.088 634.383 310.581C634.383 306.074 630.74 302.421 626.246 302.421C621.752 302.421 618.109 306.074 618.109 310.581C618.109 315.088 621.752 318.741 626.246 318.741Z" fill="white"/>
      <path d="M390.514 318.501C395.008 318.501 398.651 314.848 398.651 310.341C398.651 305.834 395.008 302.181 390.514 302.181C386.02 302.181 382.377 305.834 382.377 310.341C382.377 314.848 386.02 318.501 390.514 318.501Z" fill="white"/>
      <path d="M626.246 276.261C630.74 276.261 634.383 272.608 634.383 268.101C634.383 263.594 630.74 259.941 626.246 259.941C621.752 259.941 618.109 263.594 618.109 268.101C618.109 272.608 621.752 276.261 626.246 276.261Z" fill="white"/>
      <path d="M404.635 357.621C409.128 357.621 412.771 353.968 412.771 349.461C412.771 344.954 409.128 341.301 404.635 341.301C400.141 341.301 396.498 344.954 396.498 349.461C396.498 353.968 400.141 357.621 404.635 357.621Z" fill="white"/>
      <path d="M612.126 237.381C616.62 237.381 620.263 233.728 620.263 229.221C620.263 224.714 616.62 221.061 612.126 221.061C607.632 221.061 603.989 224.714 603.989 229.221C603.989 233.728 607.632 237.381 612.126 237.381Z" fill="white"/>
      <path d="M431.678 389.541C436.172 389.541 439.815 385.887 439.815 381.381C439.815 376.874 436.172 373.221 431.678 373.221C427.184 373.221 423.541 376.874 423.541 381.381C423.541 385.887 427.184 389.541 431.678 389.541Z" fill="white"/>
      <path d="M585.322 205.461C589.816 205.461 593.459 201.808 593.459 197.301C593.459 192.794 589.816 189.141 585.322 189.141C580.829 189.141 577.186 192.794 577.186 197.301C577.186 201.808 580.829 205.461 585.322 205.461Z" fill="white"/>
      <path d="M467.097 410.181C471.591 410.181 475.234 406.528 475.234 402.021C475.234 397.514 471.591 393.861 467.097 393.861C462.603 393.861 458.96 397.514 458.96 402.021C458.96 406.528 462.603 410.181 467.097 410.181Z" fill="white"/>
      <path d="M549.663 184.821C554.157 184.821 557.8 181.168 557.8 176.661C557.8 172.154 554.157 168.501 549.663 168.501C545.169 168.501 541.526 172.154 541.526 176.661C541.526 181.168 545.169 184.821 549.663 184.821Z" fill="white"/>
      <path d="M508.739 417.621C513.233 417.621 516.876 413.968 516.876 409.461C516.876 404.954 513.233 401.301 508.739 401.301C504.246 401.301 500.603 404.954 500.603 409.461C500.603 413.968 504.246 417.621 508.739 417.621Z" fill="white"/>
      <path d="M508.021 177.381C512.515 177.381 516.158 173.728 516.158 169.221C516.158 164.714 512.515 161.061 508.021 161.061C503.527 161.061 499.884 164.714 499.884 169.221C499.884 173.728 503.527 177.381 508.021 177.381Z" fill="white"/>
      <path d="M549.424 410.421C553.918 410.421 557.561 406.768 557.561 402.261C557.561 397.754 553.918 394.101 549.424 394.101C544.93 394.101 541.287 397.754 541.287 402.261C541.287 406.768 544.93 410.421 549.424 410.421Z" fill="white"/>
      <path d="M467.576 184.581C472.07 184.581 475.713 180.928 475.713 176.421C475.713 171.914 472.07 168.261 467.576 168.261C463.082 168.261 459.439 171.914 459.439 176.421C459.439 180.928 463.082 184.581 467.576 184.581Z" fill="white"/>
      <path d="M585.562 389.301C590.056 389.301 593.699 385.647 593.699 381.141C593.699 376.634 590.056 372.981 585.562 372.981C581.068 372.981 577.425 376.634 577.425 381.141C577.425 385.647 581.068 389.301 585.562 389.301Z" fill="white"/>
      <path d="M431.199 205.701C435.693 205.701 439.336 202.048 439.336 197.541C439.336 193.034 435.693 189.381 431.199 189.381C426.706 189.381 423.062 193.034 423.062 197.541C423.062 202.048 426.706 205.701 431.199 205.701Z" fill="white"/>
      <path d="M375.437 218.181C378.477 218.181 380.941 215.709 380.941 212.661C380.941 209.612 378.477 207.141 375.437 207.141C372.397 207.141 369.933 209.612 369.933 212.661C369.933 215.709 372.397 218.181 375.437 218.181Z" fill="white"/>
      <path d="M641.084 372.021C644.124 372.021 646.588 369.549 646.588 366.501C646.588 363.452 644.124 360.981 641.084 360.981C638.044 360.981 635.58 363.452 635.58 366.501C635.58 369.549 638.044 372.021 641.084 372.021Z" fill="white"/>
      <path d="M360.121 255.381C363.161 255.381 365.625 252.91 365.625 249.861C365.625 246.812 363.161 244.341 360.121 244.341C357.081 244.341 354.616 246.812 354.616 249.861C354.616 252.91 357.081 255.381 360.121 255.381Z" fill="white"/>
      <path d="M656.401 335.061C659.441 335.061 661.905 332.589 661.905 329.541C661.905 326.492 659.441 324.021 656.401 324.021C653.361 324.021 650.896 326.492 650.896 329.541C650.896 332.589 653.361 335.061 656.401 335.061Z" fill="white"/>
      <path d="M354.855 294.261C357.895 294.261 360.36 291.789 360.36 288.741C360.36 285.692 357.895 283.221 354.855 283.221C351.815 283.221 349.351 285.692 349.351 288.741C349.351 291.789 351.815 294.261 354.855 294.261Z" fill="white"/>
      <path d="M661.666 295.941C664.706 295.941 667.17 293.469 667.17 290.421C667.17 287.372 664.706 284.901 661.666 284.901C658.626 284.901 656.161 287.372 656.161 290.421C656.161 293.469 658.626 295.941 661.666 295.941Z" fill="white"/>
      <path d="M360.121 335.061C363.161 335.061 365.625 332.589 365.625 329.541C365.625 326.492 363.161 324.021 360.121 324.021C357.081 324.021 354.616 326.492 354.616 329.541C354.616 332.589 357.081 335.061 360.121 335.061Z" fill="white"/>
      <path d="M656.401 255.381C659.441 255.381 661.905 252.91 661.905 249.861C661.905 246.812 659.441 244.341 656.401 244.341C653.361 244.341 650.896 246.812 650.896 249.861C650.896 252.91 653.361 255.381 656.401 255.381Z" fill="white"/>
      <path d="M375.437 372.261C378.477 372.261 380.941 369.789 380.941 366.741C380.941 363.692 378.477 361.221 375.437 361.221C372.397 361.221 369.933 363.692 369.933 366.741C369.933 369.789 372.397 372.261 375.437 372.261Z" fill="white"/>
      <path d="M641.084 218.181C644.124 218.181 646.588 215.709 646.588 212.661C646.588 209.612 644.124 207.141 641.084 207.141C638.044 207.141 635.58 209.612 635.58 212.661C635.58 215.709 638.044 218.181 641.084 218.181Z" fill="white"/>
      <path d="M399.848 403.941C402.888 403.941 405.353 401.47 405.353 398.421C405.353 395.372 402.888 392.901 399.848 392.901C396.808 392.901 394.344 395.372 394.344 398.421C394.344 401.47 396.808 403.941 399.848 403.941Z" fill="white"/>
      <path d="M616.673 186.501C619.713 186.501 622.178 184.03 622.178 180.981C622.178 177.932 619.713 175.461 616.673 175.461C613.633 175.461 611.169 177.932 611.169 180.981C611.169 184.03 613.633 186.501 616.673 186.501Z" fill="white"/>
      <path d="M431.438 428.421C434.478 428.421 436.943 425.949 436.943 422.901C436.943 419.852 434.478 417.381 431.438 417.381C428.398 417.381 425.934 419.852 425.934 422.901C425.934 425.949 428.398 428.421 431.438 428.421Z" fill="white"/>
      <path d="M585.083 162.021C588.123 162.021 590.587 159.549 590.587 156.501C590.587 153.452 588.123 150.981 585.083 150.981C582.043 150.981 579.579 153.452 579.579 156.501C579.579 159.549 582.043 162.021 585.083 162.021Z" fill="white"/>
      <path d="M468.533 443.781C471.573 443.781 474.038 441.309 474.038 438.261C474.038 435.212 471.573 432.741 468.533 432.741C465.493 432.741 463.029 435.212 463.029 438.261C463.029 441.309 465.493 443.781 468.533 443.781Z" fill="white"/>
      <path d="M547.988 146.661C551.028 146.661 553.492 144.189 553.492 141.141C553.492 138.092 551.028 135.621 547.988 135.621C544.948 135.621 542.483 138.092 542.483 141.141C542.483 144.189 544.948 146.661 547.988 146.661Z" fill="white"/>
      <path d="M508.74 449.061C511.78 449.061 514.244 446.589 514.244 443.541C514.244 440.492 511.78 438.021 508.74 438.021C505.7 438.021 503.235 440.492 503.235 443.541C503.235 446.589 505.7 449.061 508.74 449.061Z" fill="white"/>
      <path d="M508.021 141.381C511.061 141.381 513.526 138.91 513.526 135.861C513.526 132.812 511.061 130.341 508.021 130.341C504.981 130.341 502.517 132.812 502.517 135.861C502.517 138.91 504.981 141.381 508.021 141.381Z" fill="white"/>
      <path d="M547.988 443.781C551.028 443.781 553.492 441.309 553.492 438.261C553.492 435.212 551.028 432.741 547.988 432.741C544.948 432.741 542.483 435.212 542.483 438.261C542.483 441.309 544.948 443.781 547.988 443.781Z" fill="white"/>
      <path d="M468.533 146.661C471.573 146.661 474.038 144.189 474.038 141.141C474.038 138.092 471.573 135.621 468.533 135.621C465.493 135.621 463.029 138.092 463.029 141.141C463.029 144.189 465.493 146.661 468.533 146.661Z" fill="white"/>
      <path d="M585.083 428.421C588.123 428.421 590.587 425.949 590.587 422.901C590.587 419.852 588.123 417.381 585.083 417.381C582.043 417.381 579.579 419.852 579.579 422.901C579.579 425.949 582.043 428.421 585.083 428.421Z" fill="white"/>
      <path d="M431.438 162.021C434.478 162.021 436.943 159.549 436.943 156.501C436.943 153.452 434.478 150.981 431.438 150.981C428.398 150.981 425.934 153.452 425.934 156.501C425.934 159.549 428.398 162.021 431.438 162.021Z" fill="white"/>
      <path d="M616.913 403.941C619.953 403.941 622.417 401.47 622.417 398.421C622.417 395.372 619.953 392.901 616.913 392.901C613.873 392.901 611.408 395.372 611.408 398.421C611.408 401.47 613.873 403.941 616.913 403.941Z" fill="white"/>
      <path d="M399.848 186.501C402.888 186.501 405.353 184.03 405.353 180.981C405.353 177.932 402.888 175.461 399.848 175.461C396.808 175.461 394.344 177.932 394.344 180.981C394.344 184.03 396.808 186.501 399.848 186.501Z" fill="white"/>
      <path d="M687.034 351.621C689.149 351.621 690.863 349.902 690.863 347.781C690.863 345.66 689.149 343.941 687.034 343.941C684.919 343.941 683.205 345.66 683.205 347.781C683.205 349.902 684.919 351.621 687.034 351.621Z" fill="white"/>
      <path d="M329.966 235.701C332.081 235.701 333.795 233.982 333.795 231.861C333.795 229.74 332.081 228.021 329.966 228.021C327.851 228.021 326.137 229.74 326.137 231.861C326.137 233.982 327.851 235.701 329.966 235.701Z" fill="white"/>
      <path d="M694.932 313.461C697.046 313.461 698.761 311.742 698.761 309.621C698.761 307.5 697.046 305.781 694.932 305.781C692.817 305.781 691.103 307.5 691.103 309.621C691.103 311.742 692.817 313.461 694.932 313.461Z" fill="white"/>
      <path d="M322.068 273.861C324.183 273.861 325.898 272.142 325.898 270.021C325.898 267.9 324.183 266.181 322.068 266.181C319.954 266.181 318.239 267.9 318.239 270.021C318.239 272.142 319.954 273.861 322.068 273.861Z" fill="white"/>
      <path d="M695.171 275.301C697.286 275.301 699 273.582 699 271.461C699 269.34 697.286 267.621 695.171 267.621C693.056 267.621 691.342 269.34 691.342 271.461C691.342 273.582 693.056 275.301 695.171 275.301Z" fill="white"/>
      <path d="M321.829 312.261C323.944 312.261 325.658 310.542 325.658 308.421C325.658 306.3 323.944 304.581 321.829 304.581C319.714 304.581 318 306.3 318 308.421C318 310.542 319.714 312.261 321.829 312.261Z" fill="white"/>
      <path d="M687.273 236.421C689.388 236.421 691.102 234.702 691.102 232.581C691.102 230.46 689.388 228.741 687.273 228.741C685.158 228.741 683.444 230.46 683.444 232.581C683.444 234.702 685.158 236.421 687.273 236.421Z" fill="white"/>
      <path d="M329.966 350.901C332.081 350.901 333.795 349.182 333.795 347.061C333.795 344.94 332.081 343.221 329.966 343.221C327.851 343.221 326.137 344.94 326.137 347.061C326.137 349.182 327.851 350.901 329.966 350.901Z" fill="white"/>
      <path d="M671.238 199.941C673.353 199.941 675.067 198.222 675.067 196.101C675.067 193.98 673.353 192.261 671.238 192.261C669.124 192.261 667.409 193.98 667.409 196.101C667.409 198.222 669.124 199.941 671.238 199.941Z" fill="white"/>
      <path d="M346.001 387.381C348.115 387.381 349.83 385.662 349.83 383.541C349.83 381.42 348.115 379.701 346.001 379.701C343.886 379.701 342.171 381.42 342.171 383.541C342.171 385.662 343.886 387.381 346.001 387.381Z" fill="white"/>
      <path d="M647.785 167.781C649.9 167.781 651.614 166.062 651.614 163.941C651.614 161.82 649.9 160.101 647.785 160.101C645.67 160.101 643.956 161.82 643.956 163.941C643.956 166.062 645.67 167.781 647.785 167.781Z" fill="white"/>
      <path d="M369.215 419.541C371.33 419.541 373.044 417.822 373.044 415.701C373.044 413.58 371.33 411.861 369.215 411.861C367.1 411.861 365.386 413.58 365.386 415.701C365.386 417.822 367.1 419.541 369.215 419.541Z" fill="white"/>
      <path d="M619.306 141.861C621.42 141.861 623.135 140.142 623.135 138.021C623.135 135.9 621.42 134.181 619.306 134.181C617.191 134.181 615.477 135.9 615.477 138.021C615.477 140.142 617.191 141.861 619.306 141.861Z" fill="white"/>
      <path d="M397.694 445.461C399.809 445.461 401.523 443.742 401.523 441.621C401.523 439.5 399.809 437.781 397.694 437.781C395.579 437.781 393.865 439.5 393.865 441.621C393.865 443.742 395.579 445.461 397.694 445.461Z" fill="white"/>
      <path d="M585.083 121.941C587.198 121.941 588.912 120.222 588.912 118.101C588.912 115.98 587.198 114.261 585.083 114.261C582.968 114.261 581.254 115.98 581.254 118.101C581.254 120.222 582.968 121.941 585.083 121.941Z" fill="white"/>
      <path d="M431.917 465.381C434.032 465.381 435.746 463.662 435.746 461.541C435.746 459.42 434.032 457.701 431.917 457.701C429.802 457.701 428.088 459.42 428.088 461.541C428.088 463.662 429.802 465.381 431.917 465.381Z" fill="white"/>
      <path d="M547.271 109.701C549.385 109.701 551.1 107.982 551.1 105.861C551.1 103.74 549.385 102.021 547.271 102.021C545.156 102.021 543.441 103.74 543.441 105.861C543.441 107.982 545.156 109.701 547.271 109.701Z" fill="white"/>
      <path d="M469.73 477.861C471.845 477.861 473.559 476.142 473.559 474.021C473.559 471.9 471.845 470.181 469.73 470.181C467.615 470.181 465.901 471.9 465.901 474.021C465.901 476.142 467.615 477.861 469.73 477.861Z" fill="white"/>
      <path d="M508.5 105.621C510.615 105.621 512.329 103.902 512.329 101.781C512.329 99.6601 510.615 97.9409 508.5 97.9409C506.385 97.9409 504.671 99.6601 504.671 101.781C504.671 103.902 506.385 105.621 508.5 105.621Z" fill="white"/>
      <path d="M508.5 481.941C510.615 481.941 512.329 480.222 512.329 478.101C512.329 475.98 510.615 474.261 508.5 474.261C506.385 474.261 504.671 475.98 504.671 478.101C504.671 480.222 506.385 481.941 508.5 481.941Z" fill="white"/>
      <path d="M469.251 109.701C471.366 109.701 473.08 107.982 473.08 105.861C473.08 103.74 471.366 102.021 469.251 102.021C467.136 102.021 465.422 103.74 465.422 105.861C465.422 107.982 467.136 109.701 469.251 109.701Z" fill="white"/>
      <path d="M547.749 477.621C549.863 477.621 551.578 475.902 551.578 473.781C551.578 471.66 549.863 469.941 547.749 469.941C545.634 469.941 543.919 471.66 543.919 473.781C543.919 475.902 545.634 477.621 547.749 477.621Z" fill="white"/>
      <path d="M432.156 121.941C434.271 121.941 435.985 120.222 435.985 118.101C435.985 115.98 434.271 114.261 432.156 114.261C430.042 114.261 428.327 115.98 428.327 118.101C428.327 120.222 430.042 121.941 432.156 121.941Z" fill="white"/>
      <path d="M584.843 465.621C586.958 465.621 588.672 463.902 588.672 461.781C588.672 459.66 586.958 457.941 584.843 457.941C582.729 457.941 581.014 459.66 581.014 461.781C581.014 463.902 582.729 465.621 584.843 465.621Z" fill="white"/>
      <path d="M397.694 141.861C399.809 141.861 401.523 140.142 401.523 138.021C401.523 135.9 399.809 134.181 397.694 134.181C395.579 134.181 393.865 135.9 393.865 138.021C393.865 140.142 395.579 141.861 397.694 141.861Z" fill="white"/>
      <path d="M619.306 445.461C621.42 445.461 623.135 443.742 623.135 441.621C623.135 439.5 621.42 437.781 619.306 437.781C617.191 437.781 615.477 439.5 615.477 441.621C615.477 443.742 617.191 445.461 619.306 445.461Z" fill="white"/>
      <path d="M368.976 168.021C371.09 168.021 372.805 166.302 372.805 164.181C372.805 162.06 371.09 160.341 368.976 160.341C366.861 160.341 365.146 162.06 365.146 164.181C365.146 166.302 366.861 168.021 368.976 168.021Z" fill="white"/>
      <path d="M648.024 419.541C650.139 419.541 651.854 417.822 651.854 415.701C651.854 413.58 650.139 411.861 648.024 411.861C645.91 411.861 644.195 413.58 644.195 415.701C644.195 417.822 645.91 419.541 648.024 419.541Z" fill="white"/>
      <path d="M346.24 199.461C348.355 199.461 350.069 197.742 350.069 195.621C350.069 193.5 348.355 191.781 346.24 191.781C344.125 191.781 342.411 193.5 342.411 195.621C342.411 197.742 344.125 199.461 346.24 199.461Z" fill="white"/>
      <path d="M670.76 388.101C672.875 388.101 674.589 386.382 674.589 384.261C674.589 382.14 672.875 380.421 670.76 380.421C668.645 380.421 666.931 382.14 666.931 384.261C666.931 386.382 668.645 388.101 670.76 388.101Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear" x1="694" y1="221" x2="120" y2="756" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stopOpacity="0.2"/>
      <stop offset="1" stopColor="white" stopOpacity="0.05"/>
      </linearGradient>
      </defs>
      </svg>
    </div>
  )
};

export default BgRight2;
