import React, { useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { addLeadActionPublic } from '../../actions/clients';
import { errorToast, standartErrorToast, successToast } from '../../components/toasts';
import { scrollToRef } from '../../utils';
import { getFormWithUTM } from '../../utils/location';
import './index.scss';

const SaleTeacher = (props) => {
  const applicationRef = useRef(null);
  const user = useSelector(state => state.user?.info)
  const [name, setName] = useState(`${user?.name || ''}${ user?.lastName || ''}`);
  const [phone, setPhone] = useState(user?.phone);
  const [agency, setAgency] = useState(user?.workCompany);
  const [errors, setErrors] = useState({
    phone: false,
    name: false,
  })
  const handleSubmit = () => {
    setErrors({
      phone: !phone,
      name: !name
    })
    if (!name || !phone) return errorToast(<div>Заполните обязательные поля</div>);

    const form = getFormWithUTM();

    form.append("name", name);
    form.append("phones[]", phone);
    form.append("title", "Заявка со страница Репетитор по продажам");
    form.append("comment", agency);

    addLeadActionPublic(form).then(
      resp => successToast(
        <div className="Toastify__text">
          Заявка отправлена
        </div>
      ),
      standartErrorToast
    )
  }


  const onScrollBtnClick = (evt) => {
    evt.preventDefault();
    scrollToRef(applicationRef, 70);
  };

  return (
    <>
      <section className="future section section_view_white sale-teacher">

        <section className="promo">
          <div className="wrapper">
            <img
              className="promo__image"
              src={require('../../assets/images/saleTeacher/promo.svg').default}
              alt=""
            />
            <div className="wrapper__center wrapper__center_start">
              <div className="promo__container">

                <div className="promo__box">

                  <h1 className="promo__box-title h1">
                    Репетитор по продажам
                  </h1>

                  <p className="promo__box-text big-text">Уважаемые партнеры, предлагаем вам пройти индивидуальное обучение в вашем агентстве. </p>

                  <div className="promo__advantages">
                    <div className="promo__advantages-item">
                      <h4 className="promo__advantages-title">Ваш офис</h4>
                      <p className="promo__advantages-text">Место проведения</p>
                    </div>
                    <div className="promo__advantages-item">
                      <h4 className="promo__advantages-title">3 часа</h4>
                      <p className="promo__advantages-text">Длительность</p>
                    </div>
                    <div className="promo__advantages-item">
                      <h4 className="promo__advantages-title">5 человек</h4>
                      <p className="promo__advantages-text">Минимальное <span className="nowrap">кол-во</span> участников</p>
                    </div>
                  </div>

                  <button onClick={onScrollBtnClick} type="button" className="promo__btn button button_view_default">Оставьте заявку</button>
                </div>

              </div>
            </div>
          </div>
        </section>


        <section className="about future__section">
          <div className="wrapper">
            <div className="wrapper__center wrapper__center_start">
              <h2 className="h2 future__h2">Индивидуальное обучение — это:</h2>

              <div className="about__list">
                <div className="about__item">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/about-1.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Необходимая теория + отработка полученных знаний на практике</p>
                </div>

                <div className="about__item about__item_pad_55">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/about-2.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Гибкость и мобильность программы</p>
                </div>

                <div className="about__item">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/about-3.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Обучение в удобное время</p>
                </div>

                <div className="about__item">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/about-4.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Небольшое кол-во учеников, только ваши сотрудники</p>
                </div>

                <div className="about__item about__item_pad_45 about__item_last">
                  <img
                    className="about__image about__image_mb_5"
                    src={require('../../assets/images/saleTeacher/about-5.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Формат, подходящий для оттачивания мастерства, расстановки акцентов и получения обратной связи</p>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="about future__section about_second">
          <div className="wrapper">
            <div className="wrapper__center wrapper__center_start">
              <h2 className="h2 future__h2">Что дает обучение:</h2>

              <div className="about__list">
                <div className="about__item about__item_pad_45">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/benefits-1.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Возможность расширить или более глубоко изучить теорию.</p>
                </div>

                <div className="about__item about__item_pad_45">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/benefits-2.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Скрипты, чек листы, алгоритм действий на этапах работы с клиентом.</p>
                </div>

                <div className="about__item about__item_pad_55">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/benefits-3.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Отработать полученные знания на практике. Перенять рабочие кейсы.</p>
                </div>

                <div className="about__item about__item_pad_35 about__item_last">
                  <img
                    className="about__image"
                    src={require('../../assets/images/saleTeacher/benefits-4.svg').default}
                    alt=""
                  />
                  <p className="about__text big-text">Получить ответы на интересующие вопросы и консультацию практикующего специалиста.</p>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="reviews future__section">
          <div className="wrapper">
            <div className="wrapper__center wrapper__center_start">
              <h2 className="h2 future__h2">Отзывы</h2>

              <div className="reviews__list">
                <div className="reviews__item">
                  <div className="reviews__author">
                    <h4 className="h4 reviews__author-name">Кирилл</h4>
                    <p className="reviews__author-agency">Love Realty</p>
                  </div>

                  <div className="reviews__text big-text">
                    <p>
                    Хотелось бы отметить профессионализм Светланы в качестве тренера по продажам - виден огромный  опыт как в продажах, так и в умении доносить информацию четко, ясно и, главное, доходчиво.<br />
                    Время на тренинге пролетело незаметно и все участники с удовольствием бы поучились у Светланы в дальнейшем.<br/>
                    Отдельно хочется отметить практичность советов - в материале обучения практически отсутствует вода, а самое главное - Светлана умеет корректно и очень точно поправить ошибки каждого менеджера.
                    </p>
                  </div>
                </div>
                <div className="reviews__item">
                  <div className="reviews__author">
                    <h4 className="h4 reviews__author-name">Андрей</h4>
                    <p className="reviews__author-agency">Love Realty</p>
                  </div>

                  <div className="reviews__text big-text">
                    <p>
                      Было получено очень много нужной информации для любого риэлтора. Огромное количество необходимых приемов для ведения клиентов по воронке продаж. Было видно, что Светлана профессионал в проживала каждый этап воронки продаж лично и теперь делится своим опытом, помогая нам эффективнее работать со своими клиентами.<br />
                      Немаловажно, что она всю информацию доносит простым и понятным каждому человеку языком.
                    </p>
                  </div>
                </div>
                <div className="reviews__item">
                  <div className="reviews__author">
                    <h4 className="h4 reviews__author-name">АН Метрика</h4>

                  </div>

                  <div className="reviews__text big-text">
                    <p>
                      Отлично прокачали навыки общения в рамках телефонных звонков и личных встреч! Хорошо подзарядились после Новогодних праздников и готовы к новым подвигам и высоким результатам)
                    </p>
                  </div>
                </div>
                <div className="reviews__item">
                  <div className="reviews__author">
                    <h4 className="h4 reviews__author-name">АН Экспонента Недвижимость</h4>

                  </div>

                  <div className="reviews__text big-text">
                    <p>
                      От лица коллектива Экспонента Недвижимость хочу выразить огромную благодарность Светлане за отличное обучение по Звонкам.<br />
                      Темы и техники, которые мы рассмотрели на обучении, были актуальными и полезными. Будем применять 👍<br />
                      Светлана профессионал своего дела, слушали её с большим интересом, 3 часа пролетели на одном дыхании.<br />
                      Большое спасибо за обучение.<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="future__section application" ref={applicationRef}>
          <div className="wrapper">
            <div className="wrapper__center wrapper__center_start">
              <h2 className="h2 future__h2 future__h2_mb_sm">Оставить заявку</h2>
              <form action="#" className="application__form modal-form modal-form_width_470">
                <div className="modal-form__row">
                  <div className="modal-form__item">
                    <label className="input input_width_available input_type_form" htmlFor="sign-in_surname">
                      <span className="input__label">Имя и фамилия</span>
                      <input
                        onChange={e => setName(e.target.value)}
                        name="lastName"
                        id="sign-up_surname"
                        className="input__control"
                        value={name}
                        placeholder="Введите имя и фамилию"
                      />
                      {errors.name && !name &&(
                        <span className="input__error-label">
                          Введите имя и фамилию
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="modal-form__row">
                  <div className="modal-form__item">
                    <label
                      className="input input_width_available input_type_form"
                      htmlFor="modal-reservation-phone"
                    >
                      <span className="input__label">Телефон</span>
                      <InputMask
                        onChange={e => setPhone(e.target.value)}
                        className={
                          'input__control'
                        }
                        name="phone"
                        placeholder="Введите телефон"
                        mask="+7 (999) 999-99-99"
                        autoComplete="off"
                        maskChar=" "
                        value={phone}
                      />
                      {errors.phone && !phone && (
                        <span className="input__error-label">
                          Введите телефон
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="modal-form__row">
                  <div className="modal-form__item">
                    <label className="input input_width_available input_type_form" htmlFor="sign-in_surname">
                      <span className="input__label">Агентство</span>
                      <input
                        onChange={e => setAgency(e.target.value)}
                        name="lastName"
                        id="sign-up_surname"
                        className="input__control"
                        placeholder="Введите название агентства"
                        value={agency}
                      />
                    </label>
                  </div>
                </div>

                <div className="modal-form__row">
                  <div className="modal-form__item application__submit">
                    <button
                      className="button button_view_default btn"
                      type="button"
                      onClick={handleSubmit}
                    >Оставьте заявку
                    </button>
                  </div>
                </div>

                <p className="application__text">Если Вы хотите записаться на обучение, звоните своему управляющему отдела развития или на общий номер: 426-18-63.</p>
              </form>

            </div>
          </div>
        </section>

      </section>
    </>
  );
};

export default SaleTeacher;
