const initialState = {
  isFetching: false,
  error: false,
  data: {
    meetingRooms: [],
  },
  clients: [],
};

const meetingRooms = (state = initialState, action) => {
  switch (action.type) {
    case "MEETING_ROOMS_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MEETING_ROOMS_TYPES_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MEETING_ROOMS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
      };
    case "MEETING_ROOMS_TYPES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.data,
      };
    case "MEETING_DATES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        error: false,
        dates: action.dates,
      };
    case "MEETING_ROOMS_CLIENTS_SUCCESS":


      return {
        ...state,
        isFetching: false,
        error: false,
        clients: action.clients,
      };
      case "ADD_USER_CLIENT":
        let clients = {
          id: action.data.id,
          title: `${action.data.name} ${action.data.lastName}`,
        }
        return {
          ...state,
          clients: [clients, ...state.clients ],
        };
    case "MEETING_ROOMS_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case "MEETING_ROOMS_RESET":
      return { ...initialState };
    default:
      return state;
  }
};

export default meetingRooms;
