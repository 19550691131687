import React, { useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import { ReactComponent as SavedFilterIcon } from "../../../assets/images/icons/SaveFilter.svg";
import { sortlistCommercial, sortlistPrimary } from "../../../hooks/useFilter/filterSets";
import { extractPrimaryMapItems } from "../../../hooks/useFilter/filterUtils";
import useMedia from "../../../hooks/useMedia";
import { getSuffix, media } from "../../../utils";
import DraggableZone from "../../FilterRealty/Draggable";
import SmartFilter from "../../FilterRealty/SmartFilter";
import LoadMoreSpinner from "../../LoadMoreSpinner";
import Preloader from "../../Preloader";
import SendFilter from "../../SavedFilters/SendFilter";
import SubmitButton from "../../SubmitButton";
import Dropdown from "../../core/Dropdown";
import MobileModal from "../../core/MobileModal";
import Modal from "../../core/Modal";
import Wrap from "../../core/ModalWrap";
import CardSkeleton from "./CardSkeleton";
import CardsList from "./CardsList";
import EstateCardContainer from "./Containers/EstateCardContainer";
import FilterToggleBtn from "./FilterToggleBtn";
import RealtyMap from "./Map";
import MapBlock from "./MapBlock";
import MapBtn from "./MapBtn";
import MapFilters from "./MapFilters/Index";
import PrimaryBottomFilters from "./PrimaryBottomFilters";
import PrimaryMapsFilters from "./PrimaryMapsFilters";
import PrimaryTopFilters from "./PrimaryTopFilters";
import TopBar from "./TopBar";
import PrimaryCommercialTopFilters from "./PrimaryCommercialTopFilters";
import PrimaryCommercialBottomFilters from "./PrimaryCommercialBottomFilters";
import PrimaryCommercialMapsFilters from "./PrimaryCommercialMapsFilters";
import { FilterSearch } from "../../FilterRealty/FilterSearch";
import EmptyTable from "../../core/EmptyTable";
import {TgDubaiIcon} from "./icons";
import useDocTitle from "../../../hooks/useDocTitle";

const EstatePage = props => {

  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      blocks,
      filter: filterData,
      savedFilter
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    limit,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,
    clearFilter,
    clearExclude,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    clearByIdent,
    favoritesProps,
    get,
    isCommercial
  } = props

  const TopFilters = isCommercial ? PrimaryCommercialTopFilters : PrimaryTopFilters;
  const BottomFilters = isCommercial ? PrimaryCommercialBottomFilters : PrimaryBottomFilters;
  const MapFiltersComponent = isCommercial ? PrimaryCommercialMapsFilters : PrimaryMapsFilters;

  const SfButton = ({ onClick }) => <div className="villages-save-filter" onClick={onClick}>
    <SavedFilterIcon />
    <span>Сохранить фильтр</span>
  </div>
  const FilterSubmitButton = ({ onClick, isLoading, count }) => {
    return (
      <SubmitButton
        className="sf__filter-btn btn btn_primary h6"
        onClick={onClick}
        isLoading={isLoading}
        type="button"
        disabled={!count?.countBlocks}
      >
        {`${count?.countBlocks ?? 0} объект${getSuffix(count?.countBlocks ?? 0)}`}
      </SubmitButton>
    );
  };

  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');

  const [showFilter, toggleFilter] = useState(false);

  const { city = "spb" } = useParams()

  const mainTitle = `${isCommercial ? "Коммерческая недвижимость" : "Новостройки"} ${city === 'spb' ? 'Санкт-Петербурга' : city === 'msk' && 'Москвы'}`;

  useDocTitle(mainTitle);

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="primary"
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap >
    ),
    [filter, exclude, filterData],
  );


  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);
  const saveToLocalSotage = id => {
    const tmpFilter = savedFilter?.filter ? { ...savedFilter.filter, ...filter } : filter;
    const tmpExclude = savedFilter?.exclude ? { ...savedFilter.exclude, ...exclude } : exclude;
    localStorage.setItem(isCommercial? "commercialBlocksFilter" : "blocksFilter", JSON.stringify({ [id]: { filter: tmpFilter, exclude: tmpExclude } })) // для новой карточки ЖК нужно это
  }

  const getLink = ({ id }) => `/realty/${city}/complex/${id}${isCommercial ? "?type=commercial" : ""}`;

  if (isLoading) return <Preloader loading={true} />;

  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={
            city === 'spb' ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
          }
          {...mapState}
          items={mapItems?.blocks}
          extractFeatures={extractPrimaryMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}

        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems?.blocks
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={EstateCardContainer}
          getLink={getLink}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={MapFiltersComponent}
          count={`${count.countBlocks} объект${getSuffix(count.countBlocks)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          isShowFilter
          onClick={saveToLocalSotage}
          dubai
          {...favoritesProps}
          get={get}
          wrapperClass={"sf__filter-item"}
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      <h3 className="h3 sf__h1 with-tg-link">
        <p>{mainTitle}</p>
        {city === 'spb' &&
          <a href={'https://t.me/panpartner'} target="_blank" className="with-tg-link__link">
            Telegram-канал ПАН Партнер
            <TgDubaiIcon/>
          </a>
        }
      </h3>
      {
        !isLoading &&
        <>
          <div className={`sf__filter filter__max form smartfilter-wrapper smartfilter-wrapper_primary`}>
            <FilterSearch
              filterData={filterData}
              config={{
                fields: {
                  blocks: {
                    addLink: ({ xmlId, id }) => `/realty/${city}/complex/${city === "dxb" ? xmlId : id}${window.location.pathname.includes("commercial") ? "?type=commercial" : ""}`
                  },
                  villages: {
                    addLink: ({ id }) => `/suburban/spb/${id}`
                  },
                  builders: {},
                  districts: {},
                  microDistricts: {},
                  subways: {},

                }
              }}

              placeholder="ЖК, район, метро, застройщик"
              toggleFilter={() => toggleFilter(prev => !prev)}
              filterValues={filter}
              excludeValues={exclude}
              handleFilter={setFilterAndExclude}
              hideInfoButton
              submit={submit}
            />

            <TopFilters
              filterData={filterData}
              handleFilter={handleFilter}
              filter={filter}
              setFilterAndExclude={setFilterAndExclude}
              clearByIdent={clearByIdent}
              disableButton={!count}
              onSumbit={submit}
              isLoading={isCountLoading || isLoading}
            />
            <button
              className="button button_type_reveal-filter white-button"
              type="button"
              onClick={() => toggleFilter(!showFilter)}
            >
              <span >Все фильтры</span>
            </button>
          </div>


          {(isLaptop || (!isTablet && showFilter)) && <div style={{ padding: "0 16px", position: 'relative', paddingBottom: '40px' }}>
            <div className="sf__filter filter__max form smartfilter-wrapper_primary smartfilter-wrapper_primary-bottom">
              {!isTablet && showFilter &&
                <BottomFilters
                  filter={filter}
                  filterData={filterData}
                  handleFilter={handleFilter}
                  setFilterAndExclude={setFilterAndExclude}
                  clearByIdent={clearByIdent}
                  clearExcludeOnly={clearExclude}
                  clearFilterOnly={clearFilter}
                  wrapperClass={"sf__filter-item filter_item_temp"}
                />

              }
            </div>
          </div>
          }

          <DraggableZone
            filterBadges={filterBadges}
            excludeBadges={excludeBadges}
            clearFilter={clearFilter}
            clearExclude={clearExclude}
            isExcludeEmpty={isExcludeEmpty}
            isFilterEmpty={isFilterEmpty}
            removeBadge={removeBadge}
            switchValues={switchFilterToExclude}
          />

          <div className="sf__row">
            {!isTablet && <SfButton onClick={saveFilterModal} />}
            <div />
            <div className="sf__filter-btns">
              {isTablet && <FilterToggleBtn />}
              <MapBtn
                setShowMap={() => setView('map')}

              />
              {!isTablet && (
                <span style={isLaptop ? { marginLeft: 20 } : {}}>
                  <FilterSubmitButton
                    onClick={props.submit}
                    isLoading={isCountLoading || isLoading}
                    count={count}
                  />
                </span>
              )}
            </div>
          </div>

        </>
      }
      {!blocks?.length && !showSkeleton && <EmptyTable title="Ничего не найдено" hideBorder />}
      {!!blocks?.length && <div className="special-filter-drop" style={{ marginBottom: -15, marginTop: 20 }}>

        <Dropdown
          innerClass={``}
          value={sortId}
          options={isCommercial ? sortlistCommercial : sortlistPrimary}
          onChange={handleSort}
        />
      </div>}
      <CardsList
        items={blocks || []}
        limit={limit}
        isLoading={showSkeleton || !blocks}
        itemsRef={infiniteRef}
        cardComponent={EstateCardContainer}
        getLink={getLink}
        listClass="sf__cards-list sf__cards-list_subuebans"
        onClick={saveToLocalSotage}
        {...favoritesProps}
        get={get}
        moreIsLoading={moreIsLoading}
      />
      <div style={{ marginTop: '-30px', marginBottom: 30 }}>
        <LoadMoreSpinner loading={moreIsLoading} />
      </div>

    </div>
  )
}

export default EstatePage;