import { maxBy } from 'lodash';
import React, { createContext, useEffect, useRef, useState } from 'react';

/**
 * @example   
 * 
  const { elements, setElements, getElementsMaxHeight } = useContentStateContext()
  <li className="compare-slider__list-item" ref={ref=>setElements(ref, field)} style={{minHeight: getElementsMaxHeight(field)}}>

 */

const ContentStateContex = createContext();

export const useContentStateContext = () => {

    const { elements } = React.useContext(ContentStateContex);
    const [___, setJustRender] = useState(true)
    useEffect(() => setJustRender(false), [])

    const setElements = (ref, key) => {
        elements.current = {
            ...elements.current,
            ...(elements.current[key] ? { [key]: [...elements.current[key], ref] } : { [key]: [ref] })
        }
    }

    const getElementsMaxHeight = (key) => maxBy(elements.current[key], "clientHeight")?.clientHeight

    return { elements: elements.current, setElements, getElementsMaxHeight }
}

export const ContentStateContexProvider = ({ children }) => {
    const elements = useRef({})

    return (
        <ContentStateContex.Provider value={{ elements: elements }}>
            {children}
        </ContentStateContex.Provider>
    );
}
