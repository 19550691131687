import React, { memo } from "react";
import { Link } from "react-router-dom";
import useMedia from "../../hooks/useMedia";
import { getUrl } from "../../api";

export const Logo = memo(({ logo }) => <Link to="/" className="header__logo work-company__logo">
    {logo ? <img className="icon icon_logo" src={getUrl(logo?.src)} alt="logo" /> :
        <svg className="icon icon_logo">
            <use xlinkHref="#logo"></use>
        </svg>
    }
</Link>)

export const TgIcon = memo(() => {
    const isMobile = useMedia("isMobile");
    if (isMobile) return (
        <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.15"
                d="M21.0619 0.104664C21.0871 0.0878691 21.6246 -0.155654 22.1032 0.163445C22.4727 0.415365 22.649 0.894014 22.5231 1.35587C21.3558 6.86452 20.1802 12.3732 19.013 17.8818C19.0046 17.949 18.9374 18.478 18.4588 18.7636C18.1397 18.9567 17.7366 18.9819 17.3839 18.8307C17.2915 18.7887 17.1992 18.73 17.1152 18.6712C14.3441 16.5886 11.5729 14.5061 8.80182 12.4152L18.4672 3.72392C18.4923 3.68193 18.6015 3.54757 18.5595 3.4636C18.5259 3.39642 18.4084 3.37123 18.2488 3.39642C18.0977 3.41322 17.9549 3.472 17.829 3.55597C13.857 6.05838 9.89347 8.56079 5.92153 11.0632L0.52204 9.42571C0.295312 9.25776 -0.0237874 8.98905 0.00140465 8.66995C0.0265967 8.37604 0.337298 8.17451 0.454861 8.09893C1.42895 7.49432 17.1908 1.5574 21.0619 0.104664Z"
                fill="#8F95AC"
            />
            <path
                d="M8.8023 12.4233L8.45801 17.764C8.58397 17.7557 8.76871 17.7389 8.97864 17.6465C9.23056 17.5373 9.39851 17.3778 9.49088 17.277C10.3222 16.4541 11.1536 15.6311 11.9849 14.8082L8.8023 12.4233Z"
                fill="#8F95AC"
            />
            <path
                opacity="0.5"
                d="M8.44947 17.7642C8.30672 17.7642 8.13877 17.739 8.00442 17.6298C7.84487 17.4955 7.79448 17.3024 7.78608 17.252C7.69371 16.8741 6.95475 14.4305 5.92188 11.0715L17.8293 3.5643C17.8797 3.53071 17.9469 3.48873 18.0392 3.45514C18.1904 3.39636 18.4759 3.32918 18.5515 3.43834C18.5851 3.48033 18.5683 3.54751 18.5599 3.5727C18.5599 3.5727 18.5431 3.65667 18.4675 3.73225C18.3667 3.83302 14.3192 7.46907 8.80216 12.4235L8.44947 17.7642Z"
                fill="#8F95AC"
            />
        </svg>
    );

    return (
        <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4984 0.0770167C15.5169 0.0646584 15.9124 -0.114538 16.2646 0.120271C16.5365 0.305646 16.6663 0.657859 16.5736 0.997714C15.7147 5.05125 14.8496 9.10479 13.9907 13.1583C13.9845 13.2078 13.9351 13.5971 13.5829 13.8071C13.3481 13.9493 13.0515 13.9678 12.7919 13.8566C12.724 13.8257 12.656 13.7824 12.5942 13.7392C10.5551 12.2067 8.51594 10.6743 6.47681 9.13569L13.589 2.74024C13.6076 2.70934 13.6879 2.61048 13.657 2.54869C13.6323 2.49925 13.5458 2.48072 13.4284 2.49925C13.3172 2.51161 13.2121 2.55487 13.1194 2.61666C10.1967 4.45805 7.2801 6.29945 4.35735 8.14084L0.384142 6.9359C0.217305 6.81232 -0.0175039 6.61458 0.00103361 6.37977C0.0195711 6.1635 0.248201 6.0152 0.334709 5.95959C1.05149 5.51469 12.6498 1.14601 15.4984 0.0770167Z"
                fill="white"
            />
            <path
                d="M6.47649 9.14172L6.22314 13.0717C6.31583 13.0655 6.45177 13.0531 6.60625 12.9852C6.79163 12.9048 6.91521 12.7874 6.98318 12.7133C7.59492 12.1077 8.20666 11.5022 8.8184 10.8966L6.47649 9.14172Z"
                fill="#A9C9DD"
            />
            <path
                d="M6.21735 13.0718C6.11231 13.0718 5.98872 13.0533 5.88986 12.9729C5.77245 12.8741 5.73538 12.732 5.7292 12.6949C5.66123 12.4168 5.11746 10.6187 4.35742 8.14701L13.1195 2.62283C13.1566 2.59811 13.206 2.56722 13.274 2.5425C13.3852 2.49925 13.5953 2.44981 13.6509 2.53014C13.6756 2.56104 13.6633 2.61047 13.6571 2.62901C13.6571 2.62901 13.6447 2.6908 13.5891 2.74641C13.515 2.82056 10.5366 5.49615 6.47688 9.14186L6.21735 13.0718Z"
                fill="#C8DAEA"
            />
        </svg>
    );
});

export const Burger = memo(({ show }) => <button className="button button_type_burger" onClick={show}>
    <svg className="icon icon_burger">
        <use xlinkHref="#burger"></use>
    </svg>
</button>
);

export const PhoneLink = memo(({ phone }) => <div className="header__top-phone sm-hide">
    <div className="header__top-anchors">
        <a className="header__top-phone-anchor" style={{ display: 'flex', alignItems: 'center' }} href={`tel:${phone}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 5 }}>
                <path d="M14.4016 4.66767C15.5836 4.98508 16.6614 5.60773 17.5268 6.47316C18.3923 7.33859 19.0149 8.41638 19.3323 9.59841" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.6791 7.35896C14.3897 7.54859 15.0377 7.92227 15.5577 8.4423C16.0777 8.96233 16.4514 9.61032 16.641 10.3209" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.57415 12.0551C9.29088 13.5358 10.488 14.7301 11.9705 15.4433C12.0799 15.4952 12.201 15.5177 12.3218 15.5085C12.4426 15.4993 12.5589 15.4588 12.6593 15.391L14.8372 13.9368C14.9337 13.8722 15.0448 13.8327 15.1604 13.8219C15.276 13.8112 15.3925 13.8295 15.4992 13.8752L19.5763 15.6268C19.7156 15.6847 19.8319 15.7868 19.9075 15.9173C19.9831 16.0479 20.0136 16.1997 19.9944 16.3493C19.8653 17.358 19.3731 18.285 18.6098 18.957C17.8466 19.6289 16.8646 19.9998 15.8477 20C12.7055 20 9.69199 18.7518 7.47011 16.5299C5.24824 14.308 4 11.2945 4 8.15229C4.00025 7.13539 4.37105 6.15342 5.04301 5.39017C5.71498 4.62691 6.64205 4.13468 7.65072 4.00559C7.80034 3.9864 7.95212 4.01693 8.08267 4.09249C8.21323 4.16806 8.31531 4.28445 8.3732 4.42374L10.1248 8.50888C10.1704 8.6138 10.1895 8.72834 10.1804 8.84239C10.1713 8.95644 10.1343 9.06649 10.0725 9.16283L8.61712 11.3698C8.55141 11.4706 8.51291 11.5867 8.50538 11.7068C8.49785 11.8268 8.52154 11.9468 8.57415 12.0551V12.0551Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {phone}
        </a>
    </div>
</div>
);

export const DropItemContent = memo(({ item }) => <div className="mobile-services__item">
    <span className="sub__title">{item.title}</span>
</div>)

export const topMenu = [
    {
        title: 'Новостройки',
        link: '/realty',
        submenu: [
            { title: 'Новостройки СПб', link: '/realty/spb' },
            { title: 'Новостройки Мск', link: '/realty/msk' },
            // { title: 'Новостройки Дубая', link: '/realty/dxb', isNew: true },
            // { title: 'Новостройки Северного Кипра', link: '/realty/cyp', isNew: true },
        ],
        className: ['sm-hidden'],
    },
    {
        title: 'Коммерция',
        link: '/commercial',
        className: ['sm-hidden'],
        submenu: [
            { title: 'Коммерция СПб', link: '/commercial/spb' },
            { title: 'Коммерция Мск', link: '/commercial/msk' },
        ],
    },
    { title: 'Переуступки', link: '/assignments', className: ['sm-hidden'] },
    // { title: 'Вторичка', link: '/secondary', className: ['sm-hidden'] },
    {
        title: 'Загородная',
        link: '/suburbans/spb/',
        submenu: [
            { title: 'Коттеджные поселки', link: '/suburbans/spb/' },
            { title: 'Строительство домов', link: '/build-house' },
        ],
        className: ['sm-hidden'],
    },
    { title: 'Дубай', link: '/realty/dxb', className: ['sm-hidden'] },
    { title: 'Кипр', link: '/realty/cyp', className: ['sm-hidden'] },
    // { title: "Ипотека", link: "/mortgage", className: ["md-hidden"] },
];


export const subMenuData = [
    {
        id: 0,
        title: 'АвтоПАН',
        // modal: 'autopan',
        // mobile: true,
        // className: ['md-hidden'],
        link: '/autopan',
        text: 'Комфортные просмотры объектов за КАДом',
        icon: 'autopan-01',
    },
    {
        id: 1,
        title: 'Trade-In',
        link: '/trade-in',
        text: 'Комиссия до 2,5% по нашей программе',
        icon: 'trade-in-01',
    },
    {
        id: 2,
        title: 'ПАН Комфорт',
        link: 'https://panpartner.ru/pan-comfort',
        external: true,
        text: 'Сеть переговорных по всему городу',
        icon: 'pan_comf-01',
    },
    {
        id: 3,
        title: 'Переговорные',
        link: '/personal/meetings',
        text: 'Для встреч в офисе ПАН',
        icon: 'meetings-01',
    },
    {
        id: 4,
        title: 'PAN School',
        link: '/pan-school',
        text: 'Готовим экспертов в сфере недвижимости',
        icon: 'school-01',
    },
    {
        id: 5,
        title: 'АвтоПАНорама',
        link: 'https://panpartner.ru/autopanorama',
        external: true,
        text: 'Обучающие экскурсии для агентов на автомобиле',
        icon: 'autopanorama-01',
    },
    {
        id: 7,
        title: 'Репетитор по продажам',
        link: '/sale-teacher',
        text: 'Научим продавать больше',
        icon: 'sale-teacher-01',
    },
    {
        id: 8,
        title: 'Выездное обучение',
        link: '/office-training',
        text: 'Застройщики, объекты, техники продаж',
        icon: 'sor',
    },
    {
        id: 9,
        title: 'Online оплата',
        link: '/payment',
        text: 'Оплата доп. услуг',
        icon: 'payment',
    },
    {
        id: 10,
        title: 'Вознаграждение по ипотеке',
        text: 'Дополнительные 0,3% за ипотечные сделки',
        link: '/cashback',
    },
];

export const bottomMenuData = [
    {
        title: 'Фиксация клиента',
        link: '/client-fixation',
        mobile: true,
        isHighlighted: true,
        className: ['md-hidden'],
    },
    { title: 'Запись на просмотр', link: '/book-a-tour' },
    { title: 'Мероприятия', link: '/events' },
    { title: 'Ипотека', link: '/mortgage' },
    {
        title: 'Личный сайт',
        tabletOnly: true,
        link: '/constructor-admin',
        goal: 'constructor_view',
    },
    { title: 'Умный бот', tabletOnly: true, link: '/telegram' },
    { title: 'Файлы', link: '/files' },
    {
        title: 'Сервисы',
        link: '/',
        mobile: true,
        openSub: true,
        className: ['md-hidden'],
    },
    {
        title: 'Контакты',
        link: '/contacts',
    },
];

export const rowServicesItems = (row, showModal) => {
    const items = [];

    row.forEach((item, i) => {
      items.push(
        item.modal ? (
          <li>
            <div
              className="mobile-services__link sub__link"
              key={`drop-menu-item-${i}`}
              onClick={() => showModal(item.modal)}
            >
              <DropItemContent item={item} />
            </div>
          </li>
        ) : item.external ? (
          <li>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              key={`drop-menu-item-${i}`}
              className="mobile-services__link sub__link"
            >
              <DropItemContent item={item} />
            </a>
          </li>
        ) : (
          <li>
            <Link
              className="mobile-services__link sub__link"
              to={item.link}
              key={`drop-menu-item-${i}`}
            >
              <DropItemContent item={item} />
            </Link>
          </li>
        ),
      );
    });

    return items;
  };