import React, { useState } from "react";
import useUniversalForm from '../../hooks/useUniversalForm';
import { standartErrorToast, standartSuccessToast } from "../toasts";
import "./index.scss";
import NewsShare from './Share.js';

const ActionSeptember = (props) => {

  const {
    isLoading,
    response,
    send,
  } = useUniversalForm({
    formId: 46,
    isOneResult: 1,
  });

  const [isParticipating, setIsParticipating] = useState(null);

  const onSend = () => {
    send().then(standartSuccessToast, standartErrorToast).then(() => setIsParticipating(true));
  };

  return (

    <section className="hot-news section section_view_white">
      <div className="wrapper news__wrapper">
        <NewsShare description={`Мастер-класс от Максима Батырева`} />
        <div className="news__container container news__container_smena">
          <h1 className="h1 news__title"><span role="img" aria-label="gift">🎁</span> Акция сентября для тех, кому ученье — свет</h1>
          <img src={require('../../assets/images/news/sep.png').default} alt="" className="news__promo-img" />
          <p className="h3 news__subtitle">
            Знания стали капиталом
          </p>

          <p className="news__text big-text">Бум спроса на знания уже не остановить! Получи конкурентное преимуществе на сложном рынке недвижимости. Участвуй в акции и развивайся вместе с ПАН Партнёр.</p>
          <p className="news__text big-text news__big-margin">Разыгрываем золотой билет сразу на 2 мастер-класса Максима Батырева, автора бестселлеров «45 татуировок менеджера», «45 татуировок продавана» и «45 татуировок личности», бизнес-спикера № 1 в России, который пройдёт 26 и 27 ноября в Санкт-Петербурге.</p>

          <p className="news__text h5 news__big-margin">Как выиграть:</p>
          <p className="news__text"><span role="img" aria-label="check">✅</span> Нажми кнопку «Участвовать» на этой странице</p>
          <p className="news__text"><span role="img" aria-label="check">✅</span> Проведи 3 сделки по ЛСР, ЦДС, Евроинвест, МАВИС и Лидер Групп с 8 сентября - 15 октября</p>

          <p className="news__text h5 news__big-margin">Обрати внимание!</p>
          <p className="news__text"><span role="img" aria-label="check">✅</span> Сделки суммируются</p>
          <p className="news__text"><span role="img" aria-label="check">✅</span> Билет на МК разыграем среди тех, кто выполнил оба пункта правил</p>
          <p className="news__text"><span role="img" aria-label="check">✅</span> Итоги конкурса подводим <b>16 октября</b> в прямом эфире Instagram Panpartner</p>

          <p className="news__text big-text news__big-margin"><span role="img" aria-label="pin">📌</span> Узнать о мастер-классе больше: <a className="link" href="https://clck.ru/XL5mY" target="_blank">https://clck.ru/XL5mY</a></p>

          <p className="h3 news__subtitle news__big-margin ">Не откладывай в дальний ящик, жми «Участвовать»</p>


          <button type="button" rel="noopener noreferrer" className="news__btn news__btn_left news__btn_mt-0 button button_view_default" onClick={onSend} disabled={isLoading || isParticipating || response.isExistResult}>
            {
              isParticipating || response.isExistResult ? `Уже участвуете` : `Участвовать`
            }
          </button>

        </div>
      </div>

    </section>
  )
};

export default ActionSeptember;
