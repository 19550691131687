import React from 'react';
import {getUrl} from "../../api"
import { media } from "../../utils"

const Sertificate = (props) => {
	const { data } = props;

	if (!data) return 'Loading...';
	const { object } = data;

	if (!object) return null;

	return (
		<div className="pdf-block">
			<div className="presentation__infrastructure for-sertificate" data-id="agent_certificate">
				<div className="presentation__title">
					Сертификат
				</div>
				{object.agentCertificate && (
					<>
						<div className="infrastructure__map for-sertificate">
							<img
								src={getUrl(object.agentCertificate.src)}
								alt=""
							/>
						</div>

						{media("isMobile") &&
							<div className="serticate-number-wrapper">
								Номер сертификата
								<div className="serticate-number">
									{object.agentCertificate.id}
								</div>
							</div>
						}
					</>
				)}
			</div>
		</div>
	);
};

export default Sertificate;
