import React from 'react';
import { Link } from 'react-router-dom';

const SuccessBlock = () => {
  return (
    <div className='hp__success'>
      <img className='hp__success-img' src={require(`../../assets/images/hp/success.svg`).default} alt="" />
      <p className="hp__sign hp__sign_sm color-brand-3 big-text text-center">
        Наши менеджеры получили Вашу заявку и&nbsp;свяжутся с Вами в ближайшее время
      </p>
      <a href={`/`} className="hp__success-btn button button_view_default">
        На главную
      </a>
    </div>

  )
}

export default SuccessBlock;

