import 'antd/es/table/style/index.css';
import React, { memo } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import useDropdown from '../../hooks/useDropdown';
import usePermission from '../../hooks/usePermission';
import { formatDate, media } from '../../utils';
import Modal from '../core/Modal';
import Table from '../core/Table';
import UnsubscribeForm from '../Events/UnsubscribeForm';
import { errorToast, infoToast } from '../toasts';


const EventName = memo(({ name, id }) => {
	return (
		<Link
			className="clients__table-link clients__table-client"
			target="_blank"
			to={`/events/${id}`}
		>
			{name}
			{!media('isMobile') &&
				<svg className="icon icon_external-link">
					<use xlinkHref="#external_link"></use>
				</svg>
			}
		</Link>
	);
});

const EventsAction = memo((props) => {
	const { unsubscribe, time, name, date } = props;

	const [node, open, show, hide] = useDropdown(false);

	const showConfirm = () => {
		unsubscribeModal();
		hide();
	};

	const onUnsubscribe = (comment) => {
		unsubscribe(props.id, props.agentId, comment).then(
			data => {
				closeUnsubscribeModal();
				infoToast(<div>{data.message}</div>)
			},
			error => errorToast(<div>{error?.[0]?.message}</div>)
		);
	};

	const [unsubscribeModal, closeUnsubscribeModal] = useModal(
		({ in: open, onExited }) => (
			<Modal close={closeUnsubscribeModal}>
				<UnsubscribeForm
					name={name}
					date={date}
					time={time}
					handleSubmit={onUnsubscribe}
				/>
			</Modal>
		),
		[name, date, time, onUnsubscribe],
	);

	return (
		<div class="control_more">
			<div class="form__item">
				<div
					ref={node}
					className={
						'dropdown js-dropdown form__drop' + (open ? ' is-showing' : '')
					}
				>
					<button
						type="link"
						class="dropdown__btn button form__drop-btn button_type_round"
						onClick={(e) => show()}
					>
						<span class="form__drop-name"></span>
						<svg class="icon icon_elipsis">
							<use xlinkHref="#elipsis"></use>
						</svg>
					</button>
					<div class="dropdown__block">
						<div class="dropdown__inner">
							<div class="form__drop-content">
								<div class="dt__more">
									<hr />
									<div class="dt__more-control" onClick={showConfirm}>
										<svg class="icon icon_delete">
											<use xlinkHref="#delete"></use>
										</svg>
										<span>Отменить запись</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

const Items = memo(props => {
	const { items, fetch, unsubscribe } = props;
	const isAgent = usePermission('agent');

	let mobileColumns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: '35%',
			render: (text, record) => {
				return <EventName {...record} />;
			},
		},
		{
			title: 'Агент',
			dataIndex: 'agentName',
			key: 'agentName',
			render: (name, record) => {
				return <span className="events__agent-name nowrap">{name}</span>;
			},
		},

		{
			title: '',
			key: 'action',
			width: 50,
			render: (text, record) => {
				if (record.statusId == 3) return null;
				return <EventsAction {...record} unsubscribe={unsubscribe} />;
			},
		},
	]

	let columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: '35%',
			render: (text, record) => {
				return <EventName {...record} />;
			},
		},
		{
			title: 'Агент',
			dataIndex: 'agentName',
			key: 'agentName',
			render: (name, record) => {
				return <span className="nowrap">{name}</span>;
			},
		},
		{
			title: 'Дата и время',
			dataIndex: 'date',
			key: 'date',
			width: 250,
			sorter: (a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1),
			render: (date, record) => {
				return (
					<div
						className="lk-events__date"
						style={{ justifyContent: 'space-between' }}
					>
						<span className="nowrap">
							{formatDate(date, {
								day: 'numeric',
								month: 'long',
								year: 'numeric',
							})}
						</span>
						<div className="lk-events__time" style={{ marginRight: '30px' }}>
							<span>{record.time}</span>
						</div>
					</div>
				);
			},
		},
		{
			title: 'Спикер',
			dataIndex: 'speaker',
			key: 'speaker',
			sorter: (a, b) => (a.speaker > b.speaker ? 1 : -1),
			render: (name) => {
				return <span className="nowrap">{name}</span>;
			},
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			sorter: (a, b) => (a.status > b.status ? 1 : -1),
			render: (status, record) => {
				return (
					<div class="lk-events__status" style={{ alignItems: 'center' }}>
						<div className={`label-item label_${record.statusColor}`}>
							{status}
						</div>
						{record.statusId == 3 && record.statusComment && (
							<div class="info lk-events__status-info">
								<svg class="icon icon_comment">
									<use xlinkHref="#comment"></use>
								</svg>
								<div class="info__popup">{record.statusComment}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			title: '',
			key: 'action',
			width: 50,
			render: (text, record) => {
				if (record.statusId == 3) return null;
				return <EventsAction {...record} unsubscribe={unsubscribe} />;
			},
		},
	];

	if (!isAgent && !media('isMobile')) {
		columns = columns.filter((item) => item.key != 'agentName');
	}

	if (!isAgent && media('isMobile')) {
		mobileColumns = mobileColumns.filter((item) => item.key != 'agentName');
	}

	const expandedRow = (record) => {
    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Дата и время:</span>
						<div
							className="lk-events__date"
							style={{ justifyContent: 'space-between' }}
						>
							<span className="nowrap">
								{formatDate(record.date, {
									day: 'numeric',
									month: 'long',
									year: 'numeric',
								})}
							</span>
							<div className="lk-events__time" style={{ marginRight: '30px' }}>
								<span>{record.time}</span>
							</div>
						</div>
          </div>

					<div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Спикер:</span>
						<span style={{ whiteSpace: 'normal' }}>{record.name}</span>
          </div>

					<div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Статус:</span>

						<div class="lk-events__status" style={{ alignItems: 'center' }}>
							<div className={`label-item label_${record.statusColor}`}>
								{record.status}
							</div>
							{record.statusId == 3 && record.statusComment && (
								<div class="info lk-events__status-info">
									<svg class="icon icon_comment">
										<use xlinkHref="#comment"></use>
									</svg>
									<div class="info__popup">{record.statusComment}</div>
								</div>
							)}
						</div>
          </div>
        </div>
      </div>
    )
  }

	return (
    <div className="orders pan-table lk-meetings__table">
      <div className="flats__row-table is-active">
				<Table
					rowKey="id"
					showSorterTooltip={false}
	        columns={media("isMobile") ? mobileColumns : columns}
	        expandable={media("isMobile") ? {
	          expandedRowRender: record => expandedRow(record),
	        } : false}
					dataSource={items}
					pagination={false}
					locale={{ emptyText: 'Ничего не найдено' }}
					customPagination={true}
				/>
			</div>
		</div>
	);
});

export default Items;
