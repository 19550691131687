
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { applyToFormData, combineFormData, scrollToRef, toFormData } from '../../utils';
import request from '../../api';
import {
    badgeTitles,
    createBadges,
    filterReducer,
    getTmpValue,
    isEmptyObject,
    realtyMapReducer,
    setter,
    specialBadgeValuesTitles,
    getDeleteCond
} from './filterUtils';
import { useRef } from 'react';
import axios from 'axios';
import useApi from '../useApi';
import { useReducer } from 'react';
import debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { useCallback } from 'react';
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import { useDispatch } from 'react-redux';
import { getFavoriteCount } from '../../actions/favorites';
import merge from 'lodash/merge';




/**
 * Жирный хук который умеет в фильтры с getCount, подгрузкой и тд, допиливать придется в процессе
 * Принимает 1 параметр - объект config, который содержит:
 * @param {Object} [apiConfig = {component: "", getCountFunction: "", submitFunction: "" }] - конфиг для запросов, включая getCount
 * @param {Object} [defaultExcludes = {}] - необязательный параметр, предустановленные исключения
 * @param {Object} [defaultFilter = {}] - необязательный параметр, предустановленный фильтр
 * @param {Object} [fields ={}] -  обязательный параметр, все поля которые могут быть в фильтре или исключениях с типом {blocks: [], prices: {}}
 * @param {Object} [defaultParams = {}]  - будут добавлены всегда перед отправкой
 * @param {Number} [defaultPage = 1]  - начальная страница, будет добавлена при отправке submitFunction, если paginationEnable === true
 * @param {Number} [defaultLimit = 9] - сколько показывать на 1 страницу, будет добавлена при отправке submitFunction, если paginationEnable === true
 * @param {String} defaultOrder - сколько показывать на 1 страницу, будет добавлена при отправке submitFunction, если sortEnabled === true
 * @param {String} defaultBy - сколько показывать на 1 страницу, будет добавлена при отправке submitFunction, если sortEnabled === true
 * @param {Boolean} paginationEnabled  - вкл пагинации
 * @param {Boolean} sortEnabled - вкл сортировки
 * @param {Array} shadowFilters - фильтры и исключения которые нужны, но они не должны показываться под фильтром, такие фильтры не участвуют при подсчёте количества фильтров
 * @param {String} loadMoreDataKey - при использовании handleLoadMore - данные будут извлекаться по этому пути, например чтобы айтемы брались из data.villages - "villages"
 * @param {Number} [scrollToRefOffset = 0] - величина контролирующая скролл после фильтрации и изменения лимитов
 * @return {Object}
 * @example
 *  
 const {
        count,
        data: {blocks: [], filterData: {}},
        getCount,
        handleExclude,
        handleFilter,
        handleLoadMore,
        isCountLoading,
        isExcludeEmpty,
        isFilterEmpty,
        isLoading,
        moreIsLoading,
        submit
    } = useFilter({
    apiConfig: {
      component: "panpartner:block.filter.ajax",
      getCountFunction: "getFilter",
      submitFunction: "getBlocks"
    },
    defaultFilter: {},
    defaultParams: {
      savedFilterXmlId: "19373ee4cb1c0ea6d7b1c78a6b1a466f",
      city: "spb"
    }
  })
 */
//  TODO: кешировать предыдущие страницы, сбрасывать кеш, если меняется сортировка/лимит/фильтры
const useFilter = (config, dep) => {
    const {
        apiConfig = {
            component: "",
            getCountFunction: "",
            submitFunction: ""
        },
        defaultExcludes = {},
        defaultFilter = {},
        fields = {},

        defaultParams = {},
        defaultPage = 1,
        defaultLimit = 9,
        defaultSortId = 0,
        defaultOrder,
        defaultBy,
        paginationEnabled,
        loadMoreEnabled,
        sortEnabled,
        shadowFilters = [],

        loadMoreDataKey,
        scrollToRefOffset = 0,
        isGetFilter = true,
        extractCount = resp => resp.count,
        sortList = [],
        extractLoadMoreCount = resp => resp.count,


        defaultView = "",
        extractMapItems = resp => { },


        mapReducer = realtyMapReducer,
        mapInitialState = {
            zoom: 9,
            mapCenter: [59.9342802, 30.3350986],
        },

        disableLoadMapItems = false,
        extractSubItems,

        refreshWhenFilterChange,
        refreshMethod,
        beforeRefresh,

        filterFromLS,
        legacyRestoreFromLS = true, // нужно чтобы не сломались старые карточки ЖК, в которых фильтр по дефолту полный
        restoreSF = false, // игнорируем фильтр из лс и восстанавливаем из data.savedFilter
        requiredFilters = [],

        defaultLoadCondition = true,
        favoritesParams,
        favoritePath,
        badgeFormattersParams = {},
        specialBadgeTitles,
        processData,
        configMutation, // сознательно, прости)
        debug = false,
        configAsRef = true
    } = config

    const configRef = useRef(config)

    const dispatch = useDispatch()

    const [loadCondition, setLoadCondition] = useState(defaultLoadCondition)
    const [isFilterChanged, setIsFilterChanged] = useState(false);
    const [view, _setView] = useState(defaultView)
    const [isCountLoading, setIsCountLoading] = useState(false)
    const [isFavoriteLoading, setIsFavoriteLoading] = useState(false)
    const [mapItems, setMapItems] = useState(false)
    const resetMapItems = () => setMapItems(false)
    const setView = view => {
        if (view === "map") dispatchMap({ type: 'setData', payload: { selectedItems: [] } });
        _setView(view)
    }
    const [pagination, setPagination] = useState({
        limit: defaultLimit,
        page: defaultPage,
        hasNextPage: false
    })
    const [sortId, setSortId] = useState(defaultSortId)

    const [sort, setSort] = useState({
        order: defaultOrder,
        by: defaultBy
    })

    const [filterState, dispatchFilter] = useReducer(filterReducer, {}, () => legacyRestoreFromLS || !filterFromLS ? {
        filter: defaultFilter,
        exclude: defaultExcludes

    } : merge({
        filter: { ...defaultFilter },
        exclude: { ...defaultExcludes },
    }, filterFromLS))

    const createSetter = type => (payload = {}) => {
        debug && console.trace("FILTER STATE SETTER:", type, payload,)
        dispatchFilter({ type, payload })
    }

    const setToStore = createSetter("setData")
    const [mapState, dispatchMap] = useReducer(mapReducer, mapInitialState)

    const [count, setCount] = useState(0);

    const toForm = (paginationEnabled, sortEnabled, isGetFilter, reset, resetPagination) => {
        const filterFormData = toFormData(reset ? defaultFilter : filterState.filter, "filter")
        const excludesFormData = toFormData(reset ? defaultExcludes : filterState.exclude, "exclude")
        if (isGetFilter) excludesFormData.append("isGetFilter", 1)
        applyToFormData(excludesFormData, configAsRef ? configRef.current.defaultParams : defaultParams)
        if (paginationEnabled) applyToFormData(excludesFormData, resetPagination ? { limit: defaultLimit, page: defaultPage } : pagination)
        if (sortEnabled && sort?.order) applyToFormData(excludesFormData, sort)
        return combineFormData(filterFormData, excludesFormData)
    }
    const [isFilterRestored, setIsFilterRestored] = useState(false)
    const restoreFromLocalStorage = resp => {
        if (resp) {
            setPagination(prev => ({ ...prev, hasNextPage: 1 * prev.limit < extractLoadMoreCount(resp) }));
            configMutation && configMutation(resp, config);
        }
        if ((!filterFromLS && !(resp.savedFilter && restoreSF)) || isFilterRestored || !legacyRestoreFromLS || !resp.filter) return Promise.resolve(resp);

        setIsFilterRestored(true)
        const filterData = resp.filter
        const newFilter = restoreSF ? resp.savedFilter : filterFromLS
        const { filter = {}, exclude = {} } = newFilter
        const tmpFilter = { ...defaultFilter, ...filter }
        const tmpExclude = { ...defaultExcludes, ...exclude }
        const result = {
            filter: {},
            exclude: {}
        }

        Object.entries(tmpFilter).forEach(([ident, values]) => {
            if (!filterData[ident] && ident !== "status" && ident !== "realtyType") return
            if (requiredFilters.includes(ident)) return;
            const type = Array.isArray(values) ? "array" : typeof values
            if (type === "array") {
                if (["isApartments", "assignments"].includes(ident)) { // фикс для старых фильтров, удалить после замены на новые 
                    result.filter[ident] = values?.[0]?.id
                    if (!result.filter[ident]?.length) delete result.filter[ident]
                    return
                }
                result.filter[ident] = values.filter(value => ident === "status" || filterData?.[ident]?.some?.(item => item.id == value || item.id == value?.id))
                if (!result.filter[ident]?.length) delete result.filter[ident]
                return
            }
            if (type === "object") {
                result.filter[ident] = {}
                // const isInFilterData = id => filterData?.[ident]?.items?.some(item=>item.id == id)
                if (values.min) result.filter[ident].min = values.min
                if (values.max) result.filter[ident].max = values.max
                if (Object.keys(result.filter[ident]).length === 0) delete result.filter[ident]
                return
            }
            result.filter[ident] = values
        })
        Object.entries(tmpExclude).forEach(([ident, values]) => {
            if (!filterData[ident]) return
            const type = Array.isArray(values) ? "array" : typeof values
            if (type === "array") {
                result.exclude[ident] = values.filter(value => filterData[ident].some(item => item.id == value || item.id === value?.id))
            }
            if (type === "object") {
                result.filter[ident] = {}
                const isInFilterData = id => filterData?.[ident]?.items?.some(item => item.id == id)
                if (isInFilterData(values.min)) result.filter[ident].min = values.min
                if (isInFilterData(values.max)) result.filter[ident].max = values.max
                return
            }
            result.exclude[ident] = values
        })
        setToStore(result)
    }
    const {
        data,
        isLoading,
        refresher,
        isRefreshing,
        loadMore,
        moreIsLoading,
        send,
        isSending,
        setData,
        dataSetter
    } = useApi({
        payload: [config.apiConfig.component, config.apiConfig.submitFunction, toForm(paginationEnabled, sortEnabled, isGetFilter)],
        successCallback: restoreFromLocalStorage,
        processData,
        loadCondition,
    }, [loadCondition, dep])

    useEffect(() => {
        if (disableLoadMapItems) return;
        getMapItems()
    }, [view])

    const abortControllerRef = useRef(null)

    const isFilterEmpty = useMemo(() => isEmptyObject(filterState.filter, shadowFilters), [filterState, shadowFilters]);

    const isExcludeEmpty = useMemo(() => isEmptyObject(filterState.exclude, shadowFilters), [filterState, shadowFilters]);


    const handleFilter = (key, value, subKey) => {
        const tmp = getTmpValue(filterState.filter, key, subKey, value, fields)
        setToStore({ filter: setter(filterState.filter, tmp, key, subKey, value) })
    }

    const handleExclude = (key, value, subKey) => {
        const tmp = getTmpValue(filterState.exclude, key, subKey, value, fields)
        setToStore({ exclude: setter(filterState.exclude, tmp, key, subKey, value) })
    }
    const setFilterAndExclude = (data, identity) => {
        let tempValue = {
            filter: {
                ...filterState.filter,
                [identity]: data.filter ? data.filter : filterState.filter[identity],
            },
            exclude: {
                ...filterState.exclude,
                [identity]: data.exclude ? data.exclude : filterState.exclude[identity],
            },
        };

        if (getDeleteCond(tempValue.filter, identity)) {
            delete tempValue.filter?.[identity]
        }
        if (getDeleteCond(tempValue.exclude, identity)) {
            delete tempValue.exclude?.[identity]
        }

        setToStore(tempValue)
    };
    const debouncedSetFilter = useCallback(debounce(setFilterAndExclude, 300), [])

    const getCount = () => {
        if (!config.apiConfig.getCountFunction || (restoreSF && !isFilterRestored)) return
        if (abortControllerRef.current) abortControllerRef.current.cancel("Отмена предыдущего запроса")
        abortControllerRef.current = axios.CancelToken.source();
        setIsCountLoading(true)
        return request(config.apiConfig.component,
            config.apiConfig.getCountFunction,
            toForm(),
            false,
            { cancelToken: abortControllerRef.current?.token })
            .then(resp => {
                setCount(extractCount(resp))
                setIsCountLoading(false)
                return Promise.resolve(resp)
            },
                err => {
                    setIsCountLoading(false)
                    return Promise.reject(err)
                })
            .catch(err => console.log('err', err))
    }

    useEffect(() => {
        getCount()
        if (Object.keys(data)?.length > 0 && refreshWhenFilterChange) refreshByFilter(true);
    }, [filterState])


    const filterBadges = useMemo(() => createBadges(filterState.filter, data?.filter, shadowFilters, badgeFormattersParams, specialBadgeTitles),
        [filterState.filter, data?.filter]);
    const excludeBadges = useMemo(() => createBadges(filterState.exclude, data?.filter, shadowFilters, badgeFormattersParams, specialBadgeTitles),
        [filterState.exclude, data?.filter]); // умышленно не добавлен badgeFormattersParams, сделано, чтобы значения менялись только при изменении фильтра, а не смене валюты и других параметров

    const removeBadge = (ident, value, subKey, filterType = "filter") => {
        const tmpFullFilter = { ...filterState }
        const values = tmpFullFilter[filterType][ident]
        const type = Array.isArray(values) ? "array" : typeof values;
        if (type === "array") {
            const tmpValues = [...values]
            const findedIndex = tmpValues.findIndex(item => item == value)
            tmpValues.splice(findedIndex, 1)
            tmpFullFilter[filterType][ident] = tmpValues
            return setFilterAndExclude({ [filterType]: tmpFullFilter[filterType][ident] }, ident)
        }
        if (type === "object") {
            delete tmpFullFilter[filterType][ident][subKey]
            const otherSubKey = subKey === "min" ? "max" : "min"
            if (!tmpFullFilter[filterType][ident][otherSubKey]) delete tmpFullFilter[filterType][ident]
            return setFilterAndExclude({ [filterType]: tmpFullFilter[filterType][ident] }, ident)
        }
        delete tmpFullFilter[filterType][ident]
        return setFilterAndExclude({ [filterType]: tmpFullFilter[filterType][ident] }, ident)
    }

    const handleLoadMore = () => {
        const form = toForm(false, sortEnabled)
        const p = pagination.page + 1;
        form.append("page", p)
        form.append("limit", pagination.limit)
        return loadMore([config.apiConfig.component, config.apiConfig.submitFunction, form], loadMoreDataKey)
            .then((data) => {
                setPagination(prev => ({ ...prev, page: p, hasNextPage: p * prev.limit < extractLoadMoreCount(data) }));

            });
    }
 
    const infiniteRef = useInfiniteScroll({
        loading: moreIsLoading,
        hasNextPage: pagination.hasNextPage,
        threshold: 20,
        checkInterval: loadMoreEnabled ? 100 : 99999999999,
        onLoadMore: handleLoadMore,
        scrollContainer: "window",
    });

    const resetPagination = () => setPagination(prev => ({ ...prev, page: defaultPage }))

    const setPaginationValues = (values = {}) => setPagination(prev => ({ ...prev, ...values }))

    const changeLimit = limit => {
        const formData = toForm(false, sortEnabled)
        formData.append("page", 1)
        formData.append("limit", limit)
        scrollToRef(infiniteRef, scrollToRefOffset)
        setPaginationValues({ limit, page: defaultPage, hasNextPage: true, })
        return refresher([config.apiConfig.component, config.apiConfig.submitFunction, formData])
    }
    const changePage = page => {
        const formData = toForm(false, sortEnabled)
        formData.append("page", page)
        formData.append("limit", pagination.limit)
        scrollToRef(infiniteRef, scrollToRefOffset)
        setPaginationValues({ limit: pagination.limit, page, hasNextPage: true, })
        return refresher([config.apiConfig.component, config.apiConfig.submitFunction, formData])
    }

    const submit = (config = {}) => {
        const { reset, params = {}, submitFunction } = config

        const formData = toForm(false, sortEnabled, false, reset)
        formData.append("page", defaultPage)
        formData.append("limit", pagination.limit)
        applyToFormData(formData, params)
        if (!reset) scrollToRef(infiniteRef, scrollToRefOffset)
        if (!isFilterEmpty || !isExcludeEmpty) setIsFilterChanged(true)
        if (view === "map") return getMapItems()
        resetMapItems()

        return refresher([apiConfig.component, submitFunction ?? apiConfig.submitFunction, formData]).then((data) => {
            setPagination(prev => ({ ...prev, page: 1, hasNextPage: 1 * prev.limit < extractLoadMoreCount(data) }));
        });
    }
    const handleSort = (id, altParams)=> {
        setSortId(id)

        const tmp = sortList[id] ?? altParams

        if (!tmp?.value) throw new Error(`Сортировка не найдена sortList: ${JSON.stringify(sortList)}, id: ${id}, altParams: ${JSON.stringify(altParams)}`)

        setSort( {
            order: tmp.value,
            by: tmp.by
        })

        const formData = toForm(paginationEnabled, false)
        formData.append("order", tmp.value)
        formData.append("by", tmp.by)
        formData.append("page", 1)
        scrollToRef(infiniteRef, scrollToRefOffset)
        return refresher([config.apiConfig.component, config.apiConfig.submitFunction, formData])
            .then((data) => {
                setPagination(prev => ({ ...prev, page: 1, hasNextPage: 1 * prev.limit < extractLoadMoreCount(data) }));
            });
    }
    const getMapItems = () => {
        const cond = !config.apiConfig.getMapItems || mapItems || view !== "map"
        if (cond) return
        const formData = toForm(false, false)
        return send([config.apiConfig.component, config.apiConfig.getMapItems, formData])
            .then(resp => {
                setMapItems(extractMapItems(resp))
            })
    }
    const submitMap = () => {
        const formData = toForm(false, false)
        return send([config.apiConfig.component, config.apiConfig.getMapItems, formData])
            .then(resp => {
                setMapItems(extractMapItems(resp))
                dispatchMap({ type: "setData", payload: { selectedItems: [], ...mapInitialState } })
            })
    }

    const resetFilter = resetAndSubmit => {
        setToStore({
            filter: defaultFilter,
            exclude: defaultExcludes
        })
        if (resetAndSubmit && isFilterChanged) return submit({ reset: true })
    }
    const [isSubItemsLoading, setIsSubItemsLoading] = useState(false) // Возможно del
    const loadSubItems = (params, removeParams = [], onlyParams) => {
        setIsSubItemsLoading(true)
        const formData = onlyParams ? params : toForm(paginationEnabled, sortEnabled)
        if (!onlyParams) removeParams.forEach(paramName => formData.delete(paramName))
        if (params && !onlyParams) applyToFormData(formData, params)
        return request(config.apiConfig.component, config.apiConfig.loadSubItems, formData)
            .then(
                resp => {
                    setIsSubItemsLoading(false)
                    extractSubItems(setData, resp)
                    Promise.resolve(resp)
                },
                err => {
                    setIsSubItemsLoading(false)
                    Promise.reject(err)
                }
            )
    }
    const refreshByFilter = resetPagination => {
        const formData = toForm(paginationEnabled, false, !data?.filter, false, resetPagination)
        if (typeof beforeRefresh === "function") beforeRefresh(formData)
        return refresher([config.apiConfig.component, config.refreshMethod ?? config.apiConfig.submitFunction, formData])
    }
    const switchFilterToExclude = (destination, { id, ident, subKey }) => {
        const removable = destination === "filter" ? "exclude" : "filter";
        const tmpFullFilter = { ...filterState }
        const valuesToBeRemoved = tmpFullFilter[removable][ident]
        const destinationItems = tmpFullFilter[destination][ident]
        const type = Array.isArray(valuesToBeRemoved) ? "array" : typeof valuesToBeRemoved;
        if (type === "array") {
            const tmpValues = [...valuesToBeRemoved]
            const findedIndex = tmpValues.findIndex(item => item == id)
            const removedArray = tmpValues.splice(findedIndex, 1)
            tmpFullFilter[removable][ident] = tmpValues
            tmpFullFilter[destination][ident] = destinationItems ? [...destinationItems, ...removedArray] : removedArray
            return setFilterAndExclude({
                [removable]: tmpFullFilter[removable][ident],
                [destination]: tmpFullFilter[destination][ident]
            }, ident)
        }
        if (type === "object") {
            const value = tmpFullFilter[removable][ident][subKey]
            delete tmpFullFilter[removable][ident][subKey]
            const otherSubKey = subKey === "min" ? "max" : "min"
            if (!tmpFullFilter[removable][ident][otherSubKey]) delete tmpFullFilter[removable][ident]
            tmpFullFilter[destination][ident] = destinationItems ? { ...destinationItems, [subKey]: value } : { [subKey]: value }
            return setFilterAndExclude({
                [removable]: tmpFullFilter[removable][ident],
                [destination]: tmpFullFilter[destination][ident]
            }, ident)
        }
        const value = tmpFullFilter[removable][ident]
        delete tmpFullFilter[removable][ident]
        return setFilterAndExclude({
            [removable]: tmpFullFilter[removable][ident],
            [destination]: value
        }, ident)
    }

    const clearFilter = () => setToStore({ filter: defaultFilter })
    const clearExclude = () => setToStore({ exclude: defaultExcludes })
    const clearByIdent = (ident, type = "filter") => {
        const tmp = { ...filterState }
        delete tmp[ident]
        setToStore({ [type]: tmp })
    }
    const toggleFavorite = (additionalParams = {}, itemPath) => {
        const path = itemPath || favoritePath
        const isMapView = view === "map"
        if (!path) throw new Error("Необходимо добавить favoritePath")
        setIsFavoriteLoading(true)
        const fav = _get(isMapView ? mapItems : data, path)
        const method = fav?.inFavorite ? "removeFromFavorite" : "addToFavorite";
        fav.inFavorite = !fav?.inFavorite;
        const params = { ...favoritesParams, ...additionalParams }
        // debugger
        return request("panpartner:favorite.ajax", method, params).then(
            success => {
                const setFn = prev => {
                    const tmp = { ...prev }
                    _set(tmp, path, fav)
                    return tmp
                }
                isMapView ? setMapItems(setFn) : setData(setFn);
                getFavoriteCount()(dispatch);
                setIsFavoriteLoading(false)
                Promise.resolve(success)
            },
            err => {
                setIsFavoriteLoading(false)
                Promise.reject(err)
            }
        )
    }
    return {
        changeLimit,
        changePage,
        count,
        data,
        setData,
        dataSetter,
        debouncedSetFilter,
        excludeBadges,
        filterBadges,
        getCount,
        handleExclude,
        handleFilter,
        handleLoadMore,
        isCountLoading,
        isExcludeEmpty,
        isFilterEmpty,
        isLoading,
        showSkeleton: isLoading || isRefreshing,
        infiniteRef,
        moreIsLoading,
        removeBadge,
        setFilterAndExclude,
        submit,
        sort,
        sortId,
        setSort,
        handleSort,
        view,
        setView,
        mapItems,
        isMapItemsLoading: isSending,
        dispatchMap,
        mapState,
        setMapItems,

        resetFilter,
        submitMap,

        loadSubItems,
        isSubItemsLoading,

        clearFilter,
        clearExclude,
        switchFilterToExclude,
        ...pagination,
        ...filterState,
        createBadges: (
            filter,
            shadowFilters = config.shadowFilters,
            formattersParams,
            specialTitles
        ) => createBadges(filter, data?.filter, shadowFilters, formattersParams, specialTitles),
        clearByIdent,
        setLoadCondition,
        favoritesProps: { toggleFavorite, isFavoriteLoading, inFavorite: _get(view === "map" ? mapItems : data, favoritePath)?.inFavorite },
        setFilterState: setToStore,
        get: path => _get(view === "map" ? mapItems : data, path),
        toForm,
        dispatchFilter
    }
}

export default useFilter
