import React, { memo } from "react";
import Cards from "../Cards";
import EmptyTable from "../core/EmptyTable";
import { propsCreatorByType } from "./fields";
import FlatCard from "./FlatCard";

const FavoritesList = memo(props => {

  return (
    <section className="section section_view_gray">
      {props.list.length > 0 ? (
        <div className="wrapper">
          <div className="section__grid favorites__list">
            {props.list.map((values, i) => {
              let tab = props.currentTab
              if (values.type === "village") tab = "village"
              if (values.type === "blockEntity") tab = "blockEntity"
              return tab === "apartment" ? <FlatCard {...propsCreatorByType(values)}  key={values.id}/> : <Cards
              currentTab={tab}
              isFav={true}
              city={values.cityId == 3 || values.cityCode === "msk" ? "msk" : "spb"}
              whatPage={"favorites"}
              isAgent={props.isAgent}
              clientMode={props.clientMode}
              key={`fav-cards-${i}`}
              {...values}
            />
            })}
          </div>
        </div>
      ) : (
        <EmptyTable
          title="Вы ничего не добавили в избранное"
        />
      )}
    </section>
  );
});
export default FavoritesList;
