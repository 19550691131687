import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import useDropdown from "../../../hooks/useDropdown";
import { searchRuSymbols } from '../../../utils';

const Dropdown = props => {
  const {
    value,
    options,
    onChange,
    disabled = false,
    placeholder,
    wrapperClass,
    withSearch,
    searchPlaceholder,
    defValue,
    flatBookingTooltip,
    customHandler,
    valueKey = "id",
    colored,
    titleKey = "title",
    error,
    openTop,
    innerClass,
  } = props


  const [node, open, show, hide] = useDropdown(false);
  const [current, setCurrent] = useState(null);

  const [search, setSearch] = useState('');
  const searchRef = useRef(null)
  const [filterable, setFilterable] = useState(options || []);

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    hide();
  };
  const handleCustom = (val) => {
    customHandler(val)
    hide();
  }

  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus();
      searchRef.current.select();
    }
  }, [open])

  useEffect(() => {
    if (value) {
      const m = options.find((item) => item[valueKey] === value);
      setCurrent(current => m ?? current);
      return;
    }
    const defaultValue = options.find((item) => item[valueKey] == defValue) ?? options[0];
    setCurrent(placeholder ? { [titleKey]: placeholder } : defaultValue);
  }, [value, options, defValue]);

  useEffect(() => {
    if (!withSearch) {
      setFilterable(options)
      return
    }
    setFilterable(
      search
        ? options.filter((item) => {
          let tmpSearch = search.toLowerCase().replace(/ё/g, 'е');
          let title = item[titleKey]
            ? item[titleKey].toLowerCase().replace(/ё/g, 'е')
            : item.name?.toLowerCase()?.replace(/ё/g, 'е');
          if (!title) {
            return;
          }
          let aliasesSearch = null;
          if (item.aliases) {
            aliasesSearch = item.aliases.some(
              (alias) =>
                alias.toLowerCase().includes(tmpSearch.toLowerCase()) ||
                alias
                  .toLowerCase()
                  .includes(searchRuSymbols(tmpSearch.toLowerCase())),
            );
          }
          if (title.includes(tmpSearch) || aliasesSearch) {
            return true;
          } else {
            return searchRuSymbols(title.toLowerCase()).includes(
              searchRuSymbols(tmpSearch.toLowerCase()),
            );
          }
        })
        : options,
    );
  }, [search, options]);

  const checkColorClass = (color) => {
    let newColor = color;
    const unwantedCharacter = "#";
    if (color.charAt(0) == unwantedCharacter) {
      newColor = `hex-${newColor.substring(1)}`;
    }
    return newColor;
  };

  return (
    <div
      ref={node}
      className={`custom-select${open ? ` is-showing` : ``}${disabled ? ` is-disabled` : ``}${wrapperClass ? ` ${wrapperClass}` : ``}${colored ? ` colored` : ``} ${error? "error": ""}${openTop ? ` open-top` : ``}`}
      style={{...(withSearch? {boxShadow: "none", borderRadius: "none"} : [])}}
    >
      {current && (
        <>
          <div
            className={`custom-select__selected${current?.color ? ` ${checkColorClass(current?.color)}` : ``}${innerClass ? ` ${innerClass}` : ``}`}
            onClick={(e) => (disabled || open ? hide() : show())}
          >
            <span>{current[titleKey] || (current.name + ' ' + current.lastName)}</span>

            {flatBookingTooltip && !current.id &&  (
              <div className="fbooking__tooltip">
                {`${flatBookingTooltip}`}
              </div>
            )}
          </div>
          {withSearch && (
              <div className="custom-select__search">
                <label className="input input_type_default input_width_available ">
                  <input
                    className="input__control"
                    type="text"
                    placeholder={searchPlaceholder}
                    autocomplete='off'
                    onChange={(e) =>
                      setSearch(e.target.value.toLowerCase())
                    }
                    ref={searchRef}
                  />
                </label>

              </div>
            )}
          <SimpleBar className="custom-select__options" style={{ ...(withSearch ? { boxShadow: "none" } : []) }}
          >
            <ul>
              {
              filterable.map((item) => (
                <li
                  className={`custom-select__option${current.id === item[valueKey]? ` is-active` : ``}${item?.color ? ` ${checkColorClass(item?.color)}` : ``}`
                  }
                  key={item[valueKey]}
                  onClick={() => item.disabled ? "" : customHandler? handleCustom(item): handleChange(item[valueKey])}
                  style={
                    item.disabled ? { filter: "opacity(0.5)", cursor: "auto"} : {}
                  }

                >
                  <span>{item[titleKey] || (item.name + ' ' + item.lastName)}</span>
                </li>
              ))}
            </ul>
          </SimpleBar>
        </>
      )}
    </div>
  );
};

export default Dropdown;
