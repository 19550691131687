import { useMediaQuery } from 'beautiful-react-hooks';
import _filter from 'lodash/filter';
import without from 'lodash/without';
import React, { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { connect } from 'react-redux';
import useMedia from '../../hooks/useMedia';
import {
  filterToParams,
  isEmptyFilterOrExclude
} from '../../utils';
import {
  defaultFullFilter
} from '../../utils/defaultFilters';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import RadioDropdown from '../core/RadioDropdown';

import EndingsFilter from '../FilterRealty/EndingsFilter';
import FloorsFilter from '../FilterRealty/FloorsFilter';
import PriceFilter from '../FilterRealty/PriceFilter';
import SelectFilter from '../FilterRealty/SelectFilter';
import SquareFilter from '../FilterRealty/SquareFilter';
import SaveFilter from '../SavedFilters/SendFilter';
import AttentionBlock from "./AttentionBlock";
import Pannels from "./Pannels";
import DraggableZone from '../FilterRealty/Draggable';


const Filter = (props) => {
  const {
    filter,
    exclude,
    filterData,
    setFilter,
    onSubmit,
    getCount,
    city,
    fetching,
    info,
    countToShow,
    filterType,
    isShowFilter,
    isPublic,
    savedFilter,
    savedFilterXmlId,
    isCommercial,
    openBuyModal,
    isClientMode,
    handleFilter,


    isExcludeEmpty,
    isFilterEmpty,
    filterBadges,
    excludeBadges,
    
    clearFilter,
    removeBadge,
    clearExclude,
    switchFilterToExclude,

    countFilters
  } = props;


  const isMobile = useMedia("isMobile")
  useEffect(() => {
    if (savedFilter) {
      setFilter({
        filter: { ...savedFilter.filter },
        exclude: { ...savedFilter.exclude },
      });
    }
  }, [savedFilter]);
 
  const smallScreen = useMediaQuery('(max-width: 1280px)');

  const handleClearFilter = (e) => {
    e.preventDefault();
    clearFilter();
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSaveFilterModal} classes="modal_filter">
        <SaveFilter
          filter={{filter, exclude}}
          filterData={filterData}
          hide={hideSaveFilterModal}
          city={city}
          filterType={filterType}
        />
      </Modal>
    ),
    [filter, filterData],
  );

  const [saveMobileFilterModal, hideSaveMobileFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal title="Сохранить фильтр" closeClick={hideSaveMobileFilterModal} classes="modal_filter">
        <SaveFilter
          filter={{filter, exclude}}
          filterData={filterData}
          hide={hideSaveMobileFilterModal}
          city={city}
          filterType={filterType}
        />
      </MobileModal>
    ),
    [filter, filterData],
  );

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    if(isMobile) document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

  useEffect(() => {
    if (props.isFiltersOpen) setIsOpen(!isOpen);
  }, [props.isFiltersOpen]);



  const filters = (
    <div className="section__grid-col index-search__filter js-index-filter">
      <form className="form">
        {(isShowFilter || !isPublic) && (
          <div className="index-search__filter-body">
            <div className="index-search__filter-top">
              <div className="form__row">
                {/* <div className="form__item form__item_2x form__item_main">
                    <div className="index-search__search">
                            <label className="input index-search__search-input" htmlFor="index-search">
                              <input className="input__control" type="text" id="index-search" placeholder="Поиск по 1 234 вариантам жилья" autocomplete="off">
                                      <svg className="icon icon_search">
                                        <use xlinkHref="#search"></use>
                                      </svg>
                              <div className="index-search__tooltip">
                                <button className="button button_view_outline-small">Исключить</button>
                              </div>
                            </label>
                              <div className="info">
                                        <svg className="icon icon_info">
                                          <use xlinkHref="#info"></use>
                                        </svg>
                                <div className="info__popup">
                                  <p>Начинайте вводить параметры фильтра и&nbsp;выбирайте из&nbsp;предложенного списка. Вы&nbsp;также можете любой запрос добавить в&nbsp;исключения</p>
                                </div>
                              </div>
                    </div>
                  </div> */}
                <SelectFilter
                  onChange={(a, b) =>
                    handleFilter({ filter: a, exclude: b }, 'blocks')
                  }
                  isExcludable={true && !isPublic}
                  isAutocomplete={true}
                  items={
                    filterData.blocks
                      ? Object.values(filterData.blocks)
                      : []
                  }
                  filterValues={filter.blocks}
                  excludeValues={exclude.blocks}
                  data={{
                    title: 'Название ЖК',
                    autocompleteTitle: 'Введите название ЖК',
                    identity: 'blocks',
                  }}

                  isMobile={isOpen && isMobile}
        					clearFilterOnly={clearFilter}
                  clearExcludeOnly={clearExclude}
                  fullFilter={filter}
                />
                <SelectFilter
                  onChange={(a, b) =>
                    handleFilter({ filter: a, exclude: b }, 'districts')
                  }
                  isExcludable={true && !isPublic}
                  isAutocomplete={true}
                  items={
                    filterData.districts
                      ? Object.values(filterData.districts)
                      : []
                  }
                  filterValues={filter.districts}
                  excludeValues={exclude.districts}
                  data={{
                    title: 'Район',
                    autocompleteTitle: 'Введите название района',
                    identity: 'districts',
                  }}

                  isMobile={isOpen && isMobile}
        					clearFilterOnly={clearFilter}
                  clearExcludeOnly={clearExclude}
                  fullFilter={filter}
                />
                {isCommercial && isPublic ? (
                  <SquareFilter
                    filterSecond={filter.squareTotal}
                    dataSecond={{
                      title: 'S',
                      minPlaceholder: 'S общ. от ',
                      maxPlaceholder: 'S общ. до ',
                      defaultValues: filterData.squareTotal
                        ? filterData.squareTotal.range
                        : null,
                    }}
                    alternativeTitle="Площадь"
                    onChange={(a, identity) => handleFilter(a, identity)}
                    isArea={true}
                    isAreaOnly={true}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                ) : (
                  <SelectFilter
                    onChange={(a, b) =>
                      handleFilter({ filter: a, exclude: b }, 'rooms')
                    }
                    isExcludable={true && !isPublic}
                    isAutocomplete={false}
                    items={
                      filterData.rooms
                        ? Object.values(filterData.rooms)
                        : []
                    }
                    filterValues={filter.rooms}
                    excludeValues={exclude.rooms}
                    data={{
                      title: 'К-во комнат',
                      autocompleteTitle: '',
                      identity: 'rooms',
                    }}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                )}
                <EndingsFilter
                  ident={"endings"}
                  right={smallScreen}
                  filter={
                    filter.endings
                      ? filter.endings
                      : { min: '', max: '' }
                  }
                  items={
                    filterData.endings ? filterData.endings.items : []
                  }
                  data={{
                    title: 'Срок сдачи',
                    minPlaceholder: 'от ',
                    maxPlaceholder: 'до ',
                    defaultValues: filterData.endings
                      ? filterData.endings.ranges
                      : null,
                  }}
                  onChange={(a) => handleFilter(a, 'endings')}
                  secondData={{
                    title: 'Сданные дома',
                    value: filter.isOnlyHanded?.length
                      ? filter.isOnlyHanded[0].id
                      : 'N',
                  }}
                  secondChange={(a) => {
                    let tmp = a === 'Y' ? [{ id: a }] : [];
                    handleFilter(
                      {
                        filter: tmp,
                        exclude: [
                          ..._filter(
                            exclude.isOnlyHanded,
                            (val) => val.id != a,
                          ),
                        ],
                      },
                      'isOnlyHanded',
                    );
                  }}

                  isMobile={isOpen && isMobile}
        					clearFilterOnly={clearFilter}
                  clearExcludeOnly={clearExclude}
                  fullFilter={filter}
                />
                <PriceFilter
                  filter={
                    filter.prices
                      ? filter.prices
                      : { min: '', max: '' }
                  }
                  exclude={
                    exclude.prices
                      ? exclude.prices
                      : { min: '', max: '' }
                  }
                  items={filterData.prices ? filterData.prices.items : []}
                  data={{
                    title: 'Цена',
                    minPlaceholder: 'от ',
                    maxPlaceholder: 'до ',
                    defaultValues: filterData.prices
                      ? filterData.prices.range
                      : 0,
                  }}
                  onChange={(a) => handleFilter(a, 'prices')}

                  isMobile={isOpen && isMobile}
        					clearFilterOnly={clearFilter}
                  clearExcludeOnly={clearExclude}
                  fullFilter={filter}
                />

                <SelectFilter
                  onChange={(a, b) =>
                    handleFilter({ filter: a, exclude: b }, 'builders')
                  }
                  isExcludable={true && !isPublic}
                  isAutocomplete={true}
                  items={
                    filterData.builders
                      ? Object.values(filterData.builders)
                      : []
                  }
                  filterValues={filter.builders}
                  excludeValues={exclude.builders}
                  data={{
                    title: 'Застройщик',
                    autocompleteTitle: 'Введите название застройщика',
                    identity: 'builders',
                  }}
                  right={!smallScreen}

                  isMobile={isOpen && isMobile}
        					clearFilterOnly={clearFilter}
                  clearExcludeOnly={clearExclude}
                  fullFilter={filter}
                />
              </div>
            </div>
            {!isPublic && (
              <div className="index-search__filter-main">
                <div className="form__row">
                <SelectFilter
                    onChange={(a, b) =>
                      handleFilter(
                        { filter: a, exclude: b },
                        'realtyType',
                      )
                    }
                    isExcludable={true && !isPublic}
                    isAutocomplete={false}
                    items={
                      filterData.realtyType
                        ? Object.values(filterData.realtyType)
                        : []
                    }
                    filterValues={filter.realtyType}
                    excludeValues={exclude.realtyType}
                    data={{
                      title: 'Тип договора',
                      autocompleteTitle: '',
                      identity: 'realtyType',
                    }}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                  <SelectFilter
                    onChange={(a, b) =>
                      handleFilter(
                        { filter: a, exclude: b },
                        'decoration',
                      )
                    }
                    isExcludable={true && !isPublic}
                    isAutocomplete={false}
                    items={
                      filterData.decoration
                        ? Object.values(filterData.decoration)
                        : []
                    }
                    filterValues={filter.decoration}
                    excludeValues={exclude.decoration}
                    data={{
                      title: 'Отделка',
                      autocompleteTitle: '',
                      identity: 'decoration',
                    }}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                  <SquareFilter
                    filterSecond={filter.squareTotal}
                    dataSecond={{
                      title: 'S общ.',
                      minPlaceholder: 'S общ. от ',
                      maxPlaceholder: 'S общ. до ',
                      defaultValues: filterData.squareTotal
                        ? filterData.squareTotal.range
                        : null,
                    }}
                    alternativeTitle={'S общ.'}
                    isAreaOnly={true}
                    isArea={true}
                    onChange={(a, identity) => handleFilter(a, identity)}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                    <SquareFilter
                    filter={filter.squareKitchen}
                    data={{
                      title: 'S кухни',
                      minPlaceholder: 'S кухни от ',
                      maxPlaceholder: 'S кухни до ',
                      defaultValues: filterData.squareKitchen
                        ? filterData.squareKitchen.range
                        : null,
                    }}
                    onChange={(a, identity) => handleFilter(a, identity)}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                  <SelectFilter
                    onChange={(a, b) =>
                      handleFilter({ filter: a, exclude: b }, 'subways')
                    }
                    isExcludable={true && !isPublic}
                    isAutocomplete={true}
                    items={
                      filterData.subways
                        ? Object.values(filterData.subways)
                        : []
                    }
                    filterValues={filter.subways}
                    excludeValues={exclude.subways}
                    data={{
                      title: 'Метро',
                      autocompleteTitle: 'Введите название станции',
                      identity: 'subways',
                    }}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                  <FloorsFilter
                    items={
                      filterData.floors?.items
                        ? filterData.floors.items
                        : []
                    }
                    filter={filter.floor}
                    data={{
                      title: 'Этаж',
                      minPlaceholder: 'от ',
                      maxPlaceholder: 'до ',
                      defaultValues: filterData.floor
                        ? filterData.floor.range
                        : null,
                    }}
                    onChange={(a) => handleFilter(a, 'floor')}
                    secondData={{
                      title: filterData.isNoFirstFloor
                        ? filterData.isNoFirstFloor[0].title
                        : 'Не первый',
                      value: filter.isNoFirstFloor?.length
                        ? filter.isNoFirstFloor[0].id
                        : 'N',
                    }}
                    secondChange={(a) => {
                      let tmp = a === 'Y' ? [{ id: a }] : [];
                      handleFilter(
                        {
                          filter: tmp,
                          exclude: [
                            ..._filter(
                              exclude.isNoFirstFloor,
                              (val) => val.id != a,
                            ),
                          ],
                        },
                        'isNoFirstFloor',
                      );
                    }}
                    ident="floor"
                    right={smallScreen}

                    isMobile={isOpen && isMobile}
          					clearFilterOnly={clearFilter}
                    clearExcludeOnly={clearExclude}
                    fullFilter={filter}
                  />
                  {/* <SquareFilter
                right={smallScreen}
                filter={filter.floor}
                data={{
                  title: "Этаж",
                  minPlaceholder: "от ",
                  maxPlaceholder: "до ",
                  defaultValues: filterData.floor
                    ? filterData.floor.range
                    : null,
                }}
                onChange={(a) => handleFilter(a, "floor")}
                // secondData={{
                //   title: filterData.isNoFirstFloor
                //     ? filterData.isNoFirstFloor[0].title
                //     : "Не первый",
                //     value: filter.isNoFirstFloor.length? filter.isNoFirstFloor[0].id : "N"

                // }}
                // secondChange={(a) =>{
                //   let tmp = a === "Y" ? [{ id: a }]: []
                //   handleFilter(
                //     {
                //       filter: tmp,
                //       exclude: [
                //         ..._filter(
                //           exclude.isNoFirstFloor,
                //           (val) => val.id != a
                //         ),
                //       ],
                //     },
                //     "isNoFirstFloor"
                //   )}
                // }
              /> */}
                  {filterData.availability && (
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter(
                          { filter: a, exclude: b },
                          'availability',
                        )
                      }
                      isExcludable={false}
                      isAutocomplete={false}
                      items={
                        filterData.availability
                          ? Object.values(filterData.availability)
                          : []
                      }
                      filterValues={filter.availability}
                      excludeValues={exclude.availability}
                      data={{
                        title: 'До метро',
                        autocompleteTitle: '',
                        identity: 'availability',
                      }}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  <RadioDropdown
                    
                    onChange={v => setFilter("isApartments", v)}
                    isAutocomplete={false}
                    items={
                      filterData.isApartments
                        ? filterData.isApartments
                        : [
                          { id: "Y", title: "Только апартаменты" },
                          { id: "N", title: "Исключить апартаменты" },
                        ]
                    }
                    value={filter.isApartments}
                    alternativeTitle={"Апартаменты"}
                    data={{
                      title: "Апартаменты",
                      identity: "isApartments",
                    }}
                  />
                  {/* <SelectFilter
                isExcludable={true && !isPublic}
                isAutocomplete={false}
                items={
                  filterData.isApartments ? [filterData.isApartments] : []
                }
                filterValues={filter.isApartments}
                excludeValues={exclude.isApartments}
                data={{
                  title: "Апартаменты",
                  autocompleteTitle: "",
                  identity: "isApartments",
                }}
              /> */}
                  {filterData.assignments && (
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter(
                          { filter: a, exclude: b },
                          'assignments',
                        )
                      }
                      isExcludable={false}
                      isAutocomplete={false}
                      items={
                        filterData.assignments
                          ? Object.values(filterData.assignments)
                          : []
                      }
                      filterValues={filter.assignments}
                      excludeValues={exclude.assignments}
                      data={{
                        title: 'Переуступки',
                        autocompleteTitle: '',
                        identity: 'assignments',
                      }}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  {filterData.payment && (
                    <SelectFilter
                      right={smallScreen}
                      onChange={(a, b) =>
                        handleFilter({ filter: a, exclude: b }, 'payment')
                      }
                      isExcludable={true && !isPublic}
                      isAutocomplete={false}
                      items={
                        filterData.payment
                          ? Object.values(filterData.payment)
                          : []
                      }
                      filterValues={filter.payment}
                      excludeValues={exclude.payment}
                      data={{
                        title: 'Способ оплаты',
                        autocompleteTitle: '',
                        identity: 'payment',
                      }}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  {filterData.blockClass && (
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter(
                          { filter: a, exclude: b },
                          'blockClass',
                        )
                      }
                      isExcludable={true && !isPublic}
                      isAutocomplete={false}
                      items={
                        filterData.blockClass
                          ? Object.values(filterData.blockClass)
                          : []
                      }
                      filterValues={filter.blockClass}
                      excludeValues={exclude.blockClass}
                      data={{
                        title: 'Класс ЖК',
                        autocompleteTitle: '',
                        identity: 'blockClass',
                      }}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  {filterData.registrations && (
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter(
                          { filter: a, exclude: b },
                          'registrations',
                        )
                      }
                      isExcludable={true && !isPublic}
                      isAutocomplete={false}
                      items={
                        filterData.registrations
                          ? Object.values(filterData.registrations)
                          : []
                      }
                      filterValues={filter.registrations}
                      excludeValues={exclude.registrations}
                      data={{
                        title: 'Прописка',
                        autocompleteTitle: '',
                        identity: 'registrations',
                      }}
                      right={!smallScreen}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  {filterData.banks && (
                    <SelectFilter
                      onChange={(a, b) =>
                        handleFilter({ filter: a, exclude: b }, 'banks')
                      }
                      isExcludable={true && !isPublic}
                      isAutocomplete={false}
                      items={
                        filterData.banks
                          ? Object.values(filterData.banks)
                          : []
                      }
                      filterValues={filter.banks}
                      excludeValues={exclude.banks}
                      data={{
                        title: 'Банки',
                        autocompleteTitle: '',
                        identity: 'banks',
                      }}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  {/* {filterData.encumbrances && (
                <SelectFilter
                  onChange={(a, b) =>
                    handleFilter({ filter: a}, "encumbrances")
                  }
                  isExcludable={true && !isPublic}
                  isAutocomplete={false}
                  items={
                    filterData.encumbrances
                  }
                  filterValues={filter.encumbrances}
                  data={{
                    title: "Обременения",
                    autocompleteTitle: "",
                    identity: "encumbrances",
                  }}
                />
              )} */}
                  {filterData.encumbrances && (
                    <RadioDropdown
                      right={!smallScreen}
                      onChange={(a, type) =>
                        handleFilter(
                          {
                            filter: [{ id: a }],
                            exclude: [
                              ..._filter(
                                exclude.encumbrances,
                                (val) => val.id != a,
                              ),
                            ],
                          },
                          'encumbrances',
                        )
                      }
                      isAutocomplete={false}
                      items={
                        filterData.encumbrances
                          ? filterData.encumbrances
                          : [
                              { id: 'Y', title: 'C обременениями' },
                              { id: 'N', title: 'Без обременений' },
                            ]
                      }
                      value={
                        filter.encumbrances?.[0]
                          ? filter.encumbrances[0].id
                          : {}
                      }
                      alternativeTitle={'Обременения'}
                      data={{
                        title: 'Обременения',
                        identity: 'encumbrances',
                      }}

                      isMobile={isOpen && isMobile}
            					clearFilterOnly={clearFilter}
                      clearExcludeOnly={clearExclude}
                      fullFilter={filter}
                    />
                  )}
                  <div
                    className="form__row"
                  >
                    <div className="index-search__show-booked">
                      <label className="switch" htmlFor="filter-booked">
                        <div className="switch__box">
                          <input
                            className="switch__control"
                            type="checkbox"
                            id="filter-booked"
                            checked={filter.status?.includes?.('3')}
                            onChange={e => setFilter("status", "3")}
                          />
                          <div
                            className="switch__input"
                            htmlFor="filter-booked"
                          ></div>
                          <div className="switch__marker"></div>
                        </div>
                        <span className="switch__label">
                          Забронированные
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {filter && filterData && !isEmptyFilterOrExclude(filter) && (
          <DraggableZone
            filterBadges={filterBadges}
            excludeBadges={excludeBadges}
            clearFilter={clearFilter}
            clearExclude={clearExclude}
            isExcludeEmpty={isExcludeEmpty}
            isFilterEmpty={isFilterEmpty}
            removeBadge={removeBadge}
            switchValues={switchFilterToExclude}
          />
        )}
        {(isShowFilter || !isPublic) && (
          <div className="index-search__filter-controls">
            {/* <div className="index-search__filter-control index-search__filter-voice">
            <button className="button button_view_round">
              <svg className="icon icon_mic">
                <use xlinkHref="#mic"></use>
              </svg>
            </button>
            <span>Голосовой помощник</span>
          </div> */}
            {!isPublic && (
              <div className="index-search__filter-control index-search__filter-options">
                <button
                  className="button button_view_outline"
                  onClick={(e) => {
                    e.preventDefault();
                    isMobile ? saveMobileFilterModal() : saveFilterModal();
                  }}
                >
                  Сохранить фильтр
                </button>
                {/* <button className="button button_type_settings">
                <svg className="icon icon_settings">
                  <use xlinkHref="#settings"></use>
                </svg>
              </button> */}
              </div>
            )}
            <button
              onClick={handleClearFilter}
              className="button button_type_clear visible_mobile"
            >
              <svg className="icon icon_x">
                <use xlinkHref="#x"></use>
              </svg>
              <span>Очистить фильтр</span>
            </button>
            <a
              className="index-search__filter-control index-search__filter-submit"
              href="#filter-results"
            >
              {countToShow ? (
                <button
                  className="button button_view_default"
                  type="submit"
                  onClick={(e) => {
                    handleFilterClick(e)
                    props.closeClick && props.closeClick()
                  }}
                  disabled={fetching ? true : false}
                >
                  Найдено{` ${countToShow} объектов`}
                </button>
              ) : (
                <button
                  className="button button_view_default"
                  type="submit"
                  disabled
                >
                  Ничего не найдено
                </button>
              )}
            </a>
            <div className="index-search__filter-control index-search__filter-options">
              <button
                onClick={handleClearFilter}
                className="button button_type_clear visible_note"
              >
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
            </div>
          </div>
        )}
        {(isShowFilter || !isPublic) && (
          <div className="index-search__filter-controls-m">
            <div className="index-search__filter-controls-col">
              {/* <div className="index-search__filter-control index-search__filter-voice">
              <button className="button button_view_round">
                <svg className="icon icon_mic">
                  <use xlinkHref="#mic"></use>
                </svg>
              </button>
            </div> */}
              {!isMobile &&
                <button
                  onClick={handleClearFilter}
                  className="button button_type_clear visible_mobile"
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
              }
            </div>
            <div className={`index-search__filter-controls-col ${isMobile ? 'mobile-filters__show-all' : ''}`}>
              <div className="index-search__filter-control index-search__filter-submit">
                {countToShow ? (
                  <button
                    className="button button_view_default"
                    type="submit"
                    onClick={(e) => {
                      handleFilterClick(e)
                      toggleFilters()
                    }}
                    disabled={fetching ? true : false}
                  >
                    Найдено{` ${countToShow} объектов`}
                  </button>
                ) : (
                  <button
                    className="button button_view_default"
                    type="submit"
                    disabled
                  >
                    Ничего не найдено
                  </button>
                )}
              </div>
            </div>
            <div className="index-search__filter-controls-col">
              <div className="index-search__filter-control index-search__filter-options">
                <button
                  onClick={handleClearFilter}
                  className="button button_type_clear visible_note"
                >
                  <svg className="icon icon_x">
                    <use xlinkHref="#x"></use>
                  </svg>
                  <span>Очистить фильтр</span>
                </button>
                {!isPublic && (
                  <div className="index-search__filter-save">
                    <button
                      className="button button_view_outline"
                      onClick={(e) => {
                        e.preventDefault();
                        isMobile ? saveMobileFilterModal() : saveFilterModal();
                      }}
                    >
                      Сохранить фильтр
                    </button>
                    {/* <button className="button button_type_settings">
                  <svg className="icon icon_settings">
                    <use xlinkHref="#settings"></use>
                  </svg>
                </button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )

  return (
    <section className="section index-search index-search_dark">
      <div className="wrapper">
        <div className="section__grid section__grid_column">
          <div className="section__grid-col index-search__header">
            <h1 className="h2" style={{ whiteSpace: 'pre-wrap' }}>
              {`${isCommercial ? 'Коммерция' : 'Переуступки'} Санкт-Петербурга`}
            </h1>
            {!isCommercial && <AttentionBlock openBuyModal={openBuyModal} isForIpad={true} />}
          </div>
          {!isPublic && !isClientMode &&<Pannels />}

          {(isShowFilter || !isPublic) && (isMobile ?
            <>
              <button
                className="button-mobile for-assignations"
                onClick={() => {
                  toggleFilters()
                }}
              >
                Фильтры
                {countFilters > 0 && ` (${countFilters})`}
              </button>

              <div
                className={`index-search__filter-controls-col ${isMobile ? 'mobile-filters__show-all' : ''}`}
                style={{ position: 'relative' }}
              >
                <div className="index-search__filter-control index-search__filter-submit">
                  {countToShow ? (
                    <button
                      className="button button_view_default"
                      type="submit"
                      onClick={(e) => {
                        handleFilterClick(e)
                        // toggleFilters()
                      }}
                      disabled={fetching ? true : false}
                      style={{ borderRadius: '5px', fontSize: '14px', textTransform: 'none' }}
                    >
                      Показать переуступки
                    </button>
                  ) : (
                    <button
                      className="button button_view_default"
                      type="submit"
                      style={{ borderRadius: '5px', fontSize: '14px', textTransform: 'none' }}
                      disabled
                    >
                      Ничего не найдено
                    </button>
                  )}
                </div>
              </div>
            </>

            :

            <>
              {filters}
            </>)
          }

          {isOpen && isMobile &&
    				<div className="mobile-filters for-commercial">
    					<div className="mobile-filters__title is-fixed">
    						<svg
    							className="icon icon_arrow_up"
    							onClick={() => {
    								toggleFilters()
                    props.closeClick && props.closeClick()
    							}}
    						>
    							<use xlinkHref="#arrow_up"></use>
    						</svg>
    						<h3>Все фильтры</h3>
    					</div>
    					<div className="mobile-filters__content">
    						{filters}
    					</div>
    				</div>
    			}

        </div>
      </div>
    </section>
  );
};

// const mapStateToProps = (state, ownProps) => ({
//   city: ownProps.city ? ownProps.city : state.user.info.city,
//   countApartments: state.estate.countApartments,
//   isClientMode: state.general?.clientMode,
// });

// const mapDispatchToProps = (dispatch, ownProps) => {
//   if (ownProps.isCommercial) {
//     return {
//       clearFilter: () => dispatch({ type: 'COMMERCIAL_CLEAR_FILTER' }),
//       clearFilterOnly: () => dispatch({ type: 'COMMERCIAL_CLEAR_FILTER_ONLY' }),
//       clearExcludeOnly: () =>
//         dispatch({ type: 'COMMERCIAL_CLEAR_EXCLUDE_ONLY' }),
//     };
//   }
//   return {
//     clearFilter: () => dispatch({ type: 'ASSIGNMENTS_CLEAR_FILTER' }),
//     clearFilterOnly: () => dispatch({ type: 'ASSIGNMENTS_CLEAR_FILTER_ONLY' }),
//     clearExcludeOnly: () =>
//       dispatch({ type: 'ASSIGNMENTS_CLEAR_EXCLUDE_ONLY' }),
//   };
// };

export default Filter
