import "antd/es/table/style/index.css";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import useDropdown from "../../hooks/useDropdown";
import { media } from "../../utils";
import { defaultFullFilterEstate, defaultFullFilterSecondaries, defaultFullFilterSuburbans } from "../../utils/defaultFilters";
import Checkbox from "../core/Checkbox";
import Confirm from "../core/Confirm";
import MobileConfirm from "../core/MobileConfirm";
import MobileModal from "../core/MobileModal";
import Modal from "../core/Modal";
import Popup from '../core/Popup';
import Table from "../core/Table";
import { errorToast, infoToast } from "../toasts";
import EditFilter from "./EditFilter";
import SendFilter from "./SendFilter";


const formattedDate = (date) => {
  const options = { day: "numeric", month: "numeric", year: "numeric" };
  return new Date(date).toLocaleDateString("ru", options);
};

const SavedFiltersAction = (props) => {
  const {
    handleDelete,
    fetch,
    filter,
    exclude,
    id,
    url,
    clients,
    filterDataEstate,
    filterEstate,
    setFilterEstate,
    clearFilterEstate,
    filterView,
    excludeView,
    isShowFilter,
    client,
    filterType,
    clearFilterOnly,
    clearExcludeOnly,
    mayBeDeleted
  } = props;
  const [node, open, show, hide] = useDropdown(false);
  const [tooltip, setTooltip] = useState(false);

  const correctedFilter = (filter) => {
    const specials = ["isNoFirstFloor", "isApartments", "isOnlyHanded","isFirstFloor", "encumbrances"];
    const result = filter;

    specials.forEach((key) => {
      if (Array.isArray(result[key]) || !result[key]) return;
      result[key] = [{ id: result[key] }];
    });

    return result;
  };
  const getDefFilter = () => {
    if (filterType === "primary") {
      return defaultFullFilterEstate
    }
    if (filterType === "secondary") {
      return defaultFullFilterSecondaries
    }
    if (filterType === "suburban") {
      return defaultFullFilterSuburbans
    }
    return null
  }
  const [showEdit, hideEdit] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideEdit} classes="modal_filter modal_filter-wide">
        <EditFilter
          defaultFilter={correctedFilter(filter)}
          defaultExclude={correctedFilter(exclude)}
          filterId={id}
          filter={filterEstate}
          filterData={filterDataEstate}
          setFilter={setFilterEstate}
          clearFilter={clearFilterEstate}
          hide={hideEdit}
          clientId={client?.id}
          fetch={fetch}
          clientName={`${client?.name} ${client?.lastName}`}
          filterShow={isShowFilter == 1}
          filterType={filterType}
          clearFilterOnly={clearFilterOnly}
          clearExcludeOnly={clearExcludeOnly}
          defFilter={getDefFilter()}
        />
      </Modal>
    ),
    [filterEstate, filterDataEstate, client, filter, exclude]
  );

  const [showMobileEdit, hideMobileEdit] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideMobileEdit} title="Редактирование" className="edit-saved-filters">
        <EditFilter
          defaultFilter={correctedFilter(filter)}
          defaultExclude={correctedFilter(exclude)}
          filterId={id}
          filter={filterEstate}
          filterData={filterDataEstate}
          setFilter={setFilterEstate}
          clearFilter={clearFilterEstate}
          hide={hideEdit}
          clientId={client?.id}
          fetch={fetch}
          clientName={`${client?.name} ${client?.lastName}`}
          filterShow={isShowFilter == 1}
          filterType={filterType}
          clearFilterOnly={clearFilterOnly}
          clearExcludeOnly={clearExcludeOnly}
          defFilter={getDefFilter()}
        />
      </MobileModal>
    ),
    [filterEstate, filterDataEstate, client, filter, exclude]
  );

  const [sendFilterModal, hideSendFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={handleCloseSendModal} classes="modal_filter">
        <SendFilter
          filterId={id}
          fetch={fetch}
          clientId={client?.id}
          filter={filterEstate}
          filterData={filterDataEstate}
          filterView={{ filter: filterView, exclude: excludeView }}
          sendOnly
          hide={handleCloseSendModal}
          city={filterType === "assignment"? "spb": null}
        />
      </Modal>
    ),
    [filterEstate, filterDataEstate, client, filterView, excludeView]
  );

  const [sendMobileFilterModal, hideMobileSendFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideMobileSendFilterModal} title="Отправить фильтр">
        <SendFilter
          filterId={id}
          fetch={fetch}
          clientId={client?.id}
          filter={filterEstate}
          filterData={filterDataEstate}
          filterView={{ filter: filterView, exclude: excludeView }}
          sendOnly
          hide={handleCloseSendModal}
          city={filterType === "assignment"? "spb": null}
        />
      </MobileModal>
    ),
    [filterEstate, filterDataEstate, client, filterView, excludeView]
  );

  useEffect(() => {
    if (tooltip) {
      delay(2000).then(() => setTooltip(false));
    }
  }, [tooltip]);

  const delay = (ms) =>
    new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });

  const showConfirm = () => {
    media('isMobile') ? confirmMobileModal() : confirmModal();
    hide();
  };

  const confirm = () => {
    handleDelete([props.id]).then(
      (data) => {
        hideModal();
        infoToast(<div>{data.message}</div>);
      },
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const handleSendModal = () => {
    clearFilterEstate();
    setFilterEstate({
      filter: filter,
      exclude: exclude,
    });
    media('isMobile') ? sendMobileFilterModal() : sendFilterModal();
  };

  const handleCloseSendModal = () => {
    clearFilterEstate();
    hideSendFilterModal();
  };

  const [confirmModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Confirm
        close={hideModal}
        title={`Вы действительно хотите удалить фильтр клиента ${client?.name} ${client?.lastName}?`}
        confirm={confirm}
        cancel={hideModal}
      />
    ),
    [client]
  );

  const [confirmMobileModal, hideMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileConfirm
        closeClick={hideMobileModal}
        title={`Вы действительно хотите удалить фильтр клиента ${client?.name} ${client?.lastName}?`}
        mobileTitle="Удаление фильтра"
        confirm={confirm}
        cancel={hideModal}
      />
    ),
    [client]
  );

  const clientPhone = () => {
    if (!clients || !clients.length) return "";

    let person = clients.find((item) => item.id == client?.id);
    if (!person) return "";

    return person.phone.replace(/[+() -]/g, "");
  };

  const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);

  const [closePopup, setClosePopup] = useState(false);
  const closeClick = () => {
    setClosePopup(true)
    setClosePopup(false)
  }

  const editClick = () => {
    closeClick()
    showMobileEdit()
  }

  const removeClick = () => {
    closeClick()
    showConfirm()
  }

  useEffect(() => {
    setClosePopup(true)
  }, [closePopup]);

  const popupContent = (
    <div className="dt__more">
      <hr />
      <div>
        <div className={`popover ${tooltip ? " is-showing" : ""}`}>
          <CopyToClipboard
            text={url}
            onCopy={() => setTooltip(true)}
          >
            <div className="dt__more-control">
              <svg className="icon">
                <use xlinkHref="#icon_copy"></use>
              </svg>
              <span>Скопировать ссылку</span>
            </div>
          </CopyToClipboard>
          <div className="popover__block popover__block_align_left clip__tooltip">
            <div className="popover__inner">
              <div className="clip__tooltip-close">
                <button
                  className="button button_type_delete"
                  onClick={() => setTooltip(false)}
                ></button>
              </div>
              <div className="clip__tooltip-text">
                Ссылка скопирована на ваш компьютер
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dt__more-control" onClick={media('isMobile') ? editClick : showEdit}>
        <svg className="icon icon_delete">
          <use xlinkHref="#edit"></use>
        </svg>
        <span>Редактировать</span>
      </div>
      {mayBeDeleted && <div className="dt__more-control" onClick={media('isMobile') ? removeClick : showConfirm}>
        <svg className="icon icon_delete">
          <use xlinkHref="#delete"></use>
        </svg>
        <span>Удалить</span>
      </div>}
      <hr style={{ margin: "15px 0 0" }} />
      <div>
        <div
          className="send-filter"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: media('isMobile') ? 'flex-start' : "center",
          }}
        >
          <div
            className="send-filter__label"
            style={{ margin: media('isMobile') ? '10px 0 10px 35px' : "10px 0" }}
          >
            Отправить клиенту
          </div>
          <ul className="subscribe__socials">
            <li
              className="subscribe__socials-item"
              onClick={() => handleSendModal()}
              style={{ width: "30px", height: "30px" }}
            >
              <div
                className="subscribe__socials-link"
                style={{ fontSize: "14px" }}
              >
                <svg
                  className="icon icon_social-mail"
                  style={{ transform: "none" }}
                >
                  <use xlinkHref="#social-mail"></use>
                </svg>
              </div>
            </li>
            <li
              className="subscribe__socials-item"
              style={{ width: "30px", height: "30px" }}
            >
              <a
                className="subscribe__socials-link"
                style={{ fontSize: "14px" }}
                href={isMobile
                  ? `https://wa.me/${clientPhone()}?text=${url}`
                  : `https://web.whatsapp.com/send?phone=${clientPhone()}&text=${url}`
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="icon icon_social-wa"
                  style={{ transform: "none" }}
                >
                  <use xlinkHref="#social-wa"></use>
                </svg>
              </a>
            </li>
            <li
              className="subscribe__socials-item"
              style={{ width: "30px", height: "30px" }}
            >
              <a
                className="subscribe__socials-link"
                style={{ fontSize: "14px" }}
                href={`tg://msg_url?url=${url}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="icon icon_social-tg"
                  style={{ transform: "none" }}
                >
                  <use xlinkHref="#social-tg"></use>
                </svg>
              </a>
            </li>
            <li
              className="subscribe__socials-item"
              style={{ width: "30px", height: "30px" }}
            >
              <a
                className="subscribe__socials-link"
                style={{ fontSize: "14px" }}
                href={`viber://pa?chatURI=${clientPhone()}&text=${url}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="icon icon_social-viber"
                  style={{ transform: "none" }}
                >
                  <use xlinkHref="#social-viber"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )



  return (
    <div className="control_more">
      <div className="form__item">
        <div
          ref={node}
          className={
            "dropdown js-dropdown form__drop" + (open ? " is-showing" : "")
          }
        >
          {media('isMobile') ?
            <Popup
              className="saved-filters-popup"
              trigger={
                <button
                  type="link"
                  className="dropdown__btn button form__drop-btn button_type_round"
                  onClick={(e) => show()}
                >
                  <span className="form__drop-name"></span>
                  <svg className="icon icon_elipsis">
                    <use xlinkHref="#elipsis"></use>
                  </svg>
                </button>
              }
              closePopup={closePopup}
            >
              {popupContent}
            </Popup> :

            <>
              <button
                type="link"
                className="dropdown__btn button form__drop-btn button_type_round"
                onClick={(e) => show()}
              >
                <span className="form__drop-name"></span>
                <svg className="icon icon_elipsis">
                  <use xlinkHref="#elipsis"></use>
                </svg>
              </button>
              <div className="dropdown__block" style={{ overflow: "visible" }}>
                <div className="dropdown__inner">
                  <div className="form__drop-content">
                    {popupContent}
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  const {
    items,
    handleDelete,
    handleActive,
    fetch,
    clients,
    filterDataEstate,
    filterEstate,
    setFilterEstate,
    clearFilterEstate,
    filterDataAssignments,
    filterAssignments,
    setFilterAssignments,
    clearFilterAssignments,
    clearEstateFilterOnly,
    clearEstateExcludeOnly,

    setFilterCommercial,
    clearFilterCommercial,
    filterDataCommercial,
    filterCommercial,
    clearCommercialExcludeOnly,
    clearCommercialFilterOnly,

    setFilterSecondary,
    clearFilterSecondary,
    clearSecondaryFilterOnly,
    clearSecondaryExcludeOnly,
    filterDataSecondaries,
    filterSecondaries,

    setFilterSuburbans,
    clearFilterSuburbans,
    clearSuburbansFilterOnly,
    clearSuburbansExcludeOnly,
    filterDataSuburbans,
    filterSuburbans,

    user
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsSettings, setSelectedItemsSettings] = useState({
    filterView: [],
    excludeView: [],
  });

  useEffect(() => {
    resetSettings();
    if (selectedItems && !selectedItems.length) return;

    let settings = { filterView: [], excludeView: [] };

    selectedItems.forEach((item) => {
      return (settings = {
        filterView: [
          ...settings.filterView,
          ...(item.filterView ? item.filterView : []),
        ],
        excludeView: [
          ...settings.excludeView,
          ...(item.excludeView ? item.excludeView : []),
        ],
      });
    });

    setSelectedItemsSettings(settings);
  }, [selectedItems]);

  const resetSettings = () => {
    setSelectedItemsSettings({
      filterView: [],
      excludeView: [],
    });
  };

  const [sendFilterModal, hideSendFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSendFilterModal} classes="modal_filter">
        <SendFilter
          fetch={fetch}
          type="sendOnly"
          hide={hideSendFilterModal}
          clients={clients}
          selectedItemsSettings={selectedItemsSettings}
        />
      </Modal>
    ),
    [selectedItemsSettings]
  );

  const [sendMobileFilterModal, hideMobileSendFilterModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideMobileSendFilterModal} title="Отправить фильтр">
        <SendFilter
          fetch={fetch}
          type="sendOnly"
          hide={hideSendFilterModal}
          clients={clients}
          selectedItemsSettings={selectedItemsSettings}
        />
      </MobileModal>
    ),
    [selectedItemsSettings]
  );

  const onSelectItem = (checked, id) => {
    if (id == "all") {
      setSelectedItems(checked ? items.filter((item) => user.id == item.agent?.id) : []);
      return;
    }

    const checkedItem = items.find((item) => item.id == id && user.id == item.agent?.id)
    setSelectedItems(
      checked
        ? [...selectedItems, ...(checkedItem? [checkedItem]: [])]
        : [...selectedItems.filter((item) => item.id != id)]
    );
  };

  const showConfirm = () => {
    if (selectedItems && !selectedItems.length) return errorToast(<div>Не выбраны фильтры</div>) 
    media('isMobile') ? confirmMobileModal() : confirmModal();
  };

  const confirm = () => {
    const ids = selectedItems.map((item) => item.id);

    handleDelete(ids).then(
      () => {
        hideModal();
        infoToast(<div>Фильтры удалены</div>)
      },
      error => {
        hideModal();
        errorToast(<div>{error?.[0]?.message}</div>)
      }
    );
  };

  const onChangeActive = (record) => {
    const { id, isActive } = record;

    handleActive(id, isActive ? "N" : "Y").then(
      data => infoToast(<div>{data.message}</div>),
      error => errorToast(<div>{error?.[0]?.message}</div>)
    );
  };

  const [confirmModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Confirm
        close={hideModal}
        title={`Вы действительно хотите удалить выбранные фильтры?`}
        confirm={confirm}
        cancel={hideModal}
      />
    ),
    [confirm]
  );

  const [confirmMobileModal, hideMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileConfirm
        closeClick={hideModal}
        title={`Вы действительно хотите удалить выбранные фильтры?`}
        mobileTitle='Удаление фильтра'
        confirm={confirm}
        cancel={hideModal}
      />
    ),
    [confirm]
  );

  const getMobileColumns = () => [
    {
      title: "Актив.",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, record) => {
        return (
          <label className="switch">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                defaultChecked={isActive}
                onChange={() => onChangeActive(record)}
              />
              <div className="switch__input"></div>
              <div className="switch__marker"></div>
            </div>
          </label>
        );
      },
    },
    {
      title: "Клиент",
      dataIndex: "client",
      key: "client",
      render: (client, record) => {
        if (!client) return null;
        return (
          <Link
            className="clients__table-link clients__table-client"
            to={`/personal/clients/${client?.id}`}
          >
            {`${client?.name} ${client?.lastName !== null ? client?.lastName : ``}`}
          </Link>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 50,
      render: (text, record) => {
        let setFilter, clearFilter, filterData, filter, clearExcludeOnly,clearFilterOnly;
        switch (record.filterType) {
          case "secondary":
            setFilter = setFilterSecondary;
            clearFilter = clearFilterSecondary;
            filterData = filterDataSecondaries;
            filter = filterSecondaries;
            clearFilterOnly = clearSecondaryFilterOnly;
            clearExcludeOnly = clearSecondaryExcludeOnly;
            break
          case "assignment":
            setFilter = setFilterAssignments;
            clearFilter = clearFilterAssignments;
            filterData = filterDataAssignments;
            filter = filterAssignments;
            clearFilterOnly = clearFilterAssignments;
            clearExcludeOnly = clearFilterAssignments; // пока нет исключений в уступках
            break
          case "commercial":
            setFilter = setFilterCommercial;
            clearFilter = clearFilterCommercial;
            filterData = filterDataCommercial;
            filter = filterCommercial;
            clearFilterOnly = clearCommercialFilterOnly;
            clearExcludeOnly = clearCommercialExcludeOnly;
            break
          case "suburban":
            setFilter = setFilterSuburbans;
            clearFilter = clearFilterSuburbans;
            filterData = filterDataSuburbans;
            filter = filterSuburbans;
            clearFilterOnly = clearSuburbansFilterOnly;
            clearExcludeOnly = clearSuburbansExcludeOnly;
            break
          default:
            setFilter = setFilterEstate;
            clearFilter = clearFilterEstate;
            filterData = filterDataEstate;
            filter = filterEstate;
            clearFilterOnly= clearEstateFilterOnly
            clearExcludeOnly= clearEstateExcludeOnly
            break

        }
        return (
          <SavedFiltersAction
            {...record}
            mayBeDeleted={record.agent.id == user.id}
            handleDelete={handleDelete}
            setFilterEstate={setFilter}
            clearFilterEstate={clearFilter}
            filterDataEstate={filterData}
            filterEstate={filter}
            fetch={fetch}
            clients={clients}
            clearFilterOnly={clearFilterOnly}
            clearExcludeOnly={clearExcludeOnly}
          />
        );
      },
    },
  ]

  const getColumns = () => [
    {
      title: () => (
        <Checkbox
          id={"all"}
          view="invert"
          onChange={(event) => onSelectItem(event.target.checked, "all")}
        />
      ),
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (id,record) => (
        record.agent?.id == user.id? <Checkbox
          id={id}
          view="invert"
          checked={!!selectedItems.find((item) => item.id == id)}
          onChange={(event) => onSelectItem(event.target.checked, id)}
        />: ""
      ),
    },
    {
      title: "Активность",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, record) => {
        return (
          <label className="switch">
            <div className="switch__box">
              <input
                className="switch__control"
                type="checkbox"
                defaultChecked={isActive}
                onChange={() => onChangeActive(record)}
              />
              <div className="switch__input"></div>
              <div className="switch__marker"></div>
            </div>
          </label>
        );
      },
    },
    {
      title: "Клиент",
      dataIndex: "client",
      key: "client",
      sorter: (a, b) => {
        if (!a.client?.name) return 0;
        if (!b.client?.name) return 1;

        const nameA = `${a.client?.name} ${a.client?.lastName}`;
        const nameB = `${b.client?.name} ${b.client?.lastName}`;

        return nameA > nameB ? 1 : -1;
      },
      render: (client, record) => {
        if (!client) return null;
        return (
          <Link
            className="clients__table-link clients__table-client"
            to={`/personal/clients/${client?.id}`}
          >
            <span className="nowrap">{`${client?.name} ${client?.lastName !== null ? client?.lastName : ``}`}</span>
            <svg className="icon icon_external-link">
              <use xlinkHref="#external_link"></use>
            </svg>
          </Link>
        );
      },
    },
    {
      title: "Агент",
      dataIndex: "agent",
      key: "agent",
      render: (agent, record) => {
        return (
          <span className="nowrap">{`${agent?.name} ${agent?.lastName}`}</span>
        );
      },
    },
    {
      title: "Дата создания",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        new Date(a.dateCreate) > new Date(b.dateCreate) ? 1 : -1,
      render: (text, record) => {
        return (
          <span className="nowrap">{formattedDate(record.dateCreate)}</span>
        );
      },
    },
    {
      title: "Посл. просмотр",
      dataIndex: "lastView",
      key: "lastView",
      sorter: (a, b) => {
        if (isNaN(new Date(a.lastView).getTime())) return -1;
        if (isNaN(new Date(b.lastView).getTime())) return 1;

        return new Date(a.lastView) > new Date(b.lastView) ? 1 : -1;
      },
      render: (text, record) => {
        let dateValue = record.lastView;
        if (isNaN(new Date(dateValue).getTime())) return "—";

        return <span className="nowrap">{formattedDate(dateValue)}</span>;
      },
    },
    {
      title: "",
      dataIndex: "views",
      key: "views",
      width: 50,
      render: (text, record) => (
        <div className="label_with-icon_prefix">
          <svg
            className="label__icon icon icon_watch"
            style={{ width: "18px", marginRight: "10px" }}
          >
            <use xlinkHref="#icon_watch"></use>
          </svg>
          {record.countView}
        </div>
      ),
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => (new Date(a.lastView) > new Date(b.lastView) ? 1 : -1),
      render: (text, record) => {
        let dateValue = record.lastView;
        if (isNaN(new Date(dateValue).getTime())) dateValue = record.dateCreate;
        return (
          <div className="filter-row">
            {record.filter &&
              record.filterView &&
              record.filterView.length > 0 && (
                <div className="filter-row__block">
                  <div className="filter-row__block-name">Параметры</div>
                  {record.filterView.map((item, i) => (
                    <div className="filter-row__item" key={i}>
                      <div className="filter-row__type">{item.name}:</div>
                      <div
                        className="filter-row__value"
                        dangerouslySetInnerHTML={{ __html: item.values }}
                      ></div>
                    </div>
                  ))}
                </div>
              )}
            {record.exclude &&
              record.excludeView &&
              record.excludeView.length > 0 && (
                <div className="filter-row__block">
                  <div className="filter-row__block-name">Исключения</div>
                  {record.excludeView.map((item, i) => (
                    <div className="filter-row__item" key={i}>
                      <div className="filter-row__type">{item.name}:</div>
                      <div
                        className="filter-row__value"
                        dangerouslySetInnerHTML={{ __html: item.values }}
                      ></div>
                    </div>
                  ))}
                </div>
              )}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 50,
      render: (text, record) => {
        let setFilter, clearFilter, filterData, filter, clearExcludeOnly,clearFilterOnly;
        switch (record.filterType) {
          case "secondary":
            setFilter = setFilterSecondary;
            clearFilter = clearFilterSecondary;
            filterData = filterDataSecondaries;
            filter = filterSecondaries;
            clearFilterOnly = clearSecondaryFilterOnly;
            clearExcludeOnly = clearSecondaryExcludeOnly;
            break
          case "assignment":
            setFilter = setFilterAssignments;
            clearFilter = clearFilterAssignments;
            filterData = filterDataAssignments;
            filter = filterAssignments;
            clearFilterOnly = clearFilterAssignments;
            clearExcludeOnly = clearFilterAssignments; // пока нет исключений в уступках
            break
          case "commercial":
            setFilter = setFilterCommercial;
            clearFilter = clearFilterCommercial;
            filterData = filterDataCommercial;
            filter = filterCommercial;
            clearFilterOnly = clearCommercialFilterOnly;
            clearExcludeOnly = clearCommercialExcludeOnly;
            break
          case "suburban":
            setFilter = setFilterSuburbans;
            clearFilter = clearFilterSuburbans;
            filterData = filterDataSuburbans;
            filter = filterSuburbans;
            clearFilterOnly = clearSuburbansFilterOnly;
            clearExcludeOnly = clearSuburbansExcludeOnly;
            break
          default:
            setFilter = setFilterEstate;
            clearFilter = clearFilterEstate;
            filterData = filterDataEstate;
            filter = filterEstate;
            clearFilterOnly= clearEstateFilterOnly
            clearExcludeOnly= clearEstateExcludeOnly
            break

        }
        return (
          <SavedFiltersAction
            {...record}
            mayBeDeleted={record.agent.id == user.id}
            handleDelete={handleDelete}
            setFilterEstate={setFilter}
            clearFilterEstate={clearFilter}
            filterDataEstate={filterData}
            filterEstate={filter}
            fetch={fetch}
            clients={clients}
            clearFilterOnly={clearFilterOnly}
            clearExcludeOnly={clearExcludeOnly}
          />
        );
      },
    },
  ];

  const expandedRow = (record) => {

    const lastView = () => {
      let dateValue = record.lastView;
      if (isNaN(new Date(dateValue).getTime())) return "—";

      return formattedDate(dateValue)
    }

    const description = () => {
      let dateValue = record.lastView;
      if (isNaN(new Date(dateValue).getTime())) dateValue = record.dateCreate;
      return (
        <div className="filter-row">
          {record.filter &&
            record.filterView &&
            record.filterView.length > 0 && (
              <div className="filter-row__block">
                <div className="filter-row__block-name">Параметры:</div>
                {record.filterView.map((item, i) => (
                  <div className="filter-row__item" key={i}>
                    <div className="filter-row__type">{item.name}:</div>
                    <div
                      className="filter-row__value"
                      dangerouslySetInnerHTML={{ __html: item.values }}
                    ></div>
                  </div>
                ))}
              </div>
            )}
          {record.exclude &&
            record.excludeView &&
            record.excludeView.length > 0 && (
              <div className="filter-row__block">
                <div className="filter-row__block-name">Исключения:</div>
                {record.excludeView.map((item, i) => (
                  <div className="filter-row__item" key={i}>
                    <div className="filter-row__type">{item.name}:</div>
                    <div
                      className="filter-row__value"
                      dangerouslySetInnerHTML={{ __html: item.values }}
                    ></div>
                  </div>
                ))}
              </div>
            )}
        </div>
      );
    }

    return (
      <div className="flats__row-table-expanded">
        <div className="flats__row-table-expanded-column">
          <div>
            {description()}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Агент:</span>
            {`${record.agent?.name} ${record.agent?.lastName}`}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Посл. просмотр:</span>
            {lastView()}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Дата создания:</span>
            {formattedDate(record.dateCreate)}
          </div>
          <div className="lk-presentation__clients">
            <span className="flats__row-table-expanded-title">Кол-во просмот.:</span>
            <div className="label_with-icon_prefix">
              <svg
                className="label__icon icon icon_watch"
                style={{ width: "18px", marginRight: "10px" }}
              >
                <use xlinkHref="#icon_watch"></use>
              </svg>
              {record.countView}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleSendModal = () => {
    media('isMobile') ? sendMobileFilterModal() : sendFilterModal();
  };

  return (
    <div className="pan-table for-saved-filters">
      <div className="table__controls">
        {/* <div className="button button_view_control" onClick={handleSendModal}>
					<svg className="icon icon_mail">
						<use xlinkHref="#mail"></use>
					</svg>
					<span>Отправить по почте</span>
				</div> */}
        {!media('isMobile') &&
          <div className="button button_view_control" onClick={showConfirm}>
            <svg className="icon icon_delete">
              <use xlinkHref="#delete"></use>
            </svg>
            <span>Удалить</span>
          </div>
        }
      </div>
      <div className="flats__row-table is-active">
        <Table
          rowKey="id"
          showSorterTooltip={false}
          columns={media("isMobile") ? getMobileColumns() : getColumns()}
          expandable={media("isMobile") ? {
            expandedRowRender: record => expandedRow(record),
          } : false}
          dataSource={items}
          pagination={false}
          locale={{ emptyText: "Ничего не найдено" }}
          customPagination={true}
        />
      </div>
    </div>
  );
};

export default Items;
