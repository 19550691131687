import { memo } from 'react';
import SelectFilter from '../../FilterRealty/SelectFilter';
import { getSelectPropsCreator } from '../utils';
import PriceFilter from '../../FilterRealty/PriceFilter';
import SquareFilterOnly from "../../FilterRealty/SquareFilterOnly";

const CommercialTopFilter = memo(props => {
  const {
    filter,
    filterData,
    handleFilter,
    setFilterAndExclude,
    clearExcludeOnly,
    clearFilterOnly,
    wrapperClass 
  } = props;

  const createSelectProps = getSelectPropsCreator(props);
  return <>
    <SelectFilter
      {...createSelectProps("districts")}
      data={{
        title: "Район",
        autocompleteTitle: "Введите название района",
        identity: "districts",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("subways")}
      data={{
        title: "Метро",
        autocompleteTitle: "Введите название метро",
        identity: "subways",
      }}
      wrapperClass={wrapperClass}
    />
    <SelectFilter
      {...createSelectProps("builders")}
      data={{
        title: "Застройщик",
        autocompleteTitle: "Введите название застройщика",
        identity: "builders",
      }}
      wrapperClass={wrapperClass}
    />
    <SquareFilterOnly
      filter={filter.squareTotal ?? { min: "", max: "" }}
      data={{
        title: 'Площадь',
        minPlaceholder: 'S общ. от ',
        maxPlaceholder: 'S общ. до ',
        defaultValues: filterData.squareTotal?.range ?? null
      }}
      onChange={({ filter }) => handleFilter("squareTotal", filter)}
      clearFilterOnly={clearFilterOnly}
      clearExcludeOnly={clearExcludeOnly}
      fullFilter={filter}
      identity={"squareTotal"}
      items={filterData.squareTotal?.items}
      showPlaceholderOnLists
      debounced
      wrapperClass={wrapperClass}
    />
    <PriceFilter
      filter={filter.prices ?? { min: "", max: "" }}
      items={filterData.prices?.items ?? []}
      data={{
        title: "Цена",
        minPlaceholder: "от ",
        maxPlaceholder: "до ",
        defaultValues: filterData.prices?.range ?? 0
      }}
      onChange={({ filter }) => setFilterAndExclude({ filter }, "prices")}
      debounced
      wrapperClass={wrapperClass}
    />
  </>
});

export default CommercialTopFilter;
