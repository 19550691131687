import formatter from "format-number";
import noUiSlider from "nouislider";
import React, { useEffect, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { connect } from "react-redux";
import { getUrl } from "../api";
import useMedia from "../hooks/useMedia";
import MobileModal from "./core/MobileModal";
import Modal from "./core/Modal";
import Wrap from "./core/ModalWrap";
import MortgageForm from "./forms/MortgageForm";
import { successToast } from "./toasts";

const priceFormat = (price) =>
  formatter({ integerSeparator: " ", suffix: " р." })(price);

const Manager = (props) => {
  const { name, lastName, photo, email, phone, position, telegram } = props;
  return (
    <div class="calc__manager calc__col">
      <div class="calc__manager-description">
        Для расчета точных платежей обратитесь к специалистам ипотечного Центра.
      </div>
      <div class="calc__manager-card">
        <div
          class="calc__manager-photo"
          style={{ backgroundImage: `url(${getUrl(photo.src)})` }}
        ></div>
        <div class="calc__manager-content">
          <h3 class="h3 calc__manager-name">
            {name} {lastName}
          </h3>
          <div class="calc__manager-label">{position}</div>
          <div class="calc__manager-contacts">
            <a class="lk-order__contacts-link" href={`tel:${phone}`}>
              {phone}
            </a>
            <a
              class="lk-order__contacts-link"
              href={`https://telegram.im/@${telegram}`}
              target="_blank"
            >
              @{telegram}
            </a>
            <a class="lk-order__contacts-link" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Mortgage = (props) => {
  const { manager, innerRef, price, period, prepay, percent, id, type } = props;
  const isMobile = useMedia("isMobile")
  const [calc, setCalc] = useState({
    price: price,
    period: period,
    prepay: prepay,
    percent: percent,
    total: 0
  });

  const prepayRef = useRef(null);

  const [total, setTotal] = useState(0);
  const [maxPrepay, setMaxPrepay] = useState(0);

  const changeTotal = (tmpCalc) => {
    let { price, period, prepay, percent } = tmpCalc;
    let payment = price - prepay;
    let m = percent / 100 / 12;
    let koef =
      (m * Math.pow(1 + m, period * 12)) / (Math.pow(1 + m, period * 12) - 1);
    let result = (payment * koef).toFixed();
    setTotal(result);
    handleChange("total", result);
  };

  const handleChange = (name, value) => {
    let tmpCalc = calc;
    tmpCalc[name] = value;
    setCalc(tmpCalc);
    if (name !== "total") {
      changeTotal(tmpCalc);
    }
    if (name === "price") {
      setMaxPrepay(value);
    }
  };

  useEffect(() => {
    initInputSliders();
  }, []);

  useEffect(() => {
    if (prepayRef !== undefined) {
      prepayRef.current.noUiSlider.updateOptions({
        range: {
            'min': 100000,
            'max': maxPrepay
        },
        start: prepay,
    });
    }
  }, [maxPrepay]);

  const formatNumbers = (n) => {
    const val = (n + "").split(" ").join("");
    n = val;
    return (n + "")
      .split("")
      .reverse()
      .join("")
      .replace(/(\d{3})/g, "$1 ")
      .split("")
      .reverse()
      .join("")
      .replace(/^ /, "");
  };

  const initInputSliders = () => {
    const slidersContainers = [...document.querySelectorAll(".slider-input")];

    slidersContainers.forEach((container) => {
      const valueInput = container.querySelector(".slider-input__control");
      const slider = container.querySelector(".slider__input");
      const postfix = container.dataset.postfix;
      const isPrice = container.dataset.isPrice !== undefined;
      const start = Number(container.dataset.start);
      const step = Number(container.dataset.step);
      const min = Number(container.dataset.min);
      const max = Number(container.dataset.max);
      const name = valueInput.name;

      noUiSlider.create(slider, {
        start,
        connect: [true, false],
        range: {
          min,
          max,
        },
        step,
        format: {
          to: function (value) {
            let result = step % 1 > 0 ? value.toFixed(1) : parseInt(value);

            return formatNumbers(result) + postfix;
          },
          from: function (value) {
            if (postfix)
              return Number(value.replace(postfix, "").replace(/\ /g, ""));
            let n = value.replace(/\,/g, ".");
            return Number(n.replace(/\ /g, ""));
          },
        },
      });

      slider.noUiSlider.on("update", function (values, handle) {
        valueInput.value = values[handle];

        handleChange(
          name,
           Number(values[handle].replace(postfix, "").replace(/\ /g, ""))
        );
      });

      valueInput.addEventListener("change", function () {
        slider.noUiSlider.set(this.value);
      });

      valueInput.addEventListener("input", function () {
        // Сохраняем позицию курсора
        let pos = this.selectionStart;
        const prevLength = this.value.length;

        this.value = formatNumbers(this.value);
        const newLength = this.value.length;

        // Возвращаем курсор к своей настоящей позиции
        pos = pos + (newLength - prevLength);
        this.setSelectionRange(pos, pos);
      });
    });

    const allPriceSliders = [...document.querySelectorAll(".price-input")];
    allPriceSliders.forEach((slider) => {
      slider.addEventListener("focus", function () {
        this.value = this.value.replace(" р.", "");
      });

      slider.addEventListener("blur", function () {
        if (!this.value) return;
        this.value = this.value.replace(" р.", "") + " р.";
      });
    });
  };

  const submitMortgage = () => {
    closeMortgageForm();
    successToast(<div>Ваша заявка успешно отправлена!</div>)
  };

  const [openMortgageForm, closeMortgageForm] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={closeMortgageForm} title="Рассчитать ипотеку">{children}</MobileModal>
        : <Modal close={closeMortgageForm} classes="modal_task">{children}</Modal>
      } >
        <MortgageForm handleSend={() => submitMortgage()} blockId={id} calc={calc} total={total} type={type} />
      </Wrap >
    ));
  const calculator = (
    <div class="calc__grid">
      <div class="calc__form calc__col mortgage__calc">
        <div class="calculator">
          <div class="calculator__grid">
            <div class="calculator__form">
              <div
                class="calculator__item slider-input"
                data-start={price}
                data-min={1000000}
                data-max={150000000}
                data-step={50000}
                data-postfix=" р."
                data-is-price
              >
                <label htmlFor="mortgage-price">Стоимость квартиры</label>
                <div class="calculator__item-wrapper">
                  <input
                    class="slider-input__control price-input"
                    type="text"
                    id="mortgage-price"
                    name="price"
                  />
                </div>
                <div class="slider__input"></div>
              </div>
              <div
                class="calculator__item slider-input"
                data-start={period}
                data-min="1"
                data-max="30"
                data-step={1}
                data-postfix=""
              >
                <label htmlFor="mortgage-period">Срок кредита, лет</label>
                <div class="calculator__item-wrapper">
                  <input
                    class="slider-input__control"
                    type="text"
                    id="mortgage-period"
                    name="period"
                  />
                </div>
                <div class="slider__input"></div>
              </div>
              <div
                class="calculator__item slider-input"
                data-start={prepay}
                data-min="100000"
                data-max={calc.price}
                data-step={20000}
                data-postfix=" р."
                data-is-price
              >
                <label htmlFor="mortgage-prepay">Первоначальный взнос</label>
                <div class="calculator__item-wrapper">
                  <input
                    class="slider-input__control price-input"
                    type="text"
                    id="mortgage-prepay"
                    name="prepay"
                  />
                </div>
                <div class="slider__input" ref={prepayRef}></div>
              </div>
              <div
                class="calculator__item slider-input"
                data-start={percent}
                data-min="1"
                data-max="20"
                data-step={0.1}
                data-postfix=""
              >
                <label htmlFor="mortgage-percent">Процентная ставка, %</label>
                <div class="calculator__item-wrapper">
                  <input
                    class="slider-input__control"
                    type="text"
                    id="mortgage-percent"
                    name="percent"
                  />
                </div>
                <div class="slider__input"></div>
              </div>
            </div>
            <div class="calculator__result">
              <div class="calculator__result-info">
                Ваш ежемесячный платеж составит *
              </div>
              <div class="calculator__result-value">
                {priceFormat(total)}
              </div>
              <div
                class="calculator__result-btn"
                data-id="modal-mortgage"
              >
                <button
                  className="button button_view_default"
                  onClick={openMortgageForm}
                >
                  Заявка на расчет
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Manager {...manager} />
    </div>
  )

  return (
    <>
      {isMobile ?
        <div className="mobile-section-dark">
          {calculator}
        </div> :
        <section class={`section section_view_dark section__calc ${props.className}`} ref={innerRef}>
          <div id="anchor-calc"></div>
          <div class="wrapper">
            <div class="wrapper__center">
              <div class="block__title">
                <div class="h2">Рассчитайте ипотеку прямо сейчас</div>
              </div>
            </div>
            {calculator}
          </div>
        </section>
      }
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(Mortgage);
