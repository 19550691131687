import intersectionBy from "lodash/intersectionBy";
import React, {
  useEffect,
  useRef, useState
} from "react";
import { connect } from 'react-redux';
import SortRealty from "../../components/SortRealty";
import useElement from '../../hooks/useElement';
import useMedia from "../../hooks/useMedia";
import { ReactComponent as Filters } from "../../images/svg/filters.svg";
import { filterToParams } from "../../utils";
import Card from "../Cards/Card";
import CountRealty from "../CountRealty";
import Pagination from "../Pagination";
import Preloader from "../Preloader";
import PrepaidBanner from "../PrepaidBanner";
import Loader from "../SkeletonLoader";
import MapList from "./MapList";
import Maps from "./Maps";
import ViewRealty from "./ViewRealty";


let paintIsStarted = false;

const Items = (props) => {
  const {
    items,
    innerRef,
    features,
    mapItems,
    countBlocks,
    view,
    handleView,
    page,
    handlePage,
    pageCount,
    handlePageCount,
    fetching,
    filter,
    city,
    setIsApartmentsFilter,
    setisAssigmentsFilter,
    isAgent,
    clientMode,
    countApartmentsToShow,
    banners,
    sortHandler,
    sort,
    sortList
  } = props;

  const [selectedItems, setSelectedItems] = useState(null);
  const [mapCenter, setMapCenter] = useState(
    props.city == "spb" ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
  );

  const [mapZoom, setMapZoom] = useState(9);
  const [currentCluster, setCluster] = useState(null);
  const [currentPoint, setPoint] = useState(null);
  const [isClusterOrPoint, setClusterOrPoint] = useState(null);
  const [isInitMap, setInitMap] = useState(false);

  const getFooter = useElement("footer")
  const isMobile = useMedia("isMobile")
  useEffect(() => {
    if (view == "map") {
      props.fetchEstateMap(props.city, filterToParams(props.filter));
    }
    setMapCenter(
      city == "spb" ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
    );
  }, [view]);
  useEffect(() => {
    if (view == "map") {
      setMapZoom(9);
    }
    setMapCenter(
      city == "spb" ? [59.9342802, 30.3350986] : [55.74954, 37.621587]
    );
  }, [props.filter]);

  const setMapCenterHandler = (coordinates) => {
    setMapCenter(coordinates);
    setMapZoom(16);
  };

  const sideEffectOnClick = (props, option) => {
    let blocks = localStorage.getItem("blocksFilter");
    let tmpFilter = { ...filter.filter };
    if (option === "card__option is-apartments") {
      tmpFilter.isApartments = [{ id: "Y" }];
    }
    if (option === "card__option is-assignments") {
      tmpFilter.assignments = ["7"];
    }
    if (blocks === null) {
      blocks = "{}";
    }
    blocks = JSON.parse(blocks);
    blocks[props.id] = { filter: tmpFilter }; // для старой карточки жк оставить просто "= tmpFilter"
    localStorage.setItem("blocksFilter", JSON.stringify(blocks));
  };
  const filterByCluster = (items) => {
    if (selectedItems) {
      return intersectionBy(items, selectedItems, "id");
    }
    if (currentPoint && isClusterOrPoint === "point") {
      paintIsStarted = false;
      return intersectionBy(items, [currentPoint], "id");
    }
    if (currentCluster && isClusterOrPoint === "cluster") {
      paintIsStarted = false;
      return intersectionBy(items, currentCluster.features, "id");
    }

    return items;
  };

  const mapButtonRef = useRef(null);
  const wrapperRef = useRef(null);

  const fix = (map, footer) => {
    if ((map.current?.offsetTop + window.scrollY) + map.current?.offsetHeight >=
      (footer.offsetTop) - 10) {
      wrapperRef.current.style.cssText = 'position: static;'
      map.current.style.cssText = `${view === "list" && 'position: absolute;'} bottom: 0px;`;
    }

    if (window.scrollY + window.innerHeight < (footer.offsetTop)) {
      wrapperRef.current.style.cssText = 'position: relative;'
      map.current.style.cssText = `position: fixed; bottom: 0;`;
    }
  };

  useEffect(() => {
    if (mapButtonRef.current && getFooter) {
      isMobile && window.addEventListener('scroll', () => fix(mapButtonRef, getFooter));
    }

    return () => {
      isMobile && window.removeEventListener('scroll', () => fix(mapButtonRef, getFooter));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapButtonRef, getFooter, view]);

  const startItems = items?.slice(0, 6)
  const endItems = items?.slice(6)


  const [showComplex, setShowComplex] = useState(false);
  const mapClick = () => {
    setShowComplex(true)
  }

  useEffect(() => {
    if (view === 'list') {
      setShowComplex(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);
  return (
    <section
      className={`section  cards for-main ${(view == "list" && !isMobile) ? "section_view_gray" : "section_view_white"
        }`}
      ref={innerRef}
    >
      <div className="wrapper" ref={wrapperRef}>
        <div className="section__grid cards__header">
          <div className="section__grid-col section__heading">
            <h2 className="section__title">
              Найдено {countApartmentsToShow} квартир в {countBlocks} ЖК
            </h2>
          </div>
          <div className="section__grid-col cards__controls">
          {view === "list" && <SortRealty onChange={sortHandler} sort={sort}  sortList={sortList} />}
            <div></div>
            {isMobile ?
              <div className="controls-wrapper" style={{ top: `${window.innerHeight - 70}px` }} ref={mapButtonRef}>
                <ViewRealty view={view} onChange={handleView} />
                <button className="presentation-filters-btn" onClick={props.onClick}>
                  <Filters />
                  Фильтры
                </button>
              </div> :

              <ViewRealty view={view} onChange={handleView} />
            }
          </div>
        </div>
      </div>
      {view === "list" && (
        <div className="wrapper">
          <div className="section__grid">
            {startItems &&
              startItems.map((item, i) =>
                fetching ? (
                  <Loader key={"loader-" + i} />
                ) : (
                  <Card
                    {...item}
                    showBadges={true}
                    key={i}
                    city={city}
                    setIsApartmentsFilter={setIsApartmentsFilter}
                    setisAssigmentsFilter={setisAssigmentsFilter}
                    whatPage={"estate"}
                    clientMode={clientMode}
                    isAgent={isAgent}
                    sideEffectOnClick={sideEffectOnClick}
                  />
                )
              )}
          </div>
          {banners?.into && startItems?.length === 6 && <PrepaidBanner bannerId="into" banners={banners} addClass="banner-into" />}
          <div className="section__grid">
            {endItems &&
              endItems.map((item, i) =>
                fetching ? (
                  <Loader key={"loader-" + i} />
                ) : (
                  <Card
                    {...item}
                    showBadges={true}
                    key={i}
                    city={city}
                    setIsApartmentsFilter={setIsApartmentsFilter}
                    setisAssigmentsFilter={setisAssigmentsFilter}
                    whatPage={"estate"}
                    clientMode={clientMode}
                    isAgent={isAgent}
                    sideEffectOnClick={sideEffectOnClick}
                  />
                )
              )}
          </div>
          <div className="section__grid">
            <div className="section__grid-col cards__pagination">
              <div className="pagination">
                <Pagination
                  currentPage={page}
                  itemsCount={countBlocks}
                  itemsPerPage={pageCount}
                  onChange={handlePage}
                />
                {!(pageCount > countBlocks) && (
                  <CountRealty
                    itemsCount={countBlocks}
                    itemsPerPage={pageCount}
                    onChange={handlePageCount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {(isInitMap || (view === "map" && props.features.length > 0)) && (
        <div
          className="section__map"
          style={{ display: view === "map" ? "block" : "none" }}
        >
          <div class="map">
            <MapList
              items={filterByCluster(mapItems)}
              city={city}
              setMapCenter={setMapCenterHandler}
              isAgent={isAgent}
              clientMode={clientMode}
              isOpen={view === "map"}
              showComplex={showComplex}
              sideEffectOnClick={sideEffectOnClick}
            />
            <div class="map__container">
              <Maps
                features={features}
                mapItems={mapItems}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                setCluster={setCluster}
                setPoint={setPoint}
                setClusterOrPoint={setClusterOrPoint}
                setInitMap={setInitMap}
                paintIsStarted={paintIsStarted}
                handleClick={isMobile && mapClick}
                hideComplexClick={() => setShowComplex(false)}
              />
            </div>
          </div>
        </div>
      )}
      <Preloader loading={view === "map" && features.length == 0} />
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Items);
