
const CardSkeleton = ({ isLaptop, small, mapBlock }) => <div className="sf__card" style={{ userSelect: "none" }}>
  <div className="sf__card skeleton-box" style={{
    backgroundColor: "#ebebeb",
    // height: isLaptop ? 576 : 640,
    height: isLaptop ? 576 : small ? 265 : 640,
    borderRadius: 20,
    marginBottom: mapBlock ? 20 : 0
  }}>
    <div className="sf-card__header skeletoned">
      <div
        className="sf-card__pic"
        alt=""
        style={{height: small && 150}}
      />
    </div>
    <div className="sf-card__body skeletoned">
      <h3 className="h3 sf-card__title">Pulse Premier</h3>
      <p className="text sf-card__text">Санкт-Петербург г., Октябрьская наб.</p>
      <p className="sf-card__row">
        <p className="text sf-card__descr">SetlCity</p>
        <p className="text sf-card__descr">3 корпусa</p>
      </p>
      {!small && <div className="sf-card__table">
        <div className="sf-card__row">
          <div className="sf-card__col">
            <b className="sf-card__type">студия</b><span >107</span>
          </div>
          <div className="sf-card__col">
            <span className="sf-card__param">от 21.2 м²</span>
          </div>
          <div className="sf-card__col">
            <span className="sf-card__param">от 6 млн.р.</span>
          </div>
        </div>
        <div className="sf-card__row">
          <div className="sf-card__col"><b className="sf-card__type">1к. кв.</b>
            <span >72</span>
          </div>
          <div className="sf-card__col"><span className="sf-card__param">от 29.18 м²</span></div>
          <div className="sf-card__col">
            <span className="sf-card__param">от 7.4 млн.р.</span>
          </div>
        </div>
        <div className="sf-card__row">
          <div className="sf-card__col"><b className="sf-card__type">2к. кв.</b><span
          >5</span></div>
          <div className="sf-card__col"><span className="sf-card__param">от 56.57 м²</span></div>
          <div className="sf-card__col"><span className="sf-card__param">от 13.7 млн.р.</span></div>
        </div>
        <div className="sf-card__row">
          <div className="sf-card__col"><b className="sf-card__type">2Е</b><span >79</span>
          </div>
          <div className="sf-card__col"><span className="sf-card__param">от 32.21 м²</span></div>
          <div className="sf-card__col"><span className="sf-card__param">от 8 млн.р.</span></div>
        </div>
      </div>}
      {!small && <div className="sf-card__metro-list">
        <div className="sf-card__metro sf-card__metro-#ea7125"><span className="sf-card__metro-station">м. Проспект
          Большевиков</span></div>
        <div className="sf-card__metro sf-card__metro-#ea7125"><span className="sf-card__metro-station">м. Улица
          Дыбенко</span></div>
      </div>}
    </div>
  </div>
</div>
export default CardSkeleton;
