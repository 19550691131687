import CardsList from '../../components/Public/markup/CardsList';
import FilterToggleBtn from '../../components/Public/markup/FilterToggleBtn';
import MapBlock from '../../components/Public/markup/MapBlock';
import MapBtn from '../../components/Public/markup/MapBtn';
import Title from '../../components/Public/markup/Title';
import TopBar from '../../components/Public/markup/TopBar';
import './index.scss';

import SubmitButton from '../../components/SubmitButton';
import useFilter from '../../hooks/useFilter';
import useMedia from '../../hooks/useMedia';
import { getSuffix, media } from '../../utils';

import Dropdown from '../../components/core/Dropdown';
import LoadMoreSpinner from '../../components/LoadMoreSpinner';
import Preloader from '../../components/Preloader';
import RealtyMap from '../../components/Public/markup/Map';
import { sortlistDxb } from '../../hooks/useFilter/filterSets';
import { extractDubaiMapItems, extractSuburbansMapItems } from '../../hooks/useFilter/filterUtils';

import { useEffect, useRef, useState } from 'react';

import { useModal } from 'react-modal-hook';
import MobileModal from '../../components/core/MobileModal';
import Modal from '../../components/core/Modal';
import Wrap from '../../components/core/ModalWrap';
import { TgDubaiIcon } from "../../components/Public/markup/icons";

import DraggableZone from '../../components/FilterRealty/Draggable';
import SmartFilter from '../../components/FilterRealty/SmartFilter';
import DubaiCardContainer from '../../components/Public/markup/Containers/DubaiCardContainer';
import MapFilters from '../../components/Public/markup/MapFilters/Index';
import MobileMapFilter from '../../components/Public/markup/MapFilters/MobileMapFilter';
import { useSelector } from 'react-redux';
import usePermission from '../../hooks/usePermission';
import SendFilter from '../../components/SavedFilters/SendFilter';
import { ReactComponent as SavedFilterIcon } from '../../assets/images/icons/SaveFilter.svg'
import { useParams, useRouteMatch } from 'react-router-dom';
import DubaiTopFilters from '../../components/Public/markup/DubaiTopFilters';
import DubaiBottomFilters from '../../components/Public/markup/DubaiBottomFilters';
import DubaiListFilterForm from '../../components/Public/markup/DubaiListFilterForm';
import CurrencysSelect from '../../components/CurrencysSelect';
const SfButton = ({ onClick }) => <div className="villages-save-filter" onClick={onClick}>
  <SavedFilterIcon />
  <span>Сохранить фильтр</span>
</div>
const FilterSubmitButton = ({ onClick, isLoading, count, loaderColor }) => {
  return (
    <SubmitButton
      className="sf__filter-btn btn btn_primary h6 sf__filter-btn_dubai"
      onClick={onClick}
      isLoading={isLoading}
      type="button"
      disabled={!count}

    >
      {`${count} объект${getSuffix(count)}`}
    </SubmitButton>
  );
};

const titleByCity = { // вообще можно и с бэка
  cyp: 'Недвижимость Северного Кипра',
  dxb: 'Недвижимость Дубая'
}

const Dubai = () => {
  const isTablet = useMedia('isTablet');
  const isLaptop = useMedia('isLaptop');
  const isMobile = useMedia('isMobile');
  // const [currency, setCurrency] = useState('USD');
  const [currency, setCurrency] = useState('RUB');

  const [clearedSmartFilter, setCleared] = useState(false);

  const { city } = useParams()

  const {
    handleFilter,
    submit,
    isLoading,
    filter = {},
    exclude = {},
    count,
    data: {
      blocks = [],
      filter: filterData = {},
      prices,
      telegramChannel,
      coordinates
    },
    setFilterAndExclude,
    filterBadges,
    excludeBadges,
    removeBadge,
    isCountLoading,
    changeLimit,
    page,
    limit,
    changePage,
    showSkeleton,
    handleSort,
    sortId,
    infiniteRef,
    moreIsLoading,
    view,
    setView,
    mapItems,
    dispatchMap,
    mapState,
    resetFilter,
    submitMap,
    isMapItemsLoading,

    clearFilter,
    clearExclude,
    filterLength,
    isExcludeEmpty,
    isFilterEmpty,
    switchFilterToExclude,
    createBadges,
    clearByIdent,
    setFilterState,
    favoritesProps,
    get,
    setLoadCondition,
    setMapItems
  } = useFilter({
    apiConfig: {
      component: 'panpartner:primary.ajax',
      getCountFunction: 'getCount',
      submitFunction: 'getBlocks',
      getMapItems: 'getBlocks',
    },
    defaultFilter: {
      status: [1],
    },
    badgeFormattersParams: {
      currency: currency === "RUB" ? "₽" : currency.toLowerCase()
    },
    defaultParams: {
      "filter[currency]": currency,
      city,
    },
    defaultOrder: 'pop',
    defaultBy: 'asc',
    defaultSortId: "0",
    sortList: sortlistDxb,
    sortEnabled: true,
    paginationEnabled: true,
    isGetFilter: true,
    loadMoreEnabled: true,
    defaultLoadCondition: city,
    loadMoreDataKey: 'blocks',
    shadowFilters: ['status'],
    extractCount: ({ countBlocks }) => countBlocks,
    extractLoadMoreCount: ({ countBlocks }) => countBlocks,
    extractMapItems: ({ blocks }) => blocks,
    mapInitialState: {
      zoom: 9,
      showFilters: false,
    },
    favoritesParams: {
      city,
      type: 'blockEntity',
    },
    fields: {
      blocks: [],
      status: [],
      districts: [],
      rooms: [],
      endings: {},
      prices: {},
      builders: [],
      isOnlyHanded: '',
    },
  }, city);
  
  useEffect(() => {
    setLoadCondition(city);
    clearFilter();
    clearExclude();
    setMapItems(false);
  }, [city])

  const [showMapBlock, setShowMapBlock] = useState(!media("isMobile"));
  const showHintRef = useRef(null);

  const saveToLocalSotage = id => localStorage.setItem(`${city}Filter`, JSON.stringify({ [id]: { filter } }))

  const [openFilter, closeFilter] = useModal(
    () => (
      <Wrap
        wrapper={(children) =>
          isMobile ? (
            <MobileModal
              className="sf-mobile"
              closeClick={closeFilter}
              title="Параметры фильтра"
            >
              {children}
            </MobileModal>
          ) : (
            <Modal close={closeFilter} classes="modal_task">
              {children}
            </Modal>
          )
        }
      >
        {!isLoading && (
          <MobileMapFilter
            redesign={true}
            component={props => <>
              <DubaiTopFilters
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
                currency={currency}
                setCurrency={setCurrency}
                currencyList={prices}
                city={city}
              />
              <DubaiBottomFilters
                filterData={filterData}
                filter={filter}
                exclude={exclude}
                setFilterAndExclude={setFilterAndExclude}
                handleFilter={handleFilter}
                count={`${count} объект${getSuffix(
                  count,
                )}`}
                onSumbit={submit}
                isLoading={isCountLoading || isLoading}
                disableButton={!count}
                clearByIdent={clearByIdent}
              />
              <button
                className="button button_view_outline for-mobile-filters"
                type='button'
                style={{ margin: "10px 0" }}
                onClick={saveFilterModal}
              >
                Сохранить фильтр
              </button>

            </>}
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            clearByIdent={clearByIdent}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={() => {
              closeFilter();
              submit();
            }}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            isMobile={isMobile}
          />
        )}
      </Wrap>
    ),
    [isLoading, filterData, filter, exclude, count, isCountLoading, isMobile],
  );

  // умный поиск, копипаста кода Айбека, отрефакторить и вынести как обёртку  или забить болт
  const [showFilter, toggleFilter] = useState(false);

  const [saveFilterModal, hideSaveFilterModal] = useModal(
    () => (
      <Wrap wrapper={children => isMobile
        ? <MobileModal closeClick={hideSaveFilterModal} title="Сохранить фильтр" classes="modal_filter">{children}</MobileModal>
        : <Modal close={hideSaveFilterModal} classes="modal_filter">{children}</Modal>
      } >
        <SendFilter
          filter={{ filter, exclude }}
          filterData={filterData}
          filterType="global"
          hide={hideSaveFilterModal}
          city={city}
        />
      </Wrap >
    ),
    [filter, exclude, filterData],
  );

  if (isLoading) return <Preloader loading={true} />;
  if (view === 'map')
    return (
      <div className="sf sf_map">
        <RealtyMap
          dispatch={dispatchMap}
          mapCenter={[coordinates[0], coordinates[1]]}
          {...mapState}
          items={mapItems}
          extractFeatures={extractDubaiMapItems}
          isLoading={isMapItemsLoading}
          innerCbRef={showHintRef}
          onObjectClick={() => setShowMapBlock(true)}
          currency={currency}
        />
        <div className="sf__map-top">
          <TopBar setShowMap={setView} />
        </div>
        <MapBlock
          items={
            mapState?.selectedItems?.length ? mapState?.selectedItems : mapItems
          }
          filterData={filterData}
          filter={filter}
          exclude={exclude}
          setFilterAndExclude={setFilterAndExclude}
          handleFilter={handleFilter}
          cardComponent={DubaiCardContainer}
          getLink={v => v.link}
          resetFilter={() => resetFilter(true)}
          MapFilterBlock={MapFilters}
          component={DubaiListFilterForm}
          count={`${count} объект${getSuffix(count)}`}
          onSumbit={submitMap}
          disableSubmit={!count}
          showSkeleton={isMapItemsLoading}
          isLoading={isCountLoading || isLoading}
          showFilters={mapState.showFilters}
          setShowFilters={(val) =>
            dispatchMap({ type: 'setData', payload: { showFilters: val } })
          }
          onMouseMove={(id) => showHintRef.current(id)}
          showBlock={showMapBlock}
          setShowBlock={setShowMapBlock}
          isShowFilter
          onClick={saveToLocalSotage}
          currency={currency}
          setCurrency={setCurrency}
          currencyList={prices}
          get={get}
          dubai
        />
      </div>
    );

  return (
    <div className="sf wrapper">
      <h3 className="h3 sf__h1 with-tg-link">
        <p>
          {titleByCity[city]}
        </p>
        {
          !isLaptop &&
          <a href={telegramChannel && telegramChannel?.value} target="_blank" className="with-tg-link__link">
            {/*{city === 'cyp' ? 'Telegram-канал о недвижимости в Северном Кипре' : 'Telegram-канал о недвижимости в Дубаи' }*/}
            {telegramChannel?.title}
            <TgDubaiIcon />
          </a>
        }
      </h3>

      {!isTablet &&
        <div className={`sf__filter filter__max form smartfilter-wrapper smartfilter-wrapper_dubai ${!isLaptop ? "" : "flex"}`}>

          <SmartFilter
            filterData={filterData}
            setFilter={setFilterState}
            handleFilter={setFilterAndExclude}
            filterValues={filter}
            excludeValues={exclude}
            fullFilterValues={{ filter, exclude }}
            clearFilter={resetFilter}
            // toggleFilter={toggleFilter}
            showFilter={showFilter}
            submit={(params) => {
              // handleFilter(params);
              // scrollToRef(itemsRef);
            }}
            city={city}
            clearExcludeOnly={clearExclude}
            clearFilterOnly={clearFilter}
            getCount={() => { }}
            isCleared={clearedSmartFilter}
            resetCleared={() => setCleared(false)}
            placholder="Название комплекса или застройщика"
            textFilters={['districts', 'subways', 'blocks', 'banks', 'builders', "villages"]}
            hideInfoButton
            showClearButton={false}
            dubai
          />
          {!isLaptop && <DubaiTopFilters
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
            wrapperClass=" "
            currency={currency}
            setCurrency={setCurrency}
            currencyList={prices}
            city={city}
          />}
          {!isTablet && city !== 'cyp' && <button
            className="button button_type_reveal-filter white-button"
            type="button"
            onClick={() => toggleFilter(!showFilter)}
          >
            <span >Все фильтры</span>
          </button>}
        </div>
      }
      {(isLaptop || (!isTablet && showFilter)) &&
        <div className="sf__filter filter__max form form_dubai-secondfilter">
          {isLaptop && !isTablet && <DubaiTopFilters
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
            currency={currency}
            setCurrency={setCurrency}
            currencyList={prices}
            city={city}
          />}
          {!isTablet && showFilter && !isMobile && <DubaiBottomFilters
            filterData={filterData}
            filter={filter}
            exclude={exclude}
            setFilterAndExclude={setFilterAndExclude}
            handleFilter={handleFilter}
            count={`${count} объект${getSuffix(
              count,
            )}`}
            onSumbit={submit}
            isLoading={isCountLoading || isLoading}
            disableButton={!count}
            clearByIdent={clearByIdent}
          />}
        </div>
      }
      {!isMobile &&
        <DraggableZone
          filterBadges={filterBadges}
          excludeBadges={excludeBadges}
          clearFilter={clearFilter}
          clearExclude={clearExclude}
          isExcludeEmpty={isExcludeEmpty}
          isFilterEmpty={isFilterEmpty}
          removeBadge={removeBadge}
          switchValues={switchFilterToExclude}
        />
      }
      {/*{isLaptop && !isTablet && (*/}
      {/*  <FilterSubmitButton*/}
      {/*    onClick={submit}*/}
      {/*    isLoading={isCountLoading || isLoading}*/}
      {/*    count={count}*/}

      {/*  />*/}
      {/*)}*/}
      <div className="sf__row sf__row_adaptive">
        {!isTablet && <SfButton onClick={saveFilterModal} />}
        <div />
        <div className="sf__filter-btns">
          {isTablet && <FilterToggleBtn onClick={openFilter} />}
          <MapBtn setShowMap={() => setView('map')} />
          {!isTablet && (
            <FilterSubmitButton
              onClick={submit}
              isLoading={isCountLoading || isLoading}
              count={count}
            />
          )}
        </div>
      </div>
      <div className="sf__row sf__row_adaptive">
        {!isTablet && (
          <div className="sf__special-filter">
            <div className="special-filter-drop special-filter-drop_white-select">
              <Dropdown
                value={sortId}
                options={sortlistDxb}
                onChange={handleSort}
              />
            </div>
            {/*<div>*/}
            {/*  {prices && <CurrencysSelect forCardlist prices={{ prices }} activeCurrency={currency} priceFormat={setCurrency} />}*/}
            {/*</div>*/}

          </div>
        )}

        <div />
        <div className="sf__filter-btns">
        </div>
      </div>
      <CardsList
        items={blocks}
        limit={limit}
        isLoading={showSkeleton}
        itemsRef={infiniteRef}
        cardComponent={DubaiCardContainer}
        getLink={item => item.link}
        listClass="sf__cards-list sf__cards-list_dubai-complex-list"
        onClick={saveToLocalSotage}
        currency={currency}
        {...favoritesProps}
        get={get}
      // withoutBtnsList={!isMobile}
      />
      <LoadMoreSpinner loading={moreIsLoading} />
      {/* <div className="section__grid"> рабочий код, подходит полностью под хук useFilter но Роман хотел автоподгрузку тут 
      <div className="section__grid-col cards__pagination">
        <div className="pagination">
          <Pagination
            currentPage={page}
            itemsCount={count}
            itemsPerPage={limit}
            onChange={changePage}
          />
          {!(limit > count) && (
            <CountRealty
              itemsCount={count}
              itemsPerPage={limit}
              onChange={changeLimit}
            />
          )}
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Dubai;
