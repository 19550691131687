import printJS from 'print-js';
import React from 'react';
import { useModal } from 'react-modal-hook';
import { useDispatch } from 'react-redux';
import { requestPresentation, requestPresentationEnd } from '../../actions/presentation';
import { downloadLink, media } from '../../utils';
import MobileModal from '../core/MobileModal';
import Modal from '../core/Modal';
import {errorToast, standartErrorToast, successToast} from '../toasts';
import SendPresentation from './SendPresentation';
import renderPDF from "./renderPDF";


const PresentationControl = (props) => {
  const { label, name, onAction, path, isFetching } = props;

  return (
    <>
      {(media('isMobile') && name === "download") ?
        <a
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          className="presentation__content-control label_with-icon_prefix link"
        >

          <span>{label}</span>
          <div className="presentation__content-icon">
            <svg className={`icon icon_${name}`}>
              <use xlinkHref={`#${name}`}></use>
            </svg>
          </div>
        </a> :
        <div
          className="presentation__content-control label_with-icon_prefix link"
          onClick={isFetching ? null : onAction}
        >

          <span>{label}</span>
          <div className="presentation__content-icon">
            <svg className={`icon icon_${name}`}>
              <use xlinkHref={`#${name}`}></use>
            </svg>
          </div>
        </div>
      }
    </>
  );
};

const PresentationControls = (props) => {
  const {
    data,
    clients,
    fetchClients,
    attachClient,
    isFetching,
    save,
    path
  } = props;

  const dispath = useDispatch()


  const handlePdfAction = (type = 'download') => {
    if (type === "download" || type === 'print' || type === 'copy') {
      requestPresentation()
    }
    save().then(() => {
      if (type === 'print') {
        printJS(path);
      } else if (type === 'copy') {
        setTimeout(()=>navigator.clipboard.writeText(path)
        .then(() => {
          successToast(<div>Ссылка скопирована в буфер обмена</div>)
        })
        .catch((error) => {
          errorToast(<div>Ошибка копирования</div>)
        }), 200);
     
      }
      else {
        setTimeout(()=>downloadLink(path), 200) ;
      }
    }, standartErrorToast).finally(() => {
      if (type === "download" || type === 'print') {
        dispath(requestPresentationEnd())
      }
    })

  };


  const openPresenttionModal = (params) => {
    save().then(() => media("isMobile") ? sendPresentationMobileModal() : sendPresentationModal(), standartErrorToast)
  }

  const [sendPresentationModal, hideSendPresentationModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal close={hideSendPresentationModal} classes="modal_pres">
        <SendPresentation
          presentationIds={[data.id]}
          fetch={fetchClients}
          clients={clients}
          city={data.city}
          hide={hideSendPresentationModal}
          attachClient={attachClient}
        />
      </Modal>
    ),
    [data, fetchClients, clients],
  );

  const [sendPresentationMobileModal, hideSendPresentationMobileModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal closeClick={hideSendPresentationMobileModal} title="Прикрепить клиента" style={{ zIndex: 110 }}>
        <SendPresentation
          presentationIds={[data.id]}
          fetch={fetchClients}
          clients={clients}
          city={data.city}
          hide={hideSendPresentationMobileModal}
          attachClient={attachClient}
        />
      </MobileModal>
    ),
    [data, fetchClients, clients],
  );

  const controls = () => [
    {
      label: 'Скачать PDF',
      name: 'download',
      action: () => handlePdfAction('download'),
    },
    {
      label: 'Отправить',
      name: 'mail',
      action: openPresenttionModal,
    },
    {
      label: 'Распечатать',
      name: 'print',
      action: () => handlePdfAction('print'),
    },
  ];

  return (
    <div className="presentation__content-controls">
      {controls().map((control, index) => (
        <PresentationControl
          {...control}
          key={index}
          onAction={control.action}
          path={path}
          isFetching={isFetching}
        />
      ))}
      <span className="presentation__content-control_copy link" onClick={() => handlePdfAction('copy')}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6582 7.45712L13.2095 5.90787C13.8658 5.30659 14.7292 4.98163 15.6197 5.0008C16.5101 5.01998 17.3587 5.3818 17.9884 6.01077C18.6181 6.63974 18.9802 7.48726 18.9992 8.37646C19.0182 9.26566 18.6926 10.1278 18.0904 10.7831L15.8735 12.9896C15.5546 13.3092 15.1758 13.5629 14.7587 13.7362C14.3416 13.9096 13.8944 13.9992 13.4427 14C12.9909 14.0008 12.5434 13.9127 12.1257 13.7407C11.708 13.5688 11.3284 13.3164 11.0084 12.9979L11 12.9896" stroke="#FF2D23" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.3414 16.5468L10.7888 18.097C10.1318 18.6961 9.26863 19.0192 8.37903 18.9991C7.48942 18.979 6.64185 18.6171 6.01264 17.9889C5.38344 17.3606 5.02106 16.5143 5.00089 15.626C4.98072 14.7378 5.30433 13.8759 5.90437 13.2198L8.12287 11.012C8.44187 10.692 8.82088 10.438 9.23825 10.2643C9.65561 10.0907 10.1032 10.0009 10.5553 10C11.0074 9.99913 11.4553 10.0872 11.8734 10.2593C12.2914 10.4313 12.6714 10.6839 12.9916 11.0026L13 11.012" stroke="#FF2D23" strokeLinecap="round" strokeLinejoin="round"/></svg>
        Cкопировать ссылку</span>
    </div>
  );
};

export default PresentationControls;
