import React from "react";

const About = (props) => {
  const { title, previewText, detailText, advantages, innerRef, description } = props;

  if (!description || !title) return '';

  return (
    <section class="section section_view_white complex__section-about for-suburbans" ref={innerRef}>
      <div id="anchor-about"></div>
      <div class="wrapper">
        <div class="wrapper__center">
          <div class="section__title">{title}</div>
          <div class="complex__about">
            {previewText && (
              <div
                class="complex__about-col complex__about-info"
                dangerouslySetInnerHTML={{ __html: previewText }}
              ></div>
            )}
            {!!description && (
              <div
                class="complex__about-col complex__about-text"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            )}
          </div>
          {advantages && advantages.length > 0 && (
            <div class="complex__about-features">
              <ul class="complex__about-features_list">
                {advantages.map((advantage) => (
                  <li class="complex__about-feature" key={advantage}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default About;
