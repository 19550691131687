import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
const initialState = {
  isFetching: true,
  dateUpdate: false,
  theme: {

  },
  // headerSlides: [ // на макетах самый верхний блок - слайдер, слайдов пока вроде нет
  //   {
  //     type: "main",
  //     fields: { title: "Первый экран", header: "Заголовок", description: "Подпись к заголовку" },
  //     header: "Продаем и подбираем недвижимость для вас",
  //     slogan: "для счастливой жизни и успешного бизнеса",
  //   }
  // ],
  services: [],
  advantages: [],
  sets: [],
  agent: {},
  promo: [],
  promoText: null,
  reviews: [],
  counters: {},
  sites: [],
  landings: [],
  agents: []
}

const constructor = (state = initialState, action) => {
  switch (action.type) {
    case "CONSTRUCTOR_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "CONSTRUCTOR_SUCCESS":
      const { site, blockTypes, setTypes, socialTypes, cities, count } = action.data
      const tmpSite = { ...site }
      // tmpSite.sets = [...setTypes.map(set => ({ id: set.id, title: set.name, active: 0 })), ...site.sets.map(set => ({ id: set.setType, title: set.title, active: 1 }))].map((set, i) => ({ ...set, fakeId: i }))
      tmpSite.blocks = blockTypes.map(blockType => {
        return {
          id: blockType.id,
          active: site.blocks.some(activeBlock => activeBlock.component === blockType.xmlId && activeBlock.active === 1),
          component: blockType.xmlId
        }
      })
      tmpSite.advantages = site.advantages.map((adv, i) => ({ ...adv, fakeId: i, active: adv.active === 1 ? 1 : 0, isActive: adv.active === 1 }))
      tmpSite.services = site.services.map((serv, i) => ({ ...serv, fakeId: i, active: serv.active === 1 ? 1 : 0, isActive: serv.active === 1 }))
      tmpSite.agency = tmpSite.agency ? tmpSite.agency : {}
      const tmpPromo = tmpSite?.promo?.social ? tmpSite.promo : { social: [] }
      tmpSite.promo.social = uniqBy([...tmpPromo.social, ...socialTypes].map((soc, i) => ({ ...soc, fakeId: i, active: soc.xmlId ? false : true, type: soc.type ? soc.type : soc.xmlId })), "type")
      tmpSite.defaultCity = tmpSite.agent.defaultCity
      tmpSite.promoText = tmpSite.promoText ? tmpSite.promoText : "Лучший сервис на рынке недвижимости"
      tmpSite.email = tmpSite.agency.email
      tmpSite.socials = socialTypes.map((soc, i) => {
        const currentActiveSocial = site.socials.find(social => social.type.xmlId === soc.xmlId)
        return {
          defaultLink: soc.defaultLink,
          id: soc.id,
          name: soc.name,
          active: site.socials.some(social => social.type.xmlId === soc.xmlId),
          fakeId: i,
          type: soc.xmlId,
          logo: soc.logo,
          link: currentActiveSocial?.link ? currentActiveSocial.link : "",
          activeInPromo: site.promo.some(promo => promo.type.xmlId === soc.xmlId), // связать с блоком promo.social, те если в promo.social что-то будет - засетить сюда как активный
        }
      })
      tmpSite.sets = setTypes.map((set, i) => {
        return {
          id: set.id,
          title: set.name,
          active: site.sets.some(activeSet => activeSet.title === set.name || activeSet.name === set.name),
          isActive: site.sets.some(activeSet => (activeSet.title === set.name || activeSet.name === set.name) && activeSet.city === set.city && activeSet.active === 1),
          fakeId: i,
          filter: set.filter,
          city: set.city,
          type: set.type
        }
      })
      tmpSite.reviews = tmpSite.reviews.map((review, i) => {
        return {
          ...review,
          fakeId: i
        }
      })
      tmpSite.dateUpdate = site.dateUpdate ? moment(site.dateUpdate).format('DD.MM.YYYY') : false;

      tmpSite.leadMagnet.file =   tmpSite.leadMagnet.file ? {...tmpSite.leadMagnet.file, fileName: tmpSite.leadMagnet.file.src?.split?.("/")?.reverse()?.[0] } : null;
      return {
        ...state,
        ...tmpSite,
        count,
        isFetching: false,
        blockTypes,
        setTypes,
        socialTypes,
        cities
      };
    case "CONSTRUCTOR_SET_STATE":
      return merge({
        ...state,
      }, action.payload);
    case "CONSTRUCTOR_FORCED_SET_STATE":
      return {
        ...state,
        ...action.payload
      }
    case "CONSTRUCTOR_STATS_SUCCES":
    case "CONSTRUCTOR_ADV_SUCCES":
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
};

export default constructor;
