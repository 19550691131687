import React from 'react';
import './index.scss';

const Answer = ({ question, index, isCorrect, correctAnswer, userAnswer }) => <div className="training__question-box">
  <span className="training__q">{index}. {question}</span>

  {isCorrect ? <span className="training__q-label positive">
    <svg className="icon icon_check_white">
      <use xlinkHref="#check_white"></use>
    </svg>
          Правильно
        </span> : <span className="training__q-label negative">
    <svg className="icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.896973 0.664673L4.89697 4.66467L8.89697 0.664673" stroke="white" strokeWidth="1.5" />
      <path d="M0.896973 8.66467L4.89697 4.66467L8.89697 8.66467" stroke="white" strokeWidth="1.5" />
    </svg>
Неправильно
</span>}

  <div className="training__result-a-row">
    <div className="training__result-a">
      <span className="training__result-text">Ваш ответ</span>
      <p>{userAnswer}</p>
    </div>
    {!isCorrect && <div className="training__result-a">
      <span className="training__result-text">Правильный ответ</span>
      <p>{correctAnswer}</p>
    </div>}
  </div>
</div>


const Result = ({ answersResult, formSettings }) => {
  return (
    <div className="training__poll" >
      <div className="training__poll-header training__poll-header_column">
        <h3 className="h3 training__poll-title">Ваш результат</h3>

        <div className="training__result">
          <div className="training__result-box">
            <span className="training__result-text">Выполнено правильно</span>
            <span className={`training__result-counter ${answersResult.percent < 80? "negative" :"positive"}`}>{answersResult.percent}%</span>
          </div>

          <div className="training__result-box">
            <span className="training__result-text">Начислено баллов</span>
            <span className={`training__result-counter ${!!answersResult.countPoints? "positive" :"negative"}`}>{answersResult.countPoints? answersResult.countPoints : 0}</span>
          </div>
        </div>
      </div>

      <div className="training__questions training__questions_results">

        {
          answersResult.answers.map(({ questionId, isCorrect, index, answer }, i) => {
            return <Answer
              key={questionId}
              index={i+1}
              question={formSettings[i].name}
              isCorrect={isCorrect}
              correctAnswer={formSettings[i]?.items?.[index]?.name}
              userAnswer={formSettings[i]?.items?.[answer]?.name}
            />
          })
        }
      </div>

    </div>
  );
};

export default Result;
