import React, { memo, useMemo, useState } from "react";
import Table from "../../components/core/Table";
import { LocaleNotFound } from "../../components/core/Table/tableUtils";
import "../../components/core/TabsNew/index.scss";
import NewTabsNavigation from "../../components/core/TabsNew/TabsNavigation";
import DownloadApp from "../../components/HomePage/DownloadApp";
import Preloader from '../../components/Preloader';
import { SmallCloseIcon } from "../../components/Public/markup/icons";
import useApi from "../../hooks/useApi";
import useMedia from "../../hooks/useMedia";
import { compareWords } from "../../utils";
import columns from './casesColumns';
import './index.scss';

const findById = (arr = [], id) => arr.find(item => item.id == id)

const Cases = memo(({ history }) => {

  const isMobile = useMedia("isMobile");
  const [downloadApp, setDownloadApp] = useState(isMobile);

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedClass, setSelectedClass] = useState(0) //класс жилья, в фильтре предусмотрено, но на макетах нет

  const { data: { cases, types, filter }, isLoading } = useApi({
    payload: ["panpartner:cases.ajax", "getItems"],
  }, [])

  const [search, setSearch] = useState("")

  const blockClasses = useMemo(() => {
    if (!filter?.class) return [{ title: "Все", id: 0 }];
    return [{ title: "Все", id: 0 }, ...filter.class]
  }, [filter])


  const filteredData = useMemo(() => {
    if (!cases) return []
    const cleanSearch = search.replace(/ /g, "")
    if (selectedTab === 0 && !cleanSearch && selectedClass === 0) return cases
    if (!cleanSearch) return cases.filter(item => {
      const typeCondition = selectedTab === 0 ? true : types[selectedTab].types.includes(item.flatType?.title)
      const classConfition = selectedClass === 0 ? true : item.class === findById(blockClasses, selectedClass)?.title
      return typeCondition && classConfition
    })
    if (!!cleanSearch) return cases.filter(item => {
      const typeCondition = selectedTab === 0 ? true : types[selectedTab].types.includes(item.flatType?.title)
      const classConfition = selectedClass === 0 ? true : item.class === findById(blockClasses, selectedClass)?.title
      const titleCondition = compareWords(item.title, search)
      const blockCondition = compareWords(item.block, search)
      const builderCondition = compareWords(item.builder, search)
      return typeCondition && classConfition && (titleCondition || blockCondition || builderCondition)
    })
  }, [search, cases, selectedTab, selectedClass])

  const openCase = e => {
    const id = e.currentTarget.getAttribute("data-row-key")
    history.push("/cases/" + id)
  }

  if (isLoading) return <Preloader loading />

  return (
    downloadApp ? <DownloadApp downloadApp={downloadApp} setDownloadApp={setDownloadApp} /> :
      <div className="wrapper">
        <div className="common-header">
          <div className="common-header__title"><h3 className="common-title">Кейсы</h3></div>
        </div>
        <div className="video-search">
          <div className="section-search">
            <input className="section-search__inp" value={search} placeholder="Поиск по названию, ЖК, застройщику" onChange={e => setSearch(e.target.value)} />
            {
              search !== '' &&
              <div className="section-search__icon" onClick={() => setSearch("")}>
                <SmallCloseIcon />
              </div>
            }
          </div>
        </div>
        <div className="cases">
          <div className="table2 cases-table">
            {types && <NewTabsNavigation tabs={types} onNavClick={setSelectedTab} activeTabId={selectedTab} />}
            <Table
              rowKey="id"
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              locale={LocaleNotFound}
              onRow={() => ({
                onClick: openCase
              })}
            />
          </div>
        </div>
      </div>
  )
})

export default Cases;
