const initState = {
  city: 'spb',
  theme: 'light',
  clientMode: false,
  togglers: {
  }
};

const general = (state = initState, action) => {
  const { city, clientMode, theme, type, toggler = {}, count } = action;
  switch (type) {
    case "SET_CITY":
      return { ...state, city };
    case "SET_CLIENT_MODE":
      return { ...state, clientMode };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        clientMode: false
      };
    case "SET_THEME":
      return { ...state, theme };
    case "SET_TOGGLERS":
      const tmp = { ...state }
      tmp.togglers = { ...state.togglers, ...toggler }
      return tmp
    case "SET_UNREAD_COUNT":
      return { ...state, unreadCount: count }
    case "GENERAL_SET_HEADER_BANNER": 
      return { ...state, headerBanner: action.payload }
    case "GENERAL_SET_SERVICES_MENU":
      return { ...state, servicesMenu: action.payload }
    default:
      return state;
  }
};

export default general;