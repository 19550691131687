import moment from 'moment';
import React, { useEffect, useState } from 'react';
import usePermission from '../../hooks/usePermission';
import { media } from '../../utils';
import DatepickerDropdown from '../core/DatepickerDropdown';
import SelectFilter from '../FilterRealty/SelectFilter';

require('moment/locale/ru');
moment.locale('ru');

const Badge = (props) => {
  const { item, handleClick, title, identity } = props;

  return (
    <div className="filtered">
      <div className="filtered__inner">
        <span className="filtered__type">{title}:</span>
        <span className="filtered__value">{item.title}</span>
        <button
          className="button filtered__del"
          onClick={(e) => handleClick(item.id, identity)}
        >
          <svg
            className="icon icon_x"
            width="7"
            height="8"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.66667 1.3L0.333333 6.7" strokeLinecap="round"></path>
            <path d="M0.333333 1.3L5.66667 6.7" strokeLinecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

const Filter = (props) => {
  const { filter, filterData, setFilter, clearFilter, isFiltersOpen } = props;
  const isAgent = usePermission('agent');

  const handleFilter = (value, identity) => {
    setFilter({ ...filter, [identity]: value });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    clearFilter();
  };

  const showBadges = () => {
    return (
      filter &&
      (filter.agents.length > 0 ||
        filter.clients.length > 0 ||
        filter.status.length > 0 ||
        filter.date.startDate ||
        filter.date.endDate)
    );
  };

  const [count, setCount] = useState(
    filter &&
      (filter.agents.length +
        filter.clients.length +
        filter.status.length +
        ((filter.date.startDate || filter.date.endDate) && 1))
  );

  useEffect(() => {
    setCount(
      filter &&
        (filter.agents.length +
          filter.clients.length +
          filter.status.length +
          ((filter.date.startDate || filter.date.endDate) && 1))
        )
  }, [filter]);

  // const badgesCount = () => {
  //   return (
  //     filter &&
  //     (filter.agents.length +
  //       filter.clients.length +
  //       filter.status.length +
  //       ((filter.date.startDate || filter.date.endDate) && 1))
  //   );
  // };

  const handleRemoveFilter = (value, identity) => {
    switch (identity) {
      case 'date':
        setFilter({
          ...filter,
          [identity]: { startDate: null, endDate: null },
        });
        break;
      default:
        let newArray = filter[identity];
        newArray = newArray.filter((item) => item != value);
        setFilter({ ...filter, [identity]: newArray });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const addClasses = () => {
    document.body.classList.add('no-scroll')
  }

  const removeClasses = () => {
    document.body.classList.remove('no-scroll')
  }

  const toggleFilters = () => {
    setIsOpen(!isOpen);
    !isOpen
      ? addClasses()
      : removeClasses()
  }

	useEffect(() => {
    if (isFiltersOpen) setIsOpen(!isOpen);
  }, [isFiltersOpen]);

  const filters = (
    <>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, 'agents')}
          isExcludable={false}
          isAutocomplete={true}
          items={filterData.agents ? Object.values(filterData.agents) : []}
          filterValues={filter.agents}
          excludeValues={[]}
          data={{
            title: 'Ответственный за бронь',
            autocompleteTitle: 'Введите имя агента',
            identity: 'agents',
          }}
					isMobile={isOpen && media("isMobile")}
					clearFilters={clearFilter}
        />
      </div>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, 'clients')}
          isExcludable={false}
          isAutocomplete={true}
          items={
            filterData.clients ? Object.values(filterData.clients) : []
          }
          filterValues={filter.clients}
          excludeValues={[]}
          data={{
            title: 'Клиент',
            autocompleteTitle: 'Введите имя клиента',
            identity: 'clients',
          }}
					isMobile={isOpen && media("isMobile")}
					clearFilters={clearFilter}
        />
      </div>
      <div className="mini-filter__col">
        <DatepickerDropdown
          onChange={(value) => handleFilter(value, 'date')}
          value={filter.date}
          label="Дата"
					isMobile={isOpen && media("isMobile")}
					clearFilters={clearFilter}
        />
      </div>
      <div className="mini-filter__col">
        <SelectFilter
          onChange={(a, b) => handleFilter(a, 'status')}
          isExcludable={false}
          isAutocomplete={false}
          items={filterData.status ? Object.values(filterData.status) : []}
          filterValues={filter.status}
          excludeValues={[]}
          data={{
            title: 'Статус',
            identity: 'status',
          }}
					isMobile={isOpen && media("isMobile")}
					clearFilters={clearFilter}
        />
      </div>
    </>
  )

  const badges = (
    <>
      {showBadges() && (
        <div className="index-search__filter-applied">
          <div className="index-search__filter-inc">
            {filter.agents.map((id) => {
              let b = filterData.agents.find((item) => id == item.id);
              return (
                <Badge
                  key={`agents${id}`}
                  identity={`agents`}
                  item={b}
                  title={`Агент`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.clients.map((id) => {
              let b = filterData.clients.find((item) => id == item.id);
              return (
                <Badge
                  key={`clients${id}`}
                  identity={`clients`}
                  item={b}
                  title={`Клиент`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.status.map((id) => {
              let b = filterData.status.find((item) => id == item.id);
              return (
                <Badge
                  key={`status${id}`}
                  identity={`status`}
                  item={b}
                  title={`Статус`}
                  handleClick={handleRemoveFilter}
                />
              );
            })}
            {filter.date && (filter.date.startDate || filter.date.endDate) && (
              <Badge
                key={`date`}
                identity={`date`}
                item={{
                  id: 0,
                  title: `${
                    filter.date.startDate
                      ? moment(filter.date.startDate).format('ll')
                      : ``
                  } - ${
                    filter.date.endDate
                      ? moment(filter.date.endDate).format('ll')
                      : ``
                  }`,
                }}
                title={`Дата`}
                handleClick={handleRemoveFilter}
              />
            )}
          </div>
        </div>
      )}
    </>
  )

  return (
    <div className="mini-filter for-meetings">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {media("isMobile") ?
          <button className="button-mobile for-main" onClick={toggleFilters}>
            Фильтры
            {count > 0 && ` (${count})`}
          </button> :

          <div className="mini-filter__grid">

            {filters}

            <div className="mini-filter__col mini-filter__col_clear">
              <button
                className="button button_type_clear mini-filter__button"
                onClick={handleClearFilter}
                disabled={!showBadges()}
              >
                <svg className="icon icon_x">
                  <use xlinkHref="#x"></use>
                </svg>
                <span>Очистить фильтр</span>
              </button>
            </div>
          </div>
        }
      </form>

      {isOpen && media("isMobile") &&
				<div className="mobile-filters for-clients">
					<div className="mobile-filters__title">
						<svg
							className="icon icon_arrow_up"
							onClick={() => {
								// props.onClick()
								toggleFilters()
							}}
						>
							<use xlinkHref="#arrow_up"></use>
						</svg>
						<h3>Все фильтры</h3>
					</div>
					<div className="mobile-filters__content">
            {filters}
            {badges}

            <div className="mobile-filters__show-all for-agents">
              <button
                className="button button_view_default"
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  toggleFilters()
                }}
              >
                Показать
              </button>
              <button
                className="button button__mobile-filters"
                onClick={(e) => {
                  handleClearFilter(e)
                  toggleFilters()
                }}
              >
                Очистить
              </button>
            </div>
          </div>
        </div>
      }

      {!media('isMobile') && badges}

    </div>
  );
};

export default Filter;
