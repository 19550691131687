import Button from '../../core/Button';

const FilterToggleBtn = (props) => {
  return (
    <Button addClass="sf__btn" buttonType="secondary" {...props}>
      {props.icon && props.icon}
      <b>Фильтр</b>
    </Button>
  );
};

export default FilterToggleBtn;
