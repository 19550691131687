import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Toggler from '../core/Toggler';
import MobileSlideMenu from '../core/Toggler/MobileSlideMenu';
import Overlay from '../core/Toggler/Overlay';
import useMedia from "../../hooks/useMedia";

const Header = () => {
  const links = [
    {
      id: 0,
      link: `/`,
      text: `С чего начать`,
    },
    // {
    //   id: 1,
    //   link: ``,
    //   text: `Инструменты`,
    // },
    {
      id: 2,
      link: `/schedule`,
      text: `Мероприятия`,
    },
    // {
    //   id: 3,
    //   link: `/selfemployed`,
    //   text: `Самозанятым`,
    // },

    {
      id: 4,
      link: `/support`,
      text: `Контакты`,
    },
  ];

  const [menu, setMenu] = useState(null);
  const openMenuHandler = () => setMenu((prevState) => !prevState);
  const isMobile = useMedia("isMobile")
  const utms = useLocation().search;


  return (
    <header className="hp__header">
      <Overlay state={menu} setState={setMenu} />
      <div className="wrapper">
        <div className="wrapper__center">
          <div className="hp__header-content">
            <Link to={`/${utms}`}>
              <img
                className="hp__header-logo"
                src={!isMobile ? require('../../assets/images/hp/newlogo.svg').default : require('../../assets/images/hp/logoicon.svg').default}
                alt=""
              />
            </Link>
            <nav className="hp__nav">
              <Toggler clickHandler={openMenuHandler} addClass="hp__toggler" />
              <MobileSlideMenu
                state={menu}
                clickHandler={openMenuHandler}
                togglerClass="hp__toggler opened"
                addClass="hp__nav-list"
              >
                {links.map((link) => (
                  <div className="hp__nav-item" key={`nav-link${link.link}`}>
                    <NavLink
                      to={`${link.link}${utms}`}
                      activeClassName="active"
                      className="hp__nav-anchor button"
                      exact
                    >
                      {link.text}
                    </NavLink>
                  </div>
                ))}

                <div className="hp__nav-enter">
                  <div className="hp__nav-item">
                    <Link
                      to={`/register${utms}`}
                      className="hp__nav-anchor button"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1345 10.6425C11.8991 10.6425 13.3295 9.15555 13.3295 7.32126C13.3295 5.48698 11.8991 4 10.1345 4C8.36992 4 6.93945 5.48698 6.93945 7.32126C6.93945 9.15555 8.36992 10.6425 10.1345 10.6425Z"
                          stroke="#8F95AC"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M12.0015 10.0149C12.0015 10.0149 16 11.0914 16 15.9816H4C4.00901 15.099 4.20268 14.2291 4.56756 13.4322C4.93244 12.6352 5.45979 11.9304 6.113 11.3666"
                          stroke="#8F95AC"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                        />
                      </svg>
                      Регистрация
                    </Link>
                  </div>
                  <div className="hp__nav-item">
                    <Link
                      to={`/login${utms}`}
                      className="hp__nav-anchor button"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9998 11.0071C10.8614 11.0071 10.726 10.966 10.6109 10.8891C10.4958 10.8122 10.4061 10.7028 10.3531 10.5749C10.3001 10.447 10.2862 10.3063 10.3133 10.1705C10.3403 10.0347 10.4069 9.90998 10.5048 9.81208C10.6027 9.71419 10.7275 9.64752 10.8632 9.62051C10.999 9.5935 11.1398 9.60736 11.2677 9.66034C11.3956 9.71332 11.5049 9.80304 11.5818 9.91816C11.6588 10.0333 11.6998 10.1686 11.6998 10.3071C11.6998 10.4927 11.6261 10.6708 11.4948 10.802C11.3635 10.9333 11.1855 11.0071 10.9998 11.0071Z"
                          fill="#8F95AC"
                        />
                        <path
                          d="M4 16.6001H16.6"
                          stroke="#8F95AC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.39941 16.6V5.4C5.39941 5.0287 5.54691 4.6726 5.80946 4.41005C6.07201 4.1475 6.42811 4 6.79941 4H10.9994M13.7994 11.35V16.6"
                          stroke="#8F95AC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.8002 4.70007L11.7002 6.80007L13.8002 8.90007M16.6002 6.80007H11.7002H16.6002Z"
                          stroke="#8F95AC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Войти
                    </Link>
                  </div>
                </div>
              </MobileSlideMenu>
            </nav>

            <div className="hp__btns">
              <Link
                // to={`/register${utms}`}
                to={`/register/new`}
                className="hp__header-anchor button link link_color_brand-3"
              >
                Регистрация
              </Link>
              <Link
                to={`/login${utms}`}
                type="button"
                className="hp__header-btn button"
              >
                Войти
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
