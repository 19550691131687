import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  clearCommercial,
  clearFilterCommercial,
  fetchCommercial,
  getCount,
  setFilterCommercial
} from "../../actions/commercial";
import useDocTitle from "../../hooks/useDocTitle";
import { mapCommercial } from "../../selectors/commercial";
import { filterToParams, scrollToRef } from "../../utils";
import { defaultFullFilter } from "../../utils/defaultFilters";
import Preloader from "../Preloader";
import Top from "../Top";
import Filter from "./Filter";
import Items from "./Items";

const List = (props) => {
  const {
    filterData,
    filter,
    fetch,
    reset,
    isFetching,
    setFilter,
    clearFilter,
    data,
    top,
    features,
    mapItems,
    getCount,
    countToShow,
    isAgent,
    clientMode,
    user
  } = props;

  const itemsRef = useRef(null);
  const [count, setCount] = useState(0);

  const handleFilter = (params) => {
    fetch("spb",0, params).then((data) => {
      scrollToRef(itemsRef, 80);
    });
  };
  useDocTitle("Коммерческая недвижимость")
  useEffect(() => {
    let params= filterToParams(defaultFullFilter)
    getCount(params);
    fetch("spb",1, params);
    return () => {
      reset();
    };
  }, []);

	const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleChildClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
	}

  const closeClick = () => {
    setIsFiltersOpen(false);
	}

  // useEffect(() => {
  //   // if (isFiltersOpen) setIsOpen(!isOpen);
  //   setIsFiltersOpen(false);
  // }, [closeClick]);

  return (
    <>
      <Filter
        filter={filter}
        filterData={filterData}
        setFilter={setFilter}
        clearFilter={clearFilter}
        onSubmit={(params) => {
          handleFilter(params);
        }}
        getCount={getCount}
        info={data}
        countToShow={countToShow}
				isFiltersOpen={isFiltersOpen}
				onClick={handleChildClick}
        closeClick={closeClick}
        countFilters={setCount}
      />
      {top.length > 0 && (
        <Top isAgent={isAgent} items={top} clientMode={clientMode} isCommercial={true}  title={`ТОП-${top.length} коммерческих объектов`} />
      )}
      <Items
        items={data}
        innerRef={itemsRef}
        features={features}
        mapItems={mapItems}
        isAgent={isAgent}
        clientMode={clientMode}
        onClick={handleChildClick}
        count={count}
      />
      <Preloader loading={isFetching} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetch: (city, isGetFilter = 0, form) => dispatch(fetchCommercial(city, isGetFilter, form)),
  reset: () => dispatch(clearCommercial()),
  setFilter: (filter) => dispatch(setFilterCommercial(filter)),
  clearFilter: () => dispatch(clearFilterCommercial()),
  getCount: (params) => dispatch(getCount(params)),
});

const mapStateToProps = (state) => {
  return {
  filterData: state.commercial.filterData,
  filter: state.commercial.filter,
  isFetching: state.commercial.isFetching,
  error: state.commercial.error,
  data: state.commercial.data,
  features: mapCommercial(state),
  mapItems: state.commercial.mapItems,
  top: state.commercial.top,
  items: state.commercial.data,
  countToShow: state.commercial.countToShow,
  isAgent: state.user.info.groups.some((group) => group === "agent"),
  clientMode: state.general.clientMode,
  user: state.user.info,
}};

export default connect(mapStateToProps, mapDispatchToProps)(List);
