import React, { memo } from "react";

const NewsShare = memo(props => {

  const {description} = props;
  return (

    <div className="news__share share">
      <ul className="share__list">
        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://t.me/share/url?url=${window.location.href}&text=${description}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon">
              <use xlinkHref="#socials_tg"></use>
            </svg>
          </a>
        </li>
        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://wa.me/?text=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon">
              <use xlinkHref="#socials_whatsapp"></use>
            </svg>
          </a>
        </li>
        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon">
              <use xlinkHref="#social-fb"></use>
            </svg>
          </a>
        </li>
        <li className="share__item">
          <a
            className="share__anchor button button_view_round-control"
            href={`https://vk.com/share.php?url=${window.location.href}&title=${description}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon">
              <use xlinkHref="#social-vk"></use>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  )
});

export default NewsShare;
