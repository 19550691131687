import React from "react";
import ContentLoader from "react-content-loader";
import { useMediaQuery } from "beautiful-react-hooks";

const Loader = (props) => {
  const middleScreen = useMediaQuery(
    "(max-width: 1600px)and (min-width: 640px) "
  );
 const wrapperClass = props.wrapperClass
  return (
    <div class={`section__grid-col cards__item cards__item_no-border ${wrapperClass? wrapperClass: ""}`}>
      {middleScreen ? (
        <ContentLoader
          speed={2}
          width={1180}
          height={350}
          viewBox="0 0 1180 350"
          backgroundColor="#ebebeb"
          foregroundColor="#f4f3f5"
          {...props}
        >
          <rect x="0" y="0" rx="4" ry="4" width="520" height="350" />
          <rect x="565" y="30" rx="4" ry="4" width="420" height="60" />
          <rect x="1019" y="30" rx="4" ry="4" width="123" height="60" />
          <rect x="565" y="125" rx="4" ry="4" width="420" height="20" />
          <rect x="565" y="158" rx="4" ry="4" width="420" height="20" />
          <rect x="565" y="194" rx="4" ry="4" width="420" height="20" />
          <rect x="566" y="240" rx="4" ry="4" width="320" height="20" />
          <rect x="565" y="290" rx="4" ry="4" width="170" height="30" />
        </ContentLoader>
      ) : (
        <ContentLoader
          speed={2}
          width={469}
          height={700}
          viewBox="0 0 469 700"
          backgroundColor="#ebebeb"
          foregroundColor="#f4f3f5"
          {...props}
        >
          <rect x="0" y="0" rx="4" ry="4" width="469" height="310" />
          <rect x="30" y="359" rx="4" ry="4" width="270" height="60" />
          <rect x="340" y="359" rx="4" ry="4" width="90" height="60" />
          <rect x="30" y="454" rx="4" ry="4" width="350" height="20" />
          <rect x="30" y="487" rx="4" ry="4" width="350" height="20" />
          <rect x="30" y="521" rx="4" ry="4" width="350" height="20" />
          <rect x="30" y="575" rx="4" ry="4" width="300" height="20" />
          <rect x="30" y="627" rx="4" ry="4" width="200" height="30" />
        </ContentLoader>
      )}
    </div>
  );
};

export default Loader;
